import * as React from 'react';
import CompanyPopup from '../../containers_v2/client-companies/popup/CompanyPopup';
import { PopupMode, PopupStyle } from '../popup/model/Model';
import Popup from '../popup/Popup';

function ClientCompany<IsOpen extends boolean>(props: {
  isOpen: IsOpen
  setOpen: (value: boolean) => void
  clientCompanyId: IsOpen extends true ? number : (number | undefined)
  fullOpenMode?: boolean
  disableOnClickOut?: boolean
  disableOnEscPressed?: boolean
  noFullScreen?: boolean
  popupStyle?: PopupStyle
}): JSX.Element {
	const [popupMode, setPopupMode] = React.useState<PopupMode>(props.fullOpenMode ? PopupMode.Default : PopupMode.Details);
	const [disableClickOut, setDisableClickOut] = React.useState<boolean>(props.disableOnClickOut ?? false);
	const [disableOnEscPressed, setDisableOnEscPressed] = React.useState<boolean>(props.disableOnEscPressed ?? false);

	React.useEffect(() => {
		setDisableClickOut(props.disableOnClickOut ?? false);
	}, [props.disableOnClickOut]);

	React.useEffect(() => {
		setDisableOnEscPressed(props.disableOnEscPressed ?? false);
	}, [props.disableOnEscPressed]);

	React.useEffect(() => {
		setPopupMode(props.fullOpenMode ? PopupMode.Default : PopupMode.Details);
	}, [props.fullOpenMode]);

	function switchFullOpenMode(value: boolean): void {
		if (value) {
			setPopupMode(PopupMode.Default);
		} else {
			setPopupMode(PopupMode.Details);
		}
	}

	function close(): void {
		props.setOpen(false);
		switchFullOpenMode(!!props.fullOpenMode);
	}

	return <Popup
		isOpen={props.isOpen}
		onClickOut={() => close()}
		popupStyle={{ ...props.popupStyle, animate: true, minWidth: '450px' }}
		popupMode={popupMode}
		disableOutClick={disableClickOut}
		disableEsc={disableOnEscPressed}
		content={props.clientCompanyId && <CompanyPopup
			onDisableClickOut={(value) => setDisableClickOut(value)}
			onClickOut={() => close()}
			mode={popupMode}
			setFullOpen={(value) => switchFullOpenMode(value)}
			clientCompanyId={props.clientCompanyId}
			noFullScreen={props.noFullScreen}
		/>}
	/>;
}

export default ClientCompany;
