import styled, { keyframes } from 'styled-components';
import CheverontWhite from 'images/icon/cheveront_white.svg';

export const WhiteContainer = styled.div`
    margin-bottom: 10px;
    background: white;
    height: calc(100vh - 175px);
    border-radius: 4px;
    padding: 25px;
    overflow: scroll;
    border-radius: 10px;
    position: relative;
`;

export const Card = styled.li`
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
    width: 240px;
    height: 340px;
    border-radius: 6px;
    display: inline-block;
    margin: 0 25px 25px 0;
    cursor: pointer;
`;

export const PhotoContainer = styled.div`
    width: 100%;
    height: 66%;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
    position: relative;
`;

export const Photo = styled.img<{ height?: number, width?: number }>`
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: relative;
    height: ${p => p.height ?? 0}px;
    width: ${p => p.width ?? 0}px;
`;

export const PhotoText = styled.p<{ size?: number, weight?: number, maxLines?: number }>`
    margin: 4px 0px 0px 15px;
    font-size: ${p => p.size ?? 12}px;
    font-weight: ${p => p.weight ?? 500};
    text-overflow: ellipsis;
    overflow: hidden; 
    width: calc(100% - 15px); 
    height: ${p => 17 * (p.maxLines ?? 1)}px;
    white-space: ${p => (p.maxLines ?? 1) < 2 ? 'nowrap' : ''};
`;

export const UserPhoto = styled.img`
    height: 20px;
    width: 20px;
    border-radius: 80px;
    margin-right: 5px;
`;

export const ImageSwapper = styled.div<{ right?: boolean }>`
    width: 30px;
    height: 30px;
    display: inline-block;
    transform: translateX(-50%) rotate(${p => p.right ? '-' : ''}90deg);
    background-image: url('${CheverontWhite}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    cursor: pointer;
    position: relative;
    top: 50%;
    left: ${p => p.right ? 30 : 0}px;
`;
export const PhotoDetails = styled.div<{ fontWeight?: number, fontSize?: number }>`
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    font-size: ${p => p.fontSize ?? 16}px;
    font-weight: ${p => p.fontWeight ?? 400};
    color: white;
    margin-top: 20px;
`;

export const CardListContainer = styled.ol<{ isClose: boolean }>`
    display: ${p => p.isClose ? 'none' : ''};
    overflow-x: scroll;
`;

export const DateTitle = styled.p`
    font-size: 17px;
    font-weight: 500;
    padding-left: 16px;
    margin-bottom: 14px;
    display: inline-block;
    width: fit-content;
    margin-right: 10px
`;

const rotate = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
  }
`;

const rotateReverse = keyframes`
  from {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
`;

export const PhotoDivider = styled.div<{ height?: number, width?: number, left?: number, top?: number, borderRight?: boolean, borderBottom?: boolean, borderLeft?: boolean, borderTop?: boolean }>`
  height: ${p => p.height ?? 0}px;
  width: ${p => p.width ?? 0}px;
  left: ${p => p.left ?? 0}px;
  top: ${p => p.top ?? 0}px;
  border-right: ${p => p.borderRight ? 'solid 2px white' : ''};
  border-bottom: ${p => p.borderBottom ? 'solid 2px white' : ''};
  border-top: ${p => p.borderTop ? 'solid 2px white' : ''};
  border-left: ${p => p.borderLeft ? 'solid 2px white' : ''};
  position: absolute;
  overflow: hidden;
`;

export const PhotoCounter = styled.div<{ len: number }>`
  display: ${p => p.len <= 4 ? 'none' : ''};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
`;

export const Close = styled.img`
  width: 50px;
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;
`;

export const LoaderContainer = styled.div`
  width: fit-content;
  top: 50%;
  position: relative;
  transform: translate(-50%, -50%);
  left: 50%;
`;

export const Wrapper = styled.div<{ isModalOpen: boolean }>`
  margin-left: ${p => p.isModalOpen ? '260px' : '0px'};
  transition: 0.25s;
`;

export const BlackContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 105;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
