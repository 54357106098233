import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import {getOption} from '../../../../../../helpers/data/defaultValueSelfAudit';
import Select from 'react-select';
import {colourStyles} from '../../../../../../helpers/colorStyle';

class DropdownList extends Component {
  state = {
    options: [],
    value: []
  };

  componentDidMount() {
    const { data } = this.props
    const options = getOption(data.type || '');
    const key = data && data.values || []
    this.setState({ options })
    if (key.length > 0) {
      const value = options.filter(c => key.includes(c.value))
      this.setState({ value: value })
    }
  }

  setValue = value => {
    const val = value.map(x => x.value)
    this.props.editElementProp('values', 'values', val)
    this.setState({ value })
  }



  render() {
    const { data } = this.props;

    return (
      <div className="form-group">
        <label id={`id-${data.shelf_audit_field_id}`} className="control-label" htmlFor={`id-${data.id}`}>
          {data.mandatory && <span className="text-danger">* </span>}
          <Translate id={data.field_name} />
        </label>
        <Select
          options={this.state.options}
          optionClassName="needsclick"
          isMulti
          id={`id-${data.id}`}
          className="form-control p-0 border-0 "
          onChange={e => this.setValue(e)}
          value={this.state.value}
          styles={colourStyles}
        />
      </div>
    );
  }
}

export default DropdownList;
