import close from 'images/icons/orders/close.svg';
import new_tab_white from 'images/icon/new_tab_white.svg';
import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { AProductFilter } from '../../atoms/filter/productsFilterAtom';
import { ATagFilter } from '../../atoms/filter/tagsFilterAtom';
import { ADatePicker } from '../../atoms/filter/timeFilterAtom';
import { AUserPicker } from '../../atoms/filter/usersFilterAtom';
import { AtomCategory } from '../../atoms/utils/model/Model';
import Pagination from '../../components_v2/pagination/Pagination';
import ToolbarFilter, { ToolbarElement, ToolbarFilterElement } from '../../components_v2/toolbarFilter/ToolbarFilter';
import { useWindowDimensions } from '../../components_v2/utils';
import storeLang from '../../helpers/storeLang';
import RefreshButton from '../../myToolbar/RefreshButton';
import { ToolbarState } from '../globals/mainPage/mainPage';
import NoData from '../noData/NoData';
import Restricted from '../permissions/Restricted';
import { FlexDiv } from '../products/style';
import { getFieldReportInstallers, getFormGallery } from './actions';
import ToolBarDotsGallery from './dots';
import { ModalLeft } from './modalLeft';
import {
	BlackContainer,
	Card,
	CardListContainer,
	Close,
	DateTitle,
	DetailsContainer,
	ImageSwapper,
	Photo,
	PhotoContainer,
	PhotoCounter,
	PhotoDetails,
	PhotoDivider,
	PhotoText,
	UserPhoto,
	WhiteContainer,
	Wrapper
} from './style';
import noUserImage from 'images/icons/user.svg';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { LoadingStateEnum } from '../import/model';
import { AShelfAuditTemplatePicker } from '../../atoms/filter/shelfAuditFilterAtom';
import { AFreeFormTemplatePicker } from '../../atoms/filter/freeFormFilterAtom';

const DEFAULT_LIMIT = 50;

export type TypeList = 'free_forms' | 'shelf_audits'

interface photoData {
  client_company_id?: number
  client_company_name?: string
  date: Date
  shelf_audit_id?: number
  shelf_audit_template_id?: number
  shelf_audit_template_name?: string
  free_form_id?: string
  free_form_template_id?: string
  free_form_template_name?: string
  urls: string[]
  beginning_pictures_length: number
  end_pictures_length: number
  user_id: number
  user_name: string
  user_photo: string
}

function checkClickOut(ref, clickOut) {
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				if (clickOut) {
					clickOut();
				}
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

function getToolBardefinition(type: string): ToolbarFilterElement[] {
	switch (type) {
		case 'shelf_audits':
			return [
				{
					kind: ToolbarElement.USER_PICKER
				},
				ToolbarElement.TAG_FILTER,
				{
					kind: ToolbarElement.PRODUCT_PER_CATEGORY
				},
				{
					kind: ToolbarElement.DATE_PICKER
				},
				ToolbarElement.SHELF_AUDIT_TEMPLATE_PICKER,
			];
		case 'free_forms':
			return [
				{
					kind: ToolbarElement.USER_PICKER
				},
				{
					kind: ToolbarElement.DATE_PICKER
				},
				ToolbarElement.FREE_FORM_TEMPLATE_PICKER,
			];
		default:
			return [];
	}
}

const getDefaultType = (): TypeList | undefined => {
	const type = localStorage.getItem('galleryView');
	if (type === 'free_forms' || type === 'shelf_audits') return type;
};

export default function Gallery(props: {
  setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(true);
	const [typeList, setTypeList] = React.useState<TypeList[]>(['free_forms']);
	const [selectedType, setSelectedType_] = React.useState<TypeList>(getDefaultType() ?? typeList[0]);
	const setSelectedType = (type: TypeList) => {
		setSelectedType_(type);
		localStorage.setItem('galleryView', type);
	};
	const { width } = useWindowDimensions();

	const translate = getTranslate(storeLang.getState().localize);
	// define Ref for keys eventListener
	const [formPhotos, _setFormPhotos] = React.useState<photoData[][]>([[]]);
	const formPhotosRef = React.useRef(formPhotos);
	const [selected, _setSelected] = React.useState<number[][]>([[]]);
	const selectedRef = React.useRef(selected);
	const [photosSettings, setPhotosSettings] = React.useState<{ nat_height: number, nat_width: number }[][]>([]);
	const [modalInfo, _setModalInfo] = React.useState({ isOpen: false, i: 0, monthIndex: 0 });
	const modalInfoRef = React.useRef(modalInfo);
	const [apiStatus, setApiStatus] = React.useState({ old: {}, reload: true, loadingState: 'loaded', limit: DEFAULT_LIMIT, offset: 0, count: undefined });
	const { height: screenHeight, width: screenWidth } = useWindowDimensions();

	const [cancelTokenSource, setCancelTokenSource] = React.useState<CancelTokenSource | undefined>(undefined);

	const pickedUsers = useRecoilValue(AUserPicker(0));
	const pickedDates = useRecoilValue(ADatePicker);
	const filteredTags = useRecoilValue(ATagFilter);
	const filteredProducts = useRecoilValue(AProductFilter);
	const pickedShelfAuditTemplates = useRecoilValue(AShelfAuditTemplatePicker);
	const pickedFreeFormTemplates = useRecoilValue(AFreeFormTemplatePicker);

	React.useEffect(() => {
		if (apiStatus.reload
			&& pickedUsers !== undefined
			&& pickedDates !== undefined
			&& (
				(selectedType == 'free_forms' && pickedFreeFormTemplates !== undefined)
				|| (
					selectedType == 'shelf_audits'
					&& filteredTags !== undefined
					&& filteredProducts !== undefined
					&& pickedShelfAuditTemplates !== undefined
				)
			)
		) {
			setApiStatus({ ...apiStatus });
		}
	}, [pickedUsers, pickedDates, filteredTags, filteredProducts, pickedShelfAuditTemplates, pickedFreeFormTemplates]);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('gallery.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewGallery'
					category={AtomCategory.GLOBAL}
					elements={getToolBardefinition(selectedType)}
					maxWidth={width - 200}
				/>
				<RefreshButton onFilter={() => setApiStatus({ ...apiStatus, reload: true })} isLoading={apiStatus.loadingState == 'loading'} />
			</FlexDiv>,
			bottomRightToolbarComponent: <Restricted to={{ objectAction: 'CreateExport' }}>
				<ToolBarDotsGallery
					limit={apiStatus.limit}
					offset={apiStatus.offset}
					selectedType={selectedType}
				/>
			</Restricted>
		});
	}, [selectedType, apiStatus.loadingState]);

	React.useEffect(() => {
		getFieldReportInstallers()
			.then(res => {
				if (res.data.data.find(ri => ri.name == 'shelf_audit')) { typeList.push('shelf_audits'); }
				setTypeList([...typeList]);
			})
			.catch(console.error);
	}, []);

	React.useEffect(() => {
		if (apiStatus.reload
			&& pickedUsers !== undefined
			&& pickedDates !== undefined
			&& (
				(selectedType == 'free_forms' && pickedFreeFormTemplates !== undefined)
				|| (
					selectedType == 'shelf_audits'
					&& filteredTags !== undefined
					&& filteredProducts !== undefined
					&& pickedShelfAuditTemplates !== undefined
				)
			)
		) {
			cancelTokenSource?.cancel();
			const cancelToken = axios.CancelToken.source();
			setCancelTokenSource(cancelToken);
			apiStatus.loadingState = 'loading';
			apiStatus.reload = false;
			setApiStatus({ ...apiStatus });
			getFormGallery({
				tags: filteredTags,
				userIdList: pickedUsers?.[0],
				products: filteredProducts?.products,
				shelf_audit_templates: pickedShelfAuditTemplates?.map(sat => sat.id),
				free_form_templates: pickedFreeFormTemplates?.map(sat => sat.id),
				periodeRange: pickedDates
					? {
						gteDate: pickedDates[0]?.format('YYYY-MM-DD') ?? '',
						lteDate: pickedDates[1]?.format('YYYY-MM-DD') ?? ''
					}
					: undefined
			}, apiStatus.limit, apiStatus.offset, selectedType, cancelToken.token)
				.then(res => {
					const dataRes = res
						.data[1]
						.map(photo => {
							let date;
							if (photo.shelf_audit_date) {
								date = new Date(photo.shelf_audit_date);
							} else {
								date = new Date(photo.free_form_date);
								date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
							}
							return {
								...photo,
								date,
								urls: photo.photos.map(p => p[0]),
								end_pictures_length: photo.photos.filter(p => p[1] == 'file_end_picture').length,
								beginning_pictures_length: photo.photos.filter(p => p[1] != 'file_end_picture').length
							};
						})
						.reduce((acc, photo) => {
							if (acc.length == 0) { return [[photo]]; }
							const index = acc.findIndex((ph_lst) => ph_lst[0]?.date.getMonth() == photo.date.getMonth() && ph_lst[0]?.date.getFullYear() == photo.date.getFullYear());
							if (index >= 0) {
								acc[index].push(photo);
								acc[index] = acc[index].sort((p1, p2) => p2.date.getTime() - p1.date.getTime());
							} else { acc.push([photo]); }
							return acc;
						}, [])
						.sort((p1, p2) => p2[0].date.getTime() - p1[0].date.getTime());
					setSelected([[]]);
					setPhotosSettings([]);
					setModalInfo({ isOpen: false, i: 0, width: 0, height: 0, monthIndex: 0 });
					setFormPhotos(dataRes);
					apiStatus.loadingState = 'loaded';
					apiStatus.count = res.data[0];
					setApiStatus({ ...apiStatus });
				})
				.catch(e => {
					console.error(e);
					apiStatus.loadingState = 'error';
					setApiStatus({ ...apiStatus });
				});
		}
	}, [apiStatus]);

	React.useEffect(() => {
		document.addEventListener('keydown', keyHandler, false);
		return () => {
			document.removeEventListener('keydown', keyHandler, false);
		};
	}, []);

	const setFormPhotos = (e) => {
		formPhotosRef.current = e;
		_setFormPhotos(e);
	};

	const setSelected = (e) => {
		selectedRef.current = e;
		_setSelected(e);
	};

	const setModalInfo = (e) => {
		modalInfoRef.current = e;
		_setModalInfo(e);
	};

	function keyHandler(event) {
		switch (event.key) {
			case 'Escape':
				closeModal();
				return;
			case 'ArrowLeft':
				changeSelected(-1);
				return;
			case 'ArrowRight':
				changeSelected(1);
		}
	}

	function resizeImage(srcWidth, srcHeight, maxWidth = screenWidth - 300, maxHeight = screenHeight - 300) {
		maxWidth = maxWidth < 0 ? 0 : maxWidth;
		maxHeight = maxHeight < 0 ? 0 : maxHeight;
		const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
		return { width: srcWidth * ratio, height: srcHeight * ratio };
	}

	function changeSelected(change: number) {
		if (!modalInfoRef.current.isOpen) { return; }
		const i = modalInfoRef.current.i;
		const monthIndex = modalInfoRef.current.monthIndex;
		const new_index = (selectedRef.current[monthIndex]?.[i] ?? 0) + change;
		// change card to right when overlap
		if (new_index >= formPhotosRef.current[monthIndex][i]?.urls.length) {
			// change card
			if (formPhotosRef.current[monthIndex][i + 1]) {
				modalInfoRef.current.i = i + 1;
				selectedRef.current[monthIndex][i + 1] = 0;
				selectedRef.current[monthIndex][i] = 0;
				// change month
			} else if (formPhotosRef.current[monthIndex + 1]) {
				modalInfoRef.current.i = 0;
				modalInfoRef.current.monthIndex = monthIndex + 1;
				selectedRef.current[monthIndex + 1] = selectedRef.current[monthIndex + 1] ?? [];
				selectedRef.current[monthIndex + 1][0] = 0;
				selectedRef.current[monthIndex][i] = 0;
			} else {
				return;
			}
			setSelected([...selectedRef.current]);
			setModalInfo({ ...modalInfoRef.current });
			return;
		}
		// change card to left when overlap
		if (new_index < 0) {
			// change card
			if (formPhotosRef.current[monthIndex][i - 1]) {
				modalInfoRef.current.i = i - 1;
				selectedRef.current[monthIndex][i - 1] = formPhotosRef.current[monthIndex][i - 1].urls.length - 1;
				selectedRef.current[monthIndex][i] = 0;
				// change month
			} else if (formPhotosRef.current[monthIndex - 1]) {
				modalInfoRef.current.i = formPhotosRef.current[monthIndex - 1].length - 1;
				modalInfoRef.current.monthIndex = monthIndex - 1;
				selectedRef.current[monthIndex - 1] = selectedRef.current[monthIndex - 1] ?? [];
				selectedRef.current[monthIndex - 1][formPhotosRef.current[monthIndex - 1].length - 1] = formPhotosRef.current[monthIndex - 1][formPhotosRef.current[monthIndex - 1].length - 1].urls.length - 1;
			} else {
				return;
			}
			setSelected([...selectedRef.current]);
			setModalInfo({ ...modalInfoRef.current });
			return;
		}
		if (selectedRef.current[monthIndex] == undefined) { selectedRef.current[monthIndex] = []; }
		selectedRef.current[monthIndex][i] = new_index;
		setSelected([...selectedRef.current]);
	}

	const dateToString = (date: Date): string => date.toLocaleDateString() + ' - ' + date.getHours() + 'h' + (date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes());

	const closeModal = () => {
		setModalInfo({ ...modalInfoRef.current, isOpen: false });
		selectedRef.current[modalInfoRef.current.monthIndex] = selected[modalInfoRef.current.monthIndex] ?? [];
		selectedRef.current[modalInfoRef.current.monthIndex][modalInfoRef.current.i] = 0;
		setSelected([...selectedRef.current]);
	};

	function ShowSelectedPhoto(): JSX.Element {
		const wrapperRef = React.useRef<HTMLDivElement>(null);
		checkClickOut(wrapperRef, closeModal);
		const localSelect = selected[modalInfo.monthIndex]?.[modalInfo.i] ?? 0;
		const currentFormPhoto = formPhotos[modalInfo.monthIndex]?.[modalInfo.i];
		const { width, height } = resizeImage(photosSettings[modalInfo.monthIndex]?.[modalInfo.i]?.[localSelect]?.nat_width ?? 1, photosSettings[modalInfo.monthIndex]?.[modalInfo.i]?.[localSelect]?.nat_height ?? 1);
		if (!modalInfo.isOpen) { return <></>; }
		return (
			(<BlackContainer>
				{/* @ts-expect-error ref */}
				<DetailsContainer innerRef={wrapperRef}>
					<PhotoDetails fontWeight={500}>
						{currentFormPhoto.client_company_id && <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => window.open(`/companies?id=${currentFormPhoto.client_company_id}`, '_blank')}>
							{currentFormPhoto.client_company_name}
							<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
						</div>}
					</PhotoDetails>
					{currentFormPhoto.urls.length > 1 && <PhotoDetails fontSize={14}>
						{(localSelect + 1) + ' / ' + currentFormPhoto.urls.length}
					</PhotoDetails>}
					<div style={{
						marginTop: 20,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
						{formPhotosRef.current[modalInfo.monthIndex]?.[modalInfo.i - 1] || formPhotosRef.current[modalInfo.monthIndex - 1] || localSelect > 0
							? <ImageSwapper onClick={() => changeSelected(-1)} />
							: <div style={{ width: 30, display: 'inline-block' }} />
						}
						<img
							src={currentFormPhoto.urls[localSelect]}
							height={height} width={width}
							onLoad={({ target }) => {
								photosSettings[modalInfo.monthIndex] = photosSettings[modalInfo.monthIndex] ?? [[]];
								photosSettings[modalInfo.monthIndex][modalInfo.i] = photosSettings[modalInfo.monthIndex][modalInfo.i] ?? [];
								// @ts-expect-error Get img height / width
								photosSettings[modalInfo.monthIndex][modalInfo.i][localSelect] = { nat_height: target.naturalHeight, nat_width: target.naturalWidth };
								setPhotosSettings([...photosSettings]);
							}}
						/>
						{formPhotosRef.current[modalInfo.monthIndex + 1] || formPhotosRef.current[modalInfo.monthIndex][modalInfo.i + 1] || currentFormPhoto.urls.length > localSelect + 1
							? <ImageSwapper right onClick={() => changeSelected(1)} />
							: <div style={{ width: 30, display: 'inline-block' }} />
						}
					</div>
					<PhotoDetails fontSize={14}>
						{localSelect < currentFormPhoto.beginning_pictures_length && (
							<div style={{ display: 'inline-block' }}><Translate id="gallery.before" /></div>
						)}
						{localSelect < currentFormPhoto.beginning_pictures_length && currentFormPhoto.beginning_pictures_length > 1 && (
							<div style={{ display: 'inline-block' }}>({localSelect + 1}/{currentFormPhoto.beginning_pictures_length})</div>
						)}
						{localSelect >= currentFormPhoto.beginning_pictures_length && (
							<div style={{ display: 'inline-block' }}><Translate id="gallery.after" /></div>
						)}
						{localSelect >= currentFormPhoto.beginning_pictures_length && currentFormPhoto.end_pictures_length > 1 && (
							<div style={{ display: 'inline-block' }}>({localSelect - currentFormPhoto.beginning_pictures_length + 1}/{currentFormPhoto.end_pictures_length})</div>
						)}
					</PhotoDetails>
					<PhotoDetails>
						{currentFormPhoto.shelf_audit_id &&
							<div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => window.open(`/enform/detaildata/${currentFormPhoto.shelf_audit_id}`, '_blank')}>
								{currentFormPhoto.shelf_audit_template_name}
								<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
							</div>}
						{currentFormPhoto.free_form_template_id &&
							<div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => window.open(`/formBuilder/formData/${currentFormPhoto.free_form_template_id}/detail/${currentFormPhoto.free_form_id}`, '_blank')}>
								{currentFormPhoto.free_form_template_name}
								<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
							</div>}
						{' - ' + currentFormPhoto.user_name + ' - ' + dateToString(currentFormPhoto.date)}
					</PhotoDetails>
				</DetailsContainer>
				<Close src={close} onClick={closeModal} />
			</BlackContainer>)
		);
	}

	function photoCard(photo_list: photoData, i: number, monthIndex: number): JSX.Element {
		const cardPhotoSettings = getPhotosDividers(photo_list.urls.length);
		return (
			<Card
				key={`PhotoCard[${monthIndex}[${i}]]`}
				onClick={() => setModalInfo({ i, isOpen: true, monthIndex })}
			>
				<PhotoContainer>
					{cardPhotoSettings.map((setting, settingIndex) => {
						const { width: cardIconWidth, height: cardIconHeight } = resizeCardImage(photosSettings[monthIndex]?.[i]?.[settingIndex]?.nat_width ?? 1, photosSettings[monthIndex]?.[i]?.[settingIndex]?.nat_height ?? 1, setting.w, setting.h);
						return (
							<PhotoDivider key={`photoDivider[${settingIndex}]`} height={setting.h} width={setting.w} left={setting.l} top={setting.t} borderRight={setting.br} borderBottom={setting.bb} borderTop={setting.bt} borderLeft={setting.bl}>
								<Photo
									src={photo_list.urls[settingIndex]}
									height={cardIconHeight}
									width={cardIconWidth}
									onLoad={({ target }) => {
										photosSettings[monthIndex] = photosSettings[monthIndex] ?? [[]];
										photosSettings[monthIndex][i] = photosSettings[monthIndex][i] ?? [];
										// @ts-expect-error Get img height / width
										photosSettings[monthIndex][i][settingIndex] = { nat_height: target.naturalHeight, nat_width: target.naturalWidth };
										setPhotosSettings([...photosSettings]);
									}}
								/>
							</PhotoDivider>
						);
					})}
					<PhotoCounter len={photo_list.urls.length}><div style={{ marginTop: -1 }}>{photo_list.urls.length}</div></PhotoCounter>
				</PhotoContainer>
				<PhotoText size={13} weight={600}>
					{photo_list.client_company_name}
				</PhotoText>
				<PhotoText weight={400} maxLines={2}>
					{photo_list.shelf_audit_template_name ?? photo_list.free_form_template_name}
				</PhotoText>
				<PhotoText maxLines={1.5}>
					<UserPhoto src={photo_list.user_photo ?? noUserImage} />
					{photo_list.user_name}
				</PhotoText>
				<PhotoText size={9}>
					{dateToString(photo_list.date)}
				</PhotoText>
			</Card>
		);
	}

	return (
		<div>
			<Restricted to={{ objectAction: 'ViewGallery' }}>
				<ModalLeft
					setSelected={t => { setSelectedType(t); setApiStatus({ ...apiStatus, reload: true }); }}
					selected={selectedType}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					typeList={typeList}
				/>
				<Wrapper isModalOpen={isModalOpen}>
					<WhiteContainer>
						{
							!apiStatus.reload && apiStatus.loadingState === 'loaded'
								? !formPhotos?.every(photo => photo.length == 0)
									? formPhotos?.map((photo_list, month_index) => {
										if (photo_list.length == 0) { return <></>; }
										return (
											<div key={`formPhotoDataMonth[${month_index}]`}>
												<DateTitle>{translateMonth(photo_list[0]?.date.getMonth()) + ' ' + photo_list[0]?.date.getFullYear()}</DateTitle>
												<CardListContainer isClose={false}>{photo_list.map((infos, i) => photoCard(infos, i, month_index))}</CardListContainer>
											</div>
										);
									}
									)
									: <NoData
										message={translate('global.nodata.message.gallery') as string}
										messageButton={translate('global.nodata.button.gallery') as string}
										urlButton={'/'}
										height={'100%'}
									/>
								: <></>
						}
						<ComponentLoader noBackground loadingState={apiStatus.loadingState === 'loading' ? LoadingStateEnum.LOADING : LoadingStateEnum.LOADED}/>
					</WhiteContainer>
					<Pagination label={'gallery'} amount={apiStatus.count} steps={[DEFAULT_LIMIT, 100, 200, 500]} onChange={(value) => {
						if (apiStatus.limit == value.step && apiStatus.offset == value.offset) {
							return;
						}
						apiStatus.limit = value.step;
						apiStatus.offset = value.offset;
						apiStatus.reload = true;
						setApiStatus({ ...apiStatus });
					}} />
				</Wrapper>
				{ShowSelectedPhoto()}
			</Restricted>
		</div>
	);
}

function translateMonth(monthId: number): string {
	const translate = getTranslate(storeLang.getState().localize);
	switch (monthId) {
		case 0:
			return translate('date.january').toString();
		case 1:
			return translate('date.february').toString();
		case 2:
			return translate('date.march').toString();
		case 3:
			return translate('date.april').toString();
		case 4:
			return translate('date.may').toString();
		case 5:
			return translate('date.june').toString();
		case 6:
			return translate('date.july').toString();
		case 7:
			return translate('date.august').toString();
		case 8:
			return translate('date.september').toString();
		case 9:
			return translate('date.october').toString();
		case 10:
			return translate('date.november').toString();
		case 11:
			return translate('date.december').toString();
	}
	return '';
}

type PhotoDividerSetting = {
	w: number,
	h: number,
	t: number,
	l: number,
	br?: boolean,
	bb?: boolean,
	bl?: boolean,
	bt?: boolean,
}

export function getPhotosDividers(len: number): PhotoDividerSetting[] {
	const cardWidth = 240; // 100%
	const cardHeight = 224; // 66%

	switch (len) {
		case 0: return [];
		case 1:
			return [{ w: cardWidth, h: cardHeight, t: 0, l: 0 }];
		case 2:
			return [{ w: cardWidth / 2, h: cardHeight, t: 0, l: 0, br: true }, { w: cardWidth / 2, h: cardHeight, t: 0, l: cardWidth / 2, bl: true }];
		case 3:
			return [
				{ w: cardWidth / 2, h: cardHeight, t: 0, l: 0, br: true },
				{ w: cardWidth / 2, h: cardHeight / 2, t: 0, l: cardWidth / 2, bb: true, bl: true },
				{ w: cardWidth / 2, h: cardHeight / 2, t: cardHeight / 2, l: cardWidth / 2, bt: true, bl: true }
			];
		default:
			return [
				{ w: cardWidth / 2, h: cardHeight / 2, t: 0, l: 0, br: true, bb: true },
				{ w: cardWidth / 2, h: cardHeight / 2, t: 0, l: cardWidth / 2, bb: true, bl: true },
				{ w: cardWidth / 2, h: cardHeight / 2, t: cardHeight / 2, l: 0, br: true, bt: true },
				{ w: cardWidth / 2, h: cardHeight / 2, t: cardHeight / 2, l: cardWidth / 2, bl: true, bt: true }
			];
	}
}

export function resizeCardImage(srcWidth, srcHeight, maxWidth, maxHeight) {
	const ratio = Math.max(maxWidth / srcWidth, maxHeight / srcHeight);
	return { width: srcWidth * ratio, height: srcHeight * ratio };
}
