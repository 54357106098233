import trash_black from 'images/ui_icon/trash_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import Avatar from '../../../../../components_v2/avatar/Avatar';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { UserToolTip } from '../../../../../styles/global/ToolTip';
import { LoadingStateEnum } from '../../../../import/model';
import { Owner } from '../../../../orders/model/Model';
import { CompanyDocument } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import { Card, CardImage, CardLeft, CardMiddle, CardRight, CardText, CardTitle, Container, SeeMore, Trash } from '../style/LinkedElementStyle';
import Restricted from '../../../../permissions/Restricted';
import { LinkedElementId } from '../../../../../containers/companies/components/linkedElements/actions';
import { deleteLinkedElementDocument, getLinkedElementDocuments } from '../actions';

function Document(props: {
  id: LinkedElementId
  isExtend: boolean
  newCreation?: CompanyDocument[]
  onDelete?: () => void
}): JSX.Element {
	const DEFAULT_LIMIT = 3;

	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [documents, setDocuments] = React.useState<CompanyDocument[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [offset, setOffset] = React.useState<number>(0);

	const [deletion, setDeletion] = React.useState<{ isOpen: boolean, data?: CompanyDocument }>({ isOpen: false });

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getLinkedElementDocuments({
			id: props.id,
			limit: DEFAULT_LIMIT,
			offset
		}).then(response => {
			setDocuments(response.documents);
			setTotal(response.total > 0 ? response.total : total);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	React.useEffect(() => {
		if (props.newCreation != null) {
			setDocuments([...props.newCreation, ...documents]);
			setOffset(offset + props.newCreation.length);
		}
	}, [JSON.stringify(props.newCreation)]);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getLinkedElementDocuments({
			id: props.id,
			limit: DEFAULT_LIMIT,
			offset: newOffset
		}).then(response => {
			setDocuments(documents.concat(response.documents));
			setTotal(response.total > 0 ? response.total : total);
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}

	function deleteDocument(doc: CompanyDocument): void {
		console.log();
		setDocuments(documents.filter(d => d.document_id !== doc.document_id));
		setOffset(offset - 1);
		setTotal(total - 1);
		(props.onDelete != null) && props.onDelete();
	}

	return (
		(<Container>
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: documents.length > 0
						? documents.map(d => {
							return (
								(<Card isExtend={props.isExtend} onClick={() => window.open(d.document_url, '_blank')} key={`Document[${d.document_id}]`}>
									<CardLeft>
										<UserToolTip user={{ name: d.user_name, photoUrl: d.user_photo } as Owner} left='-35px'>
											{
												d.user_photo && d.user_photo.length > 0
													? <CardImage url={d.user_photo} />
													: <Avatar width="35px" name={d.user_name?.trim() ?? ''} />
											}
										</UserToolTip>
									</CardLeft>
									<CardMiddle>
										<CardTitle>{d.document_name}</CardTitle>
										<CardText>{translate('by_on').toString().replace('{{NAME}}', d.user_name?.trim() ?? '').replace('{{DATE}}', moment.utc(d.document_created_at).local().format('LLL'))}</CardText>
									</CardMiddle>
									<CardRight>
										<Restricted to={{ objectAction: 'DeleteCompanyDocument' }}>
											<Trash src={trash_black} onClick={(e) => {
												e.stopPropagation();
												setDeletion({ ...deletion, isOpen: true, data: d });
											
											}} />
										</Restricted>
									</CardRight>
								</Card>)
							);
						})
						: <NoDataText><Translate id='no_documents' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && documents.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
			<PopupDeletion 
				isOpen={deletion.isOpen} 
				records={0} 
				translationKey={''}
				noVerification
				onClickOut={() => setDeletion({ ...deletion, isOpen: false })}
				customTitle={translate('global.fire.title.delete_document').toString()}
				onValidation={() => {
					if (deletion.data) {
						deleteLinkedElementDocument({
							linkedId: props.id,
							elementId: deletion.data.document_id
						}).then(response => {
							console.log(response);
							deleteDocument(deletion.data!);
						});
					}
				}}
				customMessage={translate('global.fire.delete_document').toString()}
			/>
		</Container>)
	);
}

export default Document;
