/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component} from 'react';
import moment from 'moment';
import {Translate} from 'react-localize-redux';

class Activities extends Component {
  state = {};

  render() {
    const { dataDetail } = this.props;

    return (
      <div className="detail-body">
        <div className="row">
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6 text-md-right px-4">
                <p><Translate id="createby" /></p>
              </div>
              <div className="col-md-6">
                <p className="detail-value-key">
                  {dataDetail.owner.name || '-'}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 text-md-right px-4">
                <p><Translate id="createddate" /></p>
              </div>
              <div className="col-md-6">
                <p className="detail-value-key">
                  {dataDetail.createdAt
                    ? moment(dataDetail.createdAt).format('DD/MM/YY [at] HH:mm')
                    : '-'}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 text-md-right px-4">
                <p><Translate id="modified by" /></p>
              </div>
              <div className="col-md-6">
                <p className="detail-value-key">
                  {dataDetail.updatedBy || '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5 text-md-right px-4">
                <p><Translate id="modification date" /></p>
              </div>
              <div className="col-md-7">
                <p className="detail-value-key">
                  {dataDetail.updatedAt
                    ? moment(dataDetail.updatedAt).format('DD/MM/YY [at] HH:mm')
                    : '-'}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5 text-md-right px-4">
                <p><Translate id="Last activity" /></p>
              </div>
              <div className="col-md-7">
                <p className="detail-value-key">
                  {dataDetail.lastActivity
                    ? moment(dataDetail.lastActivity).format(
                        'DD/MM/YY [at] HH:mm'
                      )
                    : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Activities;
