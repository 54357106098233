/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {Component} from 'react';
import {get} from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Image extends Component {
  state = {
    modalPreview: false
  };

  render() {
    const { data } = this.props;
    const { modalPreview } = this.state;

    const imgSrc = get(data, 'details.temp_url');

    return (
      <div className="form-group row">
        <div className="col-md-3" />
        <div className="col-md-9">
          <div className="image-title">
            {data.is_display_field_name && (
              <p className="">{data.field_name}</p>
            )}
            {imgSrc ? (
              <div className="img-fit-cs">
                <img
                  src={imgSrc}
                  alt="avatar"
                  width="100px"
                  onClick={() => this.setState({ modalPreview: true })}
                />
              </div>
            ) : (
              <div className="image-placeholder">
                <p className="mx-auto my-auto">No Image</p>
              </div>
            )}

            {modalPreview && (
              <Lightbox
                mainSrc={imgSrc}
                onCloseRequest={() => this.setState({ modalPreview: false })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Image;
