import { Address } from '../model/Model';

function addressToString(company: Address | undefined, oneLine = false): string {
	if (company == null) {
		return '';
	}

	let result = company.street ? company.street : '';
	result += company.postalCode ? (result.length > 0 ? (oneLine ? ', ' : '\n') : '') + company.postalCode : '';
	result += company.town ? (result.trim().length !== 0 ? ', ' : '') + company.town : '';
	result += company.country ? (result.length > 0 ? (oneLine ? ', ' : '\n') : '') + company.country : '';
	return result.trim();
}

export {
	addressToString
};
