import trash from 'images/formbuilder/trash.svg';
import * as React from 'react';
import { useState } from 'react';
import { Table } from '../../../components_v2/table/Table';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { TableRow } from '../../orders/templateOrders/style/Style';
import { ModalState } from '../../products/model';
import { Column } from 'react-table';
import useAlert from '../../alert/UseAlert';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { EmailTemplate } from '../../email-templates/model/models';
import NewEmailTemplate from '../../email-templates/newEmailTemplate';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import Restricted from '../../permissions/Restricted';
import usePermission from '../../permissions/usePermission';
import Add from '../../../components_v2/add/Add';
import { deleteEmailTemplate, getEmailTemplates } from './actions';
import { AlertRes } from '../../alert/AlertProvider';
import { getLang } from '../../reports/utils';




function getColumns(
	onDelete: (id: number) => void,
	onOpen: (emailTemplate: EmailTemplate) => void,
	deleteAlowed: boolean,
	editionAlowed: boolean
): Column<EmailTemplate>[] {

	function toCustomLocaleDateString(date: string | null) {
		if (date === null) return '-';
		return 	new Date(date).toLocaleString(
			getLang(), {
				month: 'short', year: 'numeric', day:'numeric'
			}
		);
	}

	const cols = [
		{
			id:'name',
			Header:translateToString('name'),
			accessor:(row: EmailTemplate) => <TableRow onClick={() => editionAlowed && onOpen(row)} cursor={editionAlowed ? 'pointer' : undefined} fontWeight='500' color={DarkGreySidely}>{row.name}</TableRow>,
			width:undefined
		}, 
		{
			id:'public',
			Header:translateToString('public'),
			accessor:(row: EmailTemplate) => <TableRow>{translateToString(row?.public ? 'yes' : 'no')}</TableRow>,
			width:undefined
		},
		{
			id:'subject',
			Header:translateToString('email_template_subject'),
			accessor:(row: EmailTemplate) => <TableRow>{row?.data?.subject}</TableRow>,
			width:undefined

		},
		{
			id:'body',
			Header:translateToString('email_template_body'),
			accessor:(row: EmailTemplate) => <TableRow>{row?.data?.body}</TableRow>,
			width:undefined
		},
		{
			id:'created_at',
			Header:translateToString('created_at'),
			accessor:(row: EmailTemplate) =>
				<TableRow>{toCustomLocaleDateString(row?.created_at)}</TableRow>,
			width:undefined
		},
		{
			id:'updated_at',
			Header:translateToString('updated_at'),
			accessor:(row: EmailTemplate) =>
				<TableRow>{toCustomLocaleDateString(row?.updated_at)}</TableRow>,
			width:undefined
		}
	];
	if (deleteAlowed) {
		cols.push({
			id: 'options',
			Header: ' ',
			accessor: row => <TableRow onClick={() => onDelete(row.id)} cursor="pointer">
				<img src={trash} width={15} />
			</TableRow>,
			// @ts-expect-error width can be a number
			width:35,
			minWidth: 35,
			unresizeable: true
		});
	}
	return cols;
}

export default function EmailTemplateSettings({ setToolBarState }: {setToolBarState: (value: ToolbarState) => void}) {
	const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
	const [creationOpen, setCreationOpen] = useState<ModalState<EmailTemplate>>({ isOpen:false });
	const alert = useAlert();

	const fetchEmailTemplates = React.useCallback(() => getEmailTemplates().then(setEmailTemplates),[]);

	React.useEffect(() => {
		fetchEmailTemplates();
		setToolBarState({
			bottomRightToolbarComponent:
				<Restricted to={{ objectAction: 'DeleteCompanySettings' }}>
					<Add onClick={() => setCreationOpen({ isOpen: true })}/>
				</Restricted>
		});
	}, []);

	const columns = React.useMemo(() => getColumns(
		(id) => {
			alert({
				title:translateToString('do_you_want_to_delete_the_email_template'),
				content: <div>{translateToNode('attention_delete_email_template')}</div>,
				width:'600px',
				mode:'delete'
			}).then(res => {
				if (res === AlertRes.Ok)
					deleteEmailTemplate(id).then(_ => fetchEmailTemplates());
			});
		},
		emailTemplate => setCreationOpen({ isOpen:true, data: emailTemplate }),
		usePermission({ objectAction: 'DeleteCompanySettings' }),
		usePermission({ objectAction: 'UpdateCompanySettings' })
	),[]);

	return <>
		<Table noBorder height={'100%'} columns={columns} data={emailTemplates} />;
		<NewEmailTemplate
			isOpen={creationOpen.isOpen}
			setIsOpen={isOpen => setCreationOpen({ isOpen }) }
			onCreate={fetchEmailTemplates}
			emailTemplate={creationOpen.data} 
		/>
	</>;
    
}