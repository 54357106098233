import { DashboardItem } from '../Dashboard';
import * as React from 'react';
import { FlexDiv } from '../../products/style';
import { Translate } from '../../../styles/global/translate';
import {
	CreateButton,
	DeleteButton,
	EditButton,
	ScrollDiv,
	WidgetBody,
	WidgetTitle,
	WidgetTitleWrapper
} from '../Widget';

export default function ClientCompanyStatusCountWidget(props: {
	value: { count: number },
	item: DashboardItem,
	setItem: (item: DashboardItem) => void,
	onCreationClicked?: () => void,
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
	isHover?: boolean
}) {
	const { value, item, onCreationClicked,onClickDelete, isHover, onClickEdit , setItem } = props;

	return <>
		<WidgetTitleWrapper>
			<FlexDiv justify="space-between" align='center' width='100%'>
				<WidgetTitle>
					{item.title ?? <Translate id='dashboard.widget.client_company_status_count'/>}
				</WidgetTitle>
				<FlexDiv justify="flex-end" gap="2px">
					{onCreationClicked && <CreateButton onClickCreate={onCreationClicked} />}
					{ isHover && onClickEdit && <EditButton onClickEdit={() => onClickEdit(item)}/>}
					{ isHover && onClickDelete && <DeleteButton onClickDelete={() => onClickDelete(item.id as number)} />}
				</FlexDiv>
			</FlexDiv>
		</WidgetTitleWrapper>
		<ScrollDiv centered>
			<WidgetBody> {value.count} </WidgetBody>
		</ScrollDiv>
	</>;
}

