import validated from 'images/icons/import/validated.svg';
import duplicates from 'images/icons/import/duplicates.svg';
import error from 'images/icons/import/error.svg';
import warning from 'images/icons/import/warning.svg';
import * as React from 'react';
import { useBlockLayout, useExpanded, useResizeColumns, useSortBy, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import ReactTooltip from 'react-tooltip';
import { RowType, SidelyRowStatus, TransformationType } from './model';
import { BlueSidely, BorderColor, BorderColor2, DarkGreySidely, DarkGreySidely2, GreenSidely, RedSidely, SidelyBlack } from '../../styles/global/css/Utils';
import { FlexDiv } from '../products/style';
import { DayPickerModal } from '../../components_v2/toolbarFilter/time/DatePicker';
import { useWindowDimensions } from '../../components_v2/utils';
import * as moment from 'moment';
import { ModalState } from '../products/model';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';
import { PartialOpen } from '../client-companies/style/Style';
import eye from 'images/icons/company/eye.svg';
import PageLoader from '../../components_v2/pageLoader/PageLoader';
import ProductPopup from '../products/product';

export const Styles = styled.div`
	padding: 1rem;
		
	.presence {
		text-align: center;
	}
	
	.table {
		overflow: auto;
		display: inline-block;
		border-spacing: 0;
		border: 1px solid ${BorderColor};
		background: white;
		

		.header-content {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-size: 11px;
			font-weight: 600;
		}

		.tr {
			:last-child {
				.td {
					border-bottom: 0;
				}
			}
		}

		input[type="text"] {
			border: none;
			background: transparent;
		}

		.Error {
			background: rgba(247, 104, 91, 0.15); 
		}

		.Checked {
			background: rgba(46, 212, 122, 0.15);
		}
		
		.Waiting {
			background: ${BlueSidely}30;
		}

		.hideText {
			text-overflow: ellipsis;
			overflow: hidden; 
		}

		.importPin {
			display: inline-block;
			width: 15px;
			margin-right: 10px;
		}

		.th {
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid ${BorderColor};
			
			text-align: center;
			
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 21px;
			color: ${DarkGreySidely};

			:last-child {
				border-right: 1px solid ${BorderColor};
			}
		}

		.td {
			margin: 0;
			padding: 0.75rem;
			border-bottom: 1px solid ${BorderColor};
			
			text-align: center;

			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 21px;
			color: ${DarkGreySidely2};
	
			:last-child {
				border-right: 1px solid ${BorderColor};
			}
		}

		.overflow {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.resizer {
			display: inline-block;
			background: ${BorderColor};
			width: 5px;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			transform: translateX(50%);
			z-index: 1;
			${'' /* prevents from scrolling while dragging on touch devices */}
			touch-action:none;

			&.isResizing {
			background: grey;
			}
		}

		.red-dot {
			height: 12px;
			width: 12px;
			background-color: ${RedSidely}CC;
			border-radius: 50%;
			display: inline-block;
			vertical-align: middle;
		}
		.green-dot {
			height: 12px;
			width: 12px;
			background-color: ${GreenSidely}CC;
			border-radius: 50%;
			display: inline-block;
			vertical-align: middle;
		}

		.triangle-up {
			margin-left: 8px;
			display : inline-block;
			height : 0;
			width : 0;
			border-right : 6px solid transparent;
			border-bottom : 6px solid #212529;
			border-left : 6px solid transparent;
		}

		.triangle-down {
			margin-left: 8px;
			display : inline-block;
			height : 0;
			width : 0;
			border-top : 6px solid #212529;
			border-right : 6px solid transparent;
			border-left : 6px solid transparent;
		}

		.percentage {
			display: block;
			text-align: right;
			margin-right: 35%;
		}

		tfoot {
			tr:first-child {
			  td {
				border: 1px solid ${BorderColor};
				text-align: center;

				margin: 0;
				padding: 0.75rem;
				border-bottom: 1px solid ${BorderColor};
				
				text-align: center;
	
				font-family: Poppins;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 21px;
				color: ${DarkGreySidely2};
		
				:last-child {
					border-right: 1px solid ${BorderColor};
				}

			  }
			  height: 50px;
			}
			font-weight: 400;
		}

		.tooltip2 {
			position: relative;
			display: inline-block;
		}
		.tooltip2 .tooltiptext2 {
			visibility: hidden;
			font-family: Poppins;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 21px;
			width: 120px;
			background-color: #fff;
			color: ${SidelyBlack};
			border-radius: 6px;
			padding: 5px;
			position: absolute;
			z-index: 1;
			top: -8px;
			right: 133%;

			// border: solid 0.3px rgb(177, 177, 177);
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
		}
		.tooltip2:hover .tooltiptext2 {
			visibility: visible;
			opacity: 1;
		} 

		ul {
			list-style: none;
		}

		.tooltip3 {
			position: relative;
			display: inline-block;
		}
		.tooltip3 .tooltiptext3 {
			visibility: hidden;
			font-family: Poppins;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 21px;
			width: 200px;
			background-color: #fff;
			color: ${SidelyBlack};
			border-radius: 6px;
			padding: 5px;
			position: absolute;
			z-index: 1;
			top: -8px;
			right: 110%;

			// border: solid 0.3px rgb(177, 177, 177);
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
		}        
		.tooltip3:hover .tooltiptext3 {
			visibility: visible;
			opacity: 1;
		} 

		.tooltipup {
			position: relative;
			display: inline-block;
		}
		.tooltipup .tooltiptextup {
			visibility: hidden;
			font-family: Poppins;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 21px;
			width: 200px;
			background-color: #fff;
			color: ${SidelyBlack};
			border-radius: 6px;
			padding: 5px;
			position: absolute;
			z-index: 1;
			bottom: 150%;
			left: 50%;
			margin-left: -100px;

			// border: solid 0.3px rgb(177, 177, 177);
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
			-o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
		}        
		.tooltipup:hover .tooltiptextup {
			visibility: visible;
			opacity: 1;
		}
	}    
`;

export function getPin(status: SidelyRowStatus | string) {
	switch (status) {
		case SidelyRowStatus.AdminWaiting:
		case 'adminwaiting':
			return <PageLoader size={15} className="importPin"/>;
		case SidelyRowStatus.Warning:
		case 'warning':
			return <img src={warning} className="importPin" />;
		case SidelyRowStatus.Error:
		case 'error':
			return <img src={error} className="importPin" />;
		case SidelyRowStatus.Duplicates:
		case 'duplicates':
			return <img src={duplicates} className="importPin" />;
		case 'validated_rows':
			return <img src={validated} className="importPin" />;
	}
}

export const DurationPickerModalWrapper = styled.div<{ isOpen: boolean, isTop?: boolean, top?: number, left?: number, zIndex?: string }>`
	position: absolute;
	top: ${p => p.top ?? 0}px;
	left: ${p => p.left ?? 0}px;
	box-shadow: 0 2px 6px rgba(0,0,0,0.05),0 0 0 1px rgba(0,0,0,0.07);
	z-index: ${p => p.zIndex ?? 2};
	${p => p.isTop ? 'transform: translateY(-100%);' : ''}
	${p => p.isOpen ? `
		&:after {
			content: " ";
			position: absolute;
			${p.isTop ? 'top' : 'bottom'}: 100%;  /* At the top of the tooltip */
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: ${p.isTop ? BorderColor2 : 'transparent' } transparent ${p.isTop ? 'transparent' : BorderColor2} transparent;
		}
	` : ''}
`;

export function Table(props: {
  height: number
  columns
  data: RowType[]
  displayableData
  editable?: boolean
  updateMyData?: (index: number, key: string, value: string) => void
}) {
	const { height, columns, data, editable, updateMyData, displayableData } = props;
	const [isOpen, setIsOpen] = React.useState<{ left?: number, isOpen: boolean, top?: number, isTop?: boolean, date?: moment.Moment, onDateChange?: (date: moment.Moment) => void }>({ isOpen: false });
	const [isCompanyOpen, setIsCompanyOpen] = React.useState<ModalState<number>>({ isOpen: false });
	const [isProductOpen, setIsProductOpen] = React.useState<ModalState<number>>({ isOpen: false });

	// Use the state and functions returned from useTable to build your UI

	const scrollbarWidth = () => {
		const scrollDiv = document.createElement('div');
		scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: auto; position:absolute; top:-9999px;');
		document.body.appendChild(scrollDiv);
		const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
		return scrollbarWidth;
	};

	// Create an editable cell renderer
	const EditableCell = ({
		value: initialValue,
		row,
		column: { id },
		updateMyData // This is a custom function that we supplied to our table instance
	}) => {
		const type = data[0]?.[id]?.type;
		// We need to keep and update the state of the cell normally
		const [value, setValue] = React.useState(initialValue);
		const { height } = useWindowDimensions();

		if (type === TransformationType.Date) {
			return <div style={{ width: '100px', position: 'relative', all: 'unset' }}>
				<div className='form-group row' style={{ marginBottom: '0' }}>
					<div className='col-12' style={{ position: 'relative' }}>
						<input type="text" value={value} style={{ width: '100%' }} onClick={e => {
							const bounds = e.currentTarget.getBoundingClientRect();
							const isTop = e.screenY > height / 2;
							const date = moment(value, 'L - LT');
							setIsOpen({
								isOpen: true,
								isTop,
								top: !isTop ? bounds.top + bounds.height + 20 : bounds.top - bounds.height,
								left: bounds.left - 60 - bounds.width,
								onDateChange: date => updateMyData(row.original.SidelyRow, id, date.format('L - LT')),
								date: date.isValid() ? date : undefined
							}); }
						} readOnly/>
					</div>
				</div>
			</div>;
		}

		const onChange = e => {
			setValue(e.target.value);
		};

		// We'll only update the external data when the input is blurred
		const onBlur = () => {
			updateMyData(row.original.SidelyRow, id, value);
		};

		// If the initialValue is changed external, sync it up with our state
		React.useEffect(() => {
			setValue(initialValue);
		}, [initialValue]);

		return <>
			<input type="text" value={value} onChange={onChange} onBlur={onBlur} style={{ width: '100%' }} />
			{type === TransformationType.CompanyId && !isNaN(parseInt(value ?? 'NaN')) && <PartialOpen parent={FlexDiv} src={eye} onClick={() => setIsCompanyOpen({ isOpen: true, data: parseInt(value) })} />}
			{type === TransformationType.ProductId && !isNaN(parseInt(value ?? 'NaN')) && <PartialOpen parent={FlexDiv} src={eye} onClick={() => setIsProductOpen({ isOpen: true, data: parseInt(value) })} />}
		</>;
	};

	// Set our editable cell renderer as the default Cell renderer
	const defaultColumn = {
		Cell: EditableCell
	};

	const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		totalColumnsWidth,
		prepareRow,
		state: { expanded }
	} = useTable(
		{
			columns,
			data: displayableData,
			defaultColumn: editable ? defaultColumn : undefined,
			// @ts-expect-error
			updateMyData
		},
		useSortBy,
		useResizeColumns,
		useBlockLayout,
		useExpanded
	);

	const RenderRow = React.useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(row);
			let className = 'tr';
			const row_status = data[index].SidelyRow.status;
			if (row_status == SidelyRowStatus.Error) {
				className += (' Error');
			} else if (row_status == SidelyRowStatus.Warning || row_status == SidelyRowStatus.Validated) {
				className += (' Checked');
			} else if (row_status == SidelyRowStatus.AdminWaiting) {
				className += ' Waiting';
			}
			return (
				<div
					{...row.getRowProps({
						style
					})}
					className={className}
				>
					{row.cells.map(cell => {
						const pin = getPin(data[cell.row.index]?.[`${cell.column.Header}`]?.status);
						return (
							<div {...cell.getCellProps()} className={`td ${data?.[index]?.[cell.column.Header]?.type === TransformationType.Date ? '' : 'hideText'}`}>
								<FlexDiv>
									{pin}
									{cell.render('Cell')}
								</FlexDiv>
							</div>
						);
					})}
				</div>
			);
		},
		[prepareRow, rows]
	);

	// Render the UI for your table
	return <>
		<DurationPickerModalWrapper isOpen={isOpen.isOpen} isTop={isOpen.isTop} top={isOpen.top} left={isOpen.left}>
			{isOpen.isOpen && 
				<DayPickerModal
					noAbsolute
					future
					onClickOut={() => setIsOpen({ isOpen: false })}
					// noDayButton
					time={true}
					date={isOpen.date}
					// noDate
					onDateChange={date => {
						setIsOpen({ isOpen: false });
						isOpen.onDateChange?.(date);
					}}
				/>}
		</DurationPickerModalWrapper>
		<ProductPopup
			isOpen={isProductOpen.isOpen}
			setIsOpen={isOpen => setIsProductOpen({ isOpen })}
			productId={isProductOpen.data}
		/>
		<ClientCompany
			isOpen={isCompanyOpen.isOpen}
			setOpen={isOpen => setIsCompanyOpen({ isOpen })}
			clientCompanyId={isCompanyOpen.data ?? 0}
		/>
		<div {...getTableProps()} style={{ height }} className="table">
			<thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<div {...column.getHeaderProps()} className="th">
								<div
									{...column.getSortByToggleProps({ title: undefined })}
									className="header-content"
								>
									{getPin(data.reduce((acc, row) => Math.max(acc, row[`${column.Header}`]?.status === 3 ? 0 : row[`${column.Header}`]?.status), 0))}
									{column.render('Header')}
									{column.isSorted
										? column.isSortedDesc
											? <div className='triangle-down' />
											: <div className='triangle-up' />
										: ''}

								</div>
								<span {...column.getResizerProps()}
									className={`resizer ${column.isResizing ? 'isResizing' : ''}`}>
								</span>
							</div>
						))}
					</tr>
				))}
			</thead>

			<tbody {...getTableBodyProps()}>
				<ReactTooltip id="reason-tooltip" className='menu-tooltip'
					place="top" effect="solid" delayShow={400} type="light"
					arrowColor={BlueSidely} textColor="white"
					getContent={(key) => {
						if (key == null) {
							return;
						}
						return (<span>
							<Translate id={key} />
						</span>);
					}} />
				<FixedSizeList
					height={height - 40}
					itemCount={rows.length}
					itemSize={50}
					width={totalColumnsWidth + scrollBarSize}
				>
					{RenderRow}
				</FixedSizeList>
			</tbody>

			{/* <tfoot>
				{footerGroups.map(group => (
					<tr {...group.getFooterGroupProps()}>
						{group.headers.map(column => (
							<td {...column.getFooterProps()}>{column.render('Footer')}</td>
						))}
					</tr>
				))}
			</tfoot> */}

		</div>
	</>;
}
