import { atom, RecoilState, selector, selectorFamily, SerializableParam } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

// ----------[ UserPicker ]---------- //
const AglobalUserPicker: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_user_picker',
		default: undefined
	})
};

const AglobalUserPicker1: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_user_picker1',
		default: undefined
	})
};

const AorderUserPicker: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_user_picker',
		default: undefined
	})
};

const AorderUserPicker1: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_user_picker1',
		default: undefined
	})
};

const AgalleryUserPicker: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_user_picker',
		default: undefined
	})
};

const AgalleryUserPicker1: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_user_picker1',
		default: undefined
	})
};

const AcompanyUserPicker: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_user_picker',
		default: undefined
	})
};

const AcompanyUserPicker1: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_user_picker1',
		default: undefined
	})
};

const AcalendarUserPicker: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_user_picker',
		default: undefined
	})
};

const AcalendarUserPicker1: AtomState<[number[], boolean, boolean] | undefined> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_user_picker1',
		default: undefined
	})
};

const AUserPicker = selectorFamily({
	key: 'atom_user_picker',
	get: (atomId = 0) => ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category, atomId);
	},
	set: (atomId = 0) => ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category, atomId), newValue);
	}
});

function getAtom(category: AtomCategory, atomId: SerializableParam): RecoilState<[number[], boolean, boolean] | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			if (atomId === 0) { return AglobalUserPicker.atom; } else { return AglobalUserPicker1.atom; }
		case AtomCategory.ORDER:
			if (atomId === 0) { return AorderUserPicker.atom; } else { return AorderUserPicker1.atom; }
		case AtomCategory.GALLERY:
			if (atomId === 0) { return AgalleryUserPicker.atom; } else { return AgalleryUserPicker1.atom; }
		case AtomCategory.COMPANY:
			if (atomId === 0) { return AcompanyUserPicker.atom; } else { return AcompanyUserPicker1.atom; }
		case AtomCategory.CALENDAR:
			if (atomId === 0) { return AcalendarUserPicker.atom; } else { return AcalendarUserPicker1.atom; }
		default:
			if (atomId === 0) { return AglobalUserPicker.atom; } else { return AglobalUserPicker1.atom; }
	}
}

export {
	AorderUserPicker,
	AcompanyUserPicker,
	AgalleryUserPicker,
	AglobalUserPicker,
	AcalendarUserPicker,
	AUserPicker
};

// ----------[ UserFilter ]---------- //
const AglobalUserFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_user_filter',
		default: undefined
	})
};

const AorderUserFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_user_filter',
		default: undefined
	})
};

const AgalleryUserFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_user_filter',
		default: undefined
	})
};

const AcompanyUserFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_user_filter',
		default: undefined
	})
};

const AcalendarUserFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_user_filter',
		default: undefined
	})
};

const AUserFilter: RecoilState<number | null | undefined> = selector({
	key: 'atom_user_filter',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getRangeFilterAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getRangeFilterAtom(category), newValue);
	}
});

function getRangeFilterAtom(category: AtomCategory): RecoilState<number | null | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalUserFilter.atom;
		case AtomCategory.ORDER:
			return AorderUserFilter.atom;
		case AtomCategory.GALLERY:
			return AgalleryUserFilter.atom;
		case AtomCategory.COMPANY:
			return AcompanyUserFilter.atom;
		case AtomCategory.CALENDAR:
			return AcalendarUserFilter.atom;
		default:
			return AglobalUserFilter.atom;
	}
}

export {
	AorderUserFilter,
	AcompanyUserFilter,
	AgalleryUserFilter,
	AglobalUserFilter,
	AcalendarUserFilter,
	AUserFilter
};
