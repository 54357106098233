import {
	CREATE_COMPANIES_ERROR,
	CREATE_COMPANIES_REQUEST,
	CREATE_COMPANIES_SUCCESS,
	CREATE_LINKEDELEMENT_ERROR,
	CREATE_LINKEDELEMENT_REQUEST,
	CREATE_LINKEDELEMENT_SUCCESS,
	DETAIL_COMPANIES_ERROR,
	DETAIL_COMPANIES_REQUEST,
	DETAIL_COMPANIES_SUCCESS,
	DETAIL_PIN_ERROR,
	DETAIL_PIN_REQUEST,
	DETAIL_PIN_SUCCESS,
	FETCH_ALL_COMPANIES_REQUEST,
	FETCH_ALL_COMPANIES_SUCCESS,
	FETCH_CLIENT_STATUS_ERROR,
	FETCH_CLIENT_STATUS_REQUEST,
	FETCH_CLIENT_STATUS_SUCCESS,
	FETCH_COMPANIES_ERROR,
	FETCH_COMPANIES_MAP_LIST_ERROR,
	FETCH_COMPANIES_MAP_LIST_REQUEST,
	FETCH_COMPANIES_MAP_LIST_SUCCESS,
	FETCH_COMPANIES_REQUEST,
	FETCH_COMPANIES_SUCCESS,
	FETCH_COMPANY_IN_EACH_COUNTRY_ERROR,
	FETCH_COMPANY_IN_EACH_COUNTRY_REQUEST,
	FETCH_COMPANY_IN_EACH_COUNTRY_SUCCESS,
	GET_CONTACTS_ERROR,
	GET_CONTACTS_REQUEST,
	GET_CONTACTS_SUCCESS,
	GET_DOCUMENTS_ERROR,
	GET_DOCUMENTS_REQUEST,
	GET_DOCUMENTS_SUCCESS,
	GET_EMAIL_DETAIL_ERROR,
	GET_EMAIL_DETAIL_REQUEST,
	GET_EMAIL_DETAIL_SUCCESS,
	GET_EMAIL_ERROR,
	GET_EMAIL_REQUEST,
	GET_EMAIL_SUCCESS,
	GET_EVENT_DETAIL_ERROR,
	GET_EVENT_DETAIL_REQUEST,
	GET_EVENT_DETAIL_SUCCESS,
	GET_EVENTS_ERROR,
	GET_EVENTS_REQUEST,
	GET_EVENTS_SUCCESS,
	GET_NOTE_DETAIL_ERROR,
	GET_NOTE_DETAIL_REQUEST,
	GET_NOTE_DETAIL_SUCCESS,
	GET_NOTES_ERROR,
	GET_NOTES_REQUEST,
	GET_NOTES_SUCCESS,
	GET_OPPORTUNITIES_ERROR,
	GET_OPPORTUNITIES_REQUEST,
	GET_OPPORTUNITIES_SUCCESS,
	GET_PICTURES_ERROR,
	GET_PICTURES_REQUEST,
	GET_PICTURES_SUCCESS,
	IMPORT_EXCEL_ERROR,
	IMPORT_EXCEL_REQUEST,
	IMPORT_EXCEL_SUCCESS,
	INSTORE_PRESENCES,
	INSTORE_PRESENCES_CALCULATE_CHILD,
	MAP_REDIRECT,
	RANGE_SELECTED,
	SEARCH_COMPANIES,
	SET_FILTER_GROUP_ID,
	SET_FILTER_OWNER_ID,
	SET_FILTER_STATUS_ID,
	SET_FILTER_TAG_ID,
	SET_INITIAL_FILTER,
	SORT_DATA,
	UPDATE_COMPANIES_ERROR,
	UPDATE_COMPANIES_REQUEST,
	UPDATE_COMPANIES_SUCCESS,
	WEB_VIEW
} from '../actionType/companies.action.type';
import { SET_ACTIVE_FILTER } from '../actions/companies.action';
import { defaultPagination } from '../../helpers/textConfig';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	isLoadingMap: false,
	current: {
		name: 'username'
	},
	allCompanies: [],
	data: [],
	dataCreated: null,
	dataDetail: [],
	parentCompanies: [],
	detail: [],
	search: '',
	sortField: null,
	sort: 'asc',
	initMap: null,
	allMapList: [],
	detailPin: null,
	view: false,

	// LINKED ELEMENTS
	isLoadingContacts: false,
	isLoadingOpportunities: false,
	isLoadingPictures: false,
	isLoadingEmails: false,
	isLoadingNotes: false,
	isLoadingDocuments: false,
	isLoadingEvents: false,
	isLoadingSalesOperations: false,
	deleteFileId: null,
	deleteNoteId: null,
	contacts: [],
	opportunities: [],
	pictures: [],
	notes: [],
	documents: [],
	events: [],
	range: {
		last: {
			firstRange: 30,
			lastRange: 0
		},
		next: {
			firstRange: 0,
			lastRange: 30
		}
	},
	salesOperations: [],
	errorValidation: [],
	initFilter: null,

	noteDetail: [],
	emailDetail: [],
	eventDetail: [],

	pagination: { ...defaultPagination() },
	paginationContacts: { ...defaultPagination() },
	paginationOpportunities: { ...defaultPagination() },
	paginationPictures: { ...defaultPagination() },
	paginationNotes: { ...defaultPagination() },
	paginationDocuments: { ...defaultPagination() },
	paginationEvents: { ...defaultPagination() },
	paginationEmails: { ...defaultPagination() },
	paginationSalesOpperations: { ...defaultPagination() },

	activeFilter: {
		ownerId: null,
		clientStatusId: null,
		billingCity: null
	},

	companiesInEachCountry: [],
	clientStatus: [],
	filterStatusId: '0',
	filterOwnerId: '0',
	filterGroupId: null,
	filterTagId: [],
	inStore: null,
	groupDistribution: null
};

export default function CompaniesReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_COMPANIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_COMPANIES_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_COMPANIES_ERROR:
			return {
				...state,
				data: [],
				pagination: {
					currentPage: 1,
					lastPage: 1,
					count: 0,
					recordPerPage: 0
				},
				isLoading: false
			};

		case FETCH_ALL_COMPANIES_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_ALL_COMPANIES_SUCCESS:
			return {
				...state,
				isLoading: false,
				allCompanies: action.payload.data
			};

		case CREATE_COMPANIES_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case CREATE_COMPANIES_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};

		case CREATE_COMPANIES_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SET_INITIAL_FILTER:
			return {
				...state,
				initFilter: action.payload
			};

		case UPDATE_COMPANIES_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_COMPANIES_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoading: false
			};

		case UPDATE_COMPANIES_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DETAIL_COMPANIES_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_COMPANIES_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				parentCompanies: action.payload.ParentCompanies,
				isLoadingDetail: false
			};
		case DETAIL_COMPANIES_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case DETAIL_PIN_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_PIN_SUCCESS:
			return {
				...state,
				detailPin: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_PIN_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case SEARCH_COMPANIES:
			return {
				...state,
				search: action.payload
			};

		case SORT_DATA:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};

		case IMPORT_EXCEL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case IMPORT_EXCEL_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case IMPORT_EXCEL_ERROR:
			return {
				...state,
				errorValidation: action.error.validation,
				isLoading: false
			};

		// CREATE LINKED ELEMENT
		case CREATE_LINKEDELEMENT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_LINKEDELEMENT_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_LINKEDELEMENT_ERROR:
			return {
				...state,
				isLoading: false
			};

		// GET LINKED ELEMENT
		case GET_CONTACTS_REQUEST:
			return {
				...state,
				isLoadingContacts: true
			};
		case GET_CONTACTS_SUCCESS:
			return {
				...state,
				contacts: action.payload.data || [],
				paginationContacts: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingContacts: false
			};
		case GET_CONTACTS_ERROR:
			return {
				...state,
				contacts: [],
				isLoadingContacts: false
			};

		case GET_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoadingOpportunities: true
			};
		case GET_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				opportunities: action.payload.data || [],
				paginationOpportunities: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingOpportunities: false
			};
		case GET_OPPORTUNITIES_ERROR:
			return {
				...state,
				opportunities: [],
				isLoadingOpportunities: false
			};

		case GET_PICTURES_REQUEST:
			return {
				...state,
				isLoadingPictures: true
			};
		case GET_PICTURES_SUCCESS:
			return {
				...state,
				pictures: action.payload.data || [],
				paginationPictures: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingPictures: false
			};
		case GET_PICTURES_ERROR:
			return {
				...state,
				isLoadingPictures: false
			};

		case GET_EMAIL_REQUEST:
			return {
				...state,
				isLoadingEmails: true
			};
		case GET_EMAIL_SUCCESS:
			return {
				...state,
				emails: action.payload.data || [],
				paginationEmails: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingEmails: false
			};
		case GET_EMAIL_ERROR:
			return {
				...state,
				isLoadingEmails: false
			};

		case GET_EMAIL_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_EMAIL_DETAIL_SUCCESS:
			return {
				...state,
				emailDetail: action.payload,
				isLoading: false
			};
		case GET_EMAIL_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_NOTES_REQUEST:
			return {
				...state,
				isLoadingNotes: true
			};
		case GET_NOTES_SUCCESS:
			return {
				...state,
				notes: action.payload.data || [],
				paginationNotes: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingNotes: false
			};
		case GET_NOTES_ERROR:
			return {
				...state,
				isLoadingNotes: false
			};

		case GET_NOTE_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_NOTE_DETAIL_SUCCESS:
			return {
				...state,
				noteDetail: action.payload.data,
				isLoading: false
			};
		case GET_NOTE_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_EVENTS_REQUEST:
			return {
				...state,
				isLoadingEvents: true
			};
		case GET_EVENTS_SUCCESS:
			return {
				...state,
				events: action.payload.data || [],
				paginationEvents: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingEvents: false
			};
		case GET_EVENTS_ERROR:
			return {
				...state,
				isLoadingNotes: false
			};

		case GET_EVENT_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_EVENT_DETAIL_SUCCESS:
			return {
				...state,
				eventDetail: action.payload.data,
				isLoading: false
			};
		case GET_EVENT_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_DOCUMENTS_REQUEST:
			return {
				...state,
				isLoadingDocuments: true
			};
		case GET_DOCUMENTS_SUCCESS:
			return {
				...state,
				documents: action.payload.data || [],
				paginationDocuments: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingDocuments: false
			};
		case GET_DOCUMENTS_ERROR:
			return {
				...state,
				isLoadingDocuments: false
			};

		case FETCH_CLIENT_STATUS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_CLIENT_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				clientStatus: action.payload.data
			};
		case FETCH_CLIENT_STATUS_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SET_ACTIVE_FILTER:
			return {
				...state,
				activeFilter: action.payload
			};

		case SET_FILTER_STATUS_ID:
			return {
				...state,
				filterStatusId: action.payload
			};
		case SET_FILTER_OWNER_ID:
			return {
				...state,
				filterOwnerId: action.payload
			};

		case SET_FILTER_GROUP_ID:
			return {
				...state,
				filterGroupId: action.payload
			};
		case SET_FILTER_TAG_ID:
			return {
				...state,
				filterTagId: action.payload
			};

		case FETCH_COMPANY_IN_EACH_COUNTRY_REQUEST:
			return {
				...state,
				isLoadingMap: true
			};
		case FETCH_COMPANY_IN_EACH_COUNTRY_SUCCESS:
			return {
				...state,
				isLoadingMap: false,
				companiesInEachCountry: action.payload.companiesInEachCountry,
				allCompanies: action.payload.companies
			};
		case FETCH_COMPANY_IN_EACH_COUNTRY_ERROR:
			return {
				...state,
				isLoadingMap: false,
				allCompanies: action.payload.companies
			};
		case MAP_REDIRECT:
			return {
				...state,
				initMap: action.payload
			};

		case FETCH_COMPANIES_MAP_LIST_REQUEST:
			return {
				...state,
				isLoadingMap: true
			};
		case FETCH_COMPANIES_MAP_LIST_SUCCESS:
			return {
				...state,
				allMapList: action.payload.data ? action.payload.data : [],
				isLoadingMap: false
			};
		case FETCH_COMPANIES_MAP_LIST_ERROR:
			return {
				...state,
				isLoadingMap: false
			};
		case WEB_VIEW:
			return {
				...state,
				view: action.payload
			};
		case RANGE_SELECTED:
			return {
				...state,
				range: action.payload
			};
		case INSTORE_PRESENCES:
			return {
				...state,
				inStore: action.payload
			};
		case INSTORE_PRESENCES_CALCULATE_CHILD:
			return {
				...state,
				groupDistribution: action.payload
			};

		default:
			return state;
	}
}
