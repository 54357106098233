import { Granularity } from 'bindings/time/Granularity';
import { Target, TargetData, TargetEnum } from './Targets';
import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';
import { DefaultTableQuery } from '../checkouts/actions';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import { ServerAdvancedFilter } from '../client-companies/model/Model';
import { FilterId } from '../../components_v2/filter/model/Model';
import { translateToString } from '../../styles/global/translate';

export type PostTargetBody = {
	name: string,
	data: TargetData,
	target: TargetEnum,
	granularity: Granularity
}

export type PutTargetBody = {
	name?: string,
	data?: TargetData
	target?: TargetEnum,
	granularity?: Granularity
}

export type TargetChartData = {
	user_id: number,
	date: string,
	target: number,
	granularity: Granularity,
	target_target: TargetEnum,
	competed?: number
}

export async function postTarget(body: PostTargetBody): Promise<number> {
	return axios.post(`${URL_FOUNDATION}/api/v2/targets`, body).then(res => res.data);
}

export async function getTargets(body: DefaultTableQuery): Promise<Target[]> {
	return axios.post(`${URL_FOUNDATION}/api/v2/targets/query`, body).then(res => res.data);
}

export async function putTarget(id: number, body: PutTargetBody): Promise<void> {
	return axios.put(`${URL_FOUNDATION}/api/v2/targets/${id}`, body).then(res => res.data);
}

export async function deleteTarget(id: number): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/targets/${id}`).then(res => res.data);
}

export async function getTargetChart(id: number, date: string, target: TargetEnum): Promise<TargetChartData[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/targets/${id}/chart?date=${date}&target=${target}`).then(res => res.data);
} 

export async function getTargetById(id: number): Promise<Target> {
	return axios.get(`${URL_FOUNDATION}/api/v2/targets/${id}`).then(res => res.data);
} 

const filterCategories = ['pricing', 'activity'] as const;
type FilterCategory = typeof filterCategories[number];
const getCheckoutFitlersCategories = (filterId: FilterId): FilterCategory[] => {
	switch (filterId) {
		case 'granularity':
		case 'target':
		case 'name':
			return ['pricing'];
		case 'created_at':
		case 'created_by':
		case 'updated_at':
		case 'updated_by':
			return ['activity'];
	}
	return [];
};

const toTranslateString = (filterId: FilterId): string => {
	switch (filterId) {
		case 'owner_id': return 'owner';
		case 'tag_id': return 'tags';
	}
	if (typeof filterId === 'string')
		return filterId;
	return '';
};

export async function getTargetsFilters(): Promise<FilterParameter[]> {
	try {
		const result = await axios.get<ServerAdvancedFilter[]>(`${URL_FOUNDATION}/api/v2/targets/filters`)
			.then(response => filterCategories.map((fc): FilterParameter => ({
				category: translateToString(fc),
				filters: response.data
					.filter(companyFilter => getCheckoutFitlersCategories(companyFilter[0]).includes(fc))
					.map(companyFilter => ({ id: companyFilter[0], name: translateToString(toTranslateString(companyFilter[0])), type: companyFilter[1] }))
			})));
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}