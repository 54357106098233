import * as React from 'react';
import styled from 'styled-components';
import EventCreation from '../../../components_v2/creation/EventCreation';
import NoteCreation from '../../../components_v2/creation/NoteCreation';
import { BlueSidely } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';
import { CompanyDetail } from '../model/Model';
import Restricted from '../../permissions/Restricted';
import { ObjectAction } from '../../../../../web-types/objectActions';
import NoteImage from 'images/menu_icon/notes_blue.svg';
import PhoneImage from 'images/icons/company/phone_blue.svg';
import EmailImage from 'images/icons/events/Email_blue.svg';
import CalendarImage from 'images/menu_icon/calendar_empty_blue.svg';
import EmailTemplatePopup from '../../email-templates/emailTemplatePopup';

interface QuickAction {
  url: string
  onClick: () => void
  style?: React.CSSProperties
  objectAction?: ObjectAction
}

const QuickActionDiv = styled.div`
    cursor: pointer;
    border: 1px solid ${BlueSidely};
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 6px;
    display: flex;
    justify-content: center;
	margin:0px 10px;
	min-width:35px;
`;

export default function QuickActions(props: { 
	company?: CompanyDetail,
	onCreate: () => void,
	isSelf: boolean
}) {
	const [isNoteOpen, setIsNoteOpen] = React.useState<boolean>(false);
	const [isEventOpen, setIsEventOpen] = React.useState<boolean>(false);
	const [isEmailTemplateOpen,setIsEmailTemplateOpen] = React.useState(false);

	const { company } = props;
	const actions: QuickAction[] = [
		{
			url: NoteImage,
			onClick: () => setIsNoteOpen(true),
			style: { marginLeft: 2, width: 21 }
		},
		{
			url: PhoneImage,
			onClick: () => window.open(`tel:${company?.company_phone}`),
			style: { width: 19 }
		},
		{
			url: EmailImage,
			onClick:() => setIsEmailTemplateOpen(prev => !prev)
		},
		{
			url: CalendarImage,
			onClick: () => setIsEventOpen(true),
			objectAction: 'CreateEvent'
		}
	];
	return <div style={{ width: '100%' }}>
		<FlexDiv justify='center' width="100%" padding="5px 15% 5px 15%">
			{actions.map(({ url, onClick, style, objectAction }, i) => <Restricted to={objectAction ? { objectAction } : []} key={`QuickAction[${i}]`} isSelf={props.isSelf}>
				<QuickActionDiv onClick={onClick}>
					<img src={url} width='100%' style={style} />
				</QuickActionDiv>
				{i === 2 && isEmailTemplateOpen && company && company.company_email &&
					<EmailTemplatePopup
						emailTemplateVariable={{
							first_name:company.owner_name.split(' ')[0] ?? '',
							last_name:company.owner_name.split(' ')[1] ?? '',
							company_name:company.company_name ?? ''
						}}
						email={company?.company_email ?? ''}
						isOpen={isEmailTemplateOpen}
						onClose={() => setIsEmailTemplateOpen(false)}
						quickAction
					/>
				}
			</Restricted>
			)}
		</FlexDiv>
		<NoteCreation
			isOpen={isNoteOpen}
			setOpen={(b: boolean) => setIsNoteOpen(b)}
			id={props.company ? { Company: props.company?.company_id } : undefined}
			onCreate={() => props.onCreate()}
		/>
		<EventCreation
			isOpen={isEventOpen}
			setOpen={(b: boolean) => setIsEventOpen(b)}
			clientCompanyId={props.company?.company_id}
			onCreate={() => props.onCreate()}
		/>
	</div>;
}
