import * as React from 'react';
import { loadingState } from '../../../components_v2/models';
import axios from 'axios';
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { getLang, queryConstructor, stringToColour } from '../utils';
import { getPresencePerUsers } from './actions';
import { reportingAction, reportingState } from '../Reducer';
import PageLoader from '../../../components/PageLoader';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { useRecoilValue } from 'recoil';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { ATimeRangeFilter } from '../../../atoms/filter/timeFilterAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { useWindowDimensions } from '../../../components_v2/utils';
import { BorderColor } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

function filterData(filters: any, data: any) {
	data.datasets = data.datasets.filter(dataset => {
		return filters.filter(f => f == dataset.label).length > 0;
	});
	return data;
}

export function PresencePerUsers(props: {
	setToolBarState: (value: ToolbarState) => void
	state: reportingState
	dispatch: (action: reportingAction) => void
	width: number
	height: number
}) {
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any>({});
	const { width } = useWindowDimensions();

	const filteredTags = useRecoilValue(ATagFilter);
	const timeRangeFilter = useRecoilValue(ATimeRangeFilter);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 120}
					category={AtomCategory.GLOBAL}
					elements={[
						ToolbarElement.TAG_FILTER,
						{
							kind: ToolbarElement.TIME_RANGE_FILTER,
							defaultValue: 3
						}

					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'}/>
			</FlexDiv>,
			bottomRightToolbarComponent: <></>
		});
	}, [loadingState, width]);

	React.useEffect(() => {
		if (!loadingState && (filteredTags != null) && timeRangeFilter) {
			try {
				if (!loadingState) {
					setLoadingState('loading');
				}
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				getPresencePerUsers(queryConstructor({
					timeRange: timeRangeFilter,
					tags: filteredTags
				}, undefined), cancelTokenSource.token)
					.then(res => {
						// set default left filter - all -
						props.state.filters.PresencePerUsers = res.data.labels;
						props.dispatch({
							type: 'SET_FILTERS',
							value: { ...props.state.filters }
						});

						// set list of users
						props.dispatch({
							type: 'SET_USERS',
							value: res.data.labels
						});
						setData(res.data);
						if (loadingState != 'loaded') {
							setLoadingState('loaded');
						}
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState, filteredTags, timeRangeFilter]);

	const translate = getTranslate(storeLang.getState().localize);

	const datasets: any[] = [];

	if (data.datasets != null && data.datasets != undefined) {
		for (const dataset of data.datasets) {
			const color = stringToColour(dataset.label);
			datasets.push({
				label: dataset.label,
				data: dataset.data,
				borderColor: color,
				backgroundColor: color + '80',
				lineTension: 0.1,
				borderWidth: 1
			});
		}
	}

	let labels = [];
	if (data.x_axis != null && data.x_axis != undefined && data.x_axis.length > 0) {
		labels = data.x_axis.map(x_axis_label => {
			const date = new Date(x_axis_label);
			const now = new Date(Date.now());
			if (date.getDay() == now.getDay() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
				return translate('shelf_audit.sections.misc.today').toString();
			} else {
				return date.toLocaleString(getLang(), { month: 'short', year: 'numeric' });
			}
		});
	}
	const formated_data = {
		labels,
		datasets
	};

	const options = {
		responsive: true,
		plugins: {
			datalabels: {
				display: function() {
					return false;
				},
			},
			legend: {
				position: 'bottom' as const
			},
			title: {
				display: true
				// text: 'Chart.js Line Chart'
			}
		},
		scales: {
			y: {
				beginAtZero: true
			}
		}
	};

	return (
		<div style={{ fontSize: '12px' }}>
			{(loadingState == 'loading' || !loadingState) && <div className='list-loader'><PageLoader/></div>}
			{loadingState == 'error' && <p>Error fetching data</p>}
			{loadingState == 'loaded' &&
				<div style={{
					backgroundColor: '#FFFFFF',
					height: `calc(${props.height}px - 130px)`,
					border: `1px solid ${BorderColor}`
				}}>
					<Line options={options} data={filterData(props.state.filters.PresencePerUsers, formated_data)}/>
				</div>
			}
		</div>);
}
