/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
import React, {Component} from 'react';

class CustomOption extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  handleTouchEnd(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const { data, innerProps, innerRef } = this.props;
    return (
      <div
        className="rs-option needsclick"
        ref={innerRef}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onTouchEnd={this.handleTouchEnd}
        {...innerProps}
      >
        {data.label}
      </div>
    );
  }
}

export default CustomOption;
