import chartImage from 'images/icons/chart.svg';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { AVariationSelect } from '../../../atoms/filter/timeFilterAtom';
import Dropdown from '../../dropdown/Dropdown';
import { DropdownData } from '../../dropdown/model/Model';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { translateToString } from '../../../styles/global/translate';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';

export type Variation = 'N1' | 'P0';
export const ALL_GRANULARITY: Variation[] = ['N1', 'P0'];

export default function VariationSelect(props: GenericToolbarFilterProps): JSX.Element {
	const { filters, setFilters } = React.useContext(ReportFiltersContext);
	const [variation, setVariation] = useRecoilState(AVariationSelect);

	if (props.hidden) return <></>;
	let localVariation;
	if (props.context) {
		localVariation = filters.variation;
		if (localVariation === undefined) {
			localVariation = null;
		}
	} else {
		localVariation = variation;
	}
	return (
		<Dropdown
			dropdownStyle={{ ...DROPDOWN_FILTER_STYLE, width: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.width, containerWidth: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.containerWidth }}
			readOnly
			selectedValue={{ label: translateToString('variation.' + localVariation).toString(), value: localVariation }}
			datalist={[...ALL_GRANULARITY.map(g => ({ label: translateToString('variation.' + g).toString(), value: g })), { label: translateToString('variation.null').toString(), value: null }]}
			name='dropdown_variation_filter'
			onChange={(value: DropdownData<Variation>) => {
				if (props.context) {
					setFilters(filters => ({ ...filters, variation: value.value }));
				} else {
					setVariation(value.value);
				}
			}}
			image={chartImage}
		/>
	);
}