import next_2 from 'images/icon/next_2.png';
import next from 'images/icon/next.png';
import previous from 'images/icon/previous.png';
import previous_2 from 'images/icon/previous_2.png';
import * as React from 'react';
import Dropdown from '../dropdown/Dropdown';
import { DropdownData } from '../dropdown/model/Model';
import { PaginationResult } from './model/Model';
import { Box, CardPage, CardPageImage, Container, ContainerCardPage, Text } from './style/Style';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';

function Pagination(props: {
  label: string
  amount?: number
  currentCount?: number
  steps: number[]
  isAtTheEnd?: boolean
  onChange?: (value: PaginationResult) => void
  disable?: boolean,
  defaultStep?: number
}): JSX.Element {
	const isUnknownList = props.amount == undefined;
	const [step, setStep] = React.useState<number>(getDefaultStep());
	const [currentPage, setCurrentPage] = React.useState<number>(1);
	const [disable, setDisable] = React.useState<boolean>(props.disable ?? false);

	const translate = getTranslate(storeLang.getState().localize);

	React.useEffect(() => {
		props.onChange?.({ step, currentPage, offset: getOffset() } as PaginationResult);
	}, [step, currentPage]);

	React.useEffect(() => {
		setDisable(props.disable ?? false);
	}, [props.disable]);

	React.useEffect(() => {
		setCurrentPage(1);
	}, [props.amount]);

	function getDefaultStep(): number {
		return props.defaultStep ?? (props.steps.length !== 0 ? props.steps[0] : 0);
	}

	function getOffset(): number {
		return step * (currentPage - 1);
	}

	function getDropdownData(): DropdownData[] {
		return props.steps.map(s => {
			return {
				label: s.toString(),
				value: s,
				selected: s === getDefaultStep()
			} as DropdownData;
		});
	}

	function getCount(): number {
		const n = ((props.amount ?? 0) <= 0 ? 0 : props.amount ?? 0) - (step * (currentPage - 1));
		return step < n ? step : n;
	}

	function getPageAmount(): number {
		return Math.ceil(((props.amount ?? 0) <= 0 ? 0 : props.amount ?? 0) / step);
	}

	function previousPage(): number {
		return (currentPage - 1) >= 1 ? (currentPage - 1) : 1;
	}

	function nextPage(): number {
		return (currentPage + 1) <= getPageAmount() || isUnknownList ? (currentPage + 1) : currentPage;
	}

	return (
		(<Container>
			<Box>
				<Dropdown
					dropdownStyle={{
						width: '65px',
						optionWidth: '65px',
						height: '21px',
						optionUp: true,
						fontSize: 12,
						arrowSize: 6
					}}
					disabled={disable}
					datalist={getDropdownData()}
					name="step"
					onChange={(value: DropdownData) => { setCurrentPage(1); setStep(value.value); }}
				/>
				{props.amount !== undefined && (
					<Text>{`${props.currentCount ?? getCount()} ${translate('global.pagination.of').toString()} `}<strong>{`${(props.amount ?? -1) < 0 ? 0 : props.amount} ${props.label}`}</strong></Text>
				)}
			</Box>
			<Box></Box>
			<Box justifyContent="end">
				<ContainerCardPage>

					<CardPage
						isActive={!disable && (currentPage > 1) && !props.disable}
						cursor="pointer"
						onClick={() => !disable && (currentPage > 1) && setCurrentPage(1)}
					>
						<CardPageImage src={previous_2} double />
					</CardPage>
					<CardPage
						isActive={!disable && (currentPage > 1) && !props.disable}
						cursor="pointer"
						onClick={() => !disable && setCurrentPage(previousPage())}
					>
						<CardPageImage src={previous} />
					</CardPage>

					<CardPage isActive={true} width="fit-content" height="34px">{`${currentPage.toString()}`}</CardPage>

					<CardPage
						isActive={!disable && (currentPage < getPageAmount() || (isUnknownList && !props.isAtTheEnd))}
						cursor="pointer"
						onClick={() => !disable && (currentPage < getPageAmount() || (isUnknownList && !props.isAtTheEnd)) && setCurrentPage(nextPage())}
					>
						<CardPageImage src={next} />
					</CardPage>
					<CardPage
						isActive={!disable && (currentPage < getPageAmount())}
						cursor="pointer"
						onClick={() => !disable && (currentPage < getPageAmount()) && setCurrentPage(getPageAmount())}
					>
						<CardPageImage src={next_2} double />
					</CardPage>

				</ContainerCardPage>
			</Box>
		</Container>)
	);
}

export default Pagination;
