import styled from 'styled-components';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { DefaultText } from '../../../../styles/global/css/GlobalText';
import { SidelyBlack } from '../../../../styles/global/css/Utils';
import CloseImage from 'images/icons/orders/close.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const Cross = styled.div`
    background-image: url('${CloseImage}');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
`;

const Title = styled.h3`
    ${DefaultText}
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: ${SidelyBlack};
    margin: 2%;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    width: 75%;
    margin-top: 5%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    margin-top: 5%;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
`;

const Button = styled(DefaultButton)`
    width: 200px;
    height: 50px;
`;

export {
	Container,
	Header,
	Title,
	Body,
	Footer,
	Cross,
	Row,
	Button
};
