import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { find } from 'lodash';
import { fetchStatus, updateData } from '../../../../store/actions/opportunities.action';
import PageLoader from '../../../../components/PageLoader';
import noUserImage from 'images/icons/user.svg';
import { selectOpportunityStatus } from '../../../../store/actions/select.action';
import { formatCurrency } from '../../../../containers_v2/reports/utils';
import Restricted from '../../../../containers_v2/permissions/Restricted';

class KanbanView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tasks: [],
			// statusCode: [],
			dataBoard: null,
			amountBoard: null
		};
		this.table = this.table.bind(this);
	}

	componentDidMount() {
		const { fetchStatus, selectOpportunityStatus } = this.props;
		fetchStatus();
		selectOpportunityStatus(true);
	}

	componentWillReceiveProps(nextProps) {
		const { tasks } = this.state;
		const { data, status, board, amount } = this.props;

		const dataTasks = [];

		if (data !== nextProps.data) {
			nextProps.data.map(
				data =>
					dataTasks.push({
						id: data.id,
						title: data.title,
						description: data.description,
						clientCompanyId: data.clientCompanyId,
						prospectOrigin: data.prospectOrigin,
						incharge: data.incharge,
						company: data.clientCompanyName,
						deadline: data.deadline,
						amount: data.amount,
						photo: data.inchargeUserPhoto || noUserImage,
						name: data.inchargeUserName,
						status: data.opportunityStatusId ? data.opportunityStatusId : null,
						probability: parseInt(data.probability, 10)
					}),
				this.setState({
					...tasks,
					tasks: dataTasks,
					dataBoard: null,
					amountBoard: null
				})
			);

			const newBoard = board;
			const newAmount = amount;

			dataTasks.length > 0 &&
				dataTasks.map(task => {
					let boardLength = 0;
					if (newBoard) {
						// mengecek apakah card yg dipush sesuai dengan jumlah data
						// untuk menghindari duplicate card
						if (newBoard[`status${task.status}`] !== undefined) {
							boardLength += newBoard[`status${task.status}`].length;
							if (boardLength !== dataTasks.length) {
								newBoard[`status${task.status}`].push(
									<button
										type="button"
										className="btn bg-transparent p-0 m-0"
										key={task.id}
										onClick={() => {
											window.location.href = `/opportunities/detail/${task.id}`;
										}}
										style={{ width: '100%' }}
									>
										<div className="p-2">
											<Restricted to={{ objectAction: 'UpdateOpportunity' }} fallback={
												<div
													className="card custom-card pointer m-auto"
													style={{ width: '100%', height: 'auto' }}
													draggable={false}
													onDragStart={e => this.onDragStart(e, task.id)}
												>
													<div className="card-body text-center p-2">
														<h6 className="card-head d-flex align-items-center justify-content-center">
															{task.company || '-'}
														</h6>
														<h6 className="card-head d-flex align-items-center justify-content-center">
															{task.title || '-'}
														</h6>
														<h6 className="card-text-1">
															<Translate id="deadline" />:{' '}
															<span style={{ color: 'rgba(76, 88, 98, 1)' }}>
																{task.deadline
																	? moment(task.deadline).format('DD/MM/YYYY')
																	: '-'}
															</span>
														</h6>
														<h6 className="card-text-2">
															{task.amount ? formatCurrency(task.amount) : '-'}
														</h6>
														<div className="row">
															<div className="col-3">
																<img
																	src={task.photo}
																	alt=""
																	className="thumbnail-circle-1"
																/>
															</div>
															<div className="col-6 px-0">
																<h6 className="card-text-3 mb-0 mx-auto">
																	{task.name || '-'}
																</h6>
															</div>
															<div className="col-3 pl-0">
																<div
																	className={`${(!task.probability ||
																	task.probability === '0') &&
																	'bg-0'}
													${task.probability === 10 && 'bg-10'}
													${task.probability === 20 && 'bg-20'}
													${task.probability === 30 && 'bg-30'}
													${task.probability === 40 && 'bg-40'}
													${task.probability === 50 && 'bg-50'}
													${task.probability === 60 && 'bg-60'}
													${task.probability === 70 && 'bg-70'}
													${task.probability === 80 && 'bg-80'}
													${task.probability === 90 && 'bg-90'}
													${task.probability === 100 && 'bg-100'}
													circle-Kanban`}
																>
																	<p>
																		<small
																			style={{ fontSize: '8px' }}
																		>{`${task.probability}%`}</small>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>}>
												<div
													className="card custom-card pointer m-auto"
													style={{ width: '100%', height: 'auto' }}
													draggable
													onDragStart={e => this.onDragStart(e, task.id)}
												>
													<div className="card-body text-center p-2">
														<h6 className="card-head d-flex align-items-center justify-content-center">
															{task.company || '-'}
														</h6>
														<h6 className="card-head d-flex align-items-center justify-content-center">
															{task.title || '-'}
														</h6>
														<h6 className="card-text-1">
															<Translate id="deadline" />:{' '}
															<span style={{ color: 'rgba(76, 88, 98, 1)' }}>
																{task.deadline
																	? moment(task.deadline).format('DD/MM/YYYY')
																	: '-'}
															</span>
														</h6>
														<h6 className="card-text-2">
															{task.amount ? formatCurrency(task.amount) : '-'}
														</h6>
														<div className="row">
															<div className="col-3">
																<img
																	src={task.photo}
																	alt=""
																	className="thumbnail-circle-1"
																/>
															</div>
															<div className="col-6 px-0">
																<h6 className="card-text-3 mb-0 mx-auto">
																	{task.name || '-'}
																</h6>
															</div>
															<div className="col-3 pl-0">
																<div
																	className={`${(!task.probability ||
																	task.probability === '0') &&
																	'bg-0'}
													${task.probability === 10 && 'bg-10'}
													${task.probability === 20 && 'bg-20'}
													${task.probability === 30 && 'bg-30'}
													${task.probability === 40 && 'bg-40'}
													${task.probability === 50 && 'bg-50'}
													${task.probability === 60 && 'bg-60'}
													${task.probability === 70 && 'bg-70'}
													${task.probability === 80 && 'bg-80'}
													${task.probability === 90 && 'bg-90'}
													${task.probability === 100 && 'bg-100'}
													circle-Kanban`}
																>
																	<p>
																		<small
																			style={{ fontSize: '8px' }}
																		>{`${task.probability}%`}</small>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Restricted>
										</div>
									</button>
								);
								if (task.amount !== null) {
									newAmount[`status${task.status}`] += parseInt(
										task.amount,
										10
									);
								}
							}
						}
					}
					return null;
				});
			this.setState({ dataBoard: newBoard, amountBoard: newAmount });
		}

		if (status !== nextProps.status) {
			const statusCode = [];
			nextProps.status.map(data =>
				statusCode.push({
					probability: data.probability,
					id: data.id,
					color: (data.colorStatus && data.colorStatus.colorCode) || '#fff'
				})
			);
			// this.setState({ statusCode });
		}
	}

	table = ref => {
		this.table = ref;
	};

	getColorCode = value => {
		const color = find(this.props.statusCode, o => {
			if (o.id === value) {
				return o.color;
			}
			return null;
		});
		return color.color || '#fff';
	};

	onDragStart = (e, id) => {
		e.dataTransfer.setData('id', id);
	};

	onDragOver = e => {
		e.preventDefault();
	};

	onDrop = (e, idStatus) => {
		e.preventDefault();
		const { tasks, dataBoard, amountBoard } = this.state;
		const { updateData } = this.props;

		const id = e.dataTransfer.getData('id');
		let prevStatus = null;
		let newStatus = null;

		const dataTasks = tasks.filter(task => {
			const data = task;
			if (String(data.id) === id) {
				prevStatus = data.status;
				data.status = idStatus;
				newStatus = data.status;
			}
			return data;
		});
		this.setState({
			tasks: dataTasks
		});

		const formData = new FormData();
		formData.append('opportunityStatusId', newStatus);
		if (newStatus === 5) {
			formData.append('deadline', moment(new Date()).format('YYYY-MM-DD'));
		}

		updateData(formData, id, false).then(() => {
			dataTasks.map(task => {
				// mencari card yg dipindah
				if (String(task.id) === id) {
					let newBoard = null;
					const newDataBoard = dataBoard;

					// menghapus card yg dipindah dari list status lama
					const dataBoardPrev = newDataBoard[`status${prevStatus}`].reduce(
						(acc, data) => {
							if (data.key === String(task.id)) {
								newBoard = data;
							} else {
								acc.push(data);
							}
							return acc;
						},
						[]
					);
					newDataBoard[`status${prevStatus}`] = dataBoardPrev;

					// menambahkan card yg dipindah ke status baru
					newDataBoard[`status${newStatus}`].push(newBoard);

					const newAmountBoard = amountBoard;
					// mengurangi total di status sebelumnya
					newAmountBoard[`status${prevStatus}`] -= parseInt(task.amount, 10);
					// menambahkan total di status baru
					newAmountBoard[`status${newStatus}`] += parseInt(task.amount, 10);

					this.setState({
						dataBoard: newDataBoard,
						amountBoard: newAmountBoard
					});
				}
				return null;
			});
		});
	};

	handleMinStatus = (key, count) => {
		switch (key) {
			case 'status':
				return (
					<div
						className="bg-kanban header-status text-center py-2 px-2"
						style={{
							borderTop: '2px solid rgb(235, 239, 242)',
							borderBottom: '1px solid rgb(235, 239, 242)'
						}}
					/>
				);
			case 'main':
				return (
					<div
						className={'bg-kanban scrollKanban text-center py-3 header-status-min'}
					/>
				);
			case 'footer':
				return (
					<div
						className="bg-kanban header-status text-center py-3"
						style={{
							borderTop: '1px solid #EBEFF2'
						}}
					/>
				);

			default:
				return <div />;
		}
	};

	getMinStatus = count => {
		const data = [];
		for (let index = 0; index < count; index++) {
			data.push('');
		}
		return data;
	};

	render() {
		const { dataBoard, amountBoard } = this.state;
		const { opp, status, amount } = this.props;
		const widthCard = status.length > 6 ? 'header-status' : 'header-status-min';
		const count = status && 6 - status.length;
		const minStatus = this.getMinStatus(count);
		const styleCount = minStatus.map(v => this.handleMinStatus('status'));
		const styleMain = minStatus.map(v => this.handleMinStatus('main'));
		const styleFooter = minStatus.map(v => this.handleMinStatus('footer'));

		return (
			<React.Fragment>
				{opp.isLoadingKanban ? (
					<div className="list-loader">
						<PageLoader />
					</div>
				) : (
					<React.Fragment>
						<div className="main-kanban" style={{ overflow: 'visible' }}>
							<div
								ref={this.table}
								onScroll={this.listenScrollEvent}
								style={{ position: 'relative', overflow: 'auto' }}
							>
								<div className="d-flex">
									{status &&
										status.map(status => (
											<div
												className="bg-kanban header-status text-center py-2 px-2"
												style={{
													borderTop: '2px solid rgb(235, 239, 242)',
													borderBottom: '1px solid rgb(235, 239, 242)'
												}}
											>
												<div
													className="mx-1 py-2 text-white"
													style={{
														background:
															(status.colorStatus &&
																status.colorStatus.colorCode) ||
															'#fff',
														borderRadius: '10px'
													}}
												>
													<p className="mb-0 text-white">
														<strong>{status.name}</strong>
													</p>
													<small>
														{(dataBoard &&
															dataBoard[`status${status.id}`].length) ||
															0}
													</small>
												</div>
											</div>
										))}
									<Fragment>
										{status && status.length < 6 && styleCount}
									</Fragment>
								</div>
								<div className="kanban-wrapper">
									<div
										className="d-flex kanbanDashboard"
										style={{ height: '65vh' }}
									>
										{status &&
											status.map(data => (
												<div
													className={`bg-kanban scrollKanban text-center py-3 ${widthCard}`}
													onDragOver={e => this.onDragOver(e)}
													onDrop={e => this.onDrop(e, data.id)}
													style={{ overflowY: 'auto' }}
												>
													{dataBoard && dataBoard[`status${data.id}`]}
												</div>
											))}
										{status && status.length < 6 && styleMain}
									</div>
								</div>
								<div className="d-flex">
									{Object.keys(amount).map(key => (
										<div
											className="bg-kanban header-status text-center py-3"
											style={{
												borderTop: '1px solid #EBEFF2',
												borderRight: '1px solid #EBEFF2'
											}}
										>
											{/* {console.log(amountBoard && amountBoard[key])} */}
											<h6 className="card-text-2 mb-0">
												{formatCurrency(amountBoard && amountBoard[key] || 0)}
											</h6>
										</div>
									))}
									{status && status.length < 6 && styleFooter}
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	opp: state.opportunities,
	status: state.select.opportunityStatus
});

export default connect(mapStateToProps, {
	fetchStatus,
	updateData,
	selectOpportunityStatus
})(KanbanView);
