/* eslint-disable @typescript-eslint/no-non-null-assertion */
import magnifying_glass_v2 from 'images/icons/magnifying_glass_v2.svg';
import add_blue from 'images/icon/add_blue.png';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { TagWrapper } from '../../containers_v2/client-companies/style/PopupStyle';
import { Tag as TagBox, TagContainer, TagDelete } from '../../containers_v2/client-companies/style/Style';
import { FlexDiv, NoneContainer, SeeAll, TagJSX, TagSearch } from '../../containers_v2/products/style';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { Open } from '../../styles/global/css/Open';
import { BorderColor, DarkGreySidely2, LightBlueSidely } from '../../styles/global/css/Utils';
import Dropdown, { checkClickOut } from './Dropdown';
import { DropdownData, DropdownStyle, TagOperator } from './model/Model';
import { InputContainer, LoadMoreBlock, LoadMoreText } from './style/Style';
import { LoadingStateEnum } from '../../containers_v2/import/model';
import PageLoader from '../pageLoader/PageLoader';
import { TranslateOrLoad } from './DropdownSearch';
import { PopupStateContext } from '../popup/PopupStateContext';
import { useKeyPress } from '../../utils/customHooks';

const TagOperatorContainer = styled.div`
	${DefaultText};
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${DarkGreySidely2};
	height: 100%;
	gap: 5px;
	margin: 0 5px;
`;

const Parenthesis = styled.div<{ close?: boolean }>`
	margin: 0 1px;
	font-size: 22px;
	font-weight: 300;
	&::before {
		content: "${p => p.close ? ')' : '('}";
	}
`;

const TagDropdownPopup = styled.div<{ isOpen: boolean, top: number, left: number, height: string, maxHeight?: string }>`
	display: ${p => p.isOpen ? 'flex' : 'none'};
	flex-flow: column;
	position: absolute;
	background-color: white;
	z-index: 999;
	min-width: 150px;
	height: ${p => p.height};
	${({ maxHeight }) => maxHeight ? `max-height: ${maxHeight};` : ''}
	box-shadow: rgb(207 207 207) 0px 1px 5px;
	top: ${p => p.top}px;
	left: ${p => p.left}px;
	width: 15em;
`;

const TagDropdownList = styled.div`
	overflow: auto;
`;

const DropdownContainer = styled.div`
	position: relative;
	width: 100%;
	height: fit-content;
`;

const DropdownRow = styled.div`
	width: 100%;
	height: fit-content;
	cursor: pointer;
`;

const NoOption = styled.div`
	${DefaultText};
	height: 24px;
	text-align: center;
`;

const DropdownTagSearchContainer = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${BorderColor};
	padding: 0.5em;
`;

const DropdownTagSearch = styled.input`
	${DefaultText};
	min-width: 50px;
	width: 100%;
	border: none;
	padding-left: 0.5em;
	background: none;
	flex-shrink: 1;
`;

const AddImage = styled.img<{ size?: string }>`
	background-color: ${LightBlueSidely};
	width: ${p => p.size ?? '25px'};
	height: ${p => p.size ?? '25px'};
	border-radius: 50%;
	margin: 0 5px;
	flex-shrink: 0;
`;

function isOverflown(element?: HTMLDivElement) {
	if (element == null) return false;
	return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

function dropdownDataFromTagOperator(operator: TagOperator): DropdownData {
	switch (operator) {
		case 'and': return { label: <Translate id='And' />, value: 'and' };
		case 'or': return { label: <Translate id='Or' />, value: 'or' };
	}
}

export function OrAndDropdown(props: {
	operator: TagOperator,
	i?: string | number,
	onChange?: (t: TagOperator) => void,
	jsxIsOpenFnc?: (isOpen: boolean) => void
}): JSX.Element {
	const { i, operator, onChange, jsxIsOpenFnc } = props;

	return <Dropdown
		dropdownStyle={{
			height: '20px',
			optionWidth: '60px'
		}}
		datalist={[{ label: <Translate id='And' />, value: 'and' }, { label: <Translate id='Or' />, value: 'or' }]}
		name={`Operator[${i}]`}
		selectedValue={dropdownDataFromTagOperator(operator)}
		JSXButton={({ isOpen }) => {
			jsxIsOpenFnc?.(isOpen);
			return <TagOperatorContainer>
				{dropdownDataFromTagOperator(operator).label}
				<Open isOpen={isOpen} size={10} />
			</TagOperatorContainer>;
		}}
		onChange={(value: DropdownData<TagOperator>) => onChange?.(value.value)}
	/>;
}

export default function DropdownTagsCloud<T extends { id?, name?: string | null, color?: string | null }>(props: {
  tags: T[]
  selected?: T[]
  onChange?: (values: T[], operators: TagOperator[]) => void
  onDelete?: (id: T['id']) => void
  horizontal?: boolean
  emptyWhenNone?: boolean
  NoneContainer?: JSX.Element
  allwaysOpen?: boolean
  center?: boolean
  combination?: boolean
  keepInSelect?: boolean
  autoOptionUp?: boolean
  addImage?: boolean
  addImageOnHover?: boolean
  addImageSize?: string
  noSearch?: boolean
  dropdownSearch?: boolean
  loadingState?: LoadingStateEnum
  operators?: TagOperator[]
}) {
	const { tags, selected, onChange, onDelete, horizontal, emptyWhenNone, allwaysOpen, center, combination, addImage } = props;
	const [isDropdownTagOpen, setIsDropdownTagOpen] = React.useState<boolean>(false);
	const [allTagsDisplayed, setAllTagsDisplayed] = React.useState<boolean>((horizontal || allwaysOpen || combination) ?? false);
	const [tagSearch, setTagSearch] = React.useState<string>();
	const childInputRef = React.useRef<HTMLDivElement>();
	const tagContainerRef = React.useRef<HTMLDivElement>();
	const dropdownRef = React.useRef<HTMLDivElement>(null);
	const [overflow, setOverflow] = React.useState<boolean>(false);
	const [operators, setOperators] = React.useState<TagOperator[]>(props.operators ?? []);
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const [isOptionUp, setOptionUp] = React.useState<boolean>(false);
	const [hovered, setHovered] = React.useState(false);


	const [position, setPosition] = React.useState<{x: number, y: number}>({ x: 0, y: 0 });

	const { popupState, setPopupState } = React.useContext(PopupStateContext);
	
	const defaultOptionHeight = 190;
	const tagId = React.useId();

	checkClickOut(wrapperRef, setIsDropdownTagOpen);
	useKeyPress([{ key: 'Escape' }], () => {
		if (popupState[popupState.length - 1] == tagId) {
			setIsDropdownTagOpen(false);
		}
	});

	React.useEffect(() => {
		if (props.operators !== undefined)
			setOperators(props.operators);
	}, [props.operators]);

	React.useEffect(() => {
		setPopupState(state => {
			if (isDropdownTagOpen) {
				if (!state.find(id => id == tagId)) return [...state, tagId];
			} else {
				const index = state.findIndex(id => id == tagId);
				if (index >= 0) {
					const newPopupstate = [...state];
					newPopupstate.splice(index, 1);
					return newPopupstate;
				}
			}
			return state;
		});
		return () => {
			setPopupState(state => {
				const index = state.findIndex(id => id == tagId);
				if (index >= 0) {
					const newPopupstate = [...state];
					newPopupstate.splice(index, 1);
					return newPopupstate;
				}
				return state;
			});
		};
	}, [isDropdownTagOpen]);

	const onContainerClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		onPositionClick(event);
		if (!isDropdownTagOpen && props.autoOptionUp) {
			setOptionUp(event.clientY >= (window.innerHeight / 2.0));
		}
		if (!isDropdownTagOpen && !combination) {
			setIsDropdownTagOpen(true);
			focusChild();
		}
	};

	const onPositionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const popupMaxWidth = 200;

		const rect = event.currentTarget.getBoundingClientRect();
		const x = event.clientX - rect.left;
		const y = event.clientY - rect.top;
		setPosition({ x: (event.currentTarget.clientWidth < (x + popupMaxWidth) ? (event.currentTarget.clientWidth - popupMaxWidth) : x), y: y });
	};

	React.useEffect(() => {
		if ((tagContainerRef.current != null) && !allTagsDisplayed) {
			setOverflow(isOverflown(tagContainerRef.current));
		}
	}, [isOverflown(tagContainerRef.current)]);

	const focusChild = () => {
		(childInputRef.current != null) && childInputRef.current.focus();
	};

	const filteredTags = tags
		.filter(t => !selected?.some(st => st && st.id == t.id) || combination)
		.filter(e => !tagSearch || e.name!.toLocaleLowerCase().includes(tagSearch.toLocaleLowerCase()));
	return (
		// @ts-expect-error ref
		<DropdownContainer innerRef={wrapperRef} onMouseEnter={_ => setHovered(true)} onMouseLeave={_ => setHovered(false)}>
			<FlexDiv flow="column" justify="center" width='100%' clickable>
				<TagWrapper
					noOverflow={combination ?? false}
					center={center}
					horizontal={horizontal}
					// @ts-expect-error ref
					innerRef={tagContainerRef}
					isOpen={allTagsDisplayed}
					onClick={onContainerClick}
				>
					{
						(selected?.length ?? 0) > 0
							? selected?.map((t, i) => {
								if (!t) return <></>;
								return (
									<FlexDiv key={`DropdownCloud[${i}]`}>
										{combination && i == 0 && <Parenthesis />}
										{combination && operators[i] == 'or' && <Parenthesis close />}
										{combination && i !== 0 &&
										<OrAndDropdown
											i={i}
											operator={operators[i] ?? 'and'}
											jsxIsOpenFnc={isOpen => {
												if (isOpen && isDropdownTagOpen) {
													setIsDropdownTagOpen(false);
												}
											}}
											onChange={value => {
												operators[i] = value;
												setOperators([...operators]);
												onChange?.(selected, operators);
												focusChild();
											}}
										/>
										}
										{combination && operators[i] == 'or' && <Parenthesis />}
										<TagContainer backgroundColor={t.color!}>
											<TagBox backgroundColor={t.color!} onClick={() => setIsDropdownTagOpen(!isDropdownTagOpen)}>
												{t.name}
											</TagBox>
											{(isDropdownTagOpen || combination) &&
											<TagDelete backgroundColor={t.color} onClick={() => {
												operators.splice(i + 1, 1);
												setOperators([...operators]);
												focusChild();
												onDelete?.(t.id!);
												setIsDropdownTagOpen(isDropdownTagOpen);
											}} />
											}
										</TagContainer>
										{combination && i == selected.length - 1 && <Parenthesis close />}
									</FlexDiv>
								);
							})
							: emptyWhenNone
								? <></>
								: (props.NoneContainer != null)
									? props.NoneContainer
									: <NoneContainer onClick={() => setIsDropdownTagOpen(!isDropdownTagOpen)}>
										-
									</NoneContainer>
					}
					{(combination || addImage || (props.addImageOnHover && hovered)) && <AddImage src={add_blue} onClick={() => {
						setIsDropdownTagOpen(!isDropdownTagOpen);
						if (!isDropdownTagOpen) { focusChild(); }
					}}
					size={props.addImageSize}
					/>}
					{!props.noSearch &&
						<TagSearch
							// @ts-expect-error ref
							width={(childInputRef.current != null) ? childInputRef.current.value.length + 'ch' : 'auto'}
							onChange={e => {
								if (!isDropdownTagOpen) {
									setIsDropdownTagOpen(true);
								}
								setTagSearch(e.target.value);
							}}
							// @ts-expect-error ref
							innerRef={childInputRef}
							value={tagSearch}
						/>
					}
				</TagWrapper>
				{(selected?.length ?? 0) > 0 && !horizontal && !allwaysOpen && !combination && overflow &&
					<FlexDiv onClick={() => setAllTagsDisplayed(!allTagsDisplayed)} gap='5px' margin='10px' clickable>
						<Open isOpen={allTagsDisplayed} color='blue' width={12} height={7} />
						<SeeAll>
							{allTagsDisplayed
								? <Translate id='see_less' />
								: <Translate id="see_all" />
							}
						</SeeAll>
					</FlexDiv>
				}
			</FlexDiv>
			{isDropdownTagOpen &&
				<TagDropdownPopup
					isOpen={isDropdownTagOpen} 
					height={`${defaultOptionHeight}px`}
					// @ts-expect-error ref
					innerRef={dropdownRef}
					left={position.x}
					top={isOptionUp ? position.y - defaultOptionHeight : position.y + 30.0}
				>
					{props.dropdownSearch && 
						<DropdownTagSearchContainer>
							{props.loadingState === LoadingStateEnum.LOADING
								? <PageLoader size={20} color='#C2CFE0' />
								: <img src={magnifying_glass_v2} />
							}
							<DropdownTagSearch onChange={e => setTagSearch(e.target.value)} value={tagSearch}/>
						</DropdownTagSearchContainer>
					}
					<TagDropdownList>
						{filteredTags.length > 0
							? filteredTags
								.sort((a, b) => {
									if (a.color && b.color && a.color !== b.color) {
										return a.color.localeCompare(b.color);
									}
									return a.name!.localeCompare(b.name!);
								})
								.map((tag, i) =>
									<DropdownRow
										onClick={() => {
											onChange?.([...(selected ?? []), tag], operators);
											focusChild();
											setTagSearch('');
										}}
										key={`TagRow[${tag.id ?? -i}]`}>
										<TagJSX color={tag.color ?? 'black'}>
											{tag.name}
										</TagJSX>
									</DropdownRow>
								)
							: <NoOption><Translate id='no_option' /></NoOption>}
					</TagDropdownList>
				</TagDropdownPopup>
			}
		</DropdownContainer>
	);
}

export function DropdownPopupSearch<T extends { id, name: string }>(props: {
	loadingState: LoadingStateEnum,
	values: T[],
	onChange?: (value: T) => void,
	JSXValue: (value: T) => JSX.Element,
	height?: string,
	top?: number,
	left?: number,
	isOpen: boolean,
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
	onSearchChange?: (search: string, offset: number | undefined) => Promise<boolean>,
	delay?: number,
	noFilter?: boolean,
	maxHeight?: string,
	defaultIsAtTheEnd?: boolean
}) {
	const [search, setSearch] = React.useState<string>('');
	const wrapperRef = React.useRef<HTMLDivElement>();
	const setRef = React.useCallback((node: HTMLDivElement) => wrapperRef.current = node, []);
	const values = props.noFilter ? props.values : props.values.filter(v => v.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
	const [, setLocalTimeout] = React.useState<NodeJS.Timeout>();
	const [isAtTheEnd, setIsAtTheEnd] = React.useState<boolean>(props.defaultIsAtTheEnd ?? true);

	const onSearchChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
		if (props.delay) {
			setLocalTimeout(timeout => {
				timeout && clearTimeout(timeout);
				return setTimeout(() => props.onSearchChange?.(e.target.value, undefined).then(searchRes => setIsAtTheEnd(searchRes == undefined ? true : searchRes)), props.delay);
			});
		} else {
			props.onSearchChange?.(e.target.value, undefined).then(searchRes => setIsAtTheEnd(searchRes == undefined ? true : searchRes));
		}
	}, [props.delay, props.onSearchChange]);

	checkClickOut(wrapperRef, props.setIsOpen);

	if (!props.isOpen) return <></>;

	return <TagDropdownPopup
		innerRef={setRef}
		isOpen={true} 
		height={props.height ?? 'fit-content'}
		top={props.top ?? 0}
		left={props.left ?? 0}
		maxHeight={props.maxHeight}
	>
		<DropdownTagSearchContainer>
			{props.loadingState === LoadingStateEnum.LOADING
				? <PageLoader size={20} color='#C2CFE0' />
				: <img src={magnifying_glass_v2} />
			}
			<DropdownTagSearch onChange={onSearchChange} value={search}/>
		</DropdownTagSearchContainer>
		<TagDropdownList>
			{values.length > 0
				? values
					.map(value =>
						<DropdownRow
							onClick={() => props.onChange?.(value)}
							key={`TagRow[${value.id}]`}>
							{props.JSXValue(value)}
						</DropdownRow>
					)
				: <NoOption><Translate id='no_option' /></NoOption>}
		</TagDropdownList>
		{!isAtTheEnd && (
			<LoadMoreBlock
				onClick={async() => {
					if (props.onSearchChange !== undefined) {
						const searchRes: boolean | undefined = await props.onSearchChange?.(search, props.values.length);
						setIsAtTheEnd(searchRes == undefined ? true : searchRes);
					}
				}}>
				<LoadMoreText>
					<TranslateOrLoad
						isLoading={props.loadingState === LoadingStateEnum.LOADING}
					/>
				</LoadMoreText>
			</LoadMoreBlock>
		)}
	</TagDropdownPopup>;

}

export function DropdownTags<T extends { id?, name: string, color?: string }>(props: {
  tags: T[]
  selected?: T[]
  onChange?: (values: DropdownData[]) => void
  onDelete?: (id: number) => void
  dropdownStyle?: DropdownStyle
  label?: string
}) {
	const { tags, selected, onChange, onDelete } = props;
	const [tagSearch, setTagSearch] = React.useState<string>();
	const childInputRef = React.useRef<HTMLInputElement>(null);
	const [isDropdownTagOpen, setIsDropdownTagOpen] = React.useState<boolean>(false);

	const focusChild = () => {
		childInputRef.current && childInputRef.current.focus();
	};

	return <Dropdown
		label={props.label}
		dropdownStyle={props.dropdownStyle}
		datalist={
			tags
				.filter(e => !tagSearch || e.name!.toLocaleLowerCase().includes(tagSearch.toLocaleLowerCase()))
				.sort((a, b) => {
					if (a.color && b.color && a.color !== b.color) {
						return a.color.localeCompare(b.color);
					}
					return a.name!.localeCompare(b.name!);
				})
				.map(t => {
					return { label: t.name!, value: t, color: t.color!, checked: selected?.find(e => e.id == t.id) != undefined };
				})
		}
		containerClickable
		autoOptionUp
		isMulti
		controlledJSXButtonOpen
		isOpen={isDropdownTagOpen}
		onOpen={setIsDropdownTagOpen}
		onChange={(values: DropdownData[]) => {
			setTagSearch('');
			focusChild();
			onChange?.(values);
		}}
		name='products-tags-dropdown'
		JSXContent={(value: DropdownData<T>) => <TagJSX color={value.color ?? 'black'}>
			{value.label}
		</TagJSX>
		}
		JSXButton={({ isOpen }) => <InputContainer
			overflow='auto'
			border={props.dropdownStyle?.containerBorder}
			width={props.dropdownStyle?.width}
			height={props.dropdownStyle?.height}
			backgroundColor={props.dropdownStyle?.backgroundColor}
			onClick={() => {
				if (!isDropdownTagOpen) {
					setIsDropdownTagOpen(true);
					focusChild();
				}
			}}>
			<FlexDiv width="100%" height='100%'>
				<FlexDiv width="90%" height="100%" padding="0 5px" overflow="auto">
					{
						selected?.map((t, i) => {
							return (
								<TagContainer key={`DropdownTagSelected[${i}]`} backgroundColor={t.color!}>
									<TagBox backgroundColor={t.color!} onClick={() => setIsDropdownTagOpen(!isDropdownTagOpen)}>{t.name}</TagBox>
									{isDropdownTagOpen &&
										<TagDelete backgroundColor={t.color!} onClick={() => {
											focusChild();
											onDelete?.(t.id!);
											setIsDropdownTagOpen(isDropdownTagOpen);
										}} />
									}
								</TagContainer>
							);
						})
					}
					<TagSearch
						width={childInputRef.current ? childInputRef.current.value.length + 'ch' : 'auto'}
						onChange={e => {
							if (!isDropdownTagOpen) {
								setIsDropdownTagOpen(true);
							}
							setTagSearch(e.target.value);
						}}
						// @ts-expect-error ref
						innerRef={childInputRef}
						value={tagSearch}
					/>
				</FlexDiv>
				<FlexDiv width="10%" height="100%" justify="center" onClick={() => setIsDropdownTagOpen(!isDropdownTagOpen)}>
					<Open isOpen={isOpen} width={9} />
				</FlexDiv>
			</FlexDiv>
		</InputContainer>
		}
	/>;
}
