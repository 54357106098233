import add_blue from 'images/icon/add_blue.png';
import * as React from 'react';
import { Translate, getTranslate } from 'react-localize-redux';
import ContactCreation from '../../../../components_v2/creation/ContactCreation';
import DocumentCreation from '../../../../components_v2/creation/DocumentCreation';
import EventCreation from '../../../../components_v2/creation/EventCreation';
import FreeFormCreation from '../../../../components_v2/creation/FreeFormCreation';
import NoteCreation from '../../../../components_v2/creation/NoteCreation';
import OpportunityCreation from '../../../../components_v2/creation/OpportunityCreation';
import PhotoCreation from '../../../../components_v2/creation/PhotoCreation';
import { EmailV2 } from '../../../../containers/companies/components/linkedElements/Emails';
import storeLang from '../../../../helpers/storeLang';
import { Open } from '../../../../styles/global/css/Open';
import {
	CompanyContact,
	CompanyDetail,
	CompanyDocument,
	CompanyEvent,
	CompanyLinkedElementAmount,
	CompanyNote,
	CompanyOpportunity,
	CompanyPhoto
} from '../../model/Model';
import { Body, ListAdd, ListContainer, ListText, ListTextFlexDiv } from '../../style/LinkedElementStyle';
import Contact from './sections/Contact';
import Document from './sections/Document';
import Event from './sections/Event';
import FreeForms from './sections/FreeFrorms';
import Note from './sections/Note';
import Opportunity from './sections/Opportunity';
import Order from './sections/Order';
import Photo from './sections/Photo';
import Restricted from '../../../permissions/Restricted';
import Promotion, { CompanyPromotionAdder, runningPromotion } from './sections/Promotion';
import { Promotion as PromotionType } from '../../../promotions/model';
import { BlueMessage } from '../../../../components_v2/utils';
import { translateToNode } from '../../../../styles/global/translate';

import LinkedForms from './sections/LinkedForms';
import { Loader } from '../../../../styles/global/css/GlobalLoader';
import { FlexDiv } from '../../../products/style';
interface LinkedElementOpen {
  contact_open: boolean
  event_open: boolean
  opportunity_open: boolean
  order_open: boolean
  photo_open: boolean
  document_open: boolean
  instances_open: boolean,
  note_open: boolean
  shelf_audit: boolean
  free_forms: boolean
  email_open: boolean
  promotion_open: boolean
}

interface CreationOpen {
  contact_open: boolean
  event_open: boolean
  opportunity_open: boolean
  order_open: boolean
  photo_open: boolean
  document_open: boolean
  note_open: boolean
  shelf_audit: boolean
  free_forms: boolean
  promotion_open: boolean
}

interface newCreation {
  note?: CompanyNote
  contact?: CompanyContact
  opportunity?: CompanyOpportunity
  event?: CompanyEvent
  documents?: CompanyDocument[]
  photos?: CompanyPhoto[]
}
export const DEFAULT_LINKED_ELEMENT_OPEN_SIZE = 13;

function LinkedElements(props: {
  clientCompanyId: number
  isExtend: boolean
  onUpdate: () => void
  linkedAmount?: CompanyLinkedElementAmount
  onDisableClickOut: (value: boolean) => void
  companyDetail?: CompanyDetail
  isSelf: boolean,
  promotions: PromotionType[]
}): JSX.Element {
	const MAX_DOCUMENT = 10;
	const translate = getTranslate(storeLang.getState().localize);

	const [maxPhoto, setMaxPhoto] = React.useState<number>(MAX_DOCUMENT - (props.linkedAmount?.amount_photos ?? 0));
	const [maxDocument, setmaxDocument] = React.useState<number>(MAX_DOCUMENT - (props.linkedAmount?.amount_documents ?? 0));


	React.useEffect(() => {
		setMaxPhoto(MAX_DOCUMENT - (props.linkedAmount?.amount_photos ?? 0));
		setmaxDocument(MAX_DOCUMENT - (props.linkedAmount?.amount_documents ?? 0));
	}, [props.linkedAmount]);

	const [linkedElementOpen, setLinkedElemenOpen] = React.useState<LinkedElementOpen>({
		contact_open: false,
		event_open: false,
		opportunity_open: false,
		order_open: false,
		photo_open: false,
		document_open: false,
		note_open: false,
		instances_open: false,
		shelf_audit: false,
		free_forms: false,
		email_open: false,
		promotion_open: false
	});

	const [creationOpen, setCreationOpen] = React.useState<CreationOpen>({
		contact_open: false,
		event_open: false,
		opportunity_open: false,
		order_open: false,
		photo_open: false,
		document_open: false,
		note_open: false,
		shelf_audit: false,
		free_forms: false,
		promotion_open: false
	});

	const [newCreations, setNewCreation] = React.useState<newCreation>();

	React.useEffect(() => {
		setNewCreation({
			contact: undefined,
			documents: undefined,
			event: undefined,
			note: undefined,
			opportunity: undefined,
			photos: undefined
		});
	}, [linkedElementOpen]);

	function switchOpen(key: string): void {
		const newl = { ...linkedElementOpen };
		newl[key] = !newl[key];
		setLinkedElemenOpen(newl);
	}

	function switchCreation(key: string, value?: boolean): void {
		const newl = { ...creationOpen };
		newl[key] = value ?? !newl[key];
		setCreationOpen(newl);
	}

	return <Body>
		<Restricted to={{ objectAction: 'ReadContact' }} isSelf={props.isSelf}>
			<>
				<ListContainer onClick={() => switchOpen('contact_open')} borderTop="none">
					<Open isOpen={linkedElementOpen.contact_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.contact').toString()}`} ({props.linkedAmount?.amount_contacts ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateContact' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('contact_open');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				<ContactCreation
					statusId={props.companyDetail?.status_id}
					isOpen={creationOpen.contact_open}
					setOpen={() => switchCreation('contact_open')}
					clientCompanyId={props.clientCompanyId}
					onCreate={(value) => {
						props.onUpdate();
						setNewCreation({ ...newCreations, contact: value });
					}}
				/>
				{
					linkedElementOpen.contact_open &&
						<Contact 
							isExtend={props.isExtend} 
							clientCompanyId={props.clientCompanyId} 
							newCreation={newCreations?.contact} 
							onDelete={() => props.onUpdate()}
						/>
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadEvent' }} isSelf={props.isSelf}>
			<>
				<ListContainer onClick={() => switchOpen('event_open')}>
					<Open isOpen={linkedElementOpen.event_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.event').toString()}`} ({props.linkedAmount?.amount_events ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateEvent' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('event_open');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				{
					<EventCreation
						isOpen={creationOpen.event_open}
						setOpen={() => switchCreation('event_open')}
						clientCompanyId={props.clientCompanyId}
						onCreate={(value) => {
							props.onUpdate();
							setNewCreation({ ...newCreations, event: value });
						}}
					/>
				}
				{
					linkedElementOpen.event_open &&
						<Event
							isExtend={props.isExtend}
							id={{ Company: props.clientCompanyId }}
							onDisableClickOut={props.onDisableClickOut}
							newCreation={newCreations?.event}
							onDelete={() => props.onUpdate()}
						/>
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadCampaign' }} isSelf={props.isSelf}>
			<>
				<ListContainer onClick={() => switchOpen('promotion_open')}>
					<Open isOpen={linkedElementOpen.promotion_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListTextFlexDiv>{`${translate('campagnes').toString()} (${props.promotions.length})`} {props.promotions.some(runningPromotion) && <BlueMessage message={translateToNode('running')}/>}</ListTextFlexDiv>
					<Restricted to={{ objectAction: 'UpdateCampaign' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('promotion_open');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				<CompanyPromotionAdder
					isOpen={creationOpen.promotion_open}
					setIsOpen={(v: boolean) => switchCreation('promotion_open', v)} 
					selectedPromotions={props.promotions}
					companyId={props.clientCompanyId}
					onCreate={props.onUpdate}
				/>
				{
					linkedElementOpen.promotion_open &&
						<Promotion
							isExtend={props.isExtend}
							clientCompanyId={props.clientCompanyId}
							onDisableClickOut={props.onDisableClickOut}
							onDelete={() => props.onUpdate()}
							promotions={props.promotions}
							companyName={props.companyDetail?.company_name}
						/>
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadOpportunity' }} isSelf={props.isSelf}>
			<>
				<ListContainer onClick={() => switchOpen('opportunity_open')}>
					<Open isOpen={linkedElementOpen.opportunity_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.opportunity').toString()}`} ({props.linkedAmount?.amount_opportunities ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateOpportunity' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('opportunity_open');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				<OpportunityCreation
					isOpen={creationOpen.opportunity_open}
					setOpen={() => switchCreation('opportunity_open')}
					clientCompanyId={props.clientCompanyId}
					clientCompanyName={props.companyDetail?.company_name}
					onCreate={(value) => {
						props.onUpdate();
						setNewCreation({ ...newCreations, opportunity: value });
					}}
				/>
				{
					linkedElementOpen.opportunity_open &&
				<Opportunity 
					isExtend={props.isExtend} 
					id={{ Company: props.clientCompanyId }} 
					newCreation={newCreations?.opportunity}
					onDelete={() => props.onUpdate()}
				/>
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadOrder' }} isSelf={props.isSelf}>
			<>
				<ListContainer onClick={() => switchOpen('order_open')}>
					<Open isOpen={linkedElementOpen.order_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.order').toString()}`} ({props.linkedAmount?.amount_orders ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateOrder' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); window.open(`/orders?create=${props.clientCompanyId}`, '_blank');}} round src={add_blue} />
					</Restricted>
				</ListContainer>

				{
					linkedElementOpen.order_open &&
				<Order 
					isExtend={props.isExtend} 
					clientCompanyId={props.clientCompanyId} 
					onDelete={() => props.onUpdate()}
				/>
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadCompanyPicture' }} isSelf={props.isSelf}>
			<>
				<ListContainer onClick={() => switchOpen('photo_open')}>
					<Open isOpen={linkedElementOpen.photo_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.photo').toString()}`} ({props.linkedAmount?.amount_photos ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateCompanyPicture' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('photo_open');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				<PhotoCreation
					isOpen={creationOpen.photo_open}
					setOpen={() => switchCreation('photo_open')}
					clientCompanyId={props.clientCompanyId}
					onCreate={(values) => {
						props.onUpdate();
						setNewCreation({ ...newCreations, photos: values });
					}}
					maxPhoto={maxPhoto}
				/>

				{
					linkedElementOpen.photo_open &&
				<Photo
					clientCompanyId={props.clientCompanyId}
					isExtend={props.isExtend}
					newCreation={newCreations?.photos}
					onDelete={() => props.onUpdate()}
				/>
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadCompanyDocument' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
			<>
				<ListContainer onClick={() => switchOpen('document_open')}>
					<Open isOpen={linkedElementOpen.document_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.document').toString()}`} ({props.linkedAmount?.amount_documents ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateCompanyDocument' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('document_open');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				<DocumentCreation
					isOpen={creationOpen.document_open}
					setOpen={() => switchCreation('document_open')}
					id={{ Company: props.clientCompanyId }}
					onCreate={(values) => {
						props.onUpdate();
						setNewCreation({ ...newCreations, documents: values });
					}}
					maxDocument={maxDocument}
				/>
				{
					linkedElementOpen.document_open &&
					<Document
						isExtend={props.isExtend}
						id={{ Company: props.clientCompanyId }}
						newCreation={newCreations?.documents}
						onDelete={() => props.onUpdate()}
					/>
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadForm' }} isSelf={props.isSelf}>
			<>
				<LinkedForms company_id={props.clientCompanyId}></LinkedForms>
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadFreeForm' }} isSelf={props.isSelf}>
			<>
				<ListContainer onClick={() => switchOpen('free_forms')}>
					<Open isOpen={linkedElementOpen.free_forms} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.free_forms').toString()}`} ({props.linkedAmount?.amount_free_forms ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateFreeForm' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('free_forms');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				<FreeFormCreation
					isOpen={creationOpen.free_forms}
					setOpen={() => switchCreation('free_forms')}
					clientCompanyId={props.clientCompanyId}
					clientCompanyName={props.companyDetail?.company_name ?? ''}
				/>
				{
					linkedElementOpen.free_forms &&
				<FreeForms isExtend={props.isExtend} clientCompanyId={props.clientCompanyId} total={props.linkedAmount?.amount_free_forms ?? 0} />
				}
			</>
		</Restricted>
		<Restricted to={{ objectAction: 'ReadCompanyNote' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
			<>
				<ListContainer onClick={() => switchOpen('note_open')}>
					<Open isOpen={linkedElementOpen.note_open} width={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} height={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
					<ListText><FlexDiv>{`${translate('company.detail.linked_elements.note').toString()}`} ({props.linkedAmount?.amount_notes ?? <><Loader width='20px' /></>})</FlexDiv></ListText>
					<Restricted to={{ objectAction: 'CreateCompanyNote' }} isSelf={props.isSelf} fallback={<div style={{ width: '25px', height: '25px' }} />}>
						<ListAdd onClick={(e) => {e.stopPropagation(); switchCreation('note_open');}} round src={add_blue} />
					</Restricted>
				</ListContainer>
				<NoteCreation
					isOpen={creationOpen.note_open}
					setOpen={() => switchCreation('note_open')}
					id={{ Company: props.clientCompanyId }}
					onCreate={(value) => {
						props.onUpdate();
						setNewCreation({ ...newCreations, note: value });
					}}
				/>
				{
					linkedElementOpen.note_open &&
				<Note
					isExtend={props.isExtend}
					id={{ Company: props.clientCompanyId }}
					newCreation={newCreations?.note}
					onDelete={() => props.onUpdate()}
				/>
				}
			</>
		</Restricted>
	</Body>;
}

export default LinkedElements;
