import axios from 'axios';
import { LinkedElementId } from '../../../../containers/companies/components/linkedElements/actions';
import { URL_FOUNDATION } from '../../../../config/keys';
import { CompanyDocumentDb, CompanyEventDb, CompanyNoteDb, CompanyOpportunityDb } from '../../model/Model';
import { UpdatedField } from '../../../../../../web-types/api';

type LinkedElementBody = {
	limit: number,
	offset: number,
	id: LinkedElementId
}

const linkedElementIdToParam = (id: LinkedElementId) => {
	const [key, value] = Object.entries(id)[0];
	return `${key}:${value}`;
};

export function getLinkedElementEvents(body: LinkedElementBody): Promise<CompanyEventDb> {
	return axios.post(`${URL_FOUNDATION}/api/v2/linked-elements/events/query`, body).then(res => res.data);
}

export function getLinkedElementOpportunities(body: LinkedElementBody): Promise<CompanyOpportunityDb> {
	return axios.post(`${URL_FOUNDATION}/api/v2/linked-elements/opportunities/query`, body).then(res => res.data);
}

export function getLinkedElementDocuments(body: LinkedElementBody): Promise<CompanyDocumentDb> {
	return axios.post(`${URL_FOUNDATION}/api/v2/linked-elements/documents/query`, body).then(res => res.data);
}

export function getLinkedElementNotes(body: LinkedElementBody): Promise<CompanyNoteDb> {
	return axios.post(`${URL_FOUNDATION}/api/v2/linked-elements/notes/query`, body).then(res => res.data);
}

type DeleteLinkedElementBody = {
	linkedId: LinkedElementId,
	elementId: number
}

export function deleteLinkedElementDocument(body: DeleteLinkedElementBody): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/linked-elements/${linkedElementIdToParam(body.linkedId)}/documents/${body.elementId}`).then(res => res.data);
}

export function deleteLinkedElementNote(body: DeleteLinkedElementBody): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/linked-elements/${linkedElementIdToParam(body.linkedId)}/note/${body.elementId}`).then(res => res.data);
}

export function putLinkedElementNote(linkedId: LinkedElementId, elementId: number, body: { title: UpdatedField<string>, body: UpdatedField<string> }): Promise<void> {
	return axios.put(`${URL_FOUNDATION}/api/v2/linked-elements/${linkedElementIdToParam(linkedId)}/note/${elementId}`, body).then(res => res.data);
}