import * as React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AUsers } from '../../atoms/global/users';
import Avatar from '../../components_v2/avatar/Avatar';
import { BodyMainImage } from '../../containers_v2/client-companies/style/PopupStyle';
import { Owner } from '../../containers_v2/orders/model/Model';
import { FlexDiv, HideTrashDiv } from '../../containers_v2/products/style';
import { DefaultText } from './css/GlobalText';
import { BorderColor, RedSidely, SidelyBlack } from './css/Utils';

interface ToolTipStyleProps {
	top?: string
	left?: string
	error?: boolean
	wrap?: boolean
	backgroundColor?: string
}

interface ToolTipProps extends ToolTipStyleProps {
	children
	toolTipContent?: JSX.Element | string
	allwaysOpen?: boolean
	mouseEnter?: () => void
	mouseLeave?: () => void
}

const ToolTypeStyle = styled.div<ToolTipStyleProps>`
		${DefaultText};
		background-color: ${p => p.error ? RedSidely : (p.backgroundColor ?? SidelyBlack)};
		padding: 4px 7px;
		border-radius: 5px;
		position: absolute;
		z-index: 1;
		transform: translate(${p => p.left ?? '0px'}, calc(-100% - 5px));
		top: ${p => p.top ?? 0};
		color: white;
		font-size: 11px;
		font-weight: 400;
		${p => p.wrap ? '' : 'white-space: nowrap;'}
		box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
		border: 0.5px solid ${BorderColor};
`;

export default function ToolTip(props: ToolTipProps): JSX.Element {
	return <HideTrashDiv>
		{props.children}
		{props.toolTipContent &&
						<ToolTypeStyle className={props.allwaysOpen ? '' : 'trash'} {...props} onMouseEnter={props.mouseEnter} onMouseLeave={props.mouseLeave}>
							{props.toolTipContent}
						</ToolTypeStyle>
		}
	</HideTrashDiv>;
}

type UserToolTipProps = ToolTipProps & {
	user?: Owner
	userId?: number
}

const UserNameDiv = styled.div`
		${DefaultText};
		color: ${SidelyBlack};
		font-weight: 700;
		font-size: 13px;
`;

const UserEmailDiv = styled.div`
		${DefaultText};
		color: ${SidelyBlack};
		font-size: 12px;
`;

export function UserToolTip(props: UserToolTipProps): JSX.Element {
	const users = useRecoilValue(AUsers);
	const user = users.find(u => u.id === props.userId) ?? props.user;
	return <ToolTip
		{...props}
		backgroundColor={props.backgroundColor ?? 'white'}
		left={props.left ?? '-50%'}
		toolTipContent={
			<FlexDiv gap='10px'>
				{user?.photoUrl ? <BodyMainImage src={user.photoUrl} size='34px' /> : <Avatar userId={user?.id} width='34px' name={user?.name ?? ''} />}
				<FlexDiv flow="column" align='flex-start'>
					<UserNameDiv>{user?.name}</UserNameDiv>
					<UserEmailDiv>{user?.email}</UserEmailDiv>
				</FlexDiv>
			</FlexDiv>
		}
	/>;
}
