import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor } from '../../../styles/global/css/Utils';
import { BodyMainContainer } from './PopupStyle';

const Span = styled.span<{ noClick?: boolean }>`
  ${p =>
    p.noClick
      ? ''
      : `
        color: ${BlueSidely};
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    `}
  font-weight: 600;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-left: 3px;
`;

const TimelineBodyMainContainer = styled(BodyMainContainer)`
  width: 100%;
  padding-left: 2em;
  height: calc(100% - 80px);
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 52px);
`;

interface TimelineHeaderProps {
  isOpen: boolean;
  noBorder?: boolean;
}

const TimelineHeader = styled.div<TimelineHeaderProps>`
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${p => (p.noBorder ? '' : `border-bottom: 1px solid ${BorderColor}; padding: 10px;`)}
  height: 70px;
  opacity: ${p => (p.isOpen ? '1' : '0')};
  justify-content: end;
`;

const TimelineHeaderLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const TimelineDate = styled.p`
  ${DefaultText}
  font-weight: 500;
  font-size: 15px;
  margin-left: 10px;
`;

interface TimelineCollapseProps {
  isOpen: boolean;
}

const TimelineCollapse = styled.div<TimelineCollapseProps>`
  display: ${p => (p.isOpen ? 'inital' : 'none')};
`;

export {
    Span,
    TimelineBodyMainContainer,
    TimelineCollapse,
    TimelineContainer,
    TimelineDate,
    TimelineHeader,
    TimelineHeaderLine
};

