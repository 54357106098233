import closeIcon from 'images/icon/closeIcon.png';
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'reactstrap-button-loader';
import { Translate } from 'react-localize-redux';
import { get } from 'lodash';
import { parseJSON } from 'jquery';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { browserGoBack } from '../../../helpers/browserHistory';
import FormProvider from './builderLib/index';
import * as variables from './builderLib/services/variables';
import Demobar from './builderLib/Demobar';
import { createData, detailData, updateData } from '../../../store/actions/formbuilder.action';
import PageLoader from '../../../components/PageLoader';
import store from './builderLib/services/store';
import { isEndSection, isEndTable, isStartSection, isStartTable } from './builderLib/services/fieldSetter';
import { sectionConverter } from './builderLib/services/sectionConverter';
import elementGetter from './builderLib/services/elementGetter';
import { EndTable } from './builderLib/services/staticData';
import ID from './builderLib/services/UUID';

const url = '/api/formdata';
const saveUrl = '/api/formdata';

class FormDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataSubmit: [],
			validate: [],
			modalValidate: false
		};

		const onUpdate = this._onChange.bind(this);
		store.subscribe(state => onUpdate(state.data));
	}

	componentDidMount() {
		const { detailData, match } = this.props;
		detailData(match.params.id).then(
			res => {
				const userFormFields = get(res, 'data.data.user_form_fields');
				const elements = [];
				userFormFields?.map(dt => {
					elements.push(elementGetter(dt, match.params.type));
					if (dt.field_type === 'table') {
						const detailsField = get(dt, 'details.fields', []) || [];
						detailsField.map(dts => {
							elements.push(
								elementGetter(
									{
										...dts,
										id: ID.uuid(),
										field_filled_value_count: dt.field_filled_value_count
									},
									match.params.type
								)
							);
						});
						elements.push(
							EndTable(
								match.params.type === 'edit' && dt.field_filled_value_count > 0
							)
						);
					}
					return null;
				});
				store.dispatch('load', { data: elements || [], isInit: true });
			},
			err => { }
		);
	}

	validateForm = () => {
		const dataSubmit = [...this.state.dataSubmit];
		const validate = [];
		const emptyArr = [null, NaN, ''];

		let countStartSection = 0;
		let countEndSection = 0;
		let countStartTable = 0;
		let countEndTable = 0;

		let lastSection = 'end_section';
		let lastTable = 'end_table';
		let sectionError = false;
		let tableError = false;

		dataSubmit.map((data, index) => {
			const errors = [];
			if (data.field_type === 'numerical') {
				if (emptyArr.includes(get(data, 'details.max'))) {
					errors.push('max value required');
				}
				if (emptyArr.includes(get(data, 'details.min'))) {
					errors.push('min value required');
				}

				if (get(data, 'details.max') <= get(data, 'details.min')) {
					errors.push(
						'The minimum value must not be more than the maximum value'
					);
				}
			}
			if (data.field_type === 'section') {
				if (get(data, 'is_start_section') === true) {
					countStartSection += 1;
					if (lastSection === 'start_section') {
						sectionError = true;
					} else {
						lastSection = 'start_section';
					}
				}
				if (get(data, 'is_end_section') === true) {
					countEndSection += 1;
					if (lastSection === 'end_section') {
						sectionError = true;
					} else {
						lastSection = 'end_section';
					}
				}
			}
			if (data.field_type === 'table') {
				if (get(data, 'is_start_table') === true) {
					countStartTable += 1;
					if (lastTable === 'start_table') {
						tableError = true;
					} else {
						lastTable = 'start_table';
					}
				}
				if (get(data, 'is_end_table') === true) {
					countEndTable += 1;
					if (lastTable === 'end_table') {
						tableError = true;
					} else {
						lastTable = 'end_table';
					}
				}
			}

			if (data.field_type === 'image') {
				if (emptyArr.includes(get(data, 'details.default_image_id'))) {
					errors.push('image file required');
				}
			}
			if (data.field_type === 'dropdown') {
				if (emptyArr.includes(get(data, 'details.accepted_value'))) {
					errors.push('dropdown file required');
				}
			}
			if (data.field_type === 'dropdown') {
				dataSubmit[index].initial_value =
          dataSubmit[index].initial_value || 'empty';
			}

			if (errors.length) {
				validate.push({
					errors,
					system_field_name: data.system_field_name
				});
			}

			return null;
		});

		if (countStartSection !== countEndSection) {
			validate.push({
				errors: ['missed start/end the section'],
				system_field_name: 'section'
			});
		}
		if (countStartTable !== countEndTable) {
			validate.push({
				errors: ['missed start/end the table'],
				system_field_name: 'table'
			});
		}

		if (sectionError) {
			validate.push({
				errors: [
					'Section inside section is not allowed or Make sure Start/End section is at proper order'
				],
				system_field_name: 'section'
			});
		}

		if (tableError) {
			validate.push({
				errors: [
					'Table inside table is not allowed or Make sure Start/End table is at proper order'
				],
				system_field_name: 'table'
			});
		}

		if (validate.length) {
			this.setState({ validate, modalValidate: true });
			return false;
		}
		this.setState({ modalValidate: false });
		return true;
	};

	onPreview = () => {
		this.child.showPreview();
	};

	_onChange = data => {
		const dataSubmit = [];
		const dataFORM = data.filter(element => element !== undefined);
		dataFORM.forEach((item, index) => {
			dataSubmit.push({
				id: item.new_element ? '' : item.id,
				field_type: item.field_type,
				field_name: item.field_name,
				system_field_name: item.system_field_name,
				is_display_field_name: item.is_display_field_name,
				is_required: item.is_required,
				is_start_section: isStartSection(item.element),
				is_end_section: isEndSection(item.element),
				is_start_table: isStartTable(item.element),
				is_end_table: isEndTable(item.element),
				is_multiple_value: item.is_multiple_value,
				ordering: index,
				details: {
					is_default_value_today: item.is_default_value_today,
					initial_value: item.initial_value,
					accepted_value: item.accepted_values,
					is_ticked: item.is_default_checked,
					default_image_id: item.default_image_id,
					min: parseInt(item.min, 10),
					max: parseInt(item.max, 10),
					is_default_user_name: item.is_default_user_name,
					link: item.link,
					fields: [],
					allow_multiple_pictures: item.allow_multiple_pictures,
					allow_picture_upload: item.allow_picture_upload
				}
			});
		});
		this.setState({
			dataSubmit
		});
	};

	saveForm = () => {
		const { createData, updateData, match, data } = this.props;
		const formFirst =
      match.params.type === 'edit'
      	? { name: data.dataDetail.name, icon_id: data.dataDetail.icon_id }
      	: parseJSON(localStorage.getItem('formTitle'));
		const dataSubmit = [...this.state.dataSubmit];

		const user_form_fields = sectionConverter(
			dataSubmit,
			'table',
			'is_start_table',
			'is_end_table'
		);

		const formFinal = { ...formFirst, user_form_fields };

		if (match.params.type === 'edit') {
			// updateData(formFinal, match.params.id);
		} else {
			createData(formFinal);
		}
	};

	render() {
		const { data, route, match } = this.props;

		const title = match.params.type === 'edit' ? get(data, 'dataDetail') : '';

		const formTitle = title || parseJSON(localStorage.getItem('formTitle'));


		return (
			(<div>
				<div className="d-flex background-bar sticky-v2 right-panel">
					<Link to="/formBuilder" className="pr-3 my-auto">
						<h2 className="card-title mb-0">{route.title}</h2>
					</Link>
					<div className="pl-3 my-auto">
						<h2 className="card-title font-weight-bold mb-0" style={{ maxWidth: 'calc(100vw - 600px)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
							{get(formTitle, 'name', null)}
						</h2>
					</div>
					<div className="ml-auto my-auto">
						<Button
							className="btn btn-preview-formbuilder"
							onClick={() => {
								this.validateForm() && this.onPreview();
							}}
							spinColor="#003d79"
						>
							<span className="text-save">
								{!data.isLoading && <Translate id="preview" />}
							</span>
						</Button>
					</div>
					<div className="ml-2 my-auto">
						<Button
							className="btn btn-save-formbuilder"
							loading={data.isLoading}
							disabled={data.isLoading}
							onClick={() =>
								!data.isLoading && this.validateForm() && this.saveForm()
							}
							spinColor="#003d79"
						>
							<span className="text-save-formbuilder">
								{!data.isLoading && <Translate id="save" />}
							</span>
						</Button>
					</div>
					<div className="ml-3 my-auto">
						<img
							src={closeIcon}
							className="custom-icon-3 my-auto mx-3"
							alt=""
							onClick={() => {
								browserGoBack();
							}}
						/>
					</div>
				</div>
				<div className="p-3">
					<div
						className="forms-sample background-form full-form"
						style={{ height: 'auto' }}
					>
						{get(data, 'isLoadingDetail') ? (
							<PageLoader />
						) : (
							<FormProvider.ReactFormBuilder
								variables={variables}
								url={url}
								saveUrl={saveUrl}
							/>
						)}
					</div>

					<Modal
						centered
						toggle={() => {
							this.setState({ modalValidate: false });
						}}
						isOpen={this.state.modalValidate}
					>
						<ModalHeader
							toggle={() => {
								this.setState({ modalValidate: false });
							}}
						>
              Please fill in the following fields{' '}
						</ModalHeader>
						<ModalBody>
							<div className="row">
								<div className="col-12">
									<ul>
										{this.state.validate.map(dt => (
											<li>
                        Sistem Field Name : {dt.system_field_name}
												<ul>
													{dt.errors.map(vl => (
														<li>{vl}</li>
													))}
												</ul>
											</li>
										))}
									</ul>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button
								color="primary"
								onClick={() => {
									this.setState({ modalValidate: false });
								}}
							>
                OK
							</Button>
						</ModalFooter>
					</Modal>

					<Demobar variables={variables} onRef={ref => (this.child = ref)} />
				</div>
			</div>)
		);
	}
}

const mapStateToProps = state => ({
	data: state.formbuilder,
	current: state.user.current
});

export default connect(mapStateToProps, { detailData, createData, updateData })(
	FormDetail
);
