import {
	GET_ALPHABET,
	GET_LIST_COMPANIES_ERROR,
	GET_LIST_COMPANIES_REQUEST,
	GET_LIST_COMPANIES_SUCCESS,
	SELECT_ALL_USER,
	SELECT_ALL_YEARS,
	SELECT_BRAND,
	SELECT_CATALOGUE,
	SELECT_CATEGORY,
	SELECT_CATEGORY_HIERARCHY,
	SELECT_CLIENT_STATUS,
	SELECT_COMPANY,
	SELECT_CONTACT,
	SELECT_DATA_CITY,
	SELECT_EVENT_STATUS,
	SELECT_EVENT_TYPE,
	SELECT_EXPENSE_CATEGORY,
	SELECT_EXPENSE_CURRENCY,
	SELECT_EXPENSE_PAYMENT_METHOD,
	SELECT_EXPENSE_STATUS,
	SELECT_GROUP,
	SELECT_GROUP_BY_MANAGER,
	SELECT_INCHARGE,
	SELECT_INTEREST,
	SELECT_MANAGER,
	SELECT_OPPORTUNITY,
	SELECT_OPPORTUNITY_STATUS,
	SELECT_PRODUCT,
	SELECT_PROSPECT_ORIGIN,
	SELECT_TAG,
	SELECT_TARGET_TYPE,
	SELECT_TRADESHOW_TYPE,
	SELECT_USER_BY_MANAGER,
	SELECT_USER_TYPE
} from '../actions/select.action';

const initState = {
	isLoading: false,
	allUser: [],
	allCity: [],
	inCharge: [],
	group: [],
	dataGroup: [],
	clientStatus: [],
	opportunityStatus: [],
	contact: [],
	company: [],
	opportunity: [],
	eventType: [],
	eventStatus: [],
	tradeshowType: [],
	interest: [],
	prospectOrigin: [],
	expenseCategory: [],
	expenseStatus: [],
	expenseCurrency: [],
	expensePaymentMethod: [],
	userType: [],
	manager: [],
	targetType: [],
	allYears: [],
	userByManager: [],
	alphabet: null,
	listCompanies: [],
	category: [],
	categoryHierarchy: [],
	brand: [],
	catalogue: [],
	tag: [],
	product: [],
	productList: [],
	listCompanies: []
};

export default function SelectReducer(state = initState, action) {
	switch (action.type) {
		case SELECT_ALL_USER:
			return {
				...state,
				allUser: action.payload.data
			};
		case SELECT_INCHARGE:
			return {
				...state,
				inCharge: action.payload.data
			};
		case SELECT_GROUP:
			return {
				...state,
				group: action.payload.data
			};
		case SELECT_GROUP_BY_MANAGER:
			return {
				...state,
				dataGroup: action.payload.data
			};
		case SELECT_CLIENT_STATUS:
			return {
				...state,
				clientStatus: action.payload.data
			};
		case SELECT_OPPORTUNITY_STATUS:
			return {
				...state,
				opportunityStatus: action.payload.data
			};
		case SELECT_CONTACT:
			return {
				...state,
				contact: action.payload.data
			};
		case SELECT_COMPANY:
			return {
				...state,
				company: action.payload.data
			};
		case SELECT_OPPORTUNITY:
			return {
				...state,
				opportunity: action.payload.data
			};
		case SELECT_EVENT_TYPE:
			return {
				...state,
				eventType: action.payload.data
			};
		case SELECT_EVENT_STATUS:
			return {
				...state,
				eventStatus: action.payload.data
			};
		case SELECT_TRADESHOW_TYPE:
			return {
				...state,
				tradeshowType: action.payload.data
			};
		case SELECT_EXPENSE_CATEGORY:
			return {
				...state,
				expenseCategory: action.payload.data
			};
		case SELECT_EXPENSE_STATUS:
			return {
				...state,
				expenseStatus: action.payload.data
			};
		case SELECT_EXPENSE_CURRENCY:
			return {
				...state,
				expenseCurrency: action.payload.data
			};
		case SELECT_EXPENSE_PAYMENT_METHOD:
			return {
				...state,
				expensePaymentMethod: action.payload.data
			};
		case SELECT_INTEREST:
			return {
				...state,
				interest: action.payload.data
			};
		case SELECT_PROSPECT_ORIGIN:
			return {
				...state,
				prospectOrigin: action.payload.data
			};
		case SELECT_USER_TYPE:
			return {
				...state,
				userType: action.payload.data
			};
		case SELECT_MANAGER:
			return {
				...state,
				manager: action.payload.data
			};
		case SELECT_TARGET_TYPE:
			return {
				...state,
				targetType: action.payload.data
			};
		case SELECT_ALL_YEARS:
			return {
				...state,
				allYears: action.payload.data
			};
		case SELECT_USER_BY_MANAGER:
			return {
				...state,
				userByManager: action.payload.data
			};

		case GET_ALPHABET:
			return {
				...state,
				alphabet: action.payload
			};

		case SELECT_DATA_CITY:
			return {
				...state,
				allCity: action.payload
			};

		case SELECT_CATEGORY:
			return {
				...state,
				category: action.payload.map(data => ({
					label: data.name,
					value: data.id
				}))
			};

		case SELECT_CATEGORY_HIERARCHY:
			return {
				...state,
				categoryHierarchy: action.payload
			};

		case SELECT_BRAND:
			return {
				...state,
				brand: action.payload.map(data => ({
					label: data.name,
					value: data.id
				}))
			};

		case SELECT_CATALOGUE:
			return {
				...state,
				catalogue: action.payload.map(data => ({
					label: data.name,
					value: data.id
				}))
			};

		case SELECT_TAG:
			return {
				...state,
				tag: action.payload.map(data => ({
					label: data.name,
					value: data.id,
					color: data.colorStatus.colorCode
				}))
			};

		case SELECT_PRODUCT:
			return {
				...state,
				product: action.payload.map(data => ({
					label: data.name,
					value: data.id,
					color: 'black'
				})),
				productList: action.payload
			};

		case GET_LIST_COMPANIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_LIST_COMPANIES_SUCCESS:
			return {
				...state,
				listCompanies: action.payload,
				isLoading: false
			};
		case GET_LIST_COMPANIES_ERROR:
			return {
				...state,
				isLoading: false
			};
		default:
			return state;
	}
}
