import styled from 'styled-components';
import { DefaultImage } from '../../../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor } from '../../../../../styles/global/css/Utils';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

const ContainerDetail = styled.div`
    width: calc(100vw - 60px);
    overflow-x: auto;
    height: calc(100vh - 120px);
`;

const StatusTop = styled.div`
    background-color: white;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    padding: 5px;
`;

interface StatusProps {
  color?: string
}

interface BlockStatusProps {
  width?: string
}

const BlockStatus = styled.div<BlockStatusProps>`
    width: ${p => p.width ? p.width : 'auto'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 270px;
    min-width: 210px;
`;

const Status = styled.div<StatusProps>`
    width: 200px;
    height: 50px;
    background: ${p => p.color ? p.color : BlueSidely};
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

const CardContainer = styled.div`
    display: flex;
    padding-top: 10px;
    background-color: white;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    height: calc(100vh - 225px)
`;

const LoaderContainer = styled.div`
    width: 95%;
    min-height: 115px;
`;

const CardColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    height: 100%;
    width: 200px;
    overflow-x: clip;
    overflow-y: auto;
    height: 100%;
`;

const Card = styled.div`
    width: 95%;
    //height: 130px;
    min-height: 115px;
    background-color: white;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
    border-radius: 4px;
    margin: 2px;
    display: flex;
    flex-direction: column;
    text-align: center;
    user-select: none;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
`;

const CardTitle = styled.h5`
    ${DefaultText}
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4C5862;
    margin: 5px;
    word-break: break-all;
`;

const CartTextTransparent = styled.span`
    color: rgba(76, 88, 98, 0.5);
`;

const CardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const CardText = styled.p`
    ${DefaultText}
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #4C5862;
    margin: 5px;
`;

const CardImage = styled(DefaultImage)`
    width: 20px;
    height: 20px;
    border-radius: 50%;
`;

const Footer = styled.footer`
    display: flex;
    flex-wrap: nowrap;
`;

const TotalPriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
`;

const FooterBottom = styled.div`
    width: 100%;
`;

interface BlockTotalPriceProps {
  width?: string
}

const BlockTotalPrice = styled.div<BlockTotalPriceProps>`
    width: ${p => p.width ? p.width : 'auto'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 270px;
    min-width: 210px;
    background-color: #FFFFFF;
    border-top: 1px solid rgb(235, 239, 242);
`;

const TotalPrice = styled.div``;

const Separator = styled.hr`
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: ${BorderColor};
    width: 90%;
`;

export {
	Container,
	StatusTop,
	Status,
	CardContainer,
	CardColumn,
	Card,
	Footer,
	Separator,
	CardTitle,
	CardText,
	TotalPrice,
	TotalPriceContainer,
	FooterBottom,
	BlockStatus,
	ContainerDetail,
	BlockTotalPrice,
	CartTextTransparent,
	CardImage,
	CardRow,
	LoaderContainer
};
