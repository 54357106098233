import React from 'react';
import Button from 'reactstrap-button-loader';

function DefaultButton(props) {
    const { classType, disabled, onClickBtn, label, isLoading } = props;
    return (
        <div>
            <Button
                className={`btn btn-save d-flex justify-content-center ${classType}`}
                spinColor="#003d79"
                disabled={disabled}
                loading={isLoading}
                onClick={onClickBtn}
            >
                <span>{label}</span>
            </Button>
        </div>
    );
}

export default DefaultButton;