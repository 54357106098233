import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_FOUNDATION } from '../../../config/keys';
import { Brand } from '../../../containers_v2/globals/Model';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function getBrands(): Promise<Brand[]> {
	try {
		const result = await axios.get<Brand[]>(`${URL_FOUNDATION}/api/brands`)
			.then(response => response.data.map(b => ({ ...b, created_at: b.created_at + 'Z' })));
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}
