import React, { Component } from 'react';
import { CompleteTime } from '../../../../helpers/time';
import phoneIcon from 'images/activity_icons/call.png';
import mailIcon from 'images/activity_icons/mail.png';
import checkedIcon from 'images/activity_icons/checked.png';
import { Translate } from 'react-localize-redux';

class Activity extends Component {
	state = {};

	render() {
		const {
			data,
			onFetch,
			isLoading,
			isFirstPage,
			isLastPage,
			count,
			firstName,
			lastName
		} = this.props;
		// console.log('firstPage', isFirstPage);
		// console.log('lastPage', isLastPage);

		return (
			<div>
				<div className="detail-top m-3 p-3">
					<div className="mb-2">
						<span className="card-title">
							<Translate id="activities" /> ({count || '0'})
						</span>
					</div>
					{data.length ? (
						<div className="row">
							{data.map(val => (
								<div className="col-md-12 p-0">
									<div className="card card-activity">
										<div className="card-body card-no-shadow p-2">
											<div className="row">
												<div className="col-md-1 my-1 d-flex justify-content-center text-center p-0">
													{val.tagActivity === 1 ? (
														<img
															className="rounded-circle"
															src={phoneIcon}
															alt=""
															width="40"
															height="40"
														/>
													) : (
														<div
															className="rounded-circle d-flex align-items-center justify-content-center"
															style={{
																height: '40px',
																width: '40px',
																backgroundColor: '#C2CFE0'
															}}
														>
															<img
																className="rounded-circle"
																src={
																	val.tagActivity === 2 || val.tagActivity === 3
																		? mailIcon
																		: checkedIcon
																}
																alt=""
																height={
																	val.tagActivity === 2 || val.tagActivity === 3
																		? '18'
																		: '15'
																}
															/>
														</div>
													)}
												</div>
												<div className="col-md-9 pl-1 d-flex align-items-center">
													<div className="d-block ml-2">
														<p className="mb-1 text-title">
															{val.title || 'no_tittle'}
														</p>
														{/* <p className="mb-1 text-description">
                              {val.description || 'no_descriptions'}
                            </p> */}
														<p className="mb-1 text-by">
															<span>{`by ${(val.owner && val.owner.name) ||
                                '-'} `}</span>
															{`on ${CompleteTime(val.createdAt)}`}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<p
							style={{
								fontSize: '14px',
								margin: '15px 0 10px',
								color: '#707683'
							}}
						>
							<Translate id="no activity" />
						</p>
					)}
					{count > 3 &&
            (!isLoading ? (
            	<div className="row mt-2">
            		<button
            			className={`${
            				isLastPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-more col-6`}
            			onClick={() => {
            				!isLastPage && onFetch();
            			}}
            		>
            			<Translate id="see_more" />
            		</button>
            		<button
            			className={`${
            				isFirstPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-less col-6`}
            			onClick={() => {
            				!isFirstPage && onFetch(1);
            			}}
            		>
            			<Translate id="see_less" />
            		</button>
            	</div>
            ) : (
            	<div className="d-flex mt-2">
            		<i className="lds-dual-ring mx-auto" />
            	</div>
            ))}
				</div>
			</div>
		);
	}
}

export default Activity;
