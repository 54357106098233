/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component, Fragment} from 'react';
import {setFormatPhone} from '../../../../helpers/textConfig';
import {Translate} from 'react-localize-redux';

class Overview extends Component {
	state = {};

	render() {
		const { dataDetail } = this.props;

		return (
			<div className="detail-body">
				<div className="row">
					<div className="col-md-3 text-md-right px-4">
						<p>
							<Translate id="status" />
						</p>
					</div>
					<div className="col-md-9">
						<p className="detail-value-key">
							{(dataDetail.clientStatus && dataDetail.clientStatus.name) || '-'}
						</p>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3 text-md-right px-4">
						<p><Translate id="company" /></p>
					</div>
					<div className="col-md-9">
						<p className="detail-value-key">
							<a
								href={
									dataDetail.clientCompany
										? `/companies?id=${dataDetail.clientCompany.id}`
										: '#'
								}
								className="type-link"
							>
								{(dataDetail.clientCompany && dataDetail.clientCompany.name) ||
                  '-'}
							</a>
						</p>
					</div>
				</div>

				<div className="row">
					<div className="col-md-3 text-md-right px-4">
						<p>Email</p>
					</div>
					<div className="col-md-9">
						<p className="detail-value-key">
							{
								dataDetail.email && dataDetail.email.trim().length > 0
									?
									<a href={`mailto:${dataDetail.email}`} target='_blank' className="type-link" rel="noreferrer">
										{dataDetail.email}
									</a>
									:
									<>-</>
							}
						</p>
					</div>
				</div>

				<div className="row">
					<div className="col-md-3 text-md-right px-4">
						<p><Translate id="phone" /></p>
					</div>
					<div className="col-md-9">
						<p className="detail-value-key">
							{setFormatPhone(dataDetail.phone) || '-'}
						</p>
					</div>
				</div>

				<div className="row">
					<div className="col-md-3 text-md-right px-4">
						<p>
							<Translate id="position" />
						</p>
					</div>
					<div className="col-md-9">
						<p className="detail-value-key">{dataDetail.position || '-'}</p>
					</div>
				</div>
				{dataDetail.contactTags && 
          <div className="row">
          	{dataDetail.contactTags.length !== 0 &&
              <Fragment>
              	<div className="col-md-3 text-md-right px-4 pt-1">
              		<p>Tags</p>
              	</div>
              	<div className="col-md-9">
              		{dataDetail.contactTags.map(data => (
              			<div
              				className="px-3 tag-detail mr-1"
              				style={{
              					border: `1px solid ${data.tags && data.tags.colorStatus.colorCode}`
              				}}
              			>
              				<p
              					className="mb-0"
              					style={{ color: `${data.tags && data.tags.colorStatus.colorCode}` }}
              				>
              					{data.tags && data.tags.name}
              				</p>
              			</div>
              		))}
              	</div>
              </Fragment>
          	}
          </div>
				}
			</div>
		);
	}
}

export default Overview;
