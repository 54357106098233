import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

// ----------[ TagFilter ]---------- //
const AglobalTagFilter: AtomState<string[] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_tag_filter',
		default: undefined
	})
};

const AorderTagFilter: AtomState<string[] | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_tag_filter',
		default: undefined
	})
};

const AgalleryTagFilter: AtomState<string[] | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_gallery_tag_filter',
		default: undefined
	})
};

const AcompanyTagFilter: AtomState<string[] | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_company_tag_filter',
		default: undefined
	})
};

const ATagFilter: RecoilState<string[] | undefined> = selector({
	key: 'atom_tag_filter',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<string[] | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalTagFilter.atom;
		case AtomCategory.ORDER:
			return AorderTagFilter.atom;
		case AtomCategory.GALLERY:
			return AgalleryTagFilter.atom;
		case AtomCategory.COMPANY:
			return AcompanyTagFilter.atom;
		default:
			return AglobalTagFilter.atom;
	}
}

export {
	AorderTagFilter,
	AcompanyTagFilter,
	AgalleryTagFilter,
	AglobalTagFilter,
	ATagFilter
};
