/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
// eslint-disable-next-line max-classes-per-file
import AddressField from './fields/AddressField';
import Calculation from './fields/Calculation';
import Camera from './fields/Camera';
import Checkbox from './fields/Checkbox';
import DatePicker from './fields/Date';
import Document from './fields/Document';
import DropdownList from './fields/DropdownList';
import Email from './fields/Email';
import EndSection from './fields/EndSection';
import EndTable from './fields/EndTable';
import FreeText from './fields/FreeText';
import Header from './fields/Header';
import Hyperlink from './fields/Hyperlink';
import Image from './fields/Image';
import Numerical from './fields/Numerical';
import StartSection from './fields/StartSection';
import StartTable from './fields/StartTable';
import UserField from './fields/UserField';
import Website from './fields/Website';

// const Header = <div>Ini Header</div>;

const Formfields = {};

Formfields.StartSection = StartSection;
Formfields.EndSection = EndSection;
Formfields.StartTable = StartTable;
Formfields.EndTable = EndTable;
Formfields.Header = Header;
Formfields.FreeText = FreeText;
Formfields.Numerical = Numerical;
Formfields.Website = Website;
Formfields.DropdownList = DropdownList;
Formfields.Checkbox = Checkbox;
Formfields.Email = Email;
Formfields.AddressField = AddressField;
Formfields.DatePicker = DatePicker;
Formfields.Image = Image;
Formfields.Hyperlink = Hyperlink;
Formfields.Document = Document;
Formfields.UserField = UserField;
Formfields.Calculation = Calculation;
Formfields.Camera = Camera;

export default Formfields;
