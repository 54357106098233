import DetailForm from '../../containers/enrichedForm/components/DetailForm';
import FieldProperties from '../../containers/enrichedForm/components/fieldComponents/index';
import ListElements from '../../containers/enrichedForm/components/ListElements';
import DetailEnForm from '../../containers/enrichedForm/DetailEnForm';
import ListTemplate from '../../containers/enrichedForm/ListTemplate';
import TemplateInstance from '../../containers/enrichedForm/subTable/ShelfAuditInstance';
import { Route } from '../Routes';

const EnrichedFormRoutes = (): Route[] => [
	{
		component: DetailForm,
		path: '/enform/detail',
		key: 'enform',
		title: 'Enform',
		name: 'assorment',
		exact: true
	},
	{
		component: DetailForm,
		path: '/enform/detail/assortment',
		key: 'enform',
		title: 'Enform',
		name: 'picturePolicy',
		exact: true
	},
	{
		component: ListElements,
		path: '/enform/detail/elements',
		key: 'elementsForm',
		title: 'Enform',
		name: 'Enform',
		exact: true
	},
	{
		component: FieldProperties,
		path: '/enform/detail/settings',
		key: 'elementsForm',
		title: 'Enform',
		name: 'Enform',
		exact: true
	},
	{
		component: ListTemplate,
		path: '/enform/list',
		key: 'shelfAudit',
		title: 'Shelf audit',
		name: 'Shelf audit',
		exact: true
	},
	{
		component: TemplateInstance,
		path: '/enform/list/instance/:id',
		key: 'shelfAudit',
		title: 'Shelf audit',
		name: 'Shelf audit',
		subPath: 'Shelf audit',
		exact: true
	},
	// {
	//   component: FormInstance,
	//   path: '/enform/instanceform/:id',
	//   key: 'shelfAudit',
	//   title: 'Shelf audit',
	//   name: 'Shelf audit',
	//   subPath: 'Shelf audit',
	//   exact: true
	// },
	// {
	//   component: FormInstance,
	//   path: '/enform/instanceform/:id/edit/:instaceId',
	//   key: 'shelfAudit',
	//   subPath: 'Shelf audit'
	// },
	// {
	//   component: FormInstance,
	//   path: '/enform/instanceform/:id/duplicate/:instaceId',
	//   key: 'shelfAudit',
	//   subPath: 'Shelf audit'
	// },
	{
		component: DetailEnForm,
		path: '/enform/detaildata/:instaceId',
		key: 'shelfAudit',
		title: 'Shelf audit',
		name: 'Shelf audit',
		exact: true
	}
];

export default EnrichedFormRoutes;
