import trash_black from 'images/ui_icon/trash_black.svg';
import edit from 'images/icon/edit.png';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import Avatar from '../../../../../components_v2/avatar/Avatar';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { UserToolTip } from '../../../../../styles/global/ToolTip';
import { LoadingStateEnum } from '../../../../import/model';
import PopupNote from '../../../../notes/popup/PopupNote';
import { ModalState } from '../../../../products/model';
import { CompanyNote } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import {
	Card,
	CardImage,
	CardLeft,
	CardMiddle,
	CardRight,
	CardText,
	CardTitle,
	Container,
	Edit,
	SeeMore,
	Trash
} from '../style/LinkedElementStyle';
import Restricted from '../../../../permissions/Restricted';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../../atoms/global/users';
import { LinkedElementId } from '../../../../../containers/companies/components/linkedElements/actions';
import { deleteLinkedElementNote, getLinkedElementNotes } from '../actions';

interface State extends ModalState<CompanyNote> {
  editmode?: boolean
}

interface DeletionState {
	isOpen: boolean
	note?: CompanyNote
}

function Note(props: {
  id: LinkedElementId
  isExtend?: boolean
  newCreation?: CompanyNote
  onDelete?: () => void
}): JSX.Element {
	const DEFAULT_LIMIT = 3;
	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [notes, setNotes] = React.useState<CompanyNote[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [offset, setOffset] = React.useState<number>(0);
	
	const [noteState, setNoteState] = React.useState<State>({ isOpen: false, editmode: false });
	const [deletionState, setDeletionState] = React.useState<DeletionState>({ isOpen: false });
	const owners = useRecoilValue(AUsers);

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getLinkedElementNotes({
			id: props.id,
			limit: DEFAULT_LIMIT,
			offset
		}).then(response => {
			setNotes(response.notes);
			setTotal(response.total > 0 ? response.total : total);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	React.useEffect(() => {
		if (props.newCreation != null) {
			setNotes([props.newCreation, ...notes]);
			setOffset(offset + 1);
		}
	}, [props.newCreation]);

	function deleteNote(note: CompanyNote | undefined): void {
		if (note === undefined) {
			return;
		}

		setNotes(notes.filter(n => n.note_id !== note.note_id));
		setOffset(offset - 1);
		setTotal(total - 1);
		props.onDelete?.();
	}

	function confirmationDeletionNote(note?: CompanyNote) {
		if (!note) {
			return;
		}

		deleteLinkedElementNote({
			linkedId: props.id,
			elementId: note.note_id
		}).then(() => deleteNote(note));
	}

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getLinkedElementNotes({
			id: props.id,
			limit: DEFAULT_LIMIT,
			offset: newOffset
		}).then(response => {
			setNotes(notes.concat(response.notes));
			setTotal(response.total);
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}
	const you = owners.find(o => o.isYou)?.id;

	return (<>
		<Container>
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: notes.length > 0
						? notes.map((n, i) => {
							const isSelf = n.user_id === you;
							return (
								(<Card isExtend={props.isExtend ?? false} onClick={() => setNoteState({ isOpen: true, data: n })} key={`NoteViewer[${i}]`}>
									<CardLeft>
										<UserToolTip userId={n.user_id} left='-35px'>
											{
												n.user_photo && n.user_photo.length > 0
													? <CardImage url={n.user_photo} />
													: <Avatar userId={n.user_id} width="35px" name={n.user_name.trim()} />
											}
										</UserToolTip>
									</CardLeft>
									<CardMiddle>
										<CardTitle nowrap>{n.note_title}</CardTitle>
										<CardText nowrap>{n.note_body}</CardText>
										<CardText>{translate('by_on').toString().replace('{{NAME}}', n.user_name.trim()).replace('{{DATE}}', moment.utc(n.note_created_at).local().format('LLL'))}</CardText>
									</CardMiddle>
									<CardRight alignItems='flex-start'>
										<Restricted to={{ objectAction: 'UpdateCompanyNote' }} isSelf={isSelf}>
											<Edit src={edit} onClick={(e) => {
												e.stopPropagation();
												setNoteState({ ...noteState, editmode: true, isOpen: true, data: n });
											}} />
										</Restricted>
										<Restricted to={{ objectAction: 'DeleteCompanyNote' }} isSelf={isSelf}>
											<Trash src={trash_black} onClick={(e) => {
												e.stopPropagation();
												setDeletionState({ ...deletionState, isOpen: true, note: n });
											}} />
										</Restricted>
									</CardRight>
								</Card>)
							);
						})
						: <NoDataText><Translate id='no_notes' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && notes.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
			<PopupDeletion 
				isOpen={deletionState.isOpen} 
				records={0} 
				translationKey=''
				customTitle={deletionState.note?.note_title ?? ''}
				customMessage={translate('global.fire.delete_note').toString()} 
				onClickOut={() => setDeletionState({ ...deletionState, isOpen: false })}
				onValidation={() => confirmationDeletionNote(deletionState.note)}
				noVerification
			/>
		</Container>
		<PopupNote
			id={props.id}
			isOpen={noteState.isOpen}
			note={noteState.data}
			onClickOut={() => setNoteState({ isOpen: false, editmode: false })}
			onDelete={(id) => deleteNote(notes.find(n => n.note_id === id))}
			onChange={(value) => setNotes(notes.map(n => n.note_id === value.note_id ? value : n))}
			editMode={noteState.editmode}
			permissions={{
				update: 'UpdateCompanyNote',
				delete: 'DeleteCompanyNote'
			}}
		/>
	</>);
}

export default Note;
