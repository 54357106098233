import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Input from '../../components_v2/input/Input';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { translateToString } from '../../styles/global/translate';
import { CreationPopupContainer, creationPopupInputStyle } from '../client-companies/style/Style';
import { Spacer } from '../contact/style/NewContactStyle';
import { Brand } from '../globals/Model';
import { LoadingStateEnum } from '../import/model';
import { postBrand, putBrand } from './action';
import { UpdatedField } from '../../../../web-types/api';

export default function NewBrand(props: {
	isOpen: boolean,
	setIsOpen: (b: boolean) => void,
	onCreate?: () => void,
	brand?: Brand
}) {

	const close = () => props.setIsOpen(false);

	return <Popup
		isOpen={props.isOpen}
		onClickOut={close}
		popupStyle={{ animate: true, height: '100%', top: '0%' }}
		popupMode={PopupMode.Details}
		content={<NewBrandContent
			onCreate={() => {
				close();
				props.onCreate?.();
			}}
			onClose={close}
			brand={props.brand}
		/>}
	/>;
}

function NewBrandContent(props: { onCreate?: () => void, onClose: () => void, brand?: Brand }) {
	const [name, setName] = React.useState<string | undefined>(props.brand?.name);
	const [description, setDescription] = React.useState<string | undefined>(props.brand?.description);
	const [loadingState, setLoading] = React.useState<LoadingStateEnum>();

	React.useEffect(() => {
		setName(props.brand?.name);
		setDescription(props.brand?.description);
	}, [props.brand]);

	const submit = React.useCallback(async(name: string, description: string | undefined, brand: Brand | undefined) => {
		setLoading(LoadingStateEnum.LOADING);
		if (brand?.id) {
			let updatedField: UpdatedField;
			if (description === brand.description) {
				updatedField = 'unmodified';
			} else if (description) {
				updatedField = { updated: description };
			} else {
				updatedField = 'deleted';
			}
			await putBrand(brand.id, { name, description: updatedField });
		} else {
			await postBrand({ name, description });
		}
		setLoading(LoadingStateEnum.LOADED);
		props.onCreate?.();
	}, []);

	return <PopupCreation
		title={translateToString(props.brand ? 'brand_modification' : 'create_brand')}
		canValidate={name !== undefined && name.length > 0 && (name !== props.brand?.name || description !== props.brand?.description)}
		onSubmit={() => name && submit(name, description, props.brand)}
		loading={loadingState}
		onClose={props.onClose}
		messageButton={props.brand
			? <Translate id='global.edit' />
			: <Translate id='global.create' />
		}
	>
		<CreationPopupContainer>
			<Input
				inputStyle={creationPopupInputStyle}
				name="name"
				type="text"
				placeholder={translateToString('name').toString()}
				label={translateToString('name').toString()}
				onChange={setName}
				required
				value={name}
			/>
			<Spacer />
			<Input
				textArea
				inputStyle={{ ...creationPopupInputStyle, height: '10em' }}
				name="description"
				type='text'
				placeholder={translateToString('description').toString()}
				label={translateToString('description').toString()}
				onChange={setDescription}
				value={description}
			/>
		</CreationPopupContainer>
	</PopupCreation>;
}