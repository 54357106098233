/* --- Reducer and his sub components types --- */
export interface reportingState {
  companiesStatus: CompanyStatus[]
  users: string[]
  filters: ReportsFilters
  cancelTokenSource
  isLoading: boolean
  selectedReport: reportKind
  products: string[]
  data?: any
}

export interface CompanyStatus {
  color_code: string
  name: string
  value: string
}

export interface ReportsFilters {
  PresencePerProductPerCompany: object
  MerchandisingPerCompany: object
  EventsPerCompany: object
  EventsPerUser: object
  CompanyStatusEvolution: string[]
  PresencePerUsers: string[]
  PresencePerProducts: string[]
  OrderPerProductPerCompany: object
  PresencePerBrandOrCategoryPerCompany: object
}

export type reportKind = string

const getDefaultReport = (): reportKind | undefined => {
	const kind = localStorage.getItem('reportKind') ?? '';
	return kind;
};

/* -------------- Action's type -------------- */
export interface reportingAction {
  type: ActionsName
  value: any
  data?: any
}

export type ActionsName =
  'SET_COMPANIES_STATUS'
  | 'SET_FILTERS'
  | 'SET_CANCEL_TOKEN_SOURCE'
  | 'SET_IS_LOADING'
  | 'SET_SELECTED_REPORT'
  | 'SET_USERS'
  | 'SET_PRODUCTS'

/* ----------------- Reducer ----------------- */
export function initState() {
	const ret: reportingState = {
		companiesStatus: [],
		filters: {
			PresencePerProductPerCompany: {},
			MerchandisingPerCompany: {},
			EventsPerCompany: {},
			EventsPerUser: {},
			CompanyStatusEvolution: [],
			PresencePerUsers: [],
			PresencePerProducts: [],
			OrderPerProductPerCompany: {},
			PresencePerBrandOrCategoryPerCompany: {}
		},
		cancelTokenSource: null,
		isLoading: false,
		selectedReport: getDefaultReport() ?? 'events_per_company',
		users: [],
		products: []
	};
	return ret;
}

export function reducer(state: reportingState, action: reportingAction): reportingState {
	switch (action.type) {
		case 'SET_COMPANIES_STATUS':
			return {
				...state,
				companiesStatus: action.value
			};
		case 'SET_FILTERS':
			return {
				...state,
				filters: action.value
			};
		case 'SET_CANCEL_TOKEN_SOURCE':
			return {
				...state
			// cancelTokenSource: action.value
			};
		case 'SET_IS_LOADING':
			return {
				...state,
				isLoading: action.value
			};
		case 'SET_SELECTED_REPORT':
			localStorage.setItem('reportKind', action.value);
			return {
				...state,
				selectedReport: action.value,
				data: action.data
			};
		case 'SET_USERS':
			return {
				...state,
				users: action.value
			};
		case 'SET_PRODUCTS':
			return {
				...state,
				products: action.value
			};
		default:
			return { ...state };
	}
}
