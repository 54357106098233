import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API_BUILDER } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const FETCH_FORMDATA_REQUEST = 'FETCH_FORMDATA_REQUEST';
export const FETCH_FORMDATA_SUCCESS = 'FETCH_FORMDATA_SUCCESS';
export const FETCH_FORMDATA_ERROR = 'FETCH_FORMDATA_ERROR';

export const DETAIL_FORMDATA_REQUEST = 'DETAIL_FORMDATA_REQUEST';
export const DETAIL_FORMDATA_SUCCESS = 'DETAIL_FORMDATA_SUCCESS';
export const DETAIL_FORMDATA_ERROR = 'DETAIL_FORMDATA_ERROR';

export const CREATE_FORMDATA_REQUEST = 'CREATE_FORMDATA_REQUEST';
export const CREATE_FORMDATA_SUCCESS = 'CREATE_FORMDATA_SUCCESS';
export const CREATE_FORMDATA_ERROR = 'CREATE_FORMDATA_ERROR';

export const UPDATE_FORMDATA_REQUEST = 'UPDATE_FORMDATA_REQUEST';
export const UPDATE_FORMDATA_SUCCESS = 'UPDATE_FORMDATA_SUCCESS';
export const UPDATE_FORMDATA_ERROR = 'UPDATE_FORMDATA_ERROR';

export const DELETE_FORMDATA_REQUEST = 'DELETE_FORMDATA_REQUEST';
export const DELETE_FORMDATA_SUCCESS = 'DELETE_FORMDATA_SUCCESS';
export const DELETE_FORMDATA_ERROR = 'DELETE_FORMDATA_ERROR';

export const FETCH_ICONS_REQUEST = 'FETCH_ICONS_REQUEST';
export const FETCH_ICONS_SUCCESS = 'FETCH_ICONS_SUCCESS';
export const FETCH_ICONS_ERROR = 'FETCH_ICONS_ERROR';

export const GET_LINKED_FORM_REQUEST = 'GET_LINKED_FORM_REQUEST';
export const GET_LINKED_FORM_SUCCESS = 'GET_LINKED_FORM_SUCCESS';
export const GET_LINKED_FORM_ERROR = 'GET_LINKED_FORM_ERROR';

export const GET_DETAIL_FORM_REQUEST = 'GET_DETAIL_FORM_REQUEST';
export const GET_DETAIL_FORM_SUCCESS = 'GET_DETAIL_FORM_SUCCESS';
export const GET_DETAIL_FORM_ERROR = 'GET_DETAIL_FORM_ERROR';

export const FIND_LINKED_FORM_REQUEST = 'FIND_LINKED_FORM_REQUEST';
export const FIND_LINKED_FORM_SUCCESS = 'FIND_LINKED_FORM_SUCCESS';
export const FIND_LINKED_FORM_ERROR = 'FIND_LINKED_FORM_ERROR';

export const GET_LINKED_ALL = 'GET_LINKED_ALL';
export const GET_COMPANY_LINKED = 'GET_COMPANY_LINKED';

const PATH = `${URL_API_BUILDER}/v1/api`;
const token = cookies.get('id_token') || null;

export function fetchData(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_FORMDATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/userfilledform`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_FORMDATA_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_FORMDATA_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_FORMDATA_ERROR,
					error
				});

				throw error;
			});
	};
}

export function fetchIcons(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_ICONS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/formicon`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_ICONS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_ICONS_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_ICONS_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_FORMDATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/userfilledform/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_FORMDATA_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_FORMDATA_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_FORMDATA_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createData(data) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_FORMDATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/userfilledform`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_FORMDATA_SUCCESS,
						payload: response.data
					});
					swal.fire('Success!', 'success created data', 'success').then(() => {
						window.location.href = `/formBuilder/formData/${response.data.data.user_form_id}/list`;
					});

					return response;
				},
				error => {
					dispatch({
						type: CREATE_FORMDATA_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						// dispatch(logoutUser());
					}
					throw error;
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_FORMDATA_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_FORMDATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/userfilledform/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_FORMDATA_SUCCESS,
					payload: response.data
				});
				swal.fire('Success!', 'Data updated', 'success').then(() => {
					window.location.href = `/formBuilder/formData/${response.data.data.user_form_id}/list`;
				});
			},
			error => {
				dispatch({
					type: UPDATE_FORMDATA_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteData(id, params) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_FORMDATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/userfilledform/id/${id}`
		}).then(
			() => {
				// dispatch({ type: DELETE_COMPANIES_SUCCESS, payload: response.data });
				swal.fire('Deleted!', 'Data has been deleted.', 'success').then(() => {
					// window.location.href = `/FORMDATA`;
				});
				dispatch(fetchData(params));
			},
			error => {
				// dispatch({ type: DELETE_COMPANIES_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function bulkDelete(params) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/userfilledform/bulk?${params}`,
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json'
				}
			});
			return true;
		} catch (error) {
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function getLinkedform(params) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: GET_LINKED_FORM_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/userfilledform/select/linkedvalueform`,
				params
			});
			dispatch({
				type: GET_LINKED_FORM_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function getDetailform(params) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: GET_DETAIL_FORM_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/userfilledform/select/all/linkedform`,
				params
			});
			dispatch({
				type: GET_DETAIL_FORM_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			swal.fire('Error!', error.response, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function getAllform(params) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/userfilledform/select/all/linkedvalueform`,
				params
			});
			dispatch({
				type: GET_LINKED_ALL,
				payload: response.data.data
			});
		} catch (error) {
			// swal.fire('Error!', error.response, 'error');
			//   if (error.response.status === 401) {
			//     dispatch(logoutUser());
			//   }
		}
	};
}

export function findLinkedform(params) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: FIND_LINKED_FORM_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/userfilledform/linked`,
				params
			});
			dispatch({
				type: FIND_LINKED_FORM_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			swal.fire('Error!', error.response, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function getLinkedId(linkedId) {
	return dispatch => {
		dispatch({ type: GET_COMPANY_LINKED, payload: linkedId });
	};
}

export function exportExcel(params, id, name) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/excel/userfilledform/${id}`,
			params
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${name} Forms.xlsx`);
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				// dispatch({ type: FETCH_COMPANIES_ERROR, error });

				throw error;
			});
}
