import { URL_ALEXANDRIA, URL_FOUNDATION } from '../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { Model } from './model';
import { CompanyStatus } from '../client-companies/model/Model';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function checkImport(table: string, checks: number[], model: Model, images, external_company: string | undefined, taxes: unknown[], checkout_company: string | undefined) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'post',
		url: `${PATH}/import/check`,
		data: {
			table,
			model,
			checks,
			images,
			external_company_id_column: external_company,
			taxes,
			checkout_company,
			create_company: false
		}
	});
}

export async function launchImport(table: string, model: Model, images, external_company: string | undefined, taxes: unknown[], checkout_company: string | undefined, createOnImport: boolean) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'post',
		url: `${PATH}/import/import`,
		data: {
			table,
			model,
			checks: [],
			images,
			external_company_id_column: external_company,
			taxes,
			checkout_company,
			create_company: createOnImport
		}
	});
}

export async function retrieveWork() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/import`
	});
}

export async function checkAsJob() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'post',
		url: `${PATH}/import`
	});
}

export async function cleanRedis() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'delete',
		url: `${PATH}/import`
	});
}

export async function exportTable(_: 'CSV' | 'PDF', status: 'Validated' | 'Error', model: Model) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'post',
		url: `${PATH}/import/export`,
		data: {
			status,
			model
		}
	});
}

export async function getDivisions() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_ALEXANDRIA}/api/companies/divisions`
	});
}

export async function getAlexandriaTags() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_ALEXANDRIA}/api/tags?external=false`
	});
}

export async function getAlexandriaCompanies(country: string, div_1: string, div_2: string, city: string, tags: string) {
	axios.defaults.headers.common.Authorization = `${token}`;
	const query =
        (tags.length ? 'tags=' + tags + '&' : '') +
        (country?.trim().length ? 'country=' + country + '&' : '') +
        (div_1?.trim().length ? 'division_1=' + div_1 + '&' : '') +
        (div_2?.trim().length ? 'division_2=' + div_2 + '&' : '') +
        (city?.trim().length ? 'city=' + city + '&' : '');
	return await axios({
		method: 'get',
		url: `${URL_ALEXANDRIA}/api/import?${query}`
	});
}

export async function getUsers() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/users`
	});
}

export async function getClientStatuses(): Promise<{ data: CompanyStatus[] }> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.get<CompanyStatus[]>(`${PATH}/crm/client-company-statuses`).then(res => ({ data: res.data.sort((a, b) => a.sequence - b.sequence) }));
}

export async function setRedisImage(data: object[]) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'post',
		url: `${PATH}/import/images`,
		data
	});
}
