import * as React from 'react';
import { getPin, Styles, Table } from './Table';
import { useWindowDimensions } from '../../components_v2/utils';
import { checkImport, exportTable, launchImport } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getTranslate, Translate } from 'react-localize-redux';
import styled from 'styled-components';
import storeLang from '../../helpers/storeLang';
import { CaseType, ImportColumns, LoadingState, MatchedRowType, Model, Panel, RowType, SidelyRowStatus, TransformationType } from './model';
import CheckboxGroupFilter from '../../containers/companies/components/CheckboxGroupFilter';
import { toast } from 'react-toastify';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { ToolbarImage } from '../globals/defaultToolbar/style/Style';
import PopupExport from './popupExport';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { BlueSidely } from '../../styles/global/css/Utils';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { translateToNode, translateToString } from '../../styles/global/translate';
import useAlert from '../alert/UseAlert';
import { AlertRes } from '../alert/AlertProvider';
import { ButtonStyle } from '../../components_v2/popup/PopupCreation';
import { Checkbox } from '../../components_v2/filterList/style/Style';
import * as moment from 'moment';
import optionGrey from 'images/icon/options_grey.png';

const AlertTable = styled.table`
	text-align: left;
	font-size: 14px;
	tr {
		td {
			padding-left: 15px;
		}
	}
`;

const FiltersStyles = styled.div`
    .importPin {
        display: inline-block;
        width: 15px;
        margin-right: 10px;
    }

    .filterText {
        display: inline-block;
        font-size: 12px;
        margin: 0;
    }

    .filterButton {
        color: ${BlueSidely};
        display: inline-block;
        margin: 0;
        cursor: pointer;
    }

    .closeFilterButton {
        background-color: #C4C4C4;
        display: inline-block;
        margin: 0;
        cursor: pointer;
        width: 10px;
        height: 10px;
        position: relative;
        top: 3px;
        left: 2px;
    }

    .crossCloseFilterButton {
        top: -4px;
        position: relative;
        left: 3px;
        font-size: 8px;
    }
	margin: 0 1em;
	display: flex;
	flex-flow: column;
`;

const VerticalSeparator = styled.div`
    float: right;
    position: relative;
    border-left: 0.5px solid #e3e3e3;
    height: 100%;
`;

const enseigne_data = [
	{ label: 'Casino', value: 'Casino' },
	{ label: 'Carrefour', value: 'Carrefour' },
	{ label: 'Auchan', value: 'Auchan' },
	{ label: 'Monoprix', value: 'Monoprix' },
	{ label: 'Intermarché', value: 'Intermarché' },
	{ label: 'Système U', value: 'Système' },
	{ label: 'Franprix', value: 'Franprix' },
	{ label: 'Cora', value: 'Cora' },
	{ label: 'Delhaize', value: 'Delhaize' },
	{ label: 'Metro', value: 'Metro' },
	{ label: 'Lecasud', value: 'Lecasud' },
	{ label: 'Scapest', value: 'Scapest' },	
];

function createCsv(columns: MatchedRowType, data: { [key: string]: string | number }[], include_headers?: boolean): string {
	let headers = 'LINE\t';
	for (const value of Object.values(columns)) {
		if (value == null) { continue; }
		headers += value.value + '\t';
	}
	headers = headers.slice(0, -1);
	if (include_headers) {
		headers += '\n-1\t';
		for (const [key, value] of Object.entries(columns)) {
			if (value == null) { continue; }
			headers += key + '\t';
		}
		headers = headers.slice(0, -1);
	}
	const rows = data.reduce((acc, d) => {
		acc += '\n' + d['SidelyRow'] + '\t';
		for (const key of Object.keys(columns)) {
			const value = d[key];
			if (value === undefined || value === null) acc += '\t';
			else if (typeof value === 'string') acc += value.replace(/[\t\n\r]/gm,'') + '\t';
			else acc += value.toString() + '\t';
		}
		return (acc as string).slice(0, -1);
	}, '');
	return headers + rows;
}

function getRowStatusName(status: SidelyRowStatus) {
	const translate = getTranslate(storeLang.getState().localize);
	switch (status) {
		case 0: return translate('import.validated');
		case 1: return translate('import.warning');
		case 2: return translate('import.duplicates');
		case 3: return translate('waiting');
		case 4: return translate('import.edited');
		case 5: return translate('import.error');
	}
}

export function fctFromStr(key: string) {
	switch (key.toLowerCase()) {
		case '0':
		case 'validated': return (e) => e == SidelyRowStatus.Validated;
		case '1':
		case 'warning': return (e) => e == SidelyRowStatus.Warning;
		case '2':
		case 'duplicates': return (e) => e == SidelyRowStatus.Duplicates;
		case '3':
		case 'adminwaiting': return e => e === SidelyRowStatus.AdminWaiting;
		case '4':
		case 'non_verified_rows':
		case 'edited': return (e) => e == SidelyRowStatus.Edited;
		case '5':
		case 'error': return (e) => e == SidelyRowStatus.Error;
		case 'validated_rows': return (e) => e == SidelyRowStatus.Warning || e == SidelyRowStatus.Validated;
	}
}

function filterData(data: RowType[], filters: object) {
	let ret = data;
	for (const [key, value] of Object.entries(filters)) {
		if (!value) {
			continue;
		} else if (key == 'validated_rows' || key == 'non_verified_rows') {
			ret = ret.filter(row => fctFromStr(key)?.(row.SidelyRow.status));
		} else {
			ret = ret.filter(row => {
				for (const [_, value] of Object.entries(row)) {
					if (fctFromStr(key)?.(value.status)) { return true; }
				}
				return false;
			});
		}
	}
	return ret;
}

function transformCaseType(value: CaseType): string | number {
	switch (value.type) {
		case TransformationType.CompanyId:
		case TransformationType.ProductId:
		case undefined: return value.value;
		case TransformationType.Date: return moment(value.value, 'L - LT').utc().unix();
	}
}

function valueOf(data: RowType[], transform?: boolean) {
	return data.map(e => {
		const ret = {};
		for (const [key, value] of Object.entries(e)) {
			ret[key] = transform ? transformCaseType(value) : value.value;
		}
		return ret;
	});
}

function getStatusNb(check_fct: (status: SidelyRowStatus) => boolean, data: RowType[]) {
	return data.reduce((acc, row) => {
		let count = 0;
		for (const [key, value] of Object.entries(row)) {
			if (check_fct(value.status) && key !== 'SidelyRow') {
				count += 1;
			}
		}
		return acc + count;
	}, 0);
}

function getRowStatusNb(check_fct: (status: SidelyRowStatus) => boolean, data: RowType[]) {
	return data.filter(row => check_fct(row.SidelyRow.status)).length;
}

export default function TableView(props: {
  columns: ImportColumns[]
  data: RowType[]
  setData: (data: RowType[]) => void
  loadingState: LoadingState
  setLoadingState: (loadingState: LoadingState) => void
  matchedList: MatchedRowType
  setPanel: (panel: Panel) => void
  images
  setToolBarState: (value: ToolbarState) => void
}) {
	//@ts-expect-error it works
	const selector = useSelector(state => state.webSocket);
	const [filters, setFilters] = React.useState({});
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const { height } = useWindowDimensions();
	const dispatch = useDispatch();
	const translate = getTranslate(storeLang.getState().localize);
	const [exportIsOpen, setExportOpen] = React.useState<boolean>(false);
	const alert = useAlert();
	const [exportOnImport, setExportOnImport] = React.useState(false);
	const [createOnImport, setCreateOnImport] = React.useState<boolean>(true);
	const [company, setCompany] = React.useState<string>();

	const { columns, data, setData, loadingState, setLoadingState, matchedList, setPanel, images } = props;
	const model = Object.values(matchedList).find(e => e?.model !== undefined)?.model;

	function onDataEdit(index: number, key: string, value: string) {
		const row_index: number = data.findIndex(row => row.SidelyRow.value === index);
		if (row_index >= 0 && data[row_index][key].value !== value) {
			data[row_index][key].value = value;
			data[row_index][key].status = SidelyRowStatus.Edited;
			data[row_index].SidelyRow.status = SidelyRowStatus.Edited;
			setData([...data]);
		}
	}

	function changeFilter(type: SidelyRowStatus | string) {
		filters[type] = !filters[type];
		setFilters({ ...filters });
	}

	function LinesFilters(type: SidelyRowStatus | string, errorNb: number) {
		if (!errorNb && filters[type]) {
			changeFilter(type);
			return;
		} else if (!errorNb) {
			return;
		}
		const status = filters[type];
		return (
			<p className="filterText" key={`LinesFilter[${type}]`}>
				{getPin(type)} {errorNb} { }
				{typeof (type) === 'string'
					? <>
						<Translate id={'import.' + type} /> | { }
					</>
					: <>
						{getRowStatusName(type)} | { }
					</>
				}
				<p className={`${status ? 'filterText' : 'filterButton'}`} onClick={status ? undefined : () => changeFilter(type)}>
					{translateToNode(`filter${status ? 'ed' : ''}`)}
				</p>
				{status && <div className='rounded-circle closeFilterButton' onClick={() => changeFilter(type)}><p className='crossCloseFilterButton'>x</p></div>}
			</p>
		);
	}

	function getCheckedCheckBox(): number[] {
		if (data.filter(e => e.SidelyRow.status == SidelyRowStatus.Edited).length == 0) {
			return [0, 1, 2];
		} else {
			return [0, 1];
		}
	}

	function getcheckBoxOption(): object[] {
		const lst: DropdownData[] = [];

		if (model === Model.Company) {
			lst.push({ label: 'Formating', value: 0 });
			lst.push({ label: translateToString('import.geographic_enrichissement'), value: 1 });
		}
		lst.push({ label: translateToString('import.check_row_error'), value: 2 });
		return lst;
	}

	function ConfirmModal(props: {
    toggleModal: () => void
  }) {
		const dispatch = useDispatch();
		const translate = getTranslate(storeLang.getState().localize);
		const [statusCheckBox, setStatusCheckBox] = React.useState<number[]>(getCheckedCheckBox());
		const checkBoxOption = getcheckBoxOption();
		const { toggleModal } = props;

		function handleCheckedStatus(isChecked: boolean, value: number) {
			if (isChecked) {
				statusCheckBox.push(value);
			} else {
				const index = statusCheckBox.indexOf(value);
				if (index >= 0) { statusCheckBox.splice(index, 1); }
			}
			setStatusCheckBox([...statusCheckBox]);
		}
		return (
			<Modal
				isOpen={isModalOpen}
				toggle={toggleModal}
				style={{ width: '450px' }}
				centered
			>
				<ModalHeader>
					<Translate id="import.what_do_you_want_to_do" />
				</ModalHeader>
				<ModalBody style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					flexFlow: 'column'
				}} >
					<CheckboxGroupFilter
						options={checkBoxOption}
						valChecked={statusCheckBox}
						input={{ value: statusCheckBox, onChange: () => null }}
						checkedAll={
							statusCheckBox && statusCheckBox.length === checkBoxOption.length
						}
						handleCheckedStatus={handleCheckedStatus}
						placeholder='Search status'
						type='status'
						meta={{ touched: false, error: 'Error fetching status data' }}
						disableLine
						disableFistCheckBox
					/>
					{model === Model.Checkout && <Dropdown
						label='Enseigne'
						name={'company-selector'}
						selectedValue={company ? { label: company, value: company } : undefined}
						datalist={enseigne_data}
						dropdownStyle={{ labelUp: true, optionWidth: '200px' }}
						onChange={v => setCompany(v.value)}
					/>}
				</ModalBody>
				<ModalFooter style={{ borderTop: 'none' }}>
					<Button
						disabled={model === Model.Checkout && !company}
						style={{
							height: '42px',
							background: BlueSidely,
							color: '#FFFFFF',
							borderRadius: '4px'
						}}
						onClick={() => {
							if (!selector.connected || model == undefined) {
								toast.error(translate('import.can_t_reach_server') as string);
								return;
							} else if (!statusCheckBox.includes(2) && data.filter(e => e.SidelyRow.status == SidelyRowStatus.Edited).length == 0) {
								toggleModal();
								return;
							}
							setLoadingState('loading');
							dispatch({ type: 'SHOW_PROGRESS_BAR', value: { value: true } });
							toggleModal();
							const csv = createCsv(matchedList, valueOf(data.filter(e => e.SidelyRow.status == SidelyRowStatus.Edited || (e.SidelyRow.status == SidelyRowStatus.Error && statusCheckBox.includes(2))), true), true);
							checkImport(
								csv,
								statusCheckBox,
								model,
								images,
								Object.values(matchedList).find(row => row?.secondMatchingValue == 'external_company')?.value,
								Object.values(matchedList).filter(row => row?.secondMatchingValue == 'tax').map(r => r?.additionalValue).filter(t => t),
								company
							);
						}}
					>
						{translate('tool_bar.dots.validate')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	const filteredData = filterData(data, filters);
	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <>
				<FiltersStyles>
					{LinesFilters(SidelyRowStatus.Error, getStatusNb((e) => e == SidelyRowStatus.Error, data))}
					{LinesFilters(SidelyRowStatus.Warning, getStatusNb((e) => e == SidelyRowStatus.Warning, data))}
					{LinesFilters(SidelyRowStatus.Duplicates, getStatusNb((e) => e == SidelyRowStatus.Duplicates, data))}
					{LinesFilters(SidelyRowStatus.AdminWaiting, getStatusNb((e) => e == SidelyRowStatus.AdminWaiting, data))}
				</FiltersStyles>
				<FiltersStyles>
					{LinesFilters('validated_rows', getRowStatusNb((e) => e <= 1, data))}
					{LinesFilters('non_verified_rows', getRowStatusNb((e) => e == SidelyRowStatus.Edited, data))}
				</FiltersStyles>
			</>,
			bottomRightToolbarComponent: <>
				<p className='mr-3'
					style={{
						top: '50%',
						margin: 0,
						fontSize: '15px'
					}}>
					{(filteredData.length == data.length ? data.length : (filteredData.length + ' / ' + data.length)) + '   '}
					<Translate id='rows' />
				</p>
				<Button
					style={{
						height: buttonHeight,
						background: '#FFFFFF',
						border: `2px solid ${BlueSidely}`,
						color: BlueSidely,
						borderRadius: '4px',
						margin: '0 1em',
						width: '12em'
					}}
					onClick={() => setPanel(Panel.UploadView)}
				>
					<Translate id='global.cancel' />
				</Button>
				<VerticalSeparator />
				<Button
					style={{
						height: buttonHeight,
						background: '#FFFFFF',
						border: `2px solid ${BlueSidely}`,
						color: BlueSidely,
						borderRadius: '4px',
						margin: '0 1em',
						width: '12em'
					}}
					disabled={loadingState == 'loading' || loadingState == 'loadingImport' || (columns.length === 0) || !data.some(e => e.SidelyRow.status == SidelyRowStatus.Edited || e.SidelyRow.status == SidelyRowStatus.Error)}
					onClick={() => setIsModalOpen(true)}
				>
					<Translate id='import.check' />
				</Button>
				<Button
					style={{
						height: buttonHeight,
						background: BlueSidely,
						color: '#FFFFFF',
						borderRadius: '4px',
						margin: '0 1em',
						width: '12em'
					}}
					disabled={loadingState == 'loading' || loadingState == 'loadingImport' || (columns.length === 0) || !data.some(e => e.SidelyRow.status < SidelyRowStatus.Edited)}
					onClick={async() => {
						let localExportOnImport = exportOnImport;
						let localCompany = company;
						let localCreateOnImport = createOnImport;
						if (!selector.connected) {
							toast.error(translate('import.can_t_reach_server') as string);
							return;
						}
						const model = Object.values(matchedList).find(e => e?.model !== undefined)?.model;
						if (model === undefined) {
							return;
						}
						const entry = Object.entries(matchedList).find(e => e[1]?.value === editPerModel(model))?.[0];
						const Content = (props: {exportOnImport: boolean, setExportOnImport: (b: boolean) => void, company: string | undefined, setCompany: (s: string) => void, createOnImport: boolean, setCreateOnImport: (b: boolean) => void}) => {
							const [exportOnImport, setExportOnImport] = React.useState<boolean>(props.exportOnImport);
							const [createOnImport, setCreateOnImport] = React.useState<boolean>(props.createOnImport);
							const [company, setCompany] = React.useState<string | undefined>(props.company);
							const waitingLength = data.filter(e => e.SidelyRow.status < SidelyRowStatus.AdminWaiting && entry && e[entry].value).length;
							return <>
								<AlertTable>
									<tr>
										<td>{data.filter(e => e.SidelyRow.status <= SidelyRowStatus.AdminWaiting && ((entry && !e[entry].value) || !entry)).length}</td>
										<td>{translateToNode('import.importations')}</td>
									</tr>
									<tr>
										<td>{data.filter(e => e.SidelyRow.status <= SidelyRowStatus.AdminWaiting && entry && e[entry].value).length}</td>
										<td>{translateToNode('import.editions')}</td>
									</tr>
									{waitingLength !== 0 &&
										<tr>
											<td>{waitingLength}</td>
											<td>{translateToNode('waiting')}</td>
										</tr>
									}
									<tr>
										<td>{data.filter(e => e.SidelyRow.status >= SidelyRowStatus.Edited).length}</td>
										<td>{translateToNode('import.ignored')}</td>
									</tr>
									<tr>
										<td>
											<Checkbox
												isActive={exportOnImport}
												onClick={() => {
													setExportOnImport(!exportOnImport);
													props.setExportOnImport(!exportOnImport);
													localExportOnImport = !exportOnImport;
												}}
											/>
										</td>
										<td>{translateToNode('export_unimported_rows')}</td>
									</tr>
									{model === Model.Checkout && 
									<tr>
										<td>
											<Checkbox
												isActive={createOnImport}
												onClick={() => {
													setCreateOnImport(!createOnImport);
													props.setCreateOnImport(!createOnImport);
													localCreateOnImport = !createOnImport;
												}}
											/>
										</td>
										<td>{translateToNode('create_missing_companies')}</td>
									</tr>}
								</AlertTable>
								{model === Model.Checkout && <Dropdown
									label='Enseigne'
									name={'company-selector'}
									selectedValue={company ? { label: company, value: company } : undefined}
									datalist={enseigne_data}
									dropdownStyle={{ labelUp: true, optionWidth: '200px' }}
									onChange={v => {
										setCompany(v.value);
										props.setCompany(v.value);
										localCompany = v.value;
									}}
								/>}
							</>;
						};
						const res = await alert({
							title: translateToNode('import.are_you_sure'),
							content: <Content
								exportOnImport={exportOnImport}
								setExportOnImport={setExportOnImport}
								setCompany={setCompany}
								company={company}
								createOnImport={createOnImport}
								setCreateOnImport={setCreateOnImport}
							/>,
							buttons: [
								{
									title: translateToNode('back'),
									res: AlertRes.Break,
									style: ButtonStyle.White

								},
								{
									canValidate: model !== Model.Checkout || localCompany !== undefined,
									title: translateToNode('import.import'),
									res: AlertRes.Ok
								}
							]
						});
						if (res === AlertRes.Break) return;
						if (!selector.connected || model == undefined) {
							toast.error(translate('import.can_t_reach_server') as string);
							return;
						}
						setLoadingState('loadingImport');
						dispatch({ type: 'SHOW_PROGRESS_BAR', value: { value: true } });
						const csv = createCsv(matchedList, valueOf(data.filter(e => e.SidelyRow.status < SidelyRowStatus.Edited), true), true);
						launchImport(
							csv,
							model,
							images,
							Object.values(matchedList).find(row => row?.secondMatchingValue == 'external_company')?.value,
							Object.values(matchedList).filter(row => row?.secondMatchingValue == 'tax').map(r => r?.additionalValue).filter(t => t),
							localCompany,
							localCreateOnImport
						);
						if (localExportOnImport) {
							exportTable('CSV', 'Error', model);
						}
					}}
				>
					<Translate id='next' />
				</Button>
				<Dropdown dropdownStyle={{ optionLeft: '-115px', optionWidth: '160px' }} datalist={[{ label: 'export', value: 'export' }]} name={'options'}
					JSXButton={() => <ToolbarImage marginLeft='10px' hasPointer src={optionGrey} />}
					onChange={(value: DropdownData) => {
						switch (value.value) {
							case 'export':
								setExportOpen(true);
								break;
							default:
								break;
						}
					}}
				/>
			</>
		});
	}, [loadingState, columns, data,matchedList, selector.connected,images, filteredData, company]);

	const table = React.useMemo(() => <Table
		height={height - 160}
		columns={columns}
		data={filteredData}
		displayableData={valueOf(filteredData)}
		editable
		updateMyData={onDataEdit}
	/>, [columns, data, height, filters]);
	const buttonHeight = 42;
	const view = React.useMemo(() => {
		return (
			<>
				<div style={{ margin: '0px 0px 0px 3px', height: height - 130 }}>
					<Styles>
						{table}
					</Styles>
				</div>
				<ConfirmModal
					toggleModal={() => setIsModalOpen(!isModalOpen)}
				/>
				<PopupExport status exportCSV isOpen={exportIsOpen} onClickOut={() => setExportOpen(false)} onClickExport={(value) => {
					setExportOpen(false);
					if (value == null) {
						return;
					}
					const model = Object.values(matchedList).find(e => e?.model !== undefined)?.model;
					if (!model) return;
					exportTable(value[0].value, value[1].value, model);
				}} />
			</>);
	}, [table, loadingState, filters, isModalOpen, exportIsOpen, company]
	);
	return (
		view
	);
}

function editPerModel(model: number | Model | undefined): string {
	switch (model) {
		case 0:
		case Model.Company:
			return 'company_id';
		case 1:
		case Model.Contact:
			return 'contact_id';
		case 2:
		case 3:
			return '';
		case 4:
		case Model.Product:
			return 'product_id';
		case 5:
		case Model.Order:
			return 'order_id';
		case Model.Assortment:
			return 'assortment_id';
		case Model.Event:
			return 'event_id';
		default:
			return '';
	}
}
