import { useContext } from 'react';
import PermissionContext from './PermissionContext';
import { ObjectAction, Scope } from '../../../../web-types/objectActions';

const getScope = (objectAction: ObjectAction | undefined): Scope | undefined => {
	const { getPermissions } = useContext(PermissionContext);

	if (!objectAction) return undefined;

	return getPermissions()?.[objectAction];
};

export default getScope;
