import styled from 'styled-components';
import { DropdownStyle } from '../../../components_v2/dropdown/model/Model';
import { InputStyle } from '../../../components_v2/input/model/Model';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { SidelyBlack } from '../../../styles/global/css/Utils';
import { HideTrash } from '../../products/style';

export const DROPDOWN_STYLE_SETTINGS: DropdownStyle = {
	containerWidth: '350px',
	optionWidth: '100%',
	labelUp: true,
	optionHeight: '150px',
	height: '45px',
	labelStyle: {
		fontSize: '14px',
		fontWeight: '500',
		padding: '0 0 15px 0',
		color: SidelyBlack,
	},
	width: '100%'
};

export const INPUT_STYLE_SETTINGS: InputStyle = {
	width: '100%',
	containerWidth: '350px',
	height: '45px',
	labelUp: true,
	padding: '.56rem 0.75rem',
	borderRadius: '5px'
};

export const SystemSettingsWrapper = styled.div`
	${DefaultText}
	display: flex;
	flex-flow: column;
	overflow: scroll;
	gap: 1rem;
	height: calc(100% - 2rem);
	margin: 2rem;
	padding-bottom: 2rem;

	p {
		margin: 0;
	}
	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}
	h4 {
		margin-top: 1em;
	}
`;

export const SystemSettingsDropdownWrapper = styled.div<{ relative?: boolean }>`
	${HideTrash}
	display: flex;
	flex-flow: column;
	gap: 0.5rem;
	${p => p.relative ? 'position: relative; width: fit-content;' : ''}
`;

export const SystemSettingsCheckBoxWrapper = styled.div`
	display: flex;
	flex-flow: row;
	gap: 0.5rem;
	align-items: center;
`;

export const SystemSettingTitle = styled.h5`
	position: sticky;
	top: 0;
	background: white;
	z-index: 2;
`;