import deleteImage from 'images/icon/delete.png';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import swal from 'sweetalert2';
import { AUsers } from '../../../atoms/global/users';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import InputFile from '../../../components_v2/input/InputFile';
import { InputErrorMode } from '../../../components_v2/input/InputImage';
import { ImageResult } from '../../../components_v2/input/model/Model';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import storeLang from '../../../helpers/storeLang';
import { GreenSidely, RedSidely } from '../../../styles/global/css/Utils';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany } from '../../orders/model/Model';
import { postDocuments } from '../data/action';
import { Company, CompanyDocument, PostDocumentParams } from '../model/Model';
import { Delete, FileContainer, FileName, QuotaText } from '../style/NewPhotoStyle';
import { CreationPopupContainer } from '../style/Style';
import { LinkedElementId } from '../../../containers/companies/components/linkedElements/actions';

function NewDocument(props: {
  onClickOut?: () => void
  onCreate?: (values: CompanyDocument[]) => void
  id?: LinkedElementId
  maxDocument?: number
}): JSX.Element {
	const LIMIT = 3;
	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [files, setFiles] = React.useState<ImageResult[]>([]);

	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);
	const [company, setCompany] = React.useState<Company>();

	const users = useRecoilValue(AUsers);

	function submit(): void {
		if ((company !== undefined || props.id !== undefined) && files.length > 0) {
			const params = {
				id: props.id ?? { Company: company?.company_id ?? -1 },
				documents: files.map(file => {
					return {
						name: file.file.name,
						file: file.content
					};
				})
			} as PostDocumentParams;

			setLoading(LoadingStateEnum.LOADING);
			postDocuments(params).then(response => {
				if (response) {
					const user = users.find(u => u.isYou);
					const nDocuments = response.map((v, i) => ({
						id: props.id ? props.id : company ? { Company: company.company_id } : -1,
						document_count: 1,
						document_created_at: moment.utc().toLocaleString(),
						document_id: v,
						user_name: user?.name,
						user_photo: user?.photoUrl,
						document_name: files[i].file.name,
						document_url: files[i].url
					} as CompanyDocument));
					props.onCreate?.(nDocuments);
					setLoading(LoadingStateEnum.LOADED);
				}
			});
		}
	}

	function errorAlert(message: string): void {
		swal({
			title: translate('global.fire.alert_title').toString(),
			text: message,
			type: 'warning',
			confirmButtonText: translate('global.fire.confirm_message').toString(),
			confirmButtonColor: RedSidely
		});
	}

	function body(): JSX.Element {
		return (
			(<CreationPopupContainer style={{ gap: '10px' }}>
				{
					!props.id &&
					<>
						<DropdownSearch
							datalist={optionCompanies}
							name="clientCompanyName"
							label={translate('company.creation.document.company').toString()}
							optionHeight={200}
							width={250}
							labelUp
							required
							link={'/companies?id='}
							onChange={(value) => setCompany(value?.value)}
							selectedValue={optionCompanies.find(comp => JSON.stringify(comp.value) == JSON.stringify(optionCompanies))}
							onSearchChange={async(search, offset) => {
								return await getFilteredCompanies(search, 20, offset)
									.then(res => {
										const ret: boolean = res.data.length == 0 || res.data.length < 20;
										if (offset !== undefined) {
											setOptionCompanies([...optionCompanies, ...res.data.map((company: ClientCompany) => {
												return {
													label: company.name,
													value: company
												};
											})]);
										} else {
											setOptionCompanies(res.data.map(company => {
												return {
													label: company.name,
													value: company
												};
											}));
										}
										return ret;
									})
									.catch(error => {
										console.log(error);
										return false;
									});
							}}
						/>
					</>
				}
				{
					files.map(file => {
						return (<>
							<FileContainer>
								<FileName>{file.file.name}</FileName>
								<Delete src={deleteImage} alt="delete" onClick={() => setFiles(files.filter(f => f.url !== file.url))} />
							</FileContainer>
						</>);
					})
				}
				{
					files.length < (props.maxDocument ?? LIMIT)
						? <InputFile
							size={25}
							backgroundColor={GreenSidely}
							limitMo={4}
							onChange={(value) => setFiles(files.concat(value))}
							onError={(value) => value.type === InputErrorMode.LIMIT_REACHED && errorAlert(value.message)}

						/>
						: <QuotaText>{translate('company.creation.document.quota_reached').toString()}</QuotaText>
				}
			</CreationPopupContainer>)
		);
	}

	return (
		<PopupCreation
			title={translate('company.creation.document.add_document').toString()}
			content={body()}
			canValidate={(company !== undefined || props.id !== undefined) && files.length > 0}
			onSubmit={() => submit()}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
			messageButton={translate('company.creation.to_add').toString()}
		/>
	);
}

export default NewDocument;
