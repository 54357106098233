import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { DisabledColor } from '../../../styles/global/css/Utils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;

const Spacer = styled.div`
    width: 100%;
    margin: 5% 0 5% 0;
`;

const TextAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 6px;
    height: 150px;
    width: 100%;
    min-width: 250px;
    font-size: 13px;
    padding: 10px;
    &:disabled {
        background-color: ${DisabledColor};
        border-color: ${DisabledColor};
    }
`;

const TextAreaLabel = styled.p`
    ${DefaultText}
`;

const SpanAreaLabel = styled.span`
    ${DefaultText}
    color: red;
`;

const HorizontalContainer = styled.div<{ gap?: string }>`
    display: flex;
    align-items: center;
    ${p => p.gap ? `gap ${p.gap};` : ''}
`;

const SwitchText = styled.p`
    ${DefaultText}
    margin-right: 5px;
`;

export {
	Container,
	Spacer,
	TextAreaContainer,
	TextArea,
	TextAreaLabel,
	HorizontalContainer,
	SwitchText,
	SpanAreaLabel
};
