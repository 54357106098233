const convertType = data => {
	switch (data.field_type) {
		case 'section':
			if (data.is_start_section) {
				return 'StartSection';
			}
			if (data.is_end_section) {
				return 'EndSection';
			}
			return '';

			// case 'section':
			//   return 'EndSection';

			// case 'StartTable':
			//   return 'table';

			// case 'EndTable':
			//   return 'table';

		case 'header':
			return 'Header';

		case 'table':
			return 'Table';

		case 'image':
			return 'Image';

		case 'free_text':
			return 'FreeText';

		case 'numerical':
			return 'Numerical';

		case 'dropdown':
			return 'DropdownList';

		case 'checkbox':
			return 'Checkbox';

		case 'email':
			return 'Email';

		case 'address_field':
			return 'AddressField';

		case 'document':
			return 'Document';

		case 'user':
			return 'UserField';

		case 'hyperlink':
			return 'Hyperlink';

		case 'calculation':
			return 'Calculation';

		case 'camera':
			return 'Camera';

		case 'date':
			return 'DatePicker';

		default:
			return '';
	}
};

const fieldTypeGetter = item => {
	const elementOptions = {
		element: convertType(item),
		text: item.name,
		icon: item.icon,
		img: item.img,
		static: item.static,
		required: false,
		content: item.content,
		href: item.href,
		showDescription: item.showDescription,
		...item
	};

	return elementOptions;
};

const processApiToBuilder = apiData => {
	const dataGet = apiData.reduce((acc, data) => {
		acc.push(fieldTypeGetter(data));
		return acc;
	}, []);

	const dataRemoveNull = dataGet.reduce((acc, data) => {
		if (data.element) {
			acc.push(data);
		}
		return acc;
	}, []);

	return dataRemoveNull;
};

const fieldSectionGetter = fieldType => {
	switch (fieldType) {
		case 'StartSection':
			return true;

		case 'EndSection':
			return false;

		case 'StartTable':
			return true;

		case 'EndTable':
			return false;

		default:
			return null;
	}
};

const fieldName = (data, dynamicField) => {
	const name = data.id ? data.id : data.system_field_name;

	let fieldname = data.id;

	if (!data.id) {
		fieldname = `${dynamicField}.${name}`;
	}

	return fieldname;
};

export { fieldSectionGetter, fieldTypeGetter, processApiToBuilder, fieldName };
