import { useContext } from 'react';
import PermissionContext from './PermissionContext';

const getPermissions = () => {
	const { getPermissions } = useContext(PermissionContext);

	return getPermissions();
};

export default getPermissions;
