import axios from 'axios';
import { Cookies } from 'react-cookie';
import { atom, selector } from 'recoil';
import { MappingResume } from '../../../../web-types/mapping';
import { URL_FOUNDATION } from '../../config/keys';
import { AtomCategory, AtomState } from '../utils/model/Model';

// -----------------------[ ATOM ]----------------------- //
export type AtomSelectedExternalMappingSelector = {
	company?: number
};

export const AGlobalExternalMappings: AtomState<MappingResume[]> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_external_mapping_id', // UNIQUE ID
		default: getExternalMappings()
	})

};

export const AExternalMappings = selector({
	key: 'AExternalMappings',
	get: async({ get }) => get(AGlobalExternalMappings.atom),
	set: ({ set }, newValue) => set(AGlobalExternalMappings.atom, newValue),
});

const getLocalExternalMappingId = () => {
	const value = localStorage.getItem('selected_external_mapping_id');
	if (!value) return {};
	return JSON.parse(value);
};

const ACompanySelectedExternalMappingId: AtomState<AtomSelectedExternalMappingSelector> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_selected_external_mapping_id', // UNIQUE ID
		default: getLocalExternalMappingId()
	})
};

export const ASelectedExternalMappingId = selector<AtomSelectedExternalMappingSelector>({
	key: 'ASelectedExternalMappingId',
	get: ({ get }) => get(ACompanySelectedExternalMappingId.atom),
	set: ({ set }, newValue) => {
		if (!newValue) {
			localStorage.removeItem('selected_external_mapping_id');
		} else {
			localStorage.setItem('selected_external_mapping_id', JSON.stringify(newValue));
		}
		return set(ACompanySelectedExternalMappingId.atom, newValue);
	}
});

// -----------------------[ API ]------------------------ //
export async function getExternalMappings(): Promise<MappingResume[]> {
	const cookies = new Cookies();
	axios.defaults.headers.common.Authorization = `${cookies.get('id_token')}`;
	try {
		return await axios.get(`${URL_FOUNDATION ?? ''}/api/mappings/external`).then(res => res.data);
	} catch (e) {
		console.error(e);
		return [];
	}
}