import label from 'images/icon/systemSettings/label.png';
import data_base from 'images/icons/import/data_base.svg';
import excelFileIcon from 'images/icons/import/excelFileIcon.svg';
import * as React from 'react';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import { useWindowDimensions } from '../../components_v2/utils';
import { getTranslate, Translate } from 'react-localize-redux';
import { ImportColumns, MatchedRowType, MatchedType, Model, Panel, RowType, SidelyRowStatus, TransformationType } from './model';
import { checkAsJob, cleanRedis, retrieveWork } from './actions';
import PageLoader from '../../components/PageLoader';
import styled from 'styled-components';
import { getPin } from './Table';
import { usePapaParse } from 'react-papaparse';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import storeLang from '../../helpers/storeLang';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { BlueSidely } from '../../styles/global/css/Utils';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { FlexDiv } from '../products/style';
import useAlert from '../alert/UseAlert';
import { translateToNode } from '../../styles/global/translate';
import { AlertRes } from '../alert/AlertProvider';
import { getTaxes } from '../settings/actions';
import { Tax } from '../settings/models';
import * as moment from 'moment';
import { getListFromModel } from './Matching';
import HubspotImage from 'images/external_companies/hubspot.svg';
import SageImage from 'images/external_companies/sage.svg';
import SalesforceImage from 'images/external_companies/salesforce.svg';
import ZohoImage from 'images/external_companies/zoho.svg';
import SeeMoreImage from 'images/external_companies/see_more.svg';

import EventTemplateEn from 'templates/import_event_en.csv';
import EventTemplateFr from 'templates/import_event_fr.csv';
import AssortmentsTemplateEn from 'templates/import_assortments_en.csv';
import AssortmentsTemplateFr from 'templates/import_assortments_fr.csv';
import CompaniesTemplateEn from 'templates/import_companies_en.csv';
import CompaniesTemplateFr from 'templates/import_companies_fr.csv';
import ContactsTemplateEn from 'templates/import_contacts_en.csv';
import ContactsTemplateFr from 'templates/import_contacts_fr.csv';
import OrdersTemplateEn from 'templates/import_orders_en.csv';
import OrdersTemplateFr from 'templates/import_orders_fr.csv';
import ProductsTemplateEn from 'templates/import_products_en.csv';
import ProductsTemplateFr from 'templates/import_products_fr.csv';
import readXlsxFile from 'read-excel-file';
import { getLanguageCode } from '../reports/utils';

type LoadingState = 'loading' | 'loaded' | 'error'
const { readString } = usePapaParse();

const Button = styled(DefaultButton)`
    width: 200px;
    height: 42px;
`;

const ButtonText = styled.p`
	padding: 0 1em;
	font-size: 13px;
`;

const RetriveWorkContainer = styled.div`
    .link {
        cursor: pointer;
        color: ${BlueSidely};
    }

    .inline {
        display: inline;
    }
`;

const Height = styled.div<{ height?: string, width?: string, margin?: string, inline?: boolean, padding?: string, float?: string }>`
    height: ${props => props.height || '100%'};
    width: ${props => props.width || '100%'};
    margin: ${props => props.margin || '0 2.5%'};
    display: ${props => props.inline ? 'inline-block' : ''};
    padding: ${props => props.padding || ''};
    float: ${props => props.float || ''};

    .importPin {
        width: 20px;
        display: inline-block;
        margin-right: 3px;
    }

    .link {
        cursor: pointer;
        color: ${BlueSidely};
    }

    .ant-upload.ant-upload-drag {
        border: 1px solid #EAEAEA;
        background-color: #FFFFFF;
    }
`;

const Text = styled.p<{ underline?: boolean, inline?: boolean, space?: boolean }>`
    font-size: 13px;
    color: #4C5862;
    display: ${props => props.inline ? 'inline-block' : ''};
    text-decoration: ${props => props.underline ? 'underline' : ''};
	margin: 0;
    margin-left: ${props => props.space ? '3px' : ''};
`;

const FlexDivText = styled(FlexDiv)`
	font-size: 13px;
	color: #4C5862;
	flex-wrap: wrap;
	width: 100%;
	a {
		color: ${BlueSidely};
		margin-left: 5px;
	}
`;

const ThirdParty = styled.div<{ allowed?: boolean }>`
        border: 1px solid #EAEAEA;
        height: 100%;
        text-align: center;
        cursor: ${p => p.allowed ? 'pointer' : 'not-allowed'};
        transition: border-color 0.3s;

        :hover {
            border: 1px solid ${BlueSidely};
        }
`;

const ExternalCompany = styled.div<{ padding: number, index: number, thirdPartyWidth: number, thirdPartyHeight: number }>`
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 1px solid #EAEAEA;
    border-radius: 50%;
    text-align: center;
    padding-top: ${props => 80 * props.padding / 100}px;
    margin-bottom: 15px;
`;

export function getBase64(img: File, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	if (img.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
		reader.readAsArrayBuffer(img);
	} else {
		reader.readAsText(img);
	}
}

export function dummyRequest({ onSuccess }): void {
	setTimeout(() => {
		onSuccess('ok');
	}, 0);
}

function createColumns(data: string[]): ImportColumns[] {
	const ret: string[] = data;
	ret.forEach((col, i) => {
		let duplicate_nb = 1;
		let loc_i = 0;
		for (const loc_col of ret) {
			if (loc_col == col && loc_i !== i) {
				ret[loc_i] += `(${duplicate_nb})`;
				duplicate_nb += 1;
			}
			loc_i += 1;
		}
	});
	return ret.map(h => ({ Header: h, accessor: h }));
}

function createData(data: string[][], columns: { Header: string, accessor: string }[]): RowType[] {
	const headers = columns.map(e => e.Header);
	const ret: RowType[] = data.slice(1).map((d, rowIndex) => {
		const loc_ret = d.reduce((acc, curr, i) => {
			if (headers[i] !== undefined) { acc[headers[i]] = { value: curr, status: SidelyRowStatus.Edited }; }
			return acc;
		}, { SidelyRow: { value: rowIndex, status: SidelyRowStatus.Edited } });
		return loc_ret;
	}).map(row => {
		const is_not_error = Object.keys(row).length == headers.length + 1;
		if (Object.entries(row).every(cell => cell[0] === 'SidelyRow' || (typeof cell[1].value === 'string' && cell[1].value === ''))) {
			return {};
		} else if (!is_not_error) {
			for (const header of headers) {
				if (row[header] == undefined) {
					row[header] = { value: '', status: SidelyRowStatus.Edited };
				}
			}
		}
		return row;
	}).filter(row => Object.keys(row).length == headers.length + 1);
	return ret;
}

export function statusFromStr(status: string): SidelyRowStatus {
	if (!status) {
		return SidelyRowStatus.Error;
	}
	switch (status.toLowerCase()) {
		case '0':
		case 'validated': return SidelyRowStatus.Validated;
		case '1':
		case 'warning': return SidelyRowStatus.Warning;
		case '2':
		case 'duplicates': return SidelyRowStatus.Duplicates;
		case '3':
		case 'adminwaiting': return SidelyRowStatus.AdminWaiting;
		case '4':
		case 'edited': return SidelyRowStatus.Edited;
		default: return SidelyRowStatus.Error;
	}
}

function formatValue(value: string | null, type: TransformationType | undefined): string | null {
	if (!value) return null;
	switch (type) {
		case TransformationType.CompanyId:
		case TransformationType.ProductId:
		case undefined: return value;
		case TransformationType.Date: return moment.unix(parseInt(value)).format('L - LT');
	}
}

export function createRetrivedData(data: RetrivedRow[], matching: MatchedRowType): RowType[] {
	return data.reduce((acc: RowType[], row) => {
		if (row.line === -1) return acc;
		const acc_row = { SidelyRow: { value: row.line, status: 0 } };
		for (const [key, value] of Object.entries(row)) {
			if (typeof value === 'number') continue;
			const valueToAccumulator = (v: RetrivedRowColumn, matching_key: [string, MatchedType | undefined] | undefined) => {
				const status: SidelyRowStatus = statusFromStr(v.status);
				if (status > acc_row.SidelyRow.status) {
					acc_row.SidelyRow.status = status;
				}
				if (matching_key !== undefined) {
					acc_row[matching_key[0]] = { value: formatValue(v.value, matching_key[1]?.type), status, type: matching_key[1]?.type };
				} else {
					const newKey = Object.entries(matching).find(m_row => m_row[1]?.secondMatchingValue === key);
					if (newKey) {
						acc_row[newKey[0]] = { value: formatValue(v.value, newKey[1]?.type), status, type: newKey[1]?.type };
					}
				}
			};
			if (Array.isArray(value)) {
				value.forEach(v => valueToAccumulator(v, Object.entries(matching).find(m_row => m_row[1]?.value === v.external_column_name)));
			} else {
				valueToAccumulator(value, Object.entries(matching).find(m_row => m_row[1]?.value === key));
			}
		}
		acc.push(acc_row);
		return acc;
	}, []);
}

function createRetrivedColumns(matching: MatchedRowType) {
	return Object
		.keys(matching)
		.map(col => {
			return { Header: col, accessor: col };
		});
}

type RetrievedWork = {
	model: Model,
	values: RetrivedRow[]
}

type RetrivedRowColumn = {
	external_column_name: string | null,
	has_been_pushed: boolean,
	order: number,
	status: string,
	value: string | null
}

type MatchingListTemporaryType = {
	ord: number,
	server: string,
	client: string | null,
	secondMatchingValue?: string,
	additionalValue?: unknown
	type?: TransformationType
}

type RetrivedRow = {
	[key: string]: RetrivedRowColumn | RetrivedRowColumn[]
} & { line: number }

function createMatchingList(data: RetrivedRow[], model: Model, taxes: Tax[]) {
	const baseList = getListFromModel(model);
	const ret: MatchedRowType = Object.entries(data[0])
		.reduce((acc: MatchingListTemporaryType[], [key, value]) => {
			if (typeof value === 'number') return acc;
			const baseElement = baseList.find(b => b.value === key);
			if (Array.isArray(value)) {
				value.forEach(v => {
					if (v.has_been_pushed) {
						const tax = taxes.find(t => t.name === v.external_column_name);
						acc.push({
							type: baseElement?.type,
							ord: v.order,
							client: v.value,
							server: v.external_column_name ?? '',
							secondMatchingValue: 'tax',
							additionalValue: tax ? {
								id: tax.id,
								header_key: tax.name
							} : undefined
						});
					}
				});
			} else if (value.has_been_pushed) {
				acc.push({ ord: value.order, client: value.value, server: key, type: baseElement?.type, });
			}
			return acc;
		}, [])
		.sort((a, b) => {
			return a.ord - b.ord;
		})
		.reduce((acc, col) => {
			acc[col.client ?? col.server] = { value: col.server, id: 0, label: undefined, model, secondMatchingValue: col.secondMatchingValue, additionalValue: col.additionalValue, type: col.type };
			return acc;
		}, {});
	return ret;
}

export default function UploadFile(props: {
  setData: (data: RowType[]) => void
  setColumns: (data: ImportColumns[]) => void
  setPanel: (panel: Panel) => void
  setMatchedList: (list: MatchedRowType) => void
  setToolBarState: (value: ToolbarState) => void
}) {
	const lang = getLanguageCode();
	const alert = useAlert();
	const { height, width } = useWindowDimensions();
	const { Dragger } = Upload;
	const [loadingState, setLoadingState] = React.useState<LoadingState>('loading');
	const [asJob, setAsJob] = React.useState<boolean>(false);
	const tableHeight = height - 152;
	const externalCompanies = [
		{ image: HubspotImage, label: 'hubspot', padding: 34 },
		{ image: SageImage, label: 'sage', padding: 34 },
		{ image: SalesforceImage, label: 'salesforce', padding: 22 },
		{ image: ZohoImage, label: 'zoho', padding: 30 },
		{ image: SeeMoreImage, label: 'see_more', padding: 30 }
	];
	// reduce size of list when the screen is shrinking
	const cropedExternal = externalCompanies.slice(Math.ceil(externalCompanies.length - ((width * 0.28) / (80 + (width - 90) * 0.028))));

	React.useEffect(() => {
		if (loadingState == 'loading' && !asJob) {
			checkAsJob()
				.then(res => {
					if (res.data == 'Ok') {
						setAsJob(true);
					}
					setLoadingState('loaded');
				})
				.catch(() => {
					setLoadingState('error');
				});
		}
	}, [loadingState]);

	function handleChange(info) {
		const translate = getTranslate(storeLang.getState().localize);

		if (info.file.status === 'uploading') {
			return;
		} else if (info.file.status === 'done') {
			const userValuesToInnerValues = (values: string[][]) => {
				const columns = createColumns(values[0]);
				const data = createData(values, columns);
				if (data.length > 5000000) {
					alert({
						title: translateToNode('error'),
						content: <div>{translateToNode('import_more_than_NUMBER_lines', { variables: [['NUMBER', '5000']] })}</div>,
						buttons: [{ title: 'Ok', res: AlertRes.Ok }]
					});
				} else {
					props.setData(data);
					props.setColumns(columns);
					props.setMatchedList({});
					props.setPanel(Panel.Matching);
					if (asJob) {
						cleanRedis();
					}
				}
			};

			getBase64(info.file.originFileObj, loadedFile => {
				if (info.file.originFileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
					return readXlsxFile(loadedFile).then((rawExcel: any[][]) => {
						const excel = rawExcel.map(re => re.map(re => {
							if (typeof re === 'number') return re.toString();
							if (!re) return '';
							if (typeof re === 'object') return moment(re).format('L - LT');
							return re;
						}));
						userValuesToInnerValues(excel);
					});
				}
				readString(loadedFile, {
					complete: (res) => {
						if (res.errors.length !== 0) {
							toast.error((translate('import.csv_error.' + res.errors[0].type) as string).replace('{{ ROW }}', (res.errors[0].row + 1).toString()).replace('{{ SEPARATOR }}', res.meta.delimiter), {
								position: 'top-right',
								autoClose: 4000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined
							});
							return;
						}
						userValuesToInnerValues(res.data.map((row: string[]) => row.map(cell => cell.replaceAll('\n', ' ').replaceAll('\t', ' '))));
					},
					worker: true
				});
			});
		}
	}

	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <>
				{loadingState !== 'loading' && asJob &&
				<RetriveWorkContainer className='ml-3'>
					<p className='inline'>
						<Translate id="import.work_found" />
					</p>
					<p className='inline link'
						onClick={() => {
							setLoadingState('loading');
							retrieveWork()
								.then(async data => {
									const json: RetrievedWork = JSON.parse(data.data);
									if (json.values.length > 0) {
										const taxes = await getTaxes();
										const retrievedWork = json.values;
										const matching = createMatchingList(retrievedWork, json.model, taxes);
										props.setData(createRetrivedData(retrievedWork, matching));
										props.setColumns(createRetrivedColumns(matching));
										props.setMatchedList(matching);
										setLoadingState('loaded');
										props.setPanel(Panel.TableView);
									} else {
										setLoadingState('error');
									}
								})
								.catch(e => {
									console.error(e);
									setLoadingState('error');
								});
						}}>
						{ } <Translate id="global.yes" />
					</p>
				</RetriveWorkContainer>
				}
			</>,
			bottomRightToolbarComponent: <Button
				className="col-2 mr-3"
				style={{
					height: 42,
					margin: 0,
					minWidth: 275,
					fontSize: 12
				}}
				onClick={() => { window.location.href = 'https://university.sidely.app/fr/'; }}
			>
				<Translate id="import.learn_more_about_import" />
			</Button>
		});
	}, [loadingState, asJob]);

	return (
		(<div style={{ margin: '0px 0px 0px 3px' }}>
			<FlexDiv style={{ height: tableHeight, margin: '0px 15px 15px 15px', backgroundColor: '#FFFFFF' }} flow='column' justify='space-between' gap='2em'>
				<Height height='fit-content' width='95%' padding='20px 0 0 0'>
					<h4><Translate id="import.importing_and_updating" /></h4>
					<Text style={{ marginBottom: '1em' }}><Translate id="import.import_all_type_of_data" /></Text>
					<Text inline>{getPin('error')} <Text underline inline>Attention</Text>: <Translate id="import.beware_import_update" /></Text>
				</Height>
				<FlexDiv height='50%' minHeight='300px' maxHeight='500px' justify='center' width='100%'>
					<Height height='100%' width='28%' inline>
						{loadingState !== 'loading'
							? <Dragger
								showUploadList={false}
								name='import'
								onChange={handleChange}
								// @ts-expect-error it work's
								customRequest={dummyRequest}
								accept='.csv,.xlsx'
							>
								<p className="ant-upload-drag-icon">
									<img src={excelFileIcon} width={80} />
								</p>
								<Button><Translate id="import.from_file" /></Button>
								<ButtonText><Translate id="import.click_or_drag" /></ButtonText>
								<p className="ant-upload-hint" style={{ fontSize: 12 }}><Translate id="import.must_be_a_csv_or_xlsx" /></p>
							</Dragger>
							: loadingState == 'loading'
								? <div className="list-loader">
									<PageLoader />
								</div>
								: <>Error fetching data</>
						}
					</Height>
					<Height height='100%' width='28%' float='right' onClick={() => props.setPanel(Panel.AlexandriaStep1)}>
						<ThirdParty allowed>
							<div style={{ top: '50%', transform: 'translateY(-50%)', position: 'relative' }}>
								<div>
									<img src={data_base} height={105} />
								</div><div>
									<Button><Translate id="import.from_sidely_leadgen" /></Button>
									<ButtonText>
										<Translate id="import.from_sidely_leadgen_text" />
									</ButtonText>
								</div>
							</div>
						</ThirdParty>
					</Height>
					<Height height='100%' width='28%' float='right'>
						<img src={label} style={{ position: 'absolute', translate: '1px 1px' }} />
						<ThirdParty>
							<div style={{ top: '50%', transform: 'translateY(-50%)', position: 'relative' }}>
								{cropedExternal.map((company, i) => <>
									<ExternalCompany padding={company.padding} index={i} thirdPartyWidth={(width - 90) * 0.28} thirdPartyHeight={tableHeight / 2}>
										<img src={company.image} width={80 * 0.8} />
									</ExternalCompany>
								</>)}
								<Button disabled><Translate id="import.from_third_party" /></Button>
								<ButtonText>
									<Translate id="import.from_third_party_text"/>
								</ButtonText>
							</div>
						</ThirdParty>
					</Height>
				</FlexDiv>
				<Height height='fit-content' margin='1% 2.5%'>
					<FlexDivText>
						<Translate id="import.export_our" />
						<a href={lang === 'fr' ? CompaniesTemplateFr : CompaniesTemplateEn} download={'companies_template.csv'}><Translate id="companies" /></a>,
						<a href={lang === 'fr' ? ContactsTemplateFr : ContactsTemplateEn} download={'contacts_template.csv'}><Translate id="global.contacts" /></a>,
						<a href={lang === 'fr' ? ProductsTemplateFr : ProductsTemplateEn} download={'products_template.csv'}><Translate id="global.products" /></a>,
						<a href={lang === 'fr' ? OrdersTemplateFr : OrdersTemplateEn} download={'orders_template.csv'}><Translate id="global.orders" /></a>,
						<a href={lang === 'fr' ? EventTemplateFr : EventTemplateEn} download={'events_template.csv'}><Translate id="events" /></a>,
						<div style={{ marginLeft: 3 }}><Translate id="global.and" /></div>
						<a href={lang === 'fr' ? AssortmentsTemplateFr : AssortmentsTemplateEn} download={'assortments_template.csv'}><Translate id="assortments" /></a>.
					</FlexDivText>
				</Height>
			</FlexDiv>
		</div>)
	);
}