import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { AProductFilter } from '../../atoms/filter/productsFilterAtom';
import { ATagFilter } from '../../atoms/filter/tagsFilterAtom';
import { ADatePicker } from '../../atoms/filter/timeFilterAtom';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { AUserPicker } from '../../atoms/filter/usersFilterAtom';
import { exportGallery } from './actions';
import { useDispatch } from 'react-redux';
import optionGrey from 'images/icon/options_grey.png';

export default function ToolBarDotsGallery(props: {
  limit: number
  offset: number
  selectedType: string
}): JSX.Element {
	const EXPORT = 0;

	const dataList = [
		{ label: 'export', value: EXPORT }
	];

	const pickedUsers = useRecoilValue(AUserPicker(0));
	const pickedDates = useRecoilValue(ADatePicker);
	const filteredTags = useRecoilValue(ATagFilter);
	const filteredProducts = useRecoilValue(AProductFilter);
	const dispatch = useDispatch();

	return <Dropdown
		dropdownStyle={{
			optionWidth: '125px',
			optionLeft: '-80px',
			marginRight: '15px'
		}}
		datalist={dataList}
		name={'dropdownDotsGallery'}
		JSXButton={() => <img src={optionGrey} width={30}/>}
		onChange={(value: DropdownData) => {
			switch (value.value) {
				case EXPORT:
					dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
					exportGallery({
						tags: { tags: filteredTags ?? null },
						userIdList: pickedUsers?.[0],
						products: filteredProducts?.products,
						periodeRange: (pickedDates != null)
							? {
								gteDate: pickedDates[0]?.format('YYYY-MM-DD') ?? '',
								lteDate: pickedDates[1]?.format('YYYY-MM-DD') ?? ''
							}
							: undefined,
						changed: false
					}, undefined, undefined, props.selectedType)
						.catch(_ => dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'import.export_error_message' }, state: 'error' } }));
			}
		}}
	/>;
}
