import trash_black from 'images/ui_icon/trash_black.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import Avatar from '../../../../../components_v2/avatar/Avatar';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { LoadingStateEnum } from '../../../../import/model';
import { deleteCompanyContact, getCompanyContacts } from '../../../data/action';
import { CompanyContact } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import { Card, CardImage, CardLeft, CardMiddle, CardRight, CardText, CardTitle, Container, SeeMore, Trash } from '../style/LinkedElementStyle';
import ContactPopup from '../../../../contact/popup/ContactPopup';
import { ModalState } from '../../../../products/model';
import { PopupContext } from '../../../../../components_v2/popup/Popup';

interface DeletionState {
	isOpen: boolean
	contact?: CompanyContact
}


function Contact(props: {
  clientCompanyId: number
  isExtend?: boolean
  newCreation?: CompanyContact
  onDelete: () => void
}): JSX.Element {
	const DEFAULT_LIMIT = 3;

	const translate = getTranslate(storeLang.getState().localize);
	const { isChild } = React.useContext(PopupContext);
	
	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [contacts, setContacts] = React.useState<CompanyContact[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [offset, setOffset] = React.useState<number>(0);

	const [deletionState, setDeletionState] = React.useState<DeletionState>({ isOpen: false });
	const [contactDetail, setContactDetails] = React.useState<ModalState<number>>({ isOpen: false });
	
	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getCompanyContacts({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset
		}).then(response => {
			setContacts(response.contacts);
			setTotal(response.total);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	React.useEffect(() => {
		if (props.newCreation != null) {
			setContacts([props.newCreation, ...contacts]);
			setOffset(offset + 1);
		}
	}, [props.newCreation]);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getCompanyContacts({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset: newOffset
		}).then(response => {
			setContacts(contacts.concat(response.contacts));
			setTotal(response.total > 0 ? response.total : total);
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}

	function confirmationDeletionContact(): void {
		if (!deletionState.contact) {
			return;
		}

		deleteCompanyContact({
			client_company_id: deletionState.contact.client_company_id,
			id: deletionState.contact.contact_id
		}).then(res => {
			if (res) {
				props.onDelete();
				setContacts(contacts.filter(c => c.contact_id !== deletionState.contact?.contact_id));
				setOffset(offset - 1);
				setTotal(total - 1);
			}
		});

	}

	return (
		(<Container>
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: contacts.length > 0
						? contacts.map(c => (
							<Card
								onClick={() => isChild ? window.open(`contacts-v2?id=${c.contact_id}`) : setContactDetails({ isOpen: true, data: c.contact_id })}
								isExtend={props.isExtend ?? false}
								key={`cardContact${c.contact_id}`}
							>
								<CardLeft>
									{c.contact_photo && c.contact_photo.length > 0
										? <CardImage url={c.contact_photo} />
										: <Avatar backgroundColor="#EAEAEA" width="35px" name={`${c.contact_first_name} ${c.contact_last_name}`.trim()} />}

								</CardLeft>
								<CardMiddle>
									<CardTitle>{`${c.contact_first_name} ${c.contact_last_name}`.trim()}</CardTitle>
									<CardText>{c.contact_position && c.contact_position.length > 0 ? c.contact_position : '-'}</CardText>
									<CardText>{c.contact_email && c.contact_email.length > 0 ? c.contact_email : '-'}</CardText>
									<CardText>{c.contact_phone && c.contact_phone.length > 0 ? c.contact_phone : '-'}</CardText>
								</CardMiddle>
								<CardRight>
									<Trash src={trash_black} onClick={(e) => {
										e.stopPropagation();
										setDeletionState({ ...deletionState, isOpen: true, contact: c });
									} } />
								</CardRight>
							</Card>
						))
						: <NoDataText><Translate id='no_contact' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADED && contacts.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
			<PopupDeletion 
				isOpen={deletionState.isOpen} 
				records={0} 
				translationKey=''
				customTitle={'Contact'}
				customMessage={translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${`${deletionState.contact?.contact_first_name ?? ''} ${deletionState.contact?.contact_last_name ?? ''}`.trim()}'`)} 
				onClickOut={() => setDeletionState({ ...deletionState, isOpen: false })}
				onValidation={() => confirmationDeletionContact()}
				noVerification
			/>
			{!isChild && <ContactPopup
				isOpen={contactDetail.isOpen}
				setOpen={isOpen => setContactDetails({ isOpen })}
				contactId={contactDetail?.data}
			/>}
		</Container>)
	);
}

export default Contact;
