import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely } from '../../../styles/global/css/Utils';

const Spacer = styled.div`
    margin-left: 2px;
    margin-right: 2px;
    padding: 0;
`;

const MoreFilterToggle = styled.div`
    color: ${BlueSidely};
    font-size: 12px;
    margin-right: 15px;
    cursor: pointer;
`;

interface ContainerProps {
  isActive?: boolean
}

const Container = styled.div<ContainerProps>`
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    background: ${p => p.isActive ? 'rgba(0, 178, 255, 0.1)' : 'white'};
    border-radius: 5px;
    justify-content: space-evenly;
    cursor: pointer;
    padding: 10px;
`;

const Image = styled.img<{ size?: string }>`
    width: ${({ size }) => size ?? '16px'};
    height: ${({ size }) => size ?? '16px'};
    filter: invert(65%) sepia(60%) saturate(5339%) hue-rotate(167deg) brightness(103%) contrast(104%);
    margin-right: 5px;
`;

const Text = styled.p`
    ${DefaultText}
    color: ${() => BlueSidely};
    font-weight: 500;
    font-size: 13px;
`;

export {
	Spacer,
	MoreFilterToggle,
	Container,
	Image,
	Text
};
