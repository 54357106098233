import React, {Component, Fragment} from 'react';
import {change, Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {Translate} from 'react-localize-redux';
import {withRouter} from 'react-router-dom';
import {renderField, renderSelectInputIcon, required} from '../../../components/FieldModal';
import {normalizeCapitalize} from '../../../helpers/fieldMask';
import {createIcon, fetchIcons} from '../../../store/actions/formbuilder.action';
import UploadIcon from '../../formBuilder/components/UploadIcon';
import {setLocalStorage} from '../../../helpers/localStorage';
import {getElements} from '../../../helpers/data/enrichedForm';
import {isArray} from 'lodash';

const FORM = 'modalFormAssortmentTitle';
const fields = ['name', 'icon_id'];
class ModalFormAssortment extends Component {
  state = {
    params: {
      page: 1,
      limit: 100
    },
    isModalImage: false
  };

  componentDidMount() {
    const { fetchIcons } = this.props;
    fetchIcons(this.state.params);
  }

  componentWillReceiveProps(nextProps) {
    const { data, dispatch } = this.props;
    if (data.detailTemplate !== nextProps.data.detailTemplate) {
      const detail = nextProps.data.detailTemplate;
      fields.map(f => dispatch(change(FORM, f, detail[f])));
    }
  }

  getValues = data => {
    if (isArray(data)) {
      return data.map(v => v.id)
    } else {
      return data
    }
  }

  onSubmit = val => {
    const { data, toggle } = this.props;
    const formData = { step: 1 };
    fields.map(field => {
      formData[field] = val[field].value || val[field];
      return null;
    });

    if (data.modal.status !== 'create') {
      const detail = data.detailTemplate;
      const fieldTem = ['catalogue_id', 'id'];
      const picturePolicy = [1, 2, 3];
      fieldTem.map(f => (formData[f] = detail[f] || []));
      const elements = []
      const shelf_audit_field_templates = [];
      const templates = detail['shelf_audit_field_templates'];
      templates && templates.map(v => {
        if (!picturePolicy.includes(v.shelf_audit_field_id)) {
          elements.push({
            ...getElements(v.shelf_audit_field.field_type),
            shelf_audit_field_id: v.shelf_audit_field_id,
            mandatory: v.mandatory,
            ordering: v.ordering,
            type: v.shelf_audit_field.field_type,
            field_name: v.shelf_audit_field.name,
            values: this.getValues(v.values)
          })
        } else {
          v.mandatory === true && shelf_audit_field_templates.push({
            shelf_audit_field_id: v.shelf_audit_field_id,
            mandatory: v.mandatory,
            ordering: v.ordering,
            values: v.values
          })
        }
      });
      formData['elements'] = elements;
      formData['shelf_audit_field_templates'] = shelf_audit_field_templates
    }
    formData['modalType'] = data.modal.status;
    this.props.history.push('/enform/detail');
    setLocalStorage('enFormTemplate', formData);
    toggle();
  };

  onAddClick = () => {
    this.setState({
      isModalImage: true
    });
  };

  onToggleImg = () => {
    this.setState({
      isModalImage: !this.state.isModalImage
    });
  };

  loadFormIcon = formIcon => {
    this.setState({
      formIcon
    });
  };

  render() {
    const { handleSubmit, formbuilder, icons, data, createIcon } = this.props;
    const options = icons.map(icon => {
      const result = {
        label: icon.name,
        icon: <img src={icon.icon_url} width="20" alt="" />,
        value: icon.id
      };
      return result;
    });

    return (
      <Fragment>
        <Modal
          centered
          toggle={this.onToggleImg}
          isOpen={this.state.isModalImage}
          style={{ maxWidth: 200 }}
        >
          <ModalHeader toggle={this.onToggleImg}>New Icon</ModalHeader>
          <ModalBody className="text-center">
            <UploadIcon loadFormIcon={this.loadFormIcon} />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              loading={formbuilder.isLoadingCreateIcon}
              spinColor="#003d79"
              onClick={() => {
                createIcon(this.state.formIcon);
                this.setState({
                  isModalImage: false
                });
              }}
            >
              {!formbuilder.isLoading && <Translate id="save" />}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className={this.props.className}
          centered
        >
          <ModalHeader
            className="text-capitalize"
            toggle={this.props.toggle}
            style={{ borderBottom: '0px' }}
          >
            <Translate id="Setup shelf audit form" />
          </ModalHeader>
          <ModalBody>
            <form
              className="forms-sample"
              onSubmit={handleSubmit(this.onSubmit)}
            >
              <Translate>
                {({ translate }) => (
                  <div className="row">
                    <div className="col-md-11">
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="name"
                            type="text"
                            component={renderField}
                            label={translate('name')}
                            placeholder={translate('enter name')}
                            normalize={normalizeCapitalize}
                            requiredStar
                            validate={[required]}
                          />
                          <Field
                            name="icon_id"
                            type="text"
                            component={renderSelectInputIcon}
                            label={translate('Icon')}
                            onAddClick={this.onAddClick}
                            options={options}
                            requiredStar
                            validate={[required]}
                            placeholder={translate('select icon')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4" />
                  </div>
                )}
              </Translate>
            </form>
          </ModalBody>
          <ModalFooter style={{ borderTop: '0px' }}>
            <Button
              color="primary"
              onClick={handleSubmit(this.onSubmit)}
              loading={data.isLoadingDetail}
              spinColor="#003d79"
              className="text-capitalize"
            >
              <Translate id={data.modal.status} />
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.contacts.isLoadingSubmit,
  formbuilder: state.formbuilder,
  icons: state.formbuilder.icons,
  data: state.enform
});

export default reduxForm({
  form: FORM
})(connect(mapStateToProps, { fetchIcons, createIcon })(withRouter(ModalFormAssortment)));
