import * as React from 'react';
import { loadingState, tooltipPos } from '../../../components_v2/models';
import { exportMerchandisingPerCompany, getMerchandisingPerCompany } from './actions';
import { InfiniteTable, InfiniteTableStyle } from '../../../components_v2/table/InfiniteTable';
import { useWindowDimensions } from '../../../components_v2/utils';
import { getColorForPercentage, getStringWidth, queryConstructor } from '../utils';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { PieChart } from 'react-minimal-pie-chart';
import PageLoader from '../../../components/PageLoader';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { reportingAction, reportingState } from '../Reducer';
import { useDispatch } from 'react-redux';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { useRecoilValue } from 'recoil';
import { AUserFilter } from '../../../atoms/filter/usersFilterAtom';
import { ADatePicker } from '../../../atoms/filter/timeFilterAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { AProductFilter } from '../../../atoms/filter/productsFilterAtom';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import RefreshButton from '../../../myToolbar/RefreshButton';
import usePermission from '../../permissions/usePermission';
import { ToolBarDotsReports } from '../../../myToolbar/OtherTools';
import { ABrand } from '../../../atoms/filter/BrandFiltersAtom';
import { BlueSidely, DarkGreySidely, GreenSidely, RedSidely } from '../../../styles/global/css/Utils';
import { Column } from '../../../components_v2/table/Table';
import { FlexDiv } from '../../products/style';

interface MerchField {
	name: string
	id: number
	field_type: string
}

interface PieChartData {
	value: number
	label: string
	color: string
}

interface MerchandisingInfo {
	shelf_sectors: Array<[key: string]>
	shelf_zones: Array<[key: string]>
	shelf_types: Array<[key: string]>
	product_highlights: Array<[key: string]>
	discount_types: Array<[key: string]>
	discount_levels: Array<[key: string]>
}

const sort = key => (rowA, rowB, _columnId, desc) => {
	const ra = rowA.original[key];
	const rb = rowB.original[key];
	if (!ra && !rb) return 0;
	if (!ra) return desc ? -1 : 1;
	if (!rb) return desc ? 1 : -1;
	if ((ra[0] / ra[1]) > (rb[0] / rb[1])) return 1;
	if ((ra[0] / ra[1]) < (rb[0] / rb[1])) return -1;
	return 0;
};

type Totals = Array<[key: string]>

function isNumber(value: string | number): boolean {
	return ((value != null) &&
		(value !== '') &&
		!isNaN(Number(value.toString())));
}

function getColor(id: string): string {
	switch (id) {
		case '0':
			return '#9F9FED';
		case '1':
			return '#FF814B';// softBeverages
		case '2':
			return RedSidely;// centralAisle
		case '3':
			return '#CBCBF4';// fruitsAndVegetables
		case '4':
			return '#227C9D';// bakery
		case '5':
			return '#90A0B7';// deli
		case '6':
			return '#F8CACE';// meat
		case '7':
			return '#DECC24';// dairy
		case '8':
			return '#C6E4E7';// dryGoods
		case '9':
			return '#CE5A28';// cannedGoods
		case '10':
			return '#FFEA2C';// healthAndBeauty
		case '11':
			return '#A0CFD3';// paperProducts
		case '12':
			return '#A8B5C7';// cleaningSupplies
		case '13':
			return '#D2F34E';// home
		case '14':
			return '#2A9D5F';// officeSupplies
		case '15':
			return '#F5767B';// registers
		case '16':
			return '#87F16D';// magazines
		case '17':
			return '#92572B';// registers
		case '18':
			return '#72ACFB';// magazines
		case '19':
			return '#FEC940';// bazaar
		case '20':
			return GreenSidely;// bio
		case '21':
			return BlueSidely;// drugstore
		case '22':
			return '#8282C6';// saltedGroceries
		case '23':
			return '#7C7287';// sweetGroceries
		case '24':
			return '#0393D0';// refrigerated
		case '25':
			return '#B6784B';// frozen
		case '26':
			return '#EA9EA5';// clothes
		case '27':
			return '#FEA240';// cereals
		case '28':
			return '#12CBC3';// jams
		case '29':
			return '#CEA07E';// biscuits
		case '30':
			return '#0C6836';// appetizers
		case '31':
			return '#12A39C';// dieteticGlutenFree
		case '32':
			return '#4895FF';// petFood
		case '33':
			return '#4B586A';// others
		case '34':
			return '#5C5CF2';// worldFood
		case '34':
			return '#726680';//
		case '35':
			return '#726680';//
		case '36':
			return '#AA9A0E';//
		case '37':
			return '#C0D374';//
		case '38':
			return '#E5C0A4';//
		case '39':
			return '#ED383F';//
	}
	return '#FFFFFF';
}

export function MerchandisingPerCompany(props: {
	state: reportingState
	dispatch: (action: reportingAction) => void
	setToolBarState: (value: ToolbarState) => void
	onCompanyClick: (n: number) => void
}) {
	const { height, width } = useWindowDimensions();
	const offset = 237;
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState([]);
	const c: Column[] = [];
	const [columns, setColumns] = React.useState(c);
	const [hover, setHover] = React.useState('init');
	const translate = getTranslate(storeLang.getState().localize);
	const [filteredColumns, setFilteredColumns] = React.useState<any>(undefined);
	const [launchExport, setLaunchExport] = React.useState({ export: false });
	const allowed = usePermission({ objectAction: 'CreateExport' });
	const dispatch = useDispatch();

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 200}
					category={AtomCategory.GLOBAL}
					elements={[
						ToolbarElement.USER_FILTER,
						{
							kind: ToolbarElement.DATE_PICKER,
							oneDay: true
						},
						ToolbarElement.TAG_FILTER,
						ToolbarElement.PRODUCT_PER_CATEGORY,
						ToolbarElement.BRAND_FILTER
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'}/>
			</FlexDiv>,
			bottomRightToolbarComponent: <ToolBarDotsReports
				current={undefined}
				startingColumns={columns}
				export={allowed}
				onFilter={(e) => {
					if (e.export) {
						setLaunchExport(e);
					} else if (e.columns) {
						setFilteredColumns(e.columns);
					}
				}}
			/>
		});
	}, [loadingState, filteredColumns, width]);

	const filteredUsers = useRecoilValue(AUserFilter);
	const pickedDates = useRecoilValue(ADatePicker);
	const filteredTags = useRecoilValue(ATagFilter);
	const filteredProducts = useRecoilValue(AProductFilter);
	const brandFilter = useRecoilValue(ABrand);

	function getTooltip(company_name: string, v: number[], pos: tooltipPos) {
		// const translate = getTranslate(storeLang.getState().localize);
		let tooltipClass = 'tooltiptext3';
		if (pos == 'up') {
			tooltipClass = 'tooltiptextup';
		}
		return (
			<span className={tooltipClass}>
				<div className='font-weight-bold' style={{ marginBottom: '2px' }}>
					{company_name}
				</div>
				<div className='row'>
					<div className='col-5' style={{ textAlign: 'right', padding: '0px' }}>
					</div>
					<div className='col-4' style={{ textAlign: 'left', padding: '0px' }}>
            Total:
					</div>
					<div className='col-2' style={{ textAlign: 'left', padding: '0px' }}>
						{v[0]}
					</div>
				</div>
				<div className='row'>
					<div className='col-5' style={{ textAlign: 'right', padding: '0px' }}>
					</div>
					<div className='col-4' style={{ textAlign: 'left', padding: '0px' }}>
						{translate('products') as string}:
					</div>
					<div className='col-2' style={{ textAlign: 'left', padding: '0px' }}>
						{v[1]}
					</div>
				</div>
			</span>
		);
	}

	function spanMultiplePercentage(company_name: string, info: MerchandisingInfo, field_type: string, o: any, pos: tooltipPos) {
		const data: PieChartData[] = [];
		const translate = getTranslate(storeLang.getState().localize);
		Object.keys(o).forEach(k => {
			if (k != 'total') {
				const v = o[k][0] / o[k][1];
				const pcd: PieChartData = { value: v, color: getColor(k), label: k };
				data.push(pcd);
			}
		});
		let tooltipClass = 'tooltip3';
		let tooltipTextClass = 'tooltiptext3';
		if (pos == 'up') {
			tooltipClass = 'tooltipup';
			tooltipTextClass = 'tooltiptextup';
		}
		return (
			<div className={tooltipClass}>
				<PieChart
					className='circle-reporting'
					lineWidth={70}
					center={[50, 50]}
					viewBoxSize={[100, 100]}
					data={data}
				/>
				{/* ({o.total}) */}
				<span className={tooltipTextClass}>
					<span className='font-weight-bold' style={{ marginBottom: '2px' }}>
						{company_name}
					</span>
					{data
						.sort((n1, n2) => n2.value - n1.value)
						.map(d =>
							<div className='row'>
								<div className='col-7' style={{ textAlign: 'right', padding: '0px' }}>
									{translate(`shelf_audit.${field_type}.` + info[field_type][d.label]) as string}
								</div>
								<div className='col-2' style={{ textAlign: 'center', padding: '0px' }}>
									<span className='dot' style={{ backgroundColor: getColor(d.label) }}/>
								</div>
								<div className='col-3' style={{ textAlign: 'left', padding: '0px' }}>
									<span>{`${(Math.round(d.value * 10000) / 100).toFixed(2)}%`}</span>
								</div>
							</div>
						)}
				</span>
			</div>
		);
	}

	function spanPercentage(company_name: string, v: number[], pos: tooltipPos) {
		const avg = v[0] / v[1];
		let tooltipClass = 'tooltip3';
		if (pos == 'up') {
			tooltipClass = 'tooltipup';
		}
		return (
			<div className={tooltipClass}>
				<span className='percentage'>
					{`${(Math.round(avg * 10000) / 100).toFixed(2)}%`}
				</span>
				{getTooltip(company_name, v, pos)}
			</div>
		);
	}

	function spanPercentageColor(company_name: string, v: number[], pos: tooltipPos, alpha: number) {
		const avg = v[0] / v[1];
		let tooltipClass = 'tooltip3';
		if (pos == 'up') {
			tooltipClass = 'tooltipup';
		}
		const color = getColorForPercentage(avg, alpha);
		return (
			<div className={tooltipClass}>
				<span className='percentage overflow' style={{
					backgroundColor: color,
					borderRadius: '25px',
					color: DarkGreySidely,
					textAlign: 'center',
					width: '80px'
				}}>
					{`${(Math.round(avg * 10000) / 100).toFixed(2)}%`}
				</span>
				{getTooltip(company_name, v, pos)}
			</div>
		);
	}

	function spanAverage(company_name: string, v: number[], pos: tooltipPos) {
		const avg = v[0] / v[1];
		let tooltipClass = 'tooltip3';
		if (pos == 'up') {
			tooltipClass = 'tooltipup';
		}
		return (
			<div className={tooltipClass}>
				<span className='overflow'>{avg.toFixed(2)}</span>
				{getTooltip(company_name, v, pos)}
			</div>
		);
	}

	function genColumns(merchandisingFields: MerchField[], info: MerchandisingInfo, totals: Totals, onCompanyClick: (n: number) => void): Column[] {
		const translate = getTranslate(storeLang.getState().localize);
		const merchandisingFieldsColumns: Column[] = merchandisingFields.map(mf => {
			const header_name: string = translate(mf.name).toString();
			return {
				Header: header_name,
				accessor: row => {
					// console.log(JSON.stringify(row));
					// console.log(mf.name);
					// console.log(row[mf.name]);
					if (row[mf.name] != undefined && row[mf.name] != null) {
						switch (mf.field_type) {
							case 'bool':
								return spanPercentage(row.company_name, row[mf.name], 'left');
							case 'int':
								return spanAverage(row.company_name, row[mf.name], 'left');
							case 'float':
								return spanAverage(row.company_name, row[mf.name], 'left');
							case 'shelf_sector':
							case 'shelf_zone':
							case 'shelf_type':
							case 'product_highlight':
							case 'discount_type':
							case 'discount_level':
								return spanMultiplePercentage(row.company_name, info, mf.field_type, row[mf.name], 'left');
						}
					}
					return <span>-</span>;
				},
				width: getStringWidth(header_name),
				sortType: sort(mf.name),
				Footer: _info => {
					switch (mf.field_type) {
						case 'bool':
							return spanPercentage('total', totals[mf.name], 'up');
						case 'int':
							return spanAverage('total', totals[mf.name], 'up');
						case 'float':
							return spanAverage('total', totals[mf.name], 'up');
						case 'shelf_sector':
						case 'shelf_zone':
						case 'shelf_type':
						case 'product_highlight':
						case 'discount_type':
							return (
								<span>
									{spanMultiplePercentage('total', info, mf.field_type, totals[mf.name], 'up')}
								</span>
							);
					}
					return <span>-</span>;
				}
			};
		});
		const company_name: string = translate('shelf_audit.column.company_name').toString();
		const instore: string = translate('shelf_audit.column.instore_presence').toString();
		const last_audit: string = translate('shelf_audit.column.last_shelf_audit').toString();
		const user_name: string = translate('shelf_audit.column.user').toString();
		const no_parent_name: string = translate('shelf_audit.column.no_parent_name').toString();
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		};
		const s: Column[] = [
			{
				id: 'expander',
				Header: (props: any) => {
					const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = props;
					return (
						<span {...getToggleAllRowsExpandedProps({ title: undefined })}
							  style={{ fontSize: '20px', cursor: 'pointer' }}>
							{isAllRowsExpanded ? '-' : '+'}
						</span>
					);
				},
				Cell: ({ row }) =>
					// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
					// to build the toggle for expanding a row
					row.canExpand ? (
						<span
							{...row.getToggleRowExpandedProps({
								title: undefined,
								style: {
									// We can even use the row.depth property
									// and paddingLeft to indicate the depth
									// of the row
									// paddingLeft: `${row.depth * 2.5}rem`,
									fontSize: '20px'
								}
							})}
						>
							{row.isExpanded ? '-' : '+'}
						</span>
					) : null,
				width: 60,
				Footer: 'Total'
			},
			{
				Header: company_name,
				accessor: row => {
					const content =
						<span>
            	{row.company_count && <span>{' '}({row.company_count})</span>}
						</span>;
					if (row.hasOwnProperty('company_id') && row.hasOwnProperty('company_name')) {
						return (
							<span style={{ display: 'block', textAlign: 'left', cursor: 'pointer', fontWeight: 500 }}
								  className='overflow'
								  onClick={() => onCompanyClick(row.company_id)}
							>
								<span>
									{row.company_name}
									{content}
								</span>
							</span>
						);
					} else {
						return (
							<span style={{ display: 'block', textAlign: 'left', fontWeight: 500 }} className='overflow'>
								<span>
									{no_parent_name}
									{content}
								</span>
							</span>
						);
					}
				},
				sortType: sort('company_name'),
				width: 200,
				Footer: _info => {
					if (totals.company_count != undefined && totals.company_count != null) {
						return <span>{totals.company_count}</span>;
					} else {
						return '-';
					}
				}
			},
			{
				Header: instore,
				accessor: row => {
					return spanPercentageColor(row.company_name, row.presence, 'left', 1.0);
				},
				width: getStringWidth(instore),
				sortType: sort('presence'),
				Footer: _info => {
					if (totals.presence) {
						return spanPercentageColor('total', totals.presence, 'up', 1.0);
					} else {
						return '-';
					}
				}
			},
			{
				Header: last_audit,
				accessor: row => {
					if (row.audit_date) {
						const d = new Date(row.audit_date);
						return (
							<span style={{ display: 'block', textAlign: 'right', cursor: 'pointer', fontWeight: 500 }}
								  className='overflow'
								  onClick={() => window.open(window.location.origin + '/enform/detaildata/' + row.audit_id, '_blank')}
							>
								{`${d.toLocaleDateString(undefined, options)}`}
							</span>
						);
					} else {
						return '-';
					}
				},
				width: getStringWidth(last_audit),
				sortType: sort('audit_date'),
				Footer: ''
			},
			{
				Header: user_name,
				accessor: row => {
					if (row.user_name) {
						return (
							<span style={{ display: 'block', textAlign: 'left' }}
								  className='overflow'>
								{row.user_name}
							</span>
						);
					} else {
						return '-';
					}
				},
				width: getStringWidth(user_name),
				sortType: sort('user_name'),
				Footer: ''
			}
		];
		return s.concat(merchandisingFieldsColumns);
	}

	React.useEffect(() => {
		if (!loadingState && filteredUsers !== undefined && (pickedDates != null) && (filteredTags != null) && (filteredProducts != null)) {
			try {
				if (!loadingState) {
					setLoadingState('loading');
				}
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				let dateStr = '';
				if (pickedDates[0]?.format('YYYY-MM-DD') == pickedDates[1]?.format('YYYY-MM-DD') && (pickedDates[0] != null) && (pickedDates[1] != null)) {
					dateStr = `${pickedDates[0].format('YYYY-MM-DD')}_${pickedDates[1].format('YYYY-MM-DD')}`;
				} else {
					dateStr = `${pickedDates[0]?.format('YYYY-MM-DD') ?? ''}_${pickedDates[1]?.format('YYYY-MM-DD') ?? ''}`;
				}
				getMerchandisingPerCompany(queryConstructor({
					tags: filteredTags,
					userId: filteredUsers,
					products: filteredProducts.products,
					brandList: brandFilter
				}, dateStr), cancelTokenSource.token)
					.then(res => {
						setData(res.data.rows);
						const columns = genColumns(res.data.columns, res.data.info, res.data.totals, props.onCompanyClick);
						setColumns(columns);
						// if (typeof (props.toolBarState.columns) !== 'undefined' && typeof (props.toolBarState.columns.setDotColumns) !== 'undefined')
						//   props.toolBarState.columns.setDotColumns(columns)
						setLoadingState('loaded');
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job in: MerchandisingPerCompany ', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				props.dispatch({ type: 'SET_IS_LOADING', value: false });
				setLoadingState('error');
			}
		}
	}, [loadingState, filteredUsers, pickedDates, filteredTags, filteredProducts, JSON.stringify(brandFilter)]);

	React.useEffect(() => {
		if (launchExport.export) {
			exportMerchandisingPerCompany(queryConstructor({
				tags: filteredTags,
				userId: filteredUsers,
				products: filteredProducts?.products
			}, `${pickedDates?.[0]?.format('YYYY-MM-DD') ?? ''}_${pickedDates?.[1]?.format('YYYY-MM-DD') ?? ''}`))
				.then(res => {
					dispatch({
						type: 'INSERT_MESSAGE',
						value: { message: { text: 'exports.ready_toast' }, state: 'loading' }
					});
				})
				.catch(e => {
					dispatch({
						type: 'INSERT_MESSAGE',
						value: { message: { text: 'import.export_error_message' }, state: 'error' }
					});
					console.log(e);
				});
		}
	}, [launchExport]);

	let ret: any[] = [];
	if (filteredColumns?.length != 0) {
		ret = filteredColumns;
	} else {
		ret = columns;
	}
	return (
		<div style={{ height: '100%' }}>
			<InfiniteTableStyle>
				{(loadingState == 'loading' || !loadingState) && <div className='list-loader'><PageLoader/></div>}
				{loadingState == 'error' && <p>Error fetching data</p>}
				{loadingState == 'loaded' && <InfiniteTable height={height - offset} columns={ret} data={data}/>}
			</InfiniteTableStyle>
		</div>
	);
}
