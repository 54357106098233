import userGroupImage from 'images/icons/userGroup.svg';
import * as React from 'react';
import { Fragment } from 'react';
import {
	Button,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	UncontrolledButtonDropdown
} from 'reactstrap';
import Select from 'react-select';
import { customStyles } from '../../../helpers/styleSelect';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AUserFilter } from '../../../atoms/filter/usersFilterAtom';
import PageLoader from '../../pageLoader/PageLoader';
import { Open } from '../../../styles/global/css/Open';
import { AUsers } from '../../../atoms/global/users';
import getScope from '../../../containers_v2/permissions/getScope';
import { ObjectAction } from '../../../../../web-types/objectActions';
import { UserBlock } from '../../dropdown/DropdownOwners';
import { GenericToolbarFilterProps } from '../ToolbarFilter';

export default function UserFilter(props: GenericToolbarFilterProps & { atomId: number, user?: 'me' | 'all', permission?: ObjectAction }) {
	const [filteredUsers, setFilteredUsers] = useRecoilState(AUserFilter);
	const [options, setOptions] = React.useState<any[]>([]);
	const [modalUser, setModalUser] = React.useState<boolean>(false);
	const [selected, setSelected] = React.useState<any>(<Translate id={props.user ?? 'all'} />);
	const translate = getTranslate(storeLang.getState().localize);
	const [selectedUser, setSelectedUser] = React.useState<any>(null);
	const owners = useRecoilValue(AUsers);
	const ownerId = owners.find(o => o.isYou)?.id ?? -1;
	const scope = getScope(props.permission);

	React.useEffect(() => {
		if (filteredUsers === undefined) {
			if (props.user == 'me' || scope === 'User') {
				setSelected(<Translate id={'me'} />);
				setFilteredUsers(ownerId);
			} else {
				setSelected(<Translate id={'all'} />);
				setFilteredUsers(null);
			}
		} else {
			if (!filteredUsers) {
				setSelected(<Translate id={'all'} />);
				setFilteredUsers(null);
			} else if (ownerId == filteredUsers) {
				setSelected(<Translate id={'me'} />);
			} else {
				const label = options.find(u => u.value == filteredUsers)?.label;
				if (label) {
					setSelected(label);
				} else {
					setSelected(<PageLoader size={25} />);
				}
			}
		}
	}, [filteredUsers, options]);

	React.useEffect(() => {
		let opt: any = [];

		const actives = owners
			.filter(data => data.status)
			.map(data => {
				return {
					value: data.id,
					label: <UserBlock user={data} />
				};
			}
			);

		const inactives = owners
			.filter(data => !data.status)
			.map(data => {
				return {
					value: data.id,
					label: <UserBlock user={data} />
				};
			}
			);

		opt = actives.concat(inactives);

		setOptions(opt);
	}, [scope]);

	if (props.hidden) return <></>;

	function toggleUser() {
		setModalUser(!modalUser);
	}

	function handleUserChange(id: number | null) {
		setFilteredUsers(id);
	}

	function handleFilterUser() {
		setModalUser(true);
	}

	function handleSpecificUser() {
		toggleUser();
		if (!selectedUser) { return; }
		handleUserChange(selectedUser.value);
		if (selectedUser.label.props.user) 
			setSelected(<UserBlock user={selectedUser.label.props.user}/>);
	}

	return (
		(<UncontrolledButtonDropdown
			direction="down"
			className="form-filter-opportunity d-flex align-items-center"
			style={{ width: 180, padding: '0 0 0 10px', margin: 0 }}
		>
			<img
				alt="ProfileImage"
				src={userGroupImage}
				height="20"
			/>
			<DropdownToggle className="toggler py-0 px-2 ellipsis" caret>
				<span className="ellipsis w-100 text-left text-black">
					{selected}
				</span>
				<Open width={7} height={4} />
			</DropdownToggle>
			<DropdownMenu style={{ minWidth: '155px' }}>
				{scope !== 'User' && 
					<DropdownItem
						onClick={() => {
							handleUserChange(null);
							setSelected(<Translate id="all" />);
						}}
					>
						<Translate id="all" />
					</DropdownItem>
				}

				<DropdownItem
					onClick={() => {
						handleUserChange(ownerId);
						setSelected(<Translate id="me" />);
					}}
				>
					<Translate id="me" />
				</DropdownItem>
				{scope !== 'User' && 
					<Fragment>
						<DropdownItem
							onClick={() => {
								handleFilterUser();
								setSelected(<Translate id="specific_user" />);
							}}
						>
							<Translate id="specific_user" />
						</DropdownItem>
					</Fragment>
				}
			</DropdownMenu>
			<Modal isOpen={modalUser} toggle={toggleUser} centered>
				<ModalHeader
					toggle={toggleUser}
					style={{ borderBottom: 'none' }}
				>
					<Translate id="select_spesific_user" />
				</ModalHeader>
				<form
					className="forms-sample"
					onSubmit={handleSpecificUser}
				>
					<ModalBody>
						<div className="form-group row">
							<div className="col-md-3 text-right mt-2">
								<label>
									{translate('specific_user') as string}
								</label>
							</div>
							<div className={'col-md-9'}>
								<div className="row">
									<div className="col-12" style={{ fontWeight: 300 }}>
										<Select
											onChange={value => {
												setSelectedUser(value);
											}}
											styles={customStyles}
											optionClassName="needsclick"
											placeholder={translate('pick_spesific_user')}
											options={options}
										/>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter style={{ borderTop: 'none' }}>
						<Button color="primary" className="px-5 mb-2" onClick={() => {
							handleSpecificUser();
							toggleUser();
						}}>
							<Translate id="save" />
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		</UncontrolledButtonDropdown>)
	);
}
