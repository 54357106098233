import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Add from '../../../components_v2/add/Add';
import Avatar from '../../../components_v2/avatar/Avatar';
import InputSearch from '../../../components_v2/input/InputSearch';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import Popup from '../../../components_v2/popup/Popup';
import { translateToString } from '../../../styles/global/translate';
import { getUsers } from '../action';
import { User } from '../model';
import { Button, Close, ProfilePic, Title, UserDescription, UserList, UserPhoto } from '../style';

export default function GroupMembers(props: {
  users: User[]
  groupId?: number
  updateMembers: (values: User[]) => void
}): JSX.Element {
	const [searchValue] = React.useState<string>('');
	const [members, setMembers] = React.useState<User[]>(props.users);
	const [availableUsers, setAvailableUsers] = React.useState<any[]>([]);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		getUsers()
			.then(res => {
				setAvailableUsers(res.data);
			})
			.catch(console.log);
	}, []);

	React.useEffect(() => {
		setMembers(props.users.sort((a, b) => a.name.localeCompare(b.name)));
	}, [JSON.stringify(props.users)]);

	const filtered = members.filter(e => e.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) && !e.to_remove);

	return (<>
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'calc(100vh - 155px)' }}>
			<UserDescription width={450}>
				<div style={{ width: 30 }} />
				<Title style={{ width: 200 }}>
					<Translate id="admin.member" /> ({props.users.filter(u => !u.to_remove).length})
				</Title>
				{/* <InputSearch
				onChange={setSearchValue}
				value={searchValue}
				type=''
				name='group-member-search'
				placeholder={translate('admin.search_members') as string}
			/> */}
				<Add onClick={_ => setIsOpen(true)} />
			</UserDescription>
			<UserList>
				{filtered.map(u => {
					return (
						(<UserDescription key={`list[${u.name}]`} width={450}>
							{u.photo
								? <ProfilePic>
									<UserPhoto src={u.photo} />
								</ProfilePic>
								: <Avatar userId={u.id} name={u.name} width={'30px'} fontSize={'13px'} />
							}
							<div style={{ width: 200 }}>
								{u.name}
							</div>
							<Close
								src={close}
								onClick={() => {
									const nMembers = members.map(m => {
										if (m.id === u.id) {
											m.to_remove = true;
										}
										return m;
									});
									setMembers(nMembers);
									props.updateMembers(nMembers);
								}} />
						</UserDescription>)
					);
				})}
			</UserList>
		</div>
		{isOpen &&
			<Popup
				key={'popupDetailGroupUSERS'}
				isOpen={isOpen}
				popupStyle={{ animate: true, height: '100vh', top: '0%' }}
				popupMode={PopupMode.Details}
				content={
					<Modal
						users={availableUsers.filter(u => members.find(m => m.id == u.id) == undefined).concat(members.filter(m => m.to_remove))}
						groupId={props.groupId ?? -1}
						closeModal={() => setIsOpen(false)}
						updateMemberList={(values) => {
							const nMembers = members
								.filter(m => values.find(v => v.id == m.id) == undefined)
								.concat(values)
								.sort((a, b) => a.name.localeCompare(b.name));

							setMembers(nMembers);
							props.updateMembers(nMembers);
						}}
					/>
				}
				onClickOut={() => setIsOpen(false)}
			/>
		}
	</>);
}

function Modal(props: {
  users: User[]
  groupId: number
  closeModal: () => void
  updateMemberList: (values: User[]) => void
}) {
	const [searchValue, setSearchValue] = React.useState<string>('');
	const [checkedList, setCheckedList] = React.useState<User[]>([]);

	const filtered = props.users.filter(e => e.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) && e.role_name != 'Owner');

	return (
		<div style={{
			position: 'relative',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}}>
			<UserDescription width={260}>
				<InputSearch
					onChange={setSearchValue}
					value={searchValue}
					type=''
					name='group-member-search'
					placeholder={translateToString('search')}
				/>
			</UserDescription>
			<UserList>
				{filtered.sort((a, b) => a.name.localeCompare(b.name)).map(u =>
					<UserDescription
						key={`popup_list[${u.name}]`}
						width={260}
						clickable
						onClick={_ => {
							const index = checkedList.findIndex(c => c.id == u.id);
							if (index >= 0) {
								checkedList.splice(index, 1);
							} else {
								checkedList.push(u);
							}
							setCheckedList([...checkedList]);
						}}
					>
						<input type='checkbox' checked={checkedList.find(c => c.id == u.id) != undefined} onChange={() => null} />
						{u.photo
							? <ProfilePic>
								<UserPhoto src={u.photo} />
							</ProfilePic>
							: <Avatar userId={u.id} name={u.name} width={'30px'} fontSize={'13px'} />
						}
						<div style={{ width: 175 }}>
							{u.name}
						</div>
					</UserDescription>
				)}
			</UserList>
			<Button
				style={{ position: 'absolute', bottom: 0 }}
				onClick={() => {
					const nCL = checkedList.map(c => {
						c.to_remove = false;
						return c;
					});
					props.updateMemberList(nCL);
					props.closeModal();
				}}
			>OK</Button>
		</div>
	);
}
