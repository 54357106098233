import moment from 'moment';

const reference = moment();
const today = reference.clone().startOf('day');
const yesterday = reference
	.clone()
	.subtract(1, 'days')
	.startOf('day');
const week = reference
	.clone()
	.subtract(7, 'days')
	.startOf('day');

function isToday(time, TODAY) {
	return moment(time).isSame(TODAY, 'd');
}

export const replaceTime = time => (time ? time.replace(':', 'h') : '-');

function setTimeCalendar(value, value2) {
	const startTime = moment(value).format('HH:mm');
	const endTime = moment(value2).format('HH:mm');
	if (startTime !== endTime) {
		if (value2 === null) {
			return replaceTime(startTime);
		}
		if (startTime === '00:00' && endTime === '23:59') {
			return 'All day';
		}
		return `${replaceTime(startTime)} - ${replaceTime(endTime)}`;
	}
	return replaceTime(startTime);
}

function isYesterday(time, YESTERDAY) {
	return moment(time).isSame(YESTERDAY, 'd');
}

function isWithinAWeek(time, AWEEK) {
	return moment(time).isAfter(AWEEK);
}

function ComplexTime(time, full = false) {
	if (isToday(time, today)) {
		return moment
			.utc(time)
			.local()
			.format('hh:mm A');
	}
	if (isYesterday(time, yesterday)) {
		if (full) {
			return `yesterday ${moment(time).format('hh:mm A')}`;
		}
		return 'yesterday';
	}
	if (isWithinAWeek(time, week)) {
		return moment(time)
			.utcOffset(60)
			.format('DD/MM/YYYY');
	}
	return moment(time)
		.utcOffset(60)
		.format('DD/MM/YYYY');
}

function ComplexTimeOnlyHours(time) {
	return moment(time).format('hh:mm');
}

function ComplexTimeWithoutHours(time) {
	if (isToday(time, today)) {
		return 'today';
	}
	if (isYesterday(time, yesterday)) {
		return 'yesterday';
	}
	if (isWithinAWeek(time, week)) {
		return moment(time)
			.utcOffset(60)
			.format('DD/MM/YYYY');
	}
	return moment(time)
		.utcOffset(60)
		.format('DD/MM/YYYY');
}

function GetExpiredDate(expDate) {
	const end = moment(expDate);
	const duration = moment.duration(end.diff(reference)).asMilliseconds();
	return duration;
}

function CompleteTime(time) {
	return moment(time).format('lll');
}

function addDays(currentDate, days) {
	const result = new Date(currentDate);

	result.setDate(result.getDate() + days);

	return result;
}

function cekEvent(date1, date2) {
	const startDate = moment(date1).format('DD/MM/YYYY');
	const endDate = moment(date2).format('DD/MM/YYYY');
	if (startDate !== endDate) {
		if (date2 === null) {
			return startDate;
		}
		return `${startDate} - ${endDate}`;
	}
	return startDate;
}

function cekEventTimes(value, value2) {
	const startTime = moment(value).format('HH:mm');
	const endTime = moment(value2).format('HH:mm');
	if (startTime !== endTime) {
		if (value2 === null) {
			return startTime;
		}
		if (startTime === '00:00' && endTime === '23:59') {
			return 'All day';
		}
		return `${startTime} - ${endTime}`;
	}
	return startTime;
}

function getDeadlineDate(filterDate = 'All') {
	let gteDate = '';
	let lteDate = '';
	const dateFormat = 'YYYY-MM-DDT00:00:00.000[Z]';

	if (filterDate == 'This week') {
		gteDate = moment()
			.startOf('week')
			.format(dateFormat);
		lteDate = moment()
			.endOf('week')
			.format(dateFormat);
	} else if (filterDate == 'This month') {
		gteDate = moment()
			.startOf('month')
			.format(dateFormat);
		lteDate = moment()
			.endOf('month')
			.format(dateFormat);
	} else if (filterDate == 'This quarter') {
		gteDate = moment()
			.startOf('quarter')
			.format(dateFormat);
		lteDate = moment()
			.endOf('quarter')
			.format(dateFormat);
	} else if (filterDate == 'This year') {
		gteDate = moment()
			.startOf('year')
			.format(dateFormat);
		lteDate = moment()
			.endOf('year')
			.format(dateFormat);
	} else {
		gteDate = '';
		lteDate = '';
	}

	return {
		gteDate,
		lteDate
	};
}

export {
	ComplexTime,
	ComplexTimeWithoutHours,
	GetExpiredDate,
	ComplexTimeOnlyHours,
	CompleteTime,
	addDays,
	cekEvent,
	getDeadlineDate,
	cekEventTimes,
	isToday,
	setTimeCalendar
};