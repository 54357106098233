import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Route } from '../Routes';
import CompaniesAndMapView from '../../containers_v2/client-companies/CompaniesAndMapView';
import CompanyRouteBlack from 'images/menu_icon/company_black.svg';
import CompanyRouteBlue from 'images/menu_icon/company_blue.svg';
import CompanyRouteWhite from 'images/menu_icon/company_white.svg';

function CompaniesRoutes(): Route[] {
	return [
		{
			component: CompaniesAndMapView,
			path: '/companies',
			name: <Translate id="sidebar.accounts" />,
			role: ['all'],
			key: 'companies',
			sectionKey: 'companies',
			logoImg: CompanyRouteBlack,
			hoverImg: CompanyRouteBlue,
			activeImg: CompanyRouteWhite,
			title: <Translate id="companies" />,
			subTitle: 'companies',
			restricted: false,
			exact: true,
			menu: true,
			permission: ['ViewCompany', 'ViewContact'],
			subroutes: [
				{ path: '/companies', name: <Translate id="company.beta" />, permission: ['ViewCompany'] },
				// { path: '/contacts', name: <Translate id="contacts" />, permission: ['ViewContact'] },
				{ path: '/contacts-v2', name: <Translate id="contacts" />, permission: ['ViewContact'] }
			]
		}
	];
}

export default CompaniesRoutes;
