import { DashboardContext, DEFAULT_LAYOUTS } from '../../Dashboard';
import { Translate, translateToString } from '../../../../styles/global/translate';
import Input from '../../../../components_v2/input/Input';
import DropdownOwners from '../../../../components_v2/dropdown/DropdownOwners';
import { DropdownSearchCompanies } from '../../../../components_v2/dropdown/DropdownSearch';
import Dropdown from '../../../../components_v2/dropdown/Dropdown';
import { ALL_GRANULARITY } from '../../../../components_v2/toolbarFilter/time/GranularitySelect';
import { DropdownData } from '../../../../components_v2/dropdown/model/Model';
import { Granularity } from '../../../../../../typelib/tabula/typings/bindings/time/Granularity';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../../components_v2/popup/PopupCreation';
import * as React from 'react';
import {
	BottomBar,
	CreationPopupTitle, CreatorProps,
	DASHBOARD_CREATION_DROPDOWN_STYLE,
	DASHBOARD_INPUT_STYLE, DashboardPopupContext,
	PopupContainer
} from '../DashboardPopup';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../atoms/global/users';
import DropdownTagsCloudInDashBoardPopup from '../DropDownTagsCloudInDashboardPopup';

export default function ClientCompanyStatusCountCreator(props: CreatorProps) {
	const { onSave, handleBack } = props;
	const users = useRecoilValue(AUsers);
	const { currentItem } = useContext(DashboardContext)!;
	const {
		user,
		setUser,
		title,
		setTitle,
		changedItems,
		setChangedItems,
		company,
		setCompany,
		companyStatus,
		setCompanyStatus,
		granularity,
		setGranularity,
		companyStatuses,
		selectedTags
	} = useContext(DashboardPopupContext)!;

	return <> <PopupContainer isCreator>
		<CreationPopupTitle>{`${translateToString('parameter')} ${translateToString('dashboard.point_of_sale')}`}</CreationPopupTitle>
		<Input
			name='title' type='text' value={title}
			onChange={setTitle} inputStyle={DASHBOARD_INPUT_STYLE}
			label={translateToString('title')}
		/>
		<DropdownOwners
			border
			dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
			label={translateToString('users')}
			users={users.map(value => ({ value, label: value.name }))}
			onChange={setUser}
			selected={user}
			cancellable
		/>
		<DropdownSearchCompanies
			name='parent-company-selector'
			parent dropdownStyle={{ labelUp: true }}
			label={translateToString('Parent company')}
			onChange={setCompany}
			selectedValue={company}
			placeholder={translateToString('all')}
		/>
		<Dropdown
			dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
			name=''
			datalist={companyStatuses.map(cs => ({ value: cs, label: cs.name }))}
			label={translateToString('status')}
			onChange={setCompanyStatus}
			selectedValue={companyStatus}
			placeholder={translateToString('all')}
			cancellable
			required
		/>
		<Dropdown
			dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
			name=''
			datalist={ALL_GRANULARITY.map(g => ({ label: translateToString(`${g}`), value: g }))}
			label={translateToString('granularity')}
			selectedValue={{
				label: granularity ? translateToString(granularity) : '',
				value: granularity ?? undefined
			}}
			onChange={(value: DropdownData<Granularity>) => setGranularity(value.value)}
			placeholder={translateToString('all')}
			cancellable
		/>
		<DropdownTagsCloudInDashBoardPopup/>
	</PopupContainer>
	<BottomBar>
		<DefaultButton margin='0' buttonStyle={ButtonStyle.White} onClick={handleBack}><Translate
			id='back'/></DefaultButton>
		<DefaultButton
			margin='0'
			disabled={companyStatus?.value?.id === undefined}
			onClick={() => {
				const id = currentItem ? currentItem.id : { unsync: Date.now() };
				const layout = currentItem ? currentItem.layout : {
					...DEFAULT_LAYOUTS['ClientCompanyStatusCount'],
					i: JSON.stringify(id)
				};
				changedItems.push({
					item_id: company?.value.id ?? undefined,
					item_type: 'ClientCompanyStatusCount',
					additional_values: {
						user:user?.value?.id,
						company_status: companyStatus?.value.id, granularity,
						tags: selectedTags.length ? selectedTags.map(t => ({ id:t.id, operator:t.value.operator })) : undefined
					},
					id,
					layout,
					title: title?.trim().length ? title : undefined,
					screen_id:props.activeTab
				});
				setChangedItems([...changedItems]);
				onSave();
			}}><Translate id='save'/></DefaultButton>
	</BottomBar></>;
}