export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const MODAL_PRODUCT = 'MODAL_PRODUCT';
export const MODAL_CATEGORY = 'MODAL_CATEGORY';
export const MODAL_NEWROLE = 'MODAL_NEWROLE';
export const MODAL_DELETE = 'MODAL_DELETE';
export const MODAL_MEDIA_PRODUCT = 'MODAL_MEDIA_PRODUCT';
export const MODAL_MEDIA_DELETE = 'MODAL_MEDIA_DELETE';
export const ALERT_ACTION = 'ALERT_ACTION';
export const CLOSE_ALERT_ACTION = 'CLOSE_ALERT_ACTION';
export const SELECTED_USER = 'SELECTED_USER';
// export const MODAL_LEAVE = 'MODAL_LEAVE';

const initState = {
	miniSidebar: true,
	isModalProduct: false,
	typeModalProduct: 'new',
	isModalMedia: false,
	isModalMediaDelete: false,
	isModalCategory: false,
	isModalNewRole: false,
	isModalDelete: false,
	selectedUser: [],
	// isModalLeave: false,
	deleteId: null,
	messageDelete: '',
	alert: {
		text: '',
		show: false,
		type: 'success'
	}
};

export function toggleSidebar() {
	return dispatch => {
		dispatch({ type: TOGGLE_SIDEBAR });
	};
}

export function selectedUser(val) {
	return dispatch => {
		dispatch({
			type: SELECTED_USER,
			payload: val
		});
	};
}

export function modalDelete(val, id, messageDelete = '') {
	return dispatch => {
		dispatch({
			type: MODAL_DELETE,
			deleteId: id,
			payload: val,
			messageDelete
		});
	};
}

export function alertAction(val) {
	return dispatch => {
		dispatch({ type: ALERT_ACTION, payload: val });
	};
}

export function closeAlertAction(val) {
	return dispatch => {
		dispatch({ type: CLOSE_ALERT_ACTION, payload: val });
	};
}

export default function themeReducer(state = initState, action) {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return {
				...state,
				miniSidebar: !state.miniSidebar
			};

		case MODAL_PRODUCT:
			return {
				...state,
				isModalProduct: action.payload,
				typeModalProduct: action.typeModalProduct
			};

		case MODAL_CATEGORY:
			return {
				...state,
				isModalCategory: action.payload
			};

		case MODAL_MEDIA_PRODUCT:
			return {
				...state,
				isModalMedia: action.payload
			};

		case MODAL_MEDIA_DELETE:
			return {
				...state,
				isModalMediaDelete: action.payload,
				deleteId: action.deleteId,
				messageDelete: action.messageDelete
			};

		case MODAL_NEWROLE:
			return {
				...state,
				isModalNewRole: action.payload
			};

		case MODAL_DELETE:
			return {
				...state,
				isModalDelete: action.payload,
				deleteId: action.deleteId,
				messageDelete: action.messageDelete
			};

		case ALERT_ACTION:
			return {
				...state,
				alert: action.payload
			};

		case CLOSE_ALERT_ACTION:
			return {
				...state,
				alert: {
					text: '',
					show: false,
					type: ''
				}
			};

		case SELECTED_USER:
			return {
				...state,
				selectedUser: action.payload
			};

		default:
			return state;
	}
}
