import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { AProducts, Product } from '../../atoms/product';
import { render, set, set_products, set_client_company, set_form_instance, set_field_values } from 'template-web';
import { getCompanyDetail } from '../client-companies/data/action';
import { Instance, InstanceField, getInstanceByUuid, getInstanceFields } from './actions';
import { UserWithAdditionalFields } from '../../containers_v2/settings/userSettings/myProfile';
import { AProfile } from '../../atoms/global/users';
import * as _ from 'lodash';
import { getPromotions } from '../promotions/actions';

async function setWasmState(products: Product[], user: UserWithAdditionalFields, form_instance_uuid: string, instance?: Instance, fields?: InstanceField[]) {
	if (!setWasmState['loaded']) {
		const p = products.reduce((acc, product) => ({ ...acc, [product.uuid]: product }), {});
		set_products(JSON.stringify(p));
		set('user', JSON.stringify(user));
		setWasmState['loaded'] = true;
	}
	if (setWasmState['form_instance_uuid'] !== form_instance_uuid) {
		const get_form_instance = instance ?? await getInstanceByUuid(form_instance_uuid);
		const form_instance = _.cloneDeep(get_form_instance);
		set_form_instance(JSON.stringify(form_instance));
		setWasmState['form_instance_uuid'] = form_instance_uuid;
		const get_field_values = fields ?? await getInstanceFields(form_instance_uuid);
		const field_values = _.cloneDeep(get_field_values);
		set_field_values(JSON.stringify(field_values));
		const client_company_id = form_instance.linked_company_id;
		if (setWasmState['client_company_id'] !== client_company_id) {
			if (client_company_id) {
				let client_company;
				try {
					client_company = await getCompanyDetail(client_company_id);
					if (!client_company)
						throw new Error('Company not found');
				} catch (e) {
					console.error(e);
					set_client_company('{}');
					setWasmState['client_company_id'] = undefined;
					return;
				}
				set_client_company(JSON.stringify(client_company));
				setWasmState['client_company_id'] = client_company_id;
				const promos = await getPromotions();
				const company_promos = promos.filter(promo => {
					promo.products.forEach(function(promo_product, index) {
						const product = products.find(product => product.id === promo_product.id);
						if (product) {
							this[index] = { ...promo_product, uuid: product.uuid };
						}
					}, promo.products);
					return promo.companies.some(c => c.id === client_company_id);
				});
				set('company_promos', JSON.stringify(company_promos));
			} else {
				set_client_company('{}');
				setWasmState['client_company_id'] = undefined;
			}
		}
	}
}

export function TemplateRenderer(props: { template: string, form_instance_uuid: string, instance?: Instance, fields?: InstanceField[] }) {
	const { template } = props;
	const [html, setHtml] = React.useState<string>('');
	const products = useRecoilValue(AProducts);
	const user = useRecoilValue(AProfile);

	React.useEffect(() => {
		setWasmState(products, user, props.form_instance_uuid, props.instance, props.fields).then(() => {
			setHtml(render(template));
		});
	}, [template, props.form_instance_uuid]);

	return <iframe id="printf" name="printf" srcDoc={html} style={{ width: '100%', height: 'calc(100vh - 115px)', border: 'none' }} />;
}