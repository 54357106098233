import { Cookies } from 'react-cookie';
import { LOGOUT_SUCCESS } from '../actions/auth.action';
import JwtAuthentication from '../../helpers/jwtAuthentication';

const cookies = new Cookies();

const localToken = JwtAuthentication.checkExpirity(cookies.get('id_token'));

const initState = {
	isFetching: false,
	isAuthenticated: !!localToken.token,
	isLoading: false
};

export default function auth(state = initState, action) {
	switch (action.type) {
		case LOGOUT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: true,
				isAuthenticated: false,
				isLoading: false
			});
		default:
			return state;
	}
}
