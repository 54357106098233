import * as React from 'react';

interface PopupStateType {
	popupState: string[],
	setPopupState: React.Dispatch<React.SetStateAction<string[]>>,
}

const defaultBehaviour: PopupStateType = {
	popupState: [],
	setPopupState: () => null
};

export const PopupStateContext = React.createContext<PopupStateType>(defaultBehaviour);

export function PopupStateProvider(props: {
	children: React.ReactNode
}) {
	const [popupState, setPopupState] = React.useState<string[]>([]);

	return <PopupStateContext.Provider value={{ popupState, setPopupState }}>
		{props.children}
	</PopupStateContext.Provider>;
}