import * as React from 'react';
import { Company } from '../../containers_v2/client-companies/model/Model';
import NewCompany from '../../containers_v2/client-companies/popup/NewCompany';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';

function ClientCompanyCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  onCreate?: (value: Company) => void
  company?: Company
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, top: '0%' }}
			popupMode={PopupMode.Details}
			content={<NewCompany
				onClickOut={() => close()}
				onCreate={(value) => (props.onCreate != null) && props.onCreate(value)}
				company={props.company}
			/>}
		/>
	);
}

export default ClientCompanyCreation;
