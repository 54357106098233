import * as React from 'react';
import { BodyMainTextEditable } from './style/TextEditableStyle';
import { blurOnEnterPressed } from './Input';

interface State {
  error: boolean
  value: string | undefined
}

export interface TextEditableResult {
  value: string
  isValid: boolean
}

export interface TextEditableStyle {
  color?: string
  fontWeight?: string
  fontSize?: string
}

function TextEditable(props: {
  value?: string
  error?: boolean
  validate?: Array<(value: string) => boolean>
  onChange?: (value: TextEditableResult, event: React.FocusEvent<HTMLParagraphElement, Element>) => void
  style?: TextEditableStyle
  readOnly?: boolean
}): JSX.Element {
	const [state, setState] = React.useState<State>({
		error: props.error ?? false,
		value: props.value
	});

	React.useEffect(() => {
		setState({
			error: props.error ?? false,
			value: props.value
		});
	}, [props.value, props.error]);

	return (
		<BodyMainTextEditable
			color={props.style?.color}
			fontSize={props.style?.fontSize}
			fontWeight={props.style?.fontWeight}
			cursor={props.readOnly ? 'initial' : 'cursor'}
			readOnly={props.readOnly}

			suppressContentEditableWarning
			contentEditable={!props.readOnly}
			error={state.error}
			onInput={e => {
				const result = props.validate?.some(f => f(e.currentTarget.innerText.trim())) ?? true;
				setState({ ...state, error: !result });
			}}

			onKeyDownCapture={blurOnEnterPressed}
			onBlur={(e) => {
				const newValue = e.currentTarget.innerText.trim()
              ;(props.onChange != null) && props.onChange({ value: newValue, isValid: !state.error }, e);
			}}
		>
			{state.value}
		</BodyMainTextEditable>
	);
}

export default TextEditable;
