import trash_black from 'images/ui_icon/trash_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import Avatar from '../../../../../components_v2/avatar/Avatar';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { UserToolTip } from '../../../../../styles/global/ToolTip';
import { LoadingStateEnum } from '../../../../import/model';
import { deleteOrder } from '../../../../orders/data/Data';
import { formatCurrency } from '../../../../reports/utils';
import { getCompanyOrders } from '../../../data/action';
import { CompanyOrder } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import { Card, CardImage, CardLeft, CardMiddle, CardRight, CardText, CardTitle, Container, SeeMore, Trash } from '../style/LinkedElementStyle';

interface DeletionState {
	isOpen: boolean
	order?: CompanyOrder
}

function Order(props: {
  clientCompanyId: number,
  isExtend?: boolean,
  onDelete: () => void
}): JSX.Element {

	const translate = getTranslate(storeLang.getState().localize);
	
	const DEFAULT_LIMIT = 3;

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [orders, setOrders] = React.useState<CompanyOrder[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [offset, setOffset] = React.useState<number>(0);

	const [deletionState, setDeletionState] = React.useState<DeletionState>({ isOpen: false });

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getCompanyOrders({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset
		}).then(response => {
			setOrders(response.orders);
			setTotal(response.total);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getCompanyOrders({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset: newOffset
		}).then(response => {
			setOrders(orders.concat(response.orders));
			setTotal(response.total > 0 ? response.total : total);
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}

	function confirmationDeletionOrder(): void {
		if (!deletionState.order) {
			return;
		}
		
		deleteOrder(deletionState.order.order_id).then(res => {
			if (res) {
				props.onDelete();
				setOrders(orders.filter(o => o.order_id !== deletionState.order?.order_id));
				setOffset(offset - 1);
				setTotal(total - 1);
			}
		});
	}

	return (
		(<Container>
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: orders.length > 0
						? orders.map(o => <>
							<Card onClick={() => window.open(`/orders?id=${o.order_id}`, '_blank')} isExtend={props.isExtend ?? false}>
								<CardLeft>
									<UserToolTip userId={o.owner_id} left='-35px'>
										{o.owner_photo && o.owner_photo.length > 0
											? <CardImage url={o.owner_photo} />
											: <Avatar userId={o.owner_id} width="35px" name={o.owner_name.trim()} />}
									</UserToolTip>
								</CardLeft>
								<CardMiddle>
									<CardTitle>{o.owner_name.trim()}</CardTitle>
									<CardText>{o.order_reference && o.order_reference.length > 0 ? o.order_reference : '-'}</CardText>
									<CardText>{moment.utc(o.order_date).local().format('DD/MM/YYYY')}</CardText>
									<CardText>{`${formatCurrency(o.order_sum_with_tax)}`}</CardText>
								</CardMiddle>
								<CardRight>
									<Trash src={trash_black} onClick={(event) => {
										event.stopPropagation();
										setDeletionState({ ...deletionState, isOpen: true, order: o });
									} } />
								</CardRight>
							</Card>
						</>)
						: <NoDataText><Translate id='no_orders' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && orders.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
			<PopupDeletion 
				isOpen={deletionState.isOpen} 
				records={0} 
				translationKey=''
				customTitle={translate('company.detail.linked_elements.order').toString()}
				customMessage={translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${deletionState.order && deletionState.order.order_reference.length > 0 ? deletionState.order.order_reference : '-'}'`)} 
				onClickOut={() => setDeletionState({ ...deletionState, isOpen: false })}
				onValidation={() => confirmationDeletionOrder()}
				noVerification
			/>
		</Container>)
	);
}

export default Order;
