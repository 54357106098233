import trash_black from 'images/ui_icon/trash_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { Tag } from '../../../containers_v2/products/model';
import storeLang from '../../../helpers/storeLang';
import { TagOperator } from '../../dropdown/model/Model';
import { FilterAction, FilterTree, FilterValueResult, FilterValueResultUnion } from '../model/Model';
import {
	Container,
	FilterEditor,
	FilterEditorContainer,
	FilterIcon,
	FilterRow,
	FilterTextSummary,
	GreyRectangle,
	HoverableContainer,
	HoverableDiv,
	RectangleCombinatorElement,
	RectangleCombinatorSwitch,
	Span,
	SummaryBlock
} from '../style/AdvancedFilterStyle';
import { convertFilter, invert } from './FilterList';
import { FlexDiv } from '../../../containers_v2/products/style';
import { translateToString } from '../../../styles/global/translate';

function getFilterJSX(result: FilterValueResult, setSelectedFilter: (f: FilterValueResult, isCategoryClicked: boolean) => void, weight: number): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const onClickValue = () => setSelectedFilter(result, false);

	const baseSummanyBlock = (result: FilterValueResult, operator = false) => {
		let jsxOperator: React.ReactNode | undefined = undefined;
		if (operator) {
			switch (result.type) {
				// @ts-expect-error TODO TOFIX THIS IS LEGACY FONT ONLY
				case 'date':
				case 'temporal':
					jsxOperator = translate('days').toString();
			}
		}
		const isList = (result.displayedValue ?? result.value) === undefined && result.values !== undefined;
		const listLen = result.values?.length ?? 0;
		return <SummaryBlock>
			<HoverableDiv weight={weight} onClick={onClickValue}>
				<FilterTextSummary>
					<Span clickable>
						{result.title}{result.titleComment ? ` (${result.titleComment})` : ''}
					</Span>
					{translate(`global.filter.action.${result.action}`).toString()}
					{isList ? result.values?.map((v, i) => <><Span isValue>{v}</Span> {listLen - 1 !== i && translateToString('or')} </>) : <Span isValue>
						{result.displayedValue ?? result.value ?? result.values?.join(' ' + translateToString('or') + ' ')}
					</Span>}
					
					{jsxOperator}
				</FilterTextSummary>
			</HoverableDiv>
		</SummaryBlock>;
	};

	switch (result.action) {
		case FilterAction.CONTAINS:
		case FilterAction.DOES_NOT_CONTAINS:
		case FilterAction.CONTAINS_LIST:
		case FilterAction.DOES_NOT_CONTAINS_LIST:
		case FilterAction.START_WITH:
		case FilterAction.END_WITH:
		case FilterAction.IS_EQUAL:
		case FilterAction.IS_NOT_EQUAL:
		case FilterAction.MORE_THAN:
		case FilterAction.LESS_THAN:
		case FilterAction.EQUAL_OR_MORE_THAN:
		case FilterAction.EQUAL_OR_LESS_THAN:
			return baseSummanyBlock(result);
		case FilterAction.LESS_THAN_LAST:
		case FilterAction.MORE_THAN_LAST:
		case FilterAction.LESS_THAN_NEXT:
		case FilterAction.MORE_THAN_NEXT:
			return baseSummanyBlock(result, true);
		case FilterAction.IS_EMPTY:
		case FilterAction.IS_NOT_EMPTY:
		case FilterAction.IS_TRUE:
		case FilterAction.IS_FALSE:
			return (
				<SummaryBlock>
					<HoverableDiv weight={weight} onClick={onClickValue}>
						<FilterTextSummary>
							<Span clickable>{result.title}{result.titleComment ? ` (${result.titleComment})` : ''}</Span>
							<Span isValue>{translate(`global.filter.action.${result.action}`).toString()}</Span>
						</FilterTextSummary>
					</HoverableDiv>
				</SummaryBlock>
			);
		case FilterAction.IS:
		case FilterAction.IS_NOT: {
			let fn;
			switch (result.type) {
				case 'tag':
					fn = (v, i) => <><Span isValue>{(v.tag as Tag).name}</Span>{i !== (result.values?.length ?? 0) - 1 && ` ${translate(v.operator)}`}</>;
					break;
				case 'select':
				case 'multi_select':
					fn = (v: string, i: number) => <>{i === 0 ? ' ' : `${translate('and')} `}<Span isValue>{v}</Span></>;
					break;
				default: fn = (v, i) => <>{i === 0 ? ' ' : `${translate('or')} `}<Span isValue>{v.name}</Span></>;
			}
			return <SummaryBlock>
				<HoverableDiv weight={weight} onClick={onClickValue}>
					<FilterTextSummary>
						<Span clickable>{result.title}{result.titleComment ? ` (${result.titleComment})` : ''}</Span>
						{translate(`global.filter.action.${result.action}`).toString()}
						{result.values ? result.values.map(fn) : <Span isValue>{result.displayedValue ?? result.value}</Span>}
					</FilterTextSummary>
				</HoverableDiv>
			</SummaryBlock>;
		}
		case FilterAction.BETWEEN:
		case FilterAction.NOT_BETWEEN: {
			// @ts-expect-error TODO TOFIX THIS IS LEGACY FONT ONLY
			if (result.type === 'date' || result.type === 'temporal') {
				const start = result.values?.[0];
				const end = result.values?.[1];
				if (start && end && typeof start === 'string' && typeof end === 'string') {
					const localStart = moment(start).local().startOf('day');
					if (localStart.toISOString() === start && moment(end).local().endOf('day').toISOString() === end) {
						const newResult = { ...result };
						if (newResult.action === FilterAction.BETWEEN) {
							newResult.action = FilterAction.IS_EQUAL;
						} else {
							newResult.action = FilterAction.IS_NOT_EQUAL;
						}
						newResult.value = localStart.format('L');

						return baseSummanyBlock(newResult);
					}
				}
			}
			const v1 = result.displayedValues?.[0] ?? (Array.isArray(result.values) && result.values[0]);
			const v2 = result.displayedValues?.[1] ?? (Array.isArray(result.values) && result.values[1]);
			return (
				(result.values != null) && result.values.length >= 2 && typeof v1 !== 'object' && typeof v2 !== 'object'
					? <SummaryBlock>
						<HoverableDiv weight={weight} onClick={onClickValue}>
							<FilterTextSummary>
								<Span clickable>{result.title}{result.titleComment ? ` (${result.titleComment})` : ''}</Span> {translate(`global.filter.action.${result.action}`).toString()}
								<Span isValue>{v1}</Span> {translate('global.filter.action.and').toString()}
								<Span isValue>{v2}</Span>
							</FilterTextSummary>
						</HoverableDiv>
					</SummaryBlock> : <></>
			);

		}
		default:
			return (
				<></>
			);
	}
}

function Summary(props: {
	onAdd: (path: number[]) => void
	onDelete: (path: number[]) => void
	onInvert: (path: number[], id: number) => void
	filters: FilterValueResultUnion
	overflow?: string
	noPadding?: boolean
	updateFilters: (filters: FilterValueResultUnion) => void
	setSelectedFilter: (f: FilterTree, depth: number[], filter: FilterValueResult, isCategoryClicked: boolean) => void
}): JSX.Element {

	return (
		<Container overflow={props.overflow} padding={props.noPadding ? '0' : undefined}>
			{renderFilterValueResultUnion({
				result: props.filters,
				depth: [],
				onDelete: props.onDelete,
				onAdd: props.onAdd,
				onInvert: props.onInvert,
				combinator: 'and',
				setSelectedFilter: props.setSelectedFilter
			})}
		</Container>
	);
}

function renderFilterValueResultUnion(props: { result: FilterValueResultUnion, depth: number[], onDelete: (id: number[]) => void, onAdd: (id: number[]) => void, onInvert: (path: number[], id: number) => void, combinator: TagOperator, setSelectedFilter: (f: FilterTree, depth: number[], filter: FilterValueResult, isCategoryClicked: boolean) => void }): JSX.Element {
	if ('val' in props.result) {
		return (
			<HoverableContainer>
				<FilterRow>
					{getFilterJSX(props.result.val, (f, isCategoryClicked) => props.setSelectedFilter(convertFilter(f), props.depth, f, isCategoryClicked), props.depth.length)}
					<FlexDiv gap='15px'>
						<FilterEditor onClick={() => props.onAdd(props.depth)}><Translate id={props.combinator} /></FilterEditor>
						<FilterIcon src={trash_black} onClick={() => props.onDelete(props.depth)}/>
					</FlexDiv>
				</FilterRow>
			</HoverableContainer>
		);
	} else {
		const { array, combinator } = props.result;
		return <GreyRectangle weight={props.depth.length}>
			{
				array
					.map((e, i) => renderFilterValueResultUnion({
						result: e,
						depth: [...props.depth, i],
						onDelete: props.onDelete,
						onAdd: props.onAdd,
						onInvert: props.onInvert,
						combinator: invert(combinator),
						setSelectedFilter: props.setSelectedFilter
					}))
					.reduce((a, e, i) => {
						return a === null ? [e] : [...a, <RectangleCombinator key={`rectangleCombinator[${i}]`}
							onClick={() => props.onInvert(props.depth, i - 1)} combinator={combinator}/>, e];
					}, null)
			}
			{
				props.depth.length == 0 && props.result.array.length < 2 ?
					<FilterEditorContainer>
						<FilterEditor onClick={() => {
							props.combinator = 'and';
							props.onAdd(props.depth);
						}}><Translate id='and' /></FilterEditor>
						<FilterEditor onClick={() => {
							props.combinator = 'or';
							props.onAdd(props.depth);
						}}><Translate id='or' /></FilterEditor>
					</FilterEditorContainer>
					:
					<FilterEditorContainer>
						<FilterEditor onClick={() => props.onAdd(props.depth)}><Translate id={combinator} /></FilterEditor>
					</FilterEditorContainer>
			}
		</GreyRectangle>;
	}
}

function RectangleCombinator(props: { combinator: TagOperator, onClick: () => void }): JSX.Element {
	const c = props.combinator;
	return (
		<RectangleCombinatorSwitch onClick={props.onClick}>
			<RectangleCombinatorElement enabled={c == 'and'}>
				<Translate id='and'/>
			</RectangleCombinatorElement>
			<RectangleCombinatorElement enabled={c == 'or'}>
				<Translate id='or'/>
			</RectangleCombinatorElement>
		</RectangleCombinatorSwitch>
	)
	;
}

export default Summary;
