import { RecoilState, atom, selector } from 'recoil';
import { AtomCategory, AtomState } from '../utils/model/Model';
import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';

// ----------[ FreeFormTemplatePicker ]---------- //

export type FreeFormTemplate = {
	id: string,
	name: string,
	icon: string
}

const AGlobalFreeFormTemplatePicker: AtomState<FreeFormTemplate[] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_free_form_template_picker',
		default: undefined
	})
};

export const AFreeFormTemplatePicker: RecoilState<FreeFormTemplate[] | undefined> = selector({
	key: 'atom_free_form_template_picker',
	get: () => AGlobalFreeFormTemplatePicker.atom,
	set: ({ set }, newValue) => set(AGlobalFreeFormTemplatePicker.atom, newValue)
});


export function getFreeFormTemplates(): Promise<FreeFormTemplate[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/free-forms/template`).then(res => res.data);
}