import system_setting_icon from 'images/setting_icons/system_setting_icon.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import swal from 'sweetalert2';
import Dropdown from '../../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../../components_v2/dropdown/model/Model';
import { Checkbox } from '../../../../components_v2/filterList/style/Style';
import storeLang from '../../../../helpers/storeLang';
import { deleteOrder, updateOrder } from '../../data/Data';
import { Order, OrderStatus, Owner } from '../../model/Model';
import { Row } from '../model/Model';
import { TableRow, TableRowStatus, TableRowStatusBackground, TableRowTitle } from '../style/Style';
import { formatCurrency } from '../../../../containers_v2/reports/utils';
import { DarkGreySidely, RedSidely } from '../../../../styles/global/css/Utils';
import { Column } from '../../../../components_v2/table/Table';
import { MappingResume } from '../../../../../../web-types/mapping';
import { To } from '../../../../Types';
import Restricted from '../../../permissions/Restricted';
import { IsAllowedToProps } from '../../../permissions/PermissionProvider';
import optionGrey from 'images/icon/options_grey.png';
import { blurOnEnterPressed } from '../../../../components_v2/input/Input';
import { IsAllowedToDefinition } from '../../../permissions/PermissionContext';

function updateOrderEdit(event: React.FocusEvent<HTMLDivElement>, orderId: number) {
	updateOrderReference(orderId, event.currentTarget.innerText.trim());
	return false;
}

function updateOrderReference(orderId: number, reference: string): void {
	updateOrder({ id: orderId, reference } as Order);
}

function updateOrderStatus(orderId: number, statusId: number): void {
	updateOrder({ id: orderId, order_status_id: statusId } as Order);
}

function warningdDelete(id: number, name: string, onDelete?: Function) {
	const translate = getTranslate(storeLang.getState().localize);

	swal({
		title: translate('global.fire.alert_title').toString(),
		text: translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${name}'`),
		type: 'warning',
		confirmButtonText: translate('global.fire.confirm_message').toString(),
		confirmButtonColor: RedSidely
	})
		.then(result => {
			if (result.value) {
				deleteOrder(id).then(response => {
					if (response.valueOf()) {
						(onDelete != null) && onDelete(id);
					}
				});
			}
		});
}

function TableColumns(
	allSelected: boolean,
	statuses: DropdownData[],
	isAllowed: IsAllowedToDefinition,
	onClick: Function | undefined,
	onDelete: Function | undefined,
	onDuplicate: Function | undefined,
	onClickChecbox: (row: Row | null, updateAll: boolean | null) => void | undefined,
	onUpdate: (row: Row) => void | undefined,
	onExport: (orderId: number) => void | undefined,
	externalMappings: MappingResume[],
	users: Owner[]
): Array<Column<Row>> {
	const translate = getTranslate(storeLang.getState().localize);
	const DUPLICATE = 0; const DELETE = 1; const EXPORT_PDF = 2;
	const you = users?.find(u => u.isYou)?.name;
	if (!you) return [];

	const columns: Array<Column<Row>> = [
		{
			id: 'order_date',
			Header: translate('orders.templateOrder.field.date').toString(),
			accessor: (row: Row) => {
				return <TableRowTitle onClick={() => (onClick != null) && onClick(row.order_id)}>{moment(row.date).format('DD/MM/YYYY')}</TableRowTitle>;
			},
			width: 200
		},
		{
			id: 'client_company_name',
			Header: translate('orders.templateOrder.field.company').toString(),
			accessor: (row: Row) => {
				return <TableRow>{row.company}</TableRow>;
			},
			width: 200
		},
		{
			id: 'order_reference',
			Header: translate('orders.templateOrder.field.commandName').toString(),
			accessor: (row: Row) => {
				return <TableRow contentEditable suppressContentEditableWarning onKeyDownCapture={blurOnEnterPressed} onBlur={(e) => {
					const newRow = { ...row };
					newRow.edit = null
					;(onUpdate != null) && onUpdate(newRow);
					updateOrderEdit(e, row.order_id);
				}}>{row.name}</TableRow>;
			},
			editable: true,
			width: 200
		},
		{
			id: 'owner_name',
			Header: translate('orders.templateOrder.field.owner').toString(),
			accessor: (row: Row) => {
				return <TableRow>{row.owner}</TableRow>;
			},
			width: 200
		},
		{
			id: 'product_count',
			Header: translate('orders.templateOrder.field.totalProduct').toString(),
			accessor: (row: Row) => {
				return <TableRow>{row.quantity}</TableRow>;
			},
			width: 200
		},
		{
			id: 'sum_with_tax',
			Header: translate('orders.templateOrder.field.amount').toString(),
			accessor: (row: Row) => {
				return <TableRow>{`${formatCurrency(row.amount)}`}</TableRow>;
			},
			width: 200
		},
		{
			id: 'order_status_name',
			Header: translate('orders.templateOrder.field.orderStatus').toString(),
			accessor: (row: Row) => <Restricted to={{ objectAction: 'UpdateOrder' }} fallback={<TableRowStatusBackground noPointer>
				<TableRowStatus backgroundColor={row.order_status_color}>{row.order_status}</TableRowStatus>
			</TableRowStatusBackground>}>
				<Dropdown
					dropdownStyle={{
						containerWidth: '100%',
						containerJustifyContent: 'flex-start',
						optionWidth: '200px',
						optionHeight: '150px',
						optionLeft: '-60px',
						optionFontWeight: '500',
						optionFontColor: '#FFFFFF',
						optionPadding: '5px 10px 5px 10px',
						optionTextWidth: 'fit-content',
						optionUp: true
					}}
					datalist={statuses}
					name={'select_status'}
					containerClickable
					autoOptionUp
					JSXButton={() => <TableRowStatusBackground>
						<TableRowStatus backgroundColor={row.order_status_color}>{row.order_status}</TableRowStatus>
					</TableRowStatusBackground>}
					onChange={(value: DropdownData) => {
						updateOrderStatus(row.order_id, (value.value as OrderStatus).id);
						const newRow = { ...row };
						newRow.order_status_id = (value.value as OrderStatus).id;
						newRow.order_status = (value.value as OrderStatus).name;
						newRow.order_status_color = (value.value as OrderStatus).color_code; (onUpdate != null) && onUpdate(newRow);
					} } />
			</Restricted>,
			width: 200
		},
		...externalMappings.filter(em => em.mapping_type_id == 4).map(mapping => ({
			id: `external_column[${mapping.name}]`,
			Header: mapping.name,
			accessor: (row: Row) => <TableRow>{row.external_ids?.find(ei => ei.mapping_name == mapping.name)?.external_id}</TableRow>,
			disableSortBy: true,
			disableOrderBy: true,
			width: 200
		})),
		...externalMappings.filter(em => em.mapping_type_id == 1).map(mapping => ({
			id: `external_column_companies[${mapping.name}]`,
			Header: mapping.name,
			accessor: (row: Row) => <TableRow>{row.external_company_ids?.find(ei => ei.mapping_name == mapping.name)?.external_id}</TableRow>,
			disableSortBy: true,
			disableOrderBy: true,
			width: 200
		}))
	];

	// if (deleteAllowed) {
	// 	columns.unshift(
	// 		{
	// 			id: 'select',
	// 			Header: <Checkbox isActive={allSelected} onClick={() => {
	// 				const newValue = !allSelected;
	// 				onClickChecbox?.(null, newValue);
	// 			}} />,
	// 			accessor: (row: Row) => {
	// 				return (
	// 					<Checkbox isActive={row.checked} onClick={() => {
	// 						const newValue = { ...row };
	// 						newValue.checked = !newValue.checked;
	// 						onClickChecbox?.(newValue, null);
	// 					}} />
	// 				);
	// 			},
	// 			width: 40,
	// 			disableSortBy: true,
	// 			unresizeable: true
	// 		}
	// 	);
	// }

	columns.push(
		{
			id: 'actions',
			Header: <img src={system_setting_icon} style={{ width: 18 }} />,
			accessor: (row: Row) => {
				const list: DropdownData[] = [];
				// if (isAllowed({ objectAction: 'CreateOrder' }, { ownerId: parseInt(row.owner) })) {
				// 	list.push(
				// 		{
				// 			label: translate('global.quick_action.duplicate').toString(),
				// 			value: DUPLICATE
				// 		}
				// 	);
				// }
				if (isAllowed({ objectAction: 'DeleteOrder' }, { ownerId: parseInt(row.owner) })) {
					list.push(
						{
							label: translate('global.quick_action.delete').toString(),
							value: DELETE
						}
					);
				}
				if (isAllowed({ objectAction: 'CreateExport' }, { ownerId: parseInt(row.owner) })) {
					list.push({
						label: translate('global.quick_action.exportPdf').toString(),
						value: EXPORT_PDF
					});
				}
				if (list.length === 0) return;
				return (
					<Dropdown
						name={'quick_action'}
						datalist={list}
						readOnly
						dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
						JSXButton={() =>
							<img
								src={optionGrey}
								width={25}
								height={25}
								style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
							/>
						}
						// freezeShowLabel
						// defaultShowLabel="Action"
						onChange={(value: DropdownData) => {
							if (value.value === DELETE) {
								warningdDelete(row.order_id, row.name, onDelete);
							} else if (value.value === DUPLICATE) {
								(onDuplicate != null) && onDuplicate(row.order_id);
							} else if (value.value === EXPORT_PDF) {
								(onExport != null) && onExport(row.order_id);
							}
						}} />
				);
			},
			width: 40,
			disableSortBy: true,
			unresizeable: true,
			freeze: 'right'
		}
	);

	return columns;
}

export {
	TableColumns
};
