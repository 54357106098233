import folderOutline from 'images/icon/folder-outline.png';
import * as React from 'react';
import { Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { Translate } from 'react-localize-redux';
import { NavLink } from 'react-router-dom';
import { getCategories } from './action';

// LEGACY

interface Category {
  id: number
  name: string
  description?: string
  hierarchy_level: number
  parent_id?: number
  children: Category[]
}

export default function LegacyCategoryModal(props: {
  isOpen: boolean
  toggle: () => void
  onSubmit: (id: number | undefined, name: string | undefined) => void
  currentId?: number
}): JSX.Element {
	const { isOpen, toggle, onSubmit, currentId } = props;
	const [categories, setCategories] = React.useState<Category[]>([]);
	const [selected, setSelected] = React.useState<number | undefined>(currentId);
	const [openedIds, setOpenedIds] = React.useState<number[]>([]);

	React.useEffect(() => {
		getCategories()
			.then(res => setCategories(res.data))
			.catch(console.log);
	}, []);

	const handleOpenedIds = id => {
		const tempArray = [...openedIds];
		if (openedIds.includes(id)) {
			const index = tempArray.indexOf(id);
			tempArray.splice(index, 1);
		} else {
			tempArray.push(id);
		}
		setOpenedIds(tempArray);
	};

	const renderRecursive = (list: Category[], depth: number) => (
		<div>
			{list.map((data, i) => {
				const isParent =
                    data.children && data.children.length > 0;
				return (
					(<div key={`RecursiveCategory[${depth}][${i}]`}>
						<div
							className={`${isParent && 'pointer'} row my-3`}
							style={{ marginLeft: `${(data.hierarchy_level - 1) * 20}px` }}
						>
							<input
								className="my-auto mr-2"
								type="radio"
								value={data.id}
								checked={selected === data.id}
								onChange={() => setSelected(data.id)}
							/>
							<div onClick={() => handleOpenedIds(data.id)}>
								{isParent && (
									<img
										src={folderOutline}
										alt="folder"
										className="mr-2"
									/>
								)}
								{data.name}
							</div>
						</div>
						<Collapse isOpen={openedIds.includes(data.id)}>
							{isParent && renderRecursive(data.children, depth + 1)}
						</Collapse>
					</div>)
				);
			})}
		</div>
	);

	return <Modal isOpen={isOpen} toggle={toggle}
		// className={className}
		centered>
		<ModalHeader toggle={toggle} style={{ borderBottom: 'none' }}>
			<Translate id="products.select_category" />
		</ModalHeader>
		<ModalBody style={{ textAlign: 'center' }}>
			{categories.length > 0
				? <div className="card-modal-product p-2">
					{renderRecursive(categories, 0)}
				</div>
				: <div style={{ fontSize: 14 }}>
					<Translate id="products.no_categories_yet" />
					{'    '}
					<NavLink to="/settings#categories_management" style={{ fontWeight: 500 }}>
						<Translate id="products.create_categories" />
					</NavLink>
				</div>
			}
		</ModalBody>
		<ModalFooter style={{ borderTop: 'none' }}>
			<Button
				type="button"
				color="primary"
				className="px-5 mb-2"
				onClick={() => {
					onSubmit(selected, findCategory(selected, categories)?.name);
					toggle();
				}}
			>
				<Translate id="validate" />
			</Button>
		</ModalFooter>
	</Modal>;
}

function findCategory(id: number | undefined, categories: Category[]): Category | undefined {
	if (!id) return undefined;
	for (const cat of categories) {
		if (cat.id === id) return cat;
		const finded = findCategory(id, cat.children);
		if (finded) return finded;
	}
}
