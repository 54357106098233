import * as React from 'react';
import { createFrequency, deleteFrequency, getFrequencies } from '../data/action';
import { CompanyDetailDisplayer, CompanyDetailDisplayerWrapper, TitleAndChild } from './Detail';
import { Translate } from '../../../styles/global/translate';
import { BodyMainText, BodyMainTextEditable } from '../style/PopupStyle';
import { AEventTypes, AtomEventType } from '../../../atoms/global/events';
import { useRecoilValue } from 'recoil';
import Restricted from '../../permissions/Restricted';
import { FlexDiv } from '../../products/style';
import { blurOnEnterPressed } from '../../../components_v2/input/Input';

export type Frequency = {
	created_at: string,
	event_type: number,
	frequency: number,
	id: number,
	updated_at?: string
};

export default function Frequencies(props: { clientCompanyId: number, isSelf: boolean }) {
	const [frequencies, setFrequencies] = React.useState<Frequency[]>();
	const event_type = useRecoilValue(AEventTypes);

	const fetchFrequencies = () => getFrequencies(props.clientCompanyId).then(setFrequencies);

	React.useEffect(() => {
		fetchFrequencies();
	}, []);

	return <FlexDiv flow="column" width='100%'>
		<TitleAndChild title={<Translate id='yearly_frequencies' />}>
			<CompanyDetailDisplayerWrapper>
				{event_type.map(et => <FrequencyDisplayer
					key={`FrequencyEventType[${et.id}]`}
					eventType={et}
					frequency={frequencies?.find(f => f.event_type === et.id)}
					isSelf={props.isSelf}
					refresh={fetchFrequencies}
					clientCompanyId={props.clientCompanyId}
				/>)}
			</CompanyDetailDisplayerWrapper>
		</TitleAndChild>
	</FlexDiv>;
}

function FrequencyDisplayer(props: {eventType: AtomEventType, frequency: Frequency | undefined, isSelf: boolean, refresh: () => void, clientCompanyId: number }) {
	const { eventType, frequency, clientCompanyId, refresh } = props;
	return <CompanyDetailDisplayer title={<Translate id={`event.${eventType.name}`} />}>
		<Restricted
			to={{ objectAction: 'UpdateCompany' }}
			isSelf={props.isSelf}
			fallback={<BodyMainText display='block' width='100%'>{frequency?.frequency ?? 0}</BodyMainText>}
		>
			<BodyMainTextEditable
				height='fit-content'
				marginLeft='0'
				minHeight='unset'
				width='100%'
				suppressContentEditableWarning
				contentEditable
				onKeyDownCapture={blurOnEnterPressed}
				onBlur={(e) => {
					const newFrequency = parseInt(e.currentTarget.innerText.trim());
					if (isNaN(newFrequency) || newFrequency === frequency?.frequency) return;
					if (newFrequency === 0) return deleteFrequency(clientCompanyId, eventType.id);
					createFrequency(clientCompanyId, { event_type: eventType.id, frequency: newFrequency }).then(refresh);
				}}
			>
				{frequency?.frequency}
			</BodyMainTextEditable>
		</Restricted>
	</CompanyDetailDisplayer>;
}