import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;

const Spacer = styled.div`
    width: 100%;
    margin: 5% 0 5% 0;
`;

export {
	Container,
	Spacer
};
