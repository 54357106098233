import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import styled from 'styled-components';
import { FlexDiv } from '../../containers_v2/products/style';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { BlueSidely } from '../../styles/global/css/Utils';
import { PopupMode, PopupStyle } from './model/Model';
import Popup from './Popup';
import { AdditionalButton, AdditionalButtons } from './PopupCreation';
import { DeletionContainer, DeletionHeader } from './PopupDeletion';

const Close = styled.img`
    cursor: pointer;
`;

const OkButton = styled(DefaultButton)`
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    background-color: white;
    border: 1px solid ${BlueSidely};
    color: ${BlueSidely};
`;

export function PopupInformationContent(props: {
  onValidation?: () => void
  onClickOut?: () => void
  title?: JSX.Element | string
  children?: any
  additionalButton?: AdditionalButton[]
  hideButton?: boolean
  gap?: string
}): JSX.Element {
	const { onValidation, onClickOut, title } = props;

	return <>
		<DeletionHeader>
			<div>{title}</div>
			<Close src={close} onClick={() => onClickOut?.()} width={30} />
		</DeletionHeader>
		<DeletionContainer gap={props.gap} maxHeight='calc(95vh - 150px)'>
			{props.children}
			<FlexDiv gap='20px'>
				{!props.hideButton &&
					<OkButton onClick={() => {
						onValidation?.();
						onClickOut?.();
					}}>
						Ok
					</OkButton>
				}
				<AdditionalButtons buttons={props.additionalButton}/>
			</FlexDiv>
		</DeletionContainer>
	</>;
}

export default function PopupInformation(props: {
  isOpen: boolean
  onClickOut?: () => void
  onValidation?: () => void
  children?: any
  title?: JSX.Element | string
  height?: string
  additionalButton?: AdditionalButton[]
  hideButton?: boolean
  popupStyle?: PopupStyle
  gap?: string
}): JSX.Element {
	return <Popup
		popupStyle={{ width: '600px', height: props.height ?? '400px', borderRadius: '4px', ...props.popupStyle }}
		isOpen={props.isOpen}
		hasBackground
		popupMode={PopupMode.Centered}
		onClickOut={props.onClickOut}
	>
		<PopupInformationContent
			onValidation={props.onValidation}
			onClickOut={props.onClickOut}
			title={props.title}
			additionalButton={props.additionalButton}
			hideButton={props.hideButton}
			gap={props.gap}
		>
			{props.children}
		</PopupInformationContent>
	</Popup>;
}
