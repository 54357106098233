import * as React from 'react';
import Dropdown from '../../dropdown/Dropdown';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { useRecoilState } from 'recoil';
import { AAverageOrInstantPicker, AverageOrInstant } from '../../../atoms/filter/averageOrInstantPicker';
import target from 'images/setting_icons/target_management_icon.svg';
import { translateToString } from '../../../styles/global/translate';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
export default function AverageOrInstantPicker(props: GenericToolbarFilterProps & { disabled?: boolean }) {
	const [averageOrInstant, setAverageOrInstant] = useRecoilState(AAverageOrInstantPicker);
	if (props.hidden) return <></>;

	return <Dropdown<AverageOrInstant>
		disabled={props.disabled}
		dropdownStyle={DROPDOWN_FILTER_STYLE}
		selectedValue={{ label: translateToString(averageOrInstant === 'average' ? 'average_period' : 'last_instance'), value: averageOrInstant }}
		datalist={[{ label: translateToString('average_period'), value: 'average' }, { label: translateToString('last_instance'), value: 'instant' }]}
		readOnly
		freezeShowLabel
		name='dropdown_scope_filter'
		onChange={value => setAverageOrInstant(value.value)}
		image={target}
	/>;
}