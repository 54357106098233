import { Target } from '../../targets/Targets';
import { DashboardItem } from '../Dashboard';
import Restricted from '../../permissions/Restricted';
import { TargetChart } from '../../targets/PopupTarget';
import * as React from 'react';
import { WidgetTitle, WidgetTitleWrapper, WidgetSelector, ScrollDiv } from '../Widget';
export default function TargetWidget(props: {
	value: Target,
	item: DashboardItem,
	selected?: boolean,
	onSelect?: (selected: boolean) => void,
	setItem: (item: DashboardItem) => void
}) {
	const { value, selected, onSelect } = props;
	return <>
		<WidgetTitleWrapper>
			<WidgetTitle>
				{value.name}
			</WidgetTitle>
			{selected !== undefined && <Restricted to={{ objectAction: 'DeleteDashboard' }}>
				<WidgetSelector isActive={selected} onClick={() => onSelect?.(!selected)}/>
			</Restricted>}
		</WidgetTitleWrapper>
		<ScrollDiv>
			<TargetChart target={value} dashboard/>
		</ScrollDiv>
	</>;
}

