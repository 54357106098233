import axios from 'axios';
import { URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import { UserWithAdditionalFields } from './myProfile';
import { UpdatedField } from '../../../../../web-types/api';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export function getMyProfile(): Promise<UserWithAdditionalFields> {
	return axios.get(`${URL_FOUNDATION}/api/v2/settings/user-profile`).then(res => res.data);
}

export type PutMyProfileBody = {
	[key: string]: UpdatedField<boolean | string | number>
} & {
	photo_name?: string
}

export function putMyProfile(body: PutMyProfileBody): Promise<null> {
	return axios.put(`${URL_FOUNDATION}/api/v2/settings/user-profile`, body).then(res => res.data);
}

export function isUserAllowedToUpdateForms(): Promise<boolean> {
	return axios.get(`${URL_FOUNDATION}/api/v2/settings/user-profile/is-allowed-to-update-forms`).then(res => res.data);
}