import * as React from 'react';
import { Column, Table } from '../../components_v2/table/Table';
import Pagination from '../../components_v2/pagination/Pagination';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { Translate, translateToString } from '../../styles/global/translate';
import { DEFAULT_STEP } from '../authorization/model';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { TableRow, TableRowStatusBackground } from '../orders/templateOrders/style/Style';
import { DateAccessor } from '../client-companies/data/CompanyColumns';
import { deleteFormTemplate } from './actions';
import Add from '../../components_v2/add/Add';
import Input from '../../components_v2/input/Input';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { useHistory } from 'react-router';
import { DarkGreySidely } from '../../styles/global/css/Utils';
import { creationPopupInputStyleMargin } from '../promotions/PopupPromotion';
import system_setting_icon from 'images/setting_icons/system_setting_icon.svg';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import optionGrey from 'images/icon/options_grey.png';
import { AlertContext, AlertRes } from '../alert/AlertProvider';
import { setLocalForm } from './localForm';
import { setLocalStorage } from '../../helpers/localStorage';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import Restricted from '../permissions/Restricted';
import { getFormTemplates, putFormTemplate } from '../../atoms/forms';
import { FormTemplate } from './jsonValidator';
import { isSuperAdmin } from '../../components_v2/utils';
import PermissionContext from '../permissions/PermissionContext';
import Switch from '../../components_v2/Switch/Switch';
import LightDropdown from '../../components_v2/dropdown/lightDropdown/LightDropdown';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { isUserAllowedToUpdateForms } from '../settings/userSettings/actions';
import { FlexDiv } from '../products/style';
import { FormTemplateImportButton } from './FormTemplateCreator';
import { Loader } from '../../styles/global/css/GlobalLoader';
function AlertConponent(props: { onBreak: () => void, onOk: () => void }) {
	const [name, setName] = React.useState<string>('');
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const history = useHistory();
	return <>
		<FlexDiv gap='10px' width='100%'>
			<Input name='' value={name} type='text' onChange={setName} label={translateToString('name')} inputStyle={creationPopupInputStyleMargin}/>
			<FormTemplateImportButton formName={name.trim().length === 0 ? undefined : name}
				onImportStarted={() => setLoadingState(LoadingStateEnum.LOADING)}
				onImportFinished={() => {
					props.onOk();
					history.push({ pathname: 'form-template' });
				}} />
		</FlexDiv>
		<DefaultButton
			margin='0'
			onClick={() => {
				props.onOk();
				setLocalForm({ name });
				history.push({ pathname: 'form-template' });
			}}
			disabled={!name || loadingState === LoadingStateEnum.LOADING}>
			{loadingState === LoadingStateEnum.LOADING ? <Loader /> : <Translate id='next' />}
		
		</DefaultButton>
	</>;
}
export type instanceNumber = {
	instances: number
};

export default function FormTemplateList(props: ComponentProps) {
	const [templates, setTemplates] = React.useState<FormTemplate[]>([]);
	const [pagination, setPagination] = React.useState<PaginationResult>();
	const history = useHistory();
	const { alertDelete, alert } = React.useContext(AlertContext);
	const [title, _setTitle] = React.useState<string>(translateToString('create_form_template'));
	const [isModalOpen, _setIsModalOpen] = React.useState<boolean>(false);
	const is_super_admin = isSuperAdmin();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [isAllowedToEditForms, setIsAllowedToEditForms] = React.useState<boolean>(is_super_admin);
	React.useEffect(() => {
		if (isAllowedToEditForms) return;
		isUserAllowedToUpdateForms().then(setIsAllowedToEditForms);
	}, []);

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('forms'),
			bottomRightToolbarComponent: <>
				<Restricted to={{ 'objectAction': 'CreateCompanySettings' } }><Add onClick={() => alert({
					title: translateToString('create_form_template'),
					content: (onBreak, onOk) => <AlertConponent onBreak={onBreak} onOk={onOk} />,
					noButtons: true,
				})} /></Restricted>
			</>
		});
	}, [title, isModalOpen]);

	const refresh = React.useCallback(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		setPagination(pagination => {
			getFormTemplates({
				limit: pagination?.step ?? DEFAULT_STEP,
				offset: pagination?.offset ?? 0
			}).then((res) => {
				setTemplates(res);
				setLoadingState(LoadingStateEnum.LOADED);
			})
				.catch(() => setLoadingState(LoadingStateEnum.ERROR));
			return pagination;
		});
	}, []);

	React.useEffect(() => {
		refresh();
	}, [pagination]);

	let list: DropdownData[] = [];
	const { isAllowedTo } = React.useContext(PermissionContext);
	if (isAllowedTo({ 'objectAction': 'CreateCompanySettings' })) {
		list = [{
			label: translateToString('global.quick_action.delete'),
			value: 'DELETE'
		},
		{
			label: translateToString('global.quick_action.edit'),
			value: 'EDIT'
		},
		{
			label: 'Editeur visuel',
			value: 'VISUAL'
		}
		];
	}

	const forceResumeOptions = [
		{ value: 0, label: translateToString('no_rules') },
		{ value: 1, label: translateToString('1_draft_for_each_company') },
		{ value: 2, label: translateToString('1_draft_for_each_user') }
	];

	const columns: Array<Column<FormTemplate>> = React.useMemo(() => [
		{
			id: 'name',
			Header: translateToString('name'),
			accessor: row => <TableRow
				fontWeight="500"
				color={DarkGreySidely}
				cursor="pointer"
				onClick={() => {
					const instanceNumber: instanceNumber = { ...row, instances: row.instances };
					setLocalStorage('INSTANCE_NUMBER', instanceNumber);
					history.push({
						pathname: 'form-instance-list',
						search: `?id=${row.id}`
					});
				}}
			>
				{row.name}
			</TableRow>,
			width: undefined,
			minWidth: 150,
			type: 'string',
		},
		{
			id: 'instances',
			Header: translateToString('instances'),
			accessor: row => <TableRow>{row.instances}</TableRow>,
			width: undefined,
			minWidth: 150,
			type: 'decimal',
		},
		{
			id: 'created_at',
			Header: translateToString('created_at'),
			accessor: row => <DateAccessor date={row.created_at} />,
			width: undefined,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'updated_at',
			Header: translateToString('updated_at'),
			accessor: row => <DateAccessor date={row.updated_at} />,
			width: undefined,
			minWidth: 150,
			type: 'temporal',
		},
		...(isAllowedToEditForms ? [{
			id: 'force_resume',
			Header: translateToString('force_resume'),
			accessor: row => 
				<LightDropdown
					dropdownStyle={{
						containerHeight: 'unset',
						optionWidth: '200px'
					}}
					datalist={forceResumeOptions}
					selectedValue={forceResumeOptions.find(fro => fro.value == row.force_resume)}
					JSXButton={({ onClick, value }) => <TableRow onClick={onClick} cursor='pointer'>{value?.label}</TableRow>}
					JSXContent={({ value, onClick }) => <div key={`Asortment-DD-content[${value.value}]`} style={{ margin: 5 }}><TableRowStatusBackground onClick={onClick}>{value?.label}</TableRowStatusBackground></div>}
					onChange={value => {
						putFormTemplate(row.id, { force_resume: value.value }).then(refresh);
					}}
				/>,
			width: undefined,
			minWidth: 150,
		}, {
			id: 'updatable_draft',
			Header: translateToString('updatable_draft'),
			accessor: row => <TableRow><Switch value={row.updatable_draft} onChange={(updatable_draft) => {
				putFormTemplate(row.id, { updatable_draft }).then(refresh);
			}}/></TableRow>,
			width: undefined,
			minWidth: 150,
		}, {
			id: 'quick_actions',
			noHeaderEllipsis: true,
			Header: <img src={system_setting_icon} style={{ width: 18 }}/>,
			accessor: row => 
				<Dropdown
					name='quick_action'
					datalist={list}
					readOnly
					dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
					JSXButton={() =>
						<img
							src={optionGrey}
							width={25}
							height={25}
							style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
						/>
					}
					onChange={value => {
						if (value.value === 'DELETE') {
							alertDelete({
								zIndex: 201,
								name: row.name
							})
								.then(result => result === AlertRes.Ok ? deleteFormTemplate(row.id).then(refresh) : undefined);
						}
						else if (value.value === 'EDIT') {
							setLocalForm({ ...row, screens: row.screens }, row.id);
							history.push({
								pathname: 'form-template',
								search: `?id=${row.id}`,
								hash: 'advanced'
							});
						}
						else if (value.value === 'VISUAL') {
							setLocalForm({ ...row, screens: row.screens }, row.id);
							history.push({
								pathname: 'form-template',
								search: `?id=${row.id}`,
								hash: 'visual'
							});
						}
					}}
				/>,
			width: 40,
			minWidth: 40,
			disableSortBy: true,
			disableFilter: true,
			unresizeable: true,
			freeze: 'right'
		}] as Column<FormTemplate>[] : []) 
	], [isAllowedToEditForms]);

	return <>
		<Table
			height='calc(100vh - 174px)'
			columns={columns}
			data={templates}
		/>
		<Pagination
			label={translateToString('forms')}
			amount={Number(templates[0]?.count ?? 0)}
			currentCount={templates.length}
			steps={[DEFAULT_STEP, 50, 100]}
			onChange={setPagination}
		/>
		<ComponentLoader allScreen loadingState={loadingState}/>
	</>;
}
