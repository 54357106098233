import { ObjectAction, Scope } from '../../web-types/objectActions';

export type Permission = {
  [key in ObjectAction]: Scope;
}

export type ObjectActionScopes = {
	objectAction: ObjectAction,
	scopes: Scope[]
}
export const ACTIONS_PER_OBJECT: ObjectActionScopes[][] = [
	[
		{ objectAction: 'ViewReporting', scopes: ['Nothing', 'Company'] },
	],
	[
		{ objectAction: 'ViewGallery', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewImport', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateImport', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewExport', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadExport', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateExport', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteExport', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewUserSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadUserSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateUserSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateUserSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteUserSettings', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewCompanySettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadCompanySettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateCompanySettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateCompanySettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteCompanySettings', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewAccountSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadAccountSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateAccountSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateAccountSettings', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteAccountSettings', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewCompany', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadCompany', scopes: ['Nothing', 'User', 'Company'] },
		{ objectAction: 'CreateCompany', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateCompany', scopes: ['Nothing', 'User', 'Company'] },
		{ objectAction: 'DeleteCompany', scopes: ['Nothing', 'User', 'Company'] },
	],
	[        
		{ objectAction: 'ReadCompanyPicture', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateCompanyPicture', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteCompanyPicture', scopes: ['Nothing', 'Company'] },
	],
	[
		{ objectAction: 'ReadCompanyNote', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateCompanyNote', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateCompanyNote', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteCompanyNote', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ReadCompanyDocument', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateCompanyDocument', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteCompanyDocument', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewContact', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadContact', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateContact', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateContact', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteContact', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ReadContactDocument', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateContactDocument', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteContactDocument', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ReadContactNote', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateContactNote', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateContactNote', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteContactNote', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewCalendar', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ReadEvent', scopes: ['Nothing', 'User', 'Company'] },
		{ objectAction: 'CreateEvent', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateEvent', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteEvent', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewMap', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewOrder', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadOrder', scopes: ['Nothing', 'User', 'Company'] },
		{ objectAction: 'CreateOrder', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateOrder', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteOrder', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewOpportunity', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadOpportunity', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateOpportunity', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateOpportunity', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteOpportunity', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewCatalog', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadCatalog', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateCatalog', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateCatalog', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteCatalog', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewFreeForm', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadFreeForm', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateFreeForm', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateFreeForm', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteFreeForm', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewShelfAudit', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadShelfAudit', scopes: ['Nothing', 'User', 'Company'] },
		{ objectAction: 'CreateShelfAudit', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateShelfAudit', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteShelfAudit', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewCampaign', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateCampaign', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadCampaign', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateCampaign', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteCampaign', scopes: ['Nothing', 'Company'] },
	],
	[
		{ objectAction: 'ViewCheckout', scopes: ['Nothing', 'Company'] },
		// { objectAction: 'CreateCheckout', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadCheckout', scopes: ['Nothing', 'Company'] },
		// { objectAction: 'UpdateCheckout', scopes: ['Nothing', 'Company'] },
		// { objectAction: 'DeleteCheckout', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewDoc', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateDoc', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadDoc', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateDoc', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteDoc', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewDashboard', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateDashboard', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadDashboard', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'UpdateDashboard', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'DeleteDashboard', scopes: ['Nothing', 'Company'] }
	],
	[
		{ objectAction: 'ViewObjective', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'CreateObjective', scopes: ['Nothing', 'Company'] },
		{ objectAction: 'ReadObjective', scopes: ['Nothing', 'User', 'Company'] },
		{ objectAction: 'UpdateObjective', scopes: ['Nothing', 'User', 'Company'] },
		{ objectAction: 'DeleteObjective', scopes: ['Nothing', 'User', 'Company'] }
	]
];

export interface To {
  objectAction: ObjectAction
  scope?: Scope
}
