import deleteImage from 'images/icon/delete.png';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import swal from 'sweetalert2';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import InputImage, { InputErrorMode } from '../../../components_v2/input/InputImage';
import { ImageResult } from '../../../components_v2/input/model/Model';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import storeLang from '../../../helpers/storeLang';
import { GreenSidely, RedSidely } from '../../../styles/global/css/Utils';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany } from '../../orders/model/Model';
import { FlexDiv } from '../../products/style';
import { postPhotos } from '../data/action';
import { Company, CompanyPhoto, PostPhotoParams } from '../model/Model';
import { Delete, FileName, Photo, PhotoContainer, QuotaText } from '../style/NewPhotoStyle';
import { CreationPopupContainer } from '../style/Style';

function NewPhoto(props: {
  onClickOut?: () => void
  onCreate?: (values: CompanyPhoto[]) => void
  clientCompanyId?: number
  maxPhoto?: number
}): JSX.Element {
	const LIMIT = 3;
	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [images, setImages] = React.useState<ImageResult[]>([]);

	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);
	const [company, setCompany] = React.useState<Company>();

	function submit(): void {
		if ((company !== undefined || props.clientCompanyId !== undefined) && images.length > 0) {
			const params = {
				client_company_id: props.clientCompanyId ? props.clientCompanyId : company?.company_id,
				images: images.map(image => {
					return {
						name: image.file.name,
						file: image.content
					};
				})
			} as PostPhotoParams;

			setLoading(LoadingStateEnum.LOADING);
			postPhotos(params).then(response => {
				if (response) {
					const nPhotos = response.map((v, i) => {
						return {
							client_company_id: props.clientCompanyId ? props.clientCompanyId : company?.company_id,
							photo_count: response.length,
							photo_id: v,
							photo_name: images[i].file.name,
							photo_url: images[i].url,
							photo_created_at: moment.utc().toLocaleString()
						} as CompanyPhoto;
					});
					(props.onCreate != null) && props.onCreate(nPhotos);
					setLoading(LoadingStateEnum.LOADED);
				}
			});
		}
	}

	function errorAlert(message: string): void {
		swal({
			title: translate('global.fire.alert_title').toString(),
			text: message,
			type: 'warning',
			confirmButtonText: translate('global.fire.confirm_message').toString(),
			confirmButtonColor: RedSidely
		});
	}

	function body(): JSX.Element {
		return (
			(<CreationPopupContainer style={{ gap: '10px' }}>
				{
					!props.clientCompanyId &&
					<>
						<DropdownSearch
							datalist={optionCompanies}
							name="clientCompanyName"
							label={translate('company.creation.photo.company').toString()}
							optionHeight={200}
							width={250}
							labelUp
							required
							link={'/companies?id='}
							onChange={(value) => setCompany(value?.value)}
							selectedValue={(optionCompanies.find(comp => JSON.stringify(comp.value) == JSON.stringify(optionCompanies)) != null) || null}
							onSearchChange={async(search, offset) => {
								return await getFilteredCompanies(search, 20, offset)
									.then(res => {
										const ret: boolean = res.data.length == 0 || res.data.length < 20;
										if (offset !== undefined) {
											setOptionCompanies([...optionCompanies, ...res.data.map((company: ClientCompany) => {
												return {
													label: company.name,
													value: company
												};
											})]);
										} else {
											setOptionCompanies(res.data.map(company => {
												return {
													label: company.name,
													value: company
												};
											}));
										}
										return ret;
									})
									.catch(error => {
										console.log(error);
										return false;
									});
							}}
						/>
					</>
				}
				{
					images.map(image => {
						return (<>
							<PhotoContainer>
								<FlexDiv gap='10px'>
									<Photo src={image.url} alt={image.file.name} />
									<FileName>{image.file.name}</FileName>
								</FlexDiv>
								<Delete src={deleteImage} alt="delete" onClick={() => setImages(images.filter(i => i.url !== image.url))} />
							</PhotoContainer>
						</>);
					})
				}
				{
					images.length < (props.maxPhoto ?? LIMIT)
						? <InputImage
							limitMo={2}
							backgroundColor={GreenSidely}
							disableShowImage
							onChange={(value) => setImages(images.concat(value))}
							onError={(value) => value.type === InputErrorMode.LIMIT_REACHED && errorAlert(value.message)}
							size={25}
						/>
						: <QuotaText>{translate('company.creation.photo.quota_reached').toString()}</QuotaText>
				}
			</CreationPopupContainer>)
		);
	}

	return (
		<PopupCreation
			title={translate('company.creation.photo.add_photo').toString()}
			content={body()}
			canValidate={(company !== undefined || props.clientCompanyId !== undefined) && images.length > 0}
			onSubmit={() => submit()}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
			messageButton={translate('company.creation.to_add').toString()}
		/>
	);
}

export default NewPhoto;
