import * as React from 'react';
import { Cell, HeaderGroup, useBlockLayout, useExpanded, useResizeColumns, useSortBy, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import ReactTooltip from 'react-tooltip';
import {
	BlueSidely,
	BorderColor,
	DarkGreySidely,
	DarkGreySidely2,
	GreenSidely,
	LightBlueSidely,
	RedSidely,
	SidelyBlack
} from '../../styles/global/css/Utils';
import { Checkbox } from '../filterList/style/Style';
import { InfiniteTableToolTip } from '../../containers_v2/reports/interpretor/ReportInterpretor';
import ReportInterpretorContext from '../../containers_v2/reports/interpretor/hoverContext';

export const InfiniteTableStyle = styled.div<{ toolTipNotHidden?: boolean }>`
    height: 100%;
    
    .circle {
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.35));
    }

    .presence {
        text-align: center;
    }
    
    .table {
        .selected {
            background-color: ${LightBlueSidely};
        }

        overflow: auto;
        display: inline-block;
        border-spacing: 0;
        border: 1px solid ${BorderColor};
        background: white;
        height: 100%;

        .header-content {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 10px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .header-no-overflow {
            overflow: visible;
        }

        .tr {
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
        }

        .tr {
            height: 25px;
        }

        .gradiant1 {
            background: #F8F8F8
        }

        .gradiant2 {
            background: #F4F4F4
        }

        .gradiant3 {
            background: #EEEEEE
        }

        .gradiant4 {
            background: #E5E5E5
        }

        .gradiant5 {
            background: #D7D7D7
        }

        .th {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid ${BorderColor};
            
            text-align: center;
            
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 21px;
            color: ${DarkGreySidely};

            :last-child {
                border-right: 1px solid ${BorderColor};
            }
        }
        
        .td {
            margin: 0;
            padding: 0.75rem;
            border-bottom: 1px solid ${BorderColor};
            
            text-align: center;

            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 21px;
            color: ${DarkGreySidely2};
    
            :last-child {
                border-right: 1px solid ${BorderColor};
            }
            .report-text {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                white-space: nowrap;
                text-align: left;

            }
        }

        .tamer {
            width: 100%;
        }

        .overflow {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .false-resizer {
            display: inline-block;
            width: 0px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
            border: 1px dashed ${BorderColor};
            z-index: 1;
            ${'' /* prevents from scrolling while dragging on touch devices */}
            touch-action:none;


        }
        .resizer {
            display: inline-block;
            background: ${BorderColor};
            width: 5px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
            z-index: 1;
            ${'' /* prevents from scrolling while dragging on touch devices */}
            touch-action:none;

            &.isResizing {
            background: grey;
            }
        }

        .red-dot {
            height: 12px;
            width: 12px;
            background-color: ${RedSidely}CC;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
        }
        .green-dot {
            height: 12px;
            width: 12px;
            background-color: ${GreenSidely}CC;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
        }

        .triangle-up {
            margin-left: 8px;
            display : inline-block;
            height : 0;
            width : 0;
            border-right : 6px solid transparent;
            border-bottom : 6px solid #212529;
            border-left : 6px solid transparent;
        }

        .triangle-down {
            margin-left: 8px;
            display : inline-block;
            height : 0;
            width : 0;
            border-top : 6px solid #212529;
            border-right : 6px solid transparent;
            border-left : 6px solid transparent;
        }

        .percentage {
            display: block;
            text-align: right;
            margin-right: 35%;
        }

        tfoot {
            tr:first-child {
              td {
                border: 1px solid ${BorderColor};
                text-align: center;

                margin: 0;
                padding: 0.75rem;
                border-bottom: 1px solid ${BorderColor};
                
                text-align: center;
    
                font-family: Poppins;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 21px;
                color: ${DarkGreySidely2};
        
                :last-child {
                    border-right: 1px solid ${BorderColor};
                }

              }
              height: 50px;
            }
            font-weight: 400;
        }

        .tooltip2 {
            position: relative;
            display: inline-block;
        }
        .tooltip2 .tooltiptext2 {
            ${p => p.toolTipNotHidden ? '' : 'visibility: hidden;'}
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            min-width: 120px;
            width: fit-content;
            background-color: #fff;
            color: ${SidelyBlack};
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            transform: translateY(-50%);
            top: 50%;
            right: calc(100% + 20px);

            // border: solid 0.3px rgb(177, 177, 177);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }
        .tooltip2:hover .tooltiptext2 {
            visibility: visible;
            opacity: 1;
        } 

        ul {
            list-style: none;
        }

        .tooltip3 {
            position: relative;
            display: inline-block;
        }
        .tooltip3 .tooltiptext3 {
            ${p => p.toolTipNotHidden ? '' : 'visibility: hidden;'}
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            width: 200px;
            background-color: #fff;
            color: ${SidelyBlack};
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            top: -8px;
            right: 110%;

            // border: solid 0.3px rgb(177, 177, 177);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }        
        .tooltip3:hover .tooltiptext3 {
            visibility: visible;
            opacity: 1;
        } 

        .tooltipup {
            position: relative;
            display: inline-block;
        }
        .tooltipup .tooltiptextup {
            ${p => p.toolTipNotHidden ? '' : 'visibility: hidden;'}
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            width: fit-content;
            min-width: 200px;
            background-color: #fff;
            color: ${SidelyBlack};
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 150%;
            left: 50%;
            transform: translateX(-50%);

            // border: solid 0.3px rgb(177, 177, 177);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }        
        .tooltipup:hover .tooltiptextup {
            visibility: visible;
            opacity: 1;
        }
    }
`;

function RowCheckbox(props: { checked: boolean, setChecked: (id: unknown) => boolean, id: unknown, row }) {
	const [checked, setChecked] = React.useState<boolean>(props.checked);

	return <Checkbox
		isActive={checked}
		onClick={() => {
			const res = props.setChecked(props.id);
			props.row.selected = res;
			setChecked(res);
		}}
		style={{ position: 'relative', top: '50%', translate: '0 -50%' }}
	/>;

}

export function InfiniteTable(props: { height: number, columns: any[], data: any[], hidden?: boolean, selectedCheckbox?: boolean[], header_thing?: JSX.Element, setSelectedCheckbox?: (id: number) => boolean, onLoad?: () => void}) {
	// Use the state and functions returned from useTable to build your UI
	const { height, columns, data, hidden, selectedCheckbox, setSelectedCheckbox } = props;
	const context = React.useContext(ReportInterpretorContext);

	React.useEffect(() => {
		props.onLoad?.();
	}, [props.columns, props.data]);

	const scrollbarWidth = () => {
		const scrollDiv = document.createElement('div');
		scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: auto; position:absolute; top:-9999px;');
		document.body.appendChild(scrollDiv);
		const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		// document.body.removeChild(scrollDiv)
		return scrollbarWidth;
	};

	const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		totalColumnsWidth,
		prepareRow,
		state: { expanded, sortBy },
		toggleSortBy
	} = useTable(
		{
			columns,
			data,
			autoResetExpanded: false,
			autoResetSortBy: false,
			initialState: {
				sortBy: [
					{
						id: 'column[0]',
						desc: false
					}
				]
			}
		},
		useSortBy,
		useResizeColumns,
		useBlockLayout,
		useExpanded,
		hooks => {
			if (setSelectedCheckbox && selectedCheckbox)
				hooks.visibleColumns.push(columns => [
					{
						id: 'selection',
						Header: props.header_thing ?? <></>,
						Cell: ({ row }) => <RowCheckbox row={row.original} id={row.original.id} checked={selectedCheckbox?.[row.original.id] ?? false} setChecked={setSelectedCheckbox}/>,
						width: 40,
					},
					...columns,
				]);
		}
	);
	React.useEffect(() => {
		if (headerGroups[0].headers.some(h => h.id === sortBy[0].id)) {
			toggleSortBy?.(sortBy[0].id, sortBy[0].desc);
		}
	}, [context.evolutionState]);

	function Test(props: { cell: Cell<any, any>}) {
		const { cell } = props;
		const context = React.useContext(ReportInterpretorContext);
		const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();
		const ref = React.useRef<HTMLElement>();
		const setRef = React.useCallback(node => ref.current = node, []);
		return (
			<div {...cell.getCellProps()} className="td" 
				ref={setRef}
				onMouseEnter={() => {
					localTimeout && clearTimeout(localTimeout);
					const content = cell.column?.toolTip?.(cell.row.original, 'row');
					if (!ref.current || !content) return;
					setLocalTimeout(setTimeout(bounds => context.setHover({
						bounds,
						...content
					}), 200, ref.current.getBoundingClientRect()));
				}}
				onMouseLeave={() => {
					localTimeout && clearTimeout(localTimeout);
					context.setHover(undefined);
				}}
			>
				{cell.render('Cell')}
			</div>
		);

	}

	const RenderRow = React.useCallback(
		// eslint-disable-next-line react/display-name
		(selectedCheckbox: boolean[] | undefined) => ({ index, style }) => {
			const row = rows[index];
			prepareRow(row);
			let className = 'tr';
			if (row.depth > 0) {
				className += (' gradiant' + row.depth);
			}
			if (row.original.selected || selectedCheckbox?.[row.original.id]) {
				className += ' selected';
			}
			return (
				<div
					{...row.getRowProps({
						style
					})}
					className={className}
				>
					{row.cells.map((cell, index2) => <Test key={`cell[${index}][${index2}]`} cell={cell}/>)}
				</div>
			);
		},
		[prepareRow, rows]
	);
	// Render the UI for your table
	return <>
		<div {...getTableProps()} className="table" style={{ height: '100%', visibility: hidden ? 'hidden' : undefined }}>
			<thead>
				{headerGroups.map(headerGroup => (
					// eslint-disable-next-line react/jsx-key
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column, i) => {
							let resizeProps;
							let isResizing;
							if (column.oldResize) {
								resizeProps = headerGroup.headers[i - 1]?.getResizerProps();
								isResizing = headerGroup.headers[i - 1]?.isResizing;
							} else {
								resizeProps = column.getResizerProps();
								isResizing = column.isResizing;
							}
							return (
							// eslint-disable-next-line react/jsx-key
								<div {...column.getHeaderProps()} className="th">
									<div
										{...column.getSortByToggleProps({ title: undefined })}
										className={`header-content ${column.noHeaderEllipsis ? 'header-no-overflow' : ''}`}
									>
										{column.render('Header')}
										{column.isSorted
											? column.isSortedDesc
												? <div className='triangle-down' />
												: <div className='triangle-up' />
											: ''}

									</div>
									{!column.noResizer ? <span {...resizeProps}
										className={`resizer ${isResizing ? 'isResizing' : ''}`}>
									</span> : <span className='false-resizer'/>}
								</div>
							);
						})}
					</tr>
				))}
			</thead>

			<tbody {...getTableBodyProps()}>
				<ReactTooltip id="reason-tooltip" className='menu-tooltip'
					place="top" effect="solid" delayShow={400} type="light"
					arrowColor={BlueSidely} textColor="white"
					getContent={(key) => {
						if (key == null) {
							return;
						}
						return (<span>
							<Translate id={key} />
						</span>);
					}} />
				<FixedSizeList
					height={height}
					itemCount={rows.length}
					itemSize={40}
					width={totalColumnsWidth + scrollBarSize}
				>
					{RenderRow(selectedCheckbox)}
				</FixedSizeList>
			</tbody>

			<tfoot>
				{footerGroups.map(group => (
					<tr {...group.getFooterGroupProps()}>
						{group.headers.map(column => <Footer column={column} />)}
					</tr>
				))}
			</tfoot>

		</div>
		<InfiniteTableToolTip/>
	</>;
}

function Footer(props: {column: HeaderGroup<any>}) {
	const context = React.useContext(ReportInterpretorContext);
	const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();
	const ref = React.useRef<HTMLElement>();
	const setRef = React.useCallback(node => ref.current = node, []);
	return <td {...props.column.getFooterProps()}
		ref={setRef}
		onMouseEnter={() => {
			localTimeout && clearTimeout(localTimeout);
			const content = props.column?.toolTip?.({}, 'footer');
			if (!ref.current || !content) return;
			setLocalTimeout(setTimeout(bounds => context.setHover({
				bounds,
				...content
			}), 200, ref.current.getBoundingClientRect()));
		}}
		onMouseLeave={() => {
			localTimeout && clearTimeout(localTimeout);
			context.setHover(undefined);
		}}
	>{props.column.render('Footer')}</td>;

}
