import React, {Fragment} from 'react';
import HeaderArrowBack from './Button/HeaderArrowBack';
import DefaultButton from './Button/DefaultButton';
import CloseButton from './Button/CloseButton';
import Demobar from '../fieldComponents/builderLib/Demobar';
import storeLang from '../../../../helpers/storeLang'
import {getTranslate} from 'react-localize-redux'

function HeaderTemplate(props) {
    const translate = getTranslate(storeLang.getState().localize)
    const { onSubmit, history, variables, onPreview, elements, isLoading } = props;
    const listButton = [
        {
            label: 'Step 3',
            classType: 'btn-previous mr-3'
        },
        {
            label: 'preview',
            classType: 'btn-previous mr-3'
        },
        {
            label: 'Done',
            classType: 'btn-primary'
        },
    ]

    const getOnClickBtn = idx => {
        switch (idx) {
            case 0:
                history.push(`/enform/detail/elements`)
                break;
            case 1:
                onPreview()
                break;
            case 2:
                onSubmit(4)
                break;

            default:
                break;
        }
    }
    return (
        <Fragment>
            <div className="d-flex background-bar sticky-v2 right-panel">
                <HeaderArrowBack history={history} />
                <div className="ml-auto my-auto d-flex">
                    {listButton.map((v, i) =>
                        <DefaultButton {...props}
                            disabled={false}
                            label={v.label == "Step 3" ? translate('step').toString() + " 3": translate(v.label).toString()}
                            isLoading={i === 2 ? isLoading : false}
                            onClickBtn={() => getOnClickBtn(i)}
                            classType={v.classType} />
                    )}
                    <CloseButton history={history} />
                </div>
            </div>
            <Demobar
                variables={variables}
                onRef={props.onRef}
                elements={elements}
            />
        </Fragment>
    );
}

export default HeaderTemplate;
