import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { UpdatedField } from '../../../../web-types/api';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import Input from '../../components_v2/input/Input';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { translateToString } from '../../styles/global/translate';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../client-companies/style/Style';
import { Spacer } from '../contact/style/NewContactStyle';
import { LoadingStateEnum } from '../import/model';
import { postCategory, putCategory } from './action';
import { Category, categoryFlatter } from '../settings/subSettings/categoryManagement';

export default function NewCategory(props: {
	isOpen: boolean,
	setIsOpen: (b: boolean) => void,
	onCreate?: () => void,
	categories: { id: number, name: string }[],
	category?: Category
}) {

	const close = () => props.setIsOpen(false);

	return <Popup
		isOpen={props.isOpen}
		onClickOut={close}
		popupStyle={{ animate: true, height: '100%', top: '0%' }}
		popupMode={PopupMode.Details}
		content={<NewCategoryContent
			onCreate={() => {
				close();
				props.onCreate?.();
			}}
			onClose={close}
			categories={props.categories}
			category={props.category}
		/>}
	/>;
}

function NewCategoryContent(props: { onCreate?: () => void, onClose: () => void, categories: { id: number, name: string }[], category?: Category }) {
	const [name, setName] = React.useState<string | undefined>(props.category?.name);
	const [description, setDescription] = React.useState<string | undefined>(props.category?.description);
	const [parentCategory, setParentCategory] = React.useState<number | undefined>(props.category?.parent_id);
	const [loadingState, setLoading] = React.useState<LoadingStateEnum>();

	React.useEffect(() => {
		setName(props.category?.name);
		setDescription(props.category?.description);
		setParentCategory(props.category?.parent_id);
	}, [props.category]);

	const submit = React.useCallback(async(name: string, description: string | undefined, parent_category: number | undefined, category: Category | undefined) => {
		setLoading(LoadingStateEnum.LOADING);
		if (category) {
			let updatedField: UpdatedField;
			if (description === category.description) {
				updatedField = 'unmodified';
			} else if (description) {
				updatedField = { updated: description };
			} else {
				updatedField = 'deleted';
			}
			let parentUpdatedField: UpdatedField;
			if (parent_category === category.parent_id) {
				parentUpdatedField = 'unmodified';
			} else if (parent_category) {
				parentUpdatedField = { updated: parent_category };
			} else {
				parentUpdatedField = 'deleted';
			}
			await putCategory(category.id, { name, description: updatedField, parent_category: parentUpdatedField });
		} else {
			await postCategory({ name, description, parent_category });
		}
		setLoading(LoadingStateEnum.LOADED);
		props.onCreate?.();
	}, []);
	const childrenCategories = props.category ? categoryFlatter(props.category) : [];

	return <PopupCreation
		title={translateToString(props.category ? 'category_modification' : 'create_category')}
		canValidate={name !== undefined && name.length > 0 && (name !== props.category?.name || description !== props.category?.description || parentCategory !== props.category?.parent_id)}
		onSubmit={() => name && submit(name, description, parentCategory, props.category)}
		loading={loadingState}
		onClose={props.onClose}
		messageButton={props.category
			? <Translate id='global.edit' />
			: <Translate id='global.create' />
		}
	>
		<CreationPopupContainer>
			<Input
				inputStyle={creationPopupInputStyle}
				name="name"
				type="text"
				placeholder={translateToString('name').toString()}
				label={translateToString('name').toString()}
				onChange={setName}
				required
				value={name}
			/>
			<Spacer />
			<Spacer />
			<Dropdown
				label={translateToString('parent_category')}
				dropdownStyle={creationPopupDropdownStyle}
				readOnly
				cancellable
				datalist={props.categories.filter(c => c.id !== props.category?.id).map(c => ({ label: c.name, value: c, disabled: childrenCategories.some(c2 => c.id === c2.id) })).sort((a, b) => {
					if (a.disabled && !b.disabled) return 1;
					if (!a.disabled && b.disabled) return -1;
					return a.label.localeCompare(b.label);
				})}
				onChange={(value: DropdownData<Category>) => setParentCategory(value.value?.id)}
				name='category_picker'
				selectedValue={props.categories.map(c => ({ label: c.name, value: c })).find(c => c.value.id === parentCategory)}
			/>
			<Spacer />
			<Input
				textArea
				inputStyle={{ ...creationPopupInputStyle, height: '10em' }}
				name="description"
				type='text'
				placeholder={translateToString('description').toString()}
				label={translateToString('description').toString()}
				onChange={setDescription}
				value={description}
			/>
		</CreationPopupContainer>
	</PopupCreation>;
}