import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { localizeReducer } from 'react-localize-redux';
import auth from './auth.reducer';
import app from './app.reducer';
import select from './select.reducer';
import modal from './modal.reducer';
import user from './user.reducer';
import companies from './companies.reducer';
import contacts from './contacts.reducer';
import opportunities from './opportunities.reducer';
import expenses from './expenses.reducer';
import salesOperations from './salesOperations.reducer';
import events from './events.reducer';
import group from './group.reducer';
import targetManagement from './targetManagement.reducer';
import selesTeam from './selesTeam.reducer';
import mapOpportunities from './mapOpportunities.reducer';
import fieldSettings from './fieldSettings.reducer';
import page from './page.reducer';
import formbuilder from './formbuilder.reducer';
import formdata from './formdata.reducer';
import products from './products.reducer';
import files from './files.reducer';
import brand from './brand.reducer';
import category from './category.reducer';
import assortment from './assortment.reducer';
import enform from './enForm.reducer';
import productMenu from './product_menu.reducer';
import webSocket from './webSocket.reducer';

const appReducer = combineReducers({
	auth,
	app,
	select,
	modal,
	user,
	companies,
	contacts,
	opportunities,
	mapOpportunities,
	expenses,
	salesOperations,
	events,
	group,
	page,
	targetManagement,
	selesTeam,
	fieldSettings,
	formbuilder,
	formdata,
	products,
	files,
	brand,
	category,
	assortment,
	enform,
	localize: localizeReducer,
	form: formReducer,
	productMenu,
	webSocket
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_SUCCESS') {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
