import * as React from 'react';
import Pagination from '../../components_v2/pagination/Pagination';
import { ToolbarState } from '../globals/mainPage/mainPage';
import AdvancedFilters from '../../components_v2/filter/AdvancedFilters';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import { getCompanyFilters } from '../client-companies/data/action';
import { FilterResult, filterTreeLength } from '../../components_v2/filter/model/Model';
import ToolbarFilterButton from '../../components_v2/toolbarFilter/ToolbarButton';
import { GalleryModalLeft } from './GalleryModalLeft';
import styled from 'styled-components';
import { InstancePhotos, PhotoData, getPhotosFromForm } from './actions';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';
import { BlackContainer, Card, Close, ImageSwapper, PhotoContainer, PhotoText, UserPhoto } from '../gallery/style';
import noUserImage from 'images/icons/user.svg';
import { DateAccessor } from '../client-companies/data/CompanyColumns';
import close from 'images/icons/orders/close.svg';
import * as moment from 'moment';
import new_tab_white from 'images/icon/new_tab_white.svg';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { ContentWrapper } from '../forms/create-from-template/FormCreation';
import { translateToString } from '../../styles/global/translate';
import { FormTemplate } from '../forms/jsonValidator';
import { AFormTemplate } from '../../atoms/forms';
import { CardImageDisplayer } from '../forms/FieldDisplayer';
import { DEFAULT_FILTER_RESULT } from '../client-companies/CompaniesAndMapView';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { ModalState } from '../products/model';

const DEFAULT_STEP = 50;

export const Wrapper = styled.div<{ isModalOpen: boolean }>`
  margin-left: ${p => p.isModalOpen ? '260px' : '0px'};
  transition: 0.25s;
`;

export const PhotoDetails = styled.div<{ fontWeight?: number, fontSize?: number }>`
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    font-size: ${p => p.fontSize ?? 16}px;
    font-weight: ${p => p.fontWeight ?? 400};
    color: white;
    margin-top: 5px;
`;

const BottomToolBar = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	width: calc(110vw - 450px);
}
`;

export default function GalleryV2(props: {
    setToolBarState: (value: ToolbarState) => void
}): JSX.Element {

	const [isFilterOpen, setFilterOpen] = React.useState<boolean>(false);
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const [filterResult, setFilterResult] = React.useState<FilterResult>(DEFAULT_FILTER_RESULT);

	const [isModalOpen, setModalOpen] = React.useState<boolean>(true);

	const formTemplates = useRecoilValue(AFormTemplate);
	const [selectedForm, setSelectedForm] = React.useState<FormTemplate>();
    
	React.useEffect(() => {
		getCompanyFilters().then(response => setFilterParams(response.filters));
	}, []);

	React.useEffect(() => {
		setSelectedForm(formTemplates[0]);
	}, [formTemplates]);

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('gallery.title'),
			bottomLeftToolbarComponent: <>
				<ToolbarFilterButton
					activeFilters={filterTreeLength(filterResult.formatted)}
					onClick={() => {
						setFilterOpen(true);
					}}
					onDeleteFilter={() => {
						setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
					}}
				/></>
		});
	}, [filterResult]);

	return <>
		<AdvancedFilters
			isOpen={isFilterOpen}
			setOpen={setFilterOpen}
			filterList={filterParams}
			isOpenSummary={true}
			onChange={setFilterResult}
			filterValues={filterResult.values} />
		<GalleryModalLeft
			templateList={formTemplates}
			selected={selectedForm ? selectedForm : formTemplates[0]}
			setSelected={setSelectedForm}
			isModalOpen={isModalOpen}
			setIsModalOpen={setModalOpen}
		/>
		<Wrapper isModalOpen={isModalOpen}>
			{selectedForm &&
                <InstanceGallery form={selectedForm} filters={filterResult} />
			}
		</Wrapper> 
	</>;
}

function InstanceGallery(props: {
    form: FormTemplate
    filters: FilterResult
}) {
	const [allInstancesWithPhotos, setAllInstancesWithPhotos] = React.useState<InstancePhotos[]>([]);
	const [modalInfo, setModalInfo] = React.useState<ModalState<InstancePhotos>>({ isOpen: false });
	const [pagination, setPagination] = React.useState<PaginationResult>({ currentPage: 1, step: DEFAULT_STEP, offset: 0 });
	const Users = useRecoilValue(AUsers);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const closeModal = () => {
		setModalInfo({ isOpen: false });
	};

	React.useEffect(() => {
		setPagination({ currentPage: 1, step: DEFAULT_STEP, offset: 0 });
		setLoadingState(LoadingStateEnum.LOADING);
		getPhotosFromForm(props.form?.id, { filters: props.filters.formatted })
			.then((res) => {
				setAllInstancesWithPhotos(res);
				setLoadingState(LoadingStateEnum.LOADED);
			})
			.catch(() => setLoadingState(LoadingStateEnum.ERROR))
		;
	}, [props.filters, props.form.id]);

	return <>
		<ComponentLoader loadingState={loadingState} allScreen />
		{modalInfo.isOpen && modalInfo.data && <BlackContainer>
			<PhotosList closeModal={closeModal} form={props.form} instance={modalInfo.data} />
			<Close src={close} onClick={closeModal} />
		</BlackContainer>}
		<ContentWrapper style={{ overflow: 'auto' }}>
			{allInstancesWithPhotos && allInstancesWithPhotos.slice(pagination.offset, pagination.offset + pagination.step).map((instance, i) => {
				const index = i + pagination.offset;
				return <>
					<Card
						key={`PhotoCard[${index}]`}
						onClick={() => setModalInfo({ isOpen: true, data: instance })}
					>
						<PhotoContainer>
							<CardImageDisplayer images={instance.photos.map(p => p.url_thumb as any as string /* WTF */)} onClick={() => undefined} />
						</PhotoContainer>
						<PhotoText size={13} weight={600}>
							{instance.cc_name}
						</PhotoText>
						<PhotoText maxLines={1.5}>
							<UserPhoto src={Users.find(u => u.id == instance.owner)?.photoUrl ?? noUserImage} />
							{Users.find(u => u.id == instance.owner)?.name}
						</PhotoText>
						<PhotoText size={9}>
							<DateAccessor date={instance.made_at} />
						</PhotoText>
					</Card>
				</>;
			})}
			<BottomToolBar>
				<Pagination
					label={translateToString('company.detail.linked_elements.form_instances')}
					amount={allInstancesWithPhotos.length}
					steps={[25, 50, 100]}
					onChange={setPagination}
					defaultStep={DEFAULT_STEP}
				/>
			</BottomToolBar>
		</ContentWrapper>
	</>;
}



export function PhotosList(props: {
	form: FormTemplate
	instance: InstancePhotos
	closeModal: () => void
}) {
	const [index, setIndex] = React.useState<number>(0);
	const url = props.instance.photos[index].url;
	const name = props.instance.photos[index].name;
	const Users = useRecoilValue(AUsers);
	const date = moment(props.instance.made_at);

	function changeIndex(newIndex: number) {
		if (newIndex < 0) {
			setIndex(props.instance.photos.length - 1);
		} else if (newIndex > props.instance.photos.length - 1) {
			setIndex(0);
		} else {
			setIndex(newIndex);
		}
	}

	function keyHandler(event) {
		switch (event.key) {
			case 'Escape':
				props.closeModal();
				return;
			case 'ArrowLeft':
				changeIndex(index - 1);
				return;
			case 'ArrowRight':
				changeIndex(index + 1);
		}
	}

	React.useEffect(() => {
		document.addEventListener('keydown', keyHandler, false);
		return () => {
			document.removeEventListener('keydown', keyHandler, false);
		};
	}, [index]);

	return <> 
		<div style={{
			marginTop: 15,
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center'
		}}>
			<PhotoDetails fontSize={14} fontWeight={600}>
				{props.instance.cc_name}
				<div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => window.open(`/companies?id=${props.instance.cc_id}`, '_blank')}>
					<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
				</div>
				<div>{ index + 1 } / { props.instance.photos.length }</div>
			</PhotoDetails>
			<br />
			{props.instance.photos.length > 1 && <ImageSwapper onClick={() => changeIndex(index - 1)} />}
			<img style={{ height: '69vh', display: 'inline' }} src={url} />
			{props.instance.photos.length > 1 && <ImageSwapper right onClick={() => changeIndex(index + 1)} />}
			<br />
			<PhotoDetails fontSize={14}>
				<div>{name}</div>
			</PhotoDetails>
			<PhotoDetails fontSize={14}>
				<div>
					{props.form.name}
					<div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => window.open(`/form-instance-list?id=${props.form.id}&instance=${props.instance.form_instance_id}`, '_blank')}>
						<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
					</div> &nbsp;-&nbsp;
					{Users.find(u => u.id == props.instance.owner)?.name} -&nbsp;
					{date.format('DD/MM/YYYY - HH:mm')}
				</div>
			</PhotoDetails>
		</div>
	</>;
}