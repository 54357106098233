import {
	CREATE_DATA_ERROR,
	CREATE_DATA_REQUEST,
	CREATE_DATA_SUCCESS,
	DETAIL_DATA_ERROR,
	DETAIL_DATA_REQUEST,
	DETAIL_DATA_SUCCESS,
	FETCH_DATA_ERROR,
	FETCH_DATA_REQUEST,
	FETCH_DATA_SUCCESS,
	IMPORT_EXCEL_ERROR,
	IMPORT_EXCEL_REQUEST,
	IMPORT_EXCEL_SUCCESS,
	SORT_DATA,
	UPDATE_DATA_ERROR,
	UPDATE_DATA_REQUEST,
	UPDATE_DATA_SUCCESS
} from '../actions/expenses.action';
import { SET_ACTIVE_FILTER } from '../actions/companies.action';

const initState = {
	data: [],
	dataDetai: [],

	search: '',
	sortField: null,
	sort: 'desc',

	isLoading: false,
	isLoadingDetail: false,

	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},

	activeFilter: {
		expenseStatusId: null
	}
};

export default function CompaniesReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_DATA_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_DATA_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_DATA_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_DATA_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case CREATE_DATA_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};

		case CREATE_DATA_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_DATA_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_DATA_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoading: false
			};

		case UPDATE_DATA_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DETAIL_DATA_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_DATA_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_DATA_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

			// case SEARCH_COMPANIES:
			//   return {
			//     ...state,
			//     search: action.payload
			//   };

		case SORT_DATA:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};

		case IMPORT_EXCEL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case IMPORT_EXCEL_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case IMPORT_EXCEL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SET_ACTIVE_FILTER:
			return {
				...state,
				activeFilter: action.payload
			};

			// CREATE LINKED ELEMENT

		default:
			return state;
	}
}
