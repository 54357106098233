import React, {Fragment, useEffect, useState} from 'react';
import ButtonOption from '../hover/ButtonOption';
import {Translate} from 'react-localize-redux';
import {getOption} from '../../../../helpers/data/defaultValueSelfAudit';


function CheckBoxOption(props) {
  const {
    name,
    handleSelected,
    index,
    description,
    typeHover,
    value,
    hover,
    typeValue,
    valCheked,
    key
  } = props;
  const [status, setStatus] = useState(false);
  const [initValue, setInitValue] = useState(null);

  useEffect(() => {
    const val = valCheked.find(v => v.shelf_audit_field_id === value) || null;
    if (val) {
      setInitValue(val.values)
    }
  }, [])

  const options = getOption(typeHover)

  return (
    <Fragment>
      <div className="d-flex" key={key}>
        <div className="form-group check-sidely my-2">
          <label className="ml-3" key={index}>
            <input
              name={name}
              id="picture_policy1"
              type="checkbox"
              checked={valCheked.find(v => v.shelf_audit_field_id === value) || false}
              onClick={e =>
                handleSelected(value, e.target.checked, typeValue, name, initValue)
              }
            />
            <div className="checkbox-style" />
          </label>
        </div>
        <small
          className="ml-4 mt-3 pt-1"
          onMouseEnter={() => setStatus(true)}
          onMouseLeave={() => setStatus(false)}
        >
          <Translate id={name} />
        </small>
      </div>
      {hover && status && typeHover !== null && (
        <ButtonOption
          description={description}
          label={name}
          type={typeHover}
          setStatus={setStatus}
          options={options}
          onClickValue={props.onClickValue}
          value={props.value}
          initValue={initValue}
          setInitValue={setInitValue}
          typeValue={props.typeValue}
          key={`btn-hover${props.value}`}
        />
      )}
    </Fragment>
  );
}

export default CheckBoxOption;
