import * as React from 'react';
import { Redirect } from 'react-router';
import { BreadcrumElement, BreadcrumStyle } from './model/Model';
import { Container, CrumImage, CrumText, Separator } from './style/Style';

function Breadcrum(props: {
  elements: BreadcrumElement[]
  separator?: string
  selectedElement?: number
  breadcrumStyle?: BreadcrumStyle
}): JSX.Element {
	const [elements, setElements] = React.useState<BreadcrumElement[]>([]);
	const [redirect, setRedirect] = React.useState<JSX.Element>();

	function update() {
		setElements(props.elements.filter((e, index) => props.selectedElement ? props.selectedElement >= index : true));
		setRedirect(undefined);
	}

	function onRedirect(element: BreadcrumElement) {
		if (!element.link) return;
		setRedirect(<Redirect push to={element.link} />);
	}

	React.useEffect(() => {
		update();
	}, []);

	React.useEffect(() => {
		update();
	}, [JSON.stringify(props.elements), props.selectedElement]);

	return (
		<Container marginLeft={props.breadcrumStyle?.marginLeft}>
			{redirect}
			{
				elements.map((e, index) => {
					if (e.img_url) {
						return (
							<>
								<CrumImage onClick={() => onRedirect(e)} src={e.img_url} />
								{
									(elements.length - 1) !== index &&
                                    <Separator value={props.separator} />
								}
							</>

						);
					} else {
						return (
							<>
								<CrumText nonClickable={e.img_url == undefined} onClick={() => onRedirect(e)} isBold={(elements.length - 1) === index}>{e.label}</CrumText>
								{
									(elements.length - 1) !== index &&
                                    <Separator value={props.separator} />
								}
							</>
						);
					}
				})
			}
		</Container>
	);
}

export default Breadcrum;
