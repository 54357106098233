import 'reactflow/dist/style.css';
import { Form } from '../create-from-template/type';
import * as _ from 'lodash';
import { Component, COMPONENT_TYPE_LIST, FieldRef, PaginationData, ScreenLink } from '../jsonValidator';
import { Field } from '../../../atoms/forms';

export function getPageList(form: Form): string[] {
	return form.map(screen => screen.name).filter((item): item is string => !!item);
}

export function getPageByName(form: Form, pageName: string): number {
	return form.findIndex(screen => screen.name == pageName);
}

export function renameComponentsAndFieldsInLayout(form: Form, pageNumber: number, oldName: string | undefined, newName: string | undefined): Form {
	const newForm: Form = _.cloneDeep(form);
	
	const screen = newForm[pageNumber];

	// switch (screen.layout.type) {
	// 	case 'column': {
	// 		screen.layout.children.forEach(child => {
	// 			if (child.name == oldName) {
	// 				child.name = newName;
	// 			}
	// 		});
	// 		break;
	// 	}
	// }
	// }


	return newForm;
}

export function renamePageAndNextScreens(form: Form, oldName: string | undefined, newName: string | undefined): Form {
	const newForm: Form = _.cloneDeep(form); 
	newForm.forEach(screen => {
		if (screen.name == oldName) {
			screen.name = newName;
		}
		screen.next_screens.forEach(nextScreen => {
			if (nextScreen.screen_name == oldName) {
				nextScreen.screen_name = newName;
			}
		});
		screen.components.forEach(component => {
			if (typeof component !== 'string' && Array.isArray(component.data) && component.data[0] == oldName) {
				component.data[0] = newName;
			}
		});
	});
	
	return newForm;
}

export function replaceFieldToPage(form: Form, pageName: string, oldField: string, field: FieldRef): Form {
	const newForm: Form = _.cloneDeep(form);

	newForm.forEach(screen => {
		if (screen.name == pageName) {
			screen.fields.forEach((f, index) => {
				if (f.name == oldField) {
					screen.fields[index] = field;
				}
			});
		}
	
		if (screen.layout && screen.layout.children) {
			screen.layout.children.forEach((child, index) => {
				if (screen.layout.children && child.name == oldField) {
					screen.layout.children[index] = { name: field.name, type: 'field' };
				}
			});
		}
	});

	
	return newForm;
}

export function addFieldToPage(form: Form, pageName: string, field: FieldRef): Form {
	const newForm: Form = _.cloneDeep(form);
	newForm.forEach(screen => {
		if (screen.name == pageName) {
			screen.fields.push(field);

			if (screen.layout && screen.layout.children) {
				screen.layout.children.push({ name: field.name, type: 'field' });
			}
			else if (screen.layout) {
				screen.layout.children = [{ name: field.name, type: 'field' }];
			}
		}
	});
	return newForm;
}

export function removeGroupFromGrid(form: Form, pageNumber: number, path: string): Form {
	const newForm: Form = _.cloneDeep(form);
	
	const children = _.get(newForm[pageNumber], path);

	console.log('children', children);

	if (children) {
		console.log('children', children.children);
		children.children.forEach(child => {
			if (child.type === 'column') {
				removeGroupFromGrid(newForm, pageNumber, `${path}.children`);
			}
		});
		_.set(newForm[pageNumber], path, undefined);
	}
	return newForm;
}

export function removeFieldFromGrid(form: Form, pageNumber: number, fieldName: string, path: string): Form {
	const newForm: Form = _.cloneDeep(form);
	// Match the array index if it's at the end of the path
	const number = path.match(/\[(\d+)\]$/);
	if (number) {
		// Remove and extract the array index. This is needed because if we remove directly at the index, it will replace it with null
		path = path.replace(new RegExp(/\[(\d+)\]$/), '');
		_.get(newForm[pageNumber], path).splice(parseInt(number[1]), 1);
	} else {
		_.set(newForm[pageNumber], path, undefined);
	}
	newForm[pageNumber].fields = newForm[pageNumber].fields.filter(field => field.slug != fieldName);
	return newForm;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getParentArray(form: Form, pageNumber: number, path: string): any[] {
	const number = path.match(/\[(\d+)\]$/);
	path = path.replace(new RegExp(/\[(\d+)\]$/), '');
	if (number) {
		return _.get(form[pageNumber], path);
	}
	return [];
}

export function getParentArrayIndex(form: Form, pageNumber: number, path: string): number {
	const number = path.match(/\[(\d+)\]$/);
	if (number) {
		return parseInt(number[1]);
	}
	return -1;
}

export function swapInLayout(form: Form, pageNumber: number, path: string, relativeNewIndex: number): Form {
	const newForm: Form = _.cloneDeep(form);
	const number = path.match(/\[(\d+)\]$/);
	path = path.replace(new RegExp(/\[(\d+)\]$/), '');
	if (number) {
		const oldIndex = parseInt(number[1]);
		const array = _.get(newForm[pageNumber], path);
		[array[oldIndex], array[oldIndex + relativeNewIndex]] = [array[oldIndex + relativeNewIndex], array[oldIndex]];
	}
	return newForm;
}

export function addExistingComponentToGrid(form: Form, pageName: string, component: string, path: string): Form {
	const newForm: Form = _.cloneDeep(form);
	newForm.forEach((screen, index) => {
		if (screen.name == pageName) {
			_.get(newForm[index], path).push({ name: component, type: 'component' });
		}
	}); 
	return newForm;
}

export function addNewComponentToGrid(form: Form, pageName: string, component: Component, path: string): Form {
	const newForm: Form = _.cloneDeep(form);

	newForm.forEach((screen, index) => {
		if (screen.name == pageName) {
			screen.components.push(component);
			_.get(newForm[index], path).push({ name: component.name, type: 'component' });
		}
	}); 
	return newForm;
}


export function addFieldToGrid(form: Form, pageName: string, field: FieldRef, path: string): Form {
	const newForm: Form = _.cloneDeep(form);
	newForm.forEach((screen, index) => {
		if (screen.name == pageName) {
			_.get(newForm[index], path).push({ name: field.slug, type: 'field' });
			screen.fields.push(field);
		}
	}); 
	return newForm;
}

export function removeComponent(form: Form, pageNumber: number, componentName: string): Form {
	const newForm: Form = _.cloneDeep(form);
	newForm[pageNumber].components = newForm[pageNumber].components.filter(component => component.name != componentName);
	return newForm;
}


export function getField(form: Form, pageName: string, fieldName: string): FieldRef | undefined {
	let returnValue: FieldRef | undefined = undefined;
	form.forEach(screen => {
		if (screen.name == pageName) {
			screen.fields.forEach(field => {
				if (field.slug == fieldName) {
					returnValue = field;
				}
			});
		}
	});
	if (returnValue) {
		return returnValue;
	} else {
		return undefined;
	}
}

export function getComponent(form: Form, pageName: string | undefined, componentName: string | undefined): Component | undefined {
	let returnValue: Component | undefined = undefined;
	form.forEach(screen => {
		if (screen.name == pageName) {
			screen.components.forEach(component => {
				if (component.name == componentName) {
					returnValue = component;
				}
			});
		}
	});
	if (returnValue) {
		return returnValue;
	} else {
		return undefined;
	}
}

export function getComponentPath(form: Form, pageNumber: number, componentName: string | undefined): string | undefined {
	let returnValue: string | undefined = undefined;
	form[pageNumber].components.forEach((component, index) => {
		if (component.name == componentName) {
			returnValue = `components[${index}]`;
		}
	});

	if (returnValue) {
		return returnValue;
	} else {
		return undefined;
	}
}

export function getFieldPath(form: Form, pageName: string, fieldName: string): string | undefined {
	let returnValue: string | undefined = undefined;
	form.forEach((screen) => {
		if (screen.name == pageName) {
			screen.fields.forEach((field, index) => {
				if (field.name == fieldName || field.slug == fieldName) {
					returnValue = `fields[${index}]`;
				}
			});
		}
	});
	if (returnValue) {
		return returnValue;
	} else {
		return undefined;
	}

}

export function nextScreenValidator(form: Form, nextScreen: ScreenLink): boolean {
	if (nextScreen.screen_name && pageExists(form, nextScreen.screen_name)) {
		return true;
	}
	else if (nextScreen.calculated_screen_name) {
		return true;
	}
	return false;
}

export function nextScreensValidator(form: Form, nextScreens: ScreenLink[]): boolean {
	let returnValue = true;
	nextScreens.forEach(nextScreen => {
		if (!nextScreenValidator(form, nextScreen)) {
			returnValue = false;
		}
	});

	return returnValue;
}

export function fieldInListValidator(field: FieldRef, fieldList: Field[]): boolean {
	if (field.slug === fieldList.find(f => f.slug === field.slug)?.slug) {
		return true;
	}
	return false;
}


// Checks if a field exists in the fields or components of a page
export function fieldValidator(form: Form, pageName: string, fieldName: string): boolean {
	let returnValue = false;
	form.forEach(screen => {
		if (screen.name == pageName) {
			screen.components.forEach(component => {
				if (component.name == fieldName) {
					returnValue = true;
				}
			});
			
			screen.fields.forEach(field => {
				if (field.slug == fieldName) {
					returnValue = true;
				}
			});
		}
	});
	return returnValue;

}

export enum ERROR_LEVEL {
	WARNING = 'warning',
	ERROR = 'error',
	INFO = 'info',
	CLEAR = 'clear'
}

function extractFirstTextBetweenQuotes(str): string {
	const matches = str.match(/"(.*?)"/);
	if (!matches) {
		return '';
	}
	return matches[1];
}
  

export function luaInValidatorOrVisibleIfValidator(form: Form, pageName: string, lua: string | undefined): boolean {
	if (lua && lua.includes('[') && lua.includes(']')) {
		const field = extractFirstTextBetweenQuotes(lua);
		if (field && lua && !fieldValidator(form, pageName, field)) {
			return false;
		} else {
			return true;
		}
	}
	return true;
}

export function pageValidator(form: Form, pageName: string, fieldList: Field[]): ERROR_LEVEL {
	let returnValue = ERROR_LEVEL.CLEAR;
	form.forEach((screen) => {
		if (screen.name == pageName) {

			// Check if fields in layout exists
			if (screen.layout && screen.layout.children) {
				screen.layout.children.forEach(child => {
					if (child && child.name && child.type === 'field' && !fieldValidator(form, pageName, child.name)) {
						console.log('error: field not found', child.name);
						returnValue = ERROR_LEVEL.ERROR;
					}
					if (child && child.name && child.type === 'component' && !getComponent(form, pageName, child.name)) {
						console.log('error: component not found', child.name);
						returnValue = ERROR_LEVEL.ERROR;
					}
					if (child && child.name) {
						const field = getField(form, pageName, child.name);
						if (field && !fieldInListValidator(field, fieldList)) {
							console.log('error: field  not in list', field.name, field);
							returnValue = ERROR_LEVEL.WARNING;
						}
					}
					if (child.visible_if && !luaInValidatorOrVisibleIfValidator(form, pageName, child.visible_if)) {
						console.log('error in lua for visible_if:', child.visible_if);
						returnValue = ERROR_LEVEL.ERROR;
					}
				});
			}

			// Check if components are valid
			screen.components.forEach(component => {
				if (!COMPONENT_TYPE_LIST.includes(component.type)) {
					console.log('error: invalid component type', component.type);
					returnValue = ERROR_LEVEL.WARNING;
				}

				switch (component.type) {
					case 'pagination': {
						const paginationData = component.data as PaginationData;
						const nextScreens: ScreenLink[] = [];
						paginationData.value.forEach(value => {
							nextScreens.push(value.screen);
						});
						if (component.data && !nextScreensValidator(form, nextScreens)) {
							console.log('error: invalid next screens in pagination', nextScreens);
							returnValue = ERROR_LEVEL.WARNING;
						}
						return;
					}
					case 'catalogue_dispatcher': {
						if (component.screen && !nextScreenValidator(form, component.screen)) {
							console.log('error: invalid next screen in catalogue dispatcher', component.screen);
							returnValue = ERROR_LEVEL.WARNING;
						}
						return;
					}
					case 'card_list': {
						return;
					}
					default:
						return;
				}
			});

			// Check if next screens are valid
			screen.next_screens.forEach(nextScreen => {
				if (nextScreen.screen_name && !nextScreenValidator(form, nextScreen)) {
					console.log('error: invalid next screen', nextScreen.screen_name);
					returnValue = ERROR_LEVEL.ERROR;
				}
			});
		}
	});
	return returnValue;
}

export function pageExists(form: Form, pageName: string): boolean {
	return form.find(screen => {
		if (screen) {
			return (screen.name == pageName);
		}
		else {
			return false;
		}
	}) != undefined;
}
