/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import {Translate} from 'react-localize-redux'

class PropNumerical extends Component {
  state = {};

  render() {
    const { element, updateElement, editElementProp } = this.props;

    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Numerical</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize
            id="field-name"
            type="text"
            className="form-control"
            defaultValue={element.field_name}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'field_name', 'value')}
          />
        </div>

        <div className="custom-control custom-checkbox">
          <input
            id="is-display-field-name"
            className="custom-control-input"
            type="checkbox"
            defaultChecked={element.is_display_field_name}
            onBlur={updateElement.bind(this)}
            disabled={this.props.isEdit}
            onChange={editElementProp.bind(
              this,
              'is_display_field_name',
              'checked'
            )}
          />
          <label
            className="custom-control-label"
            htmlFor="is-display-field-name"
          >
            Display field name
          </label>
        </div>

        <div className="form-group  mt-3">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <input
            id="system-field-name"
            type="text"
            className="form-control"
            disabled={this.props.isEdit}
            defaultValue={element.system_field_name}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'system_field_name', 'value')}
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Validation
          </label>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              id="is-required"
              className="custom-control-input"
              type="checkbox"
              defaultChecked={this.props.element.is_required}
              onBlur={this.props.updateElement.bind(this)}
              disabled={this.props.isEdit}
              onChange={this.props.editElementProp.bind(
                this,
                'is_required',
                'checked'
              )}
            />
            <label className="custom-control-label" htmlFor="is-required">
              <Translate id="Field required" />
            </label>
          </div>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            <Translate id="Initial Value" />
          </label>
          <input
            id="initial-value"
            type="number"
            disabled={this.props.isEdit}
            className="form-control"
            defaultValue={element.initial_value}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'initial_value', 'value')}
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Min Value
          </label>
          <input
            id="min-value"
            type="number"
            disabled={this.props.isEdit}
            className="form-control"
            defaultValue={element.min}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'min', 'value')}
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Max Value
          </label>
          <input
            id="max-value"
            disabled={this.props.isEdit}
            type="number"
            className="form-control"
            defaultValue={element.max}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'max', 'value')}
          />
        </div>
      </div>
    );
  }
}

export default PropNumerical;
