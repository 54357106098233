import { WebSocketMessage } from '../../components/toolbar/WebSocketListener';
import { getLocalStorage } from '../../helpers/localStorage';

const NB_PER_SLICE = 5000;
const MAX_MESSAGES = 5;

export interface WebSocketState {
  slice: number
  check: unknown[]
  percentage: number
  showProgressBar: { value: boolean }
  connected: boolean
  is_job_running: Date | null
  messages: WebSocketMessage[]
}

const initState: WebSocketState = {
	slice: 0,
	check: [],
	percentage: 0,
	showProgressBar: { value: false },
	connected: false,
	is_job_running: null,
	messages: getLocalStorage('webSocketMessages') ?? []
};

function webSocketReducer(state = initState, action) {
	switch (action.type) {
		case 'FETCHED_ROWS': {
			if (state.percentage === 1) {
				state.slice += 1;
			}
			if (state.check.length >= NB_PER_SLICE) {
				state.check = action.value;
			} else {
				state.check = state.check.concat(action.value);
				if (state.check.length >= NB_PER_SLICE) {
					state.slice += 1;
				}
			}
			return {
				...state
			};
		}
		case 'FETCHED_ROW':
			if (state.check.length == NB_PER_SLICE - 1 || state.percentage === 1) {
				state.slice += 1;
			}
			if (state.check.length >= NB_PER_SLICE) {
				state.check = [action.value];
			} else {
				state.check.push(action.value);
			}
			return {
				...state
			};
		case 'PERCENTAGE':
			if (action.value === 1) {
				state.slice += 1;
			}
			return {
				...state,
				percentage: action.value,
				check: action.value === 0 ? [] : state.check
			};
		case 'SHOW_PROGRESS_BAR':
			return {
				...state,
				showProgressBar: action.value
			};
		case 'JOB_RUNNING':
			return {
				...state,
				is_job_running: new Date(parseInt(action.value, 10) * 1000)
			};
		case 'CONNECTION_STATUS':
			return {
				...state,
				connected: action.value
			};
		case 'INSERT_MESSAGE': {
			let messages: WebSocketMessage[] = getLocalStorage('webSocketMessages') ?? [];
			const message_obj = action.value.message as object;
			const loading_index = messages.map(e => e.loadingState).lastIndexOf('loading');
			const message: WebSocketMessage = {
				time: Date.now(),
				text: message_obj['text'],
				fileName: message_obj['file_name'],
				loadingState: action.value.state
			};
			if (typeof action.value.message === 'string') {
				message.text = action.value.message;
			}
			if (message_obj['replace_loading'] && loading_index >= 0) {
				messages.splice(loading_index, 1, message);
			} else {
				messages.unshift(message);
			}
			messages = messages.slice(0, MAX_MESSAGES);
			localStorage.setItem('webSocketMessages', JSON.stringify(messages));
			return {
				...state,
				messages
			};
		}
		default:
			return state;
	}
}

export default webSocketReducer;
