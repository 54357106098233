/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';

class FreeText extends Component {
  state = {};

  render() {
    const { data } = this.props;
    console.log('dataFreeText', data);
    return (
      <div className="form-group">
        <label id={`id-${data.id}`} className="control-label" htmlFor="">
          {data.is_required && <span className="text-danger">* </span>}
          {data.is_display_field_name && data.field_name}
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={data.initial_value}
        />
      </div>
    );
  }
}

export default FreeText;
