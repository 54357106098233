import * as React from 'react';
import { loadingState } from '../../../components_v2/models';
import axios from 'axios';
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';

import { queryConstructor } from '../utils';
import { getPresencePerProducts } from './actions';
import { reportingAction, reportingState } from '../Reducer';
import PageLoader from '../../../components/PageLoader';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { useRecoilValue } from 'recoil';
import { ATimeRangeFilter } from '../../../atoms/filter/timeFilterAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { useWindowDimensions } from '../../../components_v2/utils';
import PresencePerProductChart from './PrecencePerProductsChart';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { BorderColor } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export function PresencePerProducts(props: {
	setToolBarState: (value: ToolbarState) => void
	state: reportingState
	dispatch: (action: reportingAction) => void
	width: number
	height: number
}) {
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any>({});

	const filteredTags = useRecoilValue(ATagFilter);
	const timeRangeFilter = useRecoilValue(ATimeRangeFilter);
	const { width } = useWindowDimensions();
	const translate = getTranslate(storeLang.getState().localize);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 120}
					category={AtomCategory.GLOBAL}
					elements={[
						ToolbarElement.TAG_FILTER,
						ToolbarElement.TIME_RANGE_FILTER
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'}/>
			</FlexDiv>,
			bottomRightToolbarComponent: <></>
		});
	}, [loadingState, width]);

	React.useEffect(() => {
		if (!loadingState && (filteredTags != null) && timeRangeFilter) {
			try {
				if (!loadingState) {
					setLoadingState('loading');
				}
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				getPresencePerProducts(queryConstructor({
					timeRange: timeRangeFilter,
					tags: filteredTags
				}, undefined), cancelTokenSource.token)
					.then(res => {
						// set default left filter - all -
						props.state.filters.PresencePerProducts = res.data.labels;
						props.dispatch({
							type: 'SET_FILTERS',
							value: { ...props.state.filters }
						});

						// set list of products
						props.dispatch({
							type: 'SET_PRODUCTS',
							value: res.data.labels
						});
						setData(res.data);
						if (loadingState != 'loaded') {
							setLoadingState('loaded');
						}
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState, props, filteredTags, timeRangeFilter]);

	return (
		<div style={{ fontSize: '12px' }}>
			{(loadingState == 'loading' || !loadingState) && <div className='list-loader'><PageLoader/></div>}
			{loadingState == 'error' && <p>Error fetching data</p>}
			{loadingState == 'loaded' &&
				<div style={{
					backgroundColor: '#FFFFFF',
					// width: `${props.width - 30}px`,
					height: `calc(${props.height}px - 130px)`,
					border: `1px solid ${BorderColor}`
				}}>
					<PresencePerProductChart
						data={data}
						filters={props.state.filters.PresencePerProducts}
					/>
				</div>
			}
		</div>);
}
