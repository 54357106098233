import * as React from 'react';
import { loadingState } from '../../../components_v2/models';
import axios from 'axios';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { getLang, queryConstructor } from '../utils';
import { getCompanyStatusEvolution } from './actions';
import { reportingAction, reportingState } from '../Reducer';
import PageLoader from '../../../components/PageLoader';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { useRecoilValue } from 'recoil';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { useWindowDimensions } from '../../../components_v2/utils';
import { BorderColor } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip
);

function filterData(filters: any, data: any, today_str: string) {
	const datasets: any[] = [];

	// init total
	const total_dataset: number[] = [];
	for (let i = 0; i < data.x_axis.length; i++) {
		total_dataset.push(0);
	}

	if (data.datasets != null && data.datasets != undefined &&
		data.statuses != null && data.statuses != undefined) {
		for (const dataset of data.datasets) {
			if (filters.filter(f => f == dataset.label).length > 0) {
				for (let i = 0; i < data.x_axis.length; i++) {
					if (dataset.data[i] != null) {
						total_dataset[i] = total_dataset[i] + dataset.data[i];
					}
				}
				datasets.push({
					order: 1,
					label: data.statuses[dataset.label].name,
					data: dataset.data,
					borderColor: data.statuses[dataset.label].color_code,
					backgroundColor: data.statuses[dataset.label].color_code + '80'
				});
			}
		}
	}

	datasets.push({
		type: 'line',
		order: 0,
		label: 'total',
		lineTension: 0.1,
		data: total_dataset,
		borderColor: '#3A9DF6',
		backgroundColor: '#3A9DF680',
		borderWidth: 1
	});

	let labels = [];
	if (data.x_axis != null && data.x_axis != undefined && data.x_axis.length > 0) {
		labels = data.x_axis.map(x_axis_label => {
			const date = new Date(x_axis_label);
			const now = new Date(Date.now());
			if (date.getDay() == now.getDay() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
				return today_str;
			} else {
				return date.toLocaleString(getLang(), { month: 'short', year: 'numeric' });
			}
		});
	}

	return {
		labels,
		datasets
	};
}

/*

      return {
        userPicker: 1,
        tagFilter: true,
        refreshButton: { status: true, props: { isLoading: isLoading } },
      }
  */

export function CompanyStatusEvolution(props: {
	setToolBarState: (value: ToolbarState) => void
	state: reportingState
	dispatch: (action: reportingAction) => void
	width: number
	height: number
}) {
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any>({});
	const translate = getTranslate(storeLang.getState().localize);
	const { width } = useWindowDimensions();

	const pickedUsers = useRecoilValue(AUserPicker(0));
	const filteredTags = useRecoilValue(ATagFilter);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 120}
					category={AtomCategory.GLOBAL}
					elements={[
						{
							kind: ToolbarElement.USER_PICKER
						},
						ToolbarElement.TAG_FILTER
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'}/>
			</FlexDiv>,
			bottomRightToolbarComponent: <></>
		});
	}, [loadingState, width]);

	React.useEffect(() => {
		if (!loadingState && (pickedUsers != null) && (filteredTags != null)) {
			try {
				if (!loadingState) {
					setLoadingState('loading');
				}
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				getCompanyStatusEvolution(queryConstructor({
					tags: filteredTags,
					userIdList: pickedUsers?.[0]
				}, undefined), cancelTokenSource.token)
					.then(res => {
						props.state.filters.CompanyStatusEvolution = Object.keys(res.data.statuses);
						props.dispatch({
							type: 'SET_FILTERS',
							value: { ...props.state.filters }
						});
						props.dispatch({
							type: 'SET_COMPANIES_STATUS',
							value: Object.entries(res.data.statuses)
								.map((status: Object) => {
									return { ...status[1], value: status[0] };
								})
						});
						setData(res.data);
						if (loadingState != 'loaded') {
							setLoadingState('loaded');
						}
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState, pickedUsers, filteredTags]);

	const options = {
		responsive: true,
		plugins: {
			datalabels: {
				display: function() {
					return false;
				},
			},
			legend: {
				position: 'bottom' as const,
				display: true,
			},
			title: {
				display: true
			}
		},
		scales: {
			x: {
				stacked: true
			},
			y: {
				stacked: true,
				beginAtZero: true
			}
		}
	};

	return (
		<div style={{ fontSize: '12px' }}>
			{(loadingState == 'loading' || !loadingState) && <div className='list-loader'><PageLoader/></div>}
			{loadingState == 'error' && <p>Error fetching data</p>}
			{loadingState == 'loaded' &&
				<div style={{
					backgroundColor: '#FFFFFF',
					height: `calc(${props.height}px - 130px)`,
					border: `1px solid ${BorderColor}`
				}}>
					<Bar
						options={options}
						data={filterData(props.state.filters.CompanyStatusEvolution, data, translate('shelf_audit.sections.misc.today').toString())}/>;
				</div>
			}
		</div>
	);
}
