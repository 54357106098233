import styled from 'styled-components';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { FilterBlue, GreySidely } from '../../../styles/global/css/Utils';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
`;

const HeaderContainer = styled.div`
    width: 100%;
    height: 15%;
    border-bottom: solid 1px ${GreySidely};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
`;

const BodyContainer = styled.div`
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px ${GreySidely};
`;

const FooterContainer = styled.div`
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const HeaderLeft = styled.div`
    width: 80%;
    overflow-x: clip;
`;

const HeaderRight = styled.div`
    width: 20%;
    display: flex;
    justify-content: flex-end;
`;

const TextArea = styled.textarea`
    ${DefaultText}
    width: 80%;
    height: 80%;
    outline-style: none;
    border: none;
`;

interface ButtonImageProps {
  isSelected?: boolean
  canHover?: boolean
}

const ButtonImage = styled(DefaultImage)<ButtonImageProps>`
    cursor: pointer;
    filter: ${p => p.isSelected ? FilterBlue : 'none'};

    &:hover {
        ${p => p.canHover
		? `filter: ${FilterBlue};`
		: ''}
    }
`;

const DateText = styled.p`
    ${DefaultText}
    letter-spacing: 0.01em;
    color: #90a0b7;
    font-weight: 400;
    font-size: 12px;
`;

export {
	Container,
	HeaderContainer,
	BodyContainer,
	FooterContainer,
	HeaderLeft,
	HeaderRight,
	TextArea,
	ButtonImage,
	DateText
};
