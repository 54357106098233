import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR';

export const DETAIL_CATEGORY_REQUEST = 'DETAIL_CATEGORY_REQUEST';
export const DETAIL_CATEGORY_SUCCESS = 'DETAIL_CATEGORY_SUCCESS';
export const DETAIL_CATEGORY_ERROR = 'DETAIL_CATEGORY_ERROR';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const SEARCH_CATEGORY = 'SEARCH_CATEGORY';
export const SORT_CATEGORY = 'SORT_CATEGORY';

const PATH = `${URL_API}/api`;

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_CATEGORY_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/categories`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_CATEGORY_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_CATEGORY_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_CATEGORY_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_CATEGORY_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/categories/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_CATEGORY_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_CATEGORY_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_CATEGORY_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteData(id, isRedirect) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_CATEGORY_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/categories/${id}`
		}).then(
			() => {
				if (isRedirect) {
					window.location.href = '/settings#categories_management';
				} else {
					dispatch(fetchData());
				}
			},
			error => {
				swal.fire('Error!', error.response.data.message, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_CATEGORY_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/categories`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_CATEGORY_SUCCESS,
						payload: response.data
					});

					window.location.href = '/settings#categories_management';
				},
				error => {
					dispatch({
						type: CREATE_CATEGORY_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.message, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_CATEGORY_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_CATEGORY_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/categories/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_CATEGORY_SUCCESS,
					payload: response.data
				});
				window.location.href = '/settings#categories_management';
			},
			error => {
				dispatch({
					type: UPDATE_CATEGORY_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.message, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export const searchData = data => async dispatch => {
	dispatch({
		type: SEARCH_CATEGORY,
		payload: data
	});
};

export const sortData = data => async dispatch => {
	// console.log('sort', data);
	dispatch({
		type: SORT_CATEGORY,
		payload: data
	});
};
