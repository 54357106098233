/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Cookies} from 'react-cookie';
import {connect} from 'react-redux';
import axios from 'axios';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {URL_API_BUILDER} from '../../../config/keys';
import RenderSelectInput from '../../../components/select/RenderSelectInputModal';
import {get} from 'lodash';

const cookies = new Cookies();

class ModalFilterOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataType: 'csv',
      modal: false
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  closeModal = () => {
    this.setState({ modal: false });
  };

  openModal = () => {
    this.setState({ modal: true });
  };

  exportFile = data => {
    const token = cookies.get('id_token') || null;

    const { getterValue } = this.props;
    const formId = get(getterValue, 'id');

    axios.defaults.headers.common.Authorization = `${token}`;
    axios({
      url: `${URL_API_BUILDER}/v1/api/${data}/userfilledform/${formId}`,
      method: 'GET',
      responseType: 'blob' // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.click();
    });

    this.closeModal();
    // onExport(dataExport, data);
  };

  handleType = data => {
    this.setState({
      dataType: data.value
    });
  };

  render() {
    const { dataType, modal } = this.state;

    const opt = [
      //   {
      //     label: '.CSV',
      //     value: 'csv'
      //   },
      {
        label: '.XLSX',
        value: 'excel'
      }
    ];

    return (
      <Modal
        isOpen={modal}
        toggle={this.closeModal}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          style={{ borderBottom: 'none' }}
        >
          Export File
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <Field
              name="data_type"
              component={RenderSelectInput}
              label="Type File"
              requiredStar
              options={opt}
              id="inputOwner"
              onChange={this.handleType}
              placeholder="Select type file"
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <Button
            color="primary"
            className="px-5 mb-2"
            onClick={() => {
              this.exportFile(dataType);
              //   this.props.toggle();
            }}
          >
            Export
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

export default reduxForm({
  form: 'modalFilterOwner'
})(
  connect(
    mapStateToProps,
    {}
  )(ModalFilterOwner)
);
