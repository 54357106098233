import { DropdownData } from '../../dropdown/model/Model';

enum PopupMode {
  Default,
  Details,
  Extended,
  Centered
}

export enum PopupAnimation {
  RightToLeft,
  Appearing
}

interface PopupStyle {
  width?: string
  maxHeight?: string;
  height?: string
  top?: string
  left?: string
  minWidth?: string
  minHeight?: string
  animate?: boolean
  borderRadius?: string
  boxShadow?: string
  zIndex?: number
  transform?: string
  position?: string
  padding?: string
  overflow?: string
  animationStyle?: PopupAnimation
  fitContent?: boolean
  containerTransform?: string
  noTransition?: boolean
}

interface PopupProps {
  content?: JSX.Element
  onClickOut?: () => void
  isOpen: boolean
  hasBackground?: boolean
  disableOutClick?: boolean
  disableEsc?: boolean
  popupStyle?: PopupStyle
  popupMode?: PopupMode
  children?
  catchOutsideEventClick?: boolean
}

interface PopupExportProps extends PopupProps {
  exportCSV?: boolean
  exportPDF?: boolean
  status?: boolean
  exportExcel?: boolean
  onClickExport?: (value: DropdownData | undefined) => void
}

export {
	PopupStyle,
	PopupProps,
	PopupExportProps,
	PopupMode
};
