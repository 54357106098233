import {
	CREATE_DATA_TARGET_ERROR,
	CREATE_DATA_TARGET_REQUEST,
	CREATE_DATA_TARGET_SUCCESS,
	DETAIL_DATA_TARGET_ERROR,
	DETAIL_DATA_TARGET_REQUEST,
	DETAIL_DATA_TARGET_SUCCESS,
	FETCH_DATA_TARGET_ERROR,
	FETCH_DATA_TARGET_REQUEST,
	FETCH_DATA_TARGET_SUCCESS,
	LIST_DATA_TARGET_ERROR,
	LIST_DATA_TARGET_REQUEST,
	LIST_DATA_TARGET_SUCCESS,
	UPDATE_DATA_TARGET_ERROR,
	UPDATE_DATA_TARGET_REQUEST,
	UPDATE_DATA_TARGET_SUCCESS
} from '../actions/targerManagement.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	current: {
		name: 'username'
	},

	data: [],
	listData: [],
	search: '',
	detailData: [],
	detail: []
};

export default function targetManagReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_DATA_TARGET_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_DATA_TARGET_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoading: false
			};
		case FETCH_DATA_TARGET_ERROR:
			return {
				...state,
				isLoading: false
			};
		case CREATE_DATA_TARGET_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_DATA_TARGET_SUCCESS:
			return {
				...state,
				activeTab: action.activeTab,
				isLoading: false
			};
		case CREATE_DATA_TARGET_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_DATA_TARGET_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_DATA_TARGET_SUCCESS:
			return {
				...state,
				activeTab: action.activeTab,
				isLoading: false
			};
		case UPDATE_DATA_TARGET_ERROR:
			return {
				...state,
				isLoading: false
			};
		case LIST_DATA_TARGET_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case LIST_DATA_TARGET_SUCCESS:
			return {
				...state,
				isLoading: false,
				listData: action.payload.data
			};
		case LIST_DATA_TARGET_ERROR:
			return {
				...state,
				isLoading: false
			};
		case DETAIL_DATA_TARGET_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_DATA_TARGET_SUCCESS:
			return {
				...state,
				isLoadingDetail: false,
				detailData: action.payload.data
			};
		case DETAIL_DATA_TARGET_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		default:
			return state;
	}
}
