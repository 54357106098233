import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { BlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { Radio } from '../../filterList/style/Style';
import { FilterAction, FilterAdditionalValue, FilterId, FilterQueryResult, FilterTree, FilterValueResult } from '../model/Model';
import { Container, FilterText, RadioContainer } from '../style/AdvancedFilterStyle';
import { filterIdFormater } from './FilterSelection';
import { FilterToIndex } from './utils';

function indexToFilter(index: number | undefined): FilterAction | undefined {
	switch (index) {
		case 0:
			return FilterAction.IS_EMPTY;
		case 1:
			return FilterAction.IS_NOT_EMPTY;
		default:
			return;
	}
}

function switchFilterToIndex(filter: FilterAction | undefined): number | undefined {
	switch (filter) {
		case FilterAction.IS_EMPTY:
			return 0;
		case FilterAction.IS_NOT_EMPTY:
			return 1;
		default:
			return;
	}
}

export default function NullableFilter(props: {
  id: FilterId
  title: string
  onReturn: () => void
  onSubmit: (value: FilterValueResult) => void
  additionalValue?: FilterAdditionalValue
  selectedValue: FilterTree | undefined
}): JSX.Element {
	let selectedValue: FilterQueryResult | undefined;
	if (props.selectedValue && 'val' in props.selectedValue) {
		selectedValue = props.selectedValue.val;
	}
	const [index, setindex] = React.useState<number | undefined>(FilterToIndex(selectedValue?.operator, switchFilterToIndex));
	const id = filterIdFormater(props.id, props.additionalValue);

	function submit(): void {
		if (index != 0 && index != 1) {
			return;
		}
		const action = indexToFilter(index);
		if (!action) return;
		if (index === 0) {
			props.onSubmit({
				action,
				type: 'nullable',
				title: props.title,
				id
			});
			return;
		}
		else if (index === 1) {
			props.onSubmit({
				action,
				type: 'nullable',
				title: props.title,
				id
			});
		}
	}

	return (
		<Container>
			<FilterText color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.onReturn()}>&lt;<Translate id='back' /></FilterText>
			<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{props.title}</FilterText>
			<RadioContainer onClick={() => setindex(0)}>
				<Radio isActive={index === 0} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_empty' /></FilterText>
			</RadioContainer>
			<RadioContainer onClick={() => setindex(1)}>
				<Radio isActive={index === 1} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not_empty' /></FilterText>
			</RadioContainer>
			<DefaultButton width="125px" disabled={(index != 0 && index != 1)
			} onClick={() => submit()}><Translate id='add_filter' /></DefaultButton>
		</Container>
	);
}

