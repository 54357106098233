import { EventFrequency, LegendItem, LegendListContainer, PercentStackBar } from './EventFrequencyWidget';
import { DashboardItem } from '../Dashboard';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { FlexDiv } from '../../products/style';
import { Translate, translateToString } from '../../../styles/global/translate';
import { getAvgColorByFrequencyScore } from '../../../atoms/global/frequency';
import { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import {
	CreateButton,
	DeleteButton,
	EditButton, NoEvent, ScrollDiv,
	WidgetBody,
	WidgetTitle,
	WidgetTitleWrapper
} from '../Widget';
import { useMemo } from 'react';

interface EventFrequencyByUser {
	owner_id: number,
	datalist: EventFrequency[]
}
export default function EventFrequencyByUserWidget(props: {
	value: EventFrequencyByUser[],
	item: DashboardItem,
	selected?: boolean,
	onSelect?: (selected: boolean) => void,
	setItem: (item: DashboardItem) => void,
	onCreationClicked?: () => void
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
	isHover?: boolean
}) {
	const { value, item, onCreationClicked , onClickDelete, isHover, onClickEdit } = props;
	const [legendTop, setLegendTop] = React.useState<number | undefined>(undefined);
	const [currentDataList, setCurrentDataList] = React.useState<EventFrequency[]>([]);
	const users = useRecoilValue(AUsers);

	function handleMouseOver(index: number, datalistWithColor: EventFrequency[]) {
		setLegendTop(80 + index * 30);
		setCurrentDataList(datalistWithColor);
	}
	function handleMouseLeave(e) {
		setLegendTop(undefined);
		setCurrentDataList([]);
	}

	const valuesSortedByLastName = useMemo(() => {
		return value.sort((a, b) => {
			const aUser = users.find(user => user.id === a.owner_id);
			const bUser = users.find(user => user.id === b.owner_id);

			const aLastName = aUser?.name.split(' ')[aUser?.name.split(' ').length - 1];
			const bLastName = bUser?.name.split(' ')[bUser?.name.split(' ').length - 1];

			return aLastName?.localeCompare(bLastName ?? '') ?? 0;
		});
	}, [users]);

	return <>
		<WidgetTitleWrapper>
			<FlexDiv justify="space-between" align='center' width='100%'>
				<WidgetTitle>
					{item.title ?? `${translateToString('frequency')}  ${translateToString('by')} ${translateToString('user')}`}
				</WidgetTitle>
				<FlexDiv justify="flex-end" gap="2px">
					{onCreationClicked && <CreateButton onClickCreate={onCreationClicked} />}
					{ isHover && onClickEdit && <EditButton onClickEdit={() => onClickEdit(item)}/>}
					{ isHover && onClickDelete && <DeleteButton onClickDelete={() => onClickDelete(item.id as number)} />}
				</FlexDiv>
			</FlexDiv>
		</WidgetTitleWrapper>
		{value.length === 0 && <NoEvent/>}
		{value.length > 0 &&
			<WidgetBody small>
				{ valuesSortedByLastName.map((current, index) => {
					const datalistWithColor: EventFrequency[] = current.datalist
						.sort((a,b) => a.max - b.max)
						.map((current) => ({ ...current, color: getAvgColorByFrequencyScore(current.min, true) }));
					// 'if onCreationClicked' is a trigger and that refers to user hard coded in constants.ts(for dashboardPopup)
					return <FlexDiv key={`user_frequency_chart${index}`} width="100%" justify="space-between" onMouseOver={() => handleMouseOver(index, datalistWithColor)} onMouseLeave={handleMouseLeave}>
						<UserBlock user={users.find(user => user.id === current.owner_id)} width="200px" flexStyle={{ overflow:'hidden' }}/>
						<PercentStackBar datalist={datalistWithColor}/>
					</FlexDiv>;
				})}
			</WidgetBody>
		}
		{legendTop &&
			<LegendListContainer style={{ position:'absolute', top:`${legendTop}px`, right:'18px', width:'200px' }}>
				{currentDataList.map((current, index) =>
					current.count !== 0 &&
					<LegendItem
						key={`legendItem[${index}]`}
						color={current.color}
						min={current.min} max={current.max} count={current.count}
						hasBorder={index !== currentDataList.length - 1}
					/>
				)}
			</LegendListContainer>
		}
	</>;
}


