import React, {useEffect, useState} from 'react';
import {getElements, listButton} from '../../../helpers/data/enrichedForm';
import {setLocalStorage} from '../../../helpers/localStorage';
import {shelfAuditSelect} from '../../../store/actions/enForm.action';
import {connect} from 'react-redux';
import ComponentFields from './componentForm/ComponenFields';
import HeaderArrowBack from './componentForm/Button/HeaderArrowBack';
import DefaultButton from './componentForm/Button/DefaultButton';
import CloseButton from './componentForm/Button/CloseButton';
import storeLang from '../../../helpers/storeLang'
import {getTranslate} from 'react-localize-redux'

function ListElements(props) {
  const translate = getTranslate(storeLang.getState().localize)
  const { history, match } = props;
  useEffect(() => {
    props.shelfAuditSelect();
  }, []);
  const data = JSON.parse(localStorage.getItem('enFormTemplate'));
  const [elements, setElements] = useState(data.elements || []);
  const handleSelected = (shelf_audit_field_id, checked, type, field_name, values) => {
    const status = elements.filter(v => v.shelf_audit_field_id === shelf_audit_field_id);
    if (status.length > 0) {
      const data = elements.filter(v => v.shelf_audit_field_id !== shelf_audit_field_id);
      setElements(data);
    } else {
      setElements([
        ...elements,
        { ...getElements(type), field_name, shelf_audit_field_id, type, values }
      ]);
    }
  };

  const onClickValue = (id, values) => {
    const status = elements.filter(v => v.id === id);
    if (status.length > 0) {
      const data = elements.filter(v => v.id !== id);
      setElements([...data, { ...status[0], values }]);
    }
  };

  const onSubmit = step => {
    const submit = {
      ...data,
      step,
      elements
    };
    setLocalStorage('enFormTemplate', submit);
  };
  return (
    <div>
      <div className="d-flex background-bar sticky-v2 right-panel">
        <HeaderArrowBack history={history} />
        <div className="ml-auto my-auto d-flex">
          {listButton.map((v, i) =>
            <DefaultButton {...props}
              disabled={false}
              label={v.label == "Step 2" ? translate('step') + " 2" : translate('step') + " 4"}
              key={`headerButton${i}`}
              onClickBtn={() => {
                { i === 1 && onSubmit(4) }
                history.push(i === 0 ? `/enform/detail/assortment` : `/enform/detail/settings`);
              }}
              classType={v.classType} />
          )}
          <CloseButton history={history} />
        </div>
      </div>
      <ComponentFields
        {...props}
        handleSelected={handleSelected}
        onClickValue={onClickValue}
        elements={elements}
        data={data}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  selectData: state.enform.shelfAuditSelects,
  enform: state.enform
});

export default connect(mapStateToProps, { shelfAuditSelect })(ListElements);
