import { Table } from '../../../../../components_v2/table/Table';
import { ShelfAuditProduct } from '../../../model/models';
import * as React from 'react';
import { TableRow, TableRowTitle } from '../../../../orders/templateOrders/style/Style';
import { Translate } from 'react-localize-redux';
import { Container } from '../../../../client-companies/popup/LinkedElements/style/LinkedElementStyle';
import { translateToString } from '../../../../../styles/global/translate';
import { NoDataText, PartialOpen } from '../../../../client-companies/style/Style';
import {
	Link,
	TABLE_COLUMN_XL_WIDTH,
	TABLE_COLUMN_WIDTH,
	TABLE_IMAGE_PADDING,
	TABLE_IMAGE_WIDTH
} from '../../../../products/style';
import noImage from 'images/no-image.jpg';
import { CircleBoolDiv } from '../../../style/styles';
import { GreenSidely, RedSidely } from '../../../../../styles/global/css/Utils';

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export default function ShelfAuditPresentProducts({ presentProducts, openProduct, isFullOpen }: {
    presentProducts: ShelfAuditProduct[],
    openProduct: (productId: number) => void
    isFullOpen: boolean
}) {
	const columns = [
		{
			id: 'product_image',
			Header:<CircleBoolDiv value={true} style={{ marginLeft:'5px' }}/>,
			accessor: (row: ShelfAuditProduct) => <Link onClick={() => openProduct(row.product_id)} background={row.product_image ?? noImage}/>,
			width: TABLE_IMAGE_WIDTH,
			padding: TABLE_IMAGE_PADDING.DEFAULT,
			noDefaultResizeDiv: true,
		},
		{
			id: 'name',
			Header: <Translate id='name'/>,
			accessor: (row: ShelfAuditProduct) => <TableRowTitle
				onClick={() => openProduct(row.product_id)}>{row.product_name}</TableRowTitle>,
			minWidth:isFullOpen ? TABLE_COLUMN_XL_WIDTH : TABLE_COLUMN_WIDTH,
		},
		{
			id: 'present_last',
			Header: translateToString('shelf_audit.column.present_last'),
			accessor: (row: ShelfAuditProduct) => <TableRowTitle style={{ color:row.present_last ? GreenSidely : RedSidely }}>{translateToString(`shelf_audit.present_last.${row.present_last}`)}</TableRowTitle>,
			minWidth:isFullOpen ? TABLE_COLUMN_XL_WIDTH : TABLE_COLUMN_WIDTH,
		},
		...getAllFields().map(fieldName => {
			const header = translateToString(`shelf_audit.product_fields.${fieldName}`);
			return {
				id: camelToSnakeCase(fieldName),
				Header: header,
				accessor: (row: ShelfAuditProduct) => {
					let currentValue = row.values[fieldName] && row.values[fieldName] [getKey()];
					if (fieldName === 'averagePriceRecorded' && currentValue !== undefined) currentValue += ' €';
					if (typeof currentValue === 'boolean') currentValue = translateToString(`shelf_audit.presence_value.${currentValue}`);

					function getKey() {
						if (fieldName === 'shelfType') {
							const currentLang = localStorage.getItem('language');
							if (currentLang === 'fr') return 'france';
							else return 'english';
						} else return 'value';
					}
					return <TableRow>{currentValue}</TableRow>;
				},
				minWidth: header.length * 8, // in our table logic, cannot put width:100%, so calculate dynamically width, using character count of translated header
			};
		})
	];

	function getAllFields() {
		const res = new Set<string>();
		presentProducts?.forEach(product => {
			if (product.values) 
				Object.keys(product.values)?.forEach(key => {
					//FIXME: remove this when backend will be fixed
					// On SQL Query, I put default 'value' to avoid null value on jsonb_object_agg
					// Find the Query on get_shelf_audit on handler:
					// jsonb_object_agg(COALESCE(saf.name, 'value'), COALESCE(sapfi.value, null)) as attributes
					if (key !== 'value')
						res.add(key);
				});
		});
		return Array.from(res);
	}

	return <Container>
		{presentProducts
			? <Table columns={columns} data={presentProducts} height="100%"/>
			: <NoDataText>{translateToString('shelf_audit.no_data.present_products')}</NoDataText>
		}
	</Container>;
}