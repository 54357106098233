import { Granularity } from 'bindings/time/Granularity';
import { Moment } from 'moment';
import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';
import { TimeRange2 } from '../../components_v2/toolbarFilter/time/TimeRangeFilter2';
import { TimeRange3 } from '../../components_v2/toolbarFilter/time/TimeRangeFilter3';
import { Variation } from '../../components_v2/toolbarFilter/time/VariationSelect';

// ----------[ DatePicker ]---------- //
const AglobalDatePicker: AtomState<[Moment | undefined, Moment | undefined] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_date_picker',
		default: undefined
	})
};

const AorderDatePicker: AtomState<[Moment | undefined, Moment | undefined] | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_date_picker',
		default: undefined
	})
};

const AgalleryDatePicker: AtomState<[Moment | undefined, Moment | undefined] | undefined> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_date_picker',
		default: undefined
	})
};

const AcompanyDatePicker: AtomState<[Moment | undefined, Moment | undefined] | undefined> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_date_picker',
		default: undefined
	})
};

const AcalendarDatePicker: AtomState<[Moment | undefined, Moment | undefined] | undefined> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_date_picker',
		default: undefined
	})
};

const ADatePicker: RecoilState<[Moment | undefined, Moment | undefined] | undefined> = selector({
	key: 'atom_date_picker',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getDatePickerAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getDatePickerAtom(category), newValue);
	}
});

function getDatePickerAtom(category: AtomCategory): RecoilState<[Moment | undefined, Moment | undefined] | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalDatePicker.atom;
		case AtomCategory.ORDER:
			return AorderDatePicker.atom;
		case AtomCategory.GALLERY:
			return AgalleryDatePicker.atom;
		case AtomCategory.COMPANY:
			return AcompanyDatePicker.atom;
		case AtomCategory.CALENDAR:
			return AcalendarDatePicker.atom;
		default:
			return AglobalDatePicker.atom;
	}
}

export {
	AorderDatePicker,
	AcompanyDatePicker,
	AgalleryDatePicker,
	AglobalDatePicker,
	AcalendarDatePicker,
	ADatePicker
};

// ----------[ TimeRangeFilter ]---------- //
const AglobalTimeRangeFilter: AtomState<number | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_time_range_filter',
		default: undefined
	})
};

const AorderTimeRangeFilter: AtomState<number | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_time_range_filter',
		default: undefined
	})
};

const AgalleryTimeRangeFilter: AtomState<number | undefined> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_time_range_filter',
		default: undefined
	})
};

const AcompanyTimeRangeFilter: AtomState<number | undefined> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_time_range_filter',
		default: undefined
	})
};

const AcalendarTimeRangeFilter: AtomState<number | undefined> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_time_range_filter',
		default: undefined
	})
};

const ATimeRangeFilter: RecoilState<number | undefined> = selector({
	key: 'atom_time_range_filter',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getRangeFilterAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getRangeFilterAtom(category), newValue);
	}
});

function getRangeFilterAtom(category: AtomCategory): RecoilState<number | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalTimeRangeFilter.atom;
		case AtomCategory.ORDER:
			return AorderTimeRangeFilter.atom;
		case AtomCategory.GALLERY:
			return AgalleryTimeRangeFilter.atom;
		case AtomCategory.COMPANY:
			return AcompanyTimeRangeFilter.atom;
		case AtomCategory.CALENDAR:
			return AcalendarTimeRangeFilter.atom;
		default:
			return AglobalTimeRangeFilter.atom;
	}
}

export {
	AorderTimeRangeFilter,
	AcompanyTimeRangeFilter,
	AgalleryTimeRangeFilter,
	AglobalTimeRangeFilter,
	ATimeRangeFilter,
	AcalendarTimeRangeFilter
};


// ----------[ GranularitySelect ]---------- //

const AGlobalGranularitySelect: AtomState<Granularity> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_global_granularity_select',
		default: 'month'
	})
};

export const AGranularitySelect: RecoilState<Granularity> = selector({
	key: 'atom_granularity_select',
	get: () => AGlobalGranularitySelect.atom,
	set: ({ set }, newValue) => set(AGlobalGranularitySelect.atom, newValue)
});

// ----------[ Variationelect ]---------- //

export const AVariationSelect = atom<Variation | null>({
	key: 'AVariationSelect',
	default: 'N1',
});

// ----------[ TimeRange2elect ]---------- //

export const ATimeRangeFilter2 = atom<TimeRange2 | null>({
	key: 'ATimeRange2',
	default: null,
});

// ----------[ TimeRange3elect ]---------- //

export const ATimeRangeFilter3 = atom<TimeRange3>({
	key: 'ATimeRange3',
	default: 'thisperiod',
});