import eye from 'images/icons/company/eye.svg';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { AAdditionalColumns, AdditionalColumn, AdditionalFormFields, CompanyAdditionalColumnValue, ReportColumnType, deleteAdditionalColumnValue, getAdditionnalFormFields, getClientCompanyAdditionalColumnsValues, updateAdditionalColumnValue } from '../../../atoms/additionalColumns';
import { AAssortments } from '../../../atoms/assortment';
import Switch from '../../../components_v2/Switch/Switch';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownSearchCompanies } from '../../../components_v2/dropdown/DropdownSearch';
import DropdownTagsCloud from '../../../components_v2/dropdown/DropdownTagsCloud';
import { blurOnEnterPressed } from '../../../components_v2/input/Input';
import { PopupContext } from '../../../components_v2/popup/Popup';
import { DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import { Translate, translateToString } from '../../../styles/global/translate';
import { FieldDisplayer } from '../../forms/FieldDisplayer';
import Restricted from '../../permissions/Restricted';
import { ModalState } from '../../products/model';
import { FlexDiv } from '../../products/style';
import { ValueInnerToNode } from '../../reports/interpretor/ReportInterpretor';
import { DateAccessor } from '../data/CompanyColumns';
import { BodyMainText, BodyMainTextEditable, ButtonAction } from '../style/PopupStyle';
import { CompanyDetailDisplayer, CompanyDetailDisplayerRowWrapper, CompanyDetailDisplayerWrapper, TitleAndChild } from './Detail';
import { SyledCompanyTable } from './KeyResults';
import { getCalculatedFieldsByCompany, ACalculatedFields, CompanyCalculatedField } from '../../../atoms/calculatedFields';
import RestrictedSuperAdmin from '../../permissions/RestrictedSuperAdmin';

import close from 'images/icons/orders/close.svg';
import styled from 'styled-components';


const Close = styled.div`
	background-image: url(${close});
	background-size: 75%;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	&:hover {
		background-color: #f3f3f3;
	}
`;

export default function AdditionalColumns(props: { clientCompanyId: number, isSelf: boolean, openSubCompany: React.Dispatch<React.SetStateAction<ModalState<number>>> }) {
	const additional_columns = useRecoilValue(AAdditionalColumns);
	const [additional_form_fields, setAdditionalFormFields] = React.useState<AdditionalFormFields[]>();
	const [companyAdditionalColumnsValues, setCompanyAdditionalColumnsValues] = React.useState<{[key: number]: CompanyAdditionalColumnValue}>();
	const [companyCalculatedFields, setCompanyCalculatedFields] = React.useState<CompanyCalculatedField[]>();
	const aCalculatedFields = useRecoilValue(ACalculatedFields);

	React.useEffect(() => {
		getAdditionnalFormFields(props.clientCompanyId).then(setAdditionalFormFields);
		getClientCompanyAdditionalColumnsValues(props.clientCompanyId).then(res => {
			setCompanyAdditionalColumnsValues(res.reduce((acc, val) => {
				acc[val.additional_column_id] = val;
				return acc;
			}, {}));
		});
		getCalculatedFieldsByCompany(props.clientCompanyId).then(setCompanyCalculatedFields);
	}, []);
	const other_columns = additional_columns?.filter(column => column.type !== 'ReportColumn');

	const onFieldUpdate = React.useCallback((value: CompanyAdditionalColumnValue) => {
		setCompanyAdditionalColumnsValues(prev => {
			if (!prev) return undefined;
			prev[value.additional_column_id] = value;
			return { ...prev };
		});
	}, []);

	return <SyledCompanyTable><FlexDiv flow="column" width='100%'>
		{other_columns && other_columns.length > 0 &&
			<TitleAndChild title={<Translate id='additional_columns' />}>
				<CompanyDetailDisplayerWrapper>
					{other_columns?.map((ac) => {
						if (!ac.ishidded)
							return <AdditionalColumnDisplayer
								onDelete={console.log}
								onUpdate={onFieldUpdate}
								additional_column={ac}
								key={`AdditionalColumnDisplayer[${ac.id}]`}
								value={companyAdditionalColumnsValues?.[ac.id]}
								openSubCompany={props.openSubCompany}
								isSelf={props.isSelf}
								clientCompanyId={props.clientCompanyId}
							/>;})}
				</CompanyDetailDisplayerWrapper>
			</TitleAndChild>
		}
		{
			companyCalculatedFields && companyCalculatedFields.length > 0 && 
			<TitleAndChild title={<Translate id='calculated_fields' />}>
				<CompanyDetailDisplayerWrapper>
					{companyCalculatedFields.map(cf => {
						const cfName = aCalculatedFields.find(c => c.id === cf[0])?.field_name;
						if (!cfName) return <></>;
						return <CompanyDetailDisplayer title={cfName} key={`calculated_field_columns[${cf[0]}]`}>
							{typeof cf[1] === 'object' && cf[1] ? <ValueInnerToNode value={cf[1] as ReportColumnType} /> : '-'}
						</CompanyDetailDisplayer>;
					})}
				</CompanyDetailDisplayerWrapper>
			</TitleAndChild>
		}
		{additional_form_fields && additional_form_fields.length > 0 && <TitleAndChild title={<Translate id='form_fields' />}>
			<CompanyDetailDisplayerWrapper>
				{additional_form_fields.map(et => <AdditionnalFormFieldDisplayer field={et} key={`additional_form_fields[${et.field_id}][${et.name}]`} />)}
			</CompanyDetailDisplayerWrapper>
		</TitleAndChild>
		}

	</FlexDiv>
	</SyledCompanyTable>;
}

function isNotNull(additional_column: AdditionalColumn[] | undefined, companyAdditionalColumnsValues: { [key: number]: CompanyAdditionalColumnValue } | undefined) {
	return additional_column?.some(ac => {
		const val = companyAdditionalColumnsValues?.[ac.id];
		if (val?.value && val?.value != 'empty') {
			return true;
		}
	});
}

const ADDITIONAL_COLUMNS_DROPDOWN_PROPS = {
	placeholder: '-',
	dropdownStyle: {
		inputMargin: '0',
		inputPadding: '0',
		containerHeight: 'unset',
		optionWidth: '100%',
		containerWidth: '100%',
		width: '100%',
		containerBorder: 'none',
		margin: '0',
	}
};

function AdditionalColumnDisplayer(props: {
	clientCompanyId: number,
	additional_column: AdditionalColumn,
	value: CompanyAdditionalColumnValue | undefined,
	openSubCompany: React.Dispatch<React.SetStateAction<ModalState<number>>>,
	isSelf: boolean,
	onUpdate: (value: CompanyAdditionalColumnValue | undefined) => void,
	onDelete?: () => void
}) {
	const { isChild } = React.useContext(PopupContext);
	const { value, openSubCompany, additional_column, clientCompanyId } = props;
	const [error, setError] = React.useState(false);
	let child: React.ReactNode = <></>;
	let val = value?.value;
	const assortments = useRecoilValue(AAssortments);
	const deleteValue = () => deleteAdditionalColumnValue(additional_column.id, clientCompanyId).then(() => props.onUpdate({ additional_column_id: additional_column.id, value: undefined }));
	const updateValue = (value) => {
		if (value === undefined) {
			return deleteValue();
		}
		return updateAdditionalColumnValue(additional_column.id, clientCompanyId, value).then(() => props.onUpdate({ additional_column_id: additional_column.id, value }));
	};

	switch (additional_column.type) {
		case 'Integer':
		case 'Number':
			if (typeof value?.value === 'number') {
				val = value.value.toString();
			} else if (typeof value?.value === 'string') {
				val = parseFloat(value.value).toString();
			}
		// eslint-disable-next-line no-fallthrough
		case 'String': {
			if (typeof val === 'string' || val === undefined || val === null) {
				child = <Restricted
					to={{ objectAction: 'UpdateCompany' }}
					isSelf={props.isSelf}
					fallback={<BodyMainText height='fit-content' marginLeft='0' minHeight='unset' display='block'>{val}</BodyMainText>}
				>
					<FlexDiv>
						<BodyMainTextEditable
							height='fit-content'
							marginLeft='0'
							minHeight='unset'
							width='100%'
							suppressContentEditableWarning
							contentEditable
							onKeyDownCapture={blurOnEnterPressed}
							error={error}
							onInput={e => {
								let parsed;
								switch (additional_column.type) {
									case 'Integer':
										parsed = parseInt(e.currentTarget.innerText.trim());
										break;
									case 'Number': {
										parsed = parseFloat(e.currentTarget.innerText.trim().replace(',', '.'));
										break;
									}
									default:
										return;
								}
								if (isNaN(parsed)) {
									setError(true);
								} else {
									setError(false);
								}
							}}
							onBlur={(e) => {
								let parsed;
								if (e.currentTarget.innerText.trim() === '') {
									deleteValue();
									return;
								}
								switch (additional_column.type) {
									case 'Integer':
										parsed = parseInt(e.currentTarget.innerText.trim());
										break;
									case 'Number': {
										parsed = parseFloat(e.currentTarget.innerText.trim().replace(',', '.'));
										break;
									}
									default:
										parsed = e.currentTarget.innerText.trim();
										break;
								}
								updateValue(parsed);
							}}
						>
							{val}
						</BodyMainTextEditable>
						{val != undefined && <Close onClick={deleteValue}/>}
					</FlexDiv>
				</Restricted>;
			} else {
				child = '-';
			}
			break;
		}
		case 'Select':
			child = <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				fallback={value?.enriched_value ?? '-'}
				isSelf={props.isSelf}
			>
				<FlexDiv>
					<Dropdown
						key={`DESTROYKEY[${additional_column.id}][${value?.value}]`}
						name=''
						{...ADDITIONAL_COLUMNS_DROPDOWN_PROPS}
						datalist={additional_column.data.map(s => ({ label: s, value: s }))}
						selectedValue={additional_column.data.map(s => ({ label: s, value: s })).find(s => s.value === value?.value)}
						onChange={value => updateValue(value?.value)}
					/>
					{val != undefined && <Close onClick={deleteValue}/>}
				</FlexDiv>
			</Restricted>;
			break;
		case 'Boolean':
			child = <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				isSelf={props.isSelf}
				fallback={value?.value === 'boolean' && value.value ? translateToString('true') : value?.value === 'boolean' ? translateToString('false') : '-'}
			>
				<FlexDiv>
					<Switch onChange={updateValue} value={typeof value?.value === 'boolean' ? value.value : false} />
					{val != undefined && <Close onClick={deleteValue}/>}
				</FlexDiv>
			</Restricted>;
			break;
		case 'Date':
			child = typeof value?.value === 'string' ? <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				isSelf={props.isSelf}
				fallback={<DateAccessor date={value.value} />}
			>
				<FlexDiv>
					<DateAccessor date={value.value} />
					{val != undefined && <Close onClick={deleteValue}/>}
				</FlexDiv>
			</Restricted> : '-';
			break;
		case 'Catalogue':
			child = <Restricted
				to={{ objectAction: 'UpdateCompany' }}
				isSelf={props.isSelf}
				fallback={value?.enriched_value ?? '-'}
			>
				<FlexDiv>
					<Dropdown
						key={`DESTROYKEY[${additional_column.id}][${value?.value}]`}
						name=''
						{...ADDITIONAL_COLUMNS_DROPDOWN_PROPS}
						datalist={assortments.map(s => ({ label: s.name, value: s }))}
						selectedValue={assortments.map(s => ({ label: s.name, value: s })).find(s => s.value.id === value?.value)}
						onChange={value => updateValue(value?.value?.id)}
					/>
					{val != undefined && <Close onClick={deleteValue}/>}
				</FlexDiv>
			</Restricted>;
			break;
		case 'ReportColumn':
			child = typeof value?.value === 'object' && value?.value ? <ValueInnerToNode value={value.value as ReportColumnType} /> : '-';
			break;
		case 'Company': {
			const companyId = value?.value !== undefined && typeof value.value === 'number' ? value.value : undefined;

			child = <FlexDiv><BodyMainText noOverflow marginLeft='0' minHeight='unset' height='20px'>
				<DropdownSearchCompanies
					dropdownStyle={{ height: '20px' }}
					JSXButton={(handleChange) => <BodyMainTextEditable
						noOverflow height='fit-content' marginLeft='0' minHeight='unset'
						width='100%'
						suppressContentEditableWarning
						contentEditable
						onKeyDownCapture={blurOnEnterPressed}
						onInput={(e) => handleChange(e.currentTarget.innerText.trim())}
						display='block'
					>
						{value?.enriched_value ?? '-'}
					</BodyMainTextEditable>}
					selectedValue={companyId ? { label: value?.enriched_value ?? '-', value: { id: companyId } as never } : undefined}
					name={''}
					onChange={value => {
						updateValue(value?.value?.id)
							.then(() => {
								props.value && props.onUpdate({ ...props.value, value: value?.value?.id, enriched_value: value?.label as string });
							});
					}}
				/>
			</BodyMainText>
			{companyId && <ButtonAction width='20px' parent={CompanyDetailDisplayerRowWrapper} src={eye} onClick={() => isChild ? window.open(`companies?id=${companyId}`) : openSubCompany({ isOpen: true, data: companyId })} />}
			{val != undefined && <Close onClick={deleteValue}/>}
			</FlexDiv>;
			break;
		}
		case 'MultiSelect': {
			let selected: string[] = [];
			try {
				if (Array.isArray(value?.value)) {
					selected = value.value;
				} else if (typeof value?.value === 'string') {
					selected = JSON.parse(value?.value ?? '[]');
				}
			} catch (e) {
				selected = [];
			}

			child = <DropdownTagsCloud
				addImageSize='1.25em'
				NoneContainer={<DefaultTextDiv>-</DefaultTextDiv>}
				autoOptionUp
				horizontal
				noSearch
				addImageOnHover
				dropdownSearch
				selected={selected.map(s => ({ name: s, id: s }))}
				onChange={values => updateValue(values.map(t => t.name))}
				onDelete={deletedValue => {
					if (!value) return;
					const newValues = (value.value as string[])?.filter(s => s !== deletedValue);
					if (newValues.length === 0) {
						deleteValue();
						return;
					}
					updateValue(newValues);
				}}
				tags={additional_column.data.map(s => ({ name: s, id: s }))}
			/>;
			break;
		}

	}
	return <CompanyDetailDisplayer title={additional_column.name}>
		{child}
	</CompanyDetailDisplayer>;
}

export function AdditionnalFormFieldDisplayer(props: { field: AdditionalFormFields }) {
	return <CompanyDetailDisplayer title={props.field.name}>
		<FieldDisplayer field_type={props.field.field_type} value={props.field.value} fieldId={props.field.field_id}/>
	</CompanyDetailDisplayer>;
}
