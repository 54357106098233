/* eslint-disable camelcase */
const sectionConverter = (data, field_type, is_start, is_end) => {
	const result = [];
	let subObj = {};
	let inSubSection = false;

	// Loop data
	for (let i = 0; i < data.length; i += 1) {
		if (data[i].field_type === field_type && data[i][is_end]) {
			//   console.log('endTable', data[i], subObj);
			inSubSection = false;
			result.push(subObj);
		} else if (data[i].field_type === field_type && data[i][is_start]) {
			inSubSection = true;
			subObj = {
				...data[i]
			};
			//   console.log('startTable', subObj, inSubSection);
		} else if (inSubSection) {
			subObj.details.fields.push({
				...data[i]
			});
			//   console.log('insubsection', data[i], subObj);
		} else {
			result.push(data[i]);
			//   console.log('else', data[i]);
		}
	}
	return result;
};

export { sectionConverter };
