/* eslint-disable react/prefer-stateless-function */
import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import {connect} from 'react-redux';
import ModalMapCompany from '../../../companies/components/companiesTables/ModalMapCompany';
import * as action from '../../../../store/actions/mapOpportunities.action';

class MapView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			center: {
				lat: 48.8566,
				lng: 2.3522
			},
			zoom: 1,
			companyByCountry: [],
			modalColor: '#00B2FF',
			mapModal: {
				title: '',
				content: '',
				company: '',
				companyId: 0,
				lat: 0,
				lng: 0
			},
			filters: {
				deadline: 'All',
				incharge: '',
				group: ''
			}
		};
	}

	componentDidMount() {
		const { currentUser, filters, tagsId } = this.props;
		if (currentUser.role.slug == 'manager') {
			if (currentUser.group.length > 1) {
				this.props.getCompaniesInEachCountry(
					filters.incharge,
					currentUser.group[1].id,
					filters.deadline,
					tagsId
				);
			} else {
				this.props.getCompaniesInEachCountry(
					filters.incharge,
					filters.group,
					filters.deadline,
					tagsId
				);
			}
		} else if (currentUser.role.slug == 'sales') {
			this.props.getCompaniesInEachCountry(
				currentUser.id,
				filters.group,
				filters.deadline,
				tagsId
			);
		} else {
			this.props.getCompaniesInEachCountry('', '', '', tagsId);
		}
	}

	componentWillUpdate(nextProps) {
		// this.setCompanyByCountry(nextProps);
		this.setFilterDeadline(nextProps);
		this.setFilterOwner(nextProps);
		this.setFilterGroup(nextProps);
	}

	setFilterDeadline(nextProps) {
		if (this.state.filters.deadline !== nextProps.filters.deadline) {
			this.setState({
				filters: {
					...this.state.filters,
					deadline: nextProps.filters.deadline
				}
			});

			this.props.getCompaniesInEachCountry(
				nextProps.filters.incharge,
				nextProps.filters.group,
				nextProps.filters.deadline
			);
		}
	}

	setFilterOwner(nextProps) {
		if (this.state.filters.incharge !== nextProps.filters.incharge) {
			this.setState({
				filters: {
					...this.state.filters,
					incharge: nextProps.filters.incharge
				}
			});
			this.props.getCompaniesInEachCountry(
				nextProps.filters.incharge,
				nextProps.filters.group,
				nextProps.filters.deadline
			);
		}
	}

	setFilterGroup(nextProps) {
		if (this.state.filters.group !== nextProps.filters.group) {
			this.setState({
				filters: {
					...this.state.filters,
					group: nextProps.filters.group
				}
			});

			this.props.getCompaniesInEachCountry(
				nextProps.filters.incharge,
				nextProps.filters.group,
				nextProps.filters.deadline
			);
		}
	}

	onOpenModal = () => {
		this.setState({
			isModalOpen: true
		});
	};

	toggle = () => {
		this.setState({
			isModalOpen: !this.state.isModalOpen
		});
	};

	getColorMarker(status) {
		let color;

		if (status == 'suspect') {
			color = '#00B2FF';
		} else if (status == 'lead') {
			color = '#FF9345';
		} else if (status == 'prospect') {
			color = '#FFEA2C';
		} else if (status == 'client') {
			color = '#2ED47A';
		} else if (status == 'inactive') {
			color = '#AEAEAE';
		} else {
			color = '#AEAEAE';
		}

		return color;
	}

	onMapMarkerCompanyClicked = (company, color) => {
		this.state.center = {
			lat: parseFloat(company.latitude),
			lng: parseFloat(company.longitude)
		};
		this.forceUpdate();
		this.state.modalColor = color;
		this.state.mapModal.title = company.clientStatus.name;
		this.state.mapModal.content = `${company.billingAddress} ${
			company.billingCity
		} ${company.billingPostCode} ${company.billingCountry}`;
		this.state.mapModal.company = company.name;
		this.state.mapModal.lat = parseFloat(company.latitude);
		this.state.mapModal.lng = parseFloat(company.longitude);
		this.state.mapModal.companyId = company.id;
		this.state.isModalOpen = true;
		if (this.state.zoom < 14) {
			this.state.zoom = 14;
		}
		this.forceUpdate();
	};

	onMapMarkerCountryClicked = company => {
		this.state.center = company.latLng;
		this.forceUpdate();
		this.state.zoom = 6;
		this.forceUpdate();
	};

	onMapZoomChange = value => {
		this.state.zoom = value.zoom;
		this.state.center = value.center;
		this.forceUpdate();
	};

	onCompanyNameClicked = () => {
		window.location.href = `/companies?id=${this.state.mapModal.companyId}`;
	};

	render() {
		const { isModalOpen } = this.state;
		return (
            // Important! Always set the container height explicitly
            (<div style={{ height: 'calc(100vh - 52px)', width: '100%' }}>
                <GoogleMapReact
					onGoogleApiLoaded={maps => {
						// console.log('map loaded', maps);
					}}
					bootstrapURLKeys={{
						key: 'AIzaSyBkQkAoGTXdwr-MrDWQYQPt0zvAw57T7p0'
					}}
					zoom={this.state.zoom}
					center={this.state.center}
					onChange={this.onMapZoomChange}
				>
					{this.renderCompanyMap()}
				</GoogleMapReact>
                <ModalMapCompany
					color={this.state.modalColor}
					onCompanyNameClicked={this.onCompanyNameClicked}
					title={this.state.mapModal.title}
					content={this.state.mapModal.content}
					company={this.state.mapModal.company}
					isOpen={isModalOpen}
					toggle={this.toggle}
				/>
            </div>)
        );
	}

	renderCompanyMap() {
		if (this.props.companies.length > 0) {
			return this.props.companies.map((company, index) => {
				const color =
          company.clientStatus &&
          this.getColorMarker(company.clientStatus.slug);
				const style = {
					transform: 'translate(-18px, -36px)',
					height: '36px',
					width: '100%',
					diplay: 'block',
					color
				};

				if (company.latitude != null && company.longitude != null) {
					return (
						<div
							key={index}
							style={style}
							className="map-pin cursor-pointer text-center"
							lat={parseFloat(company.latitude)}
							lng={parseFloat(company.longitude)}
							onClick={() => {
								this.onMapMarkerCompanyClicked(company, color);
							}}
						>
							<a className="btn-link bg-transparent">
								<i
									className="fa fa-map-marker-alt w-100"
									style={{ fontSize: '36px' }}
								/>
							</a>
						</div>
					);
				}
				return <div />;
			});
		}
	}

	renderCountryMap() {
		if (this.props.companiesInEachCountry.length > 0) {
			return this.props.companiesInEachCountry.map((company, index) => {
				if (company.latLng != undefined) {
					const style = {
						transform: 'translate(-18px, -36px)',
						height: '36px',
						width: '100%',
						diplay: 'block'
					};
					const textStyle = {
						color: '#fff',
						position: 'absolute',
						display: 'block',
						width: '27px',
						fontSize: '11px',
						margin: '5px 0'
					};

					return (
						<div
							key={index}
							style={style}
							className="map-pin cursor-pointer text-center"
							lat={company.latLng.lat}
							lng={company.latLng.lng}
							onClick={() => {
								this.onMapMarkerCountryClicked(company);
							}}
						>
							<a className="btn-link bg-transparent">
								<span style={textStyle}>{company.companies.length}</span>
								<i
									className="fa fa-map-marker w-100"
									style={{ fontSize: '36px', color: '#254153' }}
								/>
							</a>
						</div>
					);
				}
			});
		}
	}
}

const mapStateToProps = state => ({
	companies: state.mapOpportunities.companies,
	companiesInEachCountry: state.mapOpportunities.companiesWithOpenOpportunities,
	filters: state.mapOpportunities.filters,
	currentUser: state.user.current
});

const mapDispatchToProps = {
	getCompaniesInEachCountry: action.fetchCompanies,
	setFilterGroup: action.setFilterMapOpportunityGroup
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MapView);
