import * as React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import { SetSelectedReport } from './Reports';
import PresencePerProductPerCompanyFilters from './presencePerProductPerCompany/PresencePerProductPerCompanyFilters';
import EventsPerCompanyFilters from './eventsPerCompany/EventsPerCompanyFilters';
import EventsPerUserFilters from './eventsPerUser/EventsPerUserFilters';
import MerchandisingPerCompanyFilters from './merchandisingPerCompany/MerchandisingPerCompanyFilters';
import CompanyStatusEvolutionFilters from './companyStatusEvolution/CompanyStatusEvolutionFilters';
import { reportingAction, reportingState } from './Reducer';
import PresencePerUsersFilters from './presencePerUsers/PresencePerUsersFilters';
import PresencePerProductsFilters from './presencePerProducts/PresencePerProductsFilters';
import OrdersEvolutionFilters from './ordersEvolution/OrdersEvolutionFilters';
import OrderPerProductPerCompanyFilters from './orderPerProductPerCompany/OrderPerProductPerCompanyFilters';
import { getFieldReportInstallers } from '../../components_v2/sidebar/actions';
import PresencePerBrandOrCategoryPerCompanyFilters from './presencePerBrandOrCategoryPerCompany/presencePerBrandOrCategoryPerCompanyFilters';
import CategorySelector, { Category } from '../../components_v2/Selector/CategorySelector';
import { getLeftOrRightImage } from '../map/modalLeft/ModalLeftFilter';
import PermissionContext from '../permissions/PermissionContext';
import { AlertContext } from '../../containers_v2/alert/AlertProvider';
import { NewReport } from './generic/generic';
import Add from '../../components_v2/add/Add';
import { FlexDiv } from '../products/style';
import styled from 'styled-components';
import { translateToString } from '../../styles/global/translate';
import { isSuperAdmin } from '../../components_v2/utils';

const StyledSpan = styled.span`
	font-size: 12px;
	font-weight: 300;
`;

export function ModalLeft(props: SetSelectedReport) {
	const [reportsfields, setReportsFields] = React.useState<string[]>([]);
	const { isAllowedTo } = React.useContext(PermissionContext);
	const isFormV2 = isAllowedTo({ objectAction: 'ViewForm' });
	const { alert } = React.useContext(AlertContext);
	const isAdmin = isSuperAdmin();
	
	const reportKindList: Category[] = React.useMemo(() => {
		return [
			{
				name: <Translate id='shelf_audit.sections.shared_reports' />,
				restricted: { objectAction: 'ReadForm' },
				hidden: !isFormV2,
				children: [...[...props.reports].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
					.map(r => { 
						if (isAdmin || !r.name.toLowerCase().startsWith('superadmin')) 
							return ({
								beta: false,
								admin: false,
								name: r.name,
								active: r.id.toString() === props.state.selectedReport,
								onClick: () => props.dispatch({ type: 'SET_SELECTED_REPORT', value: r.id.toString(), data: { ...r } }),
								report_id: r.id
							});
						return ({});
					}), { 
					name: <FlexDiv gap='6px'><Add size='20px'/><StyledSpan><Translate id='reports.create_report'></Translate></StyledSpan></FlexDiv>,
					owner: true,
					create_report: true,
					onClick: () => {
						alert({
							title: translateToString('reports.name_report'),
							content: (onBreak, onOk) => <NewReport onBreak={onBreak} onOk={onOk} />,
							noButtons: true,
						});}
				}
				]
			}];
	}, [props.reports, reportsfields]);

	React.useEffect(() => {
		getFieldReportInstallers().then(res => {
			setReportsFields(res.data.data.map(rep => rep.name));
		});
	}, []);

	return (
		<div className="filter-modal" style={{ marginTop: '52px', height: 'calc(100% - 107px)', left: '0' }}>
			<div className={props.isModalOpen ? 'box-filter-modal' : 'd-none'} style={{ width: '260px' }}>
				<CategorySelector categories={reportKindList} hasSettings title={<Translate id="reports.categories.report_categories" />} />
			</div>
			<button
				type="button"
				className="btn btn-filter-modal"
				onClick={() => props.setIsModalOpen(!props.isModalOpen)}
			>
				<img
					src={getLeftOrRightImage(props.isModalOpen)}
					alt=""
				/>
			</button>
		</div>
	);
}
