import * as React from 'react';
import NewFreeForms from '../../containers_v2/client-companies/popup/NewFreeForms';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';

function FreeFormCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  clientCompanyId: number
  clientCompanyName: string
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, top: '0%' }}
			popupMode={PopupMode.Details}
			content={<NewFreeForms
				onClickOut={() => close()}
				clientCompanyId={props.clientCompanyId}
				clientCompanyName={props.clientCompanyName}
			/>}
		/>
	);
}

export default FreeFormCreation;
