import axios from 'axios';
import { Cookies } from 'react-cookie';
import { atom, selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';
import { AtomCategory, AtomState } from './utils/model/Model';

export type AssortmentShort = {
	id: number,
	name: string
}

// -----------------------[ ATOM ]----------------------- //
export const AGlobalAssortments: AtomState<AssortmentShort[]> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_assortments', // UNIQUE ID
		default: getAssortments()
	})
};

export const AAssortments = selector({
	key: 'AAssortments',
	get: async({ get }) => get(AGlobalAssortments.atom),
	set: ({ set }, newValue) => set(AGlobalAssortments.atom, newValue),
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getAssortments(): Promise<AssortmentShort[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/assortments/all`).then(res => res.data);
}