import { getTranslate } from 'react-localize-redux';
import storeLang from '../storeLang';

export function product_highlight(translate) {
	return [{
		value: 1,
		label: translate('shelf_audit.product_highlight.aisleEndDisplay')
	},
	{
		value: 2,
		label: translate('shelf_audit.product_highlight.island')
	},
	{
		value: 3,
		label: translate('shelf_audit.product_highlight.inAisleDisplay')
	},
	{
		value: 4,
		label: translate('shelf_audit.product_highlight.displayRack')
	},
	{
		value: 5,
		label: translate('shelf_audit.product_highlight.noHighlight')
	},
	{
		value: 6,
		label: translate('shelf_audit.product_highlight.counterDisplayUnit')
	},
	{
		value: 7,
		label: translate('shelf_audit.product_highlight.aisleEndDisplaySides')
	},
	{
		value: 8,
		label: translate('shelf_audit.product_highlight.box')
	},
	{
		value: 9,
		label: translate('shelf_audit.product_highlight.shelfPOS')
	},
	{
		value: 10,
		label: translate('shelf_audit.product_highlight.boxAndShelfPOS')
	},
	{
		value: 11,
		label: translate('shelf_audit.product_highlight.shelfStopper')
	},
	{
		value: 12,
		label: translate('shelf_audit.product_highlight.neckHanger')
	},
	{
		value: 13,
		label: translate('shelf_audit.product_highlight.labelHolder')
	},
	{
		value: 14,
		label: translate('shelf_audit.product_highlight.header')
	}
	];
}

export function shelf_type(translate) {
	return [{
		value: 1,
		label: translate('shelf_audit.shelf_type.softBeverages')
	},
	{
		value: 2,
		label: translate('shelf_audit.shelf_type.centralAisle')
	},
	{
		value: 3,
		label: translate('shelf_audit.shelf_type.fruitsAndVegetables')
	},
	{
		value: 4,
		label: translate('shelf_audit.shelf_type.bakery')
	},
	{
		value: 5,
		label: translate('shelf_audit.shelf_type.deli')
	},
	{
		value: 6,
		label: translate('shelf_audit.shelf_type.meat')
	},
	{
		value: 7,
		label: translate('shelf_audit.shelf_type.dairy')
	},
	{
		value: 8,
		label: translate('shelf_audit.shelf_type.dryGoods')
	},
	{
		value: 9,
		label: translate('shelf_audit.shelf_type.cannedGoods')
	},
	{
		value: 10,
		label: translate('shelf_audit.shelf_type.healthAndBeauty')
	},
	{
		value: 11,
		label: translate('shelf_audit.shelf_type.paperProducts')
	}, {
		value: 12,
		label: translate('shelf_audit.shelf_type.cleaningSupplies')
	},
	{
		value: 13,
		label: translate('shelf_audit.shelf_type.home')
	},
	{
		value: 14,
		label: translate('shelf_audit.shelf_type.officeSupplies')
	},
	{
		value: 15,
		label: translate('shelf_audit.shelf_type.registers')
	},
	{
		value: 16,
		label: translate('shelf_audit.shelf_type.magazines')
	},
	{
		value: 17,
		label: translate('shelf_audit.shelf_type.fishAndSeafood')
	},
	{
		value: 18,
		label: translate('shelf_audit.shelf_type.beerWineSpirits')
	},
	{
		value: 19,
		label: translate('shelf_audit.shelf_type.bazaar')
	},
	{
		value: 20,
		label: translate('shelf_audit.shelf_type.bio')
	},
	{
		value: 21,
		label: translate('shelf_audit.shelf_type.drugstore')
	},
	{
		value: 22,
		label: translate('shelf_audit.shelf_type.saltedGroceries')
	},
	{
		value: 23,
		label: translate('shelf_audit.shelf_type.sweetGroceries')
	},
	{
		value: 24,
		label: translate('shelf_audit.shelf_type.refrigerated')
	},
	{
		value: 25,
		label: translate('shelf_audit.shelf_type.frozen')
	},
	{
		value: 26,
		label: translate('shelf_audit.shelf_type.clothes')
	},
	{
		value: 27,
		label: translate('shelf_audit.shelf_type.cereals')
	},
	{
		value: 28,
		label: translate('shelf_audit.shelf_type.jams')
	},
	{
		value: 29,
		label: translate('shelf_audit.shelf_type.biscuits')
	},
	{
		value: 30,
		label: translate('shelf_audit.shelf_type.appetizers')
	},
	{
		value: 31,
		label: translate('shelf_audit.shelf_type.dieteticGlutenFree')
	},
	{
		value: 32,
		label: translate('shelf_audit.shelf_type.petFood')
	},
	{
		value: 33,
		label: translate('shelf_audit.shelf_type.others')
	},
	{
		value: 34,
		label: translate('shelf_audit.shelf_type.worldFood')
	},
	{
		value: 35,
		label: translate('shelf_audit.shelf_type.nutrition')
	},
	{
		value: 36,
		label: translate('shelf_audit.shelf_type.nutritionAndCounter')
	},
	{
		value: 37,
		label: translate('shelf_audit.shelf_type.checkoutLine')
	},
	{
		value: 38,
		label: translate('shelf_audit.shelf_type.wine')
	},
	{
		value: 39,
		label: translate('shelf_audit.shelf_type.organicWine')
	}
	];
}

export function shelf_sector(translate) {
	return [{
		value: 1,
		label: translate('shelf_audit.shelf_sector.dairy')
	},
	{
		value: 2,
		label: translate('shelf_audit.shelf_sector.deli')
	},
	{
		value: 3,
		label: translate('shelf_audit.shelf_sector.beverage')
	},
	{
		value: 4,
		label: translate('shelf_audit.shelf_sector.nonFood')
	},
	{
		value: 5,
		label: translate('shelf_audit.shelf_sector.drugStore')
	},
	{
		value: 6,
		label: translate('shelf_audit.shelf_sector.hpc')
	},
	{
		value: 7,
		label: translate('shelf_audit.shelf_sector.registers')
	},
	{
		value: 8,
		label: translate('shelf_audit.shelf_sector.discountAisle')
	},
	{
		value: 9,
		label: translate('shelf_audit.shelf_sector.organic')
	},
	{
		value: 10,
		label: translate('shelf_audit.shelf_sector.athleteHealth')
	}, {
		value: 11,
		label: translate('shelf_audit.shelf_sector.fitness')
	}, {
		value: 12,
		label: translate('shelf_audit.shelf_sector.running')
	}, {
		value: 13,
		label: translate('shelf_audit.shelf_sector.cycling')
	}, {
		value: 14,
		label: translate('shelf_audit.shelf_sector.other')
	}, {
		value: 15,
		label: translate('shelf_audit.shelf_sector.roomTemperature')
	}, {
		value: 16,
		label: translate('shelf_audit.shelf_sector.refrigerated')
	}
	];
}

export function shelf_zone(translate) {
	return [{
		value: 1,
		label: translate('shelf_audit.shelf_zone.topLeft')
	},
	{
		value: 2,
		label: translate('shelf_audit.shelf_zone.middleLeft')
	},
	{
		value: 3,
		label: translate('shelf_audit.shelf_zone.bottomLeft')
	},
	{
		value: 4,
		label: translate('shelf_audit.shelf_zone.topCenter')
	},
	{
		value: 5,
		label: translate('shelf_audit.shelf_zone.middleCenter')
	},
	{
		value: 6,
		label: translate('shelf_audit.shelf_zone.bottomCenter')
	},
	{
		value: 7,
		label: translate('shelf_audit.shelf_zone.topRight')
	},
	{
		value: 8,
		label: translate('shelf_audit.shelf_zone.middleRight')
	},
	{
		value: 9,
		label: translate('shelf_audit.shelf_zone.bottomRight')
	}
	];
}

export function typeOfDiscount(translate) {
	return [{
		value: 1,
		label: translate('shelf_audit.discount_type.catalogDiscount')
	},
	{
		value: 2,
		label: translate('shelf_audit.discount_type.pirateDiscount')
	}
	];
}

export function discountLevel(translate) {
	return [
		{
			value: 1,
			label: translate('shelf_audit.discount_level.national')
		},
		{
			value: 2,
			label: translate('shelf_audit.discount_level.regional')
		},
		{
			value: 3,
			label: translate('shelf_audit.discount_level.local')
		},
		{
			value: 4,
			label: translate('shelf_audit.discount_level.chainStore')
		}
	];
}

function sortLabel(a, b) {
	if (a.label < b.label) {
		return -1;
	}
	if (a.label > b.label) {
		return 1;
	}
	return 0;
}

export const getOption = key => {
	const translate = getTranslate(storeLang.getState().localize);
	switch (key) {
		case 'product_highlight':
			return product_highlight(translate).sort(sortLabel);
		case 'shelf_type':
			return shelf_type(translate).sort(sortLabel);
		case 'shelf_sector':
			return shelf_sector(translate).sort(sortLabel);
		case 'shelf_zone':
			return shelf_zone(translate).sort(sortLabel);
		case 'discount_type':
			return typeOfDiscount(translate).sort(sortLabel);
		case 'discount_level':
			return discountLevel(translate).sort(sortLabel);
		default:
			return [];
	}
};
