import * as React from 'react';
import { Collapse, TabPane } from 'reactstrap';
import { getTranslate, Translate } from 'react-localize-redux';
import Select from 'react-select';
import CheckboxGroupFilter from '../../../containers/companies/components/CheckboxGroupFilter';
import EventsFilter from './EventsFilter';
import storeLang from '../../../helpers/storeLang';
import SliderFilterBoxChecker from './slider/SliderFilterBoxChecker';
import { ButtonToggle } from '../../../styles/global/css/Open';
import { BlueSidely, BorderColor, SidelyBlack } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';
import { MapCheckbox, MapTextFilters, MapTextFilters2 } from '../modalRight/style';
import Input from '../../../components_v2/input/Input';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AOrderStatuses } from '../../../atoms/global/orders';
import Slider from '@mui/material/Slider';
import { ColumnPicker, GranularityPicker } from '../../client-companies/data/CompanyColumns';
import { AEventTypes } from '../../../atoms/global/events';
import { AFrequencyEventType, getColorByFrequencyScore, getEmojiByFrequencyScore } from '../../../atoms/global/frequency';
import Restricted from '../../permissions/Restricted';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import styled from 'styled-components';
import { CompanyStatus } from '../../client-companies/model/Model';
import { getColorForPercentage } from '../../reports/utils';
import { FetchKey } from '../MapView';
import { AAdditionalColumns, isAdditionalColumnTypeVisibleOnMap } from '../../../atoms/additionalColumns';
import { AAdditionalFieldColumns } from '../../../atoms/additionalFieldColumns';
import { ACalculatedFields } from '../../../atoms/calculatedFields';
import { translateToString } from '../../../styles/global/translate';
import RestrictedSuperAdmin from '../../permissions/RestrictedSuperAdmin';

const optionEvent: { label: string, key: FetchKey }[] = [
	{
		label: 'Last completed event',
		key: 'last'
	},
	{
		label: 'Next planned event',
		key: 'plan'
	}
];

const selectStyles = {
	placeholder: (provided) => {
		return {
			...provided,
			color: '#cdcdcd',
			fontSize: '12px',
			fontWeight: '300'
		};
	},
	option: (provided) => {
		return {
			...provided,
			// color: '#cdcdcd',
			fontSize: '12px',
			fontWeight: '300'
		};
	},
	multiValue: (provided) => {
		return {
			...provided,
			fontSize: '12px',
			fontWeight: '300',
			backgroundColor: 'white',
			border: '1px solid',
			borderColor: 'black',
			borderRadius: '5px'
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: 'transparant',
			color: data.color,
			padding: '2px 4px 4px 4px'
		}
	}),
	control: (styles, state) => ({
		...styles,
		borderColor: state.isFocused ? BlueSidely : '#f2f2f2',
		borderRadius: '5px',
		boxShadow: state.isFocused ? 'none' : '',
		'&:hover': {
			borderColor: BlueSidely
		},
		minHeight: '38px',
		backgroundColor: 'white'
	})
};

export default function TabFilter(props: {
  all_cities: DropdownData<string | undefined>[]
  all_postcodes: DropdownData<string | undefined>[]
  all_countries: DropdownData<string | undefined>[]
  changeFilters: (key: string, value) => void,
  status: CompanyStatus[]
  updatePieFilter: (key: string, value) => void,
  pieFilter: { [key: string]: { index: number, isActive: boolean, color: string, value: [number, number] }},
  fetchFor: (key: FetchKey) => void
}) {
	const [activeCollapse, setActiveCollapse] = React.useState<string>();
	const [selectedCities, setSelectedCities] = React.useState<DropdownData<string | undefined>[]>([]);
	const [selectedCountries, setSelectedCountries] = React.useState<DropdownData<string | undefined>[]>([]);
	const [selectedPostcodes, setSelectedPoscodes] = React.useState<DropdownData<string | undefined>[]>([]);
	const [hideShelfAudit, setHideCompanyShelfAudit] = React.useState<boolean>(false);
	const [hideShelfAuditDate, setHideCompanyShelfAuditDate] = React.useState<boolean>(false);
	const [hideOrders, setHideOrders] = React.useState<boolean>(false);
	const [hideCheckouts, setHideCheckouts] = React.useState<boolean>(false);
	const [hideFrequencies, setHideFrequencies] = React.useState<boolean>(false);
	const [hideOrdersRange, setHideOrdersRange] = React.useState<boolean>(false);
	const [statusCheckBox, setStatusCheckBox] = React.useState<number[]>(props.status.map(e => e.id));
	const [reset, setReset] = React.useState<boolean>(false);
	const translate = getTranslate(storeLang.getState().localize);
	const orderStatuses = useRecoilValue(AOrderStatuses);
	const [selectedOrderStatuses, setSelectedStatuses] = React.useState<number[]>(orderStatuses.map(s => s.id));
	const [frequencySliderValues, setFrequencySliderValues] = React.useState<number[]>([0, 7]);
	const [checkoutSliderValues, setCheckoutSliderValues] = React.useState<number[]>([-7, 7]);
	const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();
	const eventTypes = useRecoilValue(AEventTypes);
	const eventTypeList = eventTypes.map(c => ({ value: c, label: translate(`event.${c.name}`).toString() }));
	const [selectedFrequencyEventType, setSelectedFrequencyEventType] = useRecoilState(AFrequencyEventType);
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const [hideAdditionalColumns, setHideAdditionalColumns] = React.useState<Set<number>>(new Set());
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const [hideAdditionalFieldColumns, setHideAdditionalFieldColumns] = React.useState<Set<number>>(new Set());
	const calculatedFields = useRecoilValue(ACalculatedFields);
	const [hideCalculatedFieldColumns, setHideCalculatedFieldColumns] = React.useState<Set<number>>(new Set());

	React.useEffect(() => {
		setStatusCheckBox(props.status.map(e => e.id));
	}, [props.status]);

	function handleCheckedStatus(isChecked: boolean, value: number | undefined) {
		let newValue = statusCheckBox;
		if (typeof (value) !== 'undefined') {
			if (isChecked) {
				newValue.push(value);
			} else {
				const index = newValue.indexOf(value);
				if (index >= 0) { newValue.splice(index, 1); }
			}
		} else {
			if (isChecked) {
				newValue = props.status.map(e => e.id);
			} else {
				newValue = [];
			}
		}
		setStatusCheckBox([...newValue]);
		props.changeFilters('clientStatusId', newValue.length == 0 ? [-1] : newValue);
	}

	function resetPieFilter() {
		Object.keys(props.pieFilter).forEach((key) => {
			const filter = props.pieFilter[key];
			filter.value = [0, 100];
			props.updatePieFilter(key, filter);
		});
	}

	function handleReset() {
		setSelectedPoscodes([]);
		setSelectedCities([]);
		setSelectedCountries([]);
		setHideCompanyShelfAudit(false);
		setStatusCheckBox(props.status.map(e => e.id));
		setHideOrders(false);
		setHideFrequencies(false);
		setHideOrdersRange(false);
		setReset(true);
		resetPieFilter();
		setSelectedStatuses(orderStatuses.map(s => s.id));
		setFrequencySliderValues([0, 7]);
		props.changeFilters('reset', null);
	}

	function toggle(type: string) {
		setActiveCollapse(activeCollapse === type ? undefined : type);
	}

	function buttonToggle(title: string, key: string | FetchKey, fetchKey?: FetchKey) {
		return (
			<div style={{ paddingLeft: 15 }}>
				<ButtonToggle
					isOpen={activeCollapse === key}
					onClick={() => {
						toggle(key as string);
						fetchKey && props.fetchFor(fetchKey);
					}}
					title={title}
				/>
			</div>
		);
	}

	return (
		<TabPane tabId={2}>
			<div className='d-flex justify-content-between align-items-center px-2 pt-3'>
				<h5 className='col mr-2 mb-0' style={{ color: SidelyBlack }}>
					<Translate id='map_datas' />
				</h5>
				<button
					type='button'
					className='btn btn-primary-outline'
					style={{
						padding: '5px 15px',
						borderRadius: 5
					}}
					onClick={() => {
						handleReset();
						// setState({ isHideEvent: false });
					}}
				>
					<Translate id='Reset All' />
				</button>
			</div>
			<div style={{ paddingBottom: '20px' }}>
				<div className='col'>
					{buttonToggle('status', 'status', 'status')}
					<Collapse isOpen={activeCollapse === 'status'}>
						<div style={{ maxWidth: '260px' }}>
							<CheckboxGroupFilter
								options={props.status.map(s => ({ label: s.name, value: s.id }))}
								valChecked={statusCheckBox}
								input={{ value: statusCheckBox, onChange: () => null }}
								checkedAll={
									statusCheckBox && statusCheckBox.length === props.status.length
								}
								handleCheckedStatus={handleCheckedStatus}
								placeholder='Search status'
								type='status'
								meta={{ touched: props.status.length == 0, error: 'Error fetching status data' }}
							/>
						</div>
					</Collapse>
				</div>
				<Restricted to={{ objectAction: 'ReadEvent' }}>
					<>
						{optionEvent.map(val => (
							<div className='col' key={`OptionEventTabFilter[${val.key}]`}>
								{buttonToggle(val.label, val.key, val.key)}
								<Collapse isOpen={activeCollapse === val.key}>
									<div style={{ overflow: 'hidden', position: 'relative' }}>
										<EventsFilter
											changeFilters={props.changeFilters}
											eventTypes={eventTypes.map(e => ({
												label: translate('event.' + e.name).toString(),
												value: e.id
											}))}
											type={val.key}
											reset={{ reset, setReset }}
										/>
									</div>
								</Collapse>
							</div>
						))}
					</>
				</Restricted>
				<Restricted to={{ objectAction: 'ReadEvent' }}>
					<div className='col'>
						{buttonToggle('frequency', 'frequencies', 'frequencies')}
						<Collapse isOpen={activeCollapse === 'frequencies'}>
							<div className='mb-3'>
								<FlexDiv clickable onClick={() => {
									setHideFrequencies(!hideFrequencies);
									props.changeFilters('hideFrequencies', !hideFrequencies);
								}}>
									<MapCheckbox isActive={hideFrequencies} />
									<MapTextFilters><Translate id='hide_companies_no_frequency' /></MapTextFilters>
								</FlexDiv>
								<hr />
								<div className='d-flex'>
									<div className='form-group check-sidely my-2'>
										<label className='ml-3 mb-3'>
											<div className='checkbox-style' />
										</label>
									</div>
									<small className='my-2' style={{ display: 'flex', gap: 5 }}>
										<Translate id='frequency_delay' />
										<ColumnPicker
											datalist={eventTypeList}
											columnId={'SliderPicker'}
											selectedValue={eventTypeList.find(et => et.value.id === selectedFrequencyEventType)}
											onChange={value => setSelectedFrequencyEventType(value.value.id)}
											height='20px'
										/>
									</small>
								</div>
								<FrequencySlider
									values={frequencySliderValues}
									setValues={values => {
										setFrequencySliderValues(values);
										localTimeout && clearTimeout(localTimeout);
										setLocalTimeout(setTimeout(values => props.changeFilters('frequencySlider', values), 250, values));
									}}
								/>
							</div>
						</Collapse>
					</div>
				</Restricted>
				<Restricted to={{ objectAction: 'ReadShelfAudit' }}>
					<div className='col'>
						{buttonToggle('shelf_audit', 'instore', 'instore')}
						<Collapse isOpen={activeCollapse === 'instore'}>
							<div className='mb-3'>
								<div className='d-flex'>
									<div className='form-group check-sidely my-2'>
										<label className='ml-3 mb-3'>
											<input
												type='checkbox'
												checked={hideShelfAudit}
												onChange={e => {
													const target = e.target.checked;
													setHideCompanyShelfAudit(target);
													props.changeFilters('hideShelfAudit', target);
												}}
											/>
											<div className='checkbox-style' />
										</label>
									</div>
									<small className='ml-4 my-2'>
										<Translate id='hide_companies_with_no_shelf_audit' />
									</small>
								</div>
								<hr />
								<HideLastDays
									checkBoxActivate={hideShelfAuditDate}
									onCheckBoxChange={range => {
										setHideCompanyShelfAuditDate(!hideShelfAuditDate);
										if (!hideShelfAuditDate) { props.changeFilters('hideShelfAuditDate', range); } else { props.changeFilters('hideShelfAuditDate', NaN); }
									}}
									onRangeChange={range => props.changeFilters('hideShelfAuditDate', parseInt(range))}
									text='map.hide_shelf_audit_date'
								/>
								<hr />
								{
									Object.keys(props.pieFilter).map((key, i) => <div key={`Props.PieFilter[${i}]`}>
										<SliderFilterBoxChecker
											minValue={0}
											maxValue={100}
											color={props.pieFilter[key].color}
											reverted
											sliderLabel={key}
											label={<Translate id={key} />}
											valueAlwaysDisplay
											step={0.1}
											handleChange={props.updatePieFilter}
											pieFilter={props.pieFilter}
										/>
										<hr />
									</div>)
								}

							</div>
						</Collapse>
					</div>
				</Restricted>
				<div className='col'>
					{buttonToggle('location', 'location')}
					<Collapse isOpen={activeCollapse === 'location'}>
						<small className='ml-4'>
							<Translate id='post_code' />
						</small>
						<Select
							closeMenuOnSelect={false}
							styles={selectStyles}
							placeholder={<Translate id='select postcode' />}
							value={selectedPostcodes}
							isMulti
							options={props.all_postcodes}
							onChange={value => {
								const postcodes = value || [];
								setSelectedPoscodes(postcodes);
								props.changeFilters('postcodes', postcodes.map(p => p.value.trim().toLowerCase()));
							}} />
						<small className='ml-4'>
							<Translate id='city' />
						</small>
						<div style={{ marginBottom: '15px' }}>
							<Select
								closeMenuOnSelect={false}
								styles={selectStyles}
								placeholder={<Translate id='select city' />}
								value={selectedCities}
								isMulti
								options={props.all_cities}
								onChange={value => {
									const cities = value || [];
									setSelectedCities(cities);
									props.changeFilters('cities', cities.map(c => c.value.trim().toLowerCase()));
								}} />
						</div>
						<small className='ml-4'>
							<Translate id='country' />
						</small>
						<div style={{ marginBottom: '15px' }}>
							<Select
								closeMenuOnSelect={false}
								styles={selectStyles}
								placeholder={<Translate id='select country' />}
								value={selectedCountries}
								isMulti
								options={props.all_countries}
								onChange={value => {
									const countries = value || [];
									setSelectedCountries(countries);
									props.changeFilters('countries', countries.map(c => c.value.trim().toLowerCase()));
								}} />
						</div>
					</Collapse>
				</div>
				<Restricted to={{ objectAction: 'ReadOrder' }}>
					<div className='col'>
						{buttonToggle('last_order', 'orders', 'orders')}
						<Collapse isOpen={activeCollapse === 'orders'}>
							<OrderFilter
								hideOrders={hideOrders}
								onHideOrders={() => {
									setHideOrders(!hideOrders);
									props.changeFilters('hideOrders', !hideOrders);
								}}
								hideOrdersRange={hideOrdersRange}
								onHideOrdersRange={(b, range) => {
									setHideOrdersRange(b);
									props.changeFilters('hideOrdersRange', [b, range]);
								}}
								selectedOrderStatuses={selectedOrderStatuses}
								setSelectedStatuses={statuses => {
									setSelectedStatuses(statuses);
									props.changeFilters('orderStatuses', statuses);
								}}
							/>
						</Collapse>
					</div>
				</Restricted>
				<div className='col'>
					{buttonToggle('checkouts', 'checkouts', 'checkouts')}
					<Collapse isOpen={activeCollapse === 'checkouts'}>
						<div className='mb-3'>
							<FlexDiv clickable onClick={() => {
								setHideCheckouts(!hideCheckouts);
								props.changeFilters('hideCheckouts', !hideCheckouts);
							}}>
								<MapCheckbox isActive={hideCheckouts} />
								<MapTextFilters><Translate id='hide_companies_no_checkouts' /></MapTextFilters>
							</FlexDiv>
							<hr />
							<div className='d-flex'>
								<div className='form-group check-sidely my-2'>
									<label className='ml-3 mb-3'>
										<div className='checkbox-style' />
									</label>
								</div>
								<small className='my-2' style={{ display: 'flex', gap: 5 }}>
									<GranularityPicker name={<Translate id="checkouts_evolution" />}/>
								</small>
							</div>
							<CheckoutSlider
								values={checkoutSliderValues}
								setValues={values => {
									setCheckoutSliderValues(values);
									localTimeout && clearTimeout(localTimeout);
									setLocalTimeout(setTimeout(values => props.changeFilters('checkoutSlider', values), 250, values));
								}}
							/>
						</div>
					</Collapse>
				</div>
				<Restricted to={{ objectAction: 'ReadForm' }}>
					<div className='col'>
						{buttonToggle('additional_columns', 'additional_columns', { additionalColumn: 0 })}
						<Collapse isOpen={activeCollapse === 'additional_columns'}>
							<div className='mb-3'>
								{additionalColumns.filter(ac => isAdditionalColumnTypeVisibleOnMap(ac.type)).map(ac => <div key={`AdditionalColumns[${ac.id}]`}>
									<FlexDiv clickable onClick={() => {
										if (hideAdditionalColumns.has(ac.id)) {
											hideAdditionalColumns.delete(ac.id);
										} else {
											hideAdditionalColumns.add(ac.id);
										}
										const newSet = new Set(hideAdditionalColumns);
										setHideAdditionalColumns(newSet);
										props.changeFilters('hideAdditionalColumns', newSet);
									}}>
										<MapCheckbox isActive={hideAdditionalColumns.has(ac.id)} />
										<MapTextFilters>{translateToString('hide_companies_with_missing', [['NAME', ac.name]])}</MapTextFilters>
									</FlexDiv>
									<hr />
								</div>)}
							</div>
						</Collapse>
					</div>
					<div className='col'>
						{buttonToggle('additional_field_columns', 'additional_field_columns', { additionalFieldColumn: 0 })}
						<Collapse isOpen={activeCollapse === 'additional_field_columns'}>
							<div className='mb-3'>
								{additionalFieldColumns.map(ac => <div key={`AdditionalFieldColumns[${ac.field_id}]`}>
									<FlexDiv clickable onClick={() => {
										if (hideAdditionalFieldColumns.has(ac.field_id)) {
											hideAdditionalFieldColumns.delete(ac.field_id);
										} else {
											hideAdditionalFieldColumns.add(ac.field_id);
										}
										const newSet = new Set(hideAdditionalFieldColumns);
										setHideAdditionalFieldColumns(newSet);
										props.changeFilters('hideAdditionalFieldColumns', newSet);
									}}>
										<MapCheckbox isActive={hideAdditionalFieldColumns.has(ac.field_id)} />
										<MapTextFilters>{translateToString('hide_companies_with_missing', [['NAME', ac.field_name]])}</MapTextFilters>
									</FlexDiv>
									<hr />
								</div>)}
							</div>
						</Collapse>
					</div>
					<div className='col'>
						{buttonToggle('calculated_fields', 'calculated_fields')}
						<Collapse isOpen={activeCollapse === 'calculated_fields'}>
							<div className='mb-3'>
								{calculatedFields.map(cf => <div key={`CalculatedFields[${cf.id}]`}>
									<FlexDiv clickable onClick={() => {
										if (hideCalculatedFieldColumns.has(cf.id)) {
											hideCalculatedFieldColumns.delete(cf.id);
										} else {
											hideCalculatedFieldColumns.add(cf.id);
										}
										const newSet = new Set(hideCalculatedFieldColumns);
										setHideCalculatedFieldColumns(newSet);
										props.changeFilters('hideCalculatedFieldColumns', newSet);
									}}>
										<MapCheckbox isActive={hideCalculatedFieldColumns.has(cf.id)} />
										<MapTextFilters>{translateToString('hide_companies_with_missing', [['NAME', cf.field_name]])}</MapTextFilters>
									</FlexDiv>
									<hr />
								</div>)}
							</div>
						</Collapse>
					</div>
				</Restricted>
			</div>
		</TabPane>
	);
}

const Line = () => <div style={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} />;

export function HideLastDays(props: {
  checkBoxActivate: boolean
  onCheckBoxChange: (range?: string) => void
  onRangeChange: (range: string) => void
  text: string
}) {
	const [range, setRange] = React.useState<string>();
	const { checkBoxActivate, onCheckBoxChange, onRangeChange } = props;

	return <FlexDiv flow='column' gap='10px' align='flex-start'>
		<FlexDiv clickable onClick={() => onCheckBoxChange(range)}>
			<MapCheckbox isActive={checkBoxActivate} />
			<MapTextFilters><Translate id={props.text} /></MapTextFilters>
		</FlexDiv>
		<FlexDiv gap='10px' margin='0 0 0 20px'>
			<Input
				name='numberSelector'
				type='text'
				inputStyle={{ height: '25px', width: '40px', borderRadius: '5px' }}
				value={range}
				disabled={!checkBoxActivate}
				onChange={e => {
					setRange(e);
					onRangeChange(e);
				}}
			/>
			<MapTextFilters><Translate id='days' /></MapTextFilters>
		</FlexDiv>
	</FlexDiv>;
}

const OrderStatusDiv = styled.div`
	display: flex;
	width: 100%;
	flex-flow: column;
	gap: 10px;
`;

function OrderFilter(props: {
  hideOrders: boolean
  onHideOrders: () => void
  hideOrdersRange: boolean
  onHideOrdersRange: (value: boolean, range: number) => void
  selectedOrderStatuses: number[]
  setSelectedStatuses: (statuses: number[]) => void
}): JSX.Element {
	const { hideOrders, onHideOrders, hideOrdersRange, onHideOrdersRange, selectedOrderStatuses, setSelectedStatuses } = props;
	const OrderStatuses = useRecoilValue(AOrderStatuses);

	return <FlexDiv flow='column' gap='10px' align='flex-start'>
		{OrderStatuses.length > 0 && <>
			<MapCheckbox isActive={selectedOrderStatuses.length == OrderStatuses.length} onClick={() => {
				if (selectedOrderStatuses.length == OrderStatuses.length) { setSelectedStatuses([]); } else { setSelectedStatuses(OrderStatuses.map(s => s.id)); }
			}} />
			<Line />
		</>
		}
		{OrderStatuses.map(status =>
			<OrderStatusDiv key={`orderStatus[${status.id}]`}>
				<FlexDiv clickable onClick={() => {
					if (selectedOrderStatuses.includes(status.id)) {
						const index = selectedOrderStatuses.findIndex(s => s == status.id);
						if (index >= 0) { selectedOrderStatuses.splice(index, 1); }
						setSelectedStatuses([...selectedOrderStatuses]);
					} else {
						setSelectedStatuses([...selectedOrderStatuses, status.id]);
					}
				} }>
					<MapCheckbox isActive={selectedOrderStatuses.includes(status.id)} />
					<MapTextFilters2>{status.name}</MapTextFilters2>
				</FlexDiv>
				<Line />
			</OrderStatusDiv>
		)}
		<FlexDiv clickable onClick={onHideOrders}>
			<MapCheckbox isActive={hideOrders} />
			<MapTextFilters><Translate id='hide_companies_no_orders' /></MapTextFilters>
		</FlexDiv>
		<Line />
		<HideLastDays
			checkBoxActivate={hideOrdersRange}
			onCheckBoxChange={range => onHideOrdersRange(!hideOrdersRange, parseInt(range ?? '0'))}
			onRangeChange={range => onHideOrdersRange(hideOrdersRange, parseInt(range))}
			text='hide_companies_no_orders_since'
		/>
	</FlexDiv>;
}


function getColorByCheckoutEvolution(evolution: number): string {
	return getColorForPercentage(evolution >= 7 ? 7 : evolution <= -7 ? -7 : evolution, 1.0, evolution > 0
		? [
			{ pct: 7, color: { r: 5, g: 119, b: 57 } },
			{ pct: 0, color: { r: 195, g: 252, b: 221 } }
		]
		: [
			{ pct: 0, color: { r: 255, g: 215, b: 212 } },
			{ pct: -7, color: { r: 162, g: 21, b: 9 } }
		]
	);
}

function CheckoutSlider(props: { values: number[], setValues: (v: number[]) => void}) {
	const valuetext = (value: number) => `${value >= 7 ? '+∞' : value <= -7 ? '-∞' : (value * 100).toFixed(0) + '%'}`;
	let background: string;
	const marks = [{
		value: -7,
		label: '-∞' 
	}, {
		value: 0,
		label: '0%'
	}, {
		value: 7,
		label: '+∞'
	}];
	
	if (props.values[0] < 0 && props.values[1] > 0) {
		const min = props.values[0];
		const max = props.values[1];
		const middlePerc = (-min / (max - min)) * 100;
		background = `linear-gradient(90deg, ${getColorByCheckoutEvolution(props.values[0])} 0%, rgb(255, 215, 212) ${middlePerc}%, rgb(195, 252, 221) ${middlePerc}%, ${getColorByCheckoutEvolution(props.values[1])} 100%)`;
	} else {
		background = `linear-gradient(90deg, ${getColorByCheckoutEvolution(props.values[0])} 0%, ${getColorByCheckoutEvolution(props.values[1])} 100%)`;
	}
	return <div style={{ padding: '0 10px' }}>
		<Slider
			getAriaLabel={() => 'Custom marks'}
			valueLabelFormat={valuetext}
			step={0.01}
			min={-7}
			max={7}
			valueLabelDisplay="auto"
			onChange={(_, values: number[]) => props.setValues(values)}
			defaultValue={[-7, 7]}
			value={props.values}
			marks={marks}
			sx={{
				color: BorderColor,
				'& .MuiSlider-thumb': {
					width: '10px',
					height: '10px'
				},
				'& .MuiSlider-track': {
					height: '8px',
					background
				},
				'& .MuiSlider-rail': {
					background: 'linear-gradient(90deg, rgb(162, 21, 9) 0%, rgb(255, 215, 212) 50%, rgb(195, 252, 221) 50%, rgb(5, 119, 57) 100%)'
				},
				'& .MuiSlider-valueLabel': {
					top: '0',
					backgroundColor: 'white',
					fontSize: '14px',
					color: 'black'
				},
				'& .Mui-active': {
					boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16) !important'
				}
			}}
		/>
	</div>;
}

function FrequencySlider(props: {values: number[], setValues: (v: number[]) => void}) {
	const marks = [
		{
			value: 0,
			label: getEmojiByFrequencyScore(0),
		},
		{
			value: 0.25,
			label: getEmojiByFrequencyScore(0.25),
		},
		{
			value: 0.5,
			label: getEmojiByFrequencyScore(0.5),
		},
		{
			value: 0.75,
			label: getEmojiByFrequencyScore(0.75),
		},
		{
			value: 1,
			label: getEmojiByFrequencyScore(1),
		},
		{
			value: 1.165,
			label: getEmojiByFrequencyScore(2),
		},
		{
			value: 1.5,
			label: getEmojiByFrequencyScore(4),
		},
		{
			value: 1.835,
			label: getEmojiByFrequencyScore(6),
		},
	];
	const valuetext = (value: number) => `${getEmojiByFrequencyScore(value)} ${value >= 7 ? '+∞' : value.toFixed(2)}`;
	const calculateValue = (value: number) => value < 1 ? value : 1 + (value - 1) * 6;
	const revertValue = (value: number) => value < 1 ? value : 1 + (value - 1) / 6;
	let background: string;
	
	if (props.values[0] < 1 && props.values[1] > 1) {
		const min = revertValue(props.values[0]);
		const max = revertValue(props.values[1]);
		const middlePerc = ((1 - min) / (max - min)) * 100;
		background = `linear-gradient(90deg, ${getColorByFrequencyScore(props.values[0])} 0%, rgb(195, 252, 221) ${middlePerc}%, rgb(255, 215, 212) ${middlePerc}%, ${getColorByFrequencyScore(props.values[1])} 100%)`;
	} else {
		background = `linear-gradient(90deg, ${getColorByFrequencyScore(props.values[0])} 0%, ${getColorByFrequencyScore(props.values[1])} 100%)`;
	}

	return (
		<div style={{ padding: '0 10px' }}>
			<Slider
				scale={calculateValue}
				getAriaLabel={() => 'Custom marks'}
				valueLabelFormat={valuetext}
				step={0.01}
				max={2}
				valueLabelDisplay="auto"
				onChange={(_, values: number[]) => props.setValues(values.map(calculateValue))}
				marks={marks}
				defaultValue={[0, 2]}
				value={props.values.map(revertValue)}
				sx={{
					color: BorderColor,
					'& .MuiSlider-thumb': {
						width: '10px',
						height: '10px'
					},
					'& .MuiSlider-track': {
						height: '8px',
						background
					},
					'& .MuiSlider-rail': {
						background: 'linear-gradient(90deg, rgb(5, 119, 57) 0%, rgb(195, 252, 221) 50%, rgb(255, 215, 212) 50%, rgb(162, 21, 9) 100%)'
					},
					'& .MuiSlider-valueLabel': {
						top: '0',
						backgroundColor: 'white',
						fontSize: '14px',
						color: 'black'
					},
					'& .Mui-active': {
						boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16) !important'
					}
				}}
			/>
		</div>
	);
}