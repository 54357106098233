import React, { Fragment, useEffect } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import RenderSelectInput from '../../../../components/select/RenderSelectInputModal';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../../helpers/storeLang';

const FORM = 'selectAssortment';
function SelectAssortment(props) {
	const translate = getTranslate(storeLang.getState().localize);
	const { history, option, dispatch } = props;
	const template = JSON.parse(localStorage.getItem('enFormTemplate'));
	useEffect(() => {
		dispatch(change(FORM, 'catalogue_id', template['catalogue_id'] || null));
	}, []);
	const opt = option.map(o => ({ label: o.name, value: o.id }));
	return (
		<Fragment>
			<h6 className="font-weight-bold mb-3">
				<Translate id="step" />
				{' '}1 -{' '} 
				<Translate id="Link an Assortment to the form template"/>
			</h6>
			<p>
				<Translate id="In order to monitor the presence of your Products in the right stores..."/>
				{' '}
			</p>
			<div style={{ width: '35%' }}>
				<Field
					name="catalogue_id"
					placeholder={translate('Pick an assortment')}
					noLabel
					options={opt}
					component={RenderSelectInput}
					isClearable
					onChange={a => props.setSelectedId(a.value || '')}
				/>
			</div>
			<p>
        -{' '}
				<Translate id="You haven’t loaded your products in Sidely yet?" />
				{' '}
				<span
					className="font-weight-bold pointer"
					onClick={() => {
						localStorage.setItem('productOption', JSON.stringify('products'));
						history.push('/products-v2');
					}}
				>
					<Translate id="Click" />
				</span>{' '}
				<Translate id="here to do it" />
			</p>
			<p>
        -{' '}
				<Translate id="You haven’t created an assortment yet?"/>
				{' '}
				<span
					className="font-weight-bold pointer"
					onClick={() => {
						localStorage.setItem(
							'productOption',
							JSON.stringify('assortments')
						);
						history.push('/assortments-v2');
					}}
				>
					<Translate id="Click" />
				</span>{' '}
				<Translate id="here to do it" />
			</p>
		</Fragment>
	);
}

export default reduxForm({
	form: FORM
})(SelectAssortment);
