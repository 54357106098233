import React, {Component} from 'react';

// import ComponentHeader from './ComponentHeader';

class EndSection extends Component {
  state = {};

  render() {
    return <div className="end-section" />;
  }
}

export default EndSection;
