import ID from './UUID';

const StartSection = () => ({
	id: ID.uuid(),
	element: 'StartSection',
	text: 'Start Section',
	field_type: 'section',
	field_name: 'start section',
	system_field_name: 'start_section_label',
	icon: 'fas fa-heading',
	img: 'start-section.png',
	static: true,
	required: false,
	bold: false,
	italic: false,
	content: 'Placeholder Text...',
	canHavePageBreakBefore: true,
	canHaveAlternateForm: true,
	canHaveDisplayHorizontal: true,
	canHaveOptionCorrect: true,
	canHaveOptionValue: true,
	canPopulateFromApi: true,
	is_display_field_name: true,
	is_required: false,
	is_start_section: false,
	is_end_section: false,
	is_multiple_value: false,
	is_default_today: false,
	is_default_checked: false,
	is_default_user: false,
	default_image_id: '',
	default_image_src: '',
	accepted_values: '',
	ordering: 1,
	details: null,
	initial_value: null
});

const EndSection = () => ({
	id: ID.uuid(),
	element: 'EndSection',
	text: 'End Section',
	field_type: 'section',
	field_name: 'end section',
	system_field_name: 'end_section_label',
	icon: 'fas fa-heading',
	img: 'start-section.png',
	static: true,
	required: false,
	bold: false,
	italic: false,
	content: 'Placeholder Text...',
	canHavePageBreakBefore: true,
	canHaveAlternateForm: true,
	canHaveDisplayHorizontal: true,
	canHaveOptionCorrect: true,
	canHaveOptionValue: true,
	canPopulateFromApi: true,
	is_display_field_name: true,
	is_required: false,
	is_start_section: false,
	is_end_section: false,
	is_multiple_value: false,
	is_default_today: false,
	is_default_checked: false,
	is_default_user: false,
	default_image_id: '',
	default_image_src: '',
	accepted_values: '',
	ordering: 1,
	details: null,
	initial_value: null
});

const StartTable = () => ({
	id: ID.uuid(),
	element: 'StartTable',
	text: 'Start Table',
	field_type: 'table',
	field_name: 'Start Table',
	system_field_name: 'start_table_label',
	icon: 'fas fa-heading',
	img: 'table.svg',
	static: true,
	required: false,
	bold: false,
	italic: false,
	content: 'Start Table',
	canHavePageBreakBefore: true,
	canHaveAlternateForm: true,
	canHaveDisplayHorizontal: true,
	canHaveOptionCorrect: true,
	canHaveOptionValue: true,
	canPopulateFromApi: true,
	is_display_field_name: true,
	is_required: false,
	is_start_section: false,
	is_end_section: false,
	is_multiple_value: false,
	is_default_today: false,
	is_default_checked: false,
	is_default_user: false,
	default_image_id: '',
	default_image_src: '',
	accepted_values: '',
	ordering: 1,
	details: null,
	initial_value: null
});

const EndTable = (disabled = false) => ({
	id: ID.uuid(),
	element: 'EndTable',
	text: 'End Table',
	field_type: 'table',
	field_name: 'End Table',
	system_field_name: 'end_table_label',
	icon: 'fas fa-heading',
	img: 'table.svg',
	static: true,
	required: false,
	bold: false,
	italic: false,
	content: 'End Table',
	canHavePageBreakBefore: true,
	canHaveAlternateForm: true,
	canHaveDisplayHorizontal: true,
	canHaveOptionCorrect: true,
	canHaveOptionValue: true,
	canPopulateFromApi: true,
	is_display_field_name: true,
	is_required: false,
	is_start_section: false,
	is_end_section: false,
	is_multiple_value: false,
	is_default_today: false,
	is_default_checked: false,
	is_default_user: false,
	default_image_id: '',
	default_image_src: '',
	accepted_values: '',
	ordering: 1,
	details: null,
	disabled,
	initial_value: null
});

export { StartSection, EndSection, StartTable, EndTable };
