import pen_black from 'images/ui_icon/pen_black.svg';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import AddressModificator from '../../../components_v2/address/AddressModificator';
import { addressToString } from '../../../components_v2/address/function/Function';
import { Address } from '../../../components_v2/address/model/Model';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { formatCurrency } from '../../../containers_v2/reports/utils';
import storeLang from '../../../helpers/storeLang';
import { DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import { BlueSidely } from '../../../styles/global/css/Utils';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { FlexDiv } from '../../products/style';
import { createOrder, getOrderStatuses } from '../data/Data';
import {
	cleanOrderCreator,
	closebottomRightToolbar,
	getDifference,
	getOrderCreator,
	getTotalDiscountPrice,
	getTotalProduct,
	getTotalTaxPrice,
	getTotalTTC,
	updatebottomLeftToolbar
} from '../global/globalFunction';
import { Order, OrderCreator, OrderProduct, OrderProductAdditionalTax, OrderStatus, PaymentType, POURCENTAGE } from '../model/Model';
import {
	AddressBlock,
	AddressContainer,
	Area,
	Container,
	ContainerBottom,
	ContainerTop,
	Edit,
	EditContainer,
	FormButton,
	Row,
	Separator,
	SummaryBlock,
	SummaryItemBlock,
	SummaryItemBlockTotal,
	SummaryItemTitle,
	SummaryItemValue,
	Title
} from './style/Style';
import useAlert from '../../alert/UseAlert';
import { translateToNode, translateToString } from '../../../styles/global/translate';

function submit(orderCreator: OrderCreator, deliveryAddress: Address, billingAddress: Address, selectedStatus: OrderStatus | undefined, selectedPayment: PaymentType | null, comment: string | undefined, setRedirect: Function): void {
	if (!orderCreator || (selectedStatus == null)) {
		return;
	}

	const orderProducts: OrderProduct[] = orderCreator.selectedProducts.map((sp) => {
		return {
			product_id: sp.product.id,
			product_name: sp.product.name,
			product_reference: sp.product.reference.length === 0 ? null : sp.product.reference,
			product_barcode: sp.product.barcode.length === 0 ? null : sp.product.barcode,
			product_upc_code: sp.product.code_upc.length === 0 ? null : sp.product.code_upc,
			product_tax: undefined,
			brand_id: sp.product.brand_id,
			brand_name: sp.product.brand_name,
			category_id: sp.product.category_id,
			category_name: sp.product.category_name && sp.product.category_name.length !== 0 ? sp.product.category_name : null,
			price: sp.price,
			quantity: sp.amount,
			discount: (sp.discountType === POURCENTAGE ? (sp.discount / 100.0) : sp.discount),
			discount_id: sp.discountType,
			product_additional_taxes: sp.extra_taxes.filter(t => t.tax_value && t.tax_value > 0).map((t): OrderProductAdditionalTax => {
				return ({
					tax_type: t.tax_type_id,
					tax_value: t.tax_type_id === 1 ? Number.parseFloat(((t.tax_value ?? 0) / 100.0).toPrecision(4)) : t.tax_value ?? 0,
					additional: t.is_additive,
					sequence: t.sequence
				});
			}
			)
		} as unknown as OrderProduct;
	});

	const order: Order = {
		reference: orderCreator.name.trim().length > 0 ? orderCreator.name.trim() : undefined,
		owner_id: orderCreator.owner.id,
		order_status_id: selectedStatus?.id,
		payment_id: selectedPayment?.id,
		client_company_id: orderCreator.company.id,
		contact_id: orderCreator.contact?.id,
		opportunity_id: orderCreator.opportunity?.id,
		discount: orderCreator.discountType === POURCENTAGE ? parseFloat((orderCreator.discount / 100.0).toString()) : parseFloat(orderCreator.discount.toString()),
		discount_id: orderCreator.discountType,
		billing_address: addressToString(billingAddress, true),
		delivery_address: addressToString(deliveryAddress, true),
		comment: comment && comment.length !== 0 ? comment : null,
		products: orderProducts
	} as Order;

	createOrder(order).then((response) => {
		if (response.valueOf()) {
			setRedirect(<Redirect push to='/orders' />);
			cleanOrderCreator();
		}
	}).catch(error => console.log(error));
}

function getPayments(): PaymentType[] {
	return [
		{ id: 1, name: 'Carte Bancaire' },
		{ id: 2, name: 'Cash' },
		{ id: 3, name: 'Chèque' },
		{ id: 4, name: 'Virement Bancaire' },
		{ id: 5, name: 'Autres' }
	] as PaymentType[];
}

function initToolbar(setToolBarState: (value: ToolbarState) => void, setRedirect: Function, order: OrderCreator): void {
	closebottomRightToolbar(setToolBarState, setRedirect, '/orders');
	updatebottomLeftToolbar(setToolBarState, 3, order.name);
}

const Td = styled.td`
	text-align: right;
	padding-right: 20px;
	padding-bottom: 20px;
`;

const Tr = styled.tr`
	margin-bottom: 10px;
`;

function Billing(props: {
  setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const order = getOrderCreator();
	const total_product = getTotalProduct(order.selectedProducts);
	const total_discount = getTotalDiscountPrice(order.selectedProducts, order.discount, order.discountType, getDifference(order.selectedProducts, order.discount));
	const total_tax = getTotalTaxPrice(order.selectedProducts);
	const total_HT = total_product - total_discount;
	const total_TTC = getTotalTTC(order.selectedProducts, order.discount, order.discountType, getDifference(order.selectedProducts, order.discount));

	const [statusList, setStatusList] = React.useState<DropdownData[]>([]);
	const [paymentList, setPaymentList] = React.useState<DropdownData[]>([]);

	//FIXME: put BillingAddress as DeliveryAddress by default(requested by Arthur)
	const [deliveryAddress, setDeliveryAddress] = React.useState<Address>(convertToBillingAddress());
	const [billingAddress, setBillingAddress] = React.useState<Address>(convertToBillingAddress());
	const [selectedStatus, setSelectedStatus] = React.useState<OrderStatus>();
	const [selectedPayment, setSelectedPayment] = React.useState<PaymentType | null>(null);
	const [comment, setComment] = React.useState<string | undefined>(order.comment);

	const [redirect, setRedirect] = React.useState<JSX.Element>(<></>);

	const [isOpenModifyDelivery, setOpenModifyDelivery] = React.useState<boolean>(false);
	const [isOpenModifyBilling, setOpenModifyBilling] = React.useState<boolean>(false);
	const alert = useAlert();

	React.useEffect(() => {
		initToolbar(props.setToolBarState, setRedirect, order);
		getOrderStatuses().then((response) => {
			setStatusList(response.map((orderStatus) => {
				return {
					label: orderStatus.name,
					value: orderStatus,
					selected: response.indexOf(orderStatus) === 0
				} as DropdownData;
			}));
			setSelectedStatus(response.length > 0 ? response[0] : undefined);
		});

		const payList = getPayments();
		setPaymentList(payList.map((payment) => {
			return {
				label: payment.name,
				value: payment
			} as DropdownData;
		}));
	}, []);

	function convertToDeliveryAddress(): Address {
		return {
			street: order.company.delivery_address,
			postalCode: order.company.delivery_post_code,
			town: order.company.delivery_city,
			country: order.company.delivery_country
		} as Address;
	}

	function convertToBillingAddress(): Address {
		return {
			street: order.company.billing_address,
			postalCode: order.company.billing_post_code,
			town: order.company.billing_city,
			country: order.company.billing_country
		} as Address;
	}

	function updateDeliveryAddress(newA: Address): void {
		setDeliveryAddress(newA);
	}

	function updateBillingAddress(newA: Address): void {
		setBillingAddress(newA);
	}

	return (
		(<Container>
			{redirect}
			<AddressModificator
				isOpen={isOpenModifyDelivery}
				setOpen={setOpenModifyDelivery}
				defaultAddress={deliveryAddress}
				title={translate('orders.billing.deliveryAddress').toString()}
				onChange={(na) => {
					updateDeliveryAddress(na);
				}}
			/>
			<AddressModificator
				isOpen={isOpenModifyBilling}
				setOpen={setOpenModifyBilling}
				defaultAddress={billingAddress}
				title={translate('orders.billing.billingAddress').toString()}
				onChange={(na) => {
					updateBillingAddress(na);
				}}
			/>
			<ContainerTop>
				<AddressContainer>
					<AddressBlock>
						<Title>{translate('orders.billing.billingAddress').toString()}</Title>
						<EditContainer>
							<Area readOnly value={addressToString(billingAddress)}><img
								src={pen_black} /></Area>
							<Edit onClick={() => setOpenModifyBilling(true)} />
						</EditContainer>
					</AddressBlock>
					<AddressBlock>
						<Title>{translate('orders.billing.deliveryAddress').toString()}</Title>
						<EditContainer>
							<Area readOnly value={addressToString(deliveryAddress)}></Area>
							<Edit onClick={() => setOpenModifyDelivery(true)} />
						</EditContainer>
					</AddressBlock>
				</AddressContainer>
			</ContainerTop>
			<ContainerBottom>
				<Separator />
				<Title marginLeft='50px'>{translate('orders.billing.summary').toString()}</Title>
				<Row>
					<SummaryBlock>
						<SummaryItemBlock>
							<SummaryItemTitle>{translate('orders.billing.totalProduct').toString()}</SummaryItemTitle>
							<SummaryItemValue>{formatCurrency(total_product)}</SummaryItemValue>
						</SummaryItemBlock>
						<SummaryItemBlock>
							<SummaryItemTitle>{translate('orders.billing.totalDiscount').toString()}</SummaryItemTitle>
							<SummaryItemValue>{formatCurrency(total_discount)}</SummaryItemValue>
						</SummaryItemBlock>
						<SummaryItemBlock>
							<SummaryItemTitle>{translate('orders.billing.totalTax').toString()}</SummaryItemTitle>
							<SummaryItemValue>{formatCurrency(total_tax)}</SummaryItemValue>
						</SummaryItemBlock>
						<SummaryItemBlock>
							<SummaryItemTitle>{translate('orders.billing.totalHT').toString()}</SummaryItemTitle>
							<SummaryItemValue color={BlueSidely}>{formatCurrency(total_HT)}</SummaryItemValue>
						</SummaryItemBlock>
					</SummaryBlock>

					<SummaryItemBlockTotal>
						<SummaryItemTitle>{translate('orders.billing.totalTTC').toString()}</SummaryItemTitle>
						<SummaryItemValue color='#FFFFFF'>{formatCurrency(total_TTC)}</SummaryItemValue>
					</SummaryItemBlockTotal>
				</Row>
				<FlexDiv width='100%' justify='center'>
					<table style={{ width: '50%', margin: 40 }}>
						<Tr>
							<Td><DefaultTextDiv> *{translate('orders.billing.commandStatus').toString()}</DefaultTextDiv></Td>
							<Td onClick={() => {
								if (statusList.length == 0) {
									alert({
										title: translateToNode('orders_'),
										content: <div>{translateToNode('no_data_yet', { onClickA: () => window.open('/settings#order_fields', '_blank'), variables: [['data', translateToString('order_statuses').toLocaleLowerCase()]] })}</div>,
										noButtons: true
									});
								}
							}}>
								<Dropdown
									dropdownStyle={{ width: '350px', optionWidth: '350px' }}
									name='status'
									required
									datalist={statusList}
									readOnly
									onChange={(value: DropdownData) => setSelectedStatus(value.value)}
								/>
							</Td>
						</Tr>
						<Tr>
							<Td><DefaultTextDiv>{translate('orders.billing.paymentMode').toString()}</DefaultTextDiv></Td>
							<Td>
								<Dropdown
									dropdownStyle={{ width: '350px', optionWidth: '350px' }}
									name='status'
									datalist={paymentList}
									readOnly
									onChange={(value: DropdownData) => setSelectedPayment(value.value)}
									cancellable
								/>
							</Td>
						</Tr>
						<Tr>
							<Td><DefaultTextDiv>{translate('orders.billing.commentary').toString()}</DefaultTextDiv></Td>
							<td>
								<Area maxLength={5000} onChange={(e) => setComment(e.target.value)} value={order.comment}></Area>
							</td>
						</Tr>
					</table>
				</FlexDiv>
				<Row>
					<FormButton
						onClick={() => submit(order, deliveryAddress, billingAddress, selectedStatus, selectedPayment, comment, setRedirect)}>
						{translate('orders.billing.createCommand').toString()}
					</FormButton>
				</Row>
			</ContainerBottom>
		</Container>)
	);
}

export default Billing;
