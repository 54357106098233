import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API, URL_FOUNDATION } from '../../config/keys';
import { logoutUser } from './auth.action';
import * as type from '../actionType/companies.action.type';
import { handleError, handleErrorCompanies, handleErrorImport, handleErrorWithMessage } from '../../helpers/error';
import { modalErrorValidate } from './modal.action';
import { exportDocuments } from '../../helpers/textConfig';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';

const cookies = new Cookies();
const PATH = `${URL_API}/api`;
const token = cookies.get('id_token') || null;

export function fetchData(data = {}) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		try {
			dispatch({
				type: type.FETCH_COMPANIES_REQUEST
			});
			const response = await axios({
				method: 'get',
				url: `${PATH}/clientCompanies`,
				params: data
			});
			dispatch({
				type: type.FETCH_COMPANIES_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.FETCH_COMPANIES_ERROR);
		}
	};
}

export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';

export const setActiveFilter = data => async dispatch => {
	dispatch({
		type: SET_ACTIVE_FILTER,
		payload: data
	});
};

export const setInitFilter = data => async dispatch => {
	dispatch({
		type: type.SET_INITIAL_FILTER,
		payload: data
	});
};

export function detailData(id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.DETAIL_COMPANIES_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/clientCompanies/id/${id}`
			});
			dispatch({
				type: type.DETAIL_COMPANIES_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.DETAIL_COMPANIES_ERROR);
		}
	};
}

export function detailPin(id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.DETAIL_PIN_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/clientCompanies/pin/id/${id}`
			});
			dispatch({
				type: type.DETAIL_PIN_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.DETAIL_PIN_ERROR);
		}
	};
}

export function createData(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_COMPANIES_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/clientCompanies`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			window.location.href = `/companies/detail/${response.data.data.id}`;
			dispatch({
				type: type.CREATE_COMPANIES_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleErrorCompanies(error, dispatch, type.CREATE_COMPANIES_ERROR);
		}
	};
}

export function createFromSelect(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_COMPANIES_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/clientCompanies`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.CREATE_COMPANIES_SUCCESS,
				payload: response.data
			});
			// swal.fire('Success!', 'Data created!', 'success');
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.CREATE_COMPANIES_ERROR);
		}
	};
}

export function updateData(data, id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.UPDATE_COMPANIES_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'PUT',
				url: `${PATH}/clientCompanies/id/${id}`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.UPDATE_COMPANIES_SUCCESS,
				payload: response.data
			});
			// swal.fire('Success!', 'Data updated!', 'success').then(() => {
			window.location.href = `/companies/detail/${id}`;
			// });
		} catch (error) {
			handleErrorCompanies(error, dispatch, type.UPDATE_COMPANIES_ERROR);
		}
	};
}

export function deleteData(id, isRedirect) {
	axios.defaults.headers.common.Authorization = `${token}`;
	const paramsData = JSON.parse(localStorage.getItem('params'));
	const translate = getTranslate(storeLang.getState().localize);

	return async dispatch => {
		dispatch({
			type: type.DELETE_COMPANIES_REQUEST,
			loading: true
		});
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/clientCompanies/${id}`
			});
			if (isRedirect) {
				window.location.href = '/companies';
			} else {
				dispatch(fetchData(paramsData));
			}
		} catch (error) {
			// console.log(error.response.data.errors);
			swal.fire(translate('company.fire.error').toString(), translate('company.fire.error_message').toString(), 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function bulkDelete(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		// dispatch({
		//   type: type.DELETE_COMPANIES_REQUEST,
		//   loading: true
		// });
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/clientCompanies`,
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json'
				},
				data
			});
			dispatch(fetchData());
		} catch (error) {
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export const searchData = data => async dispatch => {
	dispatch({
		type: type.SEARCH_COMPANIES,
		payload: data
	});
};

export const sortData = data => async dispatch => {
	dispatch({
		type: type.SORT_DATA,
		payload: data
	});
};

export function importExcel(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.IMPORT_EXCEL_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/clientcompanies/importExcel`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.IMPORT_EXCEL_SUCCESS,
				payload: response.data
			});
			window.location.href = '/companies';
		} catch (error) {
			handleErrorImport(
				error,
				dispatch,
				type.IMPORT_EXCEL_ERROR,
				modalErrorValidate
			);
		}
	};
}

export function exportExcel(data) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		try {
			const response = await axios({
				method: 'get',
				responseType: 'blob',
				url: `${PATH}/clientCompanies/exportExcel`,
				params: data
			});
			exportDocuments(response.data, 'Companies');
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function exportCsv(data) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		try {
			const response = await axios({
				method: 'get',
				responseType: 'blob',
				url: `${PATH}/clientCompanies/exportCsv`,
				params: data
			});
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Companies.csv');
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function createOpportunity(data, id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_LINKEDELEMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/opportunities`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.CREATE_LINKEDELEMENT_SUCCESS,
				payload: response.data
			});
			// swal.fire('Success!', 'Data created!', 'success').then(() => {
			window.location.href = `/companies/detail/${id.clientCompanyId}`;
			// });
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.CREATE_LINKEDELEMENT_ERROR);
		}
	};
}

export function createCompanyFile(data, id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_LINKEDELEMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/clientCompanyFiles/`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.CREATE_LINKEDELEMENT_SUCCESS,
				payload: response.data
			});
			// swal.fire('Success!', 'Data created!', 'success').then(() => {
			window.location.href = `/companies/detail/${id.clientCompanyId}`;
			// });
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.CREATE_LINKEDELEMENT_ERROR);
		}
	};
}

export function createNote(data, clientCompanyId) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_LINKEDELEMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/notes/`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.CREATE_LINKEDELEMENT_SUCCESS,
				payload: response.data
			});
			// swal.fire('Success!', 'Data created!', 'success').then(() => {
			window.location.href = `/companies/detail/${clientCompanyId}`;
			// });
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.CREATE_LINKEDELEMENT_ERROR);
		}
	};
}

export function createEvent(data, clientCompanyId) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_LINKEDELEMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/events/`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.CREATE_LINKEDELEMENT_SUCCESS,
				payload: response.data
			});
			if (clientCompanyId !== null) {
				if (clientCompanyId !== 'map') {
					window.location.href = `/companies/detail/${clientCompanyId}`;
				}
			} else {
				window.location.href = '/calendar';
			}
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.CREATE_LINKEDELEMENT_ERROR);
		}
	};
}

// GET LINKED ELEMENTS
export function getContacts(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.GET_CONTACTS_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/contacts`,
				params: data
			});
			dispatch({
				type: type.GET_CONTACTS_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_CONTACTS_ERROR);
		}
	};
}

export function getOpportunities(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.GET_OPPORTUNITIES_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/opportunities`,
				params: data
			});
			dispatch({
				type: type.GET_OPPORTUNITIES_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_OPPORTUNITIES_ERROR);
		}
	};
}

export function getEvents(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	const params = {
		...data,
		sort: 'desc'
	};

	return async dispatch => {
		dispatch({
			type: type.GET_EVENTS_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/events`,
				params
			});
			dispatch({
				type: type.GET_EVENTS_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_EVENTS_ERROR);
		}
	};
}

export function getEventDetail(id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.GET_EVENT_DETAIL_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/events/id/${id}`
			});
			dispatch({
				type: type.GET_EVENT_DETAIL_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_EVENT_DETAIL_ERROR);
		}
	};
}

export function getEmails(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	const params = {
		...data,
		sort: 'desc'
	};

	return async dispatch => {
		dispatch({
			type: type.GET_EMAIL_REQUEST,
			loading: true
		});
		try {
			const contactResp = await axios({
				method: 'get',
				url: `${PATH}/contacts/all`,
				params: data
			});
			const companieResponse = await axios({
				method: 'get',
				url: `${PATH}/clientCompanies/id/${data.clientCompanyId}`,
				params: data
			});
			contactResp.data.data.push(companieResponse.data.data);
			const queryParams = contactResp.data.data
				.map((e) => e.email)
				.filter((e) => e != null)
				.reduce((acc, e) => acc + 'from:' + e + ' OR cc:' + e + ' OR to:' + e + ' OR ', '?q=')
				.slice(0, -4);
			const response = await axios({
				method: 'get',
				url: `${URL_FOUNDATION}/api/third-party/google-mail/get-mails${queryParams}`,
				user: data.user
			});
			dispatch({
				type: type.GET_EMAIL_SUCCESS,
				payload: {
					data: response.data.messages ? response.data.messages.slice((params.page - 1) * params.limit, params.page * params.limit) : response.data,
					pagination: {
						currentPage: params.page,
						lastPage: response.data.messages ? Math.ceil(response.data.messages.length / params.limit) : 0,
						count: response.data.messages ? response.data.messages.length : 0,
						recordPerPage: params.limit

					}
				}
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_EMAIL_ERROR);
		}
	};
}

export function getNotes(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	const params = {
		...data,
		by: 'createdAt',
		sort: 'desc'
	};

	return async dispatch => {
		dispatch({
			type: type.GET_NOTES_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/notes`,
				params
			});
			dispatch({
				type: type.GET_NOTES_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_NOTES_ERROR);
		}
	};
}

export function getNoteDetail(id) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.GET_NOTE_DETAIL_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/notes/id/${id}`
			});
			dispatch({
				type: type.GET_NOTE_DETAIL_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_NOTE_DETAIL_ERROR);
		}
	};
}

export function getEmailDetail(mailData) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.GET_EMAIL_DETAIL_REQUEST,
			loading: true
		});

		const response = {
			id: mailData.id,
			from: mailData.payload.headers.find(e => e.name == 'From').value,
			to: mailData.payload.headers.find(e => e.name == 'Delivered-To' || e.name == 'To').value,
			body: atob(mailData.payload.parts[0].body.data)
		};
		dispatch({
			type: type.GET_EMAIL_DETAIL_SUCCESS,
			payload: response
		});
	};
}

export function getDocuments(data) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.GET_DOCUMENTS_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'get',
				url: `${PATH}/clientCompanyFiles`,
				params: data
			});
			dispatch({
				type: type.GET_DOCUMENTS_SUCCESS,
				payload: response.data
			});
		} catch (error) {
			handleError(error, dispatch, type.GET_DOCUMENTS_ERROR);
		}
	};
}

export function deleteNote(id, clientCompanyId) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.DELETE_NOTE_REQUEST,
			id
		});
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/notes/${id}`,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			dispatch({
				type: type.DELETE_NOTE_SUCCESS
			});
			// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
			window.location.href = `/companies/detail/${clientCompanyId}`;
			// });
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.DELETE_NOTE_ERROR);
		}
	};
}

export function editNote(data, id, clientCompanyId) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_LINKEDELEMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'PUT',
				url: `${PATH}/notes/id/${id}`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.CREATE_LINKEDELEMENT_SUCCESS,
				payload: response.data
			});
			// swal.fire('Success!', 'Data updated!', 'success').then(() => {
			window.location.href = `/companies/detail/${clientCompanyId}`;
			// });
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.CREATE_LINKEDELEMENT_ERROR);
		}
	};
}

export function deleteEvent(id, clientCompanyId, path) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.DELETE_EVENT_REQUEST,
			id
		});
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/events/${id}`,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			dispatch({
				type: type.DELETE_EVENT_SUCCESS
			});
			// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
			if (path === undefined) {
				window.location.href = `/companies/detail/${clientCompanyId}`;
			} else {
				window.location.href = '/calendar';
			}
			// });
		} catch (error) {
			handleErrorWithMessage(error, dispatch, type.DELETE_EVENT_ERROR);
		}
	};
}

export function editEvent(data, id, clientCompanyId, path) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.CREATE_LINKEDELEMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'PUT',
				url: `${PATH}/events/id/${id}`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: type.CREATE_LINKEDELEMENT_SUCCESS,
				payload: response.data
			});
			if (path === undefined) {
				window.location.href = `/companies/detail/${clientCompanyId}`;
			} else {
				window.location.href = '/calendar';
			}
		} catch (error) {
			dispatch({
				type: type.CREATE_LINKEDELEMENT_ERROR,
				error
			});
			throw error;
		}
	};
}

export function getAllCompanies(params) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		try {
			dispatch({
				type: type.FETCH_COMPANY_IN_EACH_COUNTRY_REQUEST
			});
			const response = await axios({
				method: 'GET',
				url: `${PATH}/clientCompanies/mapViewRefactor`,
				params
			});

			const filteredCompanies = filterClusterCompanies(response.data);

			dispatch({
				type: type.FETCH_COMPANY_IN_EACH_COUNTRY_SUCCESS,
				payload: {
					companies: filteredCompanies
				}
			});
		} catch (error) {
			if (error.response) {
				dispatch({ type, type: error.response });
				if (error.response.data.errors === 'empty') {
					dispatch({
						type: type.FETCH_COMPANY_IN_EACH_COUNTRY_ERROR,
						payload: {
							companies: []
						}
					});
				} else if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		}
	};
}

export function setFilterTag(tagId) {
	return dispatch => {
		dispatch({ type: type.SET_FILTER_TAG_ID, payload: tagId });
	};
}

export function setFilterGroup(groupId = '') {
	return dispatch => {
		dispatch({ type: type.SET_FILTER_GROUP_ID, payload: groupId });
	};
}

function compareValues(key, order = 'asc') {
	return function innerSort(a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			return 0;
		}

		const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
		const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return order === 'desc' ? comparison * -1 : comparison;
	};
}

function filterCompanies(companies = [], isOpportunities = false) {
	const filterComp = companies.filter(comp => comp.billingCountry != '');

	const filterCompanyBillAddr = filterComp.filter(
		comp => comp.billingCountry != 'null'
	);

	const filterCompanyBillCountry = filterCompanyBillAddr.filter(
		comp => comp.billingCountry != null
	);

	const filterCompanies = filterCompanyBillCountry;

	if (isOpportunities) {
		filterCompanies = filterCompanyBillCountry.filter(
			comp => comp.oppenedOpportunities != 0
		);
	}

	const sortedCompanies = filterCompanies.sort(compareValues('billingCountry'));

	return sortedCompanies;
}

function getCluster(companies, count) {
	let latitude = 0;
	let longitude = 0;
	for (let i = 0; i < count; i++) {
		latitude = latitude + parseFloat(companies[i].latitude);
		longitude = longitude + parseFloat(companies[i].longitude);
	}

	return {
		latitude: latitude / count,
		longitude: longitude / count,
		clientStatusId: 1,
		count
	};
}

function filterClusterCompanies(companies) {
	let allData = [];
	for (let i = 1; i <= 4; i++) {
		if (companies.meta[`data${i}`] !== 0) {
			if (companies[`centerCluster${i}`] !== undefined) {
				allData = allData.concat({
					latitude: companies[`centerCluster${i}`].latitude,
					longitude: companies[`centerCluster${i}`].longitude,
					clientStatusId: 1,
					count: companies.meta[`data${i}`]
				});
			} else {
				allData = allData.concat(companies[`data${i}`]);
			}
		}
	}
	return allData;
}

function filterCompanyInEachCountry(filteredCompanies) {
	const tempCompanyInEachCountry = [];

	filteredCompanies.map((company, index) => {
		if (index + 1 < filteredCompanies.length) {
			if (
				company.billingCountry != filteredCompanies[index + 1].billingCountry
			) {
				tempCompanyInEachCountry.push({
					country: company.billingCountry,
					latLng: {},
					companies: []
				});
			}
		} else if (
			company.billingCountry != filteredCompanies[index - 1].billingCountry
		) {
			tempCompanyInEachCountry.push({
				country: company.billingCountry,
				latLng: {},
				companies: []
			});
		}
	});

	return tempCompanyInEachCountry;
}

async function getCompaniesInEachCountry(companies, companyInEachCountry) {
	return new Promise((resolve, rejected) => {
		companyInEachCountry.map(async compMap => {
			const latLng = await getLatLngByCountry(compMap.country);
			compMap.latLng = latLng;

			companies.map(company => {
				if (company.billingCountry == compMap.country) {
					compMap.companies.push(company);
				}
			});
		});

		resolve(companyInEachCountry);
	});
}

async function getLatLngByCountry(country = 'Indonesia') {
	return {
		lat: 0,
		lng: 0
	};
}

export const setRange = data => async dispatch => {
	dispatch({
		type: type.RANGE_SELECTED,
		payload: data
	});
};

export function detailInStore(params) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		return axios({
			method: 'GET',
			url: `${PATH}/inStorePresences/Calculate`,
			params
		}).then(response => {
			if (params.calculateChild) {
				dispatch({
					type: type.INSTORE_PRESENCES_CALCULATE_CHILD,
					payload: response.data.data
				});
			} else {
				dispatch({
					type: type.INSTORE_PRESENCES,
					payload: response.data.data
				});
			}
		});
	};
}
