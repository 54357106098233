import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

type AverageOrInstant = 'average' | 'instant';

const AglobalAverageOrInstantPicker: AtomState<AverageOrInstant> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_average_or_instant_picker', // UNIQUE ID
		default: 'instant'
	})
};

const AreportAverageOrInstantPicker: AtomState<AverageOrInstant> = {
	category: AtomCategory.REPORTS,
	atom: atom({
		key: 'atom_report_average_or_instant_picker', // UNIQUE ID
		default: 'instant'
	})
};

const AAverageOrInstantPicker: RecoilState<AverageOrInstant> = selector({
	key: 'atom_average_or_instant_picker',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<AverageOrInstant> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalAverageOrInstantPicker.atom;
		case AtomCategory.REPORTS:
			return AreportAverageOrInstantPicker.atom;

		default:
			return AglobalAverageOrInstantPicker.atom;
	}
}

export {
	AAverageOrInstantPicker,
	AverageOrInstant
};
