import listWithPenImage from 'images/icon/list_with_pen.svg';
import * as React from 'react';
import { loadingState, tooltipPos } from '../../../components_v2/models';
import { exportOrderPerProductPerCompany, getOrderPerProductPerCompany } from './actions';
import { InfiniteTable, InfiniteTableStyle } from '../../../components_v2/table/InfiniteTable';
import { useWindowDimensions } from '../../../components_v2/utils';
import { formatCurrency, getStringWidth, queryConstructor } from '../utils';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import PageLoader from '../../../components/PageLoader';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { reportingAction, reportingState } from '../Reducer';
import { useDispatch } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { ADatePicker } from '../../../atoms/filter/timeFilterAtom';
import { AProductFilter } from '../../../atoms/filter/productsFilterAtom';
import { ToolBarDotsReports } from '../../../myToolbar/OtherTools';
import usePermission from '../../permissions/usePermission';
import { Column } from '../../../components_v2/table/Table';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';

const sort = key => (rowA, rowB, _columnId) => {
	const a = rowA.original[key];
	const b = rowB.original[key];
	if (a > b) return 1;
	if (a < b) return -1;
	return 0;
};

const sortSum = key => (rowA, rowB, _columnId) => {
	let a = rowA.original[key];
	let b = rowB.original[key];
	if (typeof (a) === 'string') {
		a = false;
	}
	if (typeof (b) === 'string') {
		b = false;
	}
	if (a > b) return 1;
	if (a < b) return -1;
	return 0;
};

// const sortCompanyPresence = () => (rowA, rowB, _columnId) => {
//   if (rowA.original.hasOwnProperty("product_presence")
//     && rowA.original["product_presence"].hasOwnProperty("present_count")
//     && rowA.original["product_presence"].hasOwnProperty("expected_count")
//     && rowB.original.hasOwnProperty("product_presence")
//     && rowB.original["product_presence"].hasOwnProperty("present_count")
//     && rowB.original["product_presence"].hasOwnProperty("expected_count")) {
//     let a_p = rowA.original['product_presence']['present_count']
//     let b_p = rowB.original['product_presence']['present_count']
//     let a_e = rowA.original['product_presence']['expected_count']
//     let b_e = rowB.original['product_presence']['expected_count']
//     if ((a_p / a_e) > (b_p / b_e)) return 1;
//     if ((a_p / a_e) < (b_p / b_e)) return -1;
//   }
//   return 0;
// }

// const sortProductCount = () => (rowA, rowB, _columnId) => {
//   if (rowA.original.hasOwnProperty("product_presence")
//     && rowA.original["product_presence"].hasOwnProperty("present_count")
//     && rowB.original.hasOwnProperty("product_presence")
//     && rowB.original["product_presence"].hasOwnProperty("present_count")) {
//     let a_p = rowA.original['product_presence']['present_count']
//     let b_p = rowB.original['product_presence']['present_count']
//     if (a_p > b_p) return 1;
//     if (a_p < b_p) return -1;
//   }
//   return 0;
// }

interface ProductByCategories {
  id: string
  name: string
  products: Array<{ id: number, name: string }>
}

interface Sum {
  without_tax_without_discount_sum: number
  discount_sum: number
  without_tax_with_discount_sum: number
  with_tax_with_discount_sum: number
}

interface Totals {
  company_count: number
  company_no_order_count: number
  order_sum_without_tax: number
  order_sum_with_tax: number
  order_count: number
  products: Record<string, Sum>
}

function isNumber(value: string | number): boolean {
	return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}

function getTooltip(company_name: string, sum: Sum, pos: tooltipPos) {
	const translate = getTranslate(storeLang.getState().localize);
	let tooltipClass = 'tooltiptext3';
	if (pos == 'up') {
		tooltipClass = 'tooltiptextup';
	}
	const without_tax_without_discount_sum_s = translate('shelf_audit.legend.without_tax_without_discount_sum').toString();
	const without_tax_with_discount_sum_t = translate('shelf_audit.legend.without_tax_with_discount_sum').toString();
	const with_tax_with_discount_sum_t = translate('shelf_audit.legend.with_tax_with_discount_sum').toString();
	const discount_sum_t = translate('shelf_audit.legend.discount_sum').toString();
	return (
		<span className={tooltipClass} style={{ width: '270px' }}>
			<div className='font-weight-bold' style={{ marginBottom: '2px' }}>
				{company_name}
			</div>
			<div className='row'>
				<div className='col-8' style={{ textAlign: 'left', paddingLeft: '30px' }}>
					{without_tax_without_discount_sum_s}
					{translate('shelf_audit.legend.without_tax_without_discount_sum') as string}
				</div>
				<div className='col-4' style={{ textAlign: 'right', paddingRight: '30px' }}>
					{formatCurrency(sum.without_tax_without_discount_sum)}
				</div>
			</div>
			<div className='row'>
				<div className='col-8' style={{ textAlign: 'left', paddingLeft: '30px' }}>
					{without_tax_with_discount_sum_t}
					{translate('shelf_audit.legend.without_tax_with_discount_sum') as string}
				</div>
				<div className='col-4' style={{ textAlign: 'right', paddingRight: '30px' }}>
					{formatCurrency(sum.without_tax_with_discount_sum)}
				</div>
			</div>
			<div className='row'>
				<div className='col-8' style={{ textAlign: 'left', paddingLeft: '30px' }}>
					{with_tax_with_discount_sum_t}
					{translate('shelf_audit.legend.with_tax_with_discount_sum') as string}
				</div>
				<div className='col-4' style={{ textAlign: 'right', paddingRight: '30px' }}>
					{formatCurrency(sum.with_tax_with_discount_sum)}
				</div>
			</div>
			<div className='row'>
				<div className='col-8' style={{ textAlign: 'left', paddingLeft: '30px' }}>
					{discount_sum_t}
					{translate('shelf_audit.legend.discount_sum') as string}
				</div>
				<div className='col-4' style={{ textAlign: 'right', paddingRight: '30px' }}>
					{formatCurrency(sum.discount_sum)}
				</div>
			</div>
		</span>
	);
}

function genProductContent(company_name: string, sum: Sum, pos: tooltipPos) {
	let tooltipClass = 'tooltip3';
	if (pos == 'up') {
		tooltipClass = 'tooltipup';
	}
	return (
		<div className={tooltipClass}>
			<span className='percentage'>
				{formatCurrency(sum.without_tax_with_discount_sum)}
			</span>
			{getTooltip(company_name, sum, pos)}
		</div>
	);
}

function genColumns(product_by_categories: ProductByCategories[], totals: Totals, rows: any[], onCompanyClick: (n: number) => void): Column[] {
	const translate = getTranslate(storeLang.getState().localize);
	const productColumns: Column[] = product_by_categories.map(pc => {
		return {
			Header: pc.name,
			columns: pc.products.map(p => {
				return {
					Header: p.name,
					accessor: row => {
						if (row[p.id] != null && row[p.id].without_tax_with_discount_sum != null && isNumber(row[p.id].without_tax_with_discount_sum)) {
							return genProductContent(row.company_name, row[p.id], 'left');
						}
						return '-';
					},
					width: getStringWidth(p.name),
					sortType: sortSum(p.id),
					Footer: _info => {
						if (totals.products[p.id] == null || totals.products[p.id].without_tax_with_discount_sum == null || totals.products[p.id].without_tax_with_discount_sum == 0) {
							return <span style={{ display: 'block', textAlign: 'center' }}>-</span>;
						} else {
							return genProductContent('', totals.products[p.id], 'up');
						}
					}
				};
			}),
			Footer: info => {
				return <span style={{ display: 'block', textAlign: 'center' }}>-</span>;
			}
		};
	});
	const company_name: string = translate('shelf_audit.column.company_name').toString();
	const order_sum_without_tax: string = translate('shelf_audit.column.order_sum_without_tax').toString();
	const order_count: string = translate('shelf_audit.column.order_count').toString();
	const order_sum_with_tax: string = translate('shelf_audit.column.order_sum_with_tax').toString();
	const last_order: string = translate('shelf_audit.column.last_order').toString();
	const owner_name: string = translate('shelf_audit.column.owner_name').toString();
	const no_parent_name: string = translate('shelf_audit.column.no_parent_name').toString();
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit'
	};
	const s: Column[] = [
		{
			Header: '-',
			columns: [
				{
					id: 'expander',
					Header: (props: any) => {
						const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = props;
						return (
							<span {...getToggleAllRowsExpandedProps({ title: undefined })}
								style={{ fontSize: '20px', cursor: 'pointer' }}>
								{isAllRowsExpanded ? '-' : '+'}
							</span>
						);
					},
					Cell: ({ row }) =>
					// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
					// to build the toggle for expanding a row
						row.canExpand ? (
							<span
								{...row.getToggleRowExpandedProps({
									title: undefined,
									style: {
										// We can even use the row.depth property
										// and paddingLeft to indicate the depth
										// of the row
										// paddingLeft: `${row.depth * 2.5}rem`,
										fontSize: '20px'
									}
								})}
							>
								{row.isExpanded ? '-' : '+'}
							</span>
						) : null,
					width: 60,
					Footer: 'Total'
				},
				{
					Header: company_name,
					accessor: row => {
						const content =
              <span>
              	{row.company_count != null &&
                  row.company_no_order_count != null &&
                  <span>{' '}({row.company_count} / {row.company_no_order_count + row.company_count})</span>
              	}
              </span>;
						if (row.hasOwnProperty('company_id') && row.hasOwnProperty('company_name')) {
							return (
								<span style={{ display: 'block', textAlign: 'left', cursor: 'pointer', fontWeight: 500 }}
									className='overflow'
									onClick={() => onCompanyClick(row.company_id)}
								>
									<span>
										{row.company_name}
										{content}
									</span>
								</span>
							);
						} else {
							return (
								<span style={{ display: 'block', textAlign: 'left', fontWeight: 500 }} className='overflow'>
									<span>
										{no_parent_name}
										{content}
									</span>
								</span>
							);
						}
					},
					sortType: sort('company_name'),
					width: 200,
					// width: getColumnWidth(rows, 'company_name', company_name),
					Footer: _info => {
						if (totals.company_count != undefined && totals.company_count != null) {
							return <span>{' '}{totals.company_count} / {totals.company_no_order_count + totals.company_count}</span>;
						} else {
							return '-';
						}
					}
				},
				{
					Header: last_order,
					accessor: row => {
						if (row.order_date) {
							const d = new Date(row.order_date);
							return (
								<span style={{ display: 'block', textAlign: 'right', cursor: 'pointer', fontWeight: 500 }}
									className='overflow'
									onClick={() => window.open(window.location.origin + '/orders?id=' + row.order_id, '_blank')}
								>
									{`${d.toLocaleDateString(undefined, options)}`}
								</span>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(last_order),
					sortType: sort('order_date'),
					Footer: ''
				},
				{
					Header: owner_name,
					accessor: row => {
						if (row.owner_name) {
							return (
								<span style={{ display: 'block', textAlign: 'left' }}
									className='overflow'>
									{row.owner_name}
								</span>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(owner_name),
					sortType: sort('owner_name'),
					Footer: ''
				},
				{
					Header: order_count,
					accessor: row => {
						if (row.hasOwnProperty('order_count')) {
							return (
								<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
									<span style={{
										borderRadius: '25px',
										color: DarkGreySidely,
										textAlign: 'center',
										width: '80px'
									}} className='overflow percentage'>
										{row.order_count}
									</span>
								</div>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(order_sum_without_tax),
					// sortType: sortCompanyPresence(),
					Footer: _info => {
						if (totals.hasOwnProperty('order_count')) {
							return (
								<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
									<span className='overflow percentage' style={{
										fontWeight: 500,
										borderRadius: '25px',
										color: DarkGreySidely,
										textAlign: 'center',
										width: '80px'
									}}>
										{totals.order_count}
									</span>
								</div>
							);
						} else {
							return '-';
						}
					}
				},
				{
					Header: order_sum_without_tax,
					accessor: row => {
						if (row.hasOwnProperty('order_sum_without_tax')) {
							return (
								<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
									<span style={{
										borderRadius: '25px',
										color: DarkGreySidely,
										textAlign: 'center',
										width: '80px'
									}} className='overflow percentage'>
										{formatCurrency(row.order_sum_without_tax)}
									</span>
								</div>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(order_sum_without_tax),
					// sortType: sortCompanyPresence(),
					Footer: _info => {
						if (totals.hasOwnProperty('order_sum_without_tax')) {
							return (
								<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
									<span className='overflow percentage' style={{
										fontWeight: 500,
										borderRadius: '25px',
										color: DarkGreySidely,
										textAlign: 'center',
										width: '80px'
									}}>
										{formatCurrency(totals.order_sum_without_tax)}
									</span>
								</div>
							);
						} else {
							return '-';
						}
					}
				},
				{
					Header: order_sum_with_tax,
					accessor: row => {
						if (row.hasOwnProperty('order_sum_with_tax')) {
							return (
								<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
									<span style={{
										borderRadius: '25px',
										color: DarkGreySidely,
										textAlign: 'center',
										width: '80px'
									}} className='overflow percentage'>
										{formatCurrency(row.order_sum_with_tax)}
									</span>
								</div>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(order_sum_with_tax),
					// sortType: sortCompanyPresence(),
					Footer: _info => {
						if (totals.hasOwnProperty('order_sum_with_tax')) {
							return (
								<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
									<span className='overflow percentage' style={{
										fontWeight: 500,
										borderRadius: '25px',
										color: DarkGreySidely,
										textAlign: 'center',
										width: '80px'
									}}>
										{formatCurrency(totals.order_sum_with_tax)}
									</span>
								</div>
							);
						} else {
							return '-';
						}
					}
				}
			],
			Footer: ''
		}
	];
	return s.concat(productColumns);
}

function filterData(filters: Object, data: any[]) {
	let ret = data;
	if (filters.lines && !filters.lines.some(f => f == 0)) {
		ret = ret.reduce((acc, e) => {
			if (!e.audit_date && (!e.company_count || e.company_count == 0)) {
				return acc;
			}
			const deleted = delNoShelfAuditTailRecursive(e);
			if (!deleted.deleteMe) { acc.push(deleted); }
			return acc;
		}, []);
	}
	if (filters.users) {
		ret = ret.reduce((acc, e) => {
			if (e.company_owner_id && !filters.users.includes(e.company_owner_id)) {
				return acc;
			}
			const deleted = delUsersTailRecursive(e);
			if (!deleted.deleteMe) {
				console.log(deleted);
				if (deleted.company_count && deleted.company_no_order_count) {
					// deleted.company_count -= deleted.company_count;
					// deleted.company_no_order_count -= deleted.company_no_order_count;
				}
				acc.push(deleted);
			}
			return acc;
		}, []);
	}
	// console.log(ret)
	return ret;

	function delUsersTailRecursive(e) {
		if (e.company_owner_id && !filters.users.includes(e.company_owner_id)) {
			return { deleteMe: true };
		} else if (e.subRows) {
			const subRows = e.subRows.map(x => delUsersTailRecursive(x));
			if (subRows.filter(x => !x.deleteMe).length == 0) {
				return {
					deleteMe: true,
					...subRows.reduce((acc, x) => {
						acc.company_count += x.company_count;
						acc.company_no_order_count += x.company_no_order_count;
						return acc;
					}, { company_count: 0, company_no_order_count: 0 })
				};
			}
			return {
				...e,
				subRows: subRows.filter(x => !x.deleteMe),
				...subRows.filter(x => !x.deleteMe).reduce((acc, x) => {
					if (x.company_count != undefined && x.company_no_order_count != undefined) {
						acc.company_count += x.company_count;
						acc.company_no_order_count += x.company_no_order_count;
					} else if (x.order_date) {
						acc.company_count += 1;
					} else {
						acc.company_no_order_count += 1;
					}
					return acc;
				}, { company_count: 0, company_no_order_count: 0 })
			};
		}
		return e;
	}

	function delNoShelfAuditTailRecursive(e) {
		if (e.order_date) {
			return e;
		} else if (e.subRows) {
			const subRows = e.subRows.map(x => delNoShelfAuditTailRecursive(x)).filter(x => !x.deleteMe);
			if (subRows.length == 0) {
				return { deleteMe: true };
			}
			return { ...e, subRows };
		}
		return { deleteMe: true };
	}
}

/*

      return {
        userPicker: 2,
        datePicker: true,
        tagFilter: true,
        productFilter: true,
        export: allowed,
        refreshButton: { status: true, props: { isLoading: isLoading } },
        optionDots: { status: true, props: { version: 2 } }
      }
  */

export function OrderPerProductPerCompany(props: {
  setToolBarState: (value: ToolbarState) => void
  state: reportingState
  dispatch: (action: reportingAction) => void
  onCompanyClick: (n: number) => void
}) {
	const { height, width } = useWindowDimensions();
	const offset = 296;
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any[]>([]);
	const c: Column[] = [];
	const [columns, setColumns] = React.useState(c);
	const translate = getTranslate(storeLang.getState().localize);
	const [filteredColumns, setFilteredColumns] = React.useState<any>(undefined);
	const [launchExport, setLaunchExport] = React.useState({ export: false });
	const dispatch = useDispatch();

	const pickedUsers = useRecoilValue(AUserPicker(0));
	const pickedUsers1 = useRecoilValue(AUserPicker(1));
	const pickedDates = useRecoilValue(ADatePicker);
	const filteredTags = useRecoilValue(ATagFilter);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 200}
					category={AtomCategory.GLOBAL}
					elements={[
						{
							kind: ToolbarElement.USER_PICKER
						},
						{
							kind: ToolbarElement.USER_PICKER,
							logo: listWithPenImage
						},
						{
							kind: ToolbarElement.DATE_PICKER,
							oneDay: true
						},
						ToolbarElement.TAG_FILTER,
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'} />
			</FlexDiv>,
			bottomRightToolbarComponent: <ToolBarDotsReports
				current={undefined}
				startingColumns={columns}
				export={false}
				onFilter={(e) => {
					if (e.export) {
						setLaunchExport(e);
					} else if (e.columns) {
						setFilteredColumns(e.columns);
					}
				}}
			/>
		});
	}, [loadingState, columns, filteredColumns, width]);

	React.useEffect(() => {
		if (!loadingState && (pickedUsers != null) && (pickedUsers1 != null) && (pickedDates != null) && (filteredTags != null)) {
			try {
				if (!loadingState) {
					setLoadingState('loading');
				}
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				let dateStr = '';
				if (pickedDates[0]?.format('YYYY-MM-DD') == pickedDates[1]?.format('YYYY-MM-DD') && (pickedDates[0] != null) && (pickedDates[1] != null)) {
					dateStr = `${pickedDates[0].format('YYYY-MM-DD')}_${pickedDates[1].format('YYYY-MM-DD')}`;
				} else {
					dateStr = `${pickedDates[0]?.format('YYYY-MM-DD') ?? ''}_${pickedDates[1]?.format('YYYY-MM-DD') ?? ''}`;
				}
				getOrderPerProductPerCompany(queryConstructor({
					tags: filteredTags,
					userIdList: [pickedUsers[0], pickedUsers1[0]]
				}, dateStr), cancelTokenSource.token)
					.then(res => {
						setData(res.data.rows);
						const columns = genColumns(res.data.columns, res.data.totals, res.data.rows, props.onCompanyClick);
						setColumns(columns);
						// if (typeof (props.toolBarState.columns) !== 'undefined' && typeof (props.toolBarState.columns.setDotColumns) !== 'undefined')
						//   props.toolBarState.columns.setDotColumns(columns);
						if (loadingState != 'loaded') {
							setLoadingState('loaded');
						}
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job in: OrderPerProductPerCompany ', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState, pickedUsers, pickedUsers1, pickedDates, filteredTags]);

	let ret: any[] = [];
	if (filteredColumns?.length != 0 && filteredColumns?.[0].columns) { ret = filteredColumns; } else { ret = columns; }
	return (
		<div style={{ height: '100%' }}>
			<InfiniteTableStyle>
				{(loadingState == 'loading' || !loadingState) && <div className='list-loader'><PageLoader /></div>}
				{loadingState == 'error' && <p>Error fetching data</p>}
				{loadingState == 'loaded' && <InfiniteTable height={height - offset} columns={ret}
					data={filterData(props.state.filters.OrderPerProductPerCompany, data)} />}
			</InfiniteTableStyle>
		</div>
	);
}
