import * as React from 'react';
import { ITag } from '../../../typings/proto/protobufs';
import Dropdown from './Dropdown';
import { DropdownData, DropdownTagProps } from './model/Model';
import { TagBox, TagName } from './style/Style';

function DropdownTag(props: DropdownTagProps): JSX.Element {
	const [tags, setTags] = React.useState<ITag[]>([]);

	React.useEffect(() => {
		setTags(props.defaultSelectedTags ?? []);
	}, [props.defaultSelectedTags]);

	return (
		<Dropdown
			dropdownStyle={props.dropdownStyle}
			datalist={props.tags.sort((a, b) => a.name!.localeCompare(b.name!)).map(t => {
				return {
					label: t.name!,
					value: t,
					color: t.color!
				};
			})}
			name={'select_dropdown_tags'}
			containerClickable
			checkboxAll
			checkbox
			readOnly
			label='Tags'
			onChange={(values: DropdownData[]) => {
				const nvalues = values.map(v => v.value);
				setTags(nvalues);
				props.onChangeTags(nvalues);
			}}
			JSXButton={() =>
				<TagBox>
					{
						tags.map((t, i) => <TagName key={`DDTagTagBox[${i}]`} backgroundColor={t.color!}>{t.name}</TagName>)
					}
				</TagBox>
			}
		/>
	);
}

export default DropdownTag;
