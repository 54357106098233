import bar from 'images/formbuilder/bar.svg';
import barGray from 'images/formbuilder/bar-gray.svg';
import pencil from 'images/formbuilder/pencil.svg';
import React from 'react';
import { Translate } from 'react-localize-redux';
import FormBuilderImageList from 'images/formbuilder/list.svg';
import FormBuilderImageCheckBox from 'images/formbuilder/checkbox.svg';
import FormBuilderAttachImage from 'images/formbuilder/attach.svg';
import FormBuilderCameraImage from 'images/formbuilder/camera.svg';
import FormBuilderStartSectionImage from 'images/formbuilder/start-section.png';
import FormBuilderTableImage from 'images/formbuilder/table.svg';
import FormBuilderCalendarImage from 'images/formbuilder/calendar.svg';
import FormBuilderUserImage from 'images/formbuilder/user.svg';


export function getFormBuilderImageUrl(image) {
	switch (image) {
		case 'list.svg': return FormBuilderImageList;
		case 'checkbox.svg': return FormBuilderImageCheckBox;
		case 'user.svg': return FormBuilderUserImage;
		case 'attach.svg': return FormBuilderAttachImage;
		case 'camera.svg': return FormBuilderCameraImage;
		case 'start-section.png': return FormBuilderStartSectionImage;
		case 'table.svg': return FormBuilderTableImage;
		case 'calendar.svg': return FormBuilderCalendarImage;
	}
	console.log('TODO', 'images/formbuilder/' + image, image);
	return '';
}

export default class HeaderBar extends React.Component {
	state = {};

	getIcon = (val, icon) => {
		switch (val) {
			case 'checkbox':
				return (
					<div
						className="icon-fields mr-3"
						style={{ fontFamily: 'Times New Roman' }}
					>
            Y / N
					</div>
				);

			default:
				return (
					<div className="mr-4">
						<i className={icon} />
					</div>
				);
		}
	};

	render() {
		const { data } = this.props;
		return (
			(<div className="toolbar-header d-flex justify-content-between">
				<div className="d-flex align-items-center">
					{data.img ? (
						<div className="mr-4">
							<img
								src={getFormBuilderImageUrl(data.img)}
								alt=""
								height="24px"
								width="24px"
							/>
						</div>
					) : (
						this.getIcon(data.field_type, data.icon)
					)}
					<div>
						<p className="text-14 m-0"><Translate id={this.props.data.field_name} /></p>
					</div>
				</div>
				<div className="toolbar-header-buttons">
					{this.props.data.element !== 'LineBreak' && (
						<div
							className="btn is-isolated btn-school px-2 pointer "
							onClick={this.props.editModeOn.bind(
								this.props.parent,
								this.props.data
							)}
						>
							<img
								className="is-isolated pointer"
								src={pencil}
								alt=""
							/>
						</div>
					)}
					<div className="btn is-isolated Epl-2 pr-0">
						{data.disabled ? (
							<img
								className="is-isolated"
								src={barGray}
								alt=""
							/>
						) : (
							<img
								className="is-isolated"
								src={bar}
								alt=""
							/>
						)}
					</div>
				</div>
			</div>)
		);
	}
}
