/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';

class Numerical extends Component {
  state = {
    number: null
  };

  handleChange = value => {
    const { data } = this.props;
    if (value <= data.max && value >= data.min) {
      this.setState({
        number: value
      });
    } else if (value > data.max) {
      this.setState({
        number: data.max
      });
    } else if (value < data.min) {
      this.setState({
        number: data.min
      });
    }
  };

  render() {
    const { data } = this.props;
    return (
      <div className="form-group">
        <label id={`id-${data.id}`} className="control-label" htmlFor="">
          {data.is_required && <span className="text-danger">* </span>}
          {data.is_display_field_name && data.field_name}
        </label>
        <input
          type="number"
          className="form-control"
          max={data.max}
          min={data.min}
          value={this.state.number || data.initial_value}
          onChange={e => this.handleChange(parseInt(e.target.value, 10))}
        />
      </div>
    );
  }
}

export default Numerical;
