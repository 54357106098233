import { FilterAction, FilterQueryType } from '../model/Model';
import { FilterQueryTypeToFilterAction } from './FilterList';

export function FilterToIndex(rawFilter: FilterQueryType | undefined, fn: (f: FilterAction | undefined) => number | undefined): number | undefined {
	const filter = FilterQueryTypeToFilterAction(rawFilter);
	if (Array.isArray(filter)) {
		for (const localFilter of filter) {
			const res = fn(localFilter);
			if (res !== undefined) return res;
		}
	} else {
		return fn(filter);
	}
}