import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import { InfiniteToolTipEntry } from '../../components_v2/infiniteTooltip/infiniteTooltip';

export function translateToString(key: string, variables?: [string, string][]) {
	const translate = getTranslate(storeLang.getState().localize);
	return (variables ?? []).reduce((acc, [key, replace]) => acc.replaceAll(`{{${key}}}`, replace), translate(key).toString());
}

export function Translate(props: { id: string, options?: Options }): JSX.Element {
	return <>{translateToNode(props.id, props.options)}</>;
}

enum NodeValue {
	Link,
	Bold,
	Return,
	ToolTip
}

type Options = {
	variables?: [string, string][],
	onClickA?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

type Value = string | { open: NodeValue } | { close: true } | { toolTipID: string }

function buildRec(arr: Array<Value>, options: Options | undefined): React.ReactNode {
	if (arr.length == 0) return undefined;
	const value = arr.shift();
	if (value === undefined) return undefined;
	if (typeof value === 'string') return <>{value}{buildRec(arr, options)}</>;
	if ('close' in value) return undefined;
	if ('toolTipID' in value) return undefined;
	let res;
	switch (value.open) {
		case NodeValue.Link: 
			res = <a onClick={options?.onClickA}>{buildRec(arr, options)}</a>;
			break;
		case NodeValue.Bold: 
			res = <b>{buildRec(arr, options)}</b>;
			break;
		case NodeValue.Return:
			res = <br />;
			break;
		case NodeValue.ToolTip: {
			const id = arr.shift();
			if (!id || typeof id === 'string' || !('toolTipID' in id)) return undefined;
			res = <InfiniteToolTipEntry nextTranslate={id.toolTipID}>{buildRec(arr, options)}</InfiniteToolTipEntry>;
			break;
		}
			
	}
	if (arr.length !== 0)
		return <>
			{res}
			{buildRec(arr, options)}
		</>;
	return res;
}

export function translateToNode(key: string, options?: Options): React.ReactNode {
	const str = translateToString(key, options?.variables);
	let res: Array<Value> = [];
	let last = '';
	let toolTipReading = false;
	let toolTipID = '';

	for (const c of str) {
		if (last == '\\') {
			switch (c) {
				case 'n': 
					res.push({ open: NodeValue.Return });
					last = '';
					break;
				default:
					res.push('\\n');
					break;
			}
			continue;
		}
		if (toolTipReading && last === '' && c === '=') {
			last = '=';
			toolTipID = '';
			continue;
		}
		if (last === '=' && toolTipReading) {
			if (c === ' ') {
				res.push({ toolTipID });
				toolTipReading = false;
				last = '';
			} else {
				toolTipID += c;
			}
			continue;
		}
		if (last == '<') {
			switch (c) {
				case 'a':
					res.push({ open: NodeValue.Link });
					last = '';
					break;
				case 'b':
					res.push({ open: NodeValue.Bold });
					last = '';
					break;
				case 't':
					res.push({ open: NodeValue.ToolTip });
					last = '';
					toolTipReading = true;
					break;
				default:
					if (c !== '<' && c !== '>') res.push('<' + c);
					else res.push('<');
					break;
			}
			continue;
		}
		if (c === '>') {
			res.push({ close: true });
			toolTipReading = false;
			last = '';
			continue;
		}
		if (c == '<' || c == '\\') {
			last = c;
			continue;
		}
		res.push(c);
	}
	res = res.reduce((acc: Array<Value>, value) => {
		if (typeof value === 'string') {
			let last = acc.pop();
			if (!last) {
				acc.push(value);
				return acc;
			}
			if (typeof last === 'string') {
				last += value;
				acc.push(last);
				return acc;
			}
			return [...acc, last, value];
		}
		acc.push(value);
		return acc;
	}, []);
	return buildRec(res, options);
}