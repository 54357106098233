import styled from 'styled-components';
import { BlueSidely, BorderColor } from '../../styles/global/css/Utils';
import * as React from 'react';
import { useFunctionState, useKeyPress } from '../../utils/customHooks';
import { translateToNode } from '../../styles/global/translate';
import { RoundProgressBar } from '../../containers_v2/map/modalRight/ModalCalendar';


const InfiniteToolTipDiv = styled.div`
	position: absolute;
	background: white;
	padding: 1em;
	display: flex;
	gap: 1em;
	border: 1px solid ${BorderColor};
	z-index: 1;
	translate: 0 15px;
	color: black;
`;

const INFINITE_TOOLTIP_LOCK_TIMEOUT = 1500;

function checkClickOut(ref: React.RefObject<HTMLDivElement>, clickOut: (() => void) | undefined, isLast: boolean) {
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (isLast && ref.current && !ref.current.contains(event.target)) {
				clickOut?.();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, isLast, clickOut]);
}

const InfiniteToolTipEntryDiv = styled.div`
	color: ${BlueSidely};
	cursor: pointer;
`;

export function InfiniteToolTipEntry(props: { children: React.ReactNode, nextTranslate: string }) {
	const [, setChildInterval] = React.useState<NodeJS.Timeout>();
	const [childTime, setChildTime] = React.useState<number>(0);
	const [childLocked, setChildLocked] = useFunctionState<boolean>(false, ({ newValue }) => {
		if (newValue) {
			setChildInterval(interval => {
				clearInterval(interval);
				return undefined;
			});
		} else {
			setChildTime(0);
		}
		return newValue;
	});
	const [hovered, setHovered] = useFunctionState<boolean>(false, ({ newValue }) => {
		if (newValue) {
			setChildInterval(setInterval(() => setChildTime(time => time + 0.01), INFINITE_TOOLTIP_LOCK_TIMEOUT / 100));
		} else {
			setChildInterval(interval => {
				clearInterval(interval);
				return undefined;
			});
			setChildTime(0);
		}
		return newValue;
	});
	const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();


	return <div>
		<InfiniteToolTipEntryDiv
			onMouseEnter={() => {
				setHovered(true);
				setLocalTimeout(setTimeout(() => {
					setChildLocked(true);
				}, INFINITE_TOOLTIP_LOCK_TIMEOUT));
			}}
			onMouseLeave={() => {
				clearTimeout(localTimeout);
				setHovered(false);
			}}

		>{props.children}
		</InfiniteToolTipEntryDiv>
		{(hovered || childLocked) && <InfiniteToolTip
			time={childTime}
			locked={childLocked}
			close={() => setChildLocked(false)}
		>
			{translateToNode(props.nextTranslate)}
		</InfiniteToolTip>}
	</div>;
}

function InfiniteToolTip(props: {children: React.ReactNode, depth?: number, time?: number, locked?: boolean, close?: () => void}) {
	const ref = React.useRef<HTMLDivElement | null>(null);
	checkClickOut(ref, props.close, true);
	useKeyPress([{ key: 'Escape' }], props.close);

	return <InfiniteToolTipDiv innerRef={React.useCallback(node => ref.current = node, [])}>
		{props.children}
		{!props.locked && props.time !== undefined && <RoundProgressBar value={props.time} size={15}/>}
	</InfiniteToolTipDiv>;
}