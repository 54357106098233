import React, {Component} from 'react';
import {Field} from 'redux-form';
import {get} from 'lodash';
import RenderSelectInput from '../../../../../components/select/RenderSelectInputModal';
import {required} from '../../../../../components/FieldModal';
import {fieldName} from '../services/fieldGetter';
import {Translate} from 'react-localize-redux';

class DropdownList extends Component {
  state = {};

  render() {
    const { data, dynamicField, type } = this.props;
    const opt = get(data, 'details.accepted_value');
    const select = opt ? opt.split(';') : [];

    const options = select.reduce((acc, dt) => {
      acc.push({ label: dt, value: dt });
      return acc;
    }, []);

    const validate = data.is_required ? [required] : [];

    const name = fieldName(data, dynamicField);

    return (
      <Translate>
        {({ translate }) => (
          <Field
            name={name}
            id={`id-${name}`}
            type="text"
            component={RenderSelectInput}
            label={data.is_display_field_name && data.field_name}
            placeholder={translate('select options')}
            requiredStar={data.is_required}
            validate={validate}
            options={options}
            readOnly={type === 'detail'}
          />
        )}
      </Translate>
    );
  }
}

export default DropdownList;
