export interface EmailTemplateData {
	subject: string
	body: string
}
export interface EmailTemplate {
	id: number
	name: string
	data: EmailTemplateData
	public: boolean
	company_id: number
	owner_id: number
	created_at: string
	updated_at: string
	deleted_at: string
}

export interface EmailTemplateSubmit {
	id?: number
	name: string
	public: boolean
	data: EmailTemplateData
}

export interface EmailTemplateVariable {
	first_name: string
	last_name: string
	company_name: string
}

/**
* this enum contains index of colorList
*/
export enum VariableColor {
	NAME = 34,
	FIRST_NAME = 31,
	LAST_NAME = 36,
	COMPANY_NAME = 25,
}