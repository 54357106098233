import * as type from '../actionType/mapOpportunities.type.js';

const initState = {
	companies: [],
	companiesWithOpenOpportunities: [],
	filters: {
		deadline: 'All',
		incharge: '',
		group: ''
	},
	isLoading: false
};

function mapOpportunities(state = initState, action) {
	switch (action.type) {
		case type.FETCH_COMPANIES_BY_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.FETCH_COMPANIES_BY_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				isLoading: false,
				companies: action.payload.companies,
				companiesWithOpenOpportunities: action.payload.companiesInEachCountry
			};
		case type.FETCH_COMPANIES_BY_OPPORTUNITIES_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.SET_FILTER_MAP_DEADLINE:
			return {
				...state,
				filters: {
					...state.filters,
					deadline: action.payload
				}
			};

		case type.SET_FILTER_MAP_INCHARGE:
			return {
				...state,
				filters: {
					...state.filters,
					incharge: action.payload,
					group: ''
				}
			};

		case type.SET_FILTER_MAP_GROUP:
			return {
				...state,
				filters: {
					...state.filters,
					group: action.payload,
					incharge: ''
				}
			};

		default:
			return state;
	}
}

export default mapOpportunities;
