import * as React from 'react';
import { CompanyNote } from '../../containers_v2/client-companies/model/Model';
import NewNote from '../../containers_v2/notes/popup/NewNote';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';
import { LinkedElementId } from '../../containers/companies/components/linkedElements/actions';

function NoteCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  onCreate?: (value: CompanyNote) => void
  id?: LinkedElementId
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, top: '0%' }}
			popupMode={PopupMode.Details}
			content={<NewNote
				onClickOut={() => close()}
				onCreate={(value) => {
					(props.onCreate != null) && props.onCreate(value);
					close();
				}}
				id={props.id}
			/>}
		/>
	);
}

export default NoteCreation;
