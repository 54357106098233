import React, {Component} from 'react';
import Select from 'react-select';
import {customStyles} from '../../../helpers/styleSelect';
import CustomOption from '../../../components/select/CustomOption';

class RenderSelectInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.props.input.onChange(0);
  }

  onBlur = e => {
    const blurHandler = this.props.onBlur;
    if (blurHandler) {
      blurHandler({
        type: 'blur',
        target: {
          value: e.target.value
        }
      });
    }
  };

  initValue = (options, input) => {
    if (input.value) {
      if (typeof input.value === 'object') {
        // return input.onChange(input.value.value);
        return options.find(option => option.value === input.value.value);
      }
      // return input.onChange(input.value);
      return options.find(option => option.value === input.value);
    }
    return null;
  };

  render() {
    const {
      input,
      options,
      id,
      label,
      noLabel,
      placeholder,
      requiredStar,
      isClearable,
      readOnly,
      meta: { touched, error, warning }
    } = this.props;

    return (
      <div>
        <div className="form-group row">
          {!noLabel && (
            <div className="col-md-3 text-right mt-2">
              <label htmlFor={id}>
                {requiredStar === true ? (
                  <span className="text-danger">* </span>
                ) : (
                  ''
                )}
                {label}
              </label>
            </div>
          )}
          <div className="col-md-9">
            <div className="row">
              <div className="col-12" style={{ fontWeight: '300' }}>
                <Select
                  {...input}
                  id={id}
                  // isSearchable={!readOnly}
                  // menuIsOpen={readOnly ? false : undefined}
                  className={` ${touched && error ? 'focus-error-select' : ''}`}
                  isDisabled={readOnly}
                  styles={customStyles}
                  placeholder={placeholder}
                  optionClassName="needsclick"
                  options={options}
                  components={{ Option: CustomOption }}
                  value={this.initValue(options, input)}
                  isClearable={isClearable}
                  onChange={value => {
                    if (value === null) {
                      input.onChange(0);
                    } else {
                      input.onChange(value);
                    }
                  }}
                  onBlur={this.onBlur}
                />
              </div>
              <div className="col-12">
                {touched &&
                  ((error && error !== 'Required' && (
                    <span className="form-error">{error}</span>
                  )) ||
                    (warning && <span className="form-error">{warning}</span>))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RenderSelectInput;
