import React, {Component} from 'react';

// import ComponentHeader from './ComponentHeader';

class FreeText extends Component {
  constructor(props) {
    super(props);
    this.options = {};
  }

  render() {
    const { data } = this.props;
    return (
      <div className="form-group checkbox">
        <label className="control-label" htmlFor={`id-${data.id}`}>
          {data.is_required && <span className="text-danger">* </span>}
          {data.is_display_field_name && data.field_name}
        </label>
        <input
          id={`id-${data.id}`}
          className="form-control"
          type="checkbox"
          checked={data.is_default_checked}
        />
      </div>
    );
  }
}

export default FreeText;
