import * as React from 'react';
import { Checkbox } from '../../components_v2/filterList/style/Style';
import Input from '../../components_v2/input/Input';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { getNonAssortmentProduct, postAssortmentProducts } from './action';
import { Product } from './model';
import { EllipsisOverflowDiv, FlexDiv, InnerContainer, InnerContainerTitle, Link, TABLE_IMAGE_PADDING, TABLE_IMAGE_WIDTH } from './style';
import { Symbol } from '../orders/selectProducts/style/Style';
import { getCurrencySymbol } from '../reports/utils';
import { normalizePrice, validateNumberAndEmpty } from '../globals/FieldFunction';
import { Translate } from 'react-localize-redux';
import { Table, TableSortType } from '../../components_v2/table/Table';
import Pagination from '../../components_v2/pagination/Pagination';
import { TableRow } from '../orders/templateOrders/style/Style';
import styled from 'styled-components';
import { DarkGreySidely, SidelyBlack } from '../../styles/global/css/Utils';
import { translateToString } from '../../styles/global/translate';
import noImage from 'images/no-image.jpg';


const DEFAULT_LIMIT = 20;

export default function AssortmentProductCreation(props: {
  isOpen: boolean
  setIsOpen: (b: boolean) => void
  refresh: () => void
  assortmentId: number
}): JSX.Element {
	const { isOpen, setIsOpen, refresh, assortmentId } = props;

	const [products, setProducts] = React.useState<Product[]>([]);
	const [pagination, setPagination] = React.useState({ step: DEFAULT_LIMIT, offset: 0 });
	const [sort, setSort] = React.useState<TableSortType>();

	React.useEffect(() => {
		getNonAssortmentProduct(assortmentId, pagination.step, pagination.offset, undefined, sort)
			.then(res => setProducts(res.data.map(p => ({
				...p,
				price: parseFloat(p.price ?? '0'),
				recommended_price: parseFloat(p.recommended_price ?? '0')
			}))))
			.catch(console.log);
	}, [assortmentId, pagination, sort]);

	return <Popup
		isOpen={isOpen}
		onClickOut={() => setIsOpen(false)}
		popupStyle={{ animate: true, height: '100vh', top: '0' }}
		popupMode={PopupMode.Extended}
	>
		<PopupCreation
			title={<Translate id='products_assignation' />}
			canValidate={products.some(p => p.checked) && products.filter(p => p.checked).every(p => p.price !== undefined && p.recommended_price !== undefined)}
			onSubmit={() => {
				postAssortmentProducts(assortmentId, products.filter(p => p.checked).map(p => ({ id: p.id, price: p.price, recommended_price: p.recommended_price, distributor_reference: p.distributor_reference })))
					.then(() => {
						refresh();
						setIsOpen(false);
					})
					.catch(console.log);
			}}
			onClose={() => setIsOpen(false)}
		>
			<ProductsAssignation
				products={products}
				setProducts={setProducts}
				setSort={setSort}
				sort={sort}
				setPagination={setPagination}
				pagination={pagination}
				limit={DEFAULT_LIMIT}
			/>
		</PopupCreation>
	</Popup>;
}

export function ProductsAssignation(props: {
  products: Product[]
  setProducts: (products: Product[]) => void
  setSort: (s?: TableSortType) => void
  sort?: TableSortType
  setPagination: (pagination: any) => void
  pagination: any
  limit: number
}): JSX.Element {
	const { products, setProducts, sort, setSort, pagination, setPagination, limit } = props;

	const all_checked = products.every(p => p.checked);

	const columns = React.useMemo(() => ([
		{
			id: 'select',
			Header: <Checkbox isActive={all_checked} onClick={() => {
				if (all_checked) {
					setProducts(products.map(p => ({ ...p, checked: false })));
				} else {
					setProducts(products.map(p => ({ ...p, checked: true })));
				}
			}} />,
			accessor: (row: Product) => {
				return (
					<Checkbox isActive={row.checked ?? false} onClick={() => {
						const index = products.findIndex(p => p.id == row.id);
						if (index >= 0) {
							products[index].checked = !(row.checked ?? false);
							setProducts([...products]);
						}
					}} />
				);
			},
			width: 40,
			disableSortBy: true,
			unresizeable: true
		},
		{
			id: 'photo',
			Header: ' ',
			accessor: (row: Product) => <Link centered background={row.photos_url_id?.[0]?.[0] ?? noImage }/>,
			disableSortBy: true,
			width: TABLE_IMAGE_WIDTH,
			padding:TABLE_IMAGE_PADDING.DEFAULT,
			unresizeable: true
		},
		{
			id: 'lower(p.name)',
			Header: <EllipsisToolTip><Translate id='name' /></EllipsisToolTip>,
			accessor: (row: Product) => <>
				<TableRow fontWeight="500" color={DarkGreySidely} cursor="pointer">
					{row.name ?? '-'}
				</TableRow>
			</>,
			width: undefined
		},
		{
			id: 'lower(reference)',
			Header: <EllipsisToolTip><Translate id='reference' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow>{row.reference ?? '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'lower(c.name)',
			Header: <EllipsisToolTip><Translate id='category' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow>{row.category_name ?? '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'distributor_reference',
			Header: <EllipsisToolTip><Translate id='product.distributor_reference' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow>
				<div style={{
					display: 'flex',
					alignItems: 'center'
				}}>
					<Input
						placeholder='code'
						inputStyle={{ width: '150px', padding: '5px 15px' }}
						key={`reference_${row.id}`}
						name={`reference_${row.id}`}
						value={row.distributor_reference}
						type='text'
						onChange={(newValue) => {
							const index = products.findIndex(ap => ap.id == row.id);
							if (index >= 0) {
								products[index].distributor_reference = newValue;
								setProducts([...products]);
							}
						}}
					/>
				</div>
			</TableRow>,
			width: 175
		},
		{
			id: 'price',
			Header: <EllipsisToolTip><Translate id='price' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow>
				<div style={{
					display: 'flex',
					alignItems: 'center'
				}}>
					<Input
						inputStyle={{ textAlign: true, width: '50px', marginRight: '4px' }}
						key={`price_${row.id}`}
						name={`price_${row.id}`}
						type='text'
						value={row.price}
						onChange={(newValue) => {
							const parsed = parseFloat(newValue);
							if (isNaN(parsed)) return;
							const index = products.findIndex(ap => ap.id == row.id);
							if (index >= 0) {
								products[index].price = parsed;
								setProducts([...products]);
							}
						}}
						validate={[validateNumberAndEmpty]}
						normalize={[normalizePrice]}
					/>
					<Symbol>{getCurrencySymbol()}</Symbol>
				</div>
			</TableRow>,
			width: 100
		},
		{
			id: 'recommended_price',
			Header: <EllipsisToolTip left={-30}><Translate id='recommended price' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow>
				<div style={{
					display: 'flex',
					alignItems: 'center'
				}}>
					<Input
						inputStyle={{ textAlign: true, width: '50px', marginRight: '4px' }}
						key={`recommended_price_${row.id}`}
						name={`recommended_price_${row.id}`}
						type='text'
						value={row.recommended_price}
						onChange={(newValue) => {
							const parsed = parseFloat(newValue);
							if (isNaN(parsed)) return;
							const index = products.findIndex(ap => ap.id == row.id);
							if (index >= 0) {
								products[index].recommended_price = parsed;
								setProducts([...products]);
							}
						}}
						validate={[validateNumberAndEmpty]}
						normalize={[normalizePrice]}
					/>
					<Symbol>{getCurrencySymbol()}</Symbol>
				</div>
			</TableRow>,
			width: 100
		}
	]), [products]);

	return <InnerContainer>
		<FlexDiv justify="space-between">
			<InnerContainerTitle>
				<Translate id='products' />
			</InnerContainerTitle>
		</FlexDiv>
		<Table
			height={`calc(40px * ${products.length + 1})`}
			columns={columns}
			data={products}
			onSort={sort => setSort(sort[0])}
			initialSortBy={sort}
		/>
		{(products[0]?.count ?? 0) > limit &&
			<Pagination label={translateToString('products')} amount={products[0]?.count} steps={[limit, 25, 50]} onChange={(value) => {
				setPagination({ ...pagination, ...value });
			}} />
		}
	</InnerContainer>;
}

const ToolTip = styled.div<{ left?: number }>`
	position: absolute;
	transform: translate(calc(-10px + ${p => p.left ?? 0}px),-50px);
	background-color: ${SidelyBlack};
	color: white;
	padding: 5px 10px;
	border-radius: 10px;
	font-weight: 400;
`;

export function EllipsisToolTip(props: { children?, content?, left?: number }) {
	return <EllipsisOverflowDiv>
		{props.children ?? props.content}
		<ToolTip className='hide' left={props.left}>{props.children ?? props.content}</ToolTip>
	</EllipsisOverflowDiv>;
}
