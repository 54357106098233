import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const FETCH_DATA_TYPE_REQUEST = 'FETCH_DATA_TYPE_REQUEST';
export const FETCH_DATA_TYPE_SUCCESS = 'FETCH_DATA_TYPE_SUCCESS';
export const FETCH_DATA_TYPE_ERROR = 'FETCH_DATA_TYPE_ERROR';

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';

export const DETAIL_DATA_REQUEST = 'DETAIL_DATA_REQUEST';
export const DETAIL_DATA_SUCCESS = 'DETAIL_DATA_SUCCESS';
export const DETAIL_DATA_ERROR = 'DETAIL_DATA_ERROR';

export const CREATE_DATA_REQUEST = 'CREATE_DATA_REQUEST';
export const CREATE_DATA_SUCCESS = 'CREATE_DATA_SUCCESS';
export const CREATE_DATA_ERROR = 'CREATE_DATA_ERROR';

export const CONVERT_DATA_REQUEST = 'CONVERT_DATA_REQUEST';
export const CONVERT_DATA_SUCCESS = 'CONVERT_DATA_SUCCESS';
export const CONVERT_DATA_ERROR = 'CONVERT_DATA_ERROR';

export const UPDATE_DATA_REQUEST = 'UPDATE_DATA_REQUEST';
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_ERROR = 'UPDATE_DATA_ERROR';

export const DELETE_DATA_REQUEST = 'UPDATE_DATA_REQUEST';
export const DELETE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const DELETE_DATA_ERROR = 'UPDATE_DATA_ERROR';

export const SORT_DATA = 'SORT_DATA';

export const GET_TRADESHOWS = 'GET_TRADESHOWS';
export const GET_MERCHANDISINGS = 'GET_MERCHANDISINGS';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

export const DELETE_SALES_OPERATIONS_REQUEST =
  'DELETE_SALES_OPERATIONS_REQUEST';

export const FETCH_FILES_REQUEST = 'FETCH_FILES_REQUEST';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const FETCH_FILES_ERROR = 'FETCH_FILES_ERROR';

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const CREATE_FILE_UPLOAD_REQUEST = 'CREATE_FILE_UPLOAD_REQUEST';
export const CREATE_FILE_UPLOAD_SUCCESS = 'CREATE_FILE_UPLOAD_SUCCESS';
export const CREATE_FILE_UPLOAD_ERROR = 'CREATE_FILE_UPLOAD_ERROR';

// CREATE LINKED ELEMENTS
// GET LINKED ELEMENTS
// MODIFY LINKED ELEMENTS

const PATH = `${URL_API}/api`;

export function fetchData(data, subPath) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/salesOperations/?type=${subPath}`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_DATA_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_DATA_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_DATA_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/salesOperations/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_DATA_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_DATA_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_DATA_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createData(data, subPath, redirect, companyId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/salesOperations/${subPath}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_DATA_SUCCESS,
						payload: response.data
					});
					console.log(redirect);

					if (redirect !== null) {
						console.log(response.data);

						window.location.href = `/${redirect}/detail/${companyId}`;
					} else {
						window.location.href = `/salesOperations/${subPath}/detail/${
							response.data.data.id
						}`;
					}
				},
				error => {
					dispatch({
						type: CREATE_DATA_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_DATA_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id, subPath) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/salesOperations/${subPath}/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_DATA_SUCCESS,
					payload: response.data
				});
				// swal.fire('Success!', 'Data updated!', 'success').then(() => {
				window.location.href = `/salesOperations/${subPath}/detail/${id}`;
				// });
			},
			error => {
				dispatch({
					type: UPDATE_DATA_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteData(id, isRedirect, path, subPath) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/salesOperations/${id}`
		}).then(
			() => {
				// dispatch({ type: DELETE_COMPANIES_SUCCESS, payload: response.data });
				// swal.fire('Deleted!', 'Data has been deleted.', 'success').then(() => {
				if (isRedirect) {
					window.location.href = `/${path}`;
				} else {
					dispatch(fetchData(null, subPath));
				}
				// });
			},
			error => {
				// dispatch({ type: DELETE_COMPANIES_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteFile(id, subPath, idData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: DELETE_FILE_REQUEST,
			id
		});
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/salesOperationFiles/${id}`,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			dispatch({
				type: DELETE_FILE_SUCCESS
			});
			window.location.href = `/salesOperations/${subPath}/edit/${idData}`;
		} catch (error) {
			dispatch({
				type: DELETE_FILE_ERROR
			});
		}
	};
}

// export const searchData = data => async dispatch => {
//   dispatch({
//     type: SEARCH_COMPANIES,
//     payload: data
//   });
// };

export const sortData = data => async dispatch => {
	dispatch({
		type: SORT_DATA,
		payload: data
	});
};

export const getTradeshows = data => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(
		`${PATH}/salesOperations/?type=tradeshows&${data}`
	);
	dispatch({
		type: GET_TRADESHOWS,
		payload: res.data
	});
};

export const getMerchandisings = data => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(
		`${PATH}/salesOperations/?type=merchandisings&${data}`
	);
	dispatch({
		type: GET_MERCHANDISINGS,
		payload: res.data
	});
};

export function importExcel(data, subPath) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: IMPORT_EXCEL_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/salesOperations/${subPath}/importExcel`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: IMPORT_EXCEL_SUCCESS,
						payload: response.data
					});
					window.location.href = `/salesOperations/${subPath}`;
				},
				error => {
					dispatch({
						type: IMPORT_EXCEL_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: IMPORT_EXCEL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function exportExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/clientCompanies/exportExcel`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Companies.xlsx');
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				// dispatch({ type: FETCH_COMPANIES_ERROR, error });

				throw error;
			});
}

export function exportExcelData(data, salesData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		try {
			const response = await axios({
				method: 'get',
				responseType: 'blob',
				url: `${PATH}/salesOperations/${salesData}/exportExcel`,
				params: data
			});
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${salesData}.xlsx`);
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function exportCsv(data, salesData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		try {
			const response = await axios({
				method: 'get',
				responseType: 'blob',
				url: `${PATH}/salesOperations/${salesData}/exportCsv`,
				params: data
			});
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${salesData}.csv`);
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function bulkDelete(data, urlpath) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: DELETE_SALES_OPERATIONS_REQUEST,
			loading: true
		});
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/salesOperations`,
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json'
				},
				data
			});
			// dispatch(fetchData());
			window.location.href = `/salesOperations/${urlpath}`;
		} catch (error) {
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}