import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_FOUNDATION } from '../../config/keys';
import { ShelfAuditFieldTemplate } from './Model';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

export async function getShelfAuditFieldTemplate(shelfAuditFieldTemplateId: number): Promise<ShelfAuditFieldTemplate[]> {
	const param = {
		shelf_audit_template_id: shelfAuditFieldTemplateId
	};

	try {
		const result = await axios.get<ShelfAuditFieldTemplate[]>(`${URL_FOUNDATION}/api/shelf-audit/field-template`, { params: param });
		return result.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
