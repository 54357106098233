import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import PageLoader from '../pageLoader/PageLoader';
import storeLang from '../../helpers/storeLang';
import { ImageThumbnail, ImageResult } from './model/Model';
import { AddBlock, AddImage, Container, FileInput } from './style/InputImageStyle';
import imageCompression from 'browser-image-compression';

export enum InputErrorMode {
  LIMIT_REACHED
}

export interface InputError {
  type: InputErrorMode
  message: string
}

type InputImageProps<IsProduct extends boolean=false> = {
	isProduct?: IsProduct
	onChange?: (value: IsProduct extends true ? ImageThumbnail : ImageResult) => void
	isLoading?: boolean
	onError?: (value: InputError) => void
	disableShowImage?: boolean
	limitMo?: number
	size?: number
	backgroundColor?: string,
	compress?: boolean
	url?: string
}

function InputImage<IsProduct extends boolean=false>(props: InputImageProps<IsProduct>): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const [image, setImage] = React.useState<string>(props.url ?? '');
	const reader = new FileReader();
	const [imageIsLoading, setImageIsLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		setImage(props.url ?? '');
	}, [props.url]);

	const hashStr = async(array: ArrayBuffer) => {
		const hashBuffer = await crypto.subtle.digest('SHA-256', array);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		return (hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''));
	};

	return (
		<Container size={props.size ?? 30} >
			<AddBlock backoungroundColor={props.backgroundColor} noBackground={props.isLoading || imageIsLoading}>
				{(props.isLoading || imageIsLoading)
					? <PageLoader />
					: <AddImage src={image} style={{ display: image.length > 0 ? 'initial' : 'none' }} />
				}
			</AddBlock>
			<FileInput name='image_input' type='file' accept='.jpeg, .jpg, .png' autoComplete='off' onInput={async(e) => {
				const files = e.currentTarget.files;
				if (!files || !files[0]) return;
				let uploadedFile: File = files[0];
				if (uploadedFile.type.startsWith('image')) {
					setImageIsLoading(true);
					if (props.compress) {
						try {
							uploadedFile = await imageCompression(uploadedFile, { maxSizeMB: props.limitMo ?? 1 });
						} catch (e) {
							console.error(e);
							props.onError?.({
								type: InputErrorMode.LIMIT_REACHED,
								message: e
							});
							return;
						}
					}
					if (props.limitMo && parseFloat(((uploadedFile.size / 1024) / 1024).toFixed(4)) > props.limitMo) {
						props.onError?.({
							type: InputErrorMode.LIMIT_REACHED,
							message: translate('global.fire.size_exceeded').toString().replace('{{SIZE}}', props.limitMo.toString())
						});
						return;
					}
					
					const img = new Image();
					reader.onload = async(evt) => {
						if (!evt || !evt.target || !evt.target.result || typeof evt.target.result != 'string') return;
						img.src = evt.target.result;
						img.onload = () => {
							const canvas = document.createElement('canvas');
							canvas.width = img.width;
							canvas.height = img.height;
							canvas.getContext('2d')?.drawImage(img, 0, 0);
							const webpDataURL = canvas.toDataURL('image/webp');
							const tab = webpDataURL.split(',');
							const contentType = tab[0];
							const contents = tab[1];
							!props.disableShowImage && setImage(webpDataURL);
							canvas.toBlob(async(blob) => {
								if (!blob) return;
								const blobArray = await blob.arrayBuffer();
								const hash = await hashStr(blobArray);
								const imageWebp = new File([blob], hash + '.webp', { type: blob.type });
								const thumbnail = await imageCompression(imageWebp, { maxWidthOrHeight: 256 });
								setImageIsLoading(false);
								if (props.onChange != null) 
								{
									if (props.isProduct)
										props.onChange({
											original: imageWebp,
											thumbnail: thumbnail,
											url: webpDataURL
										} as IsProduct extends true ? ImageThumbnail : ImageResult);
									else
										props.onChange({
											file: imageWebp,
											content: contents,
											contentType: contentType,
											url: webpDataURL
										} as IsProduct extends true ? ImageThumbnail : ImageResult);
								}
							}, 'image/webp');
						};
					};
					reader.readAsDataURL(uploadedFile);
				}
			}} />
		</Container>
	);
}

export default InputImage;
