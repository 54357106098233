import { SELECT_MENU } from '../actions/product_menu.action';

const initState = {
	selectedMenu: 'products'
};

export default function pageMenuReducer(state = initState, action) {
	switch (action.type) {
		case SELECT_MENU:
			return {
				...state,
				selectedMenu: action.payload
			};

		default:
			return state;
	}
}
