import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import {Translate} from 'react-localize-redux'

class PropCamera extends Component {
  state = {};

  render() {
    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Camera</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize
            type="text"
            className="form-control"
            defaultValue={this.props.element.field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'field_name',
              'value'
            )}
          />
        </div>

        <div className="custom-control custom-checkbox">
          <input
            id="is-display-field-name"
            className="custom-control-input"
            type="checkbox"
            defaultChecked={this.props.element.is_display_field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'is_display_field_name',
              'checked'
            )}
          />
          <label
            className="custom-control-label"
            htmlFor="is-display-field-name"
          >
            Display field name
          </label>
        </div>

        <div className="form-group  mt-3">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <TextAreaAutosize
            type="text"
            className="form-control"
            disabled={this.props.isEdit}
            defaultValue={this.props.element.system_field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'system_field_name',
              'value'
            )}
          />
        </div>

        <div className="form-group mt-3">
          <div className="custom-control custom-checkbox">
            <input
              id="allow-multiple-pictures"
              className="custom-control-input"
              type="checkbox"
              disabled={this.props.isEdit}
              defaultChecked={this.props.element.allow_multiple_pictures}
              onBlur={this.props.updateElement.bind(this)}
              onChange={this.props.editElementProp.bind(
                this,
                'allow_multiple_pictures',
                'checked'
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="allow-multiple-pictures"
            >
              Allow multiple pictures
            </label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              id="allow-picture-upload"
              className="custom-control-input"
              type="checkbox"
              disabled={this.props.isEdit}
              defaultChecked={this.props.element.allow_picture_upload}
              onBlur={this.props.updateElement.bind(this)}
              onChange={this.props.editElementProp.bind(
                this,
                'allow_picture_upload',
                'checked'
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="allow-picture-upload"
            >
              Allow picture upload
            </label>
          </div>
        </div>

        <div className="form-group p-0 my-2">
          <label className="control-label" htmlFor="">
            Validation
          </label>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              id="is-required"
              className="custom-control-input"
              type="checkbox"
              disabled={this.props.isEdit}
              defaultChecked={this.props.element.is_required}
              onBlur={this.props.updateElement.bind(this)}
              onChange={this.props.editElementProp.bind(
                this,
                'is_required',
                'checked'
              )}
            />
            <label className="custom-control-label" htmlFor="is-required">
              <Translate id="Field required" />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default PropCamera;
