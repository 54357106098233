import * as React from 'react';
import styled from 'styled-components';
import { BlueSidely, GreySidely, LightGreySidely3 } from '../../../styles/global/css/Utils';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { Fields, Form, ImageTypeMap } from './type';
import { Checkbox } from '@mui/material';
import DragAndDropImage from 'images/icons/drag_and_drop_dots.svg';
import { addFieldToJsonFields, changeFieldId, getEnabledFields, hasFieldId, isHistory, setHistory, setNewFieldData, setValidator, swapTwoElementsInLayout } from './formUtils';
import Input from '../../../components_v2/input/Input';
import { FlexDiv } from '../../products/style';
import Popup from '../../../components_v2/popup/Popup';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import { translateToString } from '../../../styles/global/translate';
import { DeleteDot } from '../../../styles/global/css/Dot';
import Add from '../../../components_v2/add/Add';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { FieldType } from 'bindings/forms/FieldType';
import { TitleAndChild } from '../../client-companies/popup/Detail';
import { ContentWrapper } from './FormCreation';
import { Translate } from 'react-localize-redux';
import ShelfAuditIcon from 'images/enrichedForm/shelfAudit.svg';
import BeerFormIcon from 'images/enrichedForm/beerForm.svg';
import OrdersIcon from 'images/enrichedForm/orders.svg';
import SettingIcon from 'images/icon/setting.png';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import Search from 'antd/lib/input/Search';
import { Layout } from '../jsonValidator';
import { assert } from '../../../utils/assert';
import _ from 'lodash';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


export function ListElementFromLayout() { 
	return <div>ListElements</div>;
}

const Box = styled.div`

    display: inline-block;
    box-shadow: 0px 0px 8px 0px rgba(100,100,100,0.15);
	width: 319.605px;
	border-radius:5px;
	height: 425px;
	margin-left: 42px;
	flex-shrink: 0;
	margin-bottom: 50px;
`;

const Icon = styled.div`
    height: 28%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: ${BlueSidely};
    `;

const Title = styled.div`
    height: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
	color: var(--table_black, #323C47);
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

export const DescriptiveFieldTitle = styled.div`
  font-size: 14px;
  color: #333333;
  font-weight: 700;
`;

export const DescriptiveFieldDescription = styled.div`
  font-size: 13px;
  color: #4C5862;
  font-weight: 400;
`;

const SubTitle = styled.div`
    height: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
	color: var(--table_black, #323C47);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Description = styled.div`
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
	color: var(--table_black, #4C5862);
	text-align: center;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 210.4%; /* 29.456px */
`;

const CenteringBoxes = styled.div`
    text-align: center;
`;

const RightPartField = styled.div<{ disabled?: boolean }>`
    text-align: right;
    display: inline-block;
    width: 4%;
    cursor: cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};;
`;

const LeftPartField = styled.div`
    text-align: left;
    display: inline-block;
    width: 95%;
`;

export const FieldTitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    padding-top: 9px;
`;

export const FieldDescription = styled.div`
    font-size: 15px;
    line-height: 2;
    font-weight: 300
`;

const SwitchableFieldsList = styled.div`
    display: inline-block;
    width: 40%;
	height: calc(100vh - 280px);
	overflow: auto;
    margin-bottom: 20px;
    vertical-align: top;
`;

const DraggingField = styled.div<{ greyed?: boolean }>`
    padding: 10px;
    border: 1px solid ${GreySidely};
    background-color: ${p => p.greyed ? LightGreySidely3 : 'white'};
    cursor: ${p => p.greyed ? 'not-allowed' : 'grab'};
    width: 100%;
    border-radius: 5px;
    font-weight: 300;
    padding-left: 10px;
    font-size: 14px;
`;

const SwitchableFields = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow: auto;
    align-items: left;
`;

const DragBox = styled.div`
    text-align: left;
    display: inline-block;
	overflow: auto;
    width: 40%;
    height: calc(100vh - 280px);
`;

const DandDDots = styled.div`
	width: 22px;
	height: 45px;
	// background-image: url('${DragAndDropImage}');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 45%;
`;

const NoDDots = styled.div`
    width: 22px;
`;

const Label = styled.div<{ white?: boolean }>`
	width: 100%;
	font-size: 14px;
	text-align: left;
	flex-shrink: 1;
	padding: 10px;
	user-select: none
`;

const UserInput = styled.div `
    width: 100%;
    font-size: 14px;
    text-align: left;
    flex-shrink: 1;
    padding: 10px;
    user-select: none
`;

const BoxTitle = styled.div`
color: #333333;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const BoxDescription = styled.div`
color: var(--table_black, #4C5862);
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 210.4%; /* 29.456px */
`;

const FeaturedText = styled.div`
color: #333333;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;



const ClassicInput = {
	width: '100%', containerWidth: '100%', height: '50px'
};

const MetaDataInput = {
	width: '75%', containerWidth: '75%', height: '50px'
};



export type FieldProps = {
    field: Fields,
    setFieldList: (fieldList: Fields[]) => void,
    fieldList: Fields[],
    setForm: (form: Form) => void,
    forceUpdate?: () => void
    form: Form,
    currentPage?: number,
	draggables?: Fields[],
	template: string,
}

export function TemplateBox(props: { title: string, category: string, template: string, description: string, image?: string, onUseClick: (template: string) => void }) {
	return <>
		<Box>
			<Icon><img height={'60px'} width={'60px'} src={props.image} alt="" /></Icon>
			<Title>{props.title}</Title>
			<SubTitle>{props.category}</SubTitle>
			<Description>{props.description}</Description>
			{/* <DefaultButton buttonStyle={ButtonStyle.White}><Translate id='form_builder.template_box.view_example' /></DefaultButton> */}
			<CenteringBoxes><DefaultButton onClick={() => props.onUseClick(props.template)}><Translate id='form_builder.template_box.use_template' /></DefaultButton></CenteringBoxes>
		</Box>
	</>;

}

export function AvailableTemplates(props: {onUseClick: (template: string) => void}): JSX.Element { 
	return <>
		<ContentWrapper>
			<BoxTitle><Translate id='form_builder.welcome' /></BoxTitle>
			<BoxDescription><Translate id='form_builder.welcome_text' /></BoxDescription>
			<br />
			<FeaturedText><Translate id='form_builder.featured' /></FeaturedText>
			<br />
			<TemplateBox template='shelfaudit' onUseClick={props.onUseClick} title={translateToString('form_builder.shelf_audit.name')} category={translateToString('form_builder.shelf_audit.category')} description={translateToString('form_builder.shelf_audit.description')} image={ShelfAuditIcon} />
			<TemplateBox template='order' onUseClick={props.onUseClick} title={translateToString('form_builder.order.name')} category={translateToString('form_builder.order.category')} description={translateToString('form_builder.order.description')} image={OrdersIcon} />
			<TemplateBox template='beer' onUseClick={props.onUseClick} title={translateToString('form_builder.beer.name')} category={translateToString('form_builder.beer.category')} description={translateToString('form_builder.beer.description')} image={BeerFormIcon} />
			<TemplateBox template='chr' onUseClick={props.onUseClick} title={translateToString('form_builder.chr.name')} category={translateToString('form_builder.chr.category')} description={translateToString('form_builder.chr.description')} image={ShelfAuditIcon} />
			<TemplateBox template='chrpresence' onUseClick={props.onUseClick} title={translateToString('form_builder.chr_presence.name')} category={translateToString('form_builder.chr_presence.category')} description={translateToString('form_builder.chr_presence.description')} image={ShelfAuditIcon} />
		</ContentWrapper>
	</>;
}

export function ClassicField(props: {field: Fields}): JSX.Element {
	return <>
		<FieldTitle>{props.field.display_name}</FieldTitle> <br />
	</>;
}

export function DescriptiveField(props: FieldProps): JSX.Element {
	const [showChecked, setShowChecked] = React.useState<boolean>(hasFieldId(props.form, props.field.name));
	const [secondChecked, setSecondChecked] = React.useState<boolean>(false);
	const [data, setData] = React.useState<number | undefined>(typeof props.field.field_data == 'number' ? props.field.field_data : 100);

	React.useEffect(() => {
		if (showChecked) {
			props.setForm(changeFieldId(props.form, props.field.name, props.field.field_id));
		}
		else {
			props.setForm(changeFieldId(props.form, props.field.name, 0));
		}

		if (secondChecked && props.field.validator) {
			props.setForm(setValidator(props.form, props.field.name, props.field.validator));
		}
		else {
			props.setForm(setValidator(props.form, props.field.name, undefined));
		}
    
	}, [showChecked, secondChecked]);

	React.useEffect(() => {
		if (props.fieldList) {
			if (data && Number(data) && Number(data) > 0) {
				props.setFieldList(setNewFieldData(props.fieldList, props.field.name, data as number));
			} else {
				props.setFieldList(setNewFieldData(props.fieldList, props.field.name, 100));
			}
		}
	}, [data]);

	return <>
		<DescriptiveFieldTitle>{props.field.descriptionTitle}</DescriptiveFieldTitle>
		<DescriptiveFieldDescription>{props.field.description}</DescriptiveFieldDescription>
		<FieldTitle><Checkbox checked={showChecked} onChange={checkbox => setShowChecked(checkbox.target.checked)} /> Activer le/la {props.field.display_name}</FieldTitle>
		{data != undefined && showChecked && props.field.name == 'check-in' && <>
			Rayon en mètres:
			<Input value={data as number} onChange={d => setData(Number(d) ? Number(d) : 1)} name='rayon' type='number' pattern='[0-9]' min={1} />
		</>}
		{showChecked && props.field.validator && <FieldTitle><Checkbox onChange={checkbox => setSecondChecked(checkbox.target.checked)} checked={secondChecked} />Rendre obligatoire le {props.field.display_name}</FieldTitle>}

	</>;
}

export function ShowField(props: FieldProps): JSX.Element {
	if (props.field.description) {
		return <>
			<DescriptiveField template={props.template} setFieldList={props.setFieldList} fieldList={props.fieldList} form={props.form} setForm={props.setForm} field={props.field} />
		</>;
	}
	else {
		return <></>;
	}
}

export function SingleField(props: FieldProps): JSX.Element {
	const [checked, setChecked] = React.useState<boolean>(hasFieldId(props.form, props.field.name));

	React.useEffect(() => {
		if (checked) {
			props.setForm(changeFieldId(props.form, props.field.name, props.field.field_id));
		}
		else {
			props.setForm(changeFieldId(props.form, props.field.name, 0));
		}
		props.forceUpdate && props.forceUpdate();
	}, [checked, props.field.field_id]);

	return <>
		<Checkbox onChange={checkbox =>
		{
			if (checkbox.target.checked) {
				props.setForm(changeFieldId(props.form, props.field.name, props.field.field_id));
			}
			else {
				props.setForm(changeFieldId(props.form, props.field.name, 0));
			}
			setChecked(checkbox.target.checked);
		}} checked={checked} />
		<ClassicField field={props.field} />
	</>;
}


export function ListDraggableFields(props: FieldProps): JSX.Element {
	const [, updateState] = React.useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);
	const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);

	const [currentFieldEditing, setCurrentFieldEditing] = React.useState<Fields>();
	const [currentMetadataEditing, setCurrentMetadataEditing] = React.useState<string>();

	const [createFieldPopup, setCreateFieldPopup] = React.useState<boolean>(false);
	const [createFieldName, setCreateFieldName] = React.useState<string>();
	const [createFieldType, setCreateFieldType] = React.useState<string>();
	const [createFieldMandatory, setCreateFieldMandatory] = React.useState<boolean>();
	const [createFieldMetadata, setCreateFieldMetadata] = React.useState<string[]>();
	const [createMetadataName, setCreateMetadataName] = React.useState<string>();
	const [filterFields, setFilterFields] = React.useState<string>('');

	const FieldTypeList: DropdownData[] = [
		{ value: 'Text', label: translateToString('form_builder.types.Text') },
		{ value: 'Number', label: translateToString('form_builder.types.Number') },
		{ value: 'Date', label: translateToString('form_builder.types.Date') },
		{ value: 'Boolean', label: translateToString('form_builder.types.Boolean') },
		{ value: 'Select', label: translateToString('form_builder.types.Select') },
		{ value: 'Multiselect', label: translateToString('form_builder.types.Multiselect') },
		{ value: 'File', label: translateToString('form_builder.types.File') },
		{ value: 'Image', label: translateToString('form_builder.types.Image') },
		{ value: 'Url', label: translateToString('form_builder.types.Url') },
		{ value: 'Email', label: translateToString('form_builder.types.Email') },
		{ value: 'Phone', label: translateToString('form_builder.types.Phone') },
		{ value: 'Address', label: translateToString('form_builder.types.Address') },
		{ value: 'Location', label: translateToString('form_builder.types.Location') },
		{ value: 'User', label: translateToString('form_builder.types.User') },
		{ value: 'Integer', label: translateToString('form_builder.types.Integer') },
		{ value: 'TextArea', label: translateToString('form_builder.types.TextArea') },
	];

	const onDragEnd = ({ source, destination }) => {
		console.log('dnd swap:', source, destination);
		if (props.currentPage) {
			const tmp = swapTwoElementsInLayout(props.form, props.currentPage, source.index, destination.index);
			props.setForm(tmp);
		}
	};


	return <>
		<CenteringBoxes>
			<SwitchableFieldsList>
				{/* Search bar */}
				<Search name='name' value={filterFields} onChange={v => setFilterFields(v.target.value)} type="text"/>
				{props.draggables && Object.entries(props.draggables.reduce((acc: { [key: string]: Fields[] }, field) => {
					if (field.category) {
						if (!acc[field.category]) acc[field.category] = [];
						acc[field.category].push(field);
					}
					return acc;
				}, {}))
					.map((category) => { // For each category
						return <>
							<TitleAndChild localCollapseStyle={{ width: '100%' }} defaultOpen={true} title={category[0]}>
								<div style={{ width: '100%' }}>
									{
										category[1].filter(f => f.display_name.toLocaleLowerCase().includes(filterFields.toLocaleLowerCase())).map((field) => {
											return <>
												<SwitchableFields>
													<SingleField template={props.template} setFieldList={props.setFieldList} forceUpdate={forceUpdate} fieldList={props.fieldList} form={props.form} setForm={props.setForm} field={field} />
												</SwitchableFields>
											</>;
										})
									}
								</div>
							</TitleAndChild>
						</>;
					})
				}
				<FlexDiv style={{ marginLeft: '8px' }}>
					<Add onClick={() => {setCreateFieldPopup(true);}} /> <Label><Translate id='form_builder.create_field'/></Label>
				</FlexDiv>
			</SwitchableFieldsList>
			<DragBox>
				{/* <DragDropContext onDragEnd={onDragEnd}> */}

				{/* <Droppable droppableId="droppable"> */}
				{/* {(provided) => ( */}
				{/* <div
					{...provided.droppableProps}
					ref={provided.innerRef}
				> */}
				{props.currentPage && getEnabledFields(props.form[props.currentPage], props.fieldList).filter(f => f != undefined).filter(field => field.inDraggable != false).map((item, index) => (
					// <Draggable isDragDisabled={item.mandatory} key={item.name} draggableId={item.name} index={index}>
					// {(provided) => (
					<div
						key={index}
						// ref={provided.innerRef}
						// {...provided.draggableProps}
						// {...provided.dragHandleProps}
					>
						<FlexDiv style={{ paddingTop: '5px' }}>
							{!item.mandatory && <DandDDots />}
							{item.mandatory && <NoDDots />}
							<DraggingField greyed={item.mandatory}>
								<LeftPartField>
									<img style={{ paddingRight: '20px', height: '25px' }} src={ImageTypeMap.find(t => item.type_ === t.type)?.icon} alt="" />
									{item.display_name}
								</LeftPartField>
								<RightPartField disabled={item.mandatory} onClick={() => {
									if (!item.mandatory) {
										setCurrentFieldEditing(item); setIsPopupOpen(true);
									}
								}}><img height={'25px'} src={SettingIcon} alt="" /></RightPartField>
							</DraggingField>
						</FlexDiv>
					</div>
					// 	)}
					// </Draggable>
				))}
				{/* </div> */}
				{/* )} */}
				{/* </Droppable> */}
				{/* </DragDropContext> */}
			</DragBox>
		</CenteringBoxes>

		<>
			<Popup popupMode={PopupMode.Details}
				isOpen={createFieldPopup}
				onClickOut={() => setCreateFieldPopup(false)}
				content={
					<>
						<div style={{ height: '100%', overflow: 'auto' }}>
							<Label>{translateToString('name')}</Label>
							<UserInput> <Input value={createFieldName} onChange={setCreateFieldName} inputStyle={ClassicInput} name='name' type="dropdown"></Input></UserInput>

							<Label>Type</Label>
							<UserInput> <Dropdown datalist={FieldTypeList} selectedValue={FieldTypeList.find(v => v.value === createFieldType)} onChange={value => setCreateFieldType(value.value)} inputStyle={ClassicInput} name='name' type="dropdown"></Dropdown></UserInput>
                    
							<Label>Validation</Label>
							<FlexDiv>
								<Checkbox checked={createFieldMandatory} onChange={checkbox => setCreateFieldMandatory(checkbox.target.checked)} />
								<Label>Champ est obligatoire</Label>
							</FlexDiv>
							{(createFieldType === 'Select' || createFieldType === 'Multiselect') &&
                        <Label>Champs disponibles</Label>
							}

							<table align="center" width={'80%'}>
								{createFieldMetadata && createFieldMetadata.map(one => {
									return <>
										<tr>
											<Label>{one}</Label>
											<td>
												<DeleteDot onClick={() => {
													setCreateFieldMetadata(createFieldMetadata.filter(data => data != one));
												}} />
											</td>
										</tr>
									</>;
								})}
							</table>
							{(createFieldType === 'Select' || createFieldType === 'Multiselect') &&
							<UserInput>
								<FlexDiv>
									<Input value={createMetadataName} onChange={setCreateMetadataName} inputStyle={MetaDataInput} name='name' type="text"></Input>
									<Add onClick={() => {
										if (createMetadataName && createFieldMetadata) {
											if (createFieldMetadata.includes(createMetadataName)) {
												alert('Ce champs existe déjà');
											}
											else {
												setCreateFieldMetadata([...createFieldMetadata, createMetadataName]);
											}
										}
										else if (createMetadataName) {
											setCreateFieldMetadata([createMetadataName]);
										}
										setCreateMetadataName('');
									}} style={{ marginLeft: '20px' }} />
								</FlexDiv>
							</UserInput>}
							<DefaultButton disabled={(createFieldName === undefined) || (createFieldType === undefined) || (props.fieldList.find(field => field.display_name === createFieldName) ? true : false)} onClick={() => {
								const newField = {
									field_id: props.fieldList.length + 1,
									name: _.kebabCase(createFieldName),
									display_name: createFieldName,
									type_: createFieldType as FieldType,
									field_data: createFieldMetadata ? createFieldMetadata : [],
									validator: createFieldMandatory ? `current_screen.fields["${_.kebabCase(createFieldName)}"].filled` : undefined,
									category: 'Custom',
									added: true,
									inDraggable: true,
								};
								props.fieldList.push(newField);
								props.setFieldList(props.fieldList);
								setCreateFieldPopup(false);
								props.setForm(addFieldToJsonFields(props.form, props.currentPage, newField, props.template));
								setCreateFieldName(undefined);
								setCreateFieldType(undefined);
								forceUpdate();
							}}>
                            Ajouter</DefaultButton>
						</div>  
					</>}
			/>
			{currentFieldEditing && <Popup popupMode={PopupMode.Details}
				isOpen={isPopupOpen}
				onClickOut={() => setIsPopupOpen(false)}
				content={
					<>
						<div style={{ height: '100%', overflow: 'auto' }}>
							<Label>{translateToString('name')}</Label>
							<UserInput> <Input value={currentFieldEditing?.display_name} disabled={true} onChange={name => { }} inputStyle={ClassicInput} name='name' type="dropdown"></Input></UserInput>

							<Label>Type</Label>
							<UserInput> <Input value={translateToString('form_builder.types.' + currentFieldEditing?.type_)} disabled={true} onChange={name => { }} inputStyle={ClassicInput} name='name' type="dropdown"></Input></UserInput>
							{/* <Label>Validation</Label> */}
							<FlexDiv>
							</FlexDiv>
							<Label>Autre paramètres</Label>
							<FlexDiv>
								<Label>Historiser ce champ</Label>
								<Checkbox checked={isHistory(props.form, currentFieldEditing.name)} onChange={checkbox => { props.setForm(setHistory(props.form, currentFieldEditing.name, checkbox.target.checked)); forceUpdate(); }} />
							</FlexDiv>
							{(currentFieldEditing?.type_ === 'Select' || currentFieldEditing?.type_ === 'Multiselect') &&
								<Label>Champs disponibles</Label>
							}

							<table align="center" width={'80%'}>
								
								{currentFieldEditing.field_data && Array.isArray(currentFieldEditing.field_data) && currentFieldEditing?.field_data?.map(one => {
									return <>
										<tr>
											<Label>{one}</Label>
											<td><DeleteDot onClick={() => {
												assert(Array.isArray(currentFieldEditing.field_data), 'currentFieldEditing.field_data is not a string[]');
												props.setFieldList(setNewFieldData(props.fieldList, currentFieldEditing.name, currentFieldEditing.field_data.filter(data => data != one))); forceUpdate();
												const newFieldEditing = currentFieldEditing;
												newFieldEditing.field_data = currentFieldEditing.field_data.filter(data => data != one);
												setCurrentFieldEditing(newFieldEditing);
												props.setFieldList(setNewFieldData(props.fieldList, currentFieldEditing.name, newFieldEditing.field_data)); forceUpdate();
											}} /></td>
										</tr>
									</>;
								})}
							</table>
							{(currentFieldEditing?.type_ === 'Select' || currentFieldEditing?.type_ === 'Multiselect') &&
									<UserInput>
										<FlexDiv>
											<Input value={currentMetadataEditing} onChange={setCurrentMetadataEditing} inputStyle={MetaDataInput} name='name' type="text"></Input>
											<Add onClick={() => {
												if (currentMetadataEditing && currentFieldEditing.field_data) {
													if (Array.isArray(currentFieldEditing.field_data) && currentFieldEditing.field_data.includes(currentMetadataEditing)) {
														alert('Ce champs existe déjà');
													}
													else if (Array.isArray(currentFieldEditing.field_data)) {
														const newFieldEditing = currentFieldEditing;
														newFieldEditing.field_data = [...currentFieldEditing.field_data, currentMetadataEditing];
														setCurrentMetadataEditing('');
														props.setFieldList(setNewFieldData(props.fieldList, currentFieldEditing.name, newFieldEditing.field_data)); forceUpdate();}
												}
											}}
											style={{ marginLeft: '20px' }} />
										</FlexDiv>
									</UserInput>}
						</div>  
					</>}
			/>}
		</>
	</>;
}

export function ListLayoutChildren(props: {
	children?: Layout[],
	currentPage: number,
	fieldList: Fields[],
	setFieldList: (fieldList: Fields[]) => void,
	form: Form,
	template: string,
	setForm: (form: Form) => void
}): JSX.Element {
	if (props.children) {
		const draggable_fields: Fields[] = [];

		props.children.map(child => {
			const current_field = props.fieldList.find(field => field.name === child.name && !(field.description || field.mandatory));
			if (current_field) {
				draggable_fields.push(current_field);
			}
		}); 

		return <>
			{props.children.map((child, index) => {
				const current_field = props.fieldList.find(field => field.name === child.name && (field.description || field.mandatory));
				if (current_field && !current_field.inDraggable) {
					return <>
						<ShowField template={props.template} key={index} setFieldList={props.setFieldList} fieldList={props.fieldList} currentPage={props.currentPage} setForm={props.setForm} form={props.form} field={current_field} />
					</>;
				}
			})}
			{draggable_fields.length > 0 && <ListDraggableFields template={props.template} setFieldList={props.setFieldList} field={draggable_fields[0]} draggables={draggable_fields} currentPage={props.currentPage} fieldList={props.fieldList} setForm={props.setForm} form={props.form} />}
		</>;
	}
	else return <></>;
}

export function ListLayout(props: {
	layout?: Layout,
	fieldList: Fields[],
	setFieldList: (fieldList: Fields[]) => void,
	setForm: (form: Form) => void,
	form: Form,
	currentPage: number,
	template: string
}): JSX.Element {
	if (props.layout) {
		if (props.layout.type === 'column') {
			console.log('layout:', props.layout);
			return <>
				{props.layout.children?.map((child, index) => {
					return <ListLayoutChildren key={index} template={props.template} setFieldList={props.setFieldList} currentPage={props.currentPage} form={props.form} setForm={props.setForm} fieldList={props.fieldList} children={child.children} />;
				})}
				<ListLayoutChildren template={props.template} setFieldList={props.setFieldList} currentPage={props.currentPage} form={props.form} setForm={props.setForm} fieldList={props.fieldList} children={props.layout.children?.filter(child => child.type === 'field')} />
			</>;
		}
		else if (props.layout.type === 'field' && props) {
			const c = props.fieldList.find(field => field.name === props.layout?.name);
			if (c) {
				return <>
					<DescriptiveField currentPage={props.currentPage} form={props.form} setForm={props.setForm} template={props.template} field={c} fieldList={props.fieldList} setFieldList={props.setFieldList}/>
				</>;
			}
		}
		return <></>;
	}
	else {
		return <></>;
	}
}
