import React, {Component} from 'react';
import Select, {components} from 'react-select';
import {styleOption} from '../../helpers/colorStyle';

const CaretDownIcon = () => {
  return <i className="fas fa-caret-down" />;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

class RenderSelectOptionMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBlur = e => {
    const blurHandler = this.props.onBlur;
    if (blurHandler) {
      blurHandler({
        type: 'blur',
        target: {
          value: e.target.value
        }
      });
    }
  };

  handlefilter = e => {
    const { filterAllData } = this.props;
    if (e !== null) {
      const tag = e.map(val => val.value);
      filterAllData(tag);
    } else {
      filterAllData([]);
    }
  };

  render() {
    const {
      input,
      id,
      requiredStar,
      label,
      icon,
      className,
      placeholder,
      options,
      closeMenu,
      multiple,
      clearable,
      disabled,
      meta: { touched, error, warning }
    } = this.props;
    return (
      <div className="selectOption">
        <div className="form-group row m-0">
          {label && (
            <div className={`col-md-2 text-right mt-2 pl-0 ${addClass}`}>
              <label htmlFor={id}>
                {requiredStar === true ? (
                  <span className="text-required">* </span>
                ) : (
                  ''
                )}
                {label}
              </label>
            </div>
          )}
          <div
            className={`${
              label ? 'col-10' : 'col-12'
            } react-select-container px-0${icon ? `input-group ` : ''}`}
          >
            <div className="col-12 d-flex flex-row px-0">
              {icon && (
                <div className="input-group-prepend">
                  <span className="input-group-text radius-select-label p-2">
                    <img alt="ProfileImage" src={icon} height="20" />
                  </span>
                </div>
              )}
              <Select
                {...input}
                id={id}
                options={options}
                optionClassName="needsclick"
                className={`form-control p-0 border-0 ${
                  touched && error
                    ? 'react-select-container'
                    : 'react-select-container'
                } ${className && 'selectFilter'}`}
                styles={styleOption}
                placeholder={placeholder}
                onChange={value => {
                  this.handlefilter(value);
                  input.onChange(value);
                }}
                onBlur={this.onBlur}
                closeMenuOnSelect={closeMenu}
                isMulti={multiple}
                isClearable={clearable}
                isDisabled={disabled}
              />
            </div>
            <div className="col-12">
              {touched &&
                ((error && error !== 'Required' && (
                  <span className="form-error">{error}</span>
                )) ||
                  (warning && <span className="form-error">{warning}</span>))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RenderSelectOptionMultiple;
