import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { FlexDiv } from '../../containers_v2/products/style';
import storeLang from '../../helpers/storeLang';
import { Dot } from '../../styles/global/css/Dot';
import { DefaultPlaceholderDiv, DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { Open } from '../../styles/global/css/Open';
import Dropdown from './Dropdown';
import { DropdownData, DropdownProps } from './model/Model';
import { InputContainer, OptionBlock } from './style/Style';

export default function DropdownStatus<T>(props: DropdownProps<T>): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	return <Dropdown
		{...props}
		dropdownStyle={props.dropdownStyle ?? { width: '250px', optionWidth: '250px', labelUp: true, height: '40px' }}
		readOnly
		label={props.label ?? translate('company.creation.contact.status').toString()}
		JSXButton={({ width, height, value, isOpen }) => <InputContainer width={width} height={height} padding='0 10px' >
			<FlexDiv gap='10px' justify='center' >
				{value 
					? <>
						<Dot size='10px' color={value?.color} />
						<DefaultTextDiv>{value?.label}</DefaultTextDiv>
					</>
					: props.placeholder
						? <DefaultPlaceholderDiv>{props.placeholder}</DefaultPlaceholderDiv>
						: <></>
				}
			</FlexDiv>
			<FlexDiv width='20px' justify='center'>
				<Open isOpen={isOpen} size={9} />
			</FlexDiv>
		</InputContainer>}
		JSXContent={(value: DropdownData) => <OptionBlock>
			<FlexDiv gap='10px' justify='center'>
				<Dot size='10px' color={value.color} />
				<DefaultTextDiv>{value.label}</DefaultTextDiv>
			</FlexDiv>
		</OptionBlock>}
	/>;
}
