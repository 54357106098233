/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import {formatCurrency} from '../../../../containers_v2/reports/utils';

class Details extends Component {
  state = {};

  render() {
    const { dataDetail } = this.props;

    return (
      <div className="detail-body">
        <div className="row">
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6 text-md-right px-3">
                <p>
                  <Translate id="amount" />
                </p>
              </div>
              <div className="col-md-6">
                <p className="detail-value-key">
                  {formatCurrency(dataDetail.amount) || '-'}
                </p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-md-6 text-md-right px-3 my-auto">
                <p className="my-auto">
                  <Translate id="probability" />
                </p>
              </div>
              <div className="col-md-6">
                <div
                  className={`${(!dataDetail.probability ||
                    dataDetail.probability === '0') &&
                    'bg-0'}
                          ${dataDetail.probability === '10' && 'bg-10'}
                          ${dataDetail.probability === '20' && 'bg-20'}
                          ${dataDetail.probability === '30' && 'bg-30'}
                          ${dataDetail.probability === '40' && 'bg-40'}
                          ${dataDetail.probability === '50' && 'bg-50'}
                          ${dataDetail.probability === '60' && 'bg-60'}
                          ${dataDetail.probability === '70' && 'bg-70'}
                          ${dataDetail.probability === '80' && 'bg-80'}
                          ${dataDetail.probability === '90' && 'bg-90'}
                          ${dataDetail.probability === '100' && 'bg-100'}
                          circle-probability`}
                >
                  <p>{`${dataDetail.probability}%`}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 text-md-right px-3">
                <p><Translate id='weighed_revenue' /></p>
              </div>
              <div className="col-md-6">
                <p className="detail-value-key">
                  {dataDetail.revenue ? formatCurrency(dataDetail.revenue) : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
