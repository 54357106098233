/* eslint-disable camelcase */
/**
 * <Toolbar />
 */

import React from 'react';
import ToolbarItem from './toolbar-draggable-item';
import ID from './services/UUID';
import store from './services/store';
import CollapseWrapper from './CollapseWrapper';
import toolbarList from './services/toolbarList';
import {Translate} from 'react-localize-redux';
import {fieldNameSetter, fieldTypeSetter} from './services/fieldSetter';

export default class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    const items = this.props.items ? this.props.items : this._defaultItems();
    this.state = {
      items
    };
    store.subscribe(state => this.setState({ store: state }));
  }

  _defaultItems = () => {
    return toolbarList;
  };

  create = item => {
    // console.log('item', item);
    const element = item.element || item.key;
    const field_type = fieldTypeSetter(element);
    const field_name = fieldNameSetter(element);

    const elementOptions = {
      id: ID.uuid(),
      new_element: true,
      element,
      field_type,
      field_name,
      system_field_name: field_type,
      is_display_field_name: true,
      is_required: false,
      is_start_section: false,
      is_end_section: false,
      is_start_table: false,
      is_end_table: false,
      is_multiple_value: false,
      is_default_value_today: false,
      is_default_checked: false,
      is_default_user: false,
      is_default_user_name: true,
      allow_picture_upload: false,
      allow_multiple_pictures: false,
      min: null,
      max: null,
      default_image_id: '',
      default_image_src: '',
      accepted_values: '',
      ordering: 1,
      details: null,
      initial_value: null,
      text: item.name,
      icon: item.icon,
      img: item.img,
      static: item.static,
      required: false,
      content: item.content,
      link: 'company',
      href: item.href,
      showDescription: item.showDescription
    };

    return elementOptions;
  };

  _onClick = item => {
    store.dispatch('create', this.create(item));
  };

  render() {
    return (
      <div className="react-form-builder-toolbar float-right">
        <h4>
          <Translate id="Form elements" />
          <span className="font-weight-normal">
            <Translate id="(drag & drop)" />
          </span>
        </h4>

        {this.state.items.map(section => (
          <CollapseWrapper title={<Translate id={section.title} />}>
            {section.items.map(item => (
              <ul>
                <ToolbarItem
                  data={item}
                  key={item.key}
                  onClick={this._onClick.bind(this, item)}
                  onCreate={this.create}
                />
              </ul>
            ))}
          </CollapseWrapper>
        ))}
      </div>
    );
  }
}
