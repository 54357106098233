import * as React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { DbView } from '../../atoms/global/views';
import { creationPopupInputStyle } from '../../containers_v2/client-companies/style/Style';
import { FlexDiv } from '../../containers_v2/products/style';
import { Radio } from '../filterList/style/Style';
import Input from '../input/Input';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';
import PopupCreation, { AdditionalButton } from '../popup/PopupCreation';

type PropsRestriction = {
	name: string
}

export type ViewCreation = {
	id?: number,
	name: string,
	public: boolean
}

const Container = styled(FlexDiv)`
	width: 100%;
	height: 100%;
	padding: 0 10%;
	flex-flow: column;
	gap: 50px;
	align-items: flex-start;
	justify-content: space-evenly;
`;

export default function PopupView<T extends PropsRestriction>(props: {
	isOpen: boolean,
	view?: DbView<T>,
	child?: React.ReactNode,
	onClickOut?: () => void,
	onValidation?: (v: ViewCreation) => void
}): JSX.Element {
	const { isOpen, view } = props;
	const [name, setName] = React.useState<string | undefined>(view?.data.name);
	const [isPublic, setIsPublic] = React.useState<boolean>(view?.public ?? false);
	const canValidate = name ? name.length > 0 : false;
	const additionalButton: AdditionalButton[] = view ? [{
		title: <Translate id='edit' />,
		onSubmit: () => name && props.onValidation?.({
			id: view.id,
			name,
			public: isPublic
		}),
		canValidate
	}] : [];

	React.useEffect(() => {
		setName(view?.data.name);
		setIsPublic(view?.public ?? false);
	}, [view]);

	return <Popup isOpen={isOpen} onClickOut={props.onClickOut} popupMode={PopupMode.Centered} popupStyle={{ height: '400px' }}>
		<PopupCreation
			title={view ? view.data.name : <Translate id='new_view' />}
			onClose={props.onClickOut}
			onSubmit={() => name && props.onValidation?.({ name, public: isPublic })}
			additionalButton={additionalButton}
			canValidate={canValidate}
			messageButton={view ? <Translate id='duplicate' /> : undefined}
		>
			<Container>
				<Input
					required
					value={name}
					label={<Translate id='name' />}
					inputStyle={creationPopupInputStyle}
					name='popup_view_name'
					type='text'
					onChange={setName}
				/>
				<FlexDiv flow='column' align='start' gap='10px' fontWeight='600' fontSize='14px'>
					<Translate id='who_must_see_this_view' />
					<FlexDiv defaultText gap='10px' clickable onClick={() => setIsPublic(false)}>
						<Radio isActive={!isPublic} />
						<Translate id='me_only' />
					</FlexDiv>
					<FlexDiv defaultText gap='10px' clickable onClick={() => setIsPublic(true)}>
						<Radio isActive={isPublic} />
						<Translate id='all_users' />
					</FlexDiv>
					{props.child}
				</FlexDiv>
			</Container>
		</PopupCreation>
	</Popup>;
}