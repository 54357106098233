import list_with_pen from 'images/icon/list_with_pen.svg';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { ATimeRangeFilter3 } from '../../../atoms/filter/timeFilterAtom';
import Dropdown from '../../dropdown/Dropdown';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { translateToString } from '../../../styles/global/translate';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';


// Réalisés durant la période sélectionnée
// Réalisés durant le mois en cours
// Réalisés durant le trimestre en cours
// Réalisés durant l'année en cours
// Réalisés jusqu'à 30 jours avant le début de la période
// Réalisés jusqu'à 90 jours avant le début de la période
// Réalisés jusqu'à 180 jours avant le début de la période
// Réalisés jusqu'à 365 jours avant le début de la période
// Tous les formulaires réalisés

export type TimeRange3 = 'thisperiod' | 'thismonth' | 'thisquarter' | 'thisyear' | '30daysbefore' | '90daysbefore' | '180daysbefore' | '365daysbefore' | 'all'
export const ALL_TIME_RANGES: TimeRange3[] = [
	'thisperiod',
	'thismonth',
	'thisquarter',
	'thisyear',
	'30daysbefore',
	'90daysbefore',
	'180daysbefore',
	'365daysbefore',
	'all'
];


export default function TimeRangeFilter3(props: GenericToolbarFilterProps & {
  defaultValue?: TimeRange3,
  disabled?: boolean,
  allowNull?: boolean
}): JSX.Element {
	const { defaultValue } = props;
	const options: TimeRange3[] = ALL_TIME_RANGES;
	const [timeRange, setTimeRange] = useRecoilState(ATimeRangeFilter3);
	const { filters, setFilters } = React.useContext(ReportFiltersContext);
	const [selectedValue, setSelectedValue] = React.useState<TimeRange3>(props.context ? (filters.my_filters?.timerange) ?? 'thisperiod' : timeRange ?? (defaultValue !== undefined ? defaultValue : 'thisperiod'));


	if (props.hidden) return <></>;
	
	const datalist: { label: string, value: TimeRange3 }[] = options.map(value => ({ label: translateToString('tool_bar.forms.' + value), value }));

	return <Dropdown
		disabled={props.disabled}
		dropdownStyle={{ ...DROPDOWN_FILTER_STYLE, width: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.width, containerWidth: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.containerWidth }}
		selectedValue={{ label: translateToString('tool_bar.forms.' + selectedValue), value: selectedValue }}
		datalist={datalist}
		readOnly
		freezeShowLabel
		name='dropdown_scope_filter_3'
		onChange={value => {
			if (props.context) {
				setFilters({ ...filters, my_filters: { ...filters.my_filters, timerange: value.value } });
			}
			else {
				setTimeRange(value.value);
			}
			setSelectedValue(value.value);
		}}
		image={list_with_pen}
	/>;
}

export function TimeRange3ToDate(timeRange: TimeRange3, start: Date): Date | undefined {
	const now = new Date();
	const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	switch (timeRange) {
		case 'thisperiod':
			return undefined;
		case 'thismonth':
			today.setMonth(today.getMonth());
			return today;
		case 'thisquarter':
			today.setMonth(today.getMonth() - (today.getMonth() % 3));
			return today;
		case 'thisyear':
			today.setFullYear(today.getFullYear());
			return today;
		case '30daysbefore':
			start.setDate(today.getDate() - 30);
			return start;
		case '90daysbefore':
			start.setDate(today.getDate() - 90);
			return start;
		case '180daysbefore':
			start.setDate(today.getDate() - 180);
			return start;
		case '365daysbefore':
			start.setDate(today.getDate() - 365);
			return start;
		case 'all':
			return new Date(0);
	}
}