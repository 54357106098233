import add_blue from 'images/icon/add_blue.png';
import React, { Component } from 'react';
import swal from 'sweetalert2';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import ModalCreateDocument from './modals/ModalCreateDocument';
import { CompleteTime } from '../../../../helpers/time';
import { deleteDocument } from '../../../../store/actions/opportunities.action';
import penUrl from 'images/ui_icon/pen_black.svg';
import trashUrl from 'images/ui_icon/trash_black.svg';
import { getPenColor, getTrashColor } from './Calendar';
import FileIcon from 'images/icon/fileIcon.png';

class Documents extends Component {
	state = {
		modal: false,
		penUrl,
		trashUrl,
	};

	onDelete = (id, opportunityId) => {
		const { deleteDocument } = this.props;

		swal
			.fire({
				title: 'Attention!',
				text: 'Are you sure you want to delete this from the database?',
				type: 'warning',
				confirmlButtonText: 'Yes, I do',
				confirmButtonColor: '#f7685b'
			})
			.then(result => {
				if (result.value) {
					deleteDocument(id, opportunityId);
				}
			});
	};

	onHover = (pen, trash) => {
		this.setState({
			penUrl: pen ? getPenColor(pen) : this.state.penUrl,
			trashUrl: trash ? getTrashColor(trash) : this.state.trashUrl
		});
	};

	toggle() {
		this.setState({
			modal: false
		});
	}

	render() {
		const {
			data,
			id,
			onFetch,
			isLoading,
			isFirstPage,
			isLastPage,
			count
			// loadingDeleteId
		} = this.props;
		// console.log('documents', data);

		return (
			(<div>
				<div className="detail-top m-3 p-3">
					<div className="row mb-2">
						<div className="col-md-9">
							<div className="my-auto">
								<span className="card-title">Documents ({count || '0'}) </span>
							</div>
						</div>
						<div className="col-md-3 d-flex justify-content-end align-items-end">
							<div
								className="my-auto custom-icon-blue rounded-circle "
								onClick={() =>
									this.setState({
										modal: true
									})
								}
							>
								<img
									src={add_blue}
									className="custom-icon"
									alt=""
								/>
							</div>
						</div>
					</div>
					{data.length ? (
						<div className="row">
							{data.map(val => (
								<div className="col-md-12 mb-1">
									<a href={val.file.fileUrl} target="_blank" rel="noreferrer">
										<div className="card card-docs pointer relative-btn-container">
											<button
												className="btn-delete-absolute"
												onClick={e => {
													e.preventDefault();
													this.onDelete(val.id, id.opportunityId);
												}}
											>
												<img
													src={this.state.trashUrl}
													alt=""
													width="15px"
													height="15px"
													onMouseEnter={() =>
														this.onHover(null, 'trash_blue.svg')
													}
													onMouseLeave={() =>
														this.onHover(null, 'trash_black.svg')
													}
												/>
											</button>
											<div className="p-2" style={{ borderRadius: '6px' }}>
												<div className="row">
													<div className="col-md-1 my-auto text-center p-0">
														<img
															src={FileIcon}
															alt=""
															width="30"
														/>
													</div>
													<div className="col-md-9 pl-1">
														<div className="d-block ml-2">
															<p className="my-1 text-title">{val.file.name}</p>
															<p className="my-1 text-by">
																{
																	(val.file && val.file.user && val.file.user.name) ? `by ${val.file.user.name} on ${CompleteTime(val.createdAt)}`
																		: `by - on ${CompleteTime(val.createdAt)}`
																}
															</p>
														</div>
														{/* <span className="count"> 100% </span> */}
													</div>
												</div>
											</div>
										</div>
									</a>
								</div>
							))}
						</div>
					) : (
						<p
							style={{
								fontSize: '14px',
								margin: '15px 0 10px',
								color: '#707683'
							}}
						>
							<Translate id="no documents" />
						</p>
					)}
					{count > 3 &&
            (!isLoading ? (
            	<div className="row mt-2">
            		<button
            			className={`${
            				isLastPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-more col-6`}
            			onClick={() => {
            				!isLastPage && onFetch();
            			}}
            		>
            			<Translate id="see_more" />
            		</button>
            		<button
            			className={`${
            				isFirstPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-less col-6`}
            			onClick={() => {
            				!isFirstPage && onFetch(1);
            			}}
            		>
            			<Translate id="see_less" />
            		</button>
            	</div>
            ) : (
            	<div className="d-flex">
            		<i className="lds-dual-ring mx-auto" />
            	</div>
            ))}
				</div>
				<ModalCreateDocument
					isOpen={this.state.modal}
					toggle={() => {
						this.toggle();
					}}
					onClick={() => {
						this.toggle();
					}}
					id={id}
				/>
			</div>)
		);
	}
}

const mapStateToProps = state => ({
	// loadingDeleteId: state.companies.deletePictureId
});

export default connect(
	mapStateToProps,
	{ deleteDocument }
)(Documents);
