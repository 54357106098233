import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { AExternalMappings } from '../../../../atoms/global/mappings';
import { DropdownData } from '../../../../components_v2/dropdown/model/Model';
import Pagination from '../../../../components_v2/pagination/Pagination';
import { PopupMode } from '../../../../components_v2/popup/model/Model';
import Popup from '../../../../components_v2/popup/Popup';
import { Column, Table, TableSortType } from '../../../../components_v2/table/Table';
import storeLang from '../../../../helpers/storeLang';
import usePermission from '../../../permissions/usePermission';
import { exportOrders, getOrders, getOrderStatuses } from '../../data/Data';
import { Order, TypeFile } from '../../model/Model';
import { TableColumns } from '../data/DataTable';
import { Row } from '../model/Model';
import PopupNewOrder from './popup/PopupNewOrder';
import PopupOrderDetail from './popup/PopupOrderDetail';
import { Container, ContainerTable, Footer } from './style/ListStyle';
import { AUsers } from '../../../../atoms/global/users';
import NoData from '../../../noData/NoData';
import { translateToString } from '../../../../styles/global/translate';

function updateOrders(
	setOrders: (orders: Row[]) => void,
	setOrderAmount: (amount: number) => void,
	limit = 50,
	offset = 0,
	search = '',
	sort: TableSortType = { id: 'order_date', desc: true }
): void {
	getOrders(limit, offset, sort, search).then(res => {
		const data: Row[] = res.orders.map((order: Order): Row => ({
			order_id: order.id,
			name: order.reference,
			date: order.order_date,
			company: order.client_company_name,
			owner: order.owner_name,
			quantity: order.product_count ? order.product_count : 0,
			amount: order.sum_with_tax ? parseFloat(order.sum_with_tax.toFixed(2)) : 0,
			order_status_id: order.order_status_id,
			order_status: order.order_status_name,
			order_status_color: order.order_color_status_code,
			checked: false,
			edit: null,
			external_ids: order.external_ids,
			external_company_ids: order.external_company_ids
		}));

		setOrderAmount(res.total);
		setOrders(data);
	});
}

function TemplateOrdersList(props: {
  searchValue: string
  onSelectedOrders?: (values: Order[]) => void
  updated?: boolean
  setRedirect?: (value) => void
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const DEFAULT_LIMIT = 50;
	const DEFAULT_OFFSET = 0;

	const [orderAmount, setOrderAmount] = React.useState<number>(0);
	const [orders, setOrders] = React.useState<Row[]>([]);
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [isOpenDuplicate, setOpenDuplicate] = React.useState<boolean>(false);
	const [orderId, setOrderId] = React.useState<number>();
	const [sort, setSort] = React.useState<TableSortType | undefined>(undefined);
	const externalMappings = useRecoilValue(AExternalMappings);

	const [limit, setLimit] = React.useState<number>(DEFAULT_LIMIT);
	const [offset, setOffset] = React.useState<number>(DEFAULT_OFFSET);
	const [statuses, setStatuses] = React.useState<DropdownData[]>([]);

	const [allSelected, setAllSelected] = React.useState<boolean>(false);
	const users = useRecoilValue(AUsers);

	const dispatch = useDispatch();

	React.useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const paramId = queryParams.get('id');
		if (paramId) {
			const orderId: number = parseInt(paramId);
			if (!isNaN(orderId)) {
				OpenPopup(orderId);
			}
		}
	}, [orders, window.location.href]);

	React.useEffect(() => {
		getOrderStatuses().then(response => {
			setStatuses(response.map(ot => {
				return {
					label: ot.name,
					value: ot,
					color: ot.color_code
				} as DropdownData;
			}));
		});
	}, []);

	React.useEffect(() => {
		updateOrders(setOrders, setOrderAmount, limit, offset, props.searchValue, sort);
	}, [limit, offset, sort, props.searchValue]);

	function OpenPopup(order_id: number): void {
		setOrderId(order_id);
		const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?id=' + order_id;
		if (window.location.href != newurl) { window.history.pushState({ path: newurl }, '', newurl); }
		setOpen(true);
	}

	function updateRowOrder(row: Row): void {
		const newOrders = orders.map(o => {
			if (o.order_id === row.order_id) {
				o.order_status_id = row.order_status_id;
				o.order_status_color = row.order_status_color;
				o.order_status = row.order_status;
			}

			return o;
		});
		setOrders(newOrders);
	}

	function onDelete(order_id: number): void {
		setOrders(orders.filter(o => o.order_id !== order_id));
	}

	function onDuplicate(id: number): void {
		setOrderId(id);
		setOpenDuplicate(true);
	}

	function onClickCheckbox(row: Row | null, allSelected: boolean | null): void {
		if (allSelected != null) {
			const newOrders = orders.map(o => {
				o.checked = allSelected;
				return o;
			});
			setOrders(newOrders);
			setAllSelected(newOrders.find(o => !o.checked) === undefined)
			;(props.onSelectedOrders != null) && props.onSelectedOrders(newOrders.filter(r => r.checked).map(r => {
				return {
					id: r.order_id,
					reference: r.name
				} as Order;
			}));
		} else {
			if (row != null) {
				const newOrders = orders.map(o => {
					o.checked = o.order_id === row.order_id ? row.checked : o.checked;
					return o;
				});
				setOrders(newOrders);
				setAllSelected(newOrders.find(o => !o.checked) === undefined)
				;(props.onSelectedOrders != null) && props.onSelectedOrders(newOrders.filter(r => r.checked).map(r => {
					return {
						id: r.order_id,
						reference: r.name
					} as Order;
				}));
			}
		}
	}

	function onExportPDF(orderId: number): void {
		exportOrders(TypeFile.PDF, 'Orders', orderId).then(response => {
			if (response.valueOf()) {
				dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
				//     toast.success(translate('exports.ready_toast').toString(), {
				//   position: 'top-right',
				//   autoClose: 4000,
				//   hideProgressBar: true,
				//   closeOnClick: true,
				//   pauseOnHover: true,
				//   draggable: true,
				//   progress: undefined,
				// });
			}
		});
	}

	function onCreateNewOrder(): void {
		setOpenDuplicate(false);
		updateOrders(setOrders, setOrderAmount, limit, offset, props.searchValue, sort);
	}

	React.useEffect(() => {
		updateOrders(setOrders, setOrderAmount, limit, offset, props.searchValue, sort);
	}, []);

	React.useEffect(() => {
		updateOrders(setOrders, setOrderAmount, limit, offset, props.searchValue, sort);
	}, [props.updated]);

	const columns: Array<Column<Row>> = React.useMemo(
		() => TableColumns(allSelected,
			statuses,
			usePermission,
			OpenPopup,
			onDelete,
			onDuplicate,
			onClickCheckbox,
			updateRowOrder,
			onExportPDF,
			externalMappings,
			users),
		[JSON.stringify(orders), JSON.stringify(statuses)]
	);

	const closeModal = () => {
		setOpen(false);
		const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
		window.history.pushState({ path: newurl }, '', newurl);
	};

	return (
		<Container>
			{orderId &&
		<Popup popupStyle={{ width: '100%', height: '100%', top: '0px', left: '0px', animate: true, borderRadius: '10px 0 0 10px' }}
			content={(<PopupOrderDetail onClose={closeModal} order_id={orderId} />)}
			isOpen={isOpen}
			popupMode={PopupMode.Default}
			hasBackground
			onClickOut={closeModal}
		/>
			}
			{
				orderId && <Popup
					popupStyle={{ width: '600px', height: '400px' }}
					hasBackground={true}
					popupMode={PopupMode.Centered}
					content={(<PopupNewOrder orderId={orderId} onCreate={onCreateNewOrder} setRedirect={props.setRedirect} />)}
					isOpen={isOpenDuplicate}
					onClickOut={() => setOpenDuplicate(false)} />
			}
			<ContainerTable>
				{orders.length == 0
					? <NoData message={translateToString('no_orders')} />
					: <Table
						height='calc(100vh - 174px)'
						columns={columns}
						data={orders}
						onSort={sort => setSort(sort[0])}
						initialSortBy={sort}
					/>
				}
			</ContainerTable>
			<Footer>
				<Pagination label={translate('orders.title').toString().toLocaleLowerCase()} amount={orderAmount} steps={[DEFAULT_LIMIT, 100, 200, 500]} onChange={(value) => {
					setLimit(value.step);
					setOffset((value.offset));
				}} />
			</Footer>
		</Container>
	);
}

export default TemplateOrdersList;
