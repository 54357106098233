import styled from 'styled-components';
import { DefaultEditable, DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, RedSidely } from '../../../styles/global/css/Utils';

interface BodyMainTextProps {
  marginLeft?: string
  cursor?: string
  isLink?: boolean
  error?: boolean
  fontSize?: string
  fontWeight?: string
  color?: string
  readOnly?: boolean
}

export const BodyMainTextEditable = styled.p<BodyMainTextProps>`
    ${DefaultText}
    transition: all 0.4s ease;
    height: 100%;
    margin-left: ${p => p.marginLeft ?? '0'};
    cursor: ${p => p.cursor ?? 'pointer'};
    display: flex;
    align-items: center;
    min-width: 50px;
    min-height: 10px;
    color: ${p => p.color ?? 'initial'};
    font-size: ${p => p.fontSize ?? 'initial'};
    font-weight: ${p => p.fontWeight ?? 'initial'};

    ${DefaultEditable}
    &:hover {
        ${p => !p.readOnly
		? `border-bottom: 1px solid;
        border-color: ${p => p.error ? RedSidely : BlueSidely};`
		: ''}
        
    }
    &:empty {
        &:not(:focus) {
            &:before {
                content: '-';
            }
        }
    }
    ${p => p.error
		? `
        &:focus-visible {
            background-color: ${RedSidely}25;
        }
        color: ${RedSidely};
        `
		: ''}
`;
