import axios from 'axios';
import { Cookies } from 'react-cookie';
import { atom, selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';
import { AtomCategory, AtomState } from './utils/model/Model';
import { FieldType } from 'bindings/forms/FieldType';
import { FilterType } from 'bindings/filters/FilterType';

export type AdditionalFieldColumn = {
	field_id: number,
	field_name: string,
	field_type: FieldType,
}

export function isFieldTypeVisibleOnMap(type: FieldType): boolean {
	switch (type) {
		case 'Boolean':
		case 'Date':
		case 'Integer':
		case 'Number':
		case 'Select':
		case 'Multiselect':
		case 'User':
			return true;
	}
	return false;
}

export function isFieldTypeSortable(type: FieldType): boolean {
	switch (type) {
		case 'Text':
		case 'Number':
		case 'Date':
		case 'Boolean':
		case 'File':
		case 'Image':
		case 'Url':
		case 'Email':
		case 'Phone':
		case 'Address':
		case 'Location':
		case 'Integer':
		case 'TextArea':
		case 'Select':
			return true;
		case 'Form':
		case 'Company':
		case 'Dispatch':
		case 'User':
		case 'CheckIn':
		case 'Multiselect':
			return false;
	}

}

export function fieldTypeToFilterType(type: FieldType): FilterType {
	switch (type) {
		case 'Text': return 'string';
		case 'Number': return 'numeric';
		case 'Date': return 'temporal';
		case 'Boolean': return 'bool';
		case 'Select': return 'select';
		case 'Multiselect': return 'multi_select';
		case 'File': return 'nullable';
		case 'Image': return 'nullable';
		case 'Url': return 'string';
		case 'Email': return 'string';
		case 'Phone': return 'string';
		case 'Address': return 'string';
		case 'Location': return 'string';
		case 'User': return 'user';
		case 'Dispatch': return 'nullable';
		case 'Company': return 'nullable';
		case 'Integer': return 'numeric';
		case 'CheckIn': return 'nullable';
		case 'Form': return 'nullable';
		case 'TextArea': return 'string';
	}
}

// -----------------------[ ATOM ]----------------------- //
export const AGlobalAdditionalFieldColumns: AtomState<AdditionalFieldColumn[]> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_additional_field_columns', // UNIQUE ID
		default: getAdditionalFieldColumns()
	})
};

export const AAdditionalFieldColumns = selector({
	key: 'AAdditionalFieldColumns',
	get: async({ get }) => get(AGlobalAdditionalFieldColumns.atom),
	set: ({ set }, newValue) => set(AGlobalAdditionalFieldColumns.atom, newValue),
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getAdditionalFieldColumns(): Promise<AdditionalFieldColumn[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/additional-field-columns`).then(res => res.data);
}