/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import { selectInCharge } from '../../store/actions/select.action';
import { currentData } from '../../store/actions/user.action';
import noUserImage from 'images/icons/user.svg';

class SelectInCharge extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			opt: []
		};
	}

	componentDidMount() {
		const { currentData } = this.props;
		currentData();
	}

	componentWillReceiveProps(nextProps) {
		const { data, current, selectInCharge } = this.props;

		if (current !== nextProps.current) {
			selectInCharge(
				nextProps.current.id,
				nextProps.current.companyId,
				nextProps.current.role.slug
			);
		}

		if (data !== nextProps.data) {
			if (current.role.slug === 'manager') {
				const opt = [];
				opt.push({
					value: current.id,
					label: (
						<div>
							{' '}
							<img
								src={current.photoUrl || noUserImage}
								alt=""
								className="img-xs rounded-circle image-thumbnail mr-2"
							/>{' '}
							{`${current.name} (You)`}
						</div>
					)
				});

				nextProps.data.map(data =>
					opt.push({
						value: data.id,
						label: (
							<div>
								{' '}
								<img
									src={data.photoUrl || noUserImage}
									alt=""
									className="img-xs rounded-circle image-thumbnail mr-2"
								/>{' '}
								{`${data.name}`}
							</div>
						)
					})
				);

				this.setState({ opt });
			} else {
				const opt = [];

				nextProps.data.map(data => {
					if (data.id == current.id) {
						opt.push({
							value: current.id,
							label: (
								<div>
									{' '}
									<img
										src={current.photoUrl}
										alt=""
										className="img-xs rounded-circle image-thumbnail mr-2"
									/>{' '}
									{`${current.name} (You)`}
								</div>
							)
						});
					} else {
						opt.push({
							value: data.id,
							label: (
								<div>
									{' '}
									<img
										src={data.photoUrl || noUserImage}
										alt=""
										className="img-xs rounded-circle image-thumbnail mr-2"
									/>{' '}
									{`${data.name}`}
								</div>
							)
						});
					}
				});

				this.setState({ opt });
			}
		}
	}

	render() {
		const { opt } = this.state;
		const {
			validate,
			label,
			requiredStar,
			name,
			placeholder,
			disabled,
			readOnly
		} = this.props;
		// console.log('current', current);
		// console.log('id', id);
		return (
			<Field
				name={name}
				component={RenderSelectInput}
				label={label}
				disabled={disabled}
				readOnly={readOnly}
				requiredStar={requiredStar}
				options={opt}
				id="inputOwner"
				placeholder={placeholder}
				validate={validate}
			/>
		);
	}
}

const mapStateToProps = state => ({
	data: state.select.inCharge,
	current: state.user.current
});

export default connect(
	mapStateToProps,
	{ selectInCharge, currentData }
)(SelectInCharge);
