import axios from 'axios';
import { Period, Promotion } from './model';
import { URL_FOUNDATION } from '../../config/keys';
import { UpdatedField } from '../../../../web-types/api';

export function getPromotions(body?: { descending: boolean, order_by: string }): Promise<Promotion[]> {
	return axios.post(`${URL_FOUNDATION}/api/v2/promotions/query`, body ?? {}).then(res => res.data);
}

export async function getPromotionById(promotion_id: number): Promise<Promotion> {
	return axios.get(`${URL_FOUNDATION}/api/v2/promotions/${promotion_id}`).then(res => res.data);
}

export type PutPromotionBody = {
	name?: string,
	periods?: Period[],
	is_active?: boolean
	description?: UpdatedField<string>,
	companies_add?: number[],
	companies_del?: number[],
	products_add?: number[],
	products_del?: number[]
}

export function putPromotion(promotionId: number, body: PutPromotionBody): Promise<number> {
	return axios.put(`${URL_FOUNDATION}/api/v2/promotions/${promotionId}`, body).then(res => res.data);
}

export type PostPromotionBody = {
	name: string,
	periods: Period[],
	is_active: boolean,
	companies: number[],
	description: string | undefined,
	products: number[]
}

export function postPromotion(body: PostPromotionBody): Promise<number> {
	return axios.post(`${URL_FOUNDATION}/api/v2/promotions`, body).then(res => res.data);
}

export function deletePromotion(promotionId: number): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/promotions/${promotionId}`).then(res => res.data);
}