import styled from 'styled-components';
import { DefaultButton, DefaultCloseButton } from '../../../styles/global/css/GlobalButton';
import { DefaultText } from '../../../styles/global/css/GlobalText';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 3px;
`;

const Title = styled.div`
    ${DefaultText}
    font-weight: bold;
    font-size: 14px;
`;

const CloseButton = styled(DefaultCloseButton)``;

const Body = styled.div``;

const Footer = styled.div``;

const ExportButton = styled(DefaultButton)`
    width: 135px;
`;

export {
	Container,
	Header,
	Title,
	CloseButton,
	Body,
	Footer,
	ExportButton
};
