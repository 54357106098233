import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';


export type Product = {
	id: number
	uuid: string,
    name: string,
	brand?: number,
	category?: number,
	tags: number[],
	price?: number,
	barcode?: string,
	code_upc?: string,
	outer?: number
}

// -----------------------[ ATOM ]----------------------- //
export const AProducts = selector<Product[]>({
	key: 'AProducts',
	get: async() => getProducts()
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getProducts(): Promise<Product[]> {
	try {
		return await axios({
			method: 'get',
			url: `${URL_FOUNDATION}/api/v2/products/all`
		}).then(res => res.data);
	} catch (e) {
		console.error(e);
		return [];
	}
}