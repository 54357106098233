import { FilterResult, FilterTree } from '../../../components_v2/filter/model/Model';
import { Tax } from '../../settings/models';

const POURCENTAGE = 1;
const FIXED = 2;
const PCB = 3;
const UNIT = 4;

enum TypeFile {
  CSV = 'CSV',
  PDF = 'PDF',
  Excel = 'Excel'
}

type ExportType = 'Orders' | { type: 'Notes', kind: 'Companies' | 'Contacts' } | { type: 'Contacts', filters?: FilterTree, limit?: number, offset?: number, order_by?: string, descending?: boolean, search?: string } | 'Assortments' 

interface ClientCompany {
  id: number
  user_id: number
  company_id: number
  owner_id: number
  name: string
  website: string
  email: string
  logo: string
  phone: string
  billing_address: string
  billing_post_code: string
  billing_city: string
  billing_country: string
  is_billing_delivery: boolean
  isBillingDelivery: boolean
  delivery_address: string
  delivery_post_code: string
  delivery_city: string
  delivery_country: string
  client_status_id: number
  created_at: Date
  updated_at: Date
  deleted_at: Date
  modified_by: number
  latitude: string
  longitude: string
  date_last_client: Date | null
  parent: string
  parentid: number
  countChild: number
  createdBy: string
  updatedBy: string
  owner: string
  clientStatusName: string
  clientStatusSlug: string
  clientStatusColorCode: string
  oppenedOpportunities: number
  signedOpportunities: number
  sumOfSignedOpportunities: number
  lostOpportunities: number
  lastActivity: Date
  permission: number
}

interface Opportunity {
  id: number
  incharge: number
  deadline: string
  title: string
  description: string
  amount: number
  probability: number
  revenue: number
  userId: number
  companyId: number
  clientCompanyId: number
  contactId: number
  opportunityStatusId: number
  modifiedBy: number
  dateLastSigned: string
  updatedAt: string
  createdAt: string
  deletedAt: string
  inchargeUser: Owner
  clientCompany: ClientCompany
}

interface Contact {
  id: number
  photoUrl: string
  ownerId: number
  owner?: Owner
  firstName: string
  lastName: string
  email: string
  phone: string
  position: string
  userId: number
  companyId: number
  clientCompanyId: number
  clientStatusId: number
  clientStatus?: CompanyStatus
  modifiedBy: number
  createdBy: string
  updatedBy: string
  clientCompany?: ClientCompany
}

interface DbProduct {
  count: number
  products: Product[]
}

interface Product {
  barcode: string
  brand_id: number
  brand_name: string
  category_id: number
  category_name: string
  code_upc: string
  created_at: Date
  description: string
  id: number
  count: number
  modified_by: number
  name: string
  outer: number
  photo_urls: string[]
  price: number
  recommended_price: number
  reference: string
  status_id: number
  stock: number
  summary: string
  tax: number
  updated_at: Date
  user_id: number

  // name: string
  // reference: string
  // barcode: string
  // codeUpc: string
  // stock: number
  // price: number
  // recommendedPrice: number
  // tax: number
  // outer: number
  // summary: string
  // description: string
  // createdAt: Date
  // updatedAt: Date
  // deletedAt: Date
  // categoryName: string
  // availability: string
  // colorCodeAvailibility: string
  // productImage: string
  // catalogueId: number[]
}

interface SelectedProduct {
  id: number
  name: string
  productImage: string
  amount: number
  price: number | null
  discount: number
  discountType: number
  extra_taxes: Tax[]
  HTPrice: number
  product: Product
}

interface PaymentType {
  id: number
  name: string
}

interface Assortment {
  id: number
  name: string
  form_icon_code: string
  form_icon_name: string
  form_icon_status: boolean
}

interface OrderStatus {
  id: number
  name: string
  company_id: number
  sequence: number
  color_status_id: number
  color_code: string
  created_at: Date
  updated_at: Date
  deleted_at: Date
  created_by: number
  updated_by: number
}

interface orderStatusTotalSum {
  id: number
  name: string
  total: number
}

interface OrderProductAdditionalTax {
  tax_value: number
  tax_type: number
  additional: boolean
  sequence: number
}

interface OrderProduct {
  id: number
  order_id: number
  product_id: number
  product_name: string
  product_reference: string
  product_barcode: string
  product_upc_code: string
  product_tax: number
  brand_id: number
  brand_name: string
  category_id: number
  category_name: string
  price: number
  quantity: number
  discount: number
  discount_id: number
  photo_urls?: string[]
  product_additional_taxes?: OrderProductAdditionalTax[]
}

export type ExternalIdResume = {
  mapping_name: string,
  external_id: string,
  external_mapping_id: number
}

interface Order {
  id: number
  reference: string
  owner_id: number
  owner_name: string
  order_status_id: number
  order_status_name: string
  order_color_status_id: number
  order_color_status_code: string
  sum_without_tax: number
  sum_with_tax: number
  payment_id: number
  client_company_id: number
  client_company_name: string
  contact_id: number
  opportunity_id: number
  discount: number
  discount_id: number
  billing_address: string
  delivery_address: string
  created_at: string
  updated_at: string
  deleted_at: string
  created_by: number
  updated_by: number
  products_sum: number
  order_sum: number
  order_date: string
  comment: string
  owner_photo: string
  product_count?: number
  products?: OrderProduct[]
  external_ids?: ExternalIdResume[],
  external_company_ids?: ExternalIdResume[]
}

interface OrderDb {
  orders: Order[]
  total: number
}

interface OrderPerStatusParam {
  status_id: number
  limit?: number
  offset?: number
  search?: string
}

interface OrderCreator {
  name: string
  company: ClientCompany
  owner: Owner
  creationDate: string
  selectedProducts: SelectedProduct[]
  discount: number
  discountType: number
  contact: Contact
  opportunity: Opportunity
  order_status_id: number
  payment_id: number
  comment: string
}

interface Owner {
  id: number
  photoUrl: string
  name: string
  email: string
  phone: string
  isYou?: boolean
  status: boolean
  color: string
  roleId: number
  longitude?: number
  latitude?: number
  roleName: 'Owner' | 'Admin'
}

export {
	OrderProduct,
	Order,
	OrderDb,
	OrderStatus,
	Product,
	SelectedProduct,
	ClientCompany,
	OrderCreator,
	Owner,
	PaymentType,
	Contact,
	Opportunity,
	Assortment,
	DbProduct,
	TypeFile,
	ExportType,
	orderStatusTotalSum,
	OrderPerStatusParam,
	OrderProductAdditionalTax,
	POURCENTAGE,
	FIXED,
	PCB,
	UNIT
};
