import axios from 'axios';
import { Cookies } from 'react-cookie';

import { URL_API } from '../../config/keys';
import { handleError } from '../../helpers/error';
import * as type from '../actionType/mapOpportunities.type';
import { getCurrentUserId } from '../../helpers/user';
import { getDeadlineDate } from '../../helpers/time';

const cookies = new Cookies();
const PATH = `${URL_API}/api`;
const token = cookies.get('id_token') || null;

export function fetchCompanies(ownerId = '', groupId = '', deadline = '', tagId) {
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		try {
			dispatch({
				type: type.FETCH_COMPANIES_BY_OPPORTUNITIES_REQUEST
			});

			if (ownerId != '') {
				groupId = '';
			}

			if (groupId != '') {
				ownerId = '';
			}

			const deadlineObj = getDeadlineDate(deadline);

			const response = await axios({
				method: 'GET',
				url: `${PATH}/clientCompanies/All`,
				params: {
					groupId,
					incharge: ownerId,
					by: 'oppenedOpportunities',
					gteDate: deadlineObj.gteDate,
					lteDate: deadlineObj.lteDate,
					tagId
				}
			});

			const filteredCompanies = filterCompanies(response.data.data, true);

			const tempCompanyInEachCountry = filterCompanyInEachCountry(
				filteredCompanies
			);

			const companiesInEachCountry = await getCompaniesInEachCountry(
				filteredCompanies,
				tempCompanyInEachCountry
			);

			dispatch({
				type: type.FETCH_COMPANIES_BY_OPPORTUNITIES_SUCCESS,
				payload: {
					companiesInEachCountry,
					companies: filteredCompanies
				}
			});
		} catch (error) {
			handleError(error, dispatch, type.FETCH_COMPANIES_BY_OPPORTUNITIES_ERROR);
		}
	};
}

function compareValues(key, order = 'asc') {
	return function innerSort(a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			return 0;
		}

		const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
		const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return order === 'desc' ? comparison * -1 : comparison;
	};
}

function filterCompanies(companies = [], isOpportunities = false) {
	const filterComp = companies.filter(comp => {
		return comp.billingCountry != '';
	});

	const filterCompanyBillAddr = filterComp.filter(comp => {
		return comp.billingCountry != 'null';
	});

	const filterCompanyBillCountry = filterCompanyBillAddr.filter(comp => {
		return comp.billingCountry != null;
	});

	let filterCompanies = filterCompanyBillCountry;

	if (isOpportunities) {
		filterCompanies = filterCompanyBillCountry.filter(comp => {
			return comp.oppenedOpportunities > 0;
		});
	}

	const sortedCompanies = filterCompanies.sort(compareValues('billingCountry'));

	return sortedCompanies;
}

async function getCompaniesInEachCountry(companies, companyInEachCountry) {
	return new Promise((resolve, rejected) => {
		companyInEachCountry.map(async function(compMap) {
			const latLng = await getLatLngByCountry(compMap.country);
			compMap.latLng = latLng;

			companies.map(company => {
				if (company.billingCountry == compMap.country) {
					compMap.companies.push(company);
				}
			});
		});

		resolve(companyInEachCountry);
	});
}

function filterCompanyInEachCountry(filteredCompanies) {
	const tempCompanyInEachCountry = [];

	filteredCompanies.map((company, index) => {
		if (index + 1 < filteredCompanies.length) {
			if (
				company.billingCountry != filteredCompanies[index + 1].billingCountry
			) {
				tempCompanyInEachCountry.push({
					country: company.billingCountry,
					latLng: {},
					companies: []
				});
			}
		} else {
			if (
				company.billingCountry != filteredCompanies[index - 1].billingCountry
			) {
				tempCompanyInEachCountry.push({
					country: company.billingCountry,
					latLng: {},
					companies: []
				});
			}
		}
	});

	return tempCompanyInEachCountry;
}

async function getLatLngByCountry(country = 'Indonesia') {
	return {
		lat: 0,
		lng: 0
	};
}

export function setFilterMapOpportunityDeadline(deadline = '') {
	return async dispatch => {
		dispatch({ type: type.SET_FILTER_MAP_DEADLINE, payload: deadline });
	};
}

export function setFilterMapOpportunityIncharge(incharge = '') {
	if (incharge == 'Me') {
		incharge = getCurrentUserId();
	}

	return dispatch => {
		dispatch({ type: type.SET_FILTER_MAP_INCHARGE, payload: incharge });
	};
}

export function setFilterMapOpportunityGroup(groupId = '') {
	return async dispatch => {
		dispatch({ type: type.SET_FILTER_MAP_GROUP, payload: groupId });
	};
}
