import styled, { keyframes } from 'styled-components';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { BorderColor, LightBlueSidely, RedSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { FreezePosition } from '../Table';
import ArrowImage from 'images/icons/arrow.svg';

interface ContainerProps {
	height: string
}

const Container = styled.div.attrs<ContainerProps>({
	role: 'table'
})`
		display: inline-block;
		border-spacing: 0;
		background: white;
		height: ${p => p.height};
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		border-radius: 10px;
		overflow: auto;
`;

interface HeaderProps {
	paddingRight?: string
}

const Header = styled.div<HeaderProps>`
		width: 100%;
		display: grid;
		padding-right: ${p => p.paddingRight ? p.paddingRight : '0'};
		position: sticky;
		top: 0;
		z-index: 8;
`;

const HeaderRow = styled.div.attrs({
	role: 'row'
})<{ noBorder?: boolean }>`
		display: -webkit-box;
		width: 100%;
		background-color: #FFFFFF;
		border-radius: 5px;
		${p => p.noBorder ? 'padding: 0 1em;' : ''}
		border-bottom: solid 0.5px ${BorderColor};
`;

interface ArrowProps {
	rotate180?: boolean
	isActive?: boolean
}

const Arrow = styled.div<ArrowProps>`
		cursor: pointer;
		background-image: url('${ArrowImage}');
		background-origin: initial;
		background-repeat: no-repeat;
		background-position: center;
		width: 8px;
		height: 8px;
		margin-left: 5%;
		transform: rotate(${p => p.rotate180 ? '180deg' : '0'});

		${p => p.isActive ? 'filter: invert(65%) sepia(60%) saturate(5339%) hue-rotate(167deg) brightness(103%) contrast(104%);' : ''}
`;

const ArrowDown = styled(Arrow)``;

const ArrowUp = styled(Arrow)``;

interface FilterButtonProps {
	isActive?: boolean
}

const FilterButton = styled(DefaultImage) <FilterButtonProps>`
		transition: all 0.4s ease;    
		width: 15px;
		height: 15px;
		cursor: pointer;
		margin-right: 3px;
		user-select: none;
		opacity: 0;

		${p => p.isActive ? 'filter: invert(65%) sepia(60%) saturate(5339%) hue-rotate(167deg) brightness(103%) contrast(104%);' : ''}

		&:hover {
				opacity: 1;
		}
`;

interface HeaderBlockProps {
	width?: string
	freezePadding?: number
	minWidth?: number
	maxWidth?: number
	showFreezeShadow?: boolean
	noBorder?: boolean,
	freeze?: FreezePosition
}

const HeaderBlock = styled.div.attrs<HeaderBlockProps>({
	role: 'columnheader'
})`
		transition: box-shadow 0.4s ease;
		width: ${p => p.width ? p.width : 'auto'};
		${p => p.noBorder ? '' : `border-right: solid 0.5px ${BorderColor};`}
		background-color: white;
		${p => p.freeze
		? `position: sticky;
		z-index: 10;
		${p.freeze}: ${p.freezePadding ?? 0}px;
		margin-right: 1px;
		`
		: ''}

		${p => p.freeze && p.showFreezeShadow ? `box-shadow: ${BorderColor} ${p.freeze === 'right' ? '-' : ''}3px 0px 4px;` : ''}

		${p => p.minWidth ? `min-width: ${p.minWidth}px;` : ''}
		${p => p.maxWidth ? `max-width: ${p.maxWidth}px;` : ''}

		&:hover ${FilterButton} {
				opacity: 1;
		}
`;

const HeaderContent = styled.div`
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 15px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		padding: 0 0 0 10px;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 150%;
		color: ${SidelyBlack};
		align-items: center;
		width: 100%;
		
		//border-left: solid 0.5px ${BorderColor};
`;

const HeaderContentRight = styled.div`
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-shrink: 0;
`;

const HeaderContentRightSort = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
`;

const Body = styled.div.attrs({
	role: 'rowgroup'
})`
		display: inline-table;
		vertical-align: middle;
		border-color: inherit;
		box-sizing: border-box;
		height: 100%;
		width: 100%;
		position: relative;
		transition: top 0.2s;
`;

const BodyRow = styled.div.attrs({
	role: 'row'
})<{ noBorder?: boolean, depth?: number, paddingRight?: string }>`
		display: -webkit-box;
		height: 11%;
		padding-right:${p => p.paddingRight ?? '0'};
		max-height: ${p => p.noBorder ? '70px' : '40px'};
		min-height: ${p => p.noBorder ? '70px' : '40px'};
		${p => p.noBorder ? `
			border-top: 1px solid ${BorderColor};
			display: flex;
			align-items: center;
			padding: 0 1em;
		` : ''}
		${p => p.depth ? `
			background: #${(0xff - 8 * p.depth).toString(16)}${(0xff - 8 * p.depth).toString(16)}${(0xff - 8 * p.depth).toString(16)};
			${p.noBorder ? 'border-top: none;' : ''}
		` : 'background: white;'}
`;

const openContainer = keyframes`
from {
	margin-top: -40px;
}

to {
	margin-top: 0px;
}
`;

const closeContainer = keyframes`
	from {
	margin-top: 0px;
	}

	to {
	margin-top: -40px;
	}
`;


export type ContainerAnimation = 'waiting_closed' | 'waiting_opened' | 'opening' | 'closing'

export const InformationHeaderContainer = styled.div<{animation: ContainerAnimation, isOpen?: boolean}>`
		align-items: center;
		background-color: white;
		height: 40px;
		display: flex;
		z-index: -1;
		animation: ${p => p.animation == 'opening' ? openContainer : p.animation == 'closing' ? closeContainer : 'waiting' } 0.5s 1;
		margin-top: ${p => p.isOpen ? 0 : -40}px;
`;

export const InformationHeader = styled.div`
		position: sticky;
		left: 0;
		width: fit-content;
		z-index: 7;
		flex-shrink: 0;
		height: 100%;
`;

export const InformationHeaderHidder = styled.div`
		width: 100%;
		height: 100%;
		top: 0;
`;

interface BodyCellProps {
	width?: string
	selected?: boolean
	edit?: boolean
	error?: boolean
	freezePadding?: number
	showFreezeShadow?: boolean
	minWidth?: number
	maxWidth?: number
	noBorder?: boolean
	padding?: number
	freeze?: FreezePosition,
	centered: boolean
}

const BodyCell = styled.div.attrs<BodyCellProps>({
	role: 'cell'
})`
		transition: box-shadow 0.4s ease;
		display: inline-block; 
		box-sizing: border-box; 
		width: ${p => p.width ? p.width : 'auto'};
		color: ${SidelyBlack};
		white-space: nowrap;
		display: flex;
		justify-content: ${p => p.centered ? 'center' : 'flex-start'};
		padding: ${p => p.padding ?? 10}px;
		overflow-z: hidden;
		${p => p.noBorder ? '' : `border-top: solid 0.5px ${BorderColor};`}
		${p => p.noBorder ? '' : `border-right: solid 0.5px ${BorderColor};`}
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 150%;
		align-items: center;
		letter-spacing: -0.06em;
		max-height: 40px;
		${p => p.minWidth ? `min-width: ${p.minWidth}px;` : ''}
		${p => p.maxWidth ? `max-width: ${p.maxWidth}px;` : ''}

		${p => p.freeze
		? `position: sticky;
			z-index: 7;
			margin-right: 1px;
			${p.freeze}: ${p.freezePadding ?? 0}px;
			`
		: ''}

		${p => p.freeze && p.showFreezeShadow ? `box-shadow: ${BorderColor} ${p.freeze === 'right' ? '-' : ''}3px 0px 4px;` : ''}

		background-color: inherit;
		${p => p.selected
		? `
				background-color: ${LightBlueSidely};
				border-color: #CCF0FF;
		`
		: ''
}

		${p => p.edit
		? `
				background-color: ${p.error ? `${RedSidely}25` : LightBlueSidely};
				border-color: black;
				border: solid 1px;
				margin-left: -1px;
				margin-right: 1px;
		`
		: ''
}

		${p => p.error
		? `
				background-color: ${RedSidely}25;
				border: solid 1px ${RedSidely};
				margin-left: -1px;
				margin-right: 1px;
				color: white;
				`
		: ''
}

		
`;

const Resizer = styled.div`
	height: 20px;
	width: 8px;
	cursor: w-resize;
	margin-left: 7px;
`;

export {
	Container,
	Header,
	Body,
	HeaderBlock,
	HeaderRow,
	HeaderContent,
	Arrow,
	BodyRow,
	BodyCell,
	FilterButton,
	HeaderContentRight,
	HeaderContentRightSort,
	ArrowDown,
	ArrowUp,
	Resizer
};
