import styled, { css } from 'styled-components';
import { DefaultImage } from '../../../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../../../styles/global/css/GlobalText';
import { BlueSidely, FilterBlue, SidelyBlack } from '../../../../../styles/global/css/Utils';
import { CardPhoto } from './PhotoStyle';

interface ContainerProps {
    paddingTop?: string
    flexDirection?: string
}

const Container = styled.div<ContainerProps>`
    width: 100%;
    padding: 0 20px;
    padding-bottom: 10px;
    padding-top: ${p => p.paddingTop ?? '0'};
    display: flex;
    flex-direction: ${p => p.flexDirection ?? 'row'};
    flex-wrap: wrap;
`;

interface CardProps {
  isExtend: boolean
}

const Card = styled.div<CardProps>`
    transition: all 0.4s;
    width: ${p => p.isExtend ? '32%' : '100%'};
    margin-right: ${p => p.isExtend ? '5px' : '0'};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 17%);
    border-radius: 6px;
    padding: 5px;
    margin-top: 15px;
    cursor: pointer;
`;

interface ColorBarProps {
  color: string
}

const ColorBar = styled.div<ColorBarProps>`
    width: 10px;
    height: 115%;
    background-color: ${p => p.color};
    border-radius: 6px 0 0 6px;
    position: relative;
    right: 5px;
`;

interface CardImageProps {
  url: string
  width?: string
  borderRadius?: string
  backgroundSize?: string
}

const CardImage = styled.div<CardImageProps>`
    width: ${p => p.width ?? '35px'};
    height: ${p => p.width ?? '35px'};
    background-image: url('${p => p.url}');
    border-radius: ${p => p.borderRadius ?? '50%'};
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${p => p.backgroundSize ?? 'cover'};
`;

const CardLeft = styled.div`
    height: 100%;
    min-width: 20%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

interface CardMiddleProps {
  width?: string
}

const CardMiddle = styled.div<CardMiddleProps>`
    height: 100%;
    min-width: 60%;
    ${p => p.width ? `width: ${p.width};` : ''}
`;

interface CardRightProps {
  alignItems?: string
}

const CardRight = styled.div<CardRightProps>`
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: ${p => p.alignItems ?? 'center'};
`;

const CardExtraRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-width: 15px;
`;

interface CardTitleProps {
  nowrap?: boolean
}

const CardTileCss = css<CardTitleProps>`
  ${DefaultText}
  line-height: 19px;
  letter-spacing: 0.01em;
  color: ${SidelyBlack};
  font-weight: 500;
  font-size: 12px;
  ${p => p.nowrap
		? `overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;`
		: ''}
`;

const CardTitle = styled.p<CardTitleProps>`
 ${CardTileCss}
`;

export const CardTitleTh = styled.th<CardTitleProps>`
${CardTileCss}
`;

interface CardTextProps {
  color?: string
  nowrap?: boolean
}

const CardTextCss = css<CardTextProps>`
  ${DefaultText}
  line-height: 15px;
  letter-spacing: 0.01em;
  color: ${p => p.color ?? '#90a0b7'};
  font-weight: 400;
  font-size: 12px;
  ${p => p.nowrap
		? `overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;`
		: ''}
`;

const CardText = styled.p<CardTextProps>`
  ${CardTextCss}
`;

export const CardTextTd = styled.td<CardTextProps>`
  ${CardTextCss}
  padding-right: 1em;
`;

const SeeMore = styled.p`
    ${DefaultText}
    color: ${BlueSidely};
    font-weight: 400;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;

interface CardProbabilityProps {
  backgroundColor: string
}

const CardProbability = styled.div<CardProbabilityProps>`
    font-size: 12px;
    font-weight: 500;
    color: white;
    padding: 4px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background-color: ${p => p.backgroundColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Edit = styled(DefaultImage)`
    cursor: pointer;
    width: 15px;
    height: 15px;
    display: none;

    ${CardPhoto}:hover & {
        display: initial;
    }

    ${Card}:hover & {
        display: initial;
    }

    &:hover {
        filter: ${FilterBlue};
    }
`;

const Trash = styled(DefaultImage)`
    cursor: pointer;
    width: 15px;
    height: 15px;
    display: none;

    ${CardPhoto}:hover & {
        display: initial;
    }
    
    ${Card}:hover & {
        display: initial;
    }

    &:hover {
        filter: ${FilterBlue};
    }
`;

export {
	Container,
	Card,
	ColorBar,
	CardImage,
	CardLeft,
	CardMiddle,
	CardRight,
	CardTitle,
	CardText,
	SeeMore,
	CardProbability,
	Trash,
	Edit,
	CardExtraRight
};
