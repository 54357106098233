import styled, { keyframes } from 'styled-components';
import { BodyCell } from '../../../components_v2/table/style/Style';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultEditable, DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, RedSidely } from '../../../styles/global/css/Utils';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

interface LeftContainerProps {
  isFullOpen: boolean
}

const LeftContainer = styled.div<LeftContainerProps>`
    transition: width 0.4s;
    width: ${p => p.isFullOpen ? '25%' : '100%'};
    ${p => p.isFullOpen ? `border-right: 1px solid ${BorderColor}; padding-right: 10px;` : '' }
    display: flex;
    flex-flow: column;
    height: 100%;
`;

interface MiddleContainerProps {
  isFullOpen: boolean
  isOpen: boolean
}

const MiddleContainer = styled.div<MiddleContainerProps>`
    transition: width 0.4s;
    display: ${p => p.isFullOpen ? 'initial' : 'none'};
    width: ${p => p.isOpen ? '45%' : '0'};
    border-right: 1px solid ${BorderColor};
`;

interface RightContainerProps {
  isFullOpen: boolean
  isOpen: boolean
}

const RightContainer = styled.div<RightContainerProps>`
    transition: width 0.4s;
    display: ${p => p.isFullOpen ? 'flex' : 'none'};
    width: ${p => p.isOpen ? '30%' : '75%'};
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

const CreationPanel = styled.iframe`
    width: 100%;
    height: 100%;
`;

const Header = styled.div<{ minHeight?: string }>`
    display: flex;
    width: 100%;
    height: 11%;
    border-bottom: 1px solid ${BorderColor};
    min-height: ${p => p.minHeight ?? '120px'};
    justify-content: space-between;
`;

const HeaderLeft = styled.div<{ width?: string }>`
    display: flex;
    ${p => p.width ? `
        width: ${p.width};
    ` : ''};
`;

const HeaderRight = styled.div`
    // width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

interface FullOpenProps {
  isActive: boolean
}

const DetailDots = styled(DefaultImage)`
    width: 25px;
    transform: rotate(90deg);
    cursor: pointer;
`;

const FullOpen = styled(DefaultImage) <FullOpenProps>`
    cursor: pointer;
    transform: rotate(${p => p.isActive ? '0' : '180'}deg);
    transition: 0.5s;
    width: 18px;
`;

interface HeaderBlockProps {
  flexDirection: string
  marginLeft?: string
  alignItem?: string
  padding?: string
  width?: string
  error?: boolean
  gap?: string
}

const HeaderBlock = styled.div<HeaderBlockProps>`
    display: flex;
    flex-direction: ${p => p.flexDirection};
    margin-left: ${p => p.marginLeft ?? 'initial'};
    align-items: ${p => p.alignItem ?? 'center'};
    ${p => p.padding ? `padding: ${p.padding};` : ''}
    width: ${p => p.width ?? '100%'};
    ${p => p.error
		? `
        background-color: ${RedSidely}25;
        color: ${RedSidely};
        `
		: ''}
    ${({ gap }) => gap ? `gap: ${gap};` : ''}
`;

interface LogoProps {
  src: string
}

const Logo = styled.div<LogoProps>`
    width: 50px;
    height: 50px;
    /*border: 1px solid #EAEAEA;*/
    padding: 5px;
    border-radius: 50%;
    background-image: url('${p => p.src}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
`;

interface HeaderExtendsProps {
  isOpen: boolean
}

const HeaderExtends = styled(DefaultImage) <HeaderExtendsProps>`
    margin-right: auto;
    margin-left: 10px;
    cursor: pointer;
    transition: transform 0.4s;
    transform: rotate(${p => p.isOpen ? '180' : '0'}deg);
    width: 18px;
`;

const Title = styled.p`
    ${DefaultText}
`;

const Body = styled.div<{ overflow?: string, columns?: boolean }>`
    display: flex;
    height: calc(100vh * 0.9 - 45px);
    ${p => p.overflow ? `overflow: ${p.overflow};` : ''}
    ${p => p.columns ? 'flex-flow: column;' : ''}
`;

interface BodyBlockProps {
  flexDirection: string
  isOpen: boolean
  marginLeft?: string
}

const BodyBlock = styled.div<BodyBlockProps>`
    display: ${p => p.isOpen ? 'flex' : 'none'};
    flex-direction: ${p => p.flexDirection};
    margin-left: ${p => p.marginLeft ?? 'initial'};
    width: 85%;
`;

interface BodyLeftProps {
  hasBorderRight: boolean
}

const BodyLeft = styled.div<BodyLeftProps>`
    width: 50%;
    display: flex;
    flex-direction: column;
    border-right: ${p => p.hasBorderRight ? `1px solid ${BorderColor}` : ''};
`;

const BodyRight = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
`;

const BodyHeader = styled.div`
    width: 100%;
    height: 35px;
    border-top: 1px solid ${BorderColor};
    border-bottom: 1px solid ${BorderColor};
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-content: center;
    align-items: center;
`;

interface BodyHeaderStatusProps {
  backgroundColor?: string
  inDropdown?: boolean
}

const BodyHeaderStatus = styled.p<BodyHeaderStatusProps>`
    ${DefaultText}
    color: ${p => p.backgroundColor ? 'white' : 'black'};
    background-color: ${p => p.backgroundColor ?? ''};
    padding: 1px 6px 1px 6px;
    border-radius: 5px;
    font-weight: 400;
    font-size : 11px;
    white-space: nowrap;
    ${p => p.inDropdown
		? `
        margin: 5px;
        cursor: pointer;
        text-align: center;
        width: fit-content;
    `
		: ''}
`;

interface BodyHeaderRowProps {
  justifyContent?: string
}

const BodyHeaderRow = styled.div<BodyHeaderRowProps>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${p => p.justifyContent ?? 'space-around'};
`;

const SelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: #C1ECFF;
    height: 100%;
    padding: 0 10px 0 10px;
    border-radius: 15px;
    cursor: pointer;
`;

const Selector = styled.p`
    ${DefaultText}
`;

interface BodyHeaderCategoryProps {
  selected?: boolean
}

const BodyHeaderCategory = styled.p<BodyHeaderCategoryProps>`
    ${DefaultText}
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${p => p.selected
		? `
    color: black;
    border-bottom: 2.85466px solid ${BlueSidely};
    `
		: ''
}
`;

const BodyMain = styled.div`
    height: 86%;
    padding: 5px;
`;


const BodyMainContainer = styled.div<{ noScroll?: boolean, noHeight?: boolean, width?: string }>`
    width: ${p => p.width ?? '85%'};
    ${p => p.noHeight ? '' : 'height: calc(100vh - 160px);'}
    ${p => p.noScroll ? '' : 'overflow-y: auto'};
`;

const BodyMainRow = styled.div<{ gap?: string }>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    padding-top: 20px;
    ${({ gap }) => gap ? `gap: ${gap};` : ''}
`;

const TagWrapper = styled.div<{ isOpen?: boolean, horizontal?: boolean, center?: boolean, noOverflow?: boolean }>`
    ${p => p.horizontal
		? `
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        ${p.center ? 'justify-content: center;' : ''}
        overflow-x: auto;
        width: 100%;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        align-items: center;
    `
		: `
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: ${p.isOpen ? 'fit-content' : '37px'};
        overflow: ${p.noOverflow ? 'visible' : 'hidden'};
        ${p.center ? 'justify-content: center;' : ''}
        width: 100%;
        align-items: center;
    `}
`;

interface TagBoxContainerProps {
  backgroundColor: string
}

const TagBoxContainer = styled.div<TagBoxContainerProps>`
    border: 1px solid ${p => p.backgroundColor};
    display: flex;
    margin: 3px;
    border-radius: 5px;
    height: 25px;
`;

const ShakingAnimation = keyframes`
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
`;

interface BodyMainImageProps {
  src: string
  backgroundSize?: string
  noBorder?: boolean
  size?: string
  opacity?: string
}

const BodyMainImage = styled.div<BodyMainImageProps>`
    width: ${p => p.size ?? '20px'};
    height: ${p => p.size ?? '20px'};
    background-image: url(${p => p.src});
    background-size: ${p => p.backgroundSize ?? 'cover'};
    background-repeat: no-repeat;
    background-position: center;
    border-radius: ${p => p.noBorder ? '0' : '50%'};
    ${p => p.opacity ? `opacity: ${p.opacity};` : ''}
    flex-shrink:0;

    &:hover{
        // animation: ${ShakingAnimation} 0.4s;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;

interface BodyMainTextProps {
  marginLeft?: string
  cursor?: string
  isLink?: boolean
  error?: boolean
  maxWidth?: string
  display?: string
  noOverflow?: boolean
  fontSize?: string
  fontWeight?: string
  color?: string
  width?: string
  minWidth?: string
  minHeight?: string
  height?: string
}

const BodyMainText = styled.p<BodyMainTextProps>`
    ${DefaultText}
    transition: all 0.4s ease;
height: ${p => p.height ?? '100%'};
    margin-left: ${p => p.marginLeft ?? '20px'};
    cursor: ${p => p.cursor ?? 'auto'};
    display: ${p => p.display ?? 'flex'};
    align-items: center;
    min-width: ${p => p.minWidth ?? '50px'};
    ${p => p.maxWidth ? `max-width: ${p.maxWidth};` : ''}
    ${p => p.width ? `width: ${p.width};` : ''}
    ${p => p.color ? `color: ${p.color};` : ''}
    ${p => p.fontSize ? `font-size: ${p.fontSize};` : ''}
    ${p => p.fontWeight ? `font-weight: ${p.fontWeight};` : ''}
    ${p => p.minHeight ? `min-height: ${p.minHeight};` : 'min-height: 25px;'}


    ${p => p.noOverflow ? '' : `
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
    `}

    &:hover {
        ${p => p.isLink ? `color: ${BlueSidely}` : ''};
    }

    &:empty {
        &:not(:focus) {
            &:before {
                content: '-';
            }
        }
    }
`;

const BodyMainTextEditable = styled(BodyMainText)<{ whiteSpace?: string }>`
    ${DefaultEditable}
    cursor: ${p => p.cursor ?? 'pointer'};
    overflow-y:hidden;
    white-space: ${({ whiteSpace }) => whiteSpace ?? 'break-spaces' };
    word-break:break-word;
    border-bottom: 1px solid transparent;
    &:hover {
        border-color: ${p => p.error ? RedSidely : BlueSidely};
    }
    ${p => p.error
		? `
        &:focus-visible {
            background-color: ${RedSidely}25;
        }
        color: ${RedSidely};
        `
		: ''}
`;

const PenEdition = styled(DefaultImage)<{parent?, hided?: boolean}>`
    transition: opacity 0.4s ease;
    width: 20px;
    height: 20px;
    opacity: 0;
    display: ${p => p.hided ? 'block' : 'none'};
    margin-left: 5px;

    ${BodyMainRow}:hover & {
        opacity: 0.6;
        display:block;
    }

    ${HeaderBlock}:hover & {
        opacity: 0.6;
        display:block;
    }

    ${BodyCell}:hover & {
        opacity: 0.6;
        display:block;
    }

    ${p => p.parent}:hover & {
        opacity: 0.6;
        display:block;
    }
`;

interface ButtonActionProps {
  width?: string
}

const ButtonAction = styled(PenEdition) <ButtonActionProps>`
    cursor: pointer;
    width: ${p => p.width ?? '25px'};
    height: ${p => p.width ?? '25px'};
`;

const LinkCompany = styled(DefaultImage)`
    cursor: pointer;
    width: 15px;
    margin-left: 1%;
`;

const Arrow = styled(DefaultImage)`
    width: 10px;
    height: 10px;
    margin-left: 5px;
    cursor: pointer;
`;

interface BodyMainPointProps {
  color: string
}

const ContainerPoint = styled.div<BodyMainPointProps>`
    background-color: ${p => p.color};
    border-radius: 50%;
`;

const BodyMainPoint = styled(DefaultImage)`
    padding: 10px;
    width: 50px;
    height: 50px;
    filter: brightness(100);
`;


const BodyMainLine = styled.div`
    background-color: #ECEFF1;
    height: 50px;
    width: 5px;
    margin-left: 22px;
`;

const BodyMainLoaderContainer = styled.div`
    height: 30px;
`;

const BodyFooter = styled.div`
    border-top: 1px solid ${BorderColor};
    height: 10%;
`;

const BodyFooterRow = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
`;

const BodyFooterBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 50px;
    width: 100px;
    border-radius: 5px;
    align-content: center;
    align-items: center;
`;

const BodyFooterText = styled.p``;

export {
    Arrow,
    Body,
    BodyBlock, BodyFooter, BodyFooterBlock, BodyFooterRow, BodyFooterText, BodyHeader, BodyHeaderCategory, BodyHeaderRow, BodyHeaderStatus, BodyLeft, BodyMain,
    BodyMainContainer, BodyMainImage, BodyMainLine,
    BodyMainLoaderContainer, BodyMainPoint, BodyMainRow, BodyMainText,
    BodyMainTextEditable, BodyRight, ButtonAction, Container, ContainerPoint, CreationPanel, DetailDots, FullOpen, Header, HeaderBlock, HeaderExtends, HeaderLeft,
    HeaderRight, LeftContainer, LinkCompany, Logo, MiddleContainer, PenEdition, RightContainer, Row, SelectContainer,
    Selector, TagBoxContainer, TagWrapper, Title
};

