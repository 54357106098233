import { URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { FilterQueryResult, FilterTree } from '../../../components_v2/filter/model/Model';
import { decodeReport, Report } from '../interpretor/bareReportingDecoder';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

type Body = {
	product_ids: number[] | undefined;
	user_ids: number[] | undefined;
	end: string;
	filters: { val: FilterQueryResult } | { and: FilterTree[] } | { or: FilterTree[] } | undefined;
	begin: string,
	templates: number[]

}

export async function getPresenceEvolution(body: Body): Promise<Report> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.post<ArrayBufferLike>(`${PATH}/v2/reports/merchandising-per-company-and-per-product`, body, { responseType: 'arraybuffer' })
		.then(res => {
			const u8Array = new Uint8Array(res.data);
			return decodeReport(u8Array);
		});
}

export async function exportPresenceEvolution(body: Body): Promise<void> {
	axios.defaults.headers.common.Authorization = `${token}`;
	await axios.post<ArrayBufferLike>(`${PATH}/v2/export/merchandising-per-company-and-per-product`, body, { responseType: 'arraybuffer' });
}
