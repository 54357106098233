import * as React from 'react';
import styled from 'styled-components';
import { checkClickOut } from '../Dropdown';
import { DropdownData, DropdownStyle } from '../model/Model';


const Container = styled.div<{ innerHeight?: string, innerWidth?: string }>`
	position: relative;
	height: ${p => p.innerHeight ?? '100%'};
	width: ${p => p.innerWidth ?? '100%'};
`;

const OptionContainer = styled.div<{ innerWidth?: string, innerHeight?: string, optionUp: boolean }>`
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-sizing: border-box;
	border-radius: 4px;
	flex-direction: column;
	position: absolute;
	z-index: 100;
	width: ${p => p.innerWidth ? p.innerWidth : 'fit-content'};
	max-height: ${p => p.innerHeight ? p.innerHeight : 'fit-content'};
	overflow-y: auto;
	box-shadow: rgb(207 207 207) 0px 1px 5px;
	${p => p.optionUp ? 'transform: translateY(calc(-100% - 35px));' : ''}
`;

export default function LightDropdown<T = any>(props: {
	JSXButton: (props: {isOpen: boolean, onClick: (event) => void, value?: DropdownData<T>}) => React.ReactNode,
	JSXContent: (props: { value: DropdownData<T>, onClick: () => void }) => React.ReactNode,
	datalist: DropdownData<T>[],
	onChange?: (value: DropdownData<T>) => void,
	selectedValue?: DropdownData<T>,
	dropdownStyle?: DropdownStyle
}) {

	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [value, setValue] = React.useState<DropdownData<T> | undefined>(props.selectedValue);
	const [isOptionUp, setOptionUp] = React.useState<boolean>(props.dropdownStyle?.optionUp ?? false);

	const onChange = (value: DropdownData<T>) => {
		setIsOpen(!isOpen);
		setValue(value);
		props.onChange?.(value);
	};

	const wrapperRef = React.useRef<HTMLDivElement>();

	const setRef = React.useCallback((node: HTMLDivElement) => wrapperRef.current = node, []);

	checkClickOut(wrapperRef, setIsOpen);

	return <Container
		innerWidth={props.dropdownStyle?.containerWidth}
		innerHeight={props.dropdownStyle?.containerHeight}
		innerRef={setRef}
	>
		{props.JSXButton({ isOpen, onClick: (event) => {
			setOptionUp(event.clientY >= (window.innerHeight / 2.0));
			setIsOpen(!isOpen);
		}, value })}
		{isOpen && <OptionContainer innerWidth={props.dropdownStyle?.optionWidth} innerHeight={props.dropdownStyle?.optionHeight} optionUp={isOptionUp}>
			{props.datalist.map(value => props.JSXContent({ value, onClick: () => onChange(value) }))}
		</OptionContainer>}
	</Container>;
}