import {
	CREATE_ENFORM_ERROR,
	CREATE_ENFORM_REQUEST,
	CREATE_ENFORM_SUCCESS,
	DETAIL_SHELF_AUDIT_ERROR,
	DETAIL_SHELF_AUDIT_REQUEST,
	DETAIL_SHELF_AUDIT_SUCCESS,
	DETAIL_TEMPLATE_REQUEST,
	DETAIL_TEMPLATE_SUCCESS,
	FETCH_ENFORM_ERROR,
	FETCH_ENFORM_REQUEST,
	FETCH_ENFORM_SUCCESS,
	FETCH_SHELFAUDIT_INSTANCE_ERROR,
	FETCH_SHELFAUDIT_INSTANCE_REQUEST,
	FETCH_SHELFAUDIT_INSTANCE_SUCCESS,
	GET_ALL_SHELF_AUDIT,
	GET_FIELD_REPORT_INSTALLER,
	GET_LINKED_ENFORM,
	GET_LINKED_ENFORM_REQUEST,
	SET_MODAL,
	SHEL_AUDIT_SELECT,
	UPDATE_ENFORM_ERROR,
	UPDATE_ENFORM_REQUEST,
	UPDATE_ENFORM_SUCCESS
} from '../actions/enForm.action';

import { defaultPagination } from '../../helpers/textConfig';

const initState = {
	allShelfAudit: [],
	isLoading: false,
	isLoadingDetail: false,
	dataDetail: null,
	data: [],
	detailTemplate: null,
	shelfAuditSelects: [],
	modal: {
		status: 'create',
		isOpen: false
	},
	pagination: { ...defaultPagination() },
	fieldInstaller: []
};

export default function CompaniesReducer(state = initState, action) {
	switch (action.type) {
		case GET_ALL_SHELF_AUDIT:
			return {
				...state,
				allShelfAudit: action.payload
			};
		case FETCH_ENFORM_REQUEST:
			return {
				...state,
				isLoading: true,
				data: []
			};
		case FETCH_ENFORM_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.meta.current_page,
					lastPage: action.payload.meta.last_page,
					count: action.payload.meta.count,
					recordPerPage: action.payload.meta.record_per_page
				},
				isLoading: false
			};
		case FETCH_ENFORM_ERROR:
			return {
				...state,
				data: [],
				isLoading: false
			};
		case FETCH_SHELFAUDIT_INSTANCE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_SHELFAUDIT_INSTANCE_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.meta.current_page,
					lastPage: action.payload.meta.last_page,
					count: action.payload.meta.count,
					recordPerPage: action.payload.meta.record_per_page
				},
				isLoading: false
			};
		case FETCH_SHELFAUDIT_INSTANCE_ERROR:
			return {
				...state,
				data: [],
				isLoading: false
			};
		case CREATE_ENFORM_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_ENFORM_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_ENFORM_ERROR:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_ENFORM_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_ENFORM_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_ENFORM_ERROR:
			return {
				...state,
				isLoading: false
			};
		case DETAIL_SHELF_AUDIT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case DETAIL_SHELF_AUDIT_SUCCESS:
		{
		// console.log(action)
			return {
				...state,
				dataDetail: action.payload,
				isLoading: false
			};
		}
		case DETAIL_SHELF_AUDIT_ERROR:
			return {
				...state,
				isLoading: false
			};
		case SET_MODAL:
			return {
				...state,
				modal: action.payload
			};
		case DETAIL_TEMPLATE_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_TEMPLATE_SUCCESS:
			return {
				...state,
				isLoadingDetail: false,
				detailTemplate: action.payload.data
			};
		case GET_FIELD_REPORT_INSTALLER:
			return {
				...state,
				fieldInstaller: action.payload.data
			};
		case SHEL_AUDIT_SELECT:
			return {
				...state,
				shelfAuditSelects: action.payload.data
			};
		case GET_LINKED_ENFORM_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_LINKED_ENFORM:
		{
			console.log('GET_LINKED_ENFORM');
			return {
				...state,
				data: action.payload ? action.payload : [],
				// pagination: {
				//     currentPage: action.payload.meta.current_page,
				//     lastPage: action.payload.meta.last_page,
				//     count: action.payload.meta.count,
				//     recordPerPage: action.payload.meta.record_per_page
				// },
				isLoading: false
			};
		}
		default:
			return state;
	}
}
