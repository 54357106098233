/* eslint-disable no-underscore-dangle */
import React, {Component} from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

class DateField extends Component {
  state = {};

  render() {
    const {
      input,
      label,
      noLabel,
      requiredStar,
      id,
      style,
      disabled,
      addClass,
      readOnly,
      meta: { touched, error, warning }
    } = this.props;

    return (
      <div>
        <div>
          <div className="form-group row">
            {!noLabel && (
              <div className={`col-md-3 text-right mt-2 ${addClass}`}>
                <label htmlFor={id} style={style}>
                  {requiredStar === true ? (
                    <span className="text-danger">* </span>
                  ) : (
                    ''
                  )}
                  {label}
                </label>
              </div>
            )}

            <div className="col-md-9 my-auto">
              <div className="row">
                <div className="col-12">
                  <DatePicker
                    {...input}
                    className={`form-control ${
                      touched && error ? 'focus-error' : ''
                    }`}
                    popperPlacement="botom"
                    popperClassName="custom-popper"
                    selected={
                      input.value ? moment(input.value).valueOf() : null
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    showYearDropdown
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-12">
                  {touched &&
                    ((error && <span className="form-error">{error}</span>) ||
                      (warning && (
                        <span className="form-error">{warning}</span>
                      )))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DateField;
