import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { BlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { Radio } from '../../filterList/style/Style';
import { FilterAction, FilterAdditionalValue, FilterId, FilterQueryResult, FilterTree, FilterValueResult } from '../model/Model';
import { Container, FilterText, RadioContainer } from '../style/AdvancedFilterStyle';
import { filterIdFormater } from './FilterSelection';
import { FilterToIndex } from './utils';

function indexToFilter(index: number | undefined): FilterAction | undefined {
	switch (index) {
		case 0:
			return FilterAction.IS_FALSE;
		case 1:
			return FilterAction.IS_TRUE;
		case 2:
			return FilterAction.IS_EMPTY;
		case 3:
			return FilterAction.IS_NOT_EMPTY;
		default:
			return;
	}
}

function switchFilterToIndex(filter: FilterAction | undefined): number | undefined {
	switch (filter) {
		case FilterAction.IS_FALSE:
			return 0;
		case FilterAction.IS_TRUE:
			return 1;
		case FilterAction.IS_EMPTY:
			return 2;
		case FilterAction.IS_NOT_EMPTY:
			return 3;
		default:
			return;
	}
}

function BoolFilter(props: {
  id: FilterId
  title: string
  onReturn: () => void
  onSubmit: (value: FilterValueResult) => void
  additionalValue?: FilterAdditionalValue
  selectedValue: FilterTree | undefined
}): JSX.Element {
	let selectedValue: FilterQueryResult | undefined;
	if (props.selectedValue && 'val' in props.selectedValue) {
		selectedValue = props.selectedValue.val;
	}
	const [index, setindex] = React.useState<number | undefined>(FilterToIndex(selectedValue?.operator, switchFilterToIndex));
	const id = filterIdFormater(props.id, props.additionalValue);

	function submit(): void {
		const action = indexToFilter(index);
		if (!action) return;
		props.onSubmit({
			action,
			type: 'bool',
			title: props.title,
			id,
			value: index === 1,
		});
	}

	return (
		<Container>
			<FilterText color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.onReturn()}>&lt;<Translate id='back' /></FilterText>
			<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{props.title}</FilterText>
			<RadioContainer onClick={() => setindex(1)}>
				<Radio isActive={index === 1} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_true' /></FilterText>
			</RadioContainer>
			<RadioContainer onClick={() => setindex(0)}>
				<Radio isActive={index === 0} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_false' /></FilterText>
			</RadioContainer>
			<RadioContainer onClick={() => setindex(2)}>
				<Radio isActive={index === 2} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_empty' /></FilterText>
			</RadioContainer>
			<RadioContainer onClick={() => setindex(3)}>
				<Radio isActive={index === 3} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not_empty' /></FilterText>
			</RadioContainer>
			<DefaultButton width="125px" disabled={index === undefined} onClick={() => submit()}><Translate id='add_filter' /></DefaultButton>
		</Container>
	);
}

export default BoolFilter;
