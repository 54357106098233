import * as React from 'react';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { get_products, getAllProducts, postAssortment } from './action';
import { Assortment, Product } from './model';
import { ProductsAssignation } from './assortmentProductCreation';
import { getTranslate, Translate } from 'react-localize-redux';
import { Spacer } from '../client-companies/style/NewCompanyStyle';
import Input from '../../components_v2/input/Input';
import storeLang from '../../helpers/storeLang';
import DropdownFormIcon from '../../components_v2/dropdown/DropdownFormIcons';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { TableSortType } from '../../components_v2/table/Table';
import { FlexDiv } from './style';
import styled from 'styled-components';

const DEFAULT_LIMIT = 10;

const DropdownContainer = styled.div`
    margin-left: 10%;
    width: fit-content;
`;

export default function AssortmentCreation(props: {
  isOpen: boolean
  setIsOpen: (b: boolean) => void
  refresh: () => void
  assortment?: Assortment
}): JSX.Element {
	const { isOpen, setIsOpen, refresh } = props;

	const [products, setProducts] = React.useState<Product[]>([]);
	const [baseProducts, setBaseProducts] = React.useState<Product[]>([]);
	const [iconRefresh, setIconRefresh] = React.useState<object>({});
	const [name, setName] = React.useState<string>('');
	const [icon, setIcon] = React.useState<DropdownData>();
	const [pagination, setPagination] = React.useState({ step: DEFAULT_LIMIT, offset: 0 });
	const [sort, setSort] = React.useState<TableSortType>();
	const translate = getTranslate(storeLang.getState().localize);
	const [savedProducts, setSavedProducts] = React.useState<Product[]>([]);

	const setProductsAndSave = (actualProducts: Product[]) => {
		let newSavedProducts = [...savedProducts];

		newSavedProducts = newSavedProducts
			.map(p => ({ ...(actualProducts.find(ap => ap.id == p.id) ?? p) }))
			.filter(p => actualProducts.find(ap => ap.id == p.id)?.checked ?? true);

		actualProducts.filter(p => p.checked).forEach(product => {
			if (!newSavedProducts.some(sp => sp.id == product.id)) {
				newSavedProducts.push({ ...product });
			}
		});

		setSavedProducts([...newSavedProducts]);
		setProducts(actualProducts);
	};

	React.useEffect(() => {
		get_products({
			limit: pagination.step,
			offset: pagination.offset,
			sort
		})
			.then(res => {
				const products = res.data.products.map(p => {
					const savedProduct = savedProducts.find(sp => sp.id == p.id);
					return {
						...p,
						price: savedProduct?.price ?? parseFloat(p.price ?? '0'),
						recommended_price: savedProduct?.recommended_price ?? parseFloat(p.recommended_price ?? '0'),
						checked: savedProduct?.checked
					};
				});
				setProducts(products);
				setBaseProducts(products);
			})
			.catch(console.log);
	}, [sort, pagination]);

	React.useEffect(() => {
		if (props.assortment?.id) {
			const assormtent: Assortment = props.assortment;
			getAllProducts(props.assortment?.id)
				.then(res => {
					const mapedRes = res.data.map(p => ({
						...p,
						price: parseFloat(p.assortment_price ?? '0'),
						recommended_price: parseFloat(p.assortment_recommended_price ?? '0'),
						checked: true
					}));

					mapedRes.forEach(product => {
						const index = products.findIndex(p => p.id == product.id);
						if (index >= 0) {
							products[index] = { ...products[index], ...product };
						}
					});

					setProducts([...products]);
					setSavedProducts([...mapedRes]);
					setName(assormtent.name);
				})
				.catch(console.log);
		}
	}, [props.assortment]);

	const onsubmit = () => {
		const body = {
			products: savedProducts.map(p => ({
				id: p.id,
				distributor_reference: p.distributor_reference,
				price: p.price,
				recommended_price: p.recommended_price
			})),
			name
		};
		if (icon?.value?.content) {
			body.icon = {
				content: icon?.value?.content,
				name: icon?.value?.file?.name
			};
		} else {
			body.icon_id = icon?.value;
		}

		postAssortment(body)
			.then(() => {
				refresh();
				setIsOpen(false);
				setName('');
				setIcon(undefined);
				setProducts([...baseProducts]);
				setIconRefresh({});
			})
			.catch(console.log);
	};

	return <Popup
		isOpen={isOpen}
		onClickOut={() => setIsOpen(false)}
		popupStyle={{ animate: true, height: '100vh', top: '0' }}
		popupMode={PopupMode.Extended}
	>
		<PopupCreation
			title={<Translate id='assortment_creation' />}
			canValidate={name.trim().length > 0 && savedProducts.length > 0 && icon != undefined}
			onSubmit={onsubmit}
			onClose={() => setIsOpen(false)}
		>
			<FlexDiv flow='column' align='stretch'>
				<Spacer height={1} />
				<DropdownContainer>
					<DropdownFormIcon
						dropdownStyle={{ width: '280px', optionWidth: '280px', labelUp: true, height: '40px', optionHeight: '200px', margin: '0' }}
						label={<Translate id='Icon' />}
						required
						onChange={setIcon}
						refresh={iconRefresh}
						iconId={props.assortment?.icon_id}
					/>
				</DropdownContainer>
				<Spacer height={1} />
				<DropdownContainer>
					<Input
						inputStyle={{ width: '280px', labelUp: true, padding: '.56rem 0.75rem', marginLeft: '0' }}
						name="assortment_name"
						type="text"
						placeholder={translate('name') as string}
						label={<Translate id='name' />}
						required
						value={name}
						onChange={setName}
					/>
				</DropdownContainer>
				<Spacer height={2.5} />
				<ProductsAssignation
					products={products}
					setProducts={setProductsAndSave}
					setSort={setSort}
					sort={sort}
					setPagination={setPagination}
					pagination={pagination}
					limit={DEFAULT_LIMIT}
				/>
			</FlexDiv>
		</PopupCreation>
	</Popup>;
}
