import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
	forceRefresh: true
});

export const browserGoBack = () => {
	history.goBack();
};

export const browserGo = n => {
	history.go(n);
};
