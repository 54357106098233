import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';
import { ClientCompanyEdit } from '../../containers_v2/client-companies/model/Model';
import { Event } from '../../containers_v2/calendar/model/Model';
import { TagEditParams } from '../../components_v2/toolbarFilter/tags/Model';
import { UpdatedField } from '../../../../web-types/api';
import { ClientCompany } from '../../containers_v2/orders/model/Model';

export type ACompanyEditionType = (ClientCompanyEdit & Omit<TagEditParams, 'ressource_id'> & {
  event?: Event
  parentCompany?: UpdatedField<ClientCompany>
}) | { deleted: number }

const AGlobalCompanyEdition: AtomState<ACompanyEditionType | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_company_edition', // UNIQUE ID
		default: undefined
	})
};

const ACompanyEdition: RecoilState<ACompanyEditionType | undefined> = selector({
	key: 'atom_company_edition',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<ACompanyEditionType | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
		default:
			return AGlobalCompanyEdition.atom;
	}
}

export {
	AGlobalCompanyEdition,
	ACompanyEdition
};
