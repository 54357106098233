import React, {Component, Fragment} from 'react';
import {change, Field, reduxForm, reset} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {renderField, required, textAreaField} from '../../../../../components/FieldModal';
import {createNote, editNote, getNoteDetail} from '../../../../../store/actions/contacts.action';
import {CompleteTime} from '../../../../../helpers/time';
import {normalizeCapitalize} from '../../../../../helpers/fieldMask';
import {Translate} from 'react-localize-redux';

class ModalNoteForm extends Component {
  state = {};

  componentWillReceiveProps(nextProps) {
    const { detail, dispatch, getNoteDetail, id } = this.props;
    const initInput = ['title', 'body'];

    if (id.noteId !== nextProps.id.noteId) {
      if (nextProps.id.noteId) {
        getNoteDetail(nextProps.id.noteId);
      } else {
        dispatch(reset('modalNoteForm'));
      }
    }

    if (detail !== nextProps.detail) {
      initInput.map(data => {
        dispatch(change('modalNoteForm', data, nextProps.detail[data]));
        return null;
      });
    }
  }

  onSubmit = value => {
    const { createNote, editNote, id } = this.props;
    const formData = new FormData();

    const keys = ['title', 'body'];

    keys.map(key => {
      value[key] && formData.append(key, value[key].value || value[key]);
      return null;
    });

    formData.append('contactId', id.contactId);

    if (id.noteId) {
      editNote(formData, id.noteId, id.contactId);
    } else {
      createNote(formData, id.contactId);
    }
  };

  render() {
    const {
      handleSubmit,
      isLoading,
      contactId,
      penUrl,
      trashUrl,
      onChangeMode,
      onDelete,
      onHover,
      detail,
      mode,
      id
    } = this.props;
    // console.log('detail', detail);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader toggle={this.props.toggle}>
          {`${mode} Note`}
          {id.noteId && (
            <div className="positionButton d-flex flex-row">
              <button
                className="btn-edit-modal"
                onClick={() => onChangeMode('Edit')}
              >
                <img
                  src={penUrl}
                  alt=""
                  width="13px"
                  height="13px"
                  className="mr-2"
                  onMouseEnter={() => onHover('pen_blue.svg')}
                  onMouseLeave={() => onHover('pen_black.svg')}
                />
              </button>
              <button
                className="btn-edit-modal"
                onClick={() => onDelete(detail.id, contactId)}
              >
                <img
                  src={trashUrl}
                  alt=""
                  width="13px"
                  height="13px"
                  onMouseEnter={() => onHover(null, 'trash_blue.svg')}
                  onMouseLeave={() => onHover(null, 'trash_black.svg')}
                />
              </button>
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <form
            className="forms-sample background-form"
            onSubmit={handleSubmit(this.onSubmit)}
            style={{ boxShadow: 'none' }}
          >
            <Fragment>
              <div className="row">
                <div className="col-md-11">
                  <div className="row">
                    <div className="col-md-12">
                      <Field
                        name="title"
                        type="text"
                        component={renderField}
                        label={<Translate id="title" />}
                        placeholder="Enter Title"
                        requiredStar
                        readOnly={mode === 'View'}
                        validate={[required]}
                        normalize={normalizeCapitalize}
                      />
                      <Field
                        name="body"
                        type="text"
                        component={textAreaField}
                        label="Description"
                        placeholder="Enter Description"
                        requiredStar
                        readOnly={mode === 'View'}
                        validate={[required]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4" />
              </div>
            </Fragment>
          </form>
        </ModalBody>
        <ModalFooter className={mode === 'View' && 'justify-content-start'}>
          {mode === 'View' ? (
            <div>
              <p className="text-by word-break mb-0">
                {`by ${detail.user && detail.user.name} on ${CompleteTime(
                  detail.createdAt
                )}`}
              </p>
            </div>
          ) : (
            <Button
              color="primary"
              onClick={handleSubmit(this.onSubmit)}
              loading={isLoading}
              spinColor="#003d79"
            >
              {!isLoading && <Translate id="save" />}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.companies.isLoading,
  detail: state.companies.noteDetail
});

export default reduxForm({
  form: 'modalNoteForm'
})(
  connect(
    mapStateToProps,
    { createNote, editNote, getNoteDetail }
  )(ModalNoteForm)
);
