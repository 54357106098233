/* eslint-disable camelcase */
import React, { Component } from 'react';
import Select from 'react-select';
import { colourStyles } from '../../../../../../helpers/colorStyle';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../../../../helpers/storeLang';

class PropDropdownList extends Component {
	state = {
		value: this.props.values ?? []
	};

	componentDidMount() {
		const { options, element, fieldTemplate, values } = this.props;
		const key = element && element.values || [];
		const fieldId = element && element.shelf_audit_field_id || -1;
		if (values?.length > 0) {
			this.setState({ value: values });
		} else if (key.length > 0 && fieldId != -1) {
			const value = options.filter(c => fieldTemplate.find(t => t.shelf_audit_field_id === fieldId && Array.isArray(t.values) && t.values.includes(c.value)) != undefined);
			this.setState({ value: value });
		}
	}

	setValue = value => {
		const newValue = value ?? [];
		const val = newValue.map(x => x.value);
		this.props.editElementProp('values', 'values', val);
		this.setState({ value: newValue });
	};

	render() {
		const translate = getTranslate(storeLang.getState().localize);
		const { options, element } = this.props;

    

		return (
			<div>
				<div className="form-group">
					<label className="control-label" htmlFor="">
            Validation
					</label>
				</div>

				<div className="form-group">
					<div className="custom-control custom-checkbox">
						<input
							id="is-required"
							className="custom-control-input"
							type="checkbox"
							defaultChecked={this.props.element.mandatory}
							onBlur={this.props.updateElement.bind(this)}
							disabled={this.props.isEdit}
							onChange={e =>
								this.props.editElementProp(
									'mandatory',
									'checked',
									e.target.checked
								)}
						/>
						<label className="custom-control-label" htmlFor="is-required">
							<Translate id="Field required" />
						</label>
					</div>
				</div>

				<div className="form-group col-12">
					<label className="control-label" htmlFor="optionSelect">
						<Translate id="Initial Value" />
					</label>
					<Select
						options={options}
						placeholder={translate('Select')}
						optionClassName="needsclick"
						isMulti
						id="optionSelect"
						className="form-control p-0 border-0"
						onChange={e => this.setValue(e)}
						value={this.state.value}
						styles={colourStyles}
						isDisabled={this.props.isEdit}
						onBlur={this.props.updateElement.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

export default PropDropdownList;
