/* eslint-disable no-unused-vars */
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import renderSelectInput from './select/RenderSelectInput';
import renderSelectInputIcon from './select/RenderSelectInputIcon';
import renderSelectInpuNoLabel from './select/RenderSelectInputNoLabel';

export const required = value => (value ? undefined : 'Fill The Field');
export const validateNumber = value => (value && /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/.test(value));
export const validateIntegerNumber = value => !isNaN(parseInt(value));
export const validatePositiveNumber = value => parseInt(value) >= 0;
export const validateSpace = value =>
	value.match(/[a-z]/i) === null && 'Fill The Field';

export const emailValid = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
		? 'Invalid email address'
		: undefined;

export const maxLength = max => value =>
	value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = min => value =>
	value && value.length < min ? `Must be ${min} characters or less` : undefined;

export const maxLength300 = maxLength(300);

export const minLength8 = minLength(8);

export const minLength6 = minLength(6);
export const minLength1 = minLength(1);

export const normalizeComma = value => value.replace(',', '.');

export const normalizeAddZero = value => value.length > 0 && (value.startsWith(',') || value.startsWith('.')) ? '0' + value : value;

export const normalizeEmptyNumber = value => value.length === 0 ? '0' : value;

export const normalizeRemoveStartZero = value => {
	let v = value;
	while (v.startsWith('0')) {
		v = v.slice(1);
	}
	return v;
};

export const minValue0 = value => {
	if (value < 0) {
		return 0;
	} else {
		return value;
	}
};

export const minValue1 = value => {
	if (value < 1) {
		return 1;
	} else {
		return value;
	}
};

export function formatNumber(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function formatNumberComma(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const normalizeNumber = value => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');
	return formatNumber(onlyNums);
};

export const lessThan = otherField => (value, previousValue, allValues) =>
	value && parseFloat(value) < parseFloat(allValues.values[otherField])
		? undefined
		: `value must not be greater than ${otherField}`;

export const greaterThan = otherField => (value, previousValue, allValues) =>
	value && parseFloat(value) > parseFloat(allValues.values[otherField])
		? undefined
		: `value cannot be lower than ${otherField}`;

export const characterEachWord = str => {
	const matches = str.match(/\b(\w)/g);
	return <div>{matches.join('')}</div>;
};

export const renderField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	style,
	readOnly,
	maxLength,
	min,
	max,
	arrowDown,
	w100,
	meta: { touched, error, warning }
}) => (
	<div style={style}>
		<div className="form-group row">
			{!noLabel && (
				<div className="col-md-3 text-right mt-2">
					<label className="text-capitalize" htmlFor={id} style={style}>
						{requiredStar === true ? (
							<span className="text-danger">* </span>
						) : (
							''
						)}
						{label}
					</label>
				</div>
			)}

			<div
				className={`my-auto ${w100 !== undefined ? 'col-md-11' : 'col-md-9'}`}
			>
				<div className="row">
					<div className="col-12">
						<input
							{...input}
							placeholder={placeholder}
							type={type}
							readOnly={readOnly}
							disabled={disabled}
							id={id}
							onChange={value => {
								input.onChange(value);
							}}
							maxLength={maxLength && maxLength}
							className={`form-control ${touched && error ? 'focus-error' : ''
							}`}
							min={min}
							max={max}
						/>
						{arrowDown && (
							<div style={{ position: 'absolute', top: '8px', right: '25px' }}>
								<i className="fas fa-angle-down"></i>
							</div>
						)}
					</div>
					<div className="col-12">
						{touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}
					</div>
				</div>
			</div>
		</div>
	</div>
);

export const inputField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	onChange,
	style,
	meta: { touched, error, warning }
}) => {
	return (
		<div>
			<div className="form-group row">
				{label !== undefined && (
					<div className="col-md-5 text-right my-auto">
						<label htmlFor={id} style={style}>
							{requiredStar === true ? (
								<span className="text-danger">* </span>
							) : (
								''
							)}
							{label}
						</label>
					</div>
				)}

				<div
					className={
						label !== undefined ? 'col-md-7 my-auto' : 'col-md-10 my-auto'
					}
				>
					<div className="row">
						<div className="col-12">
							<input
								{...input}
								placeholder={placeholder}
								type={type}
								disabled={disabled}
								id={id}
								// onChange={value => {
								//   input.onChange(value);
								//   onChange(value);
								// }}
								// onFocus={type === 'date'}
								// onBlur={type === 'text'}
								className={`form-control ${touched && error ? 'focus-error' : ''
								}`}
							/>
						</div>
						<div className="col-12">
							{touched &&
                ((error && <span className="form-error">{error}</span>) ||
                  (warning && <span className="form-error">{warning}</span>))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const renderCheckField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	onChange,
	style,
	readOnly,
	meta: { touched, error, warning }
}) => (
	<div style={style}>
		<div className="form-group row">
			{!noLabel && (
				<div className="col-md-3 text-right">
					<label htmlFor={id} style={style}>
						{requiredStar === true ? (
							<span className="text-danger">*</span>
						) : (
							''
						)}
						{label}
					</label>
				</div>
			)}

			<div className="col-md-9 my-auto">
				<div className="row">
					<div className="col-1 my-auto">
						<input
							{...input}
							placeholder={placeholder}
							type={type}
							disabled={disabled}
							id={id}
							className={`form-control ${touched && error ? 'focus-error' : ''
							}`}
							readOnly={readOnly}
							style={{ width: '20px' }}
						/>
					</div>
					<div className="col-11 text-left">
						<label>{placeholder}</label>
					</div>
					<div className="col-12">
						{touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}
					</div>
				</div>
			</div>
		</div>
	</div>
);

export const renderEmailField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group row">
			<div className="col-md-3 text-right mt-2">
				<div className="">
					{!noLabel && (
						<label htmlFor={id}>
							{label}
							{requiredStar === true ? (
								<span className="text-required">* </span>
							) : (
								''
							)}
						</label>
					)}
				</div>
				<div className="">
					{' '}
					{touched &&
            ((error && error !== 'Required' && (
            	<span
            		className={`form-error ${error === 'email tersedia' ? 'text-green' : null
            		}`}
            	>
            		{error}
            	</span>
            )) ||
              (warning && <span className="form-error">{warning}</span>))}
				</div>
			</div>

			<div className="col">
				<div
					className={`form-control-email ${touched && error !== 'email tersedia' ? 'focus-error' : ''
					} col`}
				>
					<input
						{...input}
						placeholder={placeholder}
						type={type}
						disabled={disabled}
						id={id}
						className="form-control-subEmail"
					/>
					{touched && error === 'email tersedia' && (
						<div className="circle-green">
							<i className="fas fa-check text-white" />
						</div>
					)}
				</div>
			</div>
		</div>
	</div>
);

export const generatePassword = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	requiredStar,
	generate,
	id,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<label htmlFor={id}>
				{label}
				{requiredStar === true ? <span className="text-required"> *</span> : ''}
			</label>
			<div className="d-flex">
				<input
					{...input}
					placeholder={placeholder}
					type={type}
					disabled={disabled}
					id={id}
					className="form-control"
				/>
				<button className="btn btn-xs ml-2 btn-primary" onClick={generate}>
          Generate
				</button>
			</div>
			{touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const checkField = ({
	input,
	disabled,
	label,
	id,
	noLabel,
	activeCheck,
	checked,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group row">
			<div className="col-2 mr-4" />
			<div className="form-check col-8">
				<label className="form-check-label pl-4">
					<input
						{...input}
						checked={!activeCheck ? input.value === true : checked}
						disabled={disabled}
						type="checkbox"
						className="form-check-input"
					/>
					{!noLabel && label}
					<i className="input-helper" />
				</label>
			</div>
		</div>

		{touched &&
      ((error && <span className="form-error">{error}</span>) ||
        (warning && <span className="form-error">{warning}</span>))}
	</div>
);

export const selectField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	children,
	requiredStar,
	id,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group row">
			<div className="col-md-3">
				<label htmlFor={id}>
					{requiredStar === true ? (
						<span className="text-required text-danger">* </span>
					) : (
						''
					)}
					{label}
				</label>
			</div>
			<div className="col">
				<select
					{...input}
					type={type}
					disabled={disabled}
					id={id}
					placeholder={placeholder}
					className="form-control"
				>
					{placeholder && (
						<option disabled value="">
							{placeholder}
						</option>
					)}
					{children}
				</select>
				{touched &&
          ((error && <span className="form-error">{error}</span>) ||
            (warning && <span className="form-error">{warning}</span>))}
			</div>
		</div>
	</div>
);

export { renderSelectInput };
export { renderSelectInputIcon };
export { renderSelectInpuNoLabel };

export const textAreaField = ({
	input,
	placeholder,
	type,
	label,
	id,
	disabled,
	requiredStar,
	rows,
	disableLabel,
	countText,
	readOnly,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group row">
			<div className="col-md-3 text-right">
				{!disableLabel && (
					<label htmlFor={id}>
						{requiredStar === true ? (
							<span className="text-required text-danger">* </span>
						) : (
							''
						)}
						{label}
					</label>
				)}
				<label
					htmlFor=""
					className={`d-flex ml-auto ${countText < 0 ? 'text-red' : ''}`}
				>
					{countText}
				</label>
			</div>
			<div className="col-md-9">
				<div className="row">
					<div className="col-12">
						<TextareaAutosize
							{...input}
							placeholder={placeholder}
							type={type}
							disabled={disabled}
							readOnly={readOnly}
							className={`form-control ${touched && error ? 'focus-error' : ''
							}`}
							rows={rows || 5}
						/>
					</div>
				</div>
				<div className="col-12">
					{touched &&
            ((error && error !== 'Required' && (
            	<span className="form-error">{error}</span>
            )) ||
              (warning && <span className="form-error">{warning}</span>))}
				</div>
			</div>
		</div>
	</div>
);

export const textAreaFieldV2 = ({
	input,
	placeholder,
	type,
	label,
	id,
	disabled,
	requiredStar,
	rows,
	disableLabel,
	countText,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="d-flex w-100">
				{!disableLabel && (
					<label htmlFor={id}>
						{label}
						{requiredStar === true ? (
							<span className="text-required"> *</span>
						) : (
							''
						)}
					</label>
				)}
			</div>
			<textarea
				{...input}
				placeholder={placeholder}
				type={type}
				rows={rows}
				disabled={disabled}
				className={`form-control ${touched && error ? 'focus-error' : ''}`}
			/>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const renderFieldRow = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	requiredStar,
	id,
	disableLabel,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="row">
				{!disableLabel && (
					<div className="col-lg-4">
						<label htmlFor={id}>
							{label}
							{requiredStar === true ? (
								<span className="text-required"> *</span>
							) : (
								''
							)}
						</label>
					</div>
				)}
				<div className="col-lg-8">
					<input
						{...input}
						placeholder={placeholder}
						type={type}
						disabled={disabled}
						id={id}
						className={`form-control ${touched && error ? 'focus-error' : ''}`}
					/>
				</div>
			</div>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const renderFieldRowCustom = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	requiredStar,
	id,
	disableLabel,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="row">
				{!disableLabel && (
					<div className="col-lg-5">
						<label htmlFor={id}>
							{label}
							{requiredStar === true ? (
								<span className="text-required"> *</span>
							) : (
								''
							)}
						</label>
					</div>
				)}
				<div className="col-lg-7" style={{ paddingLeft: 25, paddingRight: 0 }}>
					<input
						{...input}
						placeholder={placeholder}
						type={type}
						disabled={disabled}
						id={id}
						className={`form-control ${touched && error ? 'focus-error' : ''}`}
					/>
				</div>
			</div>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const renderFieldValidation = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	meta: { asyncValidating, touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="d-flex justify-content-between">
				{!noLabel && (
					<label htmlFor={id}>
						{label}
						{requiredStar === true ? (
							<span className="text-required"> *</span>
						) : (
							''
						)}
					</label>
				)}
				<span className="form-error">Warning</span>
				{/* {touched &&
          ((error && error !== 'Required' && (
            <span className="form-error">{error}</span>
          )) ||
            (warning && <span className="form-error">{warning}</span>))} */}
			</div>

			<input
				{...input}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				id={id}
				className={`form-control ${touched && error ? 'focus-error' : ''}`}
			/>
		</div>
	</div>
);