import * as React from 'react';
import { useEffect, useState } from 'react';
import { ComponentProps } from '../../globals/mainPage/mainPage';
import { useParams } from 'react-router';
import { ShelfAuditSimple } from '../model/models';
import { deleteShelfAudit, getShelfAuditsQueryByTemplateId } from '../actions';
import { Column, Table, TableSortType } from '../../../components_v2/table/Table';
import { Translate } from 'react-localize-redux';
import {
	TableRow,
	TableRowDnContainer,
	TableRowDnEvolutionContainer,
	TableRowTitle
} from '../../orders/templateOrders/style/Style';
import ShelfAuditPopup from '../popup/ShelfAuditPopup';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import * as moment from 'moment/moment';
import { LoadingStateEnum } from '../../import/model';
import { FlexDiv } from '../../products/style';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import Pagination from '../../../components_v2/pagination/Pagination';
import { translateToString } from '../../../styles/global/translate';
import { PaginationResult } from '../../../components_v2/pagination/model/Model';
import { PartialOpen } from '../../client-companies/style/Style';
import eye from 'images/icons/company/eye.svg';
import system_setting_icon from 'images/setting_icons/system_setting_icon.svg';
import optionGrey from 'images/icon/options_grey.png';
import { TableContext } from '../../contact/data/ContactContext';
import ClientCompany from '../../../components_v2/clientCompany/ClientCompany';
import { ModalState } from '../../products/model';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { AlertContext, AlertRes } from '../../alert/AlertProvider';
import { AlertProps, AlertPropsDelete } from '../../alert/Alert';
import Restricted from '../../../containers_v2/permissions/Restricted';
interface TableEvolutionRow {
	dn: number,
	dn_evolution: number | null,
}

export function DnRow({ dn, dn_evolution }: TableEvolutionRow) {
	const dnEvolutionPercent = (Math.abs((dn_evolution ?? 0) * 100)).toFixed(2);
	return <FlexDiv gap="10px">
		<TableRowDnContainer dn={dn}>{(dn * 100).toFixed(2)}%</TableRowDnContainer>
		<TableRowDnEvolutionContainer dnEvolution={dn_evolution}>
			{dn_evolution && dn_evolution > 0 ? <span>&#8593;</span> : undefined}
			{dn_evolution && dn_evolution < 0 ? <span>&#8595;</span> : undefined}
			{!dn_evolution ? '= -' : dnEvolutionPercent}%
		</TableRowDnEvolutionContainer>
	</FlexDiv>;
}


const DEFAULT_STEP = 25;
const DEFAULT_OFFSET = 0;


interface deleteShelfAuditPopupProps {
	alertDelete: (p: AlertPropsDelete & { name: string }) => Promise<AlertRes>,
	alert: (p: AlertProps) => Promise<AlertRes>,
	id: number,
	name: string,
	onDelete: () => void,
}
function deleteShelfAuditPopup({ alertDelete, alert, id, name, onDelete }: deleteShelfAuditPopupProps) {
	alertDelete({
		zIndex: 201,
		name
	}).then(result => {
		if (result === AlertRes.Ok)
			deleteShelfAudit(id).then(_ => {
				onDelete();
			}).catch(_ => alert({ title:'401', content:'Unauthorized' }));
	});
}

function ShelfAuditTemplate(props: ComponentProps) {
	const { setToolBarState } = props;
	const context = React.useContext(TableContext);
	const { filterResult, sortColumns, setFilterResult } = context;
	const [loadingState, setLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [shelfAudits, setShelfAudits] = useState<ShelfAuditSimple[]>([]);

	const [shelfAuditModal, setShelfAuditModal] = useState<ModalState>({ isOpen: false });
	const [clientCompanyModal, setClientCompanyModal] = useState<ModalState>({ isOpen: false });

	const [sort, setSort] = useState<TableSortType>({ id: 'date', desc: true });
	const [pagination, setPagination] = React.useState<PaginationResult | undefined>(undefined);

	const refresh = () => setPagination(pagination => pagination ? ({ ...pagination }) : undefined);


	const { id } = useParams();
	const users = useRecoilValue(AUsers);

	const { alertDelete, alert } = React.useContext(AlertContext);

	// TODO: this code is temporal solution before put advanced filter
	const queryParams = new URLSearchParams(window.location.search);
	const legacyClientCompanyId = queryParams.get('client_company_id');

	useEffect(() => {
		setToolBarState({
			title: shelfAudits[0]?.name,
		});
	}, [shelfAudits]);
	useEffect(() => {
		getShelfAuditsQueryByTemplateId(id, {
			limit: pagination?.step ?? DEFAULT_STEP,
			offset: pagination?.offset ?? 0,
			order_by: sort?.id ?? 'date',
			descending: sort?.desc ?? true,
			filters: filterResult?.formatted,
			client_company_id:legacyClientCompanyId ? Number(legacyClientCompanyId) : undefined
		}).then(setShelfAudits)
			.then(_ => setLoadingState(LoadingStateEnum.LOADED))
			.catch(_ => setLoadingState(LoadingStateEnum.ERROR));

	}, [pagination, sort]);


	const columns = [
		{
			id: 'date',
			Header: 'Date',
			accessor: (row: ShelfAuditSimple) => <TableRowTitle
				onClick={() => setShelfAuditModal({ fullOpenMode:true, isOpen: true, data:{ shelfAuditId:row.id, clientCompanyId:row.client_company_id } })}
			>{moment.utc(row.date).format('L LT')}</TableRowTitle>,
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setShelfAuditModal({ fullOpenMode:false, isOpen: true, data:{ shelfAuditId:row.id, clientCompanyId:row.client_company_id } })}
			/>,
			width: undefined,
		},
		{
			id: 'client_company_name',
			Header: <Translate id='shelf_audit.template.detail.company_name'/>,
			accessor: (row: ShelfAuditSimple) => <TableRowTitle
				onClick={() => setClientCompanyModal({ fullOpenMode:true, isOpen: true, data:row.client_company_id })}
			>{row.client_company_name}</TableRowTitle>,
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setClientCompanyModal({ fullOpenMode:false, isOpen: true, data:row.client_company_id })}
			/>,
			width: undefined,
		},
		{
			id: 'owner_id',
			Header: <Translate id='shelf_audit.template.detail.owner'/>,
			accessor: (row: ShelfAuditSimple) =>
				<UserBlock user={users.find(el => el.id === row.owner_id)}/>,
			width: undefined,
		},
		{
			id: 'present_amount',
			Header: <Translate id='shelf_audit.template.detail.present_amount'/>,
			accessor: (row: ShelfAuditSimple) =>
				<TableRow>{row.present_amount}</TableRow>,
			width: undefined,
		},
		{
			id: 'absent_amount',
			Header: <Translate id='shelf_audit.template.detail.absent_amount'/>,
			accessor: (row: ShelfAuditSimple) =>
				<TableRow>{row.absent_amount}</TableRow>,
			width: undefined,
		},
		{
			id: 'dn',
			Header: <Translate id='shelf_audit.template.detail.dn'/>,
			accessor: (row: ShelfAuditSimple) => <DnRow dn={row.dn} dn_evolution={row.dn_evolution}/>,
			width: undefined,
		},
		{

			id: 'quick_actions',
			noHeaderEllipsis: true,
			Header: <img src={system_setting_icon} style={{ width: 18 }} alt="quick_actions"/>,
			accessor: row => {
				const list: DropdownData<number>[] = [];

				list.push({
					label: translateToString('global.quick_action.delete').toString(),
					value: 1
				});
				if (list.length == 0) return;
				return (
					<Restricted to={{ objectAction: 'DeleteShelfAudit' }} ownerId={row.owner_id} >
						<Dropdown
							name='quick_action'
							datalist={list}
							readOnly
							dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
							JSXButton={() =>
								<img
									alt="delete"
									src={optionGrey}
									width={25}
									height={25}
									style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
								/>
							}
							onChange={(_) => {
								deleteShelfAuditPopup({
									alertDelete,
									alert,
									id:row.id,
									name:(`${row.name} (${moment.utc(row.date).format('L LT')})`),
									onDelete:() => refresh()
								});
							}}
						/>
					</Restricted>
				);
			},
			width: 40,
			minWidth: 40,
			disableSortBy: true,
			disableFilter: true,
			unresizeable: true,
		}
	];


	return <>
		{loadingState === LoadingStateEnum.LOADING &&
			<FlexDiv justify="center" align="center" width="100%" height="calc(100vh - 174px)"><PageLoader/></FlexDiv>}
		{loadingState !== LoadingStateEnum.LOADING &&
			<>
				<Table
					columns={columns as Column[]} data={shelfAudits} height="calc(100vh - 190px)"
					onSort={sort => setSort(sort[0])} initialSortBy={sort}/>
				<Pagination
					label={translateToString('shelf_audit.beta')}
					amount={Number(shelfAudits[0]?.count ?? DEFAULT_OFFSET)}
					currentCount={shelfAudits.length}
					steps={[DEFAULT_STEP, 50, 100]}
					onChange={setPagination}
				/>
				<ClientCompany
					isOpen={clientCompanyModal.isOpen}
					setOpen={(b) => setClientCompanyModal({ isOpen: b })}
					clientCompanyId={clientCompanyModal.data}
					fullOpenMode={clientCompanyModal.fullOpenMode}
				/>
				<ShelfAuditPopup
					shelfAuditId={shelfAuditModal.data?.shelfAuditId}
					clientCompanyId={shelfAuditModal.data?.clientCompanyId}
					isOpen={shelfAuditModal.isOpen}
					fullOpen={shelfAuditModal.fullOpenMode}
					handleResizePopup={() => setShelfAuditModal((prev) => ({
						...prev,
						fullOpenMode: (prev.fullOpenMode !== true)
					}))}
					onClickOut={() => setShelfAuditModal({ isOpen: false })}
					popupStyle={{ animate: true, width: '450px' }}
					popupMode={shelfAuditModal.fullOpenMode ? PopupMode.Default : PopupMode.Details}
					isChild={false}
				/>
			</>
		}
	</>;
}

export default ShelfAuditTemplate;