import {
	CREATE_USER_ERROR,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	CURRENT_USER_ERROR,
	CURRENT_USER_REQUEST,
	CURRENT_USER_SUCCESS,
	DETAIL_USER_ERROR,
	DETAIL_USER_REQUEST,
	DETAIL_USER_SUCCESS,
	FETCH_USER_COMPANIES_ERROR,
	FETCH_USER_COMPANIES_REQUEST,
	FETCH_USER_COMPANIES_SUCCESS,
	FETCH_USER_ERROR,
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	GET_USER_BYCOMPANY_ERROR,
	GET_USER_BYCOMPANY_REQUEST,
	GET_USER_BYCOMPANY_SUCCESS,
	SEARCH_USER,
	SET_ACTIVE_FILTER,
	UPDATE_USER_ERROR,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	USERID_BYTOKEN_ERROR,
	USERID_BYTOKEN_REQUEST,
	USERID_BYTOKEN_SUCCESS
} from '../actions/user.action';

export const CELL_EDIT = 'CELL_EDIT';
export const CELL_SAVE = 'CELL_SAVE';
export const CELL_VALUE = 'CELL_VALUE';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	current: null,
	currentPermission: [],
	search: '',
	data: [],
	id: null,
	dataDetail: null,
	selectRole: [],
	roleId: 0,
	permissionList: [],
	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	isCellEdit: false,
	row: null,
	value: null,

	activeFilter: {
		role: null
	},
	sales: null
};

export default function UserReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_USER_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_USER_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_USER_ERROR:
			return {
				...state,
				isLoading: false
			};
		case DETAIL_USER_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_USER_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_USER_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case CREATE_USER_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case CREATE_USER_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoading: false
			};

		case CREATE_USER_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_USER_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_USER_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SEARCH_USER:
			return {
				...state,
				search: action.payload
			};
		case CURRENT_USER_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CURRENT_USER_SUCCESS:
			return {
				...state,
				isLoading: false,
				current: action.payload.data
			};
		case CURRENT_USER_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CELL_EDIT:
			return {
				...state,
				isCellEdit: true,
				row: action.row,
				value: null
			};

		case CELL_SAVE:
			return {
				...state,
				isCellEdit: false,
				row: null,
				value: null
			};
		case CELL_VALUE:
			return {
				...state,
				value: action.payload
			};

		case FETCH_USER_COMPANIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_USER_COMPANIES_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				isLoading: false
			};
		case FETCH_USER_COMPANIES_ERROR:
			return {
				...state,
				isLoading: false
			};

		case USERID_BYTOKEN_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case USERID_BYTOKEN_SUCCESS:
			return {
				...state,
				id: action.payload.data ? action.payload.data.id : [],
				isLoading: false
			};
		case USERID_BYTOKEN_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_USER_BYCOMPANY_REQUEST:
			return {
				...state,
				isLoading: false
			};
		case GET_USER_BYCOMPANY_SUCCESS:
			return {
				...state,
				isLoading: false,
				userByCompany: action.payload.data
			};
		case GET_USER_BYCOMPANY_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SET_ACTIVE_FILTER:
			return {
				...state,
				activeFilter: action.payload
			};

		default:
			return state;
	}
}
