import { URL_FOUNDATION } from '../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function get_exports(step: number, offset: number) {
	axios.defaults.headers.common.Authorization = `${token}`;
	const params = {
		limit: step,
		offset
	};
	return await axios.get(`${PATH}/export`, { params });
}

export async function delete_export(key: string) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios.delete(`${PATH}/export`, { params: { key } });
}

export async function get_link(key: string) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios.get(`${PATH}/export/link`, { params: { key } });
}
