import add_blue from 'images/icon/add_blue.png';
import kanbanView_grey from 'images/ui_icon/kanbanView_grey.svg';
import kanbanView_black from 'images/ui_icon/kanbanView_black.svg';
import listView_grey from 'images/ui_icon/listView_grey.svg';
import listView_black from 'images/ui_icon/listView_black.svg';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import { Translate } from 'react-localize-redux';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Filter from './OpportunityFilter';
import ListView from './opportunitiesTables/ListView';
import KanbanView from './opportunitiesTables/KanbanView';
import MapView from './opportunitiesTables/MapView';
import ModalFilterDate from '../../../components/modal/filter/ModalFilterDate';
import ModalFilterOwner from '../../../components/modal/filter/ModalFilterOwnerWithoutAll';
import ModalFilterGroup from '../../../components/modal/filter/ModalFilterGroupWhithoutAll';
import ModalFilterOpportunityStatus from '../../../components/modal/filter/ModalFilterOpportunityStatus';
import ModalImportExcel from '../../../components/modal/create/ModalImportExcel';
import ModalExport from '../../../components/modal/create/ModalExportData';
import ModalFilterCompanies from '../../../components/modal/filter/ModalFilterCompanies';
import ModalError from '../../../components/modal/ModalErrorValidate';
import Restricted from '../../../containers_v2/permissions/Restricted';
import RestrictedSuperAdmin from '../../../containers_v2/permissions/RestrictedSuperAdmin';
import optionGrey from 'images/icon/options_grey.png';

class TableWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			viewType: 'list',
			currentDetailId: null,
			done: false,
			modalImport: false,
			modalExport: false,
			selectedRows: [],
			modalFilterDate: false,
			modalFilterOwner: false,
			modalFilterGroup: false,
			modalSearch: false
		};
	}

	componentWillReceiveProps(nextProps) {
		const { data } = this.props;
		if (
			nextProps.data.length > 0 &&
      data !== nextProps.data &&
      !this.state.done
		) {
			this.setState({
				currentDetailId: nextProps.data[0].id,
				done: true
			});
		}
	}

	onChangeDetailId = id => {
		this.setState({
			currentDetailId: id
		});
	};

	onSelect = row => {
		this.setState({
			selectedRows: row.toString(),
			selectedRowsArray: row
		});
	};

	onToggleFilterDate = () => {
		this.setState(prevState => ({
			modalFilterDate: !prevState.modalFilterDate
		}));
	};

	onToggleFilterOwner = () => {
		this.setState(prevState => ({
			modalFilterOwner: !prevState.modalFilterOwner
		}));
	};

	onToggleFilterGroup = () => {
		this.setState(prevState => ({
			modalFilterGroup: !prevState.modalFilterGroup
		}));
	};

	toggle = () => {
		this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
	};

	setModalSeacrh = () => {
		this.setState({ modalSearch: !this.state.modalSearch });
	};

	onOpenModalImport = () => {
		this.setState({ modalImport: true });
	};

	onCloseModal = () => {
		this.setState({ modalImport: false });
	};

	onOpenModalExport = () => {
		this.setState({ modalExport: true });
	};

	onCloseModalExport = () => {
		this.setState({ modalExport: false });
	};

	render() {
		const { viewType, currentDetailId, modalSearch } = this.state;
		const {
			current,
			data,
			dataNoPagination,
			page,
			onTableChange,
			route,
			totalSize,
			columns,
			lastPage,
			handleFilter,
			isSelectRow,
			showPagination,
			loading,
			enableDownload,
			disablePagination,
			onNext,
			onPrev,
			onFirstPage,
			onLastPage,
			onFilterByDeadline,
			onFilterByOwner,
			onInitViewList,
			onInitViewKanban,
			modal,
			onCloseFilterStatus,
			filterState,
			onFilter,
			onExport,
			onImport,
			onBulkDelete,
			statusKanban,
			closeFilterCompanyModal,
			filterCompanies,
			handleLimit,
			limit,
			tagsId,
			filterByAlphabet,
			handleFilterTag,
			modalErrorValidate,
			errorValidation,
			getTypeView,
			board,
			amount,
			handleSearch,
			statusCode
		} = this.props;

		return (
			(<div>
				<div className="table-bg sticky-v2 right-panel">
					<div className="row py-auto">
						<div className="col-md-10 d-flex">
							<Filter
								current={current}
								filterState={filterState}
								onToggleFilterDate={this.onToggleFilterDate}
								onToggleFilterOwner={this.onToggleFilterOwner}
								onToggleFilterGroup={this.onToggleFilterGroup}
								onFilterByDeadline={onFilterByDeadline}
								onFilterByOwner={onFilterByOwner}
								type={viewType}
								data={data}
								statusKanban={statusKanban}
								handleFilterTag={handleFilterTag}
								typeList="opportunities"
								searchData={this.props.searchData}
							/>
						</div>
						<div className="col-md-2 d-flex justify-content-end pr-3 pointer">
							<React.Fragment>
								<div
									className="mx-1 my-auto"
									onClick={() => {
										onInitViewList();
										getTypeView('list');
										this.setState({
											viewType: 'list'
										});
									}}
									style={{ cursor: 'pointer' }}
								>
									<img
										src={
											viewType === 'list'
												? listView_black
												: listView_grey
										}
										className="custom-icon-views"
										alt=""
									/>
								</div>
								<div
									className="mx-1 my-auto"
									onClick={() => {
										if (viewType !== 'mixt') {
											onInitViewKanban();
											getTypeView('mixt');
											this.setState({
												viewType: 'mixt'
											});
										}
									}}
								>
									<img
										src={
											viewType === 'mixt'
												? kanbanView_black
												: kanbanView_grey
										}
										className="custom-icon-views"
										alt=""
									/>
								</div>
								<Restricted to={{ objectAction: 'CreateOpportunity' }}>
									<div className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle">
										<a href={`${route.path}/create`}>
											<img
												src={add_blue}
												className="custom-icon"
												alt=""
											/>
										</a>
									</div>
								</Restricted>
								<Restricted to={[{ objectAction: 'CreateExport' }, { objectAction: 'DeleteOpportunity' }]}>
									<div className="my-auto mx-2">
										<ButtonDropdown
											className="border-transparent"
											isOpen={this.state.dropdownOpen}
											toggle={this.toggle}
										>
											<DropdownToggle className="bg-white p-0">
												<img
													src={optionGrey}
													className="custom-icon"
													alt=""
												/>
											</DropdownToggle>
											<DropdownMenu className="custom-position-v2">
												{/* <DropdownItem>
                        <Translate id="advanced_filters" />
                      </DropdownItem>
                      <DropdownItem>
                        <Translate id="mass_modification" />
                      </DropdownItem> */}
												<Fragment>
													<RestrictedSuperAdmin>
														<DropdownItem
															onClick={() => {
																this.onOpenModalImport();
															}}
														>
															<Translate id="import_opportunities" />
														</DropdownItem>
													</RestrictedSuperAdmin>
													<Restricted to={{ objectAction: 'CreateExport' }}>
														<DropdownItem
															onClick={() => {
																// onExport(this.state.selectedRows);
																this.onOpenModalExport();
															}}
														>
															<Translate id="export_opportunities" />
														</DropdownItem>
													</Restricted>
												</Fragment>
												<Restricted to={{ objectAction: 'DeleteOpportunity' }}>
													<DropdownItem
														onClick={() => {
															onBulkDelete(this.state.selectedRowsArray);
														}}
														// className="text-danger"
													>
														<Translate id="delete_selection" />
													</DropdownItem>
												</Restricted>
											</DropdownMenu>
										</ButtonDropdown>
									</div>
								</Restricted>
							</React.Fragment>
						</div>
					</div>
				</div>
				<div className="d-flex">
					{/* <div className="" style={{ width: `${modalSearch ? '23%' : '0%'}` }}>
            <ModalSearch
              handleModalOpen={this.setModalSeacrh}
              handleSearch={handleSearch}
            />
          </div> */}
					<div style={{ width: `${modalSearch ? '77%' : '100%'}` }}>
						{viewType === 'list' && (
							<ListView
								data={data}
								page={page}
								onTableChange={onTableChange}
								route={route}
								totalSize={totalSize}
								columns={columns}
								lastPage={lastPage}
								handleFilter={handleFilter}
								isSelectRow={isSelectRow}
								showPagination={showPagination}
								loading={loading}
								enableDownload={enableDownload}
								disablePagination={disablePagination}
								onNext={onNext}
								onPrev={onPrev}
								onLastPage={onLastPage}
								onFirstPage={onFirstPage}
								onSelect={this.onSelect}
								handleLimit={handleLimit}
								limit={limit}
								filterByAlphabet={filterByAlphabet}
							/>
						)}
						{viewType === 'mixt' && (
							<KanbanView
								data={dataNoPagination}
								page={page}
								lastPage={lastPage}
								onChangeTab={this.onChangeDetailId}
								route={route}
								currentDetailId={currentDetailId}
								board={board}
								amount={amount}
								statusCode={statusCode}
							/>
						)}
					</div>
				</div>
				{viewType === 'map' && <MapView data={data} tagsId={tagsId} />}
				<ModalImportExcel
					isOpen={this.state.modalImport}
					toggle={this.onCloseModal}
					onImport={onImport}
				/>
				<ModalExport
					isOpen={this.state.modalExport}
					toggle={this.onCloseModalExport}
					dataExport={this.state.selectedRows}
					onExport={onExport}
				/>
				<ModalFilterDate
					isOpen={this.state.modalFilterDate}
					toggle={() => {
						this.onToggleFilterDate();
					}}
					onClick={() => {
						this.onToggleFilterDate();
					}}
					type={viewType}
					onFilter={onFilterByDeadline}
				/>
				<ModalFilterOwner
					isOpen={this.state.modalFilterOwner}
					toggle={() => {
						this.onToggleFilterOwner();
					}}
					onClick={() => {
						this.onToggleFilterOwner();
					}}
					type={viewType}
					onFilter={onFilterByOwner}
				/>
				<ModalFilterGroup
					isOpen={this.state.modalFilterGroup}
					toggle={() => {
						this.onToggleFilterGroup();
					}}
					onClick={() => {
						this.onToggleFilterGroup();
					}}
					type={viewType}
					onFilter={onFilterByOwner}
					current={current}
				/>
				<ModalFilterOpportunityStatus
					isOpen={modal.filterOpportunityStatus}
					toggle={onCloseFilterStatus}
					onFilter={onFilter}
				/>
				<ModalFilterCompanies
					isOpen={modal.filterCompanies}
					toggle={closeFilterCompanyModal}
					onFilter={filterCompanies}
				/>
				<ModalError
					isOpen={modal.modalErrorMessage}
					toggle={modalErrorValidate}
					errorValidation={errorValidation}
				/>
			</div>)
		);
	}
}

export default TableWrapper;
