import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { FilterBlue } from '../../../styles/global/css/Utils';

interface ContainerProps {
  width: string
  height: string
  backgroundColor: string
}

const Container = styled.div<ContainerProps>`
    width: ${p => p.width};
    height: ${p => p.width};
    background-color: ${p => p.backgroundColor};
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

interface TextProps {
  fontSize?: string
  name?: string
}

const Text = styled.div<TextProps>`
    ${DefaultText}
    font-size: ${p => p.fontSize ?? ''};
    ${p => p.name
		? `
            color: white;
            font-weight: 600;
        `
		: `
            &::before {
                content: "-";
            }
        `
}
`;

interface ImageProps {
  url?: string;
  width: string;
  radius?: string;
  bgSize?: string;
  noCursor?: boolean;
  hasFilter?: boolean;
  filter?: string;
}

const Image = styled.div<ImageProps>`
    background-image: url(${p => p.url ?? ''});
    width: ${p => p.width};
    height: ${p => p.width};
    background-size: ${p => p.bgSize ?? 'cover'};
    border-radius: ${p => p.radius ?? ''};

    background-repeat: no-repeat;
    background-position: center;
    ${({ noCursor }) => noCursor ? '' : 'cursor: pointer;'}
    flex-shrink: 0;
 	
  	&:hover {
      ${({ hasFilter, filter }) => hasFilter ? `filter: ${filter ?? FilterBlue};` : ''}
	}
`;

export {
	Container,
	Image,
	Text
};
