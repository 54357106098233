import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DropdownActionDetail from '../../components/DropdownActionDetail';
import EnformDetail from './detail/EnformDetail';
import { detailInstance } from '../../store/actions/enForm.action';
import moment from 'moment';
import Restricted from '../../containers_v2/permissions/Restricted';

function DetailEnForm(props) {
	const { match, dispatch, data, history } = props;
	const detail = data.dataDetail;
	useEffect(() => {
		dispatch(detailInstance(match.params.instaceId));
	}, []);

	return (
		<Restricted to={{ objectAction: 'ReadShelfAudit' }}>
			<div>
				<div className="d-flex background-bar sticky-v2 right-panel">
					<div
						className="my-auto mr-2 pointer"
						onClick={() =>
							detail
								? history.push(
									`/enform/list/instance/${detail.shelf_audit_template_id}`
								)
								: history.push('/enform/list')
						}
					>
						<i className="fa fa-arrow-left" style={{ fontSize: '18px' }} />
					</div>
					<h2 className="card-title font-weight-bold">
						{detail && moment(detail.created_at || '-').format('DD/MM/YY HH:mm')}
					</h2>
					<div className="ml-auto mx-2 d-flex">
						<DropdownActionDetail
							id={22}
							name="arthur"
							path="products"
							permission='ShelfAudit'
							noDuplicate
							{...props}
						/>
					</div>
				</div>
				<EnformDetail {...props} detail={detail} />
			</div>
		</Restricted>
	);
}

const mapStateToProps = state => ({
	data: state.enform
});

export default connect(mapStateToProps)(DetailEnForm);
