import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';

// -----------------------[ ATOM ]----------------------- //
export const AStatuses = selector<Record<number, Status>>({
	key: 'AStatuses',
	get: async() => getStatuses().then(a => a.reduce((acc, c) => {
		acc[c.status_id] = c;
		return acc;
	}, {} as Record<number, Status>))
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getStatuses(): Promise<Status[]> {
	try {
		return await axios.get(`${URL_FOUNDATION}/api/v2/client-companies/statuses`).then(res => res.data);
	} catch (e) {
		console.error(e);
		return [];
	}
}

export interface Status {
  status_id: number;
  status_name: string;
  status_color: string;
}
