import listWithPenImage from 'images/icon/list_with_pen.svg';
import axios from 'axios';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilValue } from 'recoil';
import { ABrand } from '../../../atoms/filter/BrandFiltersAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { ADatePicker } from '../../../atoms/filter/timeFilterAtom';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import PageLoader from '../../../components/PageLoader';
import { loadingState } from '../../../components_v2/models';
import { InfiniteTable, InfiniteTableStyle } from '../../../components_v2/table/InfiniteTable';
import { Column } from '../../../components_v2/table/Table';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { useWindowDimensions } from '../../../components_v2/utils';
import storeLang from '../../../helpers/storeLang';
import { ToolBarDotsReports } from '../../../myToolbar/OtherTools';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import usePermission from '../../permissions/usePermission';
import { FlexDiv } from '../../products/style';
import { reportingAction, reportingState } from '../Reducer';
import { getColorForPercentage, getStringWidth, queryConstructor } from '../utils';
import { exportPresencePerBrandOrCategoryPerCompany, getPresencePerBrandOrCategoryPerCompany } from './actions';

const sort = key => (rowA, rowB, _columnId) => {
	const a = rowA.original[key];
	const b = rowB.original[key];
	if (a > b) return 1;
	if (a < b) return -1;
	return 0;
};

const sortPresence = key => (rowA, rowB, _columnId) => {
	let a = rowA.original[key];
	let b = rowB.original[key];
	if (typeof (a) === 'string') {
		a = false;
	}
	if (typeof (b) === 'string') {
		b = false;
	}
	if (a > b) return 1;
	if (a < b) return -1;
	return 0;
};

const sortCompanyPresence = () => (rowA, rowB, _columnId) => {
	if (rowA.original.hasOwnProperty('product_presence') &&
    rowA.original.product_presence.hasOwnProperty('present_count') &&
    rowA.original.product_presence.hasOwnProperty('expected_count') &&
    rowB.original.hasOwnProperty('product_presence') &&
    rowB.original.product_presence.hasOwnProperty('present_count') &&
    rowB.original.product_presence.hasOwnProperty('expected_count')) {
		const a_p = rowA.original.product_presence.present_count;
		const b_p = rowB.original.product_presence.present_count;
		const a_e = rowA.original.product_presence.expected_count;
		const b_e = rowB.original.product_presence.expected_count;
		if ((a_p / a_e) > (b_p / b_e)) return 1;
		if ((a_p / a_e) < (b_p / b_e)) return -1;
	}
	return 0;
};

const sortProductCount = () => (rowA, rowB, _columnId) => {
	if (rowA.original.hasOwnProperty('product_presence') &&
    rowA.original.product_presence.hasOwnProperty('present_count') &&
    rowB.original.hasOwnProperty('product_presence') &&
    rowB.original.product_presence.hasOwnProperty('present_count')) {
		const a_p = rowA.original.product_presence.present_count;
		const b_p = rowB.original.product_presence.present_count;
		if (a_p > b_p) return 1;
		if (a_p < b_p) return -1;
	}
	return 0;
};

interface Brand {
  id: string
  name: string
}

interface BrandTotals {
  company_average_activated_brands_total: number
  brands: Record<string, number>
}

function isNumber(value: string | number): boolean {
	return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}

function genColumns(brands: Brand[], totals: BrandTotals, rows: any[], onCompanyClick: (n: number) => void): Column[] {
	const translate = getTranslate(storeLang.getState().localize);
	const brand_columns: Column[] = brands.map(b => {
		return {
			Header: b.name,
			accessor: row => {
				if (row[b.id] != null && isNumber(row[b.id])) {
					const color = getColorForPercentage(row[b.id], 0.4);
					return (
						<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
							<span style={{
								backgroundColor: color,
								borderRadius: '25px',
								color: DarkGreySidely,
								textAlign: 'center',
								width: '80px'
							}}
							className='overflow percentage'>{`${(Math.round(row[b.id] * 10000) / 100).toFixed(2)}%`}
							</span>
						</div>
					);
				}
				if (row[b.id] != null && row[b.id] == true) {
					return <span className="green-dot"></span>;
				}
				if (row[b.id] != null && typeof (row[b.id]) === 'string') {
					return (
						<span className="red-dot tooltip2">
							<span className="tooltiptext2">{translate(row[b.id].toLowerCase()).toString()}</span>
						</span>);
				}
				return '-';
			},
			width: getStringWidth(b.name),
			sortType: sortPresence(b.id),
			Footer: _info => {
				if (totals[b.id] != null) {
					const total_avg = totals[b.id];
					const color = getColorForPercentage(total_avg, 1.0);
					return (
						<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
							<span className='overflow percentage' style={{
								fontWeight: 500,
								backgroundColor: color,
								borderRadius: '25px',
								color: DarkGreySidely,
								textAlign: 'center',
								width: '80px'
							}}>
								{`${(Math.round(total_avg * 10000) / 100).toFixed(2)}%`}
							</span>
						</div>
					);
				} else {
					return <span style={{ display: 'block', textAlign: 'center' }}>-</span>;
				}
			}
		};
	});
	const company_name: string = translate('shelf_audit.column.company_name').toString();
	const brand_activation_average: string = translate('shelf_audit.column.brand_activation_average').toString();
	const last_audit: string = translate('shelf_audit.column.last_shelf_audit').toString();
	const owner_name: string = translate('shelf_audit.column.owner_name').toString();
	const user_name: string = translate('shelf_audit.column.user').toString();
	const no_parent_name: string = translate('shelf_audit.column.no_parent_name').toString();
	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
	const s: Column[] = [
		{
			Header: '-',
			columns: [
				{
					id: 'expander',
					Header: (props: any) => {
						const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = props;
						return (
							<span {...getToggleAllRowsExpandedProps({ title: undefined })} style={{ fontSize: '20px', cursor: 'pointer' }}>
								{isAllRowsExpanded ? '-' : '+'}
							</span>
						);
					},
					Cell: ({ row }) =>
					// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
					// to build the toggle for expanding a row
						row.canExpand ? (
							<span
								{...row.getToggleRowExpandedProps({
									title: undefined,
									style: {
										// We can even use the row.depth property
										// and paddingLeft to indicate the depth
										// of the row
										// paddingLeft: `${row.depth * 2.5}rem`,
										fontSize: '20px'
									}
								})}
							>
								{row.isExpanded ? '-' : '+'}
							</span>
						) : null,
					width: 60,
					Footer: 'Total'
				},
				{
					Header: company_name,
					accessor: row => {
						const content =
              <span>
              	{row.company_count != null &&
                  row.company_no_report_count != null &&
                  <span>{' '}({row.company_count} / {row.company_no_report_count + row.company_count})</span>
              	}
              </span>;
						if (row.hasOwnProperty('company_id') && row.hasOwnProperty('company_name')) {
							return (
								<span style={{ display: 'block', textAlign: 'left', cursor: 'pointer', fontWeight: 500 }}
									className='overflow'
									onClick={() => onCompanyClick(row.company_id)}
								>
									<span>
										{row.company_name}
										{content}
									</span>
								</span>
							);
						} else {
							return (
								<span style={{ display: 'block', textAlign: 'left', fontWeight: 500 }} className='overflow'>
									<span>
										{no_parent_name}
										{content}
									</span>
								</span>
							);
						}
					},
					sortType: sort('company_name'),
					width: 200,
					// width: getColumnWidth(rows, 'company_name', company_name),
					Footer: _info => {
						if (totals.company_count != undefined && totals.company_count != null) {
							return <span>{' '}{totals.company_count} / {totals.company_no_report_count + totals.company_count}</span>;
						} else {
							return '-';
						}
					}
				},
				{
					Header: owner_name,
					accessor: row => {
						if (row.company_owner_name) {
							return (
								<span style={{ display: 'block', textAlign: 'left' }}
									className='overflow'>
									{row.company_owner_name}
								</span>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(owner_name),
					sortType: sort('company_owner_name'),
					Footer: ''
				},
				{
					Header: last_audit,
					accessor: row => {
						if (row.audit_date) {
							const d = new Date(row.audit_date);
							return (
								<span style={{ display: 'block', textAlign: 'right', cursor: 'pointer', fontWeight: 500 }}
									className='overflow'
									onClick={() => window.open(window.location.origin + '/enform/detaildata/' + row.audit_id, '_blank')}
								>
									{`${d.toLocaleDateString(undefined, options)}`}
								</span>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(last_audit),
					sortType: sort('audit_date'),
					Footer: ''
				},
				{
					Header: user_name,
					accessor: row => {
						if (row.user_name) {
							return (
								<span style={{ display: 'block', textAlign: 'left' }}
									className='overflow'>
									{row.user_name}
								</span>
							);
						} else {
							return '-';
						}
					},
					width: getStringWidth(user_name),
					sortType: sort('user_name'),
					Footer: ''
				}
			],
			Footer: ''
		},
		{
			Header: brand_activation_average,
			accessor: row => {
				if (row.hasOwnProperty('brand_activation_average')) {
					const color = getColorForPercentage(row.brand_activation_average, 1.0);
					return (
						<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
							<span style={{ backgroundColor: color, borderRadius: '25px', color: DarkGreySidely, textAlign: 'center', width: '80px' }} className='overflow percentage'>
								{`${(Math.round(row.brand_activation_average * 10000) / 100).toFixed(2)}%`}
							</span>
						</div>
					);
				} else {
					return '-';
				}
			},
			width: getStringWidth(brand_activation_average),
			sortType: sortCompanyPresence(),
			Footer: _info => {
				if (totals.hasOwnProperty('brand_activation_average')) {
					const color = getColorForPercentage(totals.brand_activation_average, 1.0);
					return (
						<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>
							<span className='overflow percentage' style={{ fontWeight: 500, backgroundColor: color, borderRadius: '25px', color: DarkGreySidely, textAlign: 'center', width: '80px' }}>
								{`${(Math.round(totals.brand_activation_average * 10000) / 100).toFixed(2)}%`}
							</span>
						</div>
					);
				} else {
					return '-';
				}
			}
		}
	];
	return s.concat(brand_columns);
}

function filterData(filters: Object, data: any[]) {
	let ret = data;
	if (filters.lines && !filters.lines.some(f => f == 0)) {
		ret = ret.reduce((acc, e) => {
			if (!e.audit_date && (!e.company_count || e.company_count == 0)) {
				return acc;
			}
			const deleted = delNoShelfAuditTailRecursive(e);
			if (!deleted.deleteMe) { acc.push(deleted); }
			return acc;
		}, []);
	}
	if (filters.users) {
		ret = ret.reduce((acc, e) => {
			if (e.company_owner_id && !filters.users.includes(e.company_owner_id)) {
				return acc;
			}
			const deleted = delUsersTailRecursive(e);
			if (!deleted.deleteMe) {
				if (deleted.company_count && deleted.company_no_report_count) {
					// deleted.company_count -= deleted.company_count;
					// deleted.company_no_report_count -= deleted.company_no_report_count;
				}
				acc.push(deleted);
			}
			return acc;
		}, []);
	}
	// console.log(ret)
	return ret;
	function delUsersTailRecursive(e) {
		if (e.company_owner_id && !filters.users.includes(e.company_owner_id)) {
			return { deleteMe: true };
		} else if (e.subRows) {
			const subRows = e.subRows.map(x => delUsersTailRecursive(x));
			if (subRows.filter(x => !x.deleteMe).length == 0) {
				return {
					deleteMe: true,
					...subRows.reduce((acc, x) => {
						acc.company_count += x.company_count;
						acc.company_no_report_count += x.company_no_report_count;
						return acc;
					}, { company_count: 0, company_no_report_count: 0 })
				};
			}
			return {
				...e,
				subRows: subRows.filter(x => !x.deleteMe),
				...subRows.filter(x => !x.deleteMe).reduce((acc, x) => {
					if (x.company_count != undefined && x.company_no_report_count != undefined) {
						acc.company_count += x.company_count;
						acc.company_no_report_count += x.company_no_report_count;
					} else if (x.audit_date) {
						acc.company_count += 1;
					} else {
						acc.company_no_report_count += 1;
					}
					return acc;
				}, { company_count: 0, company_no_report_count: 0 })
			};
		}
		return e;
	}
	function delNoShelfAuditTailRecursive(e) {
		if (e.audit_date) {
			return e;
		} else if (e.subRows) {
			const subRows = e.subRows.map(x => delNoShelfAuditTailRecursive(x)).filter(x => !x.deleteMe);
			if (subRows.length == 0) {
				return { deleteMe: true };
			}
			return { ...e, subRows };
		}
		return { deleteMe: true };
	}
}

export function PresencePerBrandOrCategoryPerCompany(props: {
  state: reportingState
  dispatch: (action: reportingAction) => void
  setToolBarState: (value: ToolbarState) => void
  onCompanyClick: (n: number) => void
}) {
	const { height, width } = useWindowDimensions();
	const offset = 296;
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any[]>([]);
	const c: Column[] = [];
	const [columns, setColumns] = React.useState(c);
	const [filteredColumns, setFilteredColumns] = React.useState<any>(undefined);
	const [launchExport, setLaunchExport] = React.useState({ export: false });
	const dispatch = useDispatch();
	const translate = getTranslate(storeLang.getState().localize);

	const allowed = usePermission({ objectAction: 'CreateExport' });

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 200}
					category={AtomCategory.GLOBAL}
					elements={[
						{
							kind: ToolbarElement.USER_PICKER
						},
						{
							kind: ToolbarElement.USER_PICKER,
							logo: listWithPenImage
						},
						{
							kind: ToolbarElement.DATE_PICKER,
							oneDay: true
						},
						ToolbarElement.TAG_FILTER,
						// ToolbarElement.PRODUCT_FILTER,
						ToolbarElement.BRAND_FILTER
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'} />
			</FlexDiv>,
			bottomRightToolbarComponent: <ToolBarDotsReports
				current={undefined}
				startingColumns={columns}
				export={allowed}
				onFilter={(e) => {
					if (e.export) {
						setLaunchExport(e);
					} else if (e.columns) {
						setFilteredColumns(e.columns);
					}
				}}
			/>
		});
	}, [loadingState, columns, filteredColumns, width]);

	const pickedUsers0 = useRecoilValue(AUserPicker(0));
	const pickedUsers1 = useRecoilValue(AUserPicker(1));
	const pickedDates = useRecoilValue(ADatePicker);
	const filteredTags = useRecoilValue(ATagFilter);
	// const filteredProducts = useRecoilValue(AProductFilter);
	const filterBrand = useRecoilValue(ABrand);

	React.useEffect(() => {
		if (!loadingState && (pickedUsers0 != null) && (pickedUsers1 != null) && (pickedDates != null) && (filteredTags != null)) {
			try {
				setLoadingState('loading');
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				let dateStr = '';
				if (pickedDates[0]?.format('YYYY-MM-DD') == pickedDates[1]?.format('YYYY-MM-DD') && (pickedDates[0] != null) && (pickedDates[1] != null)) {
					dateStr = `${pickedDates[0].format('YYYY-MM-DD')}_${pickedDates[1].format('YYYY-MM-DD')}`;
				} else {
					dateStr = `${pickedDates[0]?.format('YYYY-MM-DD') ?? ''}_${pickedDates[1]?.format('YYYY-MM-DD') ?? ''}`;
				}
				getPresencePerBrandOrCategoryPerCompany(queryConstructor({
					tags: filteredTags,
					userIdList: [pickedUsers0[0], pickedUsers1[0]],
					// products: filteredProducts,
					brandList: filterBrand
				}, dateStr), cancelTokenSource.token)
					.then(res => {
						setData(res.data.rows);
						// console.log(res.data.rows)
						const columns = genColumns(res.data.columns, res.data.totals, res.data.rows, props.onCompanyClick);
						setColumns(columns);
						// if (typeof (props.toolBarState.columns) !== 'undefined' && typeof (props.toolBarState.columns.setDotColumns) !== 'undefined')
						//   props.toolBarState.columns.setDotColumns(columns)
						if (loadingState != 'loaded') {
							setLoadingState('loaded');
						}
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job in: OrderPerProductPerCompany ', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState, pickedUsers0, pickedUsers1, pickedDates, filteredTags, JSON.stringify(filterBrand)]);

	React.useEffect(() => {
		if (launchExport.export) {
			exportPresencePerBrandOrCategoryPerCompany(queryConstructor({
				tags: filteredTags,
				userIdList: [pickedUsers0?.[0], pickedUsers1?.[0]],
				// products: filteredProducts
			}, `${pickedDates?.[0]?.format('YYYY-MM-DD') ?? ''}_${pickedDates?.[1]?.format('YYYY-MM-DD') ?? ''}`))
				.then(res => {
					dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
				})
				.catch(e => {
					dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'import.export_error_message' }, state: 'error' } });
					console.log(e);
				});
		}
	}, [launchExport]);

	let ret: any[] = [];
	if (filteredColumns?.length != 0 && filteredColumns?.[0].columns) { ret = filteredColumns; } else { ret = columns; }
	return (
		<div style={{ height: '100%' }}>
			<InfiniteTableStyle>
				{(loadingState == 'loading' || !loadingState) && <div className="list-loader"><PageLoader /></div>}
				{loadingState == 'error' && <p>Error fetching data</p>}
				{loadingState == 'loaded' && <InfiniteTable height={height - offset} columns={ret} data={filterData(props.state.filters.PresencePerProductPerCompany, data)} />}
			</InfiniteTableStyle>
		</div>
	);
}
