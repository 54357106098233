import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Gallery from '../../containers_v2/gallery/Gallery';
import { Route } from '../Routes';
import GalleryV2 from '../../containers_v2/gallery_v2/GalleryV2';

const GalleryRoutes = (): Route[] => [
	{
		component: Gallery,
		path: '/gallery',
		name: <Translate id="gallery.title" />,
		title: <Translate id="gallery.title" />,
		subTitle: <Translate id="gallery.title" />,
		sectionKey: 'gallery.title',
		key: 'gallery',
		role: ['owner'],
		exact: true,
		permission: ['ViewGallery'],
		precedence: 1
	},
	{
		component: GalleryV2,
		path: '/galleryV2',
		name: <Translate id="gallery.title" />,
		title: <Translate id="gallery.title" />,
		subTitle: <Translate id="gallery.title" />,
		sectionKey: 'gallery.title',
		key: 'gallery',
		role: ['owner'],
		exact: true,
		permission: ['ViewGallery'],
		precedence: 1
	},
];

export default GalleryRoutes;
