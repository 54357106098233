import { SET_FILES, UPLOAD_FILE_ERROR, UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS } from '../actions/files.action';

const initState = {
	isLoading: false,
	files: {}
};

export default function FilesReducer(state = initState, action) {
	switch (action.type) {
		case UPLOAD_FILE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPLOAD_FILE_SUCCESS:
			return {
				...state,
				files: {
					...state.files,
					[action.fieldName]: state.files[action.fieldName]
						? [...state.files[action.fieldName], action.payload.data]
						: [action.payload.data]
				},
				isLoading: false
			};
		case UPLOAD_FILE_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SET_FILES: {
			return {
				...state,
				files: {
					...state.files,
					[action.fieldName]: action.payload
				}
			};
		}

		default:
			return state;
	}
}
