import {
	MODAL_CREATE_EVENT,
	MODAL_ERROR_MESSAGE_IMPORT,
	MODAL_EVENT_FORM,
	MODAL_FILTER_CITY,
	MODAL_FILTER_CLIENTSTATUS,
	MODAL_FILTER_COMPANIES,
	MODAL_FILTER_EVENTTYPE,
	MODAL_FILTER_EXPENSESTATUS,
	MODAL_FILTER_OPPORTUNITYSTATUS,
	MODAL_FILTER_OWNER,
	MODAL_FILTER_USERTYPE
} from '../actions/modal.action';

const initState = {
	filterOwner: false,
	filterCity: false,
	filterUserType: false,
	filterExpenseStatus: false,
	createEvent: false,
	filterEventType: false,
	filterClientStatus: false,
	filterOpportunityStatus: false,
	filterCompanies: false,
	modalEventForm: false,
	modalErrorMessage: false
};

export default function ModalReducer(state = initState, action) {
	switch (action.type) {
		case MODAL_FILTER_OWNER:
			return {
				...state,
				filterOwner: action.payload
			};

		case MODAL_FILTER_USERTYPE:
			return {
				...state,
				filterUserType: action.payload
			};

		case MODAL_FILTER_CLIENTSTATUS:
			return {
				...state,
				filterClientStatus: action.payload
			};

		case MODAL_FILTER_EXPENSESTATUS:
			return {
				...state,
				filterExpenseStatus: action.payload
			};

		case MODAL_FILTER_OPPORTUNITYSTATUS:
			return {
				...state,
				filterOpportunityStatus: action.payload
			};

		case MODAL_FILTER_COMPANIES: {
			return {
				...state,
				filterCompanies: action.payload
			};
		}

		case MODAL_CREATE_EVENT:
			return {
				...state,
				createEvent: action.payload
			};

		case MODAL_FILTER_EVENTTYPE:
			return {
				...state,
				filterEventType: action.payload
			};

		case MODAL_EVENT_FORM:
			return {
				...state,
				modalEventForm: action.payload
			};
		case MODAL_ERROR_MESSAGE_IMPORT:
			return {
				...state,
				modalErrorMessage: action.payload
			};
		case MODAL_FILTER_CITY:
			return {
				...state,
				filterCity: action.payload
			};
		default:
			return state;
	}
}
