import styled from 'styled-components';
import CheverontWhite from 'images/icon/cheveront_white.svg';

export const BlackContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0px;
  left: -57px;
  z-index: 105;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PhotoDetails = styled.div<{ fontWeight?: number, fontSize?: number }>`
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    font-size: ${p => p.fontSize ?? 16}px;
    font-weight: ${p => p.fontWeight ?? 400};
    color: white;
    margin-top: 20px;
`;

export const Close = styled.img`
  width: 50px;
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;
`;

export const ImageSwapper = styled.div<{ right?: boolean }>`
    width: 30px;
    height: 30px;
    display: inline-block;
    transform: translateX(-50%) rotate(${p => p.right ? '-' : ''}90deg);
    background-image: url('${CheverontWhite}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    cursor: pointer;
    position: relative;
    top: 50%;
    left: ${p => p.right ? 30 : 0}px;
`;

export const Spacer = styled.div`
  width: 30px;
  display: inline-block;
`;
