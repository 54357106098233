import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';

export const CalendarModalTitle = styled.div<{ noMargin?: boolean }>`
    ${DefaultText}
    color: rgb(50, 60, 71);
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    ${p => p.noMargin ? '' : 'margin-bottom: 20px;'}
`;

export const CalendarModalSubContainer = styled.div`
    width: 100%;
    padding: 0 20px;
`;
