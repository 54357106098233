import React, {Component} from 'react';

class Header extends Component {
  state = {};

  render() {
    const { data } = this.props;

    return (
      <div className="header-text">
        <h3 className="font-weight-bold">{data.field_name}</h3>
      </div>
    );
  }
}

export default Header;
