import * as React from 'react';
import { Collapse } from 'reactstrap';
import { ButtonToggle } from '../../styles/global/css/Open';
import { FilterCheckboxData, FilterRadioData, FilterResult } from './model/Model';
import { Container, Header, Label, Radio, Row } from './style/Style';

function FilterRadioList(props: {
  label: string
  data: FilterRadioData[]
  allButton?: boolean
  allButtonLabel?: string
  allButtonValue?: any
  onChange?: (value: FilterResult) => void
}): JSX.Element {
	const [selections, setSelections] = React.useState<FilterCheckboxData[]>(props.data);
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [selected, setSelected] = React.useState<number>(props.data.findIndex(d => d.selected));
	const ALL_SELECTED_INDEX = -1;

	React.useEffect(() => {
		setSelections(props.data);
		setSelected(props.data.findIndex(d => d.selected));
	}, []);

	React.useEffect(() => {
		setSelections(props.data);
	}, [JSON.stringify(props.data)]);

	function sendResult(index: number): void {
		if (props.onChange == null) return;

		if (index == ALL_SELECTED_INDEX) {
			props.onChange({ label: (props.allButtonLabel ? props.allButtonLabel : 'all'), value: (props.allButtonValue ? props.allButtonValue : 'all') } as FilterResult);
			return;
		}

		const result: FilterCheckboxData = selections[index];
		props.onChange({ label: result.label, value: result.value } as FilterResult);
	}
	return (
		<Container>
			<Header>
				<ButtonToggle
					isOpen={isOpen}
					alreadyTranslated={true}
					title={props.label}
					onClick={() => setOpen(!isOpen)}
					key='modalLeftFilterOrder1'
				/>
			</Header>
			<Collapse isOpen={isOpen}>
				<div>
					{
						props.allButton &&
						<Row>
							<Radio isActive={selected == ALL_SELECTED_INDEX} onClick={() => {
								setSelected(ALL_SELECTED_INDEX);
								sendResult(ALL_SELECTED_INDEX);
							}} />
							<Label>{(props.allButtonLabel ? props.allButtonLabel : 'All')}</Label>
						</Row>
					}
					{
						...selections.map((fdata, index) => {
							return (
								<Row key={`FilterRadioListSelection[${index}]`}>
									<Radio isActive={selected === index} onClick={() => {
										if (index !== selected) {
											setSelected(index);
											sendResult(index);
										}
									}} />
									<Label>{fdata.label}</Label>
								</Row>
							);
						})
					}
				</div>
			</Collapse>
		</Container>
	);
}

export default FilterRadioList;
