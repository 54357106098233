/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import FreeText from './elements/FreeText';
import Numerical from './elements/Numerical';
import DropdownList from './elements/DropdownList';
import Checkbox from './elements/Checkbox';
import TextArea from './elements/TextArea';

const FormElements = {};

FormElements.FreeText = FreeText;
FormElements.Numerical = Numerical;
FormElements.DropdownList = DropdownList;
FormElements.Checkbox = Checkbox;
FormElements.TextArea = TextArea;

export default FormElements;
