import close from 'images/icons/orders/close.svg';
import filter from 'images/icons/filter.svg';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import styled from 'styled-components';
import { FlexDiv } from '../../containers_v2/products/style';
import storeLang from '../../helpers/storeLang';
import { DEFAULT_BUTTON_HEIGHT } from '../../styles/global/css/GlobalButton';
import { Container, Image, Text } from './style/Style';

const Close = styled.img`
    cursor: pointer;
    height: 25px;
`;
const NoWrapText = styled(Text)`
	white-space:nowrap;
`;

function ToolbarFilterButton(props: {
  activeFilters?: number
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onDeleteFilter?: () => void,
  title?: string,
  activeTitle?: string,
  icon?: string,
  iconSize?: string
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	return (
		(<FlexDiv height={DEFAULT_BUTTON_HEIGHT + 'px'} hideTrash>
			<Container onClick={(e) => {
				(props.onClick != null) && props.onClick(e);
			}} isActive={props.activeFilters !== undefined && props.activeFilters > 0}>
				<Image src={props.icon ?? filter} alt="filter" size={props.iconSize} />
				<NoWrapText>{props.activeFilters !== undefined && props.activeFilters > 0 ? (props.activeTitle ?? translate('global.filter.active_filter').toString()).replace('{{NUMBER}}', props.activeFilters.toString()) : (props.title ?? translate('global.filter.advanced_filter').toString())}</NoWrapText>
			</Container>
			{(props.onDeleteFilter != null) && (props.activeFilters ?? 0) > 0 && <Close src={close} onClick={props.onDeleteFilter} className='trash' />}
		</FlexDiv>)
	);
}

export default ToolbarFilterButton;
