import add_blue from 'images/icon/add_blue.png';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import ListView from './ListView';
import ModalExport from './ModalExportData';
import FilterUser from './FilterUser';
import Filter from './Filter';
import Restricted from '../../../containers_v2/permissions/Restricted';
import { BlueSidely } from '../../../styles/global/css/Utils';
import ReactTooltip from 'react-tooltip';
import optionGrey from 'images/icon/options_grey.png';

class TableWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOptionsOpen: false,
			dropdownCreateOpen: false,
			viewType: 'list',
			done: false,
			modalFilterDate: false,
			modalFilterOwner: false,
			modalFilterGroup: false
		};
	}

	componentWillReceiveProps(nextProps) {
		const { data } = this.props;
		if (
			nextProps.data.length > 0 &&
      data !== nextProps.data &&
      !this.state.done
		) {
			this.setState({
				currentDetailId: nextProps.data[0].id,
				done: true
			});
		}
	}

	onToggleFilterDate = () => {
		this.setState(prevState => ({
			modalFilterDate: !prevState.modalFilterDate
		}));
	};

	onToggleFilterOwner = () => {
		this.setState(prevState => ({
			modalFilterOwner: !prevState.modalFilterOwner
		}));
	};

	onToggleFilterGroup = () => {
		this.setState(prevState => ({
			modalFilterGroup: !prevState.modalFilterGroup
		}));
	};

	toggleOptions = () => {
		this.setState(prevState => ({
			dropdownOptionsOpen: !prevState.dropdownOptionsOpen
		}));
	};

	toggleCreate = () => {
		this.setState(prevState => ({
			dropdownCreateOpen: !prevState.dropdownCreateOpen
		}));
	};

	onSelect = row => {
		// console.log('row', row);
		this.setState({
			selectedRows: row.toString(),
			selectedRowsArray: row
		});
	};

	onOpenModalExport = () => {
		this.setState({ modalExport: true });
	};

	onCloseModalExport = () => {
		this.setState({ modalExport: false });
	};

	onOpenModalImport = () => {
		this.setState({ modalImport: true });
	};

	onCloseModal = () => {
		this.setState({ modalImport: false });
	};

	toggle() {
		this.setState({
			modal: false
		});
	}

	render() {
		const { viewType } = this.state;
		const {
			data,
			page,
			onTableChange,
			route,
			totalSize,
			columns,
			lastPage,
			handleFilter,
			isSelectRow,
			showPagination,
			loading,
			enableDownload,
			disablePagination,
			onNext,
			onPrev,
			onFirstPage,
			onLastPage,
			onExport,
			current,
			getterValue,
			onBulkDelete,
			onImport,
			match,
			limit,
			handleLimit,
			handleFilterGroup
		} = this.props;

		return (
			(<div>
				<div className="table-bg sticky-v2 right-panel">
					<div className="row d-flex justify-content-between">
						{route.subPath ? (
							<Fragment>
								<div className="col-md-8 card-title mb-0 my-auto d-flex justify-content-start pl-3">
									<div className="d-flex flex-row mr-4">
										<Link to='/formBuilder' className="my-auto">
											<h2
												className="card-title mr-4 mb-0"
											>
												{route.title}
											</h2>
										</Link>
										<h2 
											className="card-title font-weight-bold my-auto"
											style={{
												maxWidth: '20rem',
												overflowX: 'hidden',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis'
											}}
											data-tip={`${get(getterValue, 'name')}`}
										>
											{/* {route.subTitle} */}
											{get(getterValue, 'name')}
										</h2>
										<ReactTooltip
											place='bottom'
											effect='solid'
											delayShow={400}
											type='light'
											arrowColor={BlueSidely}
											textColor="white"
											backgroundColor={BlueSidely}
										/>
									</div>
									<div className="d-flex flex-row">
										<Filter onFilter={handleFilter} />
										<FilterUser
											onFilter={handleFilter}
											handleFilterGroup={handleFilterGroup}
											current={current}
										/>
									</div>
								</div>
							</Fragment>
						) : (
							<div className="col-md-7" />
						)}
						<div className="col-md-2 d-flex justify-content-end pr-3 pointer">
							<React.Fragment>
								<Restricted to={{ objectAction: 'CreateFreeForm' }}>
									{route.subPath ? (
										<div className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle">
											<a href={`/formBuilder/formData/${match.params.id}/create`}>
												<img
													src={add_blue}
													className="custom-icon"
													alt=""
												/>
											</a>
										</div>
									) : (
										<div className="my-auto mx-1">
											<div className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle">
												<a
													onClick={() => {
														this.setState({
															modal: true
														});
													}}
												>
													<img
														src={add_blue}
														className="custom-icon"
														alt=""
													/>
												</a>
											</div>
										</div>
									)}
								</Restricted>
								<Restricted to={[{ objectAction: 'CreateExport' }, { objectAction: 'DeleteFreeForm' }]}>
									<div className="my-auto mx-2">
										<ButtonDropdown
											className="border-transparent"
											isOpen={this.state.dropdownOptionsOpen}
											toggle={this.toggleOptions}
										>
											<DropdownToggle className="bg-white p-0">
												<img
													src={optionGrey}
													className="custom-icon"
													alt=""
												/>
											</DropdownToggle>
											<DropdownMenu className="custom-position-v2">
												<Fragment>
													<Restricted to={{ objectAction: 'CreateExport' }}>
														<DropdownItem
															onClick={() => {
																onExport(this.state.selectedRowsArray);
															}}
														>
															<Translate id="export_forms" />
														</DropdownItem>
													</Restricted>
													<Restricted to={{ objectAction: 'DeleteFreeForm' }}>
														<DropdownItem
															onClick={() => {
																onBulkDelete(this.state.selectedRowsArray);
															}}
														>
															<Translate id="delete_selection" />
														</DropdownItem>
													</Restricted>
												</Fragment>
											</DropdownMenu>
										</ButtonDropdown>
									</div>
								</Restricted>
							</React.Fragment>
						</div>
					</div>
				</div>
				{viewType === 'list' && (
					<ListView
						data={data}
						page={page}
						onTableChange={onTableChange}
						route={route}
						totalSize={totalSize}
						columns={columns}
						lastPage={lastPage}
						handleFilter={handleFilter}
						isSelectRow={isSelectRow}
						showPagination={showPagination}
						loading={loading}
						enableDownload={enableDownload}
						disablePagination={disablePagination}
						onNext={onNext}
						onPrev={onPrev}
						onLastPage={onLastPage}
						onFirstPage={onFirstPage}
						onSelect={this.onSelect}
						limit={limit}
						handleLimit={handleLimit}
					/>
				)}
				<ModalExport onRef={ref => (this.child = ref)} {...this.props} />
			</div>)
		);
	}
}

export default TableWrapper;
