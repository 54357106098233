import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { BlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { Radio } from '../../filterList/style/Style';
import Input from '../../input/Input';
import { FilterAction, FilterAdditionalValue, FilterId, FilterQueryResult, FilterTree, FilterValueResult } from '../model/Model';
import { Container, FilterText, RadioContainer } from '../style/AdvancedFilterStyle';
import { filterIdFormater } from './FilterSelection';
import { FilterToIndex } from './utils';
import { DropdownSearchCompanies } from '../../dropdown/DropdownSearch';
import { ClientCompany } from '../../../containers_v2/orders/model/Model';
import { DropdownData } from '../../dropdown/model/Model';

function indexToFilter(index: number | undefined): FilterAction | undefined {
	switch (index) {
		case 0:
			return FilterAction.IS_EQUAL;
		case 1:
			return FilterAction.IS_NOT_EQUAL;
		case 2:
			return FilterAction.CONTAINS;
		case 3:
			return FilterAction.DOES_NOT_CONTAINS;
		case 4:
			return FilterAction.START_WITH;
		case 5:
			return FilterAction.END_WITH;
		case 6:
			return FilterAction.IS_EMPTY;
		case 7:
			return FilterAction.IS_NOT_EMPTY;
		default:
			return;
	}
}

function switchFilterToIndex(filter: FilterAction | undefined): number | undefined {
	switch (filter) {
		case FilterAction.IS_EQUAL:
			return 0;
		case FilterAction.IS_NOT_EQUAL:
			return 1;
		case FilterAction.CONTAINS:
			return 2;
		case FilterAction.DOES_NOT_CONTAINS:
			return 3;
		case FilterAction.START_WITH:
			return 4;
		case FilterAction.END_WITH:
			return 5;
		case FilterAction.IS_EMPTY:
			return 6;
		case FilterAction.IS_NOT_EMPTY:
			return 7;
		default:
			return;
	}
}

const MAPPINGS = {
	'parent_name': 'parent_id'
};

function StringFilter(props: {
  id: FilterId
  title: string
  onReturn: () => void
  onSubmit: (value: FilterValueResult) => void
  additionalValue?: FilterAdditionalValue
  selectedValue: FilterTree | undefined
}): JSX.Element {
	let selectedValue: FilterQueryResult | undefined;
	if (props.selectedValue && 'val' in props.selectedValue) {
		selectedValue = props.selectedValue.val;
	}
	const [index, setindex] = React.useState<number | undefined>(FilterToIndex(selectedValue?.operator, switchFilterToIndex));
	const [value, setValue] = React.useState<string>(selectedValue?.value ?? '');
	const [dropdownSearchValue, setDropdownSearchValue] = React.useState<DropdownData<ClientCompany>>();
	const id = filterIdFormater(props.id, props.additionalValue);

	const mappingID: string | undefined = MAPPINGS[typeof props.id === 'string' ? props.id : '']; 

	React.useEffect(() => {
		setValue(selectedValue?.value ?? '');
	}, [index]);

	function submit(): void {
		if (!((value && value.trim().length > 0) || index === 6 || index === 7 || (mappingID && (index === 0 || index === 1) && dropdownSearchValue))) {
			return;
		}
		const action = indexToFilter(index);
		if (!action) return;
		if (mappingID && dropdownSearchValue && (index === 0 || index === 1)) {
			props.onSubmit({
				action,
				type: 'numeric',
				title: props.title,
				id: mappingID,
				value: dropdownSearchValue.value.id,
				titleComment: props.additionalValue?.titleComment,
				displayedValue: dropdownSearchValue.label
			});
			return;
		}
		props.onSubmit({
			action,
			type: 'string',
			title: props.title,
			id,
			value,
			titleComment: props.additionalValue?.titleComment,
		});
	}

	return (
		<Container>
			<FilterText color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.onReturn()}>&lt;<Translate id='back' /></FilterText>
			<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{props.title}</FilterText>
			<RadioContainer onClick={() => setindex(0)}>
				<Radio isActive={index === 0} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is' /></FilterText>
			</RadioContainer>
			{
				index === 0 && mappingID &&
				<DropdownSearchCompanies
					dropdownStyle={{ containerWidth: '100%', width: '100%' }}
					parent
					name='dropdown_search_Companies_filter'
					selectedValue={dropdownSearchValue}
					onChange={(e: DropdownData<ClientCompany>) => setDropdownSearchValue(e)}
				/>
			}
			{index == 0 && !mappingID && <Input name="filter_value" type="text" onChange={(value) => setValue(value)} value={value} />}
			<RadioContainer onClick={() => setindex(1)}>
				<Radio isActive={index === 1} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not' /></FilterText>
			</RadioContainer>
			{
				index === 1 && mappingID &&
				<DropdownSearchCompanies
					dropdownStyle={{ containerWidth: '100%', width: '100%' }}
					parent
					name='dropdown_search_Companies_filter'
					selectedValue={dropdownSearchValue}
					onChange={(e: DropdownData<ClientCompany>) => setDropdownSearchValue(e)}
				/>
			}
			{index == 1 && !mappingID && <Input name="filter_value" type="text" onChange={(value) => setValue(value)} value={value} />}
			<RadioContainer onClick={() => setindex(2)}>
				<Radio isActive={index === 2} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='contains' /></FilterText>
			</RadioContainer>
			{
				index === 2 &&
                <Input name="filter_value" type="text" onChange={(value) => setValue(value)} value={value} />
			}
			<RadioContainer onClick={() => setindex(3)}>
				<Radio isActive={index === 3} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='do_not_contains' /></FilterText>
			</RadioContainer>
			{
				index === 3 &&
                <Input name="filter_value" type="text" onChange={(value) => setValue(value)} value={value} />
			}
			<RadioContainer onClick={() => setindex(4)}>
				<Radio isActive={index === 4} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='start_with' /></FilterText>
			</RadioContainer>
			{
				index === 4 &&
                <Input name="filter_value" type="text" onChange={(value) => setValue(value)} value={value} />
			}
			<RadioContainer onClick={() => setindex(5)}>
				<Radio isActive={index === 5} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='end_with' /></FilterText>
			</RadioContainer>
			{
				index === 5 &&
                <Input name="filter_value" type="text" onChange={(value) => setValue(value)} value={value} />
			}
			<RadioContainer onClick={() => setindex(6)}>
				<Radio isActive={index === 6} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_empty' /></FilterText>
			</RadioContainer>
			<RadioContainer onClick={() => setindex(7)}>
				<Radio isActive={index === 7} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not_empty' /></FilterText>
			</RadioContainer>

			<DefaultButton width="125px" disabled={
				!((value && value.trim().length > 0) || index === 6 || index === 7 || (mappingID && (index === 0 || index === 1) && dropdownSearchValue))
			} onClick={() => submit()}><Translate id='add_filter' /></DefaultButton>
		</Container>
	);
}

export default StringFilter;
