import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { SidelyBlack } from '../../../styles/global/css/Utils';

export const Container = styled.div`
    width: 100%;
    margin: 15px 0;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: ${SidelyBlack};
`;

export const HeaderText = styled.div`
    ${DefaultText}
    color: ${SidelyBlack};
    font-weight: 600;
    font-size: 12px;
`;

export const Body = styled.div`
    width: 100%;
`;

interface BodyCollapseProps {
    isOpen: boolean
}

export const BodyCollapse = styled.div<BodyCollapseProps>`
    width: 100%;
    display: ${p => p.isOpen ? 'initial' : 'none'};
`;