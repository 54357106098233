import { useState } from 'react';
import { translateToString } from '../../../styles/global/translate';

export function reportErrorMessage(msg?: string): string {
	let errMsg = translateToString('reports.loading_error');
	console.log('reportErrorMessage', msg);
	if (msg === 'Unsupported additional column type') {
		errMsg = errMsg + ': ' + translateToString('report_editor.error_messages.unsupported_additional_column_type');
	}
	if (msg === '(byte:0) too large buffer') {
		errMsg = errMsg + ': ' + translateToString('report_editor.error_messages.too_large_buffer');
	}
	if (msg === 'Too many columns') {
		errMsg = errMsg + ': ' + translateToString('report_editor.error_messages.too_many_columns');
	}
	if (msg === 'Network Error') {
		errMsg = errMsg + ': ';
	}
	return errMsg;
}