import * as React from 'react';
import { FlexDiv } from '../../products/style';
import { Label, LabelContainer } from '../../../components_v2/dropdown/style/Style';
import DropdownTagsCloud from '../../../components_v2/dropdown/DropdownTagsCloud';
import { DashboardPopupContext } from './DashboardPopup';

export default function DropdownTagsCloudInDashBoardPopup() {
	const { tags, selectedTags, onChangeTags, onDeleteTags } = React.useContext(DashboardPopupContext)!;

	return <FlexDiv justify="center" width="100%">
		<FlexDiv width="400px" flow="column">
			<LabelContainer alignSelf="flex-start">
				<Label>Tags</Label>
			</LabelContainer>
			<DropdownTagsCloud
				tags={tags.filter(t => selectedTags?.find(st => st.id === t.id) === undefined).map(t => ({ ...t, label: t.name }))}
				selected={selectedTags?.map(i => i.value.tag ?? [])}
				operators={['and', ...selectedTags.map(t => t.value.operator ?? 'and')]}
				onChange={onChangeTags}
				onDelete={onDeleteTags}
				combination
				emptyWhenNone
			/>
		</FlexDiv>
	</FlexDiv>;
}
