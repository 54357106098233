import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Translate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import FieldDropzoneDoc from '../../../../../components/dropzone/FieldDropzoneDoc';
import {createContactFile} from '../../../../../store/actions/contacts.action';

class ModalCreateDocument extends Component {
  state = {};

  onSubmit = value => {
    const { createContactFile, id } = this.props;
    const formData = new FormData();

    const keys = ['documents'];

    keys.map(key => {
      value[key] && formData.append(key, value[key].value || value[key]);
    });

    formData.append('contactId', id.contactId);

    createContactFile(formData, id);
  };

  render() {
    const { handleSubmit, id, isLoading } = this.props;
    // console.log('id', id);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader toggle={this.props.toggle}>
          <Translate id="add" /> Document
        </ModalHeader>
        <ModalBody>
          <form
            className="forms-sample background-form"
            onSubmit={handleSubmit(this.onSubmit)}
            style={{ boxShadow: 'none' }}
          >
            <Fragment>
              <div className="row">
                <div className="col-md-11">
                  <div className="row">
                    <div className="col-md-12">
                      <Field
                        name="documents"
                        type="text"
                        label="Document"
                        id="inputAvatar"
                        component={FieldDropzoneDoc}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4" />
              </div>
            </Fragment>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit(this.onSubmit)}
            loading={isLoading}
            spinColor="#003d79"
          >
            {!isLoading && <Translate id="save" />}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.contacts.isLoading
});

export default reduxForm({
  form: 'modalDocumentForm'
})(
  connect(
    mapStateToProps,
    { createContactFile }
  )(ModalCreateDocument)
);
