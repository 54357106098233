import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';

// import ComponentHeader from './ComponentHeader';

class DropdownList extends Component {
  state = {};

  render() {
    const { data } = this.props;
    const opt = data.accepted_values;
    const select = opt && opt.split(';');

    return (
      <div className="form-group">
        <label className="control-label" htmlFor={`id-${data.id}`}>
          {data.is_required && <span className="text-danger">* </span>}
          {data.is_display_field_name && data.field_name}
        </label>

        <select
          id={`id-${data.id}`}
          className="form-control"
          defaultValue={
            data.initial_value &&
            data.initial_value !== 'empty' &&
            data.initial_value
          }
        >
          <option value="" disabled selected>
            <Translate id="select options" />
          </option>
          {select && select.map(data => <option>{data}</option>)}
        </select>
      </div>
    );
  }
}

export default DropdownList;
