import styled from 'styled-components';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BorderColor, SidelyBlack } from '../../../styles/global/css/Utils';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

interface HeaderProps {
  backgroundColor?: string
}

const Header = styled.div<HeaderProps>`
    width: 100%;
    height: 5%;
    background: ${p => p.backgroundColor ?? 'white'};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${BorderColor};
    min-height: 45px;
`;

const CrossClose = styled(DefaultImage)<{auto?: boolean, width?: string, height?: string, size?: string}>`
    margin-right: 20px;
    cursor: pointer;
    width: ${p => p.width ?? p.size ?? '20px'};
    height: ${p => p.height ?? p.size ?? '20px'};
    margin-left: ${p => p.auto ? 'auto' : '20px'};
`;

const HeaderTitle = styled.p`
    ${DefaultText}
    color: ${SidelyBlack};
    font-weight: 700;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`;

export const CommentHeaderTitle = styled.p`
    margin: 0;
    font-size: 10px;
    line-height: 10px;
    color: ${SidelyBlack};
    width: 100%;
`;

interface BodyProps {
  hideValidation?: boolean
  overflow?: string
}

const Body = styled.div<BodyProps>`
    width: 100%;
    height: ${p => p.hideValidation ? '95%' : '85%'};
    margin-top: 15px;
    overflow-y: ${p => p.overflow ?? 'auto'};
    overflow-x: ${p => p.overflow ?? 'hidden'};
`;

const Footer = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-height: 62px;
`;

const ValidateButton = styled(DefaultButton)`
    margin: 0;
    min-width: 150px;
    width: fit-content;
`;

export {
	Container,
	Header,
	HeaderTitle,
	Body,
	Footer,
	ValidateButton,
	CrossClose
};
