import React, {Component} from 'react';
// import ComponentHeader from './ComponentHeader';
import {Translate} from 'react-localize-redux';

class FreeText extends Component {
  constructor(props) {
    super(props);
    this.options = {};
  }

  render() {
    const { data } = this.props;
    const initial_value = data.values && data.values.initial_value || null
    return (
      <div className="form-group checkbox">
        <label id={`id-${data.shelf_audit_field_id}`} className="control-label" htmlFor="">
          {data.mandatory && <span className="text-danger">* </span>}
          <Translate id={data.field_name} />
        </label>
        <input
          id={`id-${data.id}`}
          className="form-control"
          type="checkbox"
          defaultChecked={initial_value}
        // checked={data.is_default_checked}
        />
      </div>
    );
  }
}

export default FreeText;
