import list_with_pen from 'images/icon/list_with_pen.svg';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { ATimeRangeFilter2 } from '../../../atoms/filter/timeFilterAtom';
import Dropdown from '../../dropdown/Dropdown';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { translateToString } from '../../../styles/global/translate';
import { GenericToolbarFilterProps } from '../ToolbarFilter';

export type TimeRange2 = 'today' | 'thisweek' | 'nextweek' | 'prevweek' | 'thismonth' | 'nextmonth' | 'prevmonth' | 'thisquarter' | 'nextquarter' | 'prevquarter' | 'thisyear' | 'nextyear' | 'prevyear'
export const ALL_TIME_RANGES: { value: TimeRange2, future: boolean }[] = [
	{ value: 'today', future: false },
	{ value: 'thisweek', future: false },
	{ value: 'nextweek', future: true },
	{ value: 'prevweek', future: false },
	{ value: 'thismonth', future: false },
	{ value: 'nextmonth', future: true },
	{ value: 'prevmonth', future: false },
	{ value: 'thisquarter', future: false },
	{ value: 'nextquarter', future: true },
	{ value: 'prevquarter', future: false },
	{ value: 'thisyear', future: false },
	{ value: 'nextyear', future: true },
	{ value: 'prevyear', future: false },
];

export default function TimeRangeFilter2(props: GenericToolbarFilterProps & {
  defaultValue?: TimeRange2 | null,
  disabled?: boolean,
  future?: boolean,
  allowNull?: boolean,
}): JSX.Element {
	const { defaultValue } = props;
	const options: TimeRange2[] = ALL_TIME_RANGES.filter(v => props.future === false ? v.future === false : true).map(v => v.value);
	const [timeRange, setTimeRange] = useRecoilState(ATimeRangeFilter2);
	const [selectedValue, setSelectedValue] = React.useState<TimeRange2 | null>(timeRange ?? (defaultValue !== undefined ? defaultValue : 'thisquarter'));

	if (props.hidden) return <></>;
	
	let datalist: { label: string, value: TimeRange2 | null }[] = options.map(value => ({ label: translateToString('tool_bar.range.' + value), value }));
	if (props.allowNull) {
		datalist = [{ label: translateToString('tool_bar.range.null'), value: null }, ...datalist];
	}

	return <Dropdown
		disabled={props.disabled}
		dropdownStyle={{ ...DROPDOWN_FILTER_STYLE, width: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.width, containerWidth: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.containerWidth }}
		selectedValue={{ label: translateToString('tool_bar.range.' + selectedValue), value: selectedValue }}
		datalist={datalist}
		readOnly
		freezeShowLabel
		name='dropdown_scope_filter_2'
		onChange={value => {
			setTimeRange(value.value);
			setSelectedValue(value.value);
		}}
		image={list_with_pen}
	/>;
}

// This code has been written by the large language model

export function TimeRange2ToDates(timeRange: TimeRange2): { start: Date, end: Date } {
	const now = new Date();
	const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	const start = new Date(today);
	const end = new Date(today);
	switch (timeRange) {
		case 'today':
			return { start: start, end: end };
		case 'thisweek':
			start.setDate(today.getDate() - today.getDay());
			end.setDate(start.getDate() + 6);
			return { start: start, end: end };
		case 'nextweek':
			start.setDate(today.getDate() - today.getDay() + 7);
			end.setDate(start.getDate() + 6);
			return { start: start, end: end };
		case 'prevweek':
			start.setDate(today.getDate() - today.getDay() - 7);
			end.setDate(start.getDate() + 6);
			return { start: start, end: end };
		case 'thismonth':
			start.setDate(1);
			end.setMonth(start.getMonth() + 1);
			end.setDate(0);
			return { start: start, end: end };
		case 'nextmonth':
			start.setDate(1);
			start.setMonth(start.getMonth() + 1);
			end.setMonth(start.getMonth() + 1);
			end.setDate(0);
			return { start: start, end: end };
		case 'prevmonth':
			start.setDate(1);
			start.setMonth(start.getMonth() - 1);
			end.setMonth(start.getMonth() + 1);
			end.setDate(0);
			return { start: start, end: end };
		case 'thisquarter':
			start.setMonth(Math.floor(today.getMonth() / 3) * 3);
			end.setMonth(start.getMonth() + 3);
			end.setDate(0);
			return { start: start, end: end };
		case 'nextquarter':
			start.setMonth(Math.floor(today.getMonth() / 3) * 3 + 3);
			end.setMonth(start.getMonth() + 3);
			end.setDate(0);
			return { start: start, end: end };
		case 'prevquarter':
			start.setMonth(Math.floor(today.getMonth() / 3) * 3 - 3);
			end.setMonth(start.getMonth() + 3);
			end.setDate(0);
			return { start: start, end: end };
		case 'thisyear':
			start.setMonth(0);
			start.setDate(1);
			end.setMonth(12);
			end.setDate(0);
			return { start: start, end: end };
		case 'nextyear':
			start.setMonth(0);
			start.setDate(1);
			start.setFullYear(start.getFullYear() + 1);
			end.setMonth(12);
			end.setDate(0);
			end.setFullYear(start.getFullYear() + 1);
			return { start: start, end: end };
		case 'prevyear':
			start.setMonth(0);
			start.setDate(1);
			start.setFullYear(start.getFullYear() - 1);
			end.setMonth(12);
			end.setDate(0);
			end.setFullYear(start.getFullYear() - 1);
			return { start: start, end: end };
	}
}

export function TimeRange2ToBegining(timeRange: TimeRange2): Date {
	return TimeRange2ToDates(timeRange).start;
}