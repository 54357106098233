import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';
import { alertAction } from '../reducers/app.reducer';
import httpService from '../../services/http.service';
import { handleError, handleErrorWithMessage } from '../../helpers/error';

const cookies = new Cookies();

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const DETAIL_USER_REQUEST = 'DETAIL_USER_REQUEST';
export const DETAIL_USER_SUCCESS = 'DETAIL_USER_SUCCESS';
export const DETAIL_USER_ERROR = 'DETAIL_USER_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const USERID_BYTOKEN_REQUEST = 'USERID_BYTOKEN_REQUEST';
export const USERID_BYTOKEN_SUCCESS = 'USERID_BYTOKEN_SUCCESS';
export const USERID_BYTOKEN_ERROR = 'USERID_BYTOKEN_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_ERROR = 'CURRENT_USER_ERROR';

export const GET_USER_BYCOMPANY_REQUEST = 'GET_USER_BYCOMPANY_REQUEST';
export const GET_USER_BYCOMPANY_SUCCESS = 'GET_USER_BYCOMPANY_SUCCESS';
export const GET_USER_BYCOMPANY_ERROR = 'GET_USER_BYCOMPANY_ERROR';

export const SEARCH_USER = 'SEARCH_USER';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

export const SAVE_USER_WITH_ROLE_SALES = 'SAVE_USER_WITH_ROLE_SALES';
export const ERROR_SAVE_USER_WITH_ROLE_SALES =
  'ERROR_SAVE_USER_WITH_ROLE_SALES';

const PATH = `${URL_API}/api`;

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_USER_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/users`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_USER_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_USER_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_USER_ERROR,
					error
				});
				throw error;
			});
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DETAIL_USER_REQUEST,
			loading: true
		});
		return axios({
			method: 'GET',
			url: `${PATH}/show/${id}`
		}).then(
			response => {
				dispatch({
					type: DETAIL_USER_SUCCESS,
					payload: response.data
				});
			},
			error => {
				dispatch({
					type: DETAIL_USER_ERROR,
					error: error.response
				});
			}
		);
	};
}

export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_USER_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/users`,
			data
		}).then(
			response => {
				dispatch({
					type: CREATE_USER_SUCCESS,
					payload: response.data
				});

				window.location.href = '/teamManagement';
			},
			error => {
				handleErrorWithMessage(error, dispatch, CREATE_USER_ERROR);
			}
		);
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_USER_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/users/${id}`,
			// headers: { 'Content-Type': 'multipart/form-data' },
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_USER_SUCCESS,
					payload: response.data
				});

				window.location.href = `/teamManagement/detail/user/${id}`;
			},
			error => {
				handleErrorWithMessage(error, dispatch, UPDATE_USER_ERROR);
			}
		);
	};
}

export function updateProfile(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: UPDATE_USER_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/users/updateProfile`,
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_USER_SUCCESS,
					payload: response.data
				});

				window.location.href = '/profile';
			},
			error => {
				handleErrorWithMessage(error, dispatch, UPDATE_USER_ERROR);
			}
		);
	};
}

export function deleteData(id, paramsRedirect) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_USER_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/id/${id}`
		}).then(
			response => {
				dispatch({
					type: DELETE_USER_SUCCESS,
					payload: response.data
				});
				dispatch(
					alertAction({
						text: 'User has been deleted',
						show: true,
						type: 'danger'
					})
				);
				window.location.href = window.location.pathname;
			},
			error => {
				dispatch({
					type: DELETE_USER_ERROR,
					error: error.response
				});
			}
		);
	};
}

export const currentData = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	const isSuperAdmin = cookies.get('superadmin') || null;

	axios.defaults.headers.common.Authorization = `${token}`;
	if (!token) {
		return;
	}
  
	try {
		dispatch({
			type: CURRENT_USER_REQUEST
		});
		const res = await axios.get(`${URL_API}/api/users/token`);
		let payload = res.data;
		payload.data?.isSuperAdmin = isSuperAdmin === 'true';
		dispatch({
			type: CURRENT_USER_SUCCESS,
			payload
		});
	} catch (error) {
		dispatch({
			type: CURRENT_USER_ERROR
		});
		// console.log('errorGetCurrent', error);
	}
};

export const searchData = data => async dispatch => {
	dispatch({
		type: SEARCH_USER,
		payload: data
	});
};

export const FETCH_USER_COMPANIES_REQUEST = 'FETCH_USER_COMPANIES_REQUEST';
export const FETCH_USER_COMPANIES_SUCCESS = 'FETCH_USER_COMPANIES_SUCCESS';
export const FETCH_USER_COMPANIES_ERROR = 'FETCH_USER_COMPANIES_ERROR';

export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';

export const setActiveFilter = data => async dispatch => {
	dispatch({
		type: SET_ACTIVE_FILTER,
		payload: data
	});
};

export function exportExcel(data, typeData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/${typeData}/exportExcel`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${typeData}.xlsx`);
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				// dispatch({ type: FETCH_COMPANIES_ERROR, error });

				throw error;
			});
}

export function exportCsv(data, typeData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/${typeData}/exportCsv`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${typeData}.csv`);
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				// dispatch({ type: FETCH_COMPANIES_ERROR, error });

				throw error;
			});
}

export function importExcel(data, typeData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: IMPORT_EXCEL_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/${typeData}/importExcel`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: IMPORT_EXCEL_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Import success!', 'success').then(() => {
					window.location.href = '/teamManagement';
					// });
				},
				error => {
					dispatch({
						type: IMPORT_EXCEL_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
				}
			)
			.catch(error => {
				dispatch({
					type: IMPORT_EXCEL_ERROR,
					error
				});
				throw error;
			});
	};
}