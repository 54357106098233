const toolbarList = [
	{
		title: 'Layout',
		items: [
			{
				key: 'StartSection',
				name: 'Start Section',
				icon: 'fas fa-heading',
				img: 'start-section.png',
				static: true,
				content: 'Section Text...'
			},
			{
				key: 'EndSection',
				name: 'End Section',
				icon: 'fas fa-heading',
				img: 'start-section.png',
				static: true,
				content: ''
			},
			{
				key: 'StartTable',
				name: 'Start Table',
				icon: 'fas fa-heading',
				img: 'table.svg',
				static: true,
				content: 'Table Text...'
			},
			{
				key: 'EndTable',
				name: 'End Table',
				icon: '',
				img: 'table.svg',
				static: true,
				content: ''
			},
			{
				key: 'Header',
				name: 'Header Text',
				field_type: 'header',
				icon: 'demo-icon icon-b',
				static: true,
				content: 'Title'
			},
			{
				key: 'Image',
				name: 'Image',
				label: '',
				icon: 'icon-image',
				field_name: 'image_',
				content: 'Title',
				src: ''
			}
		]
	},

	{
		title: 'Basic Fields',
		items: [
			{
				key: 'FreeText',
				field_type: 'free_text',
				canHaveAnswer: true,
				name: 'Free Text',
				label: 'Placeholder Label',
				icon: 'demo-icon icon-t',
				field_name: 'text_input_',
				content: 'Label'
			},
			{
				key: 'Numerical',
				field_type: 'numerical',
				canHaveAnswer: true,
				name: 'Numerical',
				label: 'Placeholder Label',
				icon: 'demo-icon icon-1',
				field_name: 'numeric_input_',
				content: 'Label'
			},
			{
				key: 'DropdownList',
				canHaveAnswer: true,
				name: 'Dropdown List',
				label: 'Placeholder Label',
				img: 'list.svg',
				content: 'Label',
				options: '',
				default: ''
			},
			{
				key: 'DatePicker',
				canHaveAnswer: true,
				name: 'Date',
				label: 'Placeholder Label',
				img: 'calendar.svg',
				content: 'Label'
			},
			{
				key: 'Checkbox',
				canHaveAnswer: true,
				name: 'Checkbox',
				label: 'Placeholder Label',
				img: 'checkbox.svg',
				content: 'Placeholder Label'
			}
			// {
			//   key: 'Email',
			//   canHaveAnswer: true,
			//   name: 'Email',
			//   label: 'Placeholder Label',
			//   img: 'email.svg'
			// },
			// {
			//   key: 'Website',
			//   canHaveAnswer: true,
			//   name: 'Website',
			//   label: 'Placeholder Label',
			//   img: 'web.svg'
			// },
			// {
			//   key: 'Document',
			//   canHaveAnswer: true,
			//   name: 'Document',
			//   label: 'Placeholder Label',
			//   img: 'file.svg'
			// },
			// {
			//   key: 'AddressField',
			//   canHaveAnswer: true,
			//   name: 'Address Field',
			//   label: 'Placeholder Label',
			//   img: 'map.svg'
			// }
			// {
			//   key: 'Dropdown',
			//   canHaveAnswer: true,
			//   name: 'Dropdown',
			//   icon: 'far fa-caret-square-down',
			//   label: 'Placeholder Label',
			//   field_name: 'dropdown_',
			//   options: []
			// },
			// {
			//   key: 'Tags',
			//   canHaveAnswer: true,
			//   name: 'Tags',
			//   icon: 'fas fa-tags',
			//   label: 'Placeholder Label',
			//   field_name: 'tags_',
			//   options: []
			// },
			// {
			//   key: 'Checkboxes',
			//   canHaveAnswer: true,
			//   name: 'Checkboxes',
			//   icon: 'far fa-check-square',
			//   label: 'Placeholder Label',
			//   field_name: 'checkboxes_',
			//   options: []
			// },
			// {
			//   key: 'RadioButtons',
			//   canHaveAnswer: true,
			//   name: 'Multiple Choice',
			//   icon: 'far fa-dot-circle',
			//   label: 'Placeholder Label',
			//   field_name: 'radiobuttons_',
			//   options: []
			// },
			// {
			//   key: 'TextInput',
			//   canHaveAnswer: true,
			//   name: 'Text Input',
			//   label: 'Placeholder Label',
			//   icon: 'fas fa-font',
			//   field_name: 'text_input_'
			// },
			// {
			//   key: 'NumberInput',
			//   canHaveAnswer: true,
			//   name: 'Number Input',
			//   label: 'Placeholder Label',
			//   icon: 'fas fa-plus',
			//   field_name: 'number_input_'
			// }
		]
	},

	{
		title: 'Links and system fields',
		items: [
			{
				key: 'UserField',
				canHaveAnswer: true,
				name: 'User Field',
				label: 'Placeholder Label',
				img: 'user.svg',
				content: 'Label'
			},
			{
				key: 'Hyperlink',
				canHaveAnswer: true,
				name: 'Hyperlink',
				label: 'Placeholder Label',
				img: 'attach.svg',
				content: 'Link Label'
			},
			{
				key: 'Camera',
				name: 'Camera',
				icon: 'fas fa-camera',
				label: 'Placeholder Label',
				img: 'camera.svg',
				field_name: 'camera_',
				content: 'Label'
			}
			// {
			//   key: 'Calculation',
			//   canHaveAnswer: true,
			//   name: 'Calculation',
			//   label: 'Placeholder Label',
			//   img: 'calc.svg'
			// }
			// {
			//   key: 'TextArea',
			//   canHaveAnswer: true,
			//   name: 'Multi-line Input',
			//   label: 'Placeholder Label',
			//   icon: 'fas fa-text-height',
			//   field_name: 'text_area_'
			// },
			// {
			//   key: 'Image',
			//   name: 'Image',
			//   label: '',
			//   icon: 'far fa-image',
			//   field_name: 'image_',
			//   src: ''
			// },
			// {
			//   key: 'Rating',
			//   canHaveAnswer: true,
			//   name: 'Rating',
			//   label: 'Placeholder Label',
			//   icon: 'fas fa-star',
			//   field_name: 'rating_'
			// },
			// {
			//   key: 'DatePicker',
			//   canDefaultToday: true,
			//   canReadOnly: true,
			//   dateFormat: 'MM/dd/yyyy',
			//   timeFormat: 'hh:mm aa',
			//   showTimeSelect: false,
			//   showTimeSelectOnly: false,
			//   name: 'Date',
			//   icon: 'far fa-calendar-alt',
			//   label: 'Placeholder Label',
			//   field_name: 'date_picker_'
			// },
			// {
			//   key: 'Signature',
			//   canReadOnly: true,
			//   name: 'Signature',
			//   icon: 'fas fa-pen-square',
			//   label: 'Signature',
			//   field_name: 'signature_'
			// },
			// {
			//   key: 'HyperLink',
			//   name: 'Web site',
			//   icon: 'fas fa-link',
			//   static: true,
			//   content: 'Placeholder Web site link ...',
			//   href: 'http://www.example.com'
			// },
			// {
			//   key: 'Download',
			//   name: 'File Attachment',
			//   icon: 'fas fa-file',
			//   static: true,
			//   content: 'Placeholder file name ...',
			//   field_name: 'download_',
			//   file_path: '',
			//   _href: ''
			// },
			// {
			//   key: 'Range',
			//   name: 'Range',
			//   icon: 'fas fa-sliders-h',
			//   label: 'Placeholder Label',
			//   field_name: 'range_',
			//   step: 1,
			//   default_value: 3,
			//   min_value: 1,
			//   max_value: 5,
			//   min_label: 'Easy',
			//   max_label: 'Difficult'
			// },
		]
	}
];

export default toolbarList;
