import React from 'react';
import {PropagateLoader as Loader} from 'react-spinners';

const PropagateLoader = () => (
  <div id="preloader">
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="sweet-loading">
        <Loader size={15} color="#00b2ff" loading />
      </div>
    </div>
  </div>
);

export default PropagateLoader;
