import { Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../../atoms/global/users';
import * as React from 'react';
import { Column, Table } from '../../../../../components_v2/table/Table';
import { SeeMore } from '../style/LinkedElementStyle';
import { FormInstance, getLinkedFormInstance } from '../../../../forms/actions';
import { translateToString } from '../../../../../styles/global/translate';
import { DarkGreySidely } from '../../../../../styles/global/css/Utils';
import { FlexDiv } from '../../../../products/style';
import { DateAccessor } from '../../../data/CompanyColumns';
import { TableRow } from '../../../../orders/templateOrders/style/Style';
import { NoDataText, PartialOpen } from '../../../style/Style';
import { InstancePopup } from '../../../../forms/formInstancePopup';
import { ModalState } from '../../../../products/model';
import eye from 'images/icons/company/eye.svg';
import { LoadingStateEnum } from '../../../../import/model';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { ListContainer, ListText } from '../../../style/LinkedElementStyle';
import { Open } from '../../../../../styles/global/css/Open';

function FormList(props: { linkedInstances: FormInstance[] }): JSX.Element {
	const [offset, setOffset] = React.useState<number>(0);
	const [widthOffset, setWidthOffset] = React.useState<number>(0);
	const users = useRecoilValue(AUsers);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	
	const [modalState, setModalState] = React.useState<ModalState<string>>({ isOpen: false });

	const DEFAULT_LIMIT = 3;
	const DEFAULT_WIDTH = 65;

	React.useEffect(() => {
		if (props.linkedInstances.length >= 3) {
			setWidthOffset((DEFAULT_WIDTH - 53) * props.linkedInstances.length);
		}
		else if (props.linkedInstances.length == 2) {
			setWidthOffset(DEFAULT_WIDTH - + 25);
		}
	}, []);



	const columns: Array<Column<FormInstance>> = React.useMemo(() => [
		{
			id: 'made_at',
			Header: translateToString('created_at'),
			accessor: row => <TableRow
				fontWeight="500"
				color={DarkGreySidely}
				cursor="pointer" onClick={() => setModalState({ isOpen: true, fullOpenMode: true, data: row.uuid })}>
				<FlexDiv fontWeight={row.progression < 100 ? '200' : '400'}>
					<DateAccessor date={row.made_at} />
					{row.progression < 100 ? translateToString('draft') : translateToString('completed')}
				</FlexDiv>
			</TableRow>,
			minWidth: 170,
			maxWidth: 1200,
			type: 'string',
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setModalState({ isOpen: true, fullOpenMode: false, data: row.uuid })}
			/>,
		},
		{
			id: 'created_by',
			Header: translateToString('created_by'),
			accessor: row => <TableRow cursor="pointer" onClick={() => setModalState({ isOpen: true, fullOpenMode: true, data: row.uuid })}>{users.find(a => a.id == row.created_by)?.name}</TableRow>,
			type: 'string',
			minWidth: 150,
			maxWidth: 1200,
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setModalState({ isOpen: true, fullOpenMode: false, data: row.uuid })}
			/>,
		},
		{
			id: 'form_name',
			Header: translateToString('name'),
			accessor: row => <TableRow cursor="pointer" onClick={() => setModalState({ isOpen: true, fullOpenMode: true, data: row.uuid })}>{row.name}</TableRow>,
			minWidth: 150,
			maxWidth: 1200,
			type: 'string',
			hoverImage: row => <PartialOpen
				src={eye}
				onClick={() => setModalState({ isOpen: true, fullOpenMode: false, data: row.uuid })}
			/>,
		},
	], []);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);

		const newWidthOffset = widthOffset + DEFAULT_WIDTH + 42;
		setWidthOffset(newWidthOffset);
	}

	return <>
		
		<ListContainer onClick={() => setIsOpen(!isOpen)}>
			<Open isOpen={isOpen} />
			<ListText><FlexDiv>{props.linkedInstances[0].name} ({props.linkedInstances.length})</FlexDiv></ListText>
		</ListContainer>
	
		{isOpen && <>
			{
				props.linkedInstances && props.linkedInstances.length > 0 &&
				<div>
					<Table
						height='474px)'
						columns={columns}
						data={props.linkedInstances.sort((a, b) => new Date(b.made_at).getTime() - new Date(a.made_at).getTime()).slice(0, DEFAULT_LIMIT + offset)}
					/>
				</div>
			}
			{
				props.linkedInstances && props.linkedInstances.length == 0 &&
				<NoDataText><Translate id='no_linked_forms' /></NoDataText>
			}
 
			{props.linkedInstances && (props.linkedInstances.length > (offset + DEFAULT_LIMIT)) &&
				<SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			<InstancePopup
				modalState={modalState}
				isOpen={modalState.isOpen}
				onClickOut={() => setModalState({ isOpen: false })}
			/>
		</>}
	</>;

}


function LinkedForms(props: {
	company_id: number
}): JSX.Element {

	const [linkedInstances, setLinkedInstances] = React.useState<{ [key: string]: FormInstance[] }>();

	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	

	React.useEffect(() => {
		getLinkedFormInstance(props.company_id).then(res => {
			setLinkedInstances(res);
			setLoadingState(LoadingStateEnum.LOADED);
		});
	}, []);



	if (loadingState == LoadingStateEnum.LOADING) {
		return <Loader width='30px' />;
	}
	return <>
		{linkedInstances && Object.keys(linkedInstances).map((key, index) => {
			return <>
				<FormList key={index} linkedInstances={linkedInstances[key]} />
			</>;
		})}
	</>;
}
  
export default LinkedForms;
  