import React, {Component} from 'react';
import {connect} from 'react-redux';
import {message, Upload} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {uploadFile} from '../../../store/actions/formbuilder.action';

const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

class UploadImage extends Component {
  state = {
    loading: false,
    fileList: []
  };

  // componentDidMount() {
  //   const { input } = this.props;
  //   const img = get(input, 'value.img');

  //   if (img) {
  //     this.setState({
  //       fileList: img
  //     });
  //   }
  //   input.onChange(input.value);
  // }

  customRequest = ({ onSuccess, onError, file }) => {
    const { uploadFile, input } = this.props;
    const formData = new FormData();
    this.setState({ loading: true });
    formData.append('type', 'camera_field');
    formData.append('file', file);

    uploadFile(formData).then(
      response => {
        const fileList = [
          {
            url: response.data.data.temp_url,
            status: 'done',
            name: response.data.data.url,
            id: response.data.data.id,
            uid: 0
          }
        ];

        this.setState({
          loading: false,
          fileList
        });

        input.onChange({ img: this.state.fileList });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { label, requiredStar, id } = this.props;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { fileList } = this.state;
    return (
      <div className="form-group">
        <div className="control-label">
          <label htmlFor={id}>
            {requiredStar === true ? (
              <span className="text-required">* </span>
            ) : (
              ''
            )}
            {label}
          </label>
        </div>
        <div className="form-upload-control">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            customRequest={this.customRequest}
            showUploadList={false}
            multiple={false}
            beforeUpload={beforeUpload}
          >
            {fileList.length ? (
              <img
                src={fileList[0].url}
                alt="images"
                style={{ width: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.formbuilder.file,
  isUploading: state.formbuilder.isUploading
});

export default connect(
  mapStateToProps,
  { uploadFile }
)(UploadImage);
