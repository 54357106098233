import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {renderField, required} from '../../FieldModal';
import SelectInCharge from '../../select/SelectInCharge';
import {createFromSelect} from '../../../store/actions/contacts.action';
import {normalizeCapitalize} from '../../../helpers/fieldMask';
import {Translate} from 'react-localize-redux';
import SelectClientStatus from '../../select/RenderSelectClientStatus';

class ModalCreateContact extends Component {
  state = {};

  onSubmit = value => {
    const { createFromSelect, id, onCreation } = this.props;
    const formData = new FormData();

    // console.log('id', id);

    const keys = [
      'ownerId',
      'lastName',
      'firstName',
      'position',
      'phone',
      'email',
      'clientStatusId'
    ];

    keys.map(key => {
      value[key] && formData.append(key, value[key].value || value[key]);
    });

    formData.append('clientCompanyId', id);

    onCreation && onCreation();
    createFromSelect(formData);
  };

  render() {
    const { handleSubmit, isLoading } = this.props;
    // console.log('isLoading', isLoading);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader toggle={this.props.toggle}>
          <Translate id="new contact" />
        </ModalHeader>
        <ModalBody>
          <form
            className="forms-sample background-form"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Translate>
              {({ translate }) => (
                <div className="row">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-12">
                        <SelectInCharge
                          name="ownerId"
                          label={<Translate id="owner" />}
                          requiredStar
                          validate={[required]}
                          placeholder={translate('pick_owner')}
                        />
                        <Field
                          name="firstName"
                          requiredStar
                          type="text"
                          component={renderField}
                          label={translate('firstname')}
                          placeholder={translate('enter_first_name')}
                          id="inputfirstName"
                          validate={[required]}
                          normalize={normalizeCapitalize}
                        />
                        <Field
                          name="lastName"
                          requiredStar
                          type="text"
                          component={renderField}
                          label={translate('lastname')}
                          placeholder={translate('enter_last_name')}
                          id="inputfirstName"
                          validate={[required]}
                          normalize={normalizeCapitalize}
                        />
                        <SelectClientStatus
                            name="clientStatusId"
                            label={translate('status')}
                            placeholder={translate('Choose Status')}
                            disabled={this.props.id}
                            requiredStar
                            validate={[required]}
                          />
                        <Field
                          name="position"
                          type="text"
                          component={renderField}
                          label={translate('position')}
                          placeholder={translate('enter_position')}
                          id="inputfirstName"
                          normalize={normalizeCapitalize}
                        />
                        <Field
                          name="phone"
                          type="text"
                          component={renderField}
                          label={<Translate id="phone" />}
                          placeholder={translate('enter_phone')}
                          id="inputPhone"
                        />
                        <Field
                          name="email"
                          type="email"
                          component={renderField}
                          label="Email"
                          id="inputemail"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4" />
                </div>
              )}
            </Translate>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit(this.onSubmit)}
            loading={isLoading}
            spinColor="#003d79"
          >
            <span className="text-white">
              {!isLoading && <Translate id="save" />}
            </span>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.contacts.isLoading
});

export default reduxForm({
  form: 'modalContactForm'
})(
  connect(
    mapStateToProps,
    { createFromSelect }
  )(ModalCreateContact)
);
