import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';
import { Checkout } from './Checkouts';
import { FilterId, FilterTree } from '../../components_v2/filter/model/Model';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import { ServerAdvancedFilter } from '../client-companies/model/Model';
import { translateToString } from '../../styles/global/translate';

export type DefaultTableQuery = {
	offset?: number,
	limit?: number,
	order_by?: string,
	descending?: boolean,
	filters?: FilterTree
}

export async function getCheckouts(body: DefaultTableQuery): Promise<Checkout[]> {
	return axios.post(`${URL_FOUNDATION}/api/v2/checkouts/query`, body).then(res => res.data);
} 
const filterCategories = ['most_used_filters', 'pricing', 'activity'] as const;
type FilterCategory = typeof filterCategories[number];
const getCheckoutFitlersCategories = (filterId: FilterId): FilterCategory[] => {
	console.log(filterId);
	switch (filterId) {
		case 'unit':
		case 'product_unit_number':
		case 'consumer_sales_unit':
		case 'volumetric_quantities':
		case 'sum_with_tax':
		case 'sum_without_tax':
		case 'cumulative_consumer_sales_unit':
		case 'cumulative_volumetric_quantities':
		case 'cumulative_sum_with_tax':
		case 'cumulative_sum_without_tax':
		case 'average_price':
		case 'average_price_evolution':
			return ['pricing'];
		case 'date':
		case 'client_company_name':
		case 'product_name':
			return ['most_used_filters', 'pricing'];
		case 'created_at':
		case 'created_by':
		case 'updated_at':
		case 'updated_by':
			return ['activity'];
	}
	return [];
};

const toTranslateString = (filterId: FilterId): string => {
	switch (filterId) {
		case 'owner_id': return 'owner';
		case 'tag_id': return 'tags';
	}
	if (typeof filterId === 'string')
		return filterId;
	return '';
};

export async function getCheckoutFilters(): Promise<FilterParameter[]> {
	try {
		const result = await axios.get<ServerAdvancedFilter[]>(`${URL_FOUNDATION}/api/v2/checkouts/filters`)
			.then(response => filterCategories.map((fc): FilterParameter => ({
				category: translateToString(fc),
				filters: response.data
					.filter(companyFilter => getCheckoutFitlersCategories(companyFilter[0]).includes(fc))
					.map(companyFilter => ({ id: companyFilter[0], name: translateToString(toTranslateString(companyFilter[0])), type: companyFilter[1] }))
			})));
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}