import { atom, RecoilState } from 'recoil';
import { AtomCategory } from '../utils/model/Model';

const Atoggle: RecoilState<AtomCategory> = atom({
	key: 'atom_toggle', // UNIQUE ID
	default: AtomCategory.GLOBAL
});

export {
	Atoggle
};
