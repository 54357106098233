export const customStyles = {
	option: (provided, state) => ({
		...provided,
		padding: 10,
		fontSize: 12,
		backgroundColor: state.isFocused ? '#00b2ff' : 'white',
		color: state.isFocused ? 'white' : 'inherit'
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: '#495057'
	}),
	menu: (provided, state) => ({
		...provided,
		textAlign: 'left',
		width: '150px',
		padding: '0px',
		margin: '0.125rem 0 0',
		boxShadow: 'none'

	}),
	menuList: (provided, state) => ({
		...provided,
		padding: '0px',
		borderRadius: '5px',
		border: '1px solid #ebeff2'
	}),
	container: (provided, state) => ({
		...provided,
		fontSize: 12
	}),
	control: (provided, state, base) => ({
		...provided,
		borderColor: '#f2f2f2',
		borderRadius: '5px',
		// height: '32px',
		width: '150px',
		boxShadow: state.isFocused ? '0 0 0 1px #00b2ff' : '',
		'&:hover': {
			borderColor: '#00b2ff'
		},
		'min-height': '38px'
		// ...base,
		// height: 30,
		// minHeight: 30
	}),

	indicatorSeparator: (provided, state) => ({
		...provided,
		backgroundColor: '#f2f2f2',
		display: 'none'
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: '5px'
	}),
	placeholder: defaultStyles => ({
		...defaultStyles,
		color: '#cdcdcd'
	})
};

export const selectLimitStyles = {
	option: (provided, state) => ({
		...provided,
		padding: 10,
		fontSize: 12,
		backgroundColor: state.isFocused ? '#7F3F98' : '#fff'
	}),
	container: (provided, state) => ({
		...provided,
		fontSize: 12
	}),
	control: (provided, state) => ({
		...provided,
		borderColor: '#f2f2f2',
		borderRadius: '5px',
		// height: '32px',
		'min-height': '32px'
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		backgroundColor: '#f2f2f2'
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: '5px'
	})
};

export const dropdownFilterStyles = {
	option: (provided, state) => ({
		...provided,
		padding: 10,
		backgroundColor: state.isFocused ? '#707683' : '#fff',
		fontSize: 12
	}),
	container: (provided, state) => ({
		...provided,
		fontSize: 12
	}),
	control: (provided, state) => ({
		...provided,
		borderColor: '#FFFFFF',
		borderRadius: '5px',
		border: '0.1px solid rgba(37, 65, 83, 0.3)',
		// height: '32px',
		'min-height': '32px'
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		backgroundColor: '#f2f2f2',
		display: 'none'
	}),
	menu: (provided, state) => ({
		...provided,
		marginTop: '-2px',
		border: '5px',
		borderTop: 'none',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: '5px'
	})
};
