import add_blue from 'images/icon/add_blue.png';
import system_setting_icon from 'images/setting_icons/system_setting_icon.svg';
import noImage from 'images/no-image.jpg';
import eye from 'images/icons/company/eye.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import swal from 'sweetalert2';
import { AInputSearch } from '../../atoms/filter/InputSearchFilter';
import { AtomCategory } from '../../atoms/utils/model/Model';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { Checkbox } from '../../components_v2/filterList/style/Style';
import PageLoader from '../../components_v2/pageLoader/PageLoader';
import Pagination from '../../components_v2/pagination/Pagination';
import { Table, TableSortType } from '../../components_v2/table/Table';
import ToolbarFilter, { ToolbarElement } from '../../components_v2/toolbarFilter/ToolbarFilter';
import { getUrlParameterByName, removeAllUrlParameters } from '../../components_v2/utils';
import storeLang from '../../helpers/storeLang';
import { ToolbarImage } from '../globals/defaultToolbar/style/Style';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { LoadingState } from '../import/model';
import Restricted from '../permissions/Restricted';
import { getAssortments, updateAssortment, updateAssortments } from './action';
import AssortmentDetail from './assortment';
import { Assortment, ModalState } from './model';
import { Container, Link, LoaderContainer, TABLE_IMAGE_PADDING, TABLE_IMAGE_WIDTH } from './style';
import { useHistory } from 'react-router';
import AssortmentCreation from './assortmentCreation';
import { TableRow } from '../orders/templateOrders/style/Style';
import { PartialOpen } from '../client-companies/style/Style';
import { DarkGreySidely, LightBlueSidely, RedSidely } from '../../styles/global/css/Utils';
import PermissionContext from '../permissions/PermissionContext';
import { IsAllowedToProps } from '../permissions/PermissionProvider';
import { To } from '../../Types';
import optionGrey from 'images/icon/options_grey.png';
import NoData from '../noData/NoData';
import { translateToString } from '../../styles/global/translate';
import { exportData } from '../client-companies/data/action';
import { TypeFile } from '../orders/model/Model';
import { useDispatch } from 'react-redux';

const DEFAULT_LIMIT = 50;

function assortmentcolumns(
	assortments: Assortment[],
	setAssortments: (assortments: Assortment[]) => void,
	refresh: () => void,
	openAssortment: (assortment: Assortment, full: boolean) => void,
	openDuplicate: (assortment: Assortment) => void,
	isAllowedTo: (to: To | To[], opt?: IsAllowedToProps | undefined) => boolean
) {
	const translate = getTranslate(storeLang.getState().localize);
	const DUPLICATE = 0; const DELETE = 1;
	const all_checked = assortments.every(row => row.checked);
	const list: DropdownData<number>[] = [];
	if (isAllowedTo({ objectAction: 'DeleteCatalog' })) list.push({
		label: translate('global.quick_action.delete').toString(),
		value: DELETE
	});
	if (isAllowedTo({ objectAction: 'CreateCatalog' })) list.push({
		label: translate('global.quick_action.duplicate').toString(),
		value: DUPLICATE
	});
	return [
		{
			id: 'select',
			Header: <Checkbox isActive={all_checked} onClick={() => {
				if (all_checked) {
					setAssortments(assortments.map(p => ({ ...p, checked: false })));
				} else {
					setAssortments(assortments.map(p => ({ ...p, checked: true })));
				}
			}} />,
			accessor: (row: Assortment) => {
				return (
					<Checkbox isActive={row.checked ?? false} onClick={() => {
						const index = assortments.findIndex(p => p.id == row.id);
						if (index >= 0) {
							assortments[index].checked = !(row.checked ?? false);
							setAssortments([...assortments]);
						}
					}} />
				);
			},
			width: 40,
			disableSortBy: true,
			unresizeable: true
		},
		{
			id: 'photo',
			Header: ' ',
			accessor: (row: Assortment) => <Link centered onClick={() => openAssortment(row, true)} background={row.url ?? noImage} />,
			disableSortBy: true,
			width: TABLE_IMAGE_WIDTH,
			padding: TABLE_IMAGE_PADDING.DEFAULT,
			unresizeable: true,
		},
		{
			id: 'lower(C.name)',
			Header: <Translate id='name' />,
			accessor: (row: Assortment) => <>
				<TableRow onClick={() => openAssortment(row, true)} fontWeight="500" color={DarkGreySidely} cursor="pointer">
					{row.name ?? '-'}
				</TableRow>
				<PartialOpen src={eye} onClick={() => openAssortment(row, false)} />
			</>,
			width: undefined
		},
		{
			id: 'coalesce(count.product_count, 0)',
			Header: <Translate id='shelf_audit.column.product_count' />,
			accessor: (row: Assortment) => <TableRow>{row.product_count ?? 0}</TableRow>,
			width: undefined
		},
		{
			id: 'C."createdAt"',
			Header: <Translate id='createat' />,
			accessor: (row: Assortment) => <TableRow>{new Date(Date.parse(row.created_at)).toLocaleDateString()}</TableRow>,
			width: undefined
		},
		{
			id: 'actions',
			Header: <img src={system_setting_icon} style={{ width: 18 }} />,
			accessor: (row: Assortment) => <Restricted to={[{ objectAction: 'DeleteCatalog' }, { objectAction: 'CreateCatalog' }]}>
				<Dropdown
					name={'quick_action'}
					datalist={list}
					readOnly
					dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-70px' }}
					JSXButton={() => <img
						src={optionGrey}
						width={25}
						height={25}
						style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }} />}
					onChange={(value: DropdownData) => {
						switch (value.value) {
							case DELETE:
								// @ts-expect-error swal
								swal.fire({
									title: 'Attention!',
									text: translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${row.name}'`),
									type: 'warning',
									confirmButtonText: translate('global.yes'),
									confirmButtonColor: RedSidely
								})
									.then(result => {
										if (result.value) {
											updateAssortment(row.id, { deleted: true })
												.then(refresh)
												.catch(console.log);
										}
									});
								break;
							case DUPLICATE:
								openDuplicate(row);
								break;
						}
					} } />
			</Restricted>,
			width: 40,
			unresizeable: true,
			disableSortBy: true
		}
	];
}

export default function AssortmentListing(props: {
  setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [assortments, setAssortments] = React.useState<Assortment[]>([]);
	const [loadingState, setLoadingState] = React.useState<LoadingState>('loading');
	const [pagination, setPagination] = React.useState({ step: DEFAULT_LIMIT, offset: 0 });
	const [max, setMax] = React.useState<number>(0);
	const [sort, setSort] = React.useState<TableSortType | undefined>(undefined);
	const [modalState, setModalState] = React.useState<ModalState>({ isOpen: false, fullOpenMode: false });
	const [isCreationOpen, setIsCreationOpen] = React.useState<[boolean, Assortment | undefined]>([false, undefined]);
	const inputSearchText = useRecoilValue(AInputSearch);
	const history = useHistory();
	const { isAllowedTo } = React.useContext(PermissionContext);
	const dispatch = useDispatch();

	function checkUrlParams() {
		const assortmentId = getUrlParameterByName('id');
		const mode = getUrlParameterByName('mode');
		if (assortmentId) {
			switch (mode) {
				case 'detail':
					setModalState({ isOpen: true, fullOpenMode: false, data: { assortmentId } });
					break;
				case 'default':
				default:
					setModalState({ isOpen: true, fullOpenMode: true, data: { assortmentId } });
					break;
			}
		}
	}

	React.useEffect(() => {
		checkUrlParams();
		history.listen(checkUrlParams);
	}, [history]);

	React.useEffect(() => {
		const data: DropdownData[] = [];
		data.push({ label: <Translate id='Delete selection' />, value: 'delete' });
		data.push({ label: 'export', value: 'export' });
		const translate = getTranslate(storeLang.getState().localize);

		props.setToolBarState({
			title: translate('product.assortments') as string,
			bottomLeftToolbarComponent: <></>,
			bottomRightToolbarComponent: <>
				{loadingState == 'loading' && <LoaderContainer><PageLoader size={20} /></LoaderContainer>}
				<ToolbarFilter
					category={AtomCategory.ASSORTMENTS}
					elements={[
						ToolbarElement.INPUT_SEARCH
					]}
				/>
				<Restricted to={{ objectAction: 'CreateCatalog' }}>
					<ToolbarImage
						marginLeft='10px'
						width='25px'
						height='25px'
						hasPointer src={add_blue}
						backgroundColor={LightBlueSidely}
						round
						onClick={() => setIsCreationOpen([true, undefined])}
					/>
				</Restricted>
				<Restricted to={{ objectAction: 'DeleteCatalog' }}>
					<Dropdown dropdownStyle={{ optionLeft: '-115px', optionWidth: '160px' }} datalist={data} name={'options'}
						JSXButton={() => <ToolbarImage marginLeft='10px' hasPointer src={optionGrey} />}
						onChange={(value: DropdownData) => {
							switch (value.value) {
								case 'delete':
									if (assortments.filter(a => a.checked).length > 0) {
									// @ts-expect-error swal
										swal.fire({
											text: translate('Are you sure you want to delete selected data from the database?') as string,
											type: 'warning',
											confirmButtonColor: RedSidely
										})
											.then(result => {
												if (result.value) {
													updateAssortments(assortments.filter(a => a.checked).map(a => ({ id: a.id, deleted: true })))
														.then(() => setPagination({ ...pagination }))
														.catch(console.log);
												}
											});
									} else {
									// @ts-expect-error swal
										swal.fire({
											title: 'Attention!',
											text: translate('No data selected !') as string,
											type: 'warning',
											showConfirmButton: false
										});
									}
									break;
								case 'export':
									dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
									exportData(TypeFile.Excel, 'Assortments');
									break;
							}
						}}
					/>
				</Restricted>
			</>
		});
	}, [assortments, loadingState]);

	React.useEffect(() => {
		setLoadingState('loading');
		getAssortments(pagination.step, pagination.offset, inputSearchText, sort)
			.then(res => {
				setMax(res.data.count);
				setAssortments(res.data.assortments ?? []);
				setLoadingState('loaded');
			})
			.catch(e => {
				console.log(e);
				setLoadingState('error');
			});
	}, [pagination, inputSearchText, sort]);

	const columns = React.useMemo(() => assortmentcolumns(assortments, setAssortments, () => setPagination({ ...pagination }), (assortment, fullOpenMode) => {
		history.push({
			search: `?id=${assortment.id}&mode=${fullOpenMode ? 'default' : 'detail'}`
		});
	}, (assormtent) => setIsCreationOpen([true, assormtent]), isAllowedTo), [assortments]);

	return <Container>
		<Table height='calc(100vh - 170px)'
			columns={columns}
			data={assortments}
			onSort={sort => setSort(sort[0])}
			initialSortBy={sort}
		/>
		{loadingState === 'loaded' && !assortments.length && <div style={{ position: 'absolute', width: '100%' }}><NoData message={translateToString('no_assortments')} /></div>}
		<Pagination label={'assortments'} amount={max} steps={[DEFAULT_LIMIT, 100, 200, 500]} onChange={(value) => {
			setPagination({ ...pagination, ...value });
		}} />
		<AssortmentDetail
			isOpen={modalState.isOpen}
			setIsOpen={isOpen => {
				if (!isOpen) { removeAllUrlParameters(); }
				setModalState({ ...modalState, isOpen });
			}}
			assortment={modalState.data?.assortment}
			assortmentId={modalState.data?.assortmentId}
			fullOpenMode={modalState.fullOpenMode}
			refresh={() => setPagination({ ...pagination })}
		/>
		<AssortmentCreation
			refresh={() => setPagination({ ...pagination })}
			isOpen={isCreationOpen[0]}
			setIsOpen={(b: boolean) => setIsCreationOpen([b, undefined])}
			assortment={isCreationOpen[1]}
		/>
	</Container>;
}
