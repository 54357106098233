import React, {Component, Fragment} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Translate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {createData, updateData} from '../../../../store/actions/enForm.action';
import {getShelfAuditFieldTemplate} from '../../Action';
import HeaderTemplate from '../../components/componentForm/HeaderTemplate';
import * as variables from '../fieldComponents/builderLib/services/variables';
import Preview from './FieldProperties';

class ReactFormBuilder extends Component {
  constructor(props) {
    super(props);
    const detail = JSON.parse(localStorage.getItem('enFormTemplate'));
    
    this.state = {
      template: detail,
      elements: detail.elements || [],
      editMode: false,
      editElement: null,
    };

    getShelfAuditFieldTemplate(detail.id).then(response => {
      this.setState({ fieldTemplate: response });
    });
  }

  editModeOn = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.editMode) {
      this.setState({ editMode: !this.state.editMode, editElement: null });
    } else {
      this.setState({ editMode: !this.state.editMode, editElement: data });
    }
  };
  manualEditModeOff = () => {
    if (this.state.editMode) {
      this.setState({
        editMode: false,
        editElement: null
      });
    }
  };

  onPreview = () => {
    this.child.showPreview();
  }

  setElements = value => {
    const { elements } = this.state;
    if (value) {
      const filterElement = elements.filter(v => v.shelf_audit_field_id !== value.shelf_audit_field_id);
      filterElement.push(value)
      this.setState({ elements: filterElement })
    }
  }

  sortingElement = elements => {
    this.setState({ elements })
  }

  onSubmit = step => {
    const { createData, history, updateData } = this.props;
    const data = JSON.parse(localStorage.getItem('enFormTemplate'));
    const elements = this.state.elements || [];
    const template = elements.map((e, i) => ({
      shelf_audit_field_id: e.shelf_audit_field_id,
      mandatory: e.mandatory,
      ordering: i + 4,
      values: e.values
    }));
    const submit = {}
    const initField = ["name", "icon_id", "catalogue_id", 'shelf_audit_field_templates'];
    initField.map(v =>
      submit[v] = data[v] || null
    )
    submit['shelf_audit_field_templates'] = [...submit.shelf_audit_field_templates, ...template];
    if (data.modalType !== 'edit') {
      createData(submit, 'shelfAuditTemplate', history);
    } else {
      updateData(submit, data.id)
    }
  };
  render() {
    return (
      <Fragment>
        {/* <HeaderForm {...this.props} disabled={false} onSubmit={this.onSubmit} /> */}
        <HeaderTemplate
          {...this.props}
          onSubmit={this.onSubmit}
          elements={this.state.elements}
          variables={variables}
          onRef={ref => (this.child = ref)}
          onPreview={this.onPreview}
        />
        <div className="row mt-8em mb-5">
          <div className="p-5 ml-4 detail-top my-0" style={{ width: '95%' }}>
            <h5>
              <Translate id="step" />
              {' '}4 -{' '}
              <Translate id="Modify the field order and properties" />
            </h5>
            <p>
              <Translate id="Pick the order and the properties of the different fields that you have chosen to monitor" />
            </p>
            <DndProvider backend={HTML5Backend}>
              <div className="react-form-builder clearfix">
                <div>
                  <Preview
                    files={this.props.files}
                    manualEditModeOff={this.manualEditModeOff.bind(this)}
                    showCorrectColumn={this.props.showCorrectColumn}
                    parent={this}
                    data={this.props.data}
                    template={this.state.template}
                    fieldTemplate={this.state.fieldTemplate}
                    url={this.props.url}
                    saveUrl={this.props.saveUrl}
                    onLoad={this.props.onLoad}
                    onPost={this.props.onPost}
                    editModeOn={this.editModeOn}
                    editMode={this.state.editMode}
                    variables={this.props.variables}
                    editElement={this.state.editElement}
                    setElements={this.setElements}
                    valElements={this.state.elements}
                    sortingElement={this.sortingElement}
                  />
                </div>
              </div>
            </DndProvider>
          </div>
        </div>
      </Fragment>
    );
  }
}

// const FormBuilders = {};
// FormBuilders.ReactFormBuilder = ReactFormBuilder;
// FormBuilders.ReactFormGenerator = ReactFormGenerator;
// FormBuilders.ElementStore = store;
// export { ReactFormBuilder, store as ElementStore };
const mapStateToProps = state => ({
  isLoading: state.enform.isLoading
});

export default connect(mapStateToProps, { createData, updateData })(ReactFormBuilder);

