const convertIcon = data => {
	switch (data.field_type) {
		case 'section':
			if (data.is_start_section) {
				return 'fas fa-heading';
			}
			if (data.is_end_section) {
				return 'fas fa-heading';
			}
			return '';

		case 'header':
			return 'demo-icon icon-b';

		case 'table':
			return 'fas fa-heading';

		case 'image':
			return 'icon-image';

		case 'free_text':
			return 'demo-icon icon-t';

		case 'numerical':
			return 'demo-icon icon-1';

		case 'dropdown':
			return 'list.svg';

		case 'checkbox':
			return 'checkbox.svg';

		case 'email':
			return '';

		case 'address_field':
			return '';

		case 'document':
			return '';

		case 'user':
			return 'user.svg';

		case 'hyperlink':
			return 'attach.svg';

		case 'calculation':
			return '';

		case 'camera':
			return 'camera.svg';

		case 'date':
			return 'calendar.svg';

		default:
			return '';
	}
};

export default convertIcon;
