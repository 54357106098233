/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from 'react';
import swal from 'sweetalert2';
import { getTranslate, Translate } from 'react-localize-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import storeLang from '../helpers/storeLang';
import Restricted from '../containers_v2/permissions/Restricted';
import optionGrey from 'images/icon/options_grey.png';

class DropdownAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle(e) {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	cekTranslate = name => {
		const translate = getTranslate(storeLang.getState().localize);
		return {
			text: translate('global.fire.delete_message_related_element').toString().replace('{{NAME}}', `'${name}'`),
			confirmButtonText: translate('global.yes')
		};
	};

	onDelete = (id, name) => {
		const { deleteData, path } = this.props;
		swal
			.fire({
				...this.cekTranslate(name),
				title: 'Attention!',
				type: 'warning',
				confirmButtonColor: '#f7685b'
			})
			.then(result => {
				if (result.value) {
					deleteData(id, true, path);
				}
			});
	};

	render() {
		const { id, name, path, permission, noDuplicate } = this.props;
		return (
			<Restricted to={permission ? [
				{ objectAction: `Update${permission}` },
				!noDuplicate ? { objectAction: `Create${permission}` } : { objectAction: `Delete${permission}` },
				{ objectAction: `Delete${permission}` },
			]
				: []}>
				<UncontrolledButtonDropdown
					direction="down"
					className="border-transparent"
				>
					<DropdownToggle className="p-0 btn-white">
						<img
							src={optionGrey}
							className="custom-icon"
							alt=""
						/>
					</DropdownToggle>
					<DropdownMenu className="custom-position">
						<Restricted to={permission ? { objectAction: `Update${permission}` } : []}>
							<DropdownItem
								onClick={() => {
									window.location.href = `/${path}/edit/${id}`;
								}}
							>
								<Translate id="edit" />
							</DropdownItem>
						</Restricted>

						<Restricted to={permission ? { objectAction: `Create${permission}` } : []}>
							{!noDuplicate && (
								<DropdownItem
									onClick={() => {
										window.location.href = `/${path}/duplicate/${id}`;
									}}
								>
									<Translate id="duplicate" />
								</DropdownItem>
							)}
						</Restricted>

						<Restricted to={permission ? { objectAction: `Delete${permission}` } : []}>
							<DropdownItem onClick={() => this.onDelete(id, name)}>
								<Translate id="delete" />
							</DropdownItem>
						</Restricted>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</Restricted>
		);
	}
}

export default DropdownAction;
