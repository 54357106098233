import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners from '../../../components_v2/dropdown/DropdownOwners';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { InputContainer, OptionBlock } from '../../../components_v2/dropdown/style/Style';
import Input from '../../../components_v2/input/Input';
import InputImage from '../../../components_v2/input/InputImage';
import { ImageResult } from '../../../components_v2/input/model/Model';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import { getTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import storeLang from '../../../helpers/storeLang';
import { ITag } from '../../../../typings/proto/protobufs';
import { Dot } from '../../../styles/global/css/Dot';
import { DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import { Company, CompanyContact, CompanyStatus } from '../../client-companies/model/Model';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../../client-companies/style/Style';
import { normalizeCapital } from '../../globals/FieldFunction';
import { getClientStatuses } from '../../import/actions';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany, Owner } from '../../orders/model/Model';
import { FlexDiv } from '../../products/style';
import { postContact } from '../data/Data';
import { PostContactParams } from '../model/Model';
import { Spacer } from '../style/NewContactStyle';
import { Label } from '../../client-companies/style/NewCompanyStyle';
import DropdownTagsCloud from '../../../components_v2/dropdown/DropdownTagsCloud';

function NewContact(props: {
  onClickOut?: () => void
  onCreate?: (value: CompanyContact) => void
  clientCompanyId?: number
  statusId?: number
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [image, setImage] = React.useState<ImageResult>();

	// const [owners, setOwners] = React.useState<Owner[]>([]);
	const [owner, setOwner] = React.useState<Owner>();

	const [firstName, setFirstName] = React.useState<string>('');
	const [lastName, setLastName] = React.useState<string>('');

	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);
	const [company, setCompany] = React.useState<Company>();

	const [statuses, setStatuses] = React.useState<CompanyStatus[]>([]);
	const [status, setStatus] = React.useState<CompanyStatus>();

	const [position, setPosition] = React.useState<string>('');
	const [phone, setPhone] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');

	const [tags, setTags] = React.useState<ITag[]>([]);
	const [selectedTags, setSelectedTags] = React.useState<ITag[]>([]);
	const owners = useRecoilValue(AUsers);

	React.useEffect(() => {
		let count = 2;
		setOwner(owners.find(o => o.isYou));

		getTags(TagType.CONTACT).then(response => {
			setTags(response);
			count = count - 1;
			if (count <= 0) {
				setLoading(LoadingStateEnum.LOADED);
			}
		});

		getClientStatuses().then(response => {
			setStatuses(response.data);
			if (props.statusId) {
				setStatus(response.data.find((d: CompanyStatus) => d.id === props.statusId));
			} else {
				setStatus(response.data.length > 0 ? response.data[0] : undefined);
			}

			count = count - 1;
			if (count <= 0) {
				setLoading(LoadingStateEnum.LOADED);
			}
		});
	}, []);

	function submit(): void {
		if (owner !== undefined && firstName.trim().length > 0 && lastName.trim().length > 0 && status !== undefined && (company !== undefined || props.clientCompanyId !== undefined)) {
			const params = {
				owner_id: owner.id,
				client_company_id: props.clientCompanyId ? props.clientCompanyId : company?.company_id,
				client_status_id: status.id ?? props.statusId,
				first_name: firstName.trim(),
				last_name: lastName.trim(),
				logo: image && {
					name: image.file.name,
					file: image.content
				},
				email: email.trim().length > 0 ? email.trim() : undefined,
				phone: phone.trim().length > 0 ? phone.replaceAll(' ', '') : undefined,
				position: position.trim().length > 0 ? position.trim() : undefined,
				tags: selectedTags.length > 0 ? selectedTags.map(t => t.id) : undefined
			} as PostContactParams;

			setLoading(LoadingStateEnum.LOADING);
			postContact(params).then(response => {
				if (response !== -1) {
					const newContact = {
						client_company_id: props.clientCompanyId ? props.clientCompanyId : company?.company_id,
						company_id: -1, // TODO récup l'info quelque part
						contact_count: 1,
						contact_email: email.trim(),
						contact_first_name: firstName.trim(),
						contact_last_name: lastName.trim(),
						contact_id: response,
						contact_phone: phone.trim(),
						contact_photo: image?.url,
						contact_position: position.trim(),
						contact_status_color: status.color_code,
						contact_status_id: status.id,
						contact_status_name: status.name
					} as CompanyContact;

					(props.onCreate != null) && props.onCreate(newContact);
					setLoading(LoadingStateEnum.LOADED);
				}
			});
		}
	}

	function body(): JSX.Element {
		return (
			<CreationPopupContainer>

				<InputImage onChange={(value) => setImage(value)} />

				<Spacer />

				<DropdownOwners
					dropdownStyle={creationPopupDropdownStyle}
					users={owners.map(o => { return { label: o.name, value: o, image: o.photoUrl }; })}
					selected={(owner != null) ? { label: owner.name, value: owner, image: owner.photoUrl } : undefined}
					border
					required
					label={translate('company.creation.contact.owner').toString()}
					onChange={(value: DropdownData) => setOwner(value.value)}
				/>

				<Spacer />

				<Input
					inputStyle={creationPopupInputStyle}
					name="first_name"
					type="text"
					placeholder={translate('company.creation.contact.first_name').toString()}
					label={translate('company.creation.contact.first_name').toString()}
					normalize={[normalizeCapital]}
					onChange={(value) => setFirstName(value)}
					required
				/>

				<Spacer />

				<Input
					inputStyle={creationPopupInputStyle}
					name="last_name"
					type="text"
					placeholder={translate('company.creation.contact.last_name').toString()}
					label={translate('company.creation.contact.last_name').toString()}
					normalize={[normalizeCapital]}
					onChange={(value) => setLastName(value)}
					required
				/>

				{
					!props.clientCompanyId &&
					<>
						<Spacer />

						<DropdownSearch
							datalist={optionCompanies}
							name="clientCompanyName"
							label={translate('company.creation.contact.company').toString()}
							optionHeight={200}
							dropdownStyle={{ width: '100%', height: '50px', containerWidth: '100%' }}
							labelUp
							required
							link='/companies?id='
							onChange={(value) => setCompany(value?.value)}
							selectedValue={optionCompanies.find(comp => JSON.stringify(comp.value) == JSON.stringify(optionCompanies)) ?? null}
							onSearchChange={async(search, offset) => {
								return await getFilteredCompanies(search, 20, offset)
									.then(res => {
										const ret: boolean = res.data.length == 0 || res.data.length < 20;
										if (offset !== undefined) {
											setOptionCompanies([...optionCompanies, ...res.data.map((company: ClientCompany) => {
												return {
													label: company.name,
													value: company
												};
											})]);
										} else {
											setOptionCompanies(res.data.map(company => {
												return {
													label: company.name,
													value: company
												};
											}));
										}
										return ret;
									})
									.catch(error => {
										console.log(error);
										return false;
									});
							}}
						/>
					</>
				}

				{
					!props.statusId &&
					<>
						<Spacer />
						<Dropdown
							dropdownStyle={creationPopupDropdownStyle}
							datalist={statuses.map((s, i) => ({ label: s.name, value: s, color: s.color_code, selected: i === 0 }))}
							name='dropdown_status'
							required
							readOnly
							label={translate('company.creation.contact.status').toString()}
							onChange={(value: DropdownData) => setStatus(value.value)}
							JSXButton={({ width, height }) => <InputContainer width={width} height={height} padding='0 10px'>
								<FlexDiv gap='10px' justify='center'>
									<Dot size='10px' color={status?.color_code} />
									<DefaultTextDiv>{status?.name}</DefaultTextDiv>
								</FlexDiv>
							</InputContainer>}
							JSXContent={(value: DropdownData) => <OptionBlock>
								<FlexDiv gap='10px' justify='center'>
									<Dot size='10px' color={value.color} />
									<DefaultTextDiv>{value.label}</DefaultTextDiv>
								</FlexDiv>
							</OptionBlock>}
						/>
					</>
				}

				<Spacer />

				<Input
					inputStyle={creationPopupInputStyle}
					name="position"
					type="text"
					placeholder={translate('company.creation.contact.position').toString()}
					label={translate('company.creation.contact.position').toString()}
					normalize={[normalizeCapital]}
					onChange={(value) => setPosition(value)}
				/>

				<Spacer />

				<Input
					inputStyle={creationPopupInputStyle}
					name="phone"
					type="tel"
					placeholder={translate('company.creation.contact.phone').toString()}
					label={translate('company.creation.contact.phone').toString()}
					onChange={(value) => setPhone(value)}
				/>

				<Spacer />

				<Input
					inputStyle={creationPopupInputStyle}
					name="email"
					type="email"
					placeholder={translate('company.creation.contact.email').toString()}
					label={translate('company.creation.contact.email').toString()}
					onChange={(value) => setEmail(value)}
				/>

				<Spacer />


				<Label>Tags</Label>
				<DropdownTagsCloud
					allwaysOpen
					addImage
					emptyWhenNone
					tags={tags}
					selected={selectedTags}
					autoOptionUp
					onChange={(values) => setSelectedTags(values) }
					onDelete={(id: number) => setSelectedTags(selectedTags.filter(t => t.id !== id)) }
				/>
			</CreationPopupContainer>
		);
	}

	return (
		<PopupCreation
			title={translate('company.creation.contact.create_contact').toString()}
			content={body()}
			canValidate={owner !== undefined &&
				firstName.trim().length > 0 &&
				lastName.trim().length > 0 &&
				status !== undefined &&
				(company !== undefined || props.clientCompanyId !== undefined)}
			onSubmit={() => submit()}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
		/>
	);
}

export default NewContact;
