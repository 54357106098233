const convertType = data => {
	//   console.log('data.field', data.field_type);
	switch (data.field_type) {
		case 'section':
			if (data.is_start_section) {
				return 'StartSection';
			}
			if (data.is_end_section) {
				return 'EndSection';
			}
			return '';

		case 'table':
			return 'StartTable';

		case 'header':
			return 'Header';

		case 'image':
			return 'Image';

		case 'free_text':
			return 'FreeText';

		case 'numerical':
			return 'Numerical';

		case 'dropdown':
			return 'DropdownList';

		case 'checkbox':
			return 'Checkbox';

		case 'email':
			return 'Email';

		case 'address_field':
			return 'AddressField';

		case 'document':
			return 'Document';

		case 'user':
			return 'UserField';

		case 'hyperlink':
			return 'Hyperlink';

		case 'calculation':
			return 'Calculation';

		case 'camera':
			return 'Camera';

		case 'date':
			return 'DatePicker';

		default:
			return '';
	}
};

export default convertType;
