import * as React from 'react';
import { LoadingStateEnum } from '../../../containers_v2/import/model';

interface LoaderObject {
  id: string
  status: LoadingStateEnum
}

interface LoaderContextType {
  loaderNotify: (id: string, status: LoadingStateEnum) => void
  loaderRegister: (id: string) => void
  getLoaderStatus: (id: string) => LoadingStateEnum
  loaderManager: LoaderObject[]
  setLoaderManager: (loaders: LoaderObject[]) => void
}

const defaultBehaviour: LoaderContextType = {
	loaderNotify: () => { return; },
	loaderRegister: () => { return; },
	getLoaderStatus: () => { return LoadingStateEnum.LOADED; },
	loaderManager: [],
	setLoaderManager: function(): void { return; }
};

const LoaderContext = React.createContext<LoaderContextType>(defaultBehaviour);

export {
	LoaderObject
};

export default LoaderContext;
