import closeIcon from 'images/icon/closeIcon.png';
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import { get, toNumber } from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { change, reduxForm } from 'redux-form';
import PageLoader from '../../../components/PageLoader';
import { browserGoBack } from '../../../helpers/browserHistory';
import { detailData as readContact } from '../../../store/actions/contacts.action';
import { detailData as detailElement } from '../../../store/actions/formbuilder.action';
import { createData, detailData, updateData } from '../../../store/actions/formdata.action';
import { detailData as readOpportunity } from '../../../store/actions/opportunities.action';
import FormReal from '../formElement/builderLib/FormReal';
import { processApiToBuilder } from '../formElement/builderLib/services/fieldGetter';
import * as variables from '../formElement/builderLib/services/variables';

const FORM = 'formDataForm';

const imgField = value => {
	const img = value.reduce((acc, data) => {
		acc.push(data.id);
		return acc;
	}, []);

	return img;
};

const proccessObjSet = value => {
	if (value !== null) {
		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				return [];
			}
			if (typeof value.getMonth === 'function') {
				return moment(value).format('YYYY-MM-DD');
			}
			if (value.hasOwnProperty('img')) {
				return imgField(get(value, 'img'));
			}
			return get(value, 'value');
		}
	}
	return value;
};

const arrayFieldValue = arr => {
	const value = arr.reduce((acc, data) => {
		const rows = [];
		Object.keys(data).map(key => {
			rows.push({
				system_field_name: key,
				values: proccessObjSet(data[key])
			});
		});

		acc.push({ rows });
		return acc;
	}, []);

	return value;
};

const proccessObj = value => {
	if (value !== null) {
		if (typeof value === 'object') {
			// table
			if (Array.isArray(value)) {
				return arrayFieldValue(value);
			}
			// date
			if (typeof value.getMonth === 'function') {
				return moment(value).format('YYYY-MM-DD');
			}
			// image
			if (value.hasOwnProperty('img')) {
				if (value) {
					return imgField(get(value, 'img'));
				}
				return [];
			}
			// result select
			return get(value, 'value');
		}
	}

	// value biasa
	return value;
};

class FormDataCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hyperlinks: {
				companyField: {
					id: '',
					value: null
				},
				contactField: {
					id: '',
					value: null
				},
				opportunityField: {
					id: '',
					value: null
				}
			},
			modalValidate: false,
			validate: []
		};
	}

	componentDidMount() {
		const {
			match,
			detailData,
			detailElement,
			readContact,
			readOpportunity
		} = this.props;
		const query = qs.parse(this.props.location.search, {
			arrayFormat: 'bracket'
		});

		detailElement(match.params.id).then(() => {
			if (match.params.idForm) {
				detailData(match.params.idForm);
			}
			if (query) {
				if (query.type === 'contact') {
					readContact(query.linkedId);
				} else if (query.type === 'opportunity') {
					readOpportunity(query.linkedId);
				}
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		const {
			dispatch,
			formDetail,
			thisForm,
			data,
			contact,
			opportunity
		} = this.props;
		// const apiData = get(data, 'dataDetail.user_form_fields', []);
		const queryId = qs.parse(this.props.location.search, {
			arrayFormat: 'bracket'
		});

		if (contact !== nextProps.contact) {
			const values = get(nextProps, 'formDetail.user_form_fields', []) || [];
			const company = get(nextProps, 'contact.clientCompany', '');
			values.map(data => {
				if (data.field_type === 'hyperlink') {
					if (data.details.link === 'company') {
						setTimeout(() => {
							dispatch(
								change(FORM, data.id, {
									value: company ? company.id : null,
									label: company ? company.name : null
								})
							);
						}, 3000);
					}
				}
			});
		}

		if (opportunity !== nextProps.opportunity) {
			const values = get(nextProps, 'formDetail.user_form_fields', []);
			const company = get(nextProps, 'opportunity.clientCompany', '');
			const contact = get(nextProps, 'opportunity.contact', '');
			values.map(data => {
				if (data.field_type === 'hyperlink') {
					if (data.details.link === 'company') {
						setTimeout(() => {
							dispatch(
								change(FORM, data.id, {
									value: company ? company.id : null,
									label: company ? company.name : null
								})
							);
						}, 3000);
					}
					if (data.details.link === 'contact') {
						setTimeout(() => {
							dispatch(
								change(FORM, data.id, {
									value: contact ? contact.id : null,
									label: contact ? contact.name : null
								})
							);
						}, 3000);
					}
				}
			});
		}

		if (JSON.stringify(nextProps.formDetail) !== JSON.stringify(formDetail)) {
			const values = get(nextProps.formDetail, 'user_form_fields') || [];
			values.map(data => {
				const initialValues = get(data, 'details.initial_value');
				const isDefaultValueToday = get(data, 'details.is_default_value_today');
				const is_ticked = get(data, 'details.is_ticked');
				const is_default_user_name = get(data, 'details.is_default_user_name');
				const table = get(data, 'field_type') === 'table';
				const user_id = get(nextProps, 'current.id');
				if (initialValues) {
					dispatch(change(FORM, data.id, initialValues));
				}
				if (data.field_type === 'hyperlink') {
					if (data.details.link === queryId.type) {
						setTimeout(() => {
							dispatch(
								change(FORM, data.id, {
									value: parseInt(queryId.linkedId),
									label: queryId.linkedName
								})
							);
						}, 3000);
					}
				}

				if (isDefaultValueToday) {
					dispatch(change(FORM, data.id, moment().format('YYYY-MM-DD')));
				}

				if (is_ticked) {
					dispatch(change(FORM, data.id, is_ticked));
				}

				if (is_default_user_name === true) {
					dispatch(change(FORM, data.id, user_id));
				}

				if (is_default_user_name === false) {
					dispatch(change(FORM, data.id, 0));
				}

				if (table) {
					dispatch(change(FORM, data.id, [{}]));
				}
			});
		}
		if (
			JSON.stringify(nextProps.initialValues) !==
      JSON.stringify(this.props.initialValues)
		) {
			const values = get(nextProps.initialValues, 'values');

			values.map(data => {
				if (Array.isArray(data.values)) {
					const arr = data.values;

					if (arr.some(obj => obj.hasOwnProperty('rows'))) {
						// table
						arr.map((dt, i) => {
							dt.rows.map((dts, j) => {
								if (dts.field_type === 'hyperlink') {
									dispatch(
										change(
											FORM,
											`${data.user_form_field_id}[${i}].${dts.system_field_name}`,
											{
												value: dts.values.id,
												label: dts.values.name
											}
										)
									);
								} else {
									dispatch(
										change(
											FORM,
											`${data.user_form_field_id}[${i}].${dts.system_field_name}`,
											dts.values
										)
									);
								}
							});
						});
					} else {
						// image
						if (arr.some(obj => obj.hasOwnProperty('url'))) {
							const img = arr.reduce((acc, dt, i) => {
								acc.push({
									uid: i,
									id: dt.id,
									url: dt.url,
									status: 'done'
								});
								return acc;
							}, []);

							dispatch(change(FORM, data.user_form_field_id, { img }));
						}
					}
				} else if (typeof data.values === 'object') {
					dispatch(
						change(FORM, data.user_form_field_id, {
							value: get(data, 'values.id'),
							label: get(data, 'values.name')
						})
					);
				} else {
					// input biasa
					dispatch(change(FORM, data.user_form_field_id, data.values));
				}
			});
		}
	}

	onSubmit = value => {
		const { data, createData, match, updateData } = this.props;
		const userFormId = get(data, 'dataDetail.id');
		const userFormFields = get(data, 'dataDetail.user_form_fields') || [];
		const { idForm } = match.params;
		const values = [];

		const validate = [];

		userFormFields.map(dt => {
			const errors = [];
			if (dt.field_type === 'numerical') {
				const numberValue = toNumber(value[dt.id]);
				const valMax = get(dt, 'details.max');
				const valMin = get(dt, 'details.min');

				// eslint-disable-next-line no-restricted-globals
				if (isNaN(numberValue)) {
					errors.push(`Please input number in range (${valMin}-${valMax})`);
				}
				if (valMax < numberValue || valMin > numberValue) {
					errors.push(`Please input number in range (${valMin}-${valMax})`);
				}
			}

			if (errors.length) {
				validate.push({
					errors,
					system_field_name: dt.field_name
				});
			}

			if (value.hasOwnProperty(dt.id)) {
				values.push({
					user_form_field_id: dt.id,
					values: proccessObj(value[dt.id])
				});
			} else {
				values.push({
					user_form_field_id: dt.id,
					values: ''
				});
			}
		});

		const formdata = {
			user_form_id: userFormId,
			values
		};

		if (validate.length) {
			this.setState({ validate, modalValidate: true });
		} else {
			this.setState({ modalValidate: false });
			if (idForm) {
				updateData(formdata, idForm);
			} else {
				createData(formdata);
			}
		}
	};

	render() {
		const {
			handleSubmit,
			data,
			route,
			match,
			isLoadingSubmit,
			formDataId,
			contact
		} = this.props;

		const { hyperlinks } = this.state;
		const apiData = get(data, 'dataDetail.user_form_fields', []) || [];
		const dataRemoveNull = processApiToBuilder(apiData);
		const title = get(data, 'dataDetail.name');
		const queryId = qs.parse(this.props.location.search, {
			arrayFormat: 'bracket'
		});
		const typeUser = queryId.type;

		return (
			(<div>
				<div className="d-flex background-bar sticky-v2 right-panel">
					<Link to="/formBuilder" className="pr-3 my-auto">
						<h2 className="card-title mb-0">{route.title}</h2>
					</Link>
					<Link
						to={`/formBuilder/formData/${match.params.id}/list`}
						className="pl-3 pr-3 my-auto"
					>
						<h2 className="card-title mb-0">{title}</h2>
					</Link>
					<div className="pl-3 my-auto">
						<h2 className="card-title font-weight-bold mb-0">
							<Translate id="New Form Data" />
						</h2>
					</div>
					<div className="ml-auto my-auto">
						{match.params.type !== 'detail' && (
							<Button
								className="btn btn-primary btn-save"
								loading={isLoadingSubmit}
								disabled={apiData.length === 0}
								spinColor="#003d79"
								onClick={handleSubmit(this.onSubmit)}
							>
								<span className="text-save">
									{!data.isLoading && <Translate id="save" />}
								</span>
							</Button>
						)}
					</div>
					<div className="ml-3 my-auto">
						<img
							src={closeIcon}
							className="custom-icon-3 my-auto mx-3"
							alt=""
							onClick={() => {
								browserGoBack();
							}}
						/>
					</div>
				</div>
				<div className="p-3">
					{data.isLoadingDetail ? (
						<PageLoader />
					) : (
						<form
							className="forms-sample background-form full-form"
							style={{ minHeight: '100vh' }}
						>
							<div className="row">
								<div className="col-md-12">
									<h2 className="card-title font-weight-bold mb-0" />
								</div>
								<div className="col-md-1" />
								<div className="col-md-8">
									<div className="row">
										<div className="col-md-12">
											<FormReal
												variables={variables}
												data={dataRemoveNull}
												type={match.params.type}
												typeUser={typeUser}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					)}
				</div>
				<Modal
					centered
					toggle={() => {
						this.setState({ modalValidate: false });
					}}
					isOpen={this.state.modalValidate}
				>
					<ModalHeader
						toggle={() => {
							this.setState({ modalValidate: false });
						}}
					>
            Please fill in the following fields{' '}
					</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col-12">
								<ul>
									{this.state.validate.map(dt => (
										<li>
                      Field Name : {dt.system_field_name}
											<ul>
												{dt.errors.map(vl => (
													<li>{vl}</li>
												))}
											</ul>
										</li>
									))}
								</ul>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={() => {
								this.setState({ modalValidate: false });
							}}
						>
              OK
						</Button>
					</ModalFooter>
				</Modal>
			</div>)
		);
	}
}

const mapStateToProps = state => ({
	data: state.formbuilder,
	formDataId: state.formdata.idLinked,
	initialValues: state.formdata.dataDetail,
	formDetail: state.formbuilder.dataDetail,
	isLoadingSubmit: state.formdata.isLoadingSubmit,
	current: state.user.current,
	thisForm: state.form.formDataForm,
	contact: state.contacts.dataDetail,
	opportunity: state.opportunities.dataDetail
});

export default reduxForm({
	form: FORM
})(
	connect(mapStateToProps, {
		createData,
		detailData,
		detailElement,
		updateData,
		readContact,
		readOpportunity
	})(FormDataCreate)
);
