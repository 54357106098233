import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { PopupProps } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import { Body, CloseButton, Container, ExportButton, Footer, Header, Title } from '../../components_v2/popup/style/PopupExportStyle';
import storeLang from '../../helpers/storeLang';

interface PopupExportProps extends PopupProps {
  exportCSV?: boolean
  exportPDF?: boolean
  status?: boolean
  // selected: DropdownData[] | undefined,
  onClickExport?: (value: DropdownData[] | undefined) => void
}

function getContent(props: PopupExportProps, setSelected: (value: { id: number, value: DropdownData } | undefined) => void, selected: DropdownData[] | undefined): JSX.Element {
	const dropdownData: DropdownData[] = [];
	let dropdownDataStatus: DropdownData[] = [];
	const translate = getTranslate(storeLang.getState().localize);

	if (props.exportCSV) {
		dropdownData.push({ label: 'CSV', value: 'CSV' });
	}

	if (props.exportPDF) {
		dropdownData.push({ label: 'PDF', value: 'PDF' });
	}

	if (props.status) {
		dropdownDataStatus = [
			{ label: translate('import.error_rows') as string, value: 'Error' },
			{ label: translate('import.validated_rows') as string, value: 'Validated' }
		];
	}

	return (
		<Container>
			<Header>
				<Title>{translate('exports.export_file').toString()}</Title>
				<CloseButton onClick={() => (props.onClickOut != null) && props.onClickOut()} />
			</Header>
			<Body>
				<Dropdown datalist={dropdownData} name={'exports'} required readOnly cancellable label={translate('exports.file_type').toString()} onChange={(value) => setSelected({ id: 0, value: value ?? undefined })} selectedValue={selected?.[0]}/>
				<div style={{ margin: 10 }}/>
				{props.status && <Dropdown datalist={dropdownDataStatus} name={'status'} required readOnly cancellable label={translate('exports.file_type').toString()} onChange={(value) => setSelected({ id: 1, value: value ?? undefined })} selectedValue={selected?.[1]}/>}
			</Body>
			<Footer>
				<ExportButton disabled={selected?.length !== 2 || selected?.some(s => !s?.value)} onClick={() => (selected != null) && (props.onClickExport != null) && props.onClickExport(selected)}>{translate('exports.export_button').toString()}</ExportButton>
			</Footer>
		</Container>
	);
}

function PopupExport(props: PopupExportProps): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const [selected, setSelected_] = React.useState<DropdownData[] | undefined>([{ label: 'CSV', value: 'CSV' }, { label: translate('import.error_rows') as string, value: 'Error' }]);

	const setSelected = (data) => {
		const tmpSelected = selected ?? [];
		tmpSelected[data.id] = data.value;
		setSelected_([...tmpSelected]);
	};

	return (
		<Popup {...props} popupStyle={{ width: '600px', height: '200px' }} hasBackground content={getContent(props, setSelected, selected)} />
	);
}

export default PopupExport;
