import trash from 'images/formbuilder/trash.svg';
import * as React from 'react';
import Add from '../../../components_v2/add/Add';
import { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import { Column, Table } from '../../../components_v2/table/Table';
import { getBrands } from '../../../components_v2/toolbarFilter/brands/action';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { AlertRes } from '../../alert/AlertProvider';
import useAlert from '../../alert/UseAlert';
import NewBrand from '../../brands/newBrand';
import { DateAccessor } from '../../client-companies/data/CompanyColumns';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { Brand } from '../../globals/Model';
import { TableRow } from '../../orders/templateOrders/style/Style';
import Restricted from '../../permissions/Restricted';
import { ModalState } from '../../products/model';
import { deleteBrand } from './actions';
import PermissionContext from '../../permissions/PermissionContext';

function getColumns(onDelete: (id: number) => void, onOpen: (brand: Brand) => void, deleteAlowed: boolean, editionAlowed: boolean): Column<Brand>[] {
	const cols = [
		{
			id: 'name',
			Header: translateToString('name'),
			accessor: row => <TableRow onClick={() => editionAlowed && onOpen(row)} cursor={editionAlowed ? 'pointer' : undefined} fontWeight='500' color={DarkGreySidely}>{row.name}</TableRow>,
			width: undefined,
			toolTip: row => row.name
		},
		{
			id: 'description',
			Header: translateToString('description'),
			accessor: row => <TableRow>{row.description}</TableRow>,
			width: undefined,
			toolTip: row => row.description
		},
		{
			id: 'created_at',
			Header: translateToString('created_at'),
			accessor: row => <DateAccessor date={row.created_at} />,
			width: undefined,
		},
		{
			id: 'created_by',
			Header: translateToString('created_by'),
			accessor: row => <TableRow><UserBlock userId={row.user_id} /></TableRow>,
			width: undefined,
		},
		{
			id: 'modified_by',
			Header: translateToString('modified_by'),
			accessor: row => <TableRow><UserBlock userId={row.modified_by} /></TableRow>,
			width: undefined,
		}
		// {
		// 	id: 'product_count',
		// 	Header: '__TRANSLATE__ product_count',
		// 	accessor: row => <TableRow>{row.product_count}</TableRow>,
		// 	width: undefined,
		// 	unresizeable: true,
		// },
	];
	if (deleteAlowed) {
		cols.push({
			id: 'options',
			Header: ' ',
			accessor: row => <TableRow onClick={() => onDelete(row.id)} cursor="pointer">
				<img src={trash} width={15} />
			</TableRow>,
			disableSortBy: true,
			// @ts-expect-error width can be a number
			width: 35,
			minWidth: 35,
			unresizeable: true
		});
	}
	return cols;
}

export default function BrandManagement(props: {
	setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [brands_, setBrands_] = React.useState<Brand[]>([]);
	const brands = React.useRef(brands_);
	const setBrands = (b: Brand[]) => {
		setBrands_(b);
		brands.current = b;
	};
	const alert = useAlert();
	const { isAllowedTo } = React.useContext(PermissionContext);
	const deleteAlowed = isAllowedTo({ objectAction: 'DeleteCompanySettings' });
	const editAllowed = isAllowedTo({ objectAction: 'UpdateCompanySettings' });
	const columns = React.useMemo(() => getColumns(id => {
		alert({
			title: translateToString('do_you_want_to_delete_the_brand'),
			content: <div>{translateToNode('attention_delete_brand')}</div>,
			width: '600px',
			mode: 'delete'
		}).then(res => {
			if (res === AlertRes.Ok) {
				deleteBrand(id)
					.then(_ => {
						fetchBrands();
					});
			}
		});
	},
	brand => setCreationOpen({ isOpen: true, data: brand }),
	deleteAlowed,
	editAllowed
	), []);
	const [creationOpen, setCreationOpen] = React.useState<ModalState<Brand>>({ isOpen: false });
	const [selectedCheckbox_, setSelectedCheckbox_] = React.useState<boolean[]>([]);
	const selectedCheckbox = React.useRef(selectedCheckbox_);
	const setSelectedCheckbox = (b: boolean[]) => {
		setSelectedCheckbox_(b);
		selectedCheckbox.current = b;
	};

	const fetchBrands = React.useCallback(() => getBrands().then(setBrands), []);

	React.useEffect(() => {
		fetchBrands();
		props.setToolBarState({
			bottomRightToolbarComponent: <Restricted to={{ objectAction: 'DeleteCompanySettings' }}><Add onClick={() => setCreationOpen({ isOpen: true })}/></Restricted>
		});
	}, []);

	const setTableCheckbox = React.useCallback(row => {
		if (row) {
			selectedCheckbox.current[row.id] = !(selectedCheckbox.current[row.id] ?? false);
			setSelectedCheckbox([...selectedCheckbox.current]);
			return selectedCheckbox.current[row.id];
		} else {
			const actualState = selectedCheckbox.current.filter(b => b).length === brands.current.length;
			const newSelectedCheckbox: boolean[] = [];
			brands.current.forEach(b => newSelectedCheckbox[b.id] = !actualState);
			setSelectedCheckbox([...newSelectedCheckbox]);
			return !actualState;
		}
	}, [brands, selectedCheckbox]);

	return <>
		<Table
			noBorder
			height='100%'
			columns={columns}
			data={brands_}
			setSelectedCheckbox={setTableCheckbox}
			isIdSelected={row => {
				if (row) {
					return selectedCheckbox.current[row.id] ?? false;
				} else {
					return brands.current.length > 0 && selectedCheckbox.current.filter(b => b).length === brands.current.length;
				}
			}}
			// allSelectable
		/>
		<NewBrand isOpen={creationOpen.isOpen} setIsOpen={isOpen => setCreationOpen({ isOpen })} onCreate={fetchBrands} brand={creationOpen.data}/>
	</>;
}