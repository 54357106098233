import play from 'images/icons/play.svg';
import trash_red from 'images/ui_icon/trash_red.svg';
import system_setting_icon from 'images/setting_icons/system_setting_icon.svg';
import eye from 'images/icons/company/eye.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ADatePicker } from '../../atoms/filter/timeFilterAtom';
import { AUsers } from '../../atoms/global/users';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import DropdownOwners, { UserBlock } from '../../components_v2/dropdown/DropdownOwners';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { OptionBlock } from '../../components_v2/dropdown/style/Style';
import { Checkbox } from '../../components_v2/filterList/style/Style';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import Pagination from '../../components_v2/pagination/Pagination';
import { Column, Table, TableSortType } from '../../components_v2/table/Table';
import storeLang from '../../helpers/storeLang';
import { Dot } from '../../styles/global/css/Dot';
import { DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { updateEvent } from '../client-companies/popup/action';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import NoData from '../noData/NoData';
import { Owner } from '../orders/model/Model';
import { TableRow } from '../orders/templateOrders/style/Style';
import { FlexDiv, Status } from '../products/style';
import { CalendarEvent, CalendarEvents, EventsTypes } from './Calendar';
import { deleteEvent, deleteEvents, EventQuery, exportEvents, getEventsV2 } from './EventsApi';
import { colorStatusMapping, colorTypeMapping } from './model/Model';
import AdvancedFilters from '../../components_v2/filter/AdvancedFilters';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import { FilterAction, FilterResult, filterTreeLength } from '../../components_v2/filter/model/Model';
import { ToolbarState } from '../globals/mainPage/mainPage';
import ToolbarFilter, { ToolbarElement } from '../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../atoms/utils/model/Model';
import { ViewSwitch, viewType } from '../../myToolbar/ViewSwitch';
import ToolbarFilterButton from '../../components_v2/toolbarFilter/ToolbarButton';
import { ToolbarImage } from '../globals/defaultToolbar/style/Style';
import EventCreation from '../../components_v2/creation/EventCreation';
import { ModalState } from '../products/model';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';
import { PartialOpen, SeclectionImage, SelectionAction, SelectionActionContainer } from '../client-companies/style/Style';
import Restricted from '../permissions/Restricted';
import PopupDeletion from '../../components_v2/popup/PopupDeletion';
import { SelectAll } from '../client-companies/Companies';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import EventBulkEdition from './popup/bulkEdition';
import PopupEvent from './popup/Event';
import { DarkGreySidely, RedSidely } from '../../styles/global/css/Utils';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { AProspectionFlow } from '../../atoms/prospectionFlow/prospectionFlowAtom';
import styled from 'styled-components';
import { AEventEdition } from '../../atoms/event/eventEdition';
import { isSuperAdmin } from '../../components_v2/utils';
import ModalImportExcel from '../../components/modal/create/ModalImportExcel';
import { importEventExcel } from '../../store/actions/events.action';
import { ARefresh } from '../../atoms/global/refresh';
import Add from '../../components_v2/add/Add';
import PermissionContext, { IsAllowedToDefinition } from '../permissions/PermissionContext';
import optionGrey from 'images/icon/options_grey.png';
import PencilVendorImage from 'images/vendors/pencil.svg';
import InputSearch from '../../components_v2/input/InputSearch';
import { translateToString } from '../../styles/global/translate';

export function CalendarEventUpdater<T extends CalendarEvents>(values: [string, any, string | undefined][], eventId: number | undefined, events: T[], setEvents: (events: T[]) => void) {
	const body = {};
	values.forEach(([key, value]) => body[key] = value);
	if (eventId) {
		updateEvent(eventId, body)
			.then(() => {
				const index = events.findIndex(e => 'id' in e && e.id == eventId);
				if (index >= 0) {
					const event = events[index];
					if (event.type === EventsTypes.Event) {
						for (const [key, value, data] of values) {
							switch (key) {
								case 'description':
									event.description = value.updated;
									break;
								case 'company_id':
									event.clientCompanyId = value.updated;
									event.companieName = data;
									break;
								case 'contact_id':
									event.contactId = value.updated;
									break;
								case 'type_id':
									event.eventTypeId = value;
									break;
								case 'status_id':
									event.eventStatusId = value;
									break;
								case 'start':
								case 'end':
									event[key] = moment.utc(value).toDate();
									break;
								default:
									event[key] = value;
									break;
							}
						}
					}
					events[index] = { ...event };
					setEvents([...events]);
				}
			})
			.catch(console.error);
	}
}

const ProspectionButton = styled(DefaultButton)`
	white-space: nowrap;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 6px;
`;

const DEFAULT_STEP = 25;

const dateFormater = (dates: [moment.Moment, moment.Moment]) => ({
	start: `${dates[0].format('YYYY-MM-DD')} 00:00`,
	end: `${dates[1].format('YYYY-MM-DD')} 23:59:59`
});

const getColumns = (
	events: CalendarEvent[],
	setEvents: (ev: CalendarEvent[]) => void,
	onSelectEvent: (ev: CalendarEvent) => void,
	users: Owner[] | undefined,
	onCompanyClick: (id: number, fullOpenMode?: boolean) => void,
	refresh: () => void,
	onCreateEvent: (event?: CalendarEvent) => void,
	isAllowedTo: IsAllowedToDefinition
): Array<Column<CalendarEvent>> => {
	const all_checked = events.every(e => e.checked);
	const translate = getTranslate(storeLang.getState().localize);
	const columns: Array<Column<CalendarEvent>> = [
		{
			id: 'title',
			Header: translate('title').toString(),
			accessor: (row: CalendarEvent) => <TableRow fontWeight="500" color={DarkGreySidely} cursor="pointer" onClick={() => onSelectEvent(row)}>
				{row.title ?? '-'}
			</TableRow>,
			width: undefined,
			type: 'string',
			toolTip: row => row.title
		},
		{
			id: 'event_type_id',
			Header: translate('type').toString(),
			accessor: (row: CalendarEvent) => {
				const mapping = colorTypeMapping.find(map => map.id == row.eventTypeId);
				return <Restricted
					to={{ objectAction: 'UpdateEvent' }}
					ownerId={row.incharge}
					fallback={<FlexDiv gap='10px' height='100%'>
						<Dot size='10px' color={mapping?.color} />
						<DefaultTextDiv><Translate id={`event.${mapping?.label}`} /></DefaultTextDiv>
					</FlexDiv>}
				>
					<Dropdown
						dropdownStyle={{
							containerWidth: '100%',
							containerJustifyContent: 'flex-start',
							optionWidth: '200px',
							optionHeight: '150px',
							optionFontWeight: '500',
							optionFontColor: '#FFFFFF',
							optionPadding: '5px 10px 5px 10px',
							optionTextWidth: 'fit-content'
						}}
						datalist={colorTypeMapping?.map(s => ({ label: s.label, value: s, color: s.color })) ?? []}
						name="select_calendar_types"
						containerClickable
						autoOptionUp
						JSXButton={() => <FlexDiv gap='10px' height='100%' width='100%'>
							<Dot size='10px' color={mapping?.color} />
							<DefaultTextDiv><Translate id={`event.${mapping?.label}`} /></DefaultTextDiv>
						</FlexDiv>}
						JSXContent={(value) =>
							<OptionBlock>
								<FlexDiv gap='10px' height='100%'>
									<Dot size='10px' color={value?.color} />
									<DefaultTextDiv><Translate id={`event.${value?.label}`} /></DefaultTextDiv>
								</FlexDiv>
							</OptionBlock>
						}
						onChange={(value: DropdownData) => {
							updateEvent(row.id, { type_id: value.value.id })
								.then(() => {
									const index = events.findIndex(e => e.id == row.id);
									if (index >= 0) {
										events[index].eventTypeId = value.value.id;
									}
									setEvents([...events]);
								});
						}}
					/>
				</Restricted>;
			},
			width: undefined,
			type: 'event_type_id'
		},
		{
			id: 'start_date',
			Header: translate('start').toString(),
			accessor: (row: CalendarEvent) => <TableRow color={moment(row.end) < moment() && row.eventStatusId == 1 ? RedSidely : undefined}>{moment(row.start).format('L - LT').replaceAll('/', ' / ').replaceAll(':', ' : ')}</TableRow>,
			width: undefined,
			disableFilter: true,
			type: undefined
		},
		{
			id: 'end_date',
			Header: translate('end').toString(),
			accessor: (row: CalendarEvent) => <TableRow color={moment(row.end) < moment() && row.eventStatusId == 1 ? RedSidely : undefined}>{moment(row.end).format('L - LT').replaceAll('/', ' / ').replaceAll(':', ' : ')}</TableRow>,
			width: undefined,
			disableFilter: true,
			type: undefined
		},
		{
			id: 'event_status_id',
			Header: translate('status').toString(),
			accessor: (row: CalendarEvent) => {
				const mapping = colorStatusMapping.find(map => map.id == row.eventStatusId);
				return <Restricted
					to={{ objectAction: 'UpdateEvent' }}
					ownerId={row.incharge}
					fallback={<FlexDiv height="100%">
						<Status color={mapping?.color}>
							<Translate id={mapping?.label} />
						</Status>
					</FlexDiv>}
				>
					<Dropdown
						dropdownStyle={{
							containerWidth: '100%',
							containerJustifyContent: 'flex-start',
							optionWidth: '200px',
							optionHeight: '150px',
							optionFontWeight: '500',
							optionFontColor: '#FFFFFF',
							optionPadding: '5px 10px 5px 10px',
							optionTextWidth: 'fit-content'
						}}
						datalist={colorStatusMapping?.map(s => ({ label: s.label, value: s, color: s.color })) ?? []}
						name={'select_calendar_status'}
						containerClickable
						autoOptionUp
						JSXButton={() => <FlexDiv height="100%" width='100%'>
							<Status color={mapping?.color}>
								<Translate id={mapping?.label} />
							</Status>
						</FlexDiv>}
						JSXContent={(value) =>
							<OptionBlock>
								<Status color={value?.color}>
									<Translate id={value?.label} />
								</Status>
							</OptionBlock>
						}
						onChange={(value: DropdownData) => {
							updateEvent(row.id, { status_id: value.value.id })
								.then(() => {
									const index = events.findIndex(e => e.id == row.id);
									if (index >= 0) {
										events[index].eventStatusId = value.value.id;
									}
									setEvents([...events]);
								});
						}}
					/>
				</Restricted>;
			},
			width: undefined,
			type: 'event_status_id'
		},
		{
			id: 'client_company_name',
			Header: translate('company_name').toString(),
			accessor: (row: CalendarEvent) => {
				const clickable = row.clientCompanyId && row.clientCompanyId > 0 && row.companieName;
				return (
					<Restricted to={{ objectAction: 'ReadCompany' }} fallback={row.companieName}>
						<TableRow
							fontWeight="500"
							color={DarkGreySidely}
							onClick={() => clickable && row.clientCompanyId && onCompanyClick(row.clientCompanyId, true)}
							cursor={clickable ? 'pointer' : undefined}

						>
							{row.companieName}
						</TableRow>
						{clickable && <PartialOpen
							src={eye}
							onClick={e => {
								e.stopPropagation();
								row.clientCompanyId && onCompanyClick(row.clientCompanyId, false);
							}}
						/>}
					</Restricted>
				);
			},
			width: undefined,
			type: 'string',
			toolTip: row => row.companieName
		},
		{
			id: 'incharge',
			Header: translate('owner').toString(),
			accessor: (row: CalendarEvent) => {
				const owner = users?.find(u => u.id == row.incharge);
				return <Restricted to={{ objectAction: 'UpdateEvent' }} fallback={<UserBlock user={owner} />}>
					<DropdownOwners
						dropdownStyle={{
							containerWidth: 'initial',
							containerJustifyContent: 'flex-start',
							optionWidth: '200px',
							optionHeight: '150px',
							optionPadding: '5px 10px 5px 10px',
							optionLeft: '-10px',
							width: '180px',
							backgroundColor: 'none'
						}}
						users={users?.map(u => ({ label: u.name, value: u, image: u.photoUrl })) ?? []}
						selected={{ label: owner?.name ?? '', value: owner, image: owner?.photoUrl }}
						onChange={value => {
							updateEvent(row.id, { incharge: value.value?.id })
								.then(() => {
									const index = events.findIndex(e => e.id == row.id);
									if (index >= 0 && value.value) {
										events[index].incharge = value.value.id;
									}
									setEvents([...events]);
								});
						}}
					/>
				</Restricted>;
			},
			width: undefined,
			type: 'user'
		},
	];

	if (isAllowedTo([{ objectAction: 'DeleteEvent' }, { objectAction: 'UpdateEvent' }])) {
		columns.unshift({
			id: 'select',
			Header: <Checkbox isActive={all_checked} onClick={() => {
				if (all_checked) {
					setEvents(events.map(e => ({ ...e, checked: false })));
				} else {
					setEvents(events.map(e => ({ ...e, checked: true })));
				}
			}} />,
			accessor: (row: CalendarEvent) => <Checkbox isActive={row.checked ?? false} onClick={() => {
				const index = events.findIndex(e => e.id == row.id);
				if (index >= 0) {
					events[index].checked = !(row.checked ?? false);
					setEvents([...events]);
				}
			} } />,
			width: 40,
			disableSortBy: true,
			type: undefined,
			disableFilter: true,
			unresizeable: true
		});
	}

	if (isAllowedTo([{ objectAction: 'DeleteEvent' }, { objectAction: 'CreateEvent' }])) {
		columns.push(
			{
				id: 'quick_actions',
				Header: <img src={system_setting_icon} style={{ width: 18 }} />,
				accessor: row => {
					const actionList: string[] = [];
					if (isAllowedTo({ objectAction: 'DeleteEvent' }, { ownerId: row.incharge })) {
						actionList.push('delete');
					}
					if (isAllowedTo({ objectAction: 'CreateEvent' }, { ownerId: row.incharge })) {
						actionList.push('duplicate');
					}
					if (!actionList.length) return <></>;

					return (
						<Dropdown
							name='quick_action'
							datalist={actionList.map(a => ({ label: translate(a).toString(), value: a }))}
							readOnly
							dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-70px' }}
							JSXButton={() =>
								<img
									src={optionGrey}
									width={25}
									height={25}
									style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
								/>
							}
							onChange={(value: DropdownData<string>) => {
								switch (value.value) {
									case 'delete':
										deleteEvent(row.id, refresh);
										break;
									case 'duplicate':
										onCreateEvent(row);
										break;
								}
							}}
						/>
					);
				},
				width: 40,
				maxWidth: 40,
				disableSortBy: true,
				disableFilter: true,
				unresizeable: true
			});
	}
	return columns;
};

export default function CalendarList(props: {
  onSelectEvent: (event: CalendarEvent) => void
  setToolBarState: (toolbarState: ToolbarState) => void
  setView: (b: viewType) => void
  refreshData: () => void
}): JSX.Element {
	const users = useRecoilValue(AUsers);
	const user = users.find(u => u.isYou);
	const translate = getTranslate(storeLang.getState().localize);
	const [events, setEvents] = React.useState<CalendarEvent[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();
	const [pagination, setPagination] = React.useState<PaginationResult>();
	const [isFiltersOpen, setIsFiltersOpen] = React.useState<boolean>(false);
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>([]);
	const [filterResult, setFilterResult] = React.useState<FilterResult>({
		values: {
			array: [{ val: {
				action: FilterAction.IS,
				type: 'user',
				id: 'incharge',
				title: translate('owner').toString(),
				values: (user != null) ? [user] : []
			} }],
			combinator: 'and'
		},
		formatted: {
			and: [{
				val: {
					column: 'incharge',
					operator: 'contained',
					value: users.find(u => u.isYou)?.id.toString()
				}
			}]
		}
	});
	const [selectedFilter, setSelectedFilter] = React.useState<string>();
	const [sort, setSort] = React.useState<TableSortType | undefined>({ id: 'start_date', desc: false });
	const [isCreationOpen, setIsCreationOpen] = React.useState<ModalState<CalendarEvent>>({ isOpen: false });
	const [isOpenSummary, setIsOpenSummary] = React.useState<boolean>(false);
	const [eventModal, setEventModal] = React.useState<ModalState<CalendarEvent>>({ isOpen: false });
	const [companyModal, setCompanyModal] = React.useState<ModalState<number>>({ isOpen: false });
	const [isBulkDeleteOpen, setIsBulkDeleteOpen] = React.useState<boolean>(false);
	const [isBulkEditOpen, setIsBulkEditOpen] = React.useState<boolean>(false);

	// Selection
	const [isAllSelected, setIsAllSelected_] = React.useState<boolean>(false);
	const [selectedEvents, setSelectedEvents] = React.useState<Array<{ id: number, checked?: boolean }>>([]);

	const [propspectionFlow, setProspectionFlow] = useRecoilState(AProspectionFlow);
	const eventEdition = useRecoilValue(AEventEdition);
	const [isImportOpen, setIsImportOpen] = React.useState<boolean>(false);
	const refreshingAtom = useRecoilValue(ARefresh);
	const dates = useRecoilValue(ADatePicker);
	const isAdmin = isSuperAdmin();
	const { isAllowedTo } = React.useContext(PermissionContext);
	const exportAllowed = isAllowedTo({ objectAction: 'CreateExport' });
	const [search, setSearch] = React.useState<string>();

	function exportList() {
		if (dates && dates[0] && dates[1]) {
			const { start, end } = dateFormater([dates[0], dates[1]]);
			const params: EventQuery = {
				start: moment(start).toISOString(),
				end: moment(end).toISOString(),
				new_filters: filterResult.formatted,
				order_by: sort?.id,
				desc: sort?.desc,
				search
			};
			exportEvents(params).catch(console.error);
		}
	}

	React.useEffect(() => {
		if (eventEdition != null) {
			const index = events.findIndex(e => e.id == eventEdition.eventId);
			if (index >= 0) {
				for (const [key, value] of Object.entries(eventEdition)) {
					switch (key) {
						case 'eventId':
							break;
						default:
							events[index][key] = value;
							break;
					}
				}
				setEvents([...events]);
			}
		}
	}, [eventEdition]);

	const setIsAllSelected = (b: boolean) => {
		setIsAllSelected_(b);
		if (!b) {
			setEvents(events.map(c => ({ ...c, checked: false })));
			setSelectedEvents([]);
		} else {
			setSelectedEvents(events.map(c => ({ id: c.id, checked: true })));
		}
	};

	const refresh = () => setPagination({
		currentPage: pagination?.currentPage ?? 0,
		step: pagination?.step ?? DEFAULT_STEP,
		offset: pagination?.offset ?? 0
	});

	React.useEffect(() => {
		if (refreshingAtom.calendar !== undefined) refresh();
	}, [refreshingAtom.calendar]);

	const columns = React.useMemo(() => {
		const columns = getColumns(
			events,
			setEvents,
			// onSelectEvent,
			(data) => setEventModal({ isOpen: true, data }),
			users,
			(data, fullOpenMode) => setCompanyModal({ isOpen: true, data, fullOpenMode }),
			refresh,
			data => setIsCreationOpen({ isOpen: true, data: { ...data, id: undefined, created_at: undefined, created_by: undefined } as any as CalendarEvent }),
			isAllowedTo
		);
		setFilterParams([
			{
				category: translate('event_informations').toString(),
				// @ts-expect-error ids
				filters: columns.filter(c => c.type !== undefined).map(c => ({ id: c.id, name: c.Header, type: c.type }))
			}
		]);
		return columns;
	}, [events, pagination]);

	React.useEffect(() => {
		const dropdownData: Array<DropdownData<string>> = [];
		if (exportAllowed) dropdownData.push({ label: translate('Export event').toString(), value: 'EXPORT' });
		if (isAdmin) dropdownData.push({ label: translate('import').toString(), value: 'IMPORT' });
		props.setToolBarState({
			title: translate('calendar.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					category={AtomCategory.CALENDAR}
					elements={[{
						kind: ToolbarElement.DATE_PICKER,
						future: true,
						oneDay: true,
						defaultStart: moment().startOf('day'),
						defaultEnd: moment().endOf('day')
					}]}
				/>
				<ToolbarFilterButton
					activeFilters={filterTreeLength(filterResult.formatted)}
					onClick={() => {
						setSelectedFilter(undefined);
						setIsOpenSummary(true);
						setIsFiltersOpen(true);
					}}
					onDeleteFilter={() => {
						setSelectedFilter(undefined);
						setFilterParams([]);
						setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
					}}
				/>
				<Restricted to={[{ objectAction: 'UpdateEvent' }, { objectAction: 'DeleteEvent' }]}>
					{events.some(c => c.checked) && <>
						<DefaultTextDiv height='40px'>
							<FlexDiv gap='10px' height='100%'>
								<SelectionActionContainer>
									<Restricted to={{ objectAction: 'UpdateEvent' }}>
										<SelectionAction onClick={() => setIsBulkEditOpen(true)} calendar>
											<SeclectionImage src={PencilVendorImage} />
											<Translate id='edit_selection' />
										</SelectionAction>
									</Restricted>
									<Restricted to={{ objectAction: 'DeleteEvent' }}>
										<SelectionAction onClick={() => setIsBulkDeleteOpen(true)} delete calendar>
											<SeclectionImage src={trash_red} />
											<Translate id='delete_selection' />
										</SelectionAction>
									</Restricted>
								</SelectionActionContainer>
							</FlexDiv>
						</DefaultTextDiv>
					</>}
				</Restricted>
				<Restricted to={{ objectAction: 'UpdateEvent' }}>
					{events.length > 0 && !propspectionFlow.isOpen &&
						<SelectionActionContainer>
							<ProspectionButton height='32px' margin='0' onClick={() => {
								if ((dates != null) && (dates[0] != null) && (dates[1] != null)) {
									const { start, end } = dateFormater([dates[0], dates[1]]);
									const apiQuery: EventQuery = {
										start: moment(start).toISOString(),
										end: moment(end).toISOString(),
										new_filters: filterResult.formatted,
										order_by: sort?.id,
										desc: sort?.desc,
										search
									};
									setProspectionFlow({ isOpen: true, apiQuery });
								}
							}}>
								<img src={play} style={{ height: '40%' }} />
								<Translate id='events_launcher' />
							</ProspectionButton>
						</SelectionActionContainer>
					}
				</Restricted>
			</FlexDiv>,
			bottomRightToolbarComponent: <FlexDiv gap='10px'>
				<InputSearch
					placeholder={translateToString('search')}
					name='search_event_name'
					type='text'
					onChange={setSearch}
					delay={500}
				/>
				<ViewSwitch dispatch={res => props.setView(res.data)} value='list' />
				<Restricted to={{ objectAction: 'CreateEvent' }}>
					<Add onClick={() => setIsCreationOpen({ isOpen: true })} />
				</Restricted>
				{dropdownData.length > 0 && <Dropdown
					dropdownStyle={{ optionLeft: '-150px' }}
					onChange={(value: DropdownData<string>) => {
						switch (value.value) {
							case 'IMPORT': return setIsImportOpen(true);
							case 'EXPORT': return exportList();
						}
					}}
					datalist={dropdownData}
					name="options"
					JSXButton={() => <ToolbarImage hasPointer src={optionGrey} />}
				/>}
			</FlexDiv>
		});
	}, [filterResult, events, propspectionFlow.isOpen, dates, sort, search]);

	const fetchEvents = () => {
		if ((dates != null) && (dates[0] != null) && (dates[1] != null) && loadingState !== LoadingStateEnum.LOADING && pagination) {
			setLoadingState(LoadingStateEnum.LOADING);
			const { start, end } = dateFormater([dates[0], dates[1]]);
			const params: EventQuery = {
				start: moment(start).toISOString(),
				end: moment(end).toISOString(),
				limit: pagination.step,
				offset: pagination.offset,
				new_filters: filterResult.formatted,
				order_by: sort?.id,
				desc: sort?.desc,
				search
			};
			getEventsV2(params)
				.then(res => {
					setEvents(res.events.map(e => ({ ...e, checked: selectedEvents.find(se => se.id == e.id)?.checked ?? isAllSelected })));
					setTotal(res.total);
					setLoadingState(LoadingStateEnum.LOADED);
				})
				.catch(e => {
					console.error(e);
					setLoadingState(LoadingStateEnum.ERROR);
				});
		}
	};

	React.useEffect(() => {
		setSelectedEvents([]);
		setPagination({
			currentPage: 0,
			step: pagination?.step ?? DEFAULT_STEP,
			offset: 0
		});
	}, [dates, filterResult.formatted, sort, search]);

	React.useEffect(() => {
		let newSelectedEvents = [...selectedEvents];

		newSelectedEvents = newSelectedEvents
			.map(e => {
				const event = events.find(ae => ae.id == e.id);
				if (event != null) {
					return { id: event.id, checked: event.checked };
				} else {
					return e;
				}
			});

		events.forEach(event => {
			if (!newSelectedEvents.some(se => se.id == event.id)) {
				newSelectedEvents.push({ id: event.id, checked: event.checked });
			}
		});
		setSelectedEvents(newSelectedEvents);
	}, [JSON.stringify(events)]);

	React.useEffect(() => {
		fetchEvents();
	}, [pagination]);

	const isEmpty = events.length == 0 && (loadingState == LoadingStateEnum.LOADED || loadingState == LoadingStateEnum.ERROR);
	const selectedLength = selectedEvents.filter(c => isAllSelected ? !c.checked : c.checked).length;

	return <>
		<Table
			height={isEmpty ? '65px' : 'calc(100vh - 170px)'}
			columns={columns}
			data={events}
			onSort={sort => setSort(sort[0])}
			onClickFilter={(id) => {
				setSelectedFilter(id);
				setIsFiltersOpen(true);
				setIsOpenSummary(false);
			}}
			initialSortBy={sort}
			isInformationHeaderOpen={(selectedEvents.some(c => c.checked) && selectedEvents.length != 0) || isAllSelected}
			informationHeader={<SelectAll
				onMaxSelected={setIsAllSelected}
				selected={selectedLength}
				max={total}
				isAllSelected={isAllSelected}
			/>}
		/>
		{isEmpty && <NoData
			message={translate('no_events').toString()}
			height='calc(100vh - 170px - 65px)'
			messageButton={translate('global.nodata.button.calendar').toString()}
			urlButton="https://university.sidely.app/fr/articles/5828829-comprendre-le-calendrier-web-mobile"
		/>}
		<Pagination
			label={translate('events').toString()}
			amount={total}
			steps={[DEFAULT_STEP, 50, 100]}
			onChange={pag => {
				if (pag.currentPage !== pagination?.currentPage || pag.offset !== pagination?.offset || pag.step !== pagination?.step) { setPagination(pag); }
			}}
		/>
		<AdvancedFilters
			permission='ReadEvent'
			isOpen={isFiltersOpen}
			setOpen={setIsFiltersOpen}
			filterList={filterParams}
			filterValues={filterResult.values}
			onChange={setFilterResult}
			selectedFilter={selectedFilter}
			isOpenSummary={isOpenSummary}
		/>
		<ComponentLoader loadingState={loadingState} allScreen noBackground />
		<EventCreation
			isOpen={isCreationOpen.isOpen}
			setOpen={isOpen => setIsCreationOpen({ isOpen })}
			onCreate={() => setPagination({
				currentPage: 0,
				step: pagination?.step ?? DEFAULT_STEP,
				offset: 0
			})}
			event={isCreationOpen.data}
		/>
		<PopupEvent
			onDelete={refresh}
			isOpen={eventModal.isOpen}
			setIsOpen={b => setEventModal({ ...eventModal, isOpen: b })}
			event={eventModal.data}
			onValueChange={(values, id) => CalendarEventUpdater(values, id, events, setEvents)}
			onCreate={refresh}
		/>
		<ClientCompany
			isOpen={companyModal.isOpen}
			setOpen={b => setCompanyModal({ isOpen: b })}
			clientCompanyId={companyModal.data ?? 0}
			fullOpenMode={companyModal.fullOpenMode}
		/>
		<Popup
			isOpen={isBulkEditOpen}
			popupMode={PopupMode.Details}
			onClickOut={() => {
				setIsBulkEditOpen(false);
			}}
			popupStyle={{ animate: true, height: '100%', top: '0%' }}
		>
			<PopupCreation
				title={<Translate id='mass_modification' />}
				hideValidation
				onClose={() => setIsBulkEditOpen(false)}
			>
				{(dates != null) && (dates[0] != null) && (dates[1] != null) &&
					<EventBulkEdition
						ids={selectedEvents.filter(c => isAllSelected ? !c.checked : c.checked).map(c => c.id)}
						refresh={() => {
							setSelectedEvents([]);
							setIsAllSelected(false);
							refresh();
						}}
						{...dateFormater([dates[0], dates[1]])}
						onClose={() => setIsBulkEditOpen(false)}
						isAll={isAllSelected}
						new_filters={filterResult.formatted}
					/>
				}
			</PopupCreation>
		</Popup>
		<PopupDeletion
			isOpen={isBulkDeleteOpen}
			onClickOut={() => setIsBulkDeleteOpen(false)}
			records={isAllSelected ? total - events.filter(c => !c.checked).length : selectedEvents.filter(c => c.checked).length}
			onValidation={() => {
				if ((dates != null) && (dates[0] != null) && (dates[1] != null)) {
					const { start, end } = dateFormater([dates[0], dates[1]]);
					deleteEvents(
						selectedEvents.filter(c => isAllSelected ? !c.checked : c.checked).map(c => c.id),
						isAllSelected,
						moment(start).toISOString(),
						moment(end).toISOString(),
						filterResult.formatted
					)
						.then(refresh);
					setSelectedEvents([]);
					setIsAllSelected(false);
				}
			}}
			translationKey='events'
		/>
		<ModalImportExcel
			isOpen={isImportOpen}
			toggle={() => setIsImportOpen(false)}
			onImport={data => {
				importEventExcel(data);
				setIsImportOpen(false);
			}}
		/>
	</>;
}
