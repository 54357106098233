import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import KanbanView, { KanbanCategory, KanbanItem } from '../../../components_v2/kanban/kanban';
import { getLocalStorage, setLocalStorage } from '../../../helpers/localStorage';
import { useFunctionState } from '../../../utils/customHooks';
import { DateAccessor } from '../../client-companies/data/CompanyColumns';
import { TableContext } from '../../contact/data/ContactContext';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { FlexDiv } from '../../products/style';
import { KanbanFormInstance, getKanbanFormInstances, putFieldValue } from '../actions';
import { FormInstanceViewSubProps } from './FormInstanceView';
import moment from 'moment';

const HIDDEN_CATEGORIES_LOCAL_STORAGE = 'defaultKabanHiddenCategories';
type HiddenCategoryLocalStorage = { [key: string]: Array<string | null> };

const KANBAN_FIELD_MAP_LOCAL_STORAGE = 'defaultKabanField';
type KanbanFieldMapLocalStorage = { [key: number]: number };

export default function FormInstanceKanban(props: FormInstanceViewSubProps): JSX.Element {
	const { formId, template, fieldMap } = props;
	const [instances, setInstances] = React.useState<KanbanFormInstance[]>([]);
	const [loadingState, setLoadingState] = React.useState(LoadingStateEnum.LOADED);
	const allSelectFields = React.useMemo(() => {
		return template?.screens.flatMap(s => s.fields).reduce((acc, f) => {
			if (Object.keys(f.metadata).length !== 0) return acc;
			const field = fieldMap[f.field_id];
			if (field?.type !== 'Select') return acc;
			return [...acc, { value: field.id, label: field.name }];
		}, []);
	}, [fieldMap, template]);
	const context = React.useContext(TableContext);
	const { filterResult } = context;

	const [selectedField, setSelectedField] = useFunctionState<DropdownData<number> | undefined>(undefined, ({ newValue }) => {
		const defaultKanbanFieldMap = getLocalStorage<KanbanFieldMapLocalStorage>(KANBAN_FIELD_MAP_LOCAL_STORAGE);
		if (newValue) setLocalStorage<KanbanFieldMapLocalStorage>(KANBAN_FIELD_MAP_LOCAL_STORAGE, { ...defaultKanbanFieldMap, [formId]: newValue.value });
		return newValue;
	});

	React.useEffect(() => {
		setSelectedField(() => {
			const defaultKanbanFieldMap = getLocalStorage<KanbanFieldMapLocalStorage>(KANBAN_FIELD_MAP_LOCAL_STORAGE);
			if (!defaultKanbanFieldMap) return allSelectFields?.[0];
			const field = fieldMap[defaultKanbanFieldMap[formId]];
			if (!field) return allSelectFields?.[0];
			return { value: field.id, label: field.name };
		});
	}, [formId]);

	React.useEffect(() => {
		if (!props.viewLoaded || !selectedField) return;
		setLoadingState(LoadingStateEnum.LOADING);
		getKanbanFormInstances(formId, selectedField.value, { filters: filterResult?.formatted }).then(res => {
			setInstances(res);
			setLoadingState(LoadingStateEnum.LOADED);
		});
	}, [filterResult, formId, props.viewLoaded, selectedField]);

	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <Dropdown
				name={''}
				dropdownStyle={{
					width: '205px',
					height: '38px',
					optionWidth: '205px',
					fontSize: 12,
					containerBorder: '1px solid rgba(0, 0, 0, 0.125)',
					arrowClassName: 'fas fa-caret-down mr-1',
					arrowColor: 'rgb(204, 204, 204)',
					arrowFontSize: '12px',
					imageWidth: '22px',
					imageHeight: '22px'
				}}
				readOnly
				selectedValue={selectedField}
				onChange={setSelectedField}
				datalist={allSelectFields ?? []}
			/>,
		});
	}, [allSelectFields, selectedField]);

	const categories: KanbanCategory<string | null>[] = React.useMemo(() => {
		if (!selectedField) return [];
		const field = fieldMap[selectedField.value];
		if (!field || !Array.isArray(field.data)) return [];
		return field.data.map(f => ({
			id: f,
			name: f
		}));
	}, [selectedField, fieldMap]);

	const items: KanbanItem<string | null, KanbanFormInstance>[] = React.useMemo(() => {
		return instances.map(instance => ({
			id: instance.uuid,
			categoryId: instance.value,
			value: instance
		}));
	}, [categories, instances]);

	const defaultHiddenCategories = React.useMemo(() => {
		const defaultFieldKabanHiddenCategories = getLocalStorage<HiddenCategoryLocalStorage>(HIDDEN_CATEGORIES_LOCAL_STORAGE);
		return new Set(defaultFieldKabanHiddenCategories?.[`${formId}-${selectedField}`] ?? []);
	}, [selectedField, formId]);

	return <>
		<KanbanView<string | null, KanbanFormInstance>
			height='calc(100vh - 128px)'
			emptyCategory
			items={items}
			categories={categories}
			onItemClick={React.useCallback((_, item) => props.openPopup(item.value.uuid), [])}
			itemDisplayer={React.useCallback(item => <ItemDisplayer item={item.value} />, [])}
			onItemCategoryChange={async(item, newCategoryId) => {
				if (!selectedField) return undefined;
				const newUuid = await putFieldValue({
					uuid: item.value.field_value_uuid ?? undefined,
					field_id: selectedField.value,
					form_instance_id: item.value.uuid,
					value: newCategoryId,
					metadata: {}
				});
				return { ...item, value: { ...item.value, field_value_uuid: newUuid ?? null, value: newCategoryId } };
			}}
			onHiddenCategoriesChange={categories => {
				const defaultFieldKabanHiddenCategories = getLocalStorage<HiddenCategoryLocalStorage>(HIDDEN_CATEGORIES_LOCAL_STORAGE);
				setLocalStorage<HiddenCategoryLocalStorage>(HIDDEN_CATEGORIES_LOCAL_STORAGE, { ...defaultFieldKabanHiddenCategories, [`${formId}-${selectedField}`]: [...categories] });
			}}
			defaultHiddenCategories={defaultHiddenCategories}
		/>
		<ComponentLoader loadingState={loadingState} allScreen />
	</>;
}

const ItemLinkedCompany = styled.h4`
	overflow: hidden;
	text-overflow: ellipsis;
`;

function ItemDisplayer(props: { item: KanbanFormInstance }) {
	const { item } = props;
	return <FlexDiv flow='column' align='stretch' gap='5px'>
		<ItemLinkedCompany>{item.linked_company}</ItemLinkedCompany>
		<DateAccessor date={moment.utc(item.made_at).toString()} />
		<UserBlock userId={item.created_by} />
		<ProgressBar
			now={item.progression}
			variant={item.progression == 100 ? 'success' : item.progression > 45 ? 'warning' : 'danger'}
			min={0}
			max={100}
		/>
	</FlexDiv>;
}
