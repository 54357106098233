import styled from 'styled-components';
import {
	BorderColor,
	FilterRed,
	GreySidely,
} from '../../styles/global/css/Utils';
import { Checkbox } from '../../components_v2/filterList/style/Style';
import { DashboardItem, DashboardItemType } from './Dashboard';
import * as React from 'react';
import { getTargetById } from '../targets/actions';
import { getDashboardChart } from './actions';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { LoadingStateEnum } from '../import/model';
import Add from '../../components_v2/add/Add';
import { AUsers } from '../../atoms/global/users';
import { useRecoilValue } from 'recoil';
import DropdownOwners from '../../components_v2/dropdown/DropdownOwners';
import { DropdownData, DropdownStyle } from '../../components_v2/dropdown/model/Model';
import { Owner } from '../orders/model/Model';
import Restricted from '../permissions/Restricted';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import trashBlack from 'images/ui_icon/trash_black.svg';
import editIcon from 'images/icon/edit.png';
import { Image } from '../../components_v2/avatar/style/Style';
import { DefaultText } from '../../styles/global/css/GlobalText';
import TargetWidget from './widgets/TargetWidget';
import ParentDnWidget from './widgets/ParentDnWidget';
import EventCountWidget from './widgets/EventCountWidget';
import EventFrequencyWidget from './widgets/EventFrequencyWidget';
import EventFrequencyByUserWidget from './widgets/EventFrequencyByUserWidget';
import DoughnutChartWidget from './widgets/DoughnutChartWidget';
import ClientCompanyStatusCountWidget from './widgets/ClientCompanyStatusCountWidget';
import TopCompanyByCheckoutWidget from './widgets/TopCompanyByCheckoutWidget';
import { FlexDiv } from '../products/style';
import { Translate } from '../../styles/global/translate';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const InfinityAjustement = styled.span`
	${DefaultText};
	font-size: 18px;
  	overflow:hidden;
`;

export const ScrollDiv = styled.div<{ centered?: boolean }>`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  ${p => p.centered ? `
		justify-content: space-around;
		align-items: center;
	` : ''}
`;

export const WidgetDiv = styled.div<{ centered?: boolean, minHeight?: string, maxHeight?: string}>`
  background-color: white;
  border: 1px solid ${BorderColor};
  border-radius: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.8em;
  position: relative;
  overflow:hidden;
  gap:0.5em;
  ${({ minHeight }) => minHeight ? `min-height: ${minHeight};` : ''}
  ${({ maxHeight }) => maxHeight ? `max-height: ${maxHeight};` : ''}
  ${p => p.centered ? `
		justify-content: space-evenly;
		align-items: center;
	` : ''}
`;

export const WidgetTitleWrapper = styled.div`
  padding-bottom: 0.5em;
  flex-shrink: 0;
  border-bottom: 1px solid ${BorderColor};
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index:10;
  //flex-direction:column;
`;

export const WidgetTitle = styled.h3`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  line-height: 25px;
  font-size:14px;
`;

export const WidgetSelector = styled(Checkbox)<{ absolute?: boolean }>`
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
  ${({ absolute }) => absolute ? `
		position: absolute;
		right: 1em;
		top: 0.75em;
	` : ''}
`;

export const WidgetBody = styled.div<{small?: boolean}>`
	font-size: ${p => p.small ? '15px' : '30px'};
  	font-weight: ${p => p.small ? '500' : '600'};
	font-family: "Poppins", sans-serif;
  	overflow-y:auto;
`;

export const CircleDiv = styled.div<{ color?: string, radius?: string, diameter?: string }>`
	width: ${p => p.diameter ?? '10px'};
	height:  ${p => p.diameter ?? '10px'};
	background-color: ${p => p.color ?? GreySidely};
  	border-radius: ${p => p.radius ?? '50%'};
  	margin:5px 10px;
`;


export const Ellipsis = styled.p<{fontWeight?: string, fontSize?: string, color?: string, margin?: string}>`
	${DefaultText};
	overflow: hidden;
	text-overflow: ellipsis;
	margin: ${p => p.margin ?? '0'};
	white-space: nowrap;
	font-weight: ${p => p.fontWeight ?? 'normal'};
  	font-size: ${p => p.fontSize ?? '13px'};
  	color: ${p => p.color ?? ''};
`;

export type DashboardValue = { type: DashboardItemType, value: any }

export const WIDGET_DROPDOWN_STYLE: DropdownStyle = {
	containerWidth: '150px',
	width: '150px',
	optionWidth: '150px'
};

export function WidgetUserSelector(props: { item: DashboardItem, setItem: (item: DashboardItem) => void }) {
	const { item } = props;
	const users = useRecoilValue(AUsers);
	let selected: DropdownData<Owner> | undefined = undefined;
	if (item.additional_values?.['user']) {
		const user = users.find(u => u.id == props.item.additional_values?.['user']);
		if (user) selected = { value: user, label: user.name };
	}

	return <DropdownOwners
		border
		dropdownStyle={WIDGET_DROPDOWN_STYLE}
		cancellable
		selected={selected}
		users={users.map(value => ({ value, label: value.name }))}
		onChange={value => {
			if (!value.value) {
				props.setItem({ ...item, additional_values: { ...item.additional_values, user: undefined } });
			} else {
				props.setItem({ ...item, additional_values: { ...item.additional_values, user: value.value.id } });
			}
		}}/>;
}

export default function Widget(props: {
	item: DashboardItem,
	value?: DashboardValue,
	selected?: boolean,
	onSelect?: (selected: boolean) => void,
	setItem: (item: DashboardItem) => void,
	minHeight?: string
	maxHeight?: string
	onCreationClicked?: () => void
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
}) {
	const [value, setValue] = React.useState<DashboardValue | undefined>(props.value);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const { item } = props;
	const [isHover, setIsHover] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (props.value) return;
		setLoadingState(LoadingStateEnum.LOADING);
		switch (item.item_type) {
			case 'Target':
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				getTargetById(item.item_id!).then(value => setValue({
					value,
					type: 'Target'
				})).then(() => setLoadingState(LoadingStateEnum.LOADED));
				break;
			default:
				if (typeof item.id === 'number')
					getDashboardChart(item.item_type, item.id ?? -1).then(setValue).then(() => setLoadingState(LoadingStateEnum.LOADED));
				break;
		}
	}, [item.id, JSON.stringify(item.additional_values), item.item_id]);


	React.useEffect(() => {
		setValue(props.value);
	}, [props.value]);


	// TODO LIVE PROMOTIONS
	return <WidgetDiv
		centered={value && value.type === 'ParentDn'}
		minHeight={props.minHeight}
		maxHeight={props.maxHeight}
		onMouseOver={() => setIsHover(true)}
		onMouseLeave={() => setIsHover(false)}
	>
		{value && value.type === 'Target' && <TargetWidget {...props} value={value.value}/>}
		{value && value.type === 'ParentDn' && <ParentDnWidget {...props} value={value.value} isHover={isHover}/>}
		{value && value.type === 'EventCount' && <EventCountWidget {...props} value={value.value} isHover={isHover}/>}
		{value && value.type === 'EventFrequency' && <EventFrequencyWidget{...props} value={value.value} isHover={isHover}/>}
		{value && value.type === 'EventFrequencyByUser' && <EventFrequencyByUserWidget{...props} value={value.value} isHover={isHover}/>}
		{(value && (value.type === 'Status' || value.type === 'Company')) && <DoughnutChartWidget {...props} value={value.value} isHover={isHover}/>}
		{value && value.type === 'ClientCompanyStatusCount' && <ClientCompanyStatusCountWidget {...props} value={value.value} isHover={isHover}/>}
		{value && value.type === 'TopCompanyByCheckout' && <TopCompanyByCheckoutWidget {...props} value={value.value} isHover={isHover}/>}
		<ComponentLoader loadingState={loadingState}/>
	</WidgetDiv>;
}

export function NoEvent() {
	return <FlexDiv justify='center' align='center' height={'100%'}><Translate id="no_events"/></FlexDiv>;
}

export function CreateButton(props: { onClickCreate: () => void}) {
	const { onClickCreate } = props;
	return <Restricted to={{ objectAction: 'CreateDashboard' }}>
		<Add onClick={onClickCreate}/>
	</Restricted>;
}

export function DeleteButton(props: { onClickDelete: () => void}) {
	const { onClickDelete } = props;
	return <Restricted to={{ objectAction: 'DeleteDashboard' }}>
		<Image
			url={trashBlack}
			onClick={onClickDelete}
			width="20px"
			hasFilter
			filter={FilterRed}
		/>
	</Restricted>;
}

export function EditButton(props: { onClickEdit: () => void}) {
	const { onClickEdit } = props;
	return (<Restricted to={{ objectAction:'UpdateDashboard' }}>
		<Image
			url={editIcon}
			width="20px"
			onClick={onClickEdit}
			hasFilter
		/>
	</Restricted>);
}