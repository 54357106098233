/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {required} from '../../../../../components/FieldModal';
import UploadMultipleImage from '../../../components/UploadMultipleImagePreview';
import UploadImage from '../../../components/UploadImagePreview';
import {fieldName} from '../services/fieldGetter';
import Delayed from '../../../../../components/Delayed';

const UploadMultipleImageRender = props => (
  <Delayed waitBeforeShow={1000}>
    <UploadMultipleImage {...props} />
  </Delayed>
);

const UploadImageRender = props => (
  <Delayed waitBeforeShow={1000}>
    <UploadImage {...props} />
  </Delayed>
);

class Camera extends Component {
  state = {};

  render() {
    const { data, dynamicField } = this.props;

    const validate = data.is_required ? [required] : [];

    const name = fieldName(data, dynamicField);

    return data.allow_multiple_pictures ? (
      <UploadMultipleImageRender
        name={name}
        id={`id-${name}`}
        type="text"
        label={data.is_display_field_name && data.field_name}
        placeholder=""
        requiredStar={data.is_required}
        validate={validate}
      />
    ) : (
      <UploadImageRender
        name={name}
        id={`id-${name}`}
        type="text"
        label={data.is_display_field_name && data.field_name}
        placeholder=""
        requiredStar={data.is_required}
        validate={validate}
      />
    );
  }
}

export default Camera;
