export const VARIABLE_ARRAY = [
	{
		text: 'name',
		varName: '#name#',
	},
	{
		text: 'first_name',
		varName: '#first_name#',
	},
	{
		text: 'last_name',
		varName: '#last_name#',
	},
	{
		text: 'company_name',
		varName: '#company_name#',
	},
];
