/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React from 'react';

export const required = value => (value ? undefined : 'Fill The Field');

export const renderCheckField = ({
  input,
  placeholder,
  type,
  disabled,
  label,
  noLabel,
  requiredStar,
  id,
  onChange,
  style,
  readOnly,
  meta: { touched, error, warning }
}) => (
  <div style={style}>
    <div className="form-group row">
      {!noLabel && (
        <div className="col-md-3 text-right">
          <label htmlFor={id} style={style}>
            {requiredStar === true ? (
              <span className="text-danger">*</span>
            ) : (
              ''
            )}
            {label}
          </label>
        </div>
      )}

      <div className="col-md-9 my-auto">
        <div className="row">
          <div className="col-1 my-auto">
            <input
              {...input}
              placeholder={placeholder}
              type={type}
              disabled={disabled}
              id={id}
              className={`form-builder form-control ${
                touched && error ? 'focus-error' : ''
              }`}
              readOnly={readOnly}
              // onChange={e => e.stopPropagation()}
            />
          </div>
          <div className="col-11 text-left">
            <label>{placeholder}</label>
          </div>
          <div className="col-12">
            {touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const renderCheckFieldReadOnly = ({
  input,
  placeholder,
  type,
  disabled,
  label,
  noLabel,
  requiredStar,
  id,
  onChange,
  style,
  readOnly,
  meta: { touched, error, warning }
}) => (
  <div style={style}>
    <div className="form-group row">
      {!noLabel && (
        <div className="col-md-3 text-right">
          <label htmlFor={id} style={style}>
            {requiredStar === true ? (
              <span className="text-danger">*</span>
            ) : (
              ''
            )}
            {label}
          </label>
        </div>
      )}

      <div className="col-md-9 my-auto">
        <div className="row">
          <div className="col-1 my-auto">
            {readOnly ? (
              input.value === true ? (
                <i
                  style={{ color: '#1479FB' }}
                  className="fas fa-check-square"
                />
              ) : (
                <i style={{ color: '#CCC' }} className="far fa-square" />
              )
            ) : (
              <input
                {...input}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                id={id}
                className={`form-builder form-control ${
                  touched && error ? 'focus-error' : ''
                }`}
                readOnly={readOnly}
                onChange={e => e.stopPropagation()}
              />
            )}
          </div>
          <div className="col-11 text-left">
            <label>{placeholder}</label>
          </div>
          <div className="col-12">
            {touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}
          </div>
        </div>
      </div>
    </div>
  </div>
);
