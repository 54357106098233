import { getLocalStorage, setLocalStorage } from '../../../helpers/localStorage';

const FORM_LOCAL_STORAGE_PATH = 'current-report';
export type LocalStorageReport = {
	id?: number,
	name: string,
	report?: {report: Report, id: number, name: string},
};

export type LocalStorageReportWrapper = {
	[key: number]: LocalStorageReport,
	newReport?: LocalStorageReport
}

export function getLocalReport(): LocalStorageReportWrapper | undefined {
	return getLocalStorage(FORM_LOCAL_STORAGE_PATH);
}
export function getLocalReportById(): LocalStorageReport | undefined {
	return getLocalReportWrapperById()?.[0];
}


function getLocalReportWrapperById(): LocalStorageReportWrapper | undefined {
	const report = getLocalStorage<LocalStorageReportWrapper>(FORM_LOCAL_STORAGE_PATH);
	return report;
}

export function setLocalReport(report: LocalStorageReport, id?: number) {
	const actualReport = getLocalReport() ?? {};
	if (id === undefined) return setLocalStorage(FORM_LOCAL_STORAGE_PATH, { ...actualReport, report });
	actualReport[id] = report;
	setLocalStorage(FORM_LOCAL_STORAGE_PATH, { ...actualReport });
}

export function removeLocalReport(id?: number) {
	const actualReport = getLocalReport() ?? {};
	if (id === undefined) delete actualReport.newReport;
	else delete actualReport[id];
	setLocalStorage(FORM_LOCAL_STORAGE_PATH, { ...actualReport });
}