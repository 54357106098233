import styled, { keyframes } from 'styled-components';
import { PopupAnimation } from '../model/Model';

interface PopupWrapperProps {
  width?: string
  height?: string
  top?: string
  left?: string
  right?: string
  minWidth?: string
  minHeight?: string
  boxShadow?: string
  isOpen: boolean
  borderRadius?: string
  zIndex?: number
  animate?: boolean
  transform?: string
  position?: string
  animationStyle?: PopupAnimation
  maxHeight?: string
  noTransition?: boolean
}

function getStartAnimation(width?: string, left?: string): string {
	return keyframes`
        0% {
            left: 100%;
        }
        100% {
            left: ${left ?? '30%'};
        }
    `;
}

const PopupWrapper = styled.div<PopupWrapperProps>`
    ${p => {
		//  p.minWidth ? `min( calc(100vw - min(${p.minWidth}, ${p.width ?? '700px'})) , ${p.left ?? '30%'} )` :
		const left = p.left ?? '30%';
		if (!p.animate) return `left: ${left};`;
		let animation;
		switch (p.animationStyle) {
			case PopupAnimation.RightToLeft:
			default:
				animation = getStartAnimation(p.width, left);
				break;
		}
		return `
            left: ${left};
            animation: ${animation} 0.4s 1;
            animation-timing-function: ease-in-out;
        `;
	}}
    transition: ${p => p.noTransition ? 'none' : 'all 0.4s ease'};
    width: ${p => p.width ?? '700px'};
    ${p => p.minWidth ? `min-width: ${p.minWidth};` : ''}
    display: ${p => p.isOpen ? 'initial' : 'none'};
    height: ${p => p.height ?? '500px'};
    min-height: ${p => p.minHeight ?? ''};
    top: ${p => p.top ?? '30%'};
    background-color: white;
    box-shadow: ${p => p.boxShadow ?? ''};
    position: ${p => p.position ?? 'fixed'};
    ${p => p.transform ? `transform: ${p.transform};` : ''}
    z-index: ${p => p.zIndex ?? 200};
    border-radius: ${p => p.borderRadius ? p.borderRadius : '10px 0 0 10px'};
    ${({ maxHeight }) => maxHeight ? `max-height: ${maxHeight};` : ''}
`;

interface ContainerProps {
  animate?: boolean
  displayB: string
  borderRadius?: string
  padding?: string
  overflow?: string
  fitContent?: boolean
  transform?: string
}

const Container = styled.div<ContainerProps>`
    background-color: white;
    z-index: 201;
    position: absolute;
    border-radius: ${p => p.borderRadius ? p.borderRadius : '10px 0 0 10px'};
    padding: ${p => p.padding ?? '10px'};
    ${p => p.overflow ? `overflow: ${p.overflow};` : ''}
    height: ${p => p.fitContent ? 'fit-content' : '100%'};
    width: 100%;
    transition: 0.5s;
    transform: ${p => p.transform ?? ''};
    display: ${p => p.displayB};
    min-height:240px;
    // this line is put for a browser that can't understand fit-content
    // but, fit-content works in almost browser(chrome, firefox)
    // I can't generate this bug...
    // See ticket, [Calendar] On some navigators/computers, the popup is too small, fix a min height
    `;

const BackgroundAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.4;
    }
`;

const Background = styled.div<{ zIndex?: number, noColor?: boolean }>`
    animation: ${BackgroundAnimation} 0.4s;
    position: fixed;
    width: 100%;
    background-color: ${p => p.noColor ? 'transparent' : 'black'};
    opacity: 0.4;
    height: 100%;
    z-index: ${p => p.zIndex ?? 200};
    top: 0;
    left: 0;
`;

const PopupContainer = styled.div``;

const PopupHead = styled.div``;

const PopupBody = styled.div``;

const PopupRow = styled.div``;

const PopupTitle = styled.p``;

const PopupInputBlock = styled.div``;

const PopupInput = styled.input``;

export {
	Container,
	PopupContainer,
	PopupHead,
	PopupBody,
	PopupRow,
	PopupTitle,
	PopupInputBlock,
	PopupInput,
	Background,
	PopupWrapper
};
