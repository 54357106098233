import userGroupImage from 'images/icons/userGroup.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { AUsers } from '../../../atoms/global/users';
import { translateToString } from '../../../styles/global/translate';
import getScope from '../../../containers_v2/permissions/getScope';
import { ObjectAction } from '../../../../../web-types/objectActions';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';

interface Option {
  value: number
  label: string
  checked: boolean
  isYou?: boolean
}

export default function UserPicker(props: GenericToolbarFilterProps & { logo?: string, atomId: number, deleted?: boolean, permission?: ObjectAction }) {
	const { logo, atomId } = props;
	const [options, setOptions] = React.useState<Option[]>([]);
	const translate = getTranslate(storeLang.getState().localize);
	const [pickedUsers, setPickedUsers] = useRecoilState(AUserPicker(atomId));
	const owners = useRecoilValue(AUsers);
	const perms = getScope(props.permission);
	const { filters, setFilters } = React.useContext(ReportFiltersContext);
	const [contextPickedUsers, setContextPickedUsers] = React.useState(filters.created_by_filter ? [filters.created_by_filter, false, false] : [[], false, true]);
	
			
	let localPickedUsers;
	if (props.context) {
		if (filters.created_by_filter) {
			localPickedUsers = contextPickedUsers;
		} 
	} else {
		localPickedUsers = pickedUsers;
	}

	React.useEffect(() => {
		const opt: Option[] = [
			{ value: -1, label: '', checked: localPickedUsers == undefined || localPickedUsers[0].length == owners.length },
			...owners.filter(o => perms === 'User' ? o.isYou : true).map(owner => ({
				isYou: owner.isYou,
				value: owner.id,
				label: owner.name,
				checked: localPickedUsers == undefined || localPickedUsers[0].find(userId => userId == owner.id) !== undefined
			})),
		];
		if (props.deleted && perms !== 'User') {
			opt.push({ value: -2, label: translateToString('without_user'), checked: true });
		}
		if (localPickedUsers == undefined) {
			if (props.context) {
				setFilters(filters => ({ ...filters, created_by_filter: undefined }));
				setContextPickedUsers([owners.filter(o => perms === 'User' ? o.isYou : true).map(e => e.id), (props.deleted && perms !== 'User') ?? false, options.every(e => e.checked)]);
			}
			else {
				setPickedUsers([owners.filter(o => perms === 'User' ? o.isYou : true).map(e => e.id), (props.deleted && perms !== 'User') ?? false, options.every(e => e.checked)]);
			}
		} 
		setOptions(opt);
	}, [props.deleted, perms, localPickedUsers]);

	if (props.hidden) return <></>;

	function handleChecked(checked: boolean, value: number) {
		let newList;
		if (value == -1) {
			newList = options.map(e => {
				return {
					...e,
					checked
				};
			});
			setOptions(newList);
		} else {
			const nbChecked = options.slice(1).reduce((acc, e) => e.checked ? acc + 1 : acc, 0);
			const allChecked = nbChecked + 1 == options.slice(1).length && checked;
			newList = options.map(e => {
				if (e.value == -1) {
					return {
						...e,
						checked: allChecked
					};
				} else if (e.value == value) {
					return {
						...e,
						checked
					};
				} else {
					return e;
				}
			});
			setOptions(newList);
		}
		if (props.context) {
			setFilters(filters => ({ ...filters, created_by_filter: newList.slice(1, props.deleted ? -1 : undefined).filter(e => e.checked).map(e => e.value) }));
			setContextPickedUsers([newList.slice(1, props.deleted ? -1 : undefined).filter(e => e.checked).map(e => e.value), newList.find(e => e.value === -2)?.checked ?? false, newList.every(e => e.checked)]);
		} else {
			setPickedUsers([newList.slice(1, props.deleted ? -1 : undefined).filter(e => e.checked).map(e => e.value), newList.find(e => e.value === -2)?.checked ?? false, newList.every(e => e.checked)]);
		}
	}

	return (
		(<UncontrolledButtonDropdown
			direction="down"
			className="form-filter-opportunity"
			style={{ padding: '0', border: '1px solid rgba(0,0,0,0.125)', width: props.fullScreen ? '100%' : 150, margin: 0 }}
		>
			<div style={{ textAlign: 'center', padding: '7px' }}>
				<img src={logo ?? userGroupImage} style={{ height: '20px' }} />
			</div>
			<DropdownToggle className="toggler p-0 ellipsis" caret>
				<span className="ellipsis w-100 text-left text-black">
					{options.every(e => e.checked)
						? (
							<Translate id="all" />
						)
						: localPickedUsers?.[0].length !== 0
							? (
								`${options.filter(e => e.checked).length} ${translate('selected')}`
							)
							: (
								translate('none') + ' ' + translate('selected')
							)}
				</span>
				<i
					className="fas fa-caret-down "
					style={{ color: 'hsl(0,0%,80%)', marginRight: '4px', padding: '12px' }}
				/>
			</DropdownToggle>
			<DropdownMenu
				className="check-sidely"
				style={{
					width: 180,
					marginLeft: '-45px',
					zIndex: 9,
					padding: '10px 10px 1px',
					overflowY: 'auto',
					maxHeight: 300,
					boxShadow: '0px 1px 5px #cfcfcf'
				}}
			>
				{options.sort((a, b) => {
					if (a.value < 0) return -1;
					if (b.value < 0) return 1;
					if (a.isYou) return -1;
					if (b.isYou) return 1;
					return a.label.localeCompare(b.label);
				}).map((option, i) => (
					<label className="d-block" key={i}>
						{option.value === -2 &&
							<div style={{ paddingBottom: '5px' }}>
								<DropdownItem divider />
							</div>}
						<input
							name={`ToolBarUserPicker[${i}]`}
							id={`ToolBarUserPicker[${i}]`}
							type="checkbox"
							value={option.value}
							checked={option.checked}
							onChange={e => handleChecked(e.target.checked, option.value)} />
						<div className="checkbox-style top-2" style={{ top: '4px' }} />
						<span className="d-block w-100 ml-4">{option.value === -1 ? translate('all') as string : option.label + (option.isYou ? ` (${translate('me')})` : '')}</span>
						{option.value === -1 &&
							<div style={{ paddingTop: '5px' }}>
								<DropdownItem divider />
							</div>}
					</label>
				))}
			</DropdownMenu>
		</UncontrolledButtonDropdown>)
	);
}
