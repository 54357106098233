import TrashImage from 'images/icons/orders/trash.svg';
import * as React from 'react';
import { getTranslate, Translate, TranslateFunction } from 'react-localize-redux';
import { Redirect } from 'react-router';
import { formatCurrency, getCurrencySymbol } from '../../../containers_v2/reports/utils';
import storeLang from '../../../helpers/storeLang';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import {
	closebottomRightToolbar,
	getDifference,
	getOrderCreator,
	getTotalDiscounGlobalPrice,
	getTotalDiscountPrice,
	getTotalDiscountProductsPrice,
	getTotalProduct,
	getTotalTaxPrice,
	getTotalTTC,
	setOrderCreator,
	updatebottomLeftToolbar
} from '../global/globalFunction';
import { OrderCreator, POURCENTAGE, SelectedProduct } from '../model/Model';
import {
	Button,
	CardImage,
	CardRow,
	Container,
	ContainerLeft,
	ContainerProducts,
	ContainerShoppingCart,
	Separator,
	ShoppingCart,
	ShoppingCartText,
	Text
} from './style/Style';
import noImage from 'images/no-image.jpg';

function submit(selectedProducts: SelectedProduct[], order: OrderCreator): void {
	if (selectedProducts.length === 0) {
		return;
	}

	setOrderCreator(order.name, order.company, order.creationDate, order.owner, order.contact, order.opportunity, selectedProducts, order.discount, order.discountType, order.order_status_id, order.payment_id, order.comment);
}

function toProductContents(product: SelectedProduct, selectedProducts: SelectedProduct[], setSelectedProducts: (sp: SelectedProduct[]) => void, translate: TranslateFunction): JSX.Element[] {
	return [
		(
			<CardImage src={product.productImage} alt={product.name} onError={(e) => e.currentTarget.src = noImage} key='productContent[1]' />
		),
		(
			<Text isBold Height="100%" key='productContent[2]'>{product.name}</Text>
		),
		(
			<Text key='productContent[3]'>{`x${product.amount}`}</Text>
		),
		(
			<Text key='productContent[4]'>{product.price != null ? product.price.toString() : translate('orders.noPrice').toString()}</Text>
		),
		(
			<Text key='productContent[5]'>{`-${product.discount}${product.discountType === POURCENTAGE ? '%' : getCurrencySymbol()}`} </Text>
		),
		(
			<CardImage height="15px" cursor="pointer" src={TrashImage} alt='trashbin' onClick={() => {
				setSelectedProducts(selectedProducts.filter(p => p.id !== product.id));
			}} key='productContent[6]' />
		)
	];
}

function getToolbarHeader(): JSX.Element[] {
	return [
		(<Text fontSize="12px" key={'toolBarHeader[a]'}><Translate id="orders.summary.titles.image" /></Text>),
		(<Text fontSize="12px" key={'toolBarHeader[b]'}><Translate id="orders.summary.titles.name" /></Text>),
		(<Text fontSize="12px" key={'toolBarHeader[c]'}><Translate id="orders.summary.titles.amount" /></Text>),
		(<Text fontSize="12px" key={'toolBarHeader[d]'}><Translate id="orders.summary.titles.price" /></Text>),
		(<Text fontSize="12px" key={'toolBarHeader[e]'}><Translate id="orders.summary.titles.discount" /></Text>),
		(<Text fontSize="12px" key={'toolBarHeader[f]'}><Translate id="orders.summary.titles.delete" /></Text>)
	];
}

function initToolbar(setToolBarState: React.Dispatch<React.SetStateAction<ToolbarState>>, setRedirect: (elem: JSX.Element) => void, order: OrderCreator): void {
	closebottomRightToolbar(setToolBarState, setRedirect, '/orders');
	updatebottomLeftToolbar(setToolBarState, 2, order.name);
}

function Summary(props: {
  setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const order = getOrderCreator();
	const [selectedProducts, setSelectedProducts] = React.useState<SelectedProduct[]>(order.selectedProducts);
	const [redirect, setRedirect] = React.useState<JSX.Element>(<></>);

	const total_product = getTotalProduct(selectedProducts);
	const total_discount_product = getTotalDiscountProductsPrice(selectedProducts);
	const total_discount_global = getTotalDiscounGlobalPrice(selectedProducts, order.discount, order.discountType, getDifference(order.selectedProducts, order.discount));
	const total_discount = getTotalDiscountPrice(selectedProducts, order.discount, order.discountType, getDifference(order.selectedProducts, order.discount));
	const total_tax = getTotalTaxPrice(order.selectedProducts);
	const total_HT = total_product - total_discount;
	const total_TTC = getTotalTTC(selectedProducts, order.discount, order.discountType, getDifference(order.selectedProducts, order.discount));

	React.useEffect(() => {
		initToolbar(props.setToolBarState, setRedirect, order);
	}, []);

	return (
		<Container>
			{redirect}
			<ContainerLeft>
				<ContainerProducts>
					<table>
						<thead>
							<tr>
								{getToolbarHeader().map((h, i) => <th key={`toolBarHeader[${i}]`}>{h}</th>)}
							</tr>
						</thead>
						<tbody>
							{selectedProducts.map((product, x) => (
								<tr key={`selectedProductMap[${x}]`}>
									{toProductContents(product, selectedProducts, setSelectedProducts, translate).map((v, i) => <td key={`productValue[${i}][${x}]`}>{v}</td>)}
								</tr>
							))
							}
						</tbody>
					</table>
				</ContainerProducts>
			</ContainerLeft>
			<ContainerShoppingCart>
				<ShoppingCart>
					<CardRow>
						<ShoppingCartText>{translate('orders.summary.result_card.totalProduct').toString()}</ShoppingCartText>
						<ShoppingCartText>{formatCurrency(total_product)}</ShoppingCartText>
					</CardRow>
					<CardRow>
						<ShoppingCartText>{translate('orders.summary.result_card.productDiscount').toString()}</ShoppingCartText>
						<ShoppingCartText>{formatCurrency(total_discount_product)}</ShoppingCartText>
					</CardRow>
					<CardRow>
						<ShoppingCartText>{translate('orders.summary.result_card.globalDiscount').toString()}</ShoppingCartText>
						<ShoppingCartText>{formatCurrency(total_discount_global)}</ShoppingCartText>
					</CardRow>
					<CardRow>
						<ShoppingCartText>{translate('orders.summary.result_card.totalDiscount').toString()}</ShoppingCartText>
						<ShoppingCartText>{formatCurrency(total_discount)}</ShoppingCartText>
					</CardRow>
					<CardRow>
						<ShoppingCartText>{translate('orders.summary.result_card.totalHT').toString()}</ShoppingCartText>
						<ShoppingCartText>{formatCurrency(total_HT)}</ShoppingCartText>
					</CardRow>
					<CardRow>
						<ShoppingCartText>{translate('orders.summary.result_card.totalTax').toString()}</ShoppingCartText>
						<ShoppingCartText>{formatCurrency(total_tax)}</ShoppingCartText>
					</CardRow>

					<Separator />

					<CardRow>
						<ShoppingCartText>{translate('orders.summary.result_card.totalTTC').toString()}</ShoppingCartText>
						<ShoppingCartText>{formatCurrency(total_TTC)}</ShoppingCartText>
					</CardRow>
					<CardRow justifyContent="center">
						<Button disabled={selectedProducts.length <= 0} type="button" onClick={_ => {
							if (selectedProducts.length > 0) {
								submit(selectedProducts, order);
								setRedirect(<Redirect push to="/orders/billing" />);
							}
						}}>{translate('orders.summary.result_card.continue').toString()}</Button>

					</CardRow>
				</ShoppingCart>
			</ContainerShoppingCart>
		</Container>
	);
}

export default Summary;
