import axios from 'axios';
import { Cookies } from 'react-cookie';
import { atom, selector } from 'recoil';
import { FilterResult } from '../../components_v2/filter/model/Model';
import { URL_FOUNDATION } from '../../config/keys';
import { AtomCategory, AtomState } from '../utils/model/Model';
import { FetchKey } from '../../containers_v2/map/MapView';
import { Granularity } from 'bindings/time/Granularity';
import { Moment } from 'moment';
import { Brand } from '../../containers_v2/globals/Model';

// -----------------------[ TYPES ]----------------------- //
export type AllViewTypes<S extends ViewKey | 'other'> = Views[S][0]['data'];

export type NewView<S extends ViewKey | 'other'> = {
	data: AllViewTypes<S>,
	public: boolean
}

export type DbView<T> = {
	created_at: string,
	data: T,
	id: number,
	owner_id: number,
	public: boolean,
	updated_at?: string
}

export type ViewCompanyColumns = {
	column: string,
	hidden: boolean
}

export type ViewKey = 'companies' | 'contacts' | 'checkouts' | 'targets' | 'metrics' | 'reports'| 'forms'
export type DefaultView<T extends ViewKey> = {
	type: T,
	name: string,
	filters: FilterResult,
	columns: ViewCompanyColumns[],
}

export type CompanyView = DefaultView<'companies'> & { mapTabType?: FetchKey };

export type ContactView = DefaultView<'contacts'>;

export type CheckoutView = DefaultView<'checkouts'>;

export type TargetView = DefaultView<'targets'>;

export type ReportView = DefaultView<'reports'> & { reportId: number, tagsFilter?: string[], brandFilter?: Brand[], newFilters: any, filteredProducts?: any, users?: [number[], boolean, boolean], allBrands?: boolean };

export type MetricsView = DefaultView<'metrics'> & { granularity: Granularity, representation: string, pickedDates: [Moment | undefined, Moment | undefined] | undefined};

export type FormView = DefaultView<'forms'> & { formId: number };

export type Views = {
	companies: DbView<CompanyView>[],
	other: DbView<unknown>[]
	contacts: DbView<ContactView>[],
	checkouts: DbView<CheckoutView>[],
	targets: DbView<TargetView>[],
	reports: DbView<ReportView>[],
	metrics: DbView<MetricsView>[],
	forms: DbView<FormView>[],
}

// -----------------------[ ATOM ]----------------------- //
const AViewsAtom: AtomState<Views> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_view', // UNIQUE ID
		default: getViews()
	})
};

export const AViews = selector<Views>({
	key: 'AViews',
	get: ({ get }) => get(AViewsAtom.atom),
	set: ({ set }, newValue) => set(AViewsAtom.atom, newValue)
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getViews(): Promise<Views> {
	const views: Views = {
		companies: [],
		contacts: [],
		checkouts: [],
		targets: [],
		reports: [],
		metrics: [],
		other: [],
		forms: [],
	};

	try {
		return await axios.get<DbView<unknown>[]>(`${URL_FOUNDATION ?? ''}/api/v2/views`)
			.then(response => {
				response.data.forEach(view => {
					try {
						if (!view.data || typeof view.data !== 'object' || !('type' in view.data) || typeof view.data.type !== 'string') {
							views.other.push(view);
							return;
						}
						views[view.data.type]?.push(view);
					} catch (e) {
						console.error(e);
						views.other.push(view);
					}
				});
				return views;
			});
	} catch (error) {
		console.error(error);
		return views;
	}
}

export async function postView<S extends ViewKey | 'other'>(view: NewView<S>): Promise<never> {
	return await axios.post(`${URL_FOUNDATION ?? ''}/api/v2/views`, view);
}

export async function putView<S extends ViewKey | 'other'>(id: number, view: NewView<S>): Promise<never> {
	return await axios.put(`${URL_FOUNDATION ?? ''}/api/v2/views/${id}`, view);

}

export async function deleteView(id: number): Promise<never> {
	return await axios.delete(`${URL_FOUNDATION ?? ''}/api/v2/views/${id}`);
}
