/* eslint-disable no-return-assign */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import { selectAllUser } from '../../../store/actions/select.action';
import calendarImage from 'images/icon/small_calendar2.svg';

class OpportunityFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			created: <Translate id="all" />,
			usersOpt: [],
			owner: 'All',
			imageUrl: null
		};
		this.toggle = this.toggle.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const { users, current, selectAllUser } = this.props;

		if (current !== nextProps.current) {
			selectAllUser(nextProps.current.companyId);
		}

		if (users !== nextProps.users) {
			const opt = [];
			opt.push({
				value: 'all',
				label: <Translate id="all" />
			});
			opt.push({
				value: current.id,
				label: `${current.name} (You)`,
				image: current.photoUrl
			});
			nextProps.users.map(
				user =>
					user.id !== current.id &&
          opt.push({
          	value: user.id,
          	label: user.name,
          	image: user.photoUrl
          })
			);
			this.setState({ usersOpt: opt });
		}
	}

	toggle(e) {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	render() {
		const { created, owner, imageUrl, usersOpt } = this.state;
		const { onFilter, current, users } = this.props;
		// console.log('created', created);

		return (
			<Fragment>
				<UncontrolledButtonDropdown
					direction="down"
					className="form-filter-opportunity d-flex align-items-center"
				>
					<img src={calendarImage} height={20} alt="" />
					<DropdownToggle className="toggler py-0 px-1" caret>
						{created}
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem
							onClick={() => {
								this.setState({
									created: <Translate id="all" />
								});
								onFilter({ date_type: 'all' });
							}}
						>
							<Translate id="all" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilter({ date_type: 'today' });
								this.setState({
									created: <Translate id="today" />
								});
							}}
						>
							<Translate id="today" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilter({ date_type: 'week' });
								this.setState({
									created: <Translate id="this_week" />
								});
							}}
						>
							<Translate id="this_week" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilter({ date_type: 'month' });
								this.setState({
									created: <Translate id="this_month" />
								});
							}}
						>
							<Translate id="this_month" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilter({ date_type: 'year' });
								this.setState({
									created: <Translate id="this_year" />
								});
							}}
						>
							<Translate id="this_year" />
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	users: state.select.allUser,
	current: state.user.current
});

export default connect(mapStateToProps, { selectAllUser })(OpportunityFilter);
