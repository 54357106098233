import trashImage from 'images/formbuilder/trash.svg';
import * as React from 'react';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { INPUT_STYLE_SETTINGS, SystemSettingTitle, SystemSettingsCheckBoxWrapper, SystemSettingsDropdownWrapper, SystemSettingsWrapper } from '../subSettings/styles';
import { Translate, translateToNode, translateToString } from '../../../styles/global/translate';
import InputImage from '../../../components_v2/input/InputImage';
import { ImageResult, InputMode } from '../../../components_v2/input/model/Model';
import Input from '../../../components_v2/input/Input';
import { Checkbox } from '../../../components_v2/filterList/style/Style';
import { diff } from '../../../components_v2/utils';
import { SaveButton } from '../subSettings/statusesAndTagsSettings';
import equal from 'deep-equal';
import { PutMyProfileBody, getMyProfile, putMyProfile } from './actions';
import { LoadingStateEnum } from '../../import/model';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import { match_email } from 'validators-web';
import { FlexDiv } from '../../products/style';
import styled from 'styled-components';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { Dot } from '../../../styles/global/css/Dot';
import { getUserColor } from '../../../components_v2/avatar/Data/Data';
import colorList from '../../../components_v2/avatar/Data/ColorList';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import Restricted from '../../permissions/Restricted';
import { Prompt } from 'react-router';
import InputAddress from '../../../components_v2/input/InputAddress';
import CloseImage from 'images/icons/orders/close.svg';

const COLOR_DOT_OPTION_SIZE = 20;

export type UserWithAdditionalFields = {
	id: number,
	name: string,
	email: string,
	phone?: string,
	photo?: string,
	address?: string,
	city?: string,
	postal_code?: string,
	country?: string,
	latitude?: number,
	longitude?: number,
	color?: string,
	use_address_as_default_map_center?: boolean,
	use_address_as_default_optimization_start?: boolean,
	use_address_as_default_optimization_end?: boolean,
}

type ErrorState = {
	name: boolean,
	email: boolean
}

const DelAddressCross = styled.div<{ size?: string, url?: string, left?: string, top?: string }>`
	position: absolute;
	width: ${p => p.size ?? '45px'};
	height: ${p => p.size ?? '45px'};
	cursor: pointer;
	bottom: 0;
	right: 0;
	${p => p.top ? `top: ${p.top};` : ''}
	${p => p.left ? `left: ${p.left};` : ''}
	background-image: url('${p => p.url ?? CloseImage}');
	background-position: center;
	background-size: 50%;
	background-repeat: no-repeat;
`;

export default function MyProfile(props: {
	setToolBarState: (value: ToolbarState) => void
}) {
	const [image, setImage] = React.useState<ImageResult>();
	const [state, setState] = React.useState<UserWithAdditionalFields>();
	const [defaultState, setDefaultState] = React.useState<UserWithAdditionalFields>();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [errorState, setErrorState] = React.useState<ErrorState>({ name: false, email: false });
	const alert = useAlert();

	const delAddress = <DelAddressCross className='trash' onClick={e => {
		e.stopPropagation();
		state && setState({
			...state,
			address: undefined,
			longitude: undefined,
			latitude: undefined,
			country: undefined,
			city: undefined,
			postal_code: undefined
		});
	}}/>;

	React.useEffect(() => {
		setErrorState({
			name: state?.name.trim().length === 0 ?? false,
			email: state?.email !== undefined ? state.email.trim().length === 0 || !match_email(state.email.trim()) : false
		});
	}, [state]);

	const fetchProfile = () => getMyProfile().then(res => {
		setDefaultState(JSON.parse(JSON.stringify(res)));
		setState(res);
		setImage(undefined);
		setLoadingState(LoadingStateEnum.LOADED);
	}).catch(() => {
		setLoadingState(LoadingStateEnum.ERROR);
		alert({
			title: translateToNode('error'),
			svg: 'error',
			content: translateToNode('error_please_retry'),
			buttons: [{ title: translateToNode('retry'), res: AlertRes.Ok, style: ButtonStyle.Error }]
		}).then(res => {
			if (res === AlertRes.Ok) fetchProfile();
		});
	});

	React.useEffect(() => {
		fetchProfile();
	}, []);

	React.useEffect(() => {
		const disabled = (equal(diff(defaultState, state), {}) && !image) || loadingState !== LoadingStateEnum.LOADED;
		props.setToolBarState({
			bottomRightToolbarComponent: <Restricted to={{ objectAction: 'UpdateAccountSettings' }}>
				<SaveButton
					buttonStyle={ButtonStyle.White}
					disabled={disabled}
					onClick={() => {
						setState(JSON.parse(JSON.stringify(defaultState)));
						setErrorState({ name: false, email: false });
					}}>
					{translateToNode('cancel')}
				</SaveButton>
				<SaveButton
					disabled={disabled || Object.values(errorState).some(e => e)}
					onClick={onSave}>
					{loadingState === LoadingStateEnum.LOADING
						? <FlexDiv width='100%' justify='center'><PageLoader size={22} /></FlexDiv>
						: translateToNode('save')
					}
				</SaveButton>
			</Restricted>
		});
	}, [state, image, loadingState, errorState]);

	function onSave() {
		setLoadingState(LoadingStateEnum.LOADING);
		const diffs = diff(defaultState, state);
		const body: PutMyProfileBody = {};
		if (diffs) {
			Object.entries(diffs).reduce((acc, [key, value]) => {
				if (value === undefined || value === null) {
					acc[key] = 'deleted';
				} else if (typeof value === 'string') {
					const trimmed = value.trim();
					if (trimmed.length === 0) {
						acc[key] = 'deleted';
					} else {
						acc[key] = { updated: trimmed };
					}
				} else {
					acc[key] = { updated: value };
				}
				return acc;
			}, body);
		}
		if (image) {
			body.photo = { updated: image.content };
			body.photo_name = image.file.name;
		}
		putMyProfile(body).then(fetchProfile).catch(_ => {
			alert({
				title: translateToNode('error'),
				svg: 'error',
				content: translateToNode('error_please_retry'),
				buttons: [{ title: translateToNode('retry'), res: AlertRes.Ok, style: ButtonStyle.Error }]
			}).then(res => res === AlertRes.Ok && onSave());
		});
	}
	
	return <>
		<Prompt
			when={!equal(diff(defaultState, state), {})}
			message={translateToString('unsaved_changes_leave')}
		/>
		<SystemSettingsWrapper>
			<SystemSettingTitle>{translateToNode('my_profile')}</SystemSettingTitle>

			<SystemSettingsDropdownWrapper relative>
				{translateToNode('Picture')}
				<InputImage
					url={image?.url ?? state?.photo}
					size={60}
					compress
					disableShowImage
					onChange={setImage}
				/>
				{(state?.photo || image?.content) &&
				<DelAddressCross
					className='trash'
					size='30px'
					url={trashImage}
					left='60px'
					top='28px'
					onClick={e => {
						e.stopPropagation();
						setImage(undefined);
						state && setState({
							...state,
							photo: ''
						});
					}}
				/>
				}
			</SystemSettingsDropdownWrapper>
		
			<SystemSettingsDropdownWrapper relative>
				{translateToNode('color')}
				<Dropdown<string>
					dropdownStyle={{ height: '40px', containerHeight: 'fit-content', optionWidth: `calc(${COLOR_DOT_OPTION_SIZE * 10}px + 2px)`, optionHeight: `${COLOR_DOT_OPTION_SIZE * 6}px` }}
					JSXButton={() => <Dot color={state?.color ?? (state?.name ? getUserColor(state.name) : undefined) } size='40px'/>}
					datalist={colorList.map(value => ({ value, label: <Dot color={value} size={COLOR_DOT_OPTION_SIZE + 'px'} cursor='pointer' /> }))}
					name={'color-picker'}
					gallery
					autoOptionUp
					onChange={(value: DropdownData<string>) => state && setState({ ...state, color: value.value })}
				/>
				{state?.color &&
				<DelAddressCross
					className='trash'
					size='30px'
					url={trashImage}
					left='40px'
					top='28px'
					onClick={e => {
						e.stopPropagation();
						setImage(undefined);
						state && setState({
							...state,
							color: undefined
						});
					}}
				/>
				}
			</SystemSettingsDropdownWrapper>

			<SystemSettingsDropdownWrapper>
				{translateToNode('name')}
				<Input
					mode={errorState.name ? InputMode.Error : undefined}
					inputStyle={INPUT_STYLE_SETTINGS}
					name='my_name'
					type='text'
					value={state?.name}
					onChange={v => state && setState({ ...state, name: v.trim() })}
				/>
			</SystemSettingsDropdownWrapper>

			<SystemSettingsDropdownWrapper>
				{translateToNode('email')}
				<Input
					mode={errorState.email ? InputMode.Error : undefined}
					inputStyle={INPUT_STYLE_SETTINGS}
					name='my_email'
					type='text'
					value={state?.email}
					onChange={v => state && setState({ ...state, email: v.trim() })}
				/>
			</SystemSettingsDropdownWrapper>

			<SystemSettingsDropdownWrapper relative>
				{translateToNode('address')}
				<InputAddress
					name=''
					type='text'
					inputStyle={INPUT_STYLE_SETTINGS}
					optionWidth={INPUT_STYLE_SETTINGS.containerWidth}
					value={state?.address}
					onChange={res => {
						state && setState({
							...state,
							latitude: res.latitude,
							longitude: res.longitude,
							city: res.city,
							country: res.country,
							address: res.street_number ? res.street_number + ' ' + res.route : res.route,
							postal_code: res.postcode
						});
					}}
				/>
				{state?.address && delAddress}
			</SystemSettingsDropdownWrapper>

			<SystemSettingsDropdownWrapper relative>
				{translateToNode('city')}
				<Input
					disabled
					inputStyle={INPUT_STYLE_SETTINGS}
					name='my_city'
					type='text'
					value={state?.city}
				/>
			</SystemSettingsDropdownWrapper>

			<SystemSettingsDropdownWrapper relative>
				{translateToNode('post_code')}
				<Input
					disabled
					inputStyle={INPUT_STYLE_SETTINGS}
					name='my_post_code'
					type='text'
					value={state?.postal_code}
				/>
			</SystemSettingsDropdownWrapper>

			<SystemSettingsDropdownWrapper relative>
				{translateToNode('country')}
				<Input
					disabled
					inputStyle={INPUT_STYLE_SETTINGS}
					name='my_country'
					type='text'
					value={state?.country}
				/>
			</SystemSettingsDropdownWrapper>

			<SystemSettingsCheckBoxWrapper>
				<Checkbox isActive={state?.use_address_as_default_map_center ?? false} onClick={() => state && setState({ ...state, use_address_as_default_map_center: !state.use_address_as_default_map_center })} size='20px' />
				<Translate id='use_address_as_starting_point_on_the_map' />
			</SystemSettingsCheckBoxWrapper>

			<SystemSettingsCheckBoxWrapper>
				<Checkbox isActive={state?.use_address_as_default_optimization_start ?? false} onClick={() => state && setState({ ...state, use_address_as_default_optimization_start: !state.use_address_as_default_optimization_start })} size='20px' />
				<Translate id='use_address_as_starting_point_on_the_route_optimizations' />
			</SystemSettingsCheckBoxWrapper>

			<SystemSettingsCheckBoxWrapper>
				<Checkbox isActive={state?.use_address_as_default_optimization_end ?? false} onClick={() => state && setState({ ...state, use_address_as_default_optimization_end: !state.use_address_as_default_optimization_end })} size='20px' />
				<Translate id='use_address_as_end_point_on_the_route_optimizations' />
			</SystemSettingsCheckBoxWrapper>
		</SystemSettingsWrapper>
	</>;
}