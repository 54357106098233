import error from 'images/loader/error.svg';
import success from 'images/loader/success.svg';
import * as React from 'react';
import styled from 'styled-components';
import { LoadingStateEnum } from '../../../containers_v2/import/model';
import LoaderContext from '../loader/LoaderContext';
import { DefaultImage } from './GlobalImage';
import { BlueSidely } from './Utils';

interface LoaderProps {
  width?: string
  center?: boolean
  centerHorizontal?: boolean
}

const LoaderContainer = styled.div<LoaderProps>`
    animation: lds-dual-ring 1.2s linear infinite;
    width: ${p => p.width ? p.width : '80px'};
    height: ${p => p.width ? p.width : '80px'};;
    position: relative;
    transform: ${p => p.center ? 'translate(-50%, -50%)' : 'none'};
    left: ${p => p.center ? '50%' : '0'};
    top: ${p => p.center ? '50%' : '0'};
    content: ' ';
    border-radius: 50%;
    border: ${p => p.width ? `calc(${p.width} / 16)` : '5px'} solid;
    border-color: ${BlueSidely} transparent ${BlueSidely} transparent;

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
    ${
	p =>
		p.centerHorizontal
			? `
        transform: translate(-50%);
        left: 50%;
        `
			: ''
}
`;

function Loader(props: {
  id?: string
  width?: string
  center?: boolean
  centerHorizontal?: boolean
}): JSX.Element {
	const { loaderManager, loaderRegister } = React.useContext(LoaderContext);
	const [status, setStatus] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);

	React.useEffect(() => {
		if (props.id) {
			loaderRegister(props.id);
			setStatus(LoadingStateEnum.LOADED);
		}
	}, []);

	React.useEffect(() => {
		if (props.id) {
			const load = loaderManager.find(l => l.id === props.id);
			if (load != null) {
				setStatus(load.status);
			}
		}
	}, [JSON.stringify(loaderManager)]);

	if (status === LoadingStateEnum.LOADING) {
		return (
			<LoaderContainer width={props.width} center={props.center} centerHorizontal={props.centerHorizontal} />
		);
	}

	if (status === LoadingStateEnum.SUCCESS) {
		return (<DefaultImage src={success} width={props.width} height={props.width} />);
	}

	if (status === LoadingStateEnum.ERROR) {
		return (<DefaultImage src={error} width={props.width} height={props.width} />);
	}

	return (
		<></>
	);
}

export {
	Loader
};
