import user from 'images/icons/user.svg';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import * as moment from 'moment';
import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import DatePicker from 'react-datepicker';
import { getTranslate, Translate } from 'react-localize-redux';
import { useStore } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, CustomInput, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import swal from 'sweetalert2';
import PageLoader from '../../components/PageLoader';
import SelelectWithSearch, { ValueLabel } from '../../components_v2/search/SearchWithFilter';
import storeLang from '../../helpers/storeLang';
import { customStyles } from '../../helpers/styleSelect';
import { BlueSidely, RedSidely } from '../../styles/global/css/Utils';
import {
	createEvent,
	editEvent,
	getEventById,
	getEventStatuses,
	getEventTypes,
	getfilteredCompanies,
	selectCompany,
	selectCompanyById,
	selectContact,
	selectInCharge
} from './EventsApi';
import { LegacyEvent } from './model/Model';
import calendarImage from 'images/icon/small_calendar2.svg';
import timeIcon from 'images/icons/timeIcon.svg';
import penUrl from 'images/ui_icon/pen_black.svg';
import penBlueUrl from 'images/ui_icon/pen_blue.svg';
import trashUrl from 'images/ui_icon/trash_black.svg';
import trashBlueUrl from 'images/ui_icon/trash_blue.svg';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { getLanguage } from '../reports/utils';

const SEARCH_LIMIT = 20;

export enum ModalMode {
  New = 'new',
  View = 'view',
  Edit = 'edit'
}

export type ModalFields =
	'inCharge'
	| 'type'
	| 'title'
	| 'allDay'
	| 'startDate'
	| 'endDate'
	| 'companies'
	| 'contacts'
	| 'status'
	| 'description'

type loadingState = 'loading' | 'loaded' | 'error' | null

const getLabel = (label: string, mandatory: boolean) => (
	<div className="form-group text-right mt-2">
		<label htmlFor="#">
			{mandatory && <span className="text-danger text-capitalize">* </span>}
			<Translate id={label} />
		</label>
	</div>
);

type DetailList<T = any> = {
	details: T,
	list: T[]
}

export function ModalEventForm(props: {
  isOpen: boolean
  toggleModal: () => void
  mode: ModalMode
  onDeleteData?: (id: number) => void
  id?: number
  defaultStart: Date | null
  defaultEnd: Date | null
  refresh?: () => void
  company?: number
  defaultEvent?: LegacyEvent
  hide?: ModalFields[]
  setCallBack?: (p: undefined | 'error' | 'success') => void
  readonly?: boolean
}) {
	moment.locale(getLanguage());

	const currState = useStore().getState();
	const translate = getTranslate(storeLang.getState().localize);
	const [loadingState, setLoadingState] = React.useState<loadingState>('loading');
	const [mode, setMode] = React.useState<ModalMode>(props.mode);
	const [allDay, setAllDay] = React.useState<boolean>(false);
	const [eventDetails, setEventDetails] = React.useState<any>();
	const [title, setTitle] = React.useState<string>();
	const [eventTypes, setEventTypes] = React.useState<DetailList>({ details: null, list: [] });
	const [eventStatuses, setEventStatuses] = React.useState<DetailList>({ details: null, list: [] });
	const [inCharge, setInCharge] = React.useState<DetailList>({ details: null, list: [] });
	const [contacts, setContacts] = React.useState<DetailList>({ details: null, list: [] });
	const [companies, setCompanies] = React.useState<DetailList>({ details: null, list: [] });
	const [icons, setIcons] = React.useState({ penUrl, trashUrl });
	const [startDate, setStartDate] = React.useState<Date | null>((props.defaultStart != null) ? props.defaultStart : new Date());
	const [endDate, setEndDate] = React.useState<Date | null>((props.defaultEnd != null) ? props.defaultEnd : new Date());
	const [globalError, setGlobalError] = React.useState<boolean>(false);
	const [buttonError, setButtonError] = React.useState<boolean>(false);
	const [description, setDescription] = React.useState<string>();
	const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();
	const [waitForApi, setWaitForApi] = React.useState<number>(0);

	const {
		onDeleteData,
		id
	} = props;

	const readOnly = mode == ModalMode.View;

	React.useEffect(() => {
		if (currState.user.isLoading == true) {
			setLoadingState('loading');
		} else if (loadingState) {
			setLoadingState(null);
		}
	}, [currState.user.isLoading]);

	React.useEffect(() => {
		const apis = async() => {
			if ((props.defaultEvent != null) && props.defaultEvent.clientCompanyId) {
				const { defaultEvent } = props;
				try {
					let eventCompany: null | ValueLabel = null;
					if (defaultEvent.clientCompanyId !== undefined && defaultEvent.clientCompanyId !== null) {
						const company_res = (await selectCompanyById(defaultEvent.clientCompanyId)).data.data;
						eventCompany = { value: company_res.id, label: company_res.name };
					}
					setCompanies({ ...companies, details: eventCompany });
				} catch (e) {
					console.log(e);
				}
				selectContact(defaultEvent.clientCompanyId)
					.then(contacts => {
						const contactList = contacts.data.data.map(e => { return { value: e.id, label: `${e.firstName} ${e.lastName}` }; });
						setContacts({ details: contactList.find(e => e.value == defaultEvent.contactId), list: contactList });
					});
				setEventTypes({ ...eventTypes, details: eventTypes.list.find(e => e.value == defaultEvent.eventTypeId) });
				setEventStatuses({ ...eventStatuses, details: eventStatuses.list.find(e => e.value == defaultEvent.eventStatusId) });
				setInCharge({ ...inCharge, details: inCharge.list.find(e => e.value == defaultEvent.incharge) });
				setTitle(defaultEvent.title);
				setStartDate(defaultEvent.startDate);
				setEndDate(defaultEvent.endDate);
				setDescription(defaultEvent.description);
				setAllDay(defaultEvent.allDay);
			}
		};
		apis();
	}, [props.defaultEvent]);

	React.useEffect(() => {
		if (!loadingState) {
			setLoadingState('loading');
			const apis = async() => {
				try {
					const types = await getEventTypes();
					const typeList = types.data.data.map(e => { return { label: translate('event.' + e.name) as string, value: e.id }; });
					setEventTypes({
						details: (props.defaultEvent != null) ? typeList.find(e => e.value == props.defaultEvent?.eventTypeId) : null,
						list: typeList
					});
					const statuses = await getEventStatuses();
					const statusesList = statuses.data.data.map(e => { return { label: translate(e.name) as string, value: e.id }; });
					setEventStatuses({
						list: statusesList,
						details: statusesList.find(e => e.value == ((props.defaultEvent != null) ? props.defaultEvent.eventStatusId : 1))
					});
					const incharges = await selectInCharge(currState.user.current.id, currState.user.current.companyId, '');

					const active_in_charge = incharges.data.data
						.filter(e => e.status == true)
						.map(e => {
							return {
								value: e.id,
								label: (
									<div>
										{' '}
										<img
											src={e.photoUrl || user}
											alt=""
											className="img-xs rounded-circle image-thumbnail mr-2"
										/>{' '}
										{`${e.name} ${e.id == currState.user.current.id ? translate('calendar.you') : ''}`}
										{e.status == false && <span style={{ opacity: '50%' }}>{e.name} {e.id == currState.user.current.id ? translate('calendar.you') : ''}</span>}                                </div>
								)
							};
						});

					const inactive_in_charge = incharges.data.data
						.filter(e => e.status == false)
						.map(e => {
							return {
								value: e.id,
								label: (
									<div>
										{' '}
										<img
											src={e.photoUrl || user}
											alt=""
											className="img-xs rounded-circle image-thumbnail mr-2"
										/>{' '}
										<span style={{ opacity: '50%' }}>{e.name} {e.id == currState.user.current.id ? translate('calendar.you') : ''}</span>                                </div>
								)
							};
						});

					const inChargeList = active_in_charge.concat(inactive_in_charge);

					setInCharge({
						list: inChargeList,
						details: inChargeList.find(e => e.value == ((props.defaultEvent != null) ? props.defaultEvent.incharge : currState.user.current.id))
					});
					if (props.company || props.defaultEvent?.clientCompanyId) {
						const companies = await selectCompany();
						const companiesList = companies.data.data.map(e => {
							return {
								value: e.id,
								label: e.name
							};
						});
						setCompanies({
							list: companiesList,
							details: props.company || props.defaultEvent?.clientCompanyId ? companiesList.find(e => e.value == props.company || props.defaultEvent?.clientCompanyId) : null
						});
						const contacts = await selectContact(props.company ? props.company : props.defaultEvent?.clientCompanyId);
						setContacts({ details: null, list: contacts.data.data.map(e => { return { value: e.id, label: `${e.firstName} ${e.lastName}` }; }) });
					}
					setLoadingState('loaded');
				} catch (e) {
					console.log(e);
					setLoadingState('error');
				}
			};
			apis();
		}
	}, [loadingState]);

	React.useEffect(() => {
		if (props.company) {
			const apis = async(companyId: number) => {
				try {
					const contacts = await selectContact(companyId);
					setContacts({ details: null, list: contacts.data.data.map(e => { return { value: e.id, label: `${e.firstName} ${e.lastName}` }; }) });
					setCompanies({ ...companies, details: { value: companyId, label: '' } });
				} catch (e) {
					console.log(e);
				}
			};
			apis(props.company);
		}
	}, [props.company]);

	React.useEffect(() => {
		if (loadingState == 'loading') {
			setTimeout((local_waitForApi) => {
				setWaitForApi(local_waitForApi + 1);
			}, 500, waitForApi);
		} else if (props.id) {
			setLoadingState('loading');
			resetModal();
			getEventById(props.id)
				.then(async(res) => {
					const data = res.data.data;
					let eventCompany: null | ValueLabel = null;
					if (data.clientCompanyId !== undefined && data.clientCompanyId !== null) {
						const company_res = (await selectCompanyById(data.clientCompanyId)).data.data;
						eventCompany = { value: company_res.id, label: company_res.name };
					}
					setEventDetails(data);
					setEventTypes({
						...eventTypes,
						details: eventTypes.list.find(e => e.value == data.eventTypeId)
					});
					setEventStatuses({
						...eventStatuses,
						details: eventStatuses.list.find(e => e.value == data.eventStatusId)
					});
					setInCharge({
						...inCharge,
						details: inCharge.list.find(e => e.value == data.incharge)
					});
					setCompanies({
						...companies,
						details: eventCompany
					});
					const contacts = (await selectContact(data.clientCompanyId)).data.data.map(e => { return { value: e.id, label: `${e.firstName} ${e.lastName}` }; });
					setContacts({
						list: contacts,
						details: contacts.find(e => e.value == data.contactId)
					});
					const start = new Date(data.startDate);
					const end = new Date(data.endDate);
					setStartDate(start);
					setEndDate(end);
					setTitle(data.title);
					setDescription(data.description);
					if (end.getTime() - start.getTime() == 86340000) { // an entire day minus a minute
						setAllDay(true);
					}
					setLoadingState('loaded');
				})
				.catch(e => console.log(e));
		} else {
			resetModal();
		}
	}, [props.id, waitForApi]);

	React.useEffect(() => {
		setMode(props.mode);
	}, [props.mode]);

	React.useEffect(() => {
		if ((props.defaultStart != null) && (props.defaultEnd != null)) {
			resetModal();
			if (props.defaultEnd.getTime() - props.defaultStart.getTime() == 86400000) { // an entire day
				setAllDay(true);
				props.defaultEnd.setTime(props.defaultEnd.getTime() - 1000 * 60); // get time minus a minute
			}
		}
		setStartDate(props.defaultStart);
		setEndDate(props.defaultEnd);
	}, [props.defaultStart, props.defaultEnd]);

	function resetModal() {
		setEventDetails(null);
		setEventTypes({
			...eventTypes,
			details: null
		});
		setEventStatuses({
			...eventStatuses,
			details: eventStatuses.list.find(e => e.value == 1)
		});
		setInCharge({
			...inCharge,
			details: currState.user.current ? inCharge.list.find(e => e.value == currState.user.current.id) : null
		});
		setCompanies({
			...companies,
			details: null
		});
		setContacts({
			...contacts,
			details: null
		});
		setTitle('');
		setDescription('');
		setAllDay(false);
		setStartDate(null);
		setEndDate(null);
		setGlobalError(false);
		setButtonError(false);
		setMode(props.mode);
	}

	function deleteEvent(id: number, name: string) {
		swal({
			title: translate('global.fire.alert_title').toString(),
			text: translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${name}'`),
			type: 'warning',
			confirmButtonText: translate('global.fire.confirm_message').toString(),
			confirmButtonColor: RedSidely
		})
			.then(result => {
				if (result.value) {
					props.setCallBack?.(undefined);
					onDeleteData?.(id);
					resetModal();
				}
			});
	}

	function handleDates(rawDate: Date | null, key: string, time?: boolean) {
		let date: Date | null;
		if (key == 'start') {
			if (startDate != null) {
				date = new Date(startDate.getTime());
			} else {
				date = rawDate;
			}
		} else {
			if (endDate != null) {
				date = new Date(endDate.getTime());
			} else {
				date = rawDate;
			}
		}
		if (time) {
			if (rawDate != null) { date?.setHours(rawDate.getHours(), rawDate.getMinutes()); } else {
				date?.setHours(0, 0);
			}
		} else {
			if (rawDate != null) { date?.setFullYear(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate()); } else {
				const today = new Date();
				date?.setFullYear(today.getFullYear(), today.getMonth(), today.getDate());
			}
		}
		const diff = (startDate != null) && (endDate != null) ? endDate.getTime() - startDate.getTime() : null;
		if (key == 'start') {
			if ((startDate != null) && (date != null) && (endDate != null) && date.getTime() >= endDate.getTime()) {
				setEndDate(new Date(date.getTime() + (diff ?? 0)));
			} else if ((date != null) && (endDate != null) && date.getTime() >= endDate.getTime()) {
				const newEnd = new Date(date);
				newEnd.setDate(newEnd.getDate() + 1);
				newEnd.setHours(endDate.getHours(), endDate.getMinutes());
				setEndDate(newEnd);
			}
			setStartDate(date);
		} else {
			if ((startDate != null) && (date != null) && (endDate != null) && date.getTime() <= startDate.getTime()) {
				setStartDate(new Date(date.getTime() - (diff ?? 0)));
			} else if ((date != null) && (startDate != null) && date.getTime() <= startDate.getTime()) {
				const newstart = new Date(date);
				newstart.setDate(newstart.getDate() - 1);
				newstart.setHours(startDate.getHours(), startDate.getMinutes());
				setStartDate(newstart);
			}
			setEndDate(date);
		}
	}

	function asAnError() {
		return (
			!inCharge.details ||
			!eventTypes.details ||
			(startDate == null) ||
			((endDate == null) && !allDay) ||
			!eventStatuses.details);
	}

	function getDate(date, time) {
		const data = moment(
			`${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`
		);
		return data;
	}

	function launchToast(message: string) {
		toast.error(translate(message).toString(), {
			position: 'top-right',
			autoClose: 10000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}

	function buildTitle(): string {
		if ((title?.trim().length ?? 0) > 0) { return title ?? ''; }
		const currentEventType = eventTypes.list?.find(e => e.value == eventTypes.details?.value)?.label;
		const currentCompanyName = companies.list?.find(e => e.value == companies.details?.value)?.label;
		if (currentCompanyName?.length > 0) {
			return currentEventType + ' -- ' + currentCompanyName;
		}
		return currentEventType ?? '';
	}

	function handleSubmit() {
		if (asAnError()) {
			setGlobalError(true);
			setButtonError(true);
			clearTimeout(localTimeout);
			setLocalTimeout(setTimeout(() => {
				setButtonError(false);
			}, 750));
		} else {
			const formData = new FormData();
			const start = getDate(
				startDate,
				allDay ? moment('00:00', 'HH:mm') : startDate
			);
			const end = getDate(
				allDay ? startDate : endDate,
				allDay ? moment('23:59:59', 'HH:mm') : endDate
			);
			const startdate = moment(
				// @ts-expect-error moment
				`${moment(start._d)
					.utc()
					.format('YYYY-MM-DD HH:mm')}`
			);
			const enddate = moment(
				// @ts-expect-error moment
				`${moment(end._d)
					.utc()
					.format('YYYY-MM-DD HH:mm')}`
			);
			eventTypes.details && formData.append('eventTypeId', eventTypes.details?.value || '');
			eventStatuses.details && formData.append('eventStatusId', eventStatuses.details?.value || '');
			formData.append('title', buildTitle());
			companies.details && formData.append('clientCompanyId', companies.details?.value || '');
			contacts.details && formData.append('contactId', contacts.details?.value || '');
			formData.append('description', description ?? '');
			inCharge.details && formData.append('incharge', inCharge.details?.value || '');
			// @ts-expect-error MOMENT
			formData.append('startDate', startdate._i);
			// @ts-expect-error MOMENT
			formData.append('endDate', enddate._i);
			if (mode === ModalMode.New) {
				setLoadingState('loading');
				createEvent(formData)
					.then(() => {
						setLoadingState('loaded');
						setMode(props.mode);
						props.setCallBack?.('success');
						props.toggleModal();
						props.refresh?.();
					})
					.catch((e) => {
						console.log(e);
						setLoadingState('error');
						launchToast('calendar.error_api_try_later');
						props.setCallBack?.('error');
					});
			} else if (id) {
				setLoadingState('loading');
				editEvent(formData, id)
					.then(() => {
						setLoadingState('loaded');
						setMode(props.mode);
						props.setCallBack?.('success');
						props.toggleModal();
						props.refresh?.();
					})
					.catch((e) => {
						console.log(e);
						setLoadingState('error');
						props.setCallBack?.('error');
						launchToast('calendar.error_api_try_later');
					});
			}
		}
	}

	// change icons color when hovered
	function onHover(pen: string | null, trash: string | null) {
		setIcons({
			penUrl: pen ? pen.includes('blue') ? penBlueUrl : penUrl : icons.penUrl,
			trashUrl: trash ? trash.includes('blue') ? trashBlueUrl : trashUrl : icons.trashUrl
		});
	}
	const titleModal = (
		<span>
			<Translate id={mode} />
			<Translate id="event" />
		</span>
	);

	if (!props.isOpen) { return <></>; }

	return (
		<div>
			<Modal
				isOpen={props.isOpen}
				toggle={() => {
					setMode(props.mode);
					props.setCallBack?.(undefined);
					props.toggleModal();
				}}
				centered
			>
				<React.Fragment>
					<ModalHeader
						toggle={() => {
							setMode(props.mode);
							props.setCallBack?.(undefined);
							props.toggleModal();
						}}
						className="text-capitalize"
					>
						{mode === ModalMode.View ? eventDetails && <div style={{ paddingRight: '60px' }}>{eventDetails.title}</div> : titleModal}
						{!props.readonly &&
							<div className="positionButton d-flex flex-row">
								{mode === ModalMode.View && (
									<button
										className="btn-edit-modal"
										onClick={() => setMode(ModalMode.Edit)}
									>
										<img
											src={icons.penUrl}
											alt=""
											width="13px"
											height="13px"
											className="mr-2"
											onMouseEnter={() => onHover('pen_blue.svg', null)}
											onMouseLeave={() => onHover('pen_black.svg', null)}
										/>
									</button>
								)}

								{mode !== ModalMode.Edit && (
									<button
										className="btn-edit-modal"
										onClick={() => {
											id && deleteEvent(id, eventDetails.title);
										}}
									>
										<img
											src={icons.trashUrl}
											alt=""
											width="13px"
											height="13px"
											onMouseEnter={() => onHover(null, 'trash_blue.svg')}
											onMouseLeave={() => onHover(null, 'trash_black.svg')}
										/>
									</button>
								)}
							</div>
						}
					</ModalHeader>
					<ModalBody>
						<form
							className="forms-sample background-form"
							style={{ boxShadow: 'none' }}
						>
							<React.Fragment>
								{loadingState == 'loading'
									? <PageLoader />
									: <div className="row">
										<div className="col-md-12">
											<div className="row">
												<div className="col-md-11">
													{!props.hide?.includes('inCharge') &&
														<ModalSelect
															label={<Translate id="in charge" />}
															readOnly={readOnly}
															requiredStar={true}
															placeholder={translate('calendar.pick_in_charge')}
															options={inCharge.list}
															details={inCharge.details}
															onChange={(value) => setInCharge({ ...inCharge, details: value })}
															error={globalError && !inCharge.details ? 'Fill the field' : null}
														/>
													}
													{!props.hide?.includes('type') &&
														<ModalSelect
															label={<Translate id="type" />}
															readOnly={readOnly}
															requiredStar={true}
															placeholder={translate('pick type')}
															options={eventTypes.list}
															details={eventTypes.details}
															onChange={(value) => setEventTypes({ ...eventTypes, details: value })}
															error={globalError && !eventTypes.details ? 'Fill the field' : null}
														/>
													}
													{!props.hide?.includes('title') &&
														<ModalInput
															label={<Translate id="title" />}
															readOnly={readOnly}
															requiredStar={false}
															placeholder={translate('enter title')}
															details={title ?? ''}
															onChange={setTitle}
															error={null}
														/>
													}
													{!props.hide?.includes('allDay') &&
														<ModalSwitch
															label={<Translate id="All day" />}
															readOnly={readOnly}
															requiredStar={false}
															checked={allDay}
															onChange={() => setAllDay(!allDay)}
														/>
													}
													{!props.hide?.includes('startDate') &&
														<div className="d-flex">
															<div className="col-3">
																{getLabel('start', true)}
															</div>
															<div className="col-5 px-0">
																<ModalDatePicker
																	readOnly={readOnly}
																	currDate={startDate}
																	onChange={date => handleDates(date, 'start')}
																	error={(startDate == null) && globalError ? 'Fill the field' : null}
																/>
															</div>
															{!allDay && <div className="col-4 pl-0">
																<ModalTimePicker
																	readOnly={readOnly}
																	currDate={startDate}
																	onChange={date => handleDates(date, 'start', true)}
																	error={(startDate == null) && globalError ? 'Fill the field' : null}
																/>
															</div>}
														</div>
													}
													{!props.hide?.includes('endDate') &&
														<div className="d-flex">
															<div className="col-3">
																{getLabel('end', true)}
															</div>
															<div className="col-5 px-0">
																<ModalDatePicker
																	readOnly={readOnly || allDay}
																	currDate={allDay ? startDate : endDate}
																	onChange={date => handleDates(date, 'end')}
																	error={(endDate == null) && globalError && !allDay ? 'Fill the field' : null}
																/>
															</div>
															{!allDay && <div className="col-4 pl-0">
																<ModalTimePicker
																	readOnly={readOnly}
																	currDate={endDate}
																	onChange={date => handleDates(date, 'end', true)}
																	error={(endDate == null) && globalError && !allDay ? 'Fill the field' : null}
																/>
															</div>}
														</div>
													}
													{!props.company && !props.hide?.includes('companies') &&
														<SelelectWithSearch
															onChange={async(value: ValueLabel | null) => {
																setCompanies({ ...companies, details: value });
																if (value != null) {
																	const contacts = await selectContact(value.value);
																	setContacts({ details: null, list: contacts.data.data.map(e => { return { value: e.id, label: `${e.firstName} ${e.lastName}` }; }) });
																} else {
																	setContacts({ details: null, list: [] });
																}
															}}
															details={companies.details}
															link={'/companies?id='}
															placeholder={translate('select_company')}
															label={<Translate id="company" />}
															readOnly={readOnly}
															options={companies.list}
															onSearchChange={async(search, offset) => {
																return await getfilteredCompanies(search, SEARCH_LIMIT, offset)
																	.then(res => {
																		const ret: boolean = res.data.length == 0 || res.data.length < SEARCH_LIMIT;
																		if (offset !== undefined) {
																			setCompanies({
																				...companies,
																				list: [...companies.list, ...res.data.map(company => {
																					return {
																						label: company.name,
																						value: company.id
																					};
																				})]
																			});
																		} else {
																			setCompanies({
																				...companies,
																				list: res.data.map(company => {
																					return {
																						label: company.name,
																						value: company.id
																					};
																				})
																			});
																		}
																		return ret;
																	})
																	.catch(error => {
																		console.log(error);
																		return false;
																	});
															}}
														/>
													}
													{!props.hide?.includes('contacts') &&
														<ModalSelect
															label='Contact'
															readOnly={readOnly}
															requiredStar={false}
															placeholder={translate('pick contact')}
															options={contacts.list}
															details={contacts.details}
															onChange={(value) => setContacts({ ...contacts, details: value })}
															isClearable={true}
															error={null}
														/>
													}
													{!props.hide?.includes('status') &&
														<ModalSelect
															label={<Translate id="status" />}
															readOnly={readOnly}
															requiredStar={true}
															placeholder={translate('pick_status')}
															options={eventStatuses.list}
															details={eventStatuses.details}
															onChange={(value) => setEventStatuses({ ...eventStatuses, details: value })}
															error={globalError && !eventStatuses.details ? 'Fill the field' : null}
														/>
													}
													{!props.hide?.includes('description') &&
														<ModalDescription
															label="description"
															placeholder={translate('enter description')}
															requiredStar={false}
															readOnly={readOnly}
															onChange={setDescription}
															error={null}
															details={description ?? ''}
														/>
													}
												</div>
											</div>
										</div>
									</div>}
							</React.Fragment>
						</form>
					</ModalBody>
					<ModalFooter
						className={mode == ModalMode.View ? 'd-flex justify-content-start' : ''}
					>
						{mode === ModalMode.View
							? (
								<div>
									<p className="text-by word-break mb-0">
										{' '}
										<Translate id='by' />
										{' '}
										<span className="text-capitalize">
											{eventDetails && eventDetails.inchargeName}
										</span>
										{' '}
										<Translate id='on' />
										{' '}
										{moment(
											eventDetails && eventDetails.createdAt
										)?.format('LLL')}
									</p>
								</div>
							)
							: (
								<Button
									onClick={handleSubmit}
									style={{
										background: buttonError ? RedSidely : BlueSidely,
										color: '#FFFFFF',
										borderRadius: '5px'
									}}
								>
									{loadingState == 'loading' && <><Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>{' '}</>}
									<Translate id="save" />
								</Button>
							)}
					</ModalFooter>
				</React.Fragment>
			</Modal>
		</div>
	);
}

function ModalInput(props: { label, requiredStar: boolean, placeholder, readOnly: boolean, onChange: (p) => void, details: string, error: string | null }) {
	const { label, requiredStar, placeholder, readOnly, onChange, details, error } = props;
	return (
		<div>
			<div className="form-group row">
				<div className="col-md-3 text-right mt-2">
					<label className="text-capitalize">
						{requiredStar
							? (
								<span className="text-danger">* </span>
							)
							: (
								''
							)}
						{label}
					</label>
				</div>

				<div
					className={'my-auto col-md-9'}
				>
					<div className="row">
						<div className="col-12">
							<input
								placeholder={placeholder}
								type={'text'}
								readOnly={readOnly}
								onChange={value => onChange(value.target.value)}
								value={details}
								className={`form-control ${error ? 'focus-error' : ''}`}
							/>
						</div>
						<div className="col-12">
							{error && <span className="form-error">{error}</span>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function ModalSwitch(props: { label, requiredStar: boolean, readOnly: boolean, checked: boolean, onChange: (p) => void }) {
	const { label, requiredStar, readOnly, checked, onChange } = props;
	return (

		<FormGroup>
			<div className="row">
				<div className="col-md-3 text-right">
					<label htmlFor={'switchAll'}>
						{requiredStar
							? (
								<span className="text-danger">* </span>
							)
							: (
								''
							)}
						{label}
					</label>
				</div>
				<div className="col-md-5 mx-2">
					<div className="row">
						<div className="col-12">
							<CustomInput
								checked={checked}
								type="switch"
								id={'switchAll'}
								color="green"
								onChange={onChange}
								disabled={readOnly}
							/>
						</div>
					</div>
				</div>
			</div>
		</FormGroup>
	);
}

function ModalTimePicker(props: { readOnly: boolean, currDate: Date | null, onChange: (p) => void, error: string | null }) {
	const { readOnly, currDate, onChange, error } = props;
	return (
		<div className='form-group row' style={{ marginBottom: '0' }}>
			<DatePicker
				className={`form-control font-date ${error ? 'focus-error focus-error-select' : ''}`}
				selected={currDate}
				onChange={onChange}
				popperPlacement={'bottom'}
				popperClassName='custom-popper field-position'
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={15}
				timeCaption='Time'
				dateFormat='HH:mm'
				placeholderText='HH:mm'
				timeFormat="HH:mm"
				disabled={readOnly}
			/>
			<div className='icon-field'>
				<img
					src={timeIcon}
					style={{ width: '20px' }}
					alt=''
				/>
			</div>
			<div className="col-12">
				{error && <span className="form-error">{error}</span>}
			</div>
		</div>
	);
}

function ModalDatePicker(props: { readOnly: boolean, currDate: Date | null, onChange: (p) => void, error: string | null }) {
	const { readOnly, currDate, onChange, error } = props;
	return (
		<div className='form-group row'>
			<div className='col-12' style={{ position: 'relative' }}>
				<DatePicker
					className={`form-control font-date ${error ? 'focus-error focus-error-select' : ''}`}
					popperPlacement={'bottom'}
					selected={currDate}
					dateFormat='dd/MM/yyyy'
					placeholderText='DD/MM/YYYY'
					showYearDropdown
					onChange={onChange}
					disabled={readOnly}
					components={{ DropdownIndicator: (x) => x }}
				/>
				<div className='icon-field'>
					<img
						src={calendarImage}
						style={{ width: '20px' }}
						alt=''
					/>
				</div>
			</div>
			<div className="col-12">
				{error && <span className="form-error">{error}</span>}
			</div>
		</div>
	);
}

function ModalDescription(props: { label: string, requiredStar: boolean, readOnly: boolean, onChange: (p) => void, error: string | null, placeholder, details: string }) {
	const { label, requiredStar, readOnly, onChange, error, placeholder, details } = props;
	return (
		<div>
			<div className="form-group row">
				<div className="col-md-3 text-right">
					{getLabel(label, requiredStar)}
				</div>
				<div className="col-md-9">
					<div className="row">
						<div className="col-12">
							<TextareaAutosize
								placeholder={placeholder}
								// type={type}
								value={details}
								disabled={readOnly}
								readOnly={readOnly}
								className={`form-control ${error ? 'focus-error' : ''}`}
								rows={5}
								onChange={e => {
									// @ts-expect-error idk i made this a long time ago
									onChange(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="col-12">
						{error && <span className="form-error">{error}</span>}
					</div>
				</div>
			</div>
		</div>
	);
}

function ModalSelect(props: {
  label: string | ReactJSXElement
  readOnly: boolean
  requiredStar: boolean
  placeholder
  options: DropdownData[]
  details: any
  onChange: (p) => void
  isClearable?: boolean
  error: string | null
}): ReactJSXElement {
	const { requiredStar, readOnly, label, placeholder, options, details, onChange, isClearable, error } = props;
	return (
		<div>
			<div className="form-group row">
				<div className="col-md-3 text-right mt-2">
					<label htmlFor={'inputOwner'}>
						{requiredStar
							? (
								<span className="text-danger">* </span>
							)
							: (
								''
							)}
						{label}
					</label>
				</div>
				<div className={'col-md-9'}>
					<div className="row">
						<div className="col-12"
							style={{ fontWeight: 300 }}
						>
							<Select
								id={'inputOwner'}
								name={'select_in_charge'}
								className={` ${error ? 'focus-error-select' : ''}`}
								isDisabled={readOnly}
								styles={customStyles}
								placeholder={placeholder}
								optionClassName="needsclick"
								options={options}
								value={details}
								isClearable={isClearable}
								onChange={onChange}
								isSearchable={false}
							/>
						</div>
						<div className="col-12">
							{error && <span className="form-error">{error}</span>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
