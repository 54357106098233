import arrow_right_date_picker from 'images/icon/arrow_right_date_picker.svg';
import trash_black from 'images/ui_icon/trash_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../../../helpers/storeLang';
import { BlueSidely, DarkGreySidely2, FilterBlue, GreySidely } from '../../../../../styles/global/css/Utils';
import { LoadingStateEnum } from '../../../../import/model';
import { ModalState } from '../../../../products/model';
import { NoDataText } from '../../../style/Style';
import promotionIcon from 'images/icons/events/Promotion.svg';
import {
	Card,
	CardExtraRight,
	CardImage,
	CardLeft,
	CardMiddle,
	CardTextTd,
	CardTitleTh,
	ColorBar,
	Container,
	SeeMore,
	Trash
} from '../style/LinkedElementStyle';
import { ARefresh } from '../../../../../atoms/global/refresh';
import { Promotion } from '../../../../../containers_v2/promotions/model';
import PopupPromotion from '../../../../promotions/PopupPromotion';
import { deleteCompanyPromotion, postCompanyPromotion } from '../../action';
import { getPromotions } from '../../../../promotions/actions';
import { DropdownPopupSearch } from '../../../../../components_v2/dropdown/DropdownTagsCloud';
import { TagJSX } from '../../../../products/style';
import { translateToNode } from '../../../../../styles/global/translate';

interface DeletionState {
	isOpen: boolean
	promotion?: Promotion
}

export function sortPromotions(a: Promotion, b: Promotion): number {
	if (a.is_active && !b.is_active) return -1;
	if (!a.is_active && b.is_active) return 1;
	const { min: aMin } = a.periods.reduce((acc: { min?: moment.Moment }, p) => {
		const start = moment(p.start);
		if (!acc.min) acc.min = start;
		else acc.min < start ? acc.min : start;
		return { min: acc.min };
	}, { });
	const { min: bMin } = b.periods.reduce((acc: { min?: moment.Moment }, p) => {
		const start = moment(p.start);
		if (!acc.min) acc.min = start;
		else acc.min < start ? acc.min : start;
		return { min: acc.min };
	}, { });
	if (!aMin) return -1;
	if (!bMin) return 1;
	return aMin < bMin ? -1 : 1;
}

export function CompanyPromotionAdder(props: {
	isOpen: boolean,
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
	selectedPromotions: Promotion[],
	onCreate: () => void,
	companyId: number
}) {
	const [promotions, setPromotions] = React.useState<Promotion[]>([]);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);

	React.useEffect(() => {
		getPromotions().then(res => {
			setPromotions(res);
			setLoadingState(LoadingStateEnum.LOADED);
		});
	}, []);

	return <div style={{ position: 'relative' }}>
		<DropdownPopupSearch
			values={promotions.filter(p => !props.selectedPromotions.some(sp => sp.id === p.id)).sort(sortPromotions)}
			loadingState={loadingState}
			JSXValue={value => <TagJSX color={value.is_active ? (runningPromotion(value) ? BlueSidely : DarkGreySidely2) : GreySidely}>{value.name}</TagJSX>}
			top={5}
			left={-5}
			isOpen={props.isOpen}
			setIsOpen={props.setIsOpen}
			onChange={value => {
				setLoadingState(LoadingStateEnum.LOADING);
				postCompanyPromotion(props.companyId, value.id)
					.then(() => {
						setLoadingState(LoadingStateEnum.LOADED);
						props.onCreate();
					});
			}}
		/>
	</div>;
}

export function runningPromotion(promotion: Promotion) {
	const now = moment();
	return promotion.is_active && promotion.periods.some(p => moment(p.start) < now && moment(p.end) > now);
}

export default function Promotion(props: {
  clientCompanyId: number
  isExtend?: boolean
  onDisableClickOut: (value: boolean) => void
  onDelete: () => void,
  promotions: Promotion[],
  companyName?: string
}): JSX.Element {
	const DEFAULT_LIMIT = 3;
	const { promotions } = props;

	const [total, setTotal] = React.useState<number>(props.promotions.length);
	const [offset, setOffset] = React.useState<number>(DEFAULT_LIMIT);
	const [modalState, setModalState] = React.useState<ModalState<Promotion>>({ isOpen: false });

	const [deletionState, setDeletionState] = React.useState<DeletionState>({ isOpen: false });

	const refreshingAtom = useRecoilValue(ARefresh);

	const translate = getTranslate(storeLang.getState().localize);

	React.useEffect(() => {
		if (refreshingAtom.timeline !== undefined) addMore();
	}, [refreshingAtom.timeline]);


	function addMore(): void {
		setOffset(offset => offset + DEFAULT_LIMIT);
	}

	function confirmationDeletionPromotion(): void {
		if (!deletionState.promotion) {
			return;
		}
		deleteCompanyPromotion(props.clientCompanyId, deletionState.promotion.id).then(props.onDelete);
	}

	React.useEffect(() => {
		setTotal(props.promotions.length);
	}, [props.promotions]);

	const now = moment();

	return (
		(<Container>
			{
				promotions.length > 0
					? promotions.sort(sortPromotions).slice(0, offset).map(promotion => {
						const running = runningPromotion(promotion);
						return (
							(<Card key={`LinkedElementPromotion[${promotion.id}]`} isExtend={props.isExtend ?? false} onClick={() => {
								setModalState({ isOpen: true, data: promotion });
								props.onDisableClickOut(true);
							}}>
								<ColorBar color={running ? BlueSidely : 'none'} />
								<CardLeft>
									<CardImage url={promotionIcon} />
								</CardLeft>
								<CardMiddle width='80%'>
									<table>
										<thead>
											<tr>
												<CardTitleTh colSpan={3}>
													{promotion.name.trim()}
												</CardTitleTh>
											</tr>
										</thead>
										<tbody>
											{promotion.periods.map((period, i) => {
												const start = moment(period.start);
												const end = moment(period.end);
												const running = promotion.is_active && start < now && end > now;
												return <tr key={`Period[${promotion.id}][${i}]`}>
													<CardTextTd color={running ? BlueSidely : undefined}>{period.name.trim()}</CardTextTd>
													<CardTextTd color={running ? BlueSidely : undefined}>{start.format('L')}</CardTextTd>
													<CardTextTd color={running ? BlueSidely : undefined}><img src={arrow_right_date_picker} style={ { filter: running ? FilterBlue : undefined }}/></CardTextTd>
													<CardTextTd color={running ? BlueSidely : undefined}>{end.format('L')}</CardTextTd>
												</tr>;
											})}
										</tbody>
									</table>
								</CardMiddle>
								<CardExtraRight>
									<Trash src={trash_black} onClick={(event) => {
										event.stopPropagation();
										setDeletionState({ ...deletionState, isOpen: true, promotion });
									}} />
								</CardExtraRight>
							</Card>)
						);
					})
					: <NoDataText><Translate id='no_campagnes' /></NoDataText>
			}
			{
				offset < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			<PopupDeletion
				isOpen={deletionState.isOpen}
				records={0}
				translationKey=''
				customTitle={translate('campagne').toString()}
				customMessage={translateToNode('delete_liaison', { variables: [['NAME', props.companyName ?? ''], ['TO', deletionState.promotion?.name ?? '']] })}
				onClickOut={() => setDeletionState({ ...deletionState, isOpen: false })}
				onValidation={confirmationDeletionPromotion}
				noVerification
			/>
			<PopupPromotion
				isOpen={modalState.isOpen}
				setIsOpen={(isOpen: boolean) => setModalState({ ...modalState, isOpen })}
				promotion={modalState.data}
				readOnly
			/>
		</Container >)
	);
}
