import equal from 'deep-equal';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { AViews, AllViewTypes, DbView, DefaultView, NewView, ViewKey, deleteView, getViews, postView, putView } from '../../atoms/global/views';
import { DEFAULT_FILTER_RESULT } from '../../containers_v2/client-companies/CompaniesAndMapView';
import { SidebarButton } from '../../containers_v2/client-companies/style/Style';
import { TableContextType } from '../../containers_v2/contact/data/ContactContext';
import { ModalState } from '../../containers_v2/products/model';
import { Translate } from '../../styles/global/translate';
import { useFunctionState } from '../../utils/customHooks';
import { FilterResult } from '../filter/model/Model';
import { convertFilters } from '../filter/pages/FilterList';
import PopupDeletion from '../popup/PopupDeletion';
import PopupView, { ViewCreation } from './PopupView';
import ViewPicker from './ViewPicker';

export function ToolBarView<S extends ViewKey, T extends AllViewTypes<S>>(props: {
	children: React.ReactNode,
	localStorageKey: string,
	localStorageHiddenKey: string,
	viewsKey: S,
	context: TableContextType,
	hidden?: boolean,
	dataFormatter?: (data: DefaultView<S>) => AllViewTypes<S>,
	viewsFilters?: (views: DbView<T>) => boolean,
	onLoad?: () => void
}) {
	const { filterResult, sortColumns, hiddenColumns, setHiddenColumns, setSortColumns, setFilterResult, setLoaded } = props.context;
	const [views, setViews] = useRecoilState(AViews);
	const [isViewOpen, setIsViewOpen] = React.useState<boolean>(false);
	const [editedView, setEditedView] = React.useState<DbView<T> | undefined>();
	const [deletionModalState, setDeletionModalState] = React.useState<ModalState<DbView<T>>>({ isOpen: false });
	const filteredViews = React.useMemo(() => {
		if (!props.viewsFilters) return views[props.viewsKey] as DbView<T>[];
		return (views[props.viewsKey] as DbView<T>[])?.filter(props.viewsFilters);
	}, [views, props.viewsFilters]);
	const [selectedView, setSelectedView] = useFunctionState<DbView<T> | undefined>(
		filteredViews?.find(v => v.id.toString() === localStorage.getItem(props.localStorageKey)),
		(p: { newValue: DbView<T> | undefined }) => {
			if (p.newValue) {
				localStorage.setItem(props.localStorageKey, p.newValue.id.toString());
			} else {
				localStorage.removeItem(props.localStorageKey);
			}
			return p.newValue;
		}
	);

	const saveView = React.useCallback(async(v: ViewCreation) => {
		const rawData: DefaultView<S> = {
			filters: filterResult ?? DEFAULT_FILTER_RESULT,
			columns: sortColumns.map(c => ({
				column: c,
				hidden: hiddenColumns.includes(c)
			})),
			name: v.name,
			type: props.viewsKey
		};
		const data: NewView<S> = {
			data: props.dataFormatter?.(rawData) ?? (rawData as AllViewTypes<S>),
			public: v.public
		};
		await (v.id ? putView(v.id, data) : postView(data));
		getViews().then(newViews => {
			setSelectedView((newViews[props.viewsKey] as DbView<T>[]).find(nv => v.id === nv.id || !filteredViews?.find(v => v.id == nv.id)));
			setViews(newViews);
		});
	}, [filteredViews, filterResult, hiddenColumns, sortColumns]);

	React.useEffect(() => {
		const view = filteredViews?.find(v => v.id.toString() === localStorage.getItem(props.localStorageKey));
		setSelectedView(view);
		if (view) {
			setFilterResult(view.data.filters);
			const columns = view?.data.columns ?? JSON.parse(localStorage.getItem(props.localStorageHiddenKey) || '[]');
			if (columns) {
				setHiddenColumns(columns.filter(l => l.hidden).map(l => l.column));
				setSortColumns(columns.map(l => l.column));
			}
		}
		props.onLoad?.();
		setLoaded(true);
	}, [filteredViews]);

	const onDelete = React.useCallback((view: DbView<T>) => view && setDeletionModalState({ isOpen: true, data: view }), []);

	if (props.hidden) return props.children;
	return <>
		<div>
			<ViewPicker<T>
				type={props.viewsKey}
				noBorder
				selectedView={selectedView}
				views={filteredViews}
				onViewChange={view => {
					setSelectedView(view);
					let filters: FilterResult;
					if (view) {
						filters = { ...view.data.filters, formatted: convertFilters(view.data.filters.values) };
					} else {
						filters = DEFAULT_FILTER_RESULT;
					}
					setFilterResult(filters);
					const columns = view?.data.columns ?? JSON.parse(localStorage.getItem(props.localStorageHiddenKey) || '[]');
					if (columns) {
						setHiddenColumns(columns.filter(l => l.hidden).map(l => l.column));
						setSortColumns(columns.map(l => l.column));
					}
				}}
				onDelete={onDelete}
				onCreate={() => setIsViewOpen(true)}
				setEditedView={setEditedView}
				onEdit={() => setIsViewOpen(true)}
			/>
			<PopupDeletion
				records={1}
				translationKey='view'
				noVerification
				isOpen={deletionModalState.isOpen}
				onClickOut={() => setDeletionModalState({ isOpen: false })}
				onValidation={() => {
					if (deletionModalState.data) {
						deleteView(deletionModalState.data.id).then(_ => getViews().then(setViews));
					}
				}}
			/>
			<PopupView
				isOpen={isViewOpen}
				view={editedView ?? selectedView}
				onClickOut={() => {setIsViewOpen(false); setEditedView(undefined);}}
				onValidation={(v) => {saveView(v).then(_ => setIsViewOpen(false)); setEditedView(undefined);}}
			/>
		</div>
		{props.children}
		{(!equal(filterResult?.formatted, selectedView?.data.filters.formatted ?? DEFAULT_FILTER_RESULT.formatted)
		|| !equal(hiddenColumns, selectedView?.data.columns?.filter(l => l.hidden).map(l => l.column) ?? [])
		|| !equal(sortColumns, selectedView?.data.columns?.map(l => l.column) ?? [])
		) && <SidebarButton onClick={() => setIsViewOpen(true)}>
			<Translate id='save_view' />
		</SidebarButton>}
	</>;
}
