import * as React from 'react';
import { BodyRow } from './style/Style';
import { Row } from 'react-table';
import { FreezePosition } from './Table';
import { TableCellToolTipProps } from './TableCellToolTip';

export default function Row<T extends object>(props: {
  i: number
  row: Row<T>
  isScrollable: boolean
  setSelected
  selected
  showFreezeColumnLeft: boolean
  showFreezeColumnRight: boolean
  onCellClicked
  data
  getFixedBody: (row: Row<T>, id: string, position?: FreezePosition) => number
  default_width
  DEFAULT_FIXED_WIDTH
  columnWidths
  noBorder?: boolean
  setHovered: (props: TableCellToolTipProps | undefined) => void,
  isIdSelected?: (row?: T) => boolean,
}) {
	const {
		i,
		row,
		setSelected,
		selected,
		showFreezeColumnLeft,
		showFreezeColumnRight,
		onCellClicked,
		data,
		getFixedBody,
		DEFAULT_FIXED_WIDTH,
		isScrollable,
		noBorder
	} = props; 

	return <BodyRow key={`BodyRow[${i}]`} noBorder={noBorder} depth={row.depth}>
		{row.cells.map((cell, j) => {
			const BodyCell = React.lazy(async() => await import('./bodyCell'));
			return <BodyCell
				key={`BodyCell[${i}][${j}]`}
				isIdSelected={props.isIdSelected}
				i={i}
				row={row}
				isScrollable={isScrollable}
				setSelected={setSelected}
				selected={selected}
				showFreezeColumnLeft={showFreezeColumnLeft}
				showFreezeColumnRight={showFreezeColumnRight}
				onCellClicked={onCellClicked}
				data={data}
				getFixedBody={getFixedBody}
				default_width={props.default_width}
				DEFAULT_FIXED_WIDTH={DEFAULT_FIXED_WIDTH}
				j={j}
				cell={cell}
				columnWidths={props.columnWidths}
				noBorder={noBorder}
				setHovered={props.setHovered}
			/>;
		})}
	</BodyRow>;
}
