import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';

class PropHeader extends Component {
  state = {};

  render() {
    return (
      <div>
        <p className="m-0">Field Type</p>
        <select className="form-control mb-2">
          <option value="Header">Header</option>
        </select>
        <p className="m-0">Field Name</p>
        <TextAreaAutosize
          type="text"
          className="form-control"
          defaultValue={this.props.element.field_name}
          onBlur={this.props.updateElement.bind(this)}
          onChange={this.props.editElementProp.bind(
            this,
            'field_name',
            'value'
          )}
        />

        <p className="m-0 mt-5">System Field</p>
        <TextAreaAutosize
          type="text"
          className="form-control"
          defaultValue={this.props.element.system_field_name}
          onBlur={this.props.updateElement.bind(this)}
          disabled={this.props.isEdit}
          onChange={this.props.editElementProp.bind(
            this,
            'system_field_name',
            'value'
          )}
        />
      </div>
    );
  }
}

export default PropHeader;
