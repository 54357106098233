/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {Field} from 'redux-form';
// import { required } from '../../../../../components/FieldModal';
import {get} from 'lodash';
import UploadMultipleImage from '../../../components/UploadMultipleImage';
import UploadImage from '../../../components/UploadImage';
import {fieldName} from '../services/fieldGetter';
import Delayed from '../../../../../components/Delayed';

const UploadMultipleImageRender = props => (
  <Delayed waitBeforeShow={1000}>
    <UploadMultipleImage {...props} />
  </Delayed>
);

const UploadImageRender = props => (
  <Delayed waitBeforeShow={1000}>
    <UploadImage {...props} />
  </Delayed>
);

const required = value => {
  if (value) {
    const img = get(value, 'img', []) || [];
    if (img.length > 0) {
      return undefined;
    }
    return 'Image is required';
  }

  return 'Image is required';
};

class Camera extends Component {
  state = {};

  render() {
    const { data, dynamicField, type } = this.props;
    const validate = data.is_required ? [required] : [];

    const name = fieldName(data, dynamicField);

    return (
      <div className="field-camera-cs">
        <Field
          name={name}
          id={`id-${name}`}
          type="text"
          component={
            data.details && data.details.allow_multiple_pictures
              ? UploadMultipleImageRender
              : UploadImageRender
          }
          multiple={data.is_multiple_value}
          label={data.is_display_field_name && data.field_name}
          placeholder=""
          defaultValue={[]}
          requiredStar={data.is_required}
          validate={validate}
          disabled={type === 'detail'}
        />
      </div>
    );
  }
}

export default Camera;
