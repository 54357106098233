/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import {selectOpportunityStatus} from '../../store/actions/select.action';

class SelectStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectOpportunityStatus } = this.props;
    selectOpportunityStatus(true);
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (data !== nextProps.data) {
      const opt = nextProps.data.map(data => ({
        value: data.id,
        label: data.name,
        probability: data.probability,
        isPrimary: data.isPrimary
      }));
      this.setState({ opt });
    }
  }

  getOpportunity = val => {
    const { getProbability } = this.props;
    if (getProbability) {
      getProbability(val?.probability);
    }
  };

  render() {
    const { opt } = this.state;
    const { validate, label, requiredStar, name, placeholder } = this.props;
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="inputOwner"
        placeholder={placeholder}
        validate={validate}
        onChange={e => this.getOpportunity(e)}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.opportunityStatus
});

export default connect(
  mapStateToProps,
  {
    selectOpportunityStatus
  }
)(SelectStatus);
