/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {change, formValueSelector, reduxForm} from 'redux-form';
import {Translate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import SelectCompanies from '../../select/RenderSelectCompanies';

// import SelectCompanies from '../../select/SelectCompanies';

class ModalFilterCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const params = JSON.parse(localStorage.getItem('params'));
    const id = params.clientCompanyId;
    if (id !== undefined && id !== null) {
      this.props.dispatch(
        change('modalFilterCompanies', 'companiesId', params.clientCompanyId)
      );
    }
  }

  render() {
    const { onFilter, companyId } = this.props;

    const data = {
      companyId: (companyId && companyId.value) || null
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          style={{ borderBottom: 'none' }}
        >
          <Translate id="select_company" />
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <Translate>
              {({ translate }) => (
                <SelectCompanies
                  name="companiesId"
                  label={<Translate id="company" />}
                  placeholder={translate('pick company')}
                />
              )}
            </Translate>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <Button
            color="primary"
            className="px-5 mb-2"
            onClick={() => {
              onFilter(data);
              this.props.toggle();
            }}
          >
            <Translate id="save" />
          </Button>
          {/* <Button
            color=""
            className="px-5 mb-2"
            onClick={() => {
              dispatch(reset('modalFilterCompanies'));
              onFilter({ companyId: '' });
              this.props.toggle();
            }}
          >
            Reset Filter
          </Button> */}
        </ModalFooter>
      </Modal>
    );
  }
}

const selector = formValueSelector('modalFilterCompanies');

const mapStateToProps = state => ({
  companyId: selector(state, 'companiesId')
});

export default reduxForm({
  form: 'modalFilterCompanies'
})(connect(mapStateToProps, {})(ModalFilterCompanies));
