import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { tag } from '../../myToolbar/MyTagFilter';

// ALEXANDRIA

export interface Division {
  city: string
  division_1: string
  division_2: string
  country: string
}

export interface DropdownDivision {
  list: DropdownData[] | tag[]
  value: DropdownData
}

export interface Columns {
  Header: string
  accessor: string
}

// IMPORT

export type LoadingState = 'loading' | 'loaded' | 'error' | 'loadingImport' | 'success'

export enum LoadingStateEnum {
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
  LOADING_IMPORT = 'loadingImport',
  SUCCESS = 'success',
  NOT_FOUND = 'not_found'
}

export enum Panel {
  UploadView,
  Matching,
  TableView,
  AlexandriaStep1,
  AlexandriaStep2,
}

export enum TransformationType {
	Date = 'Date',
  CompanyId = 'CompanyId',
  ProductId = 'ProductId',
}

export interface CaseType {
  value: string | number
  status: SidelyRowStatus
  type?: TransformationType
}

export type RowType = Record<string, CaseType>

export type ImportColumns = { Header: string, accessor: string }

export interface MatchedType {
  value: string
  label: any
  model: Model
  id: number
  secondMatchingValue: string
  matchingLink?: number
  unMatchingLink?: number
  additionalValue?: unknown
  type?: TransformationType
}

export type MatchedRowType = Record<string, MatchedType | undefined>

export enum SidelyRowStatus {
  Validated = 0,
  Warning = 1,
  Duplicates = 2,
  AdminWaiting = 3,
  Edited = 4,
  Error = 5,
}

export enum Model {
  Company = 'Company',
  Contact = 'Contact',
  Opportunity = 'Opportunity',
  Event = 'Event',
  Product = 'Product',
  Order = 'Order',
  Note = 'Note',
  Assortment = 'Assortment',
  Checkout = 'Checkout'
}
