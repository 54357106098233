import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Route } from '../Routes';
import ShelfAuditsV2 from '../../containers_v2/shelfAudit/ShelfAudits';
import ShelfAuditTemplates from '../../containers_v2/shelfAudit/template/ShelfAuditTemplate';

const ShelfAuditsRoutes = (): Route[] => [
	{
		component: ShelfAuditsV2,
		path: '/shelf-audits',
		name: <Translate id="shelf_audits" />,
		exact:true
	},
	{
		component:ShelfAuditTemplates,
		path:'/shelf-audits/:id',
		name:'template',
		exact:true,
	}

];
export default ShelfAuditsRoutes;
