import { ContactRow } from 'bindings/contacts/ContactRow';
import optionGrey from 'images/icon/options_grey.png';
import trash_red from 'images/ui_icon/trash_red.svg';
import PencilVendorImage from 'images/vendors/pencil.svg';
import * as moment from 'moment';
import { ITag } from 'proto/protobufs';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { AContactEdition } from '../../atoms/contact/contactEdition';
import { AProfile, AUsers } from '../../atoms/global/users';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';
import { AddAndContactCreation } from '../../components_v2/creation/ContactCreation';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import AdvancedFilters from '../../components_v2/filter/AdvancedFilters';
import { FilterId, filterTreeLength } from '../../components_v2/filter/model/Model';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import InputSearch from '../../components_v2/input/InputSearch';
import Pagination from '../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import PopupDeletion from '../../components_v2/popup/PopupDeletion';
import { Table, TableSortType } from '../../components_v2/table/Table';
import ToolbarFilterButton from '../../components_v2/toolbarFilter/ToolbarButton';
import { TagType } from '../../components_v2/toolbarFilter/tags/Model';
import { getTags, updateTags } from '../../components_v2/toolbarFilter/tags/action';
import { removeAllUrlParameters } from '../../components_v2/utils';
import { ToolBarView } from '../../components_v2/view/ToolBarView';
import { DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { Translate, translateToString } from '../../styles/global/translate';
import { useFunctionState, useRefState } from '../../utils/customHooks';
import { SelectAll, checkUrlModalParams } from '../client-companies/Companies';
import { exportData } from '../client-companies/data/action';
import { CompanyStatus } from '../client-companies/model/Model';
import { SeclectionImage, SelectionAction, SelectionActionContainer } from '../client-companies/style/Style';
import { ToolbarBox, ToolbarImage } from '../globals/defaultToolbar/style/Style';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { getClientStatuses } from '../import/actions';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { TypeFile } from '../orders/model/Model';
import Restricted from '../permissions/Restricted';
import { ModalState } from '../products/model';
import { FlexDiv } from '../products/style';
import ContactColumns from './data/ContactColumns';
import { TableContext, TableProvider } from './data/ContactContext';
import { QueryUpdateContact, deleteContact, deleteContacts, getContactFilters, queryContact, updateContact } from './data/Data';
import ContactBulkEdition from './popup/ContactBulkEdition';
import ContactPopup from './popup/ContactPopup';

const DEFAULT_STEP = 25;

const CONTACT_VIEW_LOCAL_STORAGE = 'contact_view_id';
export const CONTACT_HIDDEN_LOCAL_STORAGE = 'contact_hidden_columns';
export const CONTACT_SORT_LOCAL_STORAGE = 'contact_sort_columns';
export default function ContactsWrapper(props: ComponentProps) {
	return <TableProvider>
		<Contacts {...props} />
	</TableProvider>;
}

export type FilterState = {
	selectedId?: FilterId,
	openSummary?: boolean,
	filterOpen?: boolean
}


function Contacts(props: ComponentProps): JSX.Element {
	const [contactsRef, setContacts, contacts] = useRefState<ContactRow[]>([]);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [pagination, setPagination] = React.useState<PaginationResult>();
	const [sort, setSort] = React.useState<TableSortType | undefined>({ id: 'created_at', desc: true });
	const users = useRecoilValue(AUsers);
	const [selectedCheckboxRef, setSelectedCheckbox, selectedCheckbox] = useRefState<boolean[]>([]);
	const [isAllSelectedRef, setIsAllSelected, isAllSelected] = useRefState<boolean>(false, ({ newValue }) => {
		setSelectedCheckbox([]);
		return newValue;
	});
	const context = React.useContext(TableContext);
	const { hiddenColumns, sortColumns, filterResult, setFilterResult, setSortColumns, setHiddenColumns } = context;
	const tableRef = React.useRef<HTMLDivElement>();
	const [isBulkDeleteOpen, setIsBulkDeleteOpen] = React.useState<boolean>(false);
	const [isBulkEditOpen, setIsBulkEditOpen] = React.useState<boolean>(false);
	const [tableResize, setTableResize] = useFunctionState<{ [key: string]: number }>(
		JSON.parse(localStorage.getItem('table_contact_resize') || '{}'),
		({ newValue }) => {
			localStorage.setItem('table_contact_resize', JSON.stringify(newValue));
			return newValue;
		}
	);
	const [filterState, setFilterState] = React.useState<FilterState>({});
	const [search, setSearch] = React.useState<string>();
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const onClickFilter = React.useCallback((selectedId: FilterId) => {
		setFilterState({
			selectedId,
			openSummary: false,
			filterOpen: true
		});
	}, []);
	const [modalState, setModalState] = React.useState<ModalState<{ id: number, fullOpen: boolean }>>({ isOpen: false });
	const history = useHistory();
	const [companyState, setCompanyState] = React.useState<ModalState<number>>({ isOpen: false });
	const profile = useRecoilValue(AProfile);
	const refresh = () => setPagination(pagination => pagination ? ({ ...pagination }) : undefined);
	const [statuses, setStatuses] = React.useState<CompanyStatus[]>([]);
	const [tags, setTags] = React.useState<ITag[]>([]);
	const atomContactEdition = useRecoilValue(AContactEdition);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (!atomContactEdition) return;
		const contact = contacts.find(c => c.id === atomContactEdition.id);
		if (!contact) return;
		if (atomContactEdition.ids_delete) {
			contact.tag_ids = contact.tag_ids.filter(t => !atomContactEdition.ids_delete?.includes(t));
		}
		if (atomContactEdition.ids_add) {
			for (const id of atomContactEdition.ids_add) {
				contact.tag_ids.push(id);
			}
		}
		setContacts([...contacts]);
	}, [atomContactEdition]);

	React.useEffect(() => {
		const localHiddenColumns = JSON.parse(localStorage.getItem(CONTACT_HIDDEN_LOCAL_STORAGE) || '[]');
		const localSortColumns = JSON.parse(localStorage.getItem(CONTACT_SORT_LOCAL_STORAGE) || '[]');
		localSortColumns && setSortColumns(localSortColumns);
		localHiddenColumns && setHiddenColumns(localHiddenColumns);
	}, []);

	const updateTagsWrapper = React.useCallback(async(contactId: number, body: { ids_delete?: number[], ids_add?: number[] }) => {
		updateTags(TagType.CONTACT, {
			ressource_id: contactId,
			...body
		}).then(() => {
			const contact = contactsRef.current.find(c => c.id === contactId);
			if (!contact) return new Promise<void>((accept, _) => accept());
			body.ids_add?.forEach(t => contact.tag_ids.push(t));
			body.ids_delete?.forEach(t => contact.tag_ids = contact.tag_ids.filter(t2 => t2 !== t));
			setContacts([...contactsRef.current]);
			return new Promise<void>((accept, _) => accept());
		})
			.catch(e => new Promise<void>((_, refuse) => refuse(e)));
	}, []);

	const updateContactWrapper = React.useCallback(async(contactId: number, params: QueryUpdateContact, localChanges?: { [key: string]: unknown }) => {
		return updateContact(contactId, params)
			.then(_ => {
				const contact = contactsRef.current.find(c => c.id === contactId);
				if (!contact) return new Promise<void>((accept, _) => accept());
				contact.updated_at = moment().utc().toString();
				contact.updated_by = profile.id;
				const f = ([key, value]) => {
					if (typeof value === 'string' && value === 'deleted') return contact[key] = undefined;
					else if (typeof value === 'string' && value === 'unmodified') return;
					else if (typeof value === 'number' || typeof value === 'string') return contact[key] = value;
					else if ('updated' in value) return contact[key] = value.updated;
					else return contact[key] = value;
				};
				Object.entries(params).forEach(f);
				localChanges && Object.entries(localChanges).forEach(f);
				setContacts([...contactsRef.current]);
				return new Promise<void>((accept, _) => accept());
			})
			.catch(e => new Promise<void>((_, reject) => reject(e)));
	}, []);

	React.useEffect(() => {
		getContactFilters().then(setFilterParams);
		getClientStatuses().then(response => setStatuses(response.data));
		getTags(TagType.CONTACT).then(setTags);
	}, []);

	React.useEffect(() => {
		//TODO REDUCE CALLS
		setLoadingState(LoadingStateEnum.LOADING);
		queryContact({
			limit: pagination?.step ?? DEFAULT_STEP,
			offset: pagination?.offset ?? 0,
			filters: filterResult?.formatted,
			search,
			order_by: sort?.id,
			descending: sort?.desc,
		}).then(res => {
			setContacts(res);
			setLoadingState(LoadingStateEnum.LOADED);
		});
	}, [pagination, sort, filterResult, search]);
	const LocalExportContacts = () => {
		dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
		exportData(TypeFile.Excel, {
			type: 'Contacts',
			filters: filterResult?.formatted,
			order_by: sort?.id,
			descending: sort?.desc,
			search
		});
	};

	React.useEffect(() => {
		props.setToolBarState({
			title: 'Contact',
			bottomLeftToolbarComponent: <ToolbarBox>
				<ToolBarView
					localStorageKey={CONTACT_VIEW_LOCAL_STORAGE}
					localStorageHiddenKey={CONTACT_HIDDEN_LOCAL_STORAGE}
					viewsKey='contacts'
					context={context}
				>
					<ToolbarFilterButton
						activeFilters={filterTreeLength(filterResult?.formatted)}
						onClick={() => setFilterState(state => ({ ...state, openSummary: true, filterOpen: true }))}
						onDeleteFilter={() => {
							setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
						}}
					/>
					{(selectedCheckbox.some(c => c) || isAllSelected) && <FlexDiv gap='10px'>
						<DefaultTextDiv height='30px'>
							<SelectionActionContainer>
								<Restricted to={{ objectAction: 'UpdateCompany' }}>
									<SelectionAction onClick={() => setIsBulkEditOpen(true)}>
										<SeclectionImage src={PencilVendorImage} />
										<Translate id='edit_selection' />
									</SelectionAction>
								</Restricted>
								<Restricted to={{ objectAction: 'DeleteCompany' }}>
									<SelectionAction onClick={() => setIsBulkDeleteOpen(true)} delete>
										<SeclectionImage src={trash_red} />
										<Translate id='delete_selection' />
									</SelectionAction>
								</Restricted>
							</SelectionActionContainer>
						</DefaultTextDiv>
					</FlexDiv>}
				</ToolBarView>
			</ToolbarBox>,
			bottomRightToolbarComponent: <ToolbarBox>
				<InputSearch
					placeholder={translateToString('search')}
					name='search_contact_name'
					type='text'
					onChange={setSearch}
					delay={500}
				/>
				<Restricted to={{ objectAction: 'CreateContact' }}>
					<AddAndContactCreation
						onCreate={c => {
							refresh();
							history.push({
								search: `?id=${c.contact_id}`
							});
							setModalState(state => state.data ? { ...state, data: { ...state.data, fullOpen: true } } : { ...state, data: { id: c.contact_id, fullOpen: true } });
						}}
					/>
				</Restricted>
				<Restricted to={{ objectAction: 'CreateExport' }}>
					<Dropdown
						dropdownStyle={{ optionLeft: '-115px', optionWidth: '160px' }}
						datalist={[{ label: translateToString('export_NAME', [['NAME', translateToString('contacts').toLocaleLowerCase()]]), value: 'EXPORT' }]}
						name='options'
						JSXButton={() => <ToolbarImage hasPointer src={optionGrey} />}
						onChange={value => {
							switch (value.value) {
								case 'EXPORT': {
									LocalExportContacts();
									return;
								}
							}
						}}
					/>
				</Restricted>
			</ToolbarBox>
		});
	}, [filterResult, selectedCheckbox, isAllSelected, hiddenColumns, sortColumns]);

	React.useEffect(() => {
		const f = ({ isOpen, isFullOpen, id }) => {
			const parsedId = parseInt(id);
			if (isNaN(parsedId)) return;
			setModalState({ isOpen, data: { fullOpen: isFullOpen, id: parsedId } });
		};
		checkUrlModalParams(f)();
		history.listen(checkUrlModalParams(f));
	}, [history]);

	const columns = React.useMemo(() => ContactColumns(
		users,
		(value, fullOpen) => {
			history.push({
				search: `?id=${value}`
			});
			setModalState((state) => state.data ? { ...state, data: { ...state.data, fullOpen } } : { ...state, data: { id: -1, fullOpen } });
		},
		data => setCompanyState({ isOpen: true, data }),
		updateContactWrapper,
		id => deleteContact(id).then(refresh),
		// row => {console.log(row);}
		loadingState,
		statuses,
		tags,
		updateTagsWrapper
	), [users, loadingState, statuses, tags, hiddenColumns, sortColumns]);

	return <>
		<Table
			allSelectable
			innerRef={tableRef}
			height='calc(100vh - 174px)'
			columns={columns}
			data={contacts}
			onSort={React.useCallback((sort: TableSortType) => setSort(sort[0]), [])}
			EnableResize
			initialSortBy={sort}
			setSelectedCheckbox={row => {
				if (row) {
					selectedCheckboxRef.current[row.id] = !selectedCheckboxRef.current[row.id];
					setSelectedCheckbox([...selectedCheckboxRef.current]);
					return selectedCheckboxRef.current[row.id];
				} else {
					const actualState = contactsRef.current.every(c => !!selectedCheckboxRef.current[c.id] === !isAllSelectedRef.current);
					const newSelectedCheckbox: boolean[] = [...selectedCheckboxRef.current];
					contactsRef.current.forEach(c => newSelectedCheckbox[c.id] = isAllSelectedRef.current != !actualState);
					setSelectedCheckbox(newSelectedCheckbox);
					return !actualState;
				}
			}}
			isIdSelected={row => {
				if (row) return isAllSelectedRef.current != (selectedCheckboxRef.current[row.id] ?? false);
				if (isAllSelectedRef.current) return selectedCheckboxRef.current.every((b, id) => !b || !contactsRef.current.some(c => c.id === id));
				return contactsRef.current.length > 0 && selectedCheckboxRef.current.filter(b => b).length === contactsRef.current.length;
			}}
			hiddenColumns={hiddenColumns}
			sortColumns={sortColumns}
			onClickFilter={onClickFilter}
			onResize={(value) => Object.keys(value).length > 0 && setTableResize(value)}
			resizeValue={tableResize}
			isInformationHeaderOpen={(selectedCheckbox.some(c => c) && selectedCheckbox.length !== 0) || isAllSelected}
			informationHeader={<SelectAll
				onMaxSelected={setIsAllSelected}
				selected={selectedCheckbox.filter(c => c).length}
				max={Number(contacts[0]?.count ?? 0)}
				isAllSelected={isAllSelected}
			/>}
		/>
		<ComponentLoader loadingState={loadingState} allScreen />
		<Pagination
			label={translateToString('contacts')}
			amount={Number(contacts[0]?.count ?? 0)}
			currentCount={contacts.length}
			steps={[DEFAULT_STEP, 50, 100]}
			onChange={setPagination}
		/>
		<ContactPopup
			isOpen={modalState.isOpen}
			setOpen={(isOpen: boolean) => {
				if (!isOpen) { removeAllUrlParameters(); }
				setModalState({ isOpen });
			}}
			contactId={modalState.data?.id}
			fullOpenMode={modalState.data?.fullOpen}
			updateContact={updateContactWrapper}
			tags={tags}
		/>
		<AdvancedFilters
			permission='ReadContact'
			isOpen={filterState.filterOpen ?? false}
			setOpen={filterOpen => setFilterState({ filterOpen })}
			isOpenSummary={filterState.openSummary ?? false}
			filterList={filterParams}
			filterValues={filterResult?.values}
			onChange={(value) => setFilterResult(value)}
			selectedFilter={filterState.selectedId}
			tagType={TagType.CONTACT}
		/>
		<ClientCompany
			isOpen={companyState.isOpen}
			setOpen={(isOpen: boolean) => setCompanyState({ isOpen })}
			clientCompanyId={companyState.data!}
		/>
		<PopupDeletion
			isOpen={isBulkDeleteOpen}
			onClickOut={() => setIsBulkDeleteOpen(false)}
			records={isAllSelected ? Number(contacts[0]?.count ?? 0) - selectedCheckbox.filter(c => c).length : selectedCheckbox.filter(c => c).length}
			onValidation={() => {
				deleteContacts(selectedCheckbox.reduce((acc, elem, i) => elem ? [...acc, i] : acc, []), isAllSelected, filterResult?.formatted, undefined)
					.then(refresh);
				setSelectedCheckbox([]);
				setIsAllSelected(false);
			}}
			translationKey='contacts'
		/>
		<ContactBulkEdition
			isOpen={isBulkEditOpen}
			setIsOpen={setIsBulkEditOpen}
			ids={selectedCheckbox.reduce((acc, elem, i) => elem ? [...acc, i] : acc, [])}
			refresh={() => {
				setIsAllSelected(false);
				refresh();
				setIsBulkEditOpen(false);
			}}
			isAll={isAllSelected}
			filters={filterResult?.formatted}
			statuses={statuses}
		/>
	</>;
}
