import Split from 'react-split';
import styled from 'styled-components';

const Splitter = styled(Split)<{ ishidden?: string }>`
	.gutter {
	    background-color: #eee;
	    background-repeat: no-repeat;
	    background-position: 50%;
	}

	.gutter.gutter-vertical {
		${p => p.ishidden === 'true' ? 'visibility: hidden;' : ''}
	    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
	    cursor: row-resize;
	}
`;

export default Splitter;