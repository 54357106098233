import styled from 'styled-components';
import { BorderColor, GreenSidely } from '../../../styles/global/css/Utils';
import * as React from 'react';

const PercentBarContainer = styled.div`
  width:100%;
  height:20px;
  background-color:${BorderColor};
  border-radius:10px;
`;

interface PercentBarContentProps {
    value: string;
    backgroundColor: string;
}

const PercentBarContent = styled.div<PercentBarContentProps>`
    width : ${p => p.value ?? '0%'};
    height:100%;
    background-color:${p => p.backgroundColor};
    border-radius:10px;
`;
interface PercentBarProps {
    value: string;
    backgroundColor: string;
}
export default function PercentBar(props: PercentBarProps) {
	return <PercentBarContainer>
		<PercentBarContent value={props.value} backgroundColor={props.backgroundColor}></PercentBarContent>
	</PercentBarContainer>;
}