import tradeshowsImage from 'images/salesops_icons/tradeshows.png';
/* eslint-disable lines-between-class-members */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import TableListingPage from './components/tableComponent/TableListingPage';
import { deleteData, detailTemplate, fetchData, setModal } from '../../store/actions/enForm.action';
import DropdownAction from './components/DropdownAction';
import moment from 'moment';
import { setLocalStorage } from '../../helpers/localStorage';

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static columns = props => {
		const { history, data } = props;

		const columns = [
			{
				dataField: 'Form template',
				text: <Translate id="form_templates" />,
				formatter: (cell, row) => (
					<div
						className="pointer"
						style={{ display: 'flex', alignItems: 'center' }}
						onClick={() => {
							setLocalStorage('instance', row.name);
							history.push(`/enform/list/instance/${row.id}`);
						}}
					>
						<div
							style={{
								width: 26,
								borderRadius: '50%',
								display: 'flex',
								justifyContent: 'left',
								alignItems: 'center',
								marginRight: 10
							}}>
							<img
								style={{ height: 'auto', width: 20, marginRight: 10 }}
								src={row.icon_url}
								alt={row.name}
								onError={e => {
									e.target.onerror = null;
									e.target.src = tradeshowsImage;
								}}
							/>
							{row.name || '-'}
						</div>

					</div>
				)
			},
			{
				dataField: 'catalogue_name',
				text: <Translate id="assortment" />,
				formatter: (cell, row) => (
					<div>{row.catalogue_name || '-'}</div>
				)
			},
			{
				dataField: 'createdOn',
				text: <Translate id="createon" />,
				formatter: (cell, row) => (
					<div>{moment(row.created_at || '-').format('DD/MM/YY') || '-'}</div>
				)
			},
			{
				dataField: 'lastUpdated',
				text: <Translate id="Last updated" />,
				formatter: (cell, row) => (
					<div>{moment(row.updated_at || '-').format('DD/MM/YY') || '-'}</div>
				)
			},
			{
				text: <Translate id="number_of_record" />,
				formatter: (cell, row) => (
					<div className="ml-5">{row.record_count || '0'}</div>
				)
			},

			{
				dataField: 'customerPlan',
				text: <Translate id="quick_action" />,
				headerStyle: { width: '100px' },
				style: { width: '100px' },
				formatter: (cell, row) => (
					<div>
						<DropdownAction
							id={row.id}
							name={row.name}
							permission='ShelfAudit'
							path="enform/list"
							count={row.record_count}
							url="shelfAuditTemplate"
							{...props}
						/>
					</div>
				)
			}
		];

		return columns;
	};

	render() {
		return (
			<Fragment>
				<div style={{ width: 200 }} />
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	data: state.enform,
	current: state.user.current,
	isLoadingCurrent: state.user.isLoading,
	fieldInstaller: state.enform.fieldInstaller
});

export default connect(mapStateToProps, {
	fetchData,
	deleteData,
	setModal,
	detailTemplate
})(
	TableListingPage({
		name: 'shelf_audit',
		columns: Contact.columns,
		noCreate: false
	})(Contact)
);
