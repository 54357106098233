import {
	CREATE_CLIENT_STATUS_ERROR,
	CREATE_CLIENT_STATUS_REQUEST,
	CREATE_CLIENT_STATUS_SUCCESS,
	CREATE_OPPORTUNITY_STATUS_ERROR,
	CREATE_OPPORTUNITY_STATUS_REQUEST,
	CREATE_OPPORTUNITY_STATUS_SUCCESS,
	DETAIL_DATA_ERROR,
	DETAIL_DATA_REQUEST,
	DETAIL_DATA_SUCCESS,
	GET_CLIENT_STATUS,
	GET_COLOR_DATA,
	GET_COLOR_ID,
	GET_COLOR_STATUS,
	GET_DATA_MODAL,
	GET_DATA_TAG,
	GET_OPPORTUNITY_STATUS,
	GET_PROBABILITY_DATA,
	GET_TRANSLATE,
	SET_WIDTH,
	TAP_OPTION,
	UPDATE_DATA_ERROR,
	UPDATE_DATA_REQUEST,
	UPDATE_DATA_SUCCESS,
	UPDATE_OPPORTUNITY_STATUS_ERROR,
	UPDATE_OPPORTUNITY_STATUS_REQUEST,
	UPDATE_OPPORTUNITY_STATUS_SUCCESS,
	UPDATE_SEQUENCE_ERROR,
	UPDATE_SEQUENCE_REQUEST,
	UPDATE_SEQUENCE_SUCCESS
} from '../actions/fieldSettings.action';

const initState = {
	isLoading: false,
	isLoadingThirdParty: [],
	isLoadingDetail: false,
	current: {
		name: 'username'
	},
	opportunityStatus: [],
	colorStatus: [],
	dataTag: [],
	clientStatus: [],
	colorData: [],
	dataDetail: null,
	data: [],
	tap: 1,
	colorId: null,
	modalDelete: null,
	dataProbability: [],
	dataModal: null,
	statusWidth: false,
	dataConnectedGoogleCalendar: []
};

export default function ROOMReducer(state = initState, action) {
	switch (action.type) {
		case CREATE_CLIENT_STATUS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case CREATE_CLIENT_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				dataCreated: action.payload.data
			};

		case CREATE_CLIENT_STATUS_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_COLOR_STATUS:
			return {
				...state,
				colorStatus: action.payload.data
			};

		case GET_CLIENT_STATUS:
			return {
				...state,
				clientStatus: action.payload.data
			};
		case UPDATE_DATA_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_DATA_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};

		case UPDATE_DATA_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_DATA_TAG:
			return {
				...state,
				dataTag: action.payload.data
			};

		case DETAIL_DATA_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_DATA_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_DATA_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};
		case TAP_OPTION:
			return {
				...state,
				tap: action.payload
			};
		case GET_OPPORTUNITY_STATUS:
			return {
				...state,
				opportunityStatus: action.payload.data
			};
		case CREATE_OPPORTUNITY_STATUS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case CREATE_OPPORTUNITY_STATUS_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};

		case CREATE_OPPORTUNITY_STATUS_ERROR:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_OPPORTUNITY_STATUS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_OPPORTUNITY_STATUS_SUCCESS:
			return {
				...state,
				dataCreated: action.payload,
				isLoading: false
			};

		case UPDATE_OPPORTUNITY_STATUS_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_TRANSLATE :
			return {
				...state,
				modalDelete: action.payload
			};
		case GET_COLOR_DATA :
			return {
				...state,
				colorData: action.payload
			};
		case GET_COLOR_ID :
			return {
				...state,
				colorId: action.payload
			};
		case GET_PROBABILITY_DATA :
			return {
				...state,
				dataProbability: action.payload
			};
		case UPDATE_SEQUENCE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_SEQUENCE_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_SEQUENCE_ERROR:
			return {
				...state,
				isLoading: false
			};
		case GET_DATA_MODAL :
			return {
				...state,
				dataModal: action.payload
			};
		case SET_WIDTH :
			return {
				...state,
				statusWidth: action.payload
			};
		default:
			return state;
	}
}
