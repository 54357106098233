import { URL_API, URL_FOUNDATION } from '../../config/keys';
import axios, { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { TableSortType } from '../../components_v2/table/Table';
import { ProductTax } from '../settings/models';
import { ITag, Tags } from '../../../typings/proto/protobufs';
import { Assortment } from './model';
import { AdditionalValue, PathAndValue } from './assortimentV3';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function getAssortments(limit?: number, offset?: number, search?: string, sort?: TableSortType): Promise<AxiosResponse<{count: number, assortments: Assortment[]}>> {
	let query = '';
	if (limit) {
		query += `limit=${limit}&`;
	}
	if (offset) {
		query += `offset=${offset}&`;
	}
	if (search) {
		query += `search=${search}&`;
	}
	if (sort != null) {
		query += `order_by=${sort.id}&desc=${sort.desc}&`;
	}

	return await axios.get(`${URL_FOUNDATION}/api/assortments/v2?${query}`);
}


export async function get_products(params: {
	limit?: number,
	offset?: number,
	search?: string,
	sort?: TableSortType,
	tags?: string,
	categories?: number[],
	brands?: number[],
	catalogues?: number[]
}) {
	const newParams = {
		limit: params.limit,
		offset: params.offset,
		search: params.search && params.search.length > 0 ? params.search : undefined,
		order_by: params.sort?.id,
		desc: params.sort?.desc,
		tags: params.tags,
		categories: params.categories && params.categories.length > 0 ? params.categories.join(',') : undefined,
		brands: params.brands && params.brands.length > 0 ? params.brands?.join(',') : undefined,
		catalogues: params.catalogues && params.catalogues.length > 0 ? params.catalogues?.join(',') : undefined
	};

	return await axios.get(`${URL_FOUNDATION}/api/products/v2`, { params: newParams });
}

export async function getNonAssortmentProduct(assortmentId: number, limit?: number, offset?: number, search?: string, sort?: TableSortType) {
	let query = '';
	if (limit) {
		query += `&limit=${limit}`;
	}
	if (offset) {
		query += `&offset=${offset}`;
	}
	if (search) {
		query += `&search=${search}`;
	}
	if (sort != null) {
		query += `&order_by=${sort.id}&desc=${sort.desc}`;
	}

	return await axios.get(`${URL_FOUNDATION}/api/assortments/${assortmentId}/non-products?${query}`);
}

export async function exportExcel() {
	return await axios({
		method: 'get',
		responseType: 'blob',
		url: `${URL_API}/api/products/exportCsv`
		// params: data
	})
		.then(
			response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Product.csv');
				document.body.appendChild(link);
				link.click();
			},
			error => {
			}
		)
		.catch(error => {
			throw error;
		});
}

export async function getStatuses() {
	return await axios.get(`${URL_FOUNDATION}/api/products/statuses`);
}

export async function getNonAlternativeProducts(id: number, limit?: number, offset?: number, sort?: TableSortType) {
	let query = '';
	if (limit) {
		query += `&limit=${limit}`;
	}
	if (offset) {
		query += `&offset=${offset}`;
	}
	if (sort != null) {
		query += `&order_by=${sort.id}&desc=${sort.desc}`;
	}

	return await axios.get(`${URL_FOUNDATION}/api/products/${id}/non-alternatives?${query}`);
}

export async function getAlternativeProducts(id: number, limit?: number, offset?: number, sort?: TableSortType) {
	let query = '';
	if (limit) {
		query += `&limit=${limit}`;
	}
	if (offset) {
		query += `&offset=${offset}`;
	}
	if (sort != null) {
		query += `&order_by=${sort.id}&desc=${sort.desc}`;
	}

	return await axios.get(`${URL_FOUNDATION}/api/products/${id}/alternative?${query}`);
}

export async function deleteAlternativeProducts(id: number, alternativeProductId: number) {
	return await axios.delete(`${URL_FOUNDATION}/api/products/${id}/alternative/${alternativeProductId}`);
}

export async function postAlternativeProducts(id: number, ids: number[]) {
	return await axios.post(`${URL_FOUNDATION}/api/products/${id}/alternatives`, ids);
}

export async function getProductById(id: number) {
	return await axios.get(`${URL_FOUNDATION}/api/products/${id}`);
}

export async function getCatalogues(id: number, limit?: number, offset?: number, sort?: TableSortType) {
	let query = '';
	if (limit) {
		query += `&limit=${limit}`;
	}
	if (offset) {
		query += `&offset=${offset}`;
	}
	if (sort != null) {
		query += `&order_by=${sort.id}&desc=${sort.desc}`;
	}

	return await axios.get(`${URL_FOUNDATION}/api/products/${id}/catalogues?${query}`);
}

export async function getProductByIdTags(id: number) {
	return await axios.get(`${URL_FOUNDATION}/api/v2/products/${id}/tags`);
}

export async function getAllProducts(id: number, limit?: number, offset?: number, sort?: TableSortType) {
	let query = '';
	if (limit) {
		query += `&limit=${limit}`;
	}
	if (offset) {
		query += `&offset=${offset}`;
	}
	if (sort != null) {
		query += `&order_by=${sort.id}&desc=${sort.desc}`;
	}
	return await axios.get(`${URL_FOUNDATION}/api/assortments/${id}/all-products?${query}`);
}

export async function getAssortmentById(id: number) {
	return await axios.get(`${URL_FOUNDATION}/api/assortments/${id}`);
}

export async function updateAssortmentProduct(assortmentId: number, productId: number, body: any) {
	return await axios.put(`${URL_FOUNDATION}/api/assortments/${assortmentId}/product/${productId}`, body);
}

export async function updateProduct(productId: number, body: any) {
	return await axios.put(`${URL_FOUNDATION}/api/products/${productId}`, body);
}

export async function updateProducts(body: any[]) {
	return await axios.put(`${URL_FOUNDATION}/api/products`, body);
}

export async function updateAssortments(body: any[]) {
	return await axios.put(`${URL_FOUNDATION}/api/assortments`, body);
}

export async function updateAssortment(assortmentId: number, body: any) {
	return await axios.put(`${URL_FOUNDATION}/api/assortments/${assortmentId}`, body);
}

export async function postAssortmentProducts(assortmentId: number, body: any) {
	return await axios.post(`${URL_FOUNDATION}/api/assortments/${assortmentId}/products`, body);
}

export async function createProductTag(productId: number, tagId: number) {
	return await axios.post(`${URL_FOUNDATION}/api/products/${productId}/tag/${tagId}`);
}

export async function deleteProductTag(productId: number, tagId: number) {
	return await axios.delete(`${URL_FOUNDATION}/api/products/${productId}/tag/${tagId}`);
}

export async function getBrands() {
	return await axios.get(`${URL_FOUNDATION}/api/brands`);
}

export async function getCategories() {
	return await axios.get(`${URL_FOUNDATION}/api/categories`);
}

export async function postProductImages(productId: number, body: any) {
	return await axios.post(`${URL_FOUNDATION}/api/products/${productId}/images`, body);
}

export async function postImages(images: File[], imageNames: string[]) {
	const res: string[] = await axios.post(`${URL_FOUNDATION}/api/v2/images`, imageNames).then(r => r.data);
	for (const [index, url] of res.entries()) {
		fetch(url, { body:images[index], mode: 'cors', method:'PUT' });
	}
}

export async function deleteProductImage(productId: number, fileId: number) {
	return await axios.delete(`${URL_FOUNDATION}/api/products/${productId}/image/${fileId}`);
}

export async function postProduct(body: any) {
	return await axios.post(`${URL_FOUNDATION}/api/products`, body);
}

export async function postAssortment(body: any) {
	return await axios.post(`${URL_FOUNDATION}/api/assortments`, body);
}

export async function getProductTax(productId: number): Promise<ProductTax[]> {
	return await axios.get<ProductTax[]>(`${URL_FOUNDATION}/api/products/${productId}/taxes`).then(res => res.data?.sort((a, b) => a.sequence - b.sequence));
}

export async function getProductTaxes(): Promise<ProductTax[]> {
	return await axios.get<ProductTax[]>(`${URL_FOUNDATION}/api/products/taxes`).then(res => res.data);
}

export async function updateTax(body: any) {
	return await axios.post(`${URL_FOUNDATION}/api/tax/product`, body);
}

export async function getTags(): Promise<ITag[]> {
	try {
		const res = await axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/products/tags`, { responseType: 'arraybuffer' });
		return Tags.decode(new Uint8Array(res.data)).tag;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function getAdditionalValues(query: string): Promise<AdditionalValue[]> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/additional-values?${query}`).then(r => r.data);
}

export async function saveAdditionalValue(path: AdditionalValue[0], value: AdditionalValue[1]) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/additional-values`, { path, value }).then(r => r.data);
}

export async function importAdditionalValues(values: PathAndValue[]) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/additional-values/import`, values).then((r) => {console.log(r); return r;}).then(r => r.data);
}
export async function getAdditionalValuesViews(): Promise<[Record<any, any>, number][]> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/additional-values/views`).then(r => r.data);
}