import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Checkbox } from '../../components_v2/filterList/style/Style';
import Pagination from '../../components_v2/pagination/Pagination';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { Table, TableSortType } from '../../components_v2/table/Table';
import { DarkGreySidely } from '../../styles/global/css/Utils';
import { TableRow } from '../orders/templateOrders/style/Style';
import { getNonAlternativeProducts, postAlternativeProducts } from './action';
import { EllipsisToolTip } from './assortmentProductCreation';
import { AlternativeProduct, Product } from './model';
import { InnerContainer, InnerContainerTitle, Link, ProductPicture } from './style';
import { translateToString } from '../../styles/global/translate';

const DEFAULT_LIMIT = 50;

export default function ProductAlternativesCreation(props: {
  isOpen: boolean
  setIsOpen: (b: boolean) => void
  refresh: () => void
  productId: number
}): JSX.Element {
	const { isOpen, setIsOpen, refresh, productId } = props;

	const [checked, setChecked] = React.useState<number[]>([]);

	return <Popup
		isOpen={isOpen}
		onClickOut={() => setIsOpen(false)}
		popupStyle={{ animate: true, height: '100vh', top: '0' }}
		popupMode={PopupMode.Extended}
	>
		<PopupCreation
			title={<Translate id='products_assignation' />}
			canValidate={checked.length > 0}
			onSubmit={() => {
				postAlternativeProducts(productId, checked)
					.then(() => {
						refresh();
						setIsOpen(false);
					})
					.catch(console.log);
			}}
			onClose={() => setIsOpen(false)}
		>
			<ProductsAlternativeList
				onChange={setChecked}
				productId={productId}
				tableHeight={'calc(85vh - 125px)'}
			/>
		</PopupCreation>
	</Popup>;
}

export function ProductsAlternativeList(props: {
  onChange: (products: number[]) => void
  noTitle?: boolean
  productId: number
  limit?: number
  tableHeight: string
}): JSX.Element {
	const { noTitle, productId } = props;
	const [sort, setSort] = React.useState<TableSortType>();
	const [pagination, setPagination] = React.useState({ step: props.limit ?? DEFAULT_LIMIT, offset: 0 });
	const [products, setProducts] = React.useState<AlternativeProduct[]>([]);
	const [checked, setChecked] = React.useState<number[]>([]);
	const all_checked = products.every(p => p.checked);

	React.useEffect(() => {
		getNonAlternativeProducts(productId, pagination.step, pagination.offset, sort)
			.then(res => setProducts(res.data))
			.catch(console.log);
	}, [productId, pagination]);

	React.useEffect(() => { props.onChange(checked); }, [checked]);

	const columns = React.useMemo(() => ([
		{
			id: 'select',
			Header: <Checkbox isActive={all_checked} onClick={() => {
				if (all_checked) {
					setChecked([]);
				} else {
					setChecked(products.map(p => p.id));
				}
			}} />,
			accessor: (row: Product) => {
				return (
					<Checkbox isActive={checked.includes(row.id)} onClick={() => {
						const index = checked.findIndex(i => i == row.id);
						if (index >= 0) {
							checked.splice(index, 1);
						} else {
							checked.push(row.id);
						}
						setChecked([...checked]);
					}} />
				);
			},
			width: 40,
			disableSortBy: true,
			unresizeable: true
		},
		{
			id: 'photo',
			accessor: (row: Product) => <Link centered >{row.photos_url_id?.[0]?.[0] ? <ProductPicture src={row.photos_url_id[0]?.[0]} /> : <></>}</Link>,
			disableSortBy: true,
			width: 50,
			unresizeable: true
		},
		{
			id: 'lower(p.name)',
			Header: <EllipsisToolTip><Translate id='name' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow fontWeight="500" color={DarkGreySidely} cursor="pointer">
				{row.name ?? '-'}
			</TableRow>,
			width: undefined
		},
		{
			id: 'lower(reference)',
			Header: <EllipsisToolTip><Translate id='reference' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow>{row.reference ?? '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'lower(c.name)',
			Header: <EllipsisToolTip><Translate id='category' /></EllipsisToolTip>,
			accessor: (row: Product) => <TableRow>{row.category_name ?? '-'}</TableRow>,
			width: undefined
		}
	]), [all_checked, products, checked]);

	return <InnerContainer>
		{!noTitle && <InnerContainerTitle><Translate id='products' /></InnerContainerTitle>}
		<Table
			height={props.tableHeight}
			columns={columns}
			data={products}
			onSort={sort => setSort(sort[0])}
			initialSortBy={sort}
		/>
		{products[0]?.count > (props.limit ?? DEFAULT_LIMIT) &&
			<Pagination label={translateToString('products')} amount={products[0]?.count} steps={[props.limit ?? DEFAULT_LIMIT]} onChange={(value) => {
				setPagination({ ...pagination, ...value });
			}} />
		}
	</InnerContainer>;
}
