import styled from 'styled-components';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { DefaultText } from '../../../../styles/global/css/GlobalText';
import { BlueSidely, SidelyBlack } from '../../../../styles/global/css/Utils';
import penUrl from 'images/ui_icon/pen_black.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    height: 100%;
    padding-top: calc(100vh - 920px);
    padding-bottom: calc(100vh - 870px);
`;

const ContainerTop = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const AddressContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
`;

const Separator = styled.hr`
    width: 90%;
    border: 1px solid #DADADA;
    background-color: #DADADA;
`;

const ContainerBottom = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

interface TitleProps {
  marginLeft?: string
}

const Title = styled.h3<TitleProps>`
    ${DefaultText}
    margin-left: ${p => p.marginLeft ? p.marginLeft : '0px'};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
`;

const SummaryBlock = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 6px;
`;

const SummaryItemBlock = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    margin: 5px;
    margin-left: 20px;
    margin-right: 20px;
`;

const SummaryItemBlockTotal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px;
    background: ${BlueSidely};
    border-radius: 4px;
    margin-left: 20px;
`;

const SummaryItemTitle = styled.h4`
    ${DefaultText}
    font-size: 14px;
    line-height: 21px;
`;

interface SummaryItemValue {
  color?: string
}

const SummaryItemValue = styled.h3`
    ${DefaultText}
    color: ${p => p.color ? p.color : SidelyBlack};
    font-size: 20px;
    line-height: 30px;
    text-align: center;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
`;

const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 2%;
    justify-content: flex-end;
`;

const FormTitle = styled.p`
    ${DefaultText}
    font-size: 12px;
    line-height: 21px;
    align-self: center;
    margin-right: 2%;
`;

const FormInputBlock = styled.div``;

const FormButton = styled(DefaultButton)`
    width: 200px;
    height: 60px;
`;

const AddressBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

const EditContainer = styled.div`
    position: relative;
`;

const Area = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 6px;
    height: 150px;
    width: 350px;
    max-width: 350px;
    min-width: 250px;
    font-size: 13px;
    padding: 10px;
`;

const Edit = styled.div`
    background-image: url('${penUrl}');
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    left: 93%;
    top: 5%;
`;

export {
	Container,
	ContainerTop,
	ContainerBottom,
	Title,
	Row,
	SummaryBlock,
	SummaryItemBlock,
	SummaryItemTitle,
	SummaryItemValue,
	Form,
	FormRow,
	FormTitle,
	FormInputBlock,
	FormButton,
	AddressBlock,
	Area,
	Separator,
	SummaryItemBlockTotal,
	AddressContainer,
	Edit,
	EditContainer
};
