import { URL_FOUNDATION } from '../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION ?? ''}/api`;
const token: string | null = cookies.get('id_token') || null;

export async function postExport(output: string, query: string, kind): Promise<unknown> {
	axios.defaults.headers.common.Authorization = `${token ?? ''}`;
	return await axios.post(`${PATH}/export${query}`, {
		output,
		kind
	});
}
