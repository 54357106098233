/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
// eslint-disable-next-line max-classes-per-file
import FreeText from './previews/FreeText';
import Numerical from './previews/Numerical';
import DropdownList from './previews/DropdownList';
import Checkbox from './previews/Checkbox';
import TextArea from './previews/TextArea';

// const Header = <div>Ini Header</div>;

const FormPreviews = {};

FormPreviews.FreeText = FreeText;
FormPreviews.Numerical = Numerical;
FormPreviews.DropdownList = DropdownList;
FormPreviews.Checkbox = Checkbox;
FormPreviews.TextArea = TextArea;

export default FormPreviews;
