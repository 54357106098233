import { Slider } from '@mui/material';
import * as React from 'react';

export default function SliderFilter(props) {
	const { minValue, maxValue, value, color = '', reverted, label, valueAlwaysDisplay, step = 1, handleChangeCommited, handleChange, isActive } = props;

	return (
		<div>
			<Slider
				getAriaLabel={() => label}
				value={value}
				defaultValue={[minValue, maxValue]}
				valueLabelDisplay={valueAlwaysDisplay ? 'on' : 'auto'}
				step={step}
				sx={{
					color,
					'& .MuiSlider-thumb': {
						width: '10px',
						height: '10px'
					},
					'& .MuiSlider-track': {
						height: '8px'
					},
					'& .MuiSlider-valueLabel': {
						top: '0',
						backgroundColor: 'transparent',
						fontSize: '0.7rem',
						color: 'black'
					},
					'& .Mui-active': {
						boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16) !important'
					}
				}}
				onChange={(event, value) => {
					handleChange(label, value);
				}}
				onChangeCommitted={(event, value) => {
					handleChangeCommited(label, value);
				}}
				disabled={!isActive}
				disableSwap
			/>
		</div>
	);
}
