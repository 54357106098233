import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';
import { handleErrorImport, handleOpportunities } from '../../helpers/error';
import { modalErrorValidate } from './modal.action';

const cookies = new Cookies();

export const CREATE_OPPORTUNITIES_REQUEST = 'CREATE_OPPORTUNITIES_REQUEST';
export const CREATE_OPPORTUNITIES_SUCCESS = 'CREATE_OPPORTUNITIES_SUCCESS';
export const CREATE_OPPORTUNITIES_ERROR = 'CREATE_OPPORTUNITIES_ERROR';

export const FETCH_OPPORTUNITIES_REQUEST = 'FETCH_OPPORTUNITIES_REQUEST';
export const FETCH_OPPORTUNITIES_SUCCESS = 'FETCH_OPPORTUNITIES_SUCCESS';
export const FETCH_OPPORTUNITIES_ERROR = 'FETCH_OPPORTUNITIES_ERROR';

export const UPDATE_OPPORTUNITIES_REQUEST = 'UPDATE_OPPORTUNITIES_REQUEST';
export const UPDATE_OPPORTUNITIES_SUCCESS = 'UPDATE_OPPORTUNITIES_SUCCESS';
export const UPDATE_OPPORTUNITIES_ERROR = 'UPDATE_OPPORTUNITIES_ERROR';

export const DETAIL_OPPORTUNITIES_REQUEST = 'DETAIL_OPPORTUNITIES_REQUEST';
export const DETAIL_OPPORTUNITIES_SUCCESS = 'DETAIL_OPPORTUNITIES_SUCCESS';
export const DETAIL_OPPORTUNITIES_ERROR = 'DETAIL_OPPORTUNITIES_ERROR';

export const DELETE_OPPORTUNITIES_REQUEST = 'UPDATE_OPPORTUNITIES_REQUEST';
export const DELETE_OPPORTUNITIES_ERROR = 'UPDATE_OPPORTUNITIES_ERROR';

export const NO_PAGINATION_OPPORTUNITIES_REQUEST =
  'NO_PAGINATION_OPPORTUNITIES_REQUEST';
export const NO_PAGINATION_OPPORTUNITIES_SUCCESS =
  'NO_PAGINATION_OPPORTUNITIES_SUCCESS';
export const NO_PAGINATION_OPPORTUNITIES_ERROR =
  'NO_PAGINATION_OPPORTUNITIES_ERROR';

export const FETCH_OPPORTUNITIES_STATUS_REQUEST =
  'FETCH_OPPORTUNITIES_STATUS_REQUEST';
export const FETCH_OPPORTUNITIES_STATUS_SUCCESS =
  'FETCH_OPPORTUNITIES_STATUS_SUCCESS';
export const FETCH_OPPORTUNITIES_STATUS_ERROR =
  'FETCH_OPPORTUNITIES_STATUS_ERROR';

export const FILTER_DATA = 'FILTER_DATA';
export const SORT_DATA = 'SORT_DATA';
export const SET_INITIAL_FILTER = 'SET_INITIAL_FILTER';
export const DEFAULT_FILTER = 'DEFAULT_FILTER';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

// CREATE LINKED ELEMENTS

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';

export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_ERROR = 'CREATE_DOCUMENT_ERROR';

// GET LINKED ELEMENTS

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const GET_EVENT_DETAIL_REQUEST = 'GET_EVENT_DETAIL_REQUEST';
export const GET_EVENT_DETAIL_SUCCESS = 'GET_EVENT_DETAIL_SUCCESS';
export const GET_EVENT_DETAIL_ERROR = 'GET_EVENT_DETAIL_ERROR';

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_ERROR = 'GET_NOTES_ERROR';

export const GET_NOTE_DETAIL_REQUEST = 'GET_NOTE_DETAIL_REQUEST';
export const GET_NOTE_DETAIL_SUCCESS = 'GET_NOTE_DETAIL_SUCCESS';
export const GET_NOTE_DETAIL_ERROR = 'GET_NOTE_DETAIL_ERROR';

export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';

// MODIFY LINKED ELEMENTS
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

export const DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const SEARCH_DATA = 'SEARCH_DATA';

const PATH = `${URL_API}/api`;

export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_OPPORTUNITIES_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/opportunities`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_OPPORTUNITIES_SUCCESS,
						payload: response.data
					});
					return response;
				},
				error => {
					dispatch({
						type: CREATE_OPPORTUNITIES_ERROR,
						error: error.response
					});
					handleOpportunities(error.response);
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_OPPORTUNITIES_ERROR,
					error
				});
				throw error;
			});
	};
}

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_OPPORTUNITIES_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/opportunities`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_OPPORTUNITIES_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_OPPORTUNITIES_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_OPPORTUNITIES_ERROR,
					error
				});

				throw error;
			});
	};
}

export function updateData(data, id, isloading) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		if (isloading === undefined) {
			dispatch({
				type: UPDATE_OPPORTUNITIES_REQUEST,
				loading: true
			});
		}
		return axios({
			method: 'PUT',
			url: `${PATH}/opportunities/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_OPPORTUNITIES_SUCCESS,
					payload: response.data
				});
				return response;
			},
			error => {
				dispatch({
					type: UPDATE_OPPORTUNITIES_ERROR,
					error: error.response
				});
				handleOpportunities(error.response);
				throw error;
			}
		);
	};
}

export function deleteData(id, isRedirect) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const paramsData = JSON.parse(localStorage.getItem('params'));
	return dispatch => {
		dispatch({
			type: DELETE_OPPORTUNITIES_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/opportunities/${id}`
		}).then(
			() => {
				if (isRedirect) {
					window.location.href = '/opportunities';
				} else {
					dispatch(fetchData(paramsData));
				}
			},
			error => {
				// dispatch({ type: DELETE_OPPORTUNITIES_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function bulkDelete(data, limit) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/opportunities`,
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json'
				},
				data
			});
			dispatch(fetchData({ limit }));
		} catch (error) {
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_OPPORTUNITIES_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/opportunities/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_OPPORTUNITIES_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_OPPORTUNITIES_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_OPPORTUNITIES_ERROR,
					error
				});
				throw error;
			});
	};
}

export function fetchDataNoPagination(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: NO_PAGINATION_OPPORTUNITIES_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/opportunities/AllRefactor`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: NO_PAGINATION_OPPORTUNITIES_SUCCESS,
						payload: response.data
					});
				},
				error => {
					if (error.response.status === 401) {
						dispatch(logoutUser());
					} else if (error.response.data.errors === 'empty') {
						dispatch({
							type: NO_PAGINATION_OPPORTUNITIES_ERROR,
							error: []
						});
					} else {
						dispatch({
							type: NO_PAGINATION_OPPORTUNITIES_ERROR,
							error: error.response
						});
					}
				}
			)
			.catch(error => {
				dispatch({
					type: NO_PAGINATION_OPPORTUNITIES_ERROR,
					error
				});

				throw error;
			});
	};
}

export function fetchStatus(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_OPPORTUNITIES_STATUS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/opportunityStatuses`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_OPPORTUNITIES_STATUS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_OPPORTUNITIES_STATUS_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_OPPORTUNITIES_STATUS_ERROR,
					error
				});

				throw error;
			});
	};
}

export const filterData = data => async dispatch => {
	dispatch({
		type: FILTER_DATA,
		payload: data
	});
};

export const setDefaultFilter = data => async dispatch => {
	dispatch({
		type: DEFAULT_FILTER,
		payload: data
	});
};

export const sortData = data => async dispatch => {
	dispatch({
		type: SORT_DATA,
		payload: data
	});
};

export function importExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: IMPORT_EXCEL_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/opportunities/importExcel`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: IMPORT_EXCEL_SUCCESS,
						payload: response.data
					});
					window.location.href = '/opportunities';
				},
				error => {
					handleErrorImport(
						error,
						dispatch,
						IMPORT_EXCEL_ERROR,
						modalErrorValidate
					);
				}
			)
			.catch(error => {
				dispatch({
					type: IMPORT_EXCEL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function exportExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/opportunities/exportExcel`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Opportunities.xlsx');
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				// dispatch({ type: FETCH_COMPANIES_ERROR, error });

				throw error;
			});
}

export function exportCsv(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/opportunities/exportCsv`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Opportunities.csv');
					document.body.appendChild(link);
					link.click();
				},
				error => {
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				throw error;
			});
}

export function createDocument(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_DOCUMENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/opportunityFiles/`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_DOCUMENT_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${id.opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_DOCUMENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_DOCUMENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createNote(data, opportunityId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_NOTE_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/notes/`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_NOTE_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_NOTE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_NOTE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createEvent(data, opportunityId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/events/`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_EVENT_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_EVENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}

// GET LINKED ELEMENTS
export function getOrders(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_ORDERS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/contacts/opportunity/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: GET_ORDERS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_ORDERS_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_ORDERS_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getEvents(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_EVENTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/events`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: GET_EVENTS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_EVENTS_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_EVENTS_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getEventDetail(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_EVENT_DETAIL_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/events/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: GET_EVENT_DETAIL_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_EVENT_DETAIL_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_EVENT_DETAIL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getNotes(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_NOTES_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/notes`,
			params: { ...data, sort: 'desc' }
		})
			.then(
				response => {
					dispatch({
						type: GET_NOTES_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_NOTES_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_NOTES_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getNoteDetail(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_NOTE_DETAIL_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/notes/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: GET_NOTE_DETAIL_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_NOTE_DETAIL_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_NOTE_DETAIL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getDocuments(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_DOCUMENTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/opportunityFiles`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: GET_DOCUMENTS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_DOCUMENTS_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_DOCUMENTS_ERROR,
					error
				});
				throw error;
			});
	};
}

// MODIFY LINKED ELEMENTS
export function deleteDocument(id, opportunityId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_DOCUMENT_REQUEST,
			id
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/opportunityFiles/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				() => {
					dispatch({
						type: DELETE_DOCUMENT_SUCCESS
					});
					// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: DELETE_DOCUMENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: DELETE_DOCUMENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteNote(id, opportunityId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_NOTE_REQUEST,
			id
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/notes/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				() => {
					dispatch({
						type: DELETE_NOTE_SUCCESS
					});
					// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: DELETE_NOTE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: DELETE_NOTE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function editNote(data, id, opportunityId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_NOTE_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/notes/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_NOTE_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data updated!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_NOTE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_NOTE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteEvent(id, opportunityId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_EVENT_REQUEST,
			id
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/events/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				() => {
					dispatch({
						type: DELETE_EVENT_SUCCESS
					});
					// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: DELETE_EVENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: DELETE_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function editEvent(data, id, opportunityId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/events/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_EVENT_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data updated!', 'success').then(() => {
					window.location.href = `/opportunities/detail/${opportunityId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_EVENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export const setInitFilter = data => async dispatch => {
	dispatch({
		type: SET_INITIAL_FILTER,
		payload: data
	});
};

export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';

export const setStatusFilter = data => async dispatch => {
	dispatch({
		type: SET_STATUS_FILTER,
		payload: data
	});
};

export const searchData = data => async dispatch => {
	dispatch({
		type: SEARCH_DATA,
		payload: data
	});
};
