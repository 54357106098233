import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely } from '../../../styles/global/css/Utils';
import Left from 'images/icon/left.png';
import Right from 'images/icon/right.png';

interface ModalLeftProps {
  isOpen: boolean
}

const ModalLeftContainer = styled.div<ModalLeftProps>`
    transition: width 0.2s ease-out;
    display: flex;
    height: calc(100vh - 118px);
    width: ${p => p.isOpen ? '290px' : '15px'};
    position: relative;
    left: -11px;
`;

const ModalLeftBlockContainer = styled.div<ModalLeftProps>`
    transition: display 0.2s ease-out;
    width: 100%;
    background-color: #FFFFFF;
    display: ${p => p.isOpen ? 'block' : 'none'};
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
`;

const ModalLeftButton = styled.button<ModalLeftProps>`
    max-height: 82px;
    max-width: 13px;
    min-width: 13px;
    min-height: 82px;
    display: block;
    margin-top: 35vh;
    background-image: url(${p => p.isOpen ? Left : Right});
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${BlueSidely};
    border-style: none;
    cursor: pointer;
    border-radius: 3px;
    border-radius: 0 3px 3px 0;
`;

const ModalLeftTitleContainer = styled.div`
    /* border-bottom-style: solid; */
    /* border-width: thin; */
    /* border-color: #edf1f3; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

const ModalLeftTitle = styled.h3`
    ${DefaultText}
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    color: rgb(50,60,71);
    font-weight: 700;
`;

const ModalLeftReset = styled.p`
    ${DefaultText}
    height: fit-content;
    color: ${BlueSidely};
    border: 1px solid ${BlueSidely};
    cursor: pointer;
    padding: 3px 15px;
    border-radius: 5px;
    font-size: 11px;
    margin-right: 1em;
`;


const ModalLeftBody = styled.div`
    ${DefaultText}
    padding-top: 10px;
    height: calc(100vh - 168px);
    overflow-y: auto;
`;

export {
	ModalLeftContainer,
	ModalLeftTitleContainer,
	ModalLeftTitle,
	ModalLeftBody,
	ModalLeftBlockContainer,
	ModalLeftButton,
	ModalLeftReset
};
