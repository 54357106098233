/* eslint-disable no-unused-vars */
import React from 'react';

export const required = value => (value ? undefined : 'Required');

export const emailValid = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
		? 'Invalid email address'
		: undefined;

export const maxLength = max => value =>
	value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = min => value =>
	value && value.length < min ? `Must be ${min} characters or less` : undefined;

export const maxLength300 = maxLength(300);

export const minLength6 = minLength(6);

export const minValue0 = value => {
	if (value < 0) {
		return 0;
	}
	return 'value';
};

export const minValue1 = value => {
	if (value < 1) {
		return 1;
	} else {
		return value;
	}
};

export function formatNumber(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function formatNumberComma(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const normalizeNumber = value => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');
	return formatNumber(onlyNums);
};

export const lessThan = otherField => (value, previousValue, allValues) =>
	value && parseFloat(value) < parseFloat(allValues.values[otherField])
		? undefined
		: `value must not be greater than ${otherField}`;

export const greaterThan = otherField => (value, previousValue, allValues) =>
	value && parseFloat(value) > parseFloat(allValues.values[otherField])
		? undefined
		: `value cannot be lower than ${otherField}`;

export const renderField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	requiredStar,
	id,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<input
				{...input}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				id={id}
				className={`border-purple form-control ${
					touched && error ? 'focus-error' : ''
				}`}
			/>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);