import * as React from 'react';
import { CheckoutEvolutionsPerGranularity, LatestShelfAuditPerCompany, getCompanyLastCheckouts, getCompanyLastShelfAudits } from './action';
import { CompanyDetailDisplayer, CompanyDetailDisplayerWrapper, TitleAndChild } from './Detail';
import { Translate } from '../../../styles/global/translate';
import { Evolution, Percentage } from '../../reports/interpretor/ReportInterpretor';
import * as moment from 'moment';
import styled from 'styled-components';
import { GreySidely2 } from '../../../styles/global/css/Utils';
import { formatCurrency } from '../../reports/utils';
import RestrictedInverted from '../../permissions/RestrictedInverted';
import { FlexDiv } from '../../products/style';

export const SyledCompanyTable = styled.div<{width?: string}>`
	${p => p.width ? `width: ${p.width};` : ''}
	table {
		font-size: 13px;
		tr > td:first-child {
			color: ${GreySidely2};
			padding-right: 10px;
			text-align: right;
			&:after {
				content: ":";
			}
		}
		tr > td:last-child {
			width: 100%;
			display: flex;
			align-items: center;
		}
		tr > td {
			gap: 0.25em;
			padding-bottom: 10px;
			&:empty {
				&:before {
					content: '-';
				}
			}
		}
		tr:last-child > td {
			padding-bottom: 0px;
		}
	}
`;

const ClickableDiv = styled.div`
	cursor: pointer;
	font-weight: 500;
`;

const TextAndEvolution = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25em;
`;



export default function KeyResults(props: {
	clientCompanyId: number,
	toggleShelfAuditPopup: (id: number, full) => void
}) {
	const [lastestShelfAudit, setLastestShelfAudit] = React.useState<LatestShelfAuditPerCompany[]>([]);
	const [checkoutEvolutionsPerGran, setCheckoutEvolutionPerGran] = React.useState<CheckoutEvolutionsPerGranularity>();

	React.useEffect(() => {
		if (!props.clientCompanyId) return;
		getCompanyLastShelfAudits(props.clientCompanyId).then(setLastestShelfAudit);
		getCompanyLastCheckouts(props.clientCompanyId).then(res => {
			setCheckoutEvolutionPerGran(res);
		});
	}, [props.clientCompanyId]);

	if (!lastestShelfAudit.length && !checkoutEvolutionsPerGran) return <></>;
	return <FlexDiv flow="column" width='100%' padding='15px 0 0 0'>
		<RestrictedInverted to={{ objectAction: 'ViewForm' }}>
			{lastestShelfAudit.length && <TitleAndChild title={<Translate id='key_results' />} defaultOpen>
				<CompanyDetailDisplayerWrapper>
					{lastestShelfAudit.map(sa => 
						<CompanyDetailDisplayer title={sa.shelf_audit_template_name} key={`shelf-audit[${sa.shelf_audit_template_id}]`}>
							<ClickableDiv onClick={() => props.toggleShelfAuditPopup(sa.shelf_audit_instance_id, false)}>{moment.utc(sa.date).format('L LT')}</ClickableDiv>
						</CompanyDetailDisplayer>)}
					<CompanyDetailDisplayer title={<Translate id='instore_presence_short' />}>
						{lastestShelfAudit && <Percentage percentage={lastestShelfAudit.reduce((acc, sa) => acc + sa.count, 0) / lastestShelfAudit.reduce((acc, sa) => acc + sa.product_count, 0)} />}
					</CompanyDetailDisplayer>
					<CompanyDetailDisplayer title={<Translate id='number_referenced_products' />}>
						{Array.from(new Set(lastestShelfAudit.reduce((acc, sa) => [...acc, ...sa.products], []))).length}
					</CompanyDetailDisplayer>
				</CompanyDetailDisplayerWrapper>
			</TitleAndChild>}
		</RestrictedInverted>

		{checkoutEvolutionsPerGran && <TitleAndChild title={<Translate id='checkout_key_results' />} defaultOpen>
			<CompanyDetailDisplayerWrapper>
				<CompanyDetailDisplayer title={<Translate id='last_checkout' />}>
					{moment(checkoutEvolutionsPerGran.month.date).format('MMM YYYY')}
				</CompanyDetailDisplayer>
				<CompanyDetailDisplayer title={<Translate id='last_checkout_income' />}>
					<TextAndEvolution>{formatCurrency(checkoutEvolutionsPerGran.month.current_sum)} <Evolution kind='small' percentage={checkoutEvolutionsPerGran.month.evolution / 100}/></TextAndEvolution>
				</CompanyDetailDisplayer>
				<CompanyDetailDisplayer title={<Translate id='yearly_checkout_income' />}>
					<TextAndEvolution>{formatCurrency(checkoutEvolutionsPerGran.year.current_sum)} {checkoutEvolutionsPerGran.last_year?.current_sum && <Evolution kind='small' percentage={(checkoutEvolutionsPerGran.year.current_sum - checkoutEvolutionsPerGran.last_year.current_sum) / checkoutEvolutionsPerGran.last_year.current_sum} />}</TextAndEvolution>
				</CompanyDetailDisplayer>
				{checkoutEvolutionsPerGran.last_year && 
						<CompanyDetailDisplayer title={<Translate id='last_year_checkout_income' />}>
							{formatCurrency(checkoutEvolutionsPerGran.last_year.current_sum)}
						</CompanyDetailDisplayer>}
				<CompanyDetailDisplayer title={<Translate id='number_referenced_products' />}>
					<TextAndEvolution>{checkoutEvolutionsPerGran.month.current_products_number} <Evolution kind='small' percentage={checkoutEvolutionsPerGran.month.product_number_evolution / 100}/></TextAndEvolution>
				</CompanyDetailDisplayer>
				{checkoutEvolutionsPerGran.month.product_count !== 0 && 
						<CompanyDetailDisplayer title={<Translate id='presence' />}>
							<TextAndEvolution><Percentage percentage={checkoutEvolutionsPerGran.month.current_products_number / checkoutEvolutionsPerGran.month.product_count} /> <Evolution kind='small' percentage={(checkoutEvolutionsPerGran.month.current_products_number / checkoutEvolutionsPerGran.month.product_count) - (checkoutEvolutionsPerGran.month.previous_products_number / checkoutEvolutionsPerGran.month.product_count)}/></TextAndEvolution>
						</CompanyDetailDisplayer>}
				{checkoutEvolutionsPerGran.month.consumer_sales_unit !== undefined && 
						<CompanyDetailDisplayer title={<Translate id='consumer_sales_unit' />}>
							<TextAndEvolution>{checkoutEvolutionsPerGran.month.consumer_sales_unit} {checkoutEvolutionsPerGran.month.consumer_sales_unit_evolution !== undefined && <Evolution kind='small' percentage={checkoutEvolutionsPerGran.month.consumer_sales_unit_evolution / 100}/>}</TextAndEvolution>
						</CompanyDetailDisplayer>}
			</CompanyDetailDisplayerWrapper>
		</TitleAndChild>}
	</FlexDiv>;
}