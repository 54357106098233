import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_FOUNDATION } from '../../config/keys';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function postMapping(body: { name: string, mapping_type_id: number }): Promise<number> {
	return axios.post(`${URL_FOUNDATION}/api/mappings/external`, body).then(res => res.data);
}