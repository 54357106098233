import * as React from 'react';
import styled from 'styled-components';
import { Owner } from '../../../containers_v2/orders/model/Model';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, GreySidely2 } from '../../../styles/global/css/Utils';
import { LabelStyle } from '../model/Model';
import cheverontBlack from 'images/icon/cheveront_black.svg';

function ContainerCss(height: string, width?: string, justifyContent?: string, isClickable?: boolean, labelUp?: boolean, margin?: string, marginRight?: string, alignSelf?: string): React.CSSProperties {
	return {
		display: 'flex',
		flexDirection: labelUp ? 'column' : 'row',
		height,
		justifyContent: justifyContent || 'center',
		width: width || 'fit-content',
		position: 'relative',
		cursor: isClickable ? 'pointer' : 'auto',
		margin,
		marginRight,
		alignSelf
	};
}

interface LabelContainerProps {
  alignSelf?: string
}

const LabelContainer = styled.div<LabelContainerProps>`
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    align-self: ${p => p.alignSelf ?? 'center'};
    text-align: end;
`;

const Asterisk = styled.p`
    padding: 0;
    margin: 0;
    color: red;
    align-self: center;
    margin-right: 3px;
    
    &:before {
        content: "*";
    }
`;

const Label = styled.p<LabelStyle>`
    ${DefaultText};
    font-size: ${p => p.fontSize ?? '12px'};
    ${p => p.fontWeight ? `font-weight: ${p.fontWeight};` : ''}
    ${p => p.padding ? `padding: ${p.padding};` : ''}
    ${p => p.color ? `color: ${p.color};` : ''}
    line-height: 21px;
    align-self: center;
    text-align: end;
`;

interface DropdownContainerProps {
  marginRight?: string
  margin?: string
  cursor?: string
  disabled?: boolean
}

const DropdownContainer = styled.div<DropdownContainerProps>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-right: ${p => p.marginRight ?? 'initial'};
    margin: ${p => p.margin ?? 'initial'};
    ${p => p.cursor ? `cursor: ${p.cursor};` : ''}
    ${p => p.disabled ? `
        cursor: not-allowed;
        opacity: 0.5;
    ` : ''}
    position:relative;
`;

interface InputContainerProps {
  width?: string
  height?: string
  border?: string
  padding?: string
  backgroundColor?: string
  overflow?: string
}

const InputContainer = styled.div<InputContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${p => p.backgroundColor ?? '#FFFFFF'};
    border: ${p => p.border ?? '1px solid #F2F2F2'};
    box-sizing: border-box;
    border-radius: 4px;
    width: ${p => p.width ? p.width : '400px'};
    height: ${p => p.height ? p.height : '40px'};
    ${p => p.padding ? `padding: ${p.padding};` : ''}
    ${p => p.overflow ? `overflow: ${p.overflow};` : ''}
    justify-content: space-between;
`;

interface InputBlockProps {
  backgroundColor?: string
  fontSize?: number
  fontWeight?: number
  fontColor?: string,
  clickable?: boolean
  margin?: string,
  padding?: string,
}

const InputBlock = styled.input<InputBlockProps>`
    ${DefaultText}
    background: #FFFFFF;
    border: 0px none;
    width: 90%;
    background-color: ${p => p.backgroundColor ? p.backgroundColor : 'white'};
    border-radius: 5px;
    padding: ${p => p.padding ?? '5px'};
    margin: ${p => p.margin ?? '5px'};
    font-size: ${p => p.fontSize ?? '12'}px;
    color: ${p => p.fontColor ?? ''};
    font-weight:  ${({ clickable, fontWeight }) => clickable ? '600' : fontWeight ?? ''};

    &:focus {
        outline: 0px none;
    } 

    &:focus-visible {
        outline: 0px none;
    } 

    &:read-only {
        cursor: pointer;
    }
    ::placeholder {
        color: #D4D0D2;
        opacity: 1;
    }
`;

interface ArrowBlockProps {
  arrowSize?: number
  url?: string
  color?: string
  fontSize?: string
  width?: string
  onClick?: () => void
  isOpened?: boolean
}

const ArrowBlock = styled.div<ArrowBlockProps>`
    padding: 10px;
    cursor: pointer;
    width: ${p => p.width ?? '10%'};
    align-self: center;
    text-align: center;
    color: ${p => p.color ?? ''};
    background-image: url('${p => p.url ? p.url : cheverontBlack}');
    background-origin: initial;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${p => p.arrowSize ?? '9'}px;
  	transform: ${p => p.isOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
    ${p => p.fontSize ? `font-size: ${p.fontSize}` : ''}
`;

const ValidateContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    background-color: white;
`;

const ValidateButton = styled(DefaultButton)`
    width: 75%;
    height: 100%;
`;

interface OptionContainerProps {
  width?: string
  height?: string
  minHeight?: string
  top?: string
  left?: string
  isClose?: boolean
}

const OptionContainer = styled.div<OptionContainerProps>`
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 4px;
    display: ${p => p.isClose ? 'none' : 'flex'};
    flex-direction: column;
    position: absolute;
    z-index: 100;
    width: ${p => p.width ? p.width : '400px'};
    max-height: ${p => p.height ? p.height : '100px'};
    ${p => p.minHeight ? `min-height: ${p.minHeight};` : ''}
    top: ${p => p.top ? p.top : '40px'};
    left: ${p => p.left ? p.left : ''};
    overflow-y: auto;
    box-shadow: rgb(207 207 207) 0px 1px 5px;
`;

function OptionBlockClickable(): React.CSSProperties {
	return {
		width: '100%',
		cursor: 'pointer'
	};
}

interface OptionListProps {
    padding?: string;
}

const OptionList = styled.ul<OptionListProps>`
    padding:${p => p.padding ?? ''};
`;

interface OptionBlockProps {
  backgroundColor?: string
  heightLength?: string
  width?: string
  padding?: string
  borderBottom?: string
  disabled?: boolean
}

const OptionBlock = styled.div<OptionBlockProps>`
    height: ${p => p.heightLength ? p.heightLength : 'auto'};
    display: flex;
    padding: ${p => p.padding ? p.padding : '5px'};
    text-align: start;
    cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
    background-color: ${p => p.backgroundColor ? p.backgroundColor : 'white'};
    border-radius: 5px;
    margin: 5px;
    width: ${p => p.width ? p.width : 'initial'};
    ${p => p.borderBottom ? `border-bottom: ${p.borderBottom};` : ''}
    gap: 10px;
    ${p => p.disabled ? 'opacity: 0.3;' : ''}


    &:hover {
        background-color: ${p => p.disabled ? GreySidely2 : p.backgroundColor ? p.backgroundColor : '#CCF0FF'};
    }
`;

const LoadMoreBlock = styled.div`
    text-align: center;
    cursor: pointer;
`;

interface OptionImageProps {
  width?: string
  height?: string
}

const OptionImage = styled.img<OptionImageProps>`
    width: ${p => p.width ?? '26px'};
    height: ${p => p.height ?? '26px'};
    border-radius: 15px;
    align-self: center;
    margin-right: 5px;
    margin-left: 5px;
  	flex-shrink: 0;
`;

const OptionImageJsx = styled.div<{ center?: boolean }>`
    margin-right: 5px;
    margin-left: 5px;
    ${p => p.center ? 'top: 50%; transform: translateY(-50%); position: relative;' : ''}
    height: fit-content;
`;

interface OptionTextProps {
  size?: string
  color?: string
  weight?: string
  ellipsis?: boolean
}

const OptionText = styled.h5<OptionTextProps>`
    ${DefaultText}
    font-size: ${p => p.size ? p.size : '12px'};
    line-height: 16px;
    font-weight: ${p => p.weight ? p.weight : '400'};
    align-self: center;
    color: ${p => p.color ? p.color : 'initial'};
    ${p => p.ellipsis ? `
        text-overflow: ellipsis;
        overflow: hidden;
    ` : ''}
`;

const LoadMoreText = styled.p`
    color: rgb(0, 178, 255);
    cursor: pointer;
    font-size: 13px; 
`;

const CleanButton = styled.button`
    font-size: 14px;
    color: hsl(0,0%,80%);
`;

const NoResultText = styled.p`
    text-align: center;
    font-weight: 500;
    margin-top: 5%;
    font-size: 14px;
`;

interface LinkProps {
  width: string
  height: string
  empty?: boolean
}

const Link = styled.a<LinkProps>`
    width: ${p => p.width};
    height: ${p => p.height};
    position: absolute;
    z-index: 1;
    cursor: ${p => p.empty ? 'default' : 'pointer'};
`;

const SortBlock = styled(DefaultImage)`
    width: 15px;
    height: 15px;
    transform: rotate(90deg);
    margin-left: auto;
`;

interface CheckboxProps {
  isActive: boolean
}

const Checkbox = styled.div<CheckboxProps>`
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    border-radius: 4px;
    border: 1px solid ${p => p.isActive ? BlueSidely : '#D5D5D5'};
    background: ${p => p.isActive ? BlueSidely : '#FFFFFF'};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-family: monospace;
    cursor: pointer;

    ${p => p.isActive
		? `  
    &:after {
        content: '✔';
        color: #FFFFFF;
    }
    `
		: ''}
    
`;

interface TagNameProps {
  backgroundColor: string
}

const TagBox = styled.div`
    width: 250px;
    height: 38px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    border: 1px solid #F2F2F2;
    align-items: center;
`;

const TagName = styled.p<TagNameProps>`
    ${DefaultText}
    white-space: nowrap;
    background-color: ${p => p.backgroundColor};
    margin: 0 4px;
    padding: 1px 4px;
    border-radius: 5px;
    color: white;
    font-weight: 500;
`;

export const UserLabel = styled.div<{ user?: Owner, me: string }>`
    ${DefaultText}
    font-size: 12px;
    text-overflow: ellipsis;
	overflow: hidden;
    white-space: nowrap;
    ${p => p.user?.status == false ? `color: ${GreySidely2};` : ''}
    &::after {
        ${p => p.user?.isYou
		? `
                content: "(${p.me})";
                margin-left: 5px;
            `
		: ''
}
    }
    
`;

export {
	ContainerCss,
	Label,
	InputBlock,
	Asterisk,
	LabelContainer,
	InputContainer,
	ArrowBlock,
	OptionList,
	OptionBlock,
	OptionContainer,
	DropdownContainer,
	OptionImage,
	OptionText,
	CleanButton,
	LoadMoreText,
	LoadMoreBlock,
	NoResultText,
	Link,
	OptionBlockClickable,
	OptionImageJsx,
	Checkbox,
	SortBlock,
	ValidateContainer,
	ValidateButton,
	TagBox,
	TagName
};
