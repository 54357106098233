/* eslint-disable react/sort-comp */
import React, {Component, Fragment} from 'react';
import swal from 'sweetalert2';
import TableWrapper from './TableWrapper';
import {get} from 'lodash';

const TableListingPage = tableProp => WrappedComponent =>
  (class TableListingHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sort: 'desc',
        page: 1,
        limit: 50,
        status: true,
        gteDate: null,
        lteDate: null,
        incharge: null,
        groupId: null,
        opportunityStatusId: null,
        statusKanban: null,
        clientCompanyId: null,
        alphabet: null,
        tagsId: [],
        typeView: 'list',
        amount: null,
        clientCompanyId: []
      };
    }

    componentDidMount() {
      const {
        setStatusFilter,
        fetchData,
        fetchDataNoPagination,
        data,
        selectOpportunityStatus
      } = this.props;
      this.props.getAlphabet(null);
      selectOpportunityStatus(true);
      this.props.setWidth(false);
      // setStatusFilter({ opportunityStatusId: null, clientCompanyId: null });
      if (data.initFilter) {
        this.setState({
          opportunityStatusId: data.initFilter.opportunityStatusId,
          incharge: data.initFilter.incharge,
          groupId: data.initFilter.groupId,
          gteDate: data.initFilter.gteDate,
          lteDate: data.initFilter.lteDate
        });
        fetchData(data.initFilter);
      } else {
        const paramsData = JSON.parse(localStorage.getItem('params'));
        const params = { ...this.state, ...paramsData };
        delete paramsData['periode'];
        setStatusFilter({
          opportunityStatusId: paramsData.opportunityStatusId || null,
          clientCompanyId: paramsData.clientCompanyId || null
        });
        fetchData({ ...paramsData });
        this.setState({ ...params });
        fetchDataNoPagination();
      }
    }

    componentWillReceiveProps(nextProps) {
      const {
        data,
        current,
        fetchData,
        fetchDataNoPagination,
        status
      } = this.props;
      const { opportunityStatusId } = this.state;

      const pr = JSON.parse(localStorage.getItem('params'));
      if (status !== nextProps.status) {
        const board = {};
        const amount = {};
        const statusCode = [];
        nextProps.status.map(val => {
          board[`status${val.id}`] = [];
          amount[`status${val.id}`] = null;
        });
        nextProps.status.map(data => {
          if (data.name.length > 10) {
            this.props.setWidth(true);
          }
          statusCode.push({
            probability: data.probability,
            id: data.id,
            color: (data.colorStatus && data.colorStatus.colorCode) || '#fff'
          });
        });
        this.setState({ statusCode, board, amount });
      }

      if (nextProps.data.defaultFilter === true) {
        if (current !== nextProps.current) {
          if (nextProps.current.role.slug === 'sales') {
            fetchData({
              ...pr,
              page: 1,
              incharge: nextProps.current.id,
              opportunityStatusId: opportunityStatusId
            });
            fetchDataNoPagination({ incharge: nextProps.current.id });
            localStorage.setItem(
              'params',
              JSON.stringify({ ...pr, incharge: nextProps.current.id })
            );
            this.setState({
              incharge: nextProps.current.id
            });
          }

          if (nextProps.current.role.slug === 'manager') {
            if (nextProps.current.group.length > 1) {
              fetchData({
                ...pr,
                page: 1,
                opportunityStatusId: opportunityStatusId,
                groupId: nextProps.current.group[1].id
              });
              fetchDataNoPagination({ groupId: nextProps.current.group[1].id });
              this.setState({
                groupId: nextProps.current.group[1].id,
                page: 1
              });
              localStorage.setItem(
                'params',
                JSON.stringify({
                  ...pr,
                  groupId: nextProps.current.group[1].id
                })
              );
            } else {
              fetchData({
                ...pr,
                page: 1,
                opportunityStatusId: opportunityStatusId
              });
              localStorage.setItem(
                'params',
                JSON.stringify({ ...pr, page: 1 })
              );
              fetchDataNoPagination();
              this.setState({ page: 1 });
            }
          }
        }
      }

      // On Sort
      if (data.sort !== nextProps.data.sort) {
        this.handleSort(nextProps.data.sort, nextProps.data.sortField);
      }

      if (data.search !== nextProps.data.search) {
        const title = nextProps.data.search;
        localStorage.setItem(
          'params',
          JSON.stringify({ ...pr, title, page: 1 })
        );
        fetchData({
          ...pr,
          page: 1,
          title
        });
        this.setState({
          title
        });
      }
    }

    closeFilterCompanyModal = () => {
      const { togleFilterCompaniesModal } = this.props;
      togleFilterCompaniesModal(false);
    };

    filterByAlphabet = val => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, alphabet: val });
      localStorage.setItem('params', JSON.stringify({ ...pr, alphabet: val }));
      this.setState({ page: 1, alphabet: val });
    };

    handleSort = (sort, by) => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, sort, by });
      localStorage.setItem('params', JSON.stringify({ ...pr, sort, by }));
      this.setState({ page: 1, sort, by });
    };

    handleLimit = limit => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, limit });
      localStorage.setItem('params', JSON.stringify({ ...pr, limit }));
      this.setState({ page: 1, limit });
    };

    handleFilter = data => {
      const { fetchData, setStatusFilter } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      const { opportunityStatusId, clientCompanyId } = this.state;
      const params = {
        ...pr,
        page: 1,
        opportunityStatusId:
          data.opportunityStatusId === 'all'
            ? null
            : data.opportunityStatusId || opportunityStatusId
      };
      fetchData(params);
      localStorage.setItem('params', JSON.stringify(params));
      this.setState({
        page: 1,
        opportunityStatusId:
          data.opportunityStatusId === 'all'
            ? null
            : data.opportunityStatusId || opportunityStatusId
      });
      if (setStatusFilter) {
        setStatusFilter({
          opportunityStatusId:
            data.opportunityStatusId === 'all'
              ? null
              : data.opportunityStatusId || opportunityStatusId,
          clientCompanyId
        });
      }
    };

    onInitViewList = () => {
      const { fetchData, current } = this.props;
      const {
        sort,
        by,
        tagsId,
        limit,
        groupId,
        incharge,
        gteDate,
        lteDate,
        opportunityStatusId
      } = this.state;
      const paramsData = {
        page: 1,
        sort,
        by,
        tagsId,
        limit,
        gteDate,
        lteDate,
        groupId,
        opportunityStatusId
      };
      if (current.role.slug !== 'sales') {
        const params = { ...paramsData, incharge };
        fetchData(params);
        localStorage.setItem('params', JSON.stringify(params));
      } else {
        const params = {
          ...paramsData,
          incharge: current.id
        };
        fetchData(params);
        localStorage.setItem('params', JSON.stringify(params));
      }
      this.setState({
        page: 1
      });
    };

    onInitViewKanban = () => {
      const { fetchDataNoPagination, current } = this.props;
      const { tagsId, groupId, incharge, gteDate, lteDate } = this.state;
      if (current.role.slug !== 'sales') {
        fetchDataNoPagination({
          gteDate,
          lteDate,
          incharge,
          groupId,
          tagsId
        });
      } else {
        fetchDataNoPagination({
          gteDate,
          lteDate,
          incharge: current.id,
          groupId
        });
      }
    };

    onFilterByDeadline = (type, data) => {
      const { fetchData, fetchDataNoPagination } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      const { groupId, incharge } = this.state;
      this.props.selectOpportunityStatus(true);
      if (type === 'list') {
        const params = {
          ...pr,
          page: 1,
          gteDate: data ? data.gteDate || null : null,
          lteDate: data ? data.lteDate || null : null
        };
        fetchData(params);
        localStorage.setItem('params', JSON.stringify(params));
      } else if (type === 'mixt') {
        fetchDataNoPagination({
          gteDate: data ? data.gteDate || null : null,
          lteDate: data ? data.lteDate || null : null,
          incharge,
          groupId
        });
      }

      this.setState({
        page: 1,
        gteDate: data ? data.gteDate || null : null,
        lteDate: data ? data.lteDate || null : null
      });
    };

    handleFilterTag = value => {
      const {
        sort,
        by,
        limit,
        page,
        groupId,
        incharge,
        gteDate,
        lteDate,
        alphabet,
        opportunityStatusId,
        typeView
      } = this.state;
      const { fetchData, fetchDataNoPagination } = this.props;
      this.props.selectOpportunityStatus(true);
      const params = {
        gteDate,
        lteDate,
        tagsId: value,
        opportunityStatusId,
        groupId,
        incharge
      };
      const paramsList = {
        page,
        sort,
        by,
        alphabet,
        limit
      };
      if (typeView !== 'mixt') {
        localStorage.setItem(
          'params',
          JSON.stringify({ ...params, ...paramsList })
        );
        fetchData({ ...paramsList, ...params });
      } else {
        fetchDataNoPagination(params);
      }
      this.setState({ tagsId: value });
    };

    onFilterByOwner = (type, data) => {
      const { fetchData, fetchDataNoPagination } = this.props;
      this.props.selectOpportunityStatus(true);
      const pr = JSON.parse(localStorage.getItem('params'));
      const { gteDate, lteDate } = this.state;
      if (type === 'list') {
        const params = {
          ...pr,
          page: 1,
          incharge: data ? data.incharge || null : null,
          groupId: data ? data.groupId || null : null
        };
        fetchData(params);
        localStorage.setItem('params', JSON.stringify(params));
      } else if (type === 'mixt') {
        fetchDataNoPagination({
          gteDate,
          lteDate,
          incharge: data ? data.incharge || null : null,
          groupId: data ? data.groupId || null : null
        });
      }

      this.setState({
        page: 1,
        incharge: data ? data.incharge || null : null,
        groupId: data ? data.groupId || null : null
      });
    };

    onNext = () => {
      const { page } = this.state;
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, page: page + 1 });
      localStorage.setItem('params', JSON.stringify({ ...pr, page: page + 1 }));
      this.setState({ page: page + 1 });
    };

    onPrev = () => {
      const { page } = this.state;
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, page: page - 1 });
      localStorage.setItem('params', JSON.stringify({ ...pr, page: page - 1 }));
      this.setState({ page: page - 1 });
    };

    onFirstPage = () => {
      const { page } = this.state;
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, page: page + 1 });
      localStorage.setItem('params', JSON.stringify({ ...pr, page: 1 }));
      this.setState({ page: 1 });
    };

    onLastPage = () => {
      const { fetchData, data } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, page: data.pagination.lastPage });
      localStorage.setItem(
        'params',
        JSON.stringify({ ...pr, page: data.pagination.lastPage })
      );
      this.setState({ page: data.pagination.lastPage });
    };

    handleSearch = (clientCompanyId, childId) => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, clientCompanyId, by: 'clientCompanyName' });
      this.setState({ clientCompanyId, by: 'clientCompanyName' });
    };

    toggle() {
      this.setState({
        modal: false
      });
    }

    onCloseFilterStatus = () => {
      const { filterOpportunityStatus } = this.props;
      filterOpportunityStatus(false);
    };

    onExport = (id, type) => {
      const { exportExcel, exportCsv } = this.props;
      const {
        opportunityStatusId,
        groupId,
        gteDate,
        lteDate,
        incharge,
        clientCompanyId
      } = this.state;

      if (type === 'csv') {
        exportCsv({
          id,
          gteDate,
          lteDate,
          groupId,
          opportunityStatusId,
          incharge,
          clientCompanyId
        });
      } else {
        exportExcel({
          id,
          groupId,
          gteDate,
          lteDate,
          opportunityStatusId,
          incharge,
          clientCompanyId
        });
      }
    };

    onImport = formData => {
      const { importExcel } = this.props;
      importExcel(formData);
    };

    getTypeView = value => {
      this.setState({ typeView: value });
    };

    onBulkDelete = id => {
      const { bulkDelete } = this.props;

      if (id.length > 0) {
        swal
          .fire({
            title: 'Attention!',
            text: `Are you sure you want to delete selected data from the database? Any related elements will also be deleted.`,
            type: 'warning',
            confirmButtonText: 'Yes, I do',
            confirmButtonColor: '#f7685b'
          })
          .then(result => {
            if (result.value) {
              bulkDelete(
                {
                  id
                },
                this.state.limit
              );
            }
          });
      } else {
        swal.fire({
          title: 'Attention!',
          text: `No data selected!.`,
          type: 'warning',
          showConfirmButton: false
        });
      }
    };

    handleFilterCompanies = param => {
      const { fetchData, setStatusFilter } = this.props;
      const { opportunityStatusId } = this.state;
      const pr = JSON.parse(localStorage.getItem('params'));
      const params = {
        ...pr,
        page: 1,
        clientCompanyId: param.companyId == 'all' ? '' : param.companyId
      };
      fetchData({ ...params });
      if (setStatusFilter) {
        setStatusFilter({
          opportunityStatusId,
          clientCompanyId: param.companyId
        });
      }
      this.setState({
        page: 1,
        clientCompanyId: param.companyId
      });
      localStorage.setItem('params', JSON.stringify({ ...params }));
    };

    render() {
      const {
        data,
        current,
        modal,
        isLoadingCurrent,
        modalErrorValidate
      } = this.props;
      const { columns } = tableProp;
      const { sort, opportunityStatusId } = this.state;
      const dataNoPagination = get(data, 'dataNoPagination', []);

      return (
        <div className="content-wrapper pt-md-5 mt-md-1">
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card mb-0">
              <div className="card rounded bg-table">
                <Fragment>
                  <TableWrapper
                    loading={isLoadingCurrent || data.isLoading}
                    dataNoPagination={dataNoPagination}
                    data={data.data}
                    // current={current.current}
                    current={current}
                    defaultSortDirection={sort}
                    columns={columns(this.props)}
                    page={data.pagination.currentPage}
                    sizePerPage={data.pagination.recordPerPage}
                    lastPage={data.pagination.lastPage}
                    totalSize={data.pagination.count}
                    opportunityStatusId={opportunityStatusId}
                    onTableChange={this.handleTableChange}
                    handleFilter={this.handleFilter}
                    history={this.props.history}
                    handleSearch={this.handleSearch}
                    disableAddBtn={tableProp.disableAddBtn}
                    customPath={tableProp.customPath}
                    disablePagination={tableProp.disablePagination}
                    customAction={
                      <WrappedComponent {...this.props} state={this.state} />
                    }
                    route={this.props.route}
                    onPageChange={this.onPageChange}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    onFirstPage={this.onFirstPage}
                    onLastPage={this.onLastPage}
                    modal={modal}
                    filterState={
                      data.initFilter && {
                        incharge: data.initFilter.incharge || null,
                        groupId: data.initFilter.groupId || null,
                        periode: data.initFilter.periode || null
                      }
                    }
                    onFilter={this.handleFilter}
                    onCloseFilterStatus={this.onCloseFilterStatus}
                    onFilterByDeadline={this.onFilterByDeadline}
                    onFilterByOwner={this.onFilterByOwner}
                    onInitViewList={this.onInitViewList}
                    onInitViewKanban={this.onInitViewKanban}
                    onExport={this.onExport}
                    onImport={this.onImport}
                    onBulkDelete={this.onBulkDelete}
                    statusKanban={this.state.statusKanban}
                    closeFilterCompanyModal={this.closeFilterCompanyModal}
                    filterCompanies={this.handleFilterCompanies}
                    limit={this.state.limit}
                    handleLimit={this.handleLimit}
                    handleFilterTag={this.handleFilterTag}
                    filterByAlphabet={this.filterByAlphabet}
                    modalErrorValidate={modalErrorValidate}
                    errorValidation={data.errorValidation}
                    selectOpportunityStatus={this.props.selectOpportunityStatus}
                    board={this.state.board}
                    statusCode={this.state.statusCode}
                    getTypeView={this.getTypeView}
                    tagsId={this.state.tagsId}
                    amount={this.state.amount}
                    searchData={this.props.searchData}
                  />
                </Fragment>
              </div>
            </div>
          </div>
        </div>
      );
    }
  });

export default TableListingPage;
