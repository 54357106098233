import close from 'images/icons/orders/close.svg';
import product from 'images/icon/systemSettings/product.svg';
import * as React from 'react';
import { CategoryAndProducts, getProductPerCategories } from './action';
import styled from 'styled-components';
import { BorderColor2 } from '../../../styles/global/css/Utils';
import Popup from '../../popup/Popup';
import { PopupMode } from '../../popup/model/Model';
import { Collapse } from 'reactstrap';
import { Open } from '../../../styles/global/css/Open';
import { Checkbox } from '../../filterList/style/Style';
import { Translate, translateToString } from '../../../styles/global/translate';
import { useRecoilState } from 'recoil';
import { AProductFilter, ProductAtomType } from '../../../atoms/filter/productsFilterAtom';
import { FlexDiv } from '../../../containers_v2/products/style';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';

const Container = styled.div<{ fullScreen?: boolean }>`
	${({ fullScreen }) => fullScreen ? '' : 'width: 180px'};
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid ${BorderColor2};
	width: 100%;
	height: 38px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 12px;
`;

const Logo = styled.img`
	height: 22px;
	padding: 0 7px;
`;

const Text = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
`;

const Arrow = styled.i`
	margin-right: 4px;
	padding: 10px;
	width: 10%;
	color: hsl(0,0%,80%);
`;

function getProductsId(category: CategoryAndProducts, uncheck?: boolean): number[] {
	return [...category.products.reduce((acc, product) => product.checked || uncheck ? [...acc, product.id] : acc, []), ...category.children.reduce((acc, category) => [...acc, ...getProductsId(category, uncheck)], [])];
}

export default function ProductPerCategoryFilter(props: GenericToolbarFilterProps & { atomId: number, max?: number }) {
	const { max } = props;
	const [categories, setCategories] = React.useState<CategoryAndProducts[]>([]);
	const [isOpen, setIsOpen] = React.useState(false);
	const count = categories.reduce((acc, category) => acc + getProductsId(category).length, 0);
	const [_filteredProducts, setFilteredProducts] = useRecoilState(AProductFilter);
	const { filters, setFilters } = React.useContext(ReportFiltersContext);


	let filteredProducts: ProductAtomType;

	if (props.context) {
		if (filters.my_filters) {
			filteredProducts = filters.my_filters.products;
		}
	} else {
		filteredProducts = _filteredProducts;
	}
	React.useEffect(() => {
		getProductPerCategories().then(categories => {
			if (max === undefined) {
				categories.forEach(category => {
					setCategory(category, true);
					if (props.context)
						category.products.forEach(p => filteredProducts?.products?.includes(p.id) ? p.checked = true : p.checked = false);
				});
			}
			if (!filteredProducts && !props.context) {
				const ids = categories.reduce((acc, category) => [...acc, ...getProductsId(category)], []);
				if (props.context) {
					setFilters({ ...filters, my_filters: { ...filters.my_filters, products: { products: ids, all: true } } });
				} else {
					setFilteredProducts({ products: ids, all: true });
				}
			}
			
			setCategories(categories);
		});
	}, []);

	React.useEffect(() => {
		if (filteredProducts) {
			categories.forEach(category => category.products.forEach(product => product.checked = filteredProducts?.products.includes(product.id) ?? false));
			setCategories([...categories]);
		}
		else {
			getProductPerCategories().then(categories => {
				if (max === undefined) {
					categories.forEach(category => setCategory(category, true));
				}
				if (!filteredProducts) {
					const ids = categories.reduce((acc, category) => [...acc, ...getProductsId(category)], []);
					if (props.context) {
						setFilters(filters => ({ ...filters, my_filters: { ...(filters?.my_filters ?? {}), products: { products: ids, all:  true } } }));
					} else {
						setFilteredProducts({ products: ids, all: true });
					}
				}
				setCategories(categories);

			});
		}
	}, [filteredProducts]);

	if (props.hidden) return <></>;

	return <>
		<Container onClick={() => setIsOpen(true)} fullScreen={props.fullScreen}>
			<Logo src={product} />
			{!filteredProducts?.all && <Text>{count} {translateToString('selected')}</Text>}
			{filteredProducts?.all && <Text> {translateToString('report_filters.all_products_selected')}</Text>}
			<Arrow className="fas fa-caret-down" />
		</Container>
		<Popup isOpen={isOpen} onClickOut={() => setIsOpen(false)} popupMode={PopupMode.Centered} popupStyle={{ fitContent: true }}>
			<ProductPerCategoryPopup
				products={categories}
				setAll={() => {
					if (props.context) {
						if (filteredProducts) {
							filteredProducts.all = !filteredProducts.all;
							filteredProducts && setFilters(filters => ({ ...filters, my_filters: { ...(filters?.my_filters ?? {}), products: filteredProducts } }));
						}
					}
					else {
						filteredProducts && setFilteredProducts({ ...filteredProducts, all: !filteredProducts.all });
					}
				}}
				all={filteredProducts?.all ?? false}
				setProducts={categories => {
					const ids = categories.reduce((acc, category) => [...acc, ...getProductsId(category)], []);
					if (props.context) {
						setFilters(filters => ({ ...filters, my_filters: { ...(filters?.my_filters ?? {}), products: { products: ids, all: false } } }));
					} else {
						setFilteredProducts({ products: ids, all: false });
					}
					setCategories(categories);
				}}
				onClose={() => setIsOpen(false)}
				max={max}
			/>
		</Popup>
	</>;
}

const CategoryTitle = styled.h4`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 0px;
	padding-bottom: 1px;
	position: sticky;
	z-index: 1;
	top: 0;
	background: white;
	padding-left: 44px;
	margin-left: -44px;
	font-size: 12px;
	font-weight: 500;
`;

const DepthPadder = styled.div<{ depth: number }>`
	padding-left: 44px;
	${p => p.depth === 1 ? '' : 'padding-bottom: 5px;'}
`;

const Product = styled.div`
	font-size: 12px;
	display: flex;
	align-items: center;
	gap: 8px;
`;

function isCategoryActive(category: CategoryAndProducts): boolean {
	return category.products.every(p => p.checked) && category.children.every(isCategoryActive);
}

function setCategory(category: CategoryAndProducts, value: boolean): void {
	category.products.forEach(p => p.checked = value);
	category.children.forEach(c => setCategory(c, value));
}

function hasCategoryProducts(category: CategoryAndProducts): boolean {
	return category.products.length !== 0 || category.children.some(hasCategoryProducts);
}

function RecursiveCategoryDisplayer(props: {
	category: CategoryAndProducts,
	depth: number,
	setProducts: () => void,
	max: number | undefined,
	disabled: boolean,
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const isActive = isCategoryActive(props.category);
	const count = getProductsId(props.category, true).length;

	if (!hasCategoryProducts(props.category)) return <></>;
	return <>
		<CategoryTitle>
			<Open isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} width={12} height={8} />
			<Checkbox
				isActive={isActive}
				size='13px'
				onClick={() => {
					if (props.disabled) return;
					if (!isActive && props.max !== undefined && props.max - count < 0) return;
					setCategory(props.category, !isActive);
					props.setProducts();
				}}
				disabled={props.disabled}
			/>
			{props.category.name ?? translateToString('no_category')}
		</CategoryTitle>
		<DepthPadder depth={props.depth}>
			<Collapse isOpen={isOpen}>
				{props.category.products.length === 0 && props.category.children.length === 0 && <Product>{translateToString('no_products')}</Product>}
				{props.category.products.map(p => <Product key={`Product[${p.id}]`}>
					<Checkbox isActive={p.checked ?? false}
						onClick={() => {
							if (props.disabled) return;
							if (!(p.checked ?? false) && props.max !== undefined && props.max - 1 < 0) return;
							p.checked = !(p.checked ?? false);
							props.setProducts();
						}}
						size='12px'
						disabled={props.disabled}
					/>
					{p.name}
				</Product>)}
				{props.category.children.map(p => <RecursiveCategoryDisplayer
					key={`RecursiveCategory[${p.id ?? 'no-category'}][${props.depth}]`}
					category={p}
					depth={props.depth + 1}
					setProducts={props.setProducts}
					max={props.max}
					disabled={props.disabled}
				/>)}
			</Collapse>
		</DepthPadder>
	</>;
}

const PopupContainer = styled.div`
	margin: 15px;
	max-height: 75vh;
	display: flex;
	gap: 5px;
	flex-flow: column;
`;

const CategoriesContainer = styled.div`
	display: flex;
	gap: 5px;
	flex-flow: column;
	overflow: auto;
`;

const PopupTitle = styled.h4`
	justify-content: space-between;
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${BorderColor2};
	margin-bottom: 5px;
	padding-bottom: 5px;
`;

const Close = styled.img`
	cursor: pointer;
	height: 25px;
`;

function ProductPerCategoryPopup(props: {
	products: CategoryAndProducts[],
	setProducts: (products: CategoryAndProducts[]) => void,
	onClose: () => void,
	max: number | undefined,
	setAll: () => void,
	all: boolean
}) {
	const count = props.products.reduce((acc, category) => acc + getProductsId(category).length, 0);

	return (
		<PopupContainer>
			<PopupTitle>
				<Translate id='categories' />
				<Close src={close} onClick={props.onClose} />
			</PopupTitle>
			<Product>
				<Checkbox isActive={props.all} onClick={props.setAll} />
				<Translate id='all' />
			</Product>
			{props.max !== undefined && <FlexDiv gap='10px'>
				<Product>
					{count} / {props.max}
				</Product>
			</FlexDiv>}
			<CategoriesContainer>
				{props.products.map(p => <RecursiveCategoryDisplayer
					key={`RecursiveCategory[${p.id ?? 'no-category'}][0]`}
					category={p}
					depth={1}
					setProducts={() => props.setProducts([...props.products])}
					max={props.max !== undefined ? props.max - count : undefined}
					disabled={props.all}
				/>)}
			</CategoriesContainer>
		</PopupContainer>
	);
}
