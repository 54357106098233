import { Chart } from 'bindings/reporting/Chart';
import { MainAxis } from 'bindings/reporting/MainAxis';
import { SecondaryAxis } from 'bindings/reporting/SecondaryAxis';
import { Granularity } from 'bindings/time/Granularity';
import dataSharingImg from 'images/icon/systemSettings/3.svg';
import merchandising from 'images/icons/events/Task.svg';
import presenceImg from 'images/icons/orders/order_box.svg';
import activityImg from 'images/menu_icon/event_black.svg';
import orderImg from 'images/menu_icon/product_menu_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';
import { PanelSelector } from '../../../components_v2/Selector/PanelSelector';
import { FilterTree } from '../../../components_v2/filter/model/Model';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import { momentToNaiveDateTime } from '../../../components_v2/utils';
import { BlueSidely } from '../../../styles/global/css/Utils';
import { Translate, translateToString } from '../../../styles/global/translate';
import NoData from '../../noData/NoData';
import { FlexDiv } from '../../products/style';
import { getEventCountEvolutionPerCompany } from '../../reports/eventCountEvolutionPerCompany/action';
import ChartReportingV2 from '../../reports/interpretor/ChartInterpretor';
import { valueToNumber, valueToString } from '../../reports/interpretor/ReportInterpretor';
import { ComplexCell, Report, Row } from '../../reports/interpretor/bareReportingDecoder';
import { getMerchandisingEvolution } from '../../reports/merchandisingEvolutionPerCompany/action';
import { getPresenceEvolution } from '../../reports/presenceEvolution/action';
import { TimelineHeader } from '../style/TimelineStyle';
const Title = styled.h6`
	margin: 0;
	margin-left: 25px;
	font-weight: 500;
	font-size: 13px;
`;

const ReportContainer = styled.div`
	padding-top: 20px;
`;

const PANELS = [
	{
		label: 'activity',
		img: activityImg
	},
	{
		label: 'orders_',
		img: orderImg,
		hidden: true
	},
	{
		label: 'presence',
		img: presenceImg
	},
	{
		label: 'merchandising',
		img: merchandising
	},
	{
		label: 'checkouts',
		img: dataSharingImg,
		hidden: true
	},
];

type DefaultFilters = {
	begin: string,
	end: string,
	granularity: Granularity,
	filters: FilterTree
}

const MAIN_AXIS: MainAxis = {
	title: '',
	labels: Array.from(Array(12).keys()).map(v => moment().month(v).format('MMM')),
	key: 'X',
	ticksType: 'string'
};

export default function CompanyReports(props: {
	id: number
}) {
	const [activeIndex, setActiveIndex] = React.useState<number>(0);
	const [activeReports, setActiveReports] = React.useState<boolean[]>([true]);
	const defaultFilters: DefaultFilters = React.useMemo(() => ({
		begin: momentToNaiveDateTime(moment().startOf('d').startOf('year').subtract(1, 'year')),
		end: momentToNaiveDateTime(moment()),
		granularity: 'month',
		filters: { and: [{ val: {
			column: 'company_id',
			operator: 'eq',
			value: props.id.toString()
		} }] }
	}), [props.id]);
	return <>
		<TimelineHeader isOpen noBorder>
			<PanelSelector
				panels={PANELS.map((p, i) => ({
					...p,
					label: translateToString(p.label),
					hidden: p.hidden ?? !activeReports[i]
				}))}
				onClick={(_, index) => setActiveIndex(index)}
				activeIndex={activeIndex}
				noToolTip
				borderBottom
			/>
		</TimelineHeader>
		<ReportContainer>
			{activeIndex === 0 && <Activity filters={defaultFilters} {...props} />}
			{activeIndex === 1 && <NoData message='Work in progress' />}
			<Presence filters={defaultFilters} display={activeIndex === 2} setActive={b => setActiveReports(ar => {
				ar[2] = b;
				return [...ar];
			})}/>
			<Merchandising filters={defaultFilters} {...props} display={activeIndex === 3} setActive={b => setActiveReports(ar => {
				ar[3] = b;
				return [...ar];
			})}/>
		</ReportContainer>
	</>;
}

function foundRowRecursively(row: Row, id: number): Row | undefined {
	if (row.id === id) return row;
	return row.rows.find(row => foundRowRecursively(row, id));
}

function Activity(props: { filters: DefaultFilters, id: number }) {
	const [chart, setChart] = React.useState<Chart>();
	const [activeIndex, setActiveIndex] = React.useState<number>(0);
	const [report, setReport] = React.useState<Report>();

	React.useEffect(() => report && genRows(report), [activeIndex]);

	function genRows(report: Report) {
		const row = report.rows.reduce((acc, row) => acc ? acc : foundRowRecursively(row, props.id), undefined);
		if (!row) return;
		const activeIndexCells = row.cells[activeIndex] ?? [];
		const max = activeIndexCells.reduce((acc, v) => Math.max(acc, valueToNumber(v.value) ?? 0), 0);
		setChart({
			stacked: false,
			title: '',
			curves: [{
				color: null,
				link: null,
				title: moment().subtract(1, 'year').format('YYYY'),
				representation: 'CURVE',
				curveValues: activeIndexCells.slice(0, 12).reduce((acc, v) => {
					let value = valueToNumber(v.value) ?? null;
					if (value === null && acc.length > 0) value = acc[acc.length - 1] ?? null;
					return [...acc, value];
				}, [])
			},
			{
				color: BlueSidely,
				link: null,
				title: moment().format('YYYY'),
				representation: 'CURVE',
				curveValues: activeIndexCells.slice(12).reduce((acc, v) => {
					let value = valueToNumber(v.value) ?? null;
					if (value === null && acc.length > 0) value = acc[acc.length - 1] ?? null;
					return [...acc, value];
				}, [])
			}],
			mainAxis: MAIN_AXIS,
			secondaryAxis: {
				title: '',
				min: 0,
				max: max + 5 - max % 5,
				scale: 1,
				ticksType: 'string'
			}
		});
	}

	React.useEffect(() => {
		getEventCountEvolutionPerCompany({ ...props.filters, status_ids: [2], user_ids: [] }).then(report => {
			setReport(report);
			genRows(report);
		});
	}, [props.filters]);

	if (!chart || !report) return <FlexDiv width='100%' justify='center'><PageLoader /></FlexDiv>;
	return <>
		<PanelSelector
			panels={report.panels.map(p => ({
				...p,
				label: valueToString(p.value) ?? ''
			}))}
			onClick={(_, index) => setActiveIndex(index)}
			activeIndex={activeIndex}
			borderBottom
			noToolTip
		/>
		<ChartReportingV2
			onCompanyClick={() => null}
			onFormInstanceClick={() => null}
			setToolBarState={() => null}
			chart={chart}
		/>
	</>;
}

function cellToPointRadius(cell: ComplexCell): number {
	if (cell.value.val !== null) return 4;
	return 0;
}

function Merchandising(props: { filters: DefaultFilters, id: number, setActive: (b: boolean) => void, display: boolean }) {
	const [activeIndex, setActiveIndex] = React.useState<number>(0);
	const [report, setReport] = React.useState<Report>();
	const [chart, setChart] = React.useState<Chart>();

	function genRows(report: Report) {
		const row = report.rows.reduce((acc, row) => acc ? acc : foundRowRecursively(row, props.id), undefined);
		if (!row) return;
		const activeIndexCells = row.cells[activeIndex] ?? [];
		const firstNonEmptyCell = activeIndexCells.find(c => c.value.val !== null);
		let secondaryAxis: SecondaryAxis | undefined;
		if (firstNonEmptyCell !== undefined && firstNonEmptyCell.value.val && 'percentage' in firstNonEmptyCell.value.val) {
			secondaryAxis = {
				title: '',
				min: 0,
				max: 110,
				scale: 10,
				ticksType: 'percentage'
			};
		}
		setChart({
			stacked: false,
			title: '',
			curves: [{
				color: null,
				link: null,
				title: moment().subtract(1, 'year').format('YYYY'),
				representation: 'CURVE',
				curveValues: activeIndexCells.slice(0, 12).reduce((acc, v) => {
					let value = valueToNumber(v.value) ?? null;
					if (value === null && acc.length > 0) value = acc[acc.length - 1] ?? null;
					return [...acc, value];
				}, []),
				//@ts-expect-error It works trust me
				pointRadius: activeIndexCells.slice(0, 12).map(cellToPointRadius)
			},
			{
				color: BlueSidely,
				link: null,
				title: moment().format('YYYY'),
				representation: 'CURVE',
				curveValues: activeIndexCells.slice(12).reduce((acc, v) => {
					let value = valueToNumber(v.value) ?? null;
					if (value === null && acc.length > 0) value = acc[acc.length - 1] ?? null;
					return [...acc, value];
				}, []),
				//@ts-expect-error It works trust me
				pointRadius: activeIndexCells.slice(12).map(cellToPointRadius)
			}],
			mainAxis: MAIN_AXIS,
			secondaryAxis: secondaryAxis ?? {
				title: '',
				min: null,
				max: null,
				scale: null,
				ticksType: 'string'
			}
		});
	}

	React.useEffect(() => report && genRows(report), [activeIndex]);

	React.useEffect(() => {
		getMerchandisingEvolution({ ...props.filters, user_ids: [], instant: true, expiration: 1 }).then(report => {
			const row = report.rows.reduce((acc, row) => acc ? acc : foundRowRecursively(row, props.id), undefined);
			if (row) props.setActive(row.cells.some(cells => cells.some(cell => cell.value.val !== null)));
			setReport(report);
			genRows(report);
		});
	}, [props.filters]);

	if (!props.display) return <></>;
	if (!chart || !report) return <FlexDiv width='100%' justify='center'><PageLoader /></FlexDiv>;
	const row = report.rows.reduce((acc, row) => acc ? acc : foundRowRecursively(row, props.id), undefined);
	if (!row) return <FlexDiv width='100%' justify='center'><PageLoader /></FlexDiv>;
	return <>
		<PanelSelector
			panels={report.panels.map((p, i) => ({
				...p,
				hidden: row?.cells[i].some(c => c.value.val && 'pieChart' in c.value.val),
				label: valueToString(p.value) ?? ''
			}))}
			onClick={(_, index) => setActiveIndex(index)}
			activeIndex={activeIndex}
			borderBottom
			noToolTip
		/>
		<ChartReportingV2
			onCompanyClick={() => null}
			onFormInstanceClick={() => null}
			setToolBarState={() => null}
			chart={chart}
		/>
	</>;
}

function Presence(props: { filters: DefaultFilters, setActive: (b: boolean) => void, display: boolean }) {
	const [chart, setChart] = React.useState<Chart>();

	React.useEffect(() => {
		getPresenceEvolution({ ...props.filters, expiration: 1, instant: true }).then(res => {
			const index = res.rows.findIndex(r => r.id !== 0);
			if (index < 0) return;
			props.setActive(res.rows[index].cells[0].some(c => c.value.val !== null));
			setChart({
				stacked: false,
				title: '',
				curves: [{
					color: null,
					link: null,
					title: moment().subtract(1, 'year').format('YYYY'),
					representation: 'CURVE',
					curveValues: res.rows[index].cells[0].slice(0, 12).reduce((acc, v) => {
						let value = valueToNumber(v.value) ?? null;
						if (value === null && acc.length > 0) value = acc[acc.length - 1] ?? null;
						return [...acc, value];
					}, []),
					//@ts-expect-error It works trust me
					pointRadius: res.rows[index].cells[0].slice(0, 12).map(cellToPointRadius)
				},
				{
					color: BlueSidely,
					link: null,
					title: moment().format('YYYY'),
					representation: 'CURVE',
					curveValues: res.rows[index].cells[0].slice(12).reduce((acc, v) => {
						let value = valueToNumber(v.value) ?? null;
						if (value === null && acc.length > 0) value = acc[acc.length - 1] ?? null;
						return [...acc, value];
					}, []),
					//@ts-expect-error It works trust me
					pointRadius: res.rows[index].cells[0].slice(12).map(cellToPointRadius)
				}],
				mainAxis: MAIN_AXIS,
				secondaryAxis: {
					title: '',
					min: 0,
					max: 110,
					scale: 10,
					ticksType: 'percentage'
				}
			});
		});
	}, [props.filters]);
	if (!props.display) return <></>;
	if (!chart) return <FlexDiv width='100%' justify='center'><PageLoader /></FlexDiv>;
	return <>
		<Title><Translate id='instore_presence_variation' /></Title>
		<ChartReportingV2
			onCompanyClick={() => null}
			onFormInstanceClick={() => null}
			setToolBarState={() => null}
			chart={chart}
		/>
	</>;
}
