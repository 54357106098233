import bin from 'images/setting_icons/bin.svg';
import export_icon from 'images/setting_icons/export_icon.svg';
import * as React from 'react';
import * as moment from 'moment';
import { genFakeDownloadLink, humanFileSize, useWindowDimensions } from '../../components_v2/utils';
import { loadingState } from '../../components_v2/models';
import { Column, Table } from '../../components_v2/table/Table';
import PageLoader from '../../components/PageLoader';
import { delete_export, get_exports, get_link } from './actions';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import Pagination from '../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { TableRow } from '../orders/templateOrders/style/Style';
import { useStore } from 'react-redux';
import Restricted from '../permissions/Restricted';
import usePermission from '../permissions/usePermission';

const DEFAULT_LIMIT = 50;

export function Exports() {

	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any[]>([]);
	const [keys, setKeys] = React.useState<string[]>([]);
	const [pagination, setPagination] = React.useState<PaginationResult>({ currentPage: 1, step: DEFAULT_LIMIT, offset: 0 });
	const [isAtTheEnd, setIsAtTheEnd] = React.useState<boolean>(false);
	const allowed = usePermission({ objectAction: 'DeleteExport' });
	const store = useStore().getState();
	const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

	if (store.user.isLoading) {
		setTimeout(forceUpdate, 500);
	}

	const gen_columns = () => {
		const translate = getTranslate(storeLang.getState().localize);
		const columns: Array<Column<any>> = [
			{
				Header: translate('exports.date') as string,
				accessor: row => {
					if (row.last_modified != null && row.last_modified != undefined) {
						const date = moment(row.last_modified).format('DD/MM/YYYY LTS');
						return <TableRow>{date}</TableRow>;
					}
					return <TableRow>{'-'}</TableRow>;
				},
				width: undefined
			},
			{
				Header: translate('exports.file_name') as string,
				accessor: row => {
					if (row.key != null && row.key != undefined) {
						return <TableRow>{row.key.substring(row.key.lastIndexOf('/') + 1)}</TableRow>;
					}
					return <TableRow>{'-'}</TableRow>;
				},
				width: undefined
			},
			{
				Header: translate('exports.file_size') as string,
				accessor: row => {
					if (row.size != null && row.size != undefined) {
						return <TableRow>{humanFileSize(row.size)}</TableRow>;
					}
					return <TableRow>{'-'}</TableRow>;
				},
				width: undefined
			},
			{
				id: 'download',
				Cell: ({ row }) => {
					const key = row.original.key.substring(row.original.key.lastIndexOf('/') + 1);
					const [downloadLoadingState, setDownloadLoadingState] = React.useState<loadingState>(null);
					const download_handler = React.useCallback(() => {
						setDownloadLoadingState('loading');
						get_link(key)
							.then(url => {
								setDownloadLoadingState('loaded');
								genFakeDownloadLink(url, key);
							})
							.catch(e => {
								console.log(e);
							});
					}, [data]);
					return (
						(<div>
							{
								downloadLoadingState == 'loading' &&
				<i className="lds-dual-ring" />
							}
							{downloadLoadingState == 'error' && <p>error</p>}
							{
								(downloadLoadingState == null || downloadLoadingState == 'loaded') &&
				<button onClick={download_handler} className="btn-edit-modal" style={{ width: '10px' }}>
					<img style={{ width: '10px' }} src={export_icon} />
				</button>
							}
						</div>)
					);
				},
				width: 40,
				unresizeable: true
			}
		];
		if (allowed) {
			columns.push(
				{
					id: 'delete',
					Cell: ({ row }) => {
						const key = row.original.key.substring(row.original.key.lastIndexOf('/') + 1);
						const [deleteLoadingState, setDeleteLoadingState] = React.useState<loadingState>(null);
						const delete_handler = React.useCallback(() => {
							setDeleteLoadingState('loading');
							delete_export(key)
								.then(() => {
									setDeleteLoadingState('loaded');
									// remove row
									const dataCopy = [...data];
									dataCopy.splice(row.index, 1);
									setData(dataCopy);
								})
								.catch(e => {
									setDeleteLoadingState('error');
									console.log(e);
								});
							setDeleteLoadingState('loaded');
						}, [deleteLoadingState]);
						return (
							(<div>
								{
									deleteLoadingState == 'loading' &&
										<i className="lds-dual-ring" />
								}
								{deleteLoadingState == 'error' && <p>error</p>}
								{
									deleteLoadingState == null &&
										<button onClick={delete_handler} className="btn-edit-modal" style={{ width: '10px' }}>
											<img style={{ width: '10px' }} src={bin} />
										</button>
								}
							</div>)
						);
					},
					width: 40,
					unresizeable: true
				});
		}
		return columns;
	};

	const columns: Array<Column<any>> = React.useMemo(
		() => gen_columns(),
		[data]
	);

	React.useEffect(() => {
		try {
			setLoadingState('loading');
			get_exports(pagination.step, pagination.offset)
				.then(res => {
					keys[pagination.currentPage] = res.data[res.data.length - 1]?.key;
					setIsAtTheEnd(res.data.length < pagination.step);
					setKeys([...keys]);
					const rows = res.data.sort((r1, r2) =>
						r2.last_modified.localeCompare(r1.last_modified)
					);
					setData(rows);
					setLoadingState('loaded');
				})
				.catch(e => {
					console.log(e);
					setLoadingState('error');
				});
		} catch (e) {
			console.log(e);
			setLoadingState('error');
		}
	}, [pagination]);

	return (
		<Restricted to={{ objectAction: 'ViewExport' }}>
			<div style={{ overflow: 'hidden', height: 'calc(100vh - 128px)' }}>
				{(loadingState == 'loading' || !loadingState) && <div style={{ position: 'absolute', width: '100%' }} className="list-loader"><PageLoader /></div>}
				{loadingState == 'error' && <p>Error fetching data</p>}
				<Table height='calc(100% - 44px)' columns={columns} data={data} noBorder/>
				<footer>
					<Pagination label={'exports'} steps={[DEFAULT_LIMIT, 100, 200, 500]} onChange={(v) => setPagination(v)} isAtTheEnd={isAtTheEnd} />
				</footer>
			</div>
		</Restricted>
	);
}
