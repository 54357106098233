import folder from 'images/icon/folder.png';
import add_grey from 'images/icon/add_grey.png';
/* eslint-disable jsx-a11y/label-has-for */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["imagePreview"] }] */
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

class FieldDropzoneIcon extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			input,
			name,
			meta: { error, warning, touched },
			label,
			requiredStar,
			disabled,
			id
		} = this.props;
		const files = input.value;
		return (
			(<div className="form-group mb-0 dropzone-icon">
				<div className="row">
					<div className="col-md-4 my-auto text-right pr-4">
						<label htmlFor={id}>
							{requiredStar === true ? (
								<span className="text-required">* </span>
							) : (
								''
							)}
							{label}
						</label>
					</div>
					<div className="col-md-8">
						<Dropzone
							className="upload-doc"
							name={name}
							onDrop={filesToUpload => input.onChange(filesToUpload[0])}
							// maxSize={2097152}
							accept=".xls, .xlsx"
							multiple={false}
							disabled={disabled}
						>
							<img
								src={add_grey}
								className="dropzone-add"
								alt=""
								style={{ position: 'relative', zIndex: '1' }}
							/>
							<img
								src={folder}
								alt=""
								className="img-fluid dropzone-folder"
								style={{ position: 'relative', marginLeft: '-25px' }}
							/>
						</Dropzone>
						{files && (
							<p className="mt-2 mb-0 font-weight-bold">{files.name}</p>
						)}
					</div>
				</div>
				{touched &&
          ((error && <span className="form-error">{error}</span>) ||
            (warning && <span className="form-error">{warning}</span>))}
			</div>)
		);
	}
}

export default FieldDropzoneIcon;
