import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';
import { FileResult } from '../../components_v2/input/model/Model';

export type GetFile = {
	uuid: string,
	company_id: number,
	path: string,
	created_by: number,
	created_at: string,
	hash: string,
	filename: string,
	url?: string,

}

export type GetFolder = {
	uuid: string,
	folder_name: string,
	path: string,
}

export type PutFile = {
	uuid: string,
	filename: string,
	path: string,
}

export type PostFile = {
	path: string,
	hash: string,
	filename: string,
	filesize: number,
}

export async function getFileManagerFiles(): Promise<GetFile[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/filemanager/get-all`).then((res) => res.data);
}

export function getAllFromFileManagerPath(path: string): Promise<GetFile[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/filemanager/get-all-from-path?path=${path}`).then((res) => res.data);
}

export async function postFileManagerGetUrl(file: PostFile): Promise<string> {
	return axios.post(`${URL_FOUNDATION}/api/v2/filemanager/get-post-file-url`, file).then((res) => res.data);
}

export async function confirmFileManagerPost(file: PostFile): Promise<string> {
	return axios.post(`${URL_FOUNDATION}/api/v2/filemanager/post-file-success`, file).then((res) => res.data);
}

export async function getSingleFileFromPath(path: string): Promise<GetFile> {
	return axios.get(`${URL_FOUNDATION}/api/v2/filemanager/get-file-from-path?path=${path}`).then((res) => res.data);
}

export async function getFoldersFromPath(path: string): Promise<GetFolder[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/filemanager/get-folders-from-path?path=${path}`).then((res) => res.data);
}

export async function deleteFile(hash: string, path: string): Promise<string> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/filemanager/delete-file/${hash}?path=${path}`).then((res) => res.data);
}



export async function deleteFolder(uuid: string, path: string): Promise<string> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/filemanager/delete-folder/${uuid}?path=${path}`).then((res) => res.data);
}

export async function folderHasFiles(uuid: string): Promise<boolean> {
	return axios.get(`${URL_FOUNDATION}/api/v2/filemanager/folder-has-files/${uuid}`).then((res) => res.data);
}



export async function getUsage(): Promise<number> {
	return axios.get(`${URL_FOUNDATION}/api/v2/filemanager/get-usage`).then((res) => res.data);
}

export async function putFile(file: PutFile): Promise<string> {
	return axios.put(`${URL_FOUNDATION}/api/v2/filemanager/put-file-changes`, file).then((res) => res.data);
}

export async function renameFolder(uuid: string, folder_name: string, old_path: string): Promise<string> {
	return axios.put(`${URL_FOUNDATION}/api/v2/filemanager/rename-folder`, { new_name: folder_name, uuid: uuid, old_path: old_path }).then((res) => res.data);
}

export async function postToS3(url: string, file: FileResult): Promise<Response> {
	return fetch(url, { body: file.file, mode: 'cors', method:'PUT' }).then((res) => res);
}

