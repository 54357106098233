import trash_black from 'images/ui_icon/trash_black.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import PhotoVisualizer from '../../../../../components_v2/visualizer/PhotoVisualizer';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { LoadingStateEnum } from '../../../../import/model';
import { deleteCompanyPhoto, getCompanyPhotos } from '../../../data/action';
import { CompanyPhoto } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import { Container, SeeMore, Trash } from '../style/LinkedElementStyle';
import { CardPhoto, PhotoImage } from '../style/PhotoStyle';
import Restricted from '../../../../permissions/Restricted';

function Photo(props: {
  clientCompanyId: number
  isExtend: boolean
  newCreation?: CompanyPhoto[]
  onDelete?: () => void
}): JSX.Element {
	const DEFAULT_LIMIT = 3;

	const translate = getTranslate(storeLang.getState().localize);
	
	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [photos, setPhotos] = React.useState<CompanyPhoto[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [offset, setOffset] = React.useState<number>(0);

	const [isPhotoOpen, setPhotoOpen] = React.useState<boolean>(false);
	const [photoSelected, setPhotoSelected] = React.useState<number>(0);

	const [deletion, setDeletion] = React.useState<{ isOpen: boolean, data?: CompanyPhoto }>({ isOpen: false });

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getCompanyPhotos({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset
		}).then(response => {
			setPhotos(response.photos);
			setTotal(response.total);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	React.useEffect(() => {
		if (props.newCreation != null) {
			setPhotos([...props.newCreation, ...photos]);
			setOffset(offset + props.newCreation.length);
		}
	}, [JSON.stringify(props.newCreation)]);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getCompanyPhotos({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset: newOffset
		}).then(response => {
			setPhotos(photos.concat(response.photos));
			setTotal(response.total > 0 ? response.total : total);
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}

	function deletePhoto(photo: CompanyPhoto): void {
		setPhotos(photos.filter(p => p.photo_id !== photo.photo_id));
		setOffset(offset - 1);
		setTotal(total - 1);
		props.onDelete?.();
	}

	return (
		(<Container paddingTop="10px">
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: photos.length > 0
						? photos.map((p, i) => {
							return (
								(<CardPhoto isExtend={props.isExtend} onClick={() => {
									setPhotoSelected(i);
									setPhotoOpen(true);
								}} key={`CardPhoto[${p.photo_id}]`}>
									<PhotoImage src={p.photo_url} onError={_ => console.error('Error loading image')} />
									<Restricted to={{ objectAction: 'DeleteCompanyPicture' }}>
										<Trash src={trash_black} onClick={(e) => {
											e.stopPropagation();
											setDeletion({ ...deletion, isOpen: true, data: p });
										
										}} />
									</Restricted>
								</CardPhoto>)
							);
						})
						: <NoDataText><Translate id='no_photos' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && photos.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
			<PopupDeletion 
				isOpen={deletion.isOpen} 
				records={0} 
				translationKey={''}
				noVerification
				onClickOut={() => setDeletion({ ...deletion, isOpen: false })}
				customTitle={translate('global.fire.title.delete_photo').toString()}
				onValidation={() => {
					if (deletion.data) {
						deleteCompanyPhoto({
							client_company_id: props.clientCompanyId,
							photo_id: deletion.data.photo_id
						}).then(response => {
							if (response.valueOf()) {
								deletePhoto(deletion.data!);
							}
						});
					}
				}}
				customMessage={translate('global.fire.delete_photo').toString()}
			/>
			<PhotoVisualizer
				isOpen={isPhotoOpen}
				setOpen={setPhotoOpen}
				photos={photos.map(p => {
					return {
						src: p.photo_url
					};
				})}
				selected={photoSelected}
			/>
		</Container>)
	);
}

export default Photo;
