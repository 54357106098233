import * as React from 'react';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { INPUT_STYLE_SETTINGS, SystemSettingTitle, SystemSettingsDropdownWrapper, SystemSettingsWrapper } from './styles';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { ApiCount, getApiCounters, getEmailAutomations, syncEmailsAutomations } from './actions';
import { FlexDiv } from '../../products/style';
import PermissionContext from '../../permissions/PermissionContext';
import { match_email } from 'validators-web';
import Restricted from '../../permissions/Restricted';
import Add from '../../../components_v2/add/Add';
import { Id, SaveButton, getIdListDifferences, valueToCreationEditionOrDeletion } from './statusesAndTagsSettings';
import Switch from '../../../components_v2/Switch/Switch';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { Prompt } from 'react-router';
import { DeleteDot } from '../../../styles/global/css/Dot';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input from '../../../components_v2/input/Input';
import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
import { BlueSidely } from '../../../styles/global/css/Utils';
import { InputMode } from '../../../components_v2/input/model/Model';
import Dropdown from '../../../components_v2/dropdown/Dropdown';

const DEFAULT_TITLE = 'Order $ORDER - $DATE - $COMPANY';
const DEFAULT_BODY = '<!DOCTYPE html><html><body><h1>Order</h1><p>Reference: $ORDER</p><p>Date: $DATE</p><p>Company Name: $COMPANY</p></body></html>';

export type EmailAutomation = {
	deleted?: boolean,
	body_template?: string,
	email?: string,
	id: Id,
	status: boolean,
	title_template?: string,
	automation_type: string,
}

const ProgressBarStyle = styled.div`
	padding-right: 20px;
	.progress .progress-bar {
		background-color: ${BlueSidely};
		width: 100%;
	}
	.progress {
		width: 100%;
		height: 20px !important;
	}
`;

const TitleWithComment = styled.div`
	display: flex;
	align-items: center;
	flex-flow: row;
	gap: 1ch;
	position: sticky;
	top: 0;
	background: white;
	z-index: 2;
`;

async function saveChanges(differences: EmailAutomation[]): Promise<void> {
	const emails = differences.map(email => valueToCreationEditionOrDeletion(email));
	return syncEmailsAutomations(emails);
}

export function ApiCountProgressBar(props: { max: number, now: number }) {
	return <ProgressBarStyle>
		<ProgressBar
			now={props.now}
			min={0}
			max={props.max}
			label={props.now}
		/>
		<FlexDiv width='100%' justify='space-between'>
			<div>0</div>
			{props.max}
		</FlexDiv>
	</ProgressBarStyle>;
}

export default function OrderNotificationsSettings(props: {
	setToolBarState: (value: ToolbarState) => void
}) {
	const { setToolBarState } = props;
	const [state, setState] = React.useState<EmailAutomation[]>([]);
	const [defaultState, setDefaultState] = React.useState<EmailAutomation[]>([]);
	const [error, setError] = React.useState<{ [key: number]: boolean }>({});
	const [apiCount, setApiCount] = React.useState<ApiCount>();
	const differences = getIdListDifferences(defaultState, state);

	const { isAllowedTo } = React.useContext(PermissionContext);

	const fetchData = () => {
		getEmailAutomations().then(res => {
			setDefaultState(JSON.parse(JSON.stringify(res)));
			setState(res);
			setError({});
		});
	};

	React.useEffect(() => {
		fetchData();
		getApiCounters().then(setApiCount);
	}, []);

	React.useEffect(() => {
		setToolBarState({
			bottomRightToolbarComponent: <Restricted to={{ objectAction: 'CreateCompanySettings' }}>
				<SaveButton
					buttonStyle={ButtonStyle.White}
					disabled={!differences.length}
					onClick={() => {
						setState(JSON.parse(JSON.stringify(defaultState)));
						setError({});
					}}>
					{translateToNode('cancel')}
				</SaveButton>
				<SaveButton
					disabled={!differences.length || state.some(t => (t.automation_type == 'Order' && (!t.email || t.email.trim().length == 0)) || Object.values(error).some(e => e))}
					onClick={() => saveChanges(differences).then(fetchData)}>
					{translateToNode('save')}
				</SaveButton>
			</Restricted>
		});
	}, [differences, error]);

	return <>
		<Prompt
			when={differences.length !== 0}
			message={translateToString('unsaved_changes_leave')}
		/>
		<SystemSettingsWrapper>
			{apiCount && <>
				<SystemSettingTitle>{translateToNode('sent_emails')}</SystemSettingTitle>
				<ApiCountProgressBar max={apiCount.mail_extra + 500} now={apiCount.sent_mail_count} />
			</>}
			<TitleWithComment>
				<SystemSettingTitle>{translateToNode('email_address')}</SystemSettingTitle>
				({translateToNode('used_to_receive_order_forms')})
			</TitleWithComment>
			{state.map((ea, i) => {
				if (ea.deleted) return undefined;
				const isUnsync = typeof ea.id !== 'number';
				let internalId: number;
				if (typeof ea.id === 'number') {
					internalId = ea.id;
				} else {
					internalId = ea.id.unsync;
				}
				return <FlexDiv key={`EmailAutomation[${internalId}]`} gap='1em'>
					<Input
						mode={error[internalId] ? InputMode.Error : undefined}
						name=''
						type='text'
						inputStyle={INPUT_STYLE_SETTINGS}
						disableChange={!isAllowedTo({ objectAction: 'UpdateAccountSettings' })}
						disabled={state[i].automation_type == 'CompanyOrder'}
						onChange={e => {
							if (state[i].automation_type == 'Order') {
								error[internalId] = !match_email(e.trim());
							}
							state[i].email = e.trim();
							setError({ ...error });
							setState([...state]);
						}}
						value={ea.email}
					/>
					<Dropdown name="tamer" selectedValue={{
						label: state[i].automation_type == 'Order' ? translateToString('custom_email') : translateToString('linked_to_company_email'), value: state[i].automation_type
					}}
					datalist={[{ label: translateToString('custom_email'), value: 'Order' }, { label: translateToString('linked_to_company_email'), value: 'CompanyOrder' }]}
					onChange={(v) => {
						state[i].automation_type = v.value;
						if (state[i].automation_type == 'CompanyOrder') { ea.email = ''; }
						setState([...state]);
					}}
					/>
					<Switch value={ea.status}
						size='17.5px'
						onChange={() => {
							state[i].status = !state[i].status;
							setState([...state]);
						}}
						readonly={!isAllowedTo({ objectAction: isUnsync ? 'CreateAccountSettings' : 'UpdateAccountSettings' })}
					/>
					<Restricted to={{ objectAction: isUnsync ? 'CreateAccountSettings' : 'DeleteAccountSettings' }}>
						<DeleteDot size='1.5em' fontSize='1em' onClick={() => {
							state[i].deleted = true;
							if (isUnsync) {
								state.splice(i, 1);
							}
							setState([...state]);

						}} />
					</Restricted>
				</FlexDiv>;
			})}
			<Restricted to={{ objectAction: 'CreateAccountSettings' }}>
				<Add onClick={() => setState([...state, { id: { unsync: Date.now() }, status: true, body_template: state[0]?.body_template, title_template: state[0]?.title_template, automation_type: 'Order' }])} />
			</Restricted>


			<SystemSettingsDropdownWrapper>
				<SystemSettingTitle className='email-title'>{translateToNode('email_title')}</SystemSettingTitle>
				<Input
					// mode={errorState.name ? InputMode.Error : undefined}
					disableChange={!isAllowedTo({ objectAction: 'UpdateAccountSettings' })}
					inputStyle={INPUT_STYLE_SETTINGS}
					name='my_name'
					type='text'
					value={state[0]?.title_template ?? DEFAULT_TITLE}
					onChange={v => {
						state.forEach(ea => ea.title_template = v.trim());
						setState([ ...state ]);
					}}
				/>
			</SystemSettingsDropdownWrapper>

			<SystemSettingsDropdownWrapper>
				<SystemSettingTitle className='email-body'>{translateToNode('email_body')}</SystemSettingTitle>
				{state && <>
					{/* @ts-expect-error this is woring */}
					<CKEditor
						disabled={!isAllowedTo({ objectAction: 'UpdateAccountSettings' })}
						editor={ ClassicEditor }
						config={{
							toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo' ],
						}}
						data={state[0]?.body_template ?? DEFAULT_BODY}
						onBlur={ (event, editor) => {
							const data = editor.getData();
							state.forEach(ea => ea.body_template = `<!DOCTYPE html><html><body>${data}</body></html>`);
							setState([ ...state ]);

						}}
					/>
				</>
				}
			</SystemSettingsDropdownWrapper>

		</SystemSettingsWrapper> 
	</>;
}