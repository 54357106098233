import * as React from 'react';
import styled from 'styled-components';
import Avatar from '../../components_v2/avatar/Avatar';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { getAlexandriaCompanies, getAlexandriaTags, getClientStatuses, getDivisions, getUsers } from './actions';
import { CompanyModel } from './Matching';
import { Columns, Division, DropdownDivision, ImportColumns, MatchedRowType, Panel, RowType, SidelyRowStatus } from './model';
import { FilterTag, tag } from '../../myToolbar/MyTagFilter';
import { useWindowDimensions } from '../../components_v2/utils';
import { Translate } from 'react-localize-redux';
import { BlueSidely } from '../../styles/global/css/Utils';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { translateToString } from '../../styles/global/translate';
import CloseImage from 'images/icons/orders/close.svg';

const Button = styled(DefaultButton)`
	width: 130px;
	height: 42px;
`;

const Close = styled.img`
	height: 35px;
	cursor: pointer;
	align-self: center;
	text-align: right;
`;

const Title = styled.div`
	margin: 0 40px 30px 40px;
	font-weight: bold;
	font-size: 14px;
	padding-top: 30px;
`;

const Text = styled.div`
	font-weight: 400;
	margin: 10px 0;
	font-size: 13px;
	color: #4C5862;

`;
function constructStructures(
	companies: object[],
	setData: (data: RowType[]) => void,
	setColumns: (data: ImportColumns[]) => void,
	setMatchedList: (list: MatchedRowType) => void,
	status: string,
	owner: string
) {
	const columns: Columns[] = [{ Header: 'owner_name', accessor: 'owner_name' }, { Header: 'status_name', accessor: 'status_name' }];
	for (const [key, _] of Object.entries(companies[0])) {
		columns.push({ Header: key, accessor: key });
	}
	const data: RowType[] = [];
	let i = 0;
	for (const company of companies) {
		const row: RowType = {
			SidelyRow: { value: i, status: SidelyRowStatus.Edited },
			owner_name: { value: owner, status: SidelyRowStatus.Edited },
			status_name: { value: status, status: SidelyRowStatus.Edited }
		};
		i++;
		for (const [key, value] of Object.entries(company)) {
			if (key == 'tags') {
				row[key] = { value: value.reduce((acc, v) => `${acc},${v}`), status: SidelyRowStatus.Edited };
			} else { row[key] = { value, status: SidelyRowStatus.Edited }; }
		}
		data.push(row);
	}
	setData(data);
	setColumns(columns);
	setMatchedList(CompanyModel([], []).reduce((acc, model) => {
		if (!model.notAlexandria) { acc[model.value] = model; }
		return acc;
	}, {}));
}

export default function AlexandriaRetriever(props: {
  panel: Panel
  setData: (data: RowType[]) => void
  setColumns: (data: ImportColumns[]) => void
  setPanel: (panel: Panel) => void
  setMatchedList: (list: MatchedRowType) => void
  setToolBarState: (value: ToolbarState) => void
}) {
	const [divisions, setDivisions] = React.useState<Division[]>([]);
	const [country, setCountry] = React.useState<DropdownDivision>({ list: [], value: { label: '', value: null } });
	const [division_1, setDivision_1] = React.useState<DropdownDivision>({ list: [], value: { label: '', value: null } });
	const [division_2, setDivision_2] = React.useState<DropdownDivision>({ list: [], value: { label: '', value: null } });
	const [division_3, setDivision_3] = React.useState<DropdownDivision>({ list: [], value: { label: '', value: null } });
	const [tags, setTags] = React.useState<DropdownDivision>({ list: [], value: { label: '', value: null } });
	const [statuses, setStatuses] = React.useState<DropdownDivision>({ list: [], value: { label: '', value: null } });
	const [owners, setOwners] = React.useState<DropdownDivision>({ list: [], value: { label: '', value: null } });
	const { height } = useWindowDimensions();

	React.useEffect(() => {
		getDivisions()
			.then(res => {
				const div: Division[] = res.data;
				setDivisions(div);
				setCountry({ value: { label: '', value: null }, list: Array.from(new Set(div.map(d => d.country))).map((d, i) => { return { label: d, value: i }; }) });
				setDivision_1({ value: { label: '', value: null }, list: Array.from(new Set(div.map(d => d.division_1))).map((d, i) => { return { label: d, value: i }; }) });
				setDivision_2({ value: { label: '', value: null }, list: Array.from(new Set(div.map(d => d.division_2))).map((d, i) => { return { label: d, value: i }; }) });
				setDivision_3({ value: { label: '', value: null }, list: Array.from(new Set(div.map(d => d.city))).map((d, i) => { return { label: d, value: i }; }) });
			})
			.catch(console.error);
		getAlexandriaTags()
			.then(res => {
				const tags: tag[] = res.data.map(t => { return { label: t.name, value: t.id.toString(), color: BlueSidely, type: 'tag' }; });
				tags.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
				setTags({ list: tags, value: { label: '', value: null } });
			})
			.catch(console.error);
		getClientStatuses()
			.then(res => {
				const list: DropdownData[] = res.data.map(s => ({ label: s.name, value: s.id }));
				setStatuses({ value: list[0], list });
			})
			.catch(console.error);
		getUsers()
			.then(res => {
				const list: DropdownData[] = res.data.map(o => ({ label: o.name, value: o.id, image: o.photo, imageJsx: <Avatar userId={o.id} name={o.name} width='26px' /> }));
				setOwners({ value: list[0], list });
			})
			.catch(console.error);
	}, []);

	const nextStep = () => {
		if (props.panel == Panel.AlexandriaStep1) { props.setPanel(Panel.AlexandriaStep2); } else {
			getAlexandriaCompanies(country.value.label, division_1.value.label, division_2.value.label, division_3.value.label, tags.value.value)
				.then(res => {
					if (res.data.length == 0) {
						props.setPanel(Panel.TableView);
						return;
					}
					constructStructures(res.data, props.setData, props.setColumns, props.setMatchedList, statuses.value.label, owners.value.label);
					props.setPanel(Panel.TableView);
				})
				.catch(console.error);
		}
	};

	let disabled = false;
	if (props.panel == Panel.AlexandriaStep1) { disabled = tags.value.value == null || country.value.value == null; } else { disabled = statuses.value.value == null || owners.value.value == null; }
	React.useEffect(() => {
		props.setToolBarState({
			bottomRightToolbarComponent: <>
				<Button disabled={disabled} onClick={nextStep} style={{ alignSelf: 'center' }} >{translateToString('next')}</Button>
				<Close src={CloseImage} onClick={() => props.setPanel(Panel.UploadView)} />
			</>
		});
	}, [nextStep]);

	return (
		<div style={{ margin: '0px 0px 0px 3px' }}>
			<div style={{ backgroundColor: 'white', margin: '0px 15px 15px 15px', height: `calc(${height}px - 150px)` }}>
				{props.panel == Panel.AlexandriaStep1 &&
					<AlexandriaStep1
						divisions={divisions}
						country={country}
						setCountry={setCountry}
						division_1={division_1}
						setDivision_1={setDivision_1}
						division_2={division_2}
						setDivision_2={setDivision_2}
						division_3={division_3}
						setDivision_3={setDivision_3}
						tags={tags}
						setTags={setTags}
						setPanel={props.setPanel}
					/>
				}
				{props.panel == Panel.AlexandriaStep2 &&
					<AlexandriaStep2
						statuses={statuses}
						setStatuses={setStatuses}
						owners={owners}
						setOwners={setOwners}
					/>
				}
			</div>
		</div>
	);
}

function AlexandriaStep1(props: {
  divisions
  country
  setCountry
  division_1
  setDivision_1
  division_2
  setDivision_2
  division_3
  setDivision_3
  tags
  setTags
  setPanel

}): JSX.Element {
	const {
		divisions,
		country,
		setCountry,
		division_1,
		setDivision_1,
		division_2,
		setDivision_2,
		division_3,
		setDivision_3,
		tags,
		setTags
	} = props;

	React.useEffect(() => {
		if (division_2.value?.value !== null) {
			setDivision_3({ value: { label: '', value: null }, list: Array.from(new Set(divisions.filter(d => d.division_2 == division_2.value?.label).map(d => d.city))).map((d, i) => { return { label: d, value: i }; }) });
		} else {
			setDivision_3({ value: { label: '', value: null }, list: Array.from(new Set(divisions.filter(d => (division_1.value.value !== null && d.division_1 == division_1.value.label) || division_1.value.value == null).map(d => d.city))).map((d, i) => { return { label: d, value: i }; }) });
		}
	}, [division_2.value]);

	React.useEffect(() => {
		if (division_1.value?.value !== null) {
			setDivision_2({ value: { label: '', value: null }, list: Array.from(new Set(divisions.filter(d => d.division_1 == division_1.value?.label).map(d => d.division_2))).map((d, i) => { return { label: d, value: i }; }) });
			setDivision_3({ value: { label: '', value: null }, list: Array.from(new Set(divisions.filter(d => d.division_1 == division_1.value?.label).map(d => d.city))).map((d, i) => { return { label: d, value: i }; }) });
		} else {
			setDivision_2({ value: { label: '', value: null }, list: Array.from(new Set(divisions.map(d => d.division_2))).map((d, i) => { return { label: d, value: i }; }) });
			setDivision_3({ value: { label: '', value: null }, list: Array.from(new Set(divisions.map(d => d.city))).map((d, i) => { return { label: d, value: i }; }) });
		}
	}, [division_1.value]);

	React.useEffect(() => {
		if (division_1.value?.value !== null) {
			setDivision_1({ value: { label: '', value: null }, list: Array.from(new Set(divisions.filter(d => d.country == country.value?.label).map(d => d.division_1))).map((d, i) => { return { label: d, value: i }; }) });
			setDivision_2({ value: { label: '', value: null }, list: Array.from(new Set(divisions.filter(d => d.country == country.value?.label).map(d => d.division_2))).map((d, i) => { return { label: d, value: i }; }) });
			setDivision_3({ value: { label: '', value: null }, list: Array.from(new Set(divisions.filter(d => d.country == country.value?.label).map(d => d.city))).map((d, i) => { return { label: d, value: i }; }) });
		} else {
			setDivision_1({ value: { label: '', value: null }, list: Array.from(new Set(divisions.map(d => d.division_1))).map((d, i) => { return { label: d, value: i }; }) });
			setDivision_2({ value: { label: '', value: null }, list: Array.from(new Set(divisions.map(d => d.division_2))).map((d, i) => { return { label: d, value: i }; }) });
			setDivision_3({ value: { label: '', value: null }, list: Array.from(new Set(divisions.map(d => d.city))).map((d, i) => { return { label: d, value: i }; }) });
		}
	}, [country.value]);

	return (
		<>
			<Title>
				<Translate id="import.alexandria.step_1_title" />
				<Text><Translate id="import.alexandria.step_1_text" /></Text>
			</Title>
			<table>
				{displayJSX(<FilterTag options={tags.list as tag[]} onFilter={filt => setTags({ ...tags, value: { value: filt.length > 0 ? filt.reduce((acc, tag) => acc + '-' + tag, '') : null, label: '' } })} isLoading={false} />, 'Category')}
				{displayJSX(<Dropdown dropdownStyle={{ width: '448px', optionWidth: '448px', height: '38px' }} placeholder={translateToString('select_country')} name='country' selectedValue={country.value} onChange={res => setCountry({ ...country, value: res })} datalist={country.list as DropdownData[]} cancellable />, translateToString('country'))}
				{displayJSX(<Dropdown dropdownStyle={{ width: '448px', optionWidth: '448px', height: '38px' }} placeholder={translateToString('all')} name='division_1' selectedValue={division_1.value} onChange={res => setDivision_1({ ...division_1, value: res })} datalist={division_1.list as DropdownData[]} cancellable />, translateToString('region'))}
				{displayJSX(<Dropdown dropdownStyle={{ width: '448px', optionWidth: '448px', height: '38px' }} placeholder={translateToString('all')} name='division_2' selectedValue={division_2.value} onChange={res => setDivision_2({ ...division_2, value: res })} datalist={division_2.list as DropdownData[]} cancellable />, translateToString('department'))}
				{displayJSX(<Dropdown dropdownStyle={{ width: '448px', optionWidth: '448px', height: '38px' }} placeholder={translateToString('all')} name='division_3' selectedValue={division_3.value} onChange={res => setDivision_3({ ...division_3, value: res })} datalist={division_3.list as DropdownData[]} cancellable />, translateToString('city'))}
			</table>
		</>
	);
}

function AlexandriaStep2(props: {
  statuses: DropdownDivision
  setStatuses: (s: DropdownDivision) => void
  owners: DropdownDivision
  setOwners: (o: DropdownDivision) => void
}): JSX.Element {
	const { statuses, setStatuses, owners, setOwners } = props;
	return (
		<>
			<Title>
				<Translate id="import.alexandria.step_2_title" />
				<Text><Translate id="import.alexandria.step_2_text" /></Text>
			</Title>
			<table>
				{displayJSX(<Dropdown dropdownStyle={{ width: '448px', optionWidth: '448px', height: '38px' }} placeholder={translateToString('All')} name='division_3' selectedValue={owners.value} onChange={(res: DropdownData) => setOwners({ ...owners, value: res })} datalist={owners.list as DropdownData[]} cancellable />, translateToString('owner'))}
				{displayJSX(<Dropdown dropdownStyle={{ width: '448px', optionWidth: '448px', height: '38px' }} placeholder={translateToString('All')} name='division_2' selectedValue={statuses.value} onChange={(res: DropdownData) => setStatuses({ ...statuses, value: res })} datalist={statuses.list as DropdownData[]} cancellable />, translateToString('status'))}
			</table>
		</>

	);
}

function displayJSX(content: JSX.Element, label: string): JSX.Element {
	return (
		<tr>
			<td style={{ width: 150, textAlign: 'end' }}>
				<Text>
					{label}
				</Text>
			</td>
			<td style={{ width: '450px', display: 'inline-block', margin: '10px 0 10px 50px' }}>
				{content}
			</td>
		</tr>
	);
}
