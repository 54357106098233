/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { formValueSelector } from 'redux-form';
import TableListingPage from './components/TableListingPage';
import {
	bulkDelete,
	deleteData,
	exportCsv,
	exportExcel,
	fetchData,
	importExcel,
	searchData,
	sortData
} from '../../store/actions/contacts.action';
import { currentData } from '../../store/actions/user.action';
import { modalErrorValidate, togleFilterCompaniesModal } from '../../store/actions/modal.action';
import DropdownAction from '../../components/DropdownAction';
import { getAlphabet } from '../../store/actions/select.action';

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static columns = props => {
		const { history, sortData, data, togleFilterCompaniesModal } = props;

		const onSorting = field => {
			sortData(field);
		};

		const openFilterCompaniesModal = () => {
			togleFilterCompaniesModal(true);
		};

		const columns = [
			{
				dataField: 'lastName',
				text: <Translate id="lastname" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
                (data.sort === 'asc' ? (
                	<i className="fas fa-caret-down" />
                ) : (
                	<i className="fas fa-caret-up" />
                ))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div
						className="table-key"
						onClick={() => {
							history.push(`/contacts/detail/${row.id}`);
						}}
					>
						{(row.lastName && row.lastName !== ' ' && row.lastName) || '-'}
					</div>
				)
			},
			{
				dataField: 'firstName',
				text: <Translate id="firstname" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
                (data.sort === 'asc' ? (
                	<i className="fas fa-caret-down" />
                ) : (
                	<i className="fas fa-caret-up" />
                ))}
						</button>
					</div>
				),
				formatter: (cell, row) => <div>{row.firstName || '-'}</div>
			},
			{
				dataField: 'clientCompanyName',
				text: <Translate id="company" />,
				headerFormatter: (cell, row) => {
					const { companyId } = props;
					let activeColor = '';

					if (companyId && companyId.value !== null) {
						activeColor = 'text-sidely';
					} else {
						activeColor = '';
					}

					return (
						<div>
							{cell.text}{' '}
							<button
								className="filter-table"
								onClick={openFilterCompaniesModal}
							>
								<i className={`fas fa-filter ${activeColor}`} />
							</button>
							<button
								className="filter-table"
								onClick={() => {
									onSorting(cell.dataField);
								}}
							>
								{data.sortField !== cell.dataField && (
									<i className="fas fa-caret-down" />
								)}
								{data.sortField === cell.dataField &&
                  (data.sort === 'asc' ? (
                  	<i className="fas fa-caret-down" />
                  ) : (
                  	<i className="fas fa-caret-up" />
                  ))}
							</button>
						</div>
					);
				},
				formatter: (cell, row) => (
					<div className="titleEvent" style={{ width: '15rem' }}>
						{row.clientCompanyName || '-'}
					</div>
				)
			},
			{
				dataField: 'phone',
				text: <Translate id="phone" />,
				formatter: (cell, row) => <div>{row.phone || '-'}</div>
			},
			{
				dataField: 'email',
				text: <Translate id="email" />,
				formatter: (cell, row) => (
					<button className="btn btn-link table-link p-0">
						{row.email || '-'}
					</button>
				)
			},
			{
				dataField: 'position',
				text: <Translate id="position" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
                (data.sort === 'asc' ? (
                	<i className="fas fa-caret-down" />
                ) : (
                	<i className="fas fa-caret-up" />
                ))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div className="titleEvent" style={{ width: '15rem' }}>
						{row.position || '-'}
					</div>
				)
			},
			{
				dataField: 'customerPlan',
				text: <Translate id="quick_action" />,
				headerStyle: { width: '100px' },
				style: { width: '100px', height: '42px' },
				formatter: (cell, row) => (
					<div>
						<DropdownAction
							id={row.id}
							name={`${row.firstName} ${row.lastName}`}
							permission='Contact'
							path="contacts"
							count={data.pagination.count}
							{...props}
						/>
					</div>
				)
			}
		];
		return columns;
	};

	render() {
		return (
			<Fragment>
				<div style={{ width: 200 }} />
			</Fragment>
		);
	}
}

const selector = formValueSelector('modalFilterCompanies');

const mapStateToProps = state => ({
	data: state.contacts,
	current: state.user.current,
	isLoadingCurrent: state.user.isLoading,
	modal: state.modal,
	companyId: selector(state, 'companiesId'),
	translate: state.fieldSettings.modalDelete
});

export default connect(mapStateToProps, {
	fetchData,
	deleteData,
	currentData,
	searchData,
	sortData,
	importExcel,
	exportExcel,
	exportCsv,
	bulkDelete,
	togleFilterCompaniesModal,
	getAlphabet,
	modalErrorValidate
})(TableListingPage({ name: 'CONTACTS', columns: Contact.columns })(Contact));
