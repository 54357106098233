import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners from '../../../components_v2/dropdown/DropdownOwners';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import DropdownTag from '../../../components_v2/dropdown/DropdownTag';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import Input from '../../../components_v2/input/Input';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import { getTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import storeLang from '../../../helpers/storeLang';
import { ITag } from '../../../../typings/proto/protobufs';
import { TextArea, TextAreaContainer, TextAreaLabel } from '../../calendar/style/NewEventStyle';
import { getCompanyContacts, getCompanyDetail, getProspectOrigins } from '../../client-companies/data/action';
import { CompanyContact, CompanyOpportunity, ProspectOrigin } from '../../client-companies/model/Model';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../../client-companies/style/Style';
import { normalizeCapital, normalizeComma, validateNumberAndEmpty } from '../../globals/FieldFunction';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany, Owner } from '../../orders/model/Model';
import { getOpportunityStatuses, postOpportunity } from '../data/Data';
import { OpportunityPostParam, OpportunityStatus } from '../model/Model';
import { Spacer } from '../style/NewOpportunityStyle';
import { NewOpportunityDefaultValues } from '../../../components_v2/creation/OpportunityCreation';

function NewOpportunity(props: {
  onClickOut?: () => void
  onCreate?: (value: CompanyOpportunity) => void
  clientCompanyId?: number
  clientCompanyName?: string
  defaultValues?: NewOpportunityDefaultValues
}): JSX.Element {
	const { defaultValues } = props;
	const translate = getTranslate(storeLang.getState().localize);
	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [title, setTitle] = React.useState<string>(defaultValues?.title ?? '');
	const [description, setDescription] = React.useState<string>(defaultValues?.description ?? '');

	const [owner, setOwner] = React.useState<Owner>();

	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);
	const [company, setCompany] = React.useState<ClientCompany>();

	const [contacts, setContacts] = React.useState<CompanyContact[]>([]);
	const [contact, setContact] = React.useState<CompanyContact>();

	const [deadline, setDeadline] = React.useState<string>(defaultValues?.deadline ?? '');
	const [amount, setAmount] = React.useState<number | undefined>(defaultValues?.amount);

	const [prospects, setProspects] = React.useState<ProspectOrigin[]>([]);
	const [prospect, setProspect] = React.useState<ProspectOrigin>();

	const [statuses, setStatuses] = React.useState<OpportunityStatus[]>([]);
	const [status, setStatus] = React.useState<OpportunityStatus>();

	const [probability, setProbability] = React.useState<number>(10);

	const [tags, setTags] = React.useState<ITag[]>([]);
	const [selectedTags, setSelectedTags] = React.useState<ITag[]>([]);
	const owners = useRecoilValue(AUsers);

	const probabilities: DropdownData<number>[] = [...Array(11)].map((o, i) => { return { label: `${10 * i}%`, value: (10 * i), selected: (10 * i) === probability }; });

	React.useEffect(() => {
		setOwner(owners.find(o => o.isYou));

		getTags(TagType.OPPORTUNITY).then(response => {
			setTags(response);
		});

		getProspectOrigins().then(response => {
			setProspects(response);
			setProspect(response.length > 0 ? response[0] : undefined);
		});

		getOpportunityStatuses().then(response => {
			setStatuses(response);
			setStatus(response.length > 0 ? response[0] : undefined);
		});

		const companyId = props.clientCompanyId ?? defaultValues?.clientCompanyId;
		if (defaultValues?.contactId && companyId) {
			getCompanyDetail(companyId).then(response => {
				setCompany({
					id: response?.company_id,
					name: response?.company_name
				} as ClientCompany);
			});
			updateContacts(companyId);
		} else if (companyId) {
			updateContacts(companyId);
		}
	}, []);

	React.useEffect(() => {
		if (company == null) {
			setContact(undefined);
			setContacts([]);
		}
	}, [company]);

	function submit(): void {
		if (owner !== undefined &&
			(props.clientCompanyId !== undefined || company !== undefined) &&
			deadline.trim().length > 0 &&
			moment(deadline).isValid() &&
			status !== undefined) {
			
			const nTitle = title.trim().length > 0 ? title.trim() : `${moment(deadline).local().format('DD/MM/YYYY')}-${props.clientCompanyName ?? ''}`;
			
			const params: OpportunityPostParam = {
				title: nTitle,
				in_charge: owner.id,
				client_company_id: props.clientCompanyId ? props.clientCompanyId : ((company != null) ? company.id : -1),
				opportunity_status_id: status.id,
				deadline: moment(deadline).format('YYYY-MM-DD HH:mm:ss'),
				amount: amount ? Number.parseFloat(`${amount}`) : undefined,
				contact_id: contact?.contact_id,
				description: description.trim().length > 0 ? description.trim() : undefined,
				probability,
				prospect_origin: prospect?.id,
				tags: selectedTags.filter(t => t.id !== undefined && t.id !== null).map(t => t.id!) ?? []
			};

			setLoading(LoadingStateEnum.LOADING);
			postOpportunity(params).then(response => {
				if (response !== -1) {
					const nOpportunity = {
						client_company_id: props.clientCompanyId ? props.clientCompanyId : ((company != null) ? company.id : -1),
						company_id: -1, // TODO récup l'info quelque part
						incharge_id: owner.id,
						incharge_email: owner.email,
						incharge_name: owner.name,
						incharge_photo: owner.photoUrl,
						opportunity_count: 1,
						opportunity_created_at: moment.utc().toLocaleString(),
						opportunity_deadline: deadline,
						opportunity_id: response,
						opportunity_probability: probability,
						opportunity_revenue: (amount ?? 0) * (probability / 100.0),
						opportunity_status_color: status.status_color_code,
						opportunity_status_id: status.id,
						opportunity_status_name: status.name,
						opportunity_title: nTitle
					} as CompanyOpportunity;

					(props.onCreate != null) && props.onCreate(nOpportunity);
					setLoading(LoadingStateEnum.LOADED);
				}
			});
		}
	}

	function updateContacts(clientCompanyId: number): void {
		getCompanyContacts({
			client_company_id: clientCompanyId,
			limit: 200
		}).then(response => {
			setContacts(response.contacts);
			if (defaultValues?.contactId) {
				setContact(response.contacts.find(c => c.contact_id === defaultValues?.contactId));
			} else {
				setContact(response.contacts.length > 0 ? response.contacts[0] : undefined);
			}
		});
	}

	const contactData = contacts.map(c => ({ label: `${c.contact_last_name} ${c.contact_first_name}`, value: c, image: c.contact_photo }));

	function body(): JSX.Element {
		return (
			<CreationPopupContainer>

				<Input
					inputStyle={creationPopupInputStyle}
					name="title"
					type="text"
					placeholder={translate('company.creation.opportunity.title').toString()}
					label={translate('company.creation.opportunity.title').toString()}
					normalize={[normalizeCapital]}
					onChange={(value) => setTitle(value)}
				/>

				<Spacer />

				<DropdownOwners
					dropdownStyle={creationPopupDropdownStyle}
					users={owners.map(o => { return { label: o.name, value: o, image: o.photoUrl }; })}
					selected={(owner != null) ? { label: owner.name, value: owner, image: owner.photoUrl } : undefined}
					border
					required
					label={translate('company.creation.contact.owner').toString()}
					onChange={(value: DropdownData) => setOwner(value.value)}
				/>

				{
					!props.clientCompanyId &&
					<>
						<Spacer />

						<DropdownSearch
							dropdownStyle={creationPopupDropdownStyle}
							datalist={optionCompanies}
							name="clientCompanyName"
							label={translate('company.creation.opportunity.company').toString()}
							optionHeight={200}
							labelUp
							required
							link={'/companies?id='}
							onChange={(value) => {
								setCompany(value?.value);
								if (value != null) {
									updateContacts((value.value as ClientCompany).id);
								}
							}}
							selectedValue={company ? { value: company, label: company.name } : undefined}
							onSearchChange={async(search, offset) => {
								return await getFilteredCompanies(search, 20, offset)
									.then(res => {
										const ret: boolean = res.data.length == 0 || res.data.length < 20;
										if (offset !== undefined) {
											setOptionCompanies([...optionCompanies, ...res.data.map((company: ClientCompany) => {
												return {
													label: company.name,
													value: company
												};
											})]);
										} else {
											setOptionCompanies(res.data.map(company => {
												return {
													label: company.name,
													value: company
												};
											}));
										}
										return ret;
									})
									.catch(error => {
										console.log(error);
										return false;
									});
							}}
						/>
					</>
				}

				<Spacer />

				{
					(company !== undefined || props.clientCompanyId !== undefined) &&
					<>
						<Dropdown
							dropdownStyle={creationPopupDropdownStyle}
							selectedValue={contactData.find(c => c.value.contact_id === contact?.contact_id)}
							datalist={contactData}
							name='dropdown_contact'
							readOnly
							label={translate('company.creation.opportunity.contact').toString()}
							cancellable
							onChange={(value: DropdownData) => setContact(value.value)}
						/>

						<Spacer />
					</>
				}

				<Input
					inputStyle={creationPopupInputStyle}
					name="deadline"
					type="date"
					label={translate('company.creation.opportunity.deadline').toString()}
					onChange={(value) => setDeadline(value)}
					required
				/>

				<Spacer />

				<Input
					inputStyle={creationPopupInputStyle}
					name="amount"
					type="text"
					placeholder={translate('company.creation.opportunity.amount').toString()}
					label={translate('company.creation.opportunity.amount').toString()}
					normalize={[normalizeComma]}
					validate={[validateNumberAndEmpty]}
					onChange={(value) => setAmount(value)}
				/>

				<Spacer />

				<Dropdown
					dropdownStyle={creationPopupDropdownStyle}
					datalist={prospects.map((p, i) => { return { label: p.name, value: p, selected: i === 0 }; })}
					name='dropdown_prospect_origin'
					readOnly
					cancellable
					label={translate('company.creation.opportunity.prospect_origin').toString()}
					onChange={(value: DropdownData) => setProspect(value.value)}
				/>

				<Spacer />

				<Dropdown
					dropdownStyle={creationPopupDropdownStyle}
					datalist={statuses.map((s, i) => { return { label: s.name, value: s, color: s.status_color_code, selected: i === 0 }; })}
					name='dropdown_status'
					required
					readOnly
					label={translate('company.creation.opportunity.status').toString()}
					onChange={(value: DropdownData) => {
						setStatus(value.value);
						setProbability((value.value as OpportunityStatus).probability);
					}}
				/>

				<Spacer />

				<Dropdown
					dropdownStyle={creationPopupDropdownStyle}
					datalist={probabilities}
					selectedValue={probabilities.find(p => p.value === probability)}
					name='probability'
					label={translate('company.creation.opportunity.probability').toString()}
					onChange={(value: DropdownData) => setProbability(value.value)}
				/>

				<Spacer />

				<DropdownTag
					dropdownStyle={{
						labelUp: true,
						optionWidth: '250px',
						optionHeight: '200px',
						optionFontWeight: '500',
						optionFontColor: '#FFFFFF',
						optionPadding: '5px 10px 5px 10px',
						optionTextWidth: 'fit-content',
						height: '38px'
					}}
					tags={tags}
					onChangeTags={(values) => setSelectedTags(values)}
				/>

				<Spacer />

				<TextAreaContainer>
					<TextAreaLabel>{translate('company.creation.opportunity.description').toString()}</TextAreaLabel>
					<TextArea onChange={(e) => setDescription(e.currentTarget.value)}></TextArea>
				</TextAreaContainer>
			</CreationPopupContainer>
		);
	}

	return (
		<PopupCreation
			title={translate('company.creation.opportunity.create_opportunity').toString()}
			content={body()}
			canValidate={owner !== undefined &&
				(props.clientCompanyId !== undefined || company !== undefined) &&
				deadline.trim().length > 0 &&
				moment(deadline).isValid() &&
				status !== undefined
			}
			onSubmit={() => submit()}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
		/>
	);
}

export default NewOpportunity;
