import * as React from 'react';
import { Translate, translateToString } from '../../../styles/global/translate';
import { generateFormWithTemplate } from '../actions';
import styled from 'styled-components';
import { Fields, Form, Options } from './type';
import { AvailableTemplates, DescriptiveFieldDescription, DescriptiveFieldTitle, ListLayout } from './listLayout';
import { Close } from '../../gallery/style';
import close from 'images/icons/orders/close.svg';
import { CreateFormModal } from './formModal';
import { shelf_audit_fields, shelf_audit_form, shelf_audit_options, shelf_audit_final_step } from './templates/shelfauditTemplate';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { useHistory } from 'react-router';
import { isComponent } from './formUtils';
import { Checkbox } from '@mui/material';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { FlexDiv } from '../../products/style';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { order_fields, order_final_step, order_form } from './templates/orderTemplate';
import { FieldRef, Layout, Screen } from '../jsonValidator';
import * as _ from 'lodash';
import { beer_fields, beer_final_step, beer_form, beer_form_options } from './templates/beerTemplate';
import { chr_form, chr_final_step, chr_fields, chr_form_options } from './templates/chrTemplate';
import { chr_presence_fields, chr_presence_final_step, chr_presence_form, chr_presence_form_options } from './templates/chrTemplatePresence';

const STEP_AHEAD = 4;

const Title = styled.div`
	color: #333333;
	font-weight: 700;
	font-size: 14px;
	line-height: 0.8;
`;

const OptionTitle = styled.div`
	color: #333333;
	font-weight: 700;
	font-size: 13px;
	line-height: 0.8;
`;


export const ContentWrapper = styled.div`
	width: 100%;
	height: calc(100vh - 130px);
	font-size: 13px;
	border-radius: 10px;
	border-radius: 5px;
	box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.01);
	background-color: white;
	padding: 20px;
	overflow: auto;
`;

export const PopupContainer = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 105;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export function pageExists(form: Form, pageName: string): boolean {
	return form.find(page => page.name === pageName) ? true : false;
}

function get_field_type(name: string | undefined, fields: Fields[]) {
	return fields.find(field => field.name === name)?.type_;
}

export function CreateForm(props: { setToolBarState: React.Dispatch<React.SetStateAction<any>> }) {

	const [selectedTemplate, setSelectedTemplate] = React.useState<string>('shelfaudit');
	const [step, setStep] = React.useState<number>(0);
	const [selectedFormTemplate, setSelectedFormTemplate] = React.useState<Form>();
	const [fieldList, setFieldList] = React.useState<Fields[]>([]);
	const [formName, setFormName] = React.useState<string>('');
	const [formOptions, setFormOptions] = React.useState<Options[]>([]);
	const [forceResume, setForceResume] = React.useState<number>(0);
	const [createAdditionalColumn, setCreateAdditionalColumn] = React.useState<boolean>(true);
	const [skippedSteps, setSkippedSteps] = React.useState<number>(0);
	const [finalStep, setFinalStep] = React.useState<number>(0);

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('forms'),
			bottomLeftToolbarComponent: <>
				{selectedFormTemplate && step - STEP_AHEAD < selectedFormTemplate.length &&
				<Title>{selectedFormTemplate[step - STEP_AHEAD] && selectedFormTemplate[step - STEP_AHEAD].name}</Title>}
			</>,
			bottomRightToolbarComponent: <>
				{step > 3 && selectedFormTemplate && <DefaultButton
					buttonStyle={ButtonStyle.White}
					onClick={() => {
						if (isComponent(selectedFormTemplate[step - STEP_AHEAD - 1]?.layout)) {
							setStep(step - 2);
						}
						else {
							if (selectedTemplate === 'order') {
								setStep(step - 2);
							}
							else {
								setStep(step - 1);
							}
						}
					}}
				>
					<Translate id='previous' />
				</DefaultButton>}
				{step > 2 && selectedFormTemplate && <DefaultButton
					onClick={() => {
						if (isComponent(selectedFormTemplate[step - STEP_AHEAD + 1]?.layout)) {
							setStep(step + 2);
						}
						else {
							setStep(step + 1);
						}
					}
					}
				>				
					{selectedFormTemplate && step === finalStep && <Translate id='form_builder.generate' />}
					{selectedFormTemplate && step < finalStep && <Translate id='next' />}
					
				</DefaultButton>}
			
			</>
		});
	}, [formName, step]);

	if (step === 0) {
		return <>
			<AvailableTemplates onUseClick={template => {
				setSelectedTemplate(template);
				setStep(step + 1);
			}} />
			
		</>;
	}
	else if (step == 1) {
		return <>
			<AvailableTemplates onUseClick={() => undefined} />
			<PopupContainer>
				<CreateFormModal
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setName={setFormName}
					setStep={setStep} />
				<Close src={close} onClick={() => {setStep(step - 1);}} />
			</PopupContainer>
		</>;
	}
	else if (step == 2) {
		if (selectedTemplate === 'shelfaudit') {
			setSelectedFormTemplate(shelf_audit_form);
			setFieldList(shelf_audit_fields);
			setFormOptions(shelf_audit_options);
			setFinalStep(shelf_audit_final_step);
			setStep(3);
		}
		else if (selectedTemplate === 'order') {
			setSelectedFormTemplate(order_form);
			setFieldList(order_fields);
			setCreateAdditionalColumn(false);
			setFinalStep(order_final_step);
			setStep(3);
		}
		else if (selectedTemplate === 'beer') {
			setSelectedFormTemplate(beer_form);
			setFieldList(beer_fields);
			setFormOptions(beer_form_options);
			setFinalStep(beer_final_step);
			setStep(3);
		}
		else if (selectedTemplate === 'chr') {
			setSelectedFormTemplate(chr_form);
			setFieldList(chr_fields);
			setFormOptions(chr_form_options);
			setFinalStep(chr_final_step);
			setStep(3);
		}
		else if (selectedTemplate === 'chrpresence') {
			setSelectedFormTemplate(chr_presence_form);
			setFieldList(chr_presence_fields);
			setFormOptions(chr_presence_form_options);
			setFinalStep(chr_presence_final_step);
			setStep(3);

		}
	}
	else if (step == 3) {
		const DraftTypeList: DropdownData[] = [
			{ value: 0, label: translateToString('form_builder.drafts.none') },
			{ value: 1, label: translateToString('form_builder.drafts.per_company') },
			{ value: 2, label: translateToString('form_builder.drafts.per_user') },
		];
		return <>
			<ContentWrapper>
				<Title>Choissisez les paramètres de votre formulaire</Title>
				<br /><br />
				<FlexDiv style={{ 'paddingLeft': '45px' }}>
					<OptionTitle style={{ 'paddingRight': '13px' }}>Comportement des brouillons</OptionTitle>
					<Dropdown name='drafttype' selectedValue={DraftTypeList.find(dt => dt.value === forceResume)} datalist={DraftTypeList} onChange={value => setForceResume(value.value)} />
				</FlexDiv>
				<FlexDiv style={{ 'paddingLeft': '33px' }}>
					<Checkbox onChange={checkbox => { setCreateAdditionalColumn(checkbox.target.checked); }} checked={createAdditionalColumn} /> <OptionTitle> Créer un assortiment lié </OptionTitle>
				</FlexDiv>
				<br />
				{formOptions && formOptions.map((option, index) => {
					return <>
						<div style={{ 'paddingLeft': '30px' }}>
							<div style={{ 'display': 'flex', 'marginTop': '-12px' }}>
								<Checkbox onChange={checkbox => { formOptions.find(o => o.name === option.name)!.value = checkbox.target.checked; setFormOptions([ ...formOptions ]);}} disabled={option.mandatory} checked={option.value} /> <OptionTitle key={index}>{option.title}</OptionTitle>
							</div>
							<div style={{
								'paddingLeft': '42px',
								'marginTop': '-20px',
								'fontSize': '13px',
								'color': '#4C5862',
							}
							}>{option.description}</div>
							<br /><br />
						</div>
					</>;
				})}
			</ContentWrapper>
		</>;
	}
	else if (step <= finalStep && selectedFormTemplate) {
		if (selectedFormTemplate[step - STEP_AHEAD].name == 'Récapitulatif produits' ||
			selectedFormTemplate[step - STEP_AHEAD].name == 'Ajouter produit(s)' ||
			selectedFormTemplate[step - STEP_AHEAD].name == 'Catalogues non liés' ||
			selectedFormTemplate[step - STEP_AHEAD].name == 'Nouvelle commande' ||
			selectedFormTemplate[step - STEP_AHEAD].name == 'Choix du point de vente' ||
			selectedFormTemplate[step - STEP_AHEAD].name == 'Choix assortiment' ||
			selectedFormTemplate[step - STEP_AHEAD].name == 'Choix du produit'
			
		) {
			setStep(step + 1);
			setSkippedSteps(skippedSteps + 1);
		}
		if (formOptions.length > 0) {
			if (formOptions.find(option => option.name === 'part-lineaire')?.value) {
				selectedFormTemplate[4].fields.find(field => field.name === 'nombre-de-facings')!.field_id = 17;
				fieldList.find(field => field.name === 'nombre-de-facings')!.mandatory = true;
				fieldList.find(field => field.name === 'nombre-de-facings')!.inDraggable = true;
			}
		}
		return <>
			<ContentWrapper>
				{selectedFormTemplate && selectedFormTemplate[step - STEP_AHEAD].template_title && <DescriptiveFieldTitle>{selectedFormTemplate && selectedFormTemplate[step - STEP_AHEAD].template_title}</DescriptiveFieldTitle>}
				{selectedFormTemplate && selectedFormTemplate[step - STEP_AHEAD].template_description && <DescriptiveFieldDescription>{selectedFormTemplate && selectedFormTemplate[step - STEP_AHEAD].template_description}</DescriptiveFieldDescription>}
				{selectedFormTemplate && selectedFormTemplate[step - STEP_AHEAD].template_title && <br />}
				<ListLayout
					setForm={setSelectedFormTemplate}
					form={selectedFormTemplate}
					layout={selectedFormTemplate[step - STEP_AHEAD].layout}
					setFieldList={setFieldList}
					fieldList={fieldList}
					currentPage={step - STEP_AHEAD}
					template={selectedTemplate}
				/>
			</ContentWrapper>
		</>;
	}
	else if (selectedFormTemplate && step > selectedFormTemplate.length) {
		generateForm(selectedFormTemplate, formName, forceResume, fieldList, formOptions.find(option => option.name === 'picking')?.value,
			formOptions.find(option => option.name === 'part-lineaire')?.value, createAdditionalColumn, selectedTemplate, formOptions.find(option => option.name === 'grid')?.value, formOptions.find(option => option.name === 'recapitulatif')?.value);
		setStep(0);
		return <></>;
	}
	return <></>;
}

export function generateForm(
	form: Form,
	name: string,
	forceResume: number,
	fields: Fields[],
	picking = false,
	partLineare = false,
	createAdditionalColumn = false,
	template: string,
	grid = false,
	recapitulatif = true,) {
	const form_copy = structuredClone(form);

	const fieldLenght = fields.length;
	if (partLineare) {
		fields.push(
			{
				'field_id': fieldLenght + 2,
				'name': 'part-de-lineaire',
				'type_': 'Number',
				'display_name': 'Part de linéaire (%)',
			},
			{
				'field_id': fieldLenght + 3,
				'name': 'nombre-etagere',
				'type_': 'Integer',
				'display_name': 'Nombre d\'étagères',
			},
			{
				'field_id': fieldLenght + 4,
				'name': 'longueur-etagere',
				'type_': 'Number',
				'display_name': 'Longueur rayon (en cm)',
			}
		);

		form_copy.forEach(page => {
			if (page.name === 'Informations du produit') {
				page.fields.push({
					'field_id': fieldLenght + 2,
					'calculated_value': 'calc_part_de_lineaire_for_product(false)',
					'metadata': {
						'catalogue': null,
						'product': null
					},
					'name': 'part-de-lineaire'
				});
				// push part de linéaire après nombre de facings
				page.layout.children?.push({
					'name': 'part-de-lineaire',
					'type': 'field',
					'visible_if': 'current_screen.fields["produit-present"].value == true'
				});
			}
			else if (page.name === 'État du linéaire avant passage') {
				page.fields.unshift(
					{
						'field_id': fieldLenght + 3,
						'metadata': {
							'catalogue': null
						},
						'name': 'nombre-etagere'
					},
					{
						'field_id': fieldLenght + 4,
						'metadata': {
							'catalogue': null
						},
						'name': 'longueur-etagere'
					}
				);
				if (page.layout.children) {
					page.layout.children?.unshift(
						{
							'name': 'nombre-etagere',
							'type': 'field'
						},
						{
							'name': 'longueur-etagere',
							'type': 'field'
						}
					);				
				}
				else {
					page.layout.children = [
						{
							'name': 'nombre-etagere',
							'type': 'field'
						},
						{
							'name': 'longueur-etagere',
							'type': 'field'
						}
					];
					page.layout.children.push({ 'name': page.layout.name, 'type': 'field' });
					page.layout.name = undefined;
					page.layout.type = 'column';
				}
	
			}
			else if (page.name === 'État du linéaire après passage') {
				page.fields.push({
					'field_id': fieldLenght + 2,
					'calculated_value': 'calc_part_de_lineaire(false)',
					'metadata': {
						'catalogue': null
					},
					'name': 'part-de-lineaire',
				});

				page.layout.type = 'column';
				page.layout.name = undefined;
				page.layout.children = [
					{
						'name': 'd.n.',
						'type': 'field'
					},
					{
						'name': 'part-de-lineaire',
						'type': 'field'
					},
					{
						'name': 'lineaire-apres-releve',
						'type': 'field'
					}
				];
			}
		});
	}
	
	
	if (picking) {
		let pickingPage: Screen | undefined;
		let productDispatcherPage: Screen | undefined;

		form_copy.forEach(page => {
			if (page.name === 'Informations du produit') {
				pickingPage = structuredClone(page);
				pickingPage.fields.forEach(field => {
					field.validator = undefined;
				});
			}
			else if (page.name === 'Choix du produit') {
				productDispatcherPage = page;
			}
		});

		if (productDispatcherPage) {
			productDispatcherPage.layout = {
				'children': [
					{
						'name': 'lien-picking',
						'type': 'component'
					},
					{
						'name': 'liste-produits',
						'type': 'component'
					}
				],
				'type': 'column'
			};

			productDispatcherPage.components.push({
				'data': [
					'Catalogue picking'
				],
				'name': 'lien-picking',
				'type': 'links',
				'options': {
					'display': 'text'
				},
			});
		}



		if (pickingPage) {
			pickingPage.name = 'Détails produit picking';
			pickingPage.next_screens[0].screen_name = 'Catalogue picking';
			pickingPage.fields.forEach(field => {
				if (field.calculated_value?.includes('calc_part_de_lineaire_for_product(false)')) {
					field.calculated_value = 'calc_part_de_lineaire_for_product(true)';
				}
				field.metadata = {
					'catalogue': null,
					'picking': true,
					'product': null
				};
			});
			pickingPage.fields.find(field => field.name === 'produit-present')!.validator = undefined;
			pickingPage.fields.find(field => field.name === 'produit-present')!.default = undefined;
			pickingPage.progression = false;
			form_copy.push(pickingPage);
		}
	}
	let audit_layout: Layout | undefined;
	let audit_picking_layout: Layout | undefined;
	let audit_fields: FieldRef[] | undefined;

			
	form_copy.forEach(page => {
		if (page.name === 'Informations du produit') {

			if (grid) {
				page.fields.forEach(field => {
					// if field is boolean
					if (get_field_type(field.name, fields) === 'Boolean') {
						field.options = {
							'display': 'checkbox'
						};
					}
				});
			}

			audit_fields = _.cloneDeep(page.fields);
			audit_layout = structuredClone(page.layout);

			audit_fields = audit_fields.filter(field => field.field_id != 0);
			audit_layout.children = audit_layout.children?.filter(child => audit_fields?.find(field => field.name === child.name));

			// transform recap into grid uncomment when boolean are responsive
			// audit_layout.children?.forEach((child, index) => {
			// 	if (audit_layout?.children?.length || 0 > index && child.name && get_field_type(child.name, fields) !== 'Boolean' && audit_layout?.children && get_field_type(audit_layout.children[index + 1].name, fields) !== 'Boolean') {
			// 		audit_layout.children[index] = {
			// 			'children': [
			// 				child,
			// 				audit_layout.children[index + 1]
			// 			],
			// 			'type': 'row'
			// 		};
			// 		audit_layout.children.splice(index + 1, 1);
			// 	}
			// });

			if (picking && audit_layout) {
				audit_picking_layout = structuredClone(audit_layout);
				audit_picking_layout.children?.forEach(child => {
					child.name = child.name + '-picking';
					child.visible_if = child.visible_if?.replace('produit-present', 'produit-present-picking');
				});
			}
		}
		else if (template === 'shelfaudit' && page.name === 'Choix du produit' && recapitulatif) {
			page.next_screens = [
				{
					'condition': 'true',
					'screen_name': 'Récapitulatif produits'
				}
			];
		}
		else if (template === 'shelfaudit' && page.name === 'Choix assortiment') {
			if (!pageExists(form_copy, 'Etat du linéaire avant passage')) {
				page.components[0].screen.screen_name = 'Choix du produit';
			}
		}
	});
	if (typeof audit_layout !== 'undefined' && audit_fields && template === 'shelfaudit') {
		const recapPage: Screen = {
			'components': [
				{
					'name': 'no-presence',
					'type': 'text',
					'data': '"Aucun produit présent"',
				},
				{
					'data': {
						'columns': [
							{
								'child': {
									'children': audit_layout.children,
									'type': 'column'
								},
								'title': {
									'children': [
										{
											'child': {
												'name': 'product_image',
												'type': 'component'
											},
											'options': {
												'display': {
													'width': 50
												}
											},
											'type': 'container'
										},
										{
											'name': 'product_text',
											'type': 'component'
										}
									],
									'type': 'row'
								},
								'name': 'Produits présents',
								'type': 'expanded_section'
							}
						],
						'name': '{"product": null}',
						'read_only': true,
						'value': 'get_present_or_not_present_products(true, false)'
					},
					'name': 'present-products',
					'type': 'card_list'
				},
				{
					'data': 'getProductByUuid(_R.product).images',
					'name': 'product_image',
					'type': 'image'
				},
				{
					'data': 'getProductByUuid(_R.product).name',
					'name': 'product_text',
					'type': 'text'
				}
			],
			'fields': audit_fields,
			'layout': {
				'children': [
					{
						'name': 'present-products',
						'type': 'component'
					},
					{
						'name': 'no-presence',
						'type': 'component',
						'visible_if': '#get_present_or_not_present_products(true, false) + #get_present_or_not_present_products(true, true) == 0'
					}
				],
				'type': 'column'
			},
			'name': 'Récapitulatif produits',
			'next_screens': [
				{
					'condition': 'true',
					'screen_name': 'État du linéaire après passage'
				}
			]
		};

		if (picking) {
			recapPage.layout.children?.push(
				{
					'name': 'presence-picking',
					'type': 'component'
				}
			);
		}

		if (picking && audit_picking_layout) {
			recapPage.components.push(
				{
					'data': {
						'columns': [
							{
								'child': {
									'children': audit_picking_layout.children,
									'type': 'column'
								},
								'title': {
									'children': [
										{
											'child': {
												'name': 'product_image',
												'type': 'component'
											},
											'options': {
												'display': {
													'width': 50
												}
											},
											'type': 'container'
										},
										{
											'name': 'product_text',
											'type': 'component'
										}
									],
									'type': 'row'
								},
								'name': 'Produits présents picking',
								'type': 'expanded_section'
							}
						],
						'name': '{"product": null, "picking": true}',
						'read_only': true,
						'value': 'get_present_or_not_present_products(true, true)'
					},
					'name': 'presence-picking',
					'type': 'card_list'
				},
			);
		}
		if (recapitulatif) {
			form_copy.push(recapPage);
		}
	}
	if (picking) {
	
		const componentPage: Screen = {
			'components': [
				{
					'filters': [
						{
							'name': 'Présence',
							'type': 'checkbox',
							'values': [
								{
									'function': 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(4, {["product"] = _F.item}) == true',
									'label': 'Produits présents'
								},
								{
									'function': 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(4, {["product"] = _F.item}) == false',
									'label': 'Produits absents'
								}
							]
						}
					],
					'data': {
						'callback': [
							{
								'color': 'green',
								'condition': 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(4, {["picking"] = true}) == true',
								'type': 'border-side'
							}
						],
						'name': 'product',
						'value': 'get_picking_products()'
					},
					'name': 'liste-picking',
					'screen': {
						'condition': 'true',
						'screen_name': 'Détails produit picking'
					},
					'type': 'product_dispatcher'
				}
			],
			'fields': [],
			'layout': {
				'children': [
					{
						'name': 'liste-picking',
						'type': 'component'
					}
				],
				'type': 'column'
			},
			'name': 'Catalogue picking',
			'next_screens': [
				{
					'condition': 'true',
					'screen_name': 'Choix du produit'
				}
			]
		};
		form_copy.push(componentPage);
	}

	const history = useHistory();
	generateFormWithTemplate(grid, form_copy, name, forceResume, fields, createAdditionalColumn, template).then(() => history.push({ pathname: 'settings', hash: 'form_templates' }));
}
