import axios from 'axios';
import swal from 'sweetalert2';
import { Cookies } from 'react-cookie';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const GET_COLOR_STATUS = 'GET_COLOR_STATUS';

export const GET_CLIENT_STATUS = 'GET_CLIENT_STATUS';

export const GET_DATA_TAG = 'GET_DATA_TAG';

export const CREATE_CLIENT_STATUS_REQUEST = 'CREATE_CLIENT_STATUS_REQUEST';
export const CREATE_CLIENT_STATUS_SUCCESS = 'CREATE_CLIENT_STATUS_SUCCESS';
export const CREATE_CLIENT_STATUS_ERROR = 'CREATE_CLIENT_STATUS_ERROR';

export const UPDATE_DATA_REQUEST = 'UPDATE_DATA_REQUEST';
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_ERROR = 'UPDATE_DATA_ERROR';

export const DETAIL_DATA_REQUEST = 'DETAIL_DATA_REQUEST';
export const DETAIL_DATA_SUCCESS = 'DETAIL_DATA_SUCCESS';
export const DETAIL_DATA_ERROR = 'DETAIL_DATA_ERROR';

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR';

export const DELETE_OPPORTUNITY_REQUEST = 'DELETE_OPPORTUNITY_REQUEST';
export const DELETE_OPPORTUNITY_SUCCESS = 'DELETE_OPPORTUNITY_SUCCESS';
export const DELETE_OPPORTUNITY_ERROR = 'DELETE_OPPORTUNITY_ERROR';

export const TAP_OPTION = 'TAP_OPTION';
export const GET_TRANSLATE = 'GET_TRANSLATE';
export const GET_DATA_MODAL = 'GET_DATA_MODAL';

export const GET_OPPORTUNITY_STATUS = 'GET_OPPORTUNITY_STATUS';
export const GET_COLOR_DATA = 'GET_COLOR_DATA';
export const GET_COLOR_ID = 'GET_COLOR_ID';
export const GET_PROBABILITY = 'GET_PROBABILITY';
export const SET_WIDTH = 'SET_WIDTH';
export const GET_PROBABILITY_DATA = 'GET_PROBABILITY_DATA';

export const CREATE_OPPORTUNITY_STATUS_REQUEST =
  'CREATE_OPPORTUNITY_STATUS_REQUEST';
export const CREATE_OPPORTUNITY_STATUS_SUCCESS =
  'CREATE_OPPORTUNITY_STATUS_SUCCESS';
export const CREATE_OPPORTUNITY_STATUS_ERROR =
  'CREATE_OPPORTUNITY_STATUS_ERROR';

export const UPDATE_OPPORTUNITY_STATUS_REQUEST =
  'UPDATE_OPPORTUNITY_STATUS_REQUEST';
export const UPDATE_OPPORTUNITY_STATUS_SUCCESS =
  'UPDATE_OPPORTUNITY_STATUS_SUCCESS';
export const UPDATE_OPPORTUNITY_STATUS_ERROR =
  'UPDATE_OPPORTUNITY_STATUS_ERROR';

export const UPDATE_SEQUENCE_REQUEST = 'UPDATE_SEQUENCE_REQUEST';
export const UPDATE_SEQUENCE_SUCCESS = 'UPDATE_SEQUENCE_SUCCESS';
export const UPDATE_SEQUENCE_ERROR = 'UPDATE_SEQUENCE_ERROR';

export const getClientStatus = status => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(`${URL_API}/api/clientStatuses?status=${status}`);
	dispatch({
		type: GET_CLIENT_STATUS,
		payload: res.data
	});
};

export function createData(data, path, tap) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_CLIENT_STATUS_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${URL_API}/api/${path}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_CLIENT_STATUS_SUCCESS,
						payload: response.data
					});
					if (path === 'clientStatuses') {
						dispatch(getClientStatus(true));
					} else {
						dispatch(getAllTag(true, tap));
					}
					// window.location.href = `/systemSettings/fieldSettings`;
				},
				error => {
					dispatch({
						type: CREATE_CLIENT_STATUS_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.message, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_CLIENT_STATUS_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateTag(data, id, path, tap) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${URL_API}/api/${path}/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_DATA_SUCCESS,
					payload: response.data
				});
				if (path === 'clientStatuses') {
					dispatch(getClientStatus(true));
				} else {
					dispatch(getAllTag(true, tap));
				}
				// window.location.href = `/systemSettings/fieldSettings`;
			},
			error => {
				dispatch({
					type: UPDATE_DATA_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.message, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export const getAllTag = (status, type) => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(
		`${URL_API}/api/tags?status=${status}&type=${type}&by=colorStatusesId&sort=desc`
	);
	dispatch({
		type: GET_DATA_TAG,
		payload: res.data
	});
};

export function detailData(id, path) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_API}/api/${path}/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_DATA_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_DATA_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_DATA_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteData(id, path, tap) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_TAG_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${URL_API}/api/${path}/${id}`
		}).then(
			() => {
				if (path === 'clientStatuses') {
					dispatch(getClientStatus(true));
				} else {
					dispatch(getAllTag(true, tap));
				}
			},
			error => {
				swal.fire('Error!', error.response.data.message, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteStatus(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_OPPORTUNITY_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${URL_API}/api/opportunityStatuses/${id}`
		}).then(
			() => {
				dispatch(getOportunityStatus(true));
			},
			error => {
				swal.fire('Error!', error.response.data.message, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export const getBulkTranslate = data => async dispatch => {
	dispatch({
		type: GET_TRANSLATE,
		payload: data
	});
};

export const setWidth = data => async dispatch => {
	dispatch({
		type: SET_WIDTH,
		payload: data
	});
};

export const getOportunityStatus = status => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(
		`${URL_API}/api/opportunityStatuses?status=${status}`
	);
	dispatch({
		type: GET_OPPORTUNITY_STATUS,
		payload: res.data
	});
};