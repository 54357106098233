/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
// import { selectInCharge } from '../../store/actions/select.action';
import { selectAllUser, selectInCharge } from '../../store/actions/select.action';
import { currentData } from '../../store/actions/user.action';
import noUserImage from 'images/icons/user.svg';

class SelectAllUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			opt: []
		};
	}

	componentDidMount() {
		const { currentData } = this.props;
		currentData();
	}

	componentWillReceiveProps(nextProps) {
		const {
			data,
			current,
			selectInCharge,
			selectAllUser,
			dataUser
		} = this.props;

		if (current !== nextProps.current) {
			if (
				nextProps.current.role.slug === 'admin' ||
        nextProps.current.role.slug === 'owner'
			) {
				selectAllUser(nextProps.current.companyId);
			} else if (nextProps.current.role.slug === 'manager') {
				selectInCharge(
					nextProps.current.id,
					nextProps.current.companyId,
					nextProps.current.role.slug
				);
			} else {
				selectAllUser(nextProps.current.id);
			}
		}
		const opt = [];
		if (data !== nextProps.data) {
			if (current.role.slug !== 'manager') {
				opt.push({
					value: current.id,
					label: (
						<div>
							{' '}
							<img
								src={current.photoUrl || noUserImage}
								alt=""
								className="img-xs rounded-circle mr-2 image-thumbnail"
							/>{' '}
							{current.status == true && `${current.name}`}
							{current.status == false && <span style={{ opacity: '50%' }}>{current.name}</span>}
						</div>
					)
				});

				let actives = nextProps.data
					.filter(data => data.status == true)
					.map(data => {
						current.id !== data.id &&
          opt.push({
          	value: data.id,
          	label: (
          		<div>
          			{' '}
          			<img
          				src={data.photoUrl || noUserImage}
          				alt=""
          				className="img-xs rounded-circle mr-2 image-thumbnail"
          			/>{' '}
          			{data.name}
          		</div>
          	)
          });
					});

				let inactives = nextProps.data
					.filter(data => data.status == false)
					.map(data => {
						current.id !== data.id &&
            opt.push({
            	value: data.id,
            	label: (
            		<div>
            			{' '}
            			<img
            				src={data.photoUrl || noUserImage}
            				alt=""
            				className="img-xs rounded-circle mr-2 image-thumbnail"
            			/>{' '}
            			<span style={{ opacity: '50%' }}>{data.name}</span>
            		</div>
            	)
            });
					});

				actives.concat(inactives);

				this.setState({ opt });
			} else {
				this.setState({
					opt: [
						{
							value: current.id,
							label: (
								<div>
									{' '}
									<img
										src={current.photoUrl || noUserImage}
										alt=""
										className="img-xs rounded-circle mr-2 image-thumbnail"
									/>{' '}
									{current.status == true && `${current.name}`}
									{current.status == false && <span style={{ opacity: '50%' }}>{current.name}</span>}
								</div>
							)
						}
					]
				});
			}
		}

		if (dataUser !== nextProps.dataUser) {
			if (current.role.slug === 'manager') {
				opt.push({
					value: current.id,
					label: (
						<div>
							{' '}
							<img
								src={current.photoUrl || noUserImage}
								alt=""
								className="img-xs rounded-circle mr-2 image-thumbnail"
							/>{' '}
							{`${current.name} (You)`}
						</div>
					)
				});

				nextProps.dataUser.map(data => {
					current.id !== data.id &&
            opt.push({
            	value: data.id,
            	label: (
            		<div>
            			{' '}
            			<img
            				src={data.photoUrl || noUserImage}
            				alt=""
            				className="img-xs rounded-circle mr-2 image-thumbnail"
            			/>{' '}
            			{`${data.name}`}
            		</div>
            	)
            });
				});
				this.setState({ opt });
			}
		}
	}

	render() {
		const { opt } = this.state;
		const {
			validate,
			label,
			requiredStar,
			name,
			placeholder,
			disabled,
			readOnly
		} = this.props;
		// console.log('current', current);
		// console.log('id', id);
		return (
			<Field
				name={name}
				component={RenderSelectInput}
				label={label}
				disabled={disabled}
				readOnly={readOnly}
				requiredStar={requiredStar}
				options={opt}
				id="inputOwner"
				placeholder={placeholder}
				validate={validate}
			/>
		);
	}
}

const mapStateToProps = state => ({
	data: state.select.allUser,
	dataUser: state.select.inCharge,
	current: state.user.current
});

export default connect(
	mapStateToProps,
	{ selectInCharge, currentData, selectAllUser }
)(SelectAllUser);
