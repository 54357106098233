import * as React from 'react';
import { CompanyOpportunity } from '../../containers_v2/client-companies/model/Model';
import NewOpportunity from '../../containers_v2/opportunities/popup/NewOpportunity';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';

export type NewOpportunityDefaultValues = {
	title?: string
	contactId?: number
	clientCompanyId?: number
	description?: string
	amount?: number
	deadline?: string
  }

function OpportunityCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  onCreate?: (value: CompanyOpportunity) => void
  clientCompanyId?: number
  clientCompanyName?: string
  defaultValues?: NewOpportunityDefaultValues
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, top: '0%' }}
			popupMode={PopupMode.Details}
			content={<NewOpportunity
				onClickOut={() => close()}
				onCreate={(value) => {
					(props.onCreate != null) && props.onCreate(value);
					close();
				}}
				clientCompanyId={props.clientCompanyId}
				clientCompanyName={props.clientCompanyName}
				defaultValues={props.defaultValues}
			/>}
		/>
	);
}

export default OpportunityCreation;
