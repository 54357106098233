import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { Address } from '../model/Model';
import Input from '../../input/Input';
import InputAddress from '../../input/InputAddress';
import { InputAddressResult } from '../../input/model/Model';
import { normalizeCapitalize } from '../../../helpers/fieldMask';
import storeLang from '../../../helpers/storeLang';
import { validateIntegerNumberAndEmpty } from '../../../containers_v2/globals/FieldFunction';
import { Body, Button, Container, Cross, Footer, Header, Row, Title } from '../../../containers_v2/orders/billing/style/ModifyAddressStyle';

function PopupModifyAddress(props: {
  title: string
  defaultAddress?: Address
  onChange: (newAddress: Address) => void
  onClose: () => void
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const [street, setStreet] = React.useState<string>('');
	const [postalCode, setPostalCode] = React.useState<string>('');
	const [town, setTown] = React.useState<string>('');
	const [country, setCountry] = React.useState<string>('');
	const [coord, setCoord] = React.useState<{ longitude: number, latitude: number }>();

	React.useEffect(() => {
		setPostalCode(props.defaultAddress?.postalCode ? props.defaultAddress.postalCode.toString() : '');
		setTown(props.defaultAddress?.town ? props.defaultAddress.town : '');
		setCountry(props.defaultAddress?.country ? props.defaultAddress.country : 'France');
		setStreet(props.defaultAddress?.street ?? '');
	}, [JSON.stringify(props.defaultAddress)]);

	function submit(): void {
		const result = {
			street: street && street.length > 0 ? street : undefined,
			postalCode: postalCode && postalCode.length > 0 ? postalCode : undefined,
			town: town && town.length > 0 ? town : undefined,
			country: country && country.length > 0 ? country : undefined,
			latitude: coord?.latitude,
			longitude: coord?.longitude
		} as Address;
		props.onChange(result);
	}

	function updateAddress(address: InputAddressResult) {
		setPostalCode(address.postcode);
		setTown(address.city);
		setCountry(address.country);
		setStreet(`${address.street_number} ${address.route}`);
		setCoord({ longitude: address.longitude, latitude: address.latitude });
	}

	function updateStringAddress(value: string): void {
		setStreet(value);
	}

	return (
		<Container>
			<Header>
				<Cross onClick={() => props.onClose()} />
				<Title>{props.title}</Title>
			</Header>
			<Body>
				<Row>
					<InputAddress
						name="street"
						type="text"
						label={translate('orders.billing.popup.street').toString()}
						normalize={[normalizeCapitalize]}
						value={street}
						maxLength={255}
						onChange={(value: InputAddressResult) => updateAddress(value)}
						onChangeStringAddress={(value) => updateStringAddress(value)}
					/>
				</Row>
				<Row>
					<Input name="postal_code" type="text" label={translate('orders.billing.popup.postalCode').toString()} maxLength={5} value={postalCode} validate={[validateIntegerNumberAndEmpty]} onChange={(value) => setPostalCode(value)} />
				</Row>
				<Row>
					<Input name="town" type="text" label={translate('orders.billing.popup.town').toString()} normalize={[normalizeCapitalize]} value={town} maxLength={255} onChange={(value) => setTown(value)} />
				</Row>
				<Row>
					<Input name="country" type="text" label={translate('orders.billing.popup.country').toString()} value={country} normalize={[normalizeCapitalize]} maxLength={255} onChange={(value) => setCountry(value)}/>
				</Row>
			</Body>
			<Footer>
				<Button onClick={() => submit()}>Enregistrer</Button>
			</Footer>
		</Container>
	);
}

export default PopupModifyAddress;
