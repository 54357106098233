import React, {Component} from 'react';

class Header extends Component {
  state = {};

  render() {
    const { data } = this.props;

    return (
      <div className="form-group row">
        <div className="col-md-3" />
        <div className="col-md-9">
          <h3 className="font-weight-bold">{data.field_name}</h3>
        </div>
      </div>
    );
  }
}

export default Header;
