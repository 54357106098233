import close from 'images/icons/orders/close.svg';
import new_tab_white from 'images/icon/new_tab_white.svg';
import * as React from 'react';
import { checkClickOut } from '../dropdown/Dropdown';
import { useWindowDimensions } from '../utils';
import { IPhoto } from './model/Model';
import { BlackContainer, Close, DetailsContainer, ImageSwapper, PhotoDetails, Spacer } from './style/Style';

function PhotoVisualizer(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  photos?: IPhoto[]
  selected?: number
  showCounter?: boolean
}): JSX.Element {
	const { height: screenHeight, width: screenWidth } = useWindowDimensions();

	const photos = React.useMemo<IPhoto[]>(() => props.photos ?? [], [JSON.stringify(props.photos)]);
	const [selected, setSelected] = React.useState<number>(props.selected ?? 0);

	const [photoSize, setPhotoSize] = React.useState<{ nat_width: number, nat_height: number }>({ nat_width: 1, nat_height: 1 });
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	checkClickOut(wrapperRef, props.setOpen);

	const { width, height } = React.useMemo(() => resizeImage(photoSize.nat_width, photoSize.nat_height), [photoSize]);

	React.useEffect(() => {
		setSelected(props.selected ?? 0);
	}, [props.selected]);

	function resizeImage(srcWidth, srcHeight, maxWidth = screenWidth - 300, maxHeight = screenHeight - 300): { width: number, height: number } {
		maxWidth = maxWidth < 0 ? 0 : maxWidth;
		maxHeight = maxHeight < 0 ? 0 : maxHeight;
		const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
		return { width: srcWidth * ratio, height: srcHeight * ratio };
	}

	if (!props.isOpen || photos.length <= 0) {
		return <></>;
	}

	return (
		(<BlackContainer>
			{/* @ts-expect-error ref */}
			<DetailsContainer innerRef={wrapperRef}>
				{
					photos[selected].title &&
			<PhotoDetails fontWeight={500}>
				<div
					style={{ display: 'inline-block', cursor: photos[selected].title_url ? 'pointer' : 'initial' }}
					onClick={() => photos[selected].title_url && window.open(photos[selected].title_url, '_blank')}
				>
					{photos[selected].title}
					{
						photos[selected].title_url &&
	<img src={new_tab_white} style={{ height: 15, marginLeft: 5 }} />
					}
				</div>
			</PhotoDetails>
				}
				{
					props.showCounter &&
					<PhotoDetails fontSize={14}>
						{`${selected + 1} / ${photos.length}`}
					</PhotoDetails>
				}
				<div style={{
					marginTop: 20,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					{
						selected > 0
							? <ImageSwapper onClick={() => setSelected(selected - 1)} />
							: <Spacer />
					}
					<img
						src={photos[selected].src}
						height={height}
						width={width}
						onLoad={(e) => setPhotoSize({ nat_width: e.currentTarget.naturalWidth, nat_height: e.currentTarget.naturalHeight })}
					/>
					{
						selected < (photos.length - 1)
							? <ImageSwapper right onClick={() => setSelected(selected + 1)} />
							: <Spacer />
					}
				</div>
			</DetailsContainer>
			<Close src={close} onClick={() => props.setOpen(false)} />
		</BlackContainer>)
	);
}

export default PhotoVisualizer;
