/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/**
 * <Preview />
 */

import React from 'react';
import update from 'immutability-helper';
import {connect} from 'react-redux';
import {get, head} from 'lodash';
import store from './services/store';
import FormElementsEdit from './form-elements-edit';
import SortableFormElements from './sortable-form-elements';

const { PlaceHolder } = SortableFormElements;

// Instant Preview in the middle of the view
class Preview extends React.Component {
  constructor(props) {
    super(props);

    const { onLoad, onPost } = props;
    store.setExternalHandler(onLoad, onPost);

    this.editForm = React.createRef();
    this.state = {
      data: [],
      answer_data: {}
    };
    this.seq = 0;

    const onUpdate = this._onChange.bind(this);
    store.subscribe(state => onUpdate(state.data));

    this.moveCard = this.moveCard.bind(this);
    this.insertCard = this.insertCard.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      store.dispatch('updateOrder', nextProps.data);
    }
  }

  componentDidMount() {
    const { data, url, saveUrl } = this.props;

    store.dispatch('load', { loadUrl: url, saveUrl, data: data || [] });
    document.addEventListener('mousedown', this.editModeOff);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.editModeOff);
  }

  editModeOff = e => {
    if (this.editForm.current && !this.editForm.current.contains(e.target)) {
      this.manualEditModeOff();
    }
  };

  manualEditModeOff = () => {
    const { editElement } = this.props;
    if (editElement && editElement.dirty) {
      editElement.dirty = false;
      this.updateElement(editElement);
    }
    this.props.manualEditModeOff();
  };

  _setValue = text => {
    return text.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
  };

  updateElement(element) {
    const { data } = this.state;
    let found = false;

    for (let i = 0, len = data.length; i < len; i++) {
      if (element.id === data[i].id) {
        data[i] = element;
        found = true;
        break;
      }
    }

    if (found) {
      this.seq = this.seq > 100000 ? 0 : this.seq + 1;
      store.dispatch('updateOrder', data);
    }
  }

  _onChange(data) {
    const answer_data = {};

    data.forEach(item => {
      if (item && item.readOnly && this.props.variables[item.variableKey]) {
        answer_data[item.field_name] = this.props.variables[item.variableKey];
      }
    });

    this.setState({
      data,
      answer_data
    });
  }

  _onDuplicate = item => {
    store.dispatch('duplicate', item);
  };

  _onDestroy = item => {
    store.dispatch('delete', item);
  };

  insertCard(item, hoverIndex) {
    const { data } = this.state;
    data.splice(hoverIndex, 0, item);
    this.saveData(item, hoverIndex, hoverIndex);
  }

  moveCard(dragIndex, hoverIndex) {
    const { data } = this.state;
    const dragCard = data[dragIndex];
    this.saveData(dragCard, dragIndex, hoverIndex);
  }

  // eslint-disable-next-line no-unused-vars
  cardPlaceHolder = (dragIndex, hoverIndex) => {
    // Dummy
  };

  saveData(dragCard, dragIndex, hoverIndex) {
    const newData = update(this.state, {
      data: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      }
    });
    this.setState(newData);
    store.dispatch('updateOrder', newData.data);
  }

  getElement(item, index) {
    const SortableFormElement = SortableFormElements[item.element];
    const editElementId = get(this, 'props.editElement.id', null);
    const { originData } = this.props;

    const rows = get(originData, 'dataDetail.user_form_fields', []) || [];

    const rowsLength = rows.reduce((acc, dt) => {
      if (dt.field_type === 'table') {
        const detailsField = get(dt, 'details.fields', []) || [];
        detailsField.map(dts => {
          acc += 1;
        });
        acc += 1;
      }
      acc += 1;

      return acc;
    }, 0);

    const isFilledCount =
      get(
        head(get(originData, 'dataDetail.user_form_fields')),
        'field_filled_value_count'
      ) > 0;

    return (
      <div className="my-1 mx-2">
        <SortableFormElement
          id={item.id}
          seq={this.seq}
          index={index}
          moveCard={this.moveCard}
          insertCard={this.insertCard}
          mutable={false}
          parent={this.props.parent}
          editModeOn={this.props.editModeOn}
          editElementId={editElementId}
          isDraggable
          canDrag={!item.disabled}
          key={item.id}
          sortData={item.id}
          data={item}
          rowsLength={rowsLength}
          isFilledCount={isFilledCount}
          _onDuplicate={this._onDuplicate}
          _onDestroy={this._onDestroy}
        />
      </div>
    );
  }

  render() {
    let classes = this.props.className;
    if (this.props.editMode) {
      classes += ' is-editing';
    }
    const data = this.state.data.filter(x => !!x);

    const items = data.map((item, index) => this.getElement(item, index));
    return (
      <div className={classes}>
        <div className="edit-form" ref={this.editForm}>
          {this.props.editElement !== null && (
            <FormElementsEdit
              showCorrectColumn={this.props.showCorrectColumn}
              files={this.props.files}
              manualEditModeOff={this.manualEditModeOff}
              preview={this}
              element={this.props.editElement}
              updateElement={this.updateElement}
            />
          )}
        </div>
        <div className="Sortable">{items}</div>
        <PlaceHolder
          id="form-place-holder"
          // show={items.length === 0}
          show
          index={items.length}
          moveCard={this.cardPlaceHolder}
          insertCard={this.insertCard}
        />
      </div>
    );
  }
}
Preview.defaultProps = {
  showCorrectColumn: false,
  files: [],
  editMode: false,
  editElement: null,
  className: 'react-form-builder-preview float-left'
};

const mapStateToProps = state => ({
  originData: state.formbuilder,
  current: state.user.current
});

export default connect(mapStateToProps, {})(Preview);
