export const FETCH_LIST_GROUP_REQUEST = 'FETCH_LIST_GROUP_REQUEST';
export const FETCH_LIST_GROUP_SUCCESS = 'FETCH_LIST_GROUP_SUCCESS';
export const MERGE_LIST_GROUP = 'MERGE_LIST_GROUP';
export const FETCH_LIST_GROUP_ERROR = 'FETCH_LIST_GROUP_ERROR';

export const FETCH_ALL_GROUP_REQUEST = 'FETCH_ALL_GROUP_REQUEST';
export const FETCH_ALL_GROUP_SUCCESS = 'FETCH_ALL_GROUP_SUCCESS';
export const FETCH_ALL_GROUP_ERROR = 'FETCH_ALL_GROUP_ERROR';

export const FETCH_ALL_GROUP_NO_PAGINATION_REQUEST =
  'FETCH_ALL_GROUP_NO_PAGINATION_REQUEST';
export const FETCH_ALL_GROUP_NO_PAGINATION_SUCCESS =
  'FETCH_ALL_GROUP_NO_PAGINATION_SUCCESS';
export const FETCH_ALL_GROUP_NO_PAGINATION_ERROR =
  'FETCH_ALL_GROUP_NO_PAGINATION_ERROR';

export const FETCH_GROUP_BY_ID_REQUEST = 'FETCH_GROUP_BY_ID_REQUEST';
export const FETCH_GROUP_BY_ID_SUCCES = 'FETCH_GROUP_BY_ID_SUCCES';
export const FETCH_GROUP_BY_ID_ERROR = 'FETCH_GROUP_BY_ID_ERROR';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';

export const FETCH_MEMBER_REQUEST = 'FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const MERGE_LIST_MEMBER = 'MERGE_LIST_MEMBER';
export const FETCH_MEMBER_ERROR = 'FETCH_MEMBER_ERROR';

export const ADD_MEMBER_TO_GROUP_REQUEST = 'ADD_MEMBER_TO_GROUP_REQUEST';
export const ADD_MEMBER_TO_GROUP_SUCCESS = 'ADD_MEMBER_TO_GROUP_SUCCESS';
export const ADD_MEMBER_TO_GROUP_ERROR = 'ADD_MEMBER_TO_GROUP_ERROR';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const DELETE_MEMBER_FROM_GROUP_REQUEST =
  'DELETE_MEMBER_FROM_GROUP_REQUEST';
export const DELETE_MEMBER_FROM_GROUP_SUCCESS =
  'DELETE_MEMBER_FROM_GROUP_SUCCESS';
export const DELETE_MEMBER_FROM_GROUP_ERROR = 'DELETE_MEMBER_FROM_GROUP_ERROR';

export const SET_FORM_GROUP = 'SET_FORM_GROUP';
