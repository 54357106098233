import * as React from 'react';
import { Permission, To } from '../../Types';
import { IsAllowedToProps } from './PermissionProvider';
export type IsAllowedToDefinition = (to: To | To[], opt?: IsAllowedToProps) => boolean
interface PermissionContextType {
  isAllowedTo: IsAllowedToDefinition
  getPermissions: () => Permission
}

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour: PermissionContextType = {
	isAllowedTo: () => false,
	getPermissions: () => ({} as Permission)
};

// Create the context
const PermissionContext = React.createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
