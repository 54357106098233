/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {renderSelectInput} from '../Field';
import {selectGroup, selectGroupAuth} from '../../store/actions/select.action';

class SelectGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectGroupAuth, selectGroup, current } = this.props;
    if (current.role.slug === 'manager') {
      selectGroupAuth({ withCompanyGroup: false });
    } else {
      selectGroup({ withCompanyGroup: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { data, group } = this.props;
    if (data !== nextProps.data) {
      this.setState({ opt: [] });
      nextProps.data.map(data => {
        this.setState(state => {
          const opt = [...state.opt, { value: data.id, label: data.name }];
          return {
            opt
          };
        });
      });
    }
    if (group !== nextProps.group) {
      this.setState({ opt: [] });
      nextProps.group.map(data => {
        this.setState(state => {
          const opt = [...state.opt, { value: data.id, label: data.name }];
          return {
            opt
          };
        });
      });
    }
  }

  render() {
    const { opt } = this.state;
    const { validate, disabled, label, name, placeholder } = this.props;
    return (
      <Field
        name={name}
        component={renderSelectInput}
        label={label}
        options={opt}
        disabled={disabled}
        id="inputUserRole"
        placeholder={placeholder}
        validate={validate}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.dataGroup,
  group: state.select.group
});

export default connect(
  mapStateToProps,
  { selectGroupAuth, selectGroup }
)(SelectGroup);
