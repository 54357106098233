import styled from 'styled-components';
import { BorderColor, SidelyBlack } from '../../../styles/global/css/Utils';
import { DashboardContext, DashboardItem, Page } from '../Dashboard';
import { Target } from '../../targets/Targets';
import * as React from 'react';
import { translateToString } from '../../../styles/global/translate';
import { PopupMode, PopupStyle } from '../../../components_v2/popup/model/Model';
import { getTargets } from '../../targets/actions';
import Popup from '../../../components_v2/popup/Popup';
import { ClientCompany, Owner } from '../../orders/model/Model';
import { DropdownData, TagOperator } from '../../../components_v2/dropdown/model/Model';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { getBrands } from '../../../components_v2/toolbarFilter/brands/action';
import { Brand } from '../../globals/Model';
import { CalendarMapping, colorStatusMapping, colorTypeMapping } from '../../calendar/model/Model';
import { Granularity } from '../../../../../typelib/tabula/typings/bindings/time/Granularity';
import { getClientCompanyById } from '../actions';
import { getClientStatuses } from '../../import/actions';
import { CompanyStatus } from '../../client-companies/model/Model';
import ParentDnCreator from './creators/ParentDnCreator';
import EventCountCreator from './creators/EventCountCreator';
import EventFrequencyCreator from './creators/EventFrequencyCreator';
import StatusAndCompanyCreator from './creators/StatusAndCompanyCreator';
import ClientCompanyStatusCountCreator from './creators/ClientCompanyStatusCountCreator';
import TopCompanyByCheckoutCreator from './creators/TopCompanyByCheckoutCreator';
import DefaultPage from './DefaultPage';
import { getTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import { ITag } from 'proto/protobufs';
import { FilterItem } from '../../../components_v2/filter/model/Model';


export const Grid = styled.div`
  display: grid;
  grid-template-columns: calc(33% - 0.66em) calc(33% - 0.66em) calc(33% - 0.66em);
  column-gap: 1em;
  row-gap: 1em;
  gap: 1em;
  width: 100%;
  flex-wrap: wrap;
`;

export const DashboardPopupTitle = styled.h3`
  z-index:11;
  padding-bottom: 1em;
  position: sticky;
  top: 0;
  background-color: white;
  margin: 0;
  font-size: 16px;
`;

export const CreationPopupTitle = styled.h3`
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index:202;
  width: 100%;
`;

export const BottomBar = styled.div`
  border-top: 1px solid ${BorderColor};
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  transition: 0.5s;
  overflow: hidden;
  margin-top: 1em;
`;

export const PopupContainer = styled.div<{ isCreator?: boolean }>`
  height: calc(100% - 60px);
  overflow: auto;
  width: 100%;
  ${({ isCreator }) => isCreator ? `
		display: flex;
		flex-direction: column;
		align-items:center;
		gap: 2em;
	` : ''}
`;


export const DASHBOARD_CREATION_DROPDOWN_STYLE = {
	labelUp: true,
	height: '40px',
	optionWidth: '400px',
	optionHeight: '150px',
	width: '400px',
};

export const DASHBOARD_INPUT_STYLE = {
	...DASHBOARD_CREATION_DROPDOWN_STYLE,
	margin: '0 0 10px 0',
	borderRadius: '4px',
};

export const DASHBOARD_CREATION_DROPDOWN_TAGS_STYLE = {
	...DASHBOARD_CREATION_DROPDOWN_STYLE,
	height: '50px',
};




export const All_EXPIRATION = ['1 day', '1 week', '1 month', '3 month', '6 month', '1 year'] as const;
export type Expiration = typeof All_EXPIRATION[number];

export interface DashboardPopupContextState {
	title: string | undefined;
	setTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
	targets: Target[] | undefined;
	setTargets: React.Dispatch<React.SetStateAction<Target[] | undefined>>;
	changedItems: DashboardItem[];
	setChangedItems: React.Dispatch<React.SetStateAction<DashboardItem[]>>;
	company: DropdownData<ClientCompany> | null | undefined;
	setCompany: React.Dispatch<React.SetStateAction<DropdownData<ClientCompany> | null | undefined>>;
	user: DropdownData<Owner | null> | undefined;
	setUser: React.Dispatch<React.SetStateAction<DropdownData<Owner | null> | undefined>>;
	brands: Brand[];
	brand: DropdownData<Brand> | undefined;
	setBrand: React.Dispatch<React.SetStateAction<DropdownData<Brand> | undefined>>;
	companyStatuses: CompanyStatus[];
	setCompanyStatuses: React.Dispatch<React.SetStateAction<CompanyStatus[]>>;
	companyStatus: DropdownData<CompanyStatus> | undefined;
	setCompanyStatus: React.Dispatch<React.SetStateAction<DropdownData<CompanyStatus> | undefined>>;
	expiration: Expiration | undefined;
	setExpiration: React.Dispatch<React.SetStateAction<Expiration | undefined>>;
	granularityForDn: Expiration | undefined;
	setGranularityForDn: React.Dispatch<React.SetStateAction<Expiration | undefined>>;
	eventType: DropdownData<CalendarMapping> | undefined;
	setEventType: React.Dispatch<React.SetStateAction<DropdownData<CalendarMapping> | undefined>>;
	status: DropdownData<CalendarMapping> | undefined;
	setStatus: React.Dispatch<React.SetStateAction<DropdownData<CalendarMapping> | undefined>>;
	granularity: Granularity | undefined;
	setGranularity: React.Dispatch<React.SetStateAction<Granularity | undefined>>;

	page: Page, //To send with context,
	setPage: React.Dispatch<React.SetStateAction<Page>>,
	items: DashboardItem[],
	tags: ITag[]
	selectedTags: FilterItem[],
	onChangeTags: (tags: ITag[], operator: TagOperator[]) => void;
	onDeleteTags: (id: number) => void;
}

export interface CreatorProps {
	onSave: () => void;
	handleBack?: () => void;
	activeTab: number;
	close?: () => void;
}

export const DashboardPopupContext = React.createContext<DashboardPopupContextState | undefined>(undefined);


export default function DashboardPopup(props: {
	isOpen: boolean,
	setOpen: (b: boolean) => void,
	items: DashboardItem[],
	setItems: React.Dispatch<React.SetStateAction<DashboardItem[]>>,
	page: Page,
	setPage: React.Dispatch<React.SetStateAction<Page>>,
	editItem: (item: DashboardItem) => void,
	activeTab: number,
}) {
	const { items, page, setPage, editItem } = props;
	const [title, setTitle] = React.useState<string>();
	const [targets, setTargets] = React.useState<Target[]>();
	const [changedItems, setChangedItems] = React.useState<DashboardItem[]>([]);
	const [company, setCompany] = React.useState<DropdownData<ClientCompany> | null>();
	const [user, setUser] = React.useState<DropdownData<Owner | null>>();
	const users = useRecoilValue(AUsers);
	const [brands, setBrands] = React.useState<Brand[]>([]);
	const [brand, setBrand] = React.useState<DropdownData<Brand>>();
	const [companyStatuses, setCompanyStatuses] = React.useState<CompanyStatus[]>([]);
	const [companyStatus, setCompanyStatus] = React.useState<DropdownData<CompanyStatus>>();
	const [expiration, setExpiration] = React.useState<Expiration>();
	const [granularityForDn, setGranularityForDn] = React.useState<Expiration>();
	const [tags, setTags] = React.useState<ITag[]>([]);


	// state for EventCount / eventFrequency
	const [eventType, setEventType] = React.useState<DropdownData<CalendarMapping>>();
	const [status, setStatus] = React.useState<DropdownData<CalendarMapping>>();
	const [granularity, setGranularity] = React.useState<Granularity>();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { currentItem, setCurrentItem } = React.useContext(DashboardContext)!;

	const [selectedTags, setSelectedTags] = React.useState<FilterItem[]>([]);

	const contextValue: DashboardPopupContextState = {
		title,
		setTitle,
		targets,
		setTargets,
		changedItems,
		setChangedItems,
		company,
		setCompany,
		user,
		setUser,
		brands,
		brand,
		setBrand,
		companyStatuses,
		setCompanyStatuses,
		companyStatus,
		setCompanyStatus,
		expiration,
		setExpiration,
		granularityForDn,
		setGranularityForDn,
		eventType,
		setEventType,
		status,
		setStatus,
		granularity,
		setGranularity,
		page,
		setPage,
		items,
		tags,
		selectedTags,
		onChangeTags,
		onDeleteTags,
	};


	const popupStyle: PopupStyle = {
		noTransition: true,
		minWidth: '500px',
		width: page === Page.Grid ? '75vw' : '25vw',
		padding: '1em',
		height: getAjustedHeight(),
	};

	function getAjustedHeight() {
		switch (page) {
			case Page.StatusCreator:
				return '40vh';
			case Page.CompanyCreator:
				return '40vh';
			case Page.EventFrequencyCreator:
				return '50vh';
			case Page.EventFrequencyByUserCreator:
				return '50vh';
			case Page.TopCompanyByCheckoutCreator:
				return '60vh';
			case Page.ParentDnCreator:
				return '70vh';
			case Page.EventCountCreator:
				return '70vh';
			case Page.ClientCompanyStatusCountCreator:
				return '70vh';
			default:
				return '90vh';
		}
	}

	React.useEffect(() => {
		getBrands().then(setBrands);
		getClientStatuses().then(response => {
			setCompanyStatuses(response.data);
		});
		getTags(TagType.COMPANY).then(setTags);
	}, []);


	React.useEffect(() => {
		if (currentItem) {
			if (currentItem.title) setTitle(currentItem.title);
			if (currentItem.item_id) {
				getClientCompanyById(currentItem.item_id).then(res => {
					setCompany({ value: { ...res } as ClientCompany, label: res.name });
				});
			}
			if (currentItem.additional_values) {
				const userId = currentItem.additional_values['user'];
				const eventTypeId = currentItem.additional_values['event_type'];
				const eventStatusId = currentItem.additional_values['event_status'];
				const brandId = currentItem.additional_values['brand'];
				const companyStatusId = currentItem.additional_values['company_status'];
				const _granularity = currentItem.additional_values['granularity'];
				const _expiration = currentItem.additional_values['expiration'];
				const _tags: any = currentItem.additional_values['tags'];


				if (userId) setUser({ value: users.find(u => u.id === userId) ?? null, label: '' });
				if (eventTypeId) {
					const value = colorTypeMapping.find(t => t.id === eventTypeId);
					if (value) setEventType({ value, label: translateToString(`event.${value.label}`) });
				}
				if (eventStatusId) {
					const value = colorStatusMapping.find(s => s.id === eventStatusId);
					if (value) setStatus({ value, label: translateToString(value.label) });
				}
				// FIXME: when use same granularity enum for 2 page, we can use simply setGranularity(_granularity);
				if (_granularity) {
					if (page === Page.ParentDnCreator) setGranularityForDn(_granularity);
					else setGranularity(_granularity);
				}
				if (_expiration) setExpiration(_expiration);
				if (brandId) {
					const value = brands.find(b => b.id === brandId);
					if (value) setBrand({ value, label: value.name });
				}
				if (companyStatusId) {
					const value = companyStatuses.find(cs => cs.id === companyStatusId);
					if (value) setCompanyStatus({ value, label: value.name });
				}
				// FIXME: Next line reformat tags data sent from backend to FilterItem[] to adapt <DropdownTagsCloud/>
				if (_tags?.length > 0 && tags?.length > 0) {
					const value = _tags.reduce((acc: FilterItem[], tagFromDashboardItem: {id: number, operator: string}) => {
						const tag = tags?.find(t => t.id === tagFromDashboardItem.id);
						if (tag) {
							return [...acc, { label: tag.name!, value: { tag, operator: tagFromDashboardItem.operator }, id: tag.id! }];
						}
					}, []);
					if (value) setSelectedTags([...value]);
				}
			}
		} else
			clear();
	}, [page]);

	function clear() {
		setCompany(undefined);
		setUser(undefined);
		setTitle(undefined);
		setBrand(undefined);
		setEventType(undefined);
		setStatus(undefined);
		setGranularity(undefined);
		setGranularityForDn(undefined);
		setExpiration(undefined);
		setCompanyStatus(undefined);
		setSelectedTags([]);
		setCurrentItem(undefined);
	}

	function handleBack() {
		if (currentItem)
			props.setOpen(false);
		setPage(Page.Grid);
		clear();
	}

	function close() {
		props.setOpen(false);
		setPage(Page.Grid);
		clear();
	}

	function onSave() {
		if (currentItem) {
			editItem(changedItems[0]);
		} else {
			props.setItems(items => {
				changedItems.forEach(changedItem => {
					const index = items.findIndex(item => item.id === changedItem.id);
					if (index < 0) {
						items.push(changedItem);
					} else {
						items.splice(index, 1);
					}
				});
				return [...items];
			});
		}
		close();
	}

	function onChangeTags(tags: ITag[], operators: TagOperator[]) {
		setSelectedTags(tags.map((t, i) => ({ label: t.name!, value: { tag: t, operator: operators[i + 1] ?? 'and' }, id: t.id! })));
	}

	function onDeleteTags(tagId: number) {
		const index = selectedTags.findIndex(t => t.id == tagId);
		if (index >= 0) {
			selectedTags.splice(index, 1);
			setSelectedTags([...selectedTags]);
		}
	}


	React.useEffect(() => {
		setChangedItems([]);
		if (!props.isOpen || targets !== undefined) return;
		getTargets({}).then(setTargets);
	}, [props.isOpen]);


	return <DashboardPopupContext.Provider value={contextValue}><Popup isOpen={props.isOpen} onClickOut={close} popupMode={PopupMode.Centered} popupStyle={popupStyle}>
		{page === Page.Grid && <DefaultPage activeTab={props.activeTab} onSave={onSave} close={close}/>}
		{page === Page.ParentDnCreator && <ParentDnCreator activeTab={props.activeTab} onSave={onSave} handleBack={handleBack}/>}
		{page === Page.EventCountCreator && <EventCountCreator onSave={onSave} handleBack={handleBack} activeTab={props.activeTab}/>}
		{(page === Page.EventFrequencyCreator || page === Page.EventFrequencyByUserCreator) && <EventFrequencyCreator onSave={onSave} handleBack={handleBack} activeTab={props.activeTab} />}
		{(page === Page.StatusCreator || page === Page.CompanyCreator) && <StatusAndCompanyCreator onSave={onSave} handleBack={handleBack} activeTab={props.activeTab}/>}
		{page === Page.ClientCompanyStatusCountCreator && <ClientCompanyStatusCountCreator onSave={onSave} handleBack={handleBack} activeTab={props.activeTab} />}
		{page === Page.TopCompanyByCheckoutCreator && <TopCompanyByCheckoutCreator onSave={onSave} handleBack={handleBack} activeTab={props.activeTab}/>}
	</Popup>
	</DashboardPopupContext.Provider>;
}


