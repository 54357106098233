/* eslint-disable no-underscore-dangle */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { change, Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import moment from 'moment';
import { renderField, required, textAreaField } from '../../../../../components/FieldModal';
import { createEvent, editEvent, getEventDetail } from '../../../../../store/actions/contacts.action';
import SwitchField from '../../../../../components/SwitchButton';
import TimeField from '../../../../../components/TimeField';
import DateField from '../../../../../components/DateField';
import SelectInCharge from '../../../../../components/select/SelectInCharge';
import SelectEventType from '../../../../../components/select/SelectEventType';
import { normalizeCapitalize } from '../../../../../helpers/fieldMask';
import { cekEventTimes, CompleteTime } from '../../../../../helpers/time';
import SelectEventStatus from '../../../../../components/select/SelectEventStatus';
import Restricted from '../../../../../containers_v2/permissions/Restricted';

class ModalEventForm extends Component {
	state = {
		title: null,
		lengthTime: {
			hours: 1,
			minutes: 0,
			date: 0
		}
	};

	componentDidMount() {
		// const { dispatch } = this.props;
		// dispatch(change('modalEventForm', 'switchAll', true));
		this.getToday('next');
	}

	componentWillReceiveProps(nextProps) {
		const {
			current,
			detail,
			dispatch,
			getEventDetail,
			id,
			mode,
			switchAll,
			startTime,
			endTime,
			startDate,
			endDate
		} = this.props;
		const { lengthTime } = this.state;

		if (mode !== 'View') {
			if (nextProps.endTime !== undefined && endTime !== undefined) {
				if (endTime !== nextProps.endTime) {
					const end = moment(nextProps.endTime);
					const start = moment(startTime);
					const duration = moment.duration(end.diff(start));
					if (duration._data.hours >= 0 && duration._data.minutes >= 0) {
						this.setLengthTime(duration._data.hours, duration._data.minutes, 0);
						dispatch(
							change(
								'modalEventForm',
								'endDate',
								moment(nextProps.endDate, 'DD/MM/YYYY')
							)
						);
					} else {
						const time = moment(startTime).format('HH:mm');
						dispatch(
							change('modalEventForm', 'endTime', moment(time, 'hh:mm'))
						);
					}
				}
			} else {
				this.getToday('next');
			}

			if (nextProps.startTime !== undefined && startTime !== undefined) {
				if (startTime !== nextProps.startTime) {
					const hour = moment(nextProps.startTime).add(
						lengthTime.hours,
						'hours'
					);
					const endHour = moment(hour).add(lengthTime.minutes, 'minutes');
					dispatch(
						change('modalEventForm', 'endTime', moment(endHour, 'hh:mm'))
					);
					if (hour.hour() < moment(nextProps.startTime).hour()) {
						dispatch(
							change(
								'modalEventForm',
								'endDate',
								moment(endDate, 'DD/MM/YYYY').add(1, 'days')
							)
						);
					}
				}
			}

			if (!switchAll && nextProps.endDate && endDate !== nextProps.endDate) {
				if (nextProps.endDate < startDate) {
					dispatch(
						change('modalEventForm', 'endDate', moment(startDate, 'DD/MM/YYYY'))
					);
				}
			}

			if (startDate !== nextProps.startDate) {
				const start = moment(nextProps.startDate).format('YYYY-MM-DD');
				dispatch(change('modalEventForm', 'endDate', moment(start)));
			}

			if (nextProps.switchAll !== switchAll) {
				if (nextProps.switchAll) {
					dispatch(
						change('modalEventForm', 'startTime', moment('00:00', 'hh:mm'))
					);
					dispatch(
						change('modalEventForm', 'endTime', moment('23:59', 'hh:mm'))
					);
				} else {
					if (nextProps.id == null) {
						const hour = now.hour();
						dispatch(
							change(
								'modalEventForm',
								'startTime',
								moment(`${hour + 1}:00`, 'hh:mm')
							)
						);
						dispatch(
							change(
								'modalEventForm',
								'endTime',
								moment(`${hour + 2}:00`, 'hh:mm')
							)
						);
					}
				}
			}
		}

		const initInput = [
			'incharge',
			'eventTypeId',
			'eventStatusId',
			'title',
			'description'
		];

		if (nextProps.switchAll !== switchAll) {
			if (nextProps.switchAll) {
				dispatch(
					change('modalEventForm', 'startTime', moment('00:00', 'hh:mm'))
				);
				dispatch(change('modalEventForm', 'endTime', moment('23:59', 'hh:mm')));
			} else {
				const now = moment();
				const hour = now.hour();

				dispatch(
					change(
						'modalEventForm',
						'startTime',
						moment(`${hour + 1}:00`, 'hh:mm')
					)
				);
				dispatch(
					change('modalEventForm', 'endTime', moment(`${hour + 2}:00`, 'hh:mm'))
				);
			}
		}

		if (mode === 'New') {
			if (current !== nextProps.current) {
				dispatch(change('modalEventForm', 'incharge', nextProps.current.id));
			}
			dispatch(change('modalEventForm', 'eventStatusId', 1));
		}

		if (id.eventId !== nextProps.id.eventId) {
			if (nextProps.id.eventId) {
				getEventDetail(nextProps.id.eventId);
			} else {
				dispatch(reset('modalEventForm'));
			}
		}

		// get current data
		if (detail !== nextProps.detail) {
			const detailData = nextProps.detail;
			if (detailData.startDate !== null && detailData.endDate !== null) {
				if (
					cekEventTimes(detailData.startDate, detailData.endDate) === 'All day'
				) {
					dispatch(change('modalEventForm', 'switchAll', true));
				} else {
					dispatch(change('modalEventForm', 'switchAll', false));
				}
			}
			this.setState({
				contactId: nextProps.detail.contactId
			});
			initInput.map(data => {
				dispatch(change('modalEventForm', data, nextProps.detail[data]));
				return null;
			});

			if (mode === 'View') {
				dispatch(
					change('modalEventForm', 'incharge', nextProps.detail.incharge)
				);
			}

			if (nextProps.detail.startDate) {
				const start = moment(nextProps.detail.startDate).format('YYYY-MM-DD');
				const time = moment(nextProps.detail.startDate).format('HH:mm');
				dispatch(change('modalEventForm', 'startDate', moment(start)));
				dispatch(change('modalEventForm', 'startTime', moment(time, 'HH:mm')));
			}
			if (nextProps.detail.endDate) {
				const end = moment(nextProps.detail.endDate).format('YYYY-MM-DD');
				const time = moment(nextProps.detail.endDate).format('HH:mm');
				dispatch(change('modalEventForm', 'endDate', moment(end)));
				dispatch(change('modalEventForm', 'endTime', moment(time, 'HH:mm')));
			} else {
				dispatch(change('modalEventForm', 'endDate', ''));
				dispatch(change('modalEventForm', 'endTime', ''));
			}
		}
	}

	onHover = (pen, trash) => {
		const { onHover } = this.props;
		onHover(pen, trash);
	};

	onDelete = (id, clientCompanyId) => {
		const { onDelete } = this.props;
		onDelete(id, clientCompanyId);
	};

	getLabel = label => (
		<div className="form-group text-right mt-2">
			<label htmlFor="#">
				<span className="text-danger">* </span>
				{label}
			</label>
		</div>
	);

	setLengthTime = (durationHour, durationTime, date) => {
		this.setState({
			lengthTime: {
				hours: durationHour,
				minutes: durationTime,
				date
			}
		});
	};

	getToday = ket => {
		const { dispatch } = this.props;
		const date = new Date();
		dispatch(change('modalEventForm', 'startDate', moment(date)));
		dispatch(change('modalEventForm', 'endDate', moment(date)));

		if (ket === 'next') {
			const now = moment();
			const hour = now.hour();
			dispatch(
				change('modalEventForm', 'startTime', moment(`${hour + 1}:00`, 'hh:mm'))
			);
			dispatch(
				change('modalEventForm', 'endTime', moment(`${hour + 2}:00`, 'hh:mm'))
			);
		} else {
			dispatch(change('modalEventForm', 'startTime', moment('00:00', 'hh:mm')));
			dispatch(change('modalEventForm', 'endTime', moment('23:59', 'hh:mm')));
		}
	};

	getLabel = label => (
		<div className="form-group text-right mt-2">
			<label htmlFor="#">
				<span className="text-danger">* </span>
				{label}
			</label>
		</div>
	);

	setEndDate = startDate => {
		const { dispatch } = this.props;
		dispatch(change('modalEventForm', 'endDate', startDate));
	};

	getDate = (date, time) => {
		const data = moment(
			`${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`
		);
		return data;
	};

	onSubmit = value => {
		const { createEvent, editEvent, id, switchAll } = this.props;
		const formData = new FormData();
		const start = this.getDate(
			value.startDate,
			switchAll === true ? moment('00:00', 'HH:mm') : value.startTime
		);
		const end = this.getDate(
			value.endDate,
			switchAll === true ? moment('23:59', 'HH:mm') : value.endTime
		);
		const startDate = moment(
			`${moment(start._d)
				.utc()
				.format('YYYY-MM-DD HH:mm')}`
		);
		const endDate = moment(
			`${moment(end._d)
				.utc()
				.format('YYYY-MM-DD HH:mm')}`
		);
		const keys = [
			'incharge',
			'eventTypeId',
			'eventStatusId',
			'title',
			'description'
		];

		keys.map(key => {
			value[key] && formData.append(key, value[key].value || value[key]);
			return null;
		});

		formData.append('startDate', startDate._i);
		if (value.endDate !== undefined && value.endDate !== null) {
			formData.append('endDate', endDate._i);
		} else {
			formData.append('endDate', '');
		}

		if (id.clientCompanyId) {
			formData.append('clientCompanyId', id.clientCompanyId);
		}

		formData.append('contactId', id.contactId);

		if (id.eventId) {
			editEvent(formData, id.eventId, id.contactId);
		} else {
			createEvent(formData, id.contactId);
		}
	};

	render() {
		const {
			handleSubmit,
			id,
			isLoading,
			contactId,
			penUrl,
			trashUrl,
			onChangeMode,
			onDelete,
			onHover,
			detail,
			mode,
			switchAll
		} = this.props;
		const titleModal = (
			<span>
				<Translate id={mode} />
				<Translate id="event" />
			</span>
		);

		return (
			<Modal
				isOpen={this.props.isOpen}
				toggle={this.props.toggle}
				className={this.props.className}
				centered
			>
				<ModalHeader toggle={this.props.toggle} className="text-capitalize">
					{mode === 'View' ? detail?.title : titleModal}
					<div className="positionButton d-flex flex-row">
						{mode === 'View' && (
							<Restricted to={{ objectAction: 'UpdateEvent' }} ownerId={detail?.incharge}>
								<button
									className="btn-edit-modal"
									onClick={() => onChangeMode('Edit')}
								>
									<img
										src={penUrl}
										alt=""
										width="13px"
										height="13px"
										className="mr-2"
										onMouseEnter={() => onHover('pen_blue.svg')}
										onMouseLeave={() => onHover('pen_black.svg')}
									/>
								</button>
							</Restricted>
						)}

						{mode !== 'New' && (
							<Restricted to={{ objectAction: 'DeleteEvent' }} ownerId={detail?.incharge}>
								<button
									className="btn-edit-modal"
									onClick={() => onDelete(detail.id, contactId)}
								>
									<img
										src={trashUrl}
										alt=""
										width="13px"
										height="13px"
										onMouseEnter={() => onHover(null, 'trash_blue.svg')}
										onMouseLeave={() => onHover(null, 'trash_black.svg')}
									/>
								</button>
							</Restricted>
						)}
					</div>
				</ModalHeader>
				<ModalBody>
					<form
						className="forms-sample background-form"
						onSubmit={handleSubmit(this.onSubmit)}
						style={{ boxShadow: 'none' }}
					>
						<Translate>
							{({ translate }) => (
								<Fragment>
									<div className="row">
										<div className="col-md-11">
											<div className="row">
												<div className="col-md-12">
													<SelectInCharge
														name="incharge"
														label={<Translate id="in charge" />}
														placeholder="Pick In Charge"
														requiredStar
														readOnly={mode === 'View'}
														validate={[required]}
														id={id}
													/>
													<SelectEventType
														name="eventTypeId"
														label="Type"
														placeholder={translate('pick_type')}
														requiredStar
														readOnly={mode === 'View'}
														validate={[required]}
														id={id}
													/>
													<Field
														name="title"
														type="text"
														component={renderField}
														label={<Translate id="title" />}
														placeholder="Enter Title"
														requiredStar
														readOnly={mode === 'View'}
														validate={[required]}
														normalize={normalizeCapitalize}
													/>
													<Field
														name="switchAll"
														component={SwitchField}
														id="switchAll"
														onChange={() => {
															if (mode !== 'View') {
																this.getToday();
															}
														}}
														label="All day"
														readOnly={mode === 'View'}
														disabled={mode === 'View'}
													/>
													<div className="d-flex">
														<div className="col-3">
															{this.getLabel('Start')}
														</div>
														<div className="col-5 px-0">
															<Field
																name="startDate"
																component={DateField}
																noLabel
																w100
																readOnly={mode === 'View'}
																requiredStar={mode !== 'View'}
																viewIcon
															/>
														</div>
														<div className="col-4 pl-0">
															{switchAll !== true && (
																<Field
																	name="startTime"
																	component={TimeField}
																	label="Start"
																	w100
																	readOnly={mode === 'View'}
																	requiredStar={mode !== 'View'}
																/>
															)}
														</div>
													</div>
													<div className="d-flex">
														<div className="col-3">{this.getLabel('End')}</div>
														<div className="col-5 px-0">
															<Field
																name="endDate"
																component={DateField}
																noLabel
																w100
																disabled={switchAll}
																readOnly={mode === 'view'}
																requiredStar={mode !== 'view'}
																viewIcon
															/>
														</div>
														<div className="col-4 pl-0">
															{switchAll !== true && (
																<Field
																	name="endTime"
																	component={TimeField}
																	noLabel
																	w100
																	readOnly={mode === 'view'}
																	requiredStar={mode !== 'view'}
																/>
															)}
														</div>
													</div>
													<SelectEventStatus
														name="eventStatusId"
														label={translate('status')}
														placeholder={translate('pick_status')}
														requiredStar
														readOnly={mode === 'View'}
														validate={[required]}
														id={id}
													/>
													<Field
														name="description"
														type="text"
														component={textAreaField}
														label="Description"
														placeholder="Enter Description"
														// requiredStar
														readOnly={mode === 'View'}
														// validate={[required]}
													/>
												</div>
											</div>
										</div>
										<div className="col-md-4" />
									</div>
								</Fragment>
							)}
						</Translate>
					</form>
				</ModalBody>
				<ModalFooter className={mode === 'View' && 'justify-content-start'}>
					{mode === 'View' ? (
						<div>
							<p className="text-by word-break mb-0">
								{'by '}
								<span className="text-capitalize">
									{detail.inchargeName && detail.inchargeName}
								</span>
								{` on ${CompleteTime(detail.createdAt)}`}
							</p>
						</div>
					) : (
						<Button
							color="primary"
							onClick={handleSubmit(this.onSubmit)}
							loading={isLoading}
							spinColor="#003d79"
						>
							{!isLoading && <Translate id="save" />}
						</Button>
					)}
				</ModalFooter>
			</Modal>
		);
	}
}

const selector = formValueSelector('modalEventForm');

const mapStateToProps = state => ({
	isLoading: state.contacts.isLoading,
	detail: state.contacts.eventDetail,
	current: state.user.current,
	date: state.events.date,
	switchAll: selector(state, 'switchAll'),
	startDate: selector(state, 'startDate'),
	endDate: selector(state, 'endDate'),
	endTime: selector(state, 'endTime'),
	startTime: selector(state, 'startTime')
});

export default reduxForm({
	form: 'modalEventForm'
})(
	connect(mapStateToProps, { createEvent, editEvent, getEventDetail })(
		ModalEventForm
	)
);
