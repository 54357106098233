import React, { useState, ChangeEvent } from 'react';
import assert from 'assert';
import Input from '../components_v2/input/Input';
import { Field } from '../atoms/forms';
import { Checkbox } from '../components_v2/filterList/style/Style';
import DropdownOwners from '../components_v2/dropdown/DropdownOwners';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../atoms/global/users';
import { FieldType } from 'bindings/forms/FieldType';
import styled from 'styled-components';
import { DarkGreySidely2 } from '../styles/global/css/Utils';

interface FieldEditorProps {
    value: unknown | undefined;
    onChange?: (value: unknown | undefined) => void;
    onSave?: (value: unknown) => void;
    product?: ArrayBuffer;
    company?: ArrayBuffer;
    border?: boolean;
    field: Field | undefined;
}

export function stringifyValue(field: Field, value: unknown | undefined): string | undefined {
	if (value === undefined) {
		return '';
	}
	switch (field.type) {
		case 'Text':
			return value as string;
		case 'Number':
			return (value as number).toString();
		case 'Money':
			return (value as number / 100).toFixed(2);
		case 'Date':
			return value as string;
		case 'User':
			return value as string;
			// if (usersSync === undefined) {
			// 	console.warn('Stringifying user field without users loaded');
			// 	return value as string;
			// } else {
			// 	return usersSync.get(Number(value))?.name ?? (value as any).toString();
			// }
		case 'Url':
			return value as string;
		case 'Email':
			return value as string;
		case 'Phone':
			return value as string;
		case 'Select':
			return value as string;
		case 'Boolean':
			return value ? 'vrai' : 'faux'; // Todo use translate
	}
}

export function parseValue(type: FieldType, value: string): string | number | undefined {
	switch (type) {
		case 'Text':
			return value.trim() !== '' ? value : undefined;
		case 'Number':
			return value.trim() !== '' ? parseFloat(value) : undefined;
		case 'Money':
			return value.trim() !== '' ? (parseFloat(value) * 100).toFixed(0) : undefined;
		case 'Date':
			return value;
		case 'User':
			return value;
		case 'Url':
			return (new URL(value)).toString();
		case 'Email':
			assert(validateEmail(value), 'Invalid email');
			return value;
		case 'Phone':
			assert(validatePhone(value), 'Invalid phone');
			return value;
		case 'Select':
			return value.trim() !== '' ? value : undefined;
	}
}

function validateEmail(email: string): boolean {
	return Boolean(String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		));
}

function validatePhone(phone: string): boolean {
	return Boolean(String(phone).match(/^(\+?\d{1,3}[- ]?)?(\d{3}[- ]?){2}\d{3}$/));
}

const Select = styled.select<{ border?: boolean }>`
    ${({ border }) => border ? '' : ' border: none; width: 100%;'}
	font-size: 12px;
	font-weight: 400;
	color: ${DarkGreySidely2};
`;

export default function FieldEditor({ field, value, onChange, onSave, border }: FieldEditorProps) {
	const [changed, setChanged] = useState(false);
	const owners = useRecoilValue(AUsers);
	const ownerDD = React.useMemo(() => owners.map((owner) => ({
		value: owner,
		label: owner.name
	}))
	, [owners]);

	if (field === undefined) {
		return <div className="center">
			<p>Unknown field</p>
		</div>;
	}

	function handleChanged(e: ChangeEvent<HTMLInputElement>) {
		setChanged(true);
		// onChange?.(e.currentTarget.value);
		if (onChange) {
			try {
				onChange(parseValue(field!.type, e.currentTarget.value));
			} catch {
				// TODO: Show red border
			}
		}
	}

	function handleBlur(e: ChangeEvent<HTMLInputElement>) {
		if (changed) {
			try {
				const v = parseValue(field!.type, e.currentTarget.value as string);
				setChanged(false);
				if (v !== undefined) {
					save(v);
				}
			} catch (e: any) {
				// toast.error(e.toString());
				console.log('Failed to parse: ', e);
			}
		}
	}

	function save(v: string | number | boolean) {
		try {
			onSave?.(v);
		} catch {
			// TODO: Show red border
		}
	}

	switch (field.type) {
		case 'Text':
			return <Input name={`INPUT_FIELD[${field.id}]`} type='text' value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} />;
		case 'Number':
			return <Input name={`INPUT_FIELD[${field.id}]`} type="number" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} />;
		case 'Money':
			return <Input name={`INPUT_FIELD[${field.id}]`} type="text" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} />;
		case 'Boolean':
			return <Checkbox isActive={value as boolean} onClick={() => { onChange?.(!value); save(!value); }} />;
		case 'User':
			return <DropdownOwners selected={ownerDD.find(o => o.value.id == value)} onChange={(v) => { onChange?.(v?.value?.id); save(v?.value!.id); } } users={ownerDD} />;
		case 'Url':
			return <Input name={`INPUT_FIELD[${field.id}]`} type="url" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} />;
		case 'Email':
			return <Input name={`INPUT_FIELD[${field.id}]`} type="email" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} />;
		case 'Phone':
			return <Input name={`INPUT_FIELD[${field.id}]`} type="phone" value={stringifyValue(field, value) ?? ''} onChange={handleChanged} onBlur={handleBlur} />;
		case 'Select':
		{
			assert(Array.isArray(field.data), `Field ${field.name} is of type select but has no data (${JSON.stringify(field.data)})`);
			// let allowedValues = undefined;
			// if (product || company && field.constraint) {
			// 	const entity = use(entities(EntityRepresentation.Map, product ? EntityType.Product : EntityType.Company)).get(product ?? company!);
			// 	assert(entity, 'Field editor has wrong field entity');
			// 	allowedValues = Array.from(field.constraint.entries()).reduce((acc, [constraintField, constraintMap]) => {
			// 		const f = entity.fields.get(constraintField);
			// 		if (!f) {
			// 			return acc;
			// 		}
			// 		assert(constraintMap.tag === 7, 'Constraint map is not a map');
			// 		assert(typeof f.val === 'string', 'Constraint field value is not a string');
			// 		const allowedValues = constraintMap.val.get(f.val);
			// 		assert(allowedValues, 'Constraint field value is not in its constraint map');
			// 		assert(allowedValues.tag === 6, 'Allowed values are not a set');
			// 		if (acc === undefined) {
			// 			return allowedValues.val.map(v => v.val);
			// 		} else {
			// 			return acc.filter(v => allowedValues.val.map(v => v.val).includes(v));
			// 		}

			// 	}, undefined as FieldValueInner[] | undefined);
			// }
			return <Select border={border} value={stringifyValue(field, value) ?? ''} onChange={(e) => { onChange?.(e.target.value); save(e.target.value); }}>
				<option disabled value=""></option>
				{(field.data as string[]).filter(v => v !== null).map((v) => (
					<option value={v} key={v}>{v}</option>
				))}
			</Select>;
		}
		default:
			return <div className="center">
				<p>Unsupported field type {field.type}</p>
			</div>;

	}
}
