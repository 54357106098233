import { DashboardItem } from '../Dashboard';
import * as React from 'react';
import { Brand } from '../../globals/Model';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { getBrands } from '../../../components_v2/toolbarFilter/brands/action';
import { FlexDiv } from '../../products/style';
import { Translate } from '../../../styles/global/translate';
import { Evolution } from '../../reports/interpretor/ReportInterpretor';
import {
	CreateButton,
	DeleteButton,
	EditButton,
	ScrollDiv,
	WidgetBody,
	WidgetTitle,
	WidgetTitleWrapper
} from '../Widget';

interface DashboardParentDn {
	name: string,
	company_id: number,
	dn: number | null,
	old_dn: number | null
}

export default function ParentDnWidget(props: {
	value: DashboardParentDn,
	item: DashboardItem,
	selected?: boolean,
	onSelect?: (selected: boolean) => void,
	setItem: (item: DashboardItem) => void,
	onCreationClicked?: () => void
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
	isHover?: boolean
}) {
	const { value, item, onCreationClicked, onClickDelete, isHover, onClickEdit } = props;
	const [brands, setBrands] = React.useState<Brand[]>();
	const users = useRecoilValue(AUsers);
	const [isOpenDetail, setIsOpenDetail] = React.useState<boolean>(false);


	const currentBrand = brands?.find(brand => brand.id === item.additional_values?.['brand']);
	const currentUser = users.find(user => user.id === item.additional_values?.['user']);
	const currentGranularity = item.additional_values?.['granularity'];
	const currentExpiration = item.additional_values?.['expiration'];

	React.useEffect(() => {
		if (brands === undefined) getBrands().then(setBrands);
	}, []);

	return <>
		<WidgetTitleWrapper>
			<FlexDiv justify="space-between" align='center' width='100%'>
				{/*{ !onCreationClicked && <ArrowBlock width='20px' onClick={() => setIsOpenDetail(!isOpenDetail)} isOpened={isOpenDetail} arrowSize={10}/>}*/}
				<WidgetTitle>
					{item.title ?? <Translate id='dashboard.widget.dtn_parent'/>}
				</WidgetTitle>
				<FlexDiv justify="flex-end" gap="2px">
					{onCreationClicked && <CreateButton onClickCreate={onCreationClicked}/>}
					{isHover && onClickEdit && <EditButton onClickEdit={() => onClickEdit(item)}/>}
					{isHover && onClickDelete && <DeleteButton onClickDelete={() => onClickDelete(item.id as number)}/>}
				</FlexDiv>
			</FlexDiv>
			{/*{isOpenDetail && <WidgetDetail>*/}
			{/*	<FlexDiv>*/}
			{/*		<Ellipsis>{translateToString('company')}:</Ellipsis>*/}
			{/*		<Ellipsis fontWeight='600'>{value.name ?? <Translate id='all'/>}</Ellipsis>*/}

			{/*	</FlexDiv>*/}
			{/*	{ currentBrand && <FlexDiv justify='flex-start' width='100%' gap='5px'>*/}
			{/*		<Ellipsis>{translateToString('brand')}</Ellipsis>*/}
			{/*		<Ellipsis fontWeight='600'>{currentBrand.name}</Ellipsis>*/}
			{/*	</FlexDiv> }*/}
			{/*	{currentUser && <FlexDiv justify='flex-start' width="100%"><UserBlock user={currentUser} /></FlexDiv>}*/}
			{/*	{currentGranularity && <FlexDiv width="100%" gap='5px'>*/}
			{/*		<Ellipsis>{translateToString('granularity')}:</Ellipsis>*/}
			{/*		<Ellipsis fontWeight='600'>{translateToString(`dashboard.expiration.${currentGranularity}`)}</Ellipsis>*/}
			{/*	</FlexDiv>}*/}
			{/*	{currentExpiration && <FlexDiv width="100%" gap='5px'>*/}
			{/*		<Ellipsis>{translateToString('expiration')}</Ellipsis>*/}
			{/*		<Ellipsis fontWeight='600'>{translateToString(`dashboard.expiration.${currentExpiration}`)}</Ellipsis>*/}
			{/*	</FlexDiv>}*/}
			{/*</WidgetDetail>}*/}
		</WidgetTitleWrapper>
		<ScrollDiv centered>
			<WidgetBody small={value.dn === null}>
				{value.dn !== null && (value.dn * 100).toFixed(0) + '%'}
				{value.dn === null && <Translate id='no_dn'/>}
			</WidgetBody>
			<FlexDiv justify='flex-end' width='100%'>
				<Evolution percentage={value.dn !== null && value.old_dn !== null ? (value.dn - value.old_dn) : 0} background/>
			</FlexDiv>
		</ScrollDiv>
	</>;
}
