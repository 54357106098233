import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { AEventStatusFilter } from '../../../atoms/filter/eventFiltersAtom';
import { EventStatus } from '../../../containers_v2/calendar/model/Model';
import { getEventStatuses } from './actions';
import { GenericToolbarFilterProps } from '../ToolbarFilter';

export default function EventStatusFilter(props: GenericToolbarFilterProps) {
	const [status, setStatus] = useRecoilState(AEventStatusFilter);
	const [label, setLabel] = React.useState<JSX.Element>(<Translate id="all" />);
	const [statuses, setStatuses] = React.useState<EventStatus[]>([]);

	React.useEffect(() => {
		getEventStatuses()
			.then(res => setStatuses(res.data.data))
			.catch(console.log);
	}, []);

	React.useEffect(() => {
		switch (status) {
			case undefined:
				setStatus(null);
			case null:
				return setLabel(<Translate id={'all'} />);
			default:
				return setLabel(<Translate id={'event.' + statuses.find(s => s.id == status)?.name} />);
		}
	}, [status, statuses]);

	if (props.hidden) return <></>;

	return (
		<React.Fragment>
			<UncontrolledButtonDropdown
				direction="down"
				className="form-filter-opportunity d-flex align-items-center"
				style={{ margin: 0 }}
			>
				<small><Translate id='status' />:</small>
				<DropdownToggle className="toggler py-0 px-2" caret>
					{label}
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem
						onClick={() => {
							setLabel(<Translate id="all" />);
							setStatus(null);
						}}
					>
						<Translate id="all" />
					</DropdownItem>
					{...statuses.map(s => <DropdownItem
						key={`dropdownEventStatusFilter[${s.id}]`}
						onClick={() => {
							setStatus(s.id);
						}}
					>
						<Translate id={`event.${s.name}`} />
					</DropdownItem>
					)}
				</DropdownMenu>
			</UncontrolledButtonDropdown>
		</React.Fragment>
	);
}
