import { FieldType } from 'bindings/forms/FieldType';
import * as React from 'react';
import styled from 'styled-components';
import Add from '../../components_v2/add/Add';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import Input from '../../components_v2/input/Input';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { DeleteDot } from '../../styles/global/css/Dot';
import { translateToString } from '../../styles/global/translate';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../client-companies/style/Style';
import { creationPopupInputStyleMargin } from '../promotions/PopupPromotion';
import { getField, postField, putField } from './actions';
import { Field } from '../../atoms/forms';
import { ModalData } from './FormTemplateCreator';

export const ALL_FIELD_TYPES: FieldType[] = ['Text', 'Number' , 'Date' , 'Boolean' , 'Select' , 'Multiselect' , 'File' , 'Image' , 'Url' , 'Email' , 'Phone' , 'Address' , 'Location' , 'User', 'Company', 'Integer', 'CheckIn', 'Form', 'TextArea', 'Signature', 'Money'];

type MetaData = {
	Select?: string[],
	Multiselect?: string[],
	Form?: number,
	CheckIn?: number,
}

const SelectMetaDataContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1em;
`;

export function SelectMetaData(props: { title?: string, value?: string, first: boolean, onDelete?: () => void, onChange: (value: string) => void }) {
	return <SelectMetaDataContainer>
		<Input
			required
			label={props.first ? (props.title ?? 'MetaData') : undefined}
			value={props.value}
			type='text'
			name='selectMetaData'
			inputStyle={props.first ? creationPopupInputStyleMargin : creationPopupInputStyle}
			onChange={props.onChange}
		/>
		{props.onDelete !== undefined && <DeleteDot style={{ marginTop: props.first ? '21px' : undefined }} onClick={props.onDelete}/>}
	</SelectMetaDataContainer>;
}

const metaDataValidator = (metaData: MetaData, type: FieldType) => {
	switch (type) {
		case 'Select':
		case 'Multiselect':
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return metaData[type] !== undefined && metaData[type]!.length !== 0 && metaData[type]!.every(e => e.length);
		case 'Form':
		case 'CheckIn':
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return metaData[type] !== undefined && metaData[type]! > 0;
		default: return true;
	}
};

export default function PopupField(props: { isOpen: boolean, setIsOpen: (b: boolean) => void, field: ModalData, onCreate: () => void }) {
	const [type, setType] = React.useState<FieldType | undefined>(props.field.type);
	const [name, setName] = React.useState<string>(props.field.name ?? '');
	const [data, setData] = React.useState<MetaData>({});

	React.useEffect(() => setType(props.field.type), [props.field.type]);
	React.useEffect(() => setName(props.field.name ?? ''), [props.field.name]);
	const reset = () => {
		setType(undefined);
		setName('');
		setData({});
	};

	React.useEffect(() => {
		if (props.field.field_id !== undefined) {
			getField(props.field.field_id).then(field => {
				setType(field.type);
				setName(field.name);
				setData(field.data ? { [field.type]: field.data } : {});
			});
		}
	}, [props.field.field_id]);

	return <Popup
		popupMode={PopupMode.Details}
		isOpen={props.isOpen}
		onClickOut={() => {
			reset();
			props.setIsOpen(false);
		}}
	>
		<PopupCreation
			messageButton={props.field.field_id ? 'Sauvegarder' : 'Créer'}
			title={props.field.field_id ? 'Field edition' : 'Field creation'}
			canValidate={type !== undefined && name.length !== 0 && metaDataValidator(data, type)}
			onSubmit={() => {
				if (props.field.field_id) {
					if (type) {
						putField(props.field.field_id, {
							id: props.field.field_id,
							type,
							name,
							data: data[type]
						}).then(() => {
							reset();
							props.onCreate();
						});
					}
				} else {
					if (!type) return;
					postField({
						type,
						name,
						data: data[type]
					}).then(() => {
						reset();
						props.onCreate();
					});
				}

			}}
			onClose={() => props.setIsOpen(false)}
		>
			<CreationPopupContainer gap='1em'>
				slug: {props.field.slug}
				<Dropdown
					dropdownStyle={{ ...creationPopupDropdownStyle, margin: '11px 0' }}
					datalist={ALL_FIELD_TYPES.map(value => ({ label: value, value }))}
					name='type-dropdown'
					onChange={value => setType(value.value)}
					disabled={props.field.field_id !== undefined}
					selectedValue={type ? { label: type, value: type } : undefined}
					required
					label={translateToString('type')}
				/>
				{type && <Input type='text' name='title-input' value={name} onChange={setName} inputStyle={creationPopupInputStyleMargin} required label={translateToString('title')} />}
				{(type === 'Select' || type === 'Multiselect') && <>
					{data[type]?.map((e, i) => <SelectMetaData
						value={e}
						key={`selectedMetaData[${i}]`}
						first={i === 0}
						onDelete={() => {
							setData(md => {
								md[type]?.splice(i, 1);
								return { ...md };
							});
						}}
						onChange={value => {
							setData(md => {
								if (!md[type]) md[type] = [];
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								md[type]![i] = value;
								return { ...md };
							});
						}}
					/>) ?? <></>}
					<Add onClick={() => setData(md => {
						if (!md[type]) md[type] = [];
						md[type]?.push('');
						return { ...md };
					})}/>
				</>}
				{(type === 'Form' || type === 'CheckIn') && <>
					<Input type='number' name='form-id' value={props.field.field_id ? data[type] : 0} onChange={value => {
						setData(md => {
							md[type] = parseInt(value);
							return { ...md };
						});

					}} inputStyle={creationPopupInputStyleMargin} required label={translateToString('form-id')} />
				</>}

			</CreationPopupContainer>

		</PopupCreation>
	</Popup>;
}
