import React, {Fragment, useEffect} from 'react';
import {useLocation} from "react-router";

function HeaderArrowBack(props) {
    const detailTemplate = JSON.parse(localStorage.getItem('enFormTemplate'));
    const step = (detailTemplate && detailTemplate.step) || 0;


    return (
        <Fragment>
            <div
                className="my-auto mr-2 pointer"
                onClick={() => {
                    //FIXME: if use only new page, condition wouldn't be util
                    // always '/shelf-audits'
                    let path = '/enform/list'
                    if (props.isFromNewShelfAudit) {
                        localStorage.removeItem('from_new_shelf_audit');
                        path = '/shelf-audits';
                    }
                    props.history.push(path)
                }}
            >
                <i class="fa fa-arrow-left" style={{ fontSize: '18px' }} />
            </div>
            <h2 className="card-title mb-0 mx-2">
                {detailTemplate && detailTemplate.name}
            </h2>
        </Fragment>
    );
}

export default HeaderArrowBack;