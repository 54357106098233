import { atom, selector } from 'recoil';
import { AtomCategory, AtomState } from '../utils/model/Model';
import { Granularity } from 'bindings/time/Granularity';

// -----------------------[ ATOM ]----------------------- //
const AGlobalGranularity: AtomState<Granularity> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_granularity', // UNIQUE ID
		default: (localStorage.getItem('selected_granularity') ?? 'month') as Granularity
	})
};

export const AGranularity = selector<Granularity>({
	key: 'AGranularity',
	get: ({ get }) => get(AGlobalGranularity.atom),
	set: ({ set }, newValue) => {
		localStorage.setItem('selected_granularity', newValue.toString());
		return set(AGlobalGranularity.atom, newValue);
	}
});