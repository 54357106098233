import React, {Component} from 'react';

class Image extends Component {
  state = {};

  render() {
    const { data } = this.props;
    return (
      <div className="form-group">
        <div className="image-title">
          {data.is_display_field_name && <p className="">{data.field_name}</p>}
          {data.default_image_src ? (
            <div className="">
              <img src={data.default_image_src} alt="avatar" width="100px" />
            </div>
          ) : (
            <div className="image-placeholder">
              <p className="mx-auto my-auto">No Image</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Image;
