import swal from 'sweetalert2';
import { logoutUser } from '../store/actions/auth.action';

// handle action error for fetching data
export function handleError(error, dispatch, type) {
	if (error.response) {
		dispatch({ type, type: error.response });

		if (error.response.status === 401) {
			dispatch(logoutUser());
		}
	}

	dispatch({ type, error });
}

export function handleOpportunities(error) {
	const lang = localStorage.getItem('language');
	const message =
    lang === 'fr'
    	? 'Veuillez entrer une somme sans espaces'
    	: 'Please enter an amount with no spaces';
	if (error.data.errors === 'column "nan" does not exist') {
		swal.fire('Error!', message, 'error');
	} else {
		swal.fire('Error!', error.data.errors, 'error');
	}
	if (error.status === 401) {
		dispatch(logoutUser());
	}
}

// handle action error for post / put data will show error message
export function handleErrorWithMessage(error, dispatch, type) {
	if (error.response) {
		dispatch({ type, error: error.response });
		swal.fire('Error!', error.response.data.errors, 'error');

		if (error.response.status === 401) {
			dispatch(logoutUser());
		}

		return;
	}
	dispatch({ type, error });
}

export function cekTranslate(val) {
	if (localStorage.getItem('language') === 'fr') {
		return 'Impossible d\'associer cette société car elle est possède déjà une affiliation';
	}
	return val;
}

export function handleErrorCompanies(error, dispatch, type) {
	if (error.response) {
		dispatch({ type, error: error.response });
		let errorData = error.response.data.errors;
		const key =
      'Can\'t input this company as a parent because it already has an affiliation';
		if (errorData === key) {
			errorData = cekTranslate(key);
		}
		swal.fire('Error!', errorData, 'error');

		if (error.response.status === 401) {
			dispatch(logoutUser());
		}
		return;
	}
	dispatch({ type, error });
}

export function handleErrorImport(error, dispatch, type, modalErrorValidate) {
	if (error.response) {
		if (error.response.data.errors.validation !== undefined) {
			dispatch({ type, error: error.response.data.errors });
		} else {
			dispatch({ type, error: { validation: [error.response.data.errors] } });
		}
		dispatch(modalErrorValidate(true));
		// swal.fire('Error!', error.response.data.errors, 'error');
		if (error.response.status === 401) {
			dispatch(logoutUser());
		}
		return;
	}
	dispatch({ type, error });
}

export function handleImportProduct(error, dispatch, type, modalErrorValidate) {
	if (error.response) {
		if (error.response.data.message.validation !== undefined) {
			dispatch({ type, error: error.response.data.message });
		} else {
			dispatch({ type, error: { validation: [error.response.data.errors] } });
		}
		dispatch(modalErrorValidate(true));
		// swal.fire('Error!', error.response.data.errors, 'error');
		if (error.response.status === 401) {
			dispatch(logoutUser());
		}
		return;
	}
	dispatch({ type, error });
}
