/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import TextareaAutosize from 'react-autosize-textarea';

class FreeText extends Component {
    state = {};

    render() {
        const { data } = this.props;
        return (
            <div className="form-group">
                <label id={`id-${data.shelf_audit_field_id}`} className="control-label" htmlFor="">
                    {data.mandatory && <span className="text-danger">* </span>}
                    <Translate id={data.field_name} />
                </label>
                <TextareaAutosize
                    type="text"
                    className="form-control"
                    defaultValue={data.initial_value}
                />
            </div>
        );
    }
}

export default FreeText;
