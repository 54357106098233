import React, {Fragment, useState} from 'react';
import Lightbox from 'react-image-lightbox';
import {Translate} from 'react-localize-redux';

function Pictures(props) {
  const { images } = props;
  const [modalPreview, setModalPriview] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <Fragment>
      <div className="d-flex">
        {images && images.length > 0 ? (
          images.map((elem, i) => (
            <img
              src={elem}
              className="image-thumbnail pointer mr-2"
              alt=""
              height="90"
              style={{ maxWidth: '150px' }}
              onClick={() => {
                setModalPriview(true);
                setPhotoIndex(i);
              }}
            />
          ))
        ) : (
          <p className="empty-placeholder">
            <Translate id="no data" />
          </p>
        )}
      </div>
      {modalPreview && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setModalPriview(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Fragment>
  );
}

export default Pictures;
