import add_blue from 'images/icon/add_blue.png';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import swal from 'sweetalert2';
import { CompleteTime } from '../../../../helpers/time';
import { deleteNote } from '../../../../store/actions/contacts.action';
import noUserImage from 'images/icons/user.svg';
import ModalNoteForm from './modals/ModalNoteForm';
import Restricted from '../../../../containers_v2/permissions/Restricted';
import penUrl from 'images/ui_icon/pen_black.svg';
import trashUrl from 'images/ui_icon/trash_black.svg';
import penBlueUrl from 'images/ui_icon/pen_blue.svg';
import trashBlueUrl from 'images/ui_icon/trash_blue.svg';

class Notes extends Component {
	state = {
		penUrl,
		trashUrl,
		id: {
			noteId: null,
			contactId: null
		},
		modal: false
	};

	onChangeMode = mode => {
		this.setState({
			mode
		});
	};

	onHover = (pen, trash) => {
		this.setState({
			penUrl: pen ? pen.includes('blue') ? penBlueUrl : penUrl : this.state.penUrl,
			trashUrl: trash ? trash.includes('blue') ? trashBlueUrl : trashUrl : this.state.trashUrl
		});
	};

	onDelete = (id, contactId) => {
		const { deleteNote } = this.props;

		swal
			.fire({
				title: 'Attention!',
				text: 'Are you sure you want to delete this from the database?',
				type: 'warning',
				confirmlButtonText: 'Yes, I do',
				confirmButtonColor: '#f7685b'
			})
			.then(result => {
				if (result.value) {
					deleteNote(id, contactId);
				}
			});
	};

	openModal = (noteId, mode) => {
		this.setState({
			id: {
				noteId,
				contactId: this.props.id.contactId
			},
			modal: true,
			mode
		});
	};

	closeModal = () => {
		this.setState({
			id: {
				noteId: null,
				contactId: this.props.id.contactId
			},
			modal: false
		});
	};

	render() {
		const {
			data,
			id,
			onFetch,
			isLoading,
			isFirstPage,
			isLastPage,
			count
			// loadingDeleteId
		} = this.props;
		// console.log('data', this.state.noteId);

		return (
			(<div>
				<div className="detail-top m-3 p-3">
					<div className="row mb-2">
						<div className="col-md-9">
							<div className="mb-2">
								<span className="card-title">Notes ({count || '0'}) </span>
							</div>
						</div>
						<div className="col-md-3 d-flex justify-content-end align-items-end">
							<Restricted to={{ objectAction: 'CreateContactNote' }}>
								<div
									className="my-auto custom-icon-blue rounded-circle "
									onClick={() => {
										this.openModal(null, 'New');
									}}
								>
									<img
										src={add_blue}
										className="custom-icon"
										alt=""
									/>
								</div>
							</Restricted>
						</div>
					</div>
					{data.length > 0 ? (
						<div className="row">
							{data.map(val => (
								<div className="col-md-12 mb-2">
									<div className="card card-notes relative-btn-container">
										<Restricted to={{ objectAction: 'UpdateContactNote' }}>
											<button
												className="btn-edit-absolute"
												onClick={e => {
													e.preventDefault();
													this.openModal(val.id, 'Edit');
												}}
											>
												<img
													src={this.state.penUrl}
													alt=""
													width="15px"
													height="15px"
													onMouseEnter={() => this.onHover('pen_blue.svg')}
													onMouseLeave={() => this.onHover('pen_black.svg')}
												/>
											</button>
										</Restricted>
										<Restricted to={{ objectAction: 'DeleteContactNote' }}>
											<button
												className="btn-delete-absolute"
												onClick={e => {
													e.preventDefault();
													this.onDelete(val.id, id.contactId);
												}}
											>
												<img
													src={this.state.trashUrl}
													alt=""
													width="15px"
													height="15px"
													onMouseEnter={() =>
														this.onHover(null, 'trash_blue.svg')
													}
													onMouseLeave={() =>
														this.onHover(null, 'trash_black.svg')
													}
												/>
											</button>
										</Restricted>
										<div
											className="card-body pointer p-2"
											onClick={() => {
												this.openModal(val.id, 'View');
											}}
											// style={{ borderRadius : '6px'}}
										>
											<div className="row">
												<div className="col-md-1 my-auto text-center p-0">
													<img
														className="rounded-circle"
														src={val.user.photoUrl || noUserImage}
														alt=""
														width="32"
														height="32"
													/>
												</div>
												<div className="col-md-9 pl-1">
													<div className="d-block ml-2">
														<p className="mb-1 text-title word-break">
															{val.title || 'No Tittle'}
														</p>
														<p className="mb-1 text-description word-break textLimitNote">
															{val.body || 'No Body'}
														</p>
														<p className="mb-1 text-by word-break">
															{`by ${val.user.name} on ${CompleteTime(
																val.createdAt
															)}`}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<p
							style={{
								fontSize: '14px',
								margin: '15px 0 10px',
								color: '#707683'
							}}
						>
							<Translate id="no notes" />
						</p>
					)}
					{count > 3 &&
            (!isLoading ? (
            	<div className="row mt-2">
            		<button
            			className={`${
            				isLastPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-more col-6`}
            			onClick={() => {
            				!isLastPage && onFetch();
            			}}
            		>
            			<Translate id="see_more" />
            		</button>
            		<button
            			className={`${
            				isFirstPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-less col-6`}
            			onClick={() => {
            				!isFirstPage && onFetch(1);
            			}}
            		>
            			<Translate id="see_less" />
            		</button>
            	</div>
            ) : (
            	<div className="d-flex">
            		<i className="lds-dual-ring mx-auto" />
            	</div>
            ))}
				</div>
				<ModalNoteForm
					isOpen={this.state.modal}
					toggle={() => {
						this.closeModal();
					}}
					onChangeMode={this.onChangeMode}
					onDelete={this.onDelete}
					onHover={this.onHover}
					mode={this.state.mode}
					penUrl={this.state.penUrl}
					trashUrl={this.state.trashUrl}
					contactId={id.contactId}
					id={this.state.id}
				/>
			</div>)
		);
	}
}

const mapStateToProps = state => ({
	// loadingDeleteId: state.compan.deleteNoteId
});

export default connect(
	mapStateToProps,
	{ deleteNote }
)(Notes);
