import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { LoadingStateEnum } from '../../../../import/model';
import { getCompanyFreeForms } from '../../../data/action';
import { CompanyFreeForm } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import { Card, CardImage, CardLeft, CardMiddle, CardText, CardTitle, Container, SeeMore } from '../style/LinkedElementStyle';

export default function FreeFroms(props: {
  clientCompanyId: number
  isExtend: boolean
  total: number
}): JSX.Element {
	const DEFAULT_LIMIT = 3;

	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [freeForms, setFreeForm] = React.useState<CompanyFreeForm[]>([]);
	const [offset, setOffset] = React.useState<number>(0);
	const [total, setTotal] = React.useState<number>(0);

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getCompanyFreeForms({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset
		}).then(response => {
			setFreeForm(response);
			setTotal(response.length > 0 ? response[0].free_forms_count : total);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getCompanyFreeForms({
			client_company_id: props.clientCompanyId,
			limit: DEFAULT_LIMIT,
			offset: newOffset
		}).then(response => {
			setFreeForm(freeForms.concat(response));
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}

	return (
		<Container>
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: freeForms.length > 0
						? freeForms.map(s => {
							return (
								<Card key={`RANDOMKEY[${s.id}]`} isExtend={props.isExtend} onClick={() => window.open(`/formBuilder/formData/${s.id}/list`, '_blank')}>
									<CardLeft>
										<CardImage url={s.url} width='25px' borderRadius='0' backgroundSize='contain' />
									</CardLeft>
									<CardMiddle>
										<CardTitle>{s.name}</CardTitle>
										<CardText>{`${s.count} ${translate('company.detail.linked_elements.free_forms').toString().toLowerCase()}`}</CardText>
									</CardMiddle>
								</Card>
							);
						})
						: <NoDataText><Translate id='no_free_forms' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADED && freeForms.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
		</Container>
	);
}
