import trash_black from 'images/ui_icon/trash_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import Avatar from '../../../../../components_v2/avatar/Avatar';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { GreenSidely, RedSidely } from '../../../../../styles/global/css/Utils';
import { UserToolTip } from '../../../../../styles/global/ToolTip';
import { LoadingStateEnum } from '../../../../import/model';
import { formatCurrency } from '../../../../reports/utils';
import { deleteCompanyOpportunity } from '../../../data/action';
import { CompanyOpportunity } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import {
	Card,
	CardExtraRight,
	CardImage,
	CardLeft,
	CardMiddle,
	CardProbability,
	CardRight,
	CardText,
	CardTitle,
	ColorBar,
	Container,
	SeeMore,
	Trash
} from '../style/LinkedElementStyle';
import Restricted from '../../../../permissions/Restricted';
import usePermission from '../../../../permissions/usePermission';
import { LinkedElementId } from '../../../../../containers/companies/components/linkedElements/actions';
import { getLinkedElementOpportunities } from '../actions';

interface DeletionState {
	isOpen: boolean
	opportunity?: CompanyOpportunity
}

function Opportunity(props: {
  id: LinkedElementId
  isExtend?: boolean
  newCreation?: CompanyOpportunity
  onDelete: () => void
}): JSX.Element {

	const translate = getTranslate(storeLang.getState().localize);
	
	const DEFAULT_LIMIT = 3;

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [opportunities, setOpportunities] = React.useState<CompanyOpportunity[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [offset, setOffset] = React.useState<number>(0);

	const [deletionState, setDeletionState] = React.useState<DeletionState>({ isOpen: false });
	const canViewOpportinityPage = usePermission({ objectAction: 'ViewOpportunity' });

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getLinkedElementOpportunities({
			id: props.id,
			limit: DEFAULT_LIMIT,
			offset
		}).then(response => {
			console.log(response);
			setOpportunities(response.opportunities);
			setTotal(response.total);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	React.useEffect(() => {
		if (props.newCreation != null) {
			setOpportunities([props.newCreation, ...opportunities]);
			setOffset(offset + 1);
		}
	}, [props.newCreation]);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getLinkedElementOpportunities({
			id: props.id,
			limit: DEFAULT_LIMIT,
			offset: newOffset
		}).then(response => {
			setOpportunities(opportunities.concat(response.opportunities));
			setTotal(response.total > 0 ? response.total : total);
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}

	function confirmationDeletionOpportunity(): void {
		if (!deletionState.opportunity) {
			return;
		}
		
		deleteCompanyOpportunity({
			client_company_id: deletionState.opportunity.client_company_id,
			id: deletionState.opportunity.opportunity_id
		}).then(res => {
			if (res) {
				props.onDelete();
				setOpportunities(opportunities.filter(o => o.opportunity_id !== deletionState.opportunity?.opportunity_id));
				setOffset(offset - 1);
				setTotal(total - 1);
			}
		});
	}

	function getColor(proba: number): string {
		const p = `${Math.floor(proba / 10.0)}0`;
		switch (p) {
			case '0':
				return '#EAEAEA';
			case '10':
				return RedSidely;
			case '20':
				return '#FF814B';
			case '30':
				return '#FEA240';
			case '40':
				return '#FEC940';
			case '50':
				return '#FFEA2C';
			case '60':
				return '#E2EE5C';
			case '70':
				return '#D2F34E';
			case '80':
				return '#87F16D';
			case '90':
				return '#3CDF87';
			case '100':
				return GreenSidely;

			default:
				return '#EAEAEA';
		}
	}

	return (
		(<Container>
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: opportunities.length > 0
						? opportunities.map(o => (
							<Restricted to={{ objectAction: 'ReadOpportunity' }} ownerId={o.incharge_id} key={`Opportunity[${o.opportunity_id}]`}>
								<Card onClick={() => canViewOpportinityPage && window.open(`/opportunities/detail/${o.opportunity_id}`, '_blank')} isExtend={props.isExtend ?? false}>
									<ColorBar color={o.opportunity_status_color} />
									<CardLeft>
										<UserToolTip userId={o.incharge_id} left='-35px'>
											{o.incharge_photo && o.incharge_photo.length > 0
												? <CardImage url={o.incharge_photo} />
												: <Avatar userId={o.incharge_id} width="35px" name={o.incharge_name.trim()} />}
										</UserToolTip>
									</CardLeft>
									<CardMiddle width='60%'>
										<CardTitle>{o.incharge_name.trim()}</CardTitle>
										<CardText>{o.opportunity_title}</CardText>
										<CardText>{moment.utc(o.opportunity_created_at).local().format('DD/MM/YYYY')}</CardText>
										<CardText>{`${formatCurrency(o.opportunity_revenue)}`}</CardText>
										<CardText>{o.opportunity_status_name}</CardText>
									</CardMiddle>
									<CardRight>
										<CardProbability backgroundColor={getColor(o.opportunity_probability)}>{o.opportunity_probability.toString().split('.')[0]}</CardProbability>
									</CardRight>
									<CardExtraRight>
										<Restricted to={{ objectAction: 'DeleteOpportunity' }} ownerId={o.incharge_id}>
											<Trash src={trash_black} onClick={(event) => {
												event.stopPropagation();
												setDeletionState({ ...deletionState, isOpen: true, opportunity: o });
											} } />
										</Restricted>
									</CardExtraRight>
								</Card>
							</Restricted>
						))
						: <NoDataText><Translate id='no_opportunities' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && opportunities.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
			<PopupDeletion 
				isOpen={deletionState.isOpen} 
				records={0} 
				translationKey=''
				customTitle={translate('company.detail.linked_elements.opportunity').toString()}
				customMessage={translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${deletionState.opportunity?.opportunity_title ?? ''}'`)} 
				onClickOut={() => setDeletionState({ ...deletionState, isOpen: false })}
				onValidation={() => confirmationDeletionOpportunity()}
				noVerification
			/>
		</Container>)
	);
}

export default Opportunity;
