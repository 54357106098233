export const MODAL_FILTER_OWNER = 'MODAL_FILTER_OWNER';
export const MODAL_FILTER_USERTYPE = 'MODAL_FILTER_USERTYPE';
export const MODAL_FILTER_CLIENTSTATUS = 'MODAL_FILTER_CLIENTSTATUS';
export const MODAL_FILTER_EXPENSESTATUS = 'MODAL_FILTER_EXPENSESTATUS';
export const MODAL_CREATE_EVENT = 'MODAL_CREATE_EVENT';
export const MODAL_FILTER_OPPORTUNITYSTATUS = 'MODAL_FILTER_OPPORTUNITYSTATUS';
export const MODAL_FILTER_EVENTTYPE = 'MODAL_FILTER_EVENTTYPE';
export const MODAL_FILTER_COMPANIES = 'MODAL_FILTER_COMPANIES';
export const MODAL_EVENT_FORM = 'MODAL_EVENT_FORM';
export const MODAL_ERROR_MESSAGE_IMPORT = 'MODAL_ERROR_MESSAGE_IMPORT';
export const MODAL_FILTER_CITY = 'MODAL_FILTER_CITY';

export const filterOwner = data => async dispatch => {
	dispatch({
		type: MODAL_FILTER_OWNER,
		payload: data
	});
};

export const filterUserType = data => async dispatch => {
	dispatch({
		type: MODAL_FILTER_USERTYPE,
		payload: data
	});
};

export const filterClientStatus = data => async dispatch => {
	dispatch({
		type: MODAL_FILTER_CLIENTSTATUS,
		payload: data
	});
};

export const filterOpportunityStatus = data => async dispatch => {
	dispatch({
		type: MODAL_FILTER_OPPORTUNITYSTATUS,
		payload: data
	});
};

export const filterExpenseStatus = data => async dispatch => {
	dispatch({
		type: MODAL_FILTER_EXPENSESTATUS,
		payload: data
	});
};

export const togleFilterCompaniesModal = data => dispatch => {
	dispatch({
		type: MODAL_FILTER_COMPANIES,
		payload: data
	});
};

export const filterEventType = data => async dispatch => {
	dispatch({
		type: MODAL_FILTER_EVENTTYPE,
		payload: data
	});
};

export const createEvent = data => async dispatch => {
	dispatch({
		type: MODAL_CREATE_EVENT,
		payload: data
	});
};

export const modalEvent = (mode, id) => async dispatch => {
	dispatch({
		type: MODAL_EVENT_FORM,
		payload: { mode, id }
	});
};

export const modalErrorValidate = value => async dispatch => {
	dispatch({
		type: MODAL_ERROR_MESSAGE_IMPORT,
		payload: value
	});
};

export const filterCity = data => async dispatch => {
	dispatch({
		type: MODAL_FILTER_CITY,
		payload: data
	});
};
