import { URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function getMerchandisingPerCompany(query, cancelToken) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/reports/shelf-audits/merchandising-per-company${query}`,
		cancelToken
	});
}

export async function exportMerchandisingPerCompany(query) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios.post(`${PATH}/export${query}`, {
		output: 'Excel',
		kind: {
			type: 'Reports',
			kind: 'Merchandising'
		}
	});
}
