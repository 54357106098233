import {
	CREATE_CATEGORY_ERROR,
	CREATE_CATEGORY_REQUEST,
	CREATE_CATEGORY_SUCCESS,
	DETAIL_CATEGORY_ERROR,
	DETAIL_CATEGORY_REQUEST,
	DETAIL_CATEGORY_SUCCESS,
	FETCH_CATEGORY_ERROR,
	FETCH_CATEGORY_REQUEST,
	FETCH_CATEGORY_SUCCESS,
	SEARCH_CATEGORY,
	SORT_CATEGORY,
	UPDATE_CATEGORY_ERROR,
	UPDATE_CATEGORY_REQUEST,
	UPDATE_CATEGORY_SUCCESS
} from '../actions/category.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	current: null,
	data: [],
	dataDetail: [],
	detail: [],
	search: null,
	sortField: null,
	sort: 'asc',

	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	}
};

export default function BrandReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_CATEGORY_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_CATEGORY_SUCCESS:
			return {
				...state,
				parent:
			action.payload.data && action.payload.data.parent
				? action.payload.data.parent
				: [],
				data:
			action.payload.data && action.payload.data.data
				? action.payload.data.data
				: [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_CATEGORY_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DETAIL_CATEGORY_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_CATEGORY_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_CATEGORY_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case CREATE_CATEGORY_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_CATEGORY_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};
		case CREATE_CATEGORY_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_CATEGORY_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_CATEGORY_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_CATEGORY_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SEARCH_CATEGORY:
			return {
				...state,
				search: action.payload
			};

		case SORT_CATEGORY:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};

		default:
			return state;
	}
}
