import * as React from 'react';
import { HorizontalCardStyle } from './model/Model';
import { CardBlock, Container } from './style/Style';

function HorizontalCard(props: {
  contents: JSX.Element[]
  withBorder?: boolean
  horizontalCardStyle?: HorizontalCardStyle
  isHeader?: boolean
}): JSX.Element {
	return (
		<Container
			height={props.horizontalCardStyle?.height}
			withBorder={props.withBorder}
			backgroundColor={props.horizontalCardStyle?.backgroundColor}
			margin={props.horizontalCardStyle?.margin}
		>
			<CardBlock padding={props.isHeader ? '0 10px' : '10px'}>
				{
					props.contents.map(content => {
						return (
							content
						);
					})
				}
			</CardBlock>
		</Container>
	);
}

export default HorizontalCard;
