import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import styled from 'styled-components';
import Avatar from '../../../components_v2/avatar/Avatar';
import { PaginationResult } from '../../../components_v2/pagination/model/Model';
import Pagination from '../../../components_v2/pagination/Pagination';
import { Column, Table } from '../../../components_v2/table/Table';
import storeLang from '../../../helpers/storeLang';
import { GreenSidely } from '../../../styles/global/css/Utils';
import { Translate, translateToString } from '../../../styles/global/translate';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { getMembers } from '../action';
import { DEFAULT_STEP, MemberRow } from '../model';
import { ProfilePic, RightContainer, TableRow, UserPhoto } from '../style';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import Restricted from '../../permissions/Restricted';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { TableRowTitle } from '../../orders/templateOrders/style/Style';

export enum OpenMode {
  NONE,
  NAME,
  EMAIL,
  PHONE,
  GROUPS
}

const MemberStatus = styled.div<{ active: boolean }>`
	border-radius: 4px;
	padding: 0.25em;
	color: white;
	min-width: 6em;
	text-align: center;
	background-color: ${p => p.active ? GreenSidely : '#DADADA'};
`;

const Button = styled(DefaultButton)`
	margin: 0.5em;
`;

export default function Members(props: {
	setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [members, setMembers] = React.useState<MemberRow[]>([]);
	const [pagination, setPagination] = React.useState<PaginationResult>({ currentPage: 1, offset: 0, step: DEFAULT_STEP });

	const translate = getTranslate(storeLang.getState().localize);

	React.useEffect(() => {
		props.setToolBarState({
			bottomRightToolbarComponent: <Restricted to={{ objectAction: 'UpdateAccountSettings' }}>
				<>
					<Button
						buttonStyle={ButtonStyle.Default}
						onClick={() => window.open('https://share-eu1.hsforms.com/1ssVkenwpSDmDdryffBsesgfgz7f', '_blank')?.focus()}
					>
						<Translate id='create_user' />
					</Button>
					<Button
						buttonStyle={ButtonStyle.White}
						onClick={() => window.open('https://share-eu1.hsforms.com/1zK9Wo-nkSt2Skl3BRrODcgfgz7f', '_blank')?.focus()}
					>
						<Translate id='swap_user' />
					</Button>
					<Button
						buttonStyle={ButtonStyle.White}
						onClick={() => window.open('https://share-eu1.hsforms.com/1NhlVLEAmRbmSeK0tNMyvcAfgz7f ', '_blank')?.focus()}
					>
						<Translate id='desactivate_user' />
					</Button>
				</>
			</Restricted>
		});
	}, []);

	const getUsers = async() => await getMembers(pagination.step, pagination.offset)
		.then(res => setMembers(res.data))
		.catch(console.log);

	React.useEffect(() => {
		getUsers();
	}, [pagination]);

	const columns: Array<Column<MemberRow>> = React.useMemo(
		() => [
			{
				id: 'photo',
				Header: ' ',
				accessor: (row: MemberRow) => row.photo
					? <ProfilePic>
						<UserPhoto src={row.photo} />
					</ProfilePic>
					: <Avatar userId={row.id} name={row.name} width={'30px'} fontSize={'13px'} />,
				width: 51,
				disableSortBy: true,
				unresizeable: true
			},
			{
				id: 'name',
				Header: translate('admin.table.name') as string,
				accessor: (row: MemberRow) => <TableRowTitle>{row.name}</TableRowTitle>,
				width: undefined
			},
			{
				id: 'email',
				Header: translate('admin.table.email') as string,
				accessor: (row: MemberRow) => <TableRow>{row.email}</TableRow>,
				width: undefined
			},
			{
				id: 'phone',
				Header: translate('admin.table.phone') as string,
				accessor: (row: MemberRow) => <TableRow>{row.phone}</TableRow>,
				width: undefined
			},
			{
				id: 'groups',
				Header: translate('admin.title') as string,
				accessor: (row: MemberRow) => {
					let display = row.group_info?.[0]?.[1] ?? '';
					if (row.group_info?.length > 1) {
						if (display.length > 15) {
							display = display.substring(0, 15) + '...';
						}
						display += ', +' + (row.group_info.length - 1);
					} else if (display.length > 20) {
						display = display.substring(0, 15) + '...';
					}

					if (row.is_owner) {
						return <TableRow>-</TableRow>;
					}

					return <TableRow>{display}</TableRow>;
				},
				width: undefined
			},
			{
				id: 'status',
				Header: translate('admin.table.status') as string,
				accessor: (row: MemberRow) => <MemberStatus active={row.status}>{translateToString(row.status ? 'active' : 'inactive')}</MemberStatus>,
				width: undefined
			}
		],
		[members]
	);

	// function getSelectedValue(mode: OpenMode): string {
	// 	switch (mode) {
	// 		case OpenMode.NAME:
	// 			return selectedValue?.name ?? '';
	// 		case OpenMode.EMAIL:
	// 			return selectedValue?.email ?? '';
	// 		case OpenMode.PHONE:
	// 			return selectedValue?.phone ?? '';
	// 		default:
	// 			return '';
	// 	}
	// }

	// function getTextId(mode: OpenMode): string {
	// 	switch (mode) {
	// 		case OpenMode.NAME:
	// 			return 'name';
	// 		case OpenMode.EMAIL:
	// 			return 'email';
	// 		case OpenMode.PHONE:
	// 			return 'phone';

	// 		default:
	// 			return '';
	// 	}
	// }

	return (
		<RightContainer>
			{/* <Restricted to={{ objectAction: 'UpdateAccountSettings' }}>
				<Popup
					isOpen={getOpenMode != OpenMode.NONE}
					hasBackground
					onClickOut={() => setOpenMode(OpenMode.NONE)}
					popupStyle={{ width: '35%', height: '25%', left: '32%', top: '37%' }}
					content={<EditMemberField defaultValue={getSelectedValue(getOpenMode)} openMode={getOpenMode} onSave={(value) => {
						onCellInput(value, getTextId(getOpenMode), selectedValue);
						setOpenMode(OpenMode.NONE);
					}} />}
				/>
			</Restricted> */}
			<Table
				noBorder
				height='calc(100vh - 174px)'
				columns={columns}
				data={members}
				onCellClicked={(value) => {
					const row = members.find(m => m.id === value.row.original.id);
					if ((row == null) || !value.column.editable) { return; }
					const newRow = { ...row };
					newRow.edit = value.column.id;
					setMembers(members.map(m => m.id == row.id ? newRow : m));
				}}
			/>
			<Pagination
				label={'Users'}
				amount={members[0]?.count ?? 0}
				steps={[DEFAULT_STEP, 100, 200, 500]}
				onChange={(value) => setPagination(value)}
			/>
		</RightContainer>
	);
}
