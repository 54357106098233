import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {Translate} from 'react-localize-redux';
import {withRouter} from 'react-router';
import {renderField, renderSelectInputIcon, required} from '../../../components/FieldModal';
import {normalizeCapitalize} from '../../../helpers/fieldMask';
import {createData, createIcon, fetchIcons} from '../../../store/actions/formbuilder.action';
import UploadIcon from './UploadIcon';

class ModalCreateForm extends Component {
  state = {
    params: {
      page: 1,
      limit: 100
    },
    isModalImage: false,
    formIcon: null
  };

  componentDidMount() {
    this.props.fetchIcons(this.state.params);
  }

  onSubmit = val => {
    const { formbuilder, history } = this.props;
    const isDuplicate = !!formbuilder.duplicateForm.id;
    const { id } = formbuilder.duplicateForm;

    const data = {
      name: val.name,
      icon_id: val.icon.value,
      user_form_fields: []
    };

    localStorage.setItem('formTitle', JSON.stringify(data));
    if (isDuplicate) {
      history.push(`/formBuilder/duplicate/${id}`);
    } else {
      history.push('/formBuilder/create');
    }
  };

  onAddClick = () => {
    this.setState({
      isModalImage: true
    });
  };

  onToggleImg = () => {
    this.setState({
      isModalImage: !this.state.isModalImage
    });
  };

  loadFormIcon = formIcon => {
    this.setState({
      formIcon
    });
  };

  render() {
    const { handleSubmit, formbuilder, icons, createIcon } = this.props;
    const isDuplicate = !!formbuilder.duplicateForm.id;
    const formName = formbuilder.duplicateForm.name;

    const options = icons.map(icon => {
      const result = {
        label: icon.name,
        icon: <img src={icon.icon_url} width="20" alt="" />,
        value: icon.icon_id
      };
      return result;
    });

    return (
      <Fragment>
        <Modal
          centered
          toggle={this.onToggleImg}
          isOpen={this.state.isModalImage}
          style={{ maxWidth: 200 }}
        >
          <ModalHeader toggle={this.onToggleImg}>New Icon</ModalHeader>
          <ModalBody className="text-center">
            <UploadIcon loadFormIcon={this.loadFormIcon} />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              loading={formbuilder.isLoadingCreateIcon}
              spinColor="#003d79"
              onClick={() => {
                createIcon(this.state.formIcon);
                this.setState({
                  isModalImage: false
                });
              }}
            >
              {!formbuilder.isLoading && <Translate id="save" />}
            </Button>
          </ModalFooter>
        </Modal>
        <Translate>
          {({ translate }) => (
            <Modal
              isOpen={this.props.isOpen}
              toggle={this.props.toggle}
              className={this.props.className}
              centered
            >
              <ModalHeader toggle={this.props.toggle}>
                {`${
                  isDuplicate
                    ? `${translate('duplicate')} ${formName} Form`
                    : translate('new form')
                }`}
              </ModalHeader>
              <ModalBody>
                <form
                  className="forms-sample background-form"
                  onSubmit={handleSubmit(this.onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-11">
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="name"
                            type="text"
                            component={renderField}
                            label={translate('name')}
                            placeholder={translate('enter name')}
                            normalize={normalizeCapitalize}
                            requiredStar
                            validate={[required]}
                          />
                          <Field
                            name="icon"
                            type="text"
                            component={renderSelectInputIcon}
                            label={translate('Icon')}
                            onAddClick={this.onAddClick}
                            options={options}
                            placeholder={translate('select icon')}
                            requiredStar
                            validate={[required]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4" />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={handleSubmit(this.onSubmit)}
                  loading={formbuilder.isLoading}
                  spinColor="#003d79"
                >
                  {!formbuilder.isLoading && <Translate id="save" />}
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </Translate>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.contacts.isLoadingSubmit,
  formbuilder: state.formbuilder,
  icons: state.formbuilder.icons
});

export default reduxForm({
  form: 'modalCreateForm'
})(
  connect(mapStateToProps, { createData, fetchIcons, createIcon })(
    withRouter(ModalCreateForm)
  )
);
