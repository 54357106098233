import { atom, RecoilState, selector } from 'recoil';
import { AtomCategory, AtomState } from '../utils/model/Model';
import { EventQuery } from '../../containers_v2/calendar/EventsApi';

export interface AProspectionFlowType {
  apiQuery?: EventQuery
  isOpen: boolean,
}

export const AGlobalProspectionFlow: AtomState<AProspectionFlowType> = {
	category: AtomCategory.GLOBAL,	
	atom: atom({
		key: 'atom_global_prospection_flow',
		default: { isOpen: false }
	})
};

export const AProspectionFlow: RecoilState<AProspectionFlowType> = selector({
	key: 'atom_prospection_flow',
	get: () => {
		return AGlobalProspectionFlow.atom;
	},
	set: ({ set }, newValue) => {
		set(AGlobalProspectionFlow.atom, newValue);
	}
});