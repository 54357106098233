import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { ARefresh } from '../../../atoms/global/refresh';
import Avatar from '../../../components_v2/avatar/Avatar';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import storeLang from '../../../helpers/storeLang';
import { Loader } from '../../../styles/global/css/GlobalLoader';
import { Open } from '../../../styles/global/css/Open';
import { BlueSidely, DarkGreySidely, GreenSidely, RedSidely } from '../../../styles/global/css/Utils';
import { UserToolTip } from '../../../styles/global/ToolTip';
import PopupEvent from '../../calendar/popup/Event';
import { LoadingStateEnum } from '../../import/model';
import NoData from '../../noData/NoData';
import PopupNote from '../../notes/popup/PopupNote';
import { Owner } from '../../orders/model/Model';
import { FlexDiv } from '../../products/style';
import { CompanyTimeline, TimelineElement } from '../model/Model';
import {
	BodyBlock,
	BodyMainImage,
	BodyMainLine,
	BodyMainLoaderContainer,
	BodyMainPoint,
	BodyMainText,
	ContainerPoint,

} from '../style/PopupStyle';
import {
	Span,
	TimelineBodyMainContainer,
	TimelineContainer,
	TimelineHeader,
	TimelineHeaderLine, 
	TimelineCollapse,
	TimelineDate,
} from '../style/TimelineStyle';
import { updateEvent } from './action';
import OpportunityWhiteImage from 'images/menu_icon/opportunity_white.svg';
import ContactWhiteImage from 'images/menu_icon/contact_white.svg';
import CalendarBlackImage from 'images/menu_icon/calendar_black.svg';
import OrderBoxWhiteImage from 'images/menu_icon/order_box_white.svg';
import FieldReportWhiteImage from 'images/menu_icon/fieldReport_white.svg';
import EditWhiteImage from 'images/icon/edit_white.png';
import WheelImage from 'images/menu_icon/wheel.svg';
import FreeFormImage from 'images/menu_icon/free_form.svg';
import DocumentImage from 'images/menu_icon/Document.svg';
import PinBlackImage from 'images/icon/map/pin_black.svg';
import WarningImage from 'images/icons/import/warning.svg';
import { ModalState } from '../../products/model';
import ContactPopup from '../../contact/popup/ContactPopup';
import { translateToString } from '../../../styles/global/translate';
import { getCompanyTimeline } from '../data/action';
import { ITag } from 'proto/protobufs';
import { getTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';


function Timeline(props: {
	clientCompanyId: number,
	isExtend: boolean,
	onRefresh: (element?: TimelineElement, owner?: Owner, newSearch?: string) => void,
	onTimelineScroll: (newValues: CompanyTimeline[]) => void,
	onNoteDelete: (id: number) => void,
	limit: number,
	timeline: CompanyTimeline[],
	owners: Owner[],
	onDisableClickOut: (b: boolean) => void
	toggleShelfAuditPopup: (id: number, full) => void,
	toggleFormPopup: (uuid: string) => void
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	const [offset, setOffset] = React.useState<number>(0);
	const [timelineFinished, setTimelineFinished] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [bottomLoading, setBottomLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [selectedOwner, setSelectedOwner] = React.useState<Owner>();

	const [search] = React.useState<string>();
	const [selectedElement, setSelectedElement] = React.useState<TimelineElement>();
	const [isNoteOpen, setIsNoteOpen] = React.useState<[boolean, number | undefined]>([false, undefined]);
	const [isEventOpen, setIsEventOpen] = React.useState<[boolean, number | undefined]>([false, undefined]);
	const [isContactOpen, setIsContactOpen] = React.useState<ModalState<number>>({ isOpen: false });
	const [tags, setTags] = React.useState<ITag[]>([]);
	const [monthOpen, setMonthOpen] = React.useState<{id: string, value: boolean}[]>([]);

	const refreshingAtom = useRecoilValue(ARefresh);

	React.useEffect(() => {
		if (refreshingAtom.timeline !== undefined) props.onRefresh(selectedElement, selectedOwner, search);
	}, [refreshingAtom.timeline]);

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADED);

		props.timeline.forEach(t => {
			const dateTimeline = moment(t.created_at).toDate();
			const key = `${dateTimeline.getFullYear()}_${dateTimeline.getMonth()}`;
			if (monthOpen.find(m => m.id === key) === undefined) {
				setMonthOpen([...monthOpen, { id: key, value: true }]);
			}
		});
	}, [props.timeline]);


	React.useEffect(() => {
		getTags(TagType.CONTACT).then(setTags);
	}, []);


	function getActionTypeProperty(timeline: CompanyTimeline): { color: string, img: string, msg: string, url?: string, noname: string, onClick?: () => void, hideName?: boolean, title?: string } {
		switch (timeline.action_type) {
			case 'OPPORTUNITY':
				return { color: '#FFB946', img: OpportunityWhiteImage, msg: translate('company.detail.timeline.opportunity_created').toString(), url: `opportunities/detail/${timeline.id}`, noname: translate('company.detail.timeline.noname').toString() };
			case 'CONTACT':
				return { color: GreenSidely, img: ContactWhiteImage, msg: translate('company.detail.timeline.contact_created').toString(), onClick: () => setIsContactOpen({ isOpen: true, data: timeline.id }), noname: translate('company.detail.timeline.noname').toString() };
			case 'EVENT':
				return { color: BlueSidely, img: CalendarBlackImage, msg: `${translate('company.detail.timeline.event_created').toString()}${timeline.sub_title && timeline.sub_title.length > 0 ? ` (${translate(`company.detail.timeline.${timeline.sub_title.toLowerCase()}`).toString()}) ` : ''}`, onClick: () => { setIsEventOpen([true, timeline.id]); props.onDisableClickOut(true); }, noname: translate('company.detail.timeline.noname').toString() };
			case 'ORDER':
				return { color: '#B9EF45', img: OrderBoxWhiteImage, msg: translate('company.detail.timeline.order_created').toString(), url: `/orders?id=${timeline.id}`, noname: translate('company.detail.timeline.noname').toString() };
			case 'SHELF_AUDIT':
				return { color: RedSidely, img: FieldReportWhiteImage, msg: translate('company.detail.timeline.shelf_audit_created').toString(), onClick:() => props.toggleShelfAuditPopup(timeline.id!, false), noname: timeline.sub_title ?? translate('company.detail.timeline.noname').toString() };
			case 'NOTE':
				return { color: '#FFEA2C', img: EditWhiteImage, msg: translate('company.detail.timeline.note_created').toString(), onClick: () => setIsNoteOpen([true, timeline.id]), noname: translate('company.detail.timeline.noname').toString() };
			case 'STATUS_CHANGE':
				return { color: '#29CF61', img: WheelImage, msg: translate('company.detail.timeline.status_changed').toString(), noname: translate('company.detail.timeline.noname').toString() };
			case 'FREE_FORM':
				return { color: '#FEA240', img: FreeFormImage, msg: translate('company.detail.timeline.free_form_created').toString(), noname: translate('company.detail.timeline.noname').toString(), url: `/formBuilder/formData/${timeline.sub_title}/detail/${timeline.id}` };
			case 'CREATED_DOCUMENT':
				return { color: '#FF814B', img: DocumentImage, msg: translate('company.detail.timeline.document_created').toString(), noname: translate('company.detail.timeline.noname').toString(), url: timeline.sub_title };
			case 'CHECK_IN':
				return { color: '#FEC940', img: PinBlackImage, msg: translate('company.detail.timeline.check_in_created').toString(), noname: translate('company.detail.timeline.noname').toString(), hideName: true };
			case 'FORM':
				return { color: RedSidely, img: FreeFormImage, msg: translate('company.detail.timeline.form_created').toString(), noname: translate('company.detail.timeline.noname').toString(), onClick:() => props.toggleFormPopup(timeline.uuid!), title: timeline.sub_title ? `(${translateToString('draft')}) ${timeline.title}` : timeline.title }; 
			default:
				return { color: DarkGreySidely, img: WarningImage, msg: translate('company.detail.timeline.not_defined').toString(), noname: translate('company.detail.timeline.noname').toString() };
		}
	}

	function getMonthString(month: number): string {
		switch (month) {
			case 0:
				return translate('date.january').toString();
			case 1:
				return translate('date.february').toString();
			case 2:
				return translate('date.march').toString();
			case 3:
				return translate('date.april').toString();
			case 4:
				return translate('date.may').toString();
			case 5:
				return translate('date.june').toString();
			case 6:
				return translate('date.july').toString();
			case 7:
				return translate('date.august').toString();
			case 8:
				return translate('date.september').toString();
			case 9:
				return translate('date.october').toString();
			case 10:
				return translate('date.november').toString();
			case 11:
				return translate('date.december').toString();
			default:
				return '';
		}
	}

	async function endScroll(e: HTMLDivElement): Promise<void> {
		if (timelineFinished || bottomLoading === LoadingStateEnum.LOADING) return;
		if (e.offsetHeight + e.scrollTop >= e.scrollHeight) {
			const newOffset = offset + props.limit;
			setOffset(newOffset);
			setBottomLoading(LoadingStateEnum.LOADING);
			const res = await getCompanyTimeline({
				client_company_id: props.clientCompanyId,
				limit: props.limit,
				offset: newOffset,
				element: selectedElement,
				search,
				user_id: selectedOwner?.id
			});
			if (res.length == 0 || res.length < props.limit) setTimelineFinished(true);
			if (res.length !== 0) props.onTimelineScroll(res);
			setBottomLoading(LoadingStateEnum.LOADED);
		}
	}

	let currentDate = props.timeline.length > 0 ? moment(props.timeline[0].created_at).toDate() : moment().toDate();
	let newMonth = true;

	return (
		<TimelineContainer>
			<TimelineHeader isOpen={props.isExtend}>
				<TimelineHeaderLine>
					<Dropdown
						placeholder={translate('tool_bar.brand.all').toString()}
						dropdownStyle={{
							width: '200px',
							optionWidth: '200px',
							margin: '0 10px 0 0',
							height: '35px',
							labelUp: true,
							optionHeight: '200px'
						}}
						readOnly
						cancellable
						name="user_filter"
						datalist={props.owners.map(o => ({ label: o.name, value: o, image: o.photoUrl, imageJsx: o.photoUrl ? undefined : <Avatar backgroundColor={o.color} width={'26px'} name={o.name} /> }))}
						onChange={(value: DropdownData) => {
							setSelectedOwner(value.value);
							setLoading(LoadingStateEnum.LOADING);
							props.onRefresh(selectedElement, value.value, search);
						}}
					/>

					<Dropdown
						placeholder={translate('tool_bar.brand.all').toString()}
						dropdownStyle={{
							width: '200px',
							optionWidth: '200px',
							margin: '0 10px 0 15px',
							height: '35px',
							labelUp: true,
							optionHeight: '200px',
						}}
						readOnly
						cancellable
						name="element_filter"
						datalist={Object.entries(TimelineElement)
							.sort((a, b) => a[1].localeCompare(b[1]))
							.map(v => ({
								label: translate(`company.detail.timeline.${v[1]
									.replace(/(.)([A-Z])/g, '$1_$2').toLowerCase()}`) // ShelfAudit => shelf_audit, StatusChange => status_change
									.toString(), value: v[1] }
							))
						}
						onChange={(value: DropdownData) => {
							setSelectedElement(value.value);
							setLoading(LoadingStateEnum.LOADING);
							props.onRefresh(value.value, selectedOwner, search);
						}}
					/>
				</TimelineHeaderLine>
			</TimelineHeader>
			<TimelineBodyMainContainer onScroll={(e) => endScroll(e.currentTarget)}>
				{
					loading === LoadingStateEnum.LOADING &&
					<Loader center width="30px" />
				}
				{
					loading === LoadingStateEnum.LOADED && props.timeline.length <= 0 &&
					<NoData message={translate('global.nodata.message.company').toString()} messageButton='' urlButton='' />
				}
				{
					loading === LoadingStateEnum.LOADED && props.timeline.map(t => {
						const dateTimeline = moment(t.created_at).toDate();
						if (dateTimeline.getMonth() !== currentDate.getMonth() || dateTimeline.getFullYear() !== currentDate.getFullYear()) {
							currentDate = dateTimeline;
							newMonth = true;
						}

						const key = `${dateTimeline.getFullYear()}_${dateTimeline.getMonth()}`;

						if (newMonth) {
							newMonth = false;
						}
						else {
							return;
						}
						

						let first = true;
						const timeBlocks = props.timeline.map(t2 => {
							if (moment(t2.created_at).format('YYYY-MM') === moment(dateTimeline).format('YYYY-MM')) {
								const property2 = getActionTypeProperty(t2);

								const result = (
									<>
										{
											!first &&
										<BodyMainLine />
										}
										<BodyBlock flexDirection={'row'} isOpen={true}>
											<ContainerPoint color={property2.color}><BodyMainPoint src={property2.img} /></ContainerPoint>
											<BodyBlock isOpen={true} marginLeft="20px" flexDirection="column">
												<BodyMainText marginLeft="0px">{property2.msg}
													{
														property2.hideName ?
															<></>
															:
															<Span noClick={!property2.onClick && !property2.url} onClick={() => (property2.onClick?.()) ?? (property2.url && window.open(property2.url, '_blank'))}>{property2.title ?? (t2.title && t2.title.length > 0 ? t2.title : property2.noname)}</Span>
													}
												</BodyMainText>
												<BodyBlock isOpen={true} flexDirection={'row'}>
													<div style={{ margin: '0 8px 0 0' }}>
														<UserToolTip userId={t2.user_id} left='-34px'>
															{t2.user_photo ? <BodyMainImage src={t2.user_photo} /> : <Avatar width='20px' userId={t2.user_id} name={t2.user_name ?? ''} fontSize='9px' />}
														</UserToolTip>
													</div>
													<BodyMainText marginLeft="0px">{moment.utc(t2.created_at).local().format(`DD/MM/YYYY [${translate('global.at').toString()}] HH:mm`)}</BodyMainText>
												</BodyBlock>
											</BodyBlock>
										</BodyBlock>
									</>
								);
								first = false;
								return result;
							}
							return undefined;
						}).filter(t => t !== undefined);
						

						const block = (
							<>
								<FlexDiv margin='30px 0 10px 0'>
									<Open onClick={() => setMonthOpen(monthOpen.map(m => {
										if (m.id === key) {
											m.value = !m.value;
										}
										return m;
									}))} isOpen={monthOpen.find(m => m.id === key)?.value ?? true} />
									<TimelineDate>{`${getMonthString(currentDate.getMonth())} ${currentDate.getFullYear()}`}</TimelineDate>
								</FlexDiv>
								<TimelineCollapse isOpen={monthOpen.find(m => m.id === key)?.value ?? true}>
									{timeBlocks.map(tb => tb)}
								</TimelineCollapse>
							</>
						);
						return block;
					})
				}
			</TimelineBodyMainContainer>
			{
				bottomLoading === LoadingStateEnum.LOADING &&
				<BodyMainLoaderContainer>
					<Loader center width="30px" />
				</BodyMainLoaderContainer>
			}
			<PopupNote
				isOpen={isNoteOpen[0]} 
				onClickOut={() => setIsNoteOpen([false, undefined])} 
				noteId={isNoteOpen[1]} 
				onDelete={(id) => props.onNoteDelete(id)}	
			/>
			<PopupEvent
				eventId={isEventOpen[1]}
				isOpen={isEventOpen[0]}
				setIsOpen={(b: boolean) => {
					setIsEventOpen([b, undefined]);
					props.onDisableClickOut(b);
				}}
				onValueChange={(values, eventId) => {
					if (!eventId) return;
					const body = {};
					values.forEach(([key, value]) => body[key] = value);
					updateEvent(eventId, body);
				}}
			/>
			<ContactPopup tags={tags} isOpen={isContactOpen.isOpen} setOpen={isOpen => setIsContactOpen({ isOpen })} contactId={isContactOpen.data} />
			{/* <ModalEventForm
				isOpen={isEventOpen[0]}
				mode={ModalMode.View}
				toggleModal={() => {
					setIsEventOpen([false, undefined]);
					props.onDisableClickOut(false);
				}}
				defaultStart={new Date()}
				defaultEnd={new Date()}
				id={isEventOpen[1]}
				readonly
			/> */}
		</TimelineContainer>
	);
}

export default Timeline;