import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { MappingResume } from '../../../../../web-types/mapping';
import { AEventTypes, AtomEventType } from '../../../atoms/global/events';
import { AExternalMappings } from '../../../atoms/global/mappings';
import { creationPopupDropdownStyle } from '../../../containers_v2/client-companies/style/Style';
import { LoadingStateEnum } from '../../../containers_v2/import/model';
import storeLang from '../../../helpers/storeLang';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import Dropdown from '../../dropdown/Dropdown';
import { DropdownData } from '../../dropdown/model/Model';
import PopupCreation from '../../popup/PopupCreation';
import {
	FilterAdditionalValue,
	FilterId,
	isCheckoutEvolutionFilter,
	isExternalIdFilter,
	isFrequencyFilter,
	isLastEventDateFilter,
	isLastFormDateFilter,
	isNextEventDateFilter
} from '../model/Model';
import { Filter } from './FilterList';
import { FilterText } from '../style/AdvancedFilterStyle';
import { BlueSidely } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { Granularity } from 'bindings/time/Granularity';
import { AFormTemplate } from '../../../atoms/forms';

export function filterIdFormater(id: FilterId, additionalValue?: FilterAdditionalValue): FilterId {
	let res: FilterId;
	if (isFrequencyFilter(id) && (typeof additionalValue?.value === 'undefined' || typeof additionalValue?.value === 'number')) {
		res = { frequencies: { ...id.frequencies, event: id.frequencies.event ?? additionalValue?.value } };
	} else if (isLastEventDateFilter(id) && (typeof additionalValue?.value === 'undefined' || typeof additionalValue?.value === 'number')) {
		res = { last_event_date: id.last_event_date === null ? (additionalValue?.value ?? null) : null };
	} else if (isNextEventDateFilter(id) && (typeof additionalValue?.value === 'undefined' || typeof additionalValue?.value === 'number')) {
		res = { next_event_date: id.next_event_date === null ? (additionalValue?.value ?? null) : null };
	} else if (isExternalIdFilter(id) && (typeof additionalValue?.value === 'undefined' || typeof additionalValue?.value === 'number')) {
		res = { external_id: id.external_id === null ? (additionalValue?.value ?? null) : null };
	} else if (isCheckoutEvolutionFilter(id) && (typeof additionalValue?.value === 'undefined' || typeof additionalValue?.value === 'string')) {
		res = { checkout: { ...id.checkout, granularity: id.checkout.granularity ?? additionalValue?.value as Granularity } };
	} else if (isLastFormDateFilter(id) && (typeof additionalValue?.value === 'undefined' || typeof additionalValue?.value === 'number')) {
		res = { last_form_date: !id.last_form_date ? (additionalValue?.value ?? null) : null };
	} else {
		res = id;
	}

	return res;
}

export default function FilterSelection(props: {
	content: JSX.Element,
	filter: Filter,
	loading: LoadingStateEnum,
	onClose?: () => void,
	buttonReturn: () => void,
	title?: string,
	noHeader?: boolean
	overflow?: string
}): JSX.Element {
	const { filter } = props;
	const [selectedValue, setSelectedValue] = React.useState<number | string>();
	const [valueTitleComment, setValueTitleComment] = React.useState<string | JSX.Element>();
	const [step, setStep] = React.useState<boolean>(false);
	const eventTypes = useRecoilValue(AEventTypes);
	const externalMappings = useRecoilValue(AExternalMappings);
	const formTemplates = useRecoilValue(AFormTemplate);
	const translate = getTranslate(storeLang.getState().localize);
	let jsx: JSX.Element = React.cloneElement(
		props.content,
		{ additionalValue: {
			value: selectedValue,
			titleComment: valueTitleComment
		} }
	);
	
	if (((isFrequencyFilter(filter.id) && filter.id.frequencies.event === undefined)
		|| (isLastEventDateFilter(filter.id) && filter.id.last_event_date === null)
		|| (isNextEventDateFilter(filter.id) && filter.id.next_event_date === null)
	)
		&& !step) {
		jsx = <div style={{ padding: '0 1em' }}>
			<FilterText margin='0 0 5px 0' color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.buttonReturn()}>&lt;<Translate id='back' /></FilterText>
			<Dropdown<AtomEventType>
				datalist={eventTypes.map(c => ({ value: c, label: translate(`event.${c.name}`).toString() }))}
				dropdownStyle={creationPopupDropdownStyle}
				name={''}
				onChange={(value: DropdownData<AtomEventType>) => {
					setSelectedValue(value.value.id);
					setValueTitleComment(value.label);
				}}
			/>
			<DefaultButton width="125px" margin='20px 0' disabled={!selectedValue} onClick={() => setStep(true)}><Translate id='continue' /></DefaultButton>
		</div>;
	} else if (isExternalIdFilter(filter.id) && filter.id.external_id === null && !step) {
		const filteredList = externalMappings.filter(em => em.mapping_type_id === 1);
		if (filteredList.length == 1) {
			setSelectedValue(filteredList[0].id);
			setValueTitleComment(filteredList[0].name);
			setStep(true);
		} else {
			jsx = <div style={{ padding: '0 1em' }}>
				<FilterText margin='0 0 5px 0' color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.buttonReturn()}>&lt;<Translate id='back' /></FilterText>
				<Dropdown<MappingResume>
					datalist={filteredList.map(c => ({ value: c, label:c.name }))}
					dropdownStyle={creationPopupDropdownStyle}
					name={''}
					onChange={(value: DropdownData<MappingResume>) => {
						setSelectedValue(value.value.id);
						setValueTitleComment(value.label);
					}}
				/>
				<DefaultButton width="125px" margin='20px 0' disabled={!selectedValue} onClick={() => setStep(true)}><Translate id='continue' /></DefaultButton>
			</div>;
		}
	} else if (isCheckoutEvolutionFilter(filter.id) && !filter.id.checkout.granularity && !step) {
		jsx = <div style={{ padding: '0 1em' }}>
			<FilterText margin='0 0 5px 0' color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.buttonReturn()}>&lt;<Translate id='back' /></FilterText>
			<Dropdown
				datalist={['month', 'quarter', 'year'].map(g => ({ label: translateToString(g), value: g }))}
				dropdownStyle={creationPopupDropdownStyle}
				name={''}
				onChange={value => {
					setSelectedValue(value.value);
					setValueTitleComment(value.label);
				}}
			/>
			<DefaultButton width="125px" margin='20px 0' disabled={!selectedValue} onClick={() => setStep(true)}><Translate id='continue' /></DefaultButton>
		</div>;
	} else if (isLastFormDateFilter(filter.id) && !filter.id.last_form_date && !step) {
		jsx = <div style={{ padding: '0 1em' }}>
			<FilterText margin='0 0 5px 0' color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.buttonReturn()}>&lt;<Translate id='back' /></FilterText>
			<Dropdown
				datalist={formTemplates.map(g => ({ label: g.name, value: g }))}
				dropdownStyle={creationPopupDropdownStyle}
				name={''}
				onChange={value => {
					setSelectedValue(value.value.id);
					setValueTitleComment(value.label);
				}}
			/>
			<DefaultButton width="125px" margin='20px 0' disabled={!selectedValue} onClick={() => setStep(true)}><Translate id='continue' /></DefaultButton>
		</div>;
	}

	return <PopupCreation
		noHeader={props.noHeader}
		title={props.title ?? translate('advanced_filters').toString()}
		content={jsx}
		loading={props.loading}
		onClose={props.onClose}
		hideValidation
		overflow={props.overflow}
	/>;
}