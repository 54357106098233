/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {required} from '../../Field';
import FieldDropzoneExcel from '../../dropzone/FieldDropzoneExcel';
import {Translate} from 'react-localize-redux';

// import { createCompanyFile } from '../../../store/actions/companies.action';

class ModalCreateDocument extends Component {
  state = {};

  onSubmit = value => {
    const { onImport } = this.props;
    const formData = new FormData();

    const keys = ['documents'];

    keys.map(key => {
      value[key] && formData.append(key, value[key].value || value[key]);
    });

    onImport(formData);
  };

  render() {
    const { handleSubmit, isLoading } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          style={{ borderBottom: 'none' }}
        >
          Import Data
        </ModalHeader>
        <ModalBody>
          <form className="forms-sample" onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              name="documents"
              label="Upload Excel"
              id="importExcel"
              component={FieldDropzoneExcel}
              requiredStar
              validate={[required]}
            />
          </form>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <Button
            color="primary"
            className="px-5 mb-2"
            onClick={handleSubmit(this.onSubmit)}
            loading={isLoading}
          >
            {!isLoading && <Translate id="save" />}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.companies.isLoading
});

export default reduxForm({
  form: 'modalImportForm'
})(
  connect(
    mapStateToProps,
    {}
  )(ModalCreateDocument)
);
