import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BorderColor, LightBlueSidely } from '../../../styles/global/css/Utils';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    width:100%;
	margin-top:15px;
`;
export const Text = styled.div`
    ${DefaultText};
	width:100%;
	padding:10px;
	border:1px solid ${BorderColor};
	border-radius:5px;
	outline:none;
	line-height:24px;
	height:calc(100vh - 470px);
	overflow-y:auto;
	word-break:break-word;
`;

interface VariableTextButtonProps {
	color: string;
}

export const VariableTextButton = styled.button<VariableTextButtonProps>`
	filter: brightness(1);
	cursor: pointer;
	border-radius: 4px;
	border-style: none;
	padding: 3px 10px;
	color: white;
	background-color: ${p => p.color ?? ''};
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 0.01em;

	&:hover {
		color:white;
		background-color:${p => p.color};
	}
`;
export const VariablesContainer = styled.div`
	${DefaultText};
	padding:10px;
	border:1px solid ${BorderColor};
	border-radius:5px;
	margin-bottom:10px;
	display:flex;
	gap:5px;
`;


export const EmailTemplatePopupContainer = styled.div`
	position:relative;
	z-index:1;
`;

interface ListProps {
	top?: string;
	right?: string;
}
export const List = styled.div<ListProps>`
	position:absolute;
	border: 1px solid ${BorderColor};
	border-radius: 4px;
	background-color:white;
	height:fit-content;
	overflow-y:auto;
	width:180px;
	box-shadow: rgb(207 207 207) 0px 1px 5px;
	top:${p => p.top ?? '10px'};
	right:${p => p.right ?? '-30px'};
`;


interface ItemProps {
	borderBottom?: boolean;
	pointer?: boolean;
	ellipsis?: boolean;
	color?: string;
	fontWeight?: string;
}
export const Item = styled.div<ItemProps>`
	${DefaultText};
	font-size: 12px;
	padding: 5px 10px;
	max-width:180px;
	cursor: ${p => p.pointer ? 'pointer' : '' };
	border-bottom: ${p => p.borderBottom ? `1px solid ${BorderColor}` : ''};
	white-space: normal;
	font-weight: ${p => p.fontWeight ?? ''};
	color: ${p => p.color ?? ''};
	
	${p => p.ellipsis
		? `
		white-space: nowrap;
		overflow-x:hidden;
		text-overflow:ellipsis;
		` : ''
};
	
	&:hover {
		background-color: ${LightBlueSidely};
	}
`;


export const NoAvailableEmailTemplate = styled.div`
	white-space:nowrap;
`;

