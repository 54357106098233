import * as React from 'react';
import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { HierarchyData } from './ProductModalLeft';

const Label = styled.p`
	${DefaultText}
	margin-left: 1rem;
	color: #4f4f4f;
	font-weight: 400;
`;

interface Node {
	data: HierarchyData
	isOpen: boolean
	isVisible: boolean
	margin: number
	children: Node[]
}

export default function RenderCheckboxHierarchy(props: {
	list: HierarchyData[],
	onReset?: boolean
	onChange: (list: HierarchyData[]) => void
}) {
	const defaultMargin = 2;
	const [tree, setTree] = React.useState<Node[]>(convertToTree(props.list, defaultMargin));

	React.useEffect(() => {
		if (tree.length <= 0) {
			setTree(convertToTree(props.list, defaultMargin));
		}
	}, [props.list]);

	React.useEffect(() => {
		if (props.onReset) {
			tree.forEach(t => {
				onClickCheckboxHierarchy(t, true);
			});
		}
	}, [props.onReset]);

	function convertToTree(list: HierarchyData[], margin: number, isVisible = true): Node[] {
		const result = list.map(l => ({
			data: l,
			isOpen: false,
			isVisible,
			margin,
			children: l.children ? convertToTree(l.children, (margin + 1), false) : []
		} as Node));

		return result;
	}

	function update(nodes: Node[], node: Node, updateChange = true, level = 1): Node[] {
		const newNodes = nodes.map(n => {
			if (n.data.id === node.data.id) {
				return node;
			} 
			else {
				n.children = update(n.children, node, updateChange, (level + 1));
			}
			return n;
		});

		if (level === 1) {
			setTree(newNodes);
			if (updateChange) {
				props.onChange(newNodes.map(n => n.data));
			}
		}
		return newNodes;
	}

	function onClickCheckboxHierarchy(node: Node, value: boolean, level = 1): Node {
		node.data.checked = value;
		node.children = node.children.map(c => onClickCheckboxHierarchy(c, value, (level + 1)));
		if (level === 1) {
			update(tree, node);
		}
		return node;
	}

	function setChildrenVisible(node: Node, isOpen: boolean, level = 1): Node {
		if (isOpen) {
			node.children = node.children.map(c => {
				c.isVisible = true;
				return c;
			});
		}
		else {
			if (level > 1) {
				node.isOpen = false;
				node.isVisible = false;
			}
			node.children = node.children.map(c => {
				c.children = c.children.map(c2 => setChildrenVisible(c2, false, (level + 1)));
				c.isVisible = false;
				c.isOpen = false;
				return c;
			});
		}
		return node;
	}

	function getFilterJsx(node: Node, i: number): JSX.Element | undefined {
		if (!node.isVisible) {
			return;
		}

		const haveChildren = node.children.length > 0;

		return (
			<div key={`Filter[${node.data.id}][${i}]`}>
				<div className="my-2 check-sidely px-2 d-flex">
					<label className={'mr-3 my-2 ml-' + node.margin} >
						<input
							type="checkbox"
							name={node.data.label}
							className="mr-3"
							checked={node.data.checked}
							onChange={e => {
								onClickCheckboxHierarchy(node, !node.data.checked);
							}}
						/>
						<div className="checkbox-style" />
					</label>

					<Label
						className={`mt-1 mb-0 ${haveChildren && 'pointer'}`}
						onClick={() => {
							if (haveChildren) {
								node.isOpen = !node.isOpen;
								node = setChildrenVisible(node, node.isOpen);
								update(tree, node, false);
							}
						}}
					>
						{
							haveChildren && <i className={'fas mr-2 fa-caret-' + (node.isOpen ? 'down' : 'right')}></i>
						}

						{node.data.label}
					</Label>
				</div>
				{
					node.children.map((c, i) => getFilterJsx(c, i))
				}
			</div>
		);
	}

	return (
		<div>
			{
				tree.map((t, i) => getFilterJsx(t, i))
			}
		</div>
	);
}
