import styled from 'styled-components';
import { BlueSidely } from '../../../styles/global/css/Utils';

interface SwitchProps {
  isActive: boolean
  size: string
}

const Container = styled.div<SwitchProps>`
    width: 100%;
    min-width: calc(2 * ${p => p.size});
    max-width: calc(2 * ${p => p.size});
    min-height: calc(${p => p.size} + 2px);
    max-height: calc(${p => p.size} + 2px);
    margin-top: 2px;
    transition-duration: 300ms;
    transition-property: border background-color;
    border-radius: 500px;
    background-color: ${p => p.isActive ? BlueSidely : '#BEBEBE'};
    border: 1px solid ${p => p.isActive ? BlueSidely : '#BEBEBE'};
    cursor: pointer;
`;

const Round = styled.div<SwitchProps>`
    width: 100%;
    transition-duration: 300ms;
    transition-property: background-color margin-left;
    background-color: white;
    border-radius: ${p => p.size};
    width: ${p => p.size};
    height: ${p => p.size};
    margin-left: calc(${p => p.isActive ? `100% - ${p.size}` : '0px'});
`;

export {
	Container,
	Round
};
