import React, {Component} from 'react';
import Select from 'react-select';
import {customStyles} from '../../helpers/styleSelect';
import CustomOption from './CustomOption';

class RenderSelectInputNoLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBlur = e => {
    const blurHandler = this.props.onBlur;
    if (blurHandler) {
      blurHandler({
        type: 'blur',
        target: {
          value: e.target.value
        }
      });
    }
  };

  render() {
    const {
      input,
      options,
      name,
      id,
      label,
      noLabel,
      disabled,
      placeholder,
      requiredStar,
      meta: { touched, error, warning }
    } = this.props;
    return (
      <div>
        <div className="form-group row">
          {/* {!noLabel && (
            <div className="col-md-2">
              <label htmlFor={id}>
                {label}
                {requiredStar === true ? (
                  <span className="text-required"> *</span>
                ) : (
                  ''
                )}
              </label>
            </div>
          )} */}
          <div className="col p-0">
            <Select
              {...input}
              id={id}
              name={name}
              isSearchable
              isDisabled={disabled}
              className={` ${touched && error ? 'focus-error-select' : ''}`}
              styles={customStyles}
              placeholder={placeholder}
              optionClassName="needsclick"
              options={options}
              components={{ Option: CustomOption }}
              value={
                input.value &&
                options.find(option => option.value === input.value)
              }
              isClearable
              onChange={value => {
                input.onChange(value);
              }}
              onBlur={this.onBlur}
            />
          </div>
          {touched &&
            ((error && error !== 'Required' && (
              <span className="form-error">{error}</span>
            )) ||
              (warning && <span className="form-error">{warning}</span>))}
        </div>
      </div>
    );
  }
}

export default RenderSelectInputNoLabel;
