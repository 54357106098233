import { DashboardContext, DEFAULT_LAYOUTS } from '../../Dashboard';
import { Translate, translateToString } from '../../../../styles/global/translate';
import Input from '../../../../components_v2/input/Input';
import DropdownOwners from '../../../../components_v2/dropdown/DropdownOwners';
import { DropdownSearchCompanies } from '../../../../components_v2/dropdown/DropdownSearch';
import Dropdown from '../../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../../components_v2/dropdown/model/Model';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../../components_v2/popup/PopupCreation';
import * as React from 'react';
import {
	All_EXPIRATION, BottomBar, CreationPopupTitle, DashboardPopupContext, DASHBOARD_CREATION_DROPDOWN_STYLE,
	DASHBOARD_INPUT_STYLE, Expiration, PopupContainer, CreatorProps,
} from '../DashboardPopup';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../atoms/global/users';
import { useContext } from 'react';
import DropdownTagsCloudInDashBoardPopup from '../DropDownTagsCloudInDashboardPopup';


export default function ParentDnCreator(props: CreatorProps) {
	const users = useRecoilValue(AUsers);
	const { onSave, handleBack, activeTab } = props;
	const { currentItem } = useContext(DashboardContext)!;
	const {
		user,
		setUser,
		title,
		setTitle,
		brands,
		brand,
		setBrand,
		granularityForDn,
		setGranularityForDn,
		company,
		setCompany,
		expiration,
		setExpiration,
		changedItems,
		setChangedItems,
		selectedTags,
	} = useContext(DashboardPopupContext)!;


	return <>
		<PopupContainer isCreator>
			<CreationPopupTitle>{`${translateToString('parameter')} ${translateToString('detention')}`}</CreationPopupTitle>
			<Input name='title' type='text' value={title} onChange={setTitle} inputStyle={DASHBOARD_INPUT_STYLE} label={translateToString('title')}/>
			<DropdownOwners
				border
				dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
				label={translateToString('users')}
				users={users.map(value => ({ value, label: value.name }))}
				onChange={setUser}
				selected={user}
				cancellable
			/>
			<DropdownSearchCompanies
				name='parent-company-selector'
				parent dropdownStyle={{ labelUp: true }}
				label={translateToString('Parent company')}
				onChange={setCompany}
				selectedValue={company}
				placeholder={translateToString('all')}
			/>
			<Dropdown
				dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
				name=''
				datalist={brands.map(b => ({ value: b, label: b.name }))}
				label={translateToString('brand')}
				onChange={setBrand}
				selectedValue={brand}
				placeholder={translateToString('all')}
				cancellable
			/>
			<Dropdown
				dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
				name=''
				datalist={All_EXPIRATION.map(g => ({
					label: translateToString(`dashboard.expiration.${g}`),
					value: g
				}))}
				label={translateToString('dashboard.evolution_since_last')}
				selectedValue={{
					label: granularityForDn ? translateToString(`dashboard.expiration.${granularityForDn}`) : '',
					value: granularityForDn ?? undefined
				}}
				onChange={(value: DropdownData<Expiration>) => setGranularityForDn(value.value)}
				placeholder={translateToString('all')}
				cancellable
			/>
			<Dropdown
				dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
				name=''
				datalist={All_EXPIRATION.map(g => ({
					label: translateToString(`dashboard.expiration.${g}`),
					value: g
				}))}
				label={translateToString('dashboard.shelf_audit_expiration_after_one')}
				selectedValue={{
					label: expiration ? translateToString(`dashboard.expiration.${expiration}`) : '',
					value: expiration ?? undefined
				}}
				onChange={(value: DropdownData<Expiration>) => setExpiration(value.value)}
				placeholder={translateToString('all')}
				cancellable
			/>
			<DropdownTagsCloudInDashBoardPopup/>
		</PopupContainer>
		<BottomBar>
			<DefaultButton margin='0' buttonStyle={ButtonStyle.White} onClick={handleBack}><Translate
				id='back'/></DefaultButton>
			<DefaultButton margin='0' onClick={() => {
				const id = currentItem ? currentItem.id : { unsync: Date.now() };
				const layout = currentItem ? currentItem.layout : {
					...DEFAULT_LAYOUTS['ParentDn'],
					i: JSON.stringify(id)
				};
				changedItems.push({
					item_id: company?.value.id ?? undefined,
					item_type: 'ParentDn',
					additional_values: {
						user: user?.value?.id,
						brand: brand?.value?.id,
						expiration,
						granularity: granularityForDn,
						tags: selectedTags.length ? selectedTags.map(t => ({ id:t.id, operator:t.value.operator })) : undefined
					},
					id,
					layout,
					title: title?.trim().length ? title : undefined,
					screen_id:activeTab
				});
				setChangedItems([...changedItems]);
				onSave();
			}}><Translate id='save'/></DefaultButton>
		</BottomBar></>;
}