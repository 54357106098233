import add_blueImage from 'images/icon/add_blue.svg';
import pen_blackImage from 'images/ui_icon/pen_black.svg';
import trashImage from 'images/icons/orders/trash.svg';
import * as React from 'react';
import { getTranslate, TranslateFunction } from 'react-localize-redux';
import { Redirect } from 'react-router';
import ReactTooltip from 'react-tooltip';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import HorizontalCard from '../../../components_v2/horizontalCard/HorizontalCard';
import { CardTd } from '../../../components_v2/horizontalCard/style/Style';
import Input from '../../../components_v2/input/Input';
import ModalLeft from '../../../components_v2/modalLeft/ModalLeft';
import { PaginationResult } from '../../../components_v2/pagination/model/Model';
import Pagination from '../../../components_v2/pagination/Pagination';
import Popup from '../../../components_v2/popup/Popup';
import Switch from '../../../components_v2/Switch/Switch';
import { formatCurrency, getCurrencySymbol } from '../../../containers_v2/reports/utils';
import storeLang from '../../../helpers/storeLang';
import { BlueSidely, LightGreySidely } from '../../../styles/global/css/Utils';
import { ToolbarImage } from '../../globals/defaultToolbar/style/Style';
import {
	normalizeAddZero,
	normalizeComma,
	normalizeEmptyNumber,
	normalizeRemoveStartZero,
	validateIntegerNumber,
	validateLimitPourcentageAndEmpty,
	validateNumber,
	validateNumberAndEmpty,
	validatePositiveNumber,
	validatePositiveNumberAndEmpty
} from '../../globals/FieldFunction';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { getProductTaxes } from '../../products/action';
import { getTaxes } from '../../settings/actions';
import { ProductTax, Tax } from '../../settings/models';
import { getProducts, getProductsByAssortment } from '../data/Data';
import {
	closebottomRightToolbar,
	getDifference,
	getOrderCreator,
	getsubTotal_totalTTC,
	getSubTotalTTC,
	getTotalTTC,
	setOrderCreator,
	updatebottomLeftToolbar
} from '../global/globalFunction';
import { Assortment, FIXED, OrderCreator, PCB, POURCENTAGE, Product, SelectedProduct, UNIT } from '../model/Model';
import PopupNewOrder from '../templateOrders/subPage/popup/PopupNewOrder';
import ModalLeftContent from './modal/ModalLeftContent';
import {
	BodyContainer,
	Button,
	ButtonAdd,
	ButtonAddBlock,
	CardImage,
	CardRow,
	Container,
	ContainerProducts,
	ContainerShoppingCart,
	ProductResultContainer,
	Separator,
	ShoppingCart,
	ShoppingCartBottom,
	ShoppingCartEmpty,
	ShoppingCartText,
	ShoppingCartTop,
	Symbol,
	Text
} from './style/Style';
import noImage from 'images/no-image.jpg';

function submit(selectedProducts: SelectedProduct[], order: OrderCreator, globalDiscount: number, globalDiscountType: number): void {
	if (selectedProducts.length === 0) {
		return;
	}

	setOrderCreator(order.name, order.company, order.creationDate, order.owner, order.contact, order.opportunity, selectedProducts, globalDiscount, globalDiscountType, order.order_status_id, order.payment_id, order.comment);
}

function addSelectedProduct(product: Product, dataProduct: any, selectedProducts: SelectedProduct[], setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProduct[]>>): void {
	const price: number = dataProduct[product.id] ? dataProduct[product.id].price : 0.0;
	const amount: number = dataProduct[product.id] ? dataProduct[product.id].amount : 0.0;
	const amountType: number = dataProduct[product.id] ? dataProduct[product.id].amount_type : PCB;
	const outer: number = dataProduct[product.id] ? dataProduct[product.id].outer : 1;
	const discount: number = dataProduct[product.id] ? dataProduct[product.id].discount : 0.0;
	const discountType: number = dataProduct[product.id] ? dataProduct[product.id].discount_type : POURCENTAGE;

	const newData = {
		id: selectedProducts.length === 0 ? 1 : (selectedProducts.reduce((prev, current) => Math.max(current.id, prev), 1) + 1),
		name: product.name,
		amount: amountType === PCB ? (amount * outer) : amount,
		price,
		extra_taxes: dataProduct[product.id].extra_taxes,
		discount,
		discountType,
		productImage: product.photo_urls[0],
		HTPrice: getSubTotalTTC(
			dataProduct[product.id].price,
			(amountType === PCB ? (amount * outer) : amount),
			dataProduct[product.id].discount,
			dataProduct[product.id].discount_type,
			dataProduct[product.id].extra_taxes as Tax[]),
		product
	} as SelectedProduct;

	setSelectedProducts(selectedProducts.concat(newData));
}

function updateDataProduct(id: number, setDataProduct: (t: any) => void, dataProduct: any, field: string, value: any): void {
	const newDataProduct = { ...dataProduct };
	newDataProduct[id][field] = value;
	setDataProduct(newDataProduct);
}

function updateDataProductExtraTaxes(id: number, setDataProduct: (t: any) => void, dataProduct: any, value: Tax): void {
	const newDataProduct = { ...dataProduct };
	newDataProduct[id].extra_taxes = (newDataProduct[id].extra_taxes as Tax[]).map(tax => tax.id === value.id ? value : tax);
	setDataProduct(newDataProduct);
}

function toCartShipContent(product: SelectedProduct, selectedProducts: SelectedProduct[], setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProduct[]>>, translate: TranslateFunction, index: number): JSX.Element[] {
	return [
		(
			<CardImage key={`SHIPCART[${product.id}][${index}]COLUMN[1]`} src={product && product.productImage && product.productImage.length > 0 ? product.productImage : noImage} alt={product.name} onError={(e) => e.currentTarget.src = noImage} />
		),
		(
			<Text key={`SHIPCART[${product.id}][${index}]COLUMN[2]`} isBold={true}>x{product.amount}</Text>
		),
		(
			<Text key={`SHIPCART[${product.id}][${index}]COLUMN[3]`}>{product.price != null ? formatCurrency(product.HTPrice) : translate('orders.noPrice').toString()}</Text>
		),
		(
			<CardImage key={`SHIPCART[${product.id}][${index}]COLUMN[4]`} src={trashImage} alt='trashbin' height='15px' cursor='pointer'
				onClick={() => {
					setSelectedProducts(selectedProducts.filter(p => p.id !== product.id));
				}} />
		)
	];
}

function getToolbarHeader(translate: TranslateFunction, taxes: Tax[]): JSX.Element[] {
	let columns = [
		(<CardTd key='Column[1]HEADER'><Text>{translate('orders.selectProduct.field.photo').toString()}</Text></CardTd>),
		(<CardTd key='Column[2]HEADER'><Text>{translate('orders.selectProduct.field.name').toString()}</Text></CardTd>),
		(<CardTd key='Column[3]HEADER'><Text>{translate('orders.selectProduct.field.outer').toString()}</Text></CardTd>),
		(<CardTd key='Column[4]HEADER'><Text>{translate('orders.selectProduct.field.category').toString()}</Text></CardTd>),
		(<CardTd key='Column[5]HEADER'><Text>{translate('orders.selectProduct.field.price').toString()}</Text></CardTd>),
		(<CardTd key='Column[6]HEADER' width='145px'><Text>{translate('orders.selectProduct.field.quantity').toString()}</Text></CardTd>),
		(<CardTd key='Column[7]HEADER'><Text>{translate('orders.selectProduct.field.discount').toString()}</Text></CardTd>)
	];

	columns = columns.concat(taxes.map(t => {
		return (
			<CardTd key={`Column[X]HEADER-TAX[${t.id}]`}><Text>{t.name}</Text></CardTd>
		);
	}));

	columns.push(<CardTd><Text>{translate('orders.selectProduct.field.result').toString()}</Text></CardTd>);
	return columns;
}

function initToolbar(setToolBarState: React.Dispatch<React.SetStateAction<ToolbarState>>, setRedirect: React.Dispatch<React.SetStateAction<JSX.Element>>, setOpen: (b: boolean) => void, order: OrderCreator): void {
	updatebottomLeftToolbar(setToolBarState, 1, order.name, (
		<ToolbarImage hasPointer marginLeft="5px" width="12px" height="12px" src={pen_blackImage} alt="edit" onClick={() => setOpen && setOpen(true)} />
	));
	closebottomRightToolbar(setToolBarState, setRedirect, '/orders');
}

function selectProduct(props: {
  setToolBarState: React.Dispatch<React.SetStateAction<ToolbarState>>
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const [orderCreator, setOrderCreator] = React.useState<OrderCreator>(getOrderCreator());
	const [products, setProducts] = React.useState<Product[]>([]);
	const [productCount, setProductCount] = React.useState<number>(0);
	const [selectedProducts, setSelectedProducts] = React.useState<SelectedProduct[]>(orderCreator.selectedProducts);
	const [dataProduct, setDataProduct] = React.useState({});
	const [globalDiscount, setGlobalDiscount] = React.useState<number>(orderCreator.discount);
	const [globalDiscountType, setGlobalDiscountType] = React.useState<number>(orderCreator.discountType);
	const [isDiscountOpen, setDiscountOpen] = React.useState<boolean>(orderCreator.discount !== 0);
	const [selectedAssortment, setSelectedAssortments] = React.useState<Assortment | null>(null);
	const [pagination, setPagination] = React.useState<PaginationResult>();

	const DEFAULT_LIMIT = 50;
	const DEFAULT_OFFSET = 0;

	const [redirect, setRedirect] = React.useState<JSX.Element>(<></>);
	const [isOpen, setOpen] = React.useState<boolean>(false);

	const [taxes, setTaxes] = React.useState<Tax[]>([]);
	const [productTaxes, setproductTaxes] = React.useState<ProductTax[]>([]);

	function toProductContents(product: Product): JSX.Element[] {
		let columns = [
			(
				<CardTd key={`Column[1]Product[${product.id}]`}><CardImage src={(product.photo_urls && product.photo_urls.length > 0 ? product.photo_urls[0] : noImage)} alt={product.name} onError={(e) => e.currentTarget.src = noImage} /></CardTd>
			),
			(
				<CardTd key={`Column[2]Product[${product.id}]`}>
					<Text data-tip={product.name} isBold={true}>{product.name}</Text>
					<ReactTooltip place='top' effect='solid' delayShow={400} type='light' arrowColor={BlueSidely} textColor="white" backgroundColor={BlueSidely} />
				</CardTd>

			),
			(
				<CardTd key={`Column[3]Product[${product.id}]`}><Text>{product.outer ?? 1}</Text></CardTd>
			),
			(
				<CardTd key={`Column[4]Product[${product.id}]`}><Text>{product.category_name}</Text></CardTd>
			),
			(
				<CardTd key={`Column[5]Product[${product.id}]`}>
					<Input
						inputStyle={{ textAlign: true, width: '50px', marginRight: '4px' }}
						key={`price_${product.id}`}
						name={`price_${product.id}`}
						type='text'
						value={product.price ? product.price : 1}
						onChange={(newValue) => updateDataProduct(product.id, setDataProduct, dataProduct, 'price', newValue.length === 0 ? null : parseFloat(newValue))}
						validate={[validateNumberAndEmpty]}
						normalize={[normalizeComma, normalizeRemoveStartZero]}
					/>
					<Symbol>{getCurrencySymbol()}</Symbol>
				</CardTd>

			),
			(
				<CardTd width='145px' key={`Column[6]Product[${product.id}]`}>
					<Input
						inputStyle={{ textAlign: true, width: '50px' }}
						key={`amount_${product.id}`}
						name={`amount_${product.id}`}
						type='number'
						required
						value={dataProduct[product.id]?.amount ?? 0}
						onChange={(newValue) => updateDataProduct(product.id, setDataProduct, dataProduct, 'amount', parseInt(newValue))}
						validate={[validateIntegerNumber, validatePositiveNumber]}
						normalize={[normalizeComma]}
					/>
					<Dropdown
						name={`amount_dropdown_${product.id}`}
						key={`amount_dropdown_${product.id}`}
						dropdownStyle={{ width: '60px', optionWidth: '60px', fontSize: 9 }}
						readOnly
						datalist={[{ label: 'PCB', value: PCB, selected: true }, { label: 'UNT', value: UNIT }]}
						onChange={(newValue: DropdownData) => updateDataProduct(product.id, setDataProduct, dataProduct, 'amount_type', newValue.value)}
					/>
				</CardTd>

			),
			(
				<CardTd key={`Column[7]Product[${product.id}]`}>
					<Input
						inputStyle={{ textAlign: true, width: '50px' }}
						key={`discount_${product.id}`}
						name={`discount_${product.id}`}
						type='text'
						required
						value={'0'}
						onChange={(newValue) => updateDataProduct(product.id, setDataProduct, dataProduct, 'discount', parseFloat(newValue))}
						validate={[validateNumber, validatePositiveNumber]}
						normalize={[normalizeComma, normalizeRemoveStartZero, normalizeEmptyNumber, normalizeAddZero]}
					/>
					<Dropdown
						name={`discount_dropdown_${product.id}`}
						key={`discount_dropdown_${product.id}`}
						dropdownStyle={{ width: '54px', optionWidth: '60px' }}
						readOnly
						datalist={[{ label: '%', value: POURCENTAGE, selected: true }, { label: getCurrencySymbol(), value: FIXED }]}
						onChange={(newValue: DropdownData) => updateDataProduct(product.id, setDataProduct, dataProduct, 'discount_type', newValue.value)}
					/>
				</CardTd>
			)
		];

		columns = columns.concat(taxes.map(t => {
			const tax = t.id !== 0 ? productTaxes.find(pt => pt.product_id === product.id && pt.tax_id === t.id)?.tax_value ?? t.tax_value : ((dataProduct[product.id]?.extra_taxes ?? []) as Tax[]).find(t => t.id === 0)?.tax_value;
			return <CardTd key={`ColumnTax[${t.id}]`}>
				<Input
					inputStyle={{ textAlign: true, marginRight: '4px', width: '50px' }}
					key={`extra_tax_${product.id}_${t.id}`}
					name={`extra_tax_${product.id}_${t.id}`}
					type='text'
					required
					value={tax ?? '0'}
					onChange={(newValue) => {
						const nTax: Tax = { ...t, tax_value: Number.parseFloat(newValue) };
						updateDataProductExtraTaxes(product.id, setDataProduct, dataProduct, nTax);
					}}
					validate={[validateNumber, validatePositiveNumber]}
					normalize={[normalizeComma, normalizeRemoveStartZero, normalizeEmptyNumber, normalizeAddZero]}
				/>
				<Symbol>{t.tax_type_name === 'percentage' ? '%' : getCurrencySymbol()}</Symbol>
			</CardTd>;
		}));

		columns.push(
			<CardTd>
				<Text isBold={true}>{(dataProduct[product.id] && dataProduct[product.id].price != null
					? formatCurrency(getSubTotalTTC(
						dataProduct[product.id].price,
						(dataProduct[product.id].amount_type === PCB ? (dataProduct[product.id].amount * dataProduct[product.id].outer) : dataProduct[product.id].amount),
						dataProduct[product.id].discount,
						dataProduct[product.id].discount_type,
						dataProduct[product.id].extra_taxes))
					: translate('orders.noPrice').toString())}</Text>
			</CardTd>
		);
		return columns;
	}

	function updateOrderCreator(): void {
		const newOrder = getOrderCreator();
		setOrderCreator(newOrder);
		initToolbar(props.setToolBarState, setRedirect, setOpen, newOrder);
	}

	function updateProducts(taxes?: Tax[], productTaxes?: ProductTax[]): void {
		if (selectedAssortment === null) {
			getProducts((pagination != null) ? pagination.step : DEFAULT_LIMIT, (pagination != null) ? pagination.offset : DEFAULT_OFFSET)
				.then(response => {
					setProducts(response.products);
					setProductCount(response.count);

					const data = {};
					response.products.forEach(product => {
						data[product.id] = {
							price: (product.price ? parseFloat(product.price.toString()) : 1.0),
							amount: 0,
							outer: (product.outer ? product.outer : 1),
							amount_type: PCB,
							discount: 0.0,
							discount_type: POURCENTAGE,
							extra_taxes: taxes?.map((t): Tax => ({ ...t, tax_value: productTaxes?.find(pt => pt.product_id == product.id && pt.tax_id == t.id)?.tax_value ?? t.tax_value })) ?? []
						};
					});

					setDataProduct(data);
				});
		} else {
			getProductsByAssortment(selectedAssortment.id, (pagination != null) ? pagination.step : DEFAULT_LIMIT, (pagination != null) ? pagination.offset : DEFAULT_OFFSET)
				.then(response => {
					setProducts(response.products);
					setProductCount(response.count);

					const data = {};
					response.products.forEach(product => {
						data[product.id] = {
							price: (product.price ? parseFloat(product.price.toString()) : 1.0),
							amount: 0,
							outer: (product.outer ? product.outer : 1),
							amount_type: PCB,
							discount: 0.0,
							discount_type: POURCENTAGE,
							extra_taxes: taxes?.map((t): Tax => ({ ...t, tax_value: productTaxes?.find(pt => pt.product_id == product.id && pt.tax_id == t.id)?.tax_value ?? t.tax_value })) ?? []
						};
					});

					orderCreator.selectedProducts.forEach(p => {
						const d = data[p.id];
						const product = response.products.find(product => product.id === p.id);
						if (d && (product != null)) {
							addSelectedProduct(product, data, selectedProducts, setSelectedProducts);
						}
					});

					setDataProduct(data);
				});
		}
	}

	React.useEffect(() => {
		initToolbar(props.setToolBarState, setRedirect, setOpen, orderCreator);
		getTaxes().then(res => {
			const response = res.sort((a, b) => a.sequence - b.sequence);
			setTaxes(response);
			getProductTaxes().then(res2 => {
				const response2 = res2.sort((a, b) => a.sequence - b.sequence);
				setproductTaxes(response2);
				updateProducts(response, response2);
			});
		});
	}, []);

	React.useEffect(() => {
		updateProducts();
	}, [selectedAssortment, pagination]);

	return (
		(<Container>
			{redirect}
			<Popup popupStyle={{ width: '600px', height: '400px' }} content={(<PopupNewOrder onClose={() => {
				updateOrderCreator();
				setOpen(false);
			}} />)} isOpen={isOpen} hasBackground={true} onClickOut={() => setOpen(false)} />
			<ModalLeft title={translate('global.modal.quickFilter').toString()} content={<ModalLeftContent onChangeAssortment={(value) => setSelectedAssortments(value)} />} />
			<BodyContainer>
				<ContainerProducts>
					<CardRow fix>
						<HorizontalCard contents={getToolbarHeader(translate, taxes)} horizontalCardStyle={{ backgroundColor: LightGreySidely, height: '30px' }} isHeader={true}/>
					</CardRow>
					{
						products.map(product => (
							<CardRow margin='0 0 10px 0' key={`modal-filter-card-product[${product.id}]`}>
								<HorizontalCard contents={toProductContents(product)}/>
								<ButtonAddBlock>
									<ButtonAdd src={add_blueImage} className='custom-icon' alt='icon' onClick={() => addSelectedProduct(product, dataProduct, selectedProducts, setSelectedProducts)} />
								</ButtonAddBlock>
							</CardRow>
						))
					}
				</ContainerProducts>
				<Pagination label={'products'} steps={[50, 100, 200, 500]} amount={productCount} onChange={(value) => { setPagination(value); }} />
			</BodyContainer>
			<ContainerShoppingCart>
				<ShoppingCart>
					<ShoppingCartTop>
						{
							selectedProducts && selectedProducts.length === 0
								? <ShoppingCartEmpty>{translate('orders.selectProduct.result_card.emptyShipCard').toString()}</ShoppingCartEmpty>
								: selectedProducts.map((product, index) => (
									<ProductResultContainer key={`selectedProduct[${product.id}][${index}]`}>
										{toCartShipContent(product, selectedProducts, setSelectedProducts, translate, index)}
									</ProductResultContainer>
								))
						}
					</ShoppingCartTop>

					<ShoppingCartBottom>
						<Separator />

						{isDiscountOpen &&
							<CardRow>
								<ShoppingCartText>{translate('orders.selectProduct.result_card.subTotal').toString()}</ShoppingCartText>
								<ShoppingCartText>{selectedProducts.length === 0 ? ' - ' : formatCurrency(getsubTotal_totalTTC(selectedProducts))}</ShoppingCartText>
							</CardRow>
						}
						<CardRow>
							<div style={{ display: 'flex' }}>
								<Switch value={isDiscountOpen} onChange={(value) => {
									setDiscountOpen(value);
									!value && setGlobalDiscount(0);
								}} />
								<ShoppingCartText marginLeft='10px'>
									{translate('orders.selectProduct.result_card.addDiscount').toString()}
								</ShoppingCartText>
							</div>

							{isDiscountOpen &&
								<div style={{ display: 'flex' }}>
									<Input
										inputStyle={{ textAlign: true, width: '60px', marginRight: '2px' }}
										name='global_discount'
										key='global_discount'
										type='text'
										required
										value={globalDiscount}
										onChange={(newValue) => {
											if (!newValue) return setGlobalDiscount(0);
											const value = parseFloat(newValue);
											if (!isNaN(value)) setGlobalDiscount(value);
										}}
										validate={[validateNumberAndEmpty, validatePositiveNumberAndEmpty, (globalDiscountType === POURCENTAGE ? validateLimitPourcentageAndEmpty : validateNumberAndEmpty)]}
										normalize={[normalizeComma, normalizeRemoveStartZero, normalizeAddZero]}
									/>
									<Dropdown
										name='global_discount_dropdown'
										key='global_discount_dropdown'
										dropdownStyle={{ width: '60px', optionWidth: '60px' }}
										readOnly
										datalist={[{
											label: '%',
											value: POURCENTAGE,
											selected: (globalDiscountType === POURCENTAGE)
										}, { label: getCurrencySymbol(), value: FIXED, selected: (globalDiscountType === FIXED) }]}
										onChange={(newValue: DropdownData) => setGlobalDiscountType(newValue.value)}
									/>
								</div>
							}
						</CardRow>

						<CardRow>
							<ShoppingCartText isBold={true}>{translate('orders.selectProduct.result_card.total').toString()}</ShoppingCartText>
							<ShoppingCartText
								isBold={true}>{selectedProducts.length === 0 ? ' - ' : formatCurrency(getTotalTTC(selectedProducts, globalDiscount, globalDiscountType, getDifference(selectedProducts, globalDiscount)))}</ShoppingCartText>
						</CardRow>
						<CardRow justifyContent='center'>
							<Button disabled={selectedProducts.length <= 0} type='button' onClick={(_) => {
								if (selectedProducts.length > 0) {
									submit(selectedProducts, orderCreator, globalDiscount, globalDiscountType);
									setRedirect(<Redirect push to='/orders/summary' />);
								}
							}}>{translate('orders.selectProduct.result_card.continue').toString()}</Button>
						</CardRow>
					</ShoppingCartBottom>
				</ShoppingCart>
			</ContainerShoppingCart>
		</Container>)
	);
}

export default selectProduct;
