/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';

class Numerical extends Component {
  state = {
    number: null
  };

  handleChange = value => {
    // const { data } = this.props;
    // if (value <= data.max && value >= data.min) {
    //   this.setState({
    //     number: value
    //   });
    // } else if (value > data.max) {
    //   this.setState({
    //     number: data.max
    //   });
    // } else if (value < data.min) {
    //   this.setState({
    //     number: data.min
    //   });
    // }

    this.setState({ number: value })
  };

  render() {
    const { data } = this.props;
    const initial_value = data.values && data.values.initial_value || null
    return (
      <div className="form-group">
        <label id={`id-${data.shelf_audit_field_id}`} className="control-label" htmlFor="">
          {data.mandatory && <span className="text-danger">* </span>}
          <Translate id={data.field_name} />
        </label>
        <input
          type="number"
          className="form-control"
          max={data.max}
          min={data.min}
          defaultValue={initial_value}
          value={this.state.number}
          onChange={e => this.handleChange(parseInt(e.target.value, 10))}
        />
      </div>
    );
  }
}

export default Numerical;
