import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API_BUILDER } from '../../config/keys';
import { logoutUser } from './auth.action';
import { translateCreateFormMessage, translateMainMessage } from '../../containers/formBuilder/formElement/builderLib/services/MessageTranslator';

const cookies = new Cookies();

export const FETCH_FORMBUILDER_REQUEST = 'FETCH_FORMBUILDER_REQUEST';
export const FETCH_FORMBUILDER_SUCCESS = 'FETCH_FORMBUILDER_SUCCESS';
export const FETCH_FORMBUILDER_ERROR = 'FETCH_FORMBUILDER_ERROR';
export const FETCH_FORMBUILDER_EMPTY = 'FETCH_FORMBUILDER_EMPTY';

export const DETAIL_FORMBUILDER_REQUEST = 'DETAIL_FORMBUILDER_REQUEST';
export const DETAIL_FORMBUILDER_SUCCESS = 'DETAIL_FORMBUILDER_SUCCESS';
export const DETAIL_FORMBUILDER_ERROR = 'DETAIL_FORMBUILDER_ERROR';

export const CREATE_FORMBUILDER_REQUEST = 'CREATE_FORMBUILDER_REQUEST';
export const CREATE_FORMBUILDER_SUCCESS = 'CREATE_FORMBUILDER_SUCCESS';
export const CREATE_FORMBUILDER_ERROR = 'CREATE_FORMBUILDER_ERROR';

export const UPDATE_FORMBUILDER_REQUEST = 'UPDATE_FORMBUILDER_REQUEST';
export const UPDATE_FORMBUILDER_SUCCESS = 'UPDATE_FORMBUILDER_SUCCESS';
export const UPDATE_FORMBUILDER_ERROR = 'UPDATE_FORMBUILDER_ERROR';

export const DELETE_FORMBUILDER_REQUEST = 'DELETE_FORMBUILDER_REQUEST';
export const DELETE_FORMBUILDER_SUCCESS = 'DELETE_FORMBUILDER_SUCCESS';
export const DELETE_FORMBUILDER_ERROR = 'DELETE_FORMBUILDER_ERROR';

export const FETCH_ICONS_REQUEST = 'FETCH_ICONS_REQUEST';
export const FETCH_ICONS_SUCCESS = 'FETCH_ICONS_SUCCESS';
export const FETCH_ICONS_ERROR = 'FETCH_ICONS_ERROR';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const CREATE_ICONS_REQUEST = 'CREATE_ICONS_REQUEST';
export const CREATE_ICONS_SUCCESS = 'CREATE_ICONS_SUCCESS';
export const CREATE_ICONS_ERROR = 'CREATE_ICONS_ERROR';

export const SELECT_USER_FORM = 'SELECT_USER_FORM';

const PATH = `${URL_API_BUILDER}/v1/api`;

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_FORMBUILDER_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/userform/company`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_FORMBUILDER_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_FORMBUILDER_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
					if (error.response.status === 400) {
						if (error.response.data.stat_msg === 'sql: no rows in result set') {
							dispatch({
								type: FETCH_FORMBUILDER_EMPTY,
								error: error.response
							});
						}
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_FORMBUILDER_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_FORMBUILDER_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/userform/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_FORMBUILDER_SUCCESS,
						payload: response.data
					});
					return response;
				},
				error => {
					dispatch({
						type: DETAIL_FORMBUILDER_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_FORMBUILDER_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_FORMBUILDER_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/userform`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_FORMBUILDER_SUCCESS,
						payload: response.data
					});
					window.location.href = '/formBuilder';
					return response;
				},
				error => {
					dispatch({
						type: CREATE_FORMBUILDER_ERROR,
						error: error.response
					});
					swal.fire(
						translateMainMessage(error.response.data),
						translateCreateFormMessage(error.response.data),
						'error'
					);
					if (error.response.status === 401) {
						// dispatch(logoutUser());
					}
					throw error;
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_FORMBUILDER_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_FORMBUILDER_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/userform/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_FORMBUILDER_SUCCESS,
					payload: response.data
				});
				swal.fire('Success!', 'Data updated!', 'success');
				window.location.href = '/formBuilder';
			},
			error => {
				dispatch({
					type: UPDATE_FORMBUILDER_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_FORMBUILDER_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/userform/id/${id}`
		}).then(
			() => {
				// dispatch({ type: DELETE_COMPANIES_SUCCESS, payload: response.data });
				swal.fire('Deleted!', 'Data has been deleted.', 'success').then(() => {
					// window.location.href = `/formBuilder`;
				});
				dispatch(fetchData({ limit: 10, page: 1 }));
			},
			error => {
				if (error.response.data.stat_msg == 'form_already_filled') {
					swal.fire(
						'Error!',
						'Sorry you can\'t delete this template because there is data in this form, you need to delete the form data one by one in order to delete this template',
						'error'
					);
				} else {
					swal.fire('Error!', error.response.data.errors, 'error');
				}
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function uploadFile(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPLOAD_FILE_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/file`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: UPLOAD_FILE_SUCCESS,
						payload: response.data
					});
					return response;
				},
				error => {
					dispatch({
						type: UPLOAD_FILE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						// dispatch(logoutUser());
					}
					throw error;
				}
			)
			.catch(error => {
				dispatch({
					type: UPLOAD_FILE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function fetchIcons() {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_ICONS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/formicon/select`
			// params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_ICONS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_ICONS_ERROR,
						error: error.response
					});
					// if (error.response.status === 401) {
					//   dispatch(logoutUser());
					// }
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_ICONS_ERROR,
					error
				});

				throw error;
			});
	};
}

export function createIcon(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: CREATE_ICONS_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/formicon`,
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_ICONS_SUCCESS,
						payload: response.data
					});
					swal.fire('Success!', 'success created data', 'success').then(() => {
						dispatch(fetchIcons({ limit: 50, page: 1 }));
					});
				},
				error => {
					dispatch({
						type: CREATE_ICONS_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_ICONS_ERROR,
					error
				});

				throw error;
			});
	};
}

export const SET_MODAL_DUPLICATE = 'SET_MODAL_DUPLICATE';

export const setModalDuplicate = (id, name) => dispatch => {
	console.log('set Duplicate', id);
	dispatch({
		type: SET_MODAL_DUPLICATE,
		id,
		name
	});
};

export const selectUserForm = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${PATH}/userform/select`);
	dispatch({
		type: SELECT_USER_FORM,
		payload: res.data
	});
};
