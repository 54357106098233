import styled, { css } from 'styled-components';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BorderColor, LightBlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';

interface ListContainerProps {
  borderTop?: string
}

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
`;

const ListContainer = styled.div<ListContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-top: ${p => p.borderTop ?? `1px solid ${BorderColor}`};
    cursor: pointer;
    padding-top: 15px;
    margin-top: 15px;

    justify-content: flex-start;
    width:100%;
    gap:20px;
    padding-left:20px;
    padding-right:20px;
`;

interface ListArrowProps {
  isOpen?: boolean
}

const ListArrow = styled(DefaultImage)<ListArrowProps>`
    width: 15px;
    height: 15px;
    cursor: pointer;
    transition: transform 0.4s;
    transform: rotate(${p => p.isOpen ? '90' : '-90'}deg);
    user-select: none;
`;

const ListTextCss = css`
    ${DefaultText}
    color: ${SidelyBlack};
    font-weight: 500;
    font-size: 13px;
    width: 100%;
`;

const ListText = styled.p`
    ${ListTextCss}
`;

export const ListTextFlexDiv = styled.div`
    ${ListTextCss}
    display: flex;
    gap: 1em;
`;

const ListAdd = styled(DefaultImage)`
    background-color: ${LightBlueSidely};
    cursor: pointer;
`;

export {
	Body,
	ListContainer,
	ListArrow,
	ListText,
	ListAdd
};
