/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import SelectOpportunityStatus from '../../select/SelectOpportunityStatusFilter';
import {Translate} from 'react-localize-redux';

class ModalFilterOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onFilter, status } = this.props;

    const data = {
      opportunityStatusId: (status && status.value) || 'all'
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          style={{ borderBottom: 'none' }}
        >
          <Translate id="select_status" />
        </ModalHeader>
        <ModalBody>
          <Translate>
            {({ translate }) => (
              <div className="text-center">
                <SelectOpportunityStatus
                  name="opportunityStatusId"
                  label={translate('status')}
                  placeholder={translate('pick_status')}
                />
              </div>
            )}
          </Translate>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <Button
            color="primary"
            className="px-5 mb-2"
            onClick={() => {
              onFilter(data);
              this.props.toggle();
            }}
          >
            <Translate id="save" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const selector = formValueSelector('modalFilterStatus');

const mapStateToProps = state => ({
  status: selector(state, 'opportunityStatusId')
});

export default reduxForm({
  form: 'modalFilterStatus'
})(
  connect(
    mapStateToProps,
    {}
  )(ModalFilterOwner)
);
