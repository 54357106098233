import * as React from 'react';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { Table } from '../../../components_v2/table/Table';
import { ACTIONS_PER_OBJECT, ObjectActionScopes } from '../../../Types';
import getPermissions from '../../permissions/getPermissions';
import { getGroupPolicies, getPlanPolicies } from '../action';
import { CererusAuthPolicy, Group, ObjectActionScope, PlanPolicy } from '../model';
import { NameInput, Title } from '../style';
import { Translate, translateToString } from '../../../styles/global/translate';
import { ObjectAction, Scope } from '../../../../../web-types/objectActions';

const ACTIONS = ['view', 'read', 'create', 'update', 'delete'];

function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

type LocalPermission = {
  [key in ObjectAction]: number;
}

export default function PermissionTable(props: {
  groupsName: string[]
  group?: Group
  setGroupName: (name: string) => void
  onDelete: () => void
  onUpdate: (values: ObjectActionScope[]) => void
}): JSX.Element {
	const [groupPolicies, setGroupPolicies] = React.useState<CererusAuthPolicy[]>([]);
	const [planPolicies, setPlanPolicies] = React.useState<LocalPermission>();
	const [name, setName] = React.useState<string>(props.group?.name ?? '');
	const [policyModifies, setPolycyModifies] = React.useState<ObjectActionScope[]>([]);
	const planPermissions = Object.keys(getPermissions());

	function localGetGroupPolicies(): void {
		getGroupPolicies(props.group?.id ?? -1).then(res => {
			setGroupPolicies(res);
			const policies = res.map((d: CererusAuthPolicy): ObjectActionScope => {
				return {
					groupId: d.group_id,
					policyId: d.id,
					scope: d.scope,
					objectAction: d.object_action,
					toRemove: false
				};
			});
			setPolycyModifies(policies);
			props.onUpdate(policies);
		}).catch(console.log);
	}

	React.useEffect(() => {
		localGetGroupPolicies();
	}, [props.group?.id]);

	React.useEffect(() => {
		getPlanPolicies()
			.then(res => {
				setPlanPolicies(res.reduce((acc: LocalPermission, per: PlanPolicy) => {
					acc[per.object_action] = per.id;
					return acc;
				}, {} as LocalPermission));
			})
			.catch(console.error);
	}, []);

	function updatePolicy(groupId: number, policyId: number | undefined, objectAction: ObjectAction | undefined, scope: Scope | undefined): void {
		const newList = policyModifies.filter(n => n.objectAction != objectAction);

		const newPolicy: ObjectActionScope = { groupId, policyId, objectAction, scope, toRemove: scope == undefined };
		newList.push(newPolicy);
		setPolycyModifies(newList);
		props.onUpdate(newList);
	}

	const { columns, data } = React.useMemo(
		() => ({
			columns: [
				{
					id: 'Actions/Objects',
					Header: ' ',
					accessor: (objectActions: ObjectActionScopes[]) => translateToString(`permission.objects.${objectActions[0]?.objectAction?.split(/(?=[A-Z])/)?.slice(1).join('_').toLowerCase()}`),
					disableSortBy: true,
					width: 150
				},
				...ACTIONS.map((action: string) => {
					return {
						id: action,
						Header: translateToString(`permission.actions.${action}`),
						accessor: (row: ObjectActionScopes[]) => {
							const objectActionScope = row.find(objectAction => objectAction.objectAction.startsWith(capitalizeFirstLetter(action)));
							// if object-action doesnt exist display nothing
							if (objectActionScope == undefined) return <></>;

							const maped: Array<DropdownData<Scope | undefined>> = objectActionScope.scopes.map(scope => ({ value: scope, label: translateToString(`permission.scope.${scope}`) }));
							maped.push({ value: undefined, label: translateToString('permission.scope.default') });
							const policy = policyModifies.find(gp => gp.objectAction == objectActionScope.objectAction);

							return <Dropdown
								autoOptionUp
								readOnly
								name={`dropdown[${objectActionScope.objectAction}]`}
								datalist={maped}
								dropdownStyle={{
									width: '100%',
									optionWidth: '200px',
									optionHeight: '150px',
									backgroundColor: 'transparent'
								}}
								selectedValue={{ value: policy?.scope, label: translateToString(`permission.scope.${policy?.scope ?? 'default'}`) }}
								onChange={({ value }: DropdownData<Scope | undefined>) => {
									updatePolicy(props.group?.id ?? -1, policy?.policyId, objectActionScope.objectAction, value);
								} }
							></Dropdown>;
						},
						disableSortBy: true,
						width: undefined
					};
				})
			],
			data: ACTIONS_PER_OBJECT.map((objectActions: ObjectActionScopes[]): ObjectActionScopes[] => objectActions.filter(oa => planPermissions.includes(oa.objectAction))).filter(oa => oa.length > 0)
		}),
		[groupPolicies, planPolicies, policyModifies]
	);

	return (
		<div style={{ marginTop: 20 }}>
			<Title>
				<Translate id="admin.group_name" />
			</Title>
			<NameInput
				error={props.groupsName.some(gName => gName == name)}
				type='text'
				value={name}
				onChange={e => {
					setName(e.target.value);
					props.setGroupName(e.target.value);
				}}
			/>
			<Title>
				<Translate id="admin.permissions" />
			</Title>
			<Table
				height='calc(100vh - 269px)'
				columns={columns}
				data={data}
			/>

		</div>
	);
}
