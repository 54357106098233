import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ContactsDetail from './components/ContactsDetail';
import {
	deleteData,
	detailData,
	fetchData,
	getActivities,
	getDocuments,
	getEmails,
	getEvents,
	getNotes,
	getOpportunities
} from '../../store/actions/contacts.action';
import { getAllform, getDetailform, getLinkedform, getLinkedId } from '../../store/actions/formdata.action';
import { fetchData as getSalesOperations, getMerchandisings, getTradeshows } from '../../store/actions/salesOperations.action';
import { currentData } from '../../store/actions/user.action';
import DropdownActionDetail from '../../components/DropdownActionDetail';
import FilterHandlePage from '../../components/FilterHandlePage';

class DetailContacts extends Component {
	state = {
		prev: null,
		next: null,
		index: null
	};

	componentDidMount() {
		const {
			page,
			fetchData,
			detailData,
			match,
			currentData,
		} = this.props;
		currentData();
		detailData(match.params.id);
		fetchData({ page, sort: 'asc', by: 'lastName' });
	}

	componentWillReceiveProps(nextProps) {
		const { match, data } = this.props;
		if (data.data !== nextProps.data.data) {
			nextProps.data.data.length > 1 &&
        nextProps.data.data.map((value, i) => {
        	if (value.id === parseInt(match.params.id)) {
        		this.setState({
        			next:
                i !== 9
                	? (nextProps.data.data[i + 1] &&
                      nextProps.data.data[i + 1].id) ||
                    null
                	: null,
        			prev:
                i !== 0
                	? (nextProps.data.data[i - 1] &&
                      nextProps.data.data[i - 1].id) ||
                    null
                	: null,
        			index: i
        		});
        	}
        });
		}
	}

	getPermission(current, data) {
		if (current != null && data != null && data.dataDetail != null) {
			const role = current.role.slug;
			if (role == 'admin' || role == 'owner') {
				return 1;
			} else if (current.id == data.dataDetail.userId) {
				return 1;
			} else {
				return 0;
			}
		}
		return 0;
	}

	render() {
		const { route, data, match, current } = this.props;

		return (
			<div>
				<div className="d-flex background-bar sticky-v2 right-panel">
					<div className="pr-3 my-auto">
						<Link to="/contacts" style={{ textDecoration: 'none' }}>
							<h2 className="card-title mb-0">{route.title}</h2>
						</Link>
					</div>
					<div className="px-3 my-auto">
						<h2 className="card-title font-weight-bold mb-0">
							{!data.isLoading &&
                `${data.dataDetail.firstName} ${data.dataDetail.lastName}`}
						</h2>
					</div>
					<div className="pl-5 my-auto">
						<FilterHandlePage
							getPage={this.props.getPage}
							page={this.props.page}
							index={this.state.index}
							next={this.state.next}
							prev={this.state.prev}
							path="/contacts/detail"
						/>
					</div>
					<div className="ml-auto mx-2">
						<DropdownActionDetail
							id={match.params.id}
							name={
								!data.isLoading &&
                `${data.dataDetail.firstName} ${data.dataDetail.lastName}`
							}
							permission='Contact'
							path="Contacts"
							{...this.props}
						/>
					</div>
				</div>
				<ContactsDetail
					firstName={data.dataDetail && data.dataDetail.firstName}
					lastName={data.dataDetail && data.dataDetail.lastName}
					{...this.props}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	data: state.contacts,
	page: state.page.page,
	salesOperation: state.salesOperations,
	current: state.user.current,
	linkedFormdata: state.formdata.allLinkedData,
	detailLinkedForm: state.formdata
});

export default connect(
	mapStateToProps,
	{
		deleteData,
		detailData,
		getOpportunities,
		getActivities,
		getEvents,
		getNotes,
		getEmails,
		getDocuments,
		currentData,
		getSalesOperations,
		getTradeshows,
		getMerchandisings,
		fetchData,
		getLinkedform,
		getLinkedId,
		getDetailform,
		getAllform,
	}
)(DetailContacts);
