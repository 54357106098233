import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { Owner } from '../../containers_v2/orders/model/Model';
import { TableRow } from '../../containers_v2/orders/templateOrders/style/Style';
import { FlexDiv, FlexDivProps } from '../../containers_v2/products/style';
import storeLang from '../../helpers/storeLang';
import { Open } from '../../styles/global/css/Open';
import Avatar from '../avatar/Avatar';
import Dropdown from './Dropdown';
import { DropdownData, DropdownStyle } from './model/Model';
import { CleanButton, InputContainer, OptionBlock, UserLabel } from './style/Style';
import { Image } from '../avatar/style/Style';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';

export function sortOwners(a: Owner, b: Owner): number {
	if (a.isYou) return -1;
	if (b.isYou) return 1;
	if (a.status && !b.status) return -1;
	if (!a.status && b.status) return 1;
	return a.name.localeCompare(b.name);
}

export function UserBlock(props: {
	user?: Owner,
	noLabel?: boolean,
	noCursor?: boolean,
	width?: string,
	flexStyle?: FlexDivProps,
	userId?: number
}): JSX.Element {
	const users = useRecoilValue(AUsers);
	const translate = getTranslate(storeLang.getState().localize);
	const user = props.user ?? users.find(u => u.id == props.userId);
	return <FlexDiv gap='5px' width={props.width} {...props.flexStyle}>
		{user?.photoUrl
			? <Image url={user.photoUrl} width='25px' radius='50%' noCursor={props.noCursor}/>
			: <Avatar backgroundColor={user?.color} userId={user?.id} name={user?.name ?? ''} width='25px' />
		}
		{!props.noLabel && <UserLabel user={user} me={translate('me').toString()}>
			{user?.name}
		</UserLabel>}
	</FlexDiv>;
}

export default function DropdownOwners(props: {
  onChange: (value: DropdownData<Owner | null>) => void
  selected?: DropdownData<Owner | undefined | null>
  users: Array<DropdownData<Owner>>
  dropdownStyle?: DropdownStyle
  open?: boolean
  border?: boolean
  label?: string | JSX.Element
  required?: boolean,
  cancellable?: boolean,
  readOnly?: boolean
  disabled?: boolean
  JSXButton?: (props: {isOpen: boolean, setIsOpen: (b: boolean) => void, width: string, height: string, value?: DropdownData<Owner>}) => JSX.Element | string
}) {
	const { onChange, dropdownStyle, users, selected, open, border, label, required } = props;
	return <Dropdown<Owner>
		label={label}
		disabled={props.disabled}
		required={required}
		dropdownStyle={dropdownStyle}
		datalist={users.sort((a, b) => sortOwners(a.value, b.value))}
		name={'select_company_owner_name'}
		containerClickable
		autoOptionUp
		readOnly={props.readOnly}
		JSXButton={props.JSXButton ? props.JSXButton : ({ isOpen, width, height }) => <InputContainer width={width} height={height} border={border ? undefined : 'none'} backgroundColor={dropdownStyle?.backgroundColor}>
			<TableRow cursor="pointer" center={border}>
				<FlexDiv justify="space-between" width="100%">
					<FlexDiv gap={'5px'} width='calc(100% - 15px)'>
						<UserBlock user={selected?.value ?? undefined} width='100%'/>
						{props.cancellable && selected && !border && <CleanButton className="btn btn-transparent p-0" type="button" onClick={e => {
							e.stopPropagation();
							onChange({ label: '', value: null });
						}}><i className="fas fa-times" /></CleanButton>}
						{open && !border && <Open isOpen={isOpen} width={12} height={7} />}
					</FlexDiv>
					{props.cancellable && selected && border && <CleanButton className="btn btn-transparent p-0" type="button" onClick={e => {
						e.stopPropagation();
						onChange({ label: '', value: null });
					}}><i className="fas fa-times" /></CleanButton>}
					{open && border && <Open isOpen={isOpen} width={12} height={7} />}
				</FlexDiv>
			</TableRow>
		</InputContainer>
		}
		JSXContent={value => <OptionBlock width='calc(100% - 5px)'>
			<UserBlock user={value.value} width='100%'/>
		</OptionBlock>}
		onChange={onChange}
	/>;
}
