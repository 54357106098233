import styled from 'styled-components';
import { BorderColor, GreenSidely, RedSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { InputStyle } from '../../../components_v2/input/model/Model';

export const InputStyleContainer = styled.div`
    width:100%;
    margin-bottom: 10px;
`;
interface InputStyleDivProps {
    padding?: string;
    border?: string;
    gap?: string;
    fontSize?: string;
}
export const InputStyleDiv = styled.div<InputStyleDivProps>`
    height:40px;
    border-radius: 5px;
    display:flex;
    align-items: center;
    border: ${p => p.border ?? `1px solid ${BorderColor}`};
    padding: ${p => p.padding ?? '0.56rem 0.75rem'};
    gap:${p => p.gap ?? ''};
    font-size: ${p => p.fontSize ?? ''};
`;
export const InputStyleLabel = styled.label`
    ${DefaultText};
    margin:0;
    font-size: 12px;
`;
export const inputStyle: InputStyle = {
	margin:'0 0 30px 0',
	color:`${SidelyBlack}`,
};

interface PercentDivProps {
    color?: string;
    fontWeight?: string;
}
export const PercentDiv = styled.div<PercentDivProps>`
    color:${p => p.color ?? ''};
    font-weight: ${p => p.fontWeight ?? ''};
`;

interface CircleBoolDiv {
    value: boolean,
    width?: string,
}
export const CircleBoolDiv = styled.div<CircleBoolDiv>`
  background-color: ${p => p.value ? GreenSidely : RedSidely};
  width: ${p => p.width ?? '20px'};
  height: ${p => p.width ?? '20px'};
  border-radius: 50%;
`;
