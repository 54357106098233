import * as React from 'react';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';
import { Address } from './model/Model';
import PopupModifyAddress from './popup/PopupModifyAddress';

function AddressModificator(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  defaultAddress?: Address
  title?: string
  onChange?: (value: Address) => void
}): JSX.Element {
	return (
		<Popup
			popupMode={PopupMode.Centered}
			content={(
				<PopupModifyAddress
					title={props.title ?? ''}
					onChange={(na) => {
						(props.onChange != null) && props.onChange(na);
						props.setOpen(false);
					}}
					defaultAddress={props.defaultAddress}
					onClose={() => props.setOpen(false)}
				/>
			)}
			isOpen={props.isOpen}
			onClickOut={() => props.setOpen(false)}
			hasBackground={true}
			popupStyle={{ borderRadius: '10px' }}
		/>
	);
}

export default AddressModificator;
