import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_FOUNDATION } from '../../../config/keys';
import { OpportunityPostParam, OpportunityStatus } from '../model/Model';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

export async function postOpportunity(params: OpportunityPostParam): Promise<number> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/opportunities`, params)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return -1;
	}
}

export async function getOpportunityStatuses(): Promise<OpportunityStatus[]> {
	try {
		const result = await axios.get<OpportunityStatus[]>(`${URL_FOUNDATION}/api/v2/opportunities/statuses`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}
