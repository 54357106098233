import * as React from 'react';
import { CompanyContact } from '../../containers_v2/client-companies/model/Model';
import NewContact from '../../containers_v2/contact/popup/NewContact';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';
import Add from '../add/Add';

export function AddAndContactCreation(props: {
	onCreate?: (value: CompanyContact) => void
}): JSX.Element {
	const [isOpen, setIsOpen] = React.useState(false);
	
	return <>
		<Add onClick={() => setIsOpen(true)} />
		<ContactCreation isOpen={isOpen} setOpen={setIsOpen} {...props} />
	</>;
}

function ContactCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  onCreate?: (value: CompanyContact) => void
  clientCompanyId?: number
  statusId?: number
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, top: '0%' }}
			popupMode={PopupMode.Details}
			content={<NewContact
				onClickOut={() => close()}
				onCreate={(value) => {
					(props.onCreate != null) && props.onCreate(value);
					close();
				}}
				clientCompanyId={props.clientCompanyId}
				statusId={props.statusId}
			/>
			}
		/>
	);
}

export default ContactCreation;
