import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor2, DarkGreySidely2 } from '../../../styles/global/css/Utils';

const Container = styled.div`
    padding-left: 20px;
    width: 100%;
`;

const Header = styled.div``;

interface BodyProps {
  isOpen: boolean
}

const Body = styled.div<BodyProps>`
    margin-left: 20px;
    display: ${p => p.isOpen ? 'block' : 'none'};
`;

const ButtonLabel = styled.button`
    font-size: 12px;
    line-height: 1;
    background: transparent;
    border: none;
    font-weight: 450;
`;

const Row = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
`;

interface childBodyProps {
  isOpen: boolean
}

const ChildBody = styled.div<childBodyProps>`
    margin-left: 20px;
    display: ${p => p.isOpen ? 'block' : 'none'};
`;

const Label = styled.p`
    ${DefaultText}
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #333;
    margin-left: 3%;
`;

interface CheckboxProps {
  isActive: boolean,
  size?: string,
  disabled?: boolean
}

const Checkbox = styled.div<CheckboxProps>`
    width: ${p => p.size ?? '14px'};
    height: ${p => p.size ?? '14px'};
    border-radius: 4px;
    border: 1px solid ${p => p.isActive && !p.disabled ? BlueSidely : BorderColor2};
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-family: monospace;
    cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
    font-size: ${p => `calc(${p.size ?? '14px'} + 2px)`};

    ${p => p.isActive
		? `  
    &:after {
        content: '✔';
        color: ${p.disabled ? BorderColor2 : BlueSidely};
    }
    `
		: ''}
    
`;

interface RadioProps {
  isActive: boolean
  inactiveColor?: string,
  size?: string
}

const Radio = styled.div<RadioProps>`
    width: ${p => p.size ?? '17px'};
    height: ${p => p.size ?? '17px'};
    border: 1px solid ${p => p.isActive ? BlueSidely : p.inactiveColor ?? DarkGreySidely2};
    background-color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    ${p => p.isActive
		? `
    &:after {
        content: ' ';
        background-color: ${BlueSidely};
        width: 9px;
        height: 9px;
        display: block;
        border-radius: 50%;
    }
    `
		: ''
}
`;

const Arrow = styled.i`
    cursor: pointer;
`;

export {
	Container,
	Header,
	ButtonLabel,
	Body,
	Row,
	Label,
	Checkbox,
	Radio,
	ChildBody,
	Arrow
};
