import { URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { decodeReport, Report } from '../interpretor/bareReportingDecoder';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function getPresenceEvolution(body: { granularity: 'day' | 'week' | 'month' | 'quarter' | 'year'; status_ids: number[]; user_ids: number[]; end: string; begin: string }): Promise<Report> {
	axios.defaults.headers.common.Authorization = `${token}`;
	const resp = await fetch(`${PATH}/v2/reports/user-events-evolution`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': token,
		},
		body: JSON.stringify(body)
	});
	return decodeReport(new Uint8Array(await resp.arrayBuffer()));

}