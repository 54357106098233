import * as React from 'react';
import { To } from '../../Types';
import usePermission from './usePermission';

interface Props {
  to: To | To[]
  isSelf?: boolean
  fallback?: React.ReactNode
  cumulative?: boolean
  // loadingComponent?: JSX.Element | string;
  children: React.ReactNode
  ownerId?: number
}

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({ to, cumulative, fallback, children, isSelf, ownerId }) => {
	// We "connect" to the provider thanks to the PermissionContext
	const allowed = usePermission(to, { isSelf, ownerId, cumulative });
	// if (loading) {
	//     return <>{loadingComponent}</>;
	// }

	// If the user has that permission, render the children
	if (allowed) {
		return <>{children}</>;
	}

	// Otherwise, render the fallback
	return <>{fallback}</>;
};

export default Restricted;
