import trash_black from 'images/ui_icon/trash_black.svg';
import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { LoadingStateEnum } from '../../containers_v2/import/model';
import { FlexDiv } from '../../containers_v2/products/style';
import storeLang from '../../helpers/storeLang';
import { Loader } from '../../styles/global/css/GlobalLoader';
import Dropdown from '../dropdown/Dropdown';
import { DropdownData } from '../dropdown/model/Model';
import { Body, CommentHeaderTitle, Container, CrossClose, Footer, Header, HeaderTitle, ValidateButton } from './style/PopupCreationStyle';
import optionGrey from 'images/icon/options_grey.png';
import { FullOpen } from '../../containers_v2/client-companies/style/PopupStyle';
import modal_open from 'images/icons/company/modal_open.svg';

export enum ButtonStyle {
	Default = 0,
	Error = 1,
	White = 2,
	Black = 3
}

export type AdditionalButton = {
	title: React.ReactNode
	onSubmit?: () => void
	canValidate?: boolean
	style?: ButtonStyle
}

export function AdditionalButtons(props: {buttons?: AdditionalButton[], disabled?: boolean}): JSX.Element {
	return <>
		{props.buttons?.map((ab, i) =>
			<ValidateButton
				key={`additionalButton[${i}]`}
				disabled={ab.canValidate === false || props.disabled}
				onClick={ab.onSubmit}
				buttonStyle={ab.style}
			>
				{ab.title}
			</ValidateButton>
		)}
	</>;
}

function PopupCreation<T = any>(props: {
  title: string | JSX.Element
  content?: JSX.Element
  children?
  canValidate?: boolean
  onSubmit?: () => void
  headerBackgroundColor?: string
  loading?: LoadingStateEnum
  onClose?: () => void
  onDelete?: () => void
  hideValidation?: boolean
  messageButton?: string | JSX.Element
  additionalButton?: AdditionalButton[]
  titleComment?: JSX.Element | string
  dropdown?: { data: DropdownData<T>[], onChange: (data: DropdownData<T>) => void }
  onModeChange?: () => void,
  extended?: boolean,
  noHeader?: boolean,
  overflow?: string
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);

	return (
		(<Container>
			{ !props.noHeader && <><Header backgroundColor={props.headerBackgroundColor}>
				<FlexDiv width='100%'>
					<CrossClose src={close} alt="" onClick={() => (props.onClose != null) && props.onClose()} />
					<FlexDiv flow='column' align='flex-start' ellipsis>
						<HeaderTitle>{props.title}</HeaderTitle>
						{props.titleComment && <CommentHeaderTitle>{props.titleComment}</CommentHeaderTitle>}
					</FlexDiv>
					{props.onDelete && <CrossClose src={trash_black} onClick={props.onDelete} auto/>}
					{props.onModeChange && <div style={{ marginLeft: 'auto' }}><FullOpen src={modal_open} isActive={props.extended ?? false} onClick={props.onModeChange} /></div>}
					{props.dropdown && <div style={{ marginLeft: props.onModeChange ? '10px' : 'auto' }}>
						<Dropdown
							dropdownStyle={{
								optionLeft: '-170px',
								optionWidth: '200px',
								optionHeight: '200px',
								height: '25px'
							}}
							datalist={props.dropdown.data}
							name={'dropdownOptionEvent'}
							JSXButton={() => <img src={optionGrey} width={25} style={{ rotate: props.onModeChange ? '90deg' : undefined }}/>}
							onChange={props.dropdown.onChange}
						/>
					</div>}
				</FlexDiv>
			</Header></>}
			<Body hideValidation={props.hideValidation} overflow={props.overflow}>
				{
					props.loading && props.loading === LoadingStateEnum.LOADING
						? <Loader width="25px" center />
						: props.children ?? props.content
				}
			</Body>
			{
				!props.hideValidation &&
				<Footer>
					<ValidateButton
						disabled={!props.canValidate || props.loading === LoadingStateEnum.LOADING}
						onClick={props.onSubmit}
					>
						{props.messageButton ?? translate('company.creation.create').toString()}
					</ValidateButton>
					<AdditionalButtons buttons={props.additionalButton} disabled={props.loading === LoadingStateEnum.LOADING}/>
				</Footer>
			}
		</Container>)
	);
}

export default PopupCreation;
