import {
	CREATE_FORMDATA_ERROR,
	CREATE_FORMDATA_REQUEST,
	CREATE_FORMDATA_SUCCESS,
	DETAIL_FORMDATA_ERROR,
	DETAIL_FORMDATA_REQUEST,
	DETAIL_FORMDATA_SUCCESS,
	FETCH_FORMDATA_ERROR,
	FETCH_FORMDATA_REQUEST,
	FETCH_FORMDATA_SUCCESS,
	FIND_LINKED_FORM_REQUEST,
	FIND_LINKED_FORM_SUCCESS,
	GET_COMPANY_LINKED,
	GET_DETAIL_FORM_REQUEST,
	GET_DETAIL_FORM_SUCCESS,
	GET_LINKED_ALL,
	GET_LINKED_FORM_ERROR,
	GET_LINKED_FORM_REQUEST,
	GET_LINKED_FORM_SUCCESS,
	UPDATE_FORMDATA_ERROR,
	UPDATE_FORMDATA_REQUEST,
	UPDATE_FORMDATA_SUCCESS
} from '../actions/formdata.action';

const initState = {
	data: [],
	icons: [],
	linkedForm: [],
	linkedDetailForm: [],
	allLinkedData: [],
	dataDetail: null,
	search: '',
	sortField: null,
	sort: 'desc',
	isLoading: false,
	isLoadingSubmit: false,
	isLoadingDetail: false,
	isLoadingLinkedForm: false,
	idLinked: {
		id: null,
		type: ''
	},
	paginationLinkedForm: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	activeFilter: {
		expenseStatusId: null
	}
};

export default function CompaniesReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_FORMDATA_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_FORMDATA_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.meta.current_page,
					lastPage: action.payload.meta.last_page,
					count: action.payload.meta.count,
					recordPerPage: action.payload.meta.record_per_page
				},
				isLoading: false
			};
		case FETCH_FORMDATA_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_FORMDATA_REQUEST:
			return {
				...state,
				isLoadingSubmit: true
			};

		case CREATE_FORMDATA_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoadingSubmit: false
			};

		case CREATE_FORMDATA_ERROR:
			return {
				...state,
				isLoadingSubmit: false
			};

		case UPDATE_FORMDATA_REQUEST:
			return {
				...state,
				isLoadingSubmit: true
			};

		case UPDATE_FORMDATA_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoadingSubmit: false
			};

		case UPDATE_FORMDATA_ERROR:
			return {
				...state,
				isLoadingSubmit: false
			};

		case DETAIL_FORMDATA_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_FORMDATA_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_FORMDATA_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};
		case GET_LINKED_FORM_REQUEST:
			return {
				...state,
				isLoadingLinkedForm: true
			};
		case GET_LINKED_FORM_SUCCESS:
			return {
				...state,
				linkedForm: action.payload.data || [],
				paginationLinkedForm: {
					currentPage: action.payload.meta.current_page,
					lastPage: action.payload.meta.last_page,
					count: action.payload.meta.count,
					recordPerPage: action.payload.meta.record_per_page
				},
				isLoadingLinkedForm: false
			};
		case GET_DETAIL_FORM_REQUEST:
			return {
				...state,
				isLoadingLinkedForm: true
			};
		case GET_LINKED_FORM_ERROR:
			return {
				...state,
				isLoadingLinkedForm: false
			};
		case GET_DETAIL_FORM_SUCCESS:
			return {
				...state,
				linkedDetailForm: action.payload.data || [],
				isLoadingLinkedForm: false
			};
		case FIND_LINKED_FORM_REQUEST:
			return {
				...state,
				isLoadingLinkedForm: true
			};
		case FIND_LINKED_FORM_SUCCESS:
			return {
				...state,
				data: action.payload.data || [],
				pagination: {
					currentPage: action.payload.meta.current_page,
					lastPage: action.payload.meta.last_page,
					count: action.payload.meta.count,
					recordPerPage: action.payload.meta.record_per_page
				},
				isLoadingLinkedForm: false
			};

		case GET_COMPANY_LINKED:
			return {
				...state,
				idLinked: action.payload
			};
		case GET_LINKED_ALL:
			return {
				...state,
				allLinkedData: action.payload
			};
		default:
			return state;
	}
}
