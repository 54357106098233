import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API, URL_FOUNDATION } from '../../../config/keys';
import { EventType } from '../../../containers_v2/client-companies/model/Model';
import { ITag, Tags } from '../../../../typings/proto/protobufs';
import { TagEditParams, TagType } from './Model';

const cookies = new Cookies();

const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function getAllTag(status: boolean, type: number) {
	return await axios({
		method: 'GET',
		url: `${URL_API}/api/tags?status=${status}&type=${type}&by=colorStatusesId&sort=desc`
	});
}

export async function getEventTypes(): Promise<EventType[]> {
	return await axios.get(`${URL_FOUNDATION}/api/crm/event-types`)
		.then(response => response.data)
		.catch(e => {
			console.log(e);
			return [];
		});
}

function getPathByTagType(tag_type: TagType): string {
	switch (tag_type) {
		case TagType.COMPANY: return 'client-companies';
		case TagType.CONTACT: return 'contacts';
		case TagType.PRODUCT: return 'products';
		case TagType.OPPORTUNITY: return 'opportunities';
		case TagType.ORDER: return 'orders';
	}
}

export async function getTags(tag_type: TagType): Promise<ITag[]> {
	try {
		const res = await axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/${getPathByTagType(tag_type)}/tags`, { responseType: 'arraybuffer' });
		// .then(response => Tags.decode(new Uint8Array(response.data)).tag );
		return Tags.decode(new Uint8Array(res.data)).tag;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function updateTags(tag_type: TagType, params: TagEditParams): Promise<boolean> {
	try {
		const result = await axios.put(`${URL_FOUNDATION}/api/v2/${getPathByTagType(tag_type)}/tags/sync`, params)
			.then(response => response.status >= 200 && response.status < 300);
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}
