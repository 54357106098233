enum TagType {
  COMPANY = 1,
  CONTACT = 2,
  OPPORTUNITY = 3,
  PRODUCT = 4,
  ORDER = 5
}

export function tagTypeToAdditionalFieldColumnType(type: TagType): string {
	switch (type) {
		case TagType.COMPANY: return 'Company';
		default: return '';
	}
}

// interface Tag {
//     id: number
//     name: string
//     slug: string
//     tag_type: number
//     status: boolean
//     sequence: number
//     company_id: number
//     user_id: number
//     status_id: number
//     status_color: string
//     created_at: string
//     updated_at: string
// }

interface TagParams {
  status?: boolean
  types?: number[]
  order_by?: string
  ascending?: boolean
}

interface TagEditParams {
  ressource_id: number
  ids_delete?: number[]
  ids_add?: number[]
}

export {
	// Tag,
	TagEditParams,
	TagParams,
	TagType
};
