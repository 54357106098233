import React, { useEffect, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { setLocalStorage } from '../../../helpers/localStorage';
import storeLang from '../../../helpers/storeLang';
import { getAllSelfAudit, shelfAuditSelect } from '../../../store/actions/enForm.action';
import { getAllCatalogues } from '../../../store/actions/products.action';
import CloseButton from './componentForm/Button/CloseButton';
import DefaultButton from './componentForm/Button/DefaultButton';
import HeaderArrowBack from './componentForm/Button/HeaderArrowBack';
import PicturePolicy from './componentForm/PicturePolicy';
import SelecteAssortment from './componentForm/SelectAssortment';

function DetailForm(props) {
	const translate = getTranslate(storeLang.getState().localize);
	const { route, history, dispatch, products, match, data } = props;
	const template = JSON.parse(localStorage.getItem('enFormTemplate'));
	const [selectedId, setSelectedId] = useState(template.catalogue_id || null);
	const [picturePolicy, setPicturePolicy] = useState(template.shelf_audit_field_templates || []);
	const [defaultValue, setdefaultValue] = useState([]);
	//FIXME: I wonder if this variable is util for us
	//If we replace page with new shelf audit, we doesn't have to redirect to '/enform/list/'
	//It's more simple to redirect always to '/shelf-audits
	const isFromNewShelfAudit = JSON.parse(localStorage.getItem('from_new_shelf_audit'));

	useEffect(() => {
		dispatch(getAllCatalogues());
		dispatch(getAllSelfAudit());
		dispatch(shelfAuditSelect());
		if (template.catalogue_id !== undefined) {
			setSelectedId(template.catalogue_id);
		}
	}, []);

	useEffect(() => {
		if (data.shelfAuditSelects) {
			const val = [];
			data.shelfAuditSelects.map((v, i) => v.category == 'camera' && val.push({
				mandatory: true,
				ordering: i + 1,
				shelf_audit_field_id: v.id,
				values: v.id === 3 ? {
					initial_value: true
				} : {}
			}));

			setdefaultValue(val);
		}
	}, [data.shelfAuditSelects]);

	const onSubmit = stepView => {
		const valTemplate = [];
		defaultValue.map(v => {
			const status = picturePolicy.find(p => p.shelf_audit_field_id === v.shelf_audit_field_id);
			if (status) {
				valTemplate.push(v);
			} else {
				valTemplate.push({ ...v, mandatory: false });
			}
		});
		const submit = {
			...template,
			catalogue_id: selectedId,
			shelf_audit_field_templates: valTemplate,
			step: stepView
		};
		localStorage.removeItem('enFormTemplate');
		setLocalStorage('enFormTemplate', submit);
	};
	const onClickButton = (stepPage, url) => {
		onSubmit(stepPage);
		history.push(url);
	};

	const step = translate('step');

	return (
		<div>
			<div className="d-flex background-bar sticky-v2 right-panel">
				<HeaderArrowBack history={history} isFromNewShelfAudit={isFromNewShelfAudit}/>
				<div className="ml-auto my-auto d-flex">
					{route.name !== 'assorment' && <DefaultButton {...props}
						disabled={false}
						label={step + ' 1'}
						onClickBtn={() => onClickButton(1, '/enform/detail')}
						classType='btn-previous mr-3' />}
					<DefaultButton {...props}
						disabled={route.name === 'assorment' ? !selectedId : false}
						label={route.name === 'assorment' ? step + ' 2' : step + ' 3'}
						onClickBtn={() => route.name === 'assorment' ? (
							onClickButton(2, '/enform/detail/assortment')) : onClickButton(3, '/enform/detail/elements')}
						classType='btn-primary' />
					<CloseButton history={history} isFromNewShelfAudit={isFromNewShelfAudit}/>
				</div>
			</div>
			<div className="row mt-8em mb-5">
				<div
					className="p-5 ml-4 detail-top my-0"
					style={{ width: '95%', height: '100vh' }}
				>
					{route.name === 'assorment' ? (
						<SelecteAssortment
							history={history}
							setSelectedId={setSelectedId}
							option={(products && products.allCatalogues) || []}
						/>
					) : (
						<PicturePolicy
							setPicturePolicy={v => setPicturePolicy(v)}
							picturePolicy={picturePolicy}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	products: state.products,
	data: state.enform,
	fieldInstaller: state.enform.fieldInstaller
});

export default connect(mapStateToProps)(DetailForm);
