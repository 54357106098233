import { Granularity } from 'bindings/time/Granularity';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';
import Add from '../../components_v2/add/Add';
import AdvancedFilters from '../../components_v2/filter/AdvancedFilters';
import { FilterId, filterTreeLength } from '../../components_v2/filter/model/Model';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import Pagination from '../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { Table, TableSortType } from '../../components_v2/table/Table';
import ToolbarFilterButton from '../../components_v2/toolbarFilter/ToolbarButton';
import { ToolBarView } from '../../components_v2/view/ToolBarView';
import { translateToString } from '../../styles/global/translate';
import { DEFAULT_STEP } from '../authorization/model';
import { FilterState } from '../contact/Contacts';
import { TableContext, TableProvider } from '../contact/data/ContactContext';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { Unhautorized } from '../noData/NoData';
import PermissionContext from '../permissions/PermissionContext';
import Restricted from '../permissions/Restricted';
import { ModalState } from '../products/model';
import PopupTarget from './PopupTarget';
import genTargetsColumns from './TargetsColumns';
import { getTargets, getTargetsFilters, postTarget, putTarget } from './actions';

const TARGET_VIEW_LOCAL_STORAGE = 'targets_view_id';
export const TARGET_HIDDEN_LOCAL_STORAGE = 'targets_hidden_columns';
export const TARGET_SORT_LOCAL_STORAGE = 'targets_sort_columns';

export const TARGET_ENUM_LIST = [
	'Dn',
	'CompletedEvents',
	'CompletedTask',
	'CompletedCall',
	'CompletedMeeting',
	'CompletedVisit',
	'CompletedSms',
	'CompletedEmail',
	'CompletedAnimation',
	'CompletedPromotion',
	'CompletedDelivery'
] as const;
export type TargetEnum = typeof TARGET_ENUM_LIST[number];
export type TargetData = {
	[key: string]: {
		[key: string]: number
	}
}

export type Target = {
	count: number,
	id: number,
	name: string,
	target: TargetEnum,
	granularity: Granularity,
	data: TargetData,
	created_by: number,
	created_at: string,
	updated_at?: string
}

export default function CheckoutsWrapper(props: ComponentProps) {
	return <TableProvider>
		<Restricted to={{ objectAction: 'ReadObjective' }} fallback={<Unhautorized />}>
			<Targets {...props} />
		</Restricted>
	</TableProvider>;
}

function Targets(props: ComponentProps) {
	const [targets, setTargets] = React.useState<Target[]>([]);
	const context = React.useContext(TableContext);
	const { filterResult, setFilterResult, hiddenColumns, sortColumns, loaded } = context;
	const [pagination, setPagination] = React.useState<PaginationResult>();
	const [popupState, setPopupState] = React.useState<ModalState<{target: Target, mode: PopupMode}>>({ isOpen: false });
	const [sort, setSort] = React.useState<TableSortType | undefined>({ id: 'created_at', desc: true });
	const users = useRecoilValue(AUsers);
	const { isAllowedTo } = React.useContext(PermissionContext);

	// FILTERS
	const [filterState, setFilterState] = React.useState<FilterState>({});
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const onClickFilter = React.useCallback((selectedId: FilterId) => {
		setFilterState({
			selectedId,
			openSummary: false,
			filterOpen: true
		});
	}, []);

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('targets'),
			bottomLeftToolbarComponent: <ToolbarBox>
				<ToolBarView
					localStorageKey={TARGET_VIEW_LOCAL_STORAGE}
					localStorageHiddenKey={TARGET_HIDDEN_LOCAL_STORAGE}
					viewsKey='targets'
					context={context}
				>
					<ToolbarFilterButton
						activeFilters={filterTreeLength(filterResult?.formatted)}
						onClick={() => setFilterState(state => ({ ...state, openSummary: true, filterOpen: true }))}
						onDeleteFilter={() => {
							setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
						}}
					/>
				</ToolBarView>
			</ToolbarBox>,
			bottomRightToolbarComponent: <ToolbarBox>
				<Restricted to={{ objectAction: 'CreateObjective' }}>
					<Add onClick={() => setPopupState({ isOpen: true })} />
				</Restricted>
			</ToolbarBox>
		});
	}, [filterResult?.formatted, hiddenColumns, sortColumns]);

	React.useEffect(() => {
		getTargetsFilters().then(setFilterParams);
	}, []);


	const refresh = React.useCallback(() => {
		getTargets({
			offset: pagination?.offset ?? 0,
			limit: pagination?.step ?? DEFAULT_STEP,
			order_by: sort?.id,
			descending: sort?.desc,
			filters: filterResult?.formatted
		}).then(setTargets);
	}, [sort, pagination, filterResult?.formatted]);

	React.useEffect(() => {
		if (loaded) refresh();
	}, [sort, pagination, filterResult?.formatted, loaded]);

	const columns = React.useMemo(() => genTargetsColumns(users, setPopupState, refresh, isAllowedTo), [users, refresh]);

	return <>
		<Table
			height='calc(100vh - 175px)'
			columns={columns}
			data={targets}
			onSort={React.useCallback((sort: TableSortType) => setSort(sort[0]), [])}
			initialSortBy={sort}
			onClickFilter={onClickFilter}
			hiddenColumns={hiddenColumns}
			sortColumns={sortColumns}
		/>
		<Pagination
			label={translateToString('targets')}
			amount={Number(targets[0]?.count ?? 0)}
			currentCount={targets.length}
			steps={[DEFAULT_STEP, 50, 100]}
			onChange={setPagination}
		/>
		<PopupTarget
			target={popupState.data?.target}
			mode={popupState.data?.mode}
			isOpen={popupState.isOpen}
			setIsOpen={(isOpen: boolean) => setPopupState({ isOpen })}
			onCreate={body => postTarget(body).then(refresh)}
			onUpdate={(id, body) => putTarget(id, body).then(refresh)}
			onDelete={refresh}
		/>
		<AdvancedFilters
			isOpen={filterState.filterOpen ?? false}
			setOpen={filterOpen => setFilterState({ filterOpen })}
			isOpenSummary={filterState.openSummary ?? false}
			filterList={filterParams}
			filterValues={filterResult?.values}
			onChange={(value) => setFilterResult(value)}
			selectedFilter={filterState.selectedId}
		/>
	</>;
}
