import * as type from '../actionType/group.action.type';

const initState = {
	isLoading: false,
	listGroup: [],
	pagination: {},
	detailGroup: null,
	formGroup: {
		id: 0,
		name: '',
		status: true,
		member: [],
		images: []
	},
	listMember: [],
	paginationMember: {}
};

function groupReducer(state = initState, action) {
	switch (action.type) {
		case type.FETCH_LIST_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.FETCH_LIST_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false,
				listGroup: action.payload.data ? action.payload.data : [],
				pagination: action.payload.pagination
			};
		case type.MERGE_LIST_GROUP:
			return {
				...state,
				isLoading: false,
				listGroup: [...state.listGroup, ...action.payload.data],
				pagination: action.payload.pagination
			};
		case type.FETCH_LIST_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.FETCH_ALL_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.FETCH_ALL_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false,
				listGroup: action.payload.data
			};
		case type.FETCH_ALL_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.FETCH_ALL_GROUP_NO_PAGINATION_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.FETCH_ALL_GROUP_NO_PAGINATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				listGroup: action.payload.data
			};
		case type.FETCH_ALL_GROUP_NO_PAGINATION_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.FETCH_GROUP_BY_ID_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.FETCH_GROUP_BY_ID_SUCCES:
			return {
				...state,
				isLoading: false,
				detailGroup: action.payload.data
			};
		case type.FETCH_GROUP_BY_ID_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.CREATE_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.CREATE_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case type.CREATE_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.FETCH_MEMBER_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case type.FETCH_MEMBER_SUCCESS:
			return {
				...state,
				isLoading: false,
				listMember: action.payload.data,
				paginationMember: action.payload.pagination
			};
		case type.MERGE_LIST_MEMBER:
			return {
				...state,
				isLoading: false,
				listMember: [...state.listMember, ...action.payload.data],
				paginationMember: action.payload.pagination
			};
		case type.FETCH_MEMBER_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.ADD_MEMBER_TO_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.ADD_MEMBER_TO_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case type.ADD_MEMBER_TO_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.UPDATE_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.UPDATE_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false,
				detailGroup: action.payload.data
			};
		case type.UPDATE_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.DELETE_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.DELETE_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case type.DELETE_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.DELETE_MEMBER_FROM_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case type.DELETE_MEMBER_FROM_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case type.DELETE_MEMBER_FROM_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case type.SET_FORM_GROUP:
			return {
				...state,
				formGroup: {
					...state.formGroup,
					id: action.payload.id,
					name: action.payload.name,
					status: action.payload.status,
					member: action.payload.member,
					images: action.payload.images
				}
			};

		default:
			return state;
	}
}

export default groupReducer;
