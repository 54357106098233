import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API, URL_FOUNDATION } from '../../config/keys';
import { EventsMapRow, FrequencyMapRow } from './model';
import { Map, Table } from '../../../typings/proto/protobufs';
import { CompanyParams } from '../client-companies/model/Model';
import { FilterResult } from '../../components_v2/filter/model/Model';
import { Granularity } from 'bindings/time/Granularity';

const cookies = new Cookies();
const PATH = `${URL_API}/api`;
const token = cookies.get('id_token') || null;

export async function getMapData() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/clientCompanies/mapList`
	});
}

export async function getClientStatues() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/clientStatuses`
	});
}

export async function getEventTypes() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_FOUNDATION}/api/crm/event-types`
	});
}

export async function getGoogleLine(origin: any, destination: any) {
	return await axios.get(`https://maps.googleapis.com/maps/api/directions/json?origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&sensor=false&key=AIzaSyCYkQC599NV99WgBGvW2RHABKHXowlisgw`);
}

export async function getMapInitial(filters: FilterResult): Promise<Map.IInitialMapRow[]> {
	return await axios.post<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/query`, { filters }, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.InitialMapTable.decode(new Uint8Array(response.data)).rows;
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}

export async function getMapCheckouts(granularity: Granularity): Promise<Map.ICheckoutRow[]> {
	return await axios.post<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/checkouts`, { granularity }, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.CheckoutsTable.decode(new Uint8Array(response.data)).rows;
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}


export type MapEventRes = Map.IEventsMapRow & {nextEventDate?: Date, lastEventDate?: Date };

export async function getMapEvents(): Promise<MapEventRes[]> {
	return axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/events`, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.EventsMapTable.decode(new Uint8Array(response.data)).rows.map(row => ({
					...row,
					nextEventDate: row.nextEventDate?.seconds ? new Date(row.nextEventDate.seconds as number * 1000) : undefined,
					lastEventDate: row.lastEventDate?.seconds ? new Date(row.lastEventDate.seconds as number * 1000) : undefined
				}));
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}

export async function getMapTags(): Promise<Map.ITagsMapRow[]> {
	return await axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/tags`, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.TagsMapTable.decode(new Uint8Array(response.data)).rows.map(r => ({ id: r.id!, tags: r.tags! }));
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}

export async function getMapFrequency(eventType: number): Promise<FrequencyMapRow[]> {
	const data: CompanyParams = {
		new_filters: { and: [
			{ val: { column: 'longitude', operator: 'not_empty' } },
			{ val: { column: 'latitude', operator: 'not_empty' } },
			{ val: { column: { frequencies: { element: 'score', event: eventType } }, operator: 'not_empty' } }
		] },
		columns: [{ frequencies: { event: eventType, element: 'score' } }]
	};
	return axios.post<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/client-companies/query`, data, { responseType: 'arraybuffer' })
		.then(async response => Table.decode(new Uint8Array(response.data)).row.map(r => ({ id: r.companyId, frequency: r.frequency })));
}

export async function getMapDistribution(): Promise<Map.IDistributionMapRow[]> {
	return await axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/distribution`, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.DistributionMapTable.decode(new Uint8Array(response.data)).rows;
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}

export async function getMapOrders(): Promise<Map.IOrdersMapRow[]> {
	return await axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/orders`, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.OrdersMapTable.decode(new Uint8Array(response.data)).rows;
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}

export async function getMapAdditionalColumns(): Promise<Map.IAdditionalColumnRow[]> {
	return await axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/additional-columns`, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.AdditionalColumnsTable.decode(new Uint8Array(response.data)).rows;
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}

export async function getMapAdditionalFieldColumns(): Promise<Map.IAdditionalFieldRow[]> {
	return await axios.get<ArrayBufferLike>(`${URL_FOUNDATION}/api/v2/map/form-fields`, { responseType: 'arraybuffer' })
		.then(async response => {
			try {
				return Map.AdditionalFieldsTable.decode(new Uint8Array(response.data)).rows;
			} catch (error) {
				console.log(error);
				return [];
			}
		})
		.catch(err => {
			console.log(err);
			return [];
		});
}