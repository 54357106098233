import Targets from '../../containers_v2/targets/Targets';
import { Route } from '../Routes';

const TargetRoutes = (): Route[] => [
	{
		component: Targets,
		path: '/targets',
	}
];

export default TargetRoutes;
