/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import {selectProspectOrigin} from '../../store/actions/select.action';

class SelectProbability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: [
        { value: '0', label: '0%' },
        { value: 10, label: '10%' },
        { value: 20, label: '20%' },
        { value: 30, label: '30%' },
        { value: 40, label: '40%' },
        { value: 50, label: '50%' },
        { value: 60, label: '60%' },
        { value: 70, label: '70%' },
        { value: 80, label: '80%' },
        { value: 90, label: '90%' },
        { value: 100, label: '100%' }
      ]
    };
  }

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      readOnly,
      placeholder
    } = this.props;
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="inputOwner"
        placeholder={placeholder}
        validate={validate}
        readOnly={readOnly}
      />
    );
  }
}

const mapStateToProps = state => ({
  // data: state.select.prospectOrigin
});

export default connect(
  mapStateToProps,
  { selectProspectOrigin }
)(SelectProbability);
