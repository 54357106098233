/* eslint-disable prettier/prettier */
import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const FETCH_DATA_TARGET_REQUEST = 'FETCH_DATA_TARGET_REQUEST';
export const FETCH_DATA_TARGET_SUCCESS = 'FETCH_DATA_TARGET_SUCCESS';
export const FETCH_DATA_TARGET_ERROR = 'FETCH_DATA_TARGET_ERROR';

export const CREATE_DATA_TARGET_REQUEST = 'CREATE_DATA_TARGET_REQUEST';
export const CREATE_DATA_TARGET_SUCCESS = 'CREATE_DATA_TARGET_SUCCESS';
export const CREATE_DATA_TARGET_ERROR = 'CREATE_DATA_TARGET_ERROR';

export const UPDATE_DATA_TARGET_REQUEST = 'UPDATE_DATA_TARGET_REQUEST';
export const UPDATE_DATA_TARGET_SUCCESS = 'UPDATE_DATA_TARGET_SUCCESS';
export const UPDATE_DATA_TARGET_ERROR = 'UPDATE_DATA_TARGET_ERROR';

export const LIST_DATA_TARGET_REQUEST = 'LIST_DATA_TARGET_REQUEST';
export const LIST_DATA_TARGET_SUCCESS = 'LIST_DATA_TARGET_SUCCESS';
export const LIST_DATA_TARGET_ERROR = 'LIST_DATA_TARGET_ERROR';

export const DETAIL_DATA_TARGET_REQUEST = 'DETAIL_DATA_TARGET_REQUEST';
export const DETAIL_DATA_TARGET_SUCCESS = 'DETAIL_DATA_TARGET_SUCCESS';
export const DETAIL_DATA_TARGET_ERROR = 'DETAIL_DATA_TARGET_ERROR';

export const DELETE_DATA_REQUEST = 'DELETE_DATA_REQUEST';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DELETE_DATA_ERROR = 'DELETE_DATA_ERROR';

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_DATA_TARGET_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_API}/api/targetType?search&order=typeName&sort=desc&page=1&limit=10`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_DATA_TARGET_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_DATA_TARGET_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_DATA_TARGET_ERROR,
					error
				});

				throw error;
			});
	};
}

export function listData(params, targetFor) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: LIST_DATA_TARGET_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_API}/api/targetManagement/${targetFor}`,
			params
		})
			.then(
				response => {
					dispatch({
						type: LIST_DATA_TARGET_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: LIST_DATA_TARGET_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_DATA_TARGET_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(targetFor, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	// console.log('params', params);

	return dispatch => {
		dispatch({
			type: DETAIL_DATA_TARGET_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_API}/api/targetManagement/${targetFor}/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_DATA_TARGET_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_DATA_TARGET_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_DATA_TARGET_ERROR,
					error
				});

				throw error;
			});
	};
}

export function createData(data, targetFor) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_DATA_TARGET_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${URL_API}/api/targetManagement/${targetFor}`,
			headers: {
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/json'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_DATA_TARGET_SUCCESS,
						payload: response.data,
						activeTab: targetFor
					});
					return response;
				},
				error => {
					console.log(error.response);
					dispatch({
						type: CREATE_DATA_TARGET_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response && error.response.status === 401) {
						dispatch(logoutUser());
					}
					throw error;
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_DATA_TARGET_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id, targetFor) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_DATA_TARGET_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${URL_API}/api/targetManagement/${targetFor}/${id}`,
			headers: {
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/json'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_DATA_TARGET_SUCCESS,
					payload: response.data,
					activeTab: targetFor
				});
				// swal.fire('Success!', 'Data updated!', 'success').then(() => {
				// window.location.href = `/targetManagement/${subPath}`;
				// });
			},
			error => {
				dispatch({
					type: UPDATE_DATA_TARGET_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteData(id, isRedirect, type, subPath, targetTypeSlug) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${URL_API}/api/targetManagement/${type}/${id}`
		}).then(
			() => {
				// dispatch({ type: DELETE_COMPANIES_SUCCESS, payload: response.data });
				// swal.fire('Deleted!', 'Data has been deleted.', 'success').then(() => {
				if (isRedirect) {
					window.location.href = `/targetManagement/${subPath}`;
				} else {
					dispatch(listData({
						targetTypeSlug
					}, type));
				}
				// });
			},
			error => {
				// dispatch({ type: DELETE_COMPANIES_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}
