import * as React from 'react';
import { BlueSidely, LightGreySidely, LightGreySidely2, DarkGreySidely2 } from '../../../styles/global/css/Utils';
import styled from 'styled-components';
import { LineHoverDiv, TitleAndChild, Volet } from '../../client-companies/popup/Detail';
import { useRecoilValue } from 'recoil';
import RemoveIcon from 'images/ui_icon/trash_red.svg';
import { Panel } from 'bindings/reports/generic/Panel';
import { Column } from 'bindings/reports/generic/Column';
import { DataSources, GenericEditorContext, Products } from './genericEditor';
import * as _ from 'lodash';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import SettingIcon from 'images/icon/setting.png';
import Popup from '../../../components_v2/popup/Popup';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { FieldContainer, LeftModalContent, OpenContainer } from '../../forms/FormTemplateCreator';
import Up from 'images/icons/Up.svg';
import Down from 'images/icons/Down.svg';
import Copy from 'images/icon/copy.png';
import { TypeFieldsContainer } from '../../forms/FormTemplateCreator';
import { FieldType } from 'bindings/forms/FieldType';
import Add from '../../../components_v2/add/Add';
import { FilterText } from '../../../components_v2/filter/style/AdvancedFilterStyle';
import { useFunctionState } from '../../../utils/customHooks';
import { Open } from '../../../styles/global/css/Open';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import Input from '../../../components_v2/input/Input';
import { FlexDiv } from '../../products/style';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { assert } from '../../../utils/assert';
import { getDistinctMetadata } from './action';
import { Translate, translateToString } from '../../../styles/global/translate';
import close from 'images/icons/orders/close.svg';
import { FieldName } from '../../forms/FormTemplateCreator';
import { AFormFields, Field, getFields, getFieldsLinkedForms, LinkedForm } from '../../../atoms/forms';
import FilterList from '../../../components_v2/filter/pages/FilterList';
import { FilterValueResultUnion } from '../../../components_v2/filter/model/Model';
import { CombinableFilter } from 'bindings/reports/generic/CombinableFilter';
import { reverseConvertFilters } from '../../../components_v2/filter/pages/FilterList';
import { AAdditionalColumns } from '../../../atoms/additionalColumns';
import { FilterTree } from '../../../components_v2/filter/model/Model';
import { getAssortments } from '../../products/action';
import { LoadingState } from '../../import/model';
import { Loader } from '../../../styles/global/css/GlobalLoader';
import { AProducts } from '../../../atoms/product';
import { FilterType } from 'bindings/filters/FilterType';
import InputSearch from '../../../components_v2/input/InputSearch';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import { ACampaigns } from '../../../atoms/campaignAtom';
import { getMetadataPeriods } from '../../forms/actions';

export interface BoxProps {
	secondary?: boolean;
	shadow?: boolean;
	border?: boolean;
	padding?: string;
}

const Box = styled.div<BoxProps>`

	width: 100%;
	background-color: ${props => props.secondary ? 'white' : LightGreySidely};
	box-shadow: ${props => props.shadow ? '4px 4px 7px 3px rgba(0,0,0,0.02)' : ''};
	border: ${props => props.border ? '1px solid rgb(228, 228, 228)' : ''};
	padding: ${props => props.padding ? props.padding : '15px'};
	border-radius: 5px;
	font-size: 13px;
`;

const SettingsButton = styled.img`
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 15px;
  height: auto;
`;

const Operation = styled.div<{ enabled: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 17px;
	width: 17px;
	color: ${props => props.enabled ? 'white' : 'black'};
	text-align: center;
	border-radius: 6px;
	cursor: pointer;
	background-color: ${props => props.enabled ? BlueSidely : LightGreySidely2};
`;

const NewOperation = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 17px;
	font-weight: 200;
	width: 17px;
	color: black;
	text-align: center;
	border-radius: 6px;
	cursor: pointer;
	background-color: ${LightGreySidely2};
	border: 1px solid #d5d5d579;
	&:hover {
		background-color: ${BlueSidely};
		color: white;
	}
`;


const Category = styled.div`
	padding-bottom: 25px;
`;

export const GenericColumnContext = React.createContext<{
	column: Column | undefined
	setColumn: (value: Column | undefined) => void
		}>({ column: {} as Column, setColumn: () => undefined });

function GenericColumnProvider(props: { column?: string, setColumn?: (value: string) => void, children: React.ReactNode }): JSX.Element {
	const [column, setColumn] = useFunctionState<Column | undefined>(props.column ? JSON.parse(props.column) : undefined, ({ newValue }) => {
		props.setColumn?.(JSON.stringify(newValue));
		return newValue;
	});

	return <GenericColumnContext.Provider value={{ column, setColumn }}>
		{props.children}
	</GenericColumnContext.Provider>;
}


function FieldOrComputedDisplay(props: { column: Column }): JSX.Element {
	const fieldsAtom = useRecoilValue(AFormFields);
	const additionalColumnsAtom = useRecoilValue(AAdditionalColumns);
	const { report } = React.useContext(GenericEditorContext);
	const { column } = props;

	if (!column) {
		return <><Translate id='report_editor.empty_column' /></>;
	}
	if (typeof column !== 'string' && 'Field' in column && column.Field) {
		return <>{fieldsAtom.find((f) => (f.id === column.Field.id || f.slug === column.Field.slug))?.name ?? translateToString('report_editor.nonexistant_field')}</>;
	}
	else if (typeof column != 'string' && 'Computed' in column) {
		return <>⨍𝑥 {translateToString('report_editor.calculated_noname')}</>;
	}
	else if (typeof column != 'string' && 'Formula' in column) {
		return <>⨍𝑥 Formule</>;
	}
	else if (typeof column != 'string' && 'AdditionalColumn' in column) {
		return <>{additionalColumnsAtom.find(ac => ac.id === column.AdditionalColumn)?.name}</>;
	}
	else if (typeof column != 'string' && 'CompanyColumn' in column) {
		return <><Translate id={'report_editor.crm_fields.' + column.CompanyColumn} /> <Translate id='report_editor.crm_fields.company' /></>;
	}
	else if (typeof column != 'string' && 'ProductColumn' in column) {
		return <><Translate id={'report_editor.crm_fields.' + column.ProductColumn} /> <Translate id='report_editor.crm_fields.product' /></>;
	}
	else if (typeof column != 'string' && 'InstanceColumn' in column) {
		return <><Translate id={'report_editor.crm_fields.' + column.InstanceColumn} /></>;
	}
	else if (typeof column != 'string' && 'EventColumn' in column) {
		return <><Translate id={'report_editor.crm_fields.' + column.EventColumn} /></>;
	}
	else if (typeof column != 'string' && 'UserColumn' in column) {
		return <><Translate id='report_editor.crm_fields.UserColumn' />: <Translate id={'report_editor.crm_fields.' + column.UserColumn} /></>;
	}
	else if (typeof column != 'string' && 'NamedColumn' in column) {
		return <>
			{typeof column.NamedColumn.column !== 'string' && ('Computed' in column.NamedColumn.column || 'Formula' in column.NamedColumn.column) && <>⨍𝑥&nbsp;</>}
			{column.NamedColumn.name}
		</>;
	}
	else if (typeof column != 'string' && 'CampaignColumn' in column) {
		return <><Translate id={'report_editor.crm_fields.Campaign'} /></>;
	}
	else if (column == 'ChildCount') {
		return <><Translate id='report_editor.number_of' /> {DataSources.find(d => d.value === report.group_by)?.label}</>;
	}
	else if (column == 'Unit') {
		return <>1</>;
	}
	else if (column == 'InstanceCount') {
		return <><Translate id='report_editor.instance_number' /></>;
	}
	else if (column == 'InstanceDate') {
		return <><Translate id='report_editor.made_at' /></>;
	}
	else if (column == 'CreatedBy') {
		return <><Translate id='report_editor.created_by' /></>;
	}
	else if (column == 'CompanyOwner') {
		return <><Translate id='report_editor.company_owner' /></>;
	}
	else if (column == 'Index') {
		return <><Translate id='report_editor.index' /></>;
	}
	else if (column == 'CompanyCount') {
		return <><Translate id='report_editor.crm_fields.CompanyCount' /></>;
	}
	else {
		return <><Translate id='report_editor.unsupported_column' /></>;
	}
}

export const CrossImg = styled.img<{ margin?: string }>`
	width: 20px;
	aspect-ratio: 1 / 1;
	cursor: pointer;
	margin: ${p => p.margin ?? '0px'};
`;

const COMPUTABLE_FIELD_TYPES: FieldType[] = ['Number', 'Boolean', 'Select', 'Multiselect', 'Integer'];

function PerFormFields(props: { form: string, search: string, fields: Field[], onChildPicked: (column: Column) => void}): JSX.Element {
	const [fields, setFields] = React.useState<{ [type in FieldType]: Field[] }>();
	const [isOpen, setIsOpen] = React.useState(false);

	React.useEffect(() => {
		setFields(props.fields.filter(f => f.name.toLowerCase().includes(props.search.toLowerCase())).reduce((acc: { [key in FieldType]: Field[] }, f) => {
			if (!acc[f.type]) acc[f.type] = [];
			acc[f.type].push(f);
			return acc;
		}, {} as { [key in FieldType]: Field[] }));
		if (props.search !== '') {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [props.search]);

	if (fields) {
		return <>
			<OpenContainer justify='space-between' onClick={() => {
				setIsOpen(!isOpen);
			}}>
				{props.form}
				<Open isOpen={isOpen} size={15} />
			</OpenContainer>

			{isOpen && <><div style={{ marginLeft: '10px' }}>
				{...COMPUTABLE_FIELD_TYPES.map((type) => <>
					{(type === 'Integer' || type === 'Boolean' || type === 'Number' || type === 'Select' || type === 'Multiselect') && <TypeFieldsContainer
						key={`Key[${type}]`}
						fields={fields?.[type] ?? []}
						type={type}
						onFieldClicked={
							f => {
								props.onChildPicked({ Field: { slug: f.slug, aggregation: 'Sum' } });
							}
						}
						creationNotAllowed={true}
						setModalState={() => undefined}
						hideId={true}
						allowEdit={false}
					/>}
				</>)}
			</div>
			</>}
		</>;
	} else {
		return <><Loader /></>;
	}
}

function ChildPicker(props: { onChildPicked: (column: Column) => void, onClickOut: () => void, isCalcField?: boolean, isInGroupBy?: boolean }): JSX.Element {
	const { report } = React.useContext(GenericEditorContext);
	
	const [linkedForms, setLinkedForms] = React.useState<LinkedForm[]>();
	const [isForceEvent, setIsForceEvent] = React.useState<boolean | undefined>(undefined);

	const [fields, setFields] = React.useState<Field[]>();

	const [search, setSearch] = React.useState<string>();

	const [isOpenCompanyColumns, setIsOpenCompanyColumns] = React.useState(true);
	const [isOpenFormFields, setIsOpenFormFields] = React.useState(true);
	const [isOpenProductFields, setIsOpenProductFields] = React.useState(true);
	const [isOpenInstanceFields, setIsOpenInstanceFields] = React.useState(true);
	const [isOpenFunctions, setIsOpenFunctions] = React.useState(true);
	const [isOpenEventFields, setIsOpenEventFields] = React.useState(true);
	const [isOpenPromotionFields, setIsOpenPromotionFields] = React.useState(true);

	const additionalColumnsAtom = useRecoilValue(AAdditionalColumns);

	const refresh = React.useCallback(() => {
		getFields().then(fields => setFields(fields));

		getFieldsLinkedForms().then(f => {
			setLinkedForms(f);
		});
	}, []);

	let forms: string[] = [];
	if (linkedForms) {
		forms = linkedForms.reduce((acc, f) => {
			if (!acc.includes(f.form_name)) {
				acc.push(f.form_name);
			}
			return acc;
		}, [] as string[]);
	}

	React.useEffect(() => {
		refresh();
	}, []);

	React.useEffect(() => {
		if (report.panels.some(p => p.columns.some(c => typeof c !== 'string' && 'EventColumn' in c))) {
			setIsForceEvent(true);
		} else if (report.panels.some(p => p.columns.some(c => typeof c == 'string' || !('EventColumn' in c)))) {
			setIsForceEvent(false);
		} else {
			setIsForceEvent(undefined);
		}
	}, [report.panels]);


	return <>
		<LeftModalContent style={{ height: '100%' }}>
			{<>
				<InputSearch
					onChange={setSearch}
					name='search'
					type='text'
				/>
				{!props.isCalcField && <FlexDiv onClick={() => { props.onClickOut(); }} width='30px' justify='center' padding='10px 20px'><CrossImg src={close} /></FlexDiv>}

				{!isForceEvent && <Category>
					{/* Champs de sociétés */}
					<OpenContainer justify='space-between' onClick={() => {
						setIsOpenCompanyColumns(!isOpenCompanyColumns);
					}}>
						<Translate id='report_editor.crm_fields.CompanyColumn' />
						<Open isOpen={isOpenCompanyColumns} size={15} />
					</OpenContainer>
					{isOpenCompanyColumns && <>
						{report.group_by === 'Company' && <>
							{translateToString('report_editor.crm_fields.Owner').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'Owner' }); }}><FieldName><Translate id='report_editor.crm_fields.Owner' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Company').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'Id' }); }}><FieldName><Translate id='report_editor.crm_fields.Company' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Count').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked('CompanyCount'); }}><FieldName><Translate id='report_editor.crm_fields.Count' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Email').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'Email' }); }}><FieldName><Translate id='report_editor.crm_fields.Email' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Phone').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'Phone' }); }}><FieldName><Translate id='report_editor.crm_fields.Phone' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Website').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'Website' }); }}><FieldName><Translate id='report_editor.crm_fields.Website' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.CompanyAddress').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'CompanyAddress' }); }}><FieldName><Translate id='report_editor.crm_fields.CompanyAddress' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.CompanyPostCode').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'CompanyPostCode' }); }}><FieldName><Translate id='report_editor.crm_fields.CompanyPostCode' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.CompanyCity').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'CompanyCity' }); }}><FieldName><Translate id='report_editor.crm_fields.CompanyCity' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.CompanyCountry').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'CompanyCountry' }); }}><FieldName><Translate id='report_editor.crm_fields.CompanyCountry' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Status').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CompanyColumn': 'Status' }); }}><FieldName><Translate id='report_editor.crm_fields.Status' /></FieldName></FieldContainer>}


						</>}
						{additionalColumnsAtom.filter(ac => ac.name.includes(search ?? '')).map(ac => <FieldContainer key={ac.id} onClick={() => { props.onChildPicked({ 'AdditionalColumn': ac.id }); }}><FieldName>{ac.name}</FieldName></FieldContainer>)}
					</>}
				</Category>}

				{/* Champs de formulaire */}
				{!isForceEvent && <Category>
					<OpenContainer justify='space-between' onClick={() => {
						setIsOpenFormFields(!isOpenFormFields);
					}}>
						<Translate id='report_editor.form_fields' />
						<Open isOpen={isOpenFormFields} size={15} />
					</OpenContainer>
					{isOpenFormFields && <>

						{/* Champs d'instances */}
						<div style={{ marginLeft: '10px' }}>
							<OpenContainer justify='space-between' onClick={() => {
								setIsOpenInstanceFields(!isOpenInstanceFields);
							}
							}>
								<Translate id='report_editor.crm_fields.InstanceColumn' />
								<Open isOpen={isOpenInstanceFields} size={15} />
							</OpenContainer>
							{isOpenInstanceFields && <>
								{translateToString('report_editor.crm_fields.MadeAt').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'InstanceColumn': 'MadeAt' }); }}><FieldName><Translate id='report_editor.crm_fields.MadeAt' /></FieldName></FieldContainer>}
								{translateToString('report_editor.crm_fields.CreatedBy').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'InstanceColumn': 'CreatedBy' }); }}><FieldName> <Translate id='report_editor.crm_fields.CreatedBy' /></FieldName></FieldContainer>}
								{translateToString('report_editor.crm_fields.Count').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'InstanceColumn': 'Count' }); }}><FieldName><Translate id='report_editor.crm_fields.Count' /></FieldName></FieldContainer>}
							</>}
						</div>						
						{/* Champs de formulaire */}
						<div style={{ marginLeft: '10px' }}>
							{linkedForms && fields && forms.map(f => {
								const thisFormFields = fields.filter(field => linkedForms.some(lf => lf.form_name === f && lf.slug === field.slug));
								return <>
									<PerFormFields
										form={f}
										search={search?.toLowerCase() ?? ''}
										fields={thisFormFields}
										onChildPicked={props.onChildPicked}
									/>
								</>;
							})}
						</div>
					</>}
				</Category>}

				{/* Champs d'évènements */}
				{(isForceEvent === undefined || isForceEvent) && <Category>
					<OpenContainer justify='space-between' onClick={() => {
						setIsOpenEventFields(!isOpenEventFields);
					}}>
						<Translate id='report_editor.crm_fields.EventColumn' />
						<Open isOpen={isOpenEventFields} size={15} />
					</OpenContainer>
					{isOpenEventFields && <>
						<>
							{translateToString('report_editor.crm_fields.EventTypeId').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'EventTypeId' }); }}><FieldName><Translate id='report_editor.crm_fields.EventTypeId' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.UserId').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'UserId' }); }}><FieldName><Translate id='report_editor.crm_fields.UserId' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Id').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'Id' }); }}><FieldName><Translate id='report_editor.crm_fields.Id' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Title').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'Title' }); }}><FieldName><Translate id='report_editor.crm_fields.Title' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.StartDate').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'StartDate' }); }}><FieldName><Translate id='report_editor.crm_fields.StartDate' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.EndDate').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'EndDate' }); }}><FieldName><Translate id='report_editor.crm_fields.EndDate' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.ClientCompanyId').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'ClientCompanyId' }); }}><FieldName><Translate id='report_editor.crm_fields.ClientCompanyId' /></FieldName></FieldContainer>}
							{/* <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'ContactId' }); }}><FieldName><Translate id='report_editor.crm_fields.ContactId' /></FieldName></FieldContainer> */}
							{translateToString('report_editor.crm_fields.EventStatusId').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'EventStatusId' }); }}><FieldName><Translate id='report_editor.crm_fields.EventStatusId' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Incharge').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'Incharge' }); }}><FieldName><Translate id='report_editor.crm_fields.Incharge' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Description').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'EventColumn': 'Description' }); }}><FieldName><Translate id='report_editor.crm_fields.Description' /></FieldName></FieldContainer>}
						</>
					</>}
				</Category>}
				{/* Champs de promotion */}
				<Category>
					<OpenContainer justify='space-between' onClick={() => {
						setIsOpenPromotionFields(!isOpenPromotionFields);
					}}>
						<Translate id='report_editor.crm_fields.Campaign' />
						<Open isOpen={isOpenPromotionFields} size={15} />
					</OpenContainer>
					{isOpenPromotionFields && <>
						{translateToString('report_editor.crm_fields.Campaign').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'CampaignColumn': 'Id' }); }}><FieldName><Translate id='report_editor.crm_fields.Campaign' /></FieldName></FieldContainer>}
					</>}
				</Category>

				{!isForceEvent && <Category>
					{/* Champs de produit */}
					{<>
						<OpenContainer justify='space-between' onClick={() => {
							setIsOpenProductFields(!isOpenProductFields);
						}}>
							<Translate id='report_editor.crm_fields.ProductColumn' />
							<Open isOpen={isOpenProductFields} size={15} />
						</OpenContainer>
						{isOpenProductFields && <>
							{translateToString('report_editor.crm_fields.Product').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Uuid' }); }}><FieldName><Translate id='report_editor.crm_fields.Product' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Owner').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Owner' }); }}><FieldName><Translate id='report_editor.crm_fields.Owner' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Category').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Category' }); }}><FieldName><Translate id='report_editor.crm_fields.Category' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Brand').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Brand' }); }}><FieldName><Translate id='report_editor.crm_fields.Brand' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Reference').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Reference' }); }}><FieldName><Translate id='report_editor.crm_fields.Reference' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Barcode').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Barcode' }); }}><FieldName><Translate id='report_editor.crm_fields.Barcode' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.CodeUpc').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'CodeUpc' }); }}><FieldName><Translate id='report_editor.crm_fields.CodeUpc' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Stock').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Stock' }); }}><FieldName><Translate id='report_editor.crm_fields.Stock' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Price').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Price' }); }}><FieldName><Translate id='report_editor.crm_fields.Price' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Outer').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Outer' }); }}><FieldName><Translate id='report_editor.crm_fields.Outer' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Width').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Width' }); }}><FieldName><Translate id='report_editor.crm_fields.Width' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Height').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Height' }); }}><FieldName><Translate id='report_editor.crm_fields.Height' /></FieldName></FieldContainer>}
							{translateToString('report_editor.crm_fields.Depth').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked({ 'ProductColumn': 'Depth' }); }}><FieldName><Translate id='report_editor.crm_fields.Depth' /></FieldName></FieldContainer>}
						</>}
					</>}
				</Category>}

				{/* Fonctions */}
				<Category>
					<OpenContainer justify='space-between' onClick={() => {
						setIsOpenFunctions(!isOpenFunctions);
					}}>
						<Translate id='Functions' />
						<Open isOpen={isOpenFunctions} size={15} />
					</OpenContainer>

					{isOpenFunctions && <>
						{'Coalesce'.toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => {
							props.onChildPicked({
								'Formula': {
									'Coalesce': []
								}
							});
						}}>
							<FieldName>Coalesce</FieldName>
						</FieldContainer>}
						{translateToString('report_editor.crm_fields.unit').toLowerCase().includes((search ?? '').toLowerCase()) && <FieldContainer onClick={() => { props.onChildPicked('Unit'); }}><FieldName><Translate id='report_editor.unit' /> </FieldName></FieldContainer>}
					</>}
				</Category>
			</>}
		</LeftModalContent>
	</>;
}

function RemoveColumnFromPath(path: string, column: Column, setColumn: (value: Column | undefined) => void): void {

	if (path === '') {
		setColumn(undefined);
		return;
	}
	const temp_path = path.split('.');
	const toRemoveType = temp_path.splice(temp_path.length - 1, 1)[0];

	temp_path.length = temp_path.length - 1;

	const newPath = temp_path.join('.');

	if (newPath === '') {
		if (toRemoveType === 'numerator') {
			assert(typeof column != 'string' && 'Computed' in column, 'Attempted to remove numerator from non-computed column at root');
			setColumn(column.Computed.denominator);
		}
		else if (toRemoveType === 'denominator') {
			assert(typeof column != 'string' && 'Computed' in column, 'Attempted to remove denominator from non-computed column at root');
			setColumn(column.Computed.numerator);
		}
		return;
	} else {
		if (toRemoveType === 'numerator') {
			assert(typeof column != 'string' && ('Computed' in column || 'Formula' in column || 'NamedColumn' in column), 'Attempted to remove numerator from non-computed column');
			_.set(column, newPath, _.get(column, newPath + '.Computed.denominator'));
		}
		else if (toRemoveType === 'denominator') {
			assert(typeof column != 'string' && ('Computed' in column || 'Formula' in column || 'NamedColumn' in column), 'Attempted to remove denominator from non-computed column');
			_.set(column, newPath, _.get(column, newPath + '.Computed.numerator'));
		}
	}

	setColumn(_.cloneDeep(column));
}

function ComputedOptionsEditor(props: {
	path: string,
	computed: Extract<Column, { 'Computed' }>,
	onClose: () => void
}): JSX.Element {
	const { column, setColumn } = React.useContext(GenericColumnContext);

	const [newName, setNewName] = React.useState<string>(props.computed.Computed.name);
	const [newPercentage, setNewPercentage] = React.useState(props.computed.Computed.percentage);

	const ValueTypes: DropdownData[] = [
		{ value: false, label: translateToString('report_editor.value') },
		{ value: true, label: translateToString('report_editor.percentage') },
	];
	return <>
		<FilterText onClick={() => props.onClose()}><StyledSpan><Translate id='back' /></StyledSpan></FilterText>
		<FlexDiv flow='column' align='stretch' gap='16px' overflow-x='hidden' width='100%' padding='10px'>
			<FlexDiv gap='4px' flow='column' align='stretch'>
				<InputDescription>
					<Translate id='name' />
				</InputDescription>
				<Input name='name' type="text" value={newName ?? ''} inputStyle={{ borderRadius: '5px', width: 'calc(25vw - 40px)', height: '50px', padding: '0px 10px' }} onChange={e => {
					setNewName(e.trim());
				}} />
			</FlexDiv>
			<FlexDiv gap='4px' flow='column' align='stretch'>
				<InputDescription>
					<Translate id='report_editor.value_type' />
				</InputDescription>
				<Dropdown
					dropdownStyle={{ width: 'calc(25vw - 40px)', height: '50px', containerWidth: 'calc(25vw - 40px)', optionWidth: 'calc(25vw - 40px)' }}
					name='aggregation'
					datalist={ValueTypes}
					selectedValue={ValueTypes.find(a => a.value === newPercentage) ?? ValueTypes[0]}
					onChange={e => setNewPercentage(e.value)}
					readOnly
				/>
			</FlexDiv>

			<DefaultButton
				width='fit-content'
				disabled={
					props.computed.Computed.name === newName &&
					props.computed.Computed.percentage === newPercentage &&
					newName !== ''
				}
				onClick={() => {
					props.computed.Computed.name = newName;
					props.computed.Computed.percentage = newPercentage;
					setColumn(_.cloneDeep(column));
					props.onClose();
				}}><Translate id='save' /></DefaultButton>
		</FlexDiv>
	</>;
}

const FieldTitle = styled.span`
	font-size: 14px;
	font-weight: 500;
`;

const InputDescription = styled.span`
	font-size: 12px;
	font-weight: 400;
	color: ${DarkGreySidely2};
`;

const DataFilter = styled(LineHoverDiv)`
	display: flex;
	flex-direction: column;
	padding-left: 14px;
	width: 100%;
	margin-left: 6px;
`;

function FieldOptionsEditor(props: {
	path: string,
	field: Extract<Column, { 'Field' }>,
	onClose: () => void
}): JSX.Element {
	const fieldsAtom = useRecoilValue(AFormFields);
	const { column, setColumn } = React.useContext(GenericColumnContext);

	const [newName, setNewName] = React.useState(props.field.Field.name);
	const [newAggregation, setNewAggregation] = React.useState(props.field.Field.aggregation);
	const [newOperation, setNewOperation] = React.useState(props.field.Field.operation);
	const [filterFormatted, setFilterFormatted] = React.useState<CombinableFilter<string>>();
	const [filterValue, setFilterValue] = React.useState<FilterValueResultUnion>();
	const [filterModified, setFilterModified] = React.useState(false);
	const products = useRecoilValue(AProducts);
	const campaigns = Object.values(useRecoilValue(ACampaigns));
	const [loaderState, setLoaderState] = React.useState<LoadingState>('loaded');
	const [metadataList, setMetadataList] = React.useState<string[]>([]);

	const AggregationTypes: DropdownData[] = [
		{ value: 'Sum', label: translateToString('report_editor.aggregation_type.sum') },
		{ value: 'Average', label: translateToString('report_editor.aggregation_type.average') },
	];

	const OperationTypes: DropdownData[] = [
		{ value: 'TrueCount', label: translateToString('report_editor.operation_types.true_count') },
		{ value: undefined, label: translateToString('report_editor.operation_types.none') },
		{ value: 'ToBoolean', label: translateToString('report_editor.operation_types.to_boolean') },
		{ value: 'TotalCount', label: translateToString('report_editor.operation_types.total_count') },
		{ value: 'Count', label: translateToString('report_editor.operation_types.count') },
	];

	React.useEffect(() => {
		setLoaderState('loading');
		getDistinctMetadata().then(m => {
			setMetadataList(m);
			setLoaderState('loaded');
		});
		if (props.field.Field.filters) {
			getAssortments().then((assortments) => {
				const reverseFilters = reverseConvertFilters(props.field.Field.filters as FilterTree, assortments.data.assortments, products, campaigns);
				if (!('combinator' in reverseFilters))
					setFilterValue({ array: [reverseFilters], combinator: 'and' });
				else
					setFilterValue(reverseFilters);
			});
		}
	}, []);

	function checkSaveEnabled(): boolean {
		return (props.field.Field.name !== newName ||
			props.field.Field.aggregation !== newAggregation ||
			props.field.Field.operation !== newOperation) || filterModified;
	}

	return <>

		<FilterText onClick={() => props.onClose()}><StyledSpan><Translate id='back' /></StyledSpan></FilterText>
		<FlexDiv flow='column' align='stretch' gap='16px' overflow-x='hidden' width='100%' padding='10px'>
			<FieldTitle >{newName ?? fieldsAtom.find(f => f.id === props.field.Field.id)?.name}</FieldTitle>

			<FlexDiv gap='4px' flow='column' align='stretch'>
				<InputDescription>
					<Translate id='report_editor.field_name' />
				</InputDescription>
				<Input name='name' type="text" disabled={true} inputStyle={{ borderRadius: '5px', width: 'calc(25vw - 40px)', height: '50px', padding: '0px 10px', color: '#b5b5b5' }} value={fieldsAtom.find(f => f.id === props.field.Field.id)?.name} />
			</FlexDiv>

			<FlexDiv gap='4px' flow='column' align='stretch'>
				<InputDescription>
					<Translate id='report_editor.totals_representation' />
				</InputDescription>
				<Dropdown
					dropdownStyle={{ width: 'calc(25vw - 40px)', height: '50px', containerWidth: 'calc(25vw - 40px)', optionWidth: 'calc(25vw - 40px)' }}
					name='aggregation'
					datalist={AggregationTypes}
					selectedValue={AggregationTypes.find(a => a.value === newAggregation) ?? AggregationTypes[0]}
					onChange={e => setNewAggregation(e.value)}
					readOnly
				/>
			</FlexDiv>

			<FlexDiv gap='4px' flow='column' align='stretch'>
				<InputDescription>
					<Translate id='report_editor.calculation_method' />
				</InputDescription>
				<Dropdown
					dropdownStyle={{ width: 'calc(25vw - 40px)', height: '50px', containerWidth: 'calc(25vw - 40px)', optionWidth: 'calc(25vw - 40px)' }}
					name='operation'
					datalist={OperationTypes}
					selectedValue={OperationTypes.find(a => a.value === newOperation) ?? OperationTypes[0]}
					onChange={e => setNewOperation(e.value)}
					readOnly
				/>
			</FlexDiv>

			<TitleAndChild title={translateToString('report_editor.filter')} defaultOpen addDiv margin='0 0 0 0'>
				<DataFilter >
					{loaderState === 'loading' ?
						<Loader width='30px' />
						:
						<FilterList
							isReportMetadata
							extractFilters
							title=''
							onChange={(filterResult) => {
								setFilterFormatted(filterResult.formatted as CombinableFilter<string>);
								setFilterModified(true);
								setFilterValue(filterResult.values);
							}}
							filterList={[{
								category: 'metadata', filters: metadataList.map((m) => {
									let filterType: FilterType = 'string';
									if (m === 'catalogue' || m === 'product' || m === 'campaign' || m === 'period')
										filterType = m;
									if (m === 'picking')
										filterType = 'nullable';
									return { id: m, name: m, type: filterType };
								})
							}]}
							filterValues={filterValue}
							isSummaryOpen={filterValue !== undefined}
							overflow='visible'
						/>
					}
				</DataFilter>
			</TitleAndChild>

			<DefaultButton width='fit-content' disabled={
				!checkSaveEnabled()
			} onClick={() => {
				props.field.Field.name = newName;
				props.field.Field.aggregation = newAggregation;
				props.field.Field.operation = newOperation;
				if (filterFormatted) {
					props.field.Field.filters = filterFormatted;
				}
				setColumn(_.cloneDeep(column));
				props.onClose();
			}}><Translate id='save' /></DefaultButton>
		</FlexDiv>
	</>;
}

export function ToNamedColumnOrNot(props: { default?: string }): JSX.Element {
	const { column, setColumn } = React.useContext(GenericColumnContext);
	const [isNamed, setIsNamed] = React.useState(column && typeof column != 'string' && 'NamedColumn' in column);
	const namedColumn = column && isNamed && typeof column !== 'string' && 'NamedColumn' in column;
	return <>
		<Input inputStyle={{ height: '30px', width:'19vw', backgroundColor: 'white', display: 'inline', borderRadius: '5px', padding: '0 0 0 10px' }} 
			name='text' 
			type='text' 
			value={namedColumn ? column.NamedColumn.name : props.default ?? ''} 
			onChange={
				(e) => {
					if (namedColumn) {
						if (e === '' || e === undefined) {
							setColumn(column.NamedColumn.column);
							setIsNamed(false);
						} else {
							column.NamedColumn.name = e;
							setColumn({ ...column });
						}
					}
					else {
						if (column) {
							setColumn({ 'NamedColumn': { name: e, column: column } });
							setIsNamed(true);
						}
					}
				}
			} />
	</>;
}

function ListAndEditColumnsArray(props: { path: string, columns: Column[] }): JSX.Element {
	const { column, setColumn } = React.useContext(GenericColumnContext);
	const localColumns = _.get(column, props.path);
	const [childPicker, setChildPicker] = React.useState(false);
	

	assert(column && typeof column !== 'string' && ('NamedColumn' in column || 'Computed' in column || 'Formula' in column), 'localColumn is not the right type');
	
	
	return <>
		<Popup
			isOpen={childPicker}
			onClickOut={() => setChildPicker(false)}
			popupMode={PopupMode.Details}
			content={
				<ChildPicker
					onClickOut={() => setChildPicker(false)}
					onChildPicked={c => {
						const newCols = localColumns.concat(c);
						_.set(column, props.path, newCols);
						setColumn(column);
						setChildPicker(false);
					}}
				/>
			}

		/>


		{localColumns.map((col, index) => {
			return <>
				<RecursiveEditor path={props.path + '[' + index + ']'} secondary={false} />
				<br />
			</>;
		})}

		<Add
			onClick={() => {
				setChildPicker(true);
			}}
		/>
	</>;
}

function RecursiveEditor(props: { path: string, secondary: boolean }): JSX.Element {
	const { column, setColumn } = React.useContext(GenericColumnContext);
	const [picking, setPicking] = React.useState<'mul' | 'add' | 'sub' | 'div' | undefined>(undefined);
	const [optionsOpen, setOptionsOpen] = React.useState(false);
	const [computedOptionsOpen, setComputedOptionsOpen] = React.useState(false);


	let localColumn = column;
	let point = '';
	if (props.path != '') {
		localColumn = _.get(column, props.path);
		point = '.';
	}
	if (localColumn === undefined) {
		return <>Column is undefined</>;
	}

	const secondary = props.secondary ? true : (typeof localColumn !== 'string' && 'NamedColumn' in localColumn);
	const borders = !(typeof localColumn !== 'string' && 'NamedColumn' in localColumn);

	return <>

		<Box border={borders} secondary={secondary}>
			{typeof localColumn !== 'string' && 'NamedColumn' in localColumn && <>
				<RecursiveEditor path={props.path + point + 'NamedColumn.column'} secondary={props.secondary} />
			</>}
			{typeof localColumn !== 'string' && 'Formula' in localColumn && <>
				<Popup isOpen={picking != undefined} onClickOut={() => setPicking(undefined)} popupMode={PopupMode.Details} content={<ChildPicker onClickOut={() => setPicking(undefined)} onChildPicked={p => {
					if (localColumn) {
						assert(picking !== undefined, 'Picking is undefined');
						const newCol: Column = {
							'Computed': {
								additional_column: undefined,
								denominator: p,
								numerator: structuredClone(localColumn),
								operator: picking,
								percentage: true,
								name: translateToString('report_editor.new_calculated')
							}
						};
						if (props.path === '') {
							setColumn(newCol);
						}
						else if (column && typeof column != 'string') {
							_.set(column, props.path, newCol);
							setColumn({ ...column });
						}
					}
					setPicking(undefined);
				}} />} />

				<Popup
					isOpen={optionsOpen}
					onClickOut={() => setOptionsOpen(false)}

					popupMode={PopupMode.Details}
					content={
						<>
							<StyledSpan onClick={() => setOptionsOpen(false)}><Translate id='back' /></StyledSpan>
							<br />
							<br />
							<Translate id='report_editor.formula.options' />
							<br />
							{'Coalesce' in localColumn.Formula && <>
								<ListAndEditColumnsArray path={props.path + point + 'Formula.Coalesce'} columns={localColumn.Formula.Coalesce} />
							</>}
						</>
					}
				/>

				<FlexDiv>
					Fonction: &nbsp; {'Coalesce' in localColumn.Formula && <span>Coalesce</span>}
					{<Operation onClick={() => setOptionsOpen(true)} enabled={false}>
						<img style={{ marginBottom: '2px' }} height={'15px'} src={SettingIcon} alt="" />
					</Operation>}
					{<Operation onClick={
						() => {
							if (localColumn && column) {
								RemoveColumnFromPath(props.path, column, setColumn);
							}
						}
					} enabled={false}>
						<img style={{ marginBottom: '2px' }} height={'15px'} src={RemoveIcon} alt="" />
					</Operation>}
				</FlexDiv>

				<FlexDiv gap='4px'>
					<NewOperation onClick={() => setPicking('div')}>÷</NewOperation>
					<NewOperation onClick={() => setPicking('mul')}>×</NewOperation>
					<NewOperation onClick={() => setPicking('add')}>+</NewOperation>
					<NewOperation onClick={() => setPicking('sub')}>-</NewOperation>
				</FlexDiv>
			</>}
			{(typeof localColumn === 'string' || 'Field' in localColumn || 'AdditionalColumn' in localColumn || 'CompanyColumn' in localColumn || 'InstanceColumn' in localColumn || 'ProductColumn' in localColumn || 'UserColumn' in localColumn) && <>
				<FlexDiv flow='column' gap='6px' align='stretch'>
					<div>
						<Popup isOpen={picking != undefined} onClickOut={() => setPicking(undefined)} popupMode={PopupMode.Details} content={<ChildPicker onClickOut={() => setPicking(undefined)} onChildPicked={p => {
							if (localColumn) {
								assert(picking !== undefined, 'Picking is undefined');
								const newCol: Column = {
									'Computed': {
										additional_column: undefined,
										denominator: p,
										numerator: structuredClone(localColumn),
										operator: picking,
										percentage: true,
										name: translateToString('report_editor.new_calculated')
									}
								};
								if (props.path === '') {
									setColumn(newCol);
								}
								else if (column && typeof column != 'string') {
									_.set(column, props.path, newCol);
									setColumn({ ...column });
								}
							}
							setPicking(undefined);
						}} />} />
						<FlexDiv gap='6px'>
							<FieldOrComputedDisplay column={localColumn} />
							{typeof localColumn != 'string' && ('Field' in localColumn || 'Computed' in localColumn) &&
								<Operation onClick={() => setOptionsOpen(true)} enabled={false}>
									<img style={{ marginBottom: '2px' }} height={'15px'} src={SettingIcon} alt="" />
								</Operation>
							}
							{<Operation onClick={
								() => {
									if (localColumn && column) {
										RemoveColumnFromPath(props.path, column, setColumn);
									}
								}
							} enabled={false}>
								<img style={{ marginBottom: '2px' }} height={'15px'} src={RemoveIcon} alt="" />
							</Operation>}
						</FlexDiv>
					</div>
					<FlexDiv gap='4px'>
						<NewOperation onClick={() => setPicking('div')}>÷</NewOperation>
						<NewOperation onClick={() => setPicking('mul')}>×</NewOperation>
						<NewOperation onClick={() => setPicking('add')}>+</NewOperation>
						<NewOperation onClick={() => setPicking('sub')}>-</NewOperation>
					</FlexDiv>
					{optionsOpen && typeof localColumn !== 'string' && 'Field' in localColumn && <Popup
						popupStyle={{ noTransition: true, overflow: 'auto' }}
						isOpen={optionsOpen}
						onClickOut={() => setOptionsOpen(false)}
						popupMode={PopupMode.Details}
						content={
							<FieldOptionsEditor
								path={props.path}
								field={localColumn}
								onClose={() => setOptionsOpen(false)}
							/>
						} />}
				</FlexDiv>
			</>}
			{typeof localColumn != 'string' && 'Computed' in localColumn && localColumn.Computed && <>

				{<Popup isOpen={computedOptionsOpen} onClickOut={() => setComputedOptionsOpen(false)} popupMode={PopupMode.Details} popupStyle={{ overflow: 'auto' }} content={
					<ComputedOptionsEditor path={props.path} computed={localColumn} onClose={() => setComputedOptionsOpen(false)} />
				} />}
				<FlexDiv gap='6px' padding='0px 0px 10px 0px'>
					<span>{localColumn.Computed.name}</span>
					<Operation onClick={() => setComputedOptionsOpen(true)} enabled={false}>
						<img style={{ marginBottom: '2px' }} height={'15px'} src={SettingIcon} alt="" />
					</Operation>
				</FlexDiv>
				{<RecursiveEditor path={props.path + point + 'Computed.numerator'} secondary={!props.secondary} />}
				<FlexDiv gap='4px' padding='6px'>
					<Operation onClick={() => {
						if (localColumn && typeof localColumn != 'string' && 'Computed' in localColumn) {
							localColumn.Computed.operator = 'div';
							if (!props.path) {
								if (typeof column != 'string')
									setColumn({ ...localColumn });
							} else if (column && typeof column != 'string') {
								_.set(column, props.path, localColumn);
								setColumn({ ...column });
							}
						}
					}} enabled={localColumn.Computed.operator === undefined || localColumn.Computed.operator === 'div'}>÷</Operation>
					<Operation onClick={() => {
						if (localColumn && typeof localColumn != 'string' && 'Computed' in localColumn) {
							localColumn.Computed.operator = 'mul';
							if (!props.path) {
								if (typeof column != 'string')
									setColumn({ ...localColumn });
							} else if (column && typeof column != 'string') {
								_.set(column, props.path, localColumn);
								setColumn({ ...column });
							}
						}
					}} enabled={localColumn.Computed.operator === 'mul'}>×</Operation>
					<Operation onClick={() => {
						if (localColumn && typeof localColumn != 'string' && 'Computed' in localColumn) {
							localColumn.Computed.operator = 'add';
							if (!props.path) {
								if (typeof column != 'string')
									setColumn({ ...localColumn });
							} else if (column && typeof column != 'string') {
								_.set(column, props.path, localColumn);
								setColumn({ ...column });
							}
						}
					}} enabled={localColumn.Computed.operator === 'add'}>+</Operation>
					<Operation onClick={() => {
						if (localColumn && typeof localColumn != 'string' && 'Computed' in localColumn) {
							localColumn.Computed.operator = 'sub';
							if (!props.path) {
								if (typeof column != 'string')
									setColumn({ ...localColumn });
							} else if (column && typeof column != 'string') {
								_.set(column, props.path, localColumn);
								setColumn({ ...column });
							}
						}
					}} enabled={localColumn.Computed.operator === 'sub'}>-</Operation>
				</FlexDiv>
				{<RecursiveEditor path={props.path + point + 'Computed.denominator'} secondary={!props.secondary} />}
			</>}
		</Box>
	</>;

}

export function Editor(props: { path: string, column?: Column, index: number, onSave: () => void, isCalcField?: boolean, lines?: boolean, explode?: boolean }): JSX.Element {
	return <GenericColumnProvider column={props.column ? JSON.stringify(props.column) : undefined}>
		<_Editor path={props.path} lines={props.lines} explode={props.explode} column={props.column} index={props.index} onSave={props.onSave} isCalcField={props.isCalcField} />
	</GenericColumnProvider>;
}

const StyledSpan = styled.span`
	color: ${BlueSidely};
	font-size: 12px;
	padding: 10px;
	cursor: pointer;
	&::before {
		content: '< ';
	}
`;

function _Editor(props: { path: string, column?: Column, index: number, onSave: () => void, isCalcField?: boolean, lines?: boolean, explode?: boolean }): JSX.Element {
	const { report, setReport } = React.useContext(GenericEditorContext);
	const [pickedColumn, setPickedColumn] = React.useState<Column | undefined>(undefined);
	const [save, setSave] = React.useState(false);
	const { column, setColumn } = React.useContext(GenericColumnContext);
	const [saveButton, setSaveButton] = React.useState(false);

	React.useEffect(() => {
		if (props.column) {
			setColumn(structuredClone(props.column));
		}
	}, [props.column]);

	React.useEffect(() => {
		if (save && (pickedColumn && column)) {
			if (props.column) {
				_.set(report, props.path + '[' + props.index + ']', column);
				setReport({ ...report });
			}
			else {
				const columnsArray = _.get(report, props.path);
				columnsArray.splice(props.index, 0, column);
				if (props.lines) {
					if (report.explode_by !== undefined) {
						setReport({ ...report, new_group_by: (report.new_group_by !== undefined) ? report.new_group_by + 1 : 1, explode_by: (report.new_group_by !== undefined) ? report.new_group_by + 1 : 1 });
					} else {
						setReport({ ...report, new_group_by: (report.new_group_by !== undefined) ? report.new_group_by + 1 : 1 });
					}
				} else {
					if (props.explode) {
						setReport({ ...report, explode_by: (report.new_group_by !== undefined) ? report.new_group_by : 0 });
					} else {
						setReport({ ...report });
					}
				}
			}
			setSave(false);
			if (props.isCalcField)
				setSaveButton(false);
			props.onSave();
		}
		else if (save && column) {
			_.set(report, props.path + '[' + props.index + ']', column);
			setReport({ ...report });
			setSave(false);
			if (props.isCalcField)
				setSaveButton(false);
			props.onSave();
		}
	}, [save, column]);

	React.useEffect(() => {
		if (_.isEqual(column, props.column)) {
			setSaveButton(false);
		}

		else {
			setSaveButton(true);
		}
	}, [column]);

	if (column) {
		return <>
			<FlexDiv flow='column' align='stretch' width='100%'>
				<StyledSpan onClick={() => setColumn(undefined)}><Translate id='back' /></StyledSpan>
				<FlexDiv flow='column' align='start' gap='6px' overflow='auto' height='100%' padding='10px'>
					<FlexDiv flow='column' align='stretch' gap='3px' padding='0 10px 0 15px'>
						<Translate id="report_editor.name_column"/>
						<ToNamedColumnOrNot />
					</FlexDiv>
					<RecursiveEditor path={''} secondary={false} />
					<DefaultButton disabled={!saveButton} onClick={() => setSave(true)}><Translate id='save' /></DefaultButton>
				</FlexDiv>
			</FlexDiv>
		</>;
	}
	else {
		return <>
			<ChildPicker onChildPicked={p => { setColumn(p); setPickedColumn(p); }} onClickOut={() => props.onSave()} isInGroupBy={props.lines} isCalcField={props.isCalcField !== undefined && props.isCalcField} />
		</>;
	}
}

const Hidden = styled.div<{ visible: boolean }>`
	visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

function ColumnDisplay(props: { path: string, column: Column, index: number, isColumnAdd?: boolean }): JSX.Element {
	const { report, setReport } = React.useContext(GenericEditorContext);
	const [viewSettings, setViewSettings] = React.useState(false);
	const [viewAdd, setViewAdd] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [openNew, setOpenNew] = React.useState(false);

	const isInGroupBy = (props.index < (report.new_group_by ?? 0));
	const explode = props.isColumnAdd;
	const newGroupby = report.new_group_by ?? 0;
	const explodeBy = report.explode_by ?? 0;

	const alert = useAlert();
	return <>
		<Popup popupMode={PopupMode.Details} onClickOut={() => setOpenEdit(false)} isOpen={openEdit} popupStyle={{ overflow: 'auto' }} content={
			<>
				<br />
				<Editor path={props.path} column={props.column} index={props.index} onSave={() => setOpenEdit(false)} explode={explode} />
			</>
		} />
		<Popup popupMode={PopupMode.Details} onClickOut={() => setOpenNew(false)} isOpen={openNew} popupStyle={{ overflow: 'auto' }} content={
			<>
				<br />
				<Editor lines={isInGroupBy} path={props.path} index={props.index + 1} onSave={() => setOpenNew(false)} explode={explode} />
			</>
		} />
		<FlexDiv flow='column' justify='flex-start' gap='6px' align='start' onMouseEnter={() => setViewSettings(true)} onMouseLeave={() => setViewSettings(false)}>
			<div>
				<FlexDiv gap='6px' align='stretch'>
					<FieldOrComputedDisplay column={props.column} />
					<div>
						{viewSettings && props.column !== 'Index' && props.column && <SettingsButton onClick={() => { setOpenEdit(true); }} src={SettingIcon} />}
						{viewSettings && props.column !== 'Index' && props.index >= 0 && props.column && <>
							<SettingsButton onClick={() => {
								const columnsArray = _.get(report, props.path);
								columnsArray.splice(props.index, 1);
								if (explode) {
									setReport({ ...report, explode_by: undefined });
								} else if (isInGroupBy) {
									setReport({
										...report,
										new_group_by: (newGroupby - 1),
										explode_by: (explodeBy ? explodeBy - 1 : undefined)
									});
								} else {
									setReport({ ...report });
								}
							}} src={RemoveIcon} /></>}
						{viewSettings && props.column !== 'Index' && <SettingsButton
							src={Copy}
							onClick={() => {
								alert({
									title: 'Duplication de champ',
									content: <>Voulez allez dupliquer ce champ</>,
								}).then(res => {
									if (res === AlertRes.Ok) {
										const columnsArray = _.get(report, props.path);
										const temp = columnsArray[props.index];
										columnsArray.splice(props.index + 1, 0, temp);
										if (isInGroupBy) {
											if (report.explode_by) {
												report.explode_by++;
											}
											report.new_group_by++;
										}
										setReport({ ...report });
									}
								});
							}
							}
						/>}
						{props.index > 0 && props.column !== 'Index' && !props.isColumnAdd && viewSettings && (props.index - 1 !== (report.explode_by ?? 0) || props.index == 1 && (report.explode_by ?? 0) == 0) && props.column && <SettingsButton onClick={() => {
							//Move up
							const columnsArray = _.get(report, props.path);
							const temp = columnsArray[props.index];
							columnsArray[props.index] = columnsArray[props.index - 1];
							columnsArray[props.index - 1] = temp;
							setReport({ ...report });
						}} src={Up} />}
						{!(props.index === _.get(report, props.path).length - 1) && !props.isColumnAdd && props.column !== 'Index' && viewSettings && props.index >= 0 && props.index + 1 !== (report.explode_by ?? 0) && props.column && <SettingsButton onClick={() => {
							//Move down
							const columnsArray = _.get(report, props.path);
							const temp = columnsArray[props.index];
							columnsArray[props.index] = columnsArray[props.index + 1];
							columnsArray[props.index + 1] = temp;
							setReport({ ...report });
						}} src={Down} />}
					</div>
				</FlexDiv>
			</div>
			<FlexDiv width='100%' onMouseEnter={() => setViewAdd(true)} onMouseLeave={() => setViewAdd(false)}>
				{!explode && <Hidden visible={viewAdd}><Add size='18px' onClick={() => { setOpenNew(true); }} /></Hidden>}
			</FlexDiv>
		</FlexDiv>
	</>;
}

export function RecursivePanelEditor(props: { path: string, panel: Panel, only_lines?: boolean, only_columns?: boolean }): JSX.Element {
	const { report, setReport } = React.useContext(GenericEditorContext);
	const [open, setOpen] = React.useState(false);
	const [explode, setExplode] = React.useState(false);

	const COLUMN = (report.explode_by !== undefined) ? 1 : 0;
	const LINES = report.new_group_by ?? 0;


	return <>
		<Popup popupMode={PopupMode.Details} onClickOut={() => setOpen(false)} isOpen={open} content={
			<>
				<Editor explode={explode} path={props.path + '.columns'} lines={props.only_lines} index={props.only_lines ? 0 : props.only_columns ? LINES : LINES} onSave={() => { setOpen(false); setExplode(false); }} />
			</>
		} />
		<FlexDiv flow='column' align='stretch' gap='6px'>
			{/* Lignes */}
			{props.only_lines && props.panel.columns.slice(0, LINES).map((column, index: number) =>
				<Box key={`${column}[${index}]`} secondary={true} padding='0 10px'>
					<ColumnDisplay key={index} column={column} path={props.path + '.columns'} index={index} />
				</Box>
			)}
			{props.only_lines && (props.panel.columns.slice(0, LINES).length < 1) && <Add onClick={() => { setOpen(true); }} />}

			{/* Colonne */}
			{props.only_columns && props.panel.columns.slice(LINES, LINES + COLUMN).map((column, index: number) =>
				<Box key={`${column}[${index}]`} secondary={true} padding='0 10px'>
					<ColumnDisplay isColumnAdd key={index} column={column} path={props.path + '.columns'} index={LINES - 1 + COLUMN} />
				</Box>
			)}
			{props.only_columns && (props.panel.columns.slice(LINES, LINES + COLUMN).length < 1) && <Add onClick={() => { setExplode(true); setOpen(true); }} />}


			{/* Valeurs */}
			{!props.only_lines && !props.only_columns && props.panel.columns.slice(LINES + COLUMN).map((column, index: number) =>
				<Box key={`${column}[${index}]`} secondary={true} padding='0 10px'>
					<ColumnDisplay key={index} column={column} path={props.path + '.columns'} index={(LINES + COLUMN) + index} />
				</Box>
			)}
			{!props.only_lines && !props.only_columns && (props.panel.columns.slice(LINES + COLUMN).length < 1) && <Add onClick={() => { setOpen(true); }} />}
		</FlexDiv>

	</>;
}

export function RecursiveReportEditor(props: { only_lines?: boolean, only_columns?: boolean }): JSX.Element {
	const { report } = React.useContext(GenericEditorContext);

	const AllFieldTypes: DropdownData[] = [
		{ value: 'Field', label: translateToString('report_editor.all_fields_type.field') },
		{ value: 'Computed', label: translateToString('report_editor.all_fields_type.computed') },
	];
	AllFieldTypes.push({ value: 'ChildCount', label: 'Nombre de ' + DataSources.find(d => d.value === report.group_by)?.label });
	AllFieldTypes.push({ value: 'InstanceCount', label: translateToString('report_editor.number_of') });
	AllFieldTypes.push({ value: 'InstanceDate', label: translateToString('report_editor.made_at') });
	AllFieldTypes.push({ value: 'CreatedBy', label: translateToString('report_editor.created_by') });
	AllFieldTypes.push({ value: 'CompanyOwner', label: translateToString('report_editor.company_owner') });

	return <>
		{report.panels && report.panels.map((panel, index: number) => {
			return <>
				<Box padding='0px' secondary={true}>
					<RecursivePanelEditor only_lines={props.only_lines} only_columns={props.only_columns} key={index} panel={panel} path={'panels[' + index + ']'} />
				</Box>
				{/* {!props.only_lines && <TitleAndChild
					defaultOpen={true}
					localCollapseStyle={{ justifyContent: 'left' }}
					title={translateToString('report_editor.linked_product_to_panel')}
				>
					<Volet>
						<Products products={report.panels[index].products} panelIndex={index} />
					</Volet>
				</TitleAndChild>} */}
			</>;
		})}
	</>;
}