import * as React from 'react';
import Input from '../../../components_v2/input/Input';
import styled from 'styled-components';
import { BorderColor, SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { Translate } from 'react-localize-redux';


const Label = styled.div<{ white?: boolean }>`
	width: 100%;
	font-size: 14px;
	text-align: left;
	flex-shrink: 1;
	padding: 10px;
	user-select: none
`;

const UserInput = styled.div `
    width: 100%;
    font-size: 14px;
    text-align: left;
    flex-shrink: 1;
    padding: 10px;
    user-select: none
`;

const Centered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const PopupTitle = styled.div`
    width: 90%;
    height: 50px;
    margin-top: -40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${BorderColor};
    font-size: 18px;
    font-weight: 700;

    color: ${SidelyBlack};
`;

const ClassicInput = {
	width: '100%', containerWidth: '100%', height: '50px'
};

export function CreateFormModal(props: {
    selectedTemplate: string,
    setSelectedTemplate: (value: string) => void,
    setName: (value: string) => void,
    setStep: (value: number) => void,
}) {
	const [localFormName, setLocalFormName] = React.useState<string>('');
	const formTemplates: DropdownData[] = [
		{ value: 'shelfaudit', label: translateToString('form_builder.shelf_audit.name') },
		{ value: 'order', label: translateToString('form_builder.order.name') },
		{ value: 'promotion', label: 'Promotion' },
		{ value: 'beer', label: 'Beer form' },
		{ value: 'chr', label: 'CHR form' },
		{ value: 'chrpresence', label: 'CHR Presence form' },
	];

	
	return <>
		<div style={{ padding: '50px', height: '355px', width: '580px', backgroundColor: 'white', borderRadius: '5px' }}>
			<PopupTitle> Générer un formulaire </PopupTitle>
			<Label><Translate id='form_builder.template'/></Label>
			<UserInput><Dropdown disabled={true} selectedValue={formTemplates.find(element => element.value === props.selectedTemplate)} dropdownStyle={ClassicInput} name='name' datalist={formTemplates} onChange={(value) => {props.setSelectedTemplate(value.value);}} /></UserInput>
			<Label>{translateToString('name')}</Label>
			<UserInput> <Input onChange={name => { props.setName(name); setLocalFormName(name); }} inputStyle={ClassicInput} name='name' type="dropdown"></Input></UserInput>
			<Centered>
				<DefaultButton disabled={localFormName === ''} onClick={() => props.setStep(2)}>{translateToString('next')}</DefaultButton>
			</Centered>
		</div>
	</>;
}