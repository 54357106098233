import * as React from 'react';
import styled from 'styled-components';

const CollapseContainer = styled.div<{ isOverflow: boolean }>`
    transition: height 0.4s;
    ${p => p.isOverflow ? 'overflow: hidden;' : ''}
`;

export default function Collapse(props: {
  isOpen: boolean
  children
}) {
	const { children } = props;
	const ref = React.useRef<HTMLDivElement>(null);
	const [height, setHeight] = React.useState<number | undefined>(ref?.current?.clientHeight ?? (props.isOpen ? undefined : 0));
	const [animation, setAnimation] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen);

	React.useEffect(() => {
		if (!height || !isOpen || (ref.current == null)) return undefined;
		const resizeObserver = new ResizeObserver((el) => {
			setHeight(el[0].contentRect.height);
		});
		resizeObserver.observe(ref.current);
		return () => {
			resizeObserver.disconnect();
		};
	}, [height, isOpen]);

	React.useEffect(() => {
		if (ref.current != null) {
			setAnimation(true);
			setIsOpen(props.isOpen);
			if (props.isOpen) setHeight(ref.current?.getBoundingClientRect().height);
			else setHeight(0);
		}
	}, [props.isOpen, ref.current]);

	return (
		<CollapseContainer style={{ height }} isOverflow={!isOpen || animation} onTransitionEnd={() => setAnimation(false)}>
			<div ref={ref}>
				{children}
			</div>
		</CollapseContainer>
	);
}
