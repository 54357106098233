/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { getDate } from '../store/actions/events.action';
import calendarImage from 'images/icon/small_calendar2.svg';

class DateField extends Component {
	state = {};

	setDate = val => {
		this.props.getDate(val);
		return val;
	};

	render() {
		const {
			input,
			label,
			noLabel,
			requiredStar,
			id,
			style,
			w100,
			disabled,
			addClass,
			readOnly,
			viewIcon,
			meta: { touched, error, warning }
		} = this.props;
		const onBlur = () => {
			const blurHandler = input.onBlur;
			if (blurHandler) {
				blurHandler(input.value);
			}
		};
		return (
			<div className="form-group row">
				{!noLabel && (
					<div className={`col-md-3 text-right mt-2 ${addClass}`}>
						<label htmlFor={id} style={style}>
							{requiredStar === true ? (
								<span className="text-danger">* </span>
							) : (
								''
							)}
							{label}
						</label>
					</div>
				)}

				<div className={`my-auto ${w100 ? 'col-12' : 'col-md-9'}`}>
					<div className="row">
						<div className="col-12" style={{ position: 'relative' }}>
							<DatePicker
								{...input}
								className={`form-control ${
									touched && error ? 'focus-error' : ''
								}`}
								popperPlacement="botom"
								popperClassName="custom-popper"
								selected={
									input.value ? this.setDate(moment(input.value)._d) : null
								}
								dateFormat="dd/MM/yyyy"
								placeholderText="DD/MM/YYYY"
								showYearDropdown
								// onChange={e => console.log(e)}
								disabled={disabled}
								readOnly={readOnly}
								onBlur={onBlur}
								components={{ DropdownIndicator: () => x }}
							/>
							{viewIcon && (
								<div className="icon-field">
									<img
										src={calendarImage}
										style={{ width: '16px' }}
										alt=""
									/>
								</div>
							)}
						</div>
						<div className="col-12">
							{touched &&
                ((error && <span className="form-error">{error}</span>) ||
                  (warning && <span className="form-error">{warning}</span>))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { getDate })(DateField);
