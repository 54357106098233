import React from 'react';
import { Translate } from 'react-localize-redux';
import noImage from 'images/no-image.jpg';

function MissingProducts(props) {
	const { missingProducts, history } = props;
	const cekImage = url => {
		if (url === '') {
			return noImage;
		}
		return url;
	};
	return (
		<div className="m-3 px-3 py-2 detail-top">
			<div className="mb-3">
				<span className="card-title">
					<Translate id="missing products" /> (
					{(missingProducts && missingProducts.length) || '0'})
				</span>
			</div>
			{missingProducts.length === 0 ? (
				<p className="empty-placeholder">
					<Translate id="no data" />
				</p>
			) : (
				missingProducts.map(m => (
					<div className="d-flex align-items-center my-2" key={`missing-${m.id}`}>
						<div className="col-1">
							<img
								src={m.product && cekImage(m.product.product_image)}
								className="img-xs rounded-circle image-thumbnail mr-2"
							/>
						</div>
						<div className="col-3">
							<p
								className="font-weight-bold mb-0 pointer"
								onClick={() => {
									localStorage.setItem(
										'productOption',
										JSON.stringify('products')
									);
									m.product && history.push(`/products/detail/${m.product_id}`);
								}}
							>
								{m.product && m.product.name}
							</p>
						</div>
						<div className="col-3">
							<p className="text-muted mb-0">
								{(m.missing_reason && (
									<Translate id={m.missing_reason.name.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_')} />
								)) ||
                  '-'}
							</p>
						</div>
					</div>
				))
			)}
		</div>
	);
}

export default MissingProducts;
