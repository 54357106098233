import close from 'images/icons/orders/close.svg';
import trash_black from 'images/ui_icon/trash_black.svg';
import edit from 'images/icon/edit.png';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ARefresh } from '../../../atoms/global/refresh';
import { AUsers } from '../../../atoms/global/users';
import TextEditable from '../../../components_v2/input/TextEditable';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import Popup from '../../../components_v2/popup/Popup';
import PopupDeletion from '../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../helpers/storeLang';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { SidelyBlack } from '../../../styles/global/css/Utils';
import { CompanyNote } from '../../client-companies/model/Model';
import {
	BodyContainer,
	ButtonImage,
	Container,
	DateText,
	FooterContainer,
	HeaderContainer,
	HeaderLeft,
	HeaderRight,
	TextArea
} from '../style/PopupNoteStyle';
import { getNoteById } from './actions';
import { ObjectAction } from '../../../../../web-types/objectActions';
import Restricted from '../../permissions/Restricted';
import styled from 'styled-components';
import { LinkedElementId } from '../../../containers/companies/components/linkedElements/actions';
import { deleteLinkedElementNote, putLinkedElementNote } from '../../client-companies/popup/LinkedElements/actions';

interface State {
  title: string
  body: string
  editmode: boolean
  openDelete: boolean
}

const PopupNoteHeaderContainer = styled(HeaderContainer)`
	height:60px;
	padding:5px;
	flex-shrink:1;
`;
const PopupNoteFooterContainer = styled(FooterContainer)`
	height:60px;
	flex-shrink:1;
`;
const PopupNoteBodyContainer = styled(BodyContainer)`
	height:100%;
`;
const PoupNoteTextArea = styled(TextArea)`
	width:100%;
	height:98%;
	padding:1em 3em;
`;

export default function PopupNote(props: {
	id?: LinkedElementId
	isOpen: boolean
	onClickOut?: () => void
	note?: CompanyNote
	noteId?: number
	onDelete?: (id: number) => void
	onChange?: (note: CompanyNote) => void
	editMode?: boolean
	permissions?: {
		update?: ObjectAction,
		create?: ObjectAction,
		delete?: ObjectAction
	}
}) {
	const { isOpen } = props;

	const translate = getTranslate(storeLang.getState().localize);
	const [note, setNote] = React.useState<CompanyNote | undefined>(props.note);
	const [, setRefreshingAtom] = useRecoilState(ARefresh);
	const [state, setState] = React.useState<State>({
		title: props.note?.note_title ?? '',
		body: props.note?.note_body ?? '',
		editmode: props.editMode ?? false,
		openDelete: false
	});

	const owners = useRecoilValue(AUsers);

	const onClickOut = () => {
		setNote(undefined);
		props.onClickOut?.();
	};

	React.useEffect(() => {
		if (props.noteId && (props.note == null)) {
			getNoteById(props.noteId, owners)
				.then(note => {
					setNote(note);
					setState({
						...state,
						title: note.note_title ?? '',
						body: note.note_body ?? ''
					});
				})
				.catch(e => console.log(e));
		}
	}, [props.noteId]);

	React.useEffect(() => {
		setState({
			...state,
			title: props.note?.note_title ?? '',
			body: props.note?.note_body ?? '',
			editmode: props.editMode ?? false
		});
		setNote(props.note);
	}, [JSON.stringify(props.note)]);

	function deleteNote() {
		if (note != null) {
			deleteLinkedElementNote({
				linkedId: props.id ?? { Company: note.client_company_id ?? -1 },
				elementId: note.note_id
			}).then(() => {
				props.onDelete?.(note.note_id);
				props.onClickOut?.();
				setRefreshingAtom({ timeline: {} });
			});
		}
	}

	function submit(): void {
		if (note === undefined || note.client_company_id === undefined || (note?.note_title === state.title && note.note_body === state.body)) {
			return;
		}

		putLinkedElementNote(props.id ?? { Company: note?.client_company_id ?? -1 }, note.note_id, {
			body: state.body.trim().length > 0 ? { 'updated': state.body.trim() } : 'unmodified',
			title: state.title.trim().length > 0 ? { 'updated': state.title.trim() } : 'unmodified'
		}).then(() => {
			if (note) {
				props.onChange?.({
					...note,
					note_body: state.body.trim(),
					note_title: state.title.trim()
				});
				setRefreshingAtom({});
			}
			props.onClickOut?.();
		});
	}

	return (
		<Popup
			isOpen={isOpen}
			onClickOut={onClickOut}
			popupMode={PopupMode.Centered}
			popupStyle={{ height: '60vh' }}
		>
			<Container>
				<PopupNoteHeaderContainer>
					<HeaderLeft>
						<TextEditable
							value={state.title}
							onChange={(v) => setState({ ...state, title: v.value })}
							readOnly={!state.editmode}
							style={{
								color: SidelyBlack,
								fontSize: '18px',
								fontWeight: '700'
							}}
						/>
					</HeaderLeft>
					<HeaderRight>
						<Restricted to={props.permissions?.update ? { objectAction: props.permissions.update } : []} ownerId={note?.user_id}>
							<ButtonImage canHover isSelected={state.editmode} src={edit} width='20px' height='20px' margin='auto 5px' onClick={() => setState({ ...state, editmode: !state.editmode })} />
						</Restricted>
						<Restricted to={props.permissions?.delete ? { objectAction: props.permissions.delete } : []} ownerId={note?.user_id}>
							<ButtonImage canHover src={trash_black} width='20px' height='20px' margin='auto 5px' onClick={() => setState({ ...state, openDelete: true })} />
						</Restricted>
						<ButtonImage src={close} onClick={onClickOut} margin='0 0 0 20px' />
					</HeaderRight>
				</PopupNoteHeaderContainer>
				<PopupNoteBodyContainer>
					<PoupNoteTextArea value={state.body} readOnly={!state.editmode} onChange={(e) => setState({ ...state, body: e.currentTarget.value })}></PoupNoteTextArea>
				</PopupNoteBodyContainer>
				<PopupNoteFooterContainer>
					{
						state.editmode &&
						<DefaultButton height='35px' onClick={submit}>{translate('save').toString()}</DefaultButton>
					}
					{
						!state.editmode &&
						<DateText>{translate('by_on').toString().replace('{{NAME}}', note?.user_name.trim() ?? '').replace('{{DATE}}', moment.utc(note?.note_created_at).local().format('LLL'))}</DateText>
					}
				</PopupNoteFooterContainer>
				<PopupDeletion 
					isOpen={state.openDelete} 
					records={0} 
					translationKey=''
					customTitle={note?.note_title ?? ''}
					customMessage={translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${note?.note_title}'`)} 
					onClickOut={() => setState({ ...state, openDelete: false })}
					onValidation={() => deleteNote()}
					noVerification
				/>
			</Container>
		</Popup>
	);
}
