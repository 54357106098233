import { AdditionalColumnDescription } from 'bindings/reports/generic/AdditionalColumnDescription';
import { Granularity } from 'bindings/time/Granularity';
import { FilterType } from '../../../../typings/bindings/filters/FilterType';
import { Owner } from '../../../containers_v2/orders/model/Model';
import { TagOperator } from '../../dropdown/model/Model';

enum FilterAction {
  CONTAINS = 'contains',
  CONTAINS_LIST = 'contains_list',
  DOES_NOT_CONTAINS = 'does_not_contains',
  DOES_NOT_CONTAINS_LIST = 'does_not_contains_list',
  START_WITH = 'start_with',
  END_WITH = 'end_with',
  IS_EMPTY = 'is_empty',
  IS_NOT_EMPTY = 'is_not_empty',
  IS_EQUAL = 'is_equal',
  IS_NOT_EQUAL = 'is_not_equal',
  MORE_THAN = 'more_than',
  LESS_THAN = 'less_than',
  EQUAL_OR_MORE_THAN = 'equal_or_more_than',
  EQUAL_OR_LESS_THAN = 'equal_or_less_than',
  BETWEEN = 'between',
  NOT_BETWEEN = 'not_between',
  IS_TRUE = 'is_true',
  IS_FALSE = 'is_false',
  IS = 'is',
  IS_NOT = 'is_not',
  MORE_THAN_LAST = 'more_than_last',
  LESS_THAN_LAST = 'less_than_last',
  MORE_THAN_NEXT = 'more_than_next',
  LESS_THAN_NEXT = 'less_than_next',
}

type FilterQueryType = 'eq' | 'ne' | 'gt' | 'gte' | 'lt' | 'lte' | 'contains' |
'contained' | 'not_contains' | 'not_contained' | 'between' | 'not_between' | 'empty' |
'not_empty' | 'starts_with' | 'ends_with' | 'tag' | 'combinator' | 'not_combinator' | 'toclear'

export function filterIdFromString(s: string): FilterId {
	try {
		return JSON.parse(s);
	} catch (e) {
		return s;
	}
}

export type FilterId = string | FrequencyFilter | LastEventDateFilter | NextEventDateFilter | ExternalIdFilter | CheckoutEvolutionFilter | AdditionalColumnFilter | AdditionalFieldColumnFilter | LastFormDateFiler | CalculatedFieldFilter;
export type FilterValueOrValues<T> = {
  value?: T,
  displayedValue?: string | JSX.Element
} | {
  values?: T[],
  displayedValues?: [string | JSX.Element, string | JSX.Element]
}

export type FilterAdditionalValue = {
  value: number | string
  titleComment: string | JSX.Element
};

export type FilterValueResultUnion = { val: FilterValueResult } | FilterValueResultArray;

export type FilterValueResultArray = {
    array: FilterValueResultUnion[],
    combinator: TagOperator,
};

interface FilterValueResult {
  value?: string | number | boolean
  displayedValue?: string | JSX.Element
  displayedValues?: [string | JSX.Element, string | JSX.Element]
  values?: string[] | number[] | boolean[] | Owner[] | object[]
  action: FilterAction
  type: FilterType
  filterType?: FilterType
  id: FilterId
  title: string
  titleComment?: string | JSX.Element
  operator?: TagOperator
}

export type ExternalIdFilter = {
  external_id: null | number
}

export type AdditionalColumnFilter = {
  additional_columns: AdditionalColumnDescription[]
}

export type AdditionalFieldColumnFilter = {
  additional_field_columns: number[]
}

export type CalculatedFieldFilter = {
  calculated_field_columns: AdditionalColumnDescription[]
}

export function isExternalIdFilter(filterId: FilterId): filterId is ExternalIdFilter {
	return (filterId as ExternalIdFilter).external_id !== undefined;
}

export type CheckoutEvolutionFilter = {
  checkout: {
    granularity?: Granularity,
    element: 'current_sum' | 'previous_sum' | 'evolution' | 'current_uvc_sum' | 'previous_uvc_sum' | 'uvc_evolution'
  }
}

export function isCheckoutEvolutionFilter(filterId: FilterId): filterId is CheckoutEvolutionFilter {
	return (filterId as CheckoutEvolutionFilter).checkout !== undefined;
}

export type NextEventDateFilter = {
  next_event_date: null | number
}

export function isNextEventDateFilter(filterId: FilterId): filterId is NextEventDateFilter {
	return (filterId as NextEventDateFilter).next_event_date !== undefined;
}

export type LastEventDateFilter = {
  last_event_date: null | number
}

export function isLastEventDateFilter(filterId: FilterId): filterId is LastEventDateFilter {
	return (filterId as LastEventDateFilter).last_event_date !== undefined;
}

export type FrequencyFilter = {
  frequencies: {
    event?: number,
    element: 'score' | 'frequency'
  }
}

export function isFrequencyFilter(filterId: FilterId): filterId is FrequencyFilter {
	return (filterId as FrequencyFilter).frequencies !== undefined;
}

export function isAdditionalColumnFilter(filterId: FilterId): filterId is AdditionalColumnFilter {
	return (filterId as AdditionalColumnFilter).additional_columns !== undefined;
}

export type LastFormDateFiler = {
  last_form_date: null | number
}

export function isLastFormDateFilter(filterId: FilterId): filterId is LastFormDateFiler {
	return (filterId as LastFormDateFiler).last_form_date !== undefined;
}


interface FilterQueryResult {
  column: FilterId
  operator: FilterQueryType
  value?: string,
  filter_type?: FilterType
}

interface FilterResult {
  values: FilterValueResultUnion
  formatted: FilterTree | undefined
}

export type FilterTree = { val: FilterQueryResult } | { and: FilterTree[] } | { or: FilterTree[] }
export function filterTreeLength(tree?: FilterTree): number {
	if (!tree) return 0;
	if (tree['val']) {
		return 1;
	}
	const subTrees: FilterTree[] = tree['and'] ?? tree['or'];
	return subTrees.reduce((acc: number, tree: FilterTree) => acc + filterTreeLength(tree), 0);
}

interface FilterList {
  categories: FilterCategory[]
}

interface FilterCategory {
  name: string
  items: FilterItem[]
}

interface FilterItem<T = any> {
  id: number | string
  label: string
  value: T
}

interface FilterColumn {
  id: number
  isOpen: boolean
}

export {
	FilterAction, FilterCategory, FilterColumn, FilterItem, FilterList, FilterQueryResult,
	FilterQueryType,
	FilterResult, FilterValueResult
};

