import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';

export const DETAIL_DATA_REQUEST = 'DETAIL_DATA_REQUEST';
export const DETAIL_DATA_SUCCESS = 'DETAIL_DATA_SUCCESS';
export const DETAIL_DATA_ERROR = 'DETAIL_DATA_ERROR';

export const CREATE_DATA_REQUEST = 'CREATE_DATA_REQUEST';
export const CREATE_DATA_SUCCESS = 'CREATE_DATA_SUCCESS';
export const CREATE_DATA_ERROR = 'CREATE_DATA_ERROR';

export const UPDATE_DATA_REQUEST = 'UPDATE_DATA_REQUEST';
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_ERROR = 'UPDATE_DATA_ERROR';

export const DELETE_DATA_REQUEST = 'DELETE_DATA_REQUEST';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DELETE_DATA_ERROR = 'DELETE_DATA_ERROR';

export const SORT_DATA = 'SORT_DATA';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

// CREATE LINKED ELEMENTS
// GET LINKED ELEMENTS
// MODIFY LINKED ELEMENTS

const PATH = `${URL_API}/api`;

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/expenses`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_DATA_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_DATA_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_DATA_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/expenses/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_DATA_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_DATA_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_DATA_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createData(data, subPath) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/expenses/${subPath}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_DATA_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/expenses${subPath && `-${subPath}`}/detail/${
						response.data.data.id
					}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_DATA_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_DATA_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id, subPath) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/expenses${subPath && `/${subPath}`}/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_DATA_SUCCESS,
					payload: response.data
				});
				// swal.fire('Success!', 'Data updated!', 'success').then(() => {
				window.location.href = `/expenses${subPath &&
          `-${subPath}`}/detail/${id}`;
				// });
			},
			error => {
				dispatch({
					type: UPDATE_DATA_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteData(id, isRedirect) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_DATA_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/expenses/${id}`
		}).then(
			() => {
				// dispatch({ type: DELETE_COMPANIES_SUCCESS, payload: response.data });
				// swal.fire('Deleted!', 'Data has been deleted.', 'success').then(() => {
				if (isRedirect) {
					window.location.href = '/expenses';
				} else {
					dispatch(fetchData());
				}
				// });
			},
			error => {
				// dispatch({ type: DELETE_COMPANIES_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function bulkDelete(selected) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		try {
			const resp = await axios({
				method: 'delete',
				url: `${PATH}/expenses`,
				data: { id: selected },
				headers: {
					'Content-Type': 'application/json'
				}
			});

			if (resp.status != 200) {
				throw new Error('Failed to bulk delete');
			}

			swal.fire(
				'Success Bulk Delete',
				'Your data has been deleted from server',
				'success'
			);

			window.location.href = '/expenses';
		} catch (error) {
			throw error;
		}
	};
}

// export const searchData = data => async dispatch => {
//   dispatch({
//     type: SEARCH_COMPANIES,
//     payload: data
//   });
// };

export const sortData = data => async dispatch => {
	dispatch({
		type: SORT_DATA,
		payload: data
	});
};

export function importExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: IMPORT_EXCEL_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/clientcompanies/importExcel`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: IMPORT_EXCEL_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Import success!', 'success').then(() => {
					window.location.href = '/companies';
					// });
				},
				error => {
					dispatch({
						type: IMPORT_EXCEL_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: IMPORT_EXCEL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function exportExcel(data, typedata) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		try {
			const response = await axios({
				method: 'get',
				responseType: 'blob',
				url: `${PATH}/${typedata}/exportExcel`,
				params: data
			});
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Expenses.xlsx');
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function exportCsv(data, typedata) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		try {
			const response = await axios({
				method: 'get',
				responseType: 'blob',
				url: `${PATH}/${typedata}/exportCsv`,
				params: data
			});
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Expenses.csv');
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';

export const setActiveFilter = data => async dispatch => {
	dispatch({
		type: SET_ACTIVE_FILTER,
		payload: data
	});
};

// CREATE LINKED ELEMENTS
// GET LINKED ELEMENTS
// MODIFY LINKED ELEMENTS
