import styled from 'styled-components';
import { BorderColor, DarkGreySidely, DarkGreySidely2, GreenSidely, RedSidely, SidelyBlack } from '../../../../../styles/global/css/Utils';

const Container = styled.div`
    height: calc(100vh - 130px);;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ContainerModalLeft = styled.div``;

const ContainerTable = styled.div``;

const Footer = styled.footer``;

const FormContainer = styled.form``;

const TableStyle = styled.div`
    padding: 1rem;
        
    .presence {
        text-align: center;
    }
    
    .table {
        overflow: auto;
        display: inline-block;
        border-spacing: 0;
        border: 1px solid ${BorderColor};
        background: white;
        height: 50vh;
        width: 100%;

        .header-content {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 11px;
        }

        .tr {
            :last-child {
                .td {
                    border-bottom: 0;
                }
            }
        }

        .gradiant1 {
            background: #F8F8F8
        }

        .gradiant2 {
            background: #F4F4F4
        }

        .gradiant3 {
            background: #EEEEEE
        }

        .gradiant4 {
            background: #E5E5E5
        }

        .gradiant5 {
            background: #D7D7D7
        }

        .th {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid ${BorderColor};
            
            text-align: center;
            
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 21px;
            color: ${DarkGreySidely};

            :last-child {
                border-right: 1px solid ${BorderColor};
            }
        }

        .td {
            margin: 0;
            padding: 0.75rem;
            border-bottom: 1px solid ${BorderColor};
            
            text-align: center;

            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 21px;
            color: ${DarkGreySidely2};
    
            :last-child {
                border-right: 1px solid ${BorderColor};
            }
        }

        .overflow {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .resizer {
            display: inline-block;
            background: ${BorderColor};
            width: 3px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
            z-index: 1;
            ${'' /* prevents from scrolling while dragging on touch devices */}
            touch-action:none;

            &.isResizing {
            background: grey;
            }
        }

        .red-dot {
            height: 12px;
            width: 12px;
            background-color: ${RedSidely};
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
        }
        .green-dot {
            height: 12px;
            width: 12px;
            background-color: ${GreenSidely}CC;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
        }

        .triangle-up {
            margin-left: 8px;
            display : inline-block;
            height : 0;
            width : 0;
            border-right : 6px solid transparent;
            border-bottom : 6px solid #212529;
            border-left : 6px solid transparent;
        }

        .triangle-down {
            margin-left: 8px;
            display : inline-block;
            height : 0;
            width : 0;
            border-top : 6px solid #212529;
            border-right : 6px solid transparent;
            border-left : 6px solid transparent;
        }

        .percentage {
            display: block;
            text-align: right;
            margin-right: 35%;
        }

        tfoot {
            tr:first-child {
              td {
                border: 1px solid ${BorderColor};
                text-align: center;

                margin: 0;
                padding: 0.75rem;
                border-bottom: 1px solid ${BorderColor};
                
                text-align: center;
    
                font-family: Poppins;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
                color: ${DarkGreySidely2};
        
                :last-child {
                    border-right: 1px solid ${BorderColor};
                }

              }
              height: 50px;
            }
            font-weight: 400;
        }

        .tooltip2 {
            position: relative;
            display: inline-block;
        }
        .tooltip2 .tooltiptext2 {
            visibility: hidden;
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            width: 120px;
            background-color: #fff;
            color: ${SidelyBlack};
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            top: -8px;
            right: 133%;

            // border: solid 0.3px rgb(177, 177, 177);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }
        .tooltip2:hover .tooltiptext2 {
            visibility: visible;
            opacity: 1;
        } 

        ul {
            list-style: none;
        }

        .tooltip3 {
            position: relative;
            display: inline-block;
        }
        .tooltip3 .tooltiptext3 {
            visibility: hidden;
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            width: 200px;
            background-color: #fff;
            color: ${SidelyBlack};
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            top: -8px;
            right: 110%;

            // border: solid 0.3px rgb(177, 177, 177);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }        
        .tooltip3:hover .tooltiptext3 {
            visibility: visible;
            opacity: 1;
        } 

        .tooltipup {
            position: relative;
            display: inline-block;
        }
        .tooltipup .tooltiptextup {
            visibility: hidden;
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 21px;
            width: 200px;
            background-color: #fff;
            color: ${SidelyBlack};
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 150%;
            left: 50%;
            margin-left: -100px;

            // border: solid 0.3px rgb(177, 177, 177);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }        
        .tooltipup:hover .tooltiptextup {
            visibility: visible;
            opacity: 1;
        }
    }    
`;

export {
	Container,
	ContainerModalLeft,
	ContainerTable,
	Footer,
	FormContainer,
	TableStyle
};
