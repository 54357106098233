import styled from 'styled-components';
import {
	BorderColor,
	DarkGreySidely2, FilterRed,
	LightBlueSidely,
	LightGreySidely,
	LightGreySidely2, LightGreySidely3,
	SidelyBlack
} from '../../../styles/global/css/Utils';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Image } from '../../../components_v2/avatar/style/Style';
import copyImg from 'images/icon/copy.svg';
import editImg from 'images/icon/edit.png';
import trashBlack from 'images/ui_icon/trash_black.svg';
import { FlexDiv } from '../../products/style';

interface TabWrapperProps {
	active?: boolean,
	width?: string
}
const TabWrapper = styled.div<TabWrapperProps>`
  	display:flex;
  	justify-content: space-between;
  	align-items: center;
  	width:100%;
  	max-width: 200px;
  	padding: 0 5px 0 10px;
  	height:30px;
  	border-radius: 5px 5px 0 0;
    border: 1px solid ${BorderColor};
    border-bottom:none;
  	background-color:${LightGreySidely3};
${ p => p.active ?
		`background-color: ${LightGreySidely};
			color:${SidelyBlack};
			font-weight:500;
		`
		: `color:${DarkGreySidely2};`
}
	cursor:pointer;
  	outline:none;
  	&:hover{
	  background-color: ${LightGreySidely};
	}
`;

const TapInput = styled.input<{readOnly: boolean}>`
  ${DefaultText};
  width: 100%;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  outline:none;
  border:none;
  background-color: inherit;
  font-weight: inherit;
  color:inherit;
  cursor:inherit;
  &:focus{
    ${({ readOnly }) => readOnly ? '' : `background-color:${LightBlueSidely};`}
  };
`;

interface TabProps {
	id: number,
	name: string,
	active: boolean,
	onSwitchTab: () => void,
	onDeleteTab: () => void,
	onUpdateTab: (id: number, name: string) => void,
	onDuplicateTab: () => void
}
export default function Tab(props: TabProps) {
	const { active, name:previousName , id } = props;
	const [name, setName] = React.useState(previousName);
	const [readOnly, setReadOnly] = React.useState<boolean>(true);
	const [isHover, setIsHover] = React.useState<boolean>(false);
	const inputRef = React.useRef<HTMLInputElement>();

	useEffect(() => {
		setName(previousName);
	}, [previousName]);

	function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
		setName(e.target.value);
	}
	function updateAndSetReadOnly(e?: React.FocusEvent<HTMLInputElement>, isEnter?: boolean) {
		// e.target.readOnly === false is a protection to avoid the call of switchTab two times(onBlur and onClick on the tab)
		if (isEnter || e?.target.readOnly === false) {
			props.onUpdateTab(id, name);
			setReadOnly(true);
		}
	}
	function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.code === 'Enter') updateAndSetReadOnly(undefined, true);
	}
	function handleOnClickEdit() {
		const { current } = inputRef;

		setReadOnly(false);
		if (current) {
			current.focus();
		}
	}
	return <TabWrapper
		onClick={props.onSwitchTab}
		active={active}
		onMouseOver={() => setIsHover(true)}
		onMouseLeave={() => setIsHover(false)}
	>
		<TapInput
			value={name}
			readOnly={readOnly}
			onChange={handleOnChange}
			onBlur={updateAndSetReadOnly}
			onKeyDown={handleOnKeyDown}
			innerRef={useCallback((node) => inputRef.current = node, [])}
		/>
		{(isHover && readOnly) && <FlexDiv gap="5px">
			<Image url={editImg} width="15px" hasFilter onClick={handleOnClickEdit}/>
			<Image url={copyImg} width="15px" hasFilter onClick={() => props.onDuplicateTab()}/>
			<Image width='18px' url={trashBlack} onClick={() => props.onDeleteTab()} hasFilter filter={FilterRed}/>
		</FlexDiv>}
	</TabWrapper>;
}



