import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Contacts from '../../containers/contacts/Contacts';
import ContactsV2 from '../../containers_v2/contact/Contacts';
import DetailContacts from '../../containers/contacts/DetailContacts';
import ContactForm from '../../containers/contacts/ContactForm';
import { Route } from '../Routes';

const ContactsRoutes = (): Route[] => [
	{
		component: ContactsV2,
		path: '/contacts-v2',
		name: <Translate id="contacts" />,
	},
	{
		component: Contacts,
		path: '/contacts',
		name: <Translate id="contacts" />,
		role: ['all'],
		key: 'contacts',
		sectionKey: 'contacts',
		title: 'contacts',
		subTitle: 'contacts',
		restricted: false,
		exact: true
	},
	{
		component: ContactForm,
		path: '/contacts/create',
		key: 'contact',
		sectionKey: 'contacts',
		title: 'contacts',
		name: <Translate id="new contact" />,
		detail: <Translate id="Contact Details" />,
		role: ['admin'],
		exact: true
	},
	{
		component: ContactForm,
		path: '/contacts/edit/:id',
		key: 'contacts',
		sectionKey: 'contacts',
		title: 'contacts',
		name: <Translate id="edit_contact" />,
		detail: <Translate id="Contact Details" />,
		role: ['admin'],
		exact: true
	},
	{
		component: ContactForm,
		path: '/contacts/duplicate/:idDupe',
		key: 'contacts',
		sectionKey: 'contacts',
		title: 'contacts',
		name: <Translate id="duplicate_contact" />,
		detail: <Translate id="Contact Details" />,
		role: ['admin'],
		exact: true
	},
	{
		component: DetailContacts,
		path: '/contacts/detail/:id',
		key: 'contacts',
		sectionKey: 'contacts',
		title: 'Contacts',
		name: <Translate id="Contact Details" />,
		role: ['admin'],
		exact: true
	}
];

export default ContactsRoutes;
