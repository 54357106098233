import bar from 'images/formbuilder/bar.svg';
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/**
 * <ToolbarItem />
 */

import React from 'react';
import { DragSource } from 'react-dnd';
import ItemTypes from './services/ItemTypes';
import ID from './services/UUID';
import { Translate } from 'react-localize-redux';
import { getFormBuilderImageUrl } from '../../../enrichedForm/components/fieldComponents/builderLib/header-bar';

const cardSource = {
	beginDrag(props) {
		// console.log('props', props);

		return {
			id: ID.uuid(),
			index: -1,
			data: props.data,
			onCreate: props.onCreate
		};
	}
};

class ToolbarItem extends React.Component {
	render() {
		const { connectDragSource, data, onClick } = this.props;
		const bg =
      data.key === 'StartSection' ||
      data.key === 'EndSection' ||
      data.key === 'StartTable' ||
      data.key === 'EndTable'
      	? 'bg-soft-dark'
      	: null;

		if (!connectDragSource) return null;
		return connectDragSource(
			<li onClick={onClick} className={bg}>
				{data.img ? (
					<img
						src={getFormBuilderImageUrl(data.img)}
						alt=""
						style={{ width: 15, margin: `0 ${bg ? '15px 0 13px' : '13px'}` }}
						// className="mr-2"
					/>
				) : (
					<i className={data.icon} />
				)}
				<span>
					<Translate id={data.name} />{' '}
				</span>
				<img
					className="float-right icon-drag"
					src={bar}
					alt=""
				/>
				{/* <span className="fas fa-bars float-right icon-drag" /> */}
			</li>
		);
	}
}

export default DragSource(ItemTypes.CARD, cardSource, connect => ({
	connectDragSource: connect.dragSource()
}))(ToolbarItem);
