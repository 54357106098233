import React from 'react';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { useRecoilValue } from 'recoil';
import { AProducts, Product } from '../../atoms/product';
import { AFormFields, Field } from '../../atoms/forms';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { useFunctionState } from '../../utils/customHooks';
import { Column, Table } from '../../components_v2/table/Table';
import { TableRow, TableRowTitle } from '../orders/templateOrders/style/Style';
import { getAdditionalValues, getAdditionalValuesViews, importAdditionalValues, saveAdditionalValue } from './action';
import FieldEditor from '../../utils/fieldEditor';
import { LoadingStateEnum } from '../import/model';
import { Loader } from '../../styles/global/css/GlobalLoader';
import { FlexDiv } from './style';
import { creationPopupDropdownStyle } from '../client-companies/style/Style';
import rowSvg from 'images/icon/row_with_arrow.svg';
import styled from 'styled-components';
import Add from '../../components_v2/add/Add';
import { DarkGreySidely2 } from '../../styles/global/css/Utils';
import ToolbarFilter, { ToolbarElement } from '../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../atoms/utils/model/Model';
import { AInputSearch } from '../../atoms/filter/InputSearchFilter';
import { Translate, translateToString } from '../../styles/global/translate';
import { CleanButton } from '../../components_v2/dropdown/style/Style';
import Pagination from '../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import { AColors } from '../../atoms/colors/colors';
import { Dot } from '../../styles/global/css/Dot';
import { RawViewPicker, ViewsCategory } from '../../components_v2/view/ViewPicker';
import { ABrand, AEmptyBrand } from '../../atoms/filter/BrandFiltersAtom';
import { AProductFilter } from '../../atoms/filter/productsFilterAtom';
import ProductPopup, { BarcodeWrapper } from './product';
import { ModalState } from './model';
import Switch from '../../components_v2/Switch/Switch';
import { Upload } from 'antd';
import { dummyRequest, getBase64 } from '../import/UploadFile';
import readXlsxFile from 'read-excel-file';

type InnerColumnType = Product & { field?: Field, values: Record<string, unknown>, comparaisonsValues: unknown[] };
type ColumnType = Column<InnerColumnType>;
export type AdditionalValue = [Record<any, any>, any];
type StaticValues = Record<string | number, unknown>;

export type PathAndValue = {
	path: AdditionalValue[0],
	value: AdditionalValue[1]
}

type AdditionalValueView = {
	id: number,
	name: string,
	count: number,
	field: number,
	path: Record<number, unknown>,
	fieldToShowId?: number
};

type BuidPathOptions = {
	product?: string,
	staticValues?: Record<string | number, unknown>,
	chosenField?: { id: number, value: unknown }
}

function buildCellPath(opt: BuidPathOptions) {
	const path = { };
	if (opt.product) path['p'] = opt.product;
	if (opt.chosenField) path[opt.chosenField.id] = opt.chosenField.value;
	return { ...path, ...opt.staticValues };
}

function findAdditionalValueRef(path: Record<string | number, unknown>, values: AdditionalValue[]): AdditionalValue | undefined {
	return values.find(([key]) => {
		if (Object.keys(key).length !== Object.keys(path).length) return false;
		for (const k in path) {
			if (key[k] !== path[k]) return false;
		}
		return true;
	});

}

function findAdditionalValue(path: Record<string | number, unknown>, values: AdditionalValue[]) {
	return findAdditionalValueRef(path, values)?.[1];
}

const Icon = styled.img<{flip?: boolean }>`
	width: 30px;
	transform: ${p => p.flip ? 'rotate(-90deg) scaleX(-1); ' : 'none;'}
`;

const Select = styled.select<{ border?: boolean }>`
	${({ border }) => border ? '' : ' border: none; width: 100%;'}
	font-size: 12px;
	font-weight: 400;
	color: ${DarkGreySidely2};
`;

const SwitchText = styled.p`
	margin: 0;
`;

const FILTER_HEIGHT = 40;
const FILTER_HEIGHT_S = `${FILTER_HEIGHT}px`;

const DropdownStyle = { ...creationPopupDropdownStyle, containerBorder: 'none', height: FILTER_HEIGHT_S };

const LocalButton = styled(DefaultButton)`
	margin: 0;
`;

type FieldFilters = { field?: Field, fieldValue?: unknown }[];

function staticValuesFromFilters(filters: FieldFilters): StaticValues {
	return filters.reduce<StaticValues>((acc, { field, fieldValue }) => {
		if (!field || !fieldValue) return acc;
		acc[field.id] = fieldValue;
		return acc;
	}, {});
}

const LOCAL_STORAGE_RESIZE_KEY = 'table_assortment_v3_resize';

export default function AssortmentListingV3(props: ComponentProps) {
	const inputSearchText = useRecoilValue(AInputSearch);
	const colors = useRecoilValue(AColors);
	const products = useRecoilValue(AProducts);
	const fields = useRecoilValue(AFormFields);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [additionalFieldsFilters, setAdditionalFieldsFilters] = React.useState<FieldFilters>([]);
	const staticValues = React.useMemo<StaticValues>(() => staticValuesFromFilters(additionalFieldsFilters), [additionalFieldsFilters]);
	const [productPopupState, setProductPopupState] = React.useState<ModalState<number>>({ isOpen: false });
	const [additionalValues, setAdditionalValues] = React.useState<AdditionalValue[]>([]);
	const [pagination, setPagination] = React.useState<PaginationResult>({ offset: 0, step: 25, currentPage: 1 });
	const [comparaisons, setComparaisons] = React.useState<{ filters: FieldFilters, color: string, id: number, staticValues: StaticValues, filterEmptyRows: boolean }[]>([]);
	const [views, setViews] = React.useState<ViewsCategory<AdditionalValueView>[]>([]);
	const [resize, setResize] = React.useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_RESIZE_KEY) || '{}'));
	const brandFilter = useRecoilValue(ABrand);
	const emptyBrand = useRecoilValue(AEmptyBrand);
	const productFilter = useRecoilValue(AProductFilter);
	const [filterEmptyRows, setFilterEmptyRows] = React.useState(false);
	const fieldsMap = React.useMemo<Map<number, Field>>(() => fields.reduce<Map<number, Field>>((acc, field) => {
		acc.set(field.id, field);
		return acc;
	}
	, new Map()), []);

	const [fieldToShow, setFieldToShow] = useFunctionState<Field | undefined>(undefined, ({ newValue }) => {
		if (!newValue) return undefined;
		setAdditionalFieldsFilters(t => {
			if (t.some(t => !t.field?.id)) return t;
			return t.filter(t => t.field?.id != newValue?.id);
		});
		return newValue;
	});
	const [fieldToSet, setFieldToSet] = useFunctionState<Field | undefined>(undefined, ({ newValue }) => {
		if (!newValue) return undefined;
		setFieldToShow(fts => {
			if (fts && fts.id == newValue?.id) return undefined;
			return fts;
		});
		setAdditionalFieldsFilters(t => {
			if (t.some(t => !t.field?.id)) return t;
			return t.filter(t => t.field?.id != newValue?.id);
		});
		return newValue;
	});
	

	React.useEffect(() => {
		let id = 1;
		getAdditionalValuesViews().then(res => {
			let mapped = res.map(([path, count]) => {
				const res: AdditionalValueView = { count, path: {}, field: path['f'], id, name: '' };
				let name: string | undefined = undefined;
				id += 1;
				let oneField = true;
				for (const [key, value] of Object.entries(path)) {
					const fieldId = parseInt(key);
					if (isNaN(fieldId)) continue;
					if (oneField == true && res.fieldToShowId !== undefined) oneField = false;
					res.fieldToShowId = fieldId;
					res.path[fieldId] = value;
					const rawName = `${fieldsMap.get(fieldId)?.name}: "${value}"`;
					if (name === undefined) {
						name = rawName;
					} else {
						name += ` - ${rawName}`;
					}
				}
				if (!oneField) delete res.fieldToShowId;
				else if (res.fieldToShowId !== undefined) name = fieldsMap.get(res.fieldToShowId)?.name ?? 'unknown';
				res.name = name ?? 'unknown';
				return res;
			});
			const { merged, other } = mapped.reduce((acc: { merged: Record<string, AdditionalValueView>, other: AdditionalValueView[]}, v) => {
				if (!v.fieldToShowId) {
					acc.other.push(v);
					return acc;
				} else {
					const key = `${v.fieldToShowId} ${v.field}`;
					if (acc.merged[key] === undefined) acc.merged[key] = v;
					else acc.merged[key].count += v.count;
				}
				return acc;
			}, { merged: {}, other: [] });
			mapped = [...Object.values(merged), ...other];
			mapped.sort((a, b) => b.count - a.count);
			const categories: Record<number, ViewsCategory<AdditionalValueView>> = {};
			for (const view of mapped) {
				if (!categories[view.field]) categories[view.field] = { title: fieldsMap.get(view.field)?.name ?? 'unknown', views: [], editable: false, deletable: false };
				view.name += ` (${view.count})`;
				categories[view.field].views.push(view);
			}
			const categoriesArray = Object.values(categories);
			categoriesArray.sort((a, b) => (a.title as string).localeCompare(b.title as string));
			return setViews(categoriesArray);
		});
	}, []);

	const refresh = React.useCallback(() => {
		if (!fieldToSet) {
			setLoadingState(LoadingStateEnum.LOADED);
			return;
		}
		const params = new URLSearchParams({ path: JSON.stringify({ f: fieldToSet.id }) });
		setLoadingState(LoadingStateEnum.LOADING);
		getAdditionalValues(params.toString()).then(res => {
			setLoadingState(LoadingStateEnum.LOADED);
			return setAdditionalValues(res);
		}).catch(() => setLoadingState(LoadingStateEnum.ERROR));
	}, [fieldToSet]);


	React.useEffect(() => {
		refresh();
	}, [fieldToSet]);



	const handleChange = React.useCallback((info) => {
		if (info.file.status === 'uploading') {
			return;
		} else if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, async loadedFile => {
				let res: PathAndValue[];
				if (info.file.originFileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
					res = await readXlsxFile(loadedFile).then((rawExcel: any[][]) => rawExcel.reduce<PathAndValue[]>((acc, [path, rawValue]) => {
						try {
							let value;
							try {
								value = JSON.parse(rawValue);
							} catch (_) {
								value = rawValue;
							}
							acc.push({
								path: JSON.parse(path),
								value
							});
						} catch (_) {
							// EMPTY BLOCK
						}
						return acc;
					}, []));
				} else {
					res = [];
					// TODO accept csv
					//readString(loadedFile, {
					//	complete: (res) => {
					//		console.log(res);
					//	},
					//	worker: true
					//});
				}
				if (res.length == 0) return;
				setLoadingState(LoadingStateEnum.LOADING);
				importAdditionalValues(res).then(refresh);
			});
		}
	}, [refresh]);

	React.useEffect(() => props.setToolBarState({
		bottomLeftToolbarComponent: <ToolbarBox>
			<RawViewPicker<AdditionalValueView>
				buttonTitle={translateToString('defined_values')}
				onCreate={undefined}
				onEdit={undefined}
				noBorder={undefined}
				setEditedView={undefined}
				onDelete={undefined}
				categories={views}
				onViewChange={(v) => {
					// TODO add popup when v.fieldToShowId is undefined and path.length > 1
					setFieldToSet(fieldsMap.get(v.field));
					if (v.fieldToShowId !== undefined) {
						setFieldToShow(fieldsMap.get(v.fieldToShowId));
					} else {
						const newAdditionalFieldsFilters: FieldFilters = [];
						for (const [key, value] of Object.entries(v.path)) {
							const fieldId = parseInt(key);
							if (isNaN(fieldId)) continue;
							newAdditionalFieldsFilters.push({ field: fieldsMap.get(fieldId), fieldValue: value });
						}
						const newFieldToShow = newAdditionalFieldsFilters.pop();
						setFieldToShow(newFieldToShow?.field);
						setAdditionalFieldsFilters(newAdditionalFieldsFilters);
					}

				}}
			/>
			<ToolbarFilter
				category={AtomCategory.ASSORTMENTS}
				elements={[
					{ kind: ToolbarElement.BRAND_FILTER, withoutBrandOption: true },
					ToolbarElement.PRODUCT_PER_CATEGORY
				]}
			/>
			{!!fieldToSet && !!fieldToShow && <LocalButton onClick={() => setComparaisons(t => {
				if (t.some(t => t.filters.length == 0)) return t;
				const color = colors.find(c => !t.some(t => t.color == c.colorCode))?.colorCode;
				if (!color) return t;
				return [...t, { filters: [{}], color, id: Date.now(), staticValues: {}, filterEmptyRows: false }];
			})}>Ajouter une ligne de comparaison</LocalButton>}
			
		</ToolbarBox>,
		bottomRightToolbarComponent: <ToolbarBox><Upload
			showUploadList={false}
			name='import'
			onChange={handleChange}
			//@ts-expect-error antd types are wrong
			customRequest={dummyRequest}
			accept='.xlsx'
		>
			<LocalButton><Translate id="import.import_data" /></LocalButton>
		</Upload>
		<ToolbarFilter
			category={AtomCategory.ASSORTMENTS}
			elements={[
				ToolbarElement.INPUT_SEARCH
			]}
		/>
		</ToolbarBox>,
		title: translateToString('product.assortments'),
	}), [!!fieldToSet && !!fieldToShow, views, fieldsMap]);

	React.useEffect(() => {
		localStorage.setItem(LOCAL_STORAGE_RESIZE_KEY, JSON.stringify(resize));
	}, [resize]);

	const allowedValues = React.useMemo(() => {
		if (fieldToShow === undefined) return undefined;
		return Object.entries(fieldToShow.constraint ?? {}).reduce<any[] | undefined>((acc, [constraintField, constraintMap]) => {
			if (!staticValues[constraintField]) return acc;
			if (typeof staticValues[constraintField] !== 'string') throw 'Constraint field value is not a string';
			const allowedValues: string[] = constraintMap[staticValues[constraintField]];
			if (acc === undefined) {
				return allowedValues;
			} else {
				return acc.filter(v => allowedValues.includes(v));
			}
		}, undefined);
	}, [fieldToShow, staticValues]);

	const productFields: DropdownData<Field>[] = React.useMemo(() => fields.filter(f => f.is_additional).map(f => ({ value: f, label: f.name })), [fields]);
	const fieldToShowData = productFields.filter(f => f.value.id != fieldToSet?.id);

	const data = React.useMemo(() => {
		const lowerInput = inputSearchText.toLocaleLowerCase();
		return products
			.filter(p => {
				if (brandFilter !== undefined && !brandFilter.some(b => b.id == p.brand) && (!emptyBrand || !!p.brand)) return false;
				if (productFilter && !productFilter.all && !productFilter.products.includes(p.id)) return false;

				return p.name.toLocaleLowerCase().includes(lowerInput);
			})
			.map<InnerColumnType>(p => {
				const values: Record<string, unknown> = {};
				const comparaisonsValues: unknown[] = [];
				for (const comparaison of comparaisons) {
					const newStaticValues = { ...comparaison.staticValues, f: fieldToSet?.id };
					const path = buildCellPath({ product: p.uuid, staticValues: newStaticValues });
					const additionalValue = findAdditionalValue(path, additionalValues);
					comparaisonsValues.push(additionalValue);
				}
				if (fieldToShow === undefined || !Array.isArray(fieldToShow.data)) return { ...p, values, comparaisonsValues };
				for (const value of (fieldToShow.data as string[]).filter(f => !allowedValues || allowedValues.includes(f))) {
					const newStaticValues = { ...staticValues, f: fieldToSet?.id };
					if (fieldToShow) newStaticValues[fieldToShow.id] = value;
					const path = buildCellPath({ product: p.uuid, staticValues: newStaticValues });
					const additionalValue = findAdditionalValue(path, additionalValues);
					values[value] = additionalValue;
				}
				return ({
					...p,
					field: fieldToSet,
					values,
					comparaisonsValues
				});
			})
			.filter(p => {
				if (filterEmptyRows && Object.values(p.values).every(v => v === undefined)) return false;
				let i = 0 ;
				for (const comparaison of comparaisons) {
					if (comparaison.filterEmptyRows && !p.comparaisonsValues[i]) return false;
					++i;
				}
				return true;
			});
	},
	[products, fieldToSet, inputSearchText, brandFilter, emptyBrand, productFilter, filterEmptyRows, additionalFieldsFilters, comparaisons, fieldToShow, allowedValues]);

	const slicedData = data.slice(pagination.offset, pagination.offset + pagination.step);

	return (
		<FlexDiv flow='column' align='stretch' gap='10px'>
			<FlexDiv width='100%' gap='10px'>
				<FlexDiv flexShrink={0} gap='10px' backgroundColor='white'padding='0 5px' ><Icon src={rowSvg} /><Dropdown dropdownStyle={DropdownStyle} datalist={productFields} selectedValue={productFields.find(f => f.value.id == fieldToSet?.id)} onChange={v => setFieldToSet(v.value)} cancellable name='dd1' /></FlexDiv>
				<FlexDiv flexShrink={0} gap='10px' backgroundColor='white'padding='0 5px' ><Icon src={rowSvg} flip/><Dropdown dropdownStyle={DropdownStyle} datalist={fieldToShowData} selectedValue={productFields.find(f => f.value.id == fieldToShow?.id)} onChange={v => setFieldToShow(v.value)} cancellable name='dd2' /></FlexDiv>
				<FilterBuilder
					filters={additionalFieldsFilters}
					setFilters={setAdditionalFieldsFilters}
					fieldToShowData={fieldToShowData}
					productFields={productFields}
					showAdd={!!fieldToSet && !!fieldToShow}
					filterEmptyRows={filterEmptyRows}
					setFilterEmptyRows={setFilterEmptyRows}
				/>
			</FlexDiv>
			{comparaisons.map((comparaison, index) => <FlexDiv width='100%' gap='10px' height={FILTER_HEIGHT_S} key={comparaison.id}>
				<Dot color={comparaison.color}/>
				<FilterBuilder
					filters={comparaison.filters}
					setFilters={t => setComparaisons((comparaisons) => {
						comparaisons[index].filters = t(comparaisons[index].filters);
						if (comparaisons[index].filters.length == 0) comparaisons.splice(index, 1);
						else comparaisons[index].staticValues = staticValuesFromFilters(comparaisons[index].filters);
						return [...comparaisons];
					})}
					filterEmptyRows={comparaison.filterEmptyRows}
					setFilterEmptyRows={b => setComparaisons((comparaisons) => {
						comparaisons[index].filterEmptyRows = b;
						return [...comparaisons];
					})}
					fieldToShowData={fieldToShowData}
					productFields={productFields}
					showAdd={true}
				/>
			</FlexDiv>)}
			<Table
				resizeValue={resize}
				EnableResize
				onResize={(value) => Object.keys(value).length > 0 && setResize(value)}
				height={`calc(100vh - ${250 + comparaisons.length * (FILTER_HEIGHT + 10)}px)`}
				columns={React.useMemo<ColumnType[]>(() => {
					const columns: ColumnType[] = [{
						disableSortBy: true,
						disableFilter: true,
						freeze: 'left',
						Header: 'name',
						id: 'name',
						accessor: (p) => <TableRowTitle onClick={() => setProductPopupState({ data: p.id, isOpen: true })}>{p.name}</TableRowTitle>,
						toolTip: (p) => p.name,
					},
					{
						disableSortBy: true,
						disableFilter: true,
						id: 'barcode',
						Header: translateToString('EAN-JAN barcode'),
						accessor: (p) => <TableRow>{p.barcode}</TableRow>,
						toolTip: (p) => <BarcodeWrapper format='EAN13' value={p.barcode ?? ''} width={1} height={70} botMargin='0px'/>,
						toolTipStyle: { backgroundColor: 'white', borderColor: 'black' }
					}];
					!!fieldToSet && comparaisons.forEach((comparaison, i) => {
						columns.push({
							disableSortBy: true,
							disableFilter: true,
							id: `COMPARAISON[${comparaison.id}]`,
							Header: <Dot color={comparaison.color} size='15px'/>,
							accessor: (p) => <Cell product={p} field={fieldToSet} staticValues={comparaison.staticValues} additionalValue={p.comparaisonsValues[i]} readonly />
						});
					});

					if (fieldToShow === undefined || !Array.isArray(fieldToShow.data)) return columns;
					for (const value of (fieldToShow.data as string[]).filter(f => !allowedValues || allowedValues.includes(f))) {
						columns.push({
							disableSortBy: true,
							disableFilter: true,
							Header: value,
							id: `ADDITIONAL_VALUE[${value}]`,
							accessor: (p) => <Cell product={p} field={fieldToShow} fieldValue={value} additionalValue={p.values[value]} setAdditionalValues={setAdditionalValues} staticValues={staticValues} />
						});
					}

					return columns;
				}, [fieldToShow, staticValues, comparaisons, allowedValues])}
				data={slicedData} />
			<Pagination label={'products'} steps={[25, 50, 100]} defaultStep={25} onChange={setPagination} amount={data.length}/>
			<ComponentLoader loadingState={loadingState} allScreen/>
			<ProductPopup isOpen={productPopupState.isOpen} setIsOpen={(isOpen) => setProductPopupState({ isOpen })} productId={productPopupState.data} />
		</FlexDiv>
	);
}

function FilterBuilder(props: {
	filters: FieldFilters,
	setFilters: (value: ((prevState: FieldFilters) => FieldFilters)) => void,
	productFields: DropdownData<Field>[],
	fieldToShowData: DropdownData<Field>[],
	showAdd: boolean,
	setFilterEmptyRows: (b: boolean) => void
	filterEmptyRows: boolean
}) {
	const { filters, setFilters, productFields, fieldToShowData } = props;
	return <>
		{filters.length > 0 && <FlexDiv gap='10px' height={FILTER_HEIGHT_S} overflow='auto'>
			{filters.map((t, myIndex) => (
				<FlexDiv key={t.field?.id} gap='10px' backgroundColor='white' padding='0 5px' flexShrink={0} height='100%'>
					<Select border={false} value={t.field?.id ?? -1} key={t.field?.id} id={`select_additional_fields[${t.field?.id}]`} onChange={e => {
						const parsedId = parseInt(e.target.value);
						const field = productFields.find(f => f.value.id == parsedId)?.value;
						return setFilters(t => {
							if (t[myIndex]?.field?.id == parsedId) return t;
							t[myIndex] = { field, fieldValue: undefined };
							return [...t];
						});
					} }>
						<option disabled value={-1}></option>
						{fieldToShowData.map((v) => (
							<option hidden={filters.some((t) => t.field?.id == v.value.id)} value={v.value.id} key={v.value?.id}>{v.value.name}</option>
						))}
					</Select>
					{t.field?.id && <FieldEditor field={t.field} value={t.fieldValue} onSave={v => setFilters(t => {
						t[myIndex].fieldValue = v;
						return [...t];
					})} />}

					<CleanButton
						className="btn btn-transparent p-0"
						type="button"
						onClick={() => {
							setFilters(t => {
								t.splice(myIndex, 1);
								return [...t];
							});
						}}
					>
						<i className="fas fa-times" />
					</CleanButton>
				</FlexDiv>
			))
			}
		</FlexDiv>}

		{props.showAdd && <Add onClick={() => setFilters(t => {
			if (t.some(t => !t.field?.id)) return t;
			return [...t, {}];
		})} />}
		{props.showAdd && <FlexDiv flexShrink={0} gap='10px'><Switch value={props.filterEmptyRows} onChange={props.setFilterEmptyRows}/> <SwitchText><Translate id='filter_empty_rows' /></SwitchText></FlexDiv>}
	</>;

}

function Cell({ product, field, fieldValue, staticValues, setAdditionalValues, readonly, additionalValue }: { product: InnerColumnType, field: Field, fieldValue?: string, staticValues: StaticValues, setAdditionalValues?: React.Dispatch<React.SetStateAction<AdditionalValue[]>>, readonly?: boolean, additionalValue: any }) {
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	if (loadingState === LoadingStateEnum.LOADING) return <Loader width='15px'/>;
	if (!product.field) return <TableRow />;
	const newStaticValues = { ...staticValues, f: product.field?.id };
	if (fieldValue) newStaticValues[field.id] = fieldValue;
	const path = buildCellPath({ product: product.uuid, staticValues: newStaticValues });
	if (readonly) return <TableRow>{additionalValue}</TableRow>;
	return <TableRow>
		<FieldEditor field={product.field} value={additionalValue} onSave={v => {
			setLoadingState(LoadingStateEnum.LOADING);
			saveAdditionalValue(path, v).then(() => {
				setLoadingState(LoadingStateEnum.LOADED);
				setAdditionalValues?.(av => {
					const ref = findAdditionalValueRef(path, av);
					if (ref) ref[1] = v;
					else av.push([path, v]);
					return [...av];
				});
			}).catch(() => setLoadingState(LoadingStateEnum.ERROR));
		}} />
	</TableRow>;
}