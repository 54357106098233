import React, {Fragment, useState} from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {Translate} from 'react-localize-redux';
import PageLoader from '../../../components/PageLoader';
import Overview from './Overview';
import Comments from './subDetail/Comments';
import Pictures from './subDetail/Pictures';
import ProductsRecap from './subDetail/ProductsRecap';
import MissingProducts from './subDetail/MissingProducts';
import {listTab} from '../../../helpers/data/enrichedForm';

function EnformDetail(props) {
  const { data, detail } = props;
  const [activeTab, setActiveTab] = useState(1);
  const products = (detail && detail.shelf_audit_product_instances) || [];
  const productsRecap =
    (products && products.filter(e => e.presence)) || [];
  const missingProducts =
    (products && products.filter(e => !e.presence)) || [];
  console.log(detail)
  return (
    <Fragment>
      <div className="mx-3 detail-top position-relative">
        {data.isLoading ? (
          <div>
            <PageLoader />
          </div>
        ) : (
          <Fragment>
            <div
              className="position-realative d-flex align-items-center p-3"
              style={{ top: 0 }}
            ></div>
            <div className="pb-3">
              <Overview detail={detail} history={props.history} />
            </div>
          </Fragment>
        )}
      </div>
      <Nav className="text-center d-flex justify-content-start tab-custom mx-4 my-3">
        {listTab.map((v, i) => (
          <NavItem className="mr-5">
            <NavLink
              to="#"
              className="px-1 py-0"
              active={activeTab === i + 1}
              onClick={() => {
                setActiveTab(i + 1);
              }}
            >
              <h2 className="card-title mb-0">
                <Translate id={v} />
              </h2>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className="tab-content">
        <div
          className={
            [1, 2].includes(activeTab) ? 'tab-pane active' : 'tab-pane'
          }
        >
          <Comments {...props} />
        </div>
        <div
          className={
            [1, 3].includes(activeTab) ? 'tab-pane active' : 'tab-pane'
          }
        >
          <Pictures {...props} />
        </div>
        <div
          className={
            [1, 4].includes(activeTab) ? 'tab-pane active' : 'tab-pane'
          }
        >
          <ProductsRecap {...props} productsRecap={productsRecap} />
        </div>

        <div
          className={
            [1, 5].includes(activeTab) ? 'tab-pane active' : 'tab-pane'
          }
        >
          <MissingProducts missingProducts={missingProducts} {...props} />
        </div>
      </div>
    </Fragment>
  );
}

export default EnformDetail;
