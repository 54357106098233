/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-for */
import {get} from 'lodash';
import React, {Component} from 'react';
import {Field} from 'redux-form';
import {validateNumber} from '../../../../../components/FieldModal';
import NumberField from '../../../../../components/NumberField';
import {fieldName} from '../services/fieldGetter';

class Numerical extends Component {
  state = {};

  render() {
    const { data, dynamicField, type } = this.props;
    const validate = data.is_required ? [validateNumber] : [];
    // console.log('type', type);
    console.log(validate);
    const min = get(data, 'details.min');
    const max = get(data, 'details.max');

    const name = fieldName(data, dynamicField);

    return (
      <Field
        name={name}
        id={`id-${name}`}
        type="text"
        component={NumberField}
        label={data.is_display_field_name && data.field_name}
        placeholder=""
        requiredStar={data.is_required}
        validate={validate}
        // min={min}
        // max={max}
        readOnly={type === 'detail'}
      />
    );
  }
}

export default Numerical;
