import arrow_circle from 'images/icon/arrow_circle.svg';
import * as React from 'react';
import { Button } from 'reactstrap';
import { BlueSidely } from '../styles/global/css/Utils';

const circle = (
	<div>
		<div style={{ width: '38px', marginLeft: '-19px', marginTop: '-3px' }}>
			<img src={arrow_circle} />
		</div>
		<div style={{ width: '38px', marginLeft: '-19px', marginTop: '-2px', transform: 'rotate(180deg)' }}>
			<img src={arrow_circle} />
		</div>
	</div>
);

export default function RefreshButton(props: { isLoading: boolean, onFilter: (obj: object) => void, disabled?: boolean }) {
	const [text, setText] = React.useState<JSX.Element>(circle);

	React.useEffect(() => {
		if (props.isLoading) {
			setText(<div style={{ width: '38px', marginLeft: '-17px', marginTop: '-2px' }}><i className="lds-dual-ring" /></div>);
		} else {
			setText(circle);
		}
	}, [props.isLoading]);

	function handleClick() {
		props.onFilter({ refresh: true });
	}

	return (
		<Button
			variant="primary"
			disabled={props.isLoading || props.disabled}
			onClick={!props.isLoading ? handleClick : null}
			style={{ height: '38px', backgroundColor: props.isLoading ? 'white' : BlueSidely, border: '1px solid rgba(0,0,0,0.125)', width: '38px' }}
		>
			{text}
		</Button>
	);
}
