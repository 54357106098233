import { RecoilState, atom, selector } from 'recoil';
import { AtomCategory, AtomState } from '../utils/model/Model';

// ----------[ FormTemplatePicker ]---------- //

const AGlobalFormTemplatePicker: AtomState<number[] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_form_template_picker',
		default: undefined
	})
};

export const AFormTemplatePicker: RecoilState<number[] | undefined> = selector({
	key: 'atom_shelf_form_picker',
	get: () => AGlobalFormTemplatePicker.atom,
	set: ({ set }, newValue) => set(AGlobalFormTemplatePicker.atom, newValue)
});
