import add_blue from 'images/icon/add_blue.png';
import mixtView_grey from 'images/ui_icon/mixtView_grey.svg';
import mixtView_black from 'images/ui_icon/mixtView_black.svg';
import listView_grey from 'images/ui_icon/listView_grey.svg';
import listView_black from 'images/ui_icon/listView_black.svg';
import React, { Component, Fragment } from 'react';
import { Translate } from 'react-localize-redux';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Search from './ContactsSearch';
import ListView from './contactsTables/ListView';
import MixtView from './contactsTables/MixtView';
import MapView from './contactsTables/MapView';
import ModalImportExcel from '../../../components/modal/create/ModalImportExcel';
import ModalExport from '../../../components/modal/create/ModalExportData';
import ModalFilterCompanies from '../../../components/modal/filter/ModalFilterCompanies';
import FilterTag from '../../../components/select/FilterTag';
import ModalError from '../../../components/modal/ModalErrorValidate';
import Restricted from '../../../containers_v2/permissions/Restricted';
import optionGrey from 'images/icon/options_grey.png';

class TableWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			viewType: 'list',
			currentDetailId: null,
			done: false,
			modalImport: false,
			modalExportType: 'Contacts',
			modalExportKind: undefined,
			modalExport: false,
			selectedRows: [],
			selectedRowsArray: []
		};
	}

	componentWillReceiveProps(nextProps) {
		const { data } = this.props;

		if (
			nextProps.data.length > 0 &&
      data !== nextProps.data
      // && !this.state.done
		) {
			this.setState({
				currentDetailId: nextProps.data[0].id,
				done: true
			});
		}
	}

	onChangeDetailId = id => {
		this.setState({
			currentDetailId: id
		});
		// console.log('currentDetailId', this.state.currentDetailId);
	};

	onSelect = row => {
		this.setState({
			selectedRows: row.toString(),
			selectedRowsArray: row
		});
	};

	handleSort = val => {
		const { handleSort } = this.props;
		this.setState({
			viewType: val
		});
		if (val === 'mixt') {
			handleSort('asc', 'firstName');
		} else {
			handleSort('asc', 'lastName');
		}
	};

	toggle = () => {
		this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
	};

	onOpenModalImport = () => {
		this.setState({ modalImport: true });
	};

	onCloseModal = () => {
		this.setState({ modalImport: false });
	};

	onOpenModalExport = (modalExportType, modalExportKind) => {
		this.setState({ modalExport: true, modalExportType, modalExportKind });
	};

	onCloseModalExport = () => {
		this.setState({ modalExport: false, modalExportKind: undefined });
	};

	render() {
		const { viewType, currentDetailId } = this.state;
		const {
			data,
			dataMixt,
			page,
			onTableChange,
			route,
			totalSize,
			columns,
			lastPage,
			handleFilter,
			isSelectRow,
			showPagination,
			loading,
			enableDownload,
			disablePagination,
			onNext,
			onPrev,
			onFirstPage,
			onLastPage,
			onFetch,
			onExport,
			onImport,
			onBulkDelete,
			handleFilterTag,
			current,
			modal,
			closeFilterCompanyModal,
			filterCompanies,
			handleLimit,
			limit,
			filterByAlphabet,
			modalErrorValidate,
			errorValidation
		} = this.props;

		return (
			(<div>
				<div className="table-bg sticky-v2 right-panel">
					<div className="row">
						<div className="col-md-3">
							<Search />
						</div>
						<div className="col-6">
							<FilterTag handleFilterTag={handleFilterTag} type={2} />
						</div>
						<div className="col-md-3 d-flex justify-content-end pr-3 pointer">
							<React.Fragment>
								<div
									className="mx-1 my-auto"
									onClick={() => this.handleSort('list')}
									style={{ cursor: 'pointer' }}
								>
									<img
										src={
											viewType === 'list'
												? listView_black
												: listView_grey
										}
										className="custom-icon-views"
										alt=""
									/>
								</div>
								<div
									className="mx-1 my-auto"
									onClick={() => this.handleSort('mixt')}
								>
									<img
										src={
											viewType === 'mixt'
												? mixtView_black
												: mixtView_grey
										}
										className="custom-icon-views"
										alt=""
									/>
								</div>
								<Restricted to={{ objectAction: 'CreateContact' }}>
									<div className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle">
										<a href={`${route.path}/create`}>
											<img
												src={add_blue}
												className="custom-icon"
												alt=""
											/>
										</a>
									</div>
								</Restricted>
								<Restricted to={[
									{ objectAction: 'CreateExport' },
									{ objectAction: 'DeleteContact' },
								]}>
									<div className="my-auto mx-2">
										<ButtonDropdown
											className="border-transparent"
											isOpen={this.state.dropdownOpen}
											toggle={this.toggle}
										>
											<DropdownToggle className="bg-white p-0">
												<img
													src={optionGrey}
													className="custom-icon"
													alt=""
												/>
											</DropdownToggle>
											<DropdownMenu className="custom-position-v2">
												{/* <DropdownItem>
                        <Translate id="advanced_filters" />
                      </DropdownItem>
                      <DropdownItem>
                        <Translate id="mass_modification" />
                      </DropdownItem> */}
												<Fragment>
													{/* <DropdownItem
                            onClick={() => {
                              this.onOpenModalImport();
                            }}
                          >
                            <Translate id="import_contacts" />
                          </DropdownItem> */}
													<DropdownItem
														onClick={() => {
															// onExport(this.state.selectedRows);
															this.onOpenModalExport('Contacts');
														}}
													>
														<Translate id="export_contacts" />
													</DropdownItem>
													<DropdownItem
														onClick={() => {
															// onExport(this.state.selectedRows);
															this.onOpenModalExport('Notes', 'Contacts');
														}}
													>
														<Translate id="exports.export_notes" />
													</DropdownItem>
												</Fragment>
												<DropdownItem
													onClick={() => {
														onBulkDelete(this.state.selectedRowsArray);
													}}
													// className="text-danger"
												>
													<Translate id="delete_selection" />
												</DropdownItem>
											</DropdownMenu>
										</ButtonDropdown>
									</div>
								</Restricted>
							</React.Fragment>
						</div>
					</div>
				</div>
				{viewType === 'list' && (
					<ListView
						data={data}
						page={page}
						onTableChange={onTableChange}
						route={route}
						totalSize={totalSize}
						columns={columns}
						lastPage={lastPage}
						handleFilter={handleFilter}
						isSelectRow={isSelectRow}
						showPagination={showPagination}
						loading={loading}
						enableDownload={enableDownload}
						disablePagination={disablePagination}
						onNext={onNext}
						onPrev={onPrev}
						onLastPage={onLastPage}
						onFirstPage={onFirstPage}
						onSelect={this.onSelect}
						handleLimit={handleLimit}
						limit={limit}
						filterByAlphabet={filterByAlphabet}
					/>
				)}
				{viewType === 'mixt' && (
					<MixtView
						data={dataMixt}
						totalSize={totalSize}
						currentDetailId={currentDetailId}
						isLastPage={page >= lastPage}
						isLoading={loading}
						onFetch={onFetch}
						route={route}
						onChangeTab={this.onChangeDetailId}
					/>
				)}
				{viewType === 'map' && <MapView data={data} />}
				<ModalImportExcel
					isOpen={this.state.modalImport}
					toggle={this.onCloseModal}
					onImport={onImport}
				/>
				<ModalExport
					isOpen={this.state.modalExport}
					toggle={this.onCloseModalExport}
					dataExport={this.state.selectedRows}
					exportType={this.state.modalExportType}
					exportKind={this.state.modalExportKind}
					onExport={onExport}
				/>
				<ModalFilterCompanies
					isOpen={modal.filterCompanies}
					toggle={closeFilterCompanyModal}
					onFilter={filterCompanies}
				/>
				<ModalError
					isOpen={modal.modalErrorMessage}
					toggle={modalErrorValidate}
					errorValidation={errorValidation}
				/>
			</div>)
		);
	}
}

export default TableWrapper;
