import styled from 'styled-components';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';

interface ContainerProps {
  marginLeft?: string
}

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-left: ${p => p.marginLeft ?? 'inherit'};
`;

interface CrumTextProps {
  isBold: boolean
  nonClickable?: boolean
}

const CrumText = styled.p<CrumTextProps>`
    ${DefaultText}
    color: #495057;
    font-size: 0.75rem;
    font-weight: ${p => p.isBold ? '700' : 'inherit'};
    cursor: ${p => p.nonClickable ? '' : 'pointer'};
`;

const CrumImage = styled(DefaultImage)`
    width: 22px;
    height: 22px;
    cursor: pointer;
    opacity: 0.7;
`;

interface SeparatorProps {
  value?: string
}

const Separator = styled.p<SeparatorProps>`
    ${DefaultText}
    color: #495057;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;

    &:before {
        content: "${p => p.value ? p.value : '>'}";
    }
`;

export {
	Container,
	CrumText,
	CrumImage,
	Separator
};
