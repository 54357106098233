import * as React from 'react';
import { loadingState, tooltipPos } from '../../../components_v2/models';
import { getEventsPerUser } from './actions';
import { InfiniteTable, InfiniteTableStyle } from '../../../components_v2/table/InfiniteTable';
import { useWindowDimensions } from '../../../components_v2/utils';
import { getStringWidth, queryConstructor } from '../utils';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import PageLoader from '../../../components/PageLoader';
import axios from 'axios';
import { reportingAction, reportingState } from '../Reducer';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { useRecoilValue } from 'recoil';
import { AUserFilter } from '../../../atoms/filter/usersFilterAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { ADatePicker } from '../../../atoms/filter/timeFilterAtom';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { RedSidely } from '../../../styles/global/css/Utils';
import { Column } from '../../../components_v2/table/Table';
import { FlexDiv } from '../../products/style';

interface EventType {
  name: string
  status: boolean
}

type EventInfo = Record<string, EventType>

type Counter = Record<string, number>

function get_total(counters: Counter): number | null {
	const keys = Object.keys(counters);
	if (keys.length == 0) return null;
	let sum = 0;
	let all_key_null = true;
	for (const key of keys) {
		if (counters[key] != null && counters[key] != undefined) {
			sum += counters[key];
			all_key_null = false;
		}
	}
	if (all_key_null) return null;
	return sum;
}

const sort = key => (rowA, rowB, _columnId, desc) => {
	const ra = rowA.original[key];
	const rb = rowB.original[key];
	if (!ra && !rb) return 0;
	if (!ra) return desc ? -1 : 1;
	if (!rb) return desc ? 1 : -1;
	if ((ra[0] / ra[1]) > (rb[0] / rb[1])) return 1;
	if ((ra[0] / ra[1]) < (rb[0] / rb[1])) return -1;
	return 0;
};

function getTooltip(toolTipMsg: string, counters: Counter, pos: tooltipPos) {
	const translate = getTranslate(storeLang.getState().localize);
	let tooltipClass = 'tooltiptext3';
	if (pos == 'up') {
		tooltipClass = 'tooltiptextup';
	}
	return (
		<span className={tooltipClass}>
			<span className='font-weight-bold' style={{ marginBottom: '2px' }}>
				{toolTipMsg}
			</span>
			{counters['2'] &&
        <div className='row'>
        	<div className='col-7' style={{ textAlign: 'right', padding: '0px' }}>
        		{translate('Completed') as string}
        	</div>
        	<div className='col-2' style={{ textAlign: 'center', padding: '0px' }}>
        		<span className='dot' style={{ backgroundColor: '#2ED47B' }} />
        	</div>
        	<div className='col-3' style={{ textAlign: 'left', padding: '0px' }}>
        		<span>{counters['2']}</span>
        	</div>
        </div>}
			{counters['1'] &&
        <div className='row'>
        	<div className='col-7' style={{ textAlign: 'right', padding: '0px' }}>
        		{translate('To Do') as string}
        	</div>
        	<div className='col-2' style={{ textAlign: 'center', padding: '0px' }}>
        		<span className='dot' style={{ backgroundColor: '#FFB945' }} />
        	</div>
        	<div className='col-3' style={{ textAlign: 'left', padding: '0px' }}>
        		<span>{counters['1']}</span>
        	</div>
        </div>}
			{counters['3'] &&
        <div className='row'>
        	<div className='col-7' style={{ textAlign: 'right', padding: '0px' }}>
        		{translate('Cancelled') as string}
        	</div>
        	<div className='col-2' style={{ textAlign: 'center', padding: '0px' }}>
        		<span className='dot' style={{ backgroundColor: RedSidely }} />
        	</div>
        	<div className='col-3' style={{ textAlign: 'left', padding: '0px' }}>
        		<span>{counters['3']}</span>
        	</div>
        </div>}
		</span>
	);
}

function spanCount(toolTipMsg: string, counters: any, pos: tooltipPos) {
	let tooltipClass = 'tooltip3';
	if (pos == 'up') {
		tooltipClass = 'tooltipup';
	}
	const total = get_total(counters);
	if (total != null) {
		return (
			<div className={tooltipClass} style={{ width: '25%' }} >
				<span className='overflow'>{total}</span>
				{getTooltip(toolTipMsg, counters, pos)}
			</div>
		);
	} else {
		return <span>-</span>;
	}
}

function genColumns(column_ids: number[], event_info: EventInfo, totals: any, rows: any[]): Column[] {
	const translate = getTranslate(storeLang.getState().localize);
	const user_name: string = translate('shelf_audit.column.user_name').toString();
	const columns: Column[] = column_ids.map(column_id => {
		const header_name: string = translate('event.' + event_info[column_id].name).toString();
		return {
			Header: header_name,
			accessor: row => {
				if (row[column_id] != undefined && row[column_id] != null) {
					return spanCount(row.company_name, row[column_id], 'left');
				} else {
					return <span>-</span>;
				}
			},
			width: getStringWidth(header_name),
			sortType: sort(column_id),
			Footer: _info => {
				if (totals[column_id]) {
					return spanCount('total', totals[column_id], 'up');
				} else {
					return '-';
				}
			}
		};
	});
	const static_colmuns: Column[] = [
		{
			Header: user_name,
			accessor: row => {
				const content =
          <span>
          	{row.user_count && <span>{' '}({row.user_count})</span>}
          </span>;
				if (row.hasOwnProperty('user_name')) {
					return (
						<span style={{ display: 'block', textAlign: 'left', cursor: 'pointer', fontWeight: 500 }}
							className='overflow'
						>
							<span>
								{row.user_name}
								{content}
							</span>
						</span>
					);
				}
			},
			sortType: sort('user_name'),
			width: 200,
			Footer: _info => {
				if (totals.user_count != undefined && totals.user_count != null) {
					return <span>{totals.user_count}</span>;
				} else {
					return '-';
				}
			}
		},
		{
			Header: 'Total',
			accessor: row => {
				if (row.total != undefined && row.total != null) {
					return spanCount(row.user_name, row.total, 'left');
				} else {
					return <span>-</span>;
				}
			},
			width: getStringWidth('Total'),
			Footer: _info => {
				if (totals.total != undefined && totals.total != null) {
					return spanCount('total', totals.total, 'up');
				} else {
					return '-';
				}
			}
		}
	];
	return static_colmuns.concat(columns);
}

function everyObject(obj: Object, f: Function) {
	for (const [insideKey, insideValue] of Object.entries(obj)) {
		if (!f(insideValue)) {
			return false;
		}
	}
	return true;
}

function filterData(filters: Object, data: any[]) {
	return data.map(e => {
		for (const [key, value] of Object.entries(e)) {
			// @ts-expect-error
			if (isNaN(key)) {
				continue;
			}
			if (filters.event_type && e[key] && !filters.event_type.some(type => type == key)) {
				for (const [statusKey, statusValue] of Object.entries(e[key])) {
					// @ts-expect-error
					e.total[statusKey] -= statusValue;
					if (e.total[statusKey] == 0) {
						// delete e['total'][statusKey];
						e.total[statusKey] = null;
					}
					if (e.subRows) {
						e.subRows = filterData(filters, e.subRows);
					}
				}
				delete e[key];
			}
			if (filters.event_status && e[key]) {
				for (const [statusKey, statusValue] of Object.entries(e[key])) {
					if (!filters.event_status.some(type => type == statusKey)) {
						e[key][statusKey] = null;
						// @ts-expect-error
						e.total[statusKey] -= statusValue;
						if (e.total[statusKey] == 0) {
							// delete e['total'][statusKey];
							e.total[statusKey] = null;
						}
						if (e.subRows) {
							e.subRows = filterData(filters, e.subRows);
						}
					}
				}
				if (everyObject(e[key], n => !n)) {
					// delete e[key]
					e[key] = null;
				}
			}
		}
		return e;
	});
}

export function EventsPerUser(props: {
  setToolBarState: (value: ToolbarState) => void
  state: reportingState
  dispatch: (action: reportingAction) => void
}) {
	const { height, width } = useWindowDimensions();
	const offset = 235;
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any>({});
	const c: Column[] = [];
	const [columns, setColumns] = React.useState(c);

	const filteredUsers = useRecoilValue(AUserFilter);
	const pickedDates = useRecoilValue(ADatePicker);
	const filteredTags = useRecoilValue(ATagFilter);
	const translate = getTranslate(storeLang.getState().localize);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 120}
					category={AtomCategory.GLOBAL}
					elements={[
						ToolbarElement.USER_FILTER,
						{
							kind: ToolbarElement.DATE_PICKER,
							oneDay: true,
							future: true
						},
						ToolbarElement.TAG_FILTER
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'} />
			</FlexDiv>,
			bottomRightToolbarComponent: <></>
		});
	}, [loadingState, width]);

	React.useEffect(() => {
		if (!loadingState && filteredUsers !== undefined && (pickedDates != null) && (filteredTags != null)) {
			try {
				if (!loadingState) {
					setLoadingState('loading');
				}
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				let dateStr = '';
				if (pickedDates[0]?.format('YYYY-MM-DD') == pickedDates[1]?.format('YYYY-MM-DD') && (pickedDates[0] != null) && (pickedDates[1] != null)) {
					dateStr = `${pickedDates[0].format('YYYY-MM-DD')}_${pickedDates[1].format('YYYY-MM-DD')}`;
				} else {
					dateStr = `${pickedDates[0]?.format('YYYY-MM-DD') ?? ''}_${pickedDates[1]?.format('YYYY-MM-DD') ?? ''}`;
				}
				getEventsPerUser(queryConstructor({
					userId: filteredUsers,
					tags: filteredTags
				}, dateStr), cancelTokenSource.token)
					.then(res => {
						setData(res.data);
						const columns = genColumns(res.data.columns, res.data.event_info, res.data.totals, res.data.rows);
						setColumns(columns);
						// if (typeof (props.toolBarState.columns) !== 'undefined' && typeof (props.toolBarState.columns.setDotColumns) !== 'undefined')
						//   props.toolBarState.columns.setDotColumns(columns)
						if (loadingState != 'loaded') {
							setLoadingState('loaded');
						}
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job in: EventsPerUser ', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState, filteredUsers, pickedDates, filteredTags]);

	let ret: any[] = [];
	// if (typeof (props.toolBarState.columns) !== 'undefined' && props.toolBarState.columns.columns.length != 0 && props.toolBarState.columns.columns[0].columns) {
	//   ret = props.toolBarState.columns.columns
	// }
	// else {
	ret = columns;
	// }
	const filteredData = data.rows ? filterData(props.state.filters.EventsPerCompany, JSON.parse(JSON.stringify(data.rows))) : [];
	const totals = filteredData.reduce((acc, e) => {
		for (const [key, value] of Object.entries(e)) {
			// @ts-expect-error
			if (isNaN(key) || !value) {
				continue;
			}
			for (const [insideKey, insideValue] of Object.entries(e[key])) {
				if (!acc[key]) { acc[key] = {}; }
				if (!acc[key][insideKey]) { acc[key][insideKey] = 0; }
				// @ts-expect-error
				acc[key][insideKey] += insideValue;
				if (!acc.total) { acc.total = {}; }
				if (!acc.total[insideKey]) { acc.total[insideKey] = 0; }
				// @ts-expect-error
				acc.total[insideKey] += insideValue;
			}
		}
		return acc;
	}, { company_count: data.totals?.company_count });
	data.columns ? ret = genColumns(data?.columns, data?.event_info, totals, data?.rows) : '';
	return (
		<div style={{ height: '100%' }}>
			<InfiniteTableStyle>
				{(loadingState == 'loading' || !loadingState) && <div className="list-loader"><PageLoader /></div>}
				{loadingState == 'error' && <p>Error fetching data</p>}
				{loadingState == 'loaded' && <InfiniteTable height={height - offset} columns={ret} data={filteredData} />}
			</InfiniteTableStyle>
		</div>
	);
}
