import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

// ----------[ ProductFilter ]---------- //
export type ProductAtomType = {
	products: number[],
	all: boolean
} | undefined

const AglobalProductFilter: AtomState<ProductAtomType> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_product_filter',
		default: undefined
	})
};

const AorderProductFilter: AtomState<ProductAtomType> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_product_filter',
		default: undefined
	})
};

const AgalleryProductFilter: AtomState<ProductAtomType> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_product_filter',
		default: undefined
	})
};

const AcompanyProductFilter: AtomState<ProductAtomType> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_product_filter',
		default: undefined
	})
};

const AcalendarProductFilter: AtomState<ProductAtomType> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_product_filter',
		default: undefined
	})
};

const AProductFilter: RecoilState<ProductAtomType> = selector({
	key: 'atom_product_filter',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<ProductAtomType> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalProductFilter.atom;
		case AtomCategory.ORDER:
			return AorderProductFilter.atom;
		case AtomCategory.GALLERY:
			return AgalleryProductFilter.atom;
		case AtomCategory.COMPANY:
			return AcompanyProductFilter.atom;
		case AtomCategory.CALENDAR:
			return AcalendarProductFilter.atom;
		default:
			return AglobalProductFilter.atom;
	}
}

export {
	AorderProductFilter,
	AcompanyProductFilter,
	AgalleryProductFilter,
	AglobalProductFilter,
	AProductFilter,
	AcalendarProductFilter
};
