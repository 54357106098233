import optionGrey from 'images/icon/options_grey.png';
import eye from 'images/icons/company/eye.svg';
import * as React from 'react';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { UserBlock } from '../../components_v2/dropdown/DropdownOwners';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { Column, DataType } from '../../components_v2/table/Table';
import { DarkGreySidely } from '../../styles/global/css/Utils';
import { Translate, translateToString } from '../../styles/global/translate';
import { AlertContext } from '../alert/AlertProvider';
import { ColumnResume } from '../client-companies/Companies';
import { DateAccessor, deleteCompanyIdPopup } from '../client-companies/data/CompanyColumns';
import { PartialOpen } from '../client-companies/style/Style';
import { ColumnHidder } from '../contact/data/ContactColumns';
import { Owner } from '../orders/model/Model';
import { TableRow } from '../orders/templateOrders/style/Style';
import { IsAllowedToDefinition } from '../permissions/PermissionContext';
import Restricted from '../permissions/Restricted';
import { ModalState } from '../products/model';
import { FlexDiv } from '../products/style';
import { TARGET_HIDDEN_LOCAL_STORAGE, TARGET_SORT_LOCAL_STORAGE, Target } from './Targets';
import { deleteTarget } from './actions';

export const ALL_TARGETS_COLUMNS: ColumnResume[] = [
	{ name: 'name', excluded: true },
	{ name: 'target' },
	{ name: 'id', hidded: true },
	{ name: 'granularity' },
	{ name: 'users' },
	{ name: 'created_at' },
	{ name: 'created_by', hidded: true },
	{ name: 'updated_at', hidded: true },
	{ name: 'quick_actions', excluded: true }
];

export default function genTargetsColumns(
	users: Owner[],
	setModalState: React.Dispatch<React.SetStateAction<ModalState<{target: Target, mode: PopupMode }>>>,
	refresh: () => void,
	isAllowedTo: IsAllowedToDefinition
): Array<Column<Target>> {
	return [
		{
			id: 'name',
			Header: translateToString('name'),
			accessor: row => <TableRow
				fontWeight="500"
				color={DarkGreySidely}
				cursor="pointer"
				onClick={() => setModalState({ isOpen: true, data: { target: row, mode: PopupMode.Default } })}
			>
				{row.name}
			</TableRow>,
			hoverImage: row => <PartialOpen src={eye} onClick={() => setModalState({ isOpen: true, data: { target: row, mode: PopupMode.Details } })} />,
			width: undefined,
			minWidth: 150,
			maxWidth: undefined,
			type: 'string',
			freeze: 'left',
			toolTip: row => row.name
		},
		{
			id: 'target',
			Header: translateToString('target'),
			accessor: row => <TableRow>
				{row.target}
			</TableRow>,
			width: undefined,
			minWidth: 150,
			maxWidth: undefined,
			type: 'string',
		},
		{
			id: 'id',
			Header: 'Id',
			accessor: row => row.id,
			width: undefined,
			minWidth: 100,
			maxWidth: undefined,
			type: 'numeric',
		},
		{
			id: 'granularity',
			Header: translateToString('granularity'),
			accessor: row => <TableRow><Translate id={row.granularity} /></TableRow>,
			width: undefined,
			minWidth: 100,
		},
		{
			id: 'users',
			Header: translateToString('users'),
			accessor: row => <TableRow>
				<FlexDiv gap='10px' overflow='auto' >
					{Object.keys(row.data).map(user_id => <UserBlock noLabel key={user_id} user={users.find(u => u.id === parseInt(user_id))} noCursor />)}
				</FlexDiv>
			</TableRow>,
			width: undefined,
			minWidth: 100,
			disableSortBy: true,
			disableFilter: true
		},
		{
			id: 'created_at',
			Header: translateToString('created_at'),
			accessor: row => <DateAccessor date={row.created_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'created_by',
			Header: translateToString('created_by'),
			accessor: row => <UserBlock user={users?.find(u => u.id == row.created_by)} />,
			width: 200,
			minWidth: 150,
			type: 'user'
		},
		{
			id: 'updated_at',
			Header: translateToString('updated_at'),
			accessor: row => <DateAccessor date={row.updated_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'quick_actions',
			noHeaderEllipsis: true,
			Header: <ColumnHidder categories={[]} columns={ALL_TARGETS_COLUMNS} sortColumnKey={TARGET_SORT_LOCAL_STORAGE} hiddenColumnKey={TARGET_HIDDEN_LOCAL_STORAGE} />,
			accessor: row => <Restricted to={{ objectAction: 'DeleteObjective' }} ownerId={row.created_by}>
				<DropdownOptions row={row} onDelete={id => deleteTarget(id).then(refresh)} />
			</Restricted>,
			width: 40,
			minWidth: 40,
			disableSortBy: true,
			disableFilter: true,
			unresizeable: true,
		}
	];
}

function DropdownOptions(props: { row: DataType<Target>, onDelete?: (id: number) => void }): JSX.Element {
	const { row } = props;
	const { alertDelete } = React.useContext(AlertContext);
	const list: DropdownData<string>[] = [];
	if (props.onDelete) {
		list.push({
			label: translateToString('global.quick_action.delete'),
			value: 'DELETE'
		});
	}
	if (list.length == 0) return <></>;
	return <Dropdown
		name='quick_action'
		datalist={list}
		readOnly
		dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
		JSXButton={() =>
			<img
				src={optionGrey}
				width={25}
				height={25}
				style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
			/>
		}
		onChange={(value: DropdownData) => {
			if (value.value === 'DELETE') {
				deleteCompanyIdPopup(alertDelete, row.id, row.name, () => props.onDelete?.(row.id));
			}
		}}
	/>;
}
