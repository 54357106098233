import * as React from 'react';
import styled from 'styled-components';
import { BlueSidely } from '../../styles/global/css/Utils';
import right from 'images/icon/right.png';
import CloseWhiteImage from 'images/icons/orders/close_white.svg';
import { DefaultButton } from '../../styles/global/css/GlobalButton';

export const HEADBAND_ANIMATION_TIME = '0.5s;';
export const TOP_HEADBAND_HEIGHT_NB = 60;
export const TOP_HEADBAND_HEIGHT = `${TOP_HEADBAND_HEIGHT_NB}px`;

export const HeadBandClose = styled.div`
	cursor: pointer;
	background-image: url('${CloseWhiteImage}');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;
`;

export const HeadBandButton = styled(DefaultButton)`
	transition: none;
	color: #7383A4;
	background-color: white;
	margin: 0;
	line-height: unset;
	font-size: 10px;
	min-height: 3.5em;

	&:hover:enabled {
		filter: none;
	}
`;

const FlowContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 201;
	display: flex;
	flex-flow: column;
	align-items: center;
`;

const FlowBar = styled.div<{ isOpen: boolean }>`
	width: 100%;
	height: ${TOP_HEADBAND_HEIGHT};
	margin-top: ${p => p.isOpen ? '0' : `-${TOP_HEADBAND_HEIGHT}`};
	background-color: ${BlueSidely};
	transition: margin ${HEADBAND_ANIMATION_TIME};
	overflow: hidden;
	color: white;
	font-size: 14px;
	font-weight: 600;
`;

const HorizontalButtonModal = styled.div`
	background-color: ${BlueSidely};
	padding: 0 4%;
	border-radius: 0 0 3px 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const HorizontalButtonModalImage = styled.img<{ isOpen: boolean }>`
	transform: rotate(${p => p.isOpen ? '-90deg' : '90deg'});
	transition: ${HEADBAND_ANIMATION_TIME};
`;

export enum TopHeadBandChildType {
	ProspectionFlow,
	Promotion,
	Error
}

interface TopHeadBandType {
	isOpen: boolean,
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
	ref: React.MutableRefObject<HTMLElement | undefined>,
	setChild: (p: { child: JSX.Element, type: TopHeadBandChildType, options?: ChildOption } | undefined) => void,
	childType: TopHeadBandChildType | undefined,
}

const defaultBehaviour: TopHeadBandType = {
	isOpen: false,
	setIsOpen: () => { return; },
	ref: { current: undefined },
	setChild: () => { return; },
	childType: undefined,
};

export const TopHeadBandContext = React.createContext<TopHeadBandType>(defaultBehaviour);

type ChildOption = {
	noButton?: boolean
}

type ChildAndOptions = {
	child: JSX.Element,
	options: ChildOption
}

export function TopHeadBandProvider(props: {
	children: React.ReactNode
}) {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [child, setChild_] = React.useState<JSX.Element | ChildAndOptions>();
	const [childType, setChildType] = React.useState<TopHeadBandChildType>();
	const ref = React.useRef<HTMLElement>();
	const setChild = React.useCallback((p: { child: JSX.Element, type: TopHeadBandChildType, options?: ChildOption } | undefined) => {
		if (!p) {
			setChild_(undefined);
			setChildType(undefined);
			return;
		}
		if (p.options) {
			setChild_({ child: p.child, options: p.options });
		} else {
			setChild_(p.child);
		}
		setChildType(p.type);
	}, []);

	const measuredRef = React.useCallback((node: HTMLElement) => {
		if (node !== null) {
			ref.current = node;
		}
	}, []);
	const options: ChildOption = child && 'options' in child ? child.options : {};

	return <TopHeadBandContext.Provider value={{ isOpen, setIsOpen, ref, setChild, childType }}>
		<FlowContainer innerRef={measuredRef}>
			<FlowBar isOpen={isOpen}>
				{child && 'child' in child ? child.child : child}
			</FlowBar>
			{child && !options.noButton && <HorizontalButtonModal onClick={() => setIsOpen(!isOpen)}>
				<HorizontalButtonModalImage src={right} isOpen={isOpen} />
			</HorizontalButtonModal>}
		</FlowContainer>
		{props.children}
	</TopHeadBandContext.Provider>;
}