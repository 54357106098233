export function getLocalStorage<T = unknown>(path: string): T | undefined {
	try {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return JSON.parse(localStorage.getItem(path)!);
	} catch (e) {
		return undefined;
	}

} 

export function setLocalStorage<T = unknown>(path: string, data: T): void {
	localStorage.setItem(path, JSON.stringify(data));
}

// reset localStorage Params
export const resetParams = () => {
	localStorage.removeItem('typeView');
	const params = {
		page: 1,
		limit: 50,
		sort: 'asc',
		by: 'name'
	};
	localStorage.setItem('params', JSON.stringify(params));
	localStorage.setItem('mapParams', JSON.stringify({}));
};