import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import styled from 'styled-components';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners from '../../../components_v2/dropdown/DropdownOwners';
import DropdownStatus from '../../../components_v2/dropdown/DropdownStatus';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { FilterTree } from '../../../components_v2/filter/model/Model';
import storeLang from '../../../helpers/storeLang';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { FlexDiv } from '../../products/style';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { Owner } from '../../orders/model/Model';
import { CalendarMapping, colorStatusMapping, colorTypeMapping } from '../model/Model';
import { bulkDuplicate, bulkEditDates, bulkEditOwner, bulkEditStatus, bulkEditType } from '../EventsApi';
import { SidelyBlack } from '../../../styles/global/css/Utils';
import * as moment from 'moment';
import Input from '../../../components_v2/input/Input';
import { Radio } from '../../../components_v2/filterList/style/Style';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { normalizeIntAbove0 } from '../../globals/FieldFunction';
import { Collapse } from 'reactstrap';
import { translateToString } from '../../../styles/global/translate';

type Data = { negative?: boolean, opt: BulkEditDateOptions };
enum BulkEditDateOptions {
	Hour = 'Hour',
	Day = 'Day',
	Week = 'Week'
	// Month = 'Month'
}

enum BulkEditStyle {
	Duplicate,
	Modify
}

const RadioText = styled.div<{ isActive: boolean }>`
  ${DefaultText}
  ${p => p.isActive ? '' : 'color: rgba(37, 65, 83, 0.35);'}
  transition: 0.5s;
`;

const OWNER = 0; const STATUS = 1; const TYPE = 2; const DATE = 3;

const ButtonText = styled.div`
  font-weight: 600;
  font-size: 13px;
`;

const DROPDOWN_STYLE = {
	containerWidth: '90%',
	optionWidth: '100%',
	labelUp: true,
	optionHeight: '150px',
	height: '50px',
	labelStyle: {
		fontSize: '14px',
		fontWeight: '500',
		padding: '0 0 15px 0',
		color: SidelyBlack
	},
	width: '100%',
	borderRadius: '4px'
};

export default function EventBulkEdition(props: {
  ids: number[]
  refresh?: () => void
  onClose?: () => void
  isAll: boolean
  new_filters?: FilterTree
  start: string
  end: string
}): JSX.Element {
	const { ids, refresh, isAll, new_filters, end, start } = props;
	const translate = getTranslate(storeLang.getState().localize);
	const [selectedProperty, setSelectedProperty] = React.useState<number>();
	const rawUsers = useRecoilValue(AUsers);
	const users: Array<DropdownData<Owner>> = rawUsers.map(u => ({ label: u.name, value: u, image: u.photoUrl }));
	const types = colorTypeMapping.map(s => ({ label: translate(`event.${s.label}`).toString(), value: s, color: s.color }));
	const statuses = colorStatusMapping.map(s => ({ label: translate(`event.${s.label}`).toString(), value: s, color: s.color }));

	// Properties
	const [owner, setOwner] = React.useState<DropdownData<Owner>>(users.find(u => u.value.isYou) ?? users[0]);
	const [status, setStatus] = React.useState<DropdownData<CalendarMapping>>(statuses[0]);
	const [type, setType] = React.useState<DropdownData<CalendarMapping>>(types[0]);
	const [dateDiff, setDateDiff] = React.useState<string>('1');
	const [dateOption, setDateOption] = React.useState<Data>({ opt: BulkEditDateOptions.Day });
	const [dateOptionBulkStyle, setDateOptionBulkStyle] = React.useState(BulkEditStyle.Duplicate);

	const CompanyProperties: DropdownData[] = [
		{ label: translate('owner').toString(), value: OWNER },
		{ label: translate('status').toString(), value: STATUS },
		{ label: translate('type').toString(), value: TYPE },
		{ label: translate('date').toString(), value: DATE },
	];

	const isDisabled = (): boolean => {
		switch (selectedProperty) {
			case OWNER:
				return owner == undefined;
			case STATUS:
				return status == undefined;
			case TYPE:
				return type == undefined;
			case DATE:
				return dateDiff == undefined || isNaN(parseInt(dateDiff)) || !dateOption;
			default:
				return true;
		}
	};

	const subComponent = () => {
		switch (selectedProperty) {
			case OWNER:
				return <DropdownOwners
					dropdownStyle={DROPDOWN_STYLE}
					onChange={value => value.value && setOwner(value as DropdownData<Owner>)}
					users={users}
					selected={owner}
					border
					label={<Translate id='company_owner' />}
				/>;
			case STATUS:
				return <DropdownStatus<CalendarMapping>
					name='dropdown-status'
					dropdownStyle={DROPDOWN_STYLE}
					datalist={statuses}
					onChange={(value: DropdownData<CalendarMapping>) => setStatus(value)}
					selectedValue={status}
				/>;
			case TYPE:
				return <DropdownStatus<CalendarMapping>
					name='dropdown-type'
					dropdownStyle={DROPDOWN_STYLE}
					datalist={types}
					onChange={(value: DropdownData<CalendarMapping>) => setType(value)}
					selectedValue={type}
				/>;
			case DATE: {
				const list: DropdownData<Data>[] = [
					{ label: translateToString('hours_later'), value: { opt: BulkEditDateOptions.Hour } },
					{ label: translateToString('days_later'), value: { opt: BulkEditDateOptions.Day } },
					{ label: translateToString('weeks_later'), value: { opt: BulkEditDateOptions.Week } },
					{ label: translateToString('hours_earlier'), value: { negative: true, opt: BulkEditDateOptions.Hour } },
					{ label: translateToString('days_earlier'), value: { negative: true, opt: BulkEditDateOptions.Day } },
					{ label: translateToString('weeks_earlier'), value: { negative: true, opt: BulkEditDateOptions.Week } },
				];
				const jsx = <FlexDiv gap='1em' padding='0 0 15px 0'>
					<div style={{ width: '100%' }}>
						<FlexDiv width='90%' gap='0.5em'>
							<Input
								normalize={[normalizeIntAbove0]}
								name={''}
								type={'number'}
								inputStyle={{ ...DROPDOWN_STYLE, padding: '10px', containerWidth: '70px', flexShrink: 0 }}
								value={dateDiff}
								onChange={setDateDiff}
							/>
							<Dropdown<Data>
								datalist={list}
								name={'ddqzdz'}
								dropdownStyle={DROPDOWN_STYLE}
								selectedValue={list.find(e => e.value.negative === dateOption.negative && e.value.opt === dateOption.opt)}
								onChange={(e: DropdownData<Data>) => setDateOption(e.value)}
							/>
						</FlexDiv>
					</div>
				</FlexDiv>;
				return <div style={{ height: 220 }}>
					<FlexDiv gap='10px' onClick={() => setDateOptionBulkStyle(BulkEditStyle.Duplicate)} margin='10px 0' clickable>
						<Radio isActive={dateOptionBulkStyle == BulkEditStyle.Duplicate} inactiveColor='rgba(37, 65, 83, 0.35)' /><RadioText
							isActive={dateOptionBulkStyle == BulkEditStyle.Duplicate}><Translate id='duplicate_events' /></RadioText>
					</FlexDiv>
					<Collapse isOpen={dateOptionBulkStyle == BulkEditStyle.Duplicate}>
						{jsx}
					</Collapse>
					<FlexDiv gap='10px' onClick={() => setDateOptionBulkStyle(BulkEditStyle.Modify)} margin='10px 0' clickable>
						<Radio isActive={dateOptionBulkStyle == BulkEditStyle.Modify} inactiveColor='rgba(37, 65, 83, 0.35)' /><RadioText
							isActive={dateOptionBulkStyle == BulkEditStyle.Modify}><Translate id='modify_events' /></RadioText>
					</FlexDiv>
					<Collapse isOpen={dateOptionBulkStyle == BulkEditStyle.Modify}>
						{jsx}
					</Collapse>
				</div>;
			}
			default:
				return <></>;
		}
	};

	const onEdit = () => {
		props.onClose?.();
		const body = { events: ids, all: isAll, new_filters, start: moment(start).toISOString(), end: moment(end).toISOString() };
		switch (selectedProperty) {
			case OWNER:
				bulkEditOwner({ ...body, owner: owner?.value.id })
					.then(() => refresh?.())
					.catch(console.error);
				break;
			case STATUS:
				bulkEditStatus({ ...body, status: status?.value.id })
					.then(() => refresh?.())
					.catch(console.error);
				break;
			case TYPE:
				bulkEditType({ ...body, type_id: type?.value.id })
					.then(() => refresh?.())
					.catch(console.error);
				break;
			case DATE: {
				const fn = dateOptionBulkStyle == BulkEditStyle.Modify ? bulkEditDates : bulkDuplicate;
				fn({ ...body, moving: {
					number: parseInt(dateDiff) * (dateOption.negative ? -1 : 1),
					event_granularity: dateOption.opt
				} })
					.then(() => refresh?.())
					.catch(console.error);
				break;
			}
		}
	};

	return <FlexDiv flow='column' gap='50px' margin='50px 0 0 0' padding='0 0 0 5%' align='stretch'>
		<Dropdown
			dropdownStyle={DROPDOWN_STYLE}
			datalist={CompanyProperties}
			name='dropdown_type'
			readOnly
			label={<Translate id='property_to_update' />}
			onChange={(value: DropdownData) => setSelectedProperty(value.value)}
		/>
		{subComponent()}
		{selectedProperty !== undefined && <DefaultButton disabled={isDisabled()} onClick={onEdit} height='30px'>
			<ButtonText>
				<Translate id="validate" />
			</ButtonText>
		</DefaultButton>
		}
	</FlexDiv>;
}
