import { GET_PAGE } from '../actions/page.action';

const initState = {
	page: 1
};

export default function ModalReducer(state = initState, action) {
	switch (action.type) {
		case GET_PAGE:
			return {
				...state,
				page: action.payload
			};
		default:
			return state;
	}
}
