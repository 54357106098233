/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React, { Fragment } from 'react';
import swal from 'sweetalert2';
import { getTranslate, Translate } from 'react-localize-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import storeLang from '../../../helpers/storeLang';
import Restricted from '../../../containers_v2/permissions/Restricted';

class DropdownAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle(e) {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	onDelete = (id, name) => {
		const translate = getTranslate(storeLang.getState().localize);
		const { deleteData } = this.props;

		swal
			.fire({
				title: 'Attention!',
				text: translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${name}'`),
				type: 'warning',
				confirmButtonText: translate('global.yes'),
				confirmButtonColor: '#f7685b'
			})
			.then(result => {
				if (result.value) {
					deleteData(id);
				}
			});
	};

	render() {
		const { current, id, name, setModalDuplicate, history, permission } = this.props;
		const role = current.current.role.slug;

		return (
			<Restricted to={permission ? [
				// { objectAction: `Update${permission}` },
				{ objectAction: `Create${permission}` },
				{ objectAction: `Delete${permission}` }
			] : []}>
				<UncontrolledButtonDropdown direction="down">
					<span className="my-auto text-center p-2">Action</span>
					<DropdownToggle className="pr-2 pl-0 bg-white" caret />
					<DropdownMenu dropdownOpen={this.state.dropdownOpen}>
						{/* className={count && count < 3 && `dropdownTransform`}> */}
						<Fragment>
							{/* <DropdownItem
              onClick={() => {
                history.push(`/formBuilder/edit/${id}`);
              }}
            >
              <Translate id="edit" />
            </DropdownItem> */}
							<Restricted to={permission ? { objectAction: `Create${permission}` } : []}>
								<DropdownItem
									onClick={() => {
										setModalDuplicate(id, name);
										this.toggle();
									}}
								>
									<Translate id="duplicate" />
								</DropdownItem>
							</Restricted>
							<Restricted to={permission ? { objectAction: `Delete${permission}` } : []}>
								<DropdownItem
									onClick={() => {
										this.onDelete(id, name);
										this.toggle();
									}}
								>
									<Translate id="delete" />
								</DropdownItem>
							</Restricted>
						</Fragment>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</Restricted>
		);
	}
}

export default DropdownAction;
