/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import {getClientStatus} from '../../store/actions/fieldSettings.action';

class SelectStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { getClientStatus } = this.props;
    getClientStatus(true);
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (data !== nextProps.data) {
        const opt = nextProps.data.map(data => ({
            value: data.id,
            label: data.name
          }));
        this.setState({ opt });
    }
  }

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      placeholder,
      disabled
    } = this.props;
    // console.log('incharge', data);
    // console.log('id', id);
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="inputOwner"
        disabled={disabled}
        placeholder={placeholder}
        validate={validate}
        isClearable={false}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.fieldSettings.clientStatus
});

export default connect(
  mapStateToProps,
  {
    getClientStatus
  }
)(SelectStatus);
