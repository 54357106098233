import * as React from 'react';
import { Column, DataType } from '../../components_v2/table/Table';
import { TableRow } from '../orders/templateOrders/style/Style';
import { Promotion } from './model';
import { translateToString } from '../../styles/global/translate';
import { DarkGreySidely, FilterWhite } from '../../styles/global/css/Utils';
import Switch from '../../components_v2/Switch/Switch';
import * as moment from 'moment';
import { RangePickerState } from './Promotions';
import { useWindowDimensions } from '../../components_v2/utils';
import styled from 'styled-components';
import arrow_right_date_picker from 'images/icon/arrow_right_date_picker.svg';
import * as ReactDates from 'react-dates';
import { PutPromotionBody } from './actions';
import { DateAccessor, EditableContent } from '../client-companies/data/CompanyColumns';
import { ModalState } from '../products/model';
import { PartialOpen } from '../client-companies/style/Style';
import eye from 'images/icons/company/eye.svg';
import { LoadingStateEnum } from '../import/model';
import { PopupMode } from '../../components_v2/popup/model/Model';

const RangeControllerInputDiv = styled.div`
	display: flex;
	align-items: center;
`;

const TimeButton = styled.div<{ noEdit?: boolean }>`
	border-radius: 5px;
	padding: 11px 11px 9px;
	font-size: 13px;
	font-weight: 500;
	width: 100px;
	text-align: center;
	${p => p.noEdit ? '' : 'cursor: pointer;'}
	${p => p.noEdit ? '' : `
		&:hover {
			background-color: #ccf0ff;
		}
	`}
`;

export function RangeControllerInput(props: {
	startDate: moment.Moment | null,
	endDate: moment.Moment | null,
	setRangePickerState?: React.Dispatch<React.SetStateAction<RangePickerState>>,
	onDatesChange?: (p: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => void,
	noEdit?: boolean,
	white?: boolean
}) {
	const { height } = useWindowDimensions();
	const { startDate, endDate, onDatesChange } = props;

	const onClick = (focus: ReactDates.FocusedInputShape) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const bounds = e.currentTarget.getBoundingClientRect();
		const isTop = e.screenY > height / 2;
		props.setRangePickerState?.({
			isOpen: true,
			isTop,
			top: !isTop ? bounds.top + bounds.height + 20 : bounds.top - bounds.height,
			left: bounds.left - 265 - bounds.width / 2,
			dates: { startDate, endDate },
			onDatesChange,
			focus
		});
	};

	return <RangeControllerInputDiv>
		<TimeButton onClick={onClick('startDate')} noEdit={props.noEdit}>
			{startDate?.format('L') ?? '-'}
		</TimeButton>
		<img src={arrow_right_date_picker} style={{ filter: props.white ? FilterWhite : undefined }}/>
		<TimeButton onClick={onClick('endDate')} noEdit={props.noEdit}>
			{endDate?.format('L') ?? '-'}
		</TimeButton>
	</RangeControllerInputDiv>;
}

export default function genPromotionColumns(
	promotions: Promotion[],
	setRangePickerState: React.Dispatch<React.SetStateAction<RangePickerState>>,
	putPromotion: (id: number, body: PutPromotionBody) => void,
	setModalState: React.Dispatch<React.SetStateAction<ModalState<{ promotion: Promotion, mode: PopupMode }>>>,
	loadingState: LoadingStateEnum | undefined
): Array<Column<Promotion>> {
	const maxPeriodsLength = promotions.reduce((acc, p) => Math.max(acc, p.periods.length), 1);
	const cols: Array<Column<Promotion>> = [
		{
			id: 'name',
			Header: translateToString('name'),
			accessor: row => <TableRow
				fontWeight="500"
				color={DarkGreySidely}
				cursor="pointer"
				onClick={() => setModalState({ isOpen: true, data: { promotion: row, mode: PopupMode.Default } })}
			>
				{row.name}
			</TableRow>,
			hoverImage: row => <PartialOpen src={eye} onClick={() => setModalState({ isOpen: true, data: { promotion: row, mode: PopupMode.Details } })} />,
			width: undefined,
			minWidth: 150,
			maxWidth: undefined,
			type: 'string',
			freeze: 'left',
			toolTip: row => row.name
		},
		{
			id: 'created_at',
			Header: translateToString('created_at'),
			accessor: row => <DateAccessor date={row.created_at}/>,
			width: undefined,
			minWidth: 150,
			maxWidth: undefined
		},
	];
	for (let index = 0; index < maxPeriodsLength; index++) {
		cols.push({
			id: `name_${index}`,
			Header: translateToString('name_period') + ' ' + (index + 1),
			accessor: row => {
				if (!row.periods[index]) return <TableRow />;
				return <EditableContent<DataType<Promotion>>
					innerId={`${row.id}-${loadingState}`}
					row={row}
					defaultValue={row.periods[index]?.name}
					valueKey="period_name"
					onValueUpdate={value => putPromotion(row.id, {
						periods: row.periods.map((p, i) => {
							if (i !== index)
								return p;
							return {
								...p,
								name: value ?? p.name
							};
						})
					})} />;
			},
			width: undefined,
			minWidth: 100,
			editable: true,
			disableSortBy: true,
		});
		cols.push({
			id: `period_${index}`,
			Header: translateToString('period') + ' ' + (index + 1),
			accessor: row => {
				if (!row.periods[index]) return <TableRow />;
				const startDate = row.periods[index]?.start ? moment(row.periods[index]?.start) : null;
				const endDate = row.periods[index]?.end ? moment(row.periods[index]?.end) : null;
				return <RangeControllerInput startDate={startDate} endDate={endDate} setRangePickerState={setRangePickerState} onDatesChange={dates => {
					putPromotion(row.id, { periods: row.periods.map((p, i) => {
						if (i !== index) return p;
						return {
							...p,
							start: dates.startDate?.utc().toISOString() ?? p.start,
							end: dates.endDate?.utc().toISOString() ?? p.end,
						};
					}) });
				}}/>;
			},
			width: undefined,
			minWidth: 242,
			disableSortBy: true,
		});
	}
	cols.push({
		id: 'companies',
		Header: translateToString('companies'),
		accessor: row => <TableRow>{row.companies.length}</TableRow>,
		width: undefined,
		minWidth: 150,
		maxWidth: undefined,
		type: 'numeric',
		disableSortBy: true,
	});
	cols.push({
		id: 'is_active',
		Header: translateToString('visible'),
		accessor: row => <Switch value={row.is_active} onChange={is_active => putPromotion(row.id, { is_active })} size='18px'/>,
		width: 75,
		centerRow: true,
		freeze: 'right',
		disableSortBy: true
	});
	return cols;
}
