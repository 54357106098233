/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component} from 'react';
import {FieldArray} from 'redux-form';

class DynamicTable extends Component {
  state = {};

  renderComponent = ({ fields, meta: { error, submitFailed } }) => {
    const { type } = this.props;
    return (
      <div className="dynamic-field-formbuilder mt-3">
        {fields.map((dynamicField, index) => {
          const childrenWithProps = React.Children.map(
            this.props.children,
            child => {
              // checking isValidElement is the safe way and avoids a typescript error too
              const props = { dynamicField };
              if (React.isValidElement(child)) {
                return React.cloneElement(child, props);
              }
              return child;
            }
          );

          return (
            <div className="" key={index}>
              <i
                className={`fas fa-minus-circle d-flex justify-content-end form-group ${type ===
                  'edit' && 'pointer'}`}
                onClick={() => type !== 'detail' && fields.remove(index)}
              />
              {childrenWithProps}
            </div>
          );
        })}
        <i
          className={`fas fa-plus-circle d-flex justify-content-end form-group ${type ===
            'edit' && 'pointer'}`}
          onClick={() => type !== 'detail' && fields.push({})}
        />
      </div>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <div className="dynamic-field-formbuilder mt-3">
        <FieldArray name={data.id} component={this.renderComponent} />
      </div>
    );
  }
}

export default DynamicTable;
