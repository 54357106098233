import styled from 'styled-components';
import { Checkbox } from '../../../components_v2/filterList/style/Style';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BorderColor, SidelyBlack } from '../../../styles/global/css/Utils';

export const ModalNoClose = styled.div`
.close {
    display: none;
}
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
`;

export const TopContainer = styled.div<{ width: number }>`
    display: flex;
    justify-content: space-between;
    width: ${p => p.width}px;
    align-items: center;
`;

export const OptimizePopupBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height fit-content;
    padding: 20px 0;
    gap: 20px;
`;

export const OptimizePopupFooter = styled.div`
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid ${BorderColor};
    padding: 20px 0;
`;

export const Hided = styled.div`
    transform: translate(-18px, -36px);
    cursor: default;

    .show-on-hover {
        display: none;
    }

    &:hover {
        .show-on-hover {
            display: block;
        }
    }
`;

export const TimeMapSelectorContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const TimeCarouselContainer = styled.div`
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: fit-content;
`;

export const TimeMapSwitcher = styled.img<{ right?: boolean }>`
  height: 14px;
  ${p => p.right ? 'rotate: 180deg;' : ''};
  cursor: pointer;
`;

export const TimeSwitcherContainer = styled.div`
  padding: 7px;
  cursor: pointer;
`;

export const TimeDisplayer = styled.div`
  min-width: 175px;
  text-align: center;
  font-size: 14px;
`;

export const MapCheckbox = styled(Checkbox)`
    flex-shrink: 0;
    margin: 0 15px;
`;
export const MapTextFilters = styled.div`
    ${DefaultText}
    font-size: 11px;
`;

export const MapTextFilters2 = styled.div`
    ${DefaultText}
    color: ${SidelyBlack};
`;
