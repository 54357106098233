import magnifying_glass from 'images/icons/magnifying_glass.svg';
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import debounce from 'lodash/debounce';
import { searchData } from '../../../store/actions/contacts.action';

class ContactsSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false,
			search: ''
		};
		this.delayedCallback = debounce(this.searchCall, 1000);
	}

	componentDidMount() {
		const paramsData = JSON.parse(localStorage.getItem('params'));
		if (paramsData.name) {
			this.setState({ search: paramsData.name });
		}
	}

	handleChange = e => {
		e.persist();
		this.delayedCallback(e);
		this.setState({ search: e.target.value });
	};

	searchCall = e => {
		const { searchData } = this.props;
		searchData(e.target.value);
	};

	render() {
		let input;
		const { focus } = this.state;

		return (
			(<div className="m-0">
				<div
					className={`input-group search-v1 ${focus ? 'focused' : ''} `}
					style={{ paddingLeft: '13px' }}
				>
					<div
						className="input-group-prepend"
						onKeyDown={this.handleKeyDown}
						role="button"
						tabIndex="0"
					>
						<span className="input-group-text">
							<img alt="ProfileImage" src={magnifying_glass} height="20" />
						</span>
					</div>
					<Translate>
						{({ translate }) => (
							<input
								type="text"
								className="form-control"
								placeholder={translate('search_contacts')}
								value={this.state.search}
								onFocus={() => {
									this.setState({
										focus: true
									});
								}}
								onBlur={() => {
									this.setState({
										focus: false
									});
								}}
								onChange={this.handleChange}
							/>
						)}
					</Translate>
				</div>
			</div>)
		);
	}
}

const mapStateToProps = state => ({
	data: state.contacts
});

export default connect(mapStateToProps, {
	searchData
})(ContactsSearch);
