import { URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { FilterQueryResult, FilterTree } from '../../../components_v2/filter/model/Model';
import { decodeReport, Report } from '../interpretor/bareReportingDecoder';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function getPresenceEvolution(body: {
	granularity: 'day' | 'week' | 'month' | 'quarter' | 'year';
	end: string;
	begin: string;
	users: number[]
}): Promise<Report> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.post<ArrayBufferLike>(`${PATH}/v2/reports/merchandising-evolution-per-product`, body, { responseType: 'arraybuffer' })
		.then(res => {
			const u8Array = new Uint8Array(res.data);
			return decodeReport(u8Array);
		});
}