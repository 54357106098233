import { FlexDiv } from '../../products/style';
import * as React from 'react';
import addImg from 'images/icon/add.png';
import { Image } from '../../../components_v2/avatar/style/Style';
import { createTab, deleteTab, duplicateTab, fetchTabList, updateTab } from './actions';
import useAlert from '../../alert/UseAlert';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { AlertRes } from '../../alert/AlertProvider';
import Tab from './Tab';


export interface DashboardScreen {
	id: number,
	name: string,
	company_id?: number,
	created_by?: number,
	created_at?: string,
	updated_at?: string,
	deleted_at?: string
}

interface TabListProps {
	activeTab?: number
	switchTab: (tabId: number) => void
}
export default function TabList(props: TabListProps) {
	const [tabList, setTabList] = React.useState<DashboardScreen[]>([]);
	const alert = useAlert();

	React.useEffect(() => {
		fetchAndSwitchTab();
	}, []);

	function fetchAndSwitchTab(id?: number) {
		fetchTabList().then(tabs => {
			setTabList(tabs);
			// put first tab as active by default or the one passed as param
			const activeTabId = id ?? tabs[0].id;
			props.switchTab(activeTabId);
		});
	}
	function handleDuplicateTab(id: number, name: string) {
		alert({
			title: translateToString('do_you_want_to_duplicate_target', [['target', name]]),
			content: <div>
				<div>{translateToString('attention_duplicate_target',[['target', name]])}</div>
				<b>{name} - copy</b>
			</div>,
		}).then(res => {
			if (res === AlertRes.Ok) {
				duplicateTab(id, { name }).then(id => fetchAndSwitchTab(id));
			}
		});
	}

	function handleCreateTab() {
		createTab().then(id => fetchAndSwitchTab(id));
	}
	function handleDeleteTab(id: number, name: string) {
		alert({
			title: translateToString('do_you_want_to_delete_the_tab'),
			content: <div>{translateToNode('attention_delete_tab', { variables: [['target', name]] })}</div>,
			mode:'delete'
		}).then(res => {
			if (res === AlertRes.Ok) {
				const activeTabIndex = tabList.findIndex(tab => tab.id === id);
				// if the tab to delete is the last one, switch to the previous one, else switch to the next one
				const nextActiveTabId = tabList[activeTabIndex + 1]?.id ?? tabList[activeTabIndex - 1]?.id;
				deleteTab(id).then(_ => fetchAndSwitchTab(nextActiveTabId));
			}
		});
	}
	function handleUpdateTab(id: number, name: string) {
		updateTab(id, { name:name }).then(_ => fetchAndSwitchTab(id));
	}

	return <>
		<FlexDiv style={{ transform:'translateY(15px)' }} margin="0px 15px" >
			<FlexDiv overflow="auto" >
				{tabList.map((tab, index) => <Tab
					id={tab.id}
					key={`tab[${index}]`}
					name={tab.name}
					active={props.activeTab === tab.id}
					onSwitchTab={() => props.switchTab(tab.id)}
					onDeleteTab={() => handleDeleteTab(tab.id, tab.name)}
					onUpdateTab={handleUpdateTab}
					onDuplicateTab={() => handleDuplicateTab(tab.id, tab.name)}
				/>)}
			</FlexDiv>
			<FlexDiv justify="center" width={'40px'}>
				<Image url={addImg} width="15px" hasFilter onClick={handleCreateTab}/>
			</FlexDiv>
		</FlexDiv>
	</>;
}
