import * as React from 'react';
import styled from 'styled-components';
import { DefaultImage } from '../../styles/global/css/GlobalImage';
import { LightBlueSidely } from '../../styles/global/css/Utils';
import add from 'images/add_blue.png';

const AddStyle = styled(DefaultImage)<{ size?: string}>`
    background-color: ${LightBlueSidely};
    cursor: pointer;
    height: ${p => p.size ?? ''};
    width: ${p => p.size ?? ''};
    border-radius: 50%;
	flex-shrink: 0;
`;

interface AddProps {
  size?: number | string
}

export default function Add({ ...rootDOMAttributes }, props: AddProps) {
	let inner_size: string | undefined;

	if (typeof props.size === 'number') {
		inner_size = `${props.size} + px`;
	} else {
		inner_size = props.size;
	}

	return <AddStyle size={inner_size} {...rootDOMAttributes} src={add} />;
}
