/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {Translate} from 'react-localize-redux';
import RenderSelectInput from './RenderSelectInputModal';
import {selectOpportunityStatus} from '../../store/actions/select.action';

class SelectStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectOpportunityStatus } = this.props;
    selectOpportunityStatus(true);
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (data !== nextProps.data) {
      const opt = [];
      opt.push({
        value: '',
        label: <Translate id="all" />
      });

      nextProps.data.map(data =>
        opt.push({
          value: data.id,
          label: data.name
        })
      );
      this.setState({ opt });
    }
  }

  render() {
    const { opt } = this.state;
    const { validate, label, requiredStar, name, placeholder } = this.props;
    // console.log('incharge', data);
    // console.log('id', id);
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="inputOwner"
        placeholder={placeholder}
        validate={validate}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.opportunityStatus
});

export default connect(
  mapStateToProps,
  {
    selectOpportunityStatus
  }
)(SelectStatus);
