import * as React from 'react';
import { useRecoilState } from 'recoil';
import Dropdown from '../../dropdown/Dropdown';
import { DropdownData } from '../../dropdown/model/Model';
import { AShelfAuditTemplatePicker, ShelfAuditTemplate, getShelfAuditTemplates } from '../../../atoms/filter/shelfAuditFilterAtom';
import boxImage from 'images/menu_icon/fieldReport_black.svg';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { translateToString } from '../../../styles/global/translate';

export default function ShelfAuditTemplatePicker(props: GenericToolbarFilterProps): JSX.Element {
	const [atomTemplates, setAtomTemplates] = useRecoilState(AShelfAuditTemplatePicker);
	const [templates, setTemplates] = React.useState<DropdownData<ShelfAuditTemplate>[]>([]);

	React.useEffect(() => {
		getShelfAuditTemplates().then(res => {
			let base;
			if (!atomTemplates) {
				base = !res ? undefined : res;
				setAtomTemplates(!res ? undefined : res);
			} else {
				base = atomTemplates;
			}
			setTemplates(res.map(value => ({ value, label: value.name, checked: base?.some(at => at.id === value.id) ?? false })));
		});
	}, []);
	if (props.hidden) return <></>;

	if (!templates) return <></>;

	function getText(): string {
		const amount = templates.filter(l => l.checked).length;		return amount > 0 ? (amount === templates.length ? translateToString('all') : `${amount} ${translateToString('selected_template')}`) : translateToString('select_template');

	}

	return (
		<Dropdown
			dropdownStyle={DROPDOWN_FILTER_STYLE}
			datalist={templates}
			readOnly
			checkbox
			checkboxAll
			defaultShowLabel={getText()}
			freezeShowLabel
			name='dropdown_scope_filter'
			onChange={values => {
				setAtomTemplates(values.map(({ value }) => value));
				setTemplates(templates => templates.map(t => ({ ...t, checked: values.some(v => v.value.id === t.value.id) })));
			}}
			image={boxImage}
		/>
	);
}