import bar from 'images/formbuilder/bar.svg';
import barGray from 'images/formbuilder/bar-gray.svg';
import trash from 'images/formbuilder/trash.svg';
import trashGray from 'images/formbuilder/trash-gray.svg';
import pencil from 'images/formbuilder/pencil.svg';
import copy from 'images/formbuilder/copy.svg';
import copyGray from 'images/formbuilder/copy-gray.svg';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * <HeaderBar />
 */

import React from 'react';
import { getFormBuilderImageUrl } from '../../../enrichedForm/components/fieldComponents/builderLib/header-bar';

export default class HeaderBar extends React.Component {
	state = {};

	render() {
		const { data, match } = this.props;
		const noDuplicateElements = [
			'StartSection',
			'EndSection',
			'StartTable',
			'EndTable'
		];

		// console.log('match', match);

		return (
			(<div className="toolbar-header d-flex justify-content-between">
				<div className="d-flex align-items-center">
					<div className={`${data.img ? 'mr-2' : 'mr-3'}`}>
						{data.img ? (
							<img
								src={getFormBuilderImageUrl(data.img)}
								alt=""
								height="24px"
								width="24px"
							/>
						) : (
							<i className={data.icon} />
						)}
					</div>
					<div>
						<p className="text-14 m-0">{this.props.data.text}</p>
						<small className="el-subtitle">{this.props.data.field_name}</small>
					</div>
				</div>
				<div className="toolbar-header-buttons">
					{!noDuplicateElements.includes(this.props.data.element) && (
						<div
							className={`btn is-isolated btn-school px-2 ${
								data.disabled ? 'pointer-drop text-muted' : 'pointer'
							}`}
							onClick={
								!data.disabled &&
                this.props.onDuplicate.bind(this, this.props.data)
							}
						>
							{data.disabled ? (
								<img
									className="is-isolated"
									src={copyGray}
									alt=""
								/>
							) : (
								<img
									className="is-isolated"
									src={copy}
									alt=""
								/>
							)}
						</div>
					)}

					{this.props.data.element !== 'LineBreak' && (
						<div
							className="btn is-isolated btn-school px-2 pointer "
							onClick={this.props.editModeOn.bind(
								this.props.parent,
								this.props.data
							)}
						>
							<img
								className="is-isolated pointer"
								src={pencil}
								alt=""
							/>
						</div>
					)}

					<div
						className={`btn is-isolated btn-school px-2  ${
							data.disabled ? 'pointer-drop text-muted' : 'pointer'
						}`}
						onClick={
							!data.disabled && this.props.onDestroy.bind(this, this.props.data)
						}
					>
						{data.disabled ? (
							<img
								className="is-isolated"
								src={trashGray}
								alt=""
							/>
						) : (
							<img
								className="is-isolated"
								src={trash}
								alt=""
							/>
						)}
					</div>
					<div className="btn is-isolated pl-2 pr-0">
						{data.disabled ? (
							<img
								className="is-isolated"
								src={barGray}
								alt=""
							/>
						) : (
							<img
								className="is-isolated"
								src={bar}
								alt=""
							/>
						)}
					</div>
				</div>
			</div>)
		);
	}
}
