import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { FlexDiv } from '../../containers_v2/products/style';
import storeLang from '../../helpers/storeLang';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, RedSidely, SidelyBlack } from '../../styles/global/css/Utils';
import Input from '../input/Input';
import { PopupMode } from './model/Model';
import Popup from './Popup';

export const DeletionHeader = styled.div`
    ${DefaultText}
    width: 90%;
    height: 70px;
    margin: 0 5%;
    padding: 0 4%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${BorderColor};
    font-size: 18px;
    font-weight: 700;
    color: ${SidelyBlack};
`;

export const DeletionContainer = styled.div<{ gap?: string, maxHeight?: string }>`
    width: 90%;
    margin: 30px 5%;
    padding: 0 4%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: calc(85% - 70px);
	gap: ${p => p.gap ?? '30px'};
	${({ maxHeight }) => maxHeight ? `max-height: ${maxHeight};` : ''}
	overflow: auto;
`;

const DeletionText = styled.p`
    ${DefaultText}
    font-size: 14px;
    color: ${SidelyBlack};
`;

const Close = styled.img`
    cursor: pointer;
`;

const DeleteButton = styled(DefaultButton)`
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    background-color: ${RedSidely}
`;

const BackButton = styled(DeleteButton)`
    background-color: white;
    border: 1px solid ${BlueSidely};
    color: ${BlueSidely};
`;

export function PopupDeletionContent(props: {
  mode: string
  records: number
  onValidation?: () => void
  onClickOut?: () => void
  translationKey: string
  customTitle?: string
  customMessage?: React.ReactNode
  noVerification?: boolean
}): JSX.Element {
	const { mode, records, onValidation, onClickOut, translationKey, noVerification, customMessage, customTitle } = props;
	const [userInput, setUserInput] = React.useState<string>();
	const translate = getTranslate(storeLang.getState().localize);

	const translatedKey = translate(translationKey).toString().toLocaleLowerCase();

	return <>
		<DeletionHeader>
			{
				customTitle !== undefined ?
					<div>{customTitle}</div>
					:
					<div>{translate(`global.fire.bulk_${mode}_key_nb`).toString().replace('{{NUMBER}}', records.toString()).replace('{{KEY}}', translatedKey)}</div>
			}
			<Close src={close} onClick={() => onClickOut?.()} width={30} />
		</DeletionHeader>
		<DeletionContainer>
			<FlexDiv flow="column" gap='15px' align='baseline'>
				{
					customMessage ?
						<DeletionText>{customMessage}</DeletionText>
						:
						<DeletionText>{translate(`global.fire.bulk_${mode}_text_warning_1`).toString().replace('{{KEY}}', translatedKey)} <b>{translate(`global.fire.bulk_${mode}_key_text_warning_2`).toString().replace('{{NUMBER}}', records.toString()).replace('{{KEY}}', translatedKey)}</b></DeletionText>
				}
				{!noVerification && <DeletionText>{translate(`global.fire.bulk_${mode}_key_text_confirmation`).toString().replace('{{KEY}}', translatedKey)}</DeletionText>}
			</FlexDiv>
			{!noVerification && 
			<Input
				name="input_deletion"
				type="text"
				placeholder={records.toString()}
				onChange={setUserInput}
				inputStyle={{
					height: '50px',
					width: '100%',
					containerWidth: '100%',
					backgroundColor: '#F9F9F9',
					borderRadius: '5px',
					border: '1px solid #D9D9D9',
					marginLeft: '0',
					marginRight: '0',
					fontSize: '16px'
				}}
			/>}
			<FlexDiv gap='20px'>
				<DeleteButton disabled={!noVerification && userInput !== records.toString()} onClick={() => {
					onValidation?.();
					onClickOut?.();
				}}>
					<Translate id={`global.${mode}`} />
				</DeleteButton>
				<BackButton onClick={() => onClickOut?.()}>
					<Translate id='global.back' />
				</BackButton>
			</FlexDiv>
		</DeletionContainer>
	</>;
}

export default function PopupDeletion(props: {
  isOpen: boolean
  isEditPopup?: boolean
  onClickOut?: () => void
  onValidation?: () => void
  records: number
  translationKey: string,
  customTitle?: string
  customMessage?: React.ReactNode,
  noVerification?: boolean
}): JSX.Element {
	return <Popup
		popupStyle={{ width: '600px', borderRadius: '4px', fitContent: true }}
		isOpen={props.isOpen}
		hasBackground
		popupMode={PopupMode.Centered}
		onClickOut={props.onClickOut}
	>
		<PopupDeletionContent
			mode={props.isEditPopup ? 'modify' : 'delete'}
			records={props.records}
			onValidation={props.onValidation}
			onClickOut={props.onClickOut}
			translationKey={props.translationKey}
			noVerification={props.noVerification}
			customTitle={props.customTitle}
			customMessage={props.customMessage}
		/>
	</Popup>;
}
