/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
// eslint-disable-next-line max-classes-per-file
import StartSection from './previews/StartSection';
import FreeText from './previews/FreeText';
import Numerical from './previews/Numerical';
import Header from './previews/Header';
import EndSection from './previews/EndSection';
import Image from './previews/Image';
import Website from './previews/Website';
import DatePicker from './previews/Date';
import DropdownList from './previews/DropdownList';
import Checkbox from './previews/Checkbox';
import Email from './previews/Email';
import AddressField from './previews/AddressField';
import Document from './previews/Document';
import UserField from './previews/UserField';
import Hyperlink from './previews/Hyperlink';
import Calculation from './previews/Calculation';
import StartTable from './previews/StartTable';
import EndTable from './previews/EndTable';
import Camera from './previews/Camera';

// const Header = <div>Ini Header</div>;

const FormPreviews = {};

FormPreviews.StartSection = StartSection;
FormPreviews.EndSection = EndSection;
FormPreviews.StartTable = StartTable;
FormPreviews.EndTable = EndTable;
FormPreviews.Header = Header;
FormPreviews.FreeText = FreeText;
FormPreviews.Numerical = Numerical;
FormPreviews.Website = Website;
FormPreviews.DropdownList = DropdownList;
FormPreviews.Checkbox = Checkbox;
FormPreviews.Email = Email;
FormPreviews.AddressField = AddressField;
FormPreviews.DatePicker = DatePicker;
FormPreviews.Image = Image;
FormPreviews.Hyperlink = Hyperlink;
FormPreviews.Document = Document;
FormPreviews.UserField = UserField;
FormPreviews.Calculation = Calculation;
FormPreviews.Camera = Camera;

export default FormPreviews;
