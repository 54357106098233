import styled from 'styled-components';
import * as React from 'react';
import { BlueSidely, RedSidely, SidelyBlack } from '../../styles/global/css/Utils';

export enum PageLoaderColors {
  White, // = "#ffffff"
  Dark, // = SidelyBlack,
  Blue, // = BlueSidely,
  Red, // = RedSidely,
  Sidely, // = BlueSidely
}

interface PageLoaderProps {
  color1?: string | PageLoaderColors
  color2?: string | PageLoaderColors
  color?: string | PageLoaderColors
  size?: number
  inline?: boolean
  weight?: number
  className?: string
}

const Circle = styled.div<{ color1: string, color2: string, size?: number, inline?: boolean, weight?: number }>`
    content: ' ';
    display: block;
    width: ${p => p.size ?? 30}px;
    height: ${p => p.size ?? 30}px;
    margin: 1px;
    border-radius: 50%;
    border: ${p => p.weight ?? 2}px solid #fff;
    border-color: ${p => p.color1} transparent ${p => p.color2} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    display: ${p => p.inline ? 'inline-block' : ''};

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
	flex-shrink: 0;
`;

const pageLoaderColorToHexa = (color: PageLoaderColors | string): string[] => {
	if (typeof color === 'string') { return [color, color]; }
	switch (color) {
		case PageLoaderColors.White:
			return ['#ffffff', '#ffffff'];
		case PageLoaderColors.Dark:
			return [SidelyBlack, SidelyBlack];
		case PageLoaderColors.Red:
			return [RedSidely, RedSidely];
		case PageLoaderColors.Sidely:
			return [BlueSidely, '#264354'];
		default:
			return [BlueSidely, BlueSidely];
	}
};

export default function PageLoader(props: PageLoaderProps) {
	const { color, color1, color2, size, inline, weight } = props;

	let local_color_1: string;
	let local_color_2: string;

	if (color !== undefined) {
		const colors = pageLoaderColorToHexa(color);
		local_color_1 = colors[0];
		local_color_2 = colors[1];
	} else {
		local_color_1 = pageLoaderColorToHexa(color1 ?? PageLoaderColors.Blue)[0];
		local_color_2 = pageLoaderColorToHexa(color2 ?? PageLoaderColors.Blue)[1];
	}

	return <Circle
		className={props.className}
		color1={local_color_1}
		color2={local_color_2}
		size={size}
		inline={inline}
		weight={weight}
	/>;
}
