import { DashboardItem } from '../Dashboard';
import * as React from 'react';
import { EventStatus } from '../../calendar/model/Model';
import { useRecoilValue } from 'recoil';
import { AEventTypes } from '../../../atoms/global/events';
import { AUsers } from '../../../atoms/global/users';
import { getEventStatuses } from '../../calendar/EventsApi';
import { FlexDiv } from '../../products/style';
import { Translate } from '../../../styles/global/translate';
import {
	CreateButton,
	DeleteButton,
	EditButton,
	ScrollDiv,
	WidgetBody,
	WidgetTitle,
	WidgetTitleWrapper
} from '../Widget';

export default function EventCountWidget(props: {
	value: { count: number },
	item: DashboardItem,
	setItem: (item: DashboardItem) => void,
	onCreationClicked?: () => void,
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
	isHover?: boolean
}) {
	const { value, item, onCreationClicked,onClickDelete, isHover, onClickEdit , setItem } = props;
	const [eventStatuses, setEventStatuses] = React.useState<EventStatus[]>();
	const eventTypes = useRecoilValue(AEventTypes);

	const users = useRecoilValue(AUsers);
	const currentUser = users.find(user => user.id === item.additional_values?.['user']);
	const currentEventStatus = eventStatuses?.find(eventStatus => eventStatus.id === item.additional_values?.['event_status']);
	const currentEventType = eventTypes.find(eventType => eventType.id === item.additional_values?.['event_type']);
	const currentGranularity = item.additional_values?.['granularity'];


	React.useEffect(() => {
		if (eventStatuses === undefined) getEventStatuses().then(res => setEventStatuses(res.data.data));
	}, []);


	return <>
		<WidgetTitleWrapper>
			<FlexDiv justify="space-between" align='center' width='100%'>
				<WidgetTitle>
					{item.title ?? <Translate id='dashboard.widget.events_count'/>}
				</WidgetTitle>
				<FlexDiv justify="flex-end" gap="2px">
					{onCreationClicked && <CreateButton onClickCreate={onCreationClicked} />}
					{ isHover && onClickEdit && <EditButton onClickEdit={() => onClickEdit(item)}/>}
					{ isHover && onClickDelete && <DeleteButton onClickDelete={() => onClickDelete(item.id as number)} />}
				</FlexDiv>
			</FlexDiv>
		</WidgetTitleWrapper>
		<ScrollDiv centered>
			<WidgetBody> {value.count} </WidgetBody>
			{/*{currentUser &&*/}
			{/*	<FlexDiv width='100%'>*/}
			{/*		<UserBlock user={currentUser} />*/}
			{/*	</FlexDiv>}*/}
			{/*{currentEventType && <FlexDiv width='100%' gap='5px'>*/}
			{/*	<Ellipsis>{translateToString('type')}:</Ellipsis>*/}
			{/*	<Ellipsis fontWeight='600'>{translateToString(`event.${currentEventType.name}`)}</Ellipsis>*/}
			{/*</FlexDiv>}*/}
			{/*{currentEventStatus && <FlexDiv width='100%' gap='5px'>*/}
			{/*	<Ellipsis>{translateToString('status')}:</Ellipsis>*/}
			{/*	<Ellipsis fontWeight='600'>{translateToString(`event.${currentEventStatus.name}`)}</Ellipsis>*/}
			{/*</FlexDiv>}*/}
			{/*{currentGranularity && <FlexDiv width="100%" gap='5px'>*/}
			{/*	<Ellipsis>{translateToString('granularity')}:</Ellipsis>*/}
			{/*	<Ellipsis fontWeight='600'>{translateToString(currentGranularity)}</Ellipsis>*/}
			{/*</FlexDiv>}*/}
		</ScrollDiv>
	</>;
}

