import {
	CREATE_BRAND_ERROR,
	CREATE_BRAND_REQUEST,
	CREATE_BRAND_SUCCESS,
	DETAIL_BRAND_ERROR,
	DETAIL_BRAND_REQUEST,
	DETAIL_BRAND_SUCCESS,
	FETCH_BRAND_ERROR,
	FETCH_BRAND_REQUEST,
	FETCH_BRAND_SUCCESS,
	SEARCH_BRAND,
	SORT_BRAND,
	UPDATE_BRAND_ERROR,
	UPDATE_BRAND_REQUEST,
	UPDATE_BRAND_SUCCESS
} from '../actions/brand.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	current: null,
	data: [],
	dataDetail: [],
	detail: [],
	search: null,
	sortField: null,
	sort: 'asc',

	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	}
};

export default function BrandReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_BRAND_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_BRAND_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_BRAND_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DETAIL_BRAND_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_BRAND_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_BRAND_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case CREATE_BRAND_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_BRAND_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};
		case CREATE_BRAND_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_BRAND_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_BRAND_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_BRAND_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SEARCH_BRAND:
			return {
				...state,
				search: action.payload
			};

		case SORT_BRAND:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};

		default:
			return state;
	}
}
