import * as moment from 'moment';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { normalizeIntAbove0 } from '../../../containers_v2/globals/FieldFunction';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { BlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { Radio } from '../../filterList/style/Style';
import Input from '../../input/Input';
import { FilterAction, FilterAdditionalValue, FilterId, FilterQueryResult, FilterTree, FilterValueOrValues, FilterValueResult } from '../model/Model';
import { Container, FilterText, InputContainer, RadioContainer } from '../style/AdvancedFilterStyle';
import { filterIdFormater } from './FilterSelection';

function parseDateOrUndefined(s: string | undefined, index: number | undefined): string | undefined {
	if (!s) return undefined;
	const filter = indexToFilter(index);
	if (!filter) return undefined;
	const date = moment.utc(s).local();
	switch (filter) {
		case FilterAction.MORE_THAN_NEXT:
		case FilterAction.LESS_THAN_NEXT:
			return (date.diff(moment(), 'days') + 1).toString();
		case FilterAction.MORE_THAN_LAST:
		case FilterAction.LESS_THAN_LAST:
			return moment().diff(date, 'days').toString();
	}
	return date.format('YYYY-MM-DD');
}

function indexToFilter(filter: number | undefined): FilterAction | undefined {
	switch (filter) {
		case 0:
			return FilterAction.BETWEEN;
		case 1:
			return FilterAction.NOT_BETWEEN;
		case 2:
			return FilterAction.MORE_THAN;
		case 3:
			return FilterAction.EQUAL_OR_MORE_THAN;
		case 4:
			return FilterAction.LESS_THAN;
		case 5:
			return FilterAction.EQUAL_OR_LESS_THAN;
		case 6:
			return FilterAction.BETWEEN;
		case 7:
			return FilterAction.IS_EMPTY;
		case 8:
			return FilterAction.IS_NOT_EMPTY;
		case 9:
			return FilterAction.MORE_THAN_NEXT;
		case 10:
			return FilterAction.LESS_THAN_NEXT;
		case 11:
			return FilterAction.MORE_THAN_LAST;
		case 12:
			return FilterAction.LESS_THAN_LAST;
		default:
			return;
	}
}

function switchFilterToIndex(filter: FilterAction | undefined): number | undefined {
	switch (filter) {
		case FilterAction.BETWEEN:
			return 0;
		case FilterAction.NOT_BETWEEN:
			return 1;
		case FilterAction.MORE_THAN:
			return 2;
		case FilterAction.EQUAL_OR_MORE_THAN:
			return 3;
		case FilterAction.LESS_THAN:
			return 4;
		case FilterAction.EQUAL_OR_LESS_THAN:
			return 5;
		// case FilterAction.BETWEEN:
		// 	return 6;
		case FilterAction.IS_EMPTY:
			return 7;
		case FilterAction.IS_NOT_EMPTY:
			return 8;
		case FilterAction.MORE_THAN_NEXT:
			return 9;
		case FilterAction.LESS_THAN_NEXT:
			return 10;
		case FilterAction.MORE_THAN_LAST:
			return 11;
		case FilterAction.LESS_THAN_LAST:
			return 12;
		default:
			return;
	}
}

function DateFilter(props: {
	id: FilterId
	title: string
	onReturn: () => void
	onSubmit: (value: FilterValueResult) => void
	additionalValue?: FilterAdditionalValue
	selectedValue: { filter: FilterValueResult, tree: FilterTree } | undefined
}): JSX.Element {
	let selectedValue: FilterQueryResult | undefined;
	if (props.selectedValue && 'val' in props.selectedValue.tree) {
		selectedValue = props.selectedValue.tree.val;
	}
	const [index, setindex] = React.useState<number | undefined>(switchFilterToIndex(props.selectedValue?.filter?.action));
	const [value, setValue] = React.useState<string | undefined>(parseDateOrUndefined(selectedValue?.value?.split(',')[0], index));
	const [value2, setValue2] = React.useState<string | undefined>(parseDateOrUndefined(selectedValue?.value?.split(',')[1], index));

	const id = filterIdFormater(props.id, props.additionalValue);

	React.useEffect(() => {
		const splitted = selectedValue?.value?.split(',');
		setValue(parseDateOrUndefined(splitted?.[0], index));
		setValue2(parseDateOrUndefined(splitted?.[1], index));
	}, [index]);

	React.useEffect(() => {
		if (value !== undefined && value.trim().length <= 0) {
			setValue(undefined);
		}
		if (value2 !== undefined && value2.trim().length <= 0) {
			setValue2(undefined);
		}
	}, [value, value2]);

	function submit(): void {
		if (!((value !== undefined && index !== 6) || index === 7 || index === 8 || (index === 6 && value !== undefined && value2 !== undefined))) {
			return;
		}
		const action = indexToFilter(index);
		if (!action) return;
		let b: FilterValueOrValues<string> = {
			value: value ? moment(value).toISOString() : undefined,
			displayedValue: moment(value).format('L'),
		};

		switch (index) {
			case 0: {
				const endOfDate = moment(value).endOf('day').toISOString();
				value && (b = {
					values: [moment(value).toISOString(), endOfDate],
					displayedValues: [moment(value).format('L'), moment(endOfDate).format('L')]
				});
			}
				break;
			case 1: {
				const endOfDate = moment(value).endOf('day').toISOString();
				value && (b = {
					values: [moment(value).toISOString(), endOfDate],
					displayedValues: [moment(value).format('L'), moment(endOfDate).format('L')]
				});

			}
				break;
			case 6:
				value && value2 && (b = {
					values: [moment(value).toISOString(), moment(value2).toISOString()],
					displayedValues: [moment(value).format('L'), moment(value2).format('L')]
				});
				break;
			case 9:
			case 10:
			case 11:
			case 12:
				b = { value };
				break;
		}
		props.onSubmit({
			id,
			title: props.title,
			// @ts-expect-error TOFIX TODO this is legacy please change this
			type: 'date',
			action,
			titleComment: props.additionalValue?.titleComment,
			...b
		});
	}

	const setValueFromInt = (value: string) => {
		const n = parseInt(value);
		if (isNaN(n)) return;
		setValue(n.toString());
	};

	const list = [
		{ label: <Translate id='is_equal_to' />, children: <Input name="filter_value" type="date" onChange={setValue} value={value}/> },
		{ label: <Translate id='is_not_equal_to' />, children: <Input name="filter_value" type="date" onChange={setValue} value={value}/> },
		{ label: <Translate id='is_greater_than' />, children: <Input name="filter_value" type="date" onChange={setValue} value={value}/> },
		{ label: <Translate id='is_greater_or_equal_to' />, children: <Input name="filter_value" type="date" onChange={setValue} value={value}/> },
		{ label: <Translate id='is_less_than' />, children: <Input name="filter_value" type="date" onChange={setValue} value={value}/> },
		{ label: <Translate id='is_less_or_equal_to' />, children: <Input name="filter_value" type="date" onChange={setValue} value={value}/> },
		{ label: <Translate id='between' />, children: <InputContainer>
			<Input inputStyle={{ width: '125px' }} name="filter_value" type="date" onChange={setValue} value={value}/>
			<FilterText><Translate id='and' /></FilterText>
			<Input inputStyle={{ width: '125px' }} name="filter_value" type="date" onChange={setValue2} value={value2}/>
		</InputContainer> },
		{ label: <Translate id='is_empty' /> },
		{ label: <Translate id='is_not_empty' /> },
		{ label: <Translate id='more_than_x_days_next' />, children: <Input name="filter_value" type="number" normalize={[normalizeIntAbove0]} onChange={setValueFromInt} value={value}/> },
		{ label: <Translate id='less_than_x_days_next' />, children: <Input name="filter_value" type="number" normalize={[normalizeIntAbove0]} onChange={setValueFromInt} value={value2}/> },
		{ label: <Translate id='more_than_x_days_last' />, children: <Input name="filter_value" type="number" normalize={[normalizeIntAbove0]} onChange={setValueFromInt} value={value}/> },
		{ label: <Translate id='less_than_x_days_last' />, children: <Input name="filter_value" type="number" normalize={[normalizeIntAbove0]} onChange={setValueFromInt} value={value}/> },
	];

	return (
		<Container>
			<FilterText color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.onReturn()}>&lt; <Translate id='back' /></FilterText>
			<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{props.title}</FilterText>
			{list.map(({ label, children }, i) => <>
				<RadioContainer onClick={() => setindex(i)}>
					<Radio isActive={index === i} />
					<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px">{label}</FilterText>
				</RadioContainer>
				{
					index === i && children
				}
			</>
			)}
			<DefaultButton width="125px" disabled={
				!((value !== undefined && index !== 6) || index === 7 || index === 8 || (index === 6 && value !== undefined && value2 !== undefined))
			} onClick={() => submit()}><Translate id='add_filter' /></DefaultButton>
		</Container>
	);
}

export default DateFilter;
