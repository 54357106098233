/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
// import SelectInCharge from '../../select/SelectInChargeFilterWithoutAll';
import SelectInCharge from '../../select/SelectInCharge';
import * as action from '../../../store/actions/mapOpportunities.action';
import {Translate} from 'react-localize-redux';

class ModalFilterOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onFilter, incharge, type } = this.props;

    const data = {
      incharge: incharge && incharge.value,
      groupId: null
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          style={{ borderBottom: 'none' }}
        >
          <Translate id="select_spesific_user" />
        </ModalHeader>
        <ModalBody>
          <Translate>
            {({ translate }) => (
              <div className="text-center">
                <SelectInCharge
                  name="ownerId"
                  label={<Translate id="owner" />}
                  placeholder={translate('pick_owner')}
                />
              </div>
            )}
          </Translate>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <Button
            color="primary"
            className="px-5 mb-2"
            onClick={() => {
              type ? onFilter(type, data) : onFilter(data);

              this.props.setFilterIncharge(data.incharge);
              this.props.toggle();
            }}
          >
            <Translate id="save" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const selector = formValueSelector('modalFilterOwner');

const mapStateToProps = state => ({
  incharge: selector(state, 'ownerId')
});

const mapDispatchToProps = {
  setFilterIncharge: action.setFilterMapOpportunityIncharge
};

export default reduxForm({
  form: 'modalFilterOwner'
})(connect(mapStateToProps, mapDispatchToProps)(ModalFilterOwner));
