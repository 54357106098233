/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from 'react';
import swal from 'sweetalert2';
import { getTranslate, Translate } from 'react-localize-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import storeLang from '../../../helpers/storeLang';
import Restricted from '../../../containers_v2/permissions/Restricted';

class DropdownAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle(e) {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	onDelete = (id, name) => {
		const translate = getTranslate(storeLang.getState().localize);
		const { deleteData, url, match, count } = this.props;
		const params = { limit: 10, page: 1 };
		if (url === 'shelfAuditInstance') {
			params['shelf_audit_template_id'] = match.params.id || 0;
		}
		swal
			.fire({
				title: 'Attention!',
				text: translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${name}'`),
				type: 'warning',
				confirmButtonText: translate('global.yes'),
				confirmButtonColor: '#f7685b'
			})
			.then(result => {
				if (result.value) {
					if (count === 0) {
						deleteData(id, url, params);
					}
				}
			});
	};

	render() {
		const {
			id,
			name,
			detailTemplate,
			setModal,
			match,
			history,
			permission,
			actions
		} = this.props;
		const paramsId = match.params.id !== undefined ? match.params.id : null;

		return (
			<Restricted to={actions?.map(act => ({ objectAction: act.charAt(0).toUpperCase() + act.slice(1) + permission })) ?? [
				{ objectAction: `Create${permission}` },
				{ objectAction: `Delete${permission}` },
				{ objectAction: `Update${permission}` },
				{ objectAction: `Read${permission}` }
			]}>
				<UncontrolledButtonDropdown direction="down">
					<span className="my-auto text-center p-2">Action</span>
					<DropdownToggle className="pr-2 pl-0 bg-white" caret />
					<DropdownMenu dropdownOpen={this.state.dropdownOpen}>
						{(!actions || actions.includes('update')) && <Restricted to={{ objectAction: `Update${permission}` }}>
							<DropdownItem
								onClick={() => {
									if (paramsId) {
										history.push(`/enform/instanceform/${paramsId}/edit/${id}`);
									} else {
										setModal({ status: 'edit', isOpen: true });
										detailTemplate(id);
									}
								}}
							>
								<Translate id="edit" />
							</DropdownItem>
						</Restricted>}
						{(!actions || actions.includes('create')) && <Restricted to={{ objectAction: `Create${permission}` }}>
							<DropdownItem
								onClick={() => {
									if (paramsId) {
										history.push(
											`/enform/instanceform/${paramsId}/duplicate/${id}`
										);
									} else {
										detailTemplate(id);
										setModal({ status: 'duplicate', isOpen: true });
									}
								}}
							>
								<Translate id="duplicate" />
							</DropdownItem>
						</Restricted>}
						{(!actions || actions.includes('delete')) && <Restricted to={{ objectAction: `Delete${permission}` }}>
							<DropdownItem
								onClick={() => {
									this.onDelete(id, name);
									this.toggle();
								}}
							>
								<Translate id="delete" />
							</DropdownItem>
						</Restricted>}
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</Restricted>
		);
	}
}

export default DropdownAction;
