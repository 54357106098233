import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';


// -----------------------[ ATOM ]----------------------- //
export const ACampaigns = selector<Record<number, Campaign>>({
	key: 'ACampaign',
	get: async() => getCampaings().then(a => a.reduce((acc, c) => {
		acc[c.id] = c;
		return acc;
	}, {} as Record<number, Campaign>))
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getCampaings(): Promise<Campaign[]> {
	try {
        return await axios.post(`${URL_FOUNDATION}/api/v2/promotions/query`, { descending: false, order_by: 'id' }).then(res => res.data);
	} catch (e) {
		console.error(e);
		return [];
	}
}

export interface Campaign {
  id: number
  name: string
  description: string
  periods: Period[]
  is_active: boolean
  companies: Company[]
  created_at: string
  products: Product[]
}

export interface Period {
  color?: string
  end: string
  name: string
  start: string
}

export interface Company {
  id: number
  name: string
}

export interface Product {
  id: number
  name: string
}
