import * as React from 'react';
import styled from 'styled-components';
import { FlexDiv } from '../../containers_v2/products/style';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { BorderColor, SidelyBlack } from '../../styles/global/css/Utils';

const PanelSwap = styled.div<{ activeIndex: number, index: number, minWidth?: string, square?: boolean, borderBottom?: boolean }>`
	${DefaultText};
	flex-shrink: 0;
	background-color: white;
	font-weight: 500;
	padding: 5px 20px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: 12px;
	position: relative;
	width: fit-content;
	${({ minWidth }) => minWidth ? `min-width: ${minWidth};` : ''}
	${({ activeIndex, index, square, borderBottom }) => {
		if (activeIndex === index) return `
			${!square ? `border: 1px solid ${BorderColor}; border-radius: 10px 10px 0 0;` : ''}
			border-bottom: none;
		`;
		return `
			${!square ? 'border: 1px solid transparent;' : ''}
			${!square && borderBottom ? `border-bottom: 1px solid ${BorderColor};` : ''}
			* {
				opacity: 0.5;
			}
			cursor: pointer;
			&:hover > * {
				opacity: 0.75;
			}
		`;
	}}
`;

const MetaPanelSwap = styled(PanelSwap)`
	border: 1px solid ${BorderColor};
	border-radius: 10px;
	display: flex;
	justify-content: center;
	${({ activeIndex, index }) => {
		if (activeIndex === index) return '';
		return `
			* {
				opacity: 0.5;
			}
			cursor: pointer;
			&:hover {
				opacity: 0.75;
			}
		`;
	}}
`;

const PanelToolTip = styled.div<{ left: number, top: number }>`
	position: absolute;
	padding: 0.5em;
	background-color: ${SidelyBlack}CC;
	left: ${p => p.left}px;
	top: calc(${p => p.top}px + 10px);
	z-index: 9;
	color: white;
	font-size: 11px;
	white-space: nowrap;
	&:after {
		content: " ";
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent ${SidelyBlack}CC transparent;
	  }
`;

type PanelSelectorProps<T extends { label: React.ReactNode, img?: string, toolTip?: string }> = {
	panels: T[],
	onClick: (panel: T, index: number) => void,
	activeIndex: number,
	minWidth?: string
}

export function PanelSelector<T extends { label: React.ReactNode, img?: string, hidden?: boolean, toolTip?: string }>(props: PanelSelectorProps<T> & {
	borderBottom?: boolean,
	noToolTip?: boolean,
	square?: boolean
}): JSX.Element {
	const [hovered, setHovered] = React.useState<number>();
	const ref = React.useRef<HTMLDivElement>();
	const setRef = React.useCallback(node => ref.current = node, []);
	const panelsSwap = document.querySelectorAll('.panel-swap');
	let founded = false;
	let left = 0;
	panelsSwap.forEach((ps, i) => {
		if (founded) return;
		if (i === hovered) {
			founded = true;
			return;
		}
		left += ps.clientWidth;
	});

	return <>
		<FlexDiv overflow='auto' flexShrink={0} innerRef={setRef} onMouseLeave={() => !props.noToolTip && setHovered(undefined)} width='100%'>
			{props.panels.map((panel, index) => <PanelSwap
				square={props.square}
				minWidth={props.minWidth}
				hidden={panel.hidden}
				className='panel-swap'
				onMouseEnter={() => !props.noToolTip && setHovered(index)}
				key={`panelSwap[${index}]`}
				activeIndex={props.activeIndex}
				index={index}
				onClick={() => props.onClick(panel, index)}
				borderBottom={props.borderBottom}
			>
				<FlexDiv gap='5px'>
					{panel.img !== undefined && <img height='20px' src={panel.img} />}
					{panel.label}
				</FlexDiv>
			</PanelSwap>
			)}
			{props.borderBottom && <div style={{ flex: 1, borderBottom: `1px solid ${BorderColor}`, alignSelf: 'end' }} />}
		</FlexDiv>
		{!props.noToolTip && hovered !== undefined && ref.current && <PanelToolTip top={ref.current.offsetTop + ref.current.clientHeight} left={left - ref.current.scrollLeft + ref.current.offsetLeft}>{props.panels[hovered].toolTip ?? props.panels[hovered].label}</PanelToolTip>}
	</>;
}

export function MetaPanelSelector<T extends { label: React.ReactNode, img?: string }>(props: PanelSelectorProps<T>) {
	return <FlexDiv flexShrink={0} width='100%' justify='center' gap='2em' margin='10px 0'>
		{props.panels.map((panel, index) => <MetaPanelSwap
			minWidth={props.minWidth}
			key={`metaPanelSwap[${index}]`}
			activeIndex={props.activeIndex}
			index={index}
			onClick={() => props.onClick(panel, index)}
		>
			<FlexDiv gap='5px'>
				{panel.img !== undefined && <img width='20px' src={panel.img} />}
				{panel.label}
			</FlexDiv>
		</MetaPanelSwap>
		)}
	</FlexDiv>;
}
