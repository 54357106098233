import styled from 'styled-components';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { DefaultImage } from '../../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../../styles/global/css/GlobalText';
import { DarkGreySidely2, LightGreySidely, SidelyBlack } from '../../../../styles/global/css/Utils';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 75%;
    min-width: 750px;
    flex-grow: 3;
    height: calc(100vh - 130px);
`;

const ContainerProducts = styled.div`
    height: 100%;
    width: 100%;
    overflow: auto;
`;

const ButtonAddBlock = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${LightGreySidely};
    position: sticky;
    right: 0px;
    z-index: 9;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ButtonAdd = styled.img`
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    width: 22px;
    height: 22px;
`;

interface CardRowProps {
  justifyContent?: string
  fix?: boolean
  margin?: string;
}

const CardRow = styled.div<CardRowProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${p => p.margin ?? '10px'};
    justify-content: ${p => p.justifyContent ? p.justifyContent : 'space-between'};
    ${p => p.fix ? `
        margin:0;
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: ${LightGreySidely};
    ` : ''}
`;

const CardImage = styled(DefaultImage)``;

const ContainerShoppingCart = styled.div`
    flex-grow: 2;
    padding: 5px;
    min-width: 300px;
    max-width: 25vw;
    width: 25vw;
`;

const ShoppingCart = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ShoppingCartTop = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(100vh - 450px);
`;

const ShoppingCartBottom = styled.div`
    margin-bottom: 50px;
`;

interface textProps {
  isBold?: boolean
}

const Text = styled.p<textProps>`
    ${DefaultText}
    font-weight: ${p => p.isBold ? '500' : '400'};
    font-size: 12px;
    line-height: 21px;
    color: ${p => p.isBold ? SidelyBlack : DarkGreySidely2};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Symbol = styled.p`
    ${DefaultText}
`;

const ShoppingCartEmpty = styled.p`
    ${DefaultText}
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #6C7177;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
`;

const Separator = styled.hr`
    border: 1px solid #D5D5D5;
    width: 90%;
    background-color: #D5D5D5;
`;

interface ShoppingCartTextProps {
  isBold?: boolean
  marginLeft?: string
}

const ShoppingCartText = styled.p<ShoppingCartTextProps>`
    ${DefaultText}
    font-weight: ${p => !p.isBold ? '400' : '500'};
    font-size: ${p => !p.isBold ? '12px' : '14px'};
    line-height: ${p => !p.isBold ? '24px' : '20px'};
    color: ${p => !p.isBold ? DarkGreySidely2 : SidelyBlack};
    margin-left: ${p => p.marginLeft ? p.marginLeft : 'initial'};

`;

const Button = styled(DefaultButton)`
    width: 130px;
    height: 42px;
`;

const ProductResultContainer = styled.div`
    border: 0.717519px solid #D5D5D5;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    margin: 5px;
`;

const ProductSummaryContainer = styled.div`
    background-color: #FFFFFF;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    margin: 5px;
    width: 100%;
`;

export {
	Container,
	ContainerProducts,
	CardRow,
	CardImage,
	ContainerShoppingCart,
	ShoppingCart,
	ShoppingCartEmpty,
	Separator,
	ShoppingCartText,
	Text,
	Button,
	ShoppingCartTop,
	ShoppingCartBottom,
	BodyContainer,
	Symbol,
	ButtonAdd,
	ButtonAddBlock,
	ProductResultContainer,
	ProductSummaryContainer
};
