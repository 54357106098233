import * as React from 'react';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { translateToString } from '../../styles/global/translate';
import { Table, TableSortType } from '../../components_v2/table/Table';
import { Promotion } from './model';
import { PutPromotionBody, getPromotions, postPromotion, putPromotion } from './actions';
import genPromotionColumns from './PromotionsColumns';
import { RangePickerModal } from '../../components_v2/toolbarFilter/time/DatePicker';
import { DurationPickerModalWrapper } from '../import/Table';
import * as moment from 'moment';
import * as ReactDates from 'react-dates';
import PopupPromotion from './PopupPromotion';
import { ModalState } from '../products/model';
import Add from '../../components_v2/add/Add';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import GanttChart from './GanttChart';
import PageSwitch from '../map/PageSwitch';
import calendarImage from 'images/icon/small_calendar2.svg';
import ListViewImage from 'images/ui_icon/listView_black.svg';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import promotionIcon from 'images/icons/events/Promotion.svg';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Restricted from '../permissions/Restricted';
import { Unhautorized } from '../noData/NoData';

export type RangePickerState = {
	isOpen: boolean,
	left?: number,
	top?: number,
	isTop?: boolean,
	dates?: { startDate: moment.Moment | null, endDate: moment.Moment | null }
	onDatesChange?: (p: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => void,
	focus?: ReactDates.FocusedInputShape
}

export default function Promotions(props: { setToolBarState: React.Dispatch<React.SetStateAction<ToolbarState>>}) {
	const [promotions, setPromotions] = React.useState<Promotion[]>([]);
	const [rangePickerState, setRangePickerState] = React.useState<RangePickerState>({ isOpen: false });
	const [modalState, setModalState] = React.useState<ModalState<{promotion: Promotion, mode: PopupMode }>>({ isOpen: false });
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();
	const [visu, setVisu] = React.useState<'table' | 'timeline'>('timeline');
	const [archivedFilter, setArchivedFilter] = React.useState(true);
	const [sort, setSort] = React.useState<TableSortType>({ id: 'created_at', desc: true });
	

	const refresh = React.useCallback(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		getPromotions(sort ? {
			order_by: sort.id,
			descending: sort.desc
		} : undefined).then(res => {
			setPromotions(res);
			setLoadingState(LoadingStateEnum.LOADED);
		}).catch(e => {
			console.error(e);
			setLoadingState(LoadingStateEnum.ERROR);
		});
	}, [sort]);

	const dropdownData = [{ label: translateToString('all'), value: false }, { label: translateToString('visible'), value: true }];

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('campagnes'),
			bottomLeftToolbarComponent: <ToolbarBox padding='0 1em'>
				<Restricted to={{ objectAction: 'ReadCampaign' }}>
					{visu === 'timeline' && <>
						<Dropdown
							datalist={dropdownData}
							dropdownStyle={{
								width: '205px',
								height: '38px',
								optionWidth: '205px',
								fontSize: 12,
								containerBorder: '1px solid rgba(0, 0, 0, 0.125)',
								arrowClassName: 'fas fa-caret-down mr-1',
								arrowColor: 'rgb(204, 204, 204)',
								arrowFontSize: '12px',
								imageWidth: '22px',
								imageHeight: '22px'
							}}
							readOnly
							name='dropdown_archived_filter'
							onChange={value => setArchivedFilter(value.value)}
							selectedValue={dropdownData.find(d => d.value === archivedFilter)}
							image={promotionIcon}
						/>
					</>}
				</Restricted>
			</ToolbarBox>,
			bottomRightToolbarComponent: <ToolbarBox>
				<Restricted to={{ objectAction: 'ReadCampaign' }}>
					<PageSwitch<'table' | 'timeline'> selectedValue={visu} list={[ { value: 'timeline', src: calendarImage, onClick: setVisu }, { value: 'table', src: ListViewImage, onClick: setVisu } ]}/>
					<Restricted to={{ objectAction: 'CreateCampaign' }}>
						<Add onClick={() => setModalState({ isOpen: true })} />
					</Restricted>
				</Restricted>
			</ToolbarBox>
		});
	}, [visu, archivedFilter]);

	React.useEffect(() => {
		refresh();
	}, [sort]);

	const localPutPromotion = React.useCallback((id: number, body: PutPromotionBody) => putPromotion(id, body).then(refresh), []);

	const columns = React.useMemo(() => genPromotionColumns(promotions, setRangePickerState, localPutPromotion, setModalState, loadingState), [promotions, loadingState]);
	const filteredPromotions = React.useMemo(() => promotions.filter(p => p.is_active || p.is_active === archivedFilter), [archivedFilter, promotions]);
	const onSort = React.useCallback(res => setSort(res[0]), []);

	return <Restricted to={{ objectAction: 'ReadCampaign' }} fallback={<Unhautorized />}>
		{visu === 'timeline' && <GanttChart
			promotions={filteredPromotions}
			onEventClick={data => setModalState({ isOpen: true, data })}
			onNewEvent={() => setModalState({ isOpen: true })}
			height='calc(100vh - 118px)'
		/>}
		{visu === 'table' && <>
			<Table
				height='calc(100vh - 174px)'
				columns={columns}
				data={promotions}
				onSort={onSort}
				initialSortBy={sort}
			/>
			<DurationPickerModalWrapper isOpen={rangePickerState.isOpen} isTop={rangePickerState.isTop} top={rangePickerState.top} left={rangePickerState.left} zIndex='10'>
				{rangePickerState.isOpen &&
				<RangePickerModal
					noAbsolute
					future
					onClickOut={() => setRangePickerState({ isOpen: false })}
					saveButton
					noDayButton
					start={rangePickerState.dates?.startDate ?? undefined}
					end={rangePickerState.dates?.endDate ?? undefined}
					onDateChange={dates => {
						setRangePickerState({ isOpen: false });
						rangePickerState.onDatesChange?.(dates);
					}}
					defaultFocus={rangePickerState.focus}
				/>}
			</DurationPickerModalWrapper>
		</>}
		<ComponentLoader loadingState={loadingState} allScreen noBackground/>
		<PopupPromotion
			mode={modalState.data?.mode}
			isOpen={modalState.isOpen}
			setIsOpen={(isOpen: boolean) => setModalState({ isOpen })}
			promotion={modalState.data?.promotion}
			onCreate={body => postPromotion(body).then(refresh)}
			onUpdate={(id, body) => putPromotion(id, body).then(refresh)}
			onDelete={refresh}
		/>
	</Restricted>;
}
