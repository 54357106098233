/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import styled from 'styled-components';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners from '../../../components_v2/dropdown/DropdownOwners';
import DropdownStatus from '../../../components_v2/dropdown/DropdownStatus';
import { DropdownTags } from '../../../components_v2/dropdown/DropdownTagsCloud';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { FilterTree } from '../../../components_v2/filter/model/Model';
import { Radio } from '../../../components_v2/filterList/style/Style';
import storeLang from '../../../helpers/storeLang';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { FlexDiv } from '../../products/style';
import { bulkEditAdditionalColumns, bulkEditFrequency, bulkEditOwner, bulkEditPromotions, bulkEditShelfAuditTemplates, bulkEditStatuses, bulkEditTags } from './action';
import { DefaultText, DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import { ITag } from '../../../../typings/proto/protobufs';
import Collapse from '../../../components_v2/Collapse/Collapse';
import { BlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import { ClientCompany as CC } from '../../orders/model/Model';
import { getFilteredCompanies } from '../../orders/data/Data';
import Input from '../../../components_v2/input/Input';
import { AEventTypes, AtomEventType } from '../../../atoms/global/events';
import { useRecoilValue } from 'recoil';
import { normalizeFloat, normalizeInt, normalizeIntAbove0 } from '../../globals/FieldFunction';
import { Translate, translateToString } from '../../../styles/global/translate';
import { Promotion } from '../../promotions/model';
import { getPromotions } from '../../promotions/actions';
import PopupDeletion from '../../../components_v2/popup/PopupDeletion';
import { ShelfAuditTemplate, getShelfAuditTemplates } from '../../../atoms/filter/shelfAuditFilterAtom';
import { AAdditionalColumns, AdditionalColumn } from '../../../atoms/additionalColumns';
import Switch from '../../../components_v2/Switch/Switch';
import { AAssortments } from '../../../atoms/assortment';
import PermissionContext from '../../permissions/PermissionContext';
import { LoadingStateEnum } from '../../import/model';

enum BulkEditEnum {
	Owner,
	Status,
	Tags,
	FrequencyTarget,
	Promotion,
	ShelfAuditTemplate,
	AdditionalColumns
}

enum BulkEditReplaceOptions {
	Add,
	Replace
}

const DROPDOWN_STYLE = {
	containerWidth: '100%',
	optionWidth: '100%',
	labelUp: true,
	optionHeight: '150px',
	height: '50px',
	labelStyle: {
		fontSize: '14px',
		fontWeight: '500',
		padding: '0 0 15px 0',
		color: SidelyBlack,
	},
	width: '100%'
};

const ButtonText = styled.div`
  font-weight: 600;
  font-size: 13px;
`;

const RadioText = styled.div<{ isActive: boolean }>`
  ${DefaultText}
  ${p => p.isActive ? '' : 'color: rgba(37, 65, 83, 0.35);'}
  transition: 0.5s;
`;

export default function CompanyBulkEdition(props: {
	ids: number[],
	users?: DropdownData[],
	statuses?: DropdownData[],
	tags?: ITag[],
	records?: number,
	refresh?: (key: string) => void,
	setLoadingState?: (state: LoadingStateEnum) => void,
	onClose?: () => void,
	isAll?: boolean,
	new_filters?: FilterTree,
	search?: string
}): JSX.Element {
	const { records, ids, users, statuses, tags, refresh, isAll, new_filters, search } = props;
	const [selectedProperty, setSelectedProperty] = React.useState<BulkEditEnum>();
	const translate = getTranslate(storeLang.getState().localize);
	const { isAllowedTo } = React.useContext(PermissionContext);


	// ConfirmationEditPopup
	const [confirmPopopOpen, setConfirmPopupOpen] = React.useState<boolean>(false);

	// Owner
	const [owner, setOwner] = React.useState<DropdownData | undefined>(users?.[0]);

	// Status
	const [status, setStatus] = React.useState<DropdownData | undefined>(statuses?.[0]);

	// Tags
	const [selectedTags, setSelectedTags] = React.useState<ITag[]>([]);
	const [selectedReplaceTags, setSelectedReplaceTags] = React.useState<ITag[]>([]);
	const [tagOption, setTagOption] = React.useState<BulkEditReplaceOptions>(BulkEditReplaceOptions.Add);

	// Promotions
	const [promotions, setPromotions] = React.useState<(Promotion & { color: string })[]>([]);
	const [selectedPromotions, setSelectedPromotions] = React.useState<Promotion[]>([]);
	const [selectedReplacePromotions, setSelectedReplacePromotions] = React.useState<Promotion[]>([]);

	// Parents
	const [parent, setParent] = React.useState<DropdownData<CC>>();
	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData<CC>[]>([]);
	
	//Frequency Target
	const [frequency, setFrequency] = React.useState<number>();
	const eventTypes = useRecoilValue(AEventTypes);
	const [eventType, setEventType] = React.useState<number>();
	
	// Company Template
	const [templates, setTemplates] = React.useState<ShelfAuditTemplate[]>([]);
	const [selectedTemplates, setSelectedTemplates] = React.useState<ShelfAuditTemplate[]>([]);
	const [selectedReplaceTemplates, setSelectedReplaceTemplates] = React.useState<ShelfAuditTemplate[]>([]);

	// Additional columns
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const [selectedAdditionalColumn ,setSelectedAdditionalColumn] = React.useState<AdditionalColumn>();
	const [additionalColumnValue, setAdditionalColumnValue] = React.useState<any>();
	const assortments = useRecoilValue(AAssortments);

	React.useEffect(() => {
		getPromotions().then(res => setPromotions(res.map(r => ({ ...r, color: BlueSidely }))));
		getShelfAuditTemplates().then(setTemplates);
	}, []);

	const CompanyProperties: DropdownData<BulkEditEnum>[] = [
		{ label: translate('owner').toString(), value: BulkEditEnum.Owner },
		{ label: translate('status').toString(), value: BulkEditEnum.Status },
		{ label: 'Tags', value: BulkEditEnum.Tags },
		{ label: translate('frequency').toString(), value: BulkEditEnum.FrequencyTarget },
		{ label: translateToString('campagnes'), value: BulkEditEnum.Promotion },
	];
	if (isAllowedTo({ objectAction: 'ReadForm' })) {
		CompanyProperties.push({ label: translateToString('additional_columns'), value: BulkEditEnum.AdditionalColumns });
	}

	const isDisabled = (): boolean => {
		switch (selectedProperty) {
			case BulkEditEnum.Owner:
				return owner == undefined;
			case BulkEditEnum.Status:
				return status == undefined;
			case BulkEditEnum.Tags:
				return tagOption == BulkEditReplaceOptions.Add ? selectedTags.length == 0 : selectedReplaceTags.length == 0;
			case BulkEditEnum.FrequencyTarget:
				return !frequency || !eventType || frequency <= 0;
			case BulkEditEnum.Promotion:
				return tagOption == BulkEditReplaceOptions.Add ? selectedPromotions.length == 0 : selectedReplacePromotions.length == 0;
			case BulkEditEnum.ShelfAuditTemplate:
				return tagOption == BulkEditReplaceOptions.Add ? selectedTemplates.length == 0 : selectedReplaceTemplates.length == 0;
			case BulkEditEnum.AdditionalColumns: {
				return additionalColumnValue === undefined;
			}
			default:
				return true;
		}
	};

	const subComponent = () => {
		switch (selectedProperty) {
			case BulkEditEnum.Owner:
				return <DropdownOwners
					dropdownStyle={DROPDOWN_STYLE}
					onChange={setOwner}
					users={users ?? []}
					selected={owner}
					border
					label={<Translate id='company_owner' />}
				/>;
			case BulkEditEnum.Status:
				return <DropdownStatus
					name='dropdown-status'
					dropdownStyle={DROPDOWN_STYLE}
					datalist={statuses ?? []}
					onChange={(value: DropdownData) => setStatus(value)}
					selectedValue={status}
				/>;
			case BulkEditEnum.Tags:
				// @ts-expect-error PROTOBUF YAY
				return <BulkEditReplace<ITag>
					setReplaceOption={setTagOption}
					replaceOption={tagOption}
					values={tags}
					selectedValues={selectedTags}
					setSelectedValues={setSelectedTags}
					selectedReplaceValues={selectedReplaceTags}
					setSelectedReplaceValues={setSelectedReplaceTags}
					translationKey={translateToString('tag')}
				/>;
			case BulkEditEnum.Promotion:
				return <BulkEditReplace<Promotion>
					setReplaceOption={setTagOption}
					replaceOption={tagOption}
					values={promotions}
					selectedValues={selectedPromotions}
					setSelectedValues={setSelectedPromotions}
					selectedReplaceValues={selectedReplacePromotions}
					setSelectedReplaceValues={setSelectedReplacePromotions}
					translationKey={translateToString('campagne')}
				/>;
			case BulkEditEnum.ShelfAuditTemplate:
				return <BulkEditReplace<ShelfAuditTemplate>
					setReplaceOption={setTagOption}
					replaceOption={tagOption}
					values={templates}
					selectedValues={selectedTemplates}
					setSelectedValues={setSelectedTemplates}
					selectedReplaceValues={selectedReplaceTemplates}
					setSelectedReplaceValues={setSelectedReplaceTemplates}
					translationKey={translateToString('form_templates')}
					singular
				/>;
				return <DropdownSearch
					label={translate('Parent company').toString()}
					dropdownStyle={DROPDOWN_STYLE}
					onSearchChange={async(search, offset) => {
						try {
							const res = await getFilteredCompanies(search, 20, offset);
							const ret: boolean = res.data.length == 0 || res.data.length < 20;
							if (offset !== undefined) {
								setOptionCompanies([...optionCompanies, ...res.data.map((company: CC) => {
									return {
										label: company.name,
										value: company
									};
								})]);
							} else {
								setOptionCompanies(res.data.map(company_1 => {
									return {
										label: company_1.name,
										value: company_1
									};
								}));
							}
							return ret;
						} catch (error) {
							console.error(error);
							return false;
						}
					}}
					datalist={optionCompanies}
					selectedValue={parent ?? null}
					name={''}
					onChange={(value: DropdownData<CC>) => setParent(value)}
				/>;
			case BulkEditEnum.FrequencyTarget:
				return <>
					<DropdownStatus<AtomEventType>
						dropdownStyle={DROPDOWN_STYLE}
						datalist={eventTypes.map(et => ({ value: et, label: translate(`event.${et.name}`).toString(), color: et.color }))}
						name='BulkEditFrequencyTargetEventTypeDropdown'
						readOnly
						label={translate('company.creation.event.type').toString()}
						onChange={(value: DropdownData<AtomEventType>) => setEventType(value.value?.id)}
					/>
					<Input
						label={translate('frequency').toString()}
						name='BulkEditFrequencyTargetInput'
						type='number'
						inputStyle={DROPDOWN_STYLE}
						normalize={[normalizeIntAbove0]}
						onChange={value => setFrequency(parseInt(value))}
					/>
				</>;
			case BulkEditEnum.AdditionalColumns: {
				const getAssortmentDV = () => {
					if (!additionalColumnValue) return;
					const assortment = assortments.find(a => a.id == additionalColumnValue);
					if (assortment) {
						return {
							label: assortment.name,
							value: assortment.id
						};
					}
				};

				const additionnalColumnsSelect: { label: string, value: string | null }[] = [{ label: translateToString('none'), value: null }];
				selectedAdditionalColumn?.data?.map(id => {
					additionnalColumnsSelect.push({ label: id, value: id });
				});

				return <>
					<Dropdown
						dropdownStyle={DROPDOWN_STYLE}
						datalist={additionalColumns.filter(ac => ac.type !== 'ReportColumn').map(ac => ({ label: ac.name, value: ac }))}
						name='BulkEditAdditionalColumnsDropdown'
						readOnly
						label={translate('column').toString()}
						onChange={value => {
							if (value.value.type !== selectedAdditionalColumn?.type) {
								setAdditionalColumnValue(value.value.type == 'Boolean' ? true : undefined);
							}
							setSelectedAdditionalColumn(value.value);
						}}
						selectedValue={selectedAdditionalColumn ? { value: selectedAdditionalColumn, label: selectedAdditionalColumn.name } : undefined}
					/>
					{selectedAdditionalColumn?.type == 'Boolean' && <Switch value={additionalColumnValue} onChange={b => setAdditionalColumnValue(b)}/>}
					{(selectedAdditionalColumn?.type == 'Integer' || selectedAdditionalColumn?.type == 'String' || selectedAdditionalColumn?.type == 'Number') && <Input
						normalize={selectedAdditionalColumn.type === 'Integer' ? [normalizeInt] : selectedAdditionalColumn.type === 'Number' ? [normalizeFloat()] : []}
						inputStyle={DROPDOWN_STYLE}
						value={additionalColumnValue}
						onChange={b => {
							if (selectedAdditionalColumn.type == 'String') return setAdditionalColumnValue(b);
							if (selectedAdditionalColumn.type == 'Integer') {
								const int = parseInt(b);
								isNaN(int) ? setAdditionalColumnValue(undefined) : setAdditionalColumnValue(int);
							}
							if (selectedAdditionalColumn.type == 'Number') {
								const float = parseFloat(b);
								isNaN(float) ? setAdditionalColumnValue(undefined) : setAdditionalColumnValue(float);
							}
						}}
						name='additional-value-input'
						type={selectedAdditionalColumn.type == 'String' ? 'text' : 'number'}
					/>}
					{/* {selectedAdditionalColumn?.type == 'Date' && <Switch value={additionalColumnValue} onChange={b => setAdditionalColumnValue(b)}/>} */}
					{selectedAdditionalColumn?.type == 'Catalogue' && <Dropdown
						dropdownStyle={DROPDOWN_STYLE}
						datalist={[{ label: translateToString('none'), value: null }, ...assortments.map(a => ({ label: a.name, value: a.id }))]}
						name='assortment-DD'
						selectedValue={getAssortmentDV()}
						onChange={b => setAdditionalColumnValue(b.value)}
					/>}
					{selectedAdditionalColumn?.type == 'MultiSelect' && <DropdownTags
						dropdownStyle={DROPDOWN_STYLE}
						tags={selectedAdditionalColumn.data.map(id => ({ name: id, id }))}
						selected={additionalColumnValue?.map(id => ({ name: id, id }))}
						onDelete={id => {
							const index = additionalColumnValue.findIndex(t => t == id);
							if (index >= 0) {
								additionalColumnValue.splice(index, 1);
								setAdditionalColumnValue([...additionalColumnValue]);
							}
						}}
						onChange={b => setAdditionalColumnValue(b.map(t => t.value.id))}
					/>}
					{selectedAdditionalColumn?.type == 'Select' && <Dropdown
						name='BulkEditAdditionalColumnsDropdown'
						dropdownStyle={DROPDOWN_STYLE}
						datalist={additionnalColumnsSelect}
						onChange={value => {
							setAdditionalColumnValue(value.value);
						}}
					/>}
				</>;

			}
		}
	};

	const onEdit = async() => {
		props.onClose?.();
		props.setLoadingState?.(LoadingStateEnum.LOADING);
		try {
			switch (selectedProperty) {
				case BulkEditEnum.Owner:
					await bulkEditOwner({ companies: ids, owner_id: owner?.value.id, all: isAll ?? false, new_filters, search });
					refresh?.('status');
					break;
				case BulkEditEnum.Status:
					await bulkEditStatuses({ companies: ids, status: status?.value.id, all: isAll ?? false, new_filters, search });
					refresh?.('status');
					break;
				case BulkEditEnum.Promotion:
					await bulkEditPromotions({
						companies: ids,
						promotions: selectedPromotions?.map(t => t.id) ?? [],
						replaced_promotions: selectedReplacePromotions?.map(t => t.id),
						replace: tagOption == 1,
						all: isAll ?? false,
						new_filters,
						search
					});
					refresh?.('promotions');
					break;
				case BulkEditEnum.ShelfAuditTemplate:
					await bulkEditShelfAuditTemplates({
						companies: ids,
						templates: selectedTemplates?.map(t => t.id) ?? [],
						replaced_templates: selectedReplaceTemplates?.map(t => t.id),
						replace: tagOption == 1,
						all: isAll ?? false,
						filters: new_filters,
						search
					});
					refresh?.('promotions');
					break;
				case BulkEditEnum.Tags:
					await bulkEditTags({
						companies: ids,
						tags: selectedTags?.map(t => t.id!) ?? [],
						replaced_tags: selectedReplaceTags?.map(t => t.id!),
						replace: tagOption == 1,
						all: isAll ?? false,
						new_filters,
						search
					});
					refresh?.('tags');
					break;
				case BulkEditEnum.FrequencyTarget:
					if (!frequency || !eventType) break;
					await bulkEditFrequency({
						companies: ids,
						frequency: {
							frequency,
							event_type: eventType
						},
						all: isAll ?? false,
						filters: new_filters,
						search
					});
					refresh?.('frequency');
					break;
				case BulkEditEnum.AdditionalColumns: {
					if (!selectedAdditionalColumn) break;
					await bulkEditAdditionalColumns(selectedAdditionalColumn.id,
						{
							companies: ids,
							filters: new_filters,
							search,
							all: isAll ?? false,
							value: additionalColumnValue
						});
					refresh?.('additional-columns');
					break;
				}
			}
			props.setLoadingState?.(LoadingStateEnum.LOADED);
		} catch (e) {
			console.error(e);
			props.setLoadingState?.(LoadingStateEnum.ERROR);
		}

	};

	return <FlexDiv flow='column' gap='50px' margin='50px 0 0 0' padding='0 5% 0 5%' align='stretch'>
		<Dropdown<BulkEditEnum>
			dropdownStyle={DROPDOWN_STYLE}
			datalist={CompanyProperties}
			name='dropdown_type'
			readOnly
			label={<Translate id='property_to_update' />}
			onChange={(value: DropdownData<BulkEditEnum>) => setSelectedProperty(value.value)}
		/>
		{subComponent()}
		{selectedProperty !== undefined && <DefaultButton disabled={isDisabled()} onClick={() => setConfirmPopupOpen(true)} height='30px'>
			<ButtonText>
				<Translate id='global.modify' />
			</ButtonText>
		</DefaultButton>
		}
		<PopupDeletion
			isOpen={confirmPopopOpen}
			isEditPopup={true}
			onClickOut={() => {
				setConfirmPopupOpen(false);
			}}
			records={records ?? ids.length}
			onValidation={onEdit}
			translationKey='companies'
		/>
	</FlexDiv>;
}

function BulkEditReplace<T extends { id: string | number, name: string, color?: string }>(props: {
	setReplaceOption: (option: BulkEditReplaceOptions) => void,
	replaceOption: BulkEditReplaceOptions,
	values?: T[],
	selectedValues: T[],
	setSelectedValues: (tags: T[]) => void,
	selectedReplaceValues: T[],
	setSelectedReplaceValues: (tags: T[]) => void,
	translationKey: string,
	singular?: true
}) {
	const {
		setReplaceOption,
		replaceOption,
		values,
		selectedValues,
		setSelectedValues,
		selectedReplaceValues,
		setSelectedReplaceValues,
		translationKey
	} = props;
	return <div>
		<FlexDiv gap='10px' onClick={() => setReplaceOption(BulkEditReplaceOptions.Add)} margin='10px 0' clickable>
			<Radio isActive={replaceOption == BulkEditReplaceOptions.Add} inactiveColor='rgba(37, 65, 83, 0.35)' />
			<RadioText isActive={replaceOption == BulkEditReplaceOptions.Add}>
				<Translate id={props.singular ? 'add_value' : 'add_values'} options={{ variables: [['VALUE', translationKey ]] }} />
			</RadioText>
		</FlexDiv>
		<Collapse isOpen={replaceOption == BulkEditReplaceOptions.Add}>
			<FlexDiv padding='10px 0'>
				<DropdownTags
					dropdownStyle={DROPDOWN_STYLE}
					tags={values ?? []}
					selected={selectedValues}
					onChange={(tags: DropdownData[]) => {
						const insert = tags
							.filter(t => !selectedValues.some(st => st.id == t.value.id))
							.map(t => t.value);
						setSelectedValues([...selectedValues, ...insert]);
					}}
					onDelete={(id: number) => {
						const index = selectedValues.findIndex(t => t.id == id);
						if (index >= 0) {
							selectedValues.splice(index, 1);
							setSelectedValues([...selectedValues]);
						}
					}}
				/>
			</FlexDiv>
		</Collapse>
		<FlexDiv gap='10px' onClick={() => setReplaceOption(BulkEditReplaceOptions.Replace)} margin='10px 0' clickable>
			<Radio isActive={replaceOption == BulkEditReplaceOptions.Replace} inactiveColor='rgba(37, 65, 83, 0.35)' />
			<RadioText isActive={replaceOption == BulkEditReplaceOptions.Replace}>
				<Translate id={props.singular ? 'replace_value' : 'replace_values'} options={{ variables: [['VALUE', translationKey ]] }} />
			</RadioText>
		</FlexDiv>
		<Collapse isOpen={replaceOption == BulkEditReplaceOptions.Replace}>
			<FlexDiv gap='20px' flow='column' align='stretch' padding='10px 0'>
				<DropdownTags
					dropdownStyle={DROPDOWN_STYLE}
					tags={values ?? []}
					selected={selectedReplaceValues}
					onChange={(tags: DropdownData[]) => {
						const insert = tags
							.filter(t => !selectedReplaceValues.some(st => st.id == t.value.id))
							.map(t => t.value);
						setSelectedReplaceValues([...selectedReplaceValues, ...insert]);
					}}
					onDelete={(id: number) => {
						const index = selectedReplaceValues.findIndex(t => t.id == id);
						if (index >= 0) {
							selectedReplaceValues.splice(index, 1);
							setSelectedReplaceValues([...selectedReplaceValues]);
						}
					}}
				/>
				<DefaultTextDiv><Translate id='replace_by' /></DefaultTextDiv>
				<DropdownTags
					dropdownStyle={DROPDOWN_STYLE}
					tags={values ?? []}
					selected={selectedValues}
					onChange={(tags: DropdownData[]) => {
						const insert = tags
							.filter(t => !selectedValues.some(st => st.id == t.value.id))
							.map(t => t.value);
						setSelectedValues([...selectedValues, ...insert]);
					}}
					onDelete={(id: number) => {
						const index = selectedValues.findIndex(t => t.id == id);
						if (index >= 0) {
							selectedValues.splice(index, 1);
							setSelectedValues([...selectedValues]);
						}
					}}
				/>
			</FlexDiv>
		</Collapse>

	</div>;
}
