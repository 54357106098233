/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component, Fragment} from 'react';
import {Translate} from 'react-localize-redux';
import moment from 'moment';

class Overview extends Component {
	state = {};

	render() {
		const { dataDetail } = this.props;

		return (
			<div className="row detail-body">
				<div className="col-md-5">
					<div className="row">
						<div className="col-md-6 text-md-right px-3">
							<p>
								<Translate id="company" />
							</p>
						</div>
						<div className="col-md-6">
							<p className="detail-value-key">
								<a
									href={
										dataDetail.clientCompany
											? `/companies?id=${dataDetail.clientCompany.id}`
											: '#'
									}
									className="type-link"
								>
									{(dataDetail.clientCompany &&
                    dataDetail.clientCompany.name) ||
                    '-'}
								</a>
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 text-md-right px-3">
							<p>Contact</p>
						</div>
						<div className="col-md-6">
							<p className="detail-value-key">
								<a
									href={
										dataDetail.contact
											? `/contacts/detail/${dataDetail.contact.id}`
											: '#'
									}
									className="type-link"
								>
									{(dataDetail.contact &&
                    `${dataDetail.contact.firstName || ''} ${dataDetail.contact
                    	.lastName || ''}`) ||
                    '-'}
								</a>
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 text-md-right px-3">
							<p>
								<Translate id="Origin prospect" />
							</p>
						</div>
						<div className="col-md-6">
							<p className="detail-value-key">
								{(dataDetail.prospect && dataDetail.prospect.name) || '-'}
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="row">
						<div className="col-md-3 text-md-right px-3">
							<p>
								<Translate id="deadline" />
							</p>
						</div>
						<div className="col-md-8">
							<p className="detail-value-key">
								{dataDetail.deadline
									? moment(dataDetail.deadline).format('DD/MM/YY')
									: '-'}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3 text-md-right px-3">
							<p>
								<Translate id="description" />
							</p>
						</div>
						<div className="col-md-8">
							<p className="detail-value-key">
								{dataDetail.description || '-'}
							</p>
						</div>
					</div>
					{dataDetail.opportunityTags && (
						<div className="row">
							{dataDetail.opportunityTags.length !== 0 && (
								<Fragment>
									<div className="col-md-3 text-md-right px-4 pt-1">
										<p>Tags</p>
									</div>
									<div className="col-md-9">
										{dataDetail.opportunityTags.map(
											data =>
												data.tags && (
													<div
														className="px-3 tag-detail mr-1"
														style={{
															border: `1px solid ${
																data.tags.colorStatus.colorCode
															}`
														}}
													>
														<p
															className="mb-0"
															style={{
																color: `${data.tags.colorStatus.colorCode}`
															}}
														>
															{data.tags.name}
														</p>
													</div>
												)
										)}
									</div>
								</Fragment>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Overview;
