import * as React from 'react';
import { getEmailTemplates } from '../settings/subSettings/actions';
import { EmailTemplate, EmailTemplateData, EmailTemplateVariable } from './model/models';
import { convertStringToMailtoURL, replaceAllVariable } from '../../components_v2/utils';
import { EmailTemplatePopupContainer, Item, List } from './style/styles';
import { ButtonAction } from '../client-companies/style/PopupStyle';
import email_button from 'images/icons/company/email_button.svg';
import { translateToString } from '../../styles/global/translate';
import { Link } from 'react-router-dom';
import { BlueSidely } from '../../styles/global/css/Utils';


interface HoverEmailWrapperProps {
	emailTemplateVariable: EmailTemplateVariable
	email: string,
	parent?
}
export function HoverEmailWrapper(props: HoverEmailWrapperProps) {
	const [isEmailTemplateOpen,setIsEmailTemplateOpen] = React.useState(false);

	return (<>
		<EmailTemplatePopup
			emailTemplateVariable={props.emailTemplateVariable}
			email={props.email ?? ''}
			isOpen={isEmailTemplateOpen}
			onClose={() => setIsEmailTemplateOpen(false)}
		/>
		<ButtonAction
			parent={props.parent}
			src={email_button}
			alt="email"
			onClick={() => setIsEmailTemplateOpen(prev => !prev)}/>
	</>);
}


interface EmailTemplatePopupProps {
	emailTemplateVariable: EmailTemplateVariable
	email: string
	isOpen: boolean
	onClose: () => void
	quickAction?: boolean
}
export default function EmailTemplatePopup({ emailTemplateVariable, email, isOpen, onClose,quickAction }: EmailTemplatePopupProps) {
	const [emailTemplates, setEmailTemplates] = React.useState<EmailTemplate[]>([]);
	const fetchEmailTemplates = React.useCallback(() => getEmailTemplates().then(setEmailTemplates),[]);


	function handleSendEmailTemplate(emailTemplateData: EmailTemplateData) {
		const { subject, body } = emailTemplateData;

		const parsedBody = replaceAllVariable(emailTemplateVariable,body);
		const mailtoURL = convertStringToMailtoURL(email, { subject, body: parsedBody });
		window.open(mailtoURL);
		onClose();
	}

	function handleSendEmail() {
		window.open(`mailto:${email}`);
		onClose();
	}
	React.useEffect(() => {
		fetchEmailTemplates();
	}, []);

	const listStyle = quickAction ? { top:'20px', right:'-100px' } : {};

	if (!isOpen) return null;
	return <EmailTemplatePopupContainer>
		{
			<List {...listStyle}>
				<Item pointer borderBottom onClick={handleSendEmail}>{translateToString('email_template.new_email')}</Item>
				{
					emailTemplates.length > 0 ? emailTemplates
						.map((el, index) =>
							<Item
								key={el.name + el.id}
								onClick={() => handleSendEmailTemplate(el.data)}
								borderBottom={index + 1 === emailTemplates.length}
								pointer
								ellipsis
							>{el.name}</Item>
						)
						: <Item borderBottom>
							{translateToString('email_template.no_available_email_template')}
						</Item>
				}
				<Link to="/settings#email_template_list">
					<Item color={`${BlueSidely}`} fontWeight="400">{translateToString('email_template.create_new_template')}</Item>
				</Link>
			</List>

		}
	</EmailTemplatePopupContainer>;
}