import React, {Component} from 'react';
import update from 'immutability-helper';
import {connect} from 'react-redux';
import {get, head} from 'lodash';
import store from './builderLib/services/store';
import FormElementsEdit from './builderLib/form-elements-edit';
import SortableFormElements from './builderLib/sortable-form-elements';
import {getOption} from '../../../../helpers/data/defaultValueSelfAudit';

class FieldProperties extends Component {
	constructor(props) {
		super(props);
		const { onLoad, onPost } = props;
		store.setExternalHandler(onLoad, onPost);

		this.editForm = React.createRef();
		this.state = {
			elements: this.props.valElements || [],
			answer_data: {},
			data: []
		};
		this.seq = 0;
		this.state.elements.sort((el1, el2) => {
			return el1.ordering - el2.ordering;
		});
		this.state.elements.forEach((item) => {
			let opt = getOption(item.type);
			item.values = [];
			opt.forEach((element) => {
				item.values.push(element.value);
			});
		});

		const onUpdate = this._onChange.bind(this);
		store.subscribe(state => onUpdate(state.data));

		this.moveCard = this.moveCard.bind(this);
		this.insertCard = this.insertCard.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.data !== nextProps.data) {
			store.dispatch('updateOrder', nextProps.data);
		}
	}

	componentDidMount() {
		const { data, url, saveUrl } = this.props;

		store.dispatch('load', {
			loadUrl: url,
			saveUrl,
			data: data || []
		});
		document.addEventListener('mousedown', this.editModeOff);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.editModeOff);
	}

	editModeOff = e => {
		if (this.editForm.current && !this.editForm.current.contains(e.target)) {
			this.manualEditModeOff();
		}
	};

	manualEditModeOff = () => {
		const { editElement } = this.props;
		if (editElement && editElement.dirty) {
			editElement.dirty = false;
			this.updateElement(editElement);
		}
		this.props.manualEditModeOff();
	};

	_setValue = text => {
		return text.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
	};

	updateElement = (preview, element) => {
		this.props.setElements(element);
		const { elements } = this.state;
		//  const filterElement = elements.filter(v => v.id !== element.id);
		//   filterElement.push(element)
		//   this.setState({elements: filterElement})
	};

	_onChange(data) {
		const answer_data = {};

		data.forEach(item => {
			if (item && item.readOnly && this.props.variables[item.variableKey]) {
				answer_data[item.field_name] = this.props.variables[item.variableKey];
			}
		});

		this.setState({
			data,
			answer_data
		});
	}

	_onDuplicate = item => {
		store.dispatch('duplicate', item);
	};

	_onDestroy = item => {
		store.dispatch('delete', item);
	};

	insertCard(item, hoverIndex) {
		const { data } = this.state;
		data.splice(hoverIndex, 0, item);
		this.saveData(item, hoverIndex, hoverIndex);
	}

	moveCard(dragIndex, hoverIndex) {
		const { elements } = this.state;
		const dragCard = elements[dragIndex];
		this.saveData(dragCard, dragIndex, hoverIndex);
	}

	// eslint-disable-next-line no-unused-vars
	cardPlaceHolder = (dragIndex, hoverIndex) => {
		// Dummy
	};

	saveData(dragCard, dragIndex, hoverIndex) {
		const newData = update(this.state, {
			elements: {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard]
				]
			}
		});
		this.setState(newData);
		newData.elements &&
      this.props.sortingElement(newData.elements);
		store.dispatch('updateOrder', newData.data);
	}

	getElement(item, index) {
		const SortableFormElement = SortableFormElements[item.element];
		const editElementId = get(this, 'props.editElement.id', null);
		const { originData } = this.props;

		const isFilledCount =
      get(
      	head(get(originData, 'dataDetail.user_form_fields')),
      	'field_filled_value_count'
      ) > 0;

		return (
			<div className="my-1 mx-2">
				<SortableFormElement
					id={item.id}
					seq={this.seq}
					index={index}
					moveCard={this.moveCard}
					insertCard={this.insertCard}
					mutable={false}
					parent={this.props.parent}
					editModeOn={this.props.editModeOn}
					editElementId={editElementId}
					isDraggable
					canDrag={!item.disabled}
					key={item.id}
					sortData={item.id}
					data={item}
					isFilledCount={isFilledCount}
					_onDuplicate={this._onDuplicate}
					_onDestroy={this._onDestroy}
				/>
			</div>
		);
	}

	render() {
		let classes = this.props.className;
		if (this.props.editMode) {
			classes += ' is-editing';
		}
		const items = this.state.elements.map((item, index) =>
			this.getElement(item, index)
		);
		return (
			<div className={classes}>
				<div className="edit-form" ref={this.editForm}>
					{this.props.editElement !== null && (
						<FormElementsEdit
							showCorrectColumn={this.props.showCorrectColumn}
							files={this.props.files}
							manualEditModeOff={this.manualEditModeOff}
							preview={this}
							element={this.props.editElement}
							updateElement={this.updateElement}
							template={this.props.template}
							fieldTemplate={this.props.fieldTemplate}
						/>
					)}
				</div>
				<div className="Sortable">{items}</div>
			</div>
		);
	}
}

FieldProperties.defaultProps = {
	showCorrectColumn: false,
	files: [],
	editMode: false,
	editElement: null,
	className: 'react-form-builder-preview float-left'
};
const mapStateToProps = state => ({});
export default connect(mapStateToProps, {})(FieldProperties);
