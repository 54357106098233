/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {Field} from 'redux-form';
import {renderCheckField, renderCheckFieldReadOnly, required} from '../../../components/CheckboxField';
import {fieldName} from '../services/fieldGetter';

class Checkbox extends Component {
  state = {};

  render() {
    const { data, dynamicField, type } = this.props;

    const validate = data.is_required ? [required] : [];

    const name = fieldName(data, dynamicField);

    const readOnly = type === 'detail';

    return (
      <Field
        name={name}
        id={`id-${name}`}
        type="checkbox"
        component={readOnly ? renderCheckFieldReadOnly : renderCheckField}
        label={data.is_display_field_name && data.field_name}
        placeholder=""
        requiredStar={data.is_required}
        validate={validate}
        checked={data.is_default_checked}
        readOnly={type === 'detail'}
      />
    );
  }
}

export default Checkbox;
