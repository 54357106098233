import * as React from 'react';

interface MapContextType {
	hoveredPin: number | number[] | undefined,
	setHoveredPin: React.Dispatch<React.SetStateAction<number | number[] | undefined>>
}

const defaultBehaviour: MapContextType = {
	hoveredPin: undefined,
	setHoveredPin: () => { return; }
};

export const MapContext = React.createContext<MapContextType>(defaultBehaviour);

export function MapProvider(props: {
	children: React.ReactNode
}) {
	const [hoveredPin, setHoveredPin] = React.useState<number | number[]>();

	return <MapContext.Provider value={{ hoveredPin, setHoveredPin }}>
		{props.children}
	</MapContext.Provider>;
}