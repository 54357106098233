import { BlueSidely, GreenSidely, RedSidely } from '../../../styles/global/css/Utils';

const colorList = [
	BlueSidely,
	'#0393D0',
	'#0C6836',
	'#12A39C',
	'#12CBC3',
	'#227C9D',
	'#2A9D5F',
	GreenSidely,
	'#4895FF',
	'#4B586A',
	'#5C5CF2',
	'#726680',
	'#72ACFB',
	'#7C7287',
	'#8282C6',
	'#87F16D',
	'#90A0B7',
	'#92572B',
	'#9F9FED',
	'#A0CFD3',
	'#A8B5C7',
	'#AA9A0E',
	'#B6784B',
	'#C0D374',
	'#C6E4E7',
	'#CBCBF4',
	'#CE5A28',
	'#CEA07E',
	'#D2F34E',
	'#DECC24',
	'#E5C0A4',
	'#EA9EA5',
	'#ED383F',
	'#F5767B',
	RedSidely,
	'#F8CACE',
	'#FEA240',
	'#FEC940',
	'#FF814B',
	'#FFEA2C'
];

export function getColorFromList(str: string): string {
	if (!str) return 'none';
	if (str && str.length > 0) {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		const index = hash % colorList.length;
		return colorList[Math.abs(index)];
	} else return '#49B3FE';
}

export default colorList;
