import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import PageLoader from '../../../components/PageLoader';
import Opportunities from './linkedElements/Opportunities';
import Calendar from './linkedElements/Calendar';
import Documents from './linkedElements/Documents';
import Notes from './linkedElements/Notes';
import Emails from '../../companies/components/linkedElements/Emails.tsx';
import noUserImage from 'images/icons/user.svg';
import Overview from './subDetails/Overview';
import Details from './subDetails/Details';
import Activities from './subDetails/Activities';
import LinkedFormdata from './linkedElements/LinkedFormData';
import Restricted from '../../../containers_v2/permissions/Restricted';

class ContactsDetail extends Component {
	state = {
		activeTab: 1,
		activeHeadTab: 1,

		opportunitiesData: [],
		eventsData: [],
		activitiesData: [],
		notesData: [],
		// emailsData: [],
		documentsData: [],
		dataMerchadisings: [],
		dataTradeshows: [],
		linkedData: []
	};

	componentDidMount() {
		const {
			match,
			getOpportunities,
			getEvents,
			getNotes,
			// getEmails,
			getDocuments,
			getActivities,
			getLinkedform,
			getDetailform,
			getAllform,
			getTradeshows,
			getMerchandisings,
			current
		} = this.props;

		getOpportunities({
			limit: 4,
			page: 1,
			contactId: match.params.id
		});

		getActivities(
			{
				limit: 3,
				page: 1
			},
			match.params.id
		);

		getEvents({
			limit: 3,
			page: 1,
			sort: 'desc',
			by: 'startDate',
			contactId: match.params.id
		});

		getNotes({
			limit: 3,
			page: 1,
			sort: 'desc',
			by: 'startDate',
			contactId: match.params.id
		});

		// getEmails({
		//   limit: 3,
		//   page: 1,
		//   user: current,
		//   contactId: match.params.id
		// });

		getDocuments({
			limit: 3,
			page: 1,
			contactId: match.params.id
		});

		getLinkedform({
			page: 1,
			limit: 4,
			link_id: match.params.id,
			link_type: 'contact',
			sort_by: 'updated_at',
			sort: 'DESC'
		});
		getAllform({
			link_id: match.params.id,
			link_type: 'contact'
		});
		getDetailform({ link_id: match.params.id, link_type: 'contact' });
		getTradeshows(`contactId=${match.params.id}`);
		getMerchandisings(`contactId=${match.params.id}`);
	}

	componentWillReceiveProps(nextProps) {
		const { data, salesOperation, detailLinkedForm } = this.props;
		const {
			opportunitiesData,
			eventsData,
			activitiesData,
			notesData,
			// emailsData,
			documentsData,
			dataTradeshows,
			dataMerchadisings,
			linkedData
		} = this.state;

		// Initial Data Loading and see less
		const linkedDetail = nextProps.detailLinkedForm.linkedForm;
		if (nextProps.detailLinkedForm.paginationLinkedForm.currentPage <= 1) {
			this.setState({
				linkedData: linkedDetail
			});
		}
		if (nextProps.data.paginationOpportunities.currentPage <= 1) {
			this.setState({
				opportunitiesData: nextProps.data.opportunities
			});
		}
		if (nextProps.data.paginationEvents.currentPage <= 1) {
			this.setState({
				eventsData: nextProps.data.events
			});
		}
		if (nextProps.data.paginationActivities.currentPage <= 1) {
			this.setState({
				activitiesData: nextProps.data.activities
			});
		}
		if (nextProps.data.paginationNotes.currentPage <= 1) {
			this.setState({
				notesData: nextProps.data.notes
			});
		}
		// if (nextProps.data.paginationEmails.currentPage <= 1) {
		//   this.setState({
		//     emailsData: nextProps.data.emails
		//   });
		// }
		if (nextProps.data.paginationDocuments.currentPage <= 1) {
			this.setState({
				documentsData: nextProps.data.documents
			});
		}

		// on seee more
		if (nextProps.detailLinkedForm.paginationLinkedForm.currentPage > 1) {
			if (detailLinkedForm.linkedForm !== linkedDetail) {
				const dataArray = linkedData;
				linkedDetail.map(data => dataArray.push(data));
				this.setState({
					linkedData: dataArray
				});
			}
		}
		if (nextProps.data.paginationOpportunities.currentPage > 1) {
			if (data.opportunities !== nextProps.data.opportunities) {
				const dataArray = opportunitiesData;
				nextProps.data.opportunities.map(data => dataArray.push(data));

				this.setState({
					opportunitiesData: dataArray
				});
			}
		}

		if (nextProps.data.paginationEvents.currentPage > 1) {
			if (data.events !== nextProps.data.events) {
				const dataArray = eventsData;
				nextProps.data.events.map(data => dataArray.push(data));

				this.setState({
					eventsData: dataArray
				});
			}
		}

		if (nextProps.data.paginationNotes.currentPage > 1) {
			if (data.notes !== nextProps.data.notes) {
				const dataArray = notesData;
				nextProps.data.notes.map(data => dataArray.push(data));

				this.setState({
					notesData: dataArray
				});
			}
		}

		// if (nextProps.data.paginationEmails.currentPage > 1) {
		//   if (data.emails !== nextProps.data.emails) {
		//     const dataArray = emailsData;
		//     nextProps.data.emails.map(data => dataArray.push(data));

		//     this.setState({
		//       emailsData: dataArray
		//     });
		//   }
		// }

		if (nextProps.data.paginationActivities.currentPage > 1) {
			if (data.activities !== nextProps.data.activities) {
				const dataArray = activitiesData;
				nextProps.data.activities.map(data => dataArray.push(data));

				this.setState({
					activitiesData: dataArray
				});
			}
		}

		if (nextProps.data.paginationDocuments.currentPage > 1) {
			if (data.documents !== nextProps.data.documents) {
				const dataArray = documentsData;
				nextProps.data.documents.map(data => dataArray.push(data));

				this.setState({
					documentsData: dataArray
				});
			}
		}

		if (salesOperation !== nextProps.salesOperation) {
			if (nextProps.salesOperation.dataTradeshows.length !== 0) {
				const dataTradeshows = nextProps.salesOperation.dataTradeshows.map(
					data => ({
						id: data.id,
						event: data.tradeshow.event,
						companies: data.tradeshow.clientCompanyName,
						date: data.tradeshow.createdAt,
						type: 'tradeshows'
					})
				);
				this.setState({ dataTradeshows });
			}
		}
		if (
			salesOperation.dataMerchadisings !==
      nextProps.salesOperation.dataMerchadisings
		) {
			if (nextProps.salesOperation.dataMerchadisings.length !== 0) {
				const dataMerchadisings = nextProps.salesOperation.dataMerchadisings.map(
					data => ({
						id: data.id,
						campaign: data.merchandise.campaign,
						companies: data.merchandise.clientCompany.name,
						date: data.merchandise.createdAt,
						type: 'merchandise'
					})
				);
				this.setState({ dataMerchadisings });
			}
		}
		this.setState({
			salesOperations: dataTradeshows.concat(dataMerchadisings)
		});
	}

	onFetchLinkedForm = page => {
		const { getLinkedform, detailLinkedForm, match } = this.props;
		const pagination = detailLinkedForm.paginationLinkedForm;
		getLinkedform({
			page: page || pagination.currentPage + 1,
			limit: 4,
			link_id: match.params.id,
			link_type: 'contact',
			sort_by: 'updated_at',
			sort: 'DESC'
		});
	};

	onFetchOpportunities = page => {
		const { match, getOpportunities, data } = this.props;

		getOpportunities({
			limit: 4,
			page: page || data.paginationOpportunities.currentPage + 1,
			contactId: match.params.id
		});
	};

	onFetchEvents = page => {
		const { match, getEvents, data } = this.props;

		getEvents({
			limit: 3,
			sort: 'desc',
			by: 'startDate',
			page: page || data.paginationEvents.currentPage + 1,
			contactId: match.params.id
		});
	};

	onFetchActivities = page => {
		const { match, getActivities, data } = this.props;

		getActivities(
			{
				limit: 3,
				page: page || data.paginationActivities.currentPage + 1
			},
			match.params.id
		);
	};

	onFetchNotes = page => {
		const { match, getNotes, data } = this.props;

		getNotes({
			limit: 3,
			sort: 'desc',
			by: 'startDate',
			page: page || data.paginationNotes.currentPage + 1,
			contactId: match.params.id
		});
	};

	// onFetchEmails = page => {
	//   const { match, getEmails, data, current } = this.props;

	//   getEmails({
	//     limit: 3,
	//     page: page || data.paginationEmails.currentPage + 1,
	//     user: current,
	//     contactId: match.params.id
	//   });
	// };

	onFetchDocuments = page => {
		const { match, getDocuments, data } = this.props;

		getDocuments({
			limit: 3,
			page: page || data.paginationDocuments.currentPage + 1,
			contactId: match.params.id,
			type: 'document'
		});
	};

	toggle = tab => {
		this.setState({
			activeTab: tab
		});
	};

	toggleHead = tab => {
		this.setState({
			activeHeadTab: tab
		});
	};

	render() {
		const {
			data,
			match,
			current,
			firstName,
			lastName,
			detailLinkedForm,
			linkedFormdata,
			salesOperations
		} = this.props;
		const { dataDetail } = data;
		const { id } = match.params;
		const { activeTab, activeHeadTab } = this.state;
		const pagination = detailLinkedForm.paginationLinkedForm;

		return (
			<Restricted to={{ objectAction: 'ReadContact' }}>
				<div>
					<div className="mx-3 detail-top">
						<Nav className="detail-head-tab d-flex justify-content-end">
							<NavItem>
								<NavLink
									active={activeHeadTab === 1}
									onClick={() => {
										this.toggleHead(1);
									}}
								>
									<h2 className="card-title mb-0">
										<Translate id="overview" />
									</h2>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={activeHeadTab === 2}
									onClick={() => {
										this.toggleHead(2);
									}}
								>
									<h2 className="card-title mb-0">
										<Translate id="details" />
									</h2>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={activeHeadTab === 3}
									onClick={() => {
										this.toggleHead(3);
									}}
								>
									<h2 className="card-title mb-0">
										<Translate id="activity" />
									</h2>
								</NavLink>
							</NavItem>
						</Nav>
						{data.isLoadingDetail ? (
							<div>
								<PageLoader />
							</div>
						) : (
							<div className="px-3 pb-5">
								<div className="row mb-3 detail-head">
									<div className="col-1 text-right d-flex align-items-end justify-content-end">
										{dataDetail.photoUrl ? (
											<img
												src={dataDetail.photoUrl}
												className={
													dataDetail.photoUrl && 'rounded-circle image-thumbnail'
												}
												alt=""
												width="50"
												height="50"
											/>
										) : (
											<div className="no-contact-thumbnail-big d-flex justify-content-center">
												<span className="my-auto">
													{dataDetail.firstName && dataDetail.firstName.charAt(0)}
													{dataDetail.lastName && dataDetail.lastName.charAt(0)}
												</span>
											</div>
										)}
									</div>
									<div className="col-11">
										<h4>
											<strong>
												{dataDetail.firstName || '-'} {dataDetail.lastName || '-'}
											</strong>
										</h4>

										<img
											src={
												(dataDetail &&
                          dataDetail.owner &&
                          dataDetail.owner.photoUrl) ||
                        noUserImage
											}
											className="rounded-circle image-thumbnail"
											width="30"
											height="30"
											alt=""
										/>
										<span>
											{(dataDetail &&
                        dataDetail.owner &&
                        dataDetail.owner.name) ||
                        '-'}
										</span>
									</div>
								</div>
								{activeHeadTab === 1 && <Overview dataDetail={dataDetail} />}
								{activeHeadTab === 2 && <Details dataDetail={dataDetail} />}
								{activeHeadTab === 3 && <Activities dataDetail={dataDetail} />}
							</div>
						)}
					</div>
					<Nav className="text-center d-flex justify-content-start tab-custom m-3">
						<NavItem className="m-auto">
							<NavLink
								to="#"
								active={activeTab === 1}
								onClick={() => {
									this.toggle(1);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="overview" />
								</h2>
							</NavLink>
						</NavItem>
						<Restricted to={{ objectAction: 'ReadEvent' }}>
							<NavItem className="m-auto">
								<NavLink
									to="#"
									active={activeTab === 3}
									onClick={() => {
										this.toggle(3);
									}}
								>
									<h2 className="card-title mb-0">
										<Translate id="event" />
									</h2>
								</NavLink>
							</NavItem>
						</Restricted>
						<Restricted to={{ objectAction: 'ReadOpportunity' }}>
							<NavItem className="m-auto">
								<NavLink
									to="#"
									active={activeTab === 2}
									onClick={() => {
										this.toggle(2);
									}}
								>
									<h2 className="card-title mb-0">
										<Translate id="opportunities" />
									</h2>
								</NavLink>
							</NavItem>
						</Restricted>
						<Restricted to={{ objectAction: 'ReadContactNote' }}>
							<NavItem className="m-auto">
								<NavLink
									to="#"
									active={activeTab === 5}
									onClick={() => {
										this.toggle(5);
									}}
								>
									<h2 className="card-title mb-0">Notes</h2>
								</NavLink>
							</NavItem>
						</Restricted>
						<Restricted to={{ objectAction: 'ReadContactDocument' }}>
							<NavItem className="m-auto">
								<NavLink
									to="#"
									active={activeTab === 6}
									onClick={() => {
										this.toggle(6);
									}}
								>
									<h2 className="card-title mb-0">Documents</h2>
								</NavLink>
							</NavItem>
						</Restricted>
						<NavItem className="m-auto">
							<NavLink
								to="#"
								active={activeTab === 7}
								onClick={() => {
									this.toggle(7);
								}}
							>
								<h2 className="card-title mb-0">Emails</h2>
							</NavLink>
						</NavItem>
						<NavItem className="m-auto">
							<NavLink
								to="#"
								active={activeTab === 8}
								onClick={() => {
									this.toggle(8);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="forms" />
								</h2>
							</NavLink>
						</NavItem>
					</Nav>
					<div className="tab-content">       
						<Restricted to={{ objectAction: 'ReadEvent' }} isSelf>
							<div
								className={
									activeTab === 1 || activeTab === 3
										? 'tab-pane active'
										: 'tab-pane'
								}
							>
								<Calendar
									data={this.state.eventsData}
									id={{
										ownerId: current && current.id,
										contactId: id,
										clientCompanyId: dataDetail && dataDetail.clientCompanyId
									}}
									isFirstPage={data.paginationEvents.currentPage === 1}
									isLastPage={
										data.paginationEvents.currentPage >=
                  data.paginationEvents.lastPage
									}
									count={data.paginationEvents.count}
									isLoading={data.isLoadingEvents}
									onFetch={this.onFetchEvents}
								/>
							</div>
						</Restricted>
						<Restricted to={{ objectAction: 'ReadOpportunity' }}>
							<div
								className={
									activeTab === 1 || activeTab === 2
										? 'tab-pane active'
										: 'tab-pane'
								}
							>
								<Opportunities
									data={this.state.opportunitiesData}
									id={{
										ownerId: current && current.id,
										contactId: id,
										clientCompanyId: dataDetail && dataDetail.clientCompanyId
									}}
									isFirstPage={data.paginationOpportunities.currentPage === 1}
									isLastPage={
										data.paginationOpportunities.currentPage >=
                  data.paginationOpportunities.lastPage
									}
									count={data.paginationOpportunities.count}
									isLoading={data.isLoadingOpportunities}
									onFetch={this.onFetchOpportunities}
								/>
							</div>
						</Restricted>
						<Restricted to={{ objectAction: 'ReadContactNote' }}>
							<div
								className={
									activeTab === 1 || activeTab === 5
										? 'tab-pane active'
										: 'tab-pane'
								}
							>
								<Notes
									data={this.state.notesData}
									count={data.paginationNotes.count}
									id={{
										ownerId: current && current.id,
										contactId: id,
										clientStatusId:
                    dataDetail.clientStatus && dataDetail.clientStatus.id
									}}
									isFirstPage={data.paginationNotes.currentPage === 1}
									isLastPage={
										data.paginationNotes.currentPage >=
                  data.paginationNotes.lastPage
									}
									isLoading={data.isLoadingNotes}
									onFetch={this.onFetchNotes}
								/>
							</div>
						</Restricted>
						<Restricted to={{ objectAction: 'ReadContactDocument' }}>
							<div
								className={
									activeTab === 1 || activeTab === 6
										? 'tab-pane active'
										: 'tab-pane'
								}
							>
								<Documents
									data={this.state.documentsData}
									count={data.paginationDocuments.count}
									id={{
										ownerId: current && current.id,
										contactId: id,
										clientStatusId:
                    dataDetail.clientStatus && dataDetail.clientStatus.id
									}}
									isFirstPage={data.paginationDocuments.currentPage === 1}
									isLastPage={
										data.paginationDocuments.currentPage >=
                  data.paginationDocuments.lastPage
									}
									isLoading={data.isLoadingDocuments}
									onFetch={this.onFetchDocuments}
								/>
							</div>
						</Restricted>
						<div
							className={
								activeTab === 1 || activeTab === 7
									? 'tab-pane active'
									: 'tab-pane'
							}
						>
							<Emails
								isActive={activeTab === 7}
								contact={dataDetail}
								isLoading={data.isLoadingDetail}
								type={'contact'}
							/>
						</div>
						{/* <div
            className={
              activeTab === 1 || activeTab === 7
                ? 'tab-pane active'
                : 'tab-pane'
            }
          >
          <SalesOperations
              data={data.dataDetail && data.dataDetail}
              dataSalesOperation={
                salesOperations !== undefined &&
                salesOperations &&
                salesOperations
              }
              id={{
                ownerId: current && current.id,
                contactId: id,
                clientStatusId:
                  dataDetail.clientStatus && dataDetail.clientStatus.id
              }}
            />
          </div> */}
						<div
							className={
								activeTab === 1 || activeTab === 8
									? 'tab-pane active'
									: 'tab-pane'
							}
						>
							<LinkedFormdata
								data={data.dataDetail && data.dataDetail}
								linkedFormdata={linkedFormdata && linkedFormdata}
								id={{
									ownerId: current && current.id,
									clientCompanyId: id,
									clientStatusId:
                    dataDetail.clientStatus && dataDetail.clientStatus.id
								}}
								isFirstPage={pagination.currentPage === 1}
								isLastPage={pagination.currentPage >= pagination.lastPage}
								linkedData={this.state.linkedData}
								onFetchLinkedForm={this.onFetchLinkedForm}
								{...this.props}
							/>
						</div>
					</div>
				</div>
			</Restricted>
		);
	}
}

export default ContactsDetail;
