import closeIcon from 'images/icon/closeIcon.png';
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'reactstrap-button-loader';
import { Translate } from 'react-localize-redux';
import { renderField, required, validateSpace } from '../../components/FieldModal';
import { createData, detailData, updateData } from '../../store/actions/contacts.action';
import { multipleSelect } from '../../components/Field';
import { detailData as getDetailCompany } from '../../store/actions/companies.action';
import PageLoader from '../../components/PageLoader';
import FieldDropzoneIcon from '../../components/dropzone/FieldDropzoneIcon';
import normalizePhone from '../../helpers/validatePhone';
import SelectInCharge from '../../components/select/SelectAllUser';
import SelectCompany from '../../components/selectCreate/SelectCompany';
import { normalizeCapitalize } from '../../helpers/fieldMask';
import { browserGoBack } from '../../helpers/browserHistory';
import SelectClientStatus from '../../components/select/RenderSelectClientStatus';
import { getAllTag } from '../../store/actions/fieldSettings.action';
import Restricted from '../../containers_v2/permissions/Restricted';
import { LegacyWrapperDropdownSearch } from '../../components_v2/dropdown/DropdownSearch';

const validate = values => {
	const errors = {};
	if (values.password) {
		if (!values.password_confirmation) {
			errors.password_confirmation = 'Required';
		} else if (values.password_confirmation !== values.password) {
			errors.password_confirmation = 'Password mismatched';
		}
	}
	return errors;
};

class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataTag: []
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		const { match, detailData, getAllTag } = this.props;
		getAllTag(true, 2);
		const initId = match.params.id || match.params.idDupe;

		if (initId) {
			detailData(initId);
		}
	}

	componentWillReceiveProps(nextProps) {
		const {
			data,
			current,
			clientCompanyId,
			getDetailCompany,
			detailCompany,
			dispatch,
			match,
			tag,
			ownerId
		} = this.props;

		const initId = match.params.id || match.params.idDupe;

		if (tag.dataTag !== nextProps.tag.dataTag) {
			const dataTag = nextProps.tag.dataTag.map(data => ({
				value: data.id,
				label: data.name && data.name,
				color: data.colorStatus.colorCode || 'black'
			}));
			// eslint-disable-next-line react/no-unused-state
			this.setState({ dataTag });
		}

		// dispatch ownerId only if its null
		if (current !== nextProps.current && !ownerId) {
			dispatch(change('contactForm', 'ownerId', nextProps.current.id));
		}

		if (initId) {
			const initInput = [
				'firstName',
				'lastName',
				'phone',
				'email',
				'position',
				'clientCompanyId',
				'ownerId',
				'clientStatusId'
			];
			if (data.dataDetail !== nextProps.data.dataDetail) {
				initInput.map(data => {
					dispatch(
						change('contactForm', data, nextProps.data.dataDetail[data])
					);
					return null;
				});
				dispatch(
					change('contactForm', 'images', nextProps.data.dataDetail.photoUrl)
				);
				const dataTag = nextProps.data.dataDetail.contactTags.reduce(
					(acc, data) => {
						if (data.tags) {
							acc.push({
								value: data.tagId,
								label: data.tags.name,
								color: data.tags.colorStatus.colorCode
							});
						}
						return acc;
					},
					[]
				);
				dispatch(change('contactForm', 'tagId', dataTag));
			}
		}

		// auto assign status when already selecting company
		if (clientCompanyId !== nextProps.clientCompanyId) {
			if (nextProps.clientCompanyId && !clientCompanyId) {
				getDetailCompany(nextProps.clientCompanyId.value);
			} else if (clientCompanyId && !nextProps.clientCompanyId) {
				dispatch(change('contactForm', 'clientStatusId', null));
			}
		}

		if (detailCompany !== nextProps.detailCompany) {
			dispatch(
				change(
					'contactForm',
					'clientStatusId',
					nextProps.detailCompany
						? nextProps.detailCompany.clientStatusId
						: null
				)
			);
		}
	}

	onSubmit = value => {
		const { createData, updateData, match } = this.props;
		const formData = new FormData();

		const isEdit = match.params.id !== undefined;

		const keys = [
			'firstName',
			'lastName',
			'phone',
			'email',
			'position',
			'clientCompanyId',
			'ownerId',
			'clientStatusId'
		];

		keys.map(key => {
			value[key] && formData.append(key, value[key].value || value[key]);
			return null;
		});

		value.email = value.email ? value.email : null;

		value.tagId &&
      value.tagId.map(key => {
      	value.tagId && formData.append('tagsId[]', key.value || key);
      });

		if (isEdit) {
			value.tagId
				? value.tagId.length === 0 && formData.append('tagsId', [''])
				: formData.append('tagsId', ['']);

			if (typeof value.images !== 'string') {
				formData.append('images', value.images);
			}
			updateData(formData, match.params.id);
		} else {
			formData.append('images', value.images || null);
			createData(formData);
		}
	};

	render() {
		const { handleSubmit, data, clientCompanyId, route } = this.props;
		const language = localStorage.getItem('language');
		const placeholderCompany =
      language !== 'fr' ? 'Pick company' : 'Choisir Société';
		this.state.dataTag.sort((a, b) => {
			if (a.color !== b.color) {
				return a.color > b.color ? 1 : -1;
			} else {
				return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
			}
		});
		let permission = { objectAction: route.path.split('/')[2] == 'edit' ? 'UpdateContact' : 'CreateContact' };

		return (
			(<Restricted to={permission}>
				<div>
					<div className="d-flex background-bar sticky-v2 right-panel">
						<Link to="/contacts" className="pr-3 my-auto">
							<h2 className="card-title mb-0">{route.title}</h2>
						</Link>
						<div className="pl-3 my-auto">
							<h2 className="card-title font-weight-bold mb-0">{route.name}</h2>
						</div>
						<div className="ml-auto my-auto">
							<Button
								className="btn btn-primary btn-save d-flex justify-content-center"
								onClick={handleSubmit(this.onSubmit)}
								loading={data.isLoading}
								spinColor="#003d79"
							>
								<span className="text-white">
									{!data.isLoading && <Translate id="save" />}
								</span>
							</Button>
						</div>
						<div className="ml-3 my-auto">
							<img
								src={closeIcon}
								className="custom-icon-3 my-auto mx-3"
								alt=""
								onClick={() => {
									browserGoBack();
								}}
							/>
						</div>
					</div>

					<div className="p-3">
						{data.isLoadingDetail ? (
							<PageLoader />
						) : (
							<form
								className="forms-sample background-form full-form"
								onSubmit={handleSubmit(this.onSubmit)}
							>
								<Translate>
									{({ translate }) => (
										<div className="row">
											<div className="col-md-12">
												<h2 className="card-title font-weight-bold mb-0">
													{route.detail}
												</h2>
											</div>
											<div className="col-md-1" />
											<div className="col-md-8 mb-5">
												<div className="row">
													<div className="col-md-12">
														<Field
															name="images"
															type="text"
															label={<Translate id="Avatar" />}
															id="inputAvatar"
															component={FieldDropzoneIcon}
														/>
														<SelectInCharge
															name="ownerId"
															label={<Translate id="owner" />}
															requiredStar
															validate={[required]}
															placeholder={translate('pick_owner')}
														/>
														<Field
															name="firstName"
															requiredStar
															type="text"
															component={renderField}
															label={<Translate id="firstname" />}
															placeholder={translate('input firstname')}
															id="inputFirstName"
															validate={[required, validateSpace]}
															normalize={normalizeCapitalize}
														/>
														<Field
															name="lastName"
															requiredStar
															type="text"
															component={renderField}
															label={<Translate id="lastname" />}
															placeholder={translate('input lastname')}
															id="inputLastName"
															validate={[required, validateSpace]}
															normalize={normalizeCapitalize}
														/>
														<Field
															name="clientCompanyId"
															label={<Translate id="company" />}
															component={LegacyWrapperDropdownSearch}
															placeholder={placeholderCompany}
															modalSlug="createCompanySelect"
															initValue={
																(data &&
                                  data.dataDetail &&
                                  data.dataDetail.clientCompany &&
                                  data.dataDetail.clientCompany.name) ||
                                null
															}
														/>
														<SelectClientStatus
															name="clientStatusId"
															label={translate('status')}
															placeholder={translate('Choose Status')}
															disabled={clientCompanyId}
															requiredStar
															validate={[required]}
														/>
														<Field
															name="position"
															type="text"
															component={renderField}
															label={translate('position')}
															placeholder={translate('input position')}
															id="inputPosition"
															normalize={normalizeCapitalize}
														/>
														<Field
															name="phone"
															type="text"
															component={renderField}
															label={<Translate id="phone" />}
															placeholder={translate('input phone')}
															id="inputPhone"
															normalize={normalizePhone}
														/>
														<Field
															name="email"
															type="email"
															component={renderField}
															label="Email"
															id="inputemail"
															placeholder={translate('input email')}
														/>
														<Field
															name="tagId"
															id="tagId"
															component={multipleSelect}
															options={this.state.dataTag}
															label="Tag"
															placeholder={translate('select_tag')}
															closeMenu={false}
															multiple
															clearable
														/>
													</div>
												</div>
											</div>
											<div className="col-md-4" />
										</div>
									)}
								</Translate>
							</form>
						)}
					</div>
				</div>
			</Restricted>)
		);
	}
}

const selector = formValueSelector('contactForm');

const mapStateToProps = state => ({
	data: state.contacts,
	current: state.user.current,
	detailCompany: state.companies.dataDetail,
	clientCompanyId: selector(state, 'clientCompanyId'),
	ownerId: selector(state, 'ownerId'),
	tag: state.fieldSettings
});

export default reduxForm({
	form: 'contactForm',
	validate
})(
	connect(mapStateToProps, {
		createData,
		detailData,
		updateData,
		getDetailCompany,
		getAllTag
	})(ContactForm)
);
