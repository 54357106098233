import styled from 'styled-components';
import AddWhite from 'images/icon/add_white.png';

const Container = styled.div<{ size?: number }>`
    width: ${p => p.size ?? 50}px;
    height: ${p => p.size ?? 50}px;
    position: relative;
    cursor: pointer;
`;

const FileInput = styled.input`
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    opacity: 0;
    cursor: pointer;
`;

const AddBlock = styled.div<{ noBackground?: boolean, backoungroundColor?: string }>`
    width: inherit;
    height: inherit;
    cursor: pointer;
    ${p => p.noBackground
		? ''
		: `background-image: url('${AddWhite}');
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${p.backoungroundColor ?? 'rgb(235, 235, 235)'};
    background-size: 50%;
    `}
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AddBlockFolder = styled(AddBlock)<{ backgroundColor?: string }>`
    background-color: ${p => p.backgroundColor ?? 'rgb(235, 235, 235)'};
    background-image: url('${AddWhite}');
    background-size: 50%;
`;

const AddImage = styled.div<{ src: string }>`
    width: inherit;
    height: inherit;
    cursor: pointer;
    background-image: url('${p => p.src}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
`;

export {
	Container,
	FileInput,
	AddBlock,
	AddImage,
	AddBlockFolder
};
