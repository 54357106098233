import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

// ----------[ StatusFilter ]---------- //
const AglobalEventStatusFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_event_status_filter',
		default: undefined
	})
};

const AorderEventStatusFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_event_status_filter',
		default: undefined
	})
};

const AgalleryEventStatusFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_event_status_filter',
		default: undefined
	})
};

const AcompanyEventStatusFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_event_status_filter',
		default: undefined
	})
};

const AcalendarEventStatusFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_event_status_filter',
		default: undefined
	})
};

const AEventStatusFilter: RecoilState<number | null | undefined> = selector({
	key: 'atom_event_status_filter',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getEventStatusFilterAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getEventStatusFilterAtom(category), newValue);
	}
});

function getEventStatusFilterAtom(category: AtomCategory): RecoilState<number | null | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalEventStatusFilter.atom;
		case AtomCategory.ORDER:
			return AorderEventStatusFilter.atom;
		case AtomCategory.GALLERY:
			return AgalleryEventStatusFilter.atom;
		case AtomCategory.COMPANY:
			return AcompanyEventStatusFilter.atom;
		case AtomCategory.CALENDAR:
			return AcalendarEventStatusFilter.atom;
		default:
			return AglobalEventStatusFilter.atom;
	}
}

export {
	AorderEventStatusFilter,
	AcompanyEventStatusFilter,
	AgalleryEventStatusFilter,
	AglobalEventStatusFilter,
	AEventStatusFilter,
	AcalendarEventStatusFilter
};

// ----------[ TypeFilter ]---------- //
const AglobalEventTypeFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_event_type_filter',
		default: undefined
	})
};

const AorderEventTypeFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_event_type_filter',
		default: undefined
	})
};

const AgalleryEventTypeFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_event_type_filter',
		default: undefined
	})
};

const AcompanyEventTypeFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_event_type_filter',
		default: undefined
	})
};

const AcalendarEventTypeFilter: AtomState<number | null | undefined> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_event_type_filter',
		default: undefined
	})
};

const AEventTypeFilter: RecoilState<number | null | undefined> = selector({
	key: 'atom_event_type_filter',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getEventTypeFilterAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getEventTypeFilterAtom(category), newValue);
	}
});

function getEventTypeFilterAtom(category: AtomCategory): RecoilState<number | null | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalEventTypeFilter.atom;
		case AtomCategory.ORDER:
			return AorderEventTypeFilter.atom;
		case AtomCategory.GALLERY:
			return AgalleryEventTypeFilter.atom;
		case AtomCategory.COMPANY:
			return AcompanyEventTypeFilter.atom;
		case AtomCategory.CALENDAR:
			return AcalendarEventTypeFilter.atom;
		default:
			return AglobalEventTypeFilter.atom;
	}
}

export {
	AorderEventTypeFilter,
	AcompanyEventTypeFilter,
	AgalleryEventTypeFilter,
	AglobalEventTypeFilter,
	AEventTypeFilter,
	AcalendarEventTypeFilter
};
