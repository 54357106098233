import React from 'react';
import {picturePolicyLabel} from '../../../../helpers/data/enrichedForm';
import {getTranslate, Translate} from 'react-localize-redux'
import storeLang from '../../../../helpers/storeLang'

function PicturePolicy(props) {
  const translate = getTranslate(storeLang.getState().localize)
  const { setPicturePolicy, picturePolicy } = props;
  const valField = {
    mandatory: false,
    values: {}
  };
  const setChekedData = id => {
    let valCheked = [...picturePolicy];
    const filter = picturePolicy.find(v => v.shelf_audit_field_id === id);
    if (filter === undefined) {
      valCheked.push({ ...valField, shelf_audit_field_id: id, ordering: id });
    } else {
      valCheked = picturePolicy.filter(v => v.shelf_audit_field_id !== id);
    }
    setPicturePolicy(valCheked);
  };

  return (
    <div>
      <h6 className="font-weight-bold mb-3">
        <Translate id="step" />
        {' '}2 -{' '}
        <Translate id="Pick your Picture policy"/>
      </h6>
      <p>
        <Translate id="Pick wether you wish to make pictures compulsory, at the beginning of..." />
      </p>
      {picturePolicyLabel.map(val => (
        <div className="d-flex">
          <div className="form-group check-sidely my-2">
            <label className="ml-3" key={1}>
              <input
                name={`picture_policy[${val.id}]`}
                id={`picture_policy[${val.id}]`}
                type="checkbox"
                checked={picturePolicy.find(v => v.shelf_audit_field_id === val.id) || false}
                onChange={() => setChekedData(val.id)}
              />
              <div className="checkbox-style" />
            </label>
          </div>
          <small className="ml-4 mt-3 pt-1">{translate(val.label)}</small>
        </div>
      ))}
    </div>
  );
}

export default PicturePolicy;
