/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import RenderSelectInput from '../RenderSelectInputModal';
import {selectOpportunity} from '../../../../store/actions/select.action';

class SelectOpportunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectOpportunity, companyId } = this.props;
    selectOpportunity(null, companyId);
  }

  componentWillReceiveProps(nextProps) {
    const {
      data,
      companyId,
      selectOpportunity,
      thisForm,
      dispatch,
      name
    } = this.props;

    if (companyId !== nextProps.companyId) {
      selectOpportunity(null, nextProps.companyId);
    }

    if (data !== nextProps.data) {
      const opt = [];
      nextProps.data.map(data =>
        opt.push({
          value: data.id,
          label: data.title
        })
      );

      // console.log('input', input);
      // console.log('thisProps', this.props);

      this.setState({ opt });
    }
  }

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      placeholder,
      readOnly
    } = this.props;
    // console.log('incharge', data);
    // console.log('id', name);

    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="select-opportunities"
        placeholder={placeholder}
        validate={validate}
        readOnly={readOnly}
        isClearable
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.opportunity,
  thisForm: state.form.formDataForm
});

export default connect(
  mapStateToProps,
  {
    selectOpportunity
  }
)(SelectOpportunity);
