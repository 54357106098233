import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API } from '../config/keys';
import swal from 'sweetalert2';

const cookies = new Cookies();
const PATH = `${URL_API}/api`;
const token = cookies.get('id_token') || null;

export async function deleteEventsRefresh(data, refresh: () => void) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'delete',
		url: `${PATH}/events`,
		headers: {
			'Cache-Control': 'no-cache',
			'Content-Type': 'application/json'
		},
		data
	}).then(
		() => {
			refresh();
		},
		error => {
			if ('fire' in swal && typeof swal.fire === 'function') {
				swal.fire('Error!', error.response.data.errors, 'error');
			}
		}
	);
}

export async function exportExcel(data, path, type) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return await axios({
		method: 'get',
		responseType: 'blob',
		url: `${PATH}/events/${path}`,
		params: data
	}).then(
		response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', type);
			document.body.appendChild(link);
			link.click();
		},
		error => {
			throw error;
		}
	);
}

export async function importExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'POST',
		url: `${PATH}/events/importExcel`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data
	}).then(
		_ => {
			window.location.href = '/calendar';
		},
		error => {
			if ('fire' in swal && typeof swal.fire === 'function') {
				swal.fire('Error!', error.response.data.errors, 'error');
			}
		}
	)
		.catch(error => {
			throw error;
		}
		);
}

export async function getAllTag(status, type) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'GET',
		url: `${URL_API}/api/tags?status=${status}&type=${type}&by=colorStatusesId&sort=desc`
	});
}

export async function selectInCharge(userId, companyId, role) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	if (role === 'manager' || role === 'owner') {
		return await axios.get(`${URL_API}/api/users/manager/${userId}`);
	} else {
		return await axios.get(`${URL_API}/api/users/company/${companyId}`);
	}
}

export async function getAllProduct() {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'GET',
		url: `${URL_API}/api/products/all`
	});
}
