import { getTranslate } from 'react-localize-redux';
import storeLang from '../helpers/storeLang';
import noImage from 'images/no-image.jpg';

const translate = getTranslate(storeLang.getState().localize);

const truncate = (string, max) => {
	if (string) {
		if (string.length > max) return `${string.substring(0, max)}...`;
		return string;
	}
};

const renderColorProgress = progress => {
	if (progress === 100) {
		return 'progressBar1';
	}
	if (progress > 85) {
		return 'progressBar1';
	}
	if (progress > 75 && progress <= 85) {
		return 'progressBar1';
	}
	if (progress > 70 && progress <= 75) {
		return 'progressBar1';
	}
	if (progress <= 70) {
		return 'progressBar1';
	}
};

const renderColorPercent = num => {
	if (num < 0) {
		return '#FF7669';
	}
	if (num === 0) {
		return '#B7B7B7';
	}
	return '#2ED47A';
};

const renderBgPercent = num => {
	if (num < 0) {
		return 'rgba(255, 0, 0, 0.06)';
	}
	if (num === 0) {
		return 'rgba(183, 183, 183, 0.14)';
	}
	return 'rgba(76, 175, 80, 0.14)';
};

const renderEventStatus = event => {
	if (event === 'to-do') {
		return '#FFB946';
	}
	if (event === 'completed') {
		return '#2ED47A';
	}
	return '#F7685B';
};

const cekType = mode => {
	if (mode === 'companies') {
		return 1;
	}
	if (mode === 'contact') {
		return 2;
	}
	if (mode === 'opportunities') {
		return 3;
	}
	return 4;
};

const setFormatPhone = value => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/(\d{2})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
	return onlyNums;
};

const cekRoleAdmin = role => {
	if (role === 'admin') {
		return true;
	}
	if (role === 'owner') {
		return true;
	}
	return false;
};

const cekRoleOwner = role => {
	if (role === 'owner') {
		return true;
	}
	return false;
};

const cekTranslate = data => {
	const val2 = data.substring(14);
	return val2;
};

export const defaultPagination = () => ({
	currentPage: 1,
	lastPage: 1,
	count: 0,
	recordPerPage: 0
});

const cekTranslateData = (translate, value) => {
	console.log(translate, value);
};

const getDeleteMessage = name => {
	return {
		text: translate('global.fire.delete_message_related_element').toString().replace('{{NAME}}', `'${name}'`),
		confirmButtonText: translate('global.fire.confirm_message').toString()
	};
};
const assignValue = value => value || '-';

export const cekImage = url => {
	if (url === '' || url === null) {
		return noImage;
	}
	return url;
};

export const exportDocuments = (response, name) => {
	const url = window.URL.createObjectURL(new Blob([response]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `${name}.xlsx`);
	document.body.appendChild(link);
	link.click();
};

const valSorting = [
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g',
	'h',
	'i',
	'j',
	'k',
	'l',
	'm',
	'n',
	'o',
	'p',
	'q',
	'r',
	's',
	't',
	'u',
	'v',
	'w',
	'x',
	'y',
	'z'
];

export {
	truncate,
	assignValue,
	renderColorProgress,
	renderColorPercent,
	renderBgPercent,
	renderEventStatus,
	cekType,
	setFormatPhone,
	cekRoleAdmin,
	cekRoleOwner,
	cekTranslate,
	valSorting,
	cekTranslateData,
	getDeleteMessage
};
