export const formatCurrency = input => {
	if (!input) return;
	if (isNaN(parseInt(input[input.length - 1], 10))) {
		if (input.substr(input.length - 1) !== ',') {
			return input.slice(0, -1);
		}
	}

	return input.replace(/ /g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const normalizeCurrency2 = val => {
	if (localStorage.getItem('language') === 'fr') {
		var normalized = val
			.replace(/ /g, '')
			.replace(/\./g, ',')
			.replace(/(?!-)[^0-9,]/g, '');
		if (isNaN(normalized)) {
			if (normalized.split(',').length > 2) { return normalized.replace(/,+$/, ''); }
		}
		return normalized;
	} else {
		var normalized = val
			.replace(/ /g, '')
			.replace(/,/g, '.')
			.replace(/(?!-)[^0-9\.]/g, '');
		if (isNaN(normalized)) {
			if (normalized.split('.').length > 2) { return normalized.replace(/\.+$/, ''); }
		}
		return normalized;
	}
};


export const normalizeCapitalize = val =>
	val && val.charAt(0).toUpperCase() + val.slice(1);