import axios from 'axios';
import { Cookies } from 'react-cookie';
import { TableSortType } from '../../../components_v2/table/Table';
import { URL_FOUNDATION } from '../../../config/keys';
import {
	Assortment,
	DbProduct,
	ExportType,
	Order,
	OrderDb,
	OrderPerStatusParam,
	OrderStatus,
	orderStatusTotalSum,
	TypeFile
} from '../model/Model';

const SUB_PATH_FOUNDATION = 'api/orders';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

// export async function getClientCompanies(): Promise<ClientCompany[]> {
//     try {
//         const result = await axios.get<any>(`${URL_API}/api/clientcompanies?limit=500`)
//         .then(response => {
//             return response.data.data;
//         });
//         result.sort((a, b) => {
//             return a.name.localeCompare(b.name);
//         });
//         return result;
//       } catch (error) {
//           console.error(error);
//           return [];
//       }
// }

// export async function getClientCompany(clientCompanyId: number): Promise<ClientCompany> {
//     try {
//         const result = await axios.get<any>(`${URL_API}/api/products/${clientCompanyId}`)
//             .then(response => {
//                 return response.data.data;
//             });
//         return result;
//     } catch (error) {
//         console.error(error);
//         return {} as ClientCompany;
//     }
// }

// export async function getUser(userId: number): Promise<Owner> {
//     try {
//         const result = await axios.get<any>(`${URL_API}/api/users/id/${userId}`)
//         .then(response => {
//             return response.data.data;
//         });
//         return result;
//     } catch (error) {
//         console.error(error);
//         return {} as Owner;
//     }
// }

export async function getOrders(limit = 0, offset = 0, sort: TableSortType, search = ''): Promise<OrderDb> {
	const extra = {
		limit,
		offset,
		sort_on: sort.id,
		desc: sort.desc,
		search: search.length > 0 ? search : undefined
	};

	try {
		const result = await axios.get<OrderDb>(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}`, { params: extra })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return {} as OrderDb;
	}
}

export async function getOrdersPerStatus(params: OrderPerStatusParam): Promise<OrderDb> {
	try {
		const result = await axios.get<OrderDb>(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}/per-status`, { params })
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return {} as OrderDb;
	}
}

export async function getOrderStatuses(): Promise<OrderStatus[]> {
	try {
		const result = await axios.get<OrderStatus[]>(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}/statuses`)
			.then(response => {
				return response.data;
			});
		return result.sort((a, b) => a.sequence - b.sequence);
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function getOrderStatusesTotalSum(): Promise<orderStatusTotalSum[]> {
	try {
		const result = await axios.get<orderStatusTotalSum[]>(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}/statuses/total-price`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function getAssortments(): Promise<Assortment[]> {
	try {
		const result = await axios.get<Assortment[]>(`${URL_FOUNDATION}/api/assortments`)
			.then(response => {
				return response.data;
			});
		return result.sort((a, b) => a.name.localeCompare(b.name));
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function getProductsByAssortment(id: number, limit = 50, offset = 0): Promise<DbProduct> {
	const extra = `?limit=${limit}&offset=${offset}`;
	try {
		const result = await axios.get<DbProduct>(`${URL_FOUNDATION}/api/assortments/${id}/products${extra}`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { count: 0, products: [] } as DbProduct;
	}
}

export async function createOrderStatus(status: OrderStatus): Promise<boolean> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}/statuses`, status)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function updateOrderStatus(status: OrderStatus): Promise<boolean> {
	try {
		const result = await axios.put(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}/statuses`, status)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function createOrder(order: Order): Promise<boolean> {
	try {
		const result = await axios.post<Order>(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}`, order)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function updateOrder(order: Order): Promise<boolean> {
	try {
		const result = await axios.put(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}`, order)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function deleteOrder(id: number): Promise<boolean> {
	try {
		const result = await axios.delete(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}/${id}`)
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function deleteOrders(idList: number[]): Promise<boolean> {
	const param = {
		orders: idList
	};

	try {
		const result = await axios.delete(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}`, { data: param })
			.then(response => {
				return response.status >= 200 && response.status < 300;
			});
		return result;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function getOrder(id: number): Promise<Order> {
	try {
		const result = await axios.get<Order>(`${URL_FOUNDATION}/${SUB_PATH_FOUNDATION}/${id}`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return {} as Order;
	}
}

export async function exportOrders(fileType: TypeFile, kind: ExportType, orderId?: number): Promise<boolean> {
	const param = {
		output: fileType,
		kind: { type: kind },
		order_id: orderId || null
	};

	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/export`, param);
		return result.status >= 200 && result.status < 300;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function getProducts(limit = 50, offset = 0): Promise<DbProduct> {
	const extra = `?limit=${limit}&offset=${offset}`;

	try {
		const result = await axios.get(`${URL_FOUNDATION}/api/products${extra}`)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return { count: 0, products: [] } as DbProduct;
	}
}

// export async function getContacts(): Promise<Contact[]> {
//     try {
//         const result = await axios.get<any>(`${URL_API}/api/contacts/All`)
//             .then(response => {
//                 return response.data.success ? response.data.data : [];
//             });
//         return result;
//     } catch (error) {
//         console.error(error);
//         return [] as Contact[];
//     }
// }

// export async function getOpportunities(): Promise<Opportunity[]> {
//     try {
//         const result = await axios.get<any>(`${URL_API}/api/opportunities/All`)
//             .then(response => {
//                 return response.data.success ? response.data.data : [];
//             });
//         return result;
//     } catch (error) {
//         console.error(error);
//         return [] as Opportunity[];
//     }
// }

export async function getFilteredCompanies(pattern: string, limit: number | undefined, offset: number | undefined, parent?: boolean) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	let query = '';
	if (limit !== undefined) {
		query += `&limit=${limit}`;
	}
	if (offset !== undefined) {
		query += `&offset=${offset}`;
	}
	if (parent !== undefined) {
		query += `&parent=${parent}`;
	}
	return await axios({
		method: 'GET',
		url: `${URL_FOUNDATION}/api/crm/client-companies/search?pattern=${pattern}${query}`,
		headers: { 'Content-Type': 'multipart/form-data' }
	});
}
