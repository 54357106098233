/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import timeIcon from 'images/icons/timeIcon.svg';

class DateField extends Component {
	state = {};

	render() {
		const {
			input,
			disabled,
			readOnly,
			meta: { touched, error, warning }
		} = this.props;
		const onBlur = () => {
			const blurHandler = input.onBlur;
			if (blurHandler) {
				blurHandler(input.value);
			}
		};
		return (
			<div className="form-group row">
				<DatePicker
					{...input}
					className={`form-control ${touched && error ? 'focus-error' : ''}`}
					popperPlacement="botom"
					popperClassName="custom-popper field-position"
					selected={input.value ? moment(input.value)._d : null}
					dateFormat="HH:mm"
					placeholderText="HH:mm"
					style={{ right: '0px' }}
					showTimeSelectOnly
					readOnly={readOnly}
					disabled={disabled}
					showTimeSelect
					onBlur={onBlur}
					timeFormat="HH:mm"
					timeIntervals={15}
					timeCaption="time"
				/>
				<div className="icon-field">
					<img
						src={timeIcon}
						style={{ width: '16px' }}
						alt=""
					/>
				</div>
				<div className="col-12">
					{touched &&
            ((error && <span className="form-error">{error}</span>) ||
              (warning && <span className="form-error">{warning}</span>))}
				</div>
			</div>
		);
	}
}

export default DateField;
