import closeIcon from 'images/icon/closeIcon.png';
import React from 'react';

function CloseButton(props) {
	return (
		(<div
			className="ml-3 my-auto"
			onClick={() => {
				let path = '/shelf-audits';
				props.history.push(path);
				localStorage.removeItem('enFormTemplate');
			}}
		>
			<img
				src={closeIcon}
				className="custom-icon-3 my-auto mx-3"
			/>
		</div>)
	);
}

export default CloseButton;