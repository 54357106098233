import axios, { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { URL_CERBERUS, URL_FOUNDATION } from '../../config/keys';
import { AuthPolicy, CererusAuthPolicy, PlanPolicy } from './model';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

export async function getGroups() {
	return await axios.get(`${URL_CERBERUS}/api/v1/auth/groups?limit=10&offset=0`);
}

export async function getGroupPolicies(groupId: number): Promise<CererusAuthPolicy[]> {
	return await axios.get(`${URL_CERBERUS}/api/v1/auth/groups/${groupId}/policies`)
		.then(res => res.data)
		.catch(e => {
			console.log(e);
			return [];
		});
}

export async function getUserGroup(groupId: number) {
	return await axios.get(`${URL_CERBERUS}/api/v1/auth/groups/list_users?group_id=${groupId}`);
}

export async function getUsers() {
	return await axios.get(`${URL_FOUNDATION}/api/users`);
}

export async function addUserToGroup(user_id: number, group_id: number) {
	return await axios.post(`${URL_CERBERUS}/api/v1/auth/users/groups`, { user_id, group_id });
}

export async function delUserFromGroup(user_id: number, group_id: number) {
	return await axios.delete(`${URL_CERBERUS}/api/v1/auth/users/groups/${group_id}/${user_id}`);
}

export async function getMembers(limit: number, offset: number) {
	return await axios.get(`${URL_CERBERUS}/api/v1/auth/users?limit=${limit}&offset=${offset}`);
}

export async function getPlanPolicies(): Promise<PlanPolicy[]> {
	return await axios.get(`${URL_CERBERUS}/api/v1/auth/plans/policies`)
		.then(res => res.data)
		.catch(e => {
			console.log(e);
			return [];
		});
}

export async function getAuthPolicies(): Promise<AxiosResponse<AuthPolicy>> {
	return await axios.get(`${URL_CERBERUS}/api/v1/auth/policies`);
}

export async function deletePolicy(policyId: number) {
	return await axios.delete(`${URL_CERBERUS}/api/v1/auth/users/policies/${policyId}`);
}

export async function postPolicy(group_id: number, object_action_id: number, scope_id: number) {
	return await axios.post(`${URL_CERBERUS}/api/v1/auth/users/policies`, { group_id, object_action_id, scope_id });
}

export async function putGroupName(groupId: number, name: string) {
	return await axios.put(`${URL_CERBERUS}/api/v1/auth/groups/${groupId}`, { name });
}

export async function createGroup(body: object) {
	return await axios.post(`${URL_CERBERUS}/api/v1/auth/groups`, body);
}

export async function deleteGroup(groupId: number) {
	return await axios.delete(`${URL_CERBERUS}/api/v1/auth/groups/${groupId}`);
}

export async function updateUser(body: object) {
	return await axios.put(`${URL_FOUNDATION}/api/users`, body);
}

export async function updateGroup(body: object) {
	return await axios.put(`${URL_CERBERUS}/api/v1/auth/groups`, body);
}
