import { URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { Granularity } from 'bindings/time/Granularity';
import { decodeReport, Report } from '../interpretor/bareReportingDecoder';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function getPresenceEvolutionPerProduct(body: {
	granularity: Granularity,
	user_ids: number[];
	end: string;
	begin: string
}): Promise<Report> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.get<ArrayBufferLike>(`${PATH}/v2/reports/product-presence-evolution?end=${body.end}&begin=${body.begin}&granularity=${body.granularity}&user_ids=${body.user_ids.join(',')}`, { responseType: 'arraybuffer' })
		.then(res => {
			const u8Array = new Uint8Array(res.data);
			return decodeReport(u8Array) as Report;
		});
}