/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Translate} from 'react-localize-redux';
import {find, get} from 'lodash';
import TableListingPage from './TableListingPage';
import {bulkDelete, deleteData, exportExcel, fetchData, findLinkedform} from '../../../store/actions/formdata.action';
import {currentData} from '../../../store/actions/user.action';
import {detailData} from '../../../store/actions/formbuilder.action';
import DropdownAction from './DropdownAction';

class FormDataList extends React.Component {
  static columns = props => {
    const { history, getterValue, data } = props;

    const userFormField = get(getterValue, 'user_form_fields', []) || [];

    const exceptColumn = ['section', 'header', 'image', 'table', 'camera'];

    const userField = userFormField.reduce((acc, data) => {
      if (!exceptColumn.includes(data.field_type)) {
        acc.push(data);
      }
      return acc;
    }, []);

    const initColumn = [
      {
        dataField: 'created_at',
        text: <Translate id="createon" />,
        formatter: (cell, row) => (
          <div
            className="table-key"
            onClick={() => {
              history.push(
                `/formBuilder/formData/${row.user_form_id}/detail/${row.id}`
              );
            }}
          >
            {row.created_at
              ? moment(row.created_at || '-').format('DD/MM/YY')
              : '-'}
          </div>
        )
      },
      {
        dataField: 'user_name',
        text: <Translate id="createby" />,
        formatter: (cell, row) => <div>{row.user_name || '-'}</div>
      }
    ];

    const actionColumn = [
      {
        dataField: 'quickAction',
        text: <Translate id="Quick Action" />,
        formatter: (cell, row) => (
          <div>
            <DropdownAction
              id={row.id}
              name="form data"
              path={`formBuilder/formData/${row.user_form_id}`}
              params={{ limit: 10, page: 1, user_form_id: row.user_form_id }}
              count={data.pagination.count}
              permission={row.permission}
              {...props}
            />
          </div>
        )
      }
    ];

    const columnParse = userField.slice(0, 5).reduce((acc, data) => {
      acc.push({
        dataField: data.id,
        text: data.field_name,
        formatter: (cell, row) => {
          const obj = get(row, 'values');
          const findObj = find(obj, { user_form_field_id: data.id });

          const value = get(findObj, 'values');
          if (typeof value === 'object') {
            return <div>{get(value, 'name')}</div>;
          }

          return <div>{String(value)}</div>;
        }
      });
      return acc;
    }, []);

    const columnsFirst = initColumn.concat(columnParse);

    const columns = columnsFirst.concat(actionColumn);

    return columns;
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.detailData(match.params.id);
  }

  render() {
    return (
      <Fragment>
        <div style={{ width: 200 }} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, route) => ({
  data: state.formdata,
  getterValue: state.formbuilder.dataDetail,
  current: state.user,
  subPath: route.route.subPath,
  modal: state.modal,
  translate: state.fieldSettings.modalDelete
});

export default connect(mapStateToProps, {
  fetchData,
  deleteData,
  currentData,
  detailData,
  findLinkedform,
  bulkDelete,
  exportExcel
})(
  TableListingPage({
    name: 'List Data',
    columns: FormDataList.columns
  })(FormDataList)
);
