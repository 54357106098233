import closeIcon from 'images/icon/closeIcon.png';
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { Component, Fragment } from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import Button from 'reactstrap-button-loader';
import { renderField, required, textAreaField } from '../../components/FieldModal';
import { createData, detailData, updateData } from '../../store/actions/opportunities.action';
import { selectContact as getContact, selectOpportunityStatus } from '../../store/actions/select.action';
import { multipleSelect } from '../../components/Field';
import { getAllTag } from '../../store/actions/fieldSettings.action';
import DateField from '../../components/DateField';
import PageLoader from '../../components/PageLoader';
import SelectInCharge from '../../components/select/SelectAllUser';
import SelectStatus from '../../components/select/SelectOpportunityStatus';
import SelectProspectOrigin from '../../components/select/SelectProspectOrigin';
import SelectProbability from '../../components/select/SelectProbability';
import SelectCompany from '../../components/selectCreate/SelectCompany';
import SelectContact from '../../components/selectCreate/SelectContact';
import { normalizeCapitalize, normalizeCurrency2 } from '../../helpers/fieldMask';
import { browserGoBack } from '../../helpers/browserHistory';
import { filter } from 'lodash';
import { LegacyWrapperDropdownSearch } from '../../components_v2/dropdown/DropdownSearch';

const validate = values => {
	const errors = {};
	if (values.password) {
		if (!values.password_confirmation) {
			errors.password_confirmation = 'Required';
		} else if (values.password_confirmation !== values.password) {
			errors.password_confirmation = 'Password mismatched';
		}
	}
	return errors;
};

class CompaniesForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataTag: [],
			dataContact: [],
			listContact: [],
			probability: 0,
			disabledProbability: false
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		const {
			match,
			detailData,
			selectOpportunityStatus,
			getAllTag
		} = this.props;
		getAllTag(true, 3);
		const initId = match.params.id || match.params.idDupe;

		if (initId) {
			detailData(initId);
		} else {
			selectOpportunityStatus(true);
		}
	}

	componentWillReceiveProps(nextProps) {
		const {
			data,
			current,
			dispatch,
			match,
			incharge,
			tag,
			statusOpportunity
		} = this.props;

		const initId = match.params.id || match.params.idDupe;

		if (tag.dataTag !== nextProps.tag.dataTag) {
			const dataTag = nextProps.tag.dataTag.map(data => ({
				value: data.id,
				label: data.name,
				color: data.colorStatus.colorCode || 'black'
			}));
			this.setState({ dataTag });
		}

		if (statusOpportunity !== nextProps.statusOpportunity) {
			if (nextProps.statusOpportunity.length > 0 && !initId) {
				const status = nextProps.statusOpportunity[0];
				dispatch(change('opportunitiesForm', 'opportunityStatusId', status.id));
				dispatch(
					change('opportunitiesForm', 'probability', status.probability)
				);
			}
		}

		if (current !== nextProps.current && !incharge) {
			dispatch(change('opportunitiesForm', 'incharge', nextProps.current.id));
		}

		if (initId) {
			const initInput = [
				'title',
				'description',
				'incharge',
				'clientCompanyId',
				'contactId',
				'amount',
				'prospectOrigin',
				'opportunityStatusId'
			];
			if (data.dataDetail !== nextProps.data.dataDetail) {
				const detail = nextProps.data.dataDetail;
				initInput.map(data => {
					dispatch(change('opportunitiesForm', data, detail[data]));
				});

				this.setState({
					statusOpportunity: detail.opportunityStatus.isPrimary
				});

				if (localStorage.getItem('language') === 'fr' && detail['amount']) {
					dispatch(
						change(
							'opportunitiesForm',
							'amount',
							detail['amount'].replace('.', ',')
						)
					);
				}

				const status = detail.probability !== 0 ? detail.probability : '0';
				dispatch(change('opportunitiesForm', 'probability', status));
				dispatch(
					change(
						'opportunitiesForm',
						'deadline',
						nextProps.data.dataDetail.deadline &&
              moment(nextProps.data.dataDetail.deadline)._d
					)
				);
				const dataTag = nextProps.data.dataDetail.opportunityTags.reduce(
					(acc, data) => {
						if (data.tags) {
							acc.push({
								value: data.tagId,
								label: data.tags.name,
								color: data.tags.colorStatus.colorCode
							});
						}
						return acc;
					},
					[]
				);
				dispatch(change('opportunitiesForm', 'tagId', dataTag));

				const dataContact = nextProps.data.dataDetail.additionalContacts.reduce(
					(acc, data) => {
						if (data.contact) {
							acc.push({
								value: data.contactId,
								label: `${data.contact.firstName} ${data.contact.lastName}`,
								color: '#4c5862'
							});
						}
						return acc;
					},
					[]
				);
				dispatch(
					change('opportunitiesForm', 'additionalContactId', dataContact)
				);
			}
		}
	}

	setContactList = value => {
		const listContact = filter(
			this.state.dataContact,
			o => o.value !== value.value
		);
		this.setState({ listContact });
	};

	getCompanyId = value => {
		this.props.getContact(value.value);
	};

	getProbability = value => {
		const { dispatch } = this.props;
		const val = value.probability !== 0 ? value.probability : '0';
		dispatch(change('opportunitiesForm', 'probability', val));
		this.setState({
			probability: value.probability,
			statusOpportunity: value.isPrimary
		});
	};

	onSubmit = value => {
		const { createData, updateData, match, history } = this.props;
		const formData = new FormData();
		const isEdit = match.params.id !== undefined;

		const keys = [
			'title',
			'description',
			'incharge',
			'clientCompanyId',
			'contactId',
			'deadline',
			'prospectOrigin',
			'opportunityStatusId'
		];

		keys.map(key => {
			value[key] && formData.append(key, value[key].value || value[key]);
		});

		value.tagId &&
      value.tagId.map(key => {
      	value.tagId && formData.append('tagsId[]', key.value || key);
      });

		formData.append(
			'amount',
			value.amount ? value.amount.replace(/,/g, '.') : 0
		);
		formData.append(
			'probability',
			value.probability.value || value.probability || 0
		);

		if (isEdit) {
			value.tagId
				? value.tagId.length === 0 && formData.append('tagsId', [''])
				: formData.append('tagsId', ['']);
			updateData(formData, match.params.id).then(() =>
				history.push(`/opportunities/detail/${match.params.id}`)
			);
		} else {
			createData(formData).then(r =>
				history.push(`/opportunities/detail/${r.data.data.id}`)
			);
		}
	};

	render() {
		const { handleSubmit, data, route, clientCompanyId, match } = this.props;
		const companyId = clientCompanyId
			? typeof clientCompanyId === 'object'
				? clientCompanyId.value
				: clientCompanyId
			: null;
		const lang = localStorage.getItem('language');
		const language = lang !== 'fr' ? 'Pick company' : 'Choisir Société';
		const contactLang = lang !== 'fr' ? 'Pick Contact' : 'Choisir Contact';
		const prospectLang =
      lang !== 'fr'
      	? 'Pick Prospect Origin'
      	: 'Indiquer l\'origine de l\'opportunité';

		return (
			(<div>
				<div className="d-flex background-bar sticky-v2 right-panel">
					<Link to="/opportunities" className="pr-3 my-auto">
						<h2 className="card-title mb-0">
							<Translate id={route.title} />
						</h2>
					</Link>
					<div className="pl-3 my-auto">
						<h2 className="card-title font-weight-bold mb-0">
							<Translate id="new opportunity" />
						</h2>
					</div>
					<div className="ml-auto my-auto">
						<Button
							className="btn btn-primary btn-save d-flex justify-content-center"
							onClick={handleSubmit(this.onSubmit)}
							loading={data.isLoading}
							spinColor="#003d79"
						>
							<span className="text-save">
								{!data.isLoading && <Translate id="save" />}
							</span>
						</Button>
					</div>
					<div className="ml-3 my-auto">
						<img
							src={closeIcon}
							className="custom-icon-3 my-auto mx-3"
							alt=""
							onClick={() => {
								browserGoBack();
							}}
						/>
					</div>
				</div>
				<div className="p-3">
					{data.isLoadingDetail ? (
						<PageLoader />
					) : (
						<form
							className="forms-sample background-form full-form"
							style={{ height: 'auto' }}
						>
							<Translate>
								{({ translate }) => (
									<Fragment>
										<div className="row">
											<div className="col-md-12">
												<h2 className="card-title font-weight-bold mb-0">
													<Translate id="new opportunity" />
												</h2>
											</div>
											<div className="col-md-1" />
											<div className="col-md-8 pb-5">
												<div className="row">
													<div className="col-md-12">
														<Field
															name="title"
															type="text"
															component={renderField}
															label={<Translate id="title" />}
															placeholder={translate('enter title')}
															normalize={normalizeCapitalize}
														/>
														<Field
															name="description"
															type="text"
															component={textAreaField}
															label={<Translate id="description" />}
															placeholder={translate('enter description')}
														/>
														<SelectInCharge
															name="incharge"
															label={<Translate id="owner" />}
															requiredStar
															validate={[required]}
															placeholder={translate('pick_owner')}
														/>
														<Field
															name="clientCompanyId"
															component={LegacyWrapperDropdownSearch}
															label={translate('company')}
															placeholder={language}
															initValue={
																(data &&
                                  data.dataDetail &&
                                  data.dataDetail.clientCompany &&
                                  data.dataDetail.clientCompany.name) ||
                                null
															}
															requiredStar
															onChange={e => this.getCompanyId(e)}
															validate={[required]}
														/>
														<Field
															name="contactId"
															component={SelectContact}
															label="Contact"
															placeholder={contactLang}
															initValue={
																(data &&
                                  data.dataDetail &&
                                  data.dataDetail.contact &&
                                  `${data.dataDetail.contact.firstName} ${data.dataDetail.contact.lastName}`) ||
                                null
															}
															contactId={null}
															id={companyId}
														/>
														<Field
															required
															requiredStar
															name="deadline"
															component={DateField}
															label={<Translate id="deadline" />}
															validate={[required]}
														/>
														<Field
															name="amount"
															type="text"
															component={renderField}
															label={translate('amount')}
															// format={formatCurrency2}
															normalize={normalizeCurrency2}
															placeholder={translate('enter amount')}
														/>
														<SelectProspectOrigin
															name="prospectOrigin"
															label={translate('Prospect Origin')}
															placeholder={prospectLang}
														/>
														<SelectStatus
															name="opportunityStatusId"
															label={translate('status')}
															placeholder={translate('pick_status')}
															requiredStar
															validate={[required]}
															getProbability={this.getProbability}
														/>
														<SelectProbability
															name="probability"
															label={translate('probability')}
															placeholder="Pick Probability"
															readOnly={this.state.statusOpportunity}
														/>
														<Field
															name="tagId"
															id="tagId"
															component={multipleSelect}
															options={this.state.dataTag}
															label="Tag"
															placeholder="Select Tag"
															closeMenu={false}
															multiple
															clearable
														/>
													</div>
												</div>
											</div>
											<div className="col-md-4" />
										</div>
										<div className="col-md-4" />
									</Fragment>
								)}
							</Translate>
						</form>
					)}
				</div>
			</div>)
		);
	}
}

const selector = formValueSelector('opportunitiesForm');

const mapStateToProps = state => ({
	data: state.opportunities,
	current: state.user.current,
	clientCompanyId: selector(state, 'clientCompanyId'),
	statusId: selector(state, 'opportunityStatusId'),
	incharge: selector(state, 'incharge'),
	tag: state.fieldSettings,
	contact: state.select.contact,
	statusOpportunity: state.select.opportunityStatus
});

export default reduxForm({
	form: 'opportunitiesForm',
	validate
})(
	connect(mapStateToProps, {
		createData,
		detailData,
		updateData,
		getAllTag,
		getContact,
		selectOpportunityStatus
	})(CompaniesForm)
);
