import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Dashboard from '../../containers_v2/dashboard/Dashboard';
import { Route } from '../Routes';
import DashboardRouteBlack from 'images/menu_icon/dashboard_black.svg';
import DashboardRouteBlue from 'images/menu_icon/dashboard_blue.svg';
import DashboardRouteWhite from 'images/menu_icon/dashboard_white.svg';

const CalendarRoutes = (): Route[] => [
	{
		component: Dashboard,
		path: '/dashboard-v2',
		name: <Translate id="dashboard" />,
		logoImg: DashboardRouteBlack,
		hoverImg: DashboardRouteBlue,
		activeImg: DashboardRouteWhite,
		permission: ['ViewDashboard'],
		menu: true,
		submenu: false,
		// precedence: 1,
		beta: true,
		admin: false
	}
];

export default CalendarRoutes;
