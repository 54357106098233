import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

export interface AEventEditionType {
  eventId: number
  eventStatusId?: number
  eventTypeId?: number
  title?: string
  start?: Date
  end?: Date
  allDay?: boolean
  companieName?: string
  incharge?: number
  description?: string
  clientCompanyId?: number
  contactId?: number
}

const AGlobalEventEdition: AtomState<AEventEditionType | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_event_edition', // UNIQUE ID
		default: undefined
	})
};

const AEventEdition: RecoilState<AEventEditionType | undefined> = selector({
	key: 'atom_event_edition',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<AEventEditionType | undefined> {
	switch (category) {
		case AtomCategory.GLOBAL:
		default:
			return AGlobalEventEdition.atom;
	}
}

export {
	AGlobalEventEdition,
	AEventEdition
};
