import { Cookies } from 'react-cookie';
import colorList from './ColorList';

const DEFAULT_COLOR = '#49B3FE';
const cookies = new Cookies();
const token = cookies.get('user_color') || null;

function getUserColor(name: string): string {
	if (!name) return 'none';
	// return cookies.get('user_color') || DEFAULT_COLOR;
	if (name && name.length > 0) {
		let total = 0;
		for (let i = 0; i < name.length; i++) {
			total += name.charCodeAt(i);
		}
		const index = total % colorList.length;
		return colorList[index];
	} else return DEFAULT_COLOR;
}

function generateUserColor(): string {
	const index = Math.floor(Math.random() * (colorList.length - 1));
	const color = colorList[index];
	cookies.set('user_color', color);
	return color;
}

function hasUserColor(): boolean {
	return !!cookies.get('user_color');
}

export {
	getUserColor,
	generateUserColor,
	hasUserColor
};
