import Axios from 'axios';
import { URL_API } from '../config/keys';
import { getCurrentUserId } from '../helpers/user';
import { Cookies } from 'react-cookie';

const httpService = Axios.create({
	baseURL: `${URL_API}/api`,
	timeout: 5000
});

httpService.interceptors.request.use(
	function(config) {
		const isUser = getCurrentUserId();

		if (isUser != 0) {
			const cookies = new Cookies();
			const token = cookies.get('id_token') || null;
			config.headers.Authorization = token;
		}

		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

httpService.interceptors.response.use(
	function(config) {
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

export default httpService;
