import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { AEventTypeFilter } from '../../../atoms/filter/eventFiltersAtom';
import { EventType } from '../../../containers_v2/calendar/model/Model';
import { getEventTypes } from './actions';
import { GenericToolbarFilterProps } from '../ToolbarFilter';

export default function EventTypesFilter(props: GenericToolbarFilterProps) {
	const [type, SetType] = useRecoilState(AEventTypeFilter);
	const [label, setLabel] = React.useState<JSX.Element>(<Translate id="all" />);
	const [types, SetTypes] = React.useState<EventType[]>([]);

	React.useEffect(() => {
		getEventTypes()
			.then(res => SetTypes(res.data.data))
			.catch(console.log);
	}, []);

	React.useEffect(() => {
		switch (type) {
			case undefined:
				SetType(null);
			case null:
				return setLabel(<Translate id={'all'} />);
			default:
				return setLabel(<Translate id={`event.${types.find(s => s.id == type)?.name}`} />);
		}
	}, [type, types]);

	if (props.hidden) return <></>;

	return (
		<React.Fragment>
			<UncontrolledButtonDropdown
				direction="down"
				className="form-filter-opportunity d-flex align-items-center"
				style={{ margin: 0 }}
			>
				<small>Type:</small>
				<DropdownToggle className="toggler py-0 px-2" caret>
					{label}
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem
						onClick={() => {
							setLabel(<Translate id="all" />);
							SetType(null);
						}}
					>
						<Translate id="all" />
					</DropdownItem>
			{...types.map(s => <DropdownItem
				key={`dropdownEventTypeFilter[${s.id}]`}
				onClick={() => {
					SetType(s.id);
				}}
			>
				<Translate id={`event.${s.name}`} />
			</DropdownItem>
			)}
				</DropdownMenu>
			</UncontrolledButtonDropdown>
		</React.Fragment>
	);
}
