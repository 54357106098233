import { FilterId, FilterTree } from './model/Model';

export type AdvancedFilterInterpretorOptions<T> = {
	eq?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	ne?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	gt?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	gte?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	lt?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	lte?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	contains?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	contained?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	not_contains?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	not_contained?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	between?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	not_between?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	empty?: (value: T, columnId: FilterId) => boolean
	not_empty?: (value: T, columnId: FilterId) => boolean
	starts_with?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	ends_with?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	tag?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	combinator?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	not_combinator?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
	toclear?: (value: T, compare: string | undefined, columnId: FilterId) => boolean
}

export default function advancedFiltersInterpretor<T>(value: T, tree: FilterTree | undefined, options?: AdvancedFilterInterpretorOptions<T>): boolean {
	if (!tree) return true;
	if ('and' in tree) return tree.and.every(tree => advancedFiltersInterpretor(value, tree, options));
	if ('or' in tree) return tree.or.some(tree => advancedFiltersInterpretor(value, tree, options));
	switch (tree.val.operator) {
		case 'eq': return options?.eq?.(value, tree.val.value, tree.val.column) ?? value == tree.val.value;
		case 'ne': return options?.ne?.(value, tree.val.value, tree.val.column) ?? value != tree.val.value; 
		case 'gt': return options?.gt?.(value, tree.val.value, tree.val.column) ?? (value as number) > Number(tree.val.value);
		case 'gte': return options?.gte?.(value, tree.val.value, tree.val.column) ?? (value as number) >= Number(tree.val.value);
		case 'lt': return options?.lt?.(value, tree.val.value, tree.val.column) ?? (value as number) < Number(tree.val.value);
		case 'lte': return options?.lte?.(value, tree.val.value, tree.val.column) ?? (value as number) <= Number(tree.val.value);
		case 'contains': return options?.contains?.(value, tree.val.value, tree.val.column) ?? false;
		case 'contained': return options?.contained?.(value, tree.val.value, tree.val.column) ?? false;
		case 'not_contains': return options?.not_contains?.(value, tree.val.value, tree.val.column) ?? false;
		case 'not_contained': return options?.not_contained?.(value, tree.val.value, tree.val.column) ?? false;
		case 'between': return options?.between?.(value, tree.val.value, tree.val.column) ?? false;
		case 'not_between': return options?.not_between?.(value, tree.val.value, tree.val.column) ?? false;
		case 'empty': return options?.empty?.(value, tree.val.column) ?? value == undefined;
		case 'not_empty': return options?.not_empty?.(value, tree.val.column) ?? value != undefined;
		case 'starts_with': return options?.starts_with?.(value, tree.val.value, tree.val.column) ?? false;
		case 'ends_with': return options?.ends_with?.(value, tree.val.value, tree.val.column) ?? false;
		case 'tag': return options?.tag?.(value, tree.val.value, tree.val.column) ?? false;
		case 'combinator': return options?.combinator?.(value, tree.val.value, tree.val.column) ?? false;
		case 'not_combinator': return options?.not_combinator?.(value, tree.val.value, tree.val.column) ?? false;
		case 'toclear': return options?.toclear?.(value, tree.val.value, tree.val.column) ?? false;
	}
}