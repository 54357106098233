import { DashboardContext, DashboardItem } from '../Dashboard';
import * as React from 'react';
import { FlexDiv } from '../../products/style';
import { Translate } from '../../../styles/global/translate';
import {
	CreateButton,
	DeleteButton,
	EditButton,
	ScrollDiv,
	WidgetTitle,
	WidgetTitleWrapper
} from '../Widget';
import { TableRow, TableRowTitle } from '../../orders/templateOrders/style/Style';
import { Table } from '../../../components_v2/table/Table';
import { getColorForEvolution } from '../../reports/utils';

interface DashboardTopCompanyByCheckout {
	client_company_id: number,
	name: string,
	current_sum: number,
	previous_sum?: number,
	evolution?: number,
}

export default function TopCompanyByCheckoutWidget(props: {
	value: DashboardTopCompanyByCheckout[],
	item: DashboardItem,
	selected?: boolean,
	onSelect?: (selected: boolean) => void,
	setItem: (item: DashboardItem) => void,
	onCreationClicked?: () => void
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
	isHover?: boolean
}) {
	const { value, item, onCreationClicked, onClickDelete, isHover, onClickEdit } = props;
	const { companyModal,setCompanyModal } = React.useContext(DashboardContext)!;

	const columns = React.useMemo(() => [
		{
			id: 'name',
			Header: <div style={{ width:'170px' }}><Translate id='name'/></div>,
			accessor: (row: DashboardTopCompanyByCheckout) => <TableRowTitle padding='0 0 0 10px'
				onClick={ () => setCompanyModal({ ...companyModal, isOpen: true, data: row.client_company_id })}
			>{row.name}</TableRowTitle>,
			width: 170,
			padding:0
		},
		{
			id: 'previous_sum',
			Header:<Translate id='previous'/>,
			accessor: (row: DashboardTopCompanyByCheckout) => <TableRow padding='0 0 0 10px'>
				{row.previous_sum ? row.previous_sum + '€' : '-'}
			</TableRow>,
			width: undefined,
			padding:0,
		},
		{
			id: 'current_sum',
			Header: <Translate id="current"/>,
			accessor: (row: DashboardTopCompanyByCheckout) => <TableRow padding='0 0 0 10px'>
				{row.current_sum}€
			</TableRow>,
			width: undefined,
			padding:0,
		},
		{
			id: 'evolution',
			Header: <><Translate id="variation"/>.</>,
			accessor: (row: DashboardTopCompanyByCheckout) =>
				<TableRow padding='0 0 0 10px' color={getColorForEvolution(row.evolution ?? null)}>
					{row.evolution && Boolean(row.evolution) && (row.evolution > 0
						? <><span>&#8593;</span>{row.evolution.toFixed(2)}%</>
						: <><span>&#8595;</span>{row.evolution.toFixed(2).slice(1)}%</>
					)}
					{!row.evolution && '= -'}
				</TableRow>,
			width: undefined,
			padding:0,
		},
	], []);


	return <>
		<WidgetTitleWrapper>
			<FlexDiv justify="space-between" align='center' width='100%'>
				<WidgetTitle>
					{item.title ?? <Translate id='dashboard.widget.top_company_by_checkout'/>}
				</WidgetTitle>
				<FlexDiv justify="flex-end" gap="2px">
					{onCreationClicked && <CreateButton onClickCreate={onCreationClicked}/>}
					{isHover && onClickEdit && <EditButton onClickEdit={() => onClickEdit(item)}/>}
					{isHover && onClickDelete && <DeleteButton onClickDelete={() => onClickDelete(item.id as number)}/>}
				</FlexDiv>
			</FlexDiv>
		</WidgetTitleWrapper>
		<ScrollDiv centered>
			<Table height={'100%'} columns={columns} data={value} noBorder EnableResize />
		</ScrollDiv>
	</>;
}
