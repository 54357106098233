import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import storeLang from '../../../helpers/storeLang';
import { LoadingStateEnum } from '../../import/model';
import { getFreeForms } from '../data/action';
import { FreeForm } from '../model/Model';
import { CreationPopupContainer } from '../style/Style';
import { Card, CardImage, CardLeft, CardMiddle, CardTitle } from './LinkedElements/style/LinkedElementStyle';

function NewFreeForms(props: {
  onClickOut?: () => void
  clientCompanyId: number
  clientCompanyName: string
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [freeForms, setFreeForms] = React.useState<FreeForm[]>([]);

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getFreeForms().then(response => {
			setFreeForms(response);
			setLoading(LoadingStateEnum.LOADED);
		});
	}, []);

	function getUrl(id: string): string {
		return `/formBuilder/formData/${id}/create?linkedId=${props.clientCompanyId}&linkedName=${props.clientCompanyName}&type=company`;
	}

	function body(): JSX.Element {
		return (
			<CreationPopupContainer style={{ gap: '10px' }}>
				{
					freeForms.map(f => {
						return (
							<Card isExtend={false} onClick={() => {
								window.open(getUrl(f.id), '_blank')
								;(props.onClickOut != null) && props.onClickOut();
							}}>
								<CardLeft>
									<CardImage url={f.url ?? ''} width='25px' borderRadius='0' backgroundSize='contain' />
								</CardLeft>
								<CardMiddle>
									<CardTitle>{f.name}</CardTitle>
								</CardMiddle>
							</Card>
						);
					})
				}
			</CreationPopupContainer>
		);
	}

	return (
		<PopupCreation
			title={translate('company.creation.free_forms.create_free_forms').toString()}
			content={body()}
			hideValidation
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
		/>
	);
}

export default NewFreeForms;
