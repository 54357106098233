/* eslint-disable react/prefer-stateless-function */
import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => (
  <div className="map-pin">
    <i className="fas fa map-marker-alt" />
    {/* <span>{text}</span> */}
  </div>
);

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 48.8566,
      lng: 2.3522
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      (<div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBkQkAoGTXdwr-MrDWQYQPt0zvAw57T7p0' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent lat={48.8566} lng={2.3522} text="90" />
        </GoogleMapReact>
      </div>)
    );
  }
}

export default SimpleMap;
