import { MapCompany } from '../../containers_v2/map/model';

export function filterAdditionalColumns(c: MapCompany, hiddableAdditionalColumns: Set<number>): boolean {
	for (const n of Array.from(hiddableAdditionalColumns)) {
		const x = c.additionalColumnsValue?.[n];
		if (x === undefined || x === null || x?.value === 'empty') return false;
	}
	return true;
}

export function filterAdditionalFieldColumns(c: MapCompany, hiddableAdditionalFieldColumns: Set<number>): boolean {
	for (const n of Array.from(hiddableAdditionalFieldColumns)) {
		const x = c.additionalFieldColumnsValue?.[n];
		if (x === undefined || x === null) return false;
	}
	return true;
}

export function filterCalculatedFieldColumns(c: MapCompany, hiddableCalculatedFieldColumns: Set<number>): boolean {
	for (const n of Array.from(hiddableCalculatedFieldColumns)) {
		const x = c.calculatedFieldColumnsValue?.[n];
		if (x === undefined || x === null) return false;
	}
	return true;
}