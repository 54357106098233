import styled from 'styled-components';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { DefaultImage } from '../../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../../styles/global/css/GlobalText';
import { DarkGreySidely2, SidelyBlack } from '../../../../styles/global/css/Utils';

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const ContainerLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    max-width: 75%;
`;

const ContainerProducts = styled.div`
    height: calc(100vh - 150px);
    overflow-y: auto;

    table {
        width: 100%;
        border-spacing: 0 15px;
        border-collapse: separate;
    }
    td {
        background-color: #FFFFFF;
    }
    th, td {
        padding: 10px 20px;
    }
    td:last-child {
        width: 1%;
        text-align: center;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }
    td:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }
`;

interface CardRowProps {
  justifyContent?: string
}

const CardRow = styled.div<CardRowProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    justify-content: ${p => p.justifyContent ? p.justifyContent : 'space-between'};
`;

const CardImage = styled(DefaultImage)`
    width: ${p => p.width ? p.width : '30px'};
    height: ${p => p.height ? p.height : '30px'};
`;

const ContainerShoppingCart = styled.div`
    flex-grow: 2;
    padding: 5px;
    width: 30vw;
    max-width: 30vw;
`;

const ShoppingCart = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 10px;
`;

const ShoppingCartEmpty = styled.p`
    ${DefaultText}
`;

const Separator = styled.hr`
    width: 90%;
    border: 1px solid #D5D5D5;
    background-color: #D5D5D5;
`;

const ShoppingCartText = styled.h3`
    ${DefaultText}
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: ${SidelyBlack};
`;

const ReturnLink = styled.h5`
    ${DefaultText}
    width: 30%;
    cursor: pointer;
    margin-left: 2%;
    margin-top: 1%;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
`;

const Button = styled(DefaultButton)`
    width: 130px;
    height: 42px;
`;

interface textProps {
  isBold?: boolean
  Height?: string
  fontSize?: string
}

const Text = styled.p<textProps>`
    ${DefaultText}
    font-weight: ${p => p.isBold ? '400' : '300'};
    font-size: ${p => p.fontSize ? p.fontSize : '14px'};
    line-height: 21px;
    color: ${p => p.isBold ? SidelyBlack : DarkGreySidely2};
    height: ${p => p.Height ? p.Height : 'auto'};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export {
	Container,
	ContainerProducts,
	CardRow,
	CardImage,
	ContainerShoppingCart,
	ShoppingCart,
	ShoppingCartEmpty,
	Separator,
	ShoppingCartText,
	ReturnLink,
	ContainerLeft,
	Button,
	Text
};
