import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const FETCH_BRAND_REQUEST = 'FETCH_BRAND_REQUEST';
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_ERROR = 'FETCH_BRAND_ERROR';

export const DETAIL_BRAND_REQUEST = 'DETAIL_BRAND_REQUEST';
export const DETAIL_BRAND_SUCCESS = 'DETAIL_BRAND_SUCCESS';
export const DETAIL_BRAND_ERROR = 'DETAIL_BRAND_ERROR';

export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_ERROR = 'CREATE_BRAND_ERROR';

export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';

export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR';

export const SEARCH_BRAND = 'SEARCH_BRAND';
export const SORT_BRAND = 'SORT_BRAND';

const PATH = `${URL_API}/api`;

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_BRAND_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/brands`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_BRAND_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_BRAND_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_BRAND_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_BRAND_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/brands/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_BRAND_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_BRAND_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_BRAND_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteData(id, isRedirect) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_BRAND_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/brands/${id}`
		}).then(
			() => {
				if (isRedirect) {
					window.location.href = '/settings#brand_management';
				} else {
					dispatch(fetchData());
				}
			},
			error => {
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_BRAND_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/brands`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_BRAND_SUCCESS,
						payload: response.data
					});

					window.location.href = '/settings#brand_management';
				},
				error => {
					dispatch({
						type: CREATE_BRAND_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_BRAND_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_BRAND_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/brands/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_BRAND_SUCCESS,
					payload: response.data
				});
				window.location.href = '/settings#brand_management';
			},
			error => {
				dispatch({
					type: UPDATE_BRAND_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export const searchData = data => async dispatch => {
	dispatch({
		type: SEARCH_BRAND,
		payload: data
	});
};

export const sortData = data => async dispatch => {
	dispatch({
		type: SORT_BRAND,
		payload: data
	});
};
