import axios from 'axios';
import { Cookies } from 'react-cookie';
import { UpdatedField } from '../../../../web-types/api';
import { URL_FOUNDATION } from '../../config/keys';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function postBrand(body: { name: string, description?: string }): Promise<number> {
	return axios.post(`${URL_FOUNDATION}/api/brands`, body).then(res => res.data);
}

export async function putBrand(brandId: number, body: { name: string, description?: UpdatedField<string> }): Promise<null> {
	return axios.put(`${URL_FOUNDATION}/api/brands/${brandId}`, body).then(res => res.data);
}