import moment from 'moment';
import Pin1 from 'images/pins_v2/events/range_1.svg';
import Pin2 from 'images/pins_v2/events/range_2.svg';
import Pin3 from 'images/pins_v2/events/range_3.svg';
import Pin4 from 'images/pins_v2/events/range_4.svg';
import Pin5 from 'images/pins_v2/events/range_5.svg';
import Pin6 from 'images/pins_v2/events/range_6.svg';
import Pin7 from 'images/pins_v2/events/range_7.svg';
import Pin8 from 'images/pins_v2/events/range_8.svg';
import PinPlanned from 'images/pins_v2/events/planned.svg';
import PinNotCompleted from 'images/pins_v2/events/not_completed.svg';
import { filterAdditionalColumns, filterAdditionalFieldColumns, filterCalculatedFieldColumns } from './map_filter';

export const getPinLegend = color => {
	switch (color) {
		case '#FFFFFF':
			return Pin1;
		case '#FFF8A9':
			return Pin2;
		case '#FFEF64':
			return Pin3;
		case '#FFE500':
			return Pin4;
		case '#FF7A00':
			return Pin5;
		case '#FF4D00':
			return Pin6;
		case '#F61300':
			return Pin7;
		case '#6D0900':
			return Pin8;
		case '#11689E':
			return PinPlanned;
		default:
			return PinNotCompleted;
	}
};

export const labelPin = [
	{
		label: 'Companies with no completed events',
		color: '#83CEEE'
	},
	{
		label: 'Companies with no completed events but planned events',
		color: '#11689E'
	}
];


export const hideCompanies = (company, withPlan) => {
	if (!withPlan) {
		if (company.range) {
			return company;
		}
	} else {
		return company && !(company.next && company.range === undefined);
	}
};

export const hideRange = (company, range) => {
	if (!company.range || isNaN(range) || company.range > range) {
		return company;
	}
};

export const checkStatusCompany = (clientStatusIds, company) => {
	if (clientStatusIds.length > 0) {
		return clientStatusIds.find(id => id == company.clientStatusId);
	}
	return company;
};

export const hideRangeNext = (company, range) => {
	if (company.nextRange === null || !(company.nextRange > -range.firstRange && company.nextRange < -range.lastRange)) {
		return company;
	}
};

export const checkRangeLastCompleted = (company, events) => {
	let range = company.range || null;
	if (events[0] !== undefined) {
		const firstEvent = moment(events[0][1]).format('YYYY-MM-DD');
		const today = moment().format('YYYY-MM-DD');
		if (firstEvent <= today) {
			range = moment(today).diff(moment(firstEvent), 'days');
		}
	}
	return { ...company, range, events };
};

export const checkRangeNextEvent = (company, nextEvent) => {
	let nextRange = company.nextRange || null;
	if (nextEvent[0] !== undefined) {
		const firstEvent =
			nextEvent[0] !== undefined &&
			moment(nextEvent[0][2]).format('YYYY-MM-DD');
		const today = moment().format('YYYY-MM-DD');
		if (today <= firstEvent) {
			nextRange = moment(firstEvent).diff(moment(today), 'days');
		}
	}
	return { ...company, nextRange, nextEvent };
};

export const hideNoShelfAudit = (company) => {
	return company.distribution && company.distribution.length >= 7;
};

export const hideShelfAuditDate = (company, date) => {
	if (!company.lastShelfAuditDate?.seconds) return true;
	const momentRange = moment().diff(moment(company.lastShelfAuditDate.seconds * 1000), 'days');
	return momentRange < date;
};

export const filterPostcodes = (company, postcodes) => {
	return company.postCode && postcodes.includes(company.postCode.trim().toLowerCase());
};

export const filterCities = (company, cities) => {
	return company.city && cities.includes(company.city.trim().toLowerCase());
};

export const filterCountries = (company, countries) => {
	return company.country && countries.includes(company.country.trim().toLowerCase());
};

export const allEventId = (events, eventSelected) => {
	return events.filter(e => eventSelected.includes(e[0]) && e);
};

function fiterByTags(tags, company) {
	return tags.some(x => x.every(z => company.tags?.includes(parseInt(z))));
}

function filterCheckoutSlider(company, sliderValue) {
	if (company.checkoutEvolution?.evolution === undefined) return true;
	if (sliderValue[0] * 100 > company.checkoutEvolution.evolution) return false;
	if (sliderValue[1] >= 7) return true;

	return sliderValue[1] * 100 >= company.checkoutEvolution.evolution; 
}

function filterFrequencySlider(company, sliderValue, eventType) {
	if (!company?.frequencies?.[eventType]) return true;
	if (sliderValue[0] > company.frequencies[eventType].score) return false;
	if (sliderValue[1] == 7) return true;

	return sliderValue[1] >= company.frequencies[eventType].score;
}

const filterOwner = (selectedUsers, owners, ownerId) => {
	if (!ownerId) return selectedUsers?.[1] ?? false;
	if (!selectedUsers?.[0].includes(ownerId) && selectedUsers?.[1] && !owners?.some(o => o.id === ownerId)) return true;
	return selectedUsers ? selectedUsers[0].includes(ownerId) : true;
};

export const checkByAllParams2 = (companies, params, eventType) => {
	if (params) {
		if (params.tags) {
			const obj = params.tags.reduce((acc, e) => {
				if (e === 'or') {
					acc.a.push(acc.b);
					acc.b = [];
				} else if (e !== 'and') {
					acc.b.push(e);
				}
				return acc;
			}, { a: [], b: [] });
			obj.a.push(obj.b);
		}
		return companies
			.filter(c => filterOwner(params.selectedUsers, params.allUsers, c.ownerId))
			.filter(c => (params.tags ? fiterByTags(obj.a, c) : c))
			.filter(c =>
				params.groupId ? params.groupId.find(id => id == c.ownerId) : c
			)
			.filter(c => params.searchInput ? c.name?.toLowerCase().includes(params.searchInput.toLowerCase()) : c)
			.filter(c =>
				params.clientStatusId
					? checkStatusCompany(params.clientStatusId, c)
					: c
			)
			.filter(c => (params.plan ? hideCompanies(c, true) : c))
			.filter(c => (params.noPlan ? hideCompanies(c, false) : c))
			.filter(c => {
				if (params.planEvent && params.planEvent.length > 0) {
					const filterEvents = allEventId(c.nextEvent, params.planEvent);
					return c.nextEvent.length > 0
						? filterEvents.length !== 0 && checkRangeNextEvent(c, filterEvents)
						: c;
				}
				return c;
			})
			.filter(c => {
				if (params.hideNext) {
					return c.next;
				}
				return c;
			})
			.filter(c => {
				if (
					c.nextRange !== null &&
					params.nextEventType &&
					params.nextEventType.length > 0
				) {
					if (c.nextEvent == undefined) return c;
					const filterEvents = allEventId(c.nextEvent, params.nextEventType);
					return c.nextEvent.length > 0
						? filterEvents.length > 0 &&
						checkRangeLastCompleted(c, filterEvents)
						: c;
				}
				return c;
			})
			.filter(c => {
				if (
					c.range !== null &&
					params.eventType &&
					params.eventType.length > 0
				) {
					if (c.events == undefined) return c;
					const filterEvents = allEventId(c.events, params.eventType);
					return c.events.length > 0
						? filterEvents.length > 0 &&
						checkRangeLastCompleted(c, filterEvents)
						: c;
				}
				return c;
			})
			.filter(c =>
				params.hideRangeLast
					? params.last
						? hideRange(c, params.last)
						: c
					: c
			)
			.filter(c =>
				params.hideRangeNext
					? params.next
						? hideRangeNext(c, params.next)
						: c
					: c
			)
			.filter(c =>
				params.hideShelfAudit
					? hideNoShelfAudit(c)
					: true
			)
			.filter(c => {
				let postcodes_ok = true;
				let cities_ok = true;
				let countries_ok = true;
				if (params.postcodes && params.postcodes.length > 0) {
					cities_ok = false;
					countries_ok = false;
				}
				if (params.cities && params.cities.length > 0) {
					postcodes_ok = false;
					countries_ok = false;
				}
				if (params.countries && params.countries.length > 0) {
					postcodes_ok = false;
					cities_ok = false;
				}
				if (params.postcodes && params.postcodes.length > 0) {
					postcodes_ok = filterPostcodes(c, params.postcodes);
				}
				if (params.cities && params.cities.length > 0) {
					cities_ok = filterCities(c, params.cities);
				}
				if (params.countries && params.countries.length > 0) {
					countries_ok = filterCountries(c, params.countries);
				}
				return (postcodes_ok || cities_ok || countries_ok);
			})
			.filter(c => {
				let r = true;
				if (params.pieFilter && c.distribution && c.distribution.length >= 7) {
					const pieFilter = params.pieFilter;
					Object.keys(pieFilter).forEach((key) => {
						const value = c.distribution[pieFilter[key].index];
						const pieValue = pieFilter[key].value;
						if (value < pieValue[0] || pieValue[1] < value) {
							r = false;
						}
					});
				}
				return r;
			})
			.filter(c =>
				params.hideShelfAuditDate ? hideShelfAuditDate(c, params.hideShelfAuditDate) : true
			)
			.filter(c => params.hideOrders ? c.lastOrderDate : true)
			.filter(c => params.hideCheckouts ? c.checkoutEvolution?.evolution !== undefined : true)
			.filter(c => filterAdditionalColumns(c, params.hideAdditionalColumns))
			.filter(c => filterAdditionalFieldColumns(c, params.hideAdditionalFieldColumns))
			.filter(c => filterCalculatedFieldColumns(c, params.hideCalculatedFieldColumns))
			.filter(c => params.hideOrdersRange?.[0] && c.orderRange !== undefined && !isNaN(params.hideOrdersRange?.[1] ?? NaN) ? c.orderRange > params.hideOrdersRange?.[1] ?? 0 : true)
			.filter(c => !c.orderStatusId || (params?.orderStatuses?.includes(c.orderStatusId) ?? true))
			.filter(c => params.hideFrequencies ? c.frequencies?.[eventType] : true)
			.filter(c => params.frequencySlider ? filterFrequencySlider(c, params.frequencySlider, eventType) : true)
			.filter(c => params.checkoutSlider ? filterCheckoutSlider(c, params.checkoutSlider) : true);
	}
	return companies;
};

export const getPinColor = value => {
	if (value <= 12.5) {
		return '#FFFFFF';
	}
	if (value <= 25) {
		return '#FFF8A9';
	}
	if (value <= 37.5) {
		return '#FFEF64';
	}
	if (value <= 50) {
		return '#FFE500';
	}
	if (value <= 62.5) {
		return '#FF7A00';
	}
	if (value <= 75) {
		return '#FF4D00';
	}
	if (value <= 87.5) {
		return '#F61300';
	}
	return '#6D0900';
};