import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';
import AdvancedFilters from '../../components_v2/filter/AdvancedFilters';
import { FilterId, filterTreeLength } from '../../components_v2/filter/model/Model';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import Pagination from '../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { Table, TableSortType } from '../../components_v2/table/Table';
import ToolbarFilterButton from '../../components_v2/toolbarFilter/ToolbarButton';
import { ToolBarView } from '../../components_v2/view/ToolBarView';
import { translateToString } from '../../styles/global/translate';
import { useFunctionState } from '../../utils/customHooks';
import { FilterState } from '../contact/Contacts';
import { TableContext, TableProvider } from '../contact/data/ContactContext';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../import/model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { Unhautorized } from '../noData/NoData';
import Restricted from '../permissions/Restricted';
import CheckoutColumns from './CheckoutsColumns';
import { getCheckoutFilters, getCheckouts } from './actions';

const DEFAULT_STEP = 25;

const CHECKOUT_VIEW_LOCAL_STORAGE = 'checkout_view_id';
export const CHECKOUT_HIDDEN_LOCAL_STORAGE = 'checkout_hidden_columns';
export const CHECKOUT_SORT_LOCAL_STORAGE = 'checkout_sort_columns';

export type Checkout = {
	count: number,
    id: number,
    date: string,
	client_company_id: number,
    client_company_name: string,
    product_id: number,
    product_name: string,
    unit?: string,
    product_unit_number?: number,
    consumer_sales_unit?: number,
    volumetric_quantities?: number,
    sum_with_tax?: number,
    sum_without_tax?: number,
    cumulative_consumer_sales_unit?: number,
    cumulative_volumetric_quantities?: number,
    cumulative_sum_with_tax?: number,
    cumulative_sum_without_tax?: number,
    average_price?: number,
    average_price_evolution?: number,
    created_by: number,
    created_at: string,
	updated_by?: number,
    updated_at?: string,
}

export default function Checkouts(props: ComponentProps) {
	return <TableProvider>
		<_Checkouts {...props} />
	</TableProvider>;
}

function _Checkouts(props: ComponentProps) {
	const [checkouts, setCheckouts] = React.useState<Checkout[]>([]);
	const context = React.useContext(TableContext);
	const { filterResult, hiddenColumns, sortColumns, setFilterResult } = context;
	const users = useRecoilValue(AUsers);
	const [sort, setSort] = React.useState<TableSortType | undefined>({ id: 'created_at', desc: true });
	const [pagination, setPagination] = React.useState<PaginationResult>();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [tableResize, setTableResize] = useFunctionState<{ [key: string]: number }>(
		JSON.parse(localStorage.getItem('table_checkout_resize') || '{}'),
		({ newValue }) => {
			localStorage.setItem('table_checkout_resize', JSON.stringify(newValue));
			return newValue;
		}
	);
	// FILTERS
	const [filterState, setFilterState] = React.useState<FilterState>({});
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const onClickFilter = React.useCallback((selectedId: FilterId) => {
		setFilterState({
			selectedId,
			openSummary: false,
			filterOpen: true
		});
	}, []);

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('checkouts'),
			bottomLeftToolbarComponent: <ToolbarBox>
				<ToolBarView
					localStorageKey={CHECKOUT_VIEW_LOCAL_STORAGE}
					localStorageHiddenKey={CHECKOUT_HIDDEN_LOCAL_STORAGE}
					viewsKey='checkouts'
					context={context}
				>
					<ToolbarFilterButton
						activeFilters={filterTreeLength(filterResult?.formatted)}
						onClick={() => setFilterState(state => ({ ...state, openSummary: true, filterOpen: true }))}
						onDeleteFilter={() => {
							setFilterResult({ values: { combinator: 'and', array: [] }, formatted: undefined });
						}}
					/>
				</ToolBarView>
			</ToolbarBox>
		});
	}, [filterResult?.formatted, hiddenColumns, sortColumns]);

	React.useEffect(() => {
		getCheckoutFilters().then(setFilterParams);
	}, []);

	React.useEffect(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		getCheckouts({
			limit: pagination?.step ?? DEFAULT_STEP,
			offset: pagination?.offset ?? 0,
			order_by: sort?.id,
			descending: sort?.desc,
			filters: filterResult?.formatted,
		}).then(res => {
			setCheckouts(res);
			setLoadingState(LoadingStateEnum.LOADED);
		}).catch(() => setLoadingState(LoadingStateEnum.ERROR));
	}, [sort, pagination, filterResult?.formatted]);

	const columns = React.useMemo(() => CheckoutColumns(users), [sortColumns, users]);

	return <Restricted to={{ objectAction: 'ReadCheckout' }} fallback={<Unhautorized />}>
		<Table
			height='calc(100vh - 174px)'
			columns={columns}
			data={checkouts}
			hiddenColumns={hiddenColumns}
			sortColumns={sortColumns}
			onSort={React.useCallback((sort: TableSortType) => setSort(sort[0]), [])}
			EnableResize
			initialSortBy={sort}
			onClickFilter={onClickFilter}
			onResize={(value) => Object.keys(value).length > 0 && setTableResize(value)}
			resizeValue={tableResize}
		/>
		<Pagination
			label={translateToString('checkouts')}
			amount={Number(checkouts[0]?.count ?? 0)}
			currentCount={checkouts.length}
			steps={[DEFAULT_STEP, 50, 100]}
			onChange={setPagination}
		/>
		<AdvancedFilters
			// permission='ReadContact'
			isOpen={filterState.filterOpen ?? false}
			setOpen={filterOpen => setFilterState({ filterOpen })}
			isOpenSummary={filterState.openSummary ?? false}
			filterList={filterParams}
			filterValues={filterResult?.values}
			onChange={(value) => setFilterResult(value)}
			selectedFilter={filterState.selectedId}
			// tagType={TagType.CONTACT}
		/>
		<ComponentLoader loadingState={loadingState} allScreen noBackground/>
	</Restricted>;
}
