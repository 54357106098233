import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API, URL_FOUNDATION } from '../../config/keys';
import { logoutUser } from './auth.action';
import { handleErrorImport } from '../../helpers/error';
import { modalErrorValidate } from './modal.action';

const cookies = new Cookies();

export const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_ERROR = 'FETCH_CONTACT_ERROR';

export const DETAIL_CONTACT_REQUEST = 'DETAIL_CONTACT_REQUEST';
export const DETAIL_CONTACT_SUCCESS = 'DETAIL_CONTACT_SUCCESS';
export const DETAIL_CONTACT_ERROR = 'DETAIL_CONTACT_ERROR';

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR';

export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const SORT_DATA = 'SORT_DATA';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

// CREATE LINKED ELEMENTS

export const CREATE_OPPORTUNITY_REQUEST = 'CREATE_OPPORTUNITY_REQUEST';
export const CREATE_OPPORTUNITY_SUCCESS = 'CREATE_OPPORTUNITY_SUCCESS';
export const CREATE_OPPORTUNITY_ERROR = 'CREATE_OPPORTUNITY_ERROR';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';

export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

export const CREATE_CONTACT_FILE_REQUEST = 'CREATE_CONTACT_FILE_REQUEST';
export const CREATE_CONTACT_FILE_SUCCESS = 'CREATE_CONTACT_FILE_SUCCESS';
export const CREATE_CONTACT_FILE_ERROR = 'CREATE_CONTACT_FILE_ERROR';

// GET LINKED ELEMENTS

export const GET_OPPORTUNITIES_REQUEST = 'GET_OPPORTUNITIES_REQUEST';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';
export const GET_OPPORTUNITIES_ERROR = 'GET_OPPORTUNITIES_ERROR';

export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_ERROR = 'GET_ACTIVITIES_ERROR';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const GET_EVENT_DETAIL_REQUEST = 'GET_EVENT_DETAIL_REQUEST';
export const GET_EVENT_DETAIL_SUCCESS = 'GET_EVENT_DETAIL_SUCCESS';
export const GET_EVENT_DETAIL_ERROR = 'GET_EVENT_DETAIL_ERROR';

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_ERROR = 'GET_NOTES_ERROR';

export const GET_NOTE_DETAIL_REQUEST = 'GET_NOTE_DETAIL_REQUEST';
export const GET_NOTE_DETAIL_SUCCESS = 'GET_NOTE_DETAIL_SUCCESS';
export const GET_NOTE_DETAIL_ERROR = 'GET_NOTE_DETAIL_ERROR';

export const GET_EMAILS_REQUEST = 'GET_EMAILS_REQUEST';
export const GET_EMAILS_SUCCESS = 'GET_EMAILS_SUCCESS';
export const GET_EMAILS_ERROR = 'GET_EMAILS_ERROR';

export const GET_EMAIL_DETAIL_REQUEST = 'GET_EMAIL_DETAIL_REQUEST';
export const GET_EMAIL_DETAIL_SUCCESS = 'GET_EMAIL_DETAIL_SUCCESS';
export const GET_EMAIL_DETAIL_ERROR = 'GET_EMAIL_DETAIL_ERROR';

export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';

// MODIFY LINKED ELEMENTS
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

export const DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR';

export const DELETE_EMAIL_REQUEST = 'DELETE_EMAIL_REQUEST';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_ERROR = 'DELETE_EMAIL_ERROR';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

const PATH = `${URL_API}/api`;

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_CONTACT_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/contacts`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_CONTACT_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_CONTACT_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_CONTACT_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_CONTACT_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/contacts/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_CONTACT_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_CONTACT_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_CONTACT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_CONTACT_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/contacts`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_CONTACT_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/contacts/detail/${response.data.data.id}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_CONTACT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_CONTACT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createFromSelect(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_CONTACT_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/contacts`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_CONTACT_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success');
				},
				error => {
					dispatch({
						type: CREATE_CONTACT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_CONTACT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_CONTACT_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/contacts/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_CONTACT_SUCCESS,
					payload: response.data
				});
				// swal.fire('Success!', 'Data updated!', 'success').then(() => {
				window.location.href = `/contacts/detail/${id}`;
				// });
			},
			error => {
				dispatch({
					type: UPDATE_CONTACT_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteData(id, isRedirect) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const paramsData = JSON.parse(localStorage.getItem('params'));
	return dispatch => {
		dispatch({
			type: DELETE_CONTACT_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/contacts/${id}`
		}).then(
			() => {
				if (isRedirect) {
					window.location.href = '/contacts';
				} else {
					dispatch(fetchData(paramsData));
				}
			},
			error => {
				// dispatch({ type: DELETE_CONTACT_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function bulkDelete(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		try {
			await axios({
				method: 'DELETE',
				url: `${PATH}/contacts`,
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json'
				},
				data
			});
			dispatch(fetchData());
		} catch (error) {
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export const searchData = data => async dispatch => {
	dispatch({
		type: SEARCH_CONTACTS,
		payload: data
	});
};

export const sortData = data => async dispatch => {
	dispatch({
		type: SORT_DATA,
		payload: data
	});
};

export function importExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: IMPORT_EXCEL_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/contacts/importExcel`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: IMPORT_EXCEL_SUCCESS,
						payload: response.data
					});
					window.location.href = '/contacts';
				},
				error => {
					handleErrorImport(error, dispatch, IMPORT_EXCEL_ERROR, modalErrorValidate);
				}
			)
			.catch(error => {
				dispatch({
					type: IMPORT_EXCEL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function exportExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/contacts/exportExcel`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Contacts.xlsx');
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				throw error;
			});
}

export function exportCsv(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/contacts/exportCsv`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Contacts.csv');
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				// dispatch({ type: FETCH_COMPANIES_ERROR, error });

				throw error;
			});
}

export function createOpportunity(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_OPPORTUNITY_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/opportunities`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_OPPORTUNITY_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/contacts/detail/${id.contactId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_OPPORTUNITY_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_OPPORTUNITY_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createContactFile(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_CONTACT_FILE_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/contactFiles/`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_CONTACT_FILE_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/contacts/detail/${id.contactId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_CONTACT_FILE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_CONTACT_FILE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createNote(data, contactId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_NOTE_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/notes/`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_NOTE_SUCCESS,
						payload: response.data
					});
					window.location.href = `/contacts/detail/${contactId}`;
				},
				error => {
					dispatch({
						type: CREATE_NOTE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_NOTE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createEvent(data, contactId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/events/`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_EVENT_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/contacts/detail/${contactId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_EVENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}

// GET LINKED ELEMENTS

export function getOpportunities(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_OPPORTUNITIES_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/opportunities`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: GET_OPPORTUNITIES_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_OPPORTUNITIES_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_OPPORTUNITIES_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getEvents(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_EVENTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/events`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: GET_EVENTS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_EVENTS_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_EVENTS_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getEventDetail(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_EVENT_DETAIL_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/events/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: GET_EVENT_DETAIL_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_EVENT_DETAIL_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_EVENT_DETAIL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getActivities(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_ACTIVITIES_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/activityLogs/${id}`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: GET_ACTIVITIES_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_ACTIVITIES_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_ACTIVITIES_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getNotes(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_NOTES_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/notes`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: GET_NOTES_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_NOTES_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_NOTES_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getNoteDetail(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_NOTE_DETAIL_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/notes/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: GET_NOTE_DETAIL_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_NOTE_DETAIL_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_NOTE_DETAIL_ERROR,
					error
				});
				throw error;
			});
	};
}

export function getEmails(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const params = {
		...data,
		sort: 'desc'
	};

	return async dispatch => {
		dispatch({
			type: GET_EMAILS_REQUEST,
			loading: true
		});
		try {
			const contactResp = await axios({
				method: 'get',
				url: `${PATH}/contacts/id/${data.contactId}`,
				params: data
			});
			const mail = contactResp.data.data.email;
			if (!mail) {
				return dispatch({
					type: GET_EMAILS_ERROR
				});
			}
			const queryParams = '?q=from:' + mail + ' OR cc:' + mail + ' OR to:' + mail;
			const response = await axios({
				method: 'get',
				url: `${URL_FOUNDATION}/api/third-party/google-mail/get-mails${queryParams}`,
				user: data.user
			});
			dispatch({
				type: GET_EMAILS_SUCCESS,
				payload: {
					data: response.data.messages ? response.data.messages.slice((params.page - 1) * params.limit, params.page * params.limit) : response.data,
					pagination: {
						currentPage: params.page,
						lastPage: response.data.messages ? Math.ceil(response.data.messages.length / params.limit) : 0,
						count: response.data.messages ? response.data.messages.length : 0,
						recordPerPage: params.limit
					}
				}
			});
		} catch (error) {
			dispatch({
				type: GET_EMAILS_ERROR,
				error
			});
			throw error;
		}
	};
}

export function getEmailDetail(mailData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: type.GET_EMAIL_DETAIL_REQUEST,
			loading: true
		});

		try {
			const response = {
				id: mailData.id,
				from: mailData.payload.headers.find(e => e.name == 'From').value,
				to: mailData.payload.headers.find(e => e.name == 'Delivered-To').value,
				body: mailData.snippet
			};
			dispatch({
				type: type.GET_EMAIL_DETAIL_SUCCESS,
				payload: response
			});
		} catch (error) {
			dispatch({
				type: GET_EMAIL_DETAIL_ERROR,
				error
			});
			throw error;
		}
	};
}

export function getDocuments(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: GET_DOCUMENTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/contactFiles`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: GET_DOCUMENTS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: GET_DOCUMENTS_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: GET_DOCUMENTS_ERROR,
					error
				});
				throw error;
			});
	};
}

// MODIFY LINKED ELEMENTS
export function deleteDocument(id, contactId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_DOCUMENT_REQUEST,
			id
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/contactFiles/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				() => {
					dispatch({
						type: DELETE_DOCUMENT_SUCCESS
					});
					// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
					window.location.href = `/contacts/detail/${contactId}`;
					// });
				},
				error => {
					dispatch({
						type: DELETE_DOCUMENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: DELETE_DOCUMENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteNote(id, contactId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_NOTE_REQUEST,
			id
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/notes/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				() => {
					dispatch({
						type: DELETE_NOTE_SUCCESS
					});
					// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
					window.location.href = `/contacts/detail/${contactId}`;
					// });
				},
				error => {
					dispatch({
						type: DELETE_NOTE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: DELETE_NOTE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function editNote(data, id, contactId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_NOTE_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/notes/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_NOTE_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data updated!', 'success').then(() => {
					window.location.href = `/contacts/detail/${contactId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_NOTE_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_NOTE_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteEvent(id, contactId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_EVENT_REQUEST,
			id
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/events/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				() => {
					dispatch({
						type: DELETE_EVENT_SUCCESS
					});
					// swal.fire('Success!', 'Data deleted!', 'success').then(() => {
					window.location.href = `/contacts/detail/${contactId}`;
					// });
				},
				error => {
					dispatch({
						type: DELETE_EVENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: DELETE_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function editEvent(data, id, contactId) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/events/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_EVENT_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data updated!', 'success').then(() => {
					window.location.href = `/contacts/detail/${contactId}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_EVENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}
