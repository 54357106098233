import * as React from 'react';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API, URL_FOUNDATION } from '../../../config/keys';

const cookies = new Cookies();
const PATH = `${URL_API}/api`;
const token = cookies.get('id_token') || null;

export async function pushEvents(list): Promise<void> {
	return axios({
		method: 'POST',
		url: `${PATH}/events/create-multiple`,
		// headers: { 'Content-Type': 'multipart/form-data' },
		data: list
	}).then(res => res.data);
}

export async function assignCompanies(companyList: any[], ownerId: number) {
	return await axios({
		method: 'PUT',
		url: `${PATH}/clientCompanies/massAssign`,
		// headers: { 'Content-Type': 'multipart/form-data' },
		data: {
			companyList,
			ownerId
		}
	});
}

export async function sequenceEvents(body: { waypoints: any[], mode: string }) {
	return await axios.post(`${URL_FOUNDATION}/api/map/sequence`, body);
}

interface BulkCreateEventBody {
  events: Array<{
    title: string
    start: number
    end: number
    company_id: number
	description?: string
  }>
  type_id: number
  owner_id: number
}

export async function bulkCreateEvents(body: BulkCreateEventBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-create/events`, body);
}
