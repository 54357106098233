import JanusOAuth from '../../containers_v2/auth/oauth/JanusOAuth';
import { hostToRustEnum, hosts } from '../../containers_v2/settings/subSettings/thirdPartyIntegration';

const OAuthRoutes = () => hosts.map(tp => ({
	component: JanusOAuth(hostToRustEnum(tp)),
	path: `/oauth/${tp}`,
	key: `oauth${tp}`,
	exact: true,
	menu: false
}));

export default OAuthRoutes;
