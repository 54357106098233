import axios from 'axios';
import { URL_FOUNDATION } from '../../../config/keys';

export function fetchTabList() {
	return axios.get(`${URL_FOUNDATION}/api/v2/dashboard-screens`)
		.then(res => res.data)
		.catch(e => console.error(e));
}

export function createTab() {
	return axios.post(`${URL_FOUNDATION}/api/v2/dashboard-screens`)
		.then(res => res.data)
		.catch(e => console.error(e));
}

export function updateTab(id: number, body: { name: string }) {
	return axios.put(`${URL_FOUNDATION}/api/v2/dashboard-screens/${id}`, body)
		.then(res => res.data)
		.catch(e => console.error(e));
}

export function deleteTab(id: number) {
	return axios.delete(`${URL_FOUNDATION}/api/v2/dashboard-screens/${id}`)
		.then(res => res.data)
		.catch(e => console.error(e));
}

export function duplicateTab(id: number, body: { name: string }) {
	return axios.post(`${URL_FOUNDATION}/api/v2/dashboard-screens/${id}/duplicate`, body)
		.then(res => res.data)
		.catch(e => console.error(e));

}
