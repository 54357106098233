import { logoutUser } from '../../../../../store/actions/auth.action';

/* eslint-disable camelcase */
const translateCreateFormMessage = responseData => {
	const { data, stat_msg } = responseData;
	const arr = Object.keys(data).map(key => key);
	const toSwitch = arr[0] || stat_msg;
	console.log('toSwitch', toSwitch);

	switch (toSwitch) {
		case 'default_image_id':
			return 'Upload an Image is Required in Image Field';

		case 'table_field_not_available':
			return 'Layout type field cannot be placed inside table, please take out these field from inside table: Start/End section, Start/End table, header, picture, camera';

		case 'numerical_invalid_max_value':
			return 'Numerical Field: Max Value is required!';

		default:
			return 'Something Wrong';
	}
};

const translateMainMessage = responseData => {
	const { stat_msg } = responseData;

	switch (stat_msg) {
		case 'validation error':
			return 'Validation Error!';

		case 'Invalid user token':
			logoutUser();
			break;

		default:
			return 'Error!';
	}
};

export { translateCreateFormMessage, translateMainMessage };
