import styled, { css } from 'styled-components';
import { DarkGreySidely2, LightBlueSidely } from './Utils';

const DefaultText = css`
    font-size: 13px;
    font-style: normal;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: ${DarkGreySidely2};
`;

export const DefaultTextDiv = styled.div<{ height?: string, fontSize?: string, color?: string }>`
    ${DefaultText}
    ${p => p.height ? `height: ${p.height};` : ''}
    ${p => p.fontSize ? `font-size: ${p.fontSize};` : ''}
    ${p => p.color ? `color: ${p.color};` : ''}
`;

const DefaultPlaceholder = css`
    ${DefaultText};
    color: #D4D0D2;
`;

export const DefaultPlaceholderDiv = styled(DefaultTextDiv)`
    ${DefaultPlaceholder}
`;

const DefaultEditable = css`
    &:focus-visible {
        border: none;
        outline: none;
        background-color: ${LightBlueSidely};
    }
`;

export {
	DefaultText,
	DefaultEditable
};
