import React, {Component} from 'react';
import InputNumber from 'rc-input-number';

class NumberField extends Component {
  state = {};

  render() {
    const {
      input,
      placeholder,
      type,
      disabled,
      label,
      noLabel,
      requiredStar,
      id,
      onChange,
      value,
      style,
      readOnly,
      min,
      max,
      meta: { touched, error, warning }
    } = this.props;
    const lng = localStorage.getItem('language') || 'en';

    const separator = lng === 'fr' ? ' ' : ',';

    return (
      (<div style={style}>
        <div className="form-group row">
          {!noLabel && (
            <div className="col-md-3 text-right mt-2">
              <label htmlFor={id} style={style}>
                {requiredStar === true ? (
                  <span className="text-danger">* </span>
                ) : (
                  ''
                )}
                {label}
              </label>
            </div>
          )}

          <div className="col-md-9 my-auto">
            <div className="row">
              <div className="col-12">
                <InputNumber
                  // {...input}
                  placeholder={placeholder}
                  type={type}
                  readOnly={readOnly}
                  disabled={disabled}
                  id={id}
                  onChange={value => {
                    input.onChange(value);
                    // onChange(value);
                  }}
                  // onFocus={type === 'date'}
                  // onBlur={type === 'text'}
                  className={`form-control ${
                    touched && error ? 'focus-error' : ''
                  }`}
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
                  }
                  value={input.value}
                  min={min}
                  max={max}
                />
              </div>
              <div className="col-12">
                {touched &&
                  ((error && <span className="form-error">{error}</span>) ||
                    (warning && <span className="form-error">{warning}</span>))}
              </div>
            </div>
          </div>
        </div>
      </div>)
    );
  }
}

export default NumberField;
