import trash from 'images/formbuilder/trash.svg';
import * as React from 'react';
import { Column, Table } from '../../../components_v2/table/Table';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { TableRow } from '../../orders/templateOrders/style/Style';
import { getCategories } from '../../products/action';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import Add from '../../../components_v2/add/Add';
import NewCategory from '../../categories/newCategory';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import { deleteCategory } from './actions';
import { ModalState } from '../../products/model';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import Restricted from '../../permissions/Restricted';
import PermissionContext from '../../permissions/PermissionContext';

interface RawCategory {
	id: number
	name: string
	description?: string
	hierarchy_level: number
	parent_id?: number
	children: RawCategory[]
}

export interface Category {
	id: number
	name: string
	description?: string,
	parent_id?: number,
	subRows: Category[]
}


function getColumns(onDelete: (id: number) => void, onOpen: (brand: Category) => void, deleteAlowed: boolean, updateAlowed: boolean): Column<Category>[] {
	const cols: Column<Category>[] = [
		{
			id: 'expander',
			Header: (props: any) => {
				const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = props;
				return (
					<span {...getToggleAllRowsExpandedProps({ title: undefined })}
						style={{ fontSize: '20px', cursor: 'pointer' }}>
						{isAllRowsExpanded ? '-' : '+'}
					</span>
				);
			},
			Cell: (props: { row }) => props.row.canExpand ? (
				<span
					{...props.row.getToggleRowExpandedProps({
						title: undefined,
						style: {
							fontSize: '20px'
						}
					})}
				>
					{props.row.isExpanded ? '-' : '+'}
				</span>
			) : null,
			width: 60,
			Footer: 'Total'
		},
		{
			id: 'name',
			Header: translateToString('name'),
			accessor: row => <TableRow onClick={() => updateAlowed && onOpen(row)} cursor={updateAlowed ? 'pointer' : undefined} fontWeight='500' color={DarkGreySidely}>{row.name}</TableRow>,
			width: undefined,
			toolTip: row => row.name
		},
		{
			id: 'description',
			Header: translateToString('description'),
			accessor: row => <TableRow>{row.description}</TableRow>,
			width: undefined,
			toolTip: row => row.description
		},
		{
			id: 'sub_categories',
			Header: translateToString('sub_categories'),
			accessor: row => <TableRow>{row.subRows.length}</TableRow>,
			width: undefined,
		},
	];
	if (deleteAlowed) {
		cols.push({
			id: 'options',
			Header: ' ',
			accessor: row => <TableRow onClick={() => onDelete(row.id)} cursor="pointer">
				<img src={trash} width={15} />
			</TableRow>,
			disableSortBy: true,
			width: 35,
			minWidth: 35,
			unresizeable: true
		});
	}
	return cols;
}

const categoryMapper = (category: RawCategory): Category => ({
	id: category.id,
	name: category.name,
	description: category.description,
	parent_id: category.parent_id,
	subRows: category.children.map(categoryMapper).sort((a, b) => a.name.localeCompare(b.name))
});

export const categoryFlatter = (category: Category): { id: number, name: string }[] => ([{
	id: category.id,
	name: category.name,
}, ...category.subRows.reduce((acc, c) => [...acc, ...categoryFlatter(c)], [])]);

function findCategory(category: Category, fn: (c: Category) => boolean): Category | undefined {
	if (fn(category)) return category;
	for (const c of category.subRows) {
		const value = findCategory(c, fn); 
		if (value) return value;
	}
}

function findCategoryOverCategories(categories: Category[], fn: (c: Category) => boolean): Category | undefined {
	for (const c of categories) {
		const value = findCategory(c, fn);
		if (value) return value;
	}
}

export default function CategoryManagement(props: {
	setToolBarState: (value: ToolbarState) => void
}) {
	const [categories, setCategories] = React.useState<Category[]>([]);
	const fetchCategories = React.useCallback(() => getCategories().then(res => setCategories(res.data.map(categoryMapper).sort((a, b) => a.name.localeCompare(b.name)))), []);
	const [creationOpen, setCreationOpen] = React.useState<ModalState<Category>>({ isOpen: false });
	const alert = useAlert();
	const { isAllowedTo } = React.useContext(PermissionContext);

	React.useEffect(() => {
		fetchCategories();
		props.setToolBarState({
			bottomRightToolbarComponent: <Restricted to={{ objectAction: 'CreateCompanySettings' }}><Add onClick={() => setCreationOpen({ isOpen: true })}/></Restricted>
		});
	}, []);

	function onDelete(id: number) {
		const cat = findCategoryOverCategories(categories, c => c.id === id);
		if (!cat) return;
		const allSubCats = categoryFlatter(cat).map(c => c.name).slice(1);
		alert({
			title: translateToString('do_you_want_to_delete_the_category'),
			content: <div>
				{translateToNode('attention_delete_category')}
				{allSubCats.length > 0 && <div>
					{translateToNode('theses_categories_will_also_be_deleted', {
						variables: [['number', allSubCats.length.toString()]]
					})}
					{allSubCats.map((sc, i) => <div key={`SubCategory[${i}]`}>- {sc}</div>)}
				</div>}
			</div>,
			width: '600px',
			mode: 'delete'
		}).then(res => {
			if (res !== AlertRes.Ok) return;
			deleteCategory(cat.id).then(res => {
				if ('Ko' in res) {
					alert({
						title: translateToString('error'),
						content: <div>
							{translateToNode('cant_delete_category_products')}
							{res.Ko.CategoryStillHaveProducts.map(([id, name], i) => <div key={`RemainingProduct[${i}]`}>
								<a onClick={() => window.open(`/products-v2?id=${id}`, '_blank')}>
									- {name}
								</a>
							</div>)}
						</div>,
						noButtons: true,
						svg: 'duplicate',
						textAlign: 'left',
						width: '600px'
					});
				} else {
					fetchCategories();
				}
			});
		});
	}

	const columns = React.useMemo(() => getColumns(
		onDelete,
		data => setCreationOpen({ isOpen: true, data }), 
		isAllowedTo({ objectAction: 'DeleteCompanySettings' }),
		isAllowedTo({ objectAction: 'UpdateCompanySettings' })
	), [categories]);

	return <>
		<Table
			noBorder
			height='100%'
			columns={columns}
			data={categories}
		/>
		<NewCategory
			isOpen={creationOpen.isOpen}
			setIsOpen={isOpen => setCreationOpen({ isOpen })}
			onCreate={fetchCategories}
			categories={categories.reduce((acc, c) => [...acc, ...categoryFlatter(c)], [])}
			category={creationOpen.data}
		/>
	</>;
}