import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector } from 'recoil';
import { URL_FOUNDATION } from '../../config/keys';


export type Color = {
	colorCode: string,
	id: number
}

// -----------------------[ ATOM ]----------------------- //
export const AColors = selector<Color[]>({
	key: 'AColors',
	get: async() => getColors()
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getColors(): Promise<(unknown & { colorCode: string, id: number })[]> {
	return await axios({
		method: 'get',
		url: `${URL_FOUNDATION}/api/crm/colors`
	}).then(res => res.data);
}