import add_blue from 'images/icon/add_blue.png';
import React, { Component } from 'react';
import swal from 'sweetalert2';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { deleteEvent, editEvent } from '../../../../store/actions/opportunities.action';
import noUserImage from 'images/icons/user.svg';
import ModalEventForm from './modals/ModalEventForm';
import { cekEvent, cekEventTimes } from '../../../../helpers/time';
import penUrl from 'images/ui_icon/pen_black.svg';
import trashUrl from 'images/ui_icon/trash_black.svg';
import penBlueUrl from 'images/ui_icon/pen_blue.svg';
import trashBlueUrl from 'images/ui_icon/trash_blue.svg';

moment.locale('en', {
	calendar: {
		lastDay() {
			return '[Yesterday]';
		},
		sameDay() {
			return '[Today]';
		},
		nextDay() {
			return '[Tomorrow]';
		},
		thisWeek() {
			return 'DD/MM/YYYY';
		},
		lastWeek() {
			return 'DD/MM/YYYY';
		},
		sameElse() {
			return 'DD/MM/YYYY';
		}
	}
});


export function getTrashColor(trash) {
	return trash.includes('blue') ? trashBlueUrl : trashUrl;
}

export function getPenColor(pen) {
	return pen.includes('blue') ? penBlueUrl : penUrl;
}

class Calendar extends Component {
	state = {
		penUrl,
		trashUrl,
		id: {
			eventId: null,
			opportunityId: null,
			contactId: null,
			clientCompanyId: null
		},
		modal: false,
		hover: false
	};

	onChangeMode = mode => {
		this.setState({
			mode
		});
	};

	onHover = (pen, trash) => {
		this.setState({
			penUrl: pen ? getPenColor(pen) : this.state.penUrl,
			trashUrl: trash ? getTrashColor(trash) : this.state.trashUrl
		});
	};

	onDelete = (id, opportunityId) => {
		const { deleteEvent } = this.props;
		// console.log('sss', id, opportunityId);
		swal
			.fire({
				title: 'Attention!',
				text: 'Are you sure you want to delete this from the database?',
				type: 'warning',
				confirmlButtonText: 'Yes, I do',
				confirmButtonColor: '#f7685b'
			})
			.then(result => {
				if (result.value) {
					deleteEvent(id, opportunityId);
				}
			});
	};

	openModal = (eventId, mode) => {
		this.setState({
			id: {
				eventId,
				opportunityId: this.props.id.opportunityId,
				contactId: this.props.id.contactId,
				clientCompanyId: this.props.id.clientCompanyId
			},
			modal: true,
			mode
		});
	};

	closeModal = () => {
		this.setState({
			id: {
				eventId: null,
				opportunityId: this.props.id.opportunityId,
				contactId: this.props.id.contactId,
				clientCompanyId: this.props.id.clientCompanyId
			},
			modal: false
		});
	};

	getStatusEvent = val => {
		const { editEvent, id } = this.props;
		const formData = new FormData();
		const keys = [
			'incharge',
			'eventTypeId',
			'startDate',
			'endDate',
			'title',
			'description'
		];

		keys.map(key => {
			val[key] && formData.append(key, val[key]);
			return null;
		});
		if (val.eventStatusId === 2) {
			formData.append('eventStatusId', 1);
		} else {
			formData.append('eventStatusId', 2);
		}
		editEvent(formData, val.id, id.opportunityId);
	};

	getImage = (url, size) => (
		<img
			className="rounded-circle image-thumbnail"
			src={url}
			alt=""
			width={size}
			height={size}
		/>
	);

	render() {
		const {
			data,
			id,
			onFetch,
			isLoading,
			isFirstPage,
			isLastPage,
			count
		} = this.props;

		return (
			(<div>
				<div className="detail-top m-3 p-3">
					<div className="row mb-2">
						<div className="col-md-9">
							<div className="my-auto">
								<span className="card-title">
									<Translate id="event" /> ({count})
								</span>
							</div>
						</div>
						<div className="col-md-3 d-flex justify-content-end align-items-end">
							<div
								className="my-auto custom-icon-blue rounded-circle "
								onClick={() => this.openModal(null, 'New')}
							>
								<img
									src={add_blue}
									className="custom-icon"
									alt=""
								/>
							</div>
						</div>
					</div>
					{data.length ? (
						<div className="row">
							{data.map(val => (
								<div
									className={`card card-event ${val.eventStatusSlug} relative-btn-container p-0 col-md-12`}
								>
									<button
										className="btn-edit-absolute"
										onClick={e => {
											e.preventDefault();
											this.openModal(val.id, 'Edit');
										}}
									>
										<img
											src={this.state.penUrl}
											alt=""
											width="15px"
											height="15px"
											onMouseEnter={() => this.onHover('pen_blue.svg')}
											onMouseLeave={() => this.onHover('pen_black.svg')}
										/>
									</button>
									<button
										className="btn-delete-absolute"
										onClick={e => {
											e.preventDefault();
											// this.onDelete(val.id, id.contactId);
											this.onDelete(val.id, id.opportunityId);
										}}
									>
										<img
											src={this.state.trashUrl}
											alt=""
											width="15px"
											height="15px"
											onMouseEnter={() => this.onHover(null, 'trash_blue.svg')}
											onMouseLeave={() => this.onHover(null, 'trash_black.svg')}
										/>
									</button>
									<div className="card-body card-no-shadow pointer p-2">
										<div className="row">
											<div
												className="col-md-1 d-flex justify-content-center align-items-center card-event"
												onClick={() => {
													this.openModal(val.id, 'View');
												}}
												onMouseEnter={() => this.setState({ hover: true })}
												onMouseLeave={() => this.setState({ hover: false })}
											>
												{this.getImage(val.inchargePhotoUrl || noUserImage, 32)}
												{this.state.hover && (
													<div className="tooltip-event d-flex">
														{this.getImage(
															val.inchargePhotoUrl || noUserImage,
															34
														)}
														<div className="ml-3 text-left">
															<h6 className="font-weight-bold mb-0">
																{val.inchargeName || '-'}
															</h6>
															<p className="mb-0">{val.inchargeEmail || '-'}</p>
														</div>
													</div>
												)}
											</div>
											<div
												className="my-auto text-center col-md-2"
												onClick={() => {
													this.openModal(val.id, 'View');
												}}
											>
												<span
													className={
														moment(val.startDate) < moment() &&
                            val.eventStatusSlug === 'to-do'
															? 'text-calendar-gray text-custom-red time'
															: 'text-calendar-gray time'
													}
												>
													{cekEvent(val.startDate, val.endDate) ===
                          'Tomorrow' ? (
															<Translate id="Tomorrow" />
														) : (
															cekEvent(val.startDate, val.endDate)
														)}
												</span>
											</div>
											<div
												className="my-auto text-center col-md-2"
												onClick={() => {
													this.openModal(val.id, 'View');
												}}
											>
												<span
													className={
														moment(val.startDate) < moment() &&
                            val.eventStatusSlug === 'to-do'
															? 'text-calendar-gray text-custom-red time'
															: 'text-calendar-gray time'
													}
												>
													{cekEventTimes(val.startDate, val.endDate) !==
                          'All day' ? (
															cekEventTimes(val.startDate, val.endDate)
														) : (
															<Translate id="All day" />
														)}
												</span>
											</div>
											<div
												className="my-auto text-center col-md-1"
												onClick={() => {
													this.openModal(val.id, 'View');
												}}
											>
												<span
													className={
														moment(val.startDate) < moment() &&
                            val.eventStatusSlug === 'to-do'
															? 'text-calendar-gray text-custom-red time'
															: 'text-calendar-gray time'
													}
												>
													<Translate id={'event.' + val.eventTypeName} />
												</span>
											</div>
											<div
												className="my-auto text-center col-md-3"
												onClick={() => {
													this.openModal(val.id, 'View');
												}}
											>
												<span
													className={
														moment(val.startDate) < moment() &&
                            val.eventStatusSlug === 'to-do'
															? 'text-calendar-gray text-custom-red time'
															: 'text-calendar-gray time'
													}
												>
													{val.title}
												</span>
											</div>
											<div className="my-auto col-md-1">
												<div
													className="checked-event"
													style={{
														background: val.eventStatusId === 2 && '#2ED47A'
													}}
													onClick={() => this.getStatusEvent(val)}
												></div>
											</div>
											<div
												className="my-auto text-center col-md-2"
												onClick={() => {
													this.openModal(val.id, 'View');
												}}
											>
												<span
													className={
														moment(val.startDate) < moment() &&
                            val.eventStatusSlug === 'to-do'
															? 'text-calendar-gray text-custom-red time'
															: 'text-calendar-gray time'
													}
												>
													<Translate id={val.eventStatusName} />
												</span>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<p
							style={{
								fontSize: '14px',
								margin: '15px 0 10px',
								color: '#707683'
							}}
						>
							<Translate id="nocalendar" />
						</p>
					)}
					{count > 3 &&
            (!isLoading ? (
            	<div className="row mt-2">
            		<button
            			className={`${
            				isLastPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-more col-6`}
            			onClick={() => {
            				!isLastPage && onFetch();
            			}}
            		>
            			<Translate id="see_more" />
            		</button>
            		<button
            			className={`${
            				isFirstPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-less col-6`}
            			onClick={() => {
            				!isFirstPage && onFetch(1);
            			}}
            		>
            			<Translate id="see_less" />
            		</button>
            	</div>
            ) : (
            	<div className="d-flex mt-2">
            		<i className="lds-dual-ring mx-auto" />
            	</div>
            ))}
				</div>
				<ModalEventForm
					isOpen={this.state.modal}
					toggle={() => {
						this.closeModal();
					}}
					onChangeMode={this.onChangeMode}
					onDelete={this.onDelete}
					onHover={this.onHover}
					mode={this.state.mode}
					penUrl={this.state.penUrl}
					trashUrl={this.state.trashUrl}
					id={this.state.id}
				/>
			</div>)
		);
	}
}

const mapStateToProps = state => ({
	// loadingDeleteId: state.companies.deleteNoteId
});

export default connect(mapStateToProps, { deleteEvent, editEvent })(Calendar);
