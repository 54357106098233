import * as React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { LightGreySidely } from '../../styles/global/css/Utils';

type SwitchValue<T = unknown> = {
	value: T,
	src: string,
	to?: string,
	onClick?: (value: T) => void
}

const SwapImage = styled.img<{ selected: boolean }>`
	height: 25px;
	${p => p.selected ? '' : `
		opacity: 33%;
		cursor: pointer;
	`}
`;

export const IconsGroupBackground = styled.div<{ background?: boolean }>`
		display: flex;
		gap: 10px;
		border-radius: 15px;
		${({ background }) => background ? `background-color: ${LightGreySidely};` : ''}
		height: 37px;
		align-items: center;
		padding: 0 10px;

`;

export default function PageSwitch<T = unknown>(props: { selectedValue?: T, list: SwitchValue<T>[], background?: boolean }) {
	const { list, selectedValue } = props;
	
	return <IconsGroupBackground background={props.background}>
		{list.map((v, i) => {
			const inner = <SwapImage src={v.src} key={`swapImage[${i}]`} onClick={() => v.onClick?.(v.value)} selected={(selectedValue !== undefined && selectedValue === v.value) || (!v.onClick && !v.to)}/>;
			if (v.to) return <NavLink to={v.to}>{inner}</NavLink>;
			return inner;
		})}
	</IconsGroupBackground>;
}