import React, {Component} from 'react';
import {message, Upload} from 'antd';
import {connect} from 'react-redux';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {uploadFile} from '../../../store/actions/formbuilder.action';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class UploadIcon extends Component {
  state = {
    loading: false
  };

  customRequest = ({ onSuccess, onError, file }) => {
    const { uploadFile, loadFormIcon } = this.props;
    const formData = new FormData();
    this.setState({ loading: true });
    formData.append('type', 'form_icon');
    formData.append('file', file);

    uploadFile(formData).then(
      response => {
        loadFormIcon({
          name: file.name,
          icon_id: response.data.data.id,
          status: true
        });
        this.setState({
          loading: false,
          imageUrl: response.data.data.temp_url
        });
      },
      () => {
        loadFormIcon(null);
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        customRequest={this.customRequest}
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

const mapStateToProps = state => ({
  data: state.formbuilder.file,
  isUploading: state.formbuilder.isUploading
});

export default connect(
  mapStateToProps,
  { uploadFile }
)(UploadIcon);
