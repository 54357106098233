/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {Field} from 'redux-form';
import debounce from 'lodash/debounce';
import RenderSelectInput from './RenderSelectInputModal';
import {fetchData} from '../../store/actions/companies.action';

class SelectCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
    this.delayedCallback = debounce(this.searchCall, 1000);
  }

  componentDidMount() {
    const { fetchData } = this.props;
    fetchData({ limit: 100, page: 1, by: 'name', sort: 'asc' });
  }

  componentWillReceiveProps(nextProps) {
    const { data, all } = this.props;
    if (data.data !== nextProps.data.data) {
      let opt = [];
      if (all === undefined) {
        opt = [
          {
            value: 'all',
            label: <Translate id="all" />
          }
        ];
      }
      nextProps.data.data.map(data =>
        opt.push({
          value: data.id,
          label: data.name
        })
      );
      this.setState({ opt });
    }
  }

  searchCall = val => {
    const params = { limit: 100, page: 1, by: 'name', sort: 'asc' };
    if (val.length > 2) {
      this.props.fetchData({ ...params, name: val });
    } else {
      this.props.fetchData(params);
    }
  };

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      placeholder,
      readOnly,
      w100
    } = this.props;
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="inputCompanies"
        placeholder={placeholder}
        validate={validate}
        readOnly={readOnly}
        w100={w100}
        handleSearch={this.delayedCallback}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.companies
});

export default connect(mapStateToProps, {
  fetchData
})(SelectCompanies);
