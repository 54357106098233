import React from 'react';
import {Translate} from 'react-localize-redux';
import PictureComponent from '../../../../components/PictureComponent';

function Pictures(props) {
  const { detail } = props;
  const beginningPictures =
    (detail && detail.beginning_pictures && detail.beginning_pictures) || [];
  const end_pictures =
    (detail && detail.end_pictures && detail.end_pictures) || [];
  const count = beginningPictures.length + end_pictures.length;
  return (
    <div className="detail-top m-3 p-3">
      <div className="mb-2">
        <span className="card-title">
          <Translate id="Pictures" /> ({count})
        </span>
      </div>
      <p className="text-muted mb-1">
        <Translate id="before" />
      </p>
      <PictureComponent images={beginningPictures || []} />
      <p className="text-muted mt-5 mb-1">
        <Translate id="after" />
      </p>
      <PictureComponent images={end_pictures || []} />
    </div>
  );
}

export default Pictures;
