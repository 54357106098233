import React from 'react';
import {Translate} from 'react-localize-redux';

function ButtonOption(props) {
	const { description, label, type, setStatus, onClickValue, initValue, setInitValue, key } = props;
	const dropdownType = [
		'shelf_type',
		'shelf_sector',
		'shelf_zone',
		'product_highlight',
		'discount_type',
		'discount_level'
	];
	const defaultNumber = { min_value: null, max_value: null };
	const onChangeBool = v => {
		onClickValue(props.value, v);
		setInitValue({ initial_value: v });
	};

	const onChangeNum = key => {
		const num = initValue && parseInt(initValue.initial_value) || 0;
		setInitValue({ ...defaultNumber, initial_value: num + key });
		onClickValue(props.value, { ...defaultNumber, initial_value: num });
	};

	const getElements = () => {
		switch (type) {
		case 'bool':
			return (
				<div className="d-flex justify-content-center" key={key}>
					<button
						className="btn-no btn-opt"
						onClick={() => onChangeBool(false)}
					>
              No
					</button>
					<button
						className="btn-opt btn-yes"
						onClick={() => onChangeBool(true)}
					>
              Yes
					</button>
				</div>
			);
		case 'float':
		case 'int':
			return (
                (<div className="d-flex justify-content-center my-2" key={key}>
                    <button className="btn-facing min" onClick={() => onChangeNum(-1)}>
              -
					</button>
                    <input
						className="input-facing"
						min={0}
						value={initValue && parseInt(initValue.initial_value) || null}
						onChange={e => {
							const num = e.target.value.replace(/[^\d]/g, '');
							setInitValue({ ...defaultNumber, initial_value: num });
							onClickValue(props.value, { ...defaultNumber, initial_value: num });
						}}
					/>
                    <button className="btn-facing plus" onClick={() => onChangeNum(1)}>
              +
					</button>
                </div>)
            );
		default:
			break;
		}
	};
	return (
		<div
			className="hover-opt"
			onMouseEnter={() => setStatus(true)}
			onMouseLeave={() => setStatus(false)}
			key={key}
		><Translate>
				{({ translate }) => (
					<div className="bg-btn-opt">
						{translate(label)}
						{getElements()}
						{dropdownType.includes(type) && (
							<div className="d-flex justify-content-center mt-2" key={`${key}-${type}`}>
								<select
									id={1}
									name="select"
									onChange={e => {
										const data = initValue || [];
										data.push(parseInt(e.target.value));
										onClickValue(props.value, data);
										setInitValue(data);
									}}
								>
									{props.options.map(v => (
										<option value={v.value}>{v.label}</option>
									))}
								</select>
							</div>
						)}
					</div>
				)}
			</Translate>
			{<Translate id={description} />}
			{`${initValue ? `: ${initValue && initValue.initial_value || ''}` : ''}`}
		</div>
	);
}

export default ButtonOption;
