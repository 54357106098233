import axios from 'axios';
import { Cookies } from 'react-cookie';
import { UpdatedField } from '../../../../web-types/api';
import { URL_FOUNDATION } from '../../config/keys';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function postCategory(body: { name: string, description?: string, parent_category?: number }): Promise<number> {
	return axios.post(`${URL_FOUNDATION}/api/categories`, body).then(res => res.data);
}

export async function putCategory(categoryId: number, body: { name: string, description: UpdatedField<string>, parent_category?: UpdatedField<number> }): Promise<void> {
	return axios.put(`${URL_FOUNDATION}/api/categories/${categoryId}`, body).then(res => res.data);
}
