import * as React from 'react';
import { CalendarEvent } from '../../containers_v2/calendar/Calendar';
import NewEvent, { EventMode, NewEventDefaultValues } from '../../containers_v2/calendar/popup/NewEvent';
import { CompanyEvent } from '../../containers_v2/client-companies/model/Model';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';

function EventCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  onCreate?: (value: CompanyEvent) => void
  clientCompanyId?: number
  event?: CalendarEvent
  defaultValues?: NewEventDefaultValues
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, top: '0%' }}
			popupMode={PopupMode.Details}
			content={<NewEvent
				eventMode={EventMode.Creation}
				onClickOut={() => close()}
				onCreate={(value) => {
					(props.onCreate != null) && props.onCreate(value);
					close();
				}}
				clientCompanyId={props.clientCompanyId}
				event={props.event}
				defaultValues={props.defaultValues}
			/>}
		/>
	);
}

export default EventCreation;
