import { Route } from '../Routes';
import Promotions from '../../containers_v2/promotions/Promotions';
import CampagneBlack from 'images/menu_icon/campagnes_black.svg';
import { Translate } from 'react-localize-redux';
import * as React from 'react';


const PromotionsRoutes = (): Route[] => [
	{
		component: Promotions,
		path: '/promotions',
		menu: true,
		logoImg: CampagneBlack,
		name: <Translate id="campagnes" />,
		permission: ['ViewCampaign'],
	}
];

export default PromotionsRoutes;
