import {
	CREATE_ASSORTMENT_ERROR,
	CREATE_ASSORTMENT_REQUEST,
	CREATE_ASSORTMENT_SUCCESS,
	CREATE_PRODUCTS_ERROR,
	CREATE_PRODUCTS_REQUEST,
	CREATE_PRODUCTS_SUCCESS,
	DETAIL_PRODUCTS_ERROR,
	DETAIL_PRODUCTS_REQUEST,
	DETAIL_PRODUCTS_SUCCESS,
	FETCH_PRODUCTS_ERROR,
	FETCH_PRODUCTS_REQUEST,
	FETCH_PRODUCTS_SUCCESS,
	GET_ALL_CATALOGUES,
	GET_ALL_PRODUCT,
	SEARCH_PRODUCTS,
	SET_DETAIL_ASSORTMENT,
	SET_MODAL_ASSORTMENT,
	SET_PRODUCT_OPTION,
	SORT_PRODUCTS_DATA,
	UPDATE_ASSORTMENT_ERROR,
	UPDATE_ASSORTMENT_REQUEST,
	UPDATE_ASSORTMENT_SUCCESS,
	UPDATE_PRODUCTS_ERROR,
	UPDATE_PRODUCTS_REQUEST,
	UPDATE_PRODUCTS_SUCCESS
} from '../actions/products.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	data: [],
	dataCreated: null,
	dataDetail: [],
	detail: [],
	modalStatus: { status: false, type: 'create' },
	search: null,
	sortField: null,
	sort: 'asc',
	detailAssortment: null,
	allProduct: [],
	allCatalogue: [],
	productOption: 'products',
	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	}
};

export default function ProductsReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_PRODUCTS_REQUEST:
			return {
				...state,
				data: [],
				isLoading: true
			};
		case FETCH_PRODUCTS_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_PRODUCTS_ERROR:
			return {
				...state,
				isLoading: false,
				data: []
			};

		case DETAIL_PRODUCTS_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_PRODUCTS_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_PRODUCTS_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case CREATE_PRODUCTS_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_PRODUCTS_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};
		case CREATE_PRODUCTS_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_PRODUCTS_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_PRODUCTS_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_PRODUCTS_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SEARCH_PRODUCTS:
			return {
				...state,
				search: action.payload
			};
		case SET_PRODUCT_OPTION:
			return {
				...state,
				productOption: action.payload
			};

		case SORT_PRODUCTS_DATA:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};
		case CREATE_ASSORTMENT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_ASSORTMENT_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};
		case CREATE_ASSORTMENT_ERROR:
			return {
				...state,
				isLoading: false
			};
		case GET_ALL_PRODUCT:
			return {
				...state,
				allProduct: action.payload
			};
		case GET_ALL_CATALOGUES:
			return {
				...state,
				allCatalogues: action.payload
			};
		case SET_DETAIL_ASSORTMENT:
			return {
				...state,
				detailAssortment: action.payload
			};
		case SET_MODAL_ASSORTMENT:
			return {
				...state,
				modalStatus: action.payload
			};
		case UPDATE_ASSORTMENT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_ASSORTMENT_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_ASSORTMENT_ERROR:
			return {
				...state,
				isLoading: false
			};

		default:
			return state;
	}
}
