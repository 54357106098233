import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { LoadingState } from '../../containers_v2/import/model';
import { translateToString } from '../../styles/global/translate';
import { DropdownData, DropdownStyle } from './model/Model';
import {
	ArrowBlock,
	Asterisk,
	CleanButton,
	ContainerCss,
	DropdownContainer,
	InputBlock,
	InputContainer,
	Label,
	LabelContainer,
	Link,
	LoadMoreBlock,
	LoadMoreText,
	NoResultText,
	OptionBlock,
	OptionContainer,
	OptionImage,
	OptionList,
	OptionText
} from './style/Style';
import { getFilteredCompanies } from '../../containers_v2/orders/data/Data';
import styled from 'styled-components';
import { ClientCompany } from '../../containers_v2/orders/model/Model';

function checkClickOut(ref: React.RefObject<HTMLDivElement>, setOpen: (value: boolean) => void) {
	React.useEffect(() => {
		function handleClickOutside(event) {
			if ((ref.current != null) && !ref.current.contains(event.target)) {
				setOpen(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}


export function TranslateOrLoad(props: {
	isLoading: boolean
  }): JSX.Element {
	if (props.isLoading) {
		return (
			<div style={{ width: '38px', margin: 'auto' }}>
				<i className="lds-dual-ring" />
			</div>
		);
	} else {
		return <Translate id="global.load_more" />;
	}
}

export default function DropdownSearch<T = any>(props: {
  datalist: DropdownData<T>[]
  selectedValue?: DropdownData<T> | null
  onChange?: (value: DropdownData<T> | null) => void
  onSearchChange?: (search: string, offset: number | undefined) => Promise<boolean>
  defaultSearch?: string
  name: string
  id?: string
  required?: boolean
  label?: string
  disabled?: boolean
  readOnly?: boolean
  readOnlyDelete?: boolean
  placeholder?: string
  width?: number
  height?: number
  optionHeight?: number
  labelUp?: boolean
  link?: string
  dropdownStyle?: DropdownStyle
  JSXButton?: (handleInput: (e: string) => void) => JSX.Element
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}): JSX.Element {
	const [list, setList] = React.useState<DropdownData<T>[]>(props.datalist);
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [label, setLabel] = React.useState<any>(props.datalist.filter(data => data.selected).shift()?.label);
	const [isAtTheEnd, setIsAtTheEnd] = React.useState<boolean>(true);
	const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();
	const [loadingState, setLoadingState] = React.useState<LoadingState>('loaded');

	const wrapperRef = React.useRef<HTMLDivElement>(null);
	checkClickOut(wrapperRef, setOpen);

	React.useEffect(() => { setLabel(props.selectedValue?.label || ''); }, [props.selectedValue]);

	React.useEffect(() => {
		setLoadingState('loaded');
		setList(props.datalist);
	}, [JSON.stringify(props.datalist)]);

	React.useEffect(() => {
		props.onSearchChange?.(props.defaultSearch ?? '', props.datalist.length)
			.then(searchRes => setIsAtTheEnd(searchRes == undefined ? true : searchRes));
	}, [props.defaultSearch]);

	function handleInput(input: string) {
		setLabel(input || '');
		if ((input || '').length !== 0) {
			setOpen(true);
		}
		if (props.onSearchChange !== undefined) {
			localTimeout && clearTimeout(localTimeout);
			setLocalTimeout(setTimeout(async(local_label) => {
				setLoadingState('loading');
				const searchRes: boolean | undefined = await props.onSearchChange?.(local_label, undefined);
				setIsAtTheEnd(searchRes == undefined ? true : searchRes);
			}, 250, input || ''));
		}
	}

	const width = props.width ? `${props.width}px` : props.dropdownStyle?.width ?? '400px';
	const height = props.height ? `${props.height}px` : props.dropdownStyle?.height ?? '40px';
	const optionHeight = props.optionHeight ? `${props.optionHeight}px` : props.dropdownStyle?.optionHeight ?? '100px';

	return (
		<div style={ContainerCss(height, props.dropdownStyle?.containerWidth, props.dropdownStyle?.containerJustifyContent, undefined, props.labelUp ?? props.dropdownStyle?.labelUp)} ref={wrapperRef}>
			{
				props.label &&
				<LabelContainer alignSelf={(props.labelUp ?? props.dropdownStyle?.labelUp) ? 'start' : 'center'}>
					{
						props.required &&
						<Asterisk />
					}
					<Label {...props.dropdownStyle?.labelStyle}>{props.label}</Label>
				</LabelContainer>
			}

			<DropdownContainer>
				{/* Link if is in read only */}
				{props.link && (props.readOnly || props.disabled || props.readOnlyDelete) && (props.selectedValue != null) && typeof props.selectedValue.value === 'object' && props.selectedValue.value && 'id' in props.selectedValue.value
					? (
						<Link
							href={`${props.link}${props.selectedValue.value?.id}`}
							width={width}
							height={height}
						/>
					)
					: props.readOnly || props.disabled
						? (
							<Link empty width={width} height={height} />
						)
						: (<></>)}

				{/* Input */}
				{(props.JSXButton != null) && (
					<div
						style={{ cursor: 'pointer', height: '100%', width: '100%' }}
						onClick={() => setOpen(!isOpen)}
					>
						{props.JSXButton(handleInput)}
					</div>
				)}
				{(props.JSXButton == null) &&
					<InputContainer width={width} height={height} onClick={props.onClick}>
						{
							props.selectedValue?.image &&
							<OptionImage src={props.selectedValue.image} alt={typeof props.selectedValue.label === 'string' ? props.selectedValue?.label : undefined} />
						}
						<InputBlock
							className={props.dropdownStyle?.inputClassName}
							id={props.id}
							name={props.name}
							type="text"
							autoComplete="off"
							onChange={e => handleInput(e.target.value)}
							value={label}
							required={props.required}
							readOnly={props.readOnly || props.readOnlyDelete}
							disabled={props.disabled}
							placeholder={props.placeholder ?? translateToString('search')}
							fontSize={props.dropdownStyle?.fontSize}
							fontColor={props.dropdownStyle?.fontColor}
							fontWeight={props.dropdownStyle?.fontWeight}
						/>

						{/* Clean button */}
						{label && !props.readOnly && (
							<CleanButton
								className="btn btn-transparent p-0"
								type="button"
								onClick={async e => {
									e.stopPropagation();
									setLabel('');
									props.onChange?.(null);
									setOpen(false);
									if (props.onSearchChange !== undefined) {
										setLoadingState('loading');
										const searchRes: boolean | undefined = await props.onSearchChange?.('', undefined);
										setIsAtTheEnd(searchRes == undefined ? true : searchRes);
									}
								}}
							>
								<i className="fas fa-times" />
							</CleanButton>
						)}
						<ArrowBlock onClick={() => setOpen(!isOpen)} className={props.dropdownStyle?.arrowClassName}/>
					</InputContainer>
				}

				{/* Dropdown options */}
				{
					isOpen &&
					<OptionContainer top={height} height={props.dropdownStyle?.optionHeight ?? optionHeight} width={props.dropdownStyle?.optionWidth ?? width}>
						{props.datalist.length > 0
							? (
								<OptionList padding='0'>
									{list.map((option, i) => {
										return (
											<OptionBlock key={`DropdownSearchListing[${i}]`} onClick={() => {
												setLabel(option.label);
												setOpen(false);
												props.onChange?.(option);
											}}>
												{
													option.image &&
												<OptionImage src={option.image} alt={typeof option.label === 'string' ? option.label : undefined} />
												}
												<OptionText ellipsis>{option.label}</OptionText>
											</OptionBlock>
										);
									})}
								</OptionList>
							)
							: (
								<NoResultText>
									<Translate id="No results found" />
								</NoResultText>
							)}

						{/* load more button */}
						{!isAtTheEnd && (
							<LoadMoreBlock
								onClick={async() => {
									if (props.onSearchChange !== undefined) {
										setLoadingState('loading');
										const searchRes: boolean | undefined = await props.onSearchChange?.(label, props.datalist.length);
										setIsAtTheEnd(searchRes == undefined ? true : searchRes);
									}
								}}>
								<LoadMoreText>
									<TranslateOrLoad
										isLoading={loadingState === 'loading'}
									/>
								</LoadMoreText>
							</LoadMoreBlock>
						)}
					</OptionContainer>

				}
			</DropdownContainer>
		</div>
	);
}

const LegacyContainer = styled.div`
	padding: 0 12.5px;
	width: 75%;
	.dropdown-input {
		width: calc(100% - 33px);
	}
	.dropdown-arrow {
		width: 33px;
	}
`;

type ValueLabel = { value: number, label: string };

export function LegacyWrapperDropdownSearch(props: {
	input: {
		name: string,
		onBlur: (e) => void
		onDragStart: (e) => void
		onDrop: (e) => void
		onFocus: (e) => void
		onChange: (props: ValueLabel | null | 0) => void,
		value: string | ValueLabel
	},
	label: React.ReactNode,
	meta,
	modalSlug: string,
	placeholder: string,
	requiredStar?: boolean,
	isFormData?: boolean,
	readOnly?: boolean
}): JSX.Element {
	React.useEffect(() => {
		if (props.isFormData) props.input.onChange(0);
	}, []);
	const [optionCompanies, setOptionCompanies] = React.useState<ValueLabel[]>([]);

	let defaultValue: ValueLabel | null = null;
	if (typeof props.input.value === 'object') {
		defaultValue = props.input.value;
	}

	return <div className="form-group row">
		<div className="col-md-3 text-right mt-2">
			<label htmlFor="label">
				{props.requiredStar === true ? (
					<span className="text-required">* </span>
				) : (
					''
				)}
				{props.label || 'Label'}
			</label>
		</div>
		<LegacyContainer>
			<DropdownSearch<number>
				readOnly={props.readOnly}
				dropdownStyle={{
					width: '100%',
					containerWidth: '100%',
					inputClassName: 'dropdown-input',
					arrowClassName: 'dropdown-arrow',
				}}
				datalist={optionCompanies}
				selectedValue={defaultValue}
				name={props.modalSlug}
				onChange={value => {
					if (value === null) return props.input?.onChange?.(value);
					if (typeof value.label !== 'string') return;
					props.input.onChange({ value: value.value, label: value.label });
				}}
				onSearchChange={async(search, offset) => {
					return await getFilteredCompanies(search, 20, offset)
						.then(res => {
							const ret: boolean = res.data.length == 0 || res.data.length < 20;
							if (offset !== undefined) {
								setOptionCompanies([...optionCompanies, ...res.data.map((company) => ({
									label: company.name,
									value: company.id
								}))]);
							} else {
								setOptionCompanies(res.data.map(company => ({
									label: company.name,
									value: company.id
								})));
							}
							return ret;
						})
						.catch(error => {
							console.log(error);
							return false;
						});
				}}
			/>
		</LegacyContainer>
	</div>;
}

export function DropdownSearchCompanies(props: {
	selectedValue?: DropdownData<ClientCompany> | null
	onChange?: (value: DropdownData<ClientCompany> | null) => void
	onSearchChange?: (search: string, offset: number | undefined) => Promise<boolean>
	defaultSearch?: string
	name: string
	id?: string
	selectedCompanyId?: number
	required?: boolean
	label?: string
	disabled?: boolean
	readOnly?: boolean
	readOnlyDelete?: boolean
	placeholder?: string
	width?: number
	height?: number
	optionHeight?: number
	labelUp?: boolean
	link?: string
	dropdownStyle?: DropdownStyle
	JSXButton?: (handleInput: (e: string) => void) => JSX.Element
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	parent?: boolean
}) {
	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);
	return <DropdownSearch<ClientCompany>
		{...props}
		selectedValue={props.selectedValue ?? optionCompanies.find(company => company.value.id == props.selectedCompanyId)}
		onSearchChange={async(search, offset) => {
			props.onSearchChange?.(search, offset);
			try {
				const res = await getFilteredCompanies(search, 20, offset, props.parent);
				const ret: boolean = res.data.length == 0 || res.data.length < 20;
				if (offset !== undefined) {
					setOptionCompanies([...optionCompanies, ...res.data.map((company: ClientCompany) => {
						return {
							label: company.name,
							value: company
						};
					})]);
				} else {
					setOptionCompanies(res.data.map(company_1 => {
						return {
							label: company_1.name,
							value: company_1
						};
					}));
				}
				return ret;
			} catch (error) {
				console.log(error);
				return false;
			}
		}}
		datalist={optionCompanies}
	/>;
}