function validateNumber(value): boolean {
	return (value && /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/.test(value));
}

function validateNumberAndEmpty(value): boolean {
	return (value.length === 0) || (value && /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/.test(value));
}

function validateLimitPourcentage(value): boolean {
	return parseFloat(value) >= 0 && parseFloat(value) <= 100;
}

function validateLimitPourcentageAndEmpty(value): boolean {
	return (value.length === 0) || parseFloat(value) >= 0 && parseFloat(value) <= 100;
}

function validateIntegerNumber(value): boolean {
	return /^\d+$/.test(value);
}

function validateIntegerNumberAndEmpty(value): boolean {
	return (value.length === 0) || /^\d+$/.test(value);
}

function validateFloatNumber(value): boolean {
	return !isNaN(parseFloat(value));
}

function validatePositiveNumber(value): boolean {
	return validateFloatNumber(value) && parseFloat(value) >= 0;
}

function validatePositiveNumberAndEmpty(value): boolean {
	return (value.length === 0) || validatePositiveNumber(value);
}

function emailValid(value: string): boolean {
	return value.trim().length === 0 || /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value);
}

export function validatePhoneNumber(value: string): boolean {
	return value.trim().length === 0 || /^(?:(?:\+|00)\d\d|0)\s*[1-9](?:[\s.-]*\d*)*$/.test(value);
}

export function validateWebsite(value: string): boolean {
	return value.trim().length === 0 || /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(value);
}

function maxLength(value, max: number): boolean {
	return !!(value && value.length <= max);
}

function minLength(value, min: number): boolean {
	return !!(value && value.length >= min);
}

function normalizeComma(value: string): string {
	return value.replace(',', '.');
}

function normalizeInt(value: string): string {
	const v = parseInt(value);
	if (isNaN(v)) { return ''; }
	if (v > 2147483647) { return '999999999'; }
	return v.toString();
}

export function normalizeIntAbove0Nullable(value: string): string {
	if (value.length == 0) return '';
	return normalizeIntAbove0(value);
}

export function normalizeIntAbove0(value: string): string {
	const v = parseInt(value);
	if (isNaN(v) || v < 0) { return '0'; }
	if (v > 2147483647) { return '999999999'; }
	return v.toString();
}

const normalizeFloat = (decimal?: number) => (value: string): string => {
	const splitted = value.replaceAll(',', '.').split('.');
	let fst = parseInt(splitted[0]).toString();
	if (fst === 'NaN') {
		fst = '';
		if (splitted[1]) {
			fst = '0';
		}
	}
	let snd = parseInt(splitted[1] ?? '').toString();
	if (snd === 'NaN') {
		if (splitted[1] == undefined) { snd = ''; } else { snd = '.'; }
	} else {
		if (splitted[1]?.[0] == '0' && snd != '0') {
			snd = '0' + snd;
		}
		if (decimal != undefined) {
			snd = '.' + snd.slice(0, decimal);
		} else {
			snd = '.' + snd;
		}
	}
	return fst + snd;
};

export const normalizePrice = normalizeFloat(2);

function normalizeAddZero(value: string): string {
	return value.length > 0 && (value.startsWith(',') || value.startsWith('.')) ? '0' + value : value;
}

function normalizeEmptyNumber(value: string): string {
	return value.length === 0 ? '0' : value;
}

function normalizeCapital(value: string): string {
	return value.length > 0 ? `${value.charAt(0).toUpperCase()}${value.slice(1)}` : '';
}

function normalizeRemoveStartZero(value: string): string {
	let v = value;
	while (v.startsWith('0')) {
		v = v.slice(1);
	}
	return v;
}

export {
	validateNumber,
	validateNumberAndEmpty,
	validateIntegerNumber,
	validateFloatNumber,
	validatePositiveNumber,
	emailValid,
	maxLength,
	minLength,
	normalizeComma,
	normalizeAddZero,
	normalizeEmptyNumber,
	normalizeRemoveStartZero,
	validateIntegerNumberAndEmpty,
	validateLimitPourcentage,
	validatePositiveNumberAndEmpty,
	validateLimitPourcentageAndEmpty,
	normalizeCapital,
	normalizeFloat,
	normalizeInt
};
