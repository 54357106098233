import seeDetail from 'images/ui_icon/seeDetail.svg';
import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import Opportunities from '../linkedElements/OpportunitiesMixt';
import Activity from '../linkedElements/Activity';
import Calendar from '../linkedElements/Calendar';
import Documents from '../linkedElements/Documents';
import Notes from '../linkedElements/Notes';
import PageLoader from '../../../../components/PageLoader';
import noUserImage from 'images/icons/user.svg';
import Overview from '../subDetails/Overview';
import Details from '../subDetails/Details';
import Activities from '../subDetails/Activities';

class ViewDetail extends Component {
	state = {
		activeTab: 1,
		activeHeadTab: 1,

		opportunitiesData: [],
		eventsData: [],
		activitiesData: [],
		notesData: [],
		documentsData: [],
		dataMerchadisings: [],
		dataTradeshows: [],
		salesOperations: []
	};

	componentDidMount() {
		const {
			data,
			getOpportunities,
			getNotes,
			getEvents,
			getActivities,
			getDocuments,
			getTradeshows,
			getMerchandisings
		} = this.props;
		if (data[0] !== undefined) {
			getOpportunities({
				limit: 3,
				page: 1,
				contactId: data[0].id
			});

			getEvents({
				limit: 3,
				page: 1,
				contactId: data[0].id
			});

			getActivities(
				{
					limit: 3,
					page: 1
				},
				data[0].id
			);

			getNotes({
				limit: 3,
				page: 1,
				contactId: data[0].id
			});

			getDocuments({
				limit: 3,
				page: 1,
				contactId: data[0].id,
				type: 'document'
			});
			getTradeshows(`contactId=${data[0].id}`);
			getMerchandisings(`contactId=${data[0].id}`);
		}
	}

	componentWillReceiveProps(nextProps) {
		const {
			detail,
			getOpportunities,
			getNotes,
			getEvents,
			getActivities,
			getDocuments,
			salesOperation,
			getTradeshows,
			getMerchandisings
		} = this.props;
		const {
			opportunitiesData,
			eventsData,
			activitiesData,
			notesData,
			documentsData,
			dataTradeshows,
			dataMerchadisings
		} = this.state;

		// On Change Tab
		if (detail.dataDetail.id !== nextProps.detail.dataDetail.id) {
			this.setState({
				opportunitiesData: [],
				notesData: [],
				documentsData: [],
				dataMerchadisings: [],
				dataTradeshows: [],
				salesOperations: []
			});

			getOpportunities({
				limit: 3,
				page: 1,
				contactId: nextProps.detail.dataDetail.id
			});

			getNotes({
				limit: 3,
				page: 1,
				contactId: nextProps.detail.dataDetail.id
			});

			getEvents({
				limit: 3,
				page: 1,
				contactId: nextProps.detail.dataDetail.id
			});

			getActivities(
				{
					limit: 3,
					page: 1
				},
				nextProps.detail.dataDetail.id
			);

			getDocuments({
				limit: 3,
				page: 1,
				contactId: nextProps.detail.dataDetail.id,
				type: 'document'
			});
			getTradeshows(`contactId=${nextProps.detail.dataDetail.id}`);
			getMerchandisings(`contactId=${nextProps.detail.dataDetail.id}`);
		}

		// On See Less
		if (nextProps.detail.paginationOpportunities.currentPage <= 1) {
			this.setState({
				opportunitiesData: nextProps.detail.opportunities
			});
		}
		if (nextProps.detail.paginationEvents.currentPage <= 1) {
			this.setState({
				eventsData: nextProps.detail.events
			});
		}
		if (nextProps.detail.paginationActivities.currentPage <= 1) {
			this.setState({
				activitiesData: nextProps.detail.activities
			});
		}
		if (nextProps.detail.paginationNotes.currentPage <= 1) {
			this.setState({
				notesData: nextProps.detail.notes
			});
		}
		if (nextProps.detail.paginationDocuments.currentPage <= 1) {
			this.setState({
				documentsData: nextProps.detail.documents
			});
		}

		// On See More
		if (nextProps.detail.paginationOpportunities.currentPage > 1) {
			if (detail.opportunities !== nextProps.detail.opportunities) {
				const dataArray = opportunitiesData;
				nextProps.detail.opportunities.map(detail => dataArray.push(detail));

				this.setState({
					opportunitiesData: dataArray
				});
			}
		}

		if (nextProps.detail.paginationEvents.currentPage > 1) {
			if (detail.events !== nextProps.detail.events) {
				const dataArray = eventsData;
				nextProps.detail.events.map(detail => dataArray.push(detail));

				this.setState({
					eventsData: dataArray
				});
			}
		}

		if (nextProps.detail.paginationActivities.currentPage > 1) {
			if (detail.activities !== nextProps.detail.activities) {
				const dataArray = activitiesData;
				nextProps.detail.activities.map(detail => dataArray.push(detail));

				this.setState({
					activitiesData: dataArray
				});
			}
		}

		if (nextProps.detail.paginationNotes.currentPage > 1) {
			if (detail.notes !== nextProps.detail.notes) {
				const dataArray = notesData;
				nextProps.detail.notes.map(detail => dataArray.push(detail));

				this.setState({
					notesData: dataArray
				});
			}
		}

		if (nextProps.detail.paginationDocuments.currentPage > 1) {
			if (detail.documents !== nextProps.detail.documents) {
				const dataArray = documentsData;
				nextProps.detail.documents.map(detail => dataArray.push(detail));

				this.setState({
					documentsData: dataArray
				});
			}
		}
		if (salesOperation !== nextProps.salesOperation) {
			this.setState({
				dataMerchadisings: [],
				dataTradeshows: [],
				salesOperations: []
			});
			if (
				salesOperation.dataTradeshows !==
        nextProps.salesOperation.dataTradeshows
			) {
				if (nextProps.salesOperation.dataTradeshows.length !== 0) {
					const dataTradeshows = nextProps.salesOperation.dataTradeshows.map(
						data => ({
							id: data.id,
							event: data.tradeshow.event,
							companies: data.tradeshow.clientCompanyName,
							date: data.tradeshow.createdAt,
							type: 'tradeshows'
						})
					);
					this.setState({ dataTradeshows });
				}
			}
			if (
				salesOperation.dataMerchadisings !==
        nextProps.salesOperation.dataMerchadisings
			) {
				if (nextProps.salesOperation.dataMerchadisings.length !== 0) {
					const dataMerchadisings = nextProps.salesOperation.dataMerchadisings.map(
						data => ({
							id: data.id,
							campaign: data.merchandise.campaign,
							companies: data.merchandise.clientCompany.name,
							date: data.merchandise.createdAt,
							type: 'merchandise'
						})
					);
					this.setState({ dataMerchadisings });
				}
			}

			this.setState({
				salesOperations: dataTradeshows.concat(dataMerchadisings)
			});
		}
	}

	onFetchOpportunities = page => {
		const { detail, getOpportunities } = this.props;

		getOpportunities({
			limit: 3,
			page: page || detail.paginationOpportunities.currentPage + 1,
			contactId: detail.dataDetail.id
		});
	};

	onFetchEvents = page => {
		const { detail, getEvents } = this.props;

		getEvents({
			limit: 3,
			page: page || detail.paginationEvents.currentPage + 1,
			contactId: detail.dataDetail.id
		});
	};

	onFetchActivities = page => {
		const { detail, getActivities } = this.props;

		getActivities(
			{
				limit: 3,
				page: page || detail.paginationActivities.currentPage + 1
			},
			detail.dataDetail.id
		);
	};

	onFetchNotes = page => {
		const { detail, getNotes } = this.props;

		getNotes({
			limit: 3,
			page: page || detail.paginationNotes.currentPage + 1,
			contactId: detail.dataDetail.id
		});
	};

	onFetchDocuments = page => {
		const { detail, getDocuments } = this.props;

		getDocuments({
			limit: 3,
			page: page || detail.paginationDocuments.currentPage + 1,
			contactId: detail.dataDetail.id,
			type: 'document'
		});
	};

	toggle = tab => {
		this.setState({
			activeTab: tab
		});
	};

	toggleHead = tab => {
		this.setState({
			activeHeadTab: tab
		});
	};

	render() {
		const { detail, route, currentDetailId } = this.props;
		const { dataDetail } = detail;
		const { activeTab, activeHeadTab, salesOperations } = this.state;

		return (
			(<div>
				<div className="mx-3 detail-top my-0">
					<Nav className="detail-head-tab d-flex justify-content-end">
						<NavItem>
							<NavLink
								active={activeHeadTab === 1}
								onClick={() => {
									this.toggleHead(1);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="overview" />
								</h2>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={activeHeadTab === 2}
								onClick={() => {
									this.toggleHead(2);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="details" />
								</h2>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={activeHeadTab === 3}
								onClick={() => {
									this.toggleHead(3);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="activities" />
								</h2>
							</NavLink>
						</NavItem>
					</Nav>
					{detail.isLoadingDetail ? (
						<div>
							<PageLoader />
						</div>
					) : (
						<div className="p-3 pb-5">
							<div className="row mb-3 detail-head">
								<div className="col-1">
									{dataDetail.photoUrl ? (
										<img
											src={dataDetail.photoUrl}
											className="rounded-circle image-thumbnail"
											alt=""
											width="50"
											height="50"
										/>
									) : (
										<div className="no-contact-thumbnail-big d-flex justify-content-center">
											<span className="my-auto">
												{dataDetail.firstName && dataDetail.firstName.charAt(0)}
												{dataDetail.lastName && dataDetail.lastName.charAt(0)}
											</span>
										</div>
									)}
									{/* <img
                    src={dataDetail.photoUrl || noContactImage}
                    className={
                      dataDetail.photoUrl && 'rounded-circle image-thumbnail'
                    }
                    alt=""
                    width="50"
                    height="50"
                  /> */}
								</div>
								<div className="col-11">
									<h4>
										<strong>
											{dataDetail.firstName || '-'} {dataDetail.lastName || '-'}
										</strong>
									</h4>
									<a href={`${route.path}/detail/${currentDetailId}`}>
										<img
											src={seeDetail}
											alt=""
											className="custom-icon-views"
											style={{
												position: 'absolute',
												right: '5px',
												top: '10px'
											}}
										/>
									</a>
									<img
										src={
											(dataDetail &&
                        dataDetail.owner &&
                        dataDetail.owner.photoUrl) ||
                      noUserImage
										}
										alt=""
										className="rounded-circle image-thumbnail"
										width="30"
										height="30"
									/>
									<span>
										{dataDetail && dataDetail.owner && dataDetail.owner.name}
									</span>
								</div>
							</div>
							{activeHeadTab === 1 && <Overview dataDetail={dataDetail} />}
							{activeHeadTab === 2 && <Details dataDetail={dataDetail} />}
							{activeHeadTab === 3 && <Activities dataDetail={dataDetail} />}
						</div>
					)}
				</div>
				<Nav className="text-center d-flex justify-content-start tab-custom m-3">
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 1}
							onClick={() => {
								this.toggle(1);
							}}
						>
							<h2 className="card-title mb-0">
								<Translate id="overview" />
							</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 3}
							onClick={() => {
								this.toggle(3);
							}}
						>
							<h2 className="card-title mb-0">
								<Translate id="event" />
							</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 2}
							onClick={() => {
								this.toggle(2);
							}}
						>
							<h2 className="card-title mb-0">
								<Translate id="opportunities" />
							</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 4}
							onClick={() => {
								this.toggle(4);
							}}
						>
							<h2 className="card-title mb-0">
								<Translate id="activities" />
							</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 5}
							onClick={() => {
								this.toggle(5);
							}}
						>
							<h2 className="card-title mb-0">Notes</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 6}
							onClick={() => {
								this.toggle(6);
							}}
						>
							<h2 className="card-title mb-0">Documents</h2>
						</NavLink>
					</NavItem>
					{/* <NavItem className="m-auto">
            <NavLink
              active={activeTab === 7}
              onClick={() => {
                this.toggle(7);
              }}
            >
              <h2 className="card-title mb-0">
                <Translate id="sales operations" />
              </h2>
            </NavLink>
          </NavItem> */}
				</Nav>
				<div className="tab-content">
					<div
						className={
							activeTab === 1 || activeTab === 3
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Calendar
							data={this.state.eventsData}
							id={{
								contactId: dataDetail && dataDetail.id,
								clientCompanyId: dataDetail && dataDetail.clientCompanyId
							}}
							isFirstPage={detail.paginationEvents.currentPage === 1}
							isLastPage={
								detail.paginationEvents.currentPage >=
                detail.paginationEvents.lastPage
							}
							count={detail.paginationEvents.count}
							isLoading={detail.isLoadingEvents}
							onFetch={this.onFetchEvents}
						/>
					</div>
					<div
						className={
							activeTab === 1 || activeTab === 2
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Opportunities
							data={this.state.opportunitiesData}
							id={{
								contactId: dataDetail && dataDetail.id,
								clientCompanyId: dataDetail && dataDetail.clientCompanyId
							}}
							isFirstPage={detail.paginationOpportunities.currentPage === 1}
							isLastPage={
								detail.paginationOpportunities.currentPage >=
                detail.paginationOpportunities.lastPage
							}
							count={detail.paginationOpportunities.count}
							isLoading={detail.isLoadingOpportunities}
							onFetch={this.onFetchOpportunities}
						/>
					</div>
					<div
						className={
							activeTab === 1 || activeTab === 4
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Activity
							data={this.state.activitiesData}
							id={{
								contactId: dataDetail && dataDetail.id,
								clientCompanyId: dataDetail && dataDetail.clientCompanyId
							}}
							isFirstPage={detail.paginationActivities.currentPage === 1}
							isLastPage={
								detail.paginationActivities.currentPage >=
                detail.paginationActivities.lastPage
							}
							count={detail.paginationActivities.count}
							isLoading={detail.isLoadingActivities}
							onFetch={this.onFetchActivities}
							firstName={dataDetail.firstName && dataDetail.firstName}
							lastName={dataDetail.lastName && dataDetail.lastName}
						/>
					</div>
					<div
						className={
							activeTab === 1 || activeTab === 5
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Notes
							data={this.state.notesData}
							id={{
								contactId: dataDetail && dataDetail.id,
								clientCompanyId: dataDetail && dataDetail.clientCompanyId
							}}
							isFirstPage={detail.paginationNotes.currentPage === 1}
							isLastPage={
								detail.paginationNotes.currentPage >=
                detail.paginationNotes.lastPage
							}
							count={detail.paginationNotes.count}
							isLoading={detail.isLoadingNotes}
							onFetch={this.onFetchNotes}
						/>
					</div>
					<div
						className={
							activeTab === 1 || activeTab === 6
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Documents
							data={this.state.documentsData}
							id={{
								contactId: dataDetail && dataDetail.id,
								clientCompanyId: dataDetail && dataDetail.clientCompanyId
							}}
							isFirstPage={detail.paginationDocuments.currentPage === 1}
							isLastPage={
								detail.paginationDocuments.currentPage >=
                detail.paginationDocuments.lastPage
							}
							count={detail.paginationDocuments.count}
							isLoading={detail.isLoadingDocuments}
							onFetch={this.onFetchDocuments}
						/>
					</div>
					{/* <div
            className={
              activeTab === 1 || activeTab === 7
                ? 'tab-pane active'
                : 'tab-pane'
            }
          >
            <SalesOperations
              data={dataDetail && detailData}
              dataSalesOperation={
                salesOperations !== undefined &&
                salesOperations &&
                salesOperations
              }
              id={{
                contactId: dataDetail && dataDetail.id,
                clientCompanyId: dataDetail && dataDetail.clientCompanyId
              }}
            />
          </div> */}
				</div>
			</div>)
		);
	}
}

export default ViewDetail;
