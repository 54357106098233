import Store from 'beedle';
import Dexie from 'dexie';
import _ from 'lodash';
// import ID from './services/UUID';
import { post } from './requests';
import { EndSection, EndTable, StartSection, StartTable } from './staticData';
import ID from './UUID';

let _saveUrl;
let _onPost;
let _onLoad;

const db = new Dexie('FormBuilderDB');

db.version(1).stores({
	elements: 'id'
});

const getData = async() => {
	const result = await db.elements.get('elements');
	const data = result ? result.data : [];
	return data;
};

const store = new Store({
	actions: {
		setData(context, data, saveData) {
			context.commit('setData', data);
			if (saveData) this.save(data);
			//
			db.elements
				.put({ id: 'elements', data })
				.then(() => {})
				.then(res => {
					// console.log('res', res);
				})
				.catch(error => {
					console.log('Ooops: ' + error);
				});
		},

		load(context, { loadUrl, saveUrl, data, isInit = false }) {
			_saveUrl = saveUrl;

			if (isInit) {
				if (_onLoad) {
					_onLoad().then(x => this.setData(context, x));
				} else if (loadUrl) {
					getData().then(x => {
						if (data && data.length > 0 && x.length === 0) {
							data.forEach(y => x.push(y));
						}
						this.setData(context, x);
					});
				} else {
					this.setData(context, data);
				}
			} else {
				this.setData(context, data);
			}
		},

		create(context, element) {
			const { data } = context.state;
			const el = _.get(element, 'element');

			if (el === 'StartSection' || el === 'EndSection') {
				data.push(StartSection());
				data.push(EndSection());
			} else if (el === 'StartTable' || el === 'EndTable') {
				data.push(StartTable());
				data.push(EndTable());
			} else {
				data.push(element);
			}
			// console.log('data', data);
			this.setData(context, data, true);
		},

		duplicate(context, element) {
			const { data } = context.state;
			const newElement = { ...element };
			const indexToInsert = data.findIndex(item => item.id === element.id) + 1;

			newElement.id = ID.uuid();
			data.splice(indexToInsert, 0, newElement);
			this.setData(context, data, true);
		},

		delete(context, element) {
			const { data } = context.state;
			data.splice(data.indexOf(element), 1);
			this.setData(context, data, true);
		},

		updateOrder(context, elements) {
			this.setData(context, elements, true);
		},

		deleteDB() {
			db.delete();
		},

		save(data) {
			if (_onPost) {
				_onPost({ task_data: data });
			} else if (_saveUrl) {
				post(_saveUrl, { task_data: data });
			}
		}
	},

	mutations: {
		setData(state, payload) {
			// eslint-disable-next-line no-param-reassign
			state.data = payload;
			return state;
		}
	},

	initialState: {
		data: []
	}
});

store.setExternalHandler = (onLoad, onPost) => {
	_onLoad = onLoad;
	_onPost = onPost;
};

export default store;
