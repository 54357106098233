import React, {Component} from 'react';
import ComponentHeader from './ComponentHeader';

class StartSection extends Component {
  state = {};

  render() {
    const { id, editElementId } = this.props;
    const isActive = id === editElementId ? 'active' : '';

    let baseClasses = 'SortableItem rfb-item bg-soft-dark';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }

    return (
      <div className={`${baseClasses} ${isActive}`}>
        <ComponentHeader {...this.props} />
      </div>
    );
  }
}

export default StartSection;
