import React, {Component} from 'react';

class PropEndTable extends Component {
  state = {};

  render() {
    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>End Table</option>
          </select>
        </div>
      </div>
    );
  }
}

export default PropEndTable;
