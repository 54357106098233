const fieldTypeSetter = fieldType => {
	switch (fieldType) {
		case 'StartSection':
			return 'section';

		case 'EndSection':
			return 'section';

		case 'StartTable':
			return 'table';

		case 'EndTable':
			return 'table';

		case 'Header':
			return 'header';

		case 'Image':
			return 'image';

		case 'FreeText':
			return 'free_text';

		case 'Numerical':
			return 'numerical';

			// case 'Website':
			//   return 'website';

		case 'DropdownList':
			return 'dropdown';

		case 'DatePicker':
			return 'date';

		case 'Checkbox':
			return 'checkbox';

		case 'Email':
			return 'email';

		case 'AddressField':
			return 'address_field';

		case 'Document':
			return 'document';

		case 'UserField':
			return 'user';

		case 'Hyperlink':
			return 'hyperlink';

		case 'Calculation':
			return 'calculation';

		case 'Camera':
			return 'camera';

		default:
			return null;
	}
};

const fieldNameSetter = fieldType => {
	switch (fieldType) {
		case 'StartSection':
			return 'Start Section';

		case 'EndSection':
			return 'End Section';

		case 'StartTable':
			return 'Start Table';

		case 'EndTable':
			return 'End Table';

		case 'Header':
			return 'Header';

		case 'Image':
			return 'Image';

		case 'FreeText':
			return 'Free Text';

		case 'Numerical':
			return 'Numerical';

			// case 'Website':
			//   return 'website';

		case 'DropdownList':
			return 'Dropdown';

		case 'DatePicker':
			return 'date';

		case 'Checkbox':
			return 'Checkbox';

		case 'Email':
			return 'Email';

		case 'AddressField':
			return 'Address Field';

		case 'Document':
			return 'Document';

		case 'UserField':
			return 'User Field';

		case 'Hyperlink':
			return 'Hyperlink';

		case 'Calculation':
			return 'Calculation';

		case 'Camera':
			return 'Camera';

		default:
			return null;
	}
};

const fieldSectionSetter = fieldType => {
	switch (fieldType) {
		case 'StartSection':
			return true;

		case 'EndSection':
			return false;

		case 'StartTable':
			return true;

		case 'EndTable':
			return false;

		default:
			return null;
	}
};

const isStartSection = type => {
	if (type === 'StartSection') {
		return true;
	}
	return false;
};

const isEndSection = type => {
	if (type === 'EndSection') {
		return true;
	}
	return false;
};

const isStartTable = type => {
	if (type === 'StartTable') {
		return true;
	}
	return false;
};

const isEndTable = type => {
	if (type === 'EndTable') {
		return true;
	}
	return false;
};

export {
	fieldSectionSetter,
	fieldNameSetter,
	fieldTypeSetter,
	isStartSection,
	isEndSection,
	isStartTable,
	isEndTable
};
