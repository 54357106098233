import * as React from 'react';
import Popup from '../../components_v2/popup/Popup';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { Period, Promotion, PromotionCompany, PromotionProduct } from './model';
import PopupCreation, { ButtonStyle } from '../../components_v2/popup/PopupCreation';
import { Translate, translateToString } from '../../styles/global/translate';
import Input from '../../components_v2/input/Input';
import { CreationPopupContainer, PartialOpen, creationPopupInputStyle as globalCreationPopupInputStyle } from '../client-companies/style/Style';
import { normalizeCapital } from '../globals/FieldFunction';
import { HorizontalContainer, SwitchText, TextArea, TextAreaContainer, TextAreaLabel } from '../calendar/style/NewEventStyle';
import Switch from '../../components_v2/Switch/Switch';
import { InputStyle } from '../../components_v2/input/model/Model';
import { FlexDiv } from '../products/style';
import { RawDatePicker } from '../../components_v2/toolbarFilter/time/DatePicker';
import * as moment from 'moment';
import Add from '../../components_v2/add/Add';
import { DeleteDot } from '../../styles/global/css/Dot';
import { PostPromotionBody, PutPromotionBody, deletePromotion, getPromotionById } from './actions';
import DropdownColor from '../../components_v2/dropdown/DropdownColors';
import { BlueSidely, BorderColor, BorderColor2, RedSidely, GreySidely } from '../../styles/global/css/Utils';
import { Color } from '../../atoms/colors/colors';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { LeftContainer, RightContainer } from '../client-companies/style/PopupStyle';
import { Column, Table } from '../../components_v2/table/Table';
import { TableRow } from '../orders/templateOrders/style/Style';
import trash_black from 'images/ui_icon/trash_black.svg';
import styled from 'styled-components';
import { PanelSelector } from '../../components_v2/Selector/PanelSelector';
import { useRefState } from '../../utils/customHooks';
import { DropdownPopupSearch } from '../../components_v2/dropdown/DropdownTagsCloud';
import { LoadingStateEnum } from '../import/model';
import { getFilteredCompanies } from '../orders/data/Data';
import { get_products } from '../products/action';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { ModalState } from '../products/model';
import equal from 'deep-equal';
import eye from 'images/icons/company/eye.svg';
import ProductPopup from '../products/product';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import useAlert from '../alert/UseAlert';
import { AlertContext, AlertRes } from '../alert/AlertProvider';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import optionGrey from 'images/icon/options_grey.png';
import orderBox from 'images/icons/orders/order_box.svg';
import companyImage from 'images/icons/company/company.svg';
import Restricted from '../permissions/Restricted';
import { Unhautorized } from '../noData/NoData';
import PermissionContext from '../permissions/PermissionContext';


const Trash = styled.img`
	width: 25px;
	height: 25px;
	cursor: pointer;
`;

const creationPopupInputStyle: InputStyle = {
	...globalCreationPopupInputStyle,
	border: '1px solid #DADADA'
};

export const creationPopupInputStyleMargin: InputStyle = {
	...creationPopupInputStyle,
	margin: `0 ${globalCreationPopupInputStyle.marginRight ?? 0} 21px ${globalCreationPopupInputStyle.marginLeft ?? 0}`
};

const Title = styled.h5<{notRequired?: boolean}>`
	font-size: 12px;
	margin: 0;
	font-weight: 400;

	${({ notRequired }) => notRequired ? '' : `
		&:before {
			content: '*';
			color: ${RedSidely};
			margin-right: 3px;
		}
	`}
`;

const TitlePeriod = styled(Title)`
	font-size: 13px;
`;

function genDefaultPeriod(): Period {
	return {} as Period;
}

function PeriodEditor(props: { period: Period, index: number, setPeriods: React.Dispatch<React.SetStateAction<Period[]>>, readOnly: boolean | undefined, periods: Period[] }) {
	const { period, index, setPeriods } = props;

	function onDatesChange(props: { startDate: moment.Moment, endDate: moment.Moment }) {
		// eslint-disable-next-line react/prop-types
		period.start = props.startDate?.toISOString() ?? period.start;
		// eslint-disable-next-line react/prop-types
		period.end = props.endDate?.toISOString() ?? period.end;
		setPeriods(p => [...p]);
	}
	return <FlexDiv gap='6px' flow='column' align='stretch' >
		<FlexDiv gap='0.5em' justify='space-between'>
			<TitlePeriod>{translateToString('period') + ' ' + index}</TitlePeriod>
			{!props.readOnly && <DeleteDot size='20px' onClick={() => setPeriods(p => {
				p.splice(index - 1, 1);
				return [...p];
			})} />}
		</FlexDiv>
		<FlexDiv gap='12px' flow='column' align='stretch' padding='15px' border={'1px solid ' + BorderColor2} borderRadius='5px'>
			<FlexDiv justify='space-between' align='stretch'>
				<FlexDiv gap='12px' flow='column'>
					<Title notRequired>{translateToString('color')}</Title>
					<DropdownColor
						dotSize='30px'
						disabled={props.readOnly}
						name={`periodColor[${index}]`}
						selectedValue={period.color ?? BlueSidely}
						autoOptionUp
						onChange={(v: DropdownData<Color>) => {
							period.color = v.value.colorCode;
							setPeriods(p => [...p]);
						}}
					/>
				</FlexDiv>
				<FlexDiv gap='6px' width='250px' flow='column' align='stretch'>
					<Title>{translateToString('title')}</Title>
					<Input
						required
						disabled={props.readOnly}
						inputStyle={creationPopupInputStyle}
						name={`periodTitle[${index}]`}
						type="text"
						placeholder={translateToString('name_period') + ' ' + index}
						normalize={[normalizeCapital]}
						onChange={value => {
							period.name = value;
							setPeriods(p => [...p]);
						}}
						value={period.name}
					/>
				</FlexDiv>
			</FlexDiv>
				
			<FlexDiv gap='6px' flow='column' align='stretch'>
				<Title>{translateToString('date')}</Title>
				<RawDatePicker
					button='hours'
					zIndex='unset'
					height='50px'
					fillWidth
					onTimeChange={onDatesChange}
					onDatesChange={onDatesChange}
					start={period.start ? moment(period.start) : null}
					end={period.end ? moment(period.end) : null}
					numberOfMonths={1}
					future
					readOnly={props.readOnly}
				/>
			</FlexDiv>
		</FlexDiv>
	</FlexDiv>;
}

const SeperatorWithColor = styled.div`
	width:100%;
	height:1px;
	background-color:${BorderColor};
`;

const DropdownLabel = styled.p`
	${DefaultText};
	padding: 5px 10px;	
`;

type Props = {
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	promotion?: Promotion,
	onCreate?: (body: PostPromotionBody) => void,
	onUpdate?: (id: number, body: PutPromotionBody) => void,
	onDelete?: (id: number) => void,
	readOnly?: boolean,
	promotionId?: number
}
type ProductAndCompanyProps = {
	products: PromotionCompany[],
	companies: PromotionCompany[]
}

type ProductAndCompanyHookProps = ProductAndCompanyProps & {
	setProducts: React.Dispatch<React.SetStateAction<PromotionProduct[]>>,
	setCompanies: React.Dispatch<React.SetStateAction<PromotionCompany[]>>
}

export const DotWrapper = styled.div`
	position:absolute;
	top: 10px;
	right: 20px;
`;

const DropdownWrapper = styled.div<{ rect?: DOMRect, isOpen: boolean }>`
	position: absolute;
	${({ isOpen }) => isOpen ? '' : 'display: none;'}
	left: calc(${({ rect }) => rect?.left ?? 0}px - 70px);
	translate: -200px 100px;
	width: 240px;
	&:after {
		content: "";
		position: absolute;
		translate: calc(-100% - 7px) -100%;
		left: 100%;
		margin-left: -5px;
		border-width: 6px;
		border-style: solid;
		border-color: transparent transparent ${BorderColor2} transparent;
	}
`;

function RightPart(props: Props & ProductAndCompanyHookProps & { readOnly?: boolean, promotion?: Promotion}) {
	const { promotion, products, setProducts, companies, setCompanies } = props;
	const [dbList, setDbList] = React.useState<{ id: number, name: string }[]>([]);
	const [activeIndexRef, setActiveIndex, activeIndex] = useRefState<number>(0, ({ newValue }) => { onSearchChange('', undefined, newValue); return newValue; });
	const [isSearchOpen, setIsSearchOpen] = React.useState<ModalState<DOMRect>>({ isOpen: false });
	const [companyOpen, setCompanyOpen] = React.useState<ModalState<number>>({ isOpen: false });
	const [productOpen, setProductOpen] = React.useState<ModalState<number>>({ isOpen: false });
	const { alertDelete } = React.useContext(AlertContext);
	const { isAllowedTo } = React.useContext(PermissionContext);
	const allowed = isAllowedTo({ objectAction: promotion ? 'UpdateCampaign' : 'CreateCampaign' });


	React.useEffect(() => {
		onSearchChange();
	}, [props.companies]);

	const list = activeIndex === 0 ? companies : products;
	const onClick = (row: PromotionCompany) => activeIndexRef.current === 0 ? setCompanyOpen({ isOpen: true, data: row.id }) : setProductOpen({ isOpen: true, data: row.id });
	const companyColumns = React.useMemo((): Column<PromotionCompany>[] => {
		return [
			{
				id: 'name',
				Header: translateToString('name'),
				accessor: row => <TableRow cursor='pointer' fontWeight='500' onClick={() => onClick(row)}>{row.name}</TableRow>,
				width: undefined,
				minWidth: 150,
				maxWidth: undefined,
				type: 'string',
				toolTip: row => row.name,
				hoverImage: row => <PartialOpen src={eye} onClick={() => onClick(row)} />
			}
		];
	}, [allowed]);
	if (!props.readOnly && allowed) {
		companyColumns.push(
			{
				id: 'quick_actions',
				noHeaderEllipsis: true,
				Header: <Add onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => setIsSearchOpen({ isOpen: true, data: e.currentTarget.getBoundingClientRect() })}/>,
				accessor: row => <Trash src={trash_black} onClick={() => {
					if (activeIndexRef.current === 0) {
						setCompanies(companies => {
							const index = companies.findIndex(c => c.id === row.id);
							if (index < 0) return companies;
							companies.splice(index, 1);
							return [...companies];
						});
					} else {
						setProducts(products => {
							const index = products.findIndex(c => c.id === row.id);
							if (index < 0) return products;
							products.splice(index, 1);
							return [...products];
						});
					}
				}}/>,
				width: 45,
				minWidth: 45,
				disableSortBy: true,
				disableFilter: true,
				unresizeable: true,
				centerHeader: true
			});
	}

	async function onSearchChange(search = '', offset?: number, index = activeIndex) {
		const res = index === 0 ? (await getFilteredCompanies(search, 10, offset)).data : (await get_products({ search, offset, limit: 10 })).data.products;
		const currentList = index === 0 ? companies : products;
		try {
			const ret: boolean = res.length == 0 || res.length < 10;
			if (offset !== undefined) {
				setDbList(companies => [...companies, ...res.map(company => ({
					id: company.id,
					name: company.name,
				})).filter(({ id }) => !currentList.some(c => c.id === id))]);
			} else {
				setDbList(res.map(company => ({
					id: company.id,
					name: company.name,
				})).filter(({ id }) => !currentList.some(c => c.id === id)));
			}
			return ret;
		} catch (error) {
			return false;
		}

	}

	return <>
		{!props.readOnly && <Restricted to={{ objectAction: 'DeleteCampaign' }}>
			<DotWrapper>
				<Dropdown
					dropdownStyle={{
						optionLeft: '-170px',
						optionWidth: '200px',
						optionHeight: '200px',
						height: '25px'
					}}
					datalist={[{
						label: translateToString('delete'),
						value: 'DELETE'
					}]}
					name={'dropdownOptionEvent'}
					JSXButton={() => <img src={optionGrey} width={25} style={{ rotate: '90deg' }}/>}
					onChange={(value) => {
						switch (value.value) {
							case 'DELETE':
								alertDelete({ name: promotion?.name ?? '', zIndex: 999 }).then(res => {
									if (res == AlertRes.Ok && promotion) {
										deletePromotion(promotion.id).then(() => {
											props.setIsOpen(false);
											props.onDelete?.(promotion.id);
										});
									}
								});

						}}
					}/>
			</DotWrapper>
		</Restricted>}
		<PanelSelector panels={[{ img:companyImage, label: translateToString('companies') }, { img:orderBox, label: translateToString('products') }]} onClick={(_, i) => setActiveIndex(i)} activeIndex={activeIndex} />
		<SeperatorWithColor />
		<Table
			height='calc(100% - 45px)'
			columns={companyColumns}
			data={list}
		/>
		<DropdownWrapper rect={isSearchOpen.data} isOpen={isSearchOpen.isOpen}>
			<DropdownPopupSearch
				defaultIsAtTheEnd={false}
				loadingState={LoadingStateEnum.LOADED}
				values={dbList}
				noFilter
				onChange={value => {
					const currentFunction = activeIndex === 0 ? setCompanies : setProducts;
					currentFunction(list => {
						if (list.some(c => c.id === value.id)) return list;
						setDbList(dbList => {
							const index = dbList.findIndex(({ id }) => id === value.id);
							if (index < 0) return dbList;
							dbList.splice(index, 1);
							return [...dbList];
						});
						return [{ id: value.id, name: value.name }, ...list];
					});
				}}
				onSearchChange={onSearchChange}
				JSXValue={value => <DropdownLabel>{value.name}</DropdownLabel>}
				isOpen={isSearchOpen.isOpen}
				setIsOpen={(isOpen: boolean) => setIsSearchOpen({ isOpen })}
				delay={250}
				maxHeight='350px'
			/>
		</DropdownWrapper>
		<ProductPopup
			isOpen={productOpen.isOpen}
			productId={productOpen.data}
			setIsOpen={isOpen => setProductOpen({ isOpen })}
		/>
		<ClientCompany
			isOpen={companyOpen.isOpen}
			setOpen={isOpen => setCompanyOpen({ isOpen })}
			clientCompanyId={companyOpen.data ?? 0}
		/>
	</>;
}

function LeftPart(props: Props & { mode: PopupMode, setMode: React.Dispatch<React.SetStateAction<PopupMode>>} & {
	title: string | undefined,
	setTitle: React.Dispatch<React.SetStateAction<string | undefined>>,
	description: string | undefined,
	setDescription: React.Dispatch<React.SetStateAction<string | undefined>>,
	isActive: boolean,
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>,
	periods: Period[],
	setPeriods: React.Dispatch<React.SetStateAction<Period[]>>,
}) {
	const { promotion, title, setTitle, description, setDescription, isActive, setIsActive, periods, setPeriods } = props;
	const { alertDelete } = React.useContext(AlertContext);
	const { isAllowedTo } = React.useContext(PermissionContext);

	return <PopupCreation
		onModeChange={() => props.setMode(mode => mode === PopupMode.Details ? PopupMode.Default : PopupMode.Details)}
		extended={props.mode === PopupMode.Default}
		onClose={() => props.setIsOpen(false)}
		title={promotion?.name ?? translateToString('new_campagne')}
		messageButton={promotion ? translateToString('save') : undefined}
		canValidate={!props.readOnly && !!title && periods.every(p => p.end && p.start && p.name && moment(p.end).diff(moment(p.start)) > 0)}
		hideValidation
		dropdown={(props.readOnly || props.mode === PopupMode.Default) || !isAllowedTo({ objectAction: 'DeleteCampaign' }) ? undefined : {
			data: [{
				label: translateToString('delete'),
				value: 'DELETE'
			}],
			onChange: value => {
				switch (value.value) {
					case 'DELETE':
						alertDelete({ name: promotion?.name ?? '', zIndex: 999 }).then(res => {
							if (res == AlertRes.Ok && promotion) {
								deletePromotion(promotion.id).then(() => {
									props.setIsOpen(false);
									props.onDelete?.(promotion.id);
								});
							}
						});

				}
			}
		}}
	>
		<CreationPopupContainer gap='1em'>
			<Input
				required
				disabled={props.readOnly}
				inputStyle={creationPopupInputStyleMargin}
				name="title"
				type="text"
				placeholder={translateToString('company.creation.event.title')}
				label={translateToString('company.creation.event.title')}
				normalize={[normalizeCapital]}
				onChange={setTitle}
				value={title}
			/>
			<TextAreaContainer>
				<TextAreaLabel>{translateToString('company.creation.event.description')}</TextAreaLabel>
				<TextArea
					disabled={props.readOnly}
					onChange={(e) => {
						setDescription(e.currentTarget.value);
					}} value={description}></TextArea>
			</TextAreaContainer>
			<HorizontalContainer>
				<SwitchText>{translateToString('visible')}</SwitchText>
				<Switch value={isActive} onChange={setIsActive} readonly={props.readOnly} />
			</HorizontalContainer>
			<FlexDiv flow='column' align='stretch' gap='2em'>
				{periods.map((p, i) => <PeriodEditor period={p} index={i + 1} key={`PeriodEditor[${i}]`} setPeriods={setPeriods} readOnly={props.readOnly} periods={periods}/>)}
			</FlexDiv>
			{!props.readOnly &&
					<Add onClick={() => setPeriods(p => [...p, genDefaultPeriod()])}/>
			}
		</CreationPopupContainer>
	</PopupCreation>;

}

export const SaveWrapper = styled.div<{ isOpen: boolean }>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid ${BorderColor};
	height: ${({ isOpen }) => isOpen ? 60 : 0}px;
	overflow: hidden;
	translate: 0 ${({ isOpen }) => isOpen ? '0' : 'calc(100% + 10px)'};
	transition: 0.5s;
	padding-top: 10px;
`;

const DEFAULT_POPUP_MODE = PopupMode.Details;

export default function PopupPromotion(props: Props & { isOpen: boolean, mode?: PopupMode }) {
	const [promotion, setPromotion] = React.useState<Promotion | undefined>(props.promotion);
	const [mode, setMode] = React.useState<PopupMode>(DEFAULT_POPUP_MODE);
	const [products, setProducts] = React.useState<PromotionProduct[]>([...promotion?.products ?? []]);
	const [companies, setCompanies] = React.useState<PromotionCompany[]>([...promotion?.companies ?? []]);
	const [title, setTitle] = React.useState<string | undefined>(promotion?.name);
	const [description, setDescription] = React.useState<string | undefined>(promotion?.description);
	const [isActive, setIsActive] = React.useState<boolean>(promotion?.is_active ?? true);
	const [periods, setPeriods] = React.useState<Period[]>(promotion?.periods ? JSON.parse(JSON.stringify(promotion.periods)) : [genDefaultPeriod()]);
	const alert = useAlert();

	React.useEffect(() => {
		setPromotion(props.promotion);
	}, [props.promotion]);

	React.useEffect(() => {
		if (props.promotionId === undefined) {
			setPromotion(props.promotion);
		} else {
			getPromotionById(props.promotionId).then(setPromotion);
		}
	}, [props.promotionId]);

	React.useEffect(() => {
		props.mode !== undefined && setMode(props.mode);
	}, [props.mode]);

	const getDifferencesUpdate = (): PutPromotionBody => {
		if (!promotion) return {
			name: title,
			is_active: isActive,
			periods,
			description: description ? { updated: description } : 'deleted',
			companies_add: companies.map(({ id }) => id),
			products_add: products.map(({ id }) => id),
		};
		const body: PutPromotionBody = {};
		if (promotion.is_active !== isActive) body.is_active = isActive;
		if (promotion.name !== title) body.name = title;
		if (promotion.periods.length !== periods.length
			|| promotion.periods.some((p, i) => p.name !== periods[i].name
				|| moment(p.end).toISOString() !== (periods[i].end ? moment(periods[i].end).toISOString() : undefined)
				|| moment(p.start).toISOString() !== (periods[i].start ? moment(periods[i].start).toISOString() : undefined)
				|| (p.color ?? BlueSidely) !== (periods[i].color ?? BlueSidely)
			)
		) body.periods = periods;
		if (promotion.description === description) body.description = 'unmodified';
		else if (!description) body.description = 'deleted';
		else body.description = { updated: description };
		const promotionComps = promotion.companies?.map(({ id }) => id).sort((idA, idB) => idB - idA) ?? [];
		const newComps = companies.map(({ id }) => id).sort((idA, idB) => idB - idA);
		if (!equal(promotionComps, newComps)) {
			const deletion = promotionComps.filter(id => !newComps.some(ncid => ncid === id));
			const addition = newComps.filter(id => !promotionComps.some(ncid => ncid === id));
			if (deletion.length > 0) {
				body.companies_del = deletion;
			}
			if (addition.length > 0) {
				body.companies_add = addition;
			}
		}
		const promotionProds = promotion.products?.map(({ id }) => id).sort((idA, idB) => idB - idA) ?? [];
		const newProds = products.map(({ id }) => id).sort((idA, idB) => idB - idA);
		if (!equal(promotionProds, newProds)) {
			const deletion = promotionProds.filter(id => !newProds.some(ncid => ncid === id));
			const addition = newProds.filter(id => !promotionProds.some(ncid => ncid === id));
			if (deletion.length > 0) {
				body.products_del = deletion;
			}
			if (addition.length > 0) {
				body.products_add = addition;
			}
		}
		return body;
	};

	const close = (force: boolean) => {
		if (force || props.readOnly || Object.values(getDifferencesUpdate()).every(d => typeof d === 'string' && d === 'unmodified')) {
			props.setIsOpen(false);
			setMode(DEFAULT_POPUP_MODE);
			return;
		}
		alert({
			zIndex: 999,
			title: translateToString('unsaved_changes'),
			content: translateToString('unsaved_changes_leave'),
			buttons: [{ title: translateToString('yes'), res: AlertRes.Ok, style: ButtonStyle.White }]
		}).then(res => {
			if (res == AlertRes.Ok) {
				props.setIsOpen(false);
				setMode(DEFAULT_POPUP_MODE);
			}
		});
	};

	React.useEffect(() => {
		setProducts([...promotion?.products ?? []]);
		setCompanies([...promotion?.companies ?? []]);
		setTitle(promotion?.name);
		setDescription(promotion?.description);
		setIsActive(promotion?.is_active ?? true);
		setPeriods(promotion?.periods ? JSON.parse(JSON.stringify(promotion.periods)) : [genDefaultPeriod()]);
	}, [promotion]);

	return <Popup
		isOpen={props.isOpen}
		onClickOut={() => close(false)}
		popupMode={mode}
		popupStyle={{ animate: true, minWidth: '450px' }}
		name='Prom'
	>
		<Restricted to={{ objectAction: 'ReadCampaign' }} fallback={<Unhautorized />}>
			<FlexDiv height='100%' width='100%' flow='column'>
				<FlexDiv height='100%' width='100%' flexShrink={1} minHeight='0'>
					<LeftContainer isFullOpen={mode === PopupMode.Default}>
						<LeftPart {...props}
							promotion={promotion}
							setIsOpen={close}
							mode={mode}
							setMode={setMode}
							title={title}
							setTitle={setTitle}
							description={description}
							setDescription={setDescription}
							isActive={isActive}
							setIsActive={setIsActive}
							periods={periods}
							setPeriods={setPeriods}
						/>
					</LeftContainer>
					<RightContainer isFullOpen={mode === PopupMode.Default} isOpen={false}>
						<RightPart {...props}
							promotion={promotion}
							products={products}
							setProducts={setProducts}
							companies={companies}
							setCompanies={setCompanies}
							readOnly={props.readOnly}
						/>
					</RightContainer>
				</FlexDiv>
				<Restricted to={promotion ? { objectAction : 'UpdateCampaign' } : { objectAction : 'CreateCampaign' }}>
					<SaveWrapper isOpen={!props.readOnly && !Object.values(getDifferencesUpdate()).every(d => typeof d === 'string' && d === 'unmodified')}>
						<DefaultButton
							width='200px'
							disabled={!title || props.readOnly || Object.values(getDifferencesUpdate()).every(d => typeof d === 'string' && d === 'unmodified') || periods.some(p => !p.end || !p.start || !p.name) || periods.length === 0 }
							onClick={() => {
								if (props.readOnly) return;
								if (promotion?.id) {
									props.onUpdate?.(promotion.id, getDifferencesUpdate());
								} else if (title) {
									props.onCreate?.({
										name: title,
										periods,
										is_active: isActive,
										companies: companies.map(({ id }) => id),
										description,
										products: products.map(({ id }) => id)
									});
								}
								close(true);
							}}><Translate id='save' /></DefaultButton>
					</SaveWrapper>
				</Restricted>
			</FlexDiv>
		</Restricted>
	</Popup>;
}