import refresh from 'images/icon/refresh.svg';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { LinkedElementId, EmailInformation, getMails } from './actions';
import * as moment from 'moment';
import ModalEmailDetails from './modals/ModalEmailDetails';
import { useStore } from 'react-redux';
import { ModalEventForm, ModalMode } from '../../../../containers_v2/calendar/ModalEventForm';
import styled from 'styled-components';
import { BlueSidely, LightBlueSidely, RedSidely } from '../../../../styles/global/css/Utils';
import { Card } from '../../../../containers_v2/client-companies/popup/LinkedElements/style/LinkedElementStyle';
import Avatar from '../../../../components_v2/avatar/Avatar';
import { FlexDiv } from '../../../../containers_v2/products/style';
import { DefaultText } from '../../../../styles/global/css/GlobalText';
import { ModalState } from '../../../../containers_v2/products/model';
import PageLoader from '../../../../components_v2/pageLoader/PageLoader';
import Popup from '../../../../components_v2/popup/Popup';
import NewEvent, { NewEventDefaultValues } from '../../../../containers_v2/calendar/popup/NewEvent';
import { colorStatusMapping, colorTypeMapping } from '../../../../containers_v2/calendar/model/Model';
import { PopupMode } from '../../../../components_v2/popup/model/Model';
import EventBlueImg from 'images/menu_icon/event_blue.svg';
import EventWhiteImg from 'images/menu_icon/event_white.svg';
import { translateToNode } from '../../../../styles/global/translate';

const TextLink = styled.div`
	cursor: pointer;
	color: ${BlueSidely};
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	margin: 10px 0;
`;

const Payload = styled.div`
	font-size: 12px;
	margin: 15px 0 10px 0;
	color: rgb(112, 118, 131);
	font-family: "Poppins", sans-serif;
	text-align: center;
`;

type loadingState = 'loading' | 'loaded' | 'error' | 'no_emails_linked' | 'payload_overflow' | null | 'token_error'

function extractContent(html) {
	return new DOMParser().parseFromString(html, 'text/html')
		.documentElement.textContent;
}

export enum EmailTypes {
  Company = 'company',
  Contact = 'contact'
}

const default_max_emails = 5;

export default function Emails(props: {
  isActive: boolean
  isLoading: boolean
  type: EmailTypes
  contacts?: any
  company?: any
  contact?: any
}) {
	const [emails, setEmails] = React.useState<any[]>([]);
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [modalState, setModalState] = React.useState({ isOpen: false, mail: null });
	const [modalEventState, setModalEventState] = React.useState({ isOpen: false, defaultEvent: {}, instance: -1 });
	const [manualSync, setManualSync] = React.useState<boolean>(false);
	const [buttons, setButtons] = React.useState<loadingState[]>([]);
	const [nextPageToken, setNextPageToken] = React.useState<string | undefined>(undefined);
	const [max, setMax] = React.useState<number>(default_max_emails);
	const store = useStore().getState();

	React.useEffect(() => {
		if (!loadingState && !props.isLoading && (props.isActive || manualSync)) {
			let mailList: string[] = [];
			if (props.type == EmailTypes.Company) {
				mailList = props.contacts.map(contact => contact.email);
				props.company.email ? mailList.push(props.company.email) : null;
			} else if (props.type == EmailTypes.Contact) {
				mailList = [props.contact.email];
			}
			mailList = mailList.filter(m => m && m.includes('@'));
			setLoadingState('loading');
			setManualSync(false);
			if (mailList.length > 0) {
				getMails(max, nextPageToken, props.company?.id ?? props.contact?.clientCompanyId)
					.then(res => {
						console.log(res, emails);
						if (typeof res === 'string' && res === 'PayloadError: `Overflow`') {
							setLoadingState('payload_overflow');
						} else {
							setMax(default_max_emails);
							setNextPageToken(res.nextPageToken);
							setEmails([...emails, ...res.messages]);
							setLoadingState('loaded');
						}
					})
					.catch(e => {
						console.log(e);
						setLoadingState('error');
					});
			} else {
				setTimeout(() => {
					setLoadingState('no_emails_linked');
				}, 1000);
			}
		}
	}, [loadingState, props.isActive, props.isLoading, manualSync]);

	return (
		(<div>
			<div className="detail-top m-3 p-3" style={{ whiteSpace: 'nowrap' }}>
				<div className="row mb-2">
					<div className="col-md-9">
						<div className="mb-2">
							<span className="card-title">
								<Translate id="emails" /> ({emails ? emails.length : '0'}){' '}
							</span>
						</div>
					</div>
					<div className='col-md-3 d-flex justify-content-end align-items-end' >
						<div
							className="my-auto custom-icon-blue rounded-circle"
							style={{
								marginRight: '2.7%',
								width: '35px',
								height: '35px'
							}}
							onClick={() => {
								setEmails([]);
								setNextPageToken(undefined);
								setManualSync(true);
								setLoadingState(null);
							}}
						>
							<img
								src={refresh}
								className="custom-icon"
								alt=""
							/>
						</div>
					</div>
				</div>
				{emails && emails.map((mail, i) => {
					const splited: string[] = mail.payload.headers.find(e => e.name == 'From')?.value?.split(' ');
					let fromMail = splited.pop();
					let from: string = splited.reduce((acc, e) => acc + ' ' + e, '');
					if (!from && fromMail) {
						from = fromMail;
					}
					const extracted = extractContent(mail.snippet);
					let object = mail.payload.headers.find(e => e.name == 'Subject')?.value;
					object = object || '';
					return (
						<div className="row" style={{ fontSize: '13px' }} key={`email[${i}]`}>
							<div className="card card-notes relative-btn-container ml-2" style={{ width: '99%' }}>
								<div className="card-body p-2">
									<div className="row pointer" style={{ width: '95%', float: 'left' }}
										onClick={() => setModalState({ ...modalState, isOpen: true, mail })}
									>
										<div className="no-contact-thumbnail d-flex justify-content-center" style={{ margin: '0px 10px 0px 15px' }}>
											<span className="my-auto">
												{splited[0]?.charAt(0)}
												{splited[1]?.charAt(0)}
											</span>
										</div>
										<div className="col-md-1" style={{ padding: '8px 0px ', textOverflow: 'ellipsis', overflow: 'hidden' }}>
											{from}
										</div>
										<div className="col-md-1" style={{ padding: '8px 0px', textAlign: 'center' }}>
											{moment(parseInt(mail.internalDate)).format('DD/MM/YYYY')}
										</div>
										<div className="col-md-2" style={{ padding: '8px 0px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
											{object}
										</div>
										<div className="col-md-7 ml-5" style={{ padding: '8px 0px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
											{extracted}
										</div>
									</div>
									<div className="d-flex justify-content-end align-items-end"
										style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.7%' }}
									>
										<div
											className="my-auto custom-icon-blue rounded-circle"
											style={{
												backgroundColor: buttons[i] == 'error' ? RedSidely : buttons[i] ? BlueSidely : LightBlueSidely,
												width: '35px',
												height: '35px',
												cursor: buttons[i] == 'loaded' ? 'not-allowed' : buttons[i] == 'loading' ? 'wait' : 'pointer'
											}}
											onClick={() => {
												if (!buttons[i] || buttons[i] == 'error') {
													buttons[i] = 'loading';
													setButtons([...buttons]);
													const deliveredTo = mail.payload.headers.find(e => e.name == 'To')?.value;
													let cc = mail.payload.headers.find(e => e.name == 'Cc')?.value.split(',').reduce((acc, e) => {
														let last = e.split(' ').pop();
														if (last && last[0] == '<' && last[last.length - 1] == '>') { last = last.slice(1, -1); }
														acc.push(last);
														return acc;
													}, []);
													if (cc == undefined) {
														cc = [];
													}
													if (fromMail && fromMail[0] == '<' && fromMail[fromMail.length - 1] == '>') { fromMail = fromMail.slice(1, -1); }
													const localContact = (props.contacts?.find(contact => {
														return contact.email == fromMail || contact.email == deliveredTo || cc.includes(contact.email);
													}));
													const contactId = props.contact?.id ?? localContact?.id;
													const clientCompanyId = props.company?.id ?? props.contact?.clientCompanyId ?? localContact?.clientCompanyId;
													setModalEventState({
														isOpen: true,
														defaultEvent: {
															contactId,
															clientCompanyId,
															title: object,
															incharge: store.user.current.id,
															startDate: new Date(parseInt(mail.internalDate)),
															endDate: new Date(parseInt(mail.internalDate) + 60 * 60 * 1000),
															description: '',
															eventTypeId: 6,
															eventStatusId: 2,
															allDAy: false
														},
														instance: i
													});
												}
											}}
										>
											<img
												src={!buttons[i] ? EventBlueImg : EventWhiteImg}
												style={{
													width: '25px',
													height: '25px'
												}}
												alt=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
				{!props.isActive && !loadingState &&
					<TextLink onClick={() => setManualSync(true)}>
						<Translate id="emails.sync_emails" />
					</TextLink>
				}
				{loadingState == 'payload_overflow' && max > 1 &&
					<TextLink onClick={() => {
						setMax(max - 1);
						setLoadingState(null);
						setManualSync(true);
					}}>
						<Translate id="emails.overflow" />({max - 1})
					</TextLink>
				}
				{loadingState == 'payload_overflow' && max <= 1 &&
					<Payload>
						<Translate id="emails.email_too_big" />
					</Payload>
				}
				{loadingState == 'no_emails_linked' &&
					<Payload>
						<Translate id="emails.no_emails_linked" />
					</Payload>
				}
				{loadingState == 'loaded' && !emails &&
					<Payload>
						<Translate id="emails.no_emails" />
					</Payload>
				}
				{loadingState == 'loaded' && nextPageToken &&
					<TextLink onClick={() => {
						setLoadingState(null);
						setManualSync(true);
					}}>
						<Translate id="emails.load_more" />
					</TextLink>
				}
				{loadingState == 'loading' && <TextLink><PageLoader /></TextLink>}
			</div>
			<ModalEmailDetails
				isOpen={modalState.isOpen}
				mail={modalState.mail}
				toggle={() => setModalState({ ...modalState, isOpen: !modalState.isOpen })}
			/>
			<ModalEventForm
				isOpen={modalEventState.isOpen}
				toggleModal={() => setModalEventState({ ...modalEventState, isOpen: !modalEventState.isOpen, defaultEvent: {} })}
				mode={ModalMode.New}
				defaultStart={null}
				defaultEnd={null}
				// @ts-expect-error IDK
				defaultEvent={modalEventState.defaultEvent}
				hide={['companies', 'contacts', 'type']}
				onDeleteData={() => setModalEventState({ ...modalEventState, isOpen: !modalEventState.isOpen, defaultEvent: {} })}
				setCallBack={callBack => {
					switch (callBack) {
						case undefined:
							buttons[modalEventState.instance] = null;
							setButtons([...buttons]);
							break;
						case 'success':
							buttons[modalEventState.instance] = 'loaded';
							setButtons([...buttons]);
							break;
						case 'error':
							buttons[modalEventState.instance] = 'error';
							setButtons([...buttons]);
							setTimeout(() => {
								buttons[modalEventState.instance] = null;
								setButtons([...buttons]);
							}, 750);
							break;
					}
					setModalEventState({ ...modalEventState, instance: -1 });
				}}
			/>
		</div >)
	);
}

const EmailText = styled.div<{ width: string }>`
	${DefaultText}
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: ${p => p.width};
`;

const CalendarContainer = styled.div<{ state?: loadingState }>`
	width: 35px;
	height: 35px;
	background-image: url('${p => !p.state ? EventBlueImg : EventWhiteImg}');
	background-color: ${p => p.state == 'error' ? RedSidely : p.state ? BlueSidely : LightBlueSidely};
	cursor: ${p => p.state == 'loaded' ? 'not-allowed' : p.state == 'loading' ? 'wait' : 'pointer'};
	background-size: 25px;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 50%;
	border: 1px solid ${p => p.state == 'error' ? RedSidely : BlueSidely};
`;

export type Email = {
	contact_id?: number,
	company_id?: number,
	historyId: string,
	id: string,
	internalDate: string,
	sizeEstimate: number,
	snippet: string,
	threadId: string
	payload: {
		body: object,
		filename: string,
		mimeType: string,
		partId: string,
		parts: object[],
		headers: { name: string, value: string }[]
	}
}

export function EmailV2(props: {
  id: LinkedElementId // might be clientCompanyId or contactId depending on EmailType
  isExtended?: boolean
  onDisableClickOut?: (b: boolean) => void
  companyId?: number
  contactId?: number
}) {
	const { id, isExtended, onDisableClickOut } = props;
	const [emails, setEmails] = React.useState<Email[]>([]);
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [nextPageToken, setNextPageToken] = React.useState<string | undefined>(undefined);
	const [max, setMax] = React.useState<number>(default_max_emails);
	const [emailInfos, setEmailInfos] = React.useState<EmailInformation[]>([]);
	const [manualSync, setManualSync] = React.useState<boolean>(false);
	const [modalState, setModalState_] = React.useState<ModalState>({ isOpen: false, data: null });
	const [eventModalState, setEventModalState] = React.useState<ModalState<[NewEventDefaultValues, number]>>({ isOpen: false });
	const [buttons, setButtons] = React.useState<loadingState[]>([]);
	const setModalState = (state: ModalState) => {
		setModalState_(state);
		onDisableClickOut?.(state.isOpen);
	};

	React.useEffect(() => {
		if (loadingState !== 'loading') {
			setLoadingState('loading');
			getMails(max, nextPageToken, id)
				.then(res => {
					if (res.token_error) {
						setLoadingState('token_error');
					} else {
						setMax(default_max_emails);
						setNextPageToken(res.nextPageToken);
						setEmails([...emails, ...res.messages]);
						setLoadingState('loaded');
					}
				})
				.catch(e => {
					console.log(e);
					setLoadingState('error');
				});
			if (manualSync) setManualSync(false);
		}
	}, [manualSync]);

	return <>
		<FlexDiv width='100%' padding='0 2%' flow='column'>
			{emails.map((mail, i) => {
				const splited: string[] = mail.payload.headers.find(e => e.name == 'From')?.value?.split(' ') ?? [];
				let fromMail = splited.pop();
				let from: string = splited.reduce((acc, e) => acc + ' ' + e, '');
				if (!from && fromMail) {
					from = fromMail;
				}
				const extracted = extractContent(mail.snippet);
				let object = mail.payload.headers.find(e => e.name == 'Subject')?.value;
				object = object || '';

				return <Card
					key={`email[${i}]`}
					onClick={() => setModalState({ isOpen: true, data: mail })}
					isExtend={false}
				>
					<FlexDiv gap={isExtended ? '2%' : '5%'} width='100%'>
						<Avatar width='35px' name={from} />
						<FlexDiv gap='5%' width={`calc(${isExtended ? '96%' : '90%'} - 70px)`}>
							<EmailText width={isExtended ? '10%' : '20%'}>
								{from}
							</EmailText>
							<EmailText width={isExtended ? '10%' : '20%'}>
								{moment(parseInt(mail.internalDate)).format('L')}
							</EmailText>
							<EmailText width={isExtended ? '70%' : '50%'}>
								{extracted}
							</EmailText>
						</FlexDiv>
						<CalendarContainer
							state={buttons[i]}
							onClick={clickEvent => {
								clickEvent.stopPropagation();
								if (buttons[i] == 'loaded' || buttons[i] == 'loading') return;
								const deliveredTo = mail.payload.headers.find(e => e.name == 'To')?.value;
								let cc = mail.payload.headers.find(e => e.name == 'Cc')?.value.split(',').reduce((acc: (string | undefined)[], e) => {
									let last = e.split(' ').pop();
									if (last && last[0] == '<' && last[last.length - 1] == '>') { last = last.slice(1, -1); }
									acc.push(last);
									return acc;
								}, []);
								if (cc == undefined) {
									cc = [];
								}
								if (fromMail && fromMail[0] == '<' && fromMail[fromMail.length - 1] == '>') { fromMail = fromMail.slice(1, -1); }
								const info = (emailInfos?.find(info => {
									return info.email == fromMail || info.email == deliveredTo || cc?.includes(info.email);
								}));
								const contactId = props.contactId ?? mail.contact_id ?? ('Contact' in id ? id.Contact : info?.company ? undefined : info?.id);
								const clientCompanyId = props.companyId ?? mail.company_id ?? ('Company' in id ? id.Company : emailInfos?.[0]?.company_id);
								const event: NewEventDefaultValues = {
									eventStatusId: colorStatusMapping.find(e => e.label == 'Completed')?.id,
									eventTypeId: colorTypeMapping.find(e => e.label == 'Email')?.id,
									start: new Date(parseInt(mail.internalDate)),
									end: new Date(parseInt(mail.internalDate) + 60 * 60 * 1000),
									allDay: false,
									contactId,
									clientCompanyId,
									title: object
								};
								setEventModalState({ isOpen: true, data: [event, i] });
							}}
						/>
					</FlexDiv>
				</Card>;
			})}
			{loadingState == 'loaded' && !emails &&
				<Payload>
					<Translate id="emails.no_emails" />
				</Payload>
			}
			{loadingState == 'loaded' && nextPageToken &&
				<TextLink onClick={() => setManualSync(true)}>
					<Translate id="emails.load_more" />
				</TextLink>
			}
			{loadingState == 'loading' && <TextLink><PageLoader /></TextLink>}
			{loadingState == 'no_emails_linked' &&
					<Payload>
						<Translate id="emails.no_emails_linked" />
					</Payload>
			}
			{loadingState == 'token_error' && 
					<Payload>
						{translateToNode('emails.error_while_retrieving_your_account', { onClickA: () => window.open('settings#emailing_integrations') })}
					</Payload>
			}
		</FlexDiv>
		<ModalEmailDetails
			isOpen={modalState.isOpen}
			mail={modalState.data}
			toggle={() => setModalState({ ...modalState, isOpen: !modalState.isOpen })}
		/>
		<Popup
			isOpen={eventModalState.isOpen}
			popupMode={PopupMode.Details}
			onClickOut={() => setEventModalState({ isOpen: false })}
			popupStyle={{ animate: true }}
		>
			<NewEvent
				onClickOut={() => setEventModalState({ isOpen: false })}
				defaultValues={eventModalState.data?.[0]}
				hide={['companies', 'contacts', 'type']}
				onCreate={() => {
					const index = eventModalState.data?.[1];
					if (index !== undefined) {
						buttons[index] = 'loaded';
						setButtons([...buttons]);
					}
					setEventModalState({ isOpen: false });
				}}
			/>
		</Popup>
	</>;
}
