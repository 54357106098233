import Restricted from '../../permissions/Restricted';
import { Translate } from '../../../styles/global/translate';
import { creatorPageMapping, DashboardItem, DashboardItemType, DEFAULT_LAYOUTS } from '../Dashboard';
import Widget from '../Widget';
import { DEFAULT_ITEMS } from '../constants';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import * as React from 'react';
import {
	BottomBar,
	CreatorProps,
	DashboardPopupContext,
	DashboardPopupTitle,
	Grid,
	PopupContainer
} from './DashboardPopup';
import { useContext } from 'react';
import styled from 'styled-components';
import { BlueSidely } from '../../../styles/global/css/Utils';

const TargetContainer = styled.div`
	font-size: 14px;
`;
const TargetLink = styled.span`
  color: ${BlueSidely};
  cursor: pointer;
`;

const Spacer = styled.div`
  height: 50px;
`;
export default function DefaultPage(props: CreatorProps) {
	const { onSave, close } = props;
	const {
		targets,
		changedItems,
		setChangedItems,
		items,
		setPage,
	} = useContext(DashboardPopupContext)!;
	return <>
		<PopupContainer>
			{/* <Restricted to={{ objectAction: 'ReadObjective' }}>
				<DashboardPopupTitle><Translate id='targets'/></DashboardPopupTitle>
				{targets && targets.length > 0
					? <Grid>
						{targets?.map(t => {
							const item: DashboardItem = items.find(item => item.item_type === 'Target' && item.item_id === t.id) ?? {
								item_type: 'Target',
								item_id: t.id,
								id: { unsync: Date.now() },
								layout: DEFAULT_LAYOUTS['Target'],
								screen_id:props.activeTab
							};
							const itemIndex = changedItems.findIndex(i => i.item_id === item.item_id && i.item_type === item.item_type);
							return <Widget
								minHeight='200px'
								item={item}
								key={`CreationPopupTargetWidget[${t.id}]`}
								value={{ value: t, type: 'Target' }}
								selected={items.find(i => i.item_type === 'Target' && i.item_id === t.id) !== undefined ? itemIndex === -1 : itemIndex !== -1}
								onSelect={() => {
									if (itemIndex < 0) {
										changedItems.push(item);
									} else {
										changedItems.splice(itemIndex, 1);
									}
									setChangedItems([...changedItems]);
								}}
								setItem={() => null}
							/>;
						})}
					</Grid>
					: <NoTarget/>
				}
			</Restricted>
			<TitleAndGrid
				title={<Translate id={'detention'}/>}
				types={['ParentDn']}
				items={DEFAULT_ITEMS.parentDn}
				onCreationClicked={(dashboardItemType) => setPage(creatorPageMapping[dashboardItemType])}
			/> */}
			<TitleAndGrid
				title={<Translate id={'dashboard.events'}/>}
				types={['EventCount', 'EventFrequency', 'EventFrequencyByUser']}
				items={DEFAULT_ITEMS.event}
				onCreationClicked={(dashboardItemType) => setPage(creatorPageMapping[dashboardItemType])}
			/>
			<TitleAndGrid
				title={<Translate id={'dashboard.point_of_sale'}/>}
				types={['Status', 'Company', 'ClientCompanyStatusCount', 'TopCompanyByCheckout']}
				items={DEFAULT_ITEMS.status}
				onCreationClicked={(dashboardItemType) => setPage(creatorPageMapping[dashboardItemType])}
			/>
		</PopupContainer>
		<BottomBar>
			<DefaultButton
				margin='0'
				buttonStyle={ButtonStyle.White}
				onClick={() => {
					setChangedItems([]);
					close && close();
				}}><Translate id='cancel'/></DefaultButton>
			<DefaultButton margin='0' disabled={changedItems.length === 0} onClick={onSave}>
				<Translate id='save'/>
			</DefaultButton>
		</BottomBar>
	</>;
}

function NoTarget() {
	return <TargetContainer>
		<Translate id='dashboard.no_target'/>
		<TargetLink onClick={() => window.location.href = '/targets'}>
			<Translate id='dashboard.target_page'/>
		</TargetLink>
	</TargetContainer>;
}


function TitleAndGrid(props: {
	onCreationClicked?: (dashboardItemType: DashboardItemType) => void,
	title: React.ReactNode,
	types: DashboardItemType[],
	items: DashboardItem[],
}) {
	const { types, items, onCreationClicked } = props;
	//TODO: verify value attr to send to widget(for dynamic db)
	return <>
		<Spacer/>
		<DashboardPopupTitle>{props.title}</DashboardPopupTitle>
		<Grid>
			{items.map((item, index) =>
				<Widget
					item={item}
					key={`CreationPopup${types[index]}Widget[${item.id}]`}
					minHeight='200px'
					setItem={() => null}
					onCreationClicked={() => onCreationClicked?.(types[index])}
					value={undefined}
					maxHeight="270px"
				/>)
			}
		</Grid>
	</>;
}
