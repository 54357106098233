/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ContactDetail } from 'bindings/contacts/ContactDetail';
import * as React from 'react';
import { FlexDiv } from '../../../products/style';
import Restricted from '../../../permissions/Restricted';
import { TitleAndChild } from '../../../client-companies/popup/Detail';
import { Translate } from '../../../../styles/global/translate';
import { BodyMainContainer, BodyMainImage, BodyMainRow, BodyMainText, BodyMainTextEditable, ButtonAction } from '../../../client-companies/style/PopupStyle';
import DropdownOwners, { UserBlock } from '../../../../components_v2/dropdown/DropdownOwners';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AProfile, AUsers } from '../../../../atoms/global/users';
import { blurOnEnterPressed } from '../../../../components_v2/input/Input';
import { DropdownSearchCompanies } from '../../../../components_v2/dropdown/DropdownSearch';
import PageLoader from '../../../../components_v2/pageLoader/PageLoader';
import { LoadingStateEnum } from '../../../import/model';
import eye from 'images/icons/company/eye.svg';
import close from 'images/icons/orders/close.svg';
import company from 'images/icons/company/company.svg';
import mail from 'images/icons/company/mail.svg';
import phone_button from 'images/icons/company/phone_button.svg';
import phoneImage from 'images/icons/company/phone.svg';
import user from 'images/icons/company/user.svg';
import { QueryUpdateContact } from '../../data/Data';
import { match_email } from 'validators-web';
import crown from 'images/vendors/crown.svg';
import ClientCompany from '../../../../components_v2/clientCompany/ClientCompany';
import DropdownTagsCloud from '../../../../components_v2/dropdown/DropdownTagsCloud';
import { ITag } from 'proto/protobufs';
import { updateTags } from '../../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../../components_v2/toolbarFilter/tags/Model';
import { AContactEdition } from '../../../../atoms/contact/contactEdition';
import ToolTip from '../../../../styles/global/ToolTip';
import { PopupContext } from '../../../../components_v2/popup/Popup';
import { HoverEmailWrapper } from '../../../email-templates/emailTemplatePopup';

function DetailInput(props: {
	image: string,
	ownerId: number,
	fallback?: React.ReactNode,
	children: React.ReactNode
	translateId: string;
}) {
	return <BodyMainRow>
		<ToolTip toolTipContent={<Translate id={props.translateId} />} left='33%'>
			<BodyMainImage noBorder backgroundSize="contain" src={props.image} />
		</ToolTip>
		<Restricted
			to={{ objectAction: 'UpdateContact' }}
			ownerId={props.ownerId}
			fallback={props.fallback}
		>
			{props.children}
		</Restricted>
	</BodyMainRow>;
} 

export default function ContactPopupDetais(props: {
	contact: ContactDetail,
	updateContact: (contactId: number, params: QueryUpdateContact, localChanges?: { [key: string]: unknown } | undefined) => Promise<void | undefined>,
	tags: ITag[]
}) {
	const { isChild } = React.useContext(PopupContext);
	const { contact, updateContact } = props;
	const owners = useRecoilValue(AUsers);
	const profile = useRecoilValue(AProfile);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const ownersOption = React.useMemo(() => owners.map(owner => ({
		label: owner.name,
		value: owner,
		image: owner.photoUrl,
		selected: owner.isYou
	})), [owners]);
	const [isCompanyOpen, setIsCompanyOpen] = React.useState(false);
	const [tagLoadingState, setTagLoadingState] = React.useState(LoadingStateEnum.LOADED);
	const [, setAtomContactEdition] = useRecoilState(AContactEdition);
	const [dropdownWidth, setDropdownWidth] = React.useState<string>(`${window.innerWidth / 6}px`);

	//FIXME: I put this to calculate dynamically dropdown size because we have to give a size 'fixed' to our dropdown.
	// The performance will be not good as it renders everytime when resizing
	// Alternative: calculate dropdown size when only popup openned => with this method, no re-render but not dynamic
	React.useEffect(() => {
		const handleResize = () => {
			setDropdownWidth(`${window.innerWidth / 6}px`);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	const selectedUser = owners.find(o => o.id === contact.owner_id);

	return <FlexDiv flow="column" width='100%' padding='15px 0 0 0'>
		<DropdownTagsCloud
			loadingState={tagLoadingState}
			addImage
			addImageSize='1.25em'
			noSearch
			dropdownSearch
			tags={props.tags}
			selected={props.tags.filter(t => contact.tags?.includes(t.id!)) ?? []}
			onChange={(values: ITag[]) => {
				setTagLoadingState(LoadingStateEnum.LOADING);
				const idsAdd = values.filter(t => !contact.tags?.includes(t.id!));
				const params = {
					ressource_id: contact.id,
					ids_add: idsAdd.map(t => t.id!)
				};
				updateTags(TagType.CONTACT, params)
					.then(response => {
						setTagLoadingState(LoadingStateEnum.LOADED);
						if (response.valueOf()) {
							setAtomContactEdition({ id: contact.id, ...params });
						}
					}).catch(_ => setTagLoadingState(LoadingStateEnum.LOADED));
			}}
			onDelete={(id: number) => {
				setTagLoadingState(LoadingStateEnum.LOADING);
				const params = {
					ressource_id: contact.id,
					ids_delete: [id]
				};
				updateTags(TagType.CONTACT, params)
					.then(() => {
						setTagLoadingState(LoadingStateEnum.LOADED);
						setAtomContactEdition({ id: contact.id, ...params });
					})
					.catch(() => setTagLoadingState(LoadingStateEnum.LOADED));
			}}
		/>
		<TitleAndChild title={<Translate id='overview' />} defaultOpen>
			<BodyMainContainer noScroll noHeight>
				<DetailInput image={user} fallback={<UserBlock user={selectedUser}/>} ownerId={profile.id} translateId='owner'>
					<DropdownOwners
						dropdownStyle={{ margin:'0 0 0 20px', optionWidth: '200px', optionHeight: '150px', width: '230px' }}
						users={ownersOption}
						selected={ownersOption.find(o => o.value.id === selectedUser?.id)}
						onChange={v => updateContact(contact.id, { owner_id: v.value.id })}
						open
					/>
				</DetailInput>
				<DetailInput image={crown} fallback={<BodyMainText display='block'>{contact.position}</BodyMainText>} ownerId={profile.id} translateId='company.creation.contact.position'>
					<BodyMainTextEditable
						marginLeft='20px'
						suppressContentEditableWarning
						contentEditable
						onKeyDownCapture={blurOnEnterPressed}
						onBlur={e => {
							const newPosition = e.currentTarget.innerText.trim();
							updateContact(contact.id, { position: newPosition ? { updated: newPosition } : 'deleted' });
						}}
					>
						{contact.position}
					</BodyMainTextEditable>
				</DetailInput>
				<DetailInput image={phoneImage} fallback={<BodyMainText display='block'>{contact.phone}</BodyMainText>} ownerId={profile.id} translateId='phone'>
					<BodyMainTextEditable
						marginLeft='20px'
						suppressContentEditableWarning
						contentEditable
						onKeyDownCapture={blurOnEnterPressed}
						onBlur={e => {
							const newPhone = e.currentTarget.innerText.trim();
							updateContact(contact.id, { phone: newPhone ? { updated: newPhone } : 'deleted' });
						}}
					>
						{contact.phone}
					</BodyMainTextEditable>
					{contact.phone && <ButtonAction src={phone_button} alt="phone" onClick={() => window.open(`tel:${contact.phone}`)} />}
				</DetailInput>
				<DetailInput image={mail} fallback={<BodyMainText display='block'>{contact.email}</BodyMainText>} ownerId={profile.id} translateId='email'>
					<BodyMainTextEditable
						marginLeft='20px'
						suppressContentEditableWarning
						contentEditable
						minWidth='160px'
						onKeyDownCapture={blurOnEnterPressed}
						onBlur={e => {
							const newEmail = e.currentTarget.innerText.trim();
							if (!newEmail) return updateContact(contact.id, { email: 'deleted' });
							if (match_email(newEmail)) updateContact(contact.id, { email: { updated: newEmail } });
						}}
					>
						{contact.email}
					</BodyMainTextEditable>
					{contact.email && 
						<HoverEmailWrapper
							emailTemplateVariable={{
								first_name:contact.first_name ?? '',
								last_name:contact.last_name ?? '',
								company_name:contact.client_company_name ?? '',
							}}
							email={contact.email} />
					}
				</DetailInput>
				<DetailInput image={company} fallback={<BodyMainText display='block'>{contact.client_company_id}</BodyMainText>} ownerId={profile.id} translateId='company.columns.company_address'>
					<>
						<BodyMainText noOverflow marginLeft='20px'>
							<DropdownSearchCompanies
								dropdownStyle={{ optionWidth: dropdownWidth, optionHeight: '200px', containerWidth: '100%', height: '25px' }}
								JSXButton={(handleChange) => <>
									{loadingState == LoadingStateEnum.LOADING && <FlexDiv height="100%"><PageLoader size={20} /></FlexDiv>}
									{loadingState !== LoadingStateEnum.LOADING &&
										<BodyMainTextEditable
											marginLeft="0px"
											suppressContentEditableWarning
											contentEditable
											onKeyDownCapture={blurOnEnterPressed}
											onInput={(e) => handleChange(e.currentTarget.innerText.trim())}
											display='block'
										>
											{contact.client_company_name}
										</BodyMainTextEditable>}
								</>
								}
								name={''}
								onChange={value => {
									setLoadingState(LoadingStateEnum.LOADING);
									updateContact(contact.id, { client_company_id: value ? { updated: value.value.id } : 'deleted' }, { client_company_name: value?.value.name })
										.then(() => setLoadingState(LoadingStateEnum.LOADED))
										.catch(() => setLoadingState(LoadingStateEnum.ERROR));
								}}
							/>
						</BodyMainText>
						{contact.client_company_name && <ButtonAction
							src={close}
							onClick={() => {
								setLoadingState(LoadingStateEnum.LOADING);
								updateContact(contact.id, { client_company_id: 'deleted' }, { client_company_name: 'deleted' })
									.then(() => setLoadingState(LoadingStateEnum.LOADED))
									.catch(() => setLoadingState(LoadingStateEnum.ERROR));
							}}
						/>}
						{contact.client_company_name && <ButtonAction src={eye} onClick={() => isChild ? window.open(`companies?id=${contact.client_company_id}`) : setIsCompanyOpen(true)} />}
					</>
					{contact.client_company_id &&
						<ClientCompany
							isOpen={isCompanyOpen}
							setOpen={setIsCompanyOpen}
							clientCompanyId={contact.client_company_id}
						/>
					}
				</DetailInput>
			</BodyMainContainer>
		</TitleAndChild>
	</FlexDiv>;
}