import arrow_down from 'images/icon/arrow_down.png';
import tag from 'images/icons/tag.svg';
import * as React from 'react';
import Select, { components } from 'react-select';
import { colourStyles } from '../../../helpers/colorStyle';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { getAllTag } from './action';
import { useRecoilState } from 'recoil';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { GenericToolbarFilterProps, TagType } from '../ToolbarFilter';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';


type loadingState = 'loading' | 'loaded' | 'error'
export interface tag {
  label: string
  value: string
  color: string
  type: 'tag' | 'operator'
}

const SelectStyle = styled.div<{ fullScreen?: boolean }>`
  ${({ fullScreen }) => fullScreen ? '' : 'width: 290px'};
  max-width: 100%;
  z-index: 8;
  .form-control {
    width: 100%;
  }
`;

function foundDuplicateIndex(list) {
	const ret = list.reduce((acc, x) => {
		if (x.type === acc.last && !acc.founded) {
			return ({ founded: true, i: acc.i - 1, last: x.type });
		} else if (acc.founded) { return ({ founded: true, i: acc.i, last: x.type }); }
		return { founded: false, i: acc.i + 1, last: x.type };
	}, { founded: false, i: 0, last: null });
	if (!ret.founded) {
		return { i: 0, type: null };
	}
	return { i: ret.i, type: list[ret.i].type };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function FilterTag(props: { options: tag[], isLoading: boolean, onFilter: Function, selectedTags: tag[] }) {
	const { isLoading, onFilter, options } = props;
	const translate = getTranslate(storeLang.getState().localize);
	const [dataTag, setDataTag] = React.useState<tag[]>([...options]);
	const [selected, setSelected] = React.useState<tag[]>([]);
	const [operatorIndex, setOperatorIOndex] = React.useState(0);

	React.useEffect(() => setDataTag([...options]), [options]);
	React.useEffect(() => setSelected([...props.selectedTags]), [props.selectedTags]);

	function delDuplicate(before, after) {
		const difference = before.filter(x => !after.includes(x));
		for (const tag of difference) {
			const index = dataTag.findIndex((t) => t.value.split('_')[0] == tag.value.split('_')[0]);
			dataTag.splice(index, 1);
		}
		setDataTag(dataTag);
	}

	const Control = ({ children, ...props }) => {
		return (
			(<components.Control {...props}>
				<span>
					<img src={tag} style={{ height: '20px', paddingLeft: '5px' }} />
				</span>
				{children}
			</components.Control>)
		);
	};

	const CustomToggle = React.forwardRef(function chezPas({ children, onClick }, ref) {
		return (
			(<a
				href=""
				ref={ref}
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
				style={{ color: DarkGreySidely, textDecoration: 'underline', textDecorationColor: DarkGreySidely }}
			>
				{(children as any).label + '         '}
				<img src={arrow_down} style={{ height: '5px' }} />
			</a>)
		);
	});

	function onChange(selectedOptions) {
		if (!selectedOptions || selectedOptions.length == 0) {
			setDataTag([...options]);
			setSelected([]);
			onFilter([]);
			return;
		}
		if (selected.length < selectedOptions.length && selected.length != 0) { // user added a tag
			const last = selectedOptions.pop();
			selectedOptions.push({ label: translate('And'), value: 'and_' + operatorIndex, type: 'operator' });
			selectedOptions.push(last);
			const index = dataTag.findIndex((tag) => tag.value.split('_')[0] == last.value.split('_')[0]);
			dataTag.splice(index, 0, { ...last, value: last.value + '_' + operatorIndex });
			setDataTag(dataTag);
			setOperatorIOndex(operatorIndex + 1);
			setSelected(selectedOptions);
		} else if (selected.length != 0 && selectedOptions.length != 0) { // user deleted a tag
			const dup = foundDuplicateIndex(selectedOptions);
			if (selectedOptions[0].type == 'operator') { // del operator if it is in first position
				delDuplicate(selected, selectedOptions);
				selectedOptions = selectedOptions.slice(1);
				setSelected(selectedOptions);
			} else if (selectedOptions[selectedOptions.length - 1].type == 'operator') { // del operator if it is in last position
				delDuplicate(selected, selectedOptions);
				selectedOptions = selectedOptions.slice(0, -1);
				setSelected(selectedOptions);
			} else if (dup.type == 'operator') { // del first operator if there are two next to each other
				delDuplicate(selected, selectedOptions);
				const retList = selectedOptions.slice(0, dup.i);
				retList.push(...selectedOptions.slice(dup.i + 1));
				selectedOptions = retList;
				setSelected(selectedOptions);
			} else {
				delDuplicate(selected, selectedOptions);
				setSelected(selectedOptions);
			}
		} else { // just push the first one
			const len = selectedOptions.length;
			setSelected(selectedOptions);
			if (len) {
				const index = dataTag.findIndex((tag) => tag.value.split('_')[0] == selectedOptions[len - 1].value.split('_')[0]);
				dataTag.splice(index, 0, { ...selectedOptions[len - 1], value: selectedOptions[len - 1].value + '_' + operatorIndex });
				setOperatorIOndex(operatorIndex + 1);
				setDataTag(dataTag);
			} else {
				delDuplicate(selected, selectedOptions);
			}
		}
		onFilter(selectedOptions.map((tag) => tag.value.split('_')[0]));
	}

	function MultiValueContainer(currProps) {
		const [operator, setOperator] = React.useState(0);
		React.useEffect(() => {
			if (operator == 1 || operator == 2) {
				const id = currProps.data.value.slice(currProps.data.value.split('_')[0].length + 1);
				if (operator == 1) {
					const index = selected.findIndex((tag) => tag.value == currProps.data.value);
					selected.splice(index, 1, { ...currProps.data, label: translate('And'), value: 'and_' + id });
					setSelected([...selected]);
					onFilter(selected.map((tag) => tag.value.split('_')[0]));
				} else if (operator == 2) {
					const index = selected.findIndex((tag) => tag.value == currProps.data.value);
					selected.splice(index, 1, { ...currProps.data, label: translate('Or'), value: 'or_' + id });
					setSelected([...selected]);
					onFilter(selected.map((tag) => tag.value.split('_')[0]));
				}
			}
		}, [operator]);
		if (currProps.data.type == 'operator') {
			currProps.innerProps.onClick = () => ({});
			return (
				<components.MultiValueContainer {...currProps} style={{ width: 311 }}>
					<Dropdown onSelect={setOperator} drop={'down'}>
						<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
							{currProps.data}
						</Dropdown.Toggle>
						<Dropdown.Menu bsPrefix="dropdown-menu-2" style={{ zIndex: 2 }}>
							<Dropdown.Header style={{ fontSize: 'x-small', fontWeight: 'bold', fontFamily: 'Poppins' }}>
								{translate('Operator')}
							</Dropdown.Header>
							<Dropdown.Item eventKey="1" style={{ fontSize: 'x-small', fontFamily: 'Poppins' }}>
								{translate('And')}
							</Dropdown.Item>
							<Dropdown.Item eventKey="2" style={{ fontSize: 'x-small', fontFamily: 'Poppins' }}>
								{translate('Or')}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</components.MultiValueContainer>
			);
		} else {
			return (<components.MultiValueContainer {...currProps} />);
		}
	}

	return <Select
		onChange={onChange}
		id={'tagId'}
		components={{ MultiValueContainer, Control }}
		options={dataTag}
		value={selected}
		optionClassName="needsclick"
		isClearable
		isLoading={isLoading}
		className={'form-control p-0 border-0 react-select-container selectFilter'}
		styles={colourStyles}
		closeMenuOnSelect={false}
		openMenuOnClick={false}
		isSearchable
		isMulti
	/>;
}

export default function TagFilter(props: GenericToolbarFilterProps & { atomId: number, tagType?: TagType }) {
	const [loadingState, setLoadingState] = React.useState<loadingState>('loading');
	const [dataTag, setDataTag] = React.useState<tag[]>([]);
	const [_filteredTags, setFilteredTags] = useRecoilState(ATagFilter);
	const { filters, setFilters } = React.useContext(ReportFiltersContext);
	
	let filteredTags;

	if (props.context && filters.my_filters) {
		filteredTags = filters.my_filters.tags;
	} else {
		filteredTags = _filteredTags;
	}
	React.useEffect(() => {
		if (loadingState == 'loading') {
			try {
				let type;
				switch (props.tagType) {
					case undefined:
					case 'company':
						type = 1;
						break;
					case 'product':
						type = 4;
						break;
				}
				getAllTag(true, type)
					.then(res => {
						if (filteredTags == undefined) {
							if (props.context) {
								setFilters(filters => ({ ...filters, my_filters: { ...(filters?.my_filters ?? {}), tags: [] } }));
							} else {
								setFilteredTags([]);
							}
						}
						const tags = res.data.data.map(c => {
							return {
								label: c.name,
								value: c.id.toString(),
								color: c.colorStatus.colorCode,
								type: 'tag'
							};
						});
						tags.sort((a, b) => {
							if (a.color !== b.color) {
								return a.color > b.color ? 1 : -1;
							} else {
								return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
							}
						});
						setDataTag(tags);
						setLoadingState('loaded');
					})
					.catch(e => {
						console.error(e);
						setLoadingState('error');
					});
			} catch (e) {
				console.error(e);
				setLoadingState('error');
			}
		}
	}, [loadingState]);

	if (props.hidden) return <></>;

	return (
		<SelectStyle fullScreen={props.fullScreen}>
			<FilterTag
				options={dataTag}
				isLoading={loadingState == 'loading'}
				onFilter={t => {
					if (props.context) {
						setFilters(filters => ({ ...filters, my_filters: { ...filters.my_filters, tags: t } }));
					} else {
						setFilteredTags(t);
					}
					
				}
				}
				selectedTags={selectedTagsInFilter(dataTag, filteredTags || [])}
			/>
		</SelectStyle>
	);
}

function selectedTagsInFilter(tags: tag[], filter: string[]) {
	const ret: tag[] = [];

	for (const tag of filter) {
		const found = tags.find((t) => t.value == tag);
		if (found) {
			ret.push(found);
		}
		else if (tag.startsWith('and')) {
			ret.push({ label: translateToString('And'), value: tag, color: '', type: 'operator' });
		}
		else if (tag.startsWith('or')) {
			ret.push({ label: translateToString('Or'), value: tag, color: '', type: 'operator' });
		}
	}
	return ret;
}
