import * as moment from 'moment';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Popup from '../../../../components_v2/popup/Popup';
import { Loader } from '../../../../styles/global/css/GlobalLoader';
import LoaderContext from '../../../../styles/global/loader/LoaderContext';
import { LoadingStateEnum } from '../../../import/model';
import { formatCurrency } from '../../../reports/utils';
import { getOrdersPerStatus, getOrderStatuses, getOrderStatusesTotalSum, updateOrder } from '../../data/Data';
import { Order, OrderStatus, orderStatusTotalSum } from '../../model/Model';
import PopupOrderDetail from './popup/PopupOrderDetail';
import {
	BlockStatus,
	BlockTotalPrice,
	Card,
	CardColumn,
	CardContainer,
	CardImage,
	CardRow,
	CardText,
	CardTitle,
	CartTextTransparent,
	Container,
	ContainerDetail,
	LoaderContainer,
	Status,
	StatusTop,
	TotalPrice,
	TotalPriceContainer
} from './style/DetailStyle';
import Avatar from '../../../../components_v2/avatar/Avatar';
import usePermission from '../../../permissions/usePermission';

function TemplateOrdersDetail(props: {
  searchValue: string
}): JSX.Element {
	const DEFAULT_LIMIT = 15;
	const DEFAULT_OFFSET = 0;

	const [statuses, setStatuses] = React.useState<OrderStatus[]>([]);

	const [orders, setOrders] = React.useState<Order[]>([]);
	const [ordersOffset, setOrdersOffset] = React.useState<Array<{ status_id: number, offset: number, total: number }>>([]);
	const [orderSum, setOrderSum] = React.useState<orderStatusTotalSum[]>([]);

	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [orderId, setOrderId] = React.useState<number>();

	const [loader, setLoader] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const canUpdate = usePermission({ objectAction: 'UpdateOrder' });

	const { loaderNotify } = React.useContext(LoaderContext);

	function OpenPopup(order_id: number): void {
		setOrderId(order_id);
		setOpen(true);
	}

	function updateOrdersColumn(statusId: number): void {
		const orderDetail = ordersOffset.find(o => o.status_id === statusId);
		const currentTotal = orders.filter(o => o.order_status_id === statusId).length;

		if ((orderDetail != null) && currentTotal < orderDetail.total) {
			const newOffset = (orderDetail.offset + DEFAULT_LIMIT);
			loaderNotify(`loader_kanban_${statusId}`, LoadingStateEnum.LOADING);
			getOrdersPerStatus({
				status_id: statusId,
				limit: DEFAULT_LIMIT,
				offset: newOffset
			}).then(response => {
				setOrders(orders.concat(response.orders));
				setOrdersOffset(ordersOffset.filter(o => o.status_id !== statusId).concat({ status_id: statusId, offset: newOffset, total: response.total }));
				loaderNotify(`loader_kanban_${statusId}`, LoadingStateEnum.LOADED);
			});
		}
	}

	function initOrders(statuses: OrderStatus[]): void {
		setLoader(LoadingStateEnum.LOADING);
		let count = statuses.length;
		setOrders([]);
		setOrdersOffset([]);
		statuses.forEach(s => {
			getOrdersPerStatus({
				status_id: s.id,
				limit: DEFAULT_LIMIT,
				offset: DEFAULT_OFFSET,
				search: props.searchValue
			}).then(response => {
				const newOrders = response.orders.filter(o => o.order_status_id !== undefined);
				setOrders(prev => prev.concat(newOrders));
				setOrdersOffset(prev => prev.concat({ status_id: s.id, offset: DEFAULT_OFFSET, total: response.total }));
				count = count - 1;
				if (count <= 0) {
					setLoader(LoadingStateEnum.LOADED);
				}
			});
		});

		getOrderStatusesTotalSum().then(response => {
			setOrderSum(response);
		});
	}

	React.useEffect(() => {
		initOrders(statuses);
	}, [props.searchValue]);

	React.useEffect(() => {
		getOrderStatuses().then((response) => {
			setStatuses(response);
			initOrders(response);
		});
	}, []);

	function endScroll(e: HTMLDivElement, statusId: number): void {
		if (e.offsetHeight + e.scrollTop >= e.scrollHeight) {
			updateOrdersColumn(statusId);
		}
	}

	function onDragEnd(result): void {
		if (!result.destination) {
			return;
		}

		const selectOrder: Order | undefined = orders.filter(o => o.id == result.draggableId)?.shift();
		const selectStatus: OrderStatus | undefined = statuses.filter(s => s.id == result.destination.droppableId.split('_')[1])?.shift();
		if ((selectOrder == null) || (selectStatus == null)) {
			return;
		}

		const newStatusId = selectStatus.id;
		selectOrder.order_status_id = newStatusId;

		updateOrder({ id: selectOrder.id, order_status_id: newStatusId } as Order).then((response) => {
			if (response.valueOf()) {
				setOrders(orders.filter(o => o.id !== selectOrder.id).concat(selectOrder));
			}
		})
			.catch(() => {
				setOrders(JSON.parse(JSON.stringify(orders)));
			});
	}

	return (
		<Container>
			{
				orderId && <Popup popupStyle={{ width: '100%', height: '100%', top: '0px', left: '0px', animate: true }}
					content={(<PopupOrderDetail onClose={() => setOpen(false)} order_id={orderId} />)}
					isOpen={isOpen} hasBackground={true} onClickOut={() => setOpen(false)} />
			}
			<ContainerDetail id='horizontal_container'>
				<StatusTop onWheel={e => {
					const container = document.getElementById('horizontal_container');
					if (container != null) {
						container.scrollLeft += e.deltaY;
					}
				}}>
					{
						statuses.map((st) => (
							<BlockStatus width={`${(100.0 / statuses.length)}%`} key={`OrderStatus[${st.id}]`}>
								<Status color={st.color_code}>
									{st.name}
								</Status>
							</BlockStatus>
						))
					}
				</StatusTop>
				{/* <Separator /> */}
				<DragDropContext onDragEnd={onDragEnd}>
					<CardContainer>
						{
							loader === LoadingStateEnum.LOADING
								? <Loader center width='25px' />
								: statuses.map((st) => (
									<Droppable droppableId={`column_${st.id}`} key={`OrderStatusColumn[${st.id}]`}>
										{(provided) => (
											<div style={{
												height: '100%',
												width: `${(100.0 / statuses.length)}%`,
												maxWidth: '270px',
												minWidth: '210px',
												display: 'flex',
												justifyContent: 'center',
												backgroundColor: '#FFFFFF'
											}} ref={provided.innerRef}>
												<CardColumn {...provided.droppableProps} onScroll={(e) => endScroll(e.currentTarget, st.id)}>
													{orders.map((order) => {
														return order.order_status_id === st.id && (
															<Draggable key={order.id} draggableId={order.id.toString()} index={order.id} isDragDisabled={!canUpdate}>
																{(dragProvided) => (
																	<div ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
																		<Card {...dragProvided.dragHandleProps}
																			onClickCapture={() => {
																				OpenPopup(order.id);
																			} }>
																			<CardTitle>{order.reference}</CardTitle>
																			<CardText><CartTextTransparent>Date: </CartTextTransparent>{moment(order.created_at).format('DD/MM/YYYY').toString()}
																			</CardText>
																			<CardText>{formatCurrency(order.sum_with_tax)}</CardText>
																			<CardRow>
																				{order.owner_photo
																					? <CardImage src={order.owner_photo} />
																					: <Avatar userId={order.owner_id} name={order.owner_name ?? ''} width='20px' fontSize='10px'/>
																				}
																				<CardText>{order.owner_name}</CardText>
																			</CardRow>
																		</Card>
																	</div>
																)}

															</Draggable>

														);
													})}
													{ordersOffset && orders.filter(o => o.order_status_id === st.id).length < (ordersOffset.find(o => o.status_id === st.id)?.total || 0) &&
														<LoaderContainer>
															<Loader center width='20px' id={`loader_kanban_${st.id}`} />
														</LoaderContainer>}
												</CardColumn>
											</div>
										)}
									</Droppable>
								))
						}
					</CardContainer>
				</DragDropContext>
				<TotalPriceContainer onWheel={e => {
					const container = document.getElementById('horizontal_container');
					if (container != null) {
						container.scrollLeft += e.deltaY;
					}
				}}>
					{
						statuses.map((st) => {
							const orderTotalSum: orderStatusTotalSum | undefined = orderSum.find(os => st.id === os.id);

							return (
								<BlockTotalPrice width={`${(100.0 / statuses.length)}%`} key={`OrderStatusTotal[${st.id}]`}>
									<TotalPrice>{`${formatCurrency((orderTotalSum != null) ? orderTotalSum.total : 0.0)}`}</TotalPrice>
								</BlockTotalPrice>
							);
						})
					}
				</TotalPriceContainer>
			</ContainerDetail>
		</Container>
	);
}

export default TemplateOrdersDetail;
