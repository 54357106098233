import Loadable from 'react-loadable';
import PropagateLoader from '../components/pageLoader/PropagateLoader';
import NotFound from '../containers/404/NotFound';
import Forgot from '../containers_v2/auth/password/Forgot';
import Reset from '../containers_v2/auth/password/Reset';

// subRoutes
import FormsRoutes from './subRoutes/FormsRoutes';
import CompaniesRoutes from './subRoutes/CompaniesRoutes';
import CompanyMapRoutes from './subRoutes/CompanyMapRoutes';
import ContactsRoutes from './subRoutes/ContactsRoutes';
import OpportunitiesRoutes from './subRoutes/OpportunitiesRoutes';
import SystemSettingsRoutes from './subRoutes/SystemSettingsRoutes';
import ProductsRoutes from './subRoutes/ProductsRoutes';
import EnForm from './subRoutes/EnForm';
import ReportsRoutes from './subRoutes/ReportsRoutes';
import CalendarRoutes from './subRoutes/CalendarRoutes';
import OrdersRoutes from './subRoutes/OrdersRoutes';
import GalleryRoutes from './subRoutes/GalleryRoutes';
import OAuthRoutes from './subRoutes/OAuthRoutes';
import ProductsRoutesV2 from './subRoutes/ProductsRoutesV2';
import { ObjectAction } from '../../../web-types/objectActions';
import PromotionsRoutes from './subRoutes/PromotionsRoutes';
import ShelfAuditRoutesV2 from './subRoutes/ShelfAuditsRoutes';
import TargetRoutes from './subRoutes/TargetRoutes';
import DashboardRoutes from './subRoutes/DashboardRoutes';
import ReportView from '../containers_v2/reports/reportView';
import FileManagerRoutes from './subRoutes/FileManagerRoutes';

export interface SubRoute {
  path: string
  name: string | JSX.Element
  permission?: ObjectAction[]
  cumulative?: boolean	
  beta?: boolean
  subMenu?: string
  admin?: boolean
}

export interface Route {
  component: (props) => JSX.Element | null
  path: string
  key?: string
  exact?: boolean
  subMenu?: string
  menu?: boolean
  role?: string[]
  sectionKey?: string
  title?: string | JSX.Element
  subTitle?: string | JSX.Element
  restricted?: boolean
  logoImg?: string
  hoverImg?: string
  activeImg?: string
  permission?: ObjectAction[]
  submenu?: boolean
  precedence?: number
  name?: string | JSX.Element
  beta?: boolean
  subroutes?: SubRoute[]
  detail?: string | JSX.Element
  subPath?: string
  admin?: boolean
  parent?: Route
  logoSize?: string
}

const App = Loadable({
	loader: async() => await import('./App'),
	loading: PropagateLoader
});

// const LegacyApp = Loadable({
//   loader: () => import('./LegacyApp'),
//   loading: PropagateLoader
// });

const Login = Loadable({
	loader: async() => await import('../containers_v2/auth/login/Login'),
	loading: PropagateLoader
});

const NotValid = () => {
	window.location.href = '/404';
};

const routes: Route[] = [
	{
		component: Login,
		path: '/login',
		key: 'login',
		exact: true
	},
	{
		component: Forgot,
		path: '/password/forgot',
		key: 'forgot',
		exact: true
	},
	{
		component: Reset,
		path: '/password/reset',
		key: 'forgot',
		exact: true
	},
	{
		component: ReportView,
		path: '/report-view',
		key: 'report-view',
		exact: true
	},
	...CalendarRoutes(),
	...CompanyMapRoutes(),
	...CompaniesRoutes(),
	...ProductsRoutes(),
	...OAuthRoutes(),
	...ContactsRoutes(),
	...OrdersRoutes(),
	...OpportunitiesRoutes(),
	...PromotionsRoutes(),
	...FormsRoutes(),
	...ReportsRoutes(),
	...GalleryRoutes(),
	...EnForm(),
	...SystemSettingsRoutes(),
	...ProductsRoutesV2(),
	...ShelfAuditRoutesV2(),
	...TargetRoutes(),
	...DashboardRoutes(),
	...FileManagerRoutes(),
	{
		component: NotFound,
		path: '/404',
		key: 'notFound',
		exact: true
	},
	{
		component: NotValid,
		path: '*',
		key: 'notValid',
		exact: true
	}
];

export default [
	{
		component: App,
		path: '/',
		routes
	}
];
