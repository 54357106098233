import * as React from 'react';
import { Translate, translateToString } from '../../../styles/global/translate';
import { RejectedValue, RejectedValueItem } from './action';
import styled from 'styled-components';
import { BlueSidely, BorderColor, BorderColor2 } from '../../../styles/global/css/Utils';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { useFunctionState } from '../../../utils/customHooks';
import GoogleMapReact from 'google-map-react';
import { MapStyleDefault } from '../../map/style';
import { Position } from '../../map/modalRight/model';
import MapPin from '../../map/MapPin';
import { Marker } from '../../map/Map';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { updateCompany } from '../data/action';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { ACompanyEdition } from '../../../atoms/company/companyEdition';
import { useRecoilState } from 'recoil';
import { Slider } from '@mui/material';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { Percentage } from '../../reports/interpretor/ReportInterpretor';
import { FlexDiv } from '../../products/style';
import { OptionBlock, OptionImageJsx, OptionText } from '../../../components_v2/dropdown/style/Style';
import magnifying_glass_v2 from 'images/icons/magnifying_glass_v2.svg';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import google_svg from 'images/icons/gmap_icon.svg';

const ButtonWrapper = styled.div<{ gap?: string }>`
	display: flex;
	width: 100%;
	align-items: center;
	gap: ${({ gap }) => gap ?? '15px'};	
`;

const RejectedDiv = styled.div`
	display: flex;
	flex-flow: column;
	gap: 15px;
	width: 100%;
	padding-top: 15px;
	border-top: 1px solid ${BorderColor};
`;

const MapWrapper = styled.div`
	height: 300px;
	width: 100%;
	position: relative;
`;


const Button = styled(DefaultButton)`
	font-size: 11px;
	margin: 0;
	white-space: nowrap;
	width: 100px;
	flex-shrink: 0;
`;

const defaultCenter = { lat: 48.85356482880834, lng: 2.348096962645894 };

function valuetext(value: number) {
	return `${value * 100}%`;
}
const marks = [
	{
		value: 0,
		label: '0%',
	},
	{
		value: 0.2,
		label: '20%',
	},
	{
		value: 0.4,
		label: '40%',
	},
	{
		value: 0.6,
		label: '60%',
	},
	{
		value: 0.8,
		label: '80%',
	},
];

const Title = styled.h4`
	margin: 0;
	font-weight: 500;
`;
const SubTitle = styled.h5`
	margin: 0;
	font-weight: 400;
	font-size: 12px;
`;

const Splitter = styled.div`
	width: 100%;
	border-bottom: 1px solid ${BorderColor2};
`;

const GoogleClickWrapper = styled(OptionText)`
	display: flex;
	align-items: center;
	height: 40px;
	padding-left: 20px;
	cursor: pointer;
	width: 100%;
`;

const DropdownTagSearch = styled.input`
	${DefaultText}
	min-width: 50px;
	width: 100%;
	border: none;
	padding-left: 0.5em;
	background: none;
	flex-shrink: 1;
`;
const DropdownTagSearchContainer = styled.div`
	height: 40px;
	display: flex;
	padding: 0 20px;
`;

const InnerOptionContainer = styled.div<{ noOptions: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: ${({ noOptions }) => noOptions ? 2 : 1}00px;
	overflow: auto;
`;

function DropdownOptions(props: { options: DropdownData[], onOptionClicked: (v: DropdownData, i: number) => void, addGoogleAddress: (value: RejectedValueItem) => void, setIsOpen: (b: boolean) => void }) {
	const { options, onOptionClicked } = props;
	const [isGoogleMap, setIsGoogleMap] = React.useState<boolean>(false);
	const [search, setSearch] = React.useState<string>('');
	const onNodeCreate = React.useCallback((node: HTMLInputElement) => node?.focus?.(), []);

	const handleAddress = React.useCallback(async(address: string) => {
		setSearch(address);
		const results = await geocodeByAddress(address);
		const bestResult = results[0];
		if (!bestResult) return;
		const res: RejectedValueItem = {
			title: address,
			id: '',
			resultType: 'google',
			houseNumberType: '',
			address: {
				label: address,
				countryCode: '',
				countryName: '',
				stateCode: '',
				state: '',
				county: '',
				city: '',
				disctrict: '',
				street: '',
				postalCode: '',
				houseNumber: ''
			},
			position: {
				lat: 0,
				lng: 0,
			},
			access: []
		};
		for (const addr of bestResult.address_components) {
			const StreetExist = addr.types.find(type => type == 'street_number');
			const RouteExist = addr.types.find(type => type == 'route');
			const countryExist = addr.types.find(type => type == 'country');
			let cityExist = addr.types.find(type => type == 'locality');
			const postCodeExist = addr.types.find(type => type == 'postal_code');

			if (StreetExist != undefined) {
				res.address.houseNumber = addr.long_name;
			}

			if (RouteExist != undefined) {
				res.address.street = addr.long_name;
			}

			if (countryExist != undefined) {
				res.address.countryName = addr.long_name;
				res.address.countryCode = addr.short_name;
			}

			if (cityExist != undefined) {
				res.address.city = addr.long_name;
			}

			if (cityExist == undefined && res.address.city == '') {
				cityExist = addr.types.find(
					type => type == 'administrative_area_level_2'
				);

				if (cityExist != undefined) {
					res.address.city = addr.long_name;
				}
			}

			if (postCodeExist != undefined) {
				res.address.postalCode = addr.long_name;
			}
		}

		res.position = await getLatLng(bestResult);
		props.addGoogleAddress(res);
		props.setIsOpen(false);
	}, []);
	const noOptions = options.length === 0;
	
	return <>
		<InnerOptionContainer noOptions={noOptions}>
			{options.map((option, i) => <OptionBlock
				key={`option[dropdownGeoPopup][${i}]`}
				onClick={e => {
					e.stopPropagation();
					onOptionClicked(option, i);
				}}
			>
				{
					(option.imageJsx != null) && <OptionImageJsx>{option.imageJsx}</OptionImageJsx>
				}
				<OptionText>{option.label}</OptionText>
			</OptionBlock>)}
		</InnerOptionContainer>
		{!noOptions && <Splitter />}
		{!isGoogleMap && <GoogleClickWrapper
			color={BlueSidely}
			onClick={e => {
				e.stopPropagation();
				setIsGoogleMap(true);
			}}
		>
			<Translate id='add_address_manualy' />
		</GoogleClickWrapper>}
		{isGoogleMap && <div onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
			<PlacesAutocomplete
				value={search}
				onChange={setSearch}
				onSelect={handleAddress}
			>
				{({ getInputProps, suggestions }) => {
					return (
						<>
							<DropdownTagSearchContainer>
								<img src={magnifying_glass_v2} width='20px' />
								<DropdownTagSearch onChange={e => setSearch(e.target.value)} value={search} innerRef={onNodeCreate} {...getInputProps()} />
							</DropdownTagSearchContainer>
							<InnerOptionContainer noOptions={noOptions}>
								{suggestions.map((s, i) => <OptionBlock
									key={`option[dropdownGeoPopup][Google][${i}]`}
									onMouseDown={() => handleAddress(s.description)}
								>
									<OptionText>{s.description}</OptionText>
								</OptionBlock>)}
							</InnerOptionContainer>
						</>);
				}}
			</PlacesAutocomplete>
		</div>}
	</>;
}

export default function GeoPopupContent(props: { changed: number, rejected: number, rejected_values: RejectedValue[], setTitle: (title: JSX.Element) => void }) {
	const [changed, setChanged] = React.useState(props.changed);
	const [rejected, setRejected] = React.useState(props.rejected);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [values, setValues] = React.useState(props.rejected_values);
	const [indexes, setIndexes] = useFunctionState<[number, number]>([0, 0], ({ oldValue, newValue }) => {
		setCenter(values[newValue[0]]?.items?.sort()[newValue[1]]?.position ?? defaultCenter);
		if (oldValue[0] !== newValue[0] && newValue[0] <= props.changed + props.rejected) {
			props.setTitle(<><Translate id='enrichment' /> ({newValue[0] + 1} / {props.rejected_values.length})</>);
		}
		return newValue;
	});
	const [zoom, setZoom] = React.useState<number>(10);
	const [center, setCenter] = React.useState<Position>(values[indexes[0]]?.items?.[indexes[1]]?.position ?? defaultCenter);
	const [, setAtomCompanyEdition] = useRecoilState(ACompanyEdition);
	const [sliderValue, setSliderValue] = React.useState(0.6);

	const rejectedValue = values[indexes[0]];
	const dataList = rejectedValue?.items?.map((value, i): DropdownData => ({
		label: value.address.label,
		value: { value, i },
		imageJsx: value.scoring ? <Percentage percentage={value.scoring.queryScore} width='65px' fontSize='11px' minHeight='20px'/> : <FlexDiv width='65px' height='20px' justify='center'><img src={google_svg} height='100%' /></FlexDiv>
	})) ?? [];

	function addGoogleAddress(address: RejectedValueItem) {
		if (!rejectedValue.items) rejectedValue.items = [];
		rejectedValue.items.unshift(address);
		setValues(values => [...values]);
		setIndexes(([a]) => [a, 0]);
	}

	function updateItem(item: RejectedValueItem, id: number) {
		let billing_address: string | undefined = undefined;
		if (item.address.houseNumber && item.address.street) {
			billing_address = `${item.address.houseNumber} ${item.address.street}`;
		} else if (item.address.street) {
			billing_address = item.address.street;
		}
		const params = {
			client_company_id: id,
			latitude: item.position.lat,
			longitude: item.position.lng,
			billing_address,
			billing_country: item.address.countryName,
			billing_city: item.address.city,
			billing_postcode: item.address.postalCode,
			ressource_id: id,
		};
		return updateCompany(params).then(() => params);
	}

	function updateCompanyGeo() {
		setLoadingState(LoadingStateEnum.LOADING);
		const item = rejectedValue.items?.[indexes[1]];
		if (!item) return;
		updateItem(item, rejectedValue.company.id).then(params => {
			setAtomCompanyEdition(params);
			setValues(values => {
				const newValues = [...values];
				newValues[indexes[0]].updated = true;
				return newValues;
			});
			setChanged(changed + 1);
			setRejected(rejected - 1);
			setLoadingState(LoadingStateEnum.LOADED);
			if (values[indexes[0] + 1]) setIndexes([indexes[0] + 1, 0]);
		}).catch(() => setLoadingState(LoadingStateEnum.ERROR));
	}

	async function updateSlider() {
		const items = values.reduce((acc: {id: number, item: RejectedValueItem}[], { company, items, updated }) => {
			if (updated) return acc;
			const item = items?.find(item => (item.scoring?.queryScore ?? 0) >= sliderValue);
			if (item) {
				acc.push({ id: company.id, item });
			}
			return acc;
		}, []);

		const newValues = [...values];
		setLoadingState(LoadingStateEnum.LOADING);
		for (const { id, item } of items) {
			try {
				const params = await updateItem(item, id);
				setAtomCompanyEdition(params);
				const newItem = newValues.find(({ company }) => id === company.id);
				setChanged(changed => changed + 1);
				setRejected(rejected => rejected - 1);
				if (newItem) {
					newItem.updated = true;
				}
			} catch (e) {
				console.error(e);
			}
		}
		setLoadingState(LoadingStateEnum.LOADED);
		setIndexes([newValues.findIndex(({ updated }) => !updated), 0]);
	}

	return <>
		<FlexDiv flow='column' align='stretch' gap='0.5em'>
			<Title>
				{translateToString('enriched_companies_NUMBER', [['NUMBER', changed.toString()]])}
			</Title>
			<Title>
				{translateToString('rejected_companies_NUMBER', [['NUMBER', rejected.toString()]])}
			</Title>
		</FlexDiv>
		{rejectedValue && 
			<RejectedDiv>
				<Title>
					{rejectedValue.company.name}
				</Title>
				<SubTitle>
					{rejectedValue.company.billing_address}, {rejectedValue.company.billing_city}, {rejectedValue.company.billing_post_code}, {rejectedValue.company.billing_country}
				</SubTitle>
				<ButtonWrapper>
					<Dropdown
						disabled={rejectedValue.updated}
						readOnly	
						datalist={dataList}
						selectedValue={dataList[indexes[1]]}
						name='dropdownGeoPopup'
						dropdownStyle={{ containerWidth: '100%', width: '100%', optionWidth: '100%', optionHeight: '250px', height: '31px' }}
						onChange={value => setIndexes([indexes[0], value.value.i])}
						JSXOption={({ options, onOptionClicked, setIsOpen }) => <DropdownOptions options={options} onOptionClicked={onOptionClicked} addGoogleAddress={addGoogleAddress} setIsOpen={setIsOpen}/>}
					/>
					<Button onClick={updateCompanyGeo} disabled={loadingState === LoadingStateEnum.LOADING || !rejectedValue.items?.[indexes[1]] || rejectedValue.updated}>
						<Translate id='update' />
					</Button>
					<Button
						buttonStyle={ButtonStyle.White}
						disabled={values.findIndex((v, i) => !v.updated && i < indexes[0]) === -1 || loadingState === LoadingStateEnum.LOADING}
						onClick={() => setIndexes([values.reduce((acc, v, i) => !v.updated && i < indexes[0] ? i : acc, -1), 0])}
					>
						<Translate id='previous'/>
					</Button>
					<Button
						buttonStyle={ButtonStyle.White}
						disabled={values.findIndex((v, i) => !v.updated && i > indexes[0]) === -1 || loadingState === LoadingStateEnum.LOADING}
						onClick={() => setIndexes([values.findIndex((v, i) => !v.updated && i > indexes[0]), 0])}
					>
						<Translate id='next'/>
					</Button>
				</ButtonWrapper>
				<MapWrapper>
					<GoogleMapReact
						bootstrapURLKeys={{
							key: 'AIzaSyBkQkAoGTXdwr-MrDWQYQPt0zvAw57T7p0'
						}}
						defaultCenter={center}
						center={center}
						defaultZoom={zoom}
						zoom={zoom}
						options={(maps) => ({
							styles: MapStyleDefault,
							zoomControlOptions: {
								position: maps.ControlPosition.RIGHT_TOP,
								style: maps.ZoomControlStyle.SMALL
							}
						})}
						onChange={val => {
							setZoom(val.zoom);
							setCenter(val.center);
						}}
						yesIWantToUseGoogleMapApiInternals
						// onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
					>
						<Marker
							key='crime'
							lat={(values[indexes[0]]?.items?.[indexes[1]]?.position ?? defaultCenter).lat}
							lng={(values[indexes[0]]?.items?.[indexes[1]]?.position ?? defaultCenter).lng}
						>
							<MapPin color={BlueSidely} style={{ transform: 'translate(-18px, -36px)' }}/>
						</Marker>
					</GoogleMapReact>
					<ComponentLoader loadingState={loadingState} />
				</MapWrapper>
				<FlexDiv flow='column' align='stretch' gap='7px'>
					<SubTitle><Translate id='bulk_update_companies_score' /></SubTitle>
					<ButtonWrapper gap='30px'>
						<Slider
							disabled={LoadingStateEnum.LOADING === loadingState}
							track="inverted"
							aria-labelledby="track-inverted-slider"
							getAriaValueText={valuetext}
							defaultValue={0.6}
							onChange={(_, value: number) => setSliderValue(value)}
							min={0}
							step={0.05}
							max={0.8}
							valueLabelDisplay="auto"
							marks={marks}
							sx={{
								color: BlueSidely,
								width: '250px',
								'& .MuiSlider-thumb': {
									width: '10px',
									height: '10px'
								},
								'& .MuiSlider-track': {
									height: '4px'
								},
								'& .MuiSlider-valueLabel': {
									top: '0',
									backgroundColor: 'transparent',
									fontSize: '0.7rem',
									color: 'black'
								},
								'& .Mui-active': {
									boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16) !important'
								}
							}}
						/>
						<Button
							onClick={updateSlider}
							buttonStyle={ButtonStyle.Default}
							disabled={LoadingStateEnum.LOADING === loadingState}
						>
							<Translate id='modify_all' />
						</Button>
					</ButtonWrapper>
				</FlexDiv>
			</RejectedDiv>
		}
	</>;
}