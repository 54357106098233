import * as React from 'react';
import { Translate } from 'react-localize-redux';
import CalendarView from '../../containers_v2/calendar/CalendarView';
import { Route } from '../Routes';
import CalendarRouteBlack from 'images/menu_icon/calendar_black.svg';
import CalendarRouteBlue from 'images/menu_icon/calendar_blue.svg';
import CalendarRouteWhite from 'images/menu_icon/calendar_white.svg';

const CalendarRoutes = (): Route[] => [
	{
		component: CalendarView,
		path: '/calendar',
		name: <Translate id="calendar" />,
		role: ['all'],
		key: 'calendar',
		sectionKey: 'calendar',
		title: 'calendar',
		subTitle: 'calendar',
		restricted: false,
		exact: true,
		logoImg: CalendarRouteBlack,
		hoverImg: CalendarRouteBlue,
		activeImg: CalendarRouteWhite,
		permission: ['ViewCalendar'],
		menu: false,
		submenu: true,
		precedence: 0
	}
];

export default CalendarRoutes;
