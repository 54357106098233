import React, {Component, Fragment} from 'react';
import {change, Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {renderField, required, textAreaField} from '../../../../../components/FieldModal';
import {selectOpportunityStatus} from '../../../../../store/actions/select.action';
import DateField from '../../../../../components/DateField';
import SelectInCharge from '../../../../../components/select/SelectInCharge';
import SelectProspectOrigin from '../../../../../components/select/SelectProspectOrigin';
import {createOpportunity} from '../../../../../store/actions/contacts.action';
import {normalizeCapitalize, normalizeCurrency2} from '../../../../../helpers/fieldMask';
import SelectStatus from '../../../../../components/select/SelectOpportunityStatus';

class ModalCreateOpportunity extends Component {
  state = {
    probability: 0
  };
  componentDidMount() {
    const { selectOpportunityStatus } = this.props;
    selectOpportunityStatus(true);
  }

  getProbability = value => {
    this.setState({ probability: value });
  };

  componentWillReceiveProps(nextProps) {
    const { dispatch, current, statusOpportunity } = this.props;

    if (current !== nextProps.current) {
      dispatch(change('modalForm', 'incharge', nextProps.current.id));
    }

    if (statusOpportunity !== nextProps.statusOpportunity) {
      if (nextProps.statusOpportunity.length > 0) {
        const status = nextProps.statusOpportunity[0];
        this.setState({ probability: status.probability });
        dispatch(change('modalForm', 'opportunityStatusId', status.id));
      }
    }
  }

  onSubmit = value => {
    const { createOpportunity, id } = this.props;
    const formData = new FormData();
    const keys = [
      'title',
      'description',
      'incharge',
      'deadline',
      'prospectOrigin',
      'opportunityStatusId'
    ];

    formData.append(
      'amount',
      value.amount ? value.amount.replace(/,/g, '.') : 0
    );

    keys.map(key => {
      value[key] && formData.append(key, value[key].value || value[key]);
      return null;
    });

    formData.append('contactId', id.contactId);
    formData.append('clientCompanyId', id.clientCompanyId);
    formData.append('probability', this.state.probability);

    createOpportunity(formData, id);
  };

  render() {
    const { handleSubmit, id, isLoading } = this.props;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader toggle={this.props.toggle}>New Opportunity</ModalHeader>
        <ModalBody>
          <form
            className="forms-sample background-form"
            onSubmit={handleSubmit(this.onSubmit)}
            style={{ boxShadow: 'none' }}
          >
            <Fragment>
              <div className="row">
                <div className="col-md-11">
                  <div className="row">
                    <Translate>
                      {({ translate }) => (
                        <div className="col-md-12">
                          <Field
                            name="title"
                            type="text"
                            component={renderField}
                            label={<Translate id="title" />}
                            placeholder="Enter Title"
                            normalize={normalizeCapitalize}
                          />
                          <Field
                            name="description"
                            type="text"
                            component={textAreaField}
                            label="Description"
                            placeholder="Enter Description"
                          />
                          <SelectInCharge
                            name="incharge"
                            label={<Translate id="owner" />}
                            placeholder="Choose In Charge"
                            requiredStar
                            validate={[required]}
                            id={id}
                          />
                          <Field
                            name="deadline"
                            component={DateField}
                            label="Deadline"
                            requiredStar
                            validate={[required]}
                          />
                          <Field
                            name="amount"
                            type="text"
                            component={renderField}
                            label={<Translate id="amount" />}
                            placeholder="Enter Amount"
                            normalize={normalizeCurrency2}
                          />
                          <SelectStatus
                            name="opportunityStatusId"
                            label={translate('status')}
                            placeholder={translate('pick_status')}
                            requiredStar
                            validate={[required]}
                            getProbability={this.getProbability}
                          />
                          <SelectProspectOrigin
                            name="prospectOrigin"
                            label="Prospect Origin"
                            placeholder="Choose Prospect Origin"
                            id={id}
                          />
                        </div>
                      )}
                    </Translate>
                  </div>
                </div>
                <div className="col-md-4" />
              </div>
            </Fragment>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit(this.onSubmit)}
            loading={isLoading}
            spinColor="#003d79"
          >
            {!isLoading && <Translate id="save" />}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.contacts.isLoading,
  company: state.contacts.dataDetail.clientCompany,
  current: state.user.current,
  statusOpportunity: state.select.opportunityStatus
});

export default reduxForm({
  form: 'modalForm'
})(
  connect(
    mapStateToProps,
    { createOpportunity, selectOpportunityStatus }
  )(ModalCreateOpportunity)
);
