import * as React from 'react';
import styled from 'styled-components';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData, DropdownStyle } from '../../../components_v2/dropdown/model/Model';
import InputSearch from '../../../components_v2/input/InputSearch';
import PageLoader, { PageLoaderColors } from '../../../components_v2/pageLoader/PageLoader';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import Switch from '../../../components_v2/Switch/Switch';
import { diff, isSuperAdmin } from '../../../components_v2/utils';
import { HUBSPOT_CLIENT_ID, GOOGLE_CLIENT_ID, OUTLOOK_CALENDAR_CLIENT_ID } from '../../../config/keys';
import {
	isConnectedExternalService,
	removeIntegration
} from '../../../containers/systemSettings/Integrations/actions';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../../import/model';
import { FlexDiv } from '../../products/style';
import { OauthConfigurationMappings, getOauthConnectionConfigs, getOauthConnectionConfigsPriorities, getPkceLink, syncOauthConnectionConfig } from './actions';
import { Id, SaveButton, valueToCreationEditionOrDeletion } from './statusesAndTagsSettings';
import { SystemSettingsWrapper, SystemSettingTitle } from './styles';
import CalendarImage from 'images/icon/systemSettings/calendar.png';
import OutlookCalendarImage from 'images/external_companies/outlook_calendar.svg';
import EmailImage from 'images/icon/systemSettings/email.png';
import OutlookEmailImage from 'images/external_companies/outlook.svg';
import HubspotImage from 'images/external_companies/hubspot.svg';
import SalesforceImage from 'images/external_companies/salesforce.svg';
import SellsyImage from 'images/external_companies/sellsy.svg';
import LabelImage from 'images/icon/systemSettings/label.png';
import { useCookies } from 'react-cookie';
import Input from '../../../components_v2/input/Input';
import { InputStyle } from '../../../components_v2/input/model/Model';
import Add from '../../../components_v2/add/Add';
import { DeleteDot } from '../../../styles/global/css/Dot';
import GoogleButtonDarkDisabled from 'images/google-buttons/btn_google_signin_dark_disabled_web.png';
import GoogleButtonDark from 'images/google-buttons/btn_google_signin_dark_normal_web.png';

const CONFIGURABLE_SERVICES = ['hubspot'];

type ThirdPartyType = 'calendar' | 'crm' | 'emailing';

export const hosts = ['hubspot', 'google_calendar', 'gmail', 'sellsy', 'outlook_calendar'] as const;
export type Host = typeof hosts[number];

export const hostToRustEnum = (host: Host): string => {
	switch (host) {
		case 'hubspot': return 'Hubspot';
		case 'google_calendar': return 'GoogleCalendar';
		case 'gmail': return 'GMail';
		case 'sellsy': return 'Sellsy';
		case 'outlook_calendar': return 'OutlookCalendar';
	}
};

type ThirdParty = {
	host?: Host,
	title: string,
	type: ThirdPartyType,
	imageSrc: string,
	commingSoon?: true,
	admin?: true,
	scopes?: string,
	buttonVariant?: 'google'
};

const THIRD_PARTY_LIST: ThirdParty[] = [
	{
		title: 'Google Calendar',
		imageSrc: CalendarImage,
		host: 'google_calendar',
		type: 'calendar',
		scopes: 'https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.calendarlist.readonly',
		buttonVariant: 'google'
	},
	{
		title: 'Outlook Calendar',
		imageSrc: OutlookCalendarImage,
		type: 'calendar',
		host: 'outlook_calendar',
	},
	{
		title: 'Gmail',
		imageSrc: EmailImage,
		host: 'gmail',
		type: 'emailing',
		scopes: 'https://www.googleapis.com/auth/gmail.readonly',
		buttonVariant: 'google'
	},
	{
		title: 'Outlook',
		imageSrc: OutlookEmailImage,
		type: 'emailing',
		commingSoon: true,
	},
	{
		title: 'Hubspot',
		imageSrc: HubspotImage,
		host: 'hubspot',
		type: 'crm',
	},
	{
		title: 'Sellsy',
		imageSrc: SellsyImage,
		host: 'sellsy',
		type: 'crm',
		admin: true
	},
	{
		title: 'Salesforce',
		imageSrc: SalesforceImage,
		commingSoon: true,
		type: 'crm'
	},
];

const CardsContainer = styled.div`
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	padding: 0 10px
`;

const Card = styled.div`
	position: relative;
	width: 320px;
	display: flex;
	justify-content: center;
	flex-flow: column;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	align-items: center;
	color: ${SidelyBlack};
	font-size: 16px;
	font-weight: 600;
	gap: 1em;
	padding: 2em 0;
`;

const CommingSoon = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	background-image: url('${LabelImage}');
	width: 20px;
	height: 20px;
	width: 84px;
	height: 74px;
`;

const CardImg = styled.img`
	height: 50px;
`;

const LocalButton = styled(DefaultButton)`
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60%;
	height: 35px;
`;

const GoogleButton = styled(LocalButton)`
	background: url('${GoogleButtonDark}');
	background-size: cover;
	height: 44px;
	&:disabled {
		background: url('${GoogleButtonDarkDisabled}');
		background-size: cover;
	}
`;

async function getPkceLinkAndSetCookie(host: Host, setCookie: (name: string, value: string, options?: object) => void): Promise<string> {
	try {
		const { url, verifier } = await getPkceLink(hostToRustEnum(host));
		const expires = new Date();
		expires.setTime(expires.getTime() + (60 * 5 * 1000));
		setCookie('pkce_verifier', verifier, { path: '/', expires });
		return url;
	} catch (e) {
		console.error(e);
		return '';
	}
}

export default function ThirdPartyIntegration(props: {
	type: ThirdPartyType,
	setToolBarState: (value: ToolbarState) => void
}) {
	const [, setCookie] = useCookies(['pkce_verifier']);
	const [search, setSearch] = React.useState<string>('');
	const superAdmin = isSuperAdmin();
	const thirdPartyList: ThirdParty[] = React.useMemo(() => THIRD_PARTY_LIST.filter(th => th.type === props.type && (!th.admin || th.admin && superAdmin)), [props.type]);
	// @ts-expect-error initialization
	const [isLoading, setLoading] = React.useState<{ [key in Host | 'NONE']: LoadingStateEnum | null }>(hosts.reduce((acc, h) => { acc[h] = null; return acc; }, {}));
	// @ts-expect-error initialization
	const [isConnected, setConnected] = React.useState<{ [key in Host | 'NONE']: boolean | undefined }>({});
	const [configuration, setConfiguration] = React.useState<ThirdParty>();

	React.useEffect(() => {
		setConfiguration(undefined);
		// @ts-expect-error initialization
		setLoading(hosts.reduce((acc, h) => { acc[h] = null; return acc; }, {}));
		// @ts-expect-error initialization
		setConnected({});
	}, [props.type]);

	React.useEffect(() => {
		if (configuration) return;
		props.setToolBarState({});
	}, [configuration]);

	React.useEffect(() => {
		if (!isLoading || !isConnected) return;
		thirdPartyList.forEach(val => {
			if ((!superAdmin && val.admin) || val.commingSoon || !val.host) return;
			const host = val.host;
			if (!isLoading[host]) {
				isLoading[host] = LoadingStateEnum.LOADING;
				setLoading({ ...isLoading });
				switch (host) {
					case undefined:
						return;
					default:
						isConnectedExternalService(host)
							.then(response => {
								isLoading[host] = LoadingStateEnum.LOADED;
								setLoading({ ...isLoading });
								isConnected[host] = response.data;
								setConnected({ ...isConnected });
							})
							.catch(err => {
								console.log(err);
								isLoading[host] = LoadingStateEnum.ERROR;
								setLoading({ ...isLoading });
							});
				}
			}
		});
	}, [isLoading]);


	async function handlePopUp(tp: ThirdParty) {
		if (!tp.host) return;
		const height = window.screen.height - 300;
		const width = 500;
		let left = window.screen.width - width;
		left = left > 0 ? left / 2 : 0;
		let top = window.screen.height - height;
		top = top > 0 ? top / 2 : 0;
		let link = '';
		switch (tp.host) {
			case 'google_calendar':
			case 'gmail': {
				link = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&response_type=code&scope=${tp.scopes}&access_type=offline&prompt=consent&redirect_uri=${location.protocol}//${location.host}/oauth/${tp.host}`;
				break;
			}
			case 'hubspot':
				link = `https://app-eu1.hubspot.com/oauth/authorize?client_id=${HUBSPOT_CLIENT_ID}&redirect_uri=${location.protocol}//${location.host}/oauth/hubspot&scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read%20crm.objects.quotes.write%20crm.objects.quotes.read%20crm.schemas.quotes.read%20crm.objects.line_items.read%20crm.objects.line_items.write%20crm.schemas.line_items.read`;
				break;
			case 'sellsy': {
				link = await getPkceLinkAndSetCookie(tp.host, setCookie);
				break;
			}
			case 'outlook_calendar':
				link = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${OUTLOOK_CALENDAR_CLIENT_ID}&response_type=code&redirect_uri=${location.protocol}//${location.host}/oauth/${tp.host}&scope=offline_access%20Calendars.ReadWrite%20User.Read`;
				break;
		}
		let win = window.open(
			link,
			'OAuth Authorization',
			`menubar=no, status=no, width=${width}, height=${height}, top=${top}, left=${left}`
		);
		const timer = setInterval(function(host: Host) {
			if ((win != null) && win.closed) {
				isLoading[host] = null;
				setLoading({ ...isLoading });
				clearInterval(timer);
				win = null;
			}
		}, 1000, tp.host);
	}

	const handleClick = (tp: ThirdParty, isConnected: boolean | undefined, loadingState: LoadingStateEnum | undefined | null) => {
		if (loadingState === LoadingStateEnum.LOADING || !tp.host) return;
		const host = tp.host;
		if (loadingState === LoadingStateEnum.ERROR) {
			isLoading[host] = null;
			setLoading({ ...isLoading });
			return;
		}
		if (isConnected) {
			isLoading[host] = LoadingStateEnum.LOADING;
			setLoading({ ...isLoading });
			removeIntegration(host)
				.then(_ => {
					isLoading[host] = null;
					setLoading({ ...isLoading });
				})
				.catch(e => {
					console.log(e);
					isLoading[host] = LoadingStateEnum.ERROR;
					setLoading({ ...isLoading });
				});
			return;
		}
		handlePopUp(tp);
	};
	if (configuration) {
		return <Configuration thirdParty={configuration} setToolBarState={props.setToolBarState} type={props.type} back={() => setConfiguration(undefined)}/>;
	}

	return <SystemSettingsWrapper>
		<SystemSettingTitle>{translateToString(props.type + '_apps')}</SystemSettingTitle>
		<InputSearch name='search-third-party' type='string' onChange={setSearch} placeholder={translateToString('search_for_app')}/>
		<CardsContainer>
			{thirdPartyList.filter(tp => tp.title.toLowerCase().includes(search.toLowerCase())).map(tp => <Card key={`ThirdPartyCard[${tp.title}][${tp.host}]`}>
				{tp.commingSoon && <CommingSoon />}
				<CardImg src={tp.imageSrc}/>
				{tp.title}
				{
					tp.buttonVariant === 'google' && isLoading?.[tp.host ?? 'NONE'] !== LoadingStateEnum.ERROR && !isConnected?.[tp.host ?? 'NONE'] ?
						<GoogleButton
							onClick={() => handleClick(tp, isConnected?.[tp.host ?? 'NONE'], isLoading?.[tp.host ?? 'NONE'])}
							disabled={tp.commingSoon}
						/>
						: <LocalButton
							buttonStyle={isLoading?.[tp.host ?? 'NONE'] === LoadingStateEnum.ERROR ? ButtonStyle.Error : isConnected?.[tp.host ?? 'NONE'] ? ButtonStyle.White : ButtonStyle.Default}
							disabled={tp.commingSoon}
							onClick={() => handleClick(tp, isConnected?.[tp.host ?? 'NONE'], isLoading?.[tp.host ?? 'NONE'])}
						>
							{getButtonText(isLoading?.[tp.host ?? 'NONE'], isConnected?.[tp.host ?? 'NONE'], tp.commingSoon)}
						</LocalButton>
				}
				{tp.host && CONFIGURABLE_SERVICES.includes(tp.host) && <LocalButton
					buttonStyle={ButtonStyle.White}
					disabled={!isConnected?.[tp.host ?? 'NONE']}
					onClick={() => setConfiguration(tp)}
				>
					{translateToNode('parameter_connector')}
				</LocalButton>}
			</Card>)}
		</CardsContainer>
	</SystemSettingsWrapper>;
}

const getButtonText = (isLoading: LoadingStateEnum | undefined | null, isConnected: boolean | undefined, commingSoon?: boolean): React.ReactNode => {
	if (commingSoon) return translateToNode('coming_soon');
	if (isLoading === LoadingStateEnum.LOADING || !isLoading) return <PageLoader color={isConnected ? PageLoaderColors.Blue : PageLoaderColors.White} size={25}/>;
	if (isLoading === LoadingStateEnum.ERROR) return translateToNode('external_integration.error_retry');
	if (isConnected) return translateToNode('external_integration.cancel_connector');
	return translateToNode('external_integration.setup_connector');
	
};

const DROPDOWN_STYLE: DropdownStyle = {
	optionWidth: '100%',
	width: '250px',
	height: '50px'
};

const INPUT_STYLE: InputStyle = {
	width: DROPDOWN_STYLE.width,
	height: '30px'
};

export type OauthConfigurationState = {
	id: Id
	oauth_connection_id?: boolean
	status?: boolean
	create_sidely_to_service?: boolean
	create_service_to_sidely?: boolean
	update_sidely_to_service?: boolean
	update_service_to_sidely?: boolean
	delete_sidely_to_service?: boolean
	delete_service_to_sidely?: boolean
	priority?: number
	mappings?: OauthConfigurationMappings
}

type Priority = {
	name: string,
	id: number
}

function Configuration(props: {
	thirdParty: ThirdParty,
	setToolBarState: (state: ToolbarState) => void,
	back: () => void,
	type: ThirdPartyType
}): JSX.Element {
	const { thirdParty } = props;
	const [defaultState, setDefaultState] = React.useState<OauthConfigurationState>({ id: { unsync: 0 } });
	const [state, setState] = React.useState<OauthConfigurationState>({ id: { unsync: 0 } });
	const [priorities, setPriorities] = React.useState<Priority[]>([]);
	const prioritiesData = priorities.map(value => ({ label: value.name === 'service' ? thirdParty.title : translateToString(value.name), value }));
	const state_differences = diff(defaultState, state);
	const hasDifferences = state_differences ? Object.entries(state_differences).length !== 0 : false;
	
	const fetchConfig = () => {
		if (!thirdParty.host) return;
		getOauthConnectionConfigs(hostToRustEnum(thirdParty.host))
			.then(res => {
				setDefaultState(JSON.parse(JSON.stringify(res)));
				setState(res);
			});
	};

	React.useEffect(fetchConfig, [thirdParty.host]);

	React.useEffect(() => {
		getOauthConnectionConfigsPriorities()
			.then(setPriorities);
	}, []);


	React.useEffect(() => {
		props.setToolBarState({
			bottomRightToolbarComponent: <>
				<SaveButton
					buttonStyle={ButtonStyle.White}
					disabled={!hasDifferences}
					onClick={() => {
						const newState: OauthConfigurationState = JSON.parse(JSON.stringify(defaultState));
						if (!newState.priority) newState.priority = state.priority;
						setState(newState);
					}}>
					{translateToNode('cancel')}
				</SaveButton>
				<SaveButton
					disabled={!hasDifferences || !state.priority}
					onClick={() => {
						if (state_differences?.mappings !== undefined) state_differences.mappings = state.mappings;
						return syncOauthConnectionConfig({
							third_party: thirdParty.host ? hostToRustEnum(thirdParty.host) : '',
							config: valueToCreationEditionOrDeletion({ ...state_differences, id: state.id })
						}).then(fetchConfig);
					}
					}>
					{translateToNode('save')}
				</SaveButton>
			</>,
			bottomLeftToolbarComponent: <FlexDiv gap='0.5em' fontSize='14px' margin='0 0 0 1em'>
				<a onClick={props.back}>{translateToString(props.type)}</a> {'>'} <b>{thirdParty.title}</b>
			</FlexDiv>
		});
	}, [state_differences, thirdParty]);

	return <SystemSettingsWrapper>
		<h3>{translateToNode('integrate_sidely_with', { variables: [['name', thirdParty.title]] })}</h3>
		<SwitchAndDescription value={state.status ?? false} desc={translateToString('status')} onChange={status => setState({ ...state, status })} />
		<p>{translateToNode('parameter_sync_logic', { variables: [['name', thirdParty.title]] })}</p>
		<h4>{translateToNode('company_creation')}</h4>
		<SwitchAndDescription value={state.create_service_to_sidely ?? false} desc={translateToString('create_from_NAME_to_NAME2', [['NAME', 'Sidely'], ['NAME2', thirdParty.title]])} onChange={create_service_to_sidely => setState({ ...state, create_service_to_sidely })} />
		<SwitchAndDescription value={state.create_sidely_to_service ?? false} desc={translateToString('create_from_NAME_to_NAME2', [['NAME2', 'Sidely'], ['NAME', thirdParty.title]])} onChange={create_sidely_to_service => setState({ ...state, create_sidely_to_service })} />
		<h4>{translateToNode('company_update')}</h4>
		<SwitchAndDescription value={state.update_service_to_sidely ?? false} desc={translateToString('update_from_NAME_to_NAME2', [['NAME', 'Sidely'], ['NAME2', thirdParty.title]])} onChange={update_service_to_sidely => setState({ ...state, update_service_to_sidely })} />
		<SwitchAndDescription value={state.update_sidely_to_service ?? false} desc={translateToString('update_from_NAME_to_NAME2', [['NAME2', 'Sidely'], ['NAME', thirdParty.title]])} onChange={update_sidely_to_service => setState({ ...state, update_sidely_to_service })} />
		<h4>{translateToNode('company_delete')}</h4>
		<SwitchAndDescription value={state.delete_service_to_sidely ?? false} desc={translateToString('delete_from_NAME_to_NAME2', [['NAME', 'Sidely'], ['NAME2', thirdParty.title]])} onChange={delete_service_to_sidely => setState({ ...state, delete_service_to_sidely })} />
		<SwitchAndDescription value={state.delete_sidely_to_service ?? false} desc={translateToString('delete_from_NAME_to_NAME2', [['NAME2', 'Sidely'], ['NAME', thirdParty.title]])} onChange={delete_sidely_to_service => setState({ ...state, delete_sidely_to_service })} />
		<h4>{translateToNode('priority_in_case_of_conficts')}</h4>
		<Dropdown
			datalist={prioritiesData}
			selectedValue={prioritiesData.find(p => p.value.id === state.priority)}
			name='priorityPicker'
			onChange={(value: DropdownData<Priority>) => setState({ ...state, priority: value.value.id })}
			dropdownStyle={DROPDOWN_STYLE}
		/>
		<h4>{translateToNode('mapping_fields')}</h4>
		<MappingField
			title={translateToString('status')}
			onChange={status => setState(s => ({ ...s, mappings: { options: [], ...s.mappings, status } }))}
			value={state.mappings?.status}
		/>
		{state.mappings?.options?.map((obj, i) => <DoubleMappingField
			key={`MappingOption[${i}]`}
			value={obj[1]}
			onTitleChange={value => {
				obj[0] = value;
				setState(s => ({ ...s }));
			}}
			onValueChange={value => {
				obj[1] = value;
				setState(s => ({ ...s }));
			}}
			onDelete={() => {
				state.mappings?.options.splice(i, 1);
				setState({ ...state });
			}}
			title={obj[0]}
		/>)}
		<Add onClick={() => {
			if (!state.mappings) state.mappings = { options: [], status: '' };
			if (!state.mappings.options) state.mappings.options = [];
			state.mappings?.options.push(['', '']);
			setState({ ...state });
		}} size='20px'/>
	</SystemSettingsWrapper>;
}

function MappingField(props: { value: string | undefined, onChange: (value: string) => void, title: string }) {
	return <FlexDiv gap='1em'>
		<p>{props.title}</p>
		<Input name='' type='text' onChange={props.onChange} value={props.value} inputStyle={INPUT_STYLE}/>
	</FlexDiv>;
}

function DoubleMappingField(props: { value: string | undefined, onTitleChange: (value: string) => void, onValueChange: (value: string) => void, title: string | undefined, onDelete: () => void }) {
	return <FlexDiv gap='1em'>
		<Input name='' type='text' onChange={props.onTitleChange} value={props.title} inputStyle={INPUT_STYLE}/>
		<Input name='' type='text' onChange={props.onValueChange} value={props.value} inputStyle={INPUT_STYLE}/>
		<DeleteDot onClick={props.onDelete} size='20px' />
	</FlexDiv>;
}

function SwitchAndDescription(props: {value: boolean, desc: string, onChange: (b: boolean) => void}) {
	return <FlexDiv gap='1em'>
		<Switch
			value={props.value}
			onChange={props.onChange}/>
		<p>{props.desc}</p>
	</FlexDiv>;
}