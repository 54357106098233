import SortableElement from './sortable-element';
import PlaceHolder from './form-place-holder';
import BaseFormElements from './form-elements';

const {
  StartSection,
  EndSection,
  StartTable,
  EndTable,
  Header,
  Website,
  Checkbox,
  Email,
  AddressField,
  DropdownList,
  DatePicker,
  Image,
  Hyperlink,
  FreeText,
  Numerical,
  Document,
  UserField,
  Calculation,
  Camera
} = BaseFormElements;

const FormElements = {};

FormElements.StartSection = SortableElement(StartSection);
FormElements.EndSection = SortableElement(EndSection);
FormElements.StartTable = SortableElement(StartTable);
FormElements.EndTable = SortableElement(EndTable);
FormElements.Header = SortableElement(Header);
FormElements.FreeText = SortableElement(FreeText);
FormElements.Numerical = SortableElement(Numerical);
FormElements.Website = SortableElement(Website);
FormElements.Checkbox = SortableElement(Checkbox);
FormElements.Email = SortableElement(Email);
FormElements.AddressField = SortableElement(AddressField);
FormElements.DropdownList = SortableElement(DropdownList);
FormElements.DatePicker = SortableElement(DatePicker);
FormElements.Image = SortableElement(Image);
FormElements.Hyperlink = SortableElement(Hyperlink);
FormElements.Document = SortableElement(Document);
FormElements.UserField = SortableElement(UserField);
FormElements.Calculation = SortableElement(Calculation);
FormElements.Camera = SortableElement(Camera);
FormElements.PlaceHolder = SortableElement(PlaceHolder);

export default FormElements;
