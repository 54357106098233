import FreeFormImage from 'images/enrichedForm/freeForms.svg';
import ShelfAuditListImage from 'images/enrichedForm/shelfAuditList.svg';
import OrderImage from 'images/enrichedForm/shelfAuditList.svg';

export const listSelect = [
	{
		label: 'Free forms',
		url: FreeFormImage,
		value: 'free_forms'
	},
	{
		label: 'sidebar.shelf_audits',
		url: ShelfAuditListImage,
		value: 'shelf_audit'
	},
	{
		label: 'orders.title',
		url: OrderImage,
		value: 'orders',
		slug: 'owner'
	}
];

export const listButton = [{
	label: 'Step 2',
	classType: 'btn-previous mr-3',
	step: 2
},
{
	label: 'Step 4',
	classType: 'btn-primary',
	step: 4
}
];

export const listTab = [
	'overview',
	'comment',
	'Pictures',
	'Products recap',
	'missing products'
];

export const setDropdown = () => ({
	url: FreeFormImage,
	val: 'free_forms'
});

export const merchandising = [{
	label: 'POS advertising',
	value: 'POS advertising',
	typeHover: 'buttonOption',
	type: 'free_text',
	description: 'Identify presence of POS advertising'
},
{
	label: 'Tag presence',
	value: 'Tag presence',
	typeHover: 'buttonOption',
	type: 'free_text',
	description: 'Identify presence of the product tag'
},
{
	label: 'Number of facings',
	value: 'Number of facings',
	type: 'number',
	typeHover: 'numberFacing',
	description: 'Identify presence of POS advertising'
},
{
	label: 'De-listing risk',
	value: 'De-listing risk',
	type: 'free_text',
	typeHover: null,
	description: 'Identify presence of POS advertising'
},
{
	label: 'Type of shelf',
	value: 'Type of shelf',
	typeHover: 'selectOpt',
	type: 'select',
	description: 'Identify presence of POS advertising'
},
{
	label: 'Product highlight',
	value: 'Product highlight',
	type: 'free_text',
	typeHover: 'buttonOption',
	description: 'Identify presence of POS advertising'
},
{
	label: 'Bull’s eye zone',
	value: 'Bull’s eye zone',
	type: 'free_text',
	typeHover: 'buttonOption',
	description: 'Identify if the product is in the correct shelf'
},
{
	label: 'Vertically grouped offer',
	value: 'Vertically grouped offer',
	type: 'free_text',
	typeHover: 'buttonOption',
	description: 'Identify presence of POS advertising'
},
{
	label: 'Horizontally grouped offer',
	value: 'Horizontally grouped offer',
	type: 'free_text',
	typeHover: 'buttonOption',
	description: 'Identify presence of POS advertising'
}
];

export const price = [{
	label: 'Average price recorded',
	value: 'Average price recorded',
	type: 'number'
},
{
	label: 'Discount',
	value: 'Discount',
	type: 'number'
}
];

export const sale = [{
	label: 'Average weekly sales',
	value: 'Average weekly sales',
	type: 'number'
},
{
	label: 'Average monthly sales',
	value: 'Average monthly sales',
	type: 'number'
}
];

export const quantities = [{
	label: 'Quantity of products',
	value: 'Quantity of products',
	type: 'number'
},
{
	label: 'Quantity risk of waste',
	value: 'Quantity risk of waste',
	type: 'number'
}
];

export const picturePolicyLabel = [{
	label: 'Make pictures compulsory at the beginning of the audit',
	id: 1
},
{
	label: 'Make pictures compulsory at the end of the audit',
	id: 2
},
{
	label: 'Allow pictures to be uploaded from the phone',
	id: 3
}
];

export const defaultValueField = {
	mandatory: false,
	required: false,
	values: {}
};
export const fields = [{
	...defaultValueField,
	element: 'FreeText',
	field_name: 'Free Text',
	field_type: 'free_text',
	icon: 'demo-icon icon-t',
	system_field_name: 'free_text'
},
{
	...defaultValueField,
	element: 'DropdownList',
	field_name: 'Dropdown',
	field_type: 'dropdown',
	img: 'list.svg',
	system_field_name: 'dropdown'
},
{
	...defaultValueField,
	element: 'Numerical',
	field_name: 'Numerical',
	field_type: 'numerical',
	icon: 'demo-icon icon-1',
	system_field_name: 'numerical'
},
{
	...defaultValueField,
	element: 'Checkbox',
	field_name: 'Checkbox',
	field_type: 'checkbox',
	icon: 'demo-icon icon-1',
	system_field_name: 'checkbox'
},
{
	...defaultValueField,
	element: 'TextArea',
	field_name: 'TextArea',
	field_type: 'text_area',
	icon: 'demo-icon icon-t',
	system_field_name: 'text_area'
}
];

export const getElements = type => {
	switch (type) {
		case 'float':
		case 'int':
			return fields[2];
		case 'shelf_type':
		case 'shelf_sector':
		case 'shelf_zone':
		case 'product_highlight':
		case 'discount_type':
		case 'discount_level':
			return fields[1];
		case 'bool':
			return fields[3];
		case 'string':
			return fields[4];
		default:
			return fields[0];
	}
};

export const getProgressbarColor = percent => {
	if (percent < 7) {
		return 1;
	}
	if (percent < 17) {
		return 2;
	}
	if (percent < 27) {
		return 3;
	}
	if (percent < 37) {
		return 4;
	}
	if (percent < 47) {
		return 5;
	}
	if (percent < 57) {
		return 6;
	}
	if (percent < 67) {
		return 7;
	}
	if (percent < 77) {
		return 8;
	}
	if (percent < 87) {
		return 9;
	}
	if (percent < 97) {
		return 10;
	}
	return 11;
};

export const simplifyNum = num => {
	const string = num.toString();
	const number = string.split('.');
	if (number[1] !== undefined && number[1] > 2) {
		const value = number[1].substring(0, 2);
		return `${number[0]}.${value}`;
	}
	return num;
};

export const getBgColor = percent => {
	if (percent < 7) {
		return '#7E3932';
	}
	if (percent < 17) {
		return '#c04a40';
	}
	if (percent < 27) {
		return '#F45A4C';
	}
	if (percent < 37) {
		return '#F87942';
	}
	if (percent < 47) {
		return '#FFA340';
	}
	if (percent < 57) {
		return '#FEC940';
	}
	if (percent < 67) {
		return '#FFEA2C';
	}
	if (percent < 77) {
		return '#C8ED34';
	}
	if (percent < 87) {
		return '#87F16D';
	}
	if (percent < 97) {
		return '#2ED47A';
	}
	return '#14AB59';
};
