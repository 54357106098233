import simple_arrow_left from 'images/icon/simple_arrow_left.svg';
import flag from 'images/icon/flag.svg';
import home from 'images/icon/home.svg';
import * as moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';
import { InputAddressResult } from '../../components_v2/input/model/Model';
import { getLocalStorage } from '../../helpers/localStorage';
import { BlueSidely, SidelyBlack } from '../../styles/global/css/Utils';
import { FlexDiv } from '../products/style';
import { DotLassoPosition, LassoDot } from './lasso';
import MapPin from './MapPin';
import { MapEvent, MapEventOptions, Position } from './modalRight/model';
import { Hided, TimeCarouselContainer, TimeDisplayer, TimeMapSelectorContainer, TimeMapSwitcher, TimeSwitcherContainer } from './modalRight/style';
import GoogleMapReact from 'google-map-react';
import { Translate } from 'react-localize-redux';
import { Carousel } from 'react-bootstrap';
import { MAP_STYLE_LIST, MapStyleDefault } from './style';
import { AProfile } from '../../atoms/global/users';
import { useRecoilValue } from 'recoil';
import { UserWithAdditionalFields } from '../settings/userSettings/myProfile';
import { initWaypoint } from './modalRight/ModalCalendar';
import LayersImage from 'images/icon/map/layers.svg';
import { Granularity } from 'bindings/time/Granularity';

let mapStyleVar = parseInt(localStorage.getItem('mapStyle') ?? '0');

const PinPopup = styled(FlexDiv)`
	position: absolute;
	top: 0;
	transform: translate(-50%,calc(-100% - 5px));
	left: 16px;
`;

export function CalendarMapView(props: {
  mapEvents: MapEventOptions[]
  hovered?: string
  setHoveredEvent?: (eventId: number | string | undefined) => void,
  width?: string | number,
  height?: string | number,
  noLink?: boolean,
  time?: moment.Moment,
  defaultTime?: moment.Moment,
  onTimeChange?: (m: moment.Moment) => void
}) {
	const profile = useRecoilValue(AProfile);
	const { mapEvents, hovered, setHoveredEvent, onTimeChange } = props;
	const [zoom, setZoom] = React.useState<number>(6);
	const [center, setCenter] = React.useState<Position>({ lat: 48.85356482880834, lng: 2.348096962645894 });
	const [map, setMap] = React.useState(null);
	const [maps, setMaps] = React.useState<any>(null);
	const [directions, setDirections] = React.useState<any>(undefined);
	const [time, setTime_] = React.useState<moment.Moment>(props.defaultTime ?? moment());
	const setTime = (time: moment.Moment) => {
		setTime_(time);
		onTimeChange?.(time);
	};
	const [mapStyle, setMapStyle_] = React.useState<number>(MAP_STYLE_LIST[mapStyleVar] ? mapStyleVar : 0);
	const setMapStyle = (n: number) => {
		setMapStyle_(n);
		localStorage.setItem('mapStyle', n.toString());
	};

	function createMapOptions(maps) {
		return {
			zoomControlOptions: {
				position: maps.ControlPosition.RIGHT_TOP,
				style: maps.ZoomControlStyle.SMALL
			},
			styles: MAP_STYLE_LIST[mapStyle][0] ?? MapStyleDefault
		};
	}

	function nightModeFn(controlDiv) {
		// Set CSS for the control border.
		const controlUI = document.createElement('div');
		controlUI.style.backgroundColor = '#fff';
		controlUI.style.border = '2px solid #fff';
		controlUI.style.borderRadius = '3px';
		controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
		controlUI.style.cursor = 'pointer';
		controlUI.style.padding = '5px';
		controlUI.style.marginRight = '10px';
		controlUI.style.textAlign = 'center';
		controlUI.title = 'View';
		controlUI.style.width = '40px';
		controlUI.style.height = '40px';
		controlDiv.appendChild(controlUI);

		// Set CSS for the control interior.
		const controlImg = document.createElement('img');
		controlImg.src = LayersImage;
		controlImg.style.width = '27px';
		controlImg.style.height = '27px';
		controlUI.appendChild(controlImg);

		// Activate / Deactivate Lasso
		controlUI.addEventListener('click', () => {
			mapStyleVar = mapStyleVar == 0 ? 1 : 0;
			setMapStyle(mapStyleVar);
		});
	}

	React.useEffect(() => props.time && setTime_(props.time), [props.time]);
	const currentEvents = mapEvents.map(eo => ({ ...eo, events: eo.events.filter(e => e.time.format('LL') == time.format('LL')).sort((a, b) => a.time.diff(b.time)) }));

	function drawRoutes(localMap?, localDirections?: any[], localMaps?) {
		const newDirection = localDirections ?? directions;
		const currentMap = localMap ?? map;
		const currentMaps = localMaps ?? maps;
		localDirections?.forEach(e => e?.directionsRenderer?.setMap(null));
		directions?.forEach(e => e?.directionsRenderer?.setMap(null));
		currentEvents.forEach((eventOption, i) => {
			const events = eventOption.events;
			let currentDirections = localDirections?.[i]
				?? directions?.[i];
			if (!localDirections?.[i] && currentMaps) {
				currentDirections = {
					directionsService: new currentMaps.DirectionsService(),
					directionsRenderer: new currentMaps.DirectionsRenderer({
						suppressMarkers: true,
						suppressBicyclingLayer: true, 
						polylineOptions: {
							strokeColor: eventOption.lineColor ?? BlueSidely
						}
					})
				};
				newDirection[i] = currentDirections;
			}
			currentDirections?.directionsRenderer?.setMap(null);
			if (!currentMap || !events || !currentDirections || !currentMaps || events.length == 0) return;
	
			const storageOrigin: InputAddressResult | undefined = initWaypoint('origin', profile);
			const storageDestination: InputAddressResult | undefined = initWaypoint('destination', profile);
			const waypoints = events.filter(e => !e.noLink).map(event => ({
				location: { lat: event.lat, lng: event.lng },
				stopover: true
			}));
			if (waypoints.length == 0) return;
			const origin: Position = { lat: storageOrigin?.latitude ?? waypoints[0].location.lat, lng: storageOrigin?.longitude ?? waypoints[0].location.lng };
			const destination: Position = { lat: storageDestination?.latitude ?? waypoints[waypoints.length - 1].location.lat, lng: storageDestination?.longitude ?? waypoints[waypoints.length - 1].location.lng };
	
			let travelMode;
			switch (getLocalStorage('transportation-mode')) {
				case 'pedestrian':
					travelMode = currentMaps.TravelMode.WALKING;
					break;
				case 'car':
				case 'truck':
					travelMode = currentMaps.TravelMode.DRIVING;
					break;
				case 'bicycle':
				default:
					travelMode = currentMaps.TravelMode.BICYCLING;
					break;
			}
	
			currentDirections.directionsService.route({
				origin,
				destination,
				waypoints,
				travelMode
			},
			(result, status) => {
				if (status === currentMaps.DirectionsStatus.OK) {
					currentDirections.directionsRenderer.setMap(currentMap);
					currentDirections.directionsRenderer.setDirections(result);
				} else {
					console.error('error fetching directions', result);
				}
			});
		});
		if (newDirection) {
			setDirections([...newDirection]);
		}
	}

	React.useEffect(() => {!props.noLink && drawRoutes();}, [JSON.stringify(mapEvents), JSON.stringify(time), getLocalStorage('transportation-mode')]);

	function handleApiLoaded(map, maps) {
		const nightModeDiv = document.createElement('div');
		nightModeFn(nightModeDiv);
		map.controls[maps.ControlPosition.RIGHT_TOP].push(nightModeDiv);
		const directionsServices: any[] = currentEvents.map(_ => new maps.DirectionsService());
		const directionsRenderers: any[] = currentEvents.map(eo => new maps.DirectionsRenderer({
			suppressMarkers: true,
			suppressBicyclingLayer: true,
			polylineOptions: {
				strokeColor: eo.lineColor ?? BlueSidely
			}
		}));
		directionsRenderers.forEach(dr => dr.setMap(map));
		const directions = currentEvents.map((_, i) => ({ directionsService: directionsServices[i], directionsRenderer: directionsRenderers[i] }));
		setDirections(directions);
		setMap(map);
		setMaps(maps);
		!props.noLink && drawRoutes(map, directions, maps);
	}

	const eventPin = (event: MapEvent, position?: number) => {
		const isHovered = hovered == event.eventId.toString();
		return <div
			style={{ zIndex: isHovered ? 1 : undefined }}
			key={`crime-${event.eventId}`}
			onMouseEnter={() => setHoveredEvent?.(event.eventId)}
			onMouseLeave={() => setHoveredEvent?.(undefined)}
			// eslint-disable-next-line react/no-unknown-property
			lat={event.lat}
			// eslint-disable-next-line react/no-unknown-property
			lng={event.lng}>
			<Hided>
				<MapPin color={isHovered ? BlueSidely : event.color ?? SidelyBlack}/>
				<PinPopup flow='column' className='text-tooltips font-weight-bold show-on-hover'>
					<div>{event.companyName}</div>
					<div>{event.time.format('LT')}</div>
				</PinPopup>
				{position && <DotLassoPosition position={position} left='2px' top='34px' color={isHovered ? BlueSidely : BlueSidely} type={LassoDot.Default} />}
			</Hided>
		</div>;
	};

	return <div style={{ height: props.height, width: props.width, position: 'relative' }}>
		<GoogleMapReact
			bootstrapURLKeys={{
				key: 'AIzaSyBkQkAoGTXdwr-MrDWQYQPt0zvAw57T7p0'
			}}
			defaultCenter={center}
			center={center}
			defaultZoom={zoom}
			zoom={zoom}
			options={createMapOptions}
			onChange={val => {
				setZoom(val.zoom);
				setCenter(val.center);
			}}
			yesIWantToUseGoogleMapApiInternals
			onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
		>
			{currentEvents.map(currentEvents => currentEvents.events.filter(e => !e.noIndex).map((event, i) => eventPin(event, i + 1)))}
			{currentEvents.map(currentEvents => currentEvents.events.filter(e => e.noIndex).map(event => eventPin(event)))}
			{currentEvents.some(ce => ce.events.length > 0) && drawOrigin(profile)}
			{currentEvents.some(ce => ce.events.length > 0) && drawDestination(profile)}
		</GoogleMapReact>
		<TimeMapSelectorContainer>
			<TimeCarousel onTimeChange={setTime} defaultTime={time} time={time}/>
		</TimeMapSelectorContainer>
	</div>;
}

const drawOrigin = (profile: UserWithAdditionalFields) => {
	const origin = initWaypoint('origin', profile);

	if (origin != null) {
		return (
			<div
				key={'crime-origin'}
				// eslint-disable-next-line react/no-unknown-property
				lat={origin.latitude}
				// eslint-disable-next-line react/no-unknown-property
				lng={origin.longitude}
			>
				<Hided>
					<img src={home} width={32} />
					<div className='text-tooltips font-weight-bold show-on-hover'>
						<Translate id='departure' />
					</div>
				</Hided>
			</div>
		);
	}
};

const drawDestination = (profile: UserWithAdditionalFields) => {
	const origin = initWaypoint('origin', profile);
	const destination = initWaypoint('destination', profile);

	if (origin?.latitude == destination?.latitude && origin?.longitude == destination?.longitude) return;
	if (destination != null) {
		return (
			<div
				key={'crime-destination'}
				// eslint-disable-next-line react/no-unknown-property
				lat={destination.latitude}
				// eslint-disable-next-line react/no-unknown-property
				lng={destination.longitude}
			>
				<Hided>
					<img src={flag} width={32} />
					<div className='text-tooltips font-weight-bold show-on-hover'>
						<Translate id='destination' />
					</div>
				</Hided>
			</div>
		);
	}
};

export function TimeCarousel(props: {
	time?: moment.Moment
	defaultTime?: moment.Moment
	onTimeChange?: (time: moment.Moment) => void,
	granularity?: Granularity,
	dateGranularityDifferences?: number,
	format?: (time: moment.Moment) => string,
}): JSX.Element {
	const { defaultTime, onTimeChange } = props;
	const [time, setTime_] = React.useState<moment.Moment>(defaultTime ?? moment());
	const setTime = (time: moment.Moment) => {
		setTime_(time);
		onTimeChange?.(time);
	};

	React.useEffect(() => props.time && setTime_(props.time), [props.time]);
	const [index, setIndex] = React.useState(1);
	const [slide, setSlide] = React.useState(true);

	const timeMinus1 = moment(time).subtract(props.dateGranularityDifferences ?? 1, props.granularity ?? 'day');
	const timePlus1 = moment(time).add(props.dateGranularityDifferences ?? 1, props.granularity ?? 'day');

	return (
		<TimeCarouselContainer>
			<TimeSwitcherContainer onClick={() => {
				if (index > 0) { setIndex(index - 1); }
			}}>
				<TimeMapSwitcher src={simple_arrow_left} />
			</TimeSwitcherContainer>
			<TimeDisplayer>
				<Carousel
					style={{ width: 175, overflow: 'hidden' }}
					controls={false}
					indicators={false}
					interval={null}
					activeIndex={index}
					slide={slide}
					onSlid={() => {
						let newTime: moment.Moment | undefined;
						if (index > 1) {
							newTime = timePlus1;
							setTime(newTime);
						} else if (index < 1) {
							newTime = timeMinus1;
							setTime(newTime);
						}
						if (newTime != null) { onTimeChange?.(newTime); }
						setSlide(!slide);
						setIndex(1);
					}}
				>
					<Carousel.Item>
						<div style={{ display: 'inline-block' }}>{props.format ? props.format(timeMinus1) : timeMinus1.format('LL')}</div>
					</Carousel.Item>
					<Carousel.Item>
						<div style={{ display: 'inline-block' }}>{props.format ? props.format(time) : time.format('LL')}</div>
					</Carousel.Item>
					<Carousel.Item>
						<div style={{ display: 'inline-block' }}>{props.format ? props.format(timePlus1) : timePlus1.format('LL')}</div>
					</Carousel.Item>
				</Carousel>
			</TimeDisplayer>
			<TimeSwitcherContainer onClick={() => {
				if (index < 2) { setIndex(index + 1); }
			}}>
				<TimeMapSwitcher src={simple_arrow_left} right />
			</TimeSwitcherContainer>
		</TimeCarouselContainer>
	);
}
