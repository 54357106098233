import add from 'images/opt_icon/add.svg';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { customStyles } from '../../helpers/styleSelect';

const CaretDownIcon = () => <i className="fas fa-caret-down" />;

const customSingleValue = ({ data }) => (
	<div className="input-select">
		<div className="input-select__single-value">
			{data.icon && <span className="input-select__icon">{data.icon}</span>}
			{/* <span>{data.label}</span> */}
		</div>
	</div>
);

const { Option } = components;

const IconOption = props => (
	<Option {...props} className="option-cs-icon">
		{props.data.icon}
		{/* {props.data.label} */}
	</Option>
);

const DropdownIndicator = props => (
	<components.DropdownIndicator {...props}>
		<CaretDownIcon />
	</components.DropdownIndicator>
);

const MenuList = props => (
	<components.MenuList {...props} className="menulist-cs-icon">
		<div
			className="option-cs-icon pointer"
			style={{ paddingTop: 10 }}
			onClick={() => {
				props.selectProps.onAddClick();
			}}
		>
			<img src={add} alt="" width="25" />
		</div>
		{props.children}
	</components.MenuList>
);

class RenderSelectInput extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onBlur = e => {
		const blurHandler = this.props.onBlur;
		if (blurHandler) {
			blurHandler({
				type: 'blur',
				target: {
					value: e.target.value
				}
			});
		}
	};

	render() {
		const {
			input,
			initInput,
			options,
			name,
			id,
			label,
			noLabel,
			disabled,
			placeholder,
			requiredStar,
			icon,
			addClass,
			onAddClick,
			isClearable,
			meta: { touched, error, warning }
		} = this.props;
		return (
			<div>
				<div className="form-group row m-0">
					{label && (
						<div className={`col-md-3 text-right mt-2 ${addClass}`}>
							<label htmlFor={id}>
								{requiredStar === true ? (
									<span className="text-required">* </span>
								) : (
									''
								)}
								{label}
							</label>
						</div>
					)}
					<div
						className={`col-9 react-select-container ${
							icon ? 'input-group' : ''
						}`}
					>
						<div className="row">
							<div className="col-12 px-1">
								{icon && (
									<div className="input-group-prepend">
										<span className="input-group-text p-2">
											<img
												alt="ProfileImage"
												className="rounded-circle"
												src={icon}
												height="20"
											/>
										</span>
									</div>
								)}
								<Select
									{...input}
									id={id}
									name={name}
									isSearchable
									isDisabled={disabled}
									className={` ${
										touched && error
											? 'focus-error-select react-select-container'
											: 'react-select-container'
									}`}
									classNamePrefix="react-select"
									styles={customStyles}
									placeholder={placeholder}
									optionClassName="needsclick"
									onAddClick={onAddClick}
									options={options}
									// components={{ Option: CustomOption }}
									components={{
										DropdownIndicator,
										SingleValue: customSingleValue,
										Option: IconOption,
										MenuList
									}}
									value={
										input.value &&
                    options.find(option => option.value === input.value)
									}
									isClearable={isClearable}
									onChange={value => {
										input.onChange(value);
									}}
									onBlur={this.onBlur}
								/>
							</div>
						</div>

						<div className="col-12">
							{touched &&
                ((error && error !== 'Required' && (
                	<span className="form-error">{error}</span>
                )) ||
                  (warning && <span className="form-error">{warning}</span>))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RenderSelectInput;
