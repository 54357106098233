import add_tight from 'images/icon/add_tight.png';
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["imagePreview"] }] */
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import noImage from 'images/no-image.jpg';

class FieldDropzoneIcon extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// resizeFile = (file) =>
	//   new Promise((resolve) => {
	//     Resizer.imageFileResizer(
	//       file,
	//       200,
	//       200,
	//       "jpeg",
	//       100,
	//       0,
	//       (uri) => {
	//         resolve(uri);
	//       },
	//       "file"
	//     );
	//   });

	resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				'png',
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				'file'
			);
		});

	imagePreview(file) {
		if (typeof file === 'string') {
			console.log(file);
			return (
				<img
					className="rounded-circle image-thumbnail"
					width="50"
					height="50"
					src={file}
					onError={e => {
						e.target.onerror = null;
						e.target.src = noImage;
					}}
					alt=""
					style={{ width: '50px', height: '50px' }}
				/>
			);
		}
		return (
			<img
				className="rounded-circle image-thumbnail"
				width="50"
				height="50"
				src={URL.createObjectURL(file)}
				alt={file.name}
				style={{ width: '50px', height: '50px' }}
			/>
		);
	}

	render() {
		const {
			input,
			name,
			meta: { error, warning, touched },
			label,
			requiredStar,
			disabled,
			id
		} = this.props;
		const files = input.value;
		return (
			(<div className="form-group dropzone-icon row">
				<div className="col-md-3 my-auto text-right">
					<label htmlFor={id}>
						{requiredStar === true ? (
							<span className="text-required text-danger">* </span>
						) : (
							''
						)}
						{label}
					</label>
				</div>
				<div className="col-md-9">
					<div className={`${disabled && 'disabled'}`}>
						<Dropzone
							className="rounded-circle"
							name={name}
							onDrop={filesToUpload => {
								try {
									this.resizeFile(filesToUpload[0]).then(file => {
										console.log(file);
										input.onChange(file);
									});
								} catch (err) {
									console.log(err);
								}
							}}
							// maxSize={2000000}
							style={{
								width: '50px',
								height: '50px'
							}}
							accept=".jpeg, .png, .jpg"
							multiple={false}
							disabled={disabled}
						>
							{files ? (
								<div className="border dropzone-icon-default rounded-circle">
									{this.imagePreview(files)}
								</div>
							) : (
								<div
									className="border rounded-circle dropzone-icon-default d-flex align-items-center justify-content-center"
									style={{
										background: '#EBEBEB',
										width: '50px',
										height: '50px'
									}}
								>
									{/* <i
                    className="fas fa-plus upload-placeholder"
                    style={{ fontSize: '25px' }}
                  /> */}
									<img
										src={add_tight}
										className="custom-icon-add-tight"
										alt=""
										onClick={() => {
											browserGoBack();
										}}
									/>
								</div>
							)}
						</Dropzone>
					</div>
				</div>
				{touched &&
          ((error && <span className="form-error">{error}</span>) ||
            (warning && <span className="form-error">{warning}</span>))}
			</div>)
		);
	}
}

export default FieldDropzoneIcon;
