import styled from 'styled-components';
import { DarkGreySidely } from '../../../../styles/global/css/Utils';

const Container = styled.div``;

interface ToolbarBoxProps {
  invertRow?: boolean
  zIndex?: number
  padding?: string
}

const ToolbarBox = styled.div<ToolbarBoxProps>`
    display: flex;
    flex-direction: ${p => p.invertRow ? 'row-reverse' : 'row'};
    height: 100%;
    max-height: 60px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    gap: 10px;
    ${p => p.zIndex ? `z-index: ${p.zIndex};` : ''}
    ${p => p.padding ? `padding: ${p.padding};` : ''}
`;

interface ToolbarImageProps {
  width?: string
  height?: string
  round?: boolean
  marginLeft?: string
  marginRight?: string
  hasPointer?: boolean
  backgroundColor?: string
}

const ToolbarImage = styled.img<ToolbarImageProps>`
    width: ${p => p.width ? p.width : '30px'};
    height: ${p => p.height ? p.height : '30px'};
    border-radius: ${p => p.round ? '50%' : '0%'};
    margin-left: ${p => p.marginLeft ? p.marginLeft : '0'};
    margin-right: ${p => p.marginRight ? p.marginRight : '0'};
    cursor: ${p => p.hasPointer ? 'pointer' : 'default'};
    ${p => p.backgroundColor
		? `background-color: ${p.backgroundColor};`
		: ''
}
`;

interface ToolbarTextProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
  hasPointer?: boolean
  marginLeft?: string
  marginRight?: string
}

const ToolbarText = styled.p<ToolbarTextProps>`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: ${p => p.fontWeight ? p.fontWeight : 500};
    font-size: ${p => p.fontSize ? p.fontSize : '14px'};
    // line-height: ${p => p.lineHeight ? p.lineHeight : '27px'};
    color: ${p => p.color ? p.color : DarkGreySidely};
    cursor: ${p => p.hasPointer ? 'pointer' : 'default'};
    margin: 0;
    margin-left: ${p => p.marginLeft ? p.marginLeft : '0'};
    margin-right: ${p => p.marginRight ? p.marginRight : '0'};
    white-space: nowrap;
    max-width: 25vw;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export {
	Container,
	ToolbarBox,
	ToolbarImage,
	ToolbarText
};
