/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {Field} from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import {selectEventType} from '../../store/actions/select.action';

class SelectEventType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectEventType } = this.props;
    selectEventType();
  }

  componentWillReceiveProps(nextProps) {
    const { data, all, isAll = false } = this.props;
    if (nextProps.data != undefined) {
      if (data !== nextProps.data) {
        let opt = [];

        if (isAll) {
          opt = [
            {
              value: null,
              label: <Translate id="all" />
            }
          ];
        }

        nextProps.data.map(e => {
          opt.push({
            value: e.id,
            label: <Translate id={"event." + e.name} />
          });
        });

        this.setState({ opt });
      }
    }
  }

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      placeholder,
      readOnly
    } = this.props;
    // console.log('id', id);
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        readOnly={readOnly}
        options={opt}
        id="inputOwner"
        placeholder={placeholder}
        validate={validate}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.eventType
});

export default connect(mapStateToProps, { selectEventType })(SelectEventType);
