import * as React from 'react';
import CreateEventFromLasso from '../../map/modalRight/createEventFromLasso';
import { InitialMapRow } from '../../map/model';
import { getCompanies } from '../data/action';
import { Company } from '../model/Model';

export default function CompanyBulkEventCreation(props: {
  type: { id: number, name: string }
  onClose: () => void
  ids: number[]
  isAll?: boolean
  body?: object
  onChildChange?: (b: boolean) => void,
  onCreate?: () => void
}) {
	const { type, ids, onClose, isAll, body, onChildChange } = props;
	const [companies, setCompanies] = React.useState<Company[]>();
	const columns = [
		'name',
		'latitude',
		'longitude'
	];

	React.useEffect(() => {
		getCompanies(!body ? { columns } : { ...body, columns }, true).then(({ companies }) => {
			if (isAll && ids.length > 0) {
				setCompanies(companies.filter(c => !ids.includes(c.company_id)));
			} else if (!isAll) {
				setCompanies(companies.filter(c => ids.includes(c.company_id)).sort((a, b) => ids.indexOf(a.company_id) - ids.indexOf(b.company_id)));
			} else {
				setCompanies(companies);
			}
		});
	}, []);

	if (companies == null) return <></>;

	return <CreateEventFromLasso
		onCreate={props.onCreate}
		type={type}
		onBack={onClose}
		noLimit
		onChildChange={onChildChange}
		v2
		noTitle
		companies={companies.map((c): InitialMapRow => ({
			id: c.company_id,
			name: c.name,
			lat: c.latitude ?? 0,
			lon: c.longitude ?? 0,
			ownerId: c.owner_id,
			clientStatusId: c.status_id,
			colorCode: c.company_status_color,
			country: c.company_country
		}))}
	/>;
}
