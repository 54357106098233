import React, {Component} from 'react';
import ComponentHeader from './ComponentHeader';

class FreeText extends Component {
    state = {};

    render() {
        const { id, editElementId } = this.props;
        const isActive = id === editElementId ? 'active' : '';

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        return (
            <div className={`${baseClasses} ${isActive}`}>
                <ComponentHeader {...this.props} />
            </div>
        );
    }
}

export default FreeText;
