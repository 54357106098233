import { Container } from '../../../../client-companies/popup/LinkedElements/style/LinkedElementStyle';
import { NoDataText } from '../../../../client-companies/style/Style';
import * as React from 'react';
import { translateToString } from '../../../../../styles/global/translate';
import styled from 'styled-components';
import { DefaultText } from '../../../../../styles/global/css/GlobalText';
import { SidelyBlack } from '../../../../../styles/global/css/Utils';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../../atoms/global/users';
import { Image } from '../../../../../components_v2/avatar/style/Style';
import { FlexDiv } from '../../../../products/style';
import * as moment from 'moment';
import Avatar from '../../../../../components_v2/avatar/Avatar';


const Border = styled.div`
  ${DefaultText};
  transition: all 0.4s;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 17%);
  border-radius: 6px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
`;
interface ShelfAuditCommentsProps {
    comments: string
    owner_id: number
    date: string
}
export default function ShelfAuditComments({ comments, owner_id, date }: ShelfAuditCommentsProps) {
	const users = useRecoilValue(AUsers);
	const owner = users.find(user => user.id === owner_id);

	return <Container>
		{comments?.length > 0
			? <Border>
				<div style={{ color:SidelyBlack }}>{comments}</div>
				<FlexDiv gap="5px">
					{owner?.photoUrl ?
						<Image url={owner?.photoUrl} width="25px" radius="50%"/>
						: <Avatar width="25px" name={owner?.name ?? ''}/>
					}
					<div style={{ marginLeft:'5px' }}>
						{`
                            ${translateToString('global.created_by')}
                            ${owner?.name},
                            ${moment.utc(date).local().format('LLL')}
                        `}
					</div>
				</FlexDiv>
			</Border>
			: <NoDataText>{translateToString('shelf_audit.no_data.comments')}</NoDataText>
		}
	</Container>;

}