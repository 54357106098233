import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Route } from '../Routes';
import CompaniesAndMapView from '../../containers_v2/client-companies/CompaniesAndMapView';
import MapRouteBlack from 'images/menu_icon/company_map_black.svg';
import MapRouteBlue from 'images/menu_icon/company_map_blue.svg';
import MapRouteWhite from 'images/menu_icon/company_map_white.svg';

const CompaniesRoutes = (): Route[] => [
	{
		component: CompaniesAndMapView,
		path: '/map',
		name: <Translate id="map" />,
		role: ['all'],
		key: 'map',
		sectionKey: 'map',
		logoImg: MapRouteBlack,
		hoverImg: MapRouteBlue,
		activeImg: MapRouteWhite,
		title: <Translate id="map" />,
		subTitle: 'company map',
		permission: ['ViewMap'],
		restricted: false,
		exact: true,
		menu: true,
		precedence: 1
	}
];

export default CompaniesRoutes;
