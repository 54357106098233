import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector } from 'recoil';
import { URL_FOUNDATION } from '../../config/keys';
import { OrderStatus } from '../../containers_v2/orders/model/Model';

// -----------------------[ ATOM ]----------------------- //
export const AOrderStatuses = selector({
	key: 'AOrderStatuses',
	get: async({ get }) => await getOrderStatuses()
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getOrderStatuses(): Promise<OrderStatus[]> {
	try {
		const result = await axios.get<OrderStatus[]>(`${URL_FOUNDATION ?? ''}/api/orders/statuses`)
			.then(response => {
				return response.data;
			});
		return result.sort((a, b) => a.sequence - b.sequence);
	} catch (error) {
		console.error(error);
		return [];
	}
}
