import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

function requestLogout() {
	return {
		type: LOGOUT_REQUEST,
		isFetching: true,
		isAuthenticated: true
	};
}

function receiveLogout() {
	return {
		type: LOGOUT_SUCCESS,
		isFetching: false,
		isAuthenticated: false
	};
}

// Logs the user out
export function logoutUser() {
	return dispatch => {
		dispatch(requestLogout());
		logoutUserWithoutDispatch();
		dispatch(receiveLogout());
	};
}

export function logoutUserWithoutDispatch() {
	cookies.remove('id_token', { path: '/' });
	cookies.remove('role', { path: '/' });
	cookies.remove('access_right', { path: '/' });
	cookies.remove('email', { path: '/' });
	cookies.remove('user_id', { path: '/' });
	cookies.remove('super_admin', { path: '/' });
	cookies.remove('policies', { path: '/' });
	const lang = localStorage.getItem('language');
	const currency = localStorage.getItem('currency');
	localStorage.clear();
	if (lang) localStorage.setItem('language', lang);
	if (currency) localStorage.setItem('currency', currency);
}