/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Nav, NavItem, NavLink} from 'reactstrap';
import PageLoader from '../../../../components/PageLoader';
import ViewDetail from './ViewDetail';
import {
    detailData,
    fetchData,
    getActivities,
    getDocuments,
    getEvents,
    getNotes,
    getOpportunities
} from '../../../../store/actions/contacts.action';
import {getMerchandisings, getTradeshows} from '../../../../store/actions/salesOperations.action';
import {currentData} from '../../../../store/actions/user.action';
import {Translate} from 'react-localize-redux';

class MixtView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      statusId: false
    };
  }

  componentDidMount() {
    const { data, detailData, currentDetailId } = this.props;
    // console.log('currentDetailId', currentDetailId);
    currentData();
  }

  componentWillReceiveProps(nextProps) {
    const { data, detailData, currentDetailId } = this.props;
    if (
      currentDetailId !== nextProps.currentDetailId &&
      this.state.statusId === false
    ) {
      detailData(nextProps.currentDetailId);
      this.setState({
        activeTab: nextProps.currentDetailId,
        statusId: true
      });
    }
  }

  onChangeTab = id => {
    const { detailData } = this.props;
    detailData(id);
    this.setState({
      activeTab: id
    });
  };

  onNext = page => {
    const { fetchData } = this.props;
    fetchData({ page: page + 1 });
  };

  onPrev = page => {
    const { fetchData } = this.props;
    fetchData({ page: page - 1 });
  };

  listData = dataList => {
    const list = [];

    dataList.map(data => {
      list.push(
        <NavItem className="w-100">
          <div key={data.id} className="nav-custom bg-white">
            <NavLink
              active={data.id === this.state.activeTab}
              onClick={() => {
                this.onChangeTab(data.id);
                this.props.onChangeTab(data.id);
              }}
            >
              <div className="pl-2 pt-2 pb-2">
                {data.firstName} {data.lastName}
              </div>
            </NavLink>
            <hr className="m-0" />
          </div>
        </NavItem>
      );
    });
    return list;
  };

  render() {
    const { data, onFetch, totalSize, isLastPage, isLoading } = this.props;

    // console.log('totalSize', data);

    return (
      <React.Fragment>
        {!data ? (
          <div className="list-loader">
            <PageLoader />
          </div>
        ) : (
          <div className="row mt-4em">
            <div className="col-2 pr-0">
              <div className="overflow-auto custom-scroll d-flex flex-column bg-white sticky-mixt-view">
                <Nav>{this.listData(data)}</Nav>
                <div className="w-100 pl-2">
                  <div className="d-flex">
                    {totalSize > 10 && (
                      <button
                        className={`${
                          isLastPage ? 'text-secondary' : 'text-primary'
                        } btn btn-link see-more  mx-auto`}
                        onClick={() => {
                          onFetch();
                        }}
                      >
                        {!isLoading ? (
                          <Translate id="see_more" />
                        ) : (
                          <i className="lds-dual-ring" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-10">
              <ViewDetail {...this.props} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  detail: state.contacts,
  current: state.user.current,
  salesOperation: state.salesOperations
});

export default connect(
  mapStateToProps,
  {
    fetchData,
    currentData,
    detailData,
    getDocuments,
    getOpportunities,
    getActivities,
    getNotes,
    getEvents,
    getTradeshows,
    getMerchandisings
  }
)(MixtView);
