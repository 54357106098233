/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React, { Fragment } from 'react';
import swal from 'sweetalert2';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../helpers/storeLang';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import Restricted from '../containers_v2/permissions/Restricted';

class DropdownAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false
		};
		this.toggle = this.toggle.bind(this);
		this.translate = getTranslate(storeLang.getState().localize);
	}

	toggle(e) {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	cekTranslate = name => {
		return {
			text: this.translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${name}'`),
			confirmButtonText: this.translate('global.fire.confirm_message').toString()
		};
	};

	onDelete = (id, name) => {
		const { deleteData, path, subPath } = this.props;
		swal
			.fire({
				...this.cekTranslate(name),
				title: 'Attention!',
				type: 'warning',
				confirmButtonColor: '#f7685b'
			})
			.then(result => {
				if (result.value) {
					deleteData(id, false, path, subPath);
				}
			});
	};

	render() {
		const { count, id, name, path, permission, noDuplicate } = this.props;
    
		return (
			<Restricted to={permission ? [
				{ objectAction: `Update${permission}` },
				{ objectAction: `Create${permission}` },
				{ objectAction: `Delete${permission}` }
			] : []}>
				<UncontrolledButtonDropdown direction="down">
					<span className="my-auto text-center p-2">Action</span>
					<DropdownToggle className="pr-2 pl-0 bg-white" caret />
					<DropdownMenu className={count && count < 3 && 'dropdownTransform'}>
						<Fragment>
							<Restricted to={permission ? { objectAction: `Update${permission}` } : []}>
								<DropdownItem
									onClick={() => {
										window.location.href = `/${path}/edit/${id}`;
									}}
								>
									<Translate id="edit" />
								</DropdownItem>
							</Restricted>
							<Restricted to={permission ? { objectAction: `Create${permission}` } : []}>
								<DropdownItem
									onClick={() => {
										window.location.href = `/${path}/duplicate/${id}`;
									}}
								>
									<Translate id="duplicate" />
								</DropdownItem>
							</Restricted>
							<Restricted to={permission ? { objectAction: `Delete${permission}` } : []}>
								<DropdownItem onClick={() => this.onDelete(id, name)}>
									<Translate id="delete" />
								</DropdownItem>
							</Restricted>
						</Fragment>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</Restricted>
		);
	}
}

export default DropdownAction;
