import * as React from 'react';
import { getUserColor } from './Data/Data';
import { Container, Text } from './style/Style';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../atoms/global/users';

function Avatar(props: {
  width: string
  name: string
  userId?: number
  fontSize?: string
	backgroundColor?: string
}): JSX.Element {
	const newName = props.name?.trim().split(' ', 2).map(c => c.charAt(0) ?? '').join('').toUpperCase() ?? '';
	const users = useRecoilValue(AUsers);
	const picture = users.find(c => c.id === props.userId)?.photoUrl;
	let color: string | undefined;
	if (props.userId) color = users.find(c => c.id === props.userId)?.color;
	if (props.backgroundColor) color = props.backgroundColor;
	else if (!color) color = getUserColor(props.name);

	return <Container style={{ overflow: 'hidden' }} width={props.width} height={props.width} backgroundColor={color}>
		{picture ? <img src={picture} style={{ borderRadius: '50%' }} height={props.width} width={props.width} /> : <Text fontSize={props.fontSize} name={newName}>{newName}</Text>}
	</Container>;
}

export default Avatar;
