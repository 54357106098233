import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import Dropdown from '../dropdown/Dropdown';
import { DropdownData } from '../dropdown/model/Model';
import { PopupExportProps } from './model/Model';
import Popup from './Popup';
import { Body, CloseButton, Container, ExportButton, Footer, Header, Title } from './style/PopupExportStyle';

function getContent(props: PopupExportProps, setSelected: (value: DropdownData | undefined) => void, selected: DropdownData | undefined): JSX.Element {
	const dropdownData: DropdownData[] = [];
	const translate = getTranslate(storeLang.getState().localize);

	if (props.exportCSV) {
		dropdownData.push({ label: 'CSV', value: 'CSV' });
	}

	if (props.exportExcel) {
		dropdownData.push({ label: 'Excel', value: 'Excel' });
	}

	if (props.exportPDF) {
		dropdownData.push({ label: 'PDF', value: 'PDF' });
	}

	return (
		<Container>
			<Header>
				<Title>{translate('exports.export_file').toString()}</Title>
				<CloseButton onClick={() => (props.onClickOut != null) && props.onClickOut()} />
			</Header>
			<Body>
				<Dropdown datalist={dropdownData} name={'exports'} required readOnly cancellable label={translate('exports.file_type').toString()} onChange={(value) => setSelected(value.value === null ? undefined : value)} />
			</Body>
			<Footer>
				<ExportButton disabled={selected == null} onClick={() => (selected != null) && (props.onClickExport != null) && props.onClickExport(selected)}>{translate('exports.export_button').toString()}</ExportButton>
			</Footer>
		</Container>
	);
}

function PopupExport(props: PopupExportProps): JSX.Element {
	const [selected, setSelected] = React.useState<DropdownData | undefined>();

	return (
		<Popup {...props} popupStyle={{ width: '600px', height: '200px' }} hasBackground content={getContent(props, setSelected, selected)} />
	);
}

export default PopupExport;
