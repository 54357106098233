import { ContactRow } from 'bindings/contacts/ContactRow';
import optionGrey from 'images/icon/options_grey.png';
import eye from 'images/icons/company/eye.svg';
import phone_button from 'images/icons/company/phone_button.svg';
import * as _ from 'lodash';
import { ITag } from 'proto/protobufs';
import * as React from 'react';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners, { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import LightDropdown from '../../../components_v2/dropdown/lightDropdown/LightDropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { Column, DataType } from '../../../components_v2/table/Table';
import { DarkGreySidely } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { AlertContext } from '../../alert/AlertProvider';
import { ColumnResume } from '../../client-companies/Companies';
import { CompanySearchCell, DateAccessor, EditableContent, TagCell, deleteCompanyIdPopup } from '../../client-companies/data/CompanyColumns';
import { CompanyStatus } from '../../client-companies/model/Model';
import { Column as ColumnOrganize, ColumnOrganizer } from '../../client-companies/popup/ColumnOrganizer';
import { ButtonAction } from '../../client-companies/style/PopupStyle';
import { PartialOpen } from '../../client-companies/style/Style';
import { HoverEmailWrapper } from '../../email-templates/emailTemplatePopup';
import { emailValid, validatePhoneNumber } from '../../globals/FieldFunction';
import { LoadingStateEnum } from '../../import/model';
import { Owner } from '../../orders/model/Model';
import { TableRow, TableRowStatus, TableRowStatusBackground } from '../../orders/templateOrders/style/Style';
import PermissionContext from '../../permissions/PermissionContext';
import Restricted from '../../permissions/Restricted';
import { CONTACT_HIDDEN_LOCAL_STORAGE, CONTACT_SORT_LOCAL_STORAGE } from '../Contacts';
import { TableContext } from './ContactContext';
import { QueryUpdateContact } from './Data';

const CONTACT_COLUMN_CATEGORIES = ['contact_informations', 'contact_activity', 'linked_company'] as const;

export const ALL_CONTACT_COLUMNS: Array<ColumnResume> = [
	{ name: 'last_name', excluded: true, fixedLeft: true, categoryIndex: 0 },
	{ name: 'first_name', excluded: true, fixedLeft: true, categoryIndex: 0 },
	{ name: 'position', categoryIndex: 0 },
	{ translationKey: 'status', name: 'client_status_id', categoryIndex: 0 },
	{ name: 'client_company_id', hidded: true, categoryIndex: 2 },
	{ name: 'client_company_name', categoryIndex: 2 },
	{ name: 'phone', categoryIndex: 0 },
	{ name: 'email', categoryIndex: 0 },
	{ translationKey: 'owner', name: 'owner_id', categoryIndex: 0 },
	{ translationKey: 'tags', name: 'tag_id', categoryIndex: 0 },
	{ name: 'created_at', categoryIndex: 1 },
	{ name: 'created_by', hidded: true, categoryIndex: 1 },
	{ name: 'updated_at', hidded: true, categoryIndex: 1 },
	{ name: 'updated_by', hidded: true, categoryIndex: 1 },
	{ name: 'quick_actions', excluded: true, categoryIndex: undefined }
];

export function ColumnHidder(props: { columns: Array<ColumnResume>, categories: readonly string[], hiddenColumnKey: string, sortColumnKey: string }) {
	const { setHiddenColumns, setSortColumns } = React.useContext(TableContext);
	let hiddenColumns = JSON.parse(localStorage.getItem(props.hiddenColumnKey) || '[]');
	let sortColumns = JSON.parse(localStorage.getItem(props.sortColumnKey) || '[]');
	if (!hiddenColumns.length) hiddenColumns = React.useContext(TableContext).hiddenColumns;
	if (!sortColumns.length) sortColumns = React.useContext(TableContext).sortColumns;
	const setLocalHiddenColumns = (values: string[]) => {
		setHiddenColumns(values);
		localStorage.setItem(props.hiddenColumnKey, JSON.stringify(values));
	};
	const setLocalSortColumns = (values: string[]) => {
		setSortColumns(values);
		localStorage.setItem(props.sortColumnKey, JSON.stringify(values));
	};
	const columnMap = props.columns.reduce((acc, v) => {
		acc.set(v.name, v);
		return acc;
	}, new Map<string, ColumnResume>());
	const fixedLength = props.columns.filter(cc => cc.fixedLeft).length;
	const sequence: Map<string, number> = sortColumns.reduce((acc, v, i) => {
		if (columnMap.get(v)?.fixedLeft) return acc;
		acc.set(v, i - fixedLength);
		return acc;
	}, new Map());
	const hidableColumns: ColumnOrganize[] = props.columns
		.filter(cc => !cc.excluded)
		.map((cc, index) => ({
			label: cc.noTranslation ? (cc.translationKey ?? cc.name) : translateToString(cc.translationKey ?? cc.name),
			value: cc.name,
			checked: !hiddenColumns.find(hc => hc === cc.name),
			sequence: sequence.get(cc.name) ?? sequence.size + index,
			categoryIndex: cc.categoryIndex
		}));
	return <ColumnOrganizer
		categories={props.categories}
		columns={hidableColumns}
		onSort={(values) => {
			props.columns.forEach(c => c.fixedLeft && values.unshift(c.name));
			setLocalSortColumns(values);
		}}
		setHiddenColumns={setLocalHiddenColumns}
	/>;
}

export default function ContactColumns(
	users: Owner[],
	onContactOpen: (value: number, mode: boolean) => void,
	onCompanyClick: (id: number) => void,
	updateContact: (contactId: number, params: QueryUpdateContact, localChanges?: { [key: string]: unknown }) => Promise<void>,
	onDelete: (id: number) => void,
	// onDuplicate: (row: DataType<ContactRow>) => void
	loadingState: LoadingStateEnum,
	statuses: CompanyStatus[],
	tags: ITag[],
	updateTags: (contactId: number, body: { ids_delete?: number[], ids_add?: number[] }) => Promise<void>
): Array<Column<ContactRow>> {
	const you = users?.find(u => u.isYou)?.id;
	if (!you) return [];

	return [
		{
			id: 'last_name',
			Header: translateToString('last_name'),
			accessor: row => <TableRow
				fontWeight="500"
				color={DarkGreySidely}
				cursor="pointer"
				onClick={() => onContactOpen(row.id, true)}
			>
				{row.last_name}
			</TableRow>,
			hoverImage: row => <PartialOpen src={eye} onClick={() => onContactOpen(row.id, false)} />,
			width: 200,
			minWidth: 150,
			freeze: 'left',
			type: 'string',
			toolTip: row => row.last_name
		},
		{
			id: 'first_name',
			Header: translateToString('first_name'),
			accessor: row => <TableRow
				fontWeight="500"
				color={DarkGreySidely}
				cursor="pointer"
				onClick={() => onContactOpen(row.id, true)}
			>
				{row.first_name}
			</TableRow>,
			hoverImage: row => <PartialOpen src={eye} onClick={() => onContactOpen(row.id, false)} />,
			width: 200,
			minWidth: 150,
			freeze: 'left',
			type: 'string',
			toolTip: row => row.first_name
		},
		{
			id: 'position',
			Header: translateToString('position'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateContact' }}
				fallback={<TableRow padding='5px'>{row.position}</TableRow>}
				ownerId={row.owner_id}
			>
				<EditableContent<ContactRow>
					innerId={`${row.id}-${loadingState}`}
					row={row}
					valueKey="position"
					regex={() => true}
					onValueUpdate={value => updateContact(row.id, { position: value ? { updated: value } : 'deleted' })}
				/>
			</Restricted>,
			width: 170,
			minWidth: 150,
			editable: true,
			type: 'string',
		},
		{
			id: 'client_status_id',
			Header: translateToString('status'),
			accessor: row => {
				const status = statuses.find(s => s.id === row.status_id);
				return <Restricted
					to={{ objectAction: 'UpdateContact', scope: 'User' }}
					isSelf={row.owner_id === you}
					fallback={<TableRowStatusBackground noPointer><TableRowStatus backgroundColor={status?.color_code}>{status?.name}</TableRowStatus></TableRowStatusBackground>}
				>
					<LightDropdown
						dropdownStyle={{
							containerHeight: 'unset',
							optionWidth: '200px'
						}}
						datalist={statuses?.sort((a, b) => a.sequence - b.sequence).map(s => ({ label: s.name, value: s, color: s.color_code })) ?? []}
						JSXButton={({ onClick }) => <TableRowStatusBackground onClick={onClick}><TableRowStatus backgroundColor={status?.color_code}>{status?.name}</TableRowStatus></TableRowStatusBackground>}
						JSXContent={({ value, onClick }) => <div style={{ margin: 5 }}><TableRowStatusBackground onClick={onClick}><TableRowStatus backgroundColor={value.color}>{value.label}</TableRowStatus></TableRowStatusBackground></div>}
						onChange={value => {
							const status = value.value;
							if (!status) return;
							updateContact(row.id, { status_id: status.id });
						}}
					/>
				</Restricted>;
			},
			width: 150,
			minWidth: 150,
			type: 'status',
		},
		{
			id: 'client_company_id',
			Header: translateToString('client_company_id'),
			accessor: row => <TableRow>{row.client_company_id}</TableRow>,
			width: 100,
			minWidth: 60,
			type: 'numeric',
		},
		{
			id: 'client_company_name',
			Header: translateToString('client_company_name'),
			accessor: row => <CompanySearchCell defaultValue={{ name: row.client_company_name ?? undefined, id: row.client_company_id ?? undefined }} onUpdateCompany={value => updateContact(row.id, { client_company_id: { updated: value.id } }, { client_company_name: value.name })}/>,
			hoverImage: row => row.client_company_id ? <PartialOpen src={eye} onClick={() => row.client_company_id && onCompanyClick(row.client_company_id)} /> : undefined,
			width: 200,
			minWidth: 150,
			type: 'string',
			toolTip: row => row.client_company_name
		},
		{
			id: 'phone',
			Header: translateToString('phone'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateContact' }}
				fallback={<TableRow padding='5px'>{row.phone}</TableRow>}
				ownerId={row.owner_id}
			>
				<EditableContent<ContactRow>
					innerId={`${row.id}-${loadingState}`}
					row={row}
					// onUpdate={onUpdateCompany}
					valueKey="phone"
					regex={validatePhoneNumber}
					onValueUpdate={value => updateContact(row.id, { phone: value ? { updated: value } : 'deleted' })}
				/>
			</Restricted>,
			hoverImage: row => row.phone ? <ButtonAction src={phone_button} alt="phone" onClick={() => window.open(`tel:${row.phone}`)} /> : undefined,
			width: 170,
			minWidth: 150,
			editable: true,
			type: 'string',
		},
		{
			id: 'email',
			Header: translateToString('email'),
			accessor: row => <Restricted
				to={{ objectAction: 'UpdateContact' }}
				fallback={<TableRow padding='5px'>{row.email}</TableRow>}
				isSelf={row.owner_id === you}
			>
				<EditableContent<ContactRow>
					innerId={`${row.id}-${loadingState}`}
					row={row}
					// onUpdate={onUpdateCompany}
					valueKey="email"
					regex={emailValid}
					onValueUpdate={value => updateContact(row.id, { email: value ? { updated: value } : 'deleted' })}
				/>
			</Restricted>,
			hoverImage: row => row.email
				? <HoverEmailWrapper
					emailTemplateVariable={{
						first_name:row.first_name ?? '',
						last_name:row.last_name ?? '',
						company_name:row.client_company_name ?? '',
					}}
					email={row.email} />
				: undefined,
			width: 180,
			minWidth: 150,
			editable: true,
			type: 'string',
		},
		{
			id: 'owner_id',
			Header: translateToString('owner'),
			accessor: row => {
				const user = users.find(u => u.id == row.owner_id);
				return <Restricted
					to={{ objectAction: 'UpdateCompany', scope: 'User' }}
					fallback={<UserBlock user={user} />}
					isSelf={row.owner_id === you}
				>
					<DropdownOwners
						dropdownStyle={{
							containerWidth: 'initial',
							containerJustifyContent: 'flex-start',
							optionWidth: '200px',
							optionHeight: '150px',
							optionPadding: '5px 10px 5px 10px',
							optionLeft: '-10px',
							width: '180px',
							backgroundColor: 'none',
							fontSize: 12
						}}
						users={users.map(u => ({ label: u.name, value: u, image: u.photoUrl })) ?? []}
						selected={user ? { label: user.name, value: users.find(u => u.id == row.owner_id), image: user.photoUrl } : undefined}
						onChange={value => {
							const owner = value.value;
							updateContact(row.id, { owner_id: owner.id });
						}}
					/>
				</Restricted>;
			},
			width: 200,
			minWidth: 200,
			type: 'user',
		},
		{
			id: 'tag_id',
			Header: translateToString('tags'),
			accessor: row => <TagCell
				row={{ ...row, tags: row.tag_ids.reduce((acc, t) => {
					const tag = tags.find(t2 => t2.id === t);
					if (tag) return [...acc, tag];
					return acc;
				}, []) }}
				tags={tags}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				onChange={v => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const ids_add: number[] = v.filter(t => !row.tag_ids.some(ct => ct == t.id)).map(t => t.id!);
					return updateTags(row.id, { ids_add });
				}}
				onDelete={v => updateTags(row.id, { ids_delete: [v] })}
			/>,
			width: 250,
			minWidth: 250,
			type: 'tag',
			disableSortBy: true,
			// visualization: true
		},
		{
			id: 'created_at',
			Header: translateToString('created_at'),
			accessor: row => <DateAccessor date={row.created_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'created_by',
			Header: translateToString('created_by'),
			accessor: row => <UserBlock user={users?.find(u => u.id == row.created_by)} />,
			width: 200,
			minWidth: 150,
			type: 'user'
		},
		{
			id: 'updated_at',
			Header: translateToString('updated_at'),
			accessor: row => <DateAccessor date={row.updated_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'updated_by',
			Header: translateToString('updated_by'),
			accessor: row => <UserBlock user={users?.find(u => u.id == row.updated_by)} />,
			width: 200,
			minWidth: 150,
			type: 'user',
		},
		{
			id: 'quick_actions',
			noHeaderEllipsis: true,
			Header: <ColumnHidder
				categories={CONTACT_COLUMN_CATEGORIES}
				columns={ALL_CONTACT_COLUMNS}
				hiddenColumnKey={CONTACT_HIDDEN_LOCAL_STORAGE}
				sortColumnKey={CONTACT_SORT_LOCAL_STORAGE}
			/>,
			accessor: row => <DropdownOptions row={row} onDelete={onDelete} /*onDuplicate={onDuplicate}*//>,
			width: 40,
			minWidth: 40,
			disableSortBy: true,
			disableFilter: true,
			unresizeable: true,
			freeze: 'right'
		}
	];
}

function DropdownOptions(props: { row: DataType<ContactRow>, onDelete?: (id: number) => void, onDuplicate?: (row: DataType<ContactRow>) => void }): JSX.Element {
	const { row } = props;
	const { alertDelete } = React.useContext(AlertContext);
	const { isAllowedTo } = React.useContext(PermissionContext);
	const list: DropdownData<string>[] = [];
	if (props.onDuplicate && isAllowedTo({ objectAction: 'CreateContact' }, { ownerId: row.owner_id })) {
		list.push({
			label: translateToString('global.quick_action.duplicate'),
			value: 'DUPLICATE'
		});
	}
	if (props.onDelete && isAllowedTo({ objectAction: 'DeleteContact' }, { ownerId: row.owner_id })) {
		list.push({
			label: translateToString('global.quick_action.delete'),
			value: 'DELETE'
		});
	}
	if (list.length == 0) return <></>;
	return <Dropdown
		name='quick_action'
		datalist={list}
		readOnly
		dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
		JSXButton={() =>
			<img
				src={optionGrey}
				width={25}
				height={25}
				style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
			/>
		}
		onChange={(value: DropdownData) => {
			if (value.value === 'DELETE') {
				deleteCompanyIdPopup(alertDelete, row.id, `${row.last_name} ${row.first_name}`, () => props.onDelete?.(row.id));
			} else if (value.value === 'DUPLICATE') {
				props.onDuplicate?.(row);
			}
		}}
	/>;
}
