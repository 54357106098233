/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React, {Fragment} from 'react';
import swal from 'sweetalert2';
import {getTranslate, Translate} from 'react-localize-redux';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from 'reactstrap';
import storeLang from '../../../helpers/storeLang'

class DropdownAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onDelete = (id, name) => {
    const translate = getTranslate(storeLang.getState().localize)
    const { deleteData, params } = this.props;

    swal
      .fire({
        title: 'Attention!',
        text: translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${name}'`),
        type: 'warning',
        confirmButtonText: translate('global.yes'),
        confirmButtonColor: '#f7685b'
      })
      .then(result => {
        if (result.value) {
          deleteData(id, params);
        }
      });
  };

  render() {
    const { count, id, name, path } = this.props;

    return (
      <UncontrolledButtonDropdown direction="down">
        <span className="my-auto text-center p-2">Action</span>
        <DropdownToggle className="pr-2 pl-0 bg-white" caret />
        <DropdownMenu className={count && count < 3 && `dropdownTransform`}>
          <Fragment>
            <DropdownItem
              onClick={() => {
                window.location.href = `/${path}/edit/${id}`;
              }}
            >
              <Translate id="edit" />
            </DropdownItem>

            <DropdownItem onClick={() => this.onDelete(id, name)}>
              <Translate id="delete" />
            </DropdownItem>
          </Fragment>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
}

export default DropdownAction;
