import * as React from 'react';
import styled from 'styled-components';
import { GetFile, GetFolder, confirmFileManagerPost, deleteFile, deleteFolder, folderHasFiles, getAllFromFileManagerPath, getFoldersFromPath, getSingleFileFromPath, getUsage, postFileManagerGetUrl, postToS3, putFile, renameFolder } from './actions';
import InputFile from '../../components_v2/input/InputFile';
import Sha256 from 'sha256-wasm';
import { toast } from 'react-toastify';
import deleteImage from 'images/icon/delete.png';
import useAlert from '../alert/UseAlert';
import downloadImage from 'images/setting_icons/export_icon.svg';
import editImpage from 'images/icon/edit.png';
import { AlertRes } from '../alert/AlertProvider';
import { ProgressBar } from 'react-bootstrap';
import { FlexDiv } from '../products/style';
import { BlueSidely, LightBlueSidely } from '../../styles/global/css/Utils';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { LoadingStateEnum } from '../import/model';
import { ToolbarState } from '../globals/mainPage/mainPage';

import FolderImage from 'images/filemanager/folder.svg';
import NewFolderImage from 'images/filemanager/new_folder.svg';

import NewFileImage from 'images/filemanager/new_file.svg';
import FileBlueImage from 'images/filemanager/file_blue.svg';


import { Translate, translateToString } from '../../styles/global/translate';
import { FileResult } from '../../components_v2/input/model/Model';
import { uploadFile } from './utils';
import { set } from 'lodash';

const MAX_FILES_LIMIT_IN_MO = 200;

const GedBody = styled.div`
	display: flex;
	background-color: #f5f5f5;
	width: 100%;
	min-height: calc(100vh - 300px);
	margin-left: 10px;
	padding-bottom: 20px;
	flex-wrap: wrap;
`;

const Footer = styled.div`
	width: 100%;
	margin-top: 45px;
	margin-left: 10px;
	padding-bottom: 20px;
`;

const FileLabel = FlexDiv.extend`
	align: stretch;
	flow: column;
	justify: space-around;
	font-size: 11px;
	overflow: clip;
	height: 250px;
	width: 100px;
 	text-align: center;
`;

const FolderLabel = FileLabel.extend`
	height: 215px;
`;

const ProgressBarStyle = styled.div`
	padding-right: 20px;
	.progress .progress-bar {
		background-color: ${BlueSidely};
		width: 100%;
	}
	.progress {
		width: 100%;
		height: 20px !important;
	}
`;

const Folder = styled.div`
	height: 100px;
	width: 130px;
	margin-top: 50px;
	background-image: url(${FolderImage});
	background-repeat: no-repeat;
	background-size: 87%;
  	margin-left: 20px;
	margin-left: 20px;
	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
`;

const File = styled.div`
	height: 120px;
	width: 100px;
	background-image: url(${FileBlueImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	overflow-wrap:  break-word;;
	margin-top: 32px;
	margin-left: 20px;
	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
`;


const NewFile = Folder.extend`
	background-image: url(${NewFileImage});
	height: 25px;
	width: 25px;
	display: inline-block;
	margin-top: 0px;
	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}
`;


const NewFolder = Folder.extend`
	height: 25px;
	width: 25px;
	display: inline-block;
	margin-top: 0px;
	background-image: url(${NewFolderImage});
	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}
`;

const UploadBody = styled.div`
	display: flex;
	width: 100%;
	min-height: calc(100vh - 300px);
	margin-left: 10px;
	padding-bottom: 20px;
	flex-wrap: wrap;
	background-image: url(${NewFileImage});
	background-position: center;
	background-size: 12%;
	background-repeat: no-repeat;

`;

const BreadCrumbItem = styled.a`
	background-color: ${LightBlueSidely};
	cursor: pointer;
	padding: 8px;
  	border-radius: 13px;
`;

function clickDownload(blob, filename) {
	const a = document.createElement('a');
	a.download = filename;
	a.href = blob;
	a.click();
	a.remove();
}


function launchToastError(message: string) {
	toast.error(message, {
		position: 'top-right',
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
}

export function FileManager(props: {setToolBarState: (value: ToolbarState) => void}): JSX.Element {
	const [files, setFiles] = React.useState<GetFile[]>([]);
	const [currentPath, setCurrentPath] = React.useState<string>('/');
	const [folders, setFolders] = React.useState<GetFolder[]>([]);
	const alert = useAlert();
	const [usage, setUsage] = React.useState<number>(0);
	const [update, setUpdate] = React.useState<number>(0);
	const [loadingState, setLoadingState] = React.useState(LoadingStateEnum.LOADED);
	const [dragging, setDragging] = React.useState(false);
	const reader = new FileReader();

	
	React.useEffect(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		getAllFromFileManagerPath(currentPath).then(f => setFiles(f));
		getFoldersFromPath(currentPath).then(f => setFolders(f));
		getUsage().then(u => setUsage((u / 1024 / 1024)));
		setLoadingState(LoadingStateEnum.LOADED);

		props.setToolBarState({
			title: translateToString('file_manager.files'),
			bottomLeftToolbarComponent: <>
				
				<BreadCrumbItem onClick={() => setCurrentPath('/')}>
								/
				</BreadCrumbItem>
				{currentPath.split('/').map((breadcrumb, index) => {
					return <>
						{index !== 0 && index + 1 !== currentPath.split('/').length &&
							<>
								<BreadCrumbItem
									onClick={() => {
									// rebuild path using index
										let newPath = '/';
										for (let i = 1; i <= index; i++) {
											newPath += currentPath.split('/')[i] + '/';
										}
										setCurrentPath(newPath);
									}}
								>
									{breadcrumb}
								</BreadCrumbItem>
								{index !== 0 && <>/</>}
							</>
						}
					</>;
				})}
			</>,
			bottomRightToolbarComponent: <>
				<NewFolder
					onClick={
						() => {
							const newFolder = prompt(translateToString('file_manager.enter_new_folder_name'));
							if (newFolder) {
								setLoadingState(LoadingStateEnum.LOADING);
								confirmFileManagerPost({ path: currentPath + newFolder + '/', filename: newFolder, hash: '', filesize: 0 }).then(() => 
									setUpdate(update + 1)
								);
							}
						}
					}
				/>
				<NewFile>
					<InputFile 
						hideAddIcon={true}
						limitMo={Math.round((MAX_FILES_LIMIT_IN_MO - usage) * 100) / 100}
						onChange={(file) => {

							setLoadingState(LoadingStateEnum.LOADING);
							let hash: string | undefined = undefined;
							if (!Sha256.WASM_SUPPORTED) {
								console.log('WebAssembly not supported by your runtime');
							} else {
								hash = Sha256().update(file.content).digest('hex');
							}
							if (hash) {
								uploadFile(file, hash, currentPath).then(msg => {
									setUpdate(update + 1);
								});
							}
						}}
						onError={e => launchToastError(e.message)}
					/>
				</NewFile>
			</>
		});

	}, [currentPath, update]);

	const currentFolder = currentPath.split('/')[currentPath.split('/').length - 2];

	return <>
		<ComponentLoader loadingState={loadingState} allScreen />

		{dragging && <UploadBody
			onDragOver={
				(ev) => {
					ev.preventDefault();
				}
			}
			onDragLeave={
				(ev) => {
					ev.preventDefault();
					setDragging(false);
				}
			}
			onDrop={
				async(ev) => {
					ev.preventDefault();
					if (ev.dataTransfer.files[0].size === 0) {
						launchToastError('Vous ne pouvez pas envoyer de dossiers.');
						setDragging(false);
						return;
					}

					ev.dataTransfer.files;

					let totalSize = 0;
					for (let i = 0; i < ev.dataTransfer.files.length; i++) {
						totalSize += ev.dataTransfer.files[i].size;
					}

					if (MAX_FILES_LIMIT_IN_MO && parseFloat(((totalSize / 1024) / 1024).toFixed(4)) > MAX_FILES_LIMIT_IN_MO) {
						launchToastError('Vous avez dépassé la limite de stockage de ' + MAX_FILES_LIMIT_IN_MO + 'Mo. Veuillez supprimer des fichiers pour continuer. ');
						setDragging(false);
						return;
					}

					setLoadingState(LoadingStateEnum.LOADING);

					const uploadPromises: Promise<string>[] = [];
					for (let i = 0; i < ev.dataTransfer.files.length; i++) {
						const file = ev.dataTransfer.files[i];
						console.log('file:', file);
						if (file != null) {
							const url = URL.createObjectURL(file);

			

							const p = new Promise(() => {
								const reader = new FileReader();
								reader.onload = async evt => {
									console.log('LAUNCH ON LOADs');
									const tab = evt.target?.result?.toString().split(',');
									const contents = (tab != null) ? tab[1] : '';
									const contentType = (tab != null) ? tab[0] : '';
									const f: FileResult = {
										file,
										content: contents,
										contentType,
										url
									};

									let hash: string | undefined = undefined;
									if (!Sha256.WASM_SUPPORTED) {
										console.log('WebAssembly not supported by your runtime');
									} else {
										hash = Sha256().update(f.content).digest('hex');
									}
									if (hash) {
										uploadFile(f, hash, currentPath).then(msg => {
											console.log('test &', msg);
											setUpdate(update => update + 1);
											setDragging(false);
										});
									}
								};
								reader.readAsDataURL(file);
							});
							uploadPromises.push(p as Promise<string>);
						}
					}
					await Promise.all(uploadPromises);

				}
			}
		>
		</UploadBody>}
		{!dragging && <GedBody
			onDragOver={
				(ev) => {
					ev.preventDefault();
					setDragging(true);
				}
			}
		>
			{files.map((file, index) =>
				<>
					<File>
						<img src={editImpage} alt="edit" height={'17px'}
							onClick={() => {
								const newName = prompt(translateToString('file_manager.enter_new_filename'));
								if (newName) {
									setLoadingState(LoadingStateEnum.LOADING);
									putFile({ uuid: file.uuid, filename: newName, path: file.path }).then(() => {
										setUpdate(update => update + 1);
									});
								}
							}}
						/>

						<img src={deleteImage} alt="delete" height={'17px'}
							onClick={() => {
								alert({
									title: translateToString('file_manager.are_you_sure'),
									content: translateToString('file_manager.are_you_sure_delete') + file.filename + '?',
								}).then(res => {
									if (res === AlertRes.Ok) {
										setLoadingState(LoadingStateEnum.LOADING);
										deleteFile(file.hash, currentPath).then(() => {
											setUpdate(update => update + 1);
										});
									}
								});
							}}
						/>
						<img src={downloadImage} alt="download" height={'15px'}
							onClick={() => {
								alert({
									title: translateToString('file_manager.file_download'),
									content: translateToString('file_manager.are_you_sure_download') + file.filename + ' ?',
								}).then(res => {
									if (res === AlertRes.Ok) {
										setLoadingState(LoadingStateEnum.LOADING);
										getSingleFileFromPath(file.path + file.hash).then(f => {
											if (f.url) {
												fetch(f.url, {
													headers: new Headers({
														'Origin': location.origin,
													}),
													mode: 'cors'
												})
													.then(response => response.blob())
													.then(blob => {
														const blobUrl = window.URL.createObjectURL(blob);
														clickDownload(blobUrl, f.filename);
														setLoadingState(LoadingStateEnum.LOADED);
													})
													.catch(e => console.error(e));
											}
										});
									}
								});
							}}
						/>
						<FileLabel>{file.filename}</FileLabel>
					</File>
				</>)}
			
			{ // map folders and filter out current folder
				folders.filter(folder => folder.path !== currentPath + currentFolder).map((folder, index) =>
					<>
						<Folder
							key={index}
							onClick={() => {
								setCurrentPath(folder.path);
							}}
						>
							<img src={editImpage} alt="edit" height={'17px'}
								onClick={ev => {
									ev.stopPropagation();
									const newName = prompt(translateToString('file_manager.enter_new_folder_name'));
									if (newName) {
										setLoadingState(LoadingStateEnum.LOADING);
										renameFolder(folder.uuid, newName, folder.path).then(() => {
											setUpdate(update => update + 1);
										});
									}
								}}
							/>
							<img src={deleteImage} alt="delete" height={'17px'}
								onClick={ev => {
									ev.stopPropagation();
									alert({
										title: translateToString('file_manager.deleting_folder'),
										content: translateToString('file_manager.delete_folder_sure'),
										mode: 'delete'
									}).then(res => {
										if (res === AlertRes.Ok) {
											setLoadingState(LoadingStateEnum.LOADING);
											deleteFolder(folder.uuid, folder.path).then(() => {
												setUpdate(update => update + 1);
											});
										}
									});	
								}}
							/>
							<FolderLabel>{folder.folder_name}</FolderLabel>
						</Folder>
					</>)}
		</GedBody>}
		<Footer>
			<FlexDiv>
				Utilisation du stockage
			</FlexDiv>
			<ProgressBarStyle>
				<ProgressBar
					now={Math.round(usage * 100) / 100}
					min={0}
					max={MAX_FILES_LIMIT_IN_MO}
					variant={usage > MAX_FILES_LIMIT_IN_MO * 0.5 ? usage > MAX_FILES_LIMIT_IN_MO * 0.8 ? 'danger' : 'warning' : 'success'}
				/>
				<FlexDiv width='100%' justify='space-between'>
					<div>0Mo</div>
					<div>Utilisation: {Math.round(usage * 100) / 100}Mo / {MAX_FILES_LIMIT_IN_MO}Mo</div>
					{MAX_FILES_LIMIT_IN_MO}Mo
				</FlexDiv>
			</ProgressBarStyle>
		</Footer>

	</>;
}