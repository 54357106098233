import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { Translate } from 'react-localize-redux';
// import ModalEventForm from '../linkedElements/modals/ModalEventForm';
import { ModalEventForm, ModalMode } from '../../../../containers_v2/calendar/ModalEventForm';
import { detailPin } from '../../../../store/actions/companies.action';
import penUrl from 'images/ui_icon/pen_black.svg';
import trashUrl from 'images/ui_icon/trash_black.svg';

class ModalMapCompany extends Component {
	state = {
		modal: false,
		penUrl,
		trashUrl,
		id: {
			eventId: null,
			clientCompanyId: null
		},
		resetForm: false
	};

	componentWillReceiveProps(nextProps) {
		const { idSelected, detailPin } = this.props;
		if (idSelected !== nextProps.idSelected) {
			detailPin(nextProps.idSelected);
		}
	}

	openModal = (eventId, mode) => {
		this.setState({
			id: {
				eventId,
				clientCompanyId: this.props.idSelected
			},
			modal: true,
			mode,
			resetForm: true
		});
	};

	closeModal = () => {
		this.setState({ modal: false });
	};

	renderButtonEvent = () => {
		return (
			<div className="d-flex justify-content-center py-2">
				<Button
					className="btn btn-primary d-flex justify-content-center"
					onClick={() => this.openModal(null, 'New')}
					spinColor="#003d79"
				>
					<span className="text-white font-weight-bold">
						<Translate id="Add event" />
					</span>
				</Button>
			</div>
		);
	};

	render() {
		const { data, idSelected } = this.props;
		const { detailPin, view } = data;

		let start = new Date();
		let end = new Date();
		end.setHours(end.getHours() + 1);
		return (
			<div>
				{detailPin && !detailPin.isLoadingDetail && (
					<Modal
						backdrop={true}
						contentClassName={`border-0 map-modal ${!view ? 'leftModal' : 'left100'
						}`}
						isOpen={this.props.isOpen}
						toggle={this.props.toggle}
						centered
						backdropClassName="backdrop-map-modal"
					>
						<ModalHeader
							style={{
								backgroundColor: detailPin.clientStatusColor
							}}
							toggle={this.props.toggle}
							className="border-0 text-center modal-header-map"
						>
							{detailPin.clientStatusName || '-'}
						</ModalHeader>
						<ModalBody className="text-center">
							<a
								// onClick={() => {
								//   window.location.href = `/companies?id=${idSelected}`;
								// }}
								onClick={() => window.open(window.location.origin + '/companies?id=' + idSelected, '_blank')}
							>
								<h4>{detailPin.name || '-'}</h4>
							</a>
							<span>{detailPin.billingAddress || '-'}</span>
							{this.renderButtonEvent()}
						</ModalBody>
					</Modal>
				)}

				<ModalEventForm
					isOpen={this.state.modal}
					toggleModal={() => {
						this.closeModal();
					}}
					mode={ModalMode.New}
					company={this.state.id.clientCompanyId}
					defaultStart={start}
					defaultEnd={end}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	isLoading: state.companies.isLoading,
	data: state.companies
});

export default connect(mapStateToProps, { detailPin })(ModalMapCompany);
