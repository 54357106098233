import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ACompanyEdition } from '../../../atoms/company/companyEdition';
import { AUsers } from '../../../atoms/global/users';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners from '../../../components_v2/dropdown/DropdownOwners';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import Input from '../../../components_v2/input/Input';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import Switch from '../../../components_v2/Switch/Switch';
import { DayPickerModal } from '../../../components_v2/toolbarFilter/time/DatePicker';
import storeLang from '../../../helpers/storeLang';
import { getCompanyContacts, getCompanyDetail, getEventStatuses, getEventTypes } from '../../client-companies/data/action';
import { CompanyContact, CompanyEvent, EventStatus, EventType } from '../../client-companies/model/Model';
import { CreationPopupContainer, creationPopupDropdownStyle, creationPopupInputStyle } from '../../client-companies/style/Style';
import { normalizeCapital } from '../../globals/FieldFunction';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany as ClientCompanyType, Owner } from '../../orders/model/Model';
import { CalendarEvent } from '../Calendar';
import { deleteEvent, postEvent } from '../EventsApi';
import { colorStatusMapping, colorTypeMapping, EventParams } from '../model/Model';
import { HorizontalContainer, Spacer, SwitchText, TextArea, TextAreaContainer, TextAreaLabel } from '../style/NewEventStyle';
import { UpdatedField } from '../../../../../web-types/api';
import { ModalFields } from '../ModalEventForm';
import { ARefresh } from '../../../atoms/global/refresh';
import DropdownStatus from '../../../components_v2/dropdown/DropdownStatus';
import PopupDeletion from '../../../components_v2/popup/PopupDeletion';
import { DarkGreySidely, DarkGreySidely2 } from '../../../styles/global/css/Utils';
import ClientCompany from '../../../components_v2/clientCompany/ClientCompany';
import { translateToString } from '../../../styles/global/translate';
import PermissionContext from '../../permissions/PermissionContext';
import { ModalState } from '../../products/model';
import ContactPopup from '../../contact/popup/ContactPopup';
import Restricted from '../../permissions/Restricted';
import { FormTemplate } from '../../forms/jsonValidator';
import { getFormTemplates } from '../../../atoms/forms';

export interface NewEventDefaultValues {
  title?: string
  eventStatusId?: number
  eventTypeId?: number
  start?: Date
  end?: Date
  allDay?: boolean
  incharge?: number
  contactId?: number
  clientCompanyId?: number
  description?: string
  form?: number
}

export enum EventMode {
	Creation,
	Edition,
}

function NewEvent(props: {
	eventMode?: EventMode
	onClickOut?: () => void
	onCreate?: (value: CompanyEvent) => void
	clientCompanyId?: number
	event?: CalendarEvent
	defaultValues?: NewEventDefaultValues
	onValueChange?: (values: Array<[string, UpdatedField | unknown, string | undefined]>) => void
	edition?: boolean
	hide?: ModalFields[]
	hideValidation?: boolean
	onSubmit?: () => void,
	onDelete?: () => void,
	onDuplicate?: () => void,
	readonly?: boolean
}): JSX.Element {
	const { event, onValueChange, edition, defaultValues, hide } = props;

	const translate = getTranslate(storeLang.getState().localize);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [title, setTitle] = React.useState<string>(defaultValues?.title ?? event?.title ?? '');

	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);
	const [company, setCompany] = React.useState<ClientCompanyType | undefined>(event?.clientCompanyId ? { id: event.clientCompanyId, name: event.companieName } as ClientCompanyType : undefined);

	const owners = useRecoilValue(AUsers);
	const [owner, setOwner] = React.useState<Owner | undefined>(owners.find(o => defaultValues?.incharge ? defaultValues?.incharge == o.id : (event != null) ? event.incharge == o.id : o.isYou));

	const [eventTypes, setEventTypes] = React.useState<EventType[]>([]);
	const [eventType, setEventType] = React.useState<EventType>();

	const [eventStatuses, setEventStatuses] = React.useState<EventStatus[]>([]);
	const [eventStatus, setEventStatus] = React.useState<EventStatus>();

	const [contacts, setContacts] = React.useState<CompanyContact[]>([]);
	const [contact, setContact] = React.useState<CompanyContact>();

	const [description, setDescription] = React.useState<string>(defaultValues?.description ?? event?.description ?? '');

	const [isAllDay, setAllDay] = React.useState<boolean>(defaultValues?.allDay ?? event?.allDay ?? false);

	const nextStartHour = moment().local().add(1, 'hours').startOf('hour');
	const nextEndHour = moment().local().add(2, 'hours').startOf('hour');

	const [dateStart, setDateStart] = React.useState<moment.Moment>((((defaultValues?.start) != null) ? moment(defaultValues.start) : (event != null) ? moment(event.start) : nextStartHour));

	const [dateEnd, setDateEnd] = React.useState<moment.Moment>((((defaultValues?.end) != null) ? moment(defaultValues.end) : (event != null) ? moment(event.end) : nextEndHour));

	const setAtomCompanyEdition = useSetRecoilState(ACompanyEdition);
	const [, setRefresh] = useRecoilState(ARefresh);
	
	const [localDescriptionTimeout, setLocalDescriptionTimeout] = React.useState<NodeJS.Timeout>();
	const [localTitleTimeout, setLocalTitleTimeout] = React.useState<NodeJS.Timeout>();

	const [isDateOpen, setIsDateOpen] = React.useState<[boolean, boolean]>([false, false]);

	const [isOpenDeletion, setOpenDeletion] = React.useState<boolean>(false);

	const [companyOpen, setCompanyOpen] = React.useState<number>();
	const [contactOpen, setContactOpen] = React.useState<ModalState<number>>({ isOpen: false });
	const { isAllowedTo } = React.useContext(PermissionContext);
	const [formTemplates, setFormTemplates] = React.useState<FormTemplate[]>([]);
	const [form, setForm] = React.useState<FormTemplate>();


	React.useEffect(() => {
		setForm(formTemplates.find(ft => ft.id == event?.form || ft.id == defaultValues?.form));
		setTitle(event?.title ?? defaultValues?.title ?? '');
		setOwner(owners.find(o => (event != null) ? event.incharge == o.id : o.isYou));
		setEventType(eventTypes.find(et => et.id == event?.eventTypeId || et.id == defaultValues?.eventTypeId));
		setEventStatus(eventStatuses.find(es => event?.eventStatusId ? es.id == event.eventStatusId : defaultValues?.eventStatusId ? es.id == defaultValues.eventStatusId : true));
		const companyId = props.clientCompanyId ?? event?.clientCompanyId ?? defaultValues?.clientCompanyId;
		if (companyId) {
			getCompanyDetail(companyId).then(response => {
				setCompany({
					id: response?.company_id,
					name: response?.company_name
				} as ClientCompanyType);
			});
			updateContacts(companyId, event?.contactId ?? defaultValues?.contactId);
		}
		setDescription(event?.description ?? defaultValues?.description ?? '');
		setAllDay(event?.allDay ?? defaultValues?.allDay ?? false);
		setDateStart(((defaultValues?.start) != null) ? moment(defaultValues.start) : ((event != null) ? moment(event.start) : nextStartHour));
		setDateEnd(((defaultValues?.end) != null) ? moment(defaultValues.end) : ((event != null) ? moment(event.end) : nextEndHour));
	}, [JSON.stringify(event), eventTypes, eventStatuses, formTemplates]);

	React.useEffect(() => {
		let count = 3;
		setLoading(LoadingStateEnum.LOADING);
		// setOwner();

		getEventTypes().then(response => {
			setEventTypes(response);
			setEventType(response.find(r => defaultValues?.eventTypeId ? r.id == defaultValues.eventTypeId : (event != null) ? r.id == event.eventTypeId : false));
			count = count - 1;
			if (count <= 0) {
				setLoading(LoadingStateEnum.LOADED);
			}
		});

		getEventStatuses().then(response => {
			setEventStatuses(response);
			setEventStatus(response.find(r => defaultValues?.eventStatusId ? r.id == defaultValues.eventStatusId : (event != null) ? r.id == event.eventStatusId : true));
			count = count - 1;
			if (count <= 0) {
				setLoading(LoadingStateEnum.LOADED);
			}
		});

		const companyId = props.clientCompanyId ?? event?.clientCompanyId ?? defaultValues?.clientCompanyId;
		if (companyId) {
			getCompanyDetail(companyId).then(response => {
				setCompany({
					id: response?.company_id,
					name: response?.company_name
				} as ClientCompanyType);
			});
			updateContacts(companyId, defaultValues?.contactId ?? event?.contactId);
		}
		getFormTemplates().then(response => {
			setFormTemplates(response);
			setForm(response.find(r => defaultValues?.form ? r.id == defaultValues.form : (event != null) ? r.id == event.form : false));
			count = count - 1;
			if (count <= 0) {
				setLoading(LoadingStateEnum.LOADED);
			}
		});
	}, []);

	const datesFormater = () => ({
		startDate: moment(`${dateStart.format('YYYY-MM-DD')} ${isAllDay ? '00:00:00' : dateStart.format('HH:mm:ss')}`).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate: moment(`${dateEnd.format('YYYY-MM-DD')} ${isAllDay ? '23:59:00' : dateEnd.format('HH:mm:ss')}`).utc().format('YYYY-MM-DD HH:mm:ss')
	});

	function submit(): void {
		if (owner !== undefined &&
			eventType !== undefined &&
			eventStatus !== undefined &&
			dateStart &&
			dateEnd
		) {
			const { startDate, endDate } = datesFormater();

			const nTitle = title.trim().length > 0 ? title.trim() : `${translate(`company.detail.timeline.${eventType.name.toLowerCase()}`).toString()} ${(company != null) ? `--- ${company.name}` : ''}`;

			const params: EventParams = {
				title: nTitle,
				event_type: eventType.id,
				event_status: eventStatus.id,
				start_date: startDate,
				end_date: endDate,
				client_company_id: props.clientCompanyId ?? company?.id,
				contact_id: contact?.contact_id,
				in_charge: owner.id,
				description: description.trim().length > 0 ? description.trim() : undefined,
				form: form?.id
			};

			setLoading(LoadingStateEnum.LOADING);

			postEvent(params).then(response => {
				if (response !== -1) {
					const newEvent: CompanyEvent = {
						client_company_id: props.clientCompanyId ?? company?.id ?? -1,
						company_id: -1, // TODO récup l'info quelque part
						event_count: 1,
						event_description: description.trim(),
						event_id: response,
						event_start_date: startDate,
						event_end_date: endDate,
						event_status_color: eventStatus.color_code,
						event_status_id: eventStatus.id,
						event_status_name: eventStatus.name,
						event_title: nTitle,
						event_type_id: eventType.id,
						event_type_name: eventType.name,
						incharge_email: owner.email,
						incharge_id: owner.id,
						incharge_name: owner.name,
						incharge_photo: owner.photoUrl,
						form: form?.id
					};

					if (newEvent.client_company_id) {
						setAtomCompanyEdition({ client_company_id: newEvent.client_company_id, event: newEvent as any });
					}

					(props.onCreate != null) && props.onCreate(newEvent);
					setLoading(LoadingStateEnum.LOADED);
					setRefresh({ timeline: {} });
				}
			});
		}
	}

	function updateContacts(clientCompanyId: number, contactId?: number): void {
		getCompanyContacts({
			client_company_id: clientCompanyId,
			limit: 200
		}).then(response => {
			setContacts(response.contacts);
			if (contactId) {
				setContact(response.contacts.find(c => c.contact_id == contactId));
			}
		});
	}

	function body(): JSX.Element {
		return (
			<CreationPopupContainer>

				{!hide?.includes('inCharge') && <>
					<DropdownOwners
						disabled={props.readonly}
						readOnly
						dropdownStyle={creationPopupDropdownStyle}
						users={owners.map(o => { return { label: o.name, value: o, image: o.photoUrl }; })}
						selected={(owner != null) ? { label: owner.name, value: owner, image: owner.photoUrl } : undefined}
						border
						required={!edition}
						label={translate('company.creation.contact.owner').toString()}
						onChange={value => {
							setOwner(value.value ?? undefined);
							onValueChange?.([['incharge', value?.value?.id, undefined]]);
						}}
					/>

					<Spacer />
				</>}
				{!hide?.includes('type') && <>
					<DropdownStatus<EventType>
						disabled={props.readonly}
						placeholder={translateToString('select_type')}
						dropdownStyle={creationPopupDropdownStyle}
						datalist={eventTypes.map(o => ({ label: translate(`event.${o.name}`) as string, value: o, color: colorTypeMapping.find(sm => sm.id == o.id)?.color }))}
						name='dropdown_type'
						required={!edition}
						readOnly
						label={translate('company.creation.event.type').toString()}
						onChange={(value: DropdownData<EventType>) => {
							setEventType(value.value);
							onValueChange?.([['type_id', value?.value.id, value.value?.name]]);
						}}
						selectedValue={(eventType != null) ? { label: translate(`event.${eventType.name}`) as string, value: eventType, color: colorTypeMapping.find(sm => sm.id == eventType.id)?.color } : undefined}
					/>
					<Spacer />
				</>}

				<Restricted to={{ objectAction: 'ReadForm' }}>
					<Dropdown<FormTemplate>
						disabled={props.readonly}
						placeholder={translateToString('select_template')}
						dropdownStyle={creationPopupDropdownStyle}
						datalist={formTemplates.map(o => ({ label: o.name, value: o }))}
						name='dropdown_forms'
						readOnly
						cancellable
						label={translate('form').toString()}
						onChange={value => {
							setForm(value?.value);
							onValueChange?.([['form', value?.value?.id ? value.value.id : 0, value?.value?.name]]);
						}}
						selectedValue={form ? { label: form.name, value: form } : undefined}
					/>
					<Spacer />
				</Restricted>

				{!hide?.includes('status') && <>
					<DropdownStatus<EventStatus>
						disabled={props.readonly}
						dropdownStyle={creationPopupDropdownStyle}
						datalist={eventStatuses.map((e, i) => { return { label: translate(`event.${e.name}`) as string, value: e, selected: i === 0, color: colorStatusMapping.find(sm => sm.id == e.id)?.color }; })}
						name='dropdown_status'
						required={!edition}
						readOnly
						label={translate('status').toString()}
						onChange={(value: DropdownData<EventStatus>) => {
							setEventStatus(value.value);
							onValueChange?.([['status_id', value?.value.id, value.value?.name]]);
						}}
						selectedValue={(eventStatus != null) ? { label: translate(`event.${eventStatus.name}`) as string, value: eventStatus, color: colorStatusMapping.find(sm => sm.id == eventStatus.id)?.color } : undefined}
					/>

					<Spacer />
				</>}

				{!hide?.includes('title') && <>
					<Input
						disabled={props.readonly}
						inputStyle={creationPopupInputStyle}
						name="title"
						type="text"
						placeholder={translate('company.creation.event.title').toString()}
						label={translate('company.creation.event.title').toString()}
						normalize={[normalizeCapital]}
						onChange={(value) => {
							setTitle(value);
							localTitleTimeout && clearTimeout(localTitleTimeout);
							setLocalTitleTimeout(setTimeout((value: string) => value ? onValueChange?.([['title', value, undefined]]) : {}, 500, value));
						}}
						value={title}
					/>

					<Spacer />
				</>}

				{!hide?.includes('allDay') && <>
					<HorizontalContainer>
						<SwitchText>{translate('company.creation.event.all_day').toString()}</SwitchText>
						<Switch readonly={props.readonly} value={isAllDay} onChange={(value) => {
							setAllDay(value);
							if (value) {
								const newStart = moment(`${dateStart.format('YYYY-MM-DD')} 00:00:00`);
								const newEnd = moment(`${dateStart.format('YYYY-MM-DD')} 23:59:00`);
								setDateStart(moment(newStart));
								setDateEnd(moment(newEnd));
								const start = newStart.utc().format('YYYY-MM-DD HH:mm:ss');
								const end = newEnd.utc().format('YYYY-MM-DD HH:mm:ss');
								onValueChange?.([['start', start, undefined], ['end', end, undefined], ['allDay', value, undefined]]);
							} else {
								setDateStart(moment(nextStartHour));
								setDateEnd(moment(nextEndHour));
								const start = nextStartHour.utc().format('YYYY-MM-DD HH:mm:ss');
								const end = nextEndHour.utc().format('YYYY-MM-DD HH:mm:ss');
								onValueChange?.([['start', start, undefined], ['end', end, undefined], ['allDay', value, undefined]]);
							}
						}} />
					</HorizontalContainer>

					<Spacer />
				</>}

				{!hide?.includes('startDate') && <>
					<HorizontalContainer>
						{isDateOpen[0] && <DayPickerModal
							transform='translateX(-110%)'
							future
							onClickOut={() => setIsDateOpen([false, isDateOpen[1]])}
							noDayButton
							time={!isAllDay}
							date={dateStart}
							onDateChange={date => {
								// setIsDateOpen([false, isDateOpen[1]]);
								setDateStart(date);
								const newEnd = moment(date).add(dateEnd.diff(dateStart));
								setDateEnd(newEnd);
								const start = moment(`${date.format('YYYY-MM-DD')} ${isAllDay ? '00:00' : date.format('HH:mm:ss')}`).utc().format('YYYY-MM-DD HH:mm:ss');
								const end = moment(`${newEnd.format('YYYY-MM-DD')} ${isAllDay ? '23:59:00' : newEnd.format('HH:mm:ss')}`).utc().format('YYYY-MM-DD HH:mm:ss');
								onValueChange?.([['start', start, undefined], ['end', end, undefined]]);
							}}
						/>}
						<Input
							disableChange
							inputStyle={{ ...creationPopupInputStyle, marginRight: isAllDay ? '0' : '10px', cursor: 'pointer' }}
							name="date_start"
							required={!edition}
							type="text"
							disabled={props.readonly}
							label={translate('company.creation.event.start').toString()}
							value={moment(dateStart).format('L')}
							onClick={() => setIsDateOpen([!isDateOpen[0], isDateOpen[1]])}
						/>
						{
							!isAllDay &&
							<Input
								disableChange
								disabled={props.readonly}
								inputStyle={{ ...creationPopupInputStyle, cursor: 'pointer' }}
								name="time_start"
								type="text"
								paddingLabel
								value={dateStart.format('HH:mm')}
								onClick={() => setIsDateOpen([!isDateOpen[0], isDateOpen[1]])}
							/>
						}
					</HorizontalContainer>

					<Spacer />
				</>}
				{!hide?.includes('endDate') && <>
					<HorizontalContainer>
						{isDateOpen[1] && !isAllDay && <DayPickerModal
							onClickOut={() => setIsDateOpen([isDateOpen[0], false])}
							transform='translateX(-110%)'
							future
							time={!isAllDay}
							noDayButton
							date={moment(dateEnd)}
							onDateChange={date => {
								setDateEnd(date);
								const end = moment(`${date.format('YYYY-MM-DD')} ${isAllDay ? '23:59:00' : date.format('HH:mm:ss')}`).utc().format('YYYY-MM-DD HH:mm:ss');
								if (date.isSameOrBefore(dateStart)) {
									const newStart = moment(date).subtract(dateEnd.diff(dateStart));
									setDateStart(newStart);
									const start = moment(`${newStart.format('YYYY-MM-DD')} ${isAllDay ? '00:00' : newStart.format('HH:mm:ss')}`).utc().format('YYYY-MM-DD HH:mm:ss');
									onValueChange?.([['start', start, undefined], ['end', end, undefined]]);
								} else onValueChange?.([['end', end, undefined]]);
							}}
						/>}
						<Input
							disableChange
							inputStyle={{ ...creationPopupInputStyle, marginRight: isAllDay ? '0' : '10px', cursor: 'pointer' }}
							name="date_end"
							required={!edition}
							type="text"
							label={translate('company.creation.event.end').toString()}
							disabled={isAllDay || props.readonly}
							value={moment(dateEnd).format('L')}
							onClick={() => setIsDateOpen([isDateOpen[0], !isDateOpen[1]])}
						/>
						{
							!isAllDay &&
							<Input
								disabled={props.readonly}
								disableChange
								inputStyle={{ ...creationPopupInputStyle, cursor: 'pointer' }}
								name="time_end"
								type="text"
								paddingLabel
								value={dateEnd.format('HH:mm')}
								onClick={() => setIsDateOpen([isDateOpen[0], !isDateOpen[1]])}
							/>
						}
					</HorizontalContainer>

					<Spacer />
				</>}

				{
					!props.clientCompanyId && !hide?.includes('companies') &&
					<>
						<Spacer />
						<DropdownSearch
							disabled={props.readonly}
							dropdownStyle={{
								...creationPopupDropdownStyle,
								fontWeight: !company ? 400 : 600,
								fontColor: DarkGreySidely,
								optionFontColor: DarkGreySidely2,
								margin: '37px 0 0 0'
							}}
							datalist={optionCompanies}
							name="clientCompanyName"
							label={translate('company.creation.event.company').toString()}
							readOnlyDelete={!!company}
							labelUp
							onClick={_ => company && setCompanyOpen(company.id)}
							onChange={(value) => {
								setCompany(value?.value);
								onValueChange?.([['company_id', value?.value?.id ? { updated: value.value.id } : 'deleted', value?.value?.name], ['contact_id', 'deleted', undefined]]);
								if (value != null) {
									updateContacts((value.value as ClientCompanyType).id);
								} else {
									setContact(undefined);
								}
							}}
							selectedValue={(company != null) ? { label: company?.name || '', value: company } : null}
							onSearchChange={async(search, offset) => {
								return await getFilteredCompanies(search, 20, offset)
									.then(res => {
										const ret: boolean = res.data.length == 0 || res.data.length < 20;
										if (offset !== undefined) {
											setOptionCompanies([...optionCompanies, ...res.data.map((company: ClientCompanyType) => {
												return {
													label: company.name,
													value: company
												};
											})]);
										} else {
											setOptionCompanies(res.data.map(company => {
												return {
													label: company.name,
													value: company
												};
											}));
										}
										return ret;
									})
									.catch(error => {
										console.error(error);
										return false;
									});
							}}
						/>
					</>
				}

				{
					company != undefined && !hide?.includes('contacts') &&
					<>
						<Spacer />
						<Dropdown<CompanyContact>
							disabled={props.readonly}
							dropdownStyle={{
								...creationPopupDropdownStyle,
								fontWeight: 400,
								fontColor: DarkGreySidely,
								optionFontColor: DarkGreySidely2
							}}
							datalist={contacts.map(c => ({
								label: `${c.contact_last_name} ${c.contact_first_name}`,
								value: c,
								image: c.contact_photo
							}))}
							onClick={e => {
								if (!contact) return;
								e.stopPropagation();
								setContactOpen({ isOpen: true, data: contact.contact_id });
							}}
							name='dropdown_contact'
							readOnly
							label={translate('company.creation.event.contact').toString()}
							cancellable
							onChange={(value: DropdownData<CompanyContact>) => {
								setContact(value.value);
								onValueChange?.([['contact_id', value?.value.contact_id ? { updated: value.value.contact_id } : 'deleted', value ? `${value.value.contact_first_name} ${value.value.contact_last_name}` : undefined]]);
							}}
							selectedValue={(contact != null) ? { label: `${contact.contact_last_name} ${contact.contact_first_name}`, value: contact, image: contact.contact_photo } : undefined}
						/>
					</>
				}

				<Spacer />

				{!hide?.includes('description') && <>
					<TextAreaContainer>
						<TextAreaLabel>{translate('company.creation.event.description').toString()}</TextAreaLabel>
						<TextArea 
							disabled={props.readonly}
							onChange={(e) => {
								setDescription(e.currentTarget.value);
								localDescriptionTimeout && clearTimeout(localDescriptionTimeout);
								setLocalDescriptionTimeout(setTimeout((value: string) => onValueChange?.([['description', value ? { updated: value } : 'deleted', undefined]]), 500, e.currentTarget.value));
							}} value={description}></TextArea>
					</TextAreaContainer>
				</>}

				<PopupDeletion 
					isOpen={isOpenDeletion} 
					records={0} 
					translationKey=''
					customTitle={translate('company.detail.linked_elements.event').toString()}
					customMessage={translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${event?.title}'`)} 
					onClickOut={() => setOpenDeletion(false)}
					onValidation={() => edition && event ? deleteEvent(event.id).then(() => {
						setRefresh({ timeline: {} });
						props.onDelete?.();
					}) : undefined}
					noVerification
				/>

			</CreationPopupContainer>
		);
	}

	const createdBy = owners.find(o => o.id == event?.created_by)?.name;
	const dropdownData: DropdownData<string>[] = [];
	if (isAllowedTo({ objectAction: 'DeleteEvent' })) {
		dropdownData.push({ label: translateToString('delete'), value: 'DELETE' });
	}
	if (isAllowedTo({ objectAction: 'CreateEvent' })) {
		dropdownData.push({ label: translateToString('duplicate'), value: 'DUPLICATE' });
	}
	return <>
		<PopupCreation
			title={edition && (event != null) && props.eventMode !== EventMode.Creation ? title : translate('company.creation.event.create_event').toString()}
			dropdown={event?.id && props.eventMode !== EventMode.Creation && dropdownData.length > 0 ? {
				data: dropdownData,
				onChange: (data) => {
					switch (data.value) {
						case 'DELETE':
							setOpenDeletion(true);
							return;
						case 'DUPLICATE':
							props.onDuplicate?.();
							return;
					}
				}
			} : undefined}
			titleComment={event && createdBy && event.created_at && props.eventMode !== EventMode.Creation ? translate('by_on').toString().replace('{{NAME}}', createdBy).replace('{{DATE}}', moment.utc(event.created_at).local().format('LLL')) : undefined}
			content={body()}
			canValidate={owner !== undefined &&
				eventType !== undefined &&
				eventStatus !== undefined &&
				dateStart !== undefined &&
				dateEnd !== undefined}
			onSubmit={() => (props.onSubmit ?? submit)()}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
			hideValidation={props.hideValidation}
			messageButton={edition ? translate('save').toString() : undefined} />
		<ClientCompany
			clientCompanyId={companyOpen ?? 0}
			isOpen={!!companyOpen}
			setOpen={() => setCompanyOpen(undefined)}
		/>
		<ContactPopup isOpen={contactOpen?.isOpen} setOpen={isOpen => setContactOpen({ isOpen })} contactId={contactOpen.data} />
	</>;
}

export default NewEvent;
