import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { localizeReducer } from 'react-localize-redux';

const langReducers = combineReducers({
	localize: localizeReducer
});

const store = createStore(
	langReducers,
	composeWithDevTools(applyMiddleware(thunk))
);

export default store;
