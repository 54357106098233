import * as React from 'react';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import TabPins, { PinId } from './TabPins';
import TabFilter from './TabFilter';
import { Owner } from '../../orders/model/Model';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import LeftImage from 'images/icon/left.png';
import LeftImageGrey from 'images/icon/left-grey.png';
import RightImageGrey from 'images/icon/right-grey.png';
import RightImage from 'images/icon/right.png';
import { FetchKey } from '../MapView';
import { CompanyStatus } from '../../client-companies/model/Model';

export function getLeftOrRightImage(isLeft: boolean, grey = false) {
	if (isLeft && grey) return LeftImageGrey;
	if (isLeft) return LeftImage;
	if (grey) return RightImageGrey;
	return RightImage;
}

export default function ModalLeftFilter(props: {
  all_postcodes: DropdownData<string | undefined>[]
  all_cities: DropdownData<string | undefined>[]
  all_countries: DropdownData<string | undefined>[]
  changeFilters: (key: string, value) => void
  updatePieFilter: (label: string, value, all_updated?: boolean) => void
  pieFilter: { [key: string]: { index: number, isActive: boolean, color: string, value: [number, number] }}
  users: Array<Owner>
  statuses: CompanyStatus[]
  fetchFor: (key: FetchKey) => void,
  tabType: FetchKey,
  setTabType: (v: PinId) => void
  range: { firstRange: number, lastRange: number},
  setRange: (data: { firstRange: number, lastRange: number}) => void
}) {
	const { tabType, setTabType, range, setRange } = props;
	const [isOpen, setIsOpen] = React.useState(true);
	const [activeTab, setActiveTab] = React.useState(1);

	return <div className="filter-modal" style={{ marginTop: '52px', height: 'calc(100% - 107px)', width: '275px', left: 3 }}>
		<div className={isOpen ? 'box-filter-modal' : 'd-none'}>
			<Nav tabs style={{ position: 'sticky', top: 0, zIndex: 1 }} >
				<NavItem>
					<NavLink
						className={activeTab === 1 ? 'active' : ''}
						onClick={() => setActiveTab(1)}
					>
						<Translate id="Markers" />
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={activeTab === 2 ? 'active' : ''}
						onClick={() => setActiveTab(2)}
					>
						<Translate id="map_datas" />
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab} style={{ height: 'calc(100vh - 130px)' }}>
				<TabPins
					handleTabRange={setTabType}
					handleRange={setTabType}
					tabType={tabType}
					range={range}
					setRange={setRange}
					status={props.statuses}
					owners={props.users}
				/>
				<TabFilter
					all_postcodes={props.all_postcodes}
					all_cities={props.all_cities}
					all_countries={props.all_countries}
					changeFilters={props.changeFilters}
					status={props.statuses}
					updatePieFilter={props.updatePieFilter}
					pieFilter={props.pieFilter}
					fetchFor={props.fetchFor}
				/>
			</TabContent>
		</div>
		<button
			type="button"
			className="btn btn-filter-modal"
			onClick={() => setIsOpen(!isOpen)}
		>
			<img
				src={getLeftOrRightImage(isOpen)}
				alt=""
			/>
		</button>
	</div>;
}
