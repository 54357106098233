import { useContext } from 'react';
import PermissionContext from './PermissionContext';
import { To } from '../../Types';
import { IsAllowedToProps } from './PermissionProvider';

const usePermission = (to: To | To[], opt?: IsAllowedToProps) => {
	const { isAllowedTo } = useContext(PermissionContext);

	return isAllowedTo(to, opt);
};

export default usePermission;
