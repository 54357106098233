import userGroup from 'images/icons/userGroup.svg';
/* eslint-disable no-return-assign */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import moment from 'moment';
import { get } from 'lodash';
import * as action from '../../../store/actions/mapOpportunities.action';
import FilterTag from '../../../components/select/FilterTag';
import Search from '../../companies/components/CompaniesSearch';
import calendarImage from 'images/icon/small_calendar2.svg';

class OpportunityFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			deadline: <Translate id="all" />,
			owner: <Translate id="all" />
		};
		this.toggle = this.toggle.bind(this);
	}

	componentDidMount() {
		const { filterState, current } = this.props;
		const paramsData = JSON.parse(localStorage.getItem('params'));
		if (filterState) {
			this.setState({
				deadline: this.getPeriod(filterState.periode),
				owner: this.getOwner(filterState.incharge, filterState.groupId)
			});
		}
		if (paramsData.periode !== undefined) {
			this.setState({ deadline: this.getPeriod(paramsData.periode) });
		}
	}

	componentWillUpdate(nextProps, nextState) {
		const { selectedFilterOwner } = nextProps;
		if (nextState.owner == 'Specific User') {
			if (selectedFilterOwner != undefined) {
				if (selectedFilterOwner.values != undefined) {
					this.setState({ owner: selectedFilterOwner.values.ownerId.label });
				}
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { current, type, onFilterByOwner, selectedFilterOwner } = this.props;
		if (selectedFilterOwner !== nextProps.selectedFilterOwner) {
			const test = get(nextProps.selectedFilterOwner, 'values');
			const test2 = get(test, 'ownerId.label');
			this.setState({
				owner: test2
			});
		}

		if (nextProps.data.defaultFilter === true) {
			if (current !== nextProps.current && selectedFilterOwner == undefined) {
				if (nextProps.current.role.slug == 'sales') {
					this.setState({ owner: <Translate id="me" /> });
				}

				if (nextProps.current.role.slug == 'manager') {
					this.setState({ owner: <Translate id="specific_group" /> });
				}
				const paramsData = JSON.parse(localStorage.getItem('params'));
				if (paramsData.incharge === nextProps.current.id) {
					this.setState({ owner: <Translate id="me" /> });
				} else {
					this.setState({ owner: <Translate id="specific_user" /> });
				}

				if (paramsData.groupId !== null) {
					this.setState({ owner: <Translate id="specific_group" /> });
				}
			}

			if (type != nextProps.type) {
				if (nextProps.type == 'mixt') {
					if (nextProps.current.role.slug == 'sales') {
						if (nextProps.current.group.length > 1) {
							const currentUser = {
								incharge: nextProps.current.id,
								groupId: nextProps.current.group[1].id
							};

							onFilterByOwner(nextProps.type, currentUser);
							this.props.setFilterIncharge('Me');

							this.setState({ owner: <Translate id="me" /> });
						}
					}
				}

				if (nextProps.type == 'list') {
					if (
						nextProps.current.role.slug === 'manager' ||
            nextProps.current.role.slug == 'sales'
					) {
						if (nextProps.current.group.length > 1) {
							const currentUser = {
								incharge: null,
								groupId: nextProps.current.group[1].id
							};

							onFilterByOwner(nextProps.type, currentUser);
							this.props.setFilterIncharge('Specific Group');

							this.setState({ owner: <Translate id="specific_group" /> });
						}
					}
				}
			}
		}
	}

	getPeriod = periode => {
		let deadline;
		switch (periode) {
			case 'isoWeek':
				deadline = 'This week';
				break;
			case 'month':
				deadline = 'This month';
				break;
			case 'quarter':
				deadline = 'This quarter';
				break;
			case 'year':
				deadline = 'This year';
				break;
			case 'range':
				deadline = 'Date Range';
				break;

			default:
				break;
		}
		return deadline;
	};

	getDefaultOwner = () => {
		const { statusKanban, current } = this.props;
	};

	getOwner = (user, group) => {
		const { current } = this.props;
		let owner;
		if (user) {
			if (current.id === user) {
				owner = 'Me';
			} else {
				owner = 'Specific user';
			}
		} else if (group) {
			owner = 'Specific group';
		} else {
			owner = 'All';
		}
		return owner;
	};

	toggle(e) {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	render() {
		const { deadline, owner } = this.state;
		const paramsData = JSON.parse(localStorage.getItem('params'));
		const {
			current,
			onToggleFilterDate,
			onToggleFilterOwner,
			onToggleFilterGroup,
			onFilterByDeadline,
			onFilterByOwner,
			type,
			typeList,
			handleFilterTag
		} = this.props;

		const thisWeek = {
			gteDate: moment()
				.startOf('isoWeek')
			// .isoWeekday(1)
				.format('YYYY-MM-DDT00:00:00.000[Z]'),
			lteDate: moment()
				.endOf('isoWeek')
			// .isoWeekday(1)
				.format('YYYY-MM-DDT23:59:59.000[Z]')
		};
		const thisMonth = {
			gteDate: moment()
				.startOf('month')
				.format('YYYY-MM-DDT00:00:00.000[Z]'),
			lteDate: moment()
				.endOf('month')
				.format('YYYY-MM-DDT23:59:00.000[Z]')
		};
		const thisQuarter = {
			gteDate: moment()
				.startOf('quarter')
				.format('YYYY-MM-DDT00:00:00.000[Z]'),
			lteDate: moment()
				.endOf('quarter')
				.format('YYYY-MM-DDT23:59:59.000[Z]')
		};
		const thisYear = {
			gteDate: moment()
				.startOf('year')
				.format('YYYY-MM-DDT00:00:00.000[Z]'),
			lteDate: moment()
				.endOf('year')
				.format('YYYY-MM-DDT23:59:59.000[Z]')
		};

		const currentUser = {
			incharge: current && current.id,
			groupId: null
		};

		return (
			(<Fragment>
				<UncontrolledButtonDropdown
					direction="down"
					className="form-filter-opportunity mx-2 d-flex align-items-center"
				>
					<img src={calendarImage} height={20} alt="" />
					<DropdownToggle className="toggler py-0 px-2" caret>
						{deadline}
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem
							onClick={() => {
								onFilterByDeadline(type, null);
								this.props.setFilterDeadline('All');
								this.setState({
									deadline: <Translate id="all" />
								});
							}}
						>
							<Translate id="all" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilterByDeadline(type, thisWeek);
								localStorage.setItem(
									'params',
									JSON.stringify({
										...paramsData,
										periode: 'isoWeek'
									})
								);
								this.props.setFilterDeadline('This week');
								this.setState({
									deadline: <Translate id="this_week" />
								});
							}}
						>
							<Translate id="this_week" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilterByDeadline(type, thisMonth);
								localStorage.setItem(
									'params',
									JSON.stringify({
										...paramsData,
										periode: 'month'
									})
								);
								this.props.setFilterDeadline('This month');
								this.setState({
									deadline: <Translate id="this_month" />
								});
							}}
						>
							<Translate id="this_month" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilterByDeadline(type, thisQuarter);
								this.props.setFilterDeadline('This quarter');
								localStorage.setItem(
									'params',
									JSON.stringify({
										...paramsData,
										periode: 'quarter'
									})
								);
								this.setState({
									deadline: <Translate id="this_quarter" />
								});
							}}
						>
							<Translate id="this_quarter" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onFilterByDeadline(type, thisYear);
								this.props.setFilterDeadline('This year');
								localStorage.setItem(
									'params',
									JSON.stringify({
										...paramsData,
										periode: 'year'
									})
								);
								this.setState({
									deadline: <Translate id="this_year" />
								});
							}}
						>
							<Translate id="this_year" />
						</DropdownItem>
						<DropdownItem
							onClick={() => {
								onToggleFilterDate();
								this.setState({
									deadline: <Translate id="date_range" />
								});
							}}
						>
							<Translate id="date_range" />
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
				{current && current.role && (
					<Fragment>
						{type == 'mixt' && current.role.slug === 'sales' ? null : (
							<UncontrolledButtonDropdown
								direction="down"
								className="form-filter-opportunity mx-2"
							>
								<img
									src={userGroup}
									alt=""
									width="20px"
								/>
								<DropdownToggle className="toggler py-0 px-2" caret>
									{owner}
								</DropdownToggle>
								<DropdownMenu>
									{type == 'mixt' && current.role.slug == 'manager' ? null : (
										<DropdownItem
											onClick={() => {
												onFilterByOwner(type, null);
												this.props.setFilterIncharge('');
												this.setState({
													owner: <Translate id="all" />
												});
											}}
										>
											<Translate id="all" />
										</DropdownItem>
									)}

									<DropdownItem
										onClick={() => {
											onFilterByOwner(type, currentUser);
											this.props.setFilterIncharge('Me');
											this.setState({
												owner: <Translate id="me" />
											});
										}}
									>
										<Translate id="me" />
									</DropdownItem>
									{current && current.role && current.role.slug !== 'sales' && (
										<Fragment>
											<DropdownItem
												onClick={() => {
													onToggleFilterOwner();
													this.setState({
														selected: <Translate id="specific_user" />
													});
												}}
											>
												<Translate id="specific_user" />
											</DropdownItem>
											{/*
                      <DropdownItem
                        onClick={() => {
                          onToggleFilterGroup();
                          this.setState({
                            owner: <Translate id="specific_group" />
                          });
                        }}
                      >
                        <Translate id="specific_group" />
                      </DropdownItem>
                      */}
										</Fragment>
									)}
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						)}
					</Fragment>
				)}
				<div className="d-flex w-100">
					<div className="col-7 pl-0">
						<FilterTag
							handleFilterTag={handleFilterTag}
							type={3}
							viewType="map"
							typeList="listOpportunities"
							widthTag={35}
						/>
					</div>
					<div className="col-5 pl-0">
						<Search
							searchData={this.props.searchData}
							viewType="map"
							placeholder="search_opportunity"
						/>
					</div>
				</div>
			</Fragment>)
		);
	}
}

const mapStateToProps = state => ({
	data: state.opportunities,
	selectedFilterOwner: state.form.modalFilterOwner
});

const mapDispatchToProps = {
	setFilterGroup: action.setFilterMapOpportunityGroup,
	setFilterIncharge: action.setFilterMapOpportunityIncharge,
	setFilterDeadline: action.setFilterMapOpportunityDeadline
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityFilter);
