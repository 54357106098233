import React, {Component} from 'react';
import {Collapse} from 'react-bootstrap';

class CollapseWrapper extends Component {
  state = {
    open: true
  };

  render() {
    const { title, children, className } = this.props;
    const { open } = this.state;
    return (
      <div className={`collapse-wrapper ${className}`}>
        <div className="collapse-header">
          <button
            onClick={() => {
              this.setState({ open: !open });
            }}
            type="button"
            aria-controls="collapse-area"
            className={`btn btn-link ${open ? 'active' : ''}`}
            aria-expanded={open}
          >
            <h6>{title}</h6>
          </button>
        </div>
        <Collapse in={open}>
          <div className="collapse-body">{children}</div>
        </Collapse>
      </div>
    );
  }
}

export default CollapseWrapper;
