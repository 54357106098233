import add_blue from 'images/icon/add_blue.png';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useStore } from 'react-redux';
import { Redirect } from 'react-router';
import swal from 'sweetalert2';
import OrderCreation from '../../../components_v2/creation/OrderCreation';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import InputSearch from '../../../components_v2/input/InputSearch';
import PopupExport from '../../../components_v2/popup/PopupExport';
import storeLang from '../../../helpers/storeLang';
import { LightBlueSidely } from '../../../styles/global/css/Utils';
import { ToolbarBox, ToolbarImage } from '../../globals/defaultToolbar/style/Style';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import Restricted from '../../permissions/Restricted';
import usePermission from '../../permissions/usePermission';
import { deleteOrders, exportOrders } from '../data/Data';
import { cleanOrderCreator, getOrderCreator } from '../global/globalFunction';
import { ExportType, Order, TypeFile } from '../model/Model';
import { Container } from './style/Style';
import TemplateOrdersKanban from './subPage/TemplateOrdersKanban';
import TemplateOrdersList from './subPage/TemplateOrdersList';
import optionGrey from 'images/icon/options_grey.png';
import ListViewGrey from 'images/ui_icon/listView_grey.svg';
import ListViewBlack from 'images/ui_icon/listView_black.svg';
import KanbanViewImageGrey from 'images/ui_icon/kanbanView_grey.svg';
import KanbanViewImageBlack from 'images/ui_icon/kanbanView_black.svg';

function warningOrder(setRedirect: Function, setUpdated: Function): void {
	const translate = getTranslate(storeLang.getState().localize);

	swal({
		text: translate('orders.fire.warningExistOrder').toString(),
		type: 'warning',
		confirmButtonText: translate('orders.fire.warningContinue').toString(),
		confirmButtonColor: '#49BAFF',
		cancelButtonText: translate('orders.fire.warningClear').toString(),
		cancelButtonColor: '#D33',
		showCancelButton: true
	})
		.then(result => {
			if (result.value) {
				setRedirect(<Redirect push to='/orders/select-products' />);
			}

			if (result.dismiss && result.dismiss === swal.DismissReason.cancel) {
				cleanOrderCreator().then(() => {
					setUpdated(true);
				});
			}
		});
}

function selectionDelete(idOrders: number[], setUpdated: Function): void {
	const translate = getTranslate(storeLang.getState().localize);

	if (idOrders.length <= 0) {
		return;
	}

	swal({
		text: translate('global.fire.alert_title').toString(),
		type: 'warning',
		confirmButtonText: translate('global.fire.delete_message').toString().replace('{{NAME}}', `${idOrders.length} orders`),
		confirmButtonColor: '#D33'
	})
		.then(result => {
			if (result.value) {
				deleteOrders(idOrders).then(() => {
					setUpdated(true);
				});
			}
		});
}

function initToolbar(
	setToolBarState: (value: ToolbarState) => void,
	setOpen: Function,
	listMode: boolean,
	setListMode: Function,
	setRedirect: Function,
	setExportOpen: Function,
	setSearchValue: Function,
	selectedOrders: Order[],
	setUpdated: Function,
	user: any,
	deleteAllowed: boolean,
	exportAllowed: boolean
): void {
	const translate = getTranslate(storeLang.getState().localize);

	const data: DropdownData[] = [];
	if (deleteAllowed) { data.push({ label: 'Delete selection', value: 'delete' }); }

	if (exportAllowed) {
		data.push({ label: 'export', value: 'export' });
	}

	setToolBarState({
		title: translate('orders.title').toString(),
		bottomLeftToolbarComponent: <></>,
		bottomRightToolbarComponent: (
			<Restricted to={{ objectAction: 'ReadOrder' }}>
				<ToolbarBox>
					<InputSearch name={'search_bar'} type={'text'} placeholder={translate('search').toString()} onChange={(value) => setSearchValue(value)} delay={500} />
					<ToolbarImage width='24px' height='24px' marginRight='7px' hasPointer src={listMode ? ListViewBlack : ListViewGrey}
						onClick={() => {
							setListMode(true);
						}} />
					<ToolbarImage width='24px' height='24px' hasPointer src={!listMode ? KanbanViewImageBlack : KanbanViewImageGrey}
						onClick={() => {
							setListMode(false);
						}} />
					<Restricted to={{ objectAction: 'CreateOrder' }}>
						<ToolbarImage marginLeft='10px' width='25px' height='25px' hasPointer src={add_blue} backgroundColor={LightBlueSidely} round onClick={() => {
							const order = getOrderCreator();
							if (order.company || order.creationDate || order.name) {
								warningOrder(setRedirect, setOpen);
							} else {
								setOpen(true);
							}
						}} />
					</Restricted>

					<Restricted to={[
						{ objectAction: 'DeleteOrder' },
						{ objectAction: 'CreateExport' }
					]}>
						<Dropdown dropdownStyle={{ optionLeft: '-115px', optionWidth: '160px' }} datalist={data} name={'options'}
							JSXButton={() => <ToolbarImage marginLeft='10px' hasPointer src={optionGrey} />}
							onChange={(value: DropdownData) => {
								switch (value.value) {
									case 'export':
										setExportOpen(true);
										break;
									case 'delete':
										selectionDelete(selectedOrders.map(o => o.id), setUpdated);
										break;
									default:
										break;
								}
							}}
						/>
					</Restricted>

				</ToolbarBox>
			</Restricted>
		)
	});
}

function TemplateOrders(props: {
  setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [listMode, setListMode] = React.useState<boolean>(true);
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [isExportOpen, setExportOpen] = React.useState<boolean>(false);

	const [searchValue, setSearchValue] = React.useState<string>('');
	const [redirect, setRedirect] = React.useState<JSX.Element>(<></>);

	const [selectedOrders, setSelectedOrders] = React.useState<Order[]>([]);
	const [updated, setUpdated] = React.useState<boolean>(false);

	// permissions
	const deleteAllowed = usePermission({ objectAction: 'DeleteOrder' });
	const exportAllowed = usePermission({ objectAction: 'CreateExport' });

	const [clientCompanyId, setClientCompanyId] = React.useState<number>();

	const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
	const store = useStore().getState();
	const dispatch = useDispatch();

	if (!store.user || store.user.isLoading) {
		setTimeout(forceUpdate, 500);
	}

	React.useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const paramId = queryParams.get('create');
		if (paramId) {
			const companyId: number = parseInt(paramId);
			if (!isNaN(companyId)) {
				setClientCompanyId(companyId);
				setOpen(true);
			}
		}
	}, []);

	React.useEffect(() => {
		setUpdated(false);
	}, [updated]);

	React.useEffect(() => {
		initToolbar(props.setToolBarState, setOpen, listMode, setListMode, setRedirect, setExportOpen, setSearchValue, selectedOrders, setUpdated, store.user, deleteAllowed, exportAllowed);
	}, [listMode, selectedOrders, store.user]);

	return (
		<Restricted to={{ objectAction: 'ReadOrder' }}>
			<Container>
				{redirect}

				<OrderCreation clientCompanyId={clientCompanyId} isOpen={isOpen} setOpen={setOpen} />

				<PopupExport exportExcel exportCSV isOpen={isExportOpen} onClickOut={() => setExportOpen(false)} onClickExport={(value) => {
					setExportOpen(false);
					if (value == null) {
						return;
					}

					if (value.value === TypeFile.PDF && selectedOrders.length <= 0) {
						return;
					}

					exportOrders(value.value, 'Orders', value.value === TypeFile.PDF ? selectedOrders[0].id : undefined).then(response => {
						if (response.valueOf()) {
							dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
							// createLoadingMessage()
							// toast.success(translate('exports.ready_toast').toString(), {
							//   position: 'top-right',
							//   autoClose: 4000,
							//   hideProgressBar: true,
							//   closeOnClick: true,
							//   pauseOnHover: true,
							//   draggable: true,
							//   progress: undefined,
							// });
						}
					});
				}} />
				{
					listMode
						? <TemplateOrdersList searchValue={searchValue} onSelectedOrders={(values) => setSelectedOrders(values)} updated={updated} setRedirect={setRedirect} />
						: <TemplateOrdersKanban searchValue={searchValue} />
				}

			</Container>
		</Restricted>
	);
}

export default TemplateOrders;
