import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Dropdown from '../../dropdown/Dropdown';
import boxImage from 'images/menu_icon/fieldReport_black.svg';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { AFormTemplate } from '../../../atoms/forms';
import { AFormTemplatePicker } from '../../../atoms/filter/formPicker';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { translateToString } from '../../../styles/global/translate';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';

export default function FormTemplateTemplatePicker(props: GenericToolbarFilterProps): JSX.Element {
	const [atomTemplates, setAtomTemplates] = useRecoilState(AFormTemplatePicker);
	const templateList = useRecoilValue(AFormTemplate);
	const { filters, setFilters } = React.useContext(ReportFiltersContext);

	if (props.hidden) return <></>;
	let localTemplates;

	if (props.context) {
		localTemplates = templateList.map(value => ({ value, label: value.name, checked: (filters.form_filter as number[])?.some(id => id === value.id) ?? false }));
		
	} else {
		localTemplates = templateList.map(value => ({ value, label: value.name, checked: atomTemplates?.some(id => id === value.id) ?? false }));
	}

	if (!localTemplates) return <></>;

	

	function getText(): string {
		const amount = localTemplates.filter(l => l.checked).length;
		return amount > 0 ? (amount === localTemplates.length ? translateToString('all') : `${amount} ${translateToString('selected_template')}`) : translateToString('select_template');
	}

	return (
		<Dropdown
			dropdownStyle={{ ...DROPDOWN_FILTER_STYLE, width: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.width, containerWidth: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.containerWidth }}
			datalist={localTemplates}
			readOnly
			checkbox
			checkboxAll
			defaultShowLabel={getText()}
			freezeShowLabel
			name='dropdown_scope_filter'
			onChange={values => {
				if (props.context) {
					setFilters(filters => ({ ...filters, form_filter: values.map(({ value }) => value.id) }));
				} else {
					setAtomTemplates(values.map(({ value }) => value.id));
				}
			}}
			image={boxImage}
		/>
	);
}