/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {truncate} from '../../helpers/textConfig';
import {selectContact} from '../../store/actions/select.action';
import ModalCreateContact from '../modal/create/ModalCreateContact';

class SelectCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      placeholder: null,
      value: null,
      options: [],
      isModalOpen: false,
      isWarning: false
    };
  }

  componentDidMount() {
    const { placeholder, selectContact, id, initValue } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);

    if (id) {
      selectContact(id);
    }

    this.setState({
      placeholder: placeholder || 'placeholder',
      value: initValue || null
    });
  }

  componentWillReceiveProps(nextProps) {
    const { data, dataCreated, selectContact, id } = this.props;

    if (id !== nextProps.id) {
      if (nextProps.id) {
        this.setState({
          isWarning: false
        });
        selectContact(nextProps.id);
      } else {
        this.setState({
          options: []
        });
      }

      if (id) {
        this.setState({
          value: null
        });
      }
    }

    if (data !== nextProps.data) {
      const options = nextProps.data.map(data => ({
        value: data.id,
        label: `${data.firstName} ${data.lastName}`
      }));

      this.setState({ options });
    }

    if (dataCreated !== nextProps.dataCreated) {
      selectContact(id);
      const value = {
        value: nextProps.dataCreated.id,
        label: `${nextProps.dataCreated.firstName} ${
          nextProps.dataCreated.lastName
        }`
      };
      this.handleChange(value);
      this.onCloseModal();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  handleDropdown = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  handleChange = opt => {
    const { input } = this.props;
    this.setState({
      isOpen: false,
      value: opt.label || null
    });
    input.onChange(opt);
  };

  onOpenModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  onCloseModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  onShowWarning = () => {
    this.setState({
      isWarning: true
    });
  };

  render() {
    const {
      requiredStar,
      label,
      input,
      id,
      meta: { touched, error, warning }
    } = this.props;
    const { isOpen, placeholder, options, value } = this.state;

    // console.log('state', this.state.isModalOpen);
    return (
      <div className="form-group row">
        <div className="col-md-3 text-right mt-2">
          <label>
            {requiredStar === true ? (
              <span className="text-required">* </span>
            ) : (
              ''
            )}
            {label || 'Label'}
          </label>
        </div>

        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <button
                className={`${isOpen && 'active'} ${touched &&
                  error &&
                  'focus-error-select'} custom-select-with-add`}
                type="button"
                onClick={() => {
                  id ? this.handleDropdown() : this.onShowWarning();
                }}
              >
                {/* <p className={`${value ? 'text-value' : 'text-placeholder'}`}>
                  {value || placeholder}
                </p> */}
                <input
                  className={`custom-select-input ${!value &&
                    'form-control text-placeholder'}`}
                  type="text"
                  value={value || placeholder}
                  placeholder={placeholder}
                  onChange={value => {
                    this.handleInput(value);
                  }}
                />
                {value && (
                  <button
                    className="btn btn-transparent p-0"
                    style={{
                      position: 'absolute',
                      fontSize: '14px',
                      right: '56px',
                      top: '12px',
                      color: 'hsl(0,0%,80%)'
                    }}
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      input.onChange(null);
                      this.setState({
                        value: null
                      });
                    }}
                  >
                    <i className="fas fa-times" />
                  </button>
                )}
                <div className="custom-chevron-with-add">
                  <i className="fas fa-chevron-down" />
                </div>
              </button>
            </div>
            {isOpen && (
              <div
                className="col-md-12 custom-dropdown-box"
                ref={this.setWrapperRef}
              >
                <input {...input} type="hidden" />
                <div className="dummy-realative">
                  <div className="custom-options overflow-auto custom-scroll">
                    {options.length > 0 ? (
                      <ul>
                        {options.map(opt => (
                          <li onClick={this.handleChange.bind(this, opt)}>
                            {truncate(opt.label, 30)}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="no-options"> No {label} </p>
                    )}
                  </div>
                  <button
                    className="btn-create-dropdown"
                    type="button"
                    onClick={() => {
                      this.handleDropdown();
                      this.onOpenModal();
                    }}
                  >
                    <p>
                      {' '}
                      <Translate id="new contact" /> +
                    </p>
                  </button>
                </div>
              </div>
            )}
            <div className="col-md-12">
              {this.state.isWarning && (
                <p className="form-error"> Pick Company First </p>
              )}

              {touched &&
                ((error && error !== 'Required' && (
                  <span className="form-error">{error}</span>
                )) ||
                  (warning && <span className="form-error">{warning}</span>))}
            </div>
          </div>
        </div>
        <ModalCreateContact
          isOpen={this.state.isModalOpen}
          toggle={() => {
            this.onCloseModal();
          }}
          onClick={() => {
            this.onCloseModal();
          }}
          id={id}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.contact,
  dataCreated: state.contacts.dataCreated
});

export default connect(
  mapStateToProps,
  { selectContact }
)(SelectCompany);
