import * as React from 'react';
import { AColors, Color } from '../../atoms/colors/colors';
import Dropdown from './Dropdown';
import { DropdownPropsNoData } from './model/Model';
import { useRecoilValue } from 'recoil';
import { Dot } from '../../styles/global/css/Dot';

const DEFAULT_COLUMNS = 4;
const DEFAULT_ROWS = 3;

export default function DropdownColor(props: DropdownPropsNoData<Color, false, false> & {
	optionDotSize?: number,
	dotSize?: string,
	optionRows?: number,
	optionCols?: number,
	selectedValue?: number | string
}) {
	const colors = useRecoilValue(AColors);

	const colorsData = React.useMemo(() => colors.map(value => ({ label: <Dot color={value.colorCode} size={(props.optionDotSize ?? 35) + 'px'} cursor='pointer' />, value }), [props.dotSize]), [colors]);

	return <Dropdown<Color>
		{...props}
		gallery
		selectedValue={props.selectedValue ? colorsData.find(c => {
			if (typeof props.selectedValue === 'number') return c.value.id === props.selectedValue;
			return c.value.colorCode === props.selectedValue;
		}) : undefined}
		JSXButton={props.JSXButton ? props.JSXButton : ({ value }) => value?.value ? <Dot color={value.value.colorCode} size={props.dotSize ?? '35px'} cursor='pointer' /> : <></>}
		dropdownStyle={{
			...props.dropdownStyle,
			optionHeight: props.dropdownStyle?.optionHeight ?? ((props.optionRows ?? DEFAULT_ROWS) * ((props.optionDotSize ?? 35) + 22)) + 'px',
			optionWidth: props.dropdownStyle?.optionWidth ?? ((props.optionCols ?? DEFAULT_COLUMNS) * ((props.optionDotSize ?? 35) + 22)) + 'px',
		}}
		datalist={colorsData}
	/>;
}