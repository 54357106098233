import React, { useEffect, useState } from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../../helpers/storeLang';
import noImage from 'images/no-image.jpg';

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);


function ProductsRecap(props) {
	const { productsRecap, history } = props;
	const [listField, setListField] = useState([]);
	const lg = localStorage.getItem('language') || 'en';

	useEffect(() => {
		if (productsRecap) {
			const list = productsRecap.map(v => ({
				id: v.id,
				fields: v.shelf_audit_product_field_instances
			}));
			// console.log(JSON.stringify(list))
			setListField(list);
		}
	}, [productsRecap]);
	const cekImage = url => {
		if (url === '') {
			return noImage;
		}
		return url;
	};

	const getDetail = val => {

		switch (val.value) {
		// add everithing to translate here
			case true:
			case false:
			{
				const translate = getTranslate(storeLang.getState().localize);
				return translate(`shelf_audit.presence_value.${val.value}`);
			}
		}
		if (val.value !== undefined && val.value !== null) {
			return `${val.value}`;
		} else if (val.name) {
			return lg === 'en' ? val.english : val.france;
		}
		return '-';
	};

	const filterType = (fields, key) => {
		const filter = fields && fields.find(k => k.shelf_audit_field.name === key) || null;
		if (filter) {
			return filter.value && getDetail(filter.value) || '-';
		}
		return null;
	};

	const createOneList = (listF, productsR) => {
		const newList = [];
		productsR.forEach(m => {
			newList.push({ product: m });
		});
		let i = 0;
		listF.forEach(v => {
			if (newList[i]) {
				newList[i] = { product: newList[i].product, field: v };
			}
			i++;
		});
		return newList;
	};

	return (
		<div className="mx-3 my-4 px-3 py-2 detail-top">
			<div className="mb-2">
				<span className="card-title">
					<Translate id="Products recap" /> (
					{(productsRecap && productsRecap.length) || '0'})
				</span>
			</div>
			{productsRecap.length === 0 ? (
				<p className="empty-placeholder">No Data</p>
			) : (
				<div className="row">

					<div className="col-12 scroll-x" style={{ overflowX: 'auto' }}>
						{createOneList(listField, productsRecap).map(list => {
							console.log(list);
							return (
								<div className="d-flex align-items-center">
									<div className="mr-3">
										<img
											src={list.product?.product && cekImage(list.product?.product.product_image)}
											className="img-xs rounded-circle image-thumbnail mr-2"
										/>
									</div>
									{/* <div className="mr-5" style={{ width: '2vw' }}> */}
									<div className="mr-5" style={{ maxWidth: '200px', minWidth: '200px' }}>
										<p
											className="font-weight-bold mb-0 pointer"
											style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
											onClick={() => {
												localStorage.setItem(
													'productOption',
													JSON.stringify('products')
												);
												list.product?.product && history.push(`/products/detail/${list.product?.product.id}`);
											}}
										>
											{list.product?.product && list.product?.product.name}
										</p>
									</div>
									{detailField('posAdvertising', filterType(list.field?.fields, 'posAdvertising'))}
									{detailField('averagePriceRecorded', filterType(list.field?.fields, 'averagePriceRecorded'))}
									{detailField('tagPresence', filterType(list.field?.fields, 'tagPresence'))}
									{detailField('productHighlight', filterType(list.field?.fields, 'productHighlight'))}
									{detailField('facingsNumber', filterType(list.field?.fields, 'facingsNumber'))}
									{detailField('bullsEyeZone', filterType(list.field?.fields, 'bullsEyeZone'))}

									{detailField('shelfType', filterType(list.field?.fields, 'shelfType'))}
									{detailField('delistingRisk', filterType(list.field?.fields, 'delistingRisk'))}
									{detailField('correctShelf', filterType(list.field?.fields, 'correctShelf'))}
									{detailField('groupedOffer', filterType(list.field?.fields, 'groupedOffer'))}
									{detailField('shelfSectors', filterType(list.field?.fields, 'shelfSectors'))}
									{detailField('shelfZone', filterType(list.field?.fields, 'shelfZone'))}
									{detailField('shelfLevel', filterType(list.field?.fields, 'shelfLevel'))}

									{detailField('discount', filterType(list.field?.fields, 'discount'))}
									{detailField('typeOfDiscount', filterType(list.field?.fields, 'typeOfDiscount'))}
									{detailField('averageDailySales', filterType(list.field?.fields, 'averageDailySales'))}
									{detailField('averageWeeklySales', filterType(list.field?.fields, 'averageWeeklySales'))}
									{detailField('averageMonthlySales', filterType(list.field?.fields, 'averageMonthlySales'))}
									{detailField('productsQuantity', filterType(list.field?.fields, 'productsQuantity'))}
									{detailField('wasteRiskQuantity', filterType(list.field?.fields, 'wasteRiskQuantity'))}
									{detailField('voucherGiven', filterType(list.field?.fields, 'voucherGiven'))}
									{detailField('voucherValue', filterType(list.field?.fields, 'voucherValue'))}

									{detailField('goodiesDistribution', filterType(list.field?.fields, 'goodiesDistribution'))}
									{detailField('outOfStock', filterType(list.field?.fields, 'outOfStock'))}
									{detailField('discountLevel', filterType(list.field?.fields, 'discountLevel'))}
                                  
									{detailField('notes', filterType(list.field?.fields, 'notes'), true)}
                  

								</div>
							);
						}
						)}
					</div>
				</div>
			)}
		</div>
	);
}

export const detailField = (label, descr, inline) => descr && (
	<div className="col-2 mx-1 my-2">
		<p className="mb-0 text-capitalize" style={{ whiteSpace: (inline ? 'nowrap' : 'normal') }}>
			<span className="font-weight-bold">
				<Translate id={`shelf_audit.product_fields.${label}`} /> : {' '}
			</span>
			{label == 'shelfType' &&
        <Translate id={`shelf_audit.shelf_type.${descr}`} />
			}
			{label == 'shelfSectors' &&
        <Translate id={`shelf_audit.shelf_sector.${descr}`} />
			}
			{label == 'productHighlight' &&
        <Translate id={`shelf_audit.product_highlight.${descr}`} />
			}
			{label == 'shelfZone' &&
        <Translate id={`shelf_audit.shelf_zone.${descr}`} />
			}
			{label == 'typeOfDiscount' &&
        <Translate id={`shelf_audit.discount_type.${descr}`} />
			}
			{(label != 'shelfType' &&
				label != 'shelfSectors' &&
				label != 'productHighlight' &&
				label != 'shelfZone' &&
				label != 'typeOfDiscount' &&
				label != 'discountLevel') &&
        <span>{` ${descr}`}</span>
			}
		</p>
	</div>
);

export default ProductsRecap;
