/* eslint-disable array-callback-return */
import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';

const ModalErrorValidate = props => {
  const { errorValidation } = props;
  return (
    <Modal isOpen={props.isOpen} className={props.className} centered>
      <ModalHeader className="text-center" style={{ borderBottom: 'none' }}>
        Detail Error
      </ModalHeader>
      <ModalBody>
        <div className="searchDashboard">
          <div className="modal-error-validate scrollSearch">
            <ul className="py-1" style={{ listStyle: 'none' }}>
              {errorValidation &&
                errorValidation.map(value => (
                  <li className="text-capitalize">
                    <i
                      className="mr-2 fas fa-circle"
                      style={{ color: '#00b2ff', fontSize: '9px' }}
                    />
                    {value}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter style={{ borderTop: 'none' }}>
        <Button
          color="primary"
          className="px-5 mb-2"
          onClick={() => {
            props.toggle(false);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalErrorValidate;
