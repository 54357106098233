import {
	CHANGE_LOADING,
	CREATE_EVENT_ERROR,
	CREATE_EVENT_REQUEST,
	CREATE_EVENT_SUCCESS,
	DETAIL_EVENT_ERROR,
	DETAIL_EVENT_REQUEST,
	DETAIL_EVENT_SUCCESS,
	FETCH_EVENT_ERROR,
	FETCH_EVENT_REQUEST,
	FETCH_EVENT_SUCCESS,
	GET_DATE,
	GET_EVENT_BY_TYPE,
	IMPORT_EXCEL_ERROR,
	IMPORT_EXCEL_REQUEST,
	IMPORT_EXCEL_SUCCESS,
	SET_ACTIVE_FILTER,
	SORT_DATA,
	UPDATE_EVENT_ERROR,
	UPDATE_EVENT_REQUEST,
	UPDATE_EVENT_SUCCESS
} from '../actions/events.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	isLoadingSubmit: false,
	current: null,
	data: [],
	dataHistory: [],
	dataProduct: [],
	eventTypeData: [],
	count: {
		all: 0,
		active: 0,
		inactive: 0
	},
	sortField: null,
	sort: 'desc',
	search: '',
	contactsData: [],
	dataDetail: null,
	detail: [],
	date: '',
	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	activeFilter: {
		eventTypeId: null
	}
};

export default function ContactsReducer(state = initState, action) {
	switch (action.type) {
		case CREATE_EVENT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_EVENT_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_EVENT_ERROR:
			return {
				...state,
				isLoading: false
			};
		case FETCH_EVENT_REQUEST:
			return {
				...state,
				// isLoading: true
				isLoading: false
			};
		case CHANGE_LOADING:
			return {
				...state,
				isLoading: action.payload
			};
		case FETCH_EVENT_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_EVENT_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_EVENT_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_EVENT_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoading: false
			};

		case UPDATE_EVENT_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DETAIL_EVENT_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_EVENT_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_EVENT_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};
		case SET_ACTIVE_FILTER:
			return {
				...state,
				activeFilter: action.payload
			};
		case GET_DATE:
			return {
				...state,
				date: action.payload
			};
		case SORT_DATA:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};
		case IMPORT_EXCEL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case IMPORT_EXCEL_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case IMPORT_EXCEL_ERROR:
			return {
				...state,
				isLoading: false
			};
		case GET_EVENT_BY_TYPE:
			return {
				...state,
				eventTypeData: action.payload
			};
		default:
			return state;
	}
}
