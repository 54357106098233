import * as React from 'react';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';
import { FilterId, FilterResult, FilterValueResultUnion } from './model/Model';
import FilterList, { FilterParameter, FilterParameterParent } from './pages/FilterList';
import { ObjectAction } from '../../../../web-types/objectActions';
import { TagType } from '../toolbarFilter/tags/Model';

function AdvancedFilters(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  isOpenSummary?: boolean
  onChange?: (values: FilterResult) => void
  filterList?: FilterParameter[] | FilterParameterParent[]
  filterValues?: FilterValueResultUnion
  selectedFilter?: FilterId
  permission?: ObjectAction
  tagType?: TagType
  title?: string
  extractFilters?: boolean
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, height: '100%', top: '0%' }}
			popupMode={PopupMode.Details}
			content={<FilterList
				title={props.title}
				onClickOut={() => close()}
				isSummaryOpen={props.isOpenSummary}
				onChange={(values) => (props.onChange != null) && props.onChange(values)}
				filterList={props.filterList}
				filterValues={props.filterValues}
				selectedFilter={props.selectedFilter}
				permission={props.permission}
				tagType={props.tagType}
				extractFilters={props.extractFilters}
			/>}
		/>
	);
}

export default AdvancedFilters;
