import React, {Component} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from 'reactstrap';

const valPaginate = [50, 100, 200, 500];

class PaginationOption extends Component {
  state = {
    val: 50
  };
  componentDidMount() {
    const paramsData = JSON.parse(localStorage.getItem('params'));
    if (paramsData?.limit !== undefined) {
      this.setState({ val: paramsData.limit });
    }
  }

  render() {
    return (
      <div>
        <UncontrolledButtonDropdown
          direction="down"
          className="form-filter-option mr-2"
        >
          <DropdownToggle className="toggler py-0 px-2" caret>
            {this.state.val}
          </DropdownToggle>
          <DropdownMenu className="dropdownSelectOption">
            {valPaginate.map(value => (
              <DropdownItem
                className="py-1 px-2"
                onClick={() => {
                  this.setState({ val: value });
                  this.props.handleLimit(value);
                }}
              >
                {value}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    );
  }
}

export default PaginationOption;
