import styled from 'styled-components';
import { BorderColor } from '../../../styles/global/css/Utils';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 5px;
    width: 100%;
`;

interface BoxProps {
  justifyContent?: string
}

const Box = styled.div<BoxProps>`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: ${p => p.justifyContent ? p.justifyContent : 'flex-start'};
`;

const Text = styled.p`
    font-family: "Poppins", sans-serif;
    color: rgb(130, 130, 130);
    margin: 0;
    margin-left: 2%;
`;

const ContainerCardPage = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 20%;
`;

interface CardPageProps {
  isActive: boolean
  cursor?: string
  urlImage?: string
  width?: string
  height?: string
}

const CardPage = styled.div<CardPageProps>`
    width: ${p => p.width ? p.width : '25px'};
    height: ${p => p.height ? p.height : '28px'};
    min-width: 22px;
    background-color:${p => p.isActive ? '#FFFFFF' : '#D6D6D6'};
    border-radius: 5px;
    cursor: ${p => p.isActive && p.cursor ? p.cursor : 'auto'};
    border: 1px solid ${BorderColor};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 400;
    background-image: ${p => p.isActive && p.urlImage ? `url('${p.urlImage}')` : 'none'};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    user-select: none;
`;

interface CardPageImageProps {
  double?: boolean
}

const CardPageImage = styled.img<CardPageImageProps>`
    width: ${p => p.double ? 7 : 6}px;
    height: ${p => p.double ? 7 : 6}px;
`;

export {
	Container,
	Box,
	Text,
	ContainerCardPage,
	CardPage,
	CardPageImage
};
