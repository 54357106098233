import React, {Component} from 'react';

// import ComponentHeader from './ComponentHeader';

class Calculation extends Component {
  state = {};

  render() {
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }

    return (
      <div className={baseClasses}>
        {/* <ComponentHeader {...this.props} /> */}
      </div>
    );
  }
}

export default Calculation;
