import React, {Component} from 'react';

// import ComponentHeader from './ComponentHeader';

class Hyperlink extends Component {
  state = {};

  render() {
    const { data } = this.props;
    console.log('dataHyper', data);
    return (
      // <div className="form-group">
      //   {/* <ComponentHeader {...this.props} /> */}
      //   <a href={data.src} className="text-12" target="_blank">
      //     {data.field_name}
      //   </a>
      // </div>
      (<div className="form-group">
        <label className="control-label" htmlFor={`id-${data.id}`}>
          {data.is_required && <span className="text-danger">* </span>}
          {data.is_display_field_name && data.field_name}
        </label>
        <select
          id={`id-${data.id}`}
          className="form-control"
          defaultValue={data.initial_value}
        >
          <option selected disabled>
            Choose {data.link}
          </option>
          <option>{data.link} 1</option>
          <option>{data.link} 2</option>
          <option>{data.link} 3</option>
        </select>
      </div>)
    );
  }
}

export default Hyperlink;
