export const SELECT_MENU = 'products';

export function selectMenu(name) {
	return dispatch => {
		dispatch({
			type: SELECT_MENU,
			payload: name
		});
	};
}
