import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import { AdditionalButton, ButtonStyle } from '../../components_v2/popup/PopupCreation';
import { DeletionContainer, DeletionHeader } from '../../components_v2/popup/PopupDeletion';
import { ValidateButton } from '../../components_v2/popup/style/PopupCreationStyle';
import { translateToString } from '../../styles/global/translate';
import { ModalState } from '../products/model';
import { FlexDiv } from '../products/style';
import { AlertRes } from './AlertProvider';
import DuplicateImage from 'images/icons/import/duplicates.svg';
import WarningImage from 'images/icons/import/warning.svg';
import ErrorImage from 'images/icons/import/error.svg';
import ValidatedImage from 'images/icons/import/validated.svg';

const Close = styled.img`
    cursor: pointer;
`;

const AlertSvg = styled.img`
	height: 60px;
`;

export type AlertPropsDelete = {
	gap?: string,
	zIndex?: number,
	width?: string,
	height?: string,
	noButtons?: boolean
	svg?: 'warning' | 'error' | 'duplicate' | 'validate' | { url: string }
	textAlign?: string,
	buttons?: (AdditionalButton & { res: AlertRes })[]
}

export type AlertProps = AlertPropsDelete & {
	title?: React.ReactNode,
	content: React.ReactNode | ((onBreak: () => void, onOk: () => void) => React.ReactNode),
	mode?: 'delete',
};

function AlertNode(props: AlertProps & {
	onBreak: () => void,
	onOk: () => void
}) {
	const [state, setState] = React.useState<ModalState<() => void>>({ isOpen: true });
	const svgUrl = React.useMemo(() => {
		switch (props.svg) {
			case undefined: return undefined;
			case 'duplicate': return DuplicateImage;
			case 'warning': return WarningImage;
			case 'validate': return ValidatedImage;
			case 'error': return ErrorImage;
			default: return props.svg.url;
		}

	}, [props.svg]);

	React.useEffect(() => {
		if (state.isOpen) return;
		state.data?.();
	}, [state.isOpen]);

	return (
		<Popup
			isOpen={state.isOpen}
			onClickOut={() => setState({ isOpen: false, data: props.onBreak })}
			popupMode={PopupMode.Centered}
			name='Alert'
			popupStyle={{
				zIndex: props.zIndex,
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				position: 'fixed',
				borderRadius: '10px',
				width: props.width ?? '500px',
				height: props.height,
				fitContent: true,
				containerTransform: props.height ? undefined : 'translateY(-50%)'
			}}>
			<>
				{props.title && <DeletionHeader>
					{props.title}
					<Close src={close} onClick={() => setState({ isOpen: false, data: props.onBreak })} width={30} />
				</DeletionHeader>}
				<DeletionContainer gap={props.gap}>
					<FlexDiv gap='20px' flow='column' justify='center' width='100%'>
						{svgUrl && <AlertSvg src={svgUrl} />}
						<FlexDiv flow="column" gap='15px' align='flex-start' style={{ textAlign: props.textAlign ?? props.mode === 'delete' ? 'left' : 'center' }} width='100%'>
							{typeof props.content === 'function' ? props.content(props.onBreak, props.onOk) : props.content}
						</FlexDiv>
					</FlexDiv>
					{!props.noButtons && <FlexDiv gap='20px' margin='30px 0 0 0' justify={props.mode === 'delete' ? 'left' : 'center'}>
						{props.buttons
							? props.buttons.map((ab, i) => 
								<ValidateButton
									key={`additionalButton[${i}]`}
									disabled={ab.canValidate === false}
									onClick={() => {
										ab.onSubmit?.();
										let data: () => void;
										switch (ab.res) {
											case AlertRes.Break:
												data = props.onBreak;
												break;
											case AlertRes.Ok:
												data = props.onOk;
												break;
										}
										setState({ isOpen: false, data });
									}}
									buttonStyle={ab.style}
								>
									{ab.title}
								</ValidateButton>
							)
							: <>
								<ValidateButton
									onClick={() => setState({ isOpen: false, data: props.onBreak })}
									buttonStyle={ButtonStyle.White}
								>
									<Translate id='global.back' />
								</ValidateButton>
								<ValidateButton
									onClick={() => setState({ isOpen: false, data: props.onOk })}
									buttonStyle={props.mode === 'delete' ? ButtonStyle.Error : ButtonStyle.Default}
								>
									{props.mode === 'delete' ? translateToString('delete') : translateToString('tool_bar.dots.validate') }
								</ValidateButton>
							</>}
					</FlexDiv>}
				</DeletionContainer>
			</>
		</Popup>
	);
}

export default async function alertPopup(props: AlertProps & {
	setChild: (child?: React.ReactNode) => void,
}): Promise<AlertRes> {
	const { setChild } = props;
	return new Promise<AlertRes>((resolve) => {
		const onBreak = () => {
			setChild();
			resolve(AlertRes.Break);
		};

		const onOk = () => {
			setChild();
			resolve(AlertRes.Ok);
		};

		setChild(<AlertNode {...props} onBreak={onBreak} onOk={onOk}/>);
	});
}