/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import {selectEventStatus} from '../../store/actions/select.action';
import {Translate} from 'react-localize-redux';

class SelectEventType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectEventStatus } = this.props;
    selectEventStatus();
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (data !== nextProps.data) {
      const opt = nextProps.data.map(data => ({
        value: parseInt(data.id, 10),
        label: <Translate id={"event." + data.name} />
      }));
      this.setState({ opt });
    }
  }

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      placeholder,
      readOnly
    } = this.props;
    // console.log('id', id);
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        readOnly={readOnly}
        options={opt}
        id="inputOwner"
        placeholder={placeholder}
        validate={validate}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.eventStatus
});

export default connect(
  mapStateToProps,
  { selectEventStatus }
)(SelectEventType);
