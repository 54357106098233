import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API } from '../../../config/keys';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

export async function getEventTypes() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_API}/api/eventTypes`
	});
}

export async function getEventStatuses() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_API}/api/eventStatuses`
	});
}
