import styled from 'styled-components';
import { BorderColor } from '../../../styles/global/css/Utils';

const Container = styled.div`
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

const Body = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const Footer = styled.div`
    width: 100%;
    height: 10%;
    border-top: 1px solid ${BorderColor};
    display: flex;
    align-items: center;
`;

const BodyLeft = styled.div`
    border-right: 1px solid ${BorderColor};
    padding-right: 10px;
`;

const BodyRight = styled.div`
    max-width: 460px;
    min-width: 400px;
    padding-left: 10px;
    margin-top: 43px;
`;

export {
	Container,
	Body,
	BodyLeft,
	BodyRight,
	Footer
};
