import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { required } from '../../../../../components/FieldModal';
import SelectCompany from '../../../components/selectCreate/SelectCompany';
import SelectContact from '../../../components/selectCreate/SelectContact';
import SelectOpportunity from '../../../components/selectCreate/SelectOpportunity';
import { fieldName } from '../services/fieldGetter';
import { Translate } from 'react-localize-redux';
import { LegacyWrapperDropdownSearch } from '../../../../../components_v2/dropdown/DropdownSearch';

class HyperLink extends Component {
	state = {
		hyperlinks: {
			companyField: {
				id: '',
				name: '',
				value: { value: null, label: '' }
			},
			contactField: {
				id: '',
				name: '',
				value: { value: null, label: '' }
			},
			opportunityField: {
				id: '',
				name: '',
				value: { value: null, label: '' }
			}
		}
	};

	componentWillReceiveProps(nextProps) {
		const { thisForm, allField } = this.props;
		const apiData = get(allField, 'dataDetail.user_form_fields', []);

		// console.log('apiData', apiData);

		if (thisForm !== nextProps.thisForm && nextProps.thisForm) {
			const hyperlinks = {
				companyField: {
					id: '',
					value: null
				},
				contactField: {
					id: '',
					value: null
				},
				opportunityField: {
					id: '',
					value: null
				}
			};

			apiData.map(data => {
				if (data.field_type === 'hyperlink') {
					if (data.details.link === 'company') {
						hyperlinks.companyField.id = data.id;
					} else if (data.details.link === 'contact') {
						hyperlinks.contactField.id = data.id;
					} else if (data.details.link === 'opportunity') {
						hyperlinks.opportunityField.id = data.id;
					}
				}
				if (data.field_type === 'table') {
					// console.log('datatables', data);

					data.details.fields.map(field => {
						if (field.field_type === 'hyperlink') {
							if (field.details.link === 'company') {
								hyperlinks.companyField.id = data.id;
								hyperlinks.companyField.name = field.system_field_name;
							} else if (field.details.link === 'contact') {
								hyperlinks.contactField.id = data.id;
								hyperlinks.contactField.name = field.system_field_name;
							} else if (field.details.link === 'opportunity') {
								hyperlinks.opportunityField.id = data.id;
								hyperlinks.opportunityField.name = field.system_field_name;
							}
						}
					});
				}
				return null;
			});

			// console.log('thisForm', nextProps.thisForm);

			hyperlinks.companyField.value = nextProps.thisForm.values[
				`${hyperlinks.companyField.id}`
			]
				? nextProps.thisForm.values[`${hyperlinks.companyField.id}`] ||
          nextProps.thisForm.values[`${hyperlinks.companyField.id}`][0][
          	`${hyperlinks.companyField.name}`
          ]
				: null;

			hyperlinks.contactField.value = nextProps.thisForm.values[
				`${hyperlinks.contactField.id}`
			]
				? nextProps.thisForm.values[`${hyperlinks.contactField.id}`] ||
          nextProps.thisForm.values[`${hyperlinks.contactField.id}`][0][
          	`${hyperlinks.contactField.name}`
          ]
				: null;

			hyperlinks.opportunityField.value = nextProps.thisForm.values[
				`${hyperlinks.opportunityField.id}`
			]
				? nextProps.thisForm.values[`${hyperlinks.opportunityField.id}`] ||
          nextProps.thisForm.values[`${hyperlinks.opportunityField.id}`][0][
          	`${hyperlinks.opportunityField.name}`
          ]
				: null;

			// console.log('hyperlinks', hyperlinks);
			this.setState({
				hyperlinks
			});
		}
	}

	render() {
		const { data, dynamicField, type, typeUser } = this.props;
		const { hyperlinks } = this.state;
		const validate = data.is_required ? [required] : [];
		const link = get(data, 'details.link');
		const name = fieldName(data, dynamicField);
		// console.log('hyperlinks', this.state.hyperlinks);
		// // console.log('dynamicField', dynamicField);

		return (
			<Translate>
				{({ translate }) => (
					<Fragment>
						{link === 'company' && (
							<Field
								name={name}
								component={LegacyWrapperDropdownSearch}
								id={`id-${name}`}
								type="text"
								label={data.is_display_field_name && data.field_name}
								isFormData={true}
								placeholder={translate('pick company')}
								requiredStar={data.is_required}
								validate={validate}
								initValue={null}
								readOnly={
									type === 'detail' ||
                  ['company', 'contact', 'opportunity'].includes(typeUser)
								}
							/>
						)}

						{link === 'contact' && (
							<Field
								name={name}
								component={SelectContact}
								companyId={get(hyperlinks, 'companyField.value.value', null)}
								id={`id-${name}`}
								type="text"
								label={data.is_display_field_name && data.field_name}
								placeholder={translate('pick contact')}
								requiredStar={data.is_required}
								validate={validate}
								initValue={null}
								readOnly={
									type === 'detail' ||
                  ['contact', 'opportunity'].includes(typeUser)
								}
							/>
						)}

						{link === 'opportunity' && (
							<SelectOpportunity
								name={name}
								id={`id-${name}`}
								companyId={get(hyperlinks, 'companyField.value.value', null)}
								type="text"
								label={data.is_display_field_name && data.field_name}
								placeholder="Choose Opportunity"
								requiredStar={data.is_required}
								validate={validate}
								initValue={null}
								readOnly={
									type === 'detail' ||
                  ['opportunity'].includes(typeUser) === true
								}
							/>
						)}
					</Fragment>
				)}
			</Translate>
		);
	}
}

const mapStateToProps = state => ({
	allField: state.formbuilder,
	thisForm: state.form.formDataForm
});

export default connect(mapStateToProps, {})(HyperLink);
