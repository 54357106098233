import add_blue from 'images/icon/add_blue.png';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import ListView from './ListView';
import DropdownTable from './DropdownTable';
import ModalTemplate from '../ModalForm';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import Restricted from '../../../../containers_v2/permissions/Restricted';

class TableWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			selectedRows: []
		};
	}
	onSelect = row => {
		this.setState({
			selectedRows: row
		});
	};

	toggle = () => {
		this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
	};

	render() {
		const {
			data,
			page,
			onTableChange,
			route,
			totalSize,
			columns,
			lastPage,
			handleFilter,
			isSelectRow,
			showPagination,
			loading,
			enableDownload,
			disablePagination,
			current,
			handleLimit,
			limit,
			filterByAlphabet,
			match,
			onPageChange,
			setModal,
			noCreate
		} = this.props;
		const instance = JSON.parse(localStorage.getItem('instance')) || '';
		return (
			(<div>
				<div className="table-bg sticky-v2 right-panel">
					<div className="d-flex justify-content-between pt-1">
						{route.subPath ? (
							<div className="d-flex flex-row mr-4">
								<Link to="/enform/list" className="my-auto">
									<h2 className="card-title mr-4 mb-0">
										<Translate id={route.subPath} />
									</h2>
								</Link>
								<h2 className="card-title font-weight-bold my-auto">
									{instance}
								</h2>
							</div>
						) : (
							<div></div>
						)}
						<div className="d-flex">
							<Restricted to={{ objectAction: 'CreateShelfAudit' }}>
								{noCreate === false && (
									<div className="my-auto mx-1">
										<div
											className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle"
											// onClick={() => history.push(`/enform/instanceform/${id}`)}
											onClick={() => {
												setModal({ status: 'create', isOpen: true });
											}}
										>
											<img
												src={add_blue}
												className="custom-icon"
												alt=""
											/>
										</div>
									</div>
								)}
							</Restricted>
							{/* <DropdownTable
								dropdownOpen={this.state.dropdownOpen}
								toggle={this.toggle}
								current={current}
								dataExport={this.state.selectedRows}
								onExport={this.props.onExport}
								match={this.props.match}
							/> */}
						</div>
					</div>
				</div>
				<ListView
					data={data && data.data}
					page={page}
					onTableChange={onTableChange}
					route={route}
					totalSize={totalSize}
					columns={columns}
					lastPage={lastPage}
					handleFilter={handleFilter}
					isSelectRow={isSelectRow}
					showPagination={showPagination}
					loading={loading}
					enableDownload={enableDownload}
					disablePagination={disablePagination}
					onSelect={this.onSelect}
					handleLimit={handleLimit}
					limit={limit}
					match={match}
					filterByAlphabet={filterByAlphabet}
					onPageChange={onPageChange}
					template={route.subPath ? 'result' : 'template'}
				/>
				<ModalTemplate
					isOpen={data.modal.isOpen}
					toggle={() => setModal({ ...data.modal, isOpen: false })}
				/>
			</div>)
		);
	}
}

export default TableWrapper;
