import * as React from 'react';
import { Translate } from 'react-localize-redux';
import TemplateOrders from '../../containers_v2/orders/templateOrders/TemplateOrders';
import selectProduct from '../../containers_v2/orders/selectProducts/SelectProducts';
import Summary from '../../containers_v2/orders/summary/Summary';
import Billing from '../../containers_v2/orders/billing/Billing';
import { Route } from '../Routes';
import OrderBoxRouteBlack from 'images/menu_icon/order_box_black.svg';
import OrderBoxRouteBlue from 'images/menu_icon/order_box_blue.svg';
import OrderBoxRouteWhite from 'images/menu_icon/order_box_white.svg';

const OrdersRoutes = (): Route[] => [
	{
		component: TemplateOrders,
		path: '/orders',
		name: <Translate id="sidebar.orders" />,
		role: ['all'],
		key: 'orders',
		sectionKey: 'orders',
		title: 'Orders',
		subTitle: 'Orders',
		restricted: false,
		exact: true,
		logoImg: OrderBoxRouteBlack,
		hoverImg: OrderBoxRouteBlue,
		activeImg: OrderBoxRouteWhite,
		permission: ['ViewOrder'],
	},
	{
		component: selectProduct,
		path: '/orders/select-products',
		key: 'orders',
		sectionKey: 'orders',
		title: 'Orders',
		name: 'Select Product',
		exact: true
	},
	{
		component: Summary,
		path: '/orders/summary',
		key: 'orders',
		sectionKey: 'orders',
		title: 'orders',
		name: 'Summary',
		exact: true
	},
	{
		component: Billing,
		path: '/orders/billing',
		key: 'orders',
		sectionKey: 'orders',
		title: 'orders',
		name: 'Billing',
		exact: true
	}
];

export default OrdersRoutes;
