import styled from 'styled-components';
import { RedSidely } from './Utils';

interface DotProps extends React.CSSProperties {
  size?: string
}

export const Dot = styled.div<DotProps>`
    height: ${p => p.size ?? '25px'};
    width: ${p => p.size ?? '25px'};
    background-color: ${p => p.color ?? p.backgroundColor ?? 'black'};
    border-radius: 50%;
    ${p => p.borderColor ? `border: 1px solid ${p.borderColor};` : ''}
    ${p => p.cursor ? `cursor: ${p.cursor};` : ''}
    ${p => p.margin ? `margin: ${p.margin};` : ''}
    flex-shrink: 0;
`;

export const DeleteDot = styled.div<{ size?: string, fontSize?: string }>`
	width: ${p => p.size ?? '25px'};
	height: ${p => p.size ?? '25px'};
	background-color: ${RedSidely};
	color: white;
	&:after {
		content: "-";
	}
	flex-shrink: 0;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${p => p.fontSize ?? '15px'};
	cursor: pointer;
`;
