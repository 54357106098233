import styled from 'styled-components';
import { BlueSidely, DarkGreySidely2 } from '../../../styles/global/css/Utils';

export const Container = styled.div<{ height?: string, backgroundColor?: string, borderRadius?: string }>`
    background-color: ${p => p.backgroundColor ?? 'white'};
    margin: 0;
    padding: 0;
    width: 100%;
    height: ${p => p.height ?? '75vh'};
    border-radius: ${p => p.borderRadius ?? '0'};
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
`;

export const Image = styled.img`
    display: block;
    width: 300px;
    height: 300px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
`;

export const Button = styled.button`
    color: white !important;
    background-color: ${BlueSidely};
    padding: 8px 15px;
    display: block;
    border-radius: 10px;
    text-align: center;
    font-size: 13px;
    margin-bottom: 20px;
    border-style: none;
    font-weight: normal;
    margin-left: auto !important;
    margin-right: auto !important;
    width: fit-content;
`;

export const Text = styled.h5`
    color: ${DarkGreySidely2};
    text-align: center;
    margin: 20px;
    font-weight: normal;
`;
