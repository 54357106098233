import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Reports } from '../../containers_v2/reports/Reports';
import { Route } from '../Routes';
import ReportRouteBlack from 'images/menu_icon/reports_black.svg';
import ReportRouteWhite from 'images/menu_icon/reports_white.svg';
import ReportRouteBlue from 'images/menu_icon/reports_blue.svg';
import GenericEditor from '../../containers_v2/reports/generic/genericEditor';

const ReportsRoutes = (): Route[] => [
	{
		component: Reports,
		path: '/reports',
		name: <Translate id="sidebar.monitoring_and_analysis" />,
		key: 'reports',
		sectionKey: 'reports',
		logoImg: ReportRouteBlack,
		hoverImg: ReportRouteBlue,
		activeImg: ReportRouteWhite,
		title: 'Reports',
		subTitle: 'Reports',
		permission: ['ViewReporting', 'ViewGallery', 'ViewObjective'],
		restricted: false,
		exact: true,
		menu: true,
		precedence: 2,
		subroutes: [
			{ path: '/targets', name: <Translate id='targets' />, admin: true, beta: true, permission: ['ViewObjective'] },
			{ path: '/reports', name: <Translate id="reports" />, permission: ['ViewReporting'] },
			{ path: '/gallery', name: <Translate id="gallery.title" />, cumulative: true, permission: ['ViewGallery', 'ReadShelfAudit'], },
			{ path: '/galleryV2', name: <Translate id="gallery.title" />, permission: ['ViewForm'], beta: true },
		]
	},
	{
		component: GenericEditor,
		path: '/reportEditor',
		key: 'reports',
		sectionKey: 'reports',
		title: 'Reports',
		subTitle: 'Reports',
		admin: true,
		restricted: false,
		exact: true
	}
];

export default ReportsRoutes;
