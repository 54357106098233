import trash_black from 'images/ui_icon/trash_black.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import { AEventEdition } from '../../../../../atoms/event/eventEdition';
import Avatar from '../../../../../components_v2/avatar/Avatar';
import PageLoader from '../../../../../components_v2/pageLoader/PageLoader';
import PopupDeletion from '../../../../../components_v2/popup/PopupDeletion';
import storeLang from '../../../../../helpers/storeLang';
import { Dot } from '../../../../../styles/global/css/Dot';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { RedSidely } from '../../../../../styles/global/css/Utils';
import { UserToolTip } from '../../../../../styles/global/ToolTip';
import { getEventStatusColor } from '../../../../calendar/Calendar';
import { deleteEvent } from '../../../../calendar/EventsApi';
import PopupEvent, { CompanyEventToCalendarEvent } from '../../../../calendar/popup/Event';
import { normalizeCapital } from '../../../../globals/FieldFunction';
import { LoadingStateEnum } from '../../../../import/model';
import { ModalState } from '../../../../products/model';
import { CompanyEvent } from '../../../model/Model';
import { NoDataText } from '../../../style/Style';
import { updateEvent } from '../../action';
import {
	Card,
	CardExtraRight,
	CardImage,
	CardLeft,
	CardMiddle,
	CardRight,
	CardText,
	CardTitle,
	ColorBar,
	Container,
	SeeMore,
	Trash
} from '../style/LinkedElementStyle';
import { ARefresh } from '../../../../../atoms/global/refresh';
import { LinkedElementId } from '../../../../../containers/companies/components/linkedElements/actions';
import { getLinkedElementEvents } from '../actions';

interface DeletionState {
	isOpen: boolean
	event?: CompanyEvent
}

function Event(props: {
  id: LinkedElementId
  isExtend?: boolean
  onDisableClickOut?: (value: boolean) => void
  newCreation?: CompanyEvent,
  onDelete: () => void
}): JSX.Element {
	const DEFAULT_LIMIT = 3;

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADING);
	const [loadingMore, setLoadingMore] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [events, setEvents] = React.useState<CompanyEvent[]>([]);
	const [total, setTotal] = React.useState<number>(0);
	const [offset, setOffset] = React.useState<number>(0);
	const [modalState, setModalState] = React.useState<ModalState<CompanyEvent>>({ isOpen: false });
	const [loadingEventStatus, setLoadingEventStatus] = React.useState<LoadingStateEnum[]>([]);
	const eventEdition = useRecoilValue(AEventEdition);

	const [deletionState, setDeletionState] = React.useState<DeletionState>({ isOpen: false });

	const refreshingAtom = useRecoilValue(ARefresh);

	React.useEffect(() => {
		if (eventEdition != null) {
			const index = events.findIndex(e => e.event_id == eventEdition.eventId);
			if (index >= 0) {
				for (const [key, value] of Object.entries(eventEdition)) {
					switch (key) {
						case 'eventStatusId':
							events[index].event_status_id = value;
							break;
						case 'eventTypeId':
							events[index].event_type_id = value;
							break;
						case 'title':
							events[index].event_title = value;
							break;
						case 'start':
							events[index].event_start_date = value;
							break;
						case 'end':
							events[index].event_end_date = value;
							break;
						case 'incharge':
							events[index].incharge_id = value;
							break;
						case 'description':
							events[index].event_description = value;
							break;
						case 'clientCompanyId':
							events[index].client_company_id = value;
							break;
					}
				}
				setEvents([...events]);
			}
		}
	}, [eventEdition]);

	const translate = getTranslate(storeLang.getState().localize);

	const fetchCompanyEvent = () => {
		getLinkedElementEvents({ limit: events.length > 0 ? events.length : DEFAULT_LIMIT, offset: 0, id: props.id }).then(response => {
			if (events) {
				response.events.forEach((event: CompanyEvent) => {
					const index = events.findIndex(e => e.event_id == event.event_id);
					if (index >= 0) {
						events[index] = event;
					} else {
						events.push(event);
					}
				});
			}
			setEvents([...events]);
			setTotal(response.total);
			setLoading(LoadingStateEnum.LOADED);
		});
	};

	React.useEffect(() => {
		if (refreshingAtom.timeline !== undefined) addMore();
	}, [refreshingAtom.timeline]);

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		fetchCompanyEvent();
	}, []);

	React.useEffect(() => {
		if (props.newCreation != null) {
			setEvents([props.newCreation, ...events]);
			setOffset(offset + 1);
		}
	}, [props.newCreation]);

	function addMore(): void {
		const newOffset = offset + DEFAULT_LIMIT;
		setOffset(newOffset);
		setLoadingMore(LoadingStateEnum.LOADING);
		getLinkedElementEvents({ limit:DEFAULT_LIMIT + events.length, offset: 0, id: props.id }).then(response => {
			setEvents(response.events);
			setTotal(response.total);
			setLoadingMore(LoadingStateEnum.LOADED);
		});
	}

	function confirmationDeletionEvent(): void {
		if (!deletionState.event) {
			return;
		}

		deleteEvent(deletionState.event.event_id, () => {
			props.onDelete();
			setEvents(events.filter(e => e.event_id !== deletionState.event?.event_id));
			setOffset(offset - 1);
			setTotal(total - 1);
		});
	}

	const updateEventStatus = async(e: CompanyEvent, i: number) => {
		loadingEventStatus[i] = LoadingStateEnum.LOADING;
		setLoadingEventStatus([...loadingEventStatus]);
		return await updateEvent(e.event_id, {
			status_id: e.event_status_id == 2 ? 1 : 2
		});
	};

	return (
		(<Container>
			{
				loading === LoadingStateEnum.LOADING
					? <Loader center width="30px" />
					: events.length > 0
						? events.map((e, i) => {
							const textColor = (e.event_status_id == 1 && moment.utc(e.event_end_date) < moment()) ? RedSidely : undefined;
							return (
								(<Card key={`LinkedElementEvent[${i}]`} isExtend={props.isExtend ?? false} onClick={() => {
									setModalState({ isOpen: true, data: e });
									props.onDisableClickOut?.(true);
								}}>
									<ColorBar color={getEventStatusColor(e.event_status_id) ?? 'none'} />
									<CardLeft>
										<UserToolTip userId={e.incharge_id} left='-35px'>
											{
												e.incharge_photo && e.incharge_photo.length > 0
													? <CardImage url={e.incharge_photo} />
													: <Avatar userId={e.incharge_id} width="35px" name={e.incharge_name.trim()} />
											}
										</UserToolTip>
									</CardLeft>
									<CardMiddle width='60%'>
										<CardTitle>{e.event_title.trim()}</CardTitle>
										{
											moment.utc(e.event_start_date).dayOfYear() === moment.utc(e.event_end_date).dayOfYear()
												? <CardText color={textColor}>{`${normalizeCapital(translate('global.the').toString())} ${moment.utc(e.event_start_date).local().format(`DD MMMM YYYY[, ${translate('global.from').toString()}] HH:mm`)} ${translate('global.to').toString()} ${moment.utc(e.event_end_date).local().format('HH:mm')}`}</CardText>
												: <CardText color={textColor}>{`${normalizeCapital(translate('global.from2').toString())} ${moment.utc(e.event_start_date).local().format('DD MMMM YYYY[, ] HH:mm')} ${translate('global.to2').toString()} ${moment.utc(e.event_end_date).local().format('DD MMMM YYYY[, ] HH:mm')}`}</CardText>
										}
										<CardText color={textColor}><Translate id={`event.${e.event_type_name}`} /></CardText>
									</CardMiddle>
									<CardRight>
										{loadingEventStatus[i] == LoadingStateEnum.LOADING
											? <PageLoader size={20} />
											: <div
												style={{ padding: 5 }}
												onClick={(clickEvent) => {
													clickEvent.stopPropagation();
													updateEventStatus(e, i).then(() => {
														events[i].event_status_id = events[i].event_status_id == 2 ? 1 : 2;
														setEvents([...events]);
														loadingEventStatus[i] = LoadingStateEnum.LOADED;
														setLoadingEventStatus([...loadingEventStatus]);
													});
												}}>
												<Dot
													size='20px'
													borderColor={getEventStatusColor(2)}
													backgroundColor={e.event_status_id == 2 ? getEventStatusColor(2) : 'none'}

												/>
											</div>
										}
									</CardRight>
									<CardExtraRight>
										<Trash src={trash_black} onClick={(event) => {
											event.stopPropagation();
											setDeletionState({ ...deletionState, isOpen: true, event: e });
										}} />
									</CardExtraRight>
								</Card>)
							);
						})
						: <NoDataText><Translate id='no_events' /></NoDataText>
			}
			{
				loading === LoadingStateEnum.LOADED && events.length < total &&
                <SeeMore onClick={() => addMore()}><Translate id='see_more' /></SeeMore>
			}
			{
				loading === LoadingStateEnum.LOADED && loadingMore === LoadingStateEnum.LOADING &&
                <Loader centerHorizontal width="30px" />
			}
			<PopupDeletion 
				isOpen={deletionState.isOpen} 
				records={0} 
				translationKey=''
				customTitle={translate('company.detail.linked_elements.event').toString()}
				customMessage={translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${deletionState.event?.event_title}'`)} 
				onClickOut={() => setDeletionState({ ...deletionState, isOpen: false })}
				onValidation={() => confirmationDeletionEvent()}
				noVerification
			/>
			<PopupEvent
				hide={['companies']}
				isOpen={modalState.isOpen}
				setIsOpen={isOpen => setModalState({ ...modalState, isOpen })}
				event={modalState.data != null ? CompanyEventToCalendarEvent(modalState.data) : undefined}
				onValueChange={values => {
					const body = {};
					values.forEach(([key, value]) => body[key] = value);
					const eventId = modalState.data?.event_id;
					if (eventId) {
						updateEvent(eventId, body)
							.then(() => {
								const index = events.findIndex(e => e.event_id == eventId);
								if (index >= 0) {
									for (const [key, value, data] of values) {
										switch (key) {
											case 'description':
												events[index].event_description = value.updated;
												break;
											case 'type_id':
												events[index].event_type_id = value;
												events[index].event_type_name = data ?? '';
												break;
											case 'status_id':
												events[index].event_status_id = value;
												events[index].event_status_name = data ?? '';
												break;
											case 'end':
												events[index].event_end_date = moment.utc(value).format('YYYY-MM-DD HH:mm');
												break;
											case 'start':
												events[index].event_start_date = moment.utc(value).format('YYYY-MM-DD HH:mm');
												break;
											case 'title':
												events[index].event_title = value;
												break;
											case 'contact_id':
												events[index].contact_id = value.updated;
												break;
										}
									}
									setEvents([...events]);
								}
							})
							.catch(console.error);
					}}
				}
			/>
		</Container >)
	);
}

export default Event;
