import { DashboardItem } from '../Dashboard';
import { getAvgColorByFrequencyScore, getEmojiByFrequencyScore } from '../../../atoms/global/frequency';
import { FlexDiv } from '../../products/style';
import { Translate } from '../../../styles/global/translate';
import * as React from 'react';
import {
	CircleDiv,
	CreateButton,
	DeleteButton,
	EditButton, Ellipsis,
	InfinityAjustement,
	NoEvent,
	WidgetBody,
	WidgetTitle,
	WidgetTitleWrapper
} from '../Widget';
import styled from 'styled-components';
import { LightGreySidely, LightGreySidely2 } from '../../../styles/global/css/Utils';
import { DefaultText } from '../../../styles/global/css/GlobalText';

export const FrequencyChartContainer = styled.div`
	width: 100%;
`;

export interface FrequencyChartProps<T = unknown> {
	datalist: T[],
	width?: string,
}

export const PercentStackBarContainer = styled.div<{ width?: string }>`
  	width: ${p => p?.width ?? 'calc(100% - 10px)'};
  	height:20px;
	display:flex;
  	align-items: center;
  	border-radius: 4px;
  	overflow:hidden;
  	gap:1px;
  	background: ${LightGreySidely};
  	margin: 5px;
  	cursor: pointer;
`;

export const PercentStackBarItem = styled.div<{ percentage: string, color?: string }>`
  	${DefaultText};
	width: ${p => p.percentage}%;
  	height:20px;
  	background-color: ${p => p.color ?? ''};
  	color:white;
  	text-align:center;
  	font-size:10px;
  	line-height:20px;
  	:before {
      content: '${p => Number(p.percentage) > 10 ? p.percentage + '%' : ''}';
	}
    min-width:2px;
  	//font-weight: 600;
`;

export const LegendListContainer = styled.div`
  	width: 100%;
  	overflow-y:auto;
  	margin-top:10px;
  	background:white;
`;

export function PercentStackBar(props: FrequencyChartProps<EventFrequency>) {
	const { datalist } = props;
	const total = datalist.reduce((acc, currentData) => acc + currentData.count, 0);

	return <PercentStackBarContainer width={props.width}>
		{datalist.map(currentData => {
			if (currentData.count === 0) return null;
			const percentage = (currentData.count / total * 100).toFixed(0);
			return <PercentStackBarItem
				key={`percentStackBarItem[${currentData.min}]`}
				percentage={percentage}
				color={currentData.color}
			/>;
		})}
	</PercentStackBarContainer>;

}

export function FrequencyChart(props: FrequencyChartProps<EventFrequency>) {
	const { datalist } = props;
	if (datalist.filter(currentData => currentData.count > 0).length === 0) return null;
	return <FrequencyChartContainer>
		<PercentStackBar datalist={datalist}/>
		<LegendListContainer>
			{datalist.map((current, index) =>
				current.count !== 0 &&
				<LegendItem
					key={`legendItem[${index}]`}
					color={current.color}
					min={current.min} max={current.max} count={current.count}
					hasBorder={index !== datalist.length - 1}
				/>
			)}
		</LegendListContainer>
	</FrequencyChartContainer>;
}
export function LegendItem(props: EventFrequency) {
	return <FlexDiv justify="space-between" width="100%" borderBottom={props.hasBorder ? `1px solid ${LightGreySidely2}` : ''} padding="3px 0px">
		<FlexDiv gap="5px" width="130px" justify="space-between">
			<CircleDiv color={props.color} radius="3px"/>
			<Ellipsis fontWeight='600'>{props.min} - {props.max > 7 ? <InfinityAjustement>∞</InfinityAjustement> : props.max}</Ellipsis>
			<FlexDiv fontSize="13px">{getEmojiByFrequencyScore(props.min)}</FlexDiv>
		</FlexDiv>
		<FlexDiv width="30px" justify="center">
			<Ellipsis fontWeight='600'>{props.count}</Ellipsis>
		</FlexDiv>
	</FlexDiv>;
}

export interface EventFrequency {
	min: number,
	max: number,
	count: number,
	color?: string,
	hasBorder?: boolean,
}
export default function EventFrequencyWidget(props: {
	value: EventFrequency[],
	item: DashboardItem,
	selected?: boolean,
	onSelect?: (selected: boolean) => void,
	setItem: (item: DashboardItem) => void,
	onCreationClicked?: () => void
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
	isHover?: boolean
}) {
	const { value, item, onCreationClicked , onClickDelete, isHover, onClickEdit } = props;
	const datalist = value.map((current) => ({ ...current, color: getAvgColorByFrequencyScore(current.min, true) }));
	const hasNoData = datalist.filter(currentData => currentData.count > 0).length === 0;

	return <>
		<WidgetTitleWrapper>
			<FlexDiv justify="space-between" align='center' width='100%'>
				<WidgetTitle>
					{item.title ?? <Translate id='frequency'/>}
				</WidgetTitle>
				<FlexDiv justify="flex-end" gap="2px">
					{onCreationClicked && <CreateButton onClickCreate={onCreationClicked} />}
					{ isHover && onClickEdit && <EditButton onClickEdit={() => onClickEdit(item)}/>}
					{ isHover && onClickDelete && <DeleteButton onClickDelete={() => onClickDelete(item.id as number)} />}
				</FlexDiv>
			</FlexDiv>
		</WidgetTitleWrapper>
		{hasNoData
			? <NoEvent/>
			: <WidgetBody small>
				<FrequencyChart datalist={datalist} />
			</WidgetBody>
		}
	</>;
}
