import magnifying_glass from 'images/icons/magnifying_glass.svg';
import add_blue from 'images/icon/add_blue.png';
import * as React from 'react';
import { ModalEventForm, ModalMode } from '../containers_v2/calendar/ModalEventForm';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getTranslate, Translate } from 'react-localize-redux';
import swal from 'sweetalert2';
import ModalExport from '../components/modal/create/ModalExportData';
import ModalImportExcel from '../components/modal/create/ModalImportExcel';
import storeLang from '../helpers/storeLang';
import { deleteEventsRefresh, exportExcel, importExcel } from './ToolBarActions';
import Restricted from '../containers_v2/permissions/Restricted';
import Dropdown from '../components_v2/dropdown/Dropdown';
import { DropdownData } from '../components_v2/dropdown/model/Model';
import { RedSidely } from '../styles/global/css/Utils';
import optionGrey from 'images/icon/options_grey.png';

export default function GenEventForm(props: { refresh: () => void }) {
	const [modalForm, setModal] = React.useState<boolean>(false);

	function toggleModal() {
		setModal(!modalForm);
	}

	const start = new Date();
	const end = new Date();
	end.setHours(end.getHours() + 1);
	return (
		(<div>
			<div
				className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle"
				onClick={toggleModal}
			>
				<img
					src={add_blue}
					className="custom-icon"
					alt=""
				/>
			</div>
			<ModalEventForm
				isOpen={modalForm}
				toggleModal={toggleModal}
				mode={ModalMode.New}
				onDeleteData={toggleModal}
				defaultStart={start}
				defaultEnd={end}
				refresh={props.refresh}
			/>
		</div>)
	);
}

export function genEventForm(props: {
  status: boolean
  props: {
    function: () => void
  }
}) {
	const [modalForm, setModal] = React.useState<boolean>(false);

	function toggleModal() {
		setModal(!modalForm);
	}

	const start = new Date();
	const end = new Date();
	end.setHours(end.getHours() + 1);
	return (
		(<div>
			<div
				className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle"
				onClick={toggleModal}
			>
				<img
					src={add_blue}
					className="custom-icon"
					alt=""
				/>
			</div>
			<ModalEventForm
				isOpen={modalForm}
				toggleModal={toggleModal}
				mode={ModalMode.New}
				onDeleteData={toggleModal}
				defaultStart={start}
				defaultEnd={end}
				refresh={props.props.function}
			/>
		</div>)
	);
}

interface dotsState {
  dropdownOptionsOpen: boolean
  modalExport: boolean
  modalImport: boolean
}

export function ToolBarDotsCalendar(props: { current, toolBarState, rows: { selectedRowsArray, selectedRows }, refresh: () => void }) {
	const { current, toolBarState, refresh } = props;
	const { selectedRowsArray, selectedRows } = props.rows;
	const translate = getTranslate(storeLang.getState().localize);
	const [importModal, setImportModal] = React.useState<boolean>(false);
	const [exportModal, setExportModal] = React.useState<boolean>(false);
	const [currState, setState] = React.useState<dotsState>(initState());

	function initState() {
		return {
			dropdownOptionsOpen: false,
			modalExport: false,
			modalImport: false
		};
	}

	function onBulkDelete(id) {
		if (id.length > 0) {
			(swal as any)
				.fire({
					title: 'Attention!',
					text: translate('Are you sure you want to delete selected data from the database?'),
					type: 'warning',
					confirmButtonText: translate('Yes, I do'),
					confirmButtonColor: RedSidely
				})
				.then(result => {
					if (result.value) {
						deleteEventsRefresh({ id }, refresh);
					}
				});
		} else {
			(swal as any).fire({
				title: 'Attention!',
				text: translate('No data selected !'),
				type: 'warning',
				showConfirmButton: false
			});
		}
	}

	function toggleOptions() {
		setState({
			...currState,
			dropdownOptionsOpen: !currState.dropdownOptionsOpen
		});
	}

	function onExport(id, type) {
		if (type === 'csv') {
			exportExcel(
				{
					id,
					incharge: toolBarState.userId,
					groupId: toolBarState.groupId,
					eventTypeId: toolBarState.typeId,
					eventStatusId: toolBarState.statusId,
					periode: toolBarState.periode
				},
				'exportCsv',
				'Events.csv'
			);
		} else {
			exportExcel(
				{
					id,
					incharge: toolBarState.userId,
					groupId: toolBarState.groupId,
					eventTypeId: toolBarState.typeId,
					eventStatusId: toolBarState.statusId,
					periode: toolBarState.periode
				},
				'exportExcel',
				'Event.xlsx'
			);
		}
	}

	if (toolBarState.view === 'calendar') {
		return (<></>);
	}

	return (
		<>
			<div className="my-auto mx-2">
				<ButtonDropdown
					className="border-transparent"
					isOpen={currState.dropdownOptionsOpen}
					toggle={toggleOptions}
				>
					<DropdownToggle className="bg-white p-0">
						<img
							src={optionGrey}
							className="custom-icon"
							alt=""
						/>
					</DropdownToggle>
					<DropdownMenu className="custom-position-v2">
						<Restricted to={{ objectAction: 'CreateImport' }}>
							<DropdownItem onClick={() => setImportModal(!importModal)}>
								<Translate id="Import event" />
							</DropdownItem>
						</Restricted>
						{toolBarState.view !== 'calendar' && (
							<Restricted to={{ objectAction: 'CreateExport' }}>
								<DropdownItem onClick={() => setExportModal(!exportModal)}>
									<Translate id="Export event" />
								</DropdownItem>
							</Restricted>
						)}
						{toolBarState.view !== 'calendar' && (
							<DropdownItem
								onClick={() => {
									onBulkDelete(selectedRowsArray);
								}}
							>
								<Translate id="delete_selection" />
							</DropdownItem>
						)}
					</DropdownMenu>
				</ButtonDropdown>
			</div>
			{importModal && <ModalImportExcel
				isOpen={importModal}
				toggle={() => setImportModal(!importModal)}
				onImport={importExcel}
			/>}
			{exportModal && <ModalExport
				isOpen={exportModal}
				toggle={() => setExportModal(!exportModal)}
				dataExport={selectedRows}
				onExport={onExport}
			/>}
		</>
	);
}

export function ToolBarDotsReports(props: { current, onFilter: (t) => void, startingColumns, export: true | false | number | { status: boolean | number, props: any } | undefined }) {
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [modalListOpen, setModalListOpen] = React.useState<boolean>(false);
	const [name, setName] = React.useState('');
	const [value, setValue] = React.useState<any[]>([]);
	const [options, setOptions] = React.useState<any[]>([]);
	const translate = getTranslate(storeLang.getState().localize);

	React.useEffect(() => {
		let ret;
		if (props.startingColumns.length > 0 && props.startingColumns[0].columns) {
			ret = props.startingColumns
				.slice(1)
				.map(e => e.columns)
				.reduce((acc, e) => acc.concat(e), [])
				.map((e, i) => {
					return {
						...e,
						selected: true,
						value: i
					};
				});
		} else {
			ret = props.startingColumns
				.filter((e, i) => i !== 0 && i !== 1)
				.map((e, i) => {
					return {
						...e,
						selected: true,
						value: i
					};
				});
		}
		setValue([control(true), ...ret]);
		setOptions([control(true), ...ret]);
		props.onFilter({ columns: props.startingColumns });
	}, [props.startingColumns]);

	const control = (selected) => {
		return {
			value: -1,
			Header: selected ? translate('none') : translate('all'),
			selected
		};
	};

	function toggleOpen() {
		setOpen(!isOpen);
	}

	function toggleModal() {
		setModalListOpen(!modalListOpen);
	}

	const handleFilter = (e) => {
		setName(e.target.value);
		setValue(filterList(options, e.target.value));
	};

	function handleChecked(isChecked: boolean, input) {
		let newOptions = options;
		let controlSelect = options[0].selected;
		if (input.value == -1 && isChecked) {
			newOptions = newOptions.map(e => { return { ...e, selected: true }; });
			newOptions[0].Header = translate('tool_bar.dots.none');
			controlSelect = !controlSelect;
		} else if (input.value == -1) {
			newOptions = newOptions.map(e => { return { ...e, selected: false }; });
			controlSelect = !controlSelect;
			newOptions[0].Header = translate('tool_bar.dots.all');
		} else {
			newOptions.forEach(option => {
				if (option.value == input.value) {
					option.selected = isChecked;
					if (isChecked && (options.filter(e => e.selected == false).length == 1)) {
						options[0].selected = true;
						options[0].Header = translate('tool_bar.dots.none');
					} else if (!isChecked) {
						options[0].selected = false;
						options[0].Header = translate('tool_bar.dots.all');
					}
				}
			});
		}
		setValue(filterList(newOptions, name));
		setOptions(newOptions);
		if (props.startingColumns[0].columns) {
			const ret: any[] = props.startingColumns.map(e => {
				return { ...e, columns: e.columns.slice(0) };
			});
			ret.forEach((element, i) => {
				if (i !== 0) {
					const columns = element.columns.filter(e => {
						const index = newOptions.findIndex(x => x.Header === e.Header);
						return (index >= 0 && newOptions[index].selected);
					});
					element.columns = columns;
				}
			});
			props.onFilter({ columns: ret.filter(e => e.columns.length > 0) });
		} else {
			const ret = props.startingColumns.filter((e, i) => {
				const index = newOptions.findIndex(x => x.Header === e.Header);
				return ((i == 0 || i == 1) || (index >= 0 && newOptions[index].selected));
			});
			props.onFilter({ columns: ret });
		}
	}

	const list = [{ label: translate('tool_bar.dots.select_displayed_columns').toString(), value: 1 }];
	if (props.export) {
		list.push({ label: translate('tool_bar.dots.export').toString(), value: 2 });
	}

	return (
		<div>
			<Dropdown
				datalist={list}
				name='dropdown_actions'
				readOnly
				JSXButton={() => (
					<img
						src={optionGrey}
						className="custom-icon"
						alt=""
					/>
				)}
				dropdownStyle={{
					optionWidth: '200px',
					optionLeft: '-175px'
				}}
				onChange={(value: DropdownData) => {
					if (value.value === 1) {
						toggleModal();
					} else if (value.value === 2) {
						props.onFilter({ export: true });
					}
				}}
			/>
			<SearchAndCheck
				isOpen={modalListOpen}
				toggleModal={toggleModal}
				name={name}
				handleFilter={handleFilter}
				value={value}
				handleChecked={handleChecked}
			/>
		</div >
	);
}

function filterList(list, filter) {
	if (filter !== '') {
		const results = list.filter((element) => {
			return element.Header.toLowerCase().startsWith(filter.toLowerCase());
		});
		return results;
	} else {
		return list;
	}
}

function SearchAndCheck(props: { isOpen: boolean, toggleModal: () => void, name: string, handleFilter: (e: any) => void, value: any[], handleChecked: (isChecked: boolean, input: any) => void
}) {
	const translate = getTranslate(storeLang.getState().localize);
	const {
		isOpen,
		toggleModal,
		name,
		handleFilter,
		value,
		handleChecked
	} = props;
	return (
		(<Modal
			isOpen={isOpen}
			toggle={toggleModal}
			style={{ width: '600px', fontSize: '14px' }}
			centered
		>
			<ModalHeader
				// toggle={this.props.toggle}
				// className="modal-map-header"
				// style={{ borderBottom: 'none', backgroundColor: 'blue' }}
			>
				<Translate id="select columns to display" />
			</ModalHeader>
			<ModalBody style={{
				width: '600px',
				paddingLeft: '130px'
			}} >
				<div style={{
					background: '#FFFFFF',
					border: '1px solid rgba(158, 172, 192, 0.23)',
					boxSizing: 'border-box',
					borderRadius: '4px',
					height: '38px',
					width: '350px'
				}}>
					<img src={magnifying_glass} style={{ marginLeft: '10px', height: '18px' }} />
					<input
						type="search"
						value={name}
						onChange={handleFilter}
						className="input"
						placeholder={(translate('tool_bar.dots.filter') as string)}
						style={{ border: 'none', paddingTop: '6px', marginLeft: '2px', width: '310px' }}
					/>
				</div>
				<div className="check-sidely" style={{
					marginTop: '10px',
					height: '500px',
					width: '400px',
					overflowY: 'auto',
					overflowX: 'hidden'
				}}>
					{value && value.length > 0
						? (
							value.map((option, i) => (
								<label className="d-block" key={i}>
									<input
										name={`ToolBarProductsSelect[${i}]`}
										id={`ToolBarProductsSelect[${i}]`}
										type="checkbox"
										value={option.value}
										checked={option.selected}
										onChange={e =>
											handleChecked(e.target.checked, option)
										}
									/>
									<div className="checkbox-style top-2" style={{ top: '4px' }} />
									<span className="d-block w-100 ml-4">{option.Header}</span>
								</label>
							))
						)
						: (
							<Translate id="No results found" />
						)}
				</div>
			</ModalBody>
			<ModalFooter style={{ borderTop: 'none' }}>
				<Button
					color="primary"
					className="px-5 mb-2"
					onClick={toggleModal}
				>
					{translate('tool_bar.dots.validate')}
				</Button>
			</ModalFooter>
		</Modal>)
	);
}
