import * as React from 'react';
import styled from 'styled-components';
import { FlexDiv } from '../../containers_v2/products/style';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor } from '../../styles/global/css/Utils';

const LeftColor = styled.div<{ selected: boolean }>`
    width: 10px;
    background-color: ${p => p.selected ? BlueSidely : 'none'};
    height: 100%;
`;
const ChildrenContainer = styled.div<{ selected: boolean }>`
    ${DefaultText}
    margin-left: 10px;
    width: calc(100% - 10px);
    color: ${p => p.selected ? BlueSidely : 'none'};
`;

export default function ListPicker(props: {
  selected: boolean
  onSelect?: () => void
  value: string | JSX.Element
}): JSX.Element {
	return <FlexDiv
		height="50px"
		onClick={() => props.onSelect?.()}
		clickable
		width="100%"
		borderTop={`1px solid ${BorderColor}`}
		borderBottom={`1px solid ${BorderColor}`}
		margin={'auto auto -1px auto'}
	>
		<LeftColor selected={props.selected} />
		<ChildrenContainer selected={props.selected}>
			{props.value}
		</ChildrenContainer>
	</FlexDiv>;
}
