import {
	CREATE_DOCUMENT_ERROR,
	CREATE_DOCUMENT_REQUEST,
	CREATE_DOCUMENT_SUCCESS,
	CREATE_EVENT_ERROR,
	CREATE_EVENT_REQUEST,
	CREATE_EVENT_SUCCESS,
	CREATE_NOTE_ERROR,
	CREATE_NOTE_REQUEST,
	CREATE_NOTE_SUCCESS,
	CREATE_OPPORTUNITIES_ERROR,
	CREATE_OPPORTUNITIES_REQUEST,
	CREATE_OPPORTUNITIES_SUCCESS,
	DEFAULT_FILTER,
	DETAIL_OPPORTUNITIES_ERROR,
	DETAIL_OPPORTUNITIES_REQUEST,
	DETAIL_OPPORTUNITIES_SUCCESS,
	FETCH_OPPORTUNITIES_ERROR,
	FETCH_OPPORTUNITIES_REQUEST,
	FETCH_OPPORTUNITIES_STATUS_ERROR,
	FETCH_OPPORTUNITIES_STATUS_REQUEST,
	FETCH_OPPORTUNITIES_STATUS_SUCCESS,
	FETCH_OPPORTUNITIES_SUCCESS,
	FILTER_DATA,
	GET_DOCUMENTS_ERROR,
	GET_DOCUMENTS_REQUEST,
	GET_DOCUMENTS_SUCCESS,
	GET_EVENT_DETAIL_ERROR,
	GET_EVENT_DETAIL_REQUEST,
	GET_EVENT_DETAIL_SUCCESS,
	GET_EVENTS_ERROR,
	GET_EVENTS_REQUEST,
	GET_EVENTS_SUCCESS,
	GET_NOTE_DETAIL_ERROR,
	GET_NOTE_DETAIL_REQUEST,
	GET_NOTE_DETAIL_SUCCESS,
	GET_NOTES_ERROR,
	GET_NOTES_REQUEST,
	GET_NOTES_SUCCESS,
	IMPORT_EXCEL_ERROR,
	IMPORT_EXCEL_REQUEST,
	IMPORT_EXCEL_SUCCESS,
	NO_PAGINATION_OPPORTUNITIES_ERROR,
	NO_PAGINATION_OPPORTUNITIES_REQUEST,
	NO_PAGINATION_OPPORTUNITIES_SUCCESS,
	SEARCH_DATA,
	SET_INITIAL_FILTER,
	SET_STATUS_FILTER,
	SORT_DATA,
	UPDATE_OPPORTUNITIES_ERROR,
	UPDATE_OPPORTUNITIES_REQUEST,
	UPDATE_OPPORTUNITIES_SUCCESS
} from '../actions/opportunities.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	isLoadingKanban: false,
	current: null,
	data: [],
	empty: [],
	dataNoPagination: [],
	dataDetail: [],
	detail: [],
	status: [],
	initFilter: null,
	filter: null,
	defaultFilter: true,
	sortField: null,
	sort: 'desc',
	statusFilter: null,
	activeFilter: {
		opportunityStatusId: null,
		clientCompanyId: null
	},
	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	errorValidation: [],

	// LINKED ELEMENTS
	isLoadingNotes: false,
	isLoadingDocuments: false,
	orders: [],
	events: [],
	notes: [],
	documents: [],
	salesOperations: [],
	search: null,
	noteDetail: [],
	eventDetail: [],

	paginationEvents: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	paginationNotes: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	paginationDocuments: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	}
};

export default function ContactsReducer(state = initState, action) {
	switch (action.type) {
		case CREATE_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_OPPORTUNITIES_ERROR:
			return {
				...state,
				isLoading: false
			};

		case FETCH_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_OPPORTUNITIES_ERROR:
			return {
				...state,
				data: [],
				isLoading: false
			};

		case UPDATE_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoading: false
			};

		case UPDATE_OPPORTUNITIES_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DETAIL_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_OPPORTUNITIES_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case FETCH_OPPORTUNITIES_STATUS_REQUEST:
			return {
				...state,
				// isLoading: true
				isLoadingKanban: true
			};
		case FETCH_OPPORTUNITIES_STATUS_SUCCESS:
			return {
				...state,
				status: action.payload.data ? action.payload.data : ['lala', 'po'],
				isLoadingKanban: false
			};
		case FETCH_OPPORTUNITIES_STATUS_ERROR:
			return {
				...state,
				isLoadingKanban: false
			};

		case NO_PAGINATION_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case NO_PAGINATION_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				dataNoPagination: action.payload.data ? action.payload.data : [],
				isLoading: false
			};
		case NO_PAGINATION_OPPORTUNITIES_ERROR:
			return {
				...state,
				dataNoPagination: action.error || [],
				isLoading: false
			};

		case SET_INITIAL_FILTER:
			return {
				...state,
				initFilter: action.payload
			};

		case FILTER_DATA:
			return {
				...state,
				filter: action.payload
			};

		case DEFAULT_FILTER:
			return {
				...state,
				defaultFilter: action.payload
			};

		case SORT_DATA:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};

		case IMPORT_EXCEL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case IMPORT_EXCEL_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case IMPORT_EXCEL_ERROR:
			return {
				...state,
				errorValidation: action.error.validation,
				isLoading: false
			};

		// CREATE LINKED ELEMENT
		case CREATE_EVENT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_EVENT_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_EVENT_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_NOTE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_NOTE_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_NOTE_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_DOCUMENT_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_DOCUMENT_ERROR:
			return {
				...state,
				isLoading: false
			};

		// GET LINKED ELEMENT
		case GET_NOTES_REQUEST:
			return {
				...state,
				isLoadingNotes: true
			};

		case GET_NOTES_SUCCESS:
			return {
				...state,
				notes: action.payload.data || [],
				paginationNotes: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingNotes: false
			};

		case GET_NOTES_ERROR:
			return {
				...state,
				isLoadingNotes: false
			};

		case GET_NOTE_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_NOTE_DETAIL_SUCCESS:
			return {
				...state,
				noteDetail: action.payload.data,
				isLoading: false
			};
		case GET_NOTE_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_EVENTS_REQUEST:
			return {
				...state,
				isLoadingEvents: true
			};
		case GET_EVENTS_SUCCESS:
			return {
				...state,
				events: action.payload.data || [],
				paginationEvents: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingEvents: false
			};
		case GET_EVENTS_ERROR:
			return {
				...state,
				isLoadingNotes: false
			};

		case GET_EVENT_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_EVENT_DETAIL_SUCCESS:
			return {
				...state,
				eventDetail: action.payload.data,
				isLoading: false
			};
		case GET_EVENT_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_DOCUMENTS_REQUEST:
			return {
				...state,
				isLoadingDocuments: true
			};
		case GET_DOCUMENTS_SUCCESS:
			return {
				...state,
				documents: action.payload.data || [],
				paginationDocuments: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingDocuments: false
			};
		case GET_DOCUMENTS_ERROR:
			return {
				...state,
				isLoadingDocuments: false
			};

		case SET_STATUS_FILTER:
			return {
				...state,
				activeFilter: action.payload
			};
		case SEARCH_DATA:
			return {
				...state,
				search: action.payload
			};

		default:
			return state;
	}
}
