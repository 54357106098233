import axios from 'axios';
import { Cookies } from 'react-cookie';
import { FilterTree } from '../../../components_v2/filter/model/Model';
import { URL_FOUNDATION } from '../../../config/keys';
import { Promotion } from '../../promotions/model';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

type BasicBulkEditBody = {
  companies?: number[]
  new_filters?: FilterTree
  all: boolean
  search?: string
}

type BulkEditOwnerBody = BasicBulkEditBody & {
  owner_id: number
}

export async function bulkEditOwner(body: BulkEditOwnerBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/owner`, body);
}

type BulkEditTagsBody = BasicBulkEditBody & {
  tags: number[]
  replace: boolean
  replaced_tags?: number[]
}

export async function bulkEditTags(body: BulkEditTagsBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/tags`, body);
}

type BulkEditPromotionBody = BasicBulkEditBody & {
  promotions: number[]
  replace: boolean
  replaced_promotions?: number[]
}

export async function bulkEditPromotions(body: BulkEditPromotionBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/promotions`, body);
}
type BulkEditTemplateBody = BasicBulkEditBody & {
  templates: number[]
  replace: boolean
  replaced_templates?: number[]
  filters?: FilterTree
}
export async function bulkEditShelfAuditTemplates(body: BulkEditTemplateBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/shelf-audit-templates`, body);
}

type BulkEditParentBody = BasicBulkEditBody & {
  filters?: FilterTree,
  new_filters?: never,
  new_parent: number
}

export async function bulkEditParent(body: BulkEditParentBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/parent`, body);
}

type BulkEditFrequencyTargetBody = BasicBulkEditBody & {
  new_filters?: never,
  filters?: FilterTree,
  frequency: {
    frequency: number,
    event_type: number
  }
}

export async function bulkEditFrequency(body: BulkEditFrequencyTargetBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/frequency-target`, body);

}

type BulkEditAdditionalColumnsBody = Omit<BasicBulkEditBody, 'new_filters'> & {
  filters?: FilterTree,
  value: any
}

export async function bulkEditAdditionalColumns(id: number, body: BulkEditAdditionalColumnsBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/additional-columns/${id}`,body);
}

type BulkEditStatusesBody = BasicBulkEditBody & {
  status: number
}

export async function bulkEditStatuses(body: BulkEditStatusesBody) {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/status`, body);
}
type EnrichcompaniesBody = BasicBulkEditBody & {
  replace: boolean
}

export type RejectedValueItem = {
  title: string,
  id: string,
  resultType: string,
  houseNumberType: string,
  address: {
    label: string,
    countryCode: string,
    countryName: string,
    stateCode: string,
    state: string,
    county: string,
    city: string,
    disctrict: string,
    street: string,
    postalCode: string,
    houseNumber: string
  },
  position: {
    lat: number,
    lng: number
  },
  access: object[],
  scoring?: {
    queryScore: number,
    fieldScore: object
  }
}

type GeoCompany = {
    name: string,
    id: number,
    billing_address?: string,
    billing_post_code?: string,
    billing_city?: string,
    billing_country?: string,
    latitude?: number,
    longitude?: number
}

export type RejectedValue = {
  company: GeoCompany,
  items?: RejectedValueItem[],
  updated?: boolean
}

export async function enrichCompanies(body: EnrichcompaniesBody): Promise<{ changed: number, rejected: number, rejected_values: RejectedValue[] }> {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/bulk-edit/geo`, body).then(res => ({
		...res.data,
		rejected_values: res.data.rejected_values?.map(([company, value]) => ({ company, items: value?.items })) ?? []
	}));
}

interface EventEdit {
  status_id?: number
  type_id?: number
  incharge?: number
  company_id?: number
  start?: string
  end?: string
}

export async function updateEvent(id: number, body: EventEdit): Promise<void> {
	return await axios.put(`${URL_FOUNDATION}/api/v2/events/${id}`, body);
}

export async function updateParentCompany(companyId: number, parent_company_id: number) {
	return await axios.put(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/parent`, { parent_company_id });
}

export async function deleteParentCompany(companyId: number) {
	return await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/parent`);
}

export async function getCompanyPromotions(companyId: number): Promise<Promotion[]> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/promotions`).then(res => res.data);
}

export async function deleteCompanyPromotion(companyId: number, promotionId: number): Promise<void> {
	return await axios.delete(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/promotions/${promotionId}`).then(res => res.data);
}

export async function postCompanyPromotion(companyId: number, promotionId: number): Promise<void> {
	return await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/promotions/${promotionId}`).then(res => res.data);
}

export type LatestShelfAuditPerCompany = {
  products: number[],
  shelf_audit_template_id: number,
  shelf_audit_template_name: string,
  shelf_audit_instance_id: number,
  dn: number,
  date: string,
  count: number,
  product_count: number
}

export async function getCompanyLastShelfAudits(companyId: number): Promise<LatestShelfAuditPerCompany[]> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/last-shelf-audit`).then(res => res.data);
}

type CheckoutEvolution = {
	evolution: number,
	current_sum: number,
	previous_sum: number,
  date: string,
  current_products_number: number,
  previous_products_number: number,
  product_number_evolution: number,
  product_count: number,
  consumer_sales_unit?: number,
  consumer_sales_unit_evolution?: number

}
export type CheckoutEvolutionsPerGranularity = {
	month: CheckoutEvolution,
	quarter: CheckoutEvolution,
	year: CheckoutEvolution,
	last_year?: CheckoutEvolution
}

export async function getCompanyLastCheckouts(companyId: number): Promise<CheckoutEvolutionsPerGranularity | undefined> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/client-companies/${companyId}/last-checkouts`).then(res => res.data);
}
