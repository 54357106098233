import * as React from 'react';
import { useEffect, useState } from 'react';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { translateToString } from '../../styles/global/translate';
import { getShelfAuditTemplates } from './actions';
import { Table, TableSortType } from '../../components_v2/table/Table';
import { ShelfAuditTemplate } from './model/models';
import { Link, TABLE_IMAGE_PADDING } from '../products/style';
import noImage from 'images/no-image.jpg';
import { Translate } from 'react-localize-redux';
import { TableRow, TableRowTitle } from '../orders/templateOrders/style/Style';
import { useHistory } from 'react-router';
import * as moment from 'moment';
import add_blue from 'images/icon/add_blue.png';
import ModalTemplate from '../../containers/enrichedForm/components/ModalForm';
import Pagination from '../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { DETAIL_TEMPLATE_REQUEST, DETAIL_TEMPLATE_SUCCESS, SET_MODAL } from '../../store/actions/enForm.action';
import { useDispatch, useStore } from 'react-redux';
import axios from 'axios';
import { URL_API_BUILDER } from '../../config/keys';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import Restricted from '../permissions/Restricted';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import optionGrey from 'images/icon/options_grey.png';
import system_setting_icon from 'images/setting_icons/system_setting_icon.svg';
import PermissionContext from '../permissions/PermissionContext';
import useAlert from '../alert/UseAlert';
import { AlertRes } from '../alert/AlertProvider';

const DEFAULT_STEP = 25;
const DEFAULT_OFFSET = 0;
enum QUICK_ACTION {
	Update = 1,
	Duplicate,
	Delete,
}
function ShelfAudits(props: ComponentProps) {
	const { setToolBarState } = props;
	const [shelfAuditTemplates, setShelfAuditTemplates] = useState<ShelfAuditTemplate[]>([]);
	const [sort, setSort] = useState<TableSortType | undefined>({ id: 'created_at', desc: true });
	const [pagination, setPagination] = React.useState<PaginationResult | undefined>(undefined);
	const { isAllowedTo } = React.useContext(PermissionContext);

	const [_modal, set_Modal] = useState<{ status?: string, isOpen: boolean }>({ status: undefined, isOpen: false });
	const history = useHistory();
	const store = useStore();
	const dispatch = useDispatch();
	const modal = store.getState().enForm?.modal;
	const alert = useAlert();

	function handleClickShelfAuditTemplate(id: number) {
		history.push('/shelf-audits/' + id);
	}

	const columns = [
		{
			id: 'icon',
			Header: 'Icon',
			accessor: (row: ShelfAuditTemplate) => <Link
				onClick={() => handleClickShelfAuditTemplate(row.id)}
				background={row.icon ?? noImage}
			/>,
			width: 75,
			padding: TABLE_IMAGE_PADDING.DEFAULT,
		},
		{
			id: 'name',
			Header: <Translate id='shelf_audit.template.name'/>,
			accessor: (row: ShelfAuditTemplate) => <TableRowTitle
				onClick={() => handleClickShelfAuditTemplate(row.id)}
			>{row.name}</TableRowTitle>,
			width: undefined,
		},
		{
			id: 'assortment_name',
			Header: <Translate id="shelf_audit.template.assortment_name"/>,
			accessor: (row: ShelfAuditTemplate) =>
				<TableRow>{row.assortment_name}</TableRow>,
			width: undefined,
		},
		{
			id: 'created_at',
			Header: <Translate id="shelf_audit.template.created_at"/>,
			accessor: (row: ShelfAuditTemplate) =>
				<TableRow>{moment.utc(row.created_at).format('L')}</TableRow>,
			width: undefined,
		},
		{
			id: 'updated_at',
			Header: <Translate id="shelf_audit.template.updated_at"/>,
			accessor: (row: ShelfAuditTemplate) =>
				<TableRow>{moment.utc(row.updated_at).format('L')}</TableRow>,
			width: undefined,
		},
		{
			id: 'amount_shelf_audit',
			Header: <Translate id="shelf_audit.template.amount_shelf_audit"/>,
			accessor: (row: ShelfAuditTemplate) =>
				<TableRow>{row.amount_shelf_audit}</TableRow>,
			width: undefined,
		},
		{
			id: 'last_shelf_audit_date',
			Header: <Translate id="shelf_audit.template.last_shelf_audit"/>,
			accessor: (row: ShelfAuditTemplate) =>
				<TableRow>{row.last_shelf_audit_date ? moment.utc(row.last_shelf_audit_date).format('L') : '-'}</TableRow>,
			width: undefined,
		},
		{

			id: 'quick_actions',
			noHeaderEllipsis: true,
			Header: <img src={system_setting_icon} style={{ width: 18 }} alt="quick_actions"/>,
			accessor: row => {
				const list: DropdownData<QUICK_ACTION>[] = [];

				if (isAllowedTo({ objectAction:'UpdateShelfAudit' }) && isAllowedTo({ objectAction:'UpdateCompanySettings' }))
					list.push({
					//FIXME: to modify `modifcation` to `modification` on fr.json and en.json
						label: translateToString('global.quick_action.modication').toString(),
						value: QUICK_ACTION.Update
					});

				if (isAllowedTo({ objectAction:'CreateShelfAudit' }) && isAllowedTo({ objectAction:'CreateCompanySettings' }))
					list.push(
						{
							label: translateToString('global.quick_action.duplicate').toString(),
							value:QUICK_ACTION.Duplicate
						});
				if (isAllowedTo({ objectAction:'DeleteShelfAudit' }) && isAllowedTo({ objectAction:'DeleteCompanySettings' }))
					list.push(
						{
							label: translateToString('global.quick_action.delete').toString(),
							value:QUICK_ACTION.Delete
						});
				if (list.length == 0) return;
				return (
					<Dropdown
						name='quick_action'
						datalist={list}
						readOnly
						dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-112px', containerTop: '-6px' }}
						JSXButton={() =>
							<img
								alt="modification"
								src={optionGrey}
								width={25}
								height={25}
								style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }}
							/>
						}
						onChange={(current) => {
							if (current.value === QUICK_ACTION.Update || current.value === QUICK_ACTION.Duplicate) {
								const status = current.value === QUICK_ACTION.Update ? 'edit' : 'duplicate';
								dispatch({ type: DETAIL_TEMPLATE_REQUEST, loading: true });
								axios({
									method: 'get',
									url: `${URL_API_BUILDER}/v1/api/shelfAuditTemplate/id/${row.id}`
								}).then(response => {
									dispatch({
										type: DETAIL_TEMPLATE_SUCCESS,
										payload: response.data
									});
									set_Modal({ status, isOpen:true });
									dispatch({
										type: SET_MODAL,
										payload: { status, isOpen:true }
									});
								});
							} else if (current.value === QUICK_ACTION.Delete) {
								dispatch({ type: DETAIL_TEMPLATE_REQUEST, loading: true });

								alert({
									title:translateToString('Attention'),
									mode: 'delete',
									content:translateToString('do_you_want_to_delete_the_target', [['target', translateToString('form_template')]])
								}).then(res => {
									if (res === AlertRes.Ok)
										axios({
											method:'delete',
											url:`${URL_API_BUILDER}/v1/api/shelfAuditTemplate/id/${row.id}`
										}).then(_ => window.location.href = '/shelf-audits');
								});
							}
						}}
					/>
				);
			},
			width: 40,
			minWidth: 40,
			disableSortBy: true,
			disableFilter: true,
			unresizeable: true,
		},
	];
	useEffect(() => {
		setToolBarState({
			title: translateToString('shelf_audits'),
			bottomRightToolbarComponent:
				<Restricted to={{ objectAction:'CreateShelfAudit' }}>
					<Restricted to={{ objectAction:'CreateCompanySettings' }}>
						<div className="my-auto mx-1">
							<div
								className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle"
								onClick={() => {
									//FIXME: if use only new page, save this argment on localStorage wouldn't be util...
									localStorage.setItem('from_new_shelf_audit', 'true');
									dispatch({
										type: SET_MODAL,
										payload: { status:'create', isOpen:true }
									});
									set_Modal({ status: 'create', isOpen: true });
								}}
							>
								<img
									src={add_blue}
									className="custom-icon"
									alt=""
								/>
							</div>
						</div>
					</Restricted>
				</Restricted>
		});
		getShelfAuditTemplates({
			limit: pagination?.step ?? DEFAULT_STEP,
			offset: pagination?.offset ?? 0,
			order_by: sort?.id,
			descending: sort?.desc,
		}).then(setShelfAuditTemplates);
	}, [pagination, sort]);
	return <>
		<Table
			onSort={(sort: TableSortType) => setSort(sort[0])}
			initialSortBy={sort}
			columns={columns}
			data={shelfAuditTemplates}
			height="calc(100vh - 190px)"
		/>
		<Pagination
			label={translateToString('shelf_audit.template.beta')}
			amount={Number(shelfAuditTemplates[0]?.count ?? DEFAULT_OFFSET)}
			currentCount={shelfAuditTemplates.length}
			steps={[DEFAULT_STEP, 50, 100]}
			onChange={setPagination}
		/>
		<ModalTemplate
			isOpen={_modal.isOpen}
			toggle={() => {
				dispatch({
					type: DETAIL_TEMPLATE_SUCCESS,
					payload: { status:'create', isOpen:true, data:{ name:'' } }
				});
				set_Modal({ ...modal, isOpen: false });
			}}
		/>
	</>;
}
export default ShelfAudits;