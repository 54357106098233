import { get } from 'lodash';
import convertIcon from './convertIcon';
import convertImg from './convertImg';
import convertType from './convertType';

const elementGetter = (item, type) => {
	const data = {
		key: convertType(item),
		name: item.content,
		field_type: item.field_type,
		static: true,
		content: item.content,
		accepted_values: get(item, 'details.accepted_value'),
		allow_multiple_pictures: get(item, 'details.allow_multiple_pictures'),
		allow_picture_upload: get(item, 'details.allow_picture_upload'),
		default_image_id: get(item, 'details.default_image_id'),
		default_image_src: '',
		details: item.details,
		element: convertType(item),
		field_name: item.field_name,
		href: undefined,
		icon: convertIcon(item),
		img: convertImg(item),
		id: item.id,
		initial_value: get(item, 'details.initial_value'),
		is_default_checked: get(item, 'details.is_default_checked'),
		is_default_user: get(item, 'details.is_default_user'),
		is_default_user_name: get(item, 'details.is_default_user_name'),
		is_default_value_today: get(item, 'details.is_default_value_today'),
		is_display_field_name: item.is_display_field_name,
		is_end_section: item.is_end_section,
		is_end_table: item.is_end_table,
		is_multiple_value: item.is_multiple_value,
		is_required: item.is_required,
		is_start_section: item.is_start_section,
		is_start_table: item.is_start_section,
		link: get(item, 'details.link'),
		max: get(item, 'details.max'),
		min: get(item, 'details.min'),
		ordering: item.ordering,
		required: item.is_required,
		showDescription: undefined,
		system_field_name: item.system_field_name,
		disabled: type === 'edit' && item.field_filled_value_count > 0, // for disabled element
		text: convertType(item)
	};

	return data;
};

export default elementGetter;
