import styled from 'styled-components';

interface CardPhotoProps {
  isExtend: boolean
}

const CardPhoto = styled.div<CardPhotoProps>`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: ${p => p.isExtend ? '15%' : '30%'};
    max-height: ${p => p.isExtend ? '30%' : '60%'};
    margin: 1px 13px 1px 1px;
`;

const PhotoImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
`;

export {
	CardPhoto,
	PhotoImage
};
