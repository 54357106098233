/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React, {Component} from 'react';
import {Translate} from 'react-localize-redux'

class PropFreeText extends Component {
    state = {};

    render() {
        const { element, updateElement, editElementProp, isEdit } = this.props;

        return (
            <div>
                <div className="form-group">
                    <label className="control-label" htmlFor="">
                        Validation
                    </label>
                </div>
                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input
                            id="is-required"
                            className="custom-control-input"
                            type="checkbox"
                            disabled={isEdit}
                            defaultChecked={this.props.element.mandatory}
                            onBlur={this.props.updateElement.bind(this)}
                            onChange={e =>
                                this.props.editElementProp(
                                    'mandatory',
                                    'checked',
                                    e.target.checked
                                )}
                        />
                        <label className="custom-control-label" htmlFor="is-required">
                            <Translate id="Field required" />
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default PropFreeText;
