import React, {Component} from 'react';
import moment from 'moment';

// import ComponentHeader from './ComponentHeader';

class Date extends Component {
  state = {};

  render() {
    const { data } = this.props;
    const defaultValue =
      data.is_default_value_today && moment().format('YYYY-MM-DD');

    return (
      <div className="form-group  mt-3">
        <label className="control-label" htmlFor="">
          {data.is_required && <span className="text-danger">* </span>}
          {data.is_display_field_name && data.field_name}
        </label>
        <input
          type="date"
          className="form-control"
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}

export default Date;
