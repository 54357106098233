import userGroupImage from 'images/icons/userGroup.svg';
import React, { Component, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
	Button,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	InputGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	UncontrolledButtonDropdown
} from 'reactstrap';
// import SelectInCharge from '../../../components/select/SelectInCharge';
import { Translate } from 'react-localize-redux';
import SelectInCharge from '../../../components/select/SelectInCharge';
// import SelectGroup from '../../../components/select/SelectGroup';
import SelectGroup from '../../../components/select/SelectGroupAuth';

// eslint-disable-next-line react/prefer-stateless-function
class SelectUser extends Component {
	state = {
		modalUser: false,
		modalGroup: false,
		selected: <Translate id="all" />
	};

	componentDidMount() {
		const { current } = this.props;

		if (current != null) {
			if (current.role.slug == 'manager') {
				this.setState({
					selected: 'Specific Group'
				});
			} else if (current.role.slug == 'sales') {
				this.setState({
					selected: <Translate id="me" />
				});
			} else {
				this.setState({ selected: <Translate id="all" /> });
			}
		}
	}

	componentWillUpdate(nextProps) {
		const { current } = nextProps;

		if (nextProps.current != null) {
			if (nextProps.current != this.props.current) {
				if (current.role.slug == 'manager') {
					this.setState({
						selected: 'Specific Group'
					});
				} else if (current.role.slug == 'sales') {
					this.setState({
						selected: <Translate id="me" />
					});
				} else {
					this.setState({ selected: <Translate id="all" /> });
				}
			}
		}
	}

	toggleUser = () => {
		this.setState({
			modalUser: !this.state.modalUser
		});
	};

	handleCurrentUser = () => {
		const { onFilter, current } = this.props;
		onFilter({ user_id: current.id });
		this.setState({
			selected: <Translate id="me" />
		});
	};

	toggleGroup = () => {
		this.setState({
			modalGroup: !this.state.modalGroup
		});
	};

	handleFilterUser = () => {
		this.setState({
			modalUser: true
		});
	};

	handleFilterGroup = () => {
		this.setState({
			modalGroup: true
		});
	};

	handleSpecificUser = val => {
		const { onFilter } = this.props;
		this.toggleUser();
		onFilter({ user_id: val.userId.value });
		if (val.userId.label.props.children[3] !== undefined) {
			this.setState({
				selected: (
					<div>
						{' '}
						<img
							src={val.userId.label.props.children[1].props.src}
							alt=""
							className="img-xs rounded-circle mr-2"
						/>{' '}
						{`${
							val.userId.label.props.children[3].length > 10
								? `${val.userId.label.props.children[3].substring(0, 10)}..`
								: val.userId.label.props.children[3]
						}`}
					</div>
				)
			});
		}
	};

	handleSpecificGroup = val => {
		const { handleFilterGroup } = this.props;
		this.toggleGroup();
		handleFilterGroup({ groupId: val.groupId.value });
		if (val.groupId.label !== undefined) {
			this.setState({
				selected:
          val.groupId.label.length > 15
          	? `${val.groupId.label.substring(0, 15)}..`
          	: val.groupId.label
			});
		}
	};

	render() {
		const { handleSubmit, handleSelected, onFilter, current } = this.props;
		const { selected } = this.state;

		return (
			(<div className="form-group row m-0">
				<InputGroup className="col p-0 react-select-container">
					<div className="input-group-prepend">
						<span className="input-group-text pl-2 pr-0 py-2">
							<img
								alt="ProfileImage"
								src={userGroupImage}
								height="20"
							/>
						</span>
					</div>
					<UncontrolledButtonDropdown
						direction="down"
						className="form-filter-opportunity react-select-container m-0"
						style={{ height: '38px' }}
					>
						<DropdownToggle className="toggler py-0 px-2" caret>
							<span>
								{selected.length > 10
									? `${selected.substring(0, 10)} . . .`
									: selected}
							</span>
						</DropdownToggle>
						<DropdownMenu style={{ minWidth: '155px' }}>
							<DropdownItem
								onClick={() => {
									this.setState(
										{
											selected: <Translate id="all" />
										},
										() => onFilter({ user_id: 'all' })
									);
								}}
							>
								<Translate id="all" />
							</DropdownItem>

							<DropdownItem onClick={this.handleCurrentUser}>
								<Translate id="me" />
							</DropdownItem>

							{current != undefined && current.role.slug != 'sales' ? (
								<Fragment>
									<DropdownItem
										onClick={() => {
											this.handleFilterUser();
											this.setState({
												selected: <Translate id="Specific User" />
											});
										}}
									>
										<Translate id="Specific User" />
									</DropdownItem>

									{/* <DropdownItem
                    onClick={() => {
                      this.handleFilterGroup();
                      this.setState({
                        selected: 'Specific Group'
                      });
                    }}
                  >
                    <Translate id="specific_group" />
                  </DropdownItem> */}
								</Fragment>
							) : null}
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</InputGroup>
				<Modal isOpen={this.state.modalUser} toggle={this.toggleUser} centered>
					<ModalHeader
						toggle={this.toggleUser}
						style={{ borderBottom: 'none' }}
					>
						<Translate id="select_spesific_user" />
					</ModalHeader>
					<form
						className="forms-sample"
						onSubmit={handleSubmit(this.handleSpecificUser)}
					>
						<ModalBody>
							<Translate>
								{({ translate }) => (
									<div className="text-center">
										<SelectInCharge
											name="userId"
											label={<Translate id="Specific User" />}
											placeholder={translate('pick_spesific_user')}
										/>
									</div>
								)}
							</Translate>
						</ModalBody>
						<ModalFooter style={{ borderTop: 'none' }}>
							<Button color="primary" className="px-5 mb-2">
								<Translate id="save" />
							</Button>
						</ModalFooter>
					</form>
				</Modal>
				<Modal
					isOpen={this.state.modalGroup}
					toggle={this.toggleGroup}
					centered
				>
					<ModalHeader toggle={this.toggleGroup}>
						<Translate id="select_spesific_group" />
					</ModalHeader>
					<form
						className="forms-sample"
						onSubmit={handleSubmit(this.handleSpecificGroup)}
					>
						<ModalBody>
							<Translate>
								{({ translate }) => (
									<div className="text-center">
										<SelectGroup
											name="groupId"
											label={translate('group')}
											placeholder={translate('pick_spesific_group')}
											current={current && current}
										/>
									</div>
								)}
							</Translate>
						</ModalBody>
						<ModalFooter style={{ borderTop: 'none' }}>
							<Button color="primary" className="px-5 mb-2">
								<Translate id="save" />
							</Button>
						</ModalFooter>
					</form>
				</Modal>
			</div>)
		);
	}
}

const mapStateToProps = state => ({});

export default reduxForm({
	form: 'selectUserForm'
})(connect(mapStateToProps, {})(SelectUser));
