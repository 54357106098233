/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component} from 'react';

class DynamicTable extends Component {
  state = {
    count: 1
  };

  handleAdd = () => {
    const { count } = this.state;
    this.setState({
      count: count + 1
    });
  };

  handleSubstract = () => {
    const { count } = this.state;
    if (count > 1) {
      this.setState({
        count: count - 1
      });
    }
  };

  render() {
    const { count } = this.state;
    const numArray = [...Array(count - 1).keys()];

    return (
      <div className="dynamic-field-formbuilder mt-3">
        {numArray.map(data => (
          <div className="" key={data}>
            {this.props.children}
            <i
              className="fas fa-minus-circle d-flex justify-content-end form-group"
              onClick={this.handleSubstract}
            />
          </div>
        ))}
        {this.props.children}
        <i
          className="fas fa-plus-circle d-flex justify-content-end form-group"
          onClick={this.handleAdd}
        />
      </div>
    );
  }
}

export default DynamicTable;
