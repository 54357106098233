/* eslint-disable camelcase */
import { get } from 'lodash';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { getOption } from '../../../../../helpers/data/defaultValueSelfAudit';
import PropCheckBox from './property/PropCheckbox';
import PropDropdownList from './property/PropDropdownList';
import PropFreeText from './property/PropFreeText';
import PropNumerical from './property/PropNumerical';
import TextArea from './property/TextArea';

export default class FormElementsEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			element: this.props.element,
			dirty: false
		};
	}

	updateElement = () => {
		const this_element = this.state.element;
		// to prevent ajax calls with no change
		if (this.state.dirty) {
			this.props.updateElement(this.props.preview, this_element);
			this.setState({ dirty: false });
		}
	};

	editElementProp = (elemProperty, targProperty, value) => {
		const this_element = this.state.element;
		this_element[elemProperty] = value;
		this.setState(
			{
				element: this_element,
				dirty: true
			},
			() => {
				if (targProperty === 'checked') {
					this.updateElement();
				}
			}
		);
	};

	renderProp = () => {
		const { element } = this.props;

		const el = get(element, 'element');
		const disabled = get(element, 'disabled');
		const type = get(element, 'type');
		switch (el) {
			case 'TextArea':
				return (
					<TextArea
						{...this.props}
						updateElement={this.updateElement}
						isEdit={disabled}
						editElementProp={this.editElementProp}
					/>
				);
			case 'FreeText':
				return (
					<PropFreeText
						{...this.props}
						updateElement={this.updateElement}
						isEdit={disabled}
						editElementProp={this.editElementProp}
					/>
				);
			case 'Checkbox':
				return (
					<PropCheckBox
						{...this.props}
						updateElement={this.updateElement}
						isEdit={disabled}
						editElementProp={this.editElementProp}
					/>
				);

			case 'Numerical':
				return (
					<PropNumerical
						{...this.props}
						updateElement={this.updateElement}
						isEdit={disabled}
						editElementProp={this.editElementProp}
					/>
				);
			case 'DropdownList':
				const opt = getOption(type);
				return (
					<PropDropdownList
						{...this.props}
						updateElement={this.updateElement}
						isEdit={disabled}
						editElementProp={this.editElementProp}
						values={opt.filter(o => this.state.element['values']?.includes(o.value))}
						options={opt}
					/>
				);

			default:
				return <div />;
		}
	};

	render() {
		if (this.state.dirty) {
			this.props.element.dirty = true;
		}

		return (
			<div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<div className="mb-0 font-weight-bold"><Translate id="Field properties" /></div>
					<i
						className="fas fa-times dismiss-edit my-0"
						onClick={this.props.manualEditModeOff}
					/>
				</div>
				{this.renderProp()}
			</div>
		);
	}
}
FormElementsEdit.defaultProps = { className: 'edit-element-fields' };
