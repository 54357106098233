import styled from 'styled-components';
import { DefaultImage } from '../../../../../styles/global/css/GlobalImage';
import { DefaultEditable, DefaultText } from '../../../../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, DarkGreySidely, SidelyBlack } from '../../../../../styles/global/css/Utils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Header = styled.div`
    height: 10%;
    display: flex;
    flex-direction: row;
    align-content: center;
    min-height: 100px;
`;

const HeaderImage = styled(DefaultImage)`
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin: 5px 0 0 5px;
`;

const HeaderBlock = styled.div`
    padding: 20px;
    margin-left: 3rem;
`;

const HeaderTitleParent = styled.p`
    ${DefaultText}
    font-size: 9px;
    color: ${SidelyBlack};
    line-height: 9px;
`;

const HeaderTitle = styled.h2`
    ${DefaultText}
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: ${SidelyBlack};
    align-self: center;
    ${DefaultEditable}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:empty {
        &:not(:focus) {
            &:before {
                content: '-';
            }
        }
    }
`;

interface HeaderStatusProps {
  backgroundColor: string
}

const HeaderStatus = styled.h3<HeaderStatusProps>`
    ${DefaultText}
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    align-self: center;
    background-color: ${p => p.backgroundColor};
    margin: 0;
    padding: 3px 20px 3px 20px;
    border-radius: 5px;
    width: fit-content;
`;

const ContainerBody = styled.div`
    height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const ContainerRight = styled.div`
    width: 100%;
`;

const ContainerProductSum = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${BorderColor};
    padding-bottom: 2%;
    padding-left: 10px;
    padding-right: 10px;
`;

interface TextProps {
  height?: string
  width?: string
  color?: string
  weight?: number
  fontSize?: string
  textAlign?: string
}

const Text = styled.p<TextProps>`
    ${DefaultText}
    font-weight: ${p => p.weight ?? 400};
    font-size: ${p => p.fontSize ?? '14px'};
    line-height: 22px;
    text-align: ${p => p.textAlign ?? 'center'};
    color: ${p => p.color ?? SidelyBlack};
    height: ${p => p.height ?? 'auto'};
    width: ${p => p.width ?? 'auto'};
`;

const ListProducts = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 60%;
    overflow-y: auto;
`;

const Product = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 11.8px;
    padding: 10px;
    margin: 5px;
`;

const ProductSummary = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px;
`;

interface ProductBlockProps {
  width?: string
}

const ProductBlock = styled.div<ProductBlockProps>`
    width: ${p => p.width ? p.width : 'auto'};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProductImage = styled(DefaultImage)``;

const ContainerResult = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
`;

const Result = styled.div``;

const ContainerLeft = styled.div`
    width: 100%;
    border-right: 0.9px solid ${BorderColor};
`;

interface ColumnsProps {
  justifyContent?: string
}

const Columns = styled.div<ColumnsProps>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: ${p => p.justifyContent ?? 'space-around'};
    border-top: 0.951552px solid ${BorderColor};
    border-bottom: 0.951552px solid ${BorderColor};
    width: 100%;
    margin-bottom: 3%;
    height: 33px;
`;

interface ColumnProps {
  selected?: boolean
  isUnderlined?: boolean
  columnNb?: number
}

const Column = styled.div<ColumnProps>`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${p => p.selected ? DarkGreySidely : '#D5D5D5'};
    cursor: pointer;
    padding: 5px;
    border-bottom: ${p => p.isUnderlined ? `2.85466px solid ${BlueSidely}` : 'none'};
    width: ${p => p.columnNb ? 100 / p.columnNb - 10 : 90}%;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    margin-left: 5%;
    margin-right: 5%;
`;

interface InfoProps {
  marginTop?: string
  marginBottom?: string
  width?: string
  withUnderline?: boolean
  height?: string
}

const Info = styled.div<InfoProps>`
    display: flex;
    flex-direction: column;
    margin-top: ${p => p.marginTop ? p.marginTop : '5%'};
    margin-bottom: ${p => p.marginBottom ? p.marginBottom : '0'};
    border-bottom: ${p => p.withUnderline ? 'solid 1px #D3D8DD' : 'none'};
    padding-bottom: 1%;
    width: ${p => p.width ? p.width : '100%'};
    height: ${p => p.height ?? ''};
`;

const InfoTitle = styled.p<{ weight?: number }>`
    ${DefaultText}
    font-weight: ${p => p.weight ?? 500};
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${p => p.weight ? SidelyBlack : '#818E9B'};
`;

const InfoImage = styled(DefaultImage)`
    border-radius: 50%;
    margin-right: 15px;
`;

const InfoBoxValue = styled.div`
    display: flex;
    flex-direction: row;
`;

const InfoValue = styled.p<{ weight?: number }>`
    ${DefaultText}
    font-weight: ${p => p.weight ?? 500};
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: ${SidelyBlack};
    width: fit-content;
`;

const InfoLink = styled(DefaultImage)`
    cursor: pointer;
    width: 15px;
    margin-left: 1%;
`;

const InfoArea = styled.textarea<{ height?: string }>`
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 176.3%;
    color: #4C5862;
    padding: 10px;
    height: ${p => p.height ?? '20vh'};
    min-height: 75px;
`;

export {
	Container,
	Header,
	HeaderTitle,
	ContainerBody,
	ContainerRight,
	ContainerProductSum,
	Text,
	ListProducts,
	Product,
	ProductImage,
	ContainerLeft,
	Columns,
	Column,
	Form,
	Info,
	InfoTitle,
	InfoImage,
	InfoValue,
	InfoArea,
	ContainerResult,
	Result,
	HeaderStatus,
	HeaderImage,
	InfoLink,
	InfoBoxValue,
	HeaderBlock,
	ProductBlock,
	ProductSummary,
	HeaderTitleParent
};
