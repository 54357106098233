import * as React from 'react';
import CheckboxGroupFilter from '../../../containers/companies/components/CheckboxGroupFilter';
import { Translate } from 'react-localize-redux';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import { HideLastDays } from './TabFilter';
import calendarImage from 'images/icon/small_calendar2.svg';
import { FetchKey } from '../MapView';

function SimpleCheckBox(checked: boolean, onChange: Function, translate: string) {
	return (
		<div className="mb-3">
			<div className="d-flex">
				<div className="form-group check-sidely my-2">
					<label className="ml-3 mb-3">
						<input
							type="checkbox"
							checked={checked}
							onChange={e => onChange(e.target.checked)}
						/>
						<div className="checkbox-style" />
					</label>
				</div>
				<small className="ml-4">
					<Translate id={translate} />
				</small>
			</div>
		</div>
	);
}

function simpleDatePicker(disabled: boolean, currDate, onChange: Function) {
	return (
		<div className="form-group row">
			<div className="col-12" style={{ position: 'relative' }}>
				<DatePicker
					className={'form-control font-date'}
					popperPlacement={'top-end'}
					selected={currDate}
					dateFormat="dd/MM/yyyy"
					placeholderText="DD/MM/YYYY"
					showYearDropdown
					onChange={onChange}
					disabled={disabled}
					components={{ DropdownIndicator: (x) => x }}
				/>
				<div className="icon-field">
					<img
						src={calendarImage}
						style={{ width: '15px' }}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
}

function EventFilterDatePicker(disabled: boolean, reset: boolean, onChange: Function) {
	const [dates, setDates] = React.useState({ firstRange: null, lastRange: null });
	const today = moment().utc().startOf('day');

	React.useEffect(() => {
		if (reset) {
			setDates({ firstRange: null, lastRange: null });
		}
	}, [reset]);

	function onChangeLocal(key: string, e) {
		const newDates = dates;
		newDates[key] = e;
		if (newDates.firstRange && newDates.lastRange) {
			onChange({
				firstRange: today.diff(moment.utc(newDates.firstRange).startOf('day'), 'days') - 1,
				lastRange: today.diff(moment.utc(newDates.lastRange).startOf('day'), 'days') - 1
			});
		}
		setDates({ ...newDates });
	}

	return (
		<div className="d-flex">
			<div className="pl-2" style={{ width: '43%' }}>
				{simpleDatePicker(disabled, dates.firstRange, (e) => onChangeLocal('firstRange', e))}
			</div>
			<div
				className="text-small d-flex justify-content-center pt-2"
				// style={{ width: '10%' }}
			>
				<small>
					<Translate id="and" />
				</small>
			</div>
			<div style={{ width: '43%' }}>
				{simpleDatePicker(disabled, dates.lastRange, (e) => onChangeLocal('lastRange', e))}
			</div>
		</div>
	);
}

function initHidding() {
	return ({ fst: false, snd: false, trd: false, frh: false, ffh: false });
}

export default function EventsFilter(props: {
  changeFilters: Function
  eventTypes: Array<{ value: number, label: string }>
  type: FetchKey
  reset: { reset: boolean, setReset: Function }
}) {
	const [eventTypesCheckBox, setEventTypesCheckBox] = React.useState<number[]>(props.eventTypes.map(e => e.value));
	const [hidding, setHidding] = React.useState(initHidding());
	const reset = props.reset.reset;

	React.useEffect(() => {
		if (reset) {
			setHidding(initHidding());
			setEventTypesCheckBox(props.eventTypes.map(e => e.value));
			props.reset.setReset(false);
		} else {
			setEventTypesCheckBox(props.eventTypes.map(e => e.value));
		}
	}, [JSON.stringify(props.eventTypes), reset]);

	function handleCheckedStatus(isChecked: boolean, value: number | undefined, type: string) {
		let newValue = eventTypesCheckBox;
		if (typeof (value) !== 'undefined') {
			if (isChecked) {
				newValue.push(value);
			} else {
				const index = newValue.indexOf(value);
				if (index >= 0) { newValue.splice(index, 1); }
			}
		} else {
			if (isChecked) {
				newValue = props.eventTypes.map(e => e.value);
			} else {
				newValue = [];
			}
		}
		setEventTypesCheckBox([...newValue]);
		props.changeFilters(props.type == 'plan' ? 'nextEventType' : 'eventType', newValue.length == 0 ? [-1] : newValue);
	}

	return (
		<div style={{ maxWidth: '260px' }}>
			<CheckboxGroupFilter
				options={props.eventTypes}
				valChecked={eventTypesCheckBox}
				input={{ value: eventTypesCheckBox, onChange: () => null }}
				checkedAll={
					eventTypesCheckBox && eventTypesCheckBox.length === props.eventTypes.length
				}
				handleCheckedStatus={handleCheckedStatus}
				placeholder="Search status"
				type="status"
				meta={{ touched: props.eventTypes.length == 0, error: 'Error fetching event types' }}
			/>

			{props.type == 'last'
				? (
					<>
						<HideLastDays
							checkBoxActivate={hidding.trd}
							onCheckBoxChange={_ => {
								setHidding({ ...hidding, trd: !hidding.trd });
								props.changeFilters('hideRangeLast', !hidding.trd);
							}}
							onRangeChange={range => props.changeFilters('last', parseInt(range))}
							text='hide_company_last_event_later'
						/>
						<hr className="my-2" />
						{SimpleCheckBox(hidding.fst, (target: boolean) => {
							setHidding({ ...hidding, fst: target });
							props.changeFilters('noPlan', target);
						}, 'Hide companies with no completed events')}
						<hr className="my-2" />
						{SimpleCheckBox(hidding.snd, (target: boolean) => {
							setHidding({ ...hidding, snd: target });
							props.changeFilters('plan', target);
						}, 'Hide companies with no completed events but planned event')}
						<hr className="my-2" />
					</>
				)
				: (
					<>
						{SimpleCheckBox(hidding.ffh, (target: boolean) => {
							setHidding({ ...hidding, ffh: target });
							props.changeFilters('hideRangeNext', target);
						}, 'Hide companies with next events planned between :')}
						{EventFilterDatePicker(!hidding.ffh, reset, (target) => {
							props.changeFilters('next', target);
						})}
						{SimpleCheckBox(hidding.frh, (target: boolean) => {
							setHidding({ ...hidding, frh: target });
							props.changeFilters('hideNext', target);
						}, 'Hide companies with no planned events')}
						<hr className="my-2" />
					</>
				)}
		</div>
	);
}
