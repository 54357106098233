import userImage from 'images/icons/user.svg';
import React from 'react';
import moment from 'moment';
import { Progress } from 'reactstrap';
import { getProgressbarColor, simplifyNum } from '../../../helpers/data/enrichedForm';
import { Translate } from 'react-localize-redux';

function Overview(props) {
	const { detail, history } = props;
	const templateName = JSON.parse(localStorage.getItem('instance'));
	const percent = ((detail && detail.presence) || 0) * 100;
	return (
		(<div className="detail-body">
			<div className="row">
				<div className="col-md-6">
					<div className="row mb-2">
						<div className="col-md-6 text-md-right px-4">
							<p>
								<Translate id="form" />
							</p>
						</div>
						<div className="col-md-6">
							<p className="detail-value-key text-capitalize">
								{templateName || '-'}
							</p>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-6 text-md-right px-4">
							<p>
								<Translate id="owner" />
							</p>
						</div>
						<div className="col-md-6">
							<div className="d-flex">
								<img
									src={(detail && detail.owner && detail.owner.photo_url) || userImage}
									className="img-xs rounded-circle image-thumbnail mr-2"
								/>
								<p className="detail-value-key mb-0">
									{(detail && detail.owner && detail.owner.name) || '-'}
								</p>
							</div>
						</div>
					</div>

					<div className="row mb-2">
						<div className="col-md-6 text-md-right px-4">
							<p>
								<Translate id="company" />
							</p>
						</div>
						<div className="col-md-6">
							<p
								className="detail-value-key font-weight-bold pointer"
								onClick={() =>
									detail &&
                  history.push(`/companies?id=${detail.client_company.id}`)
								}
							>
								{(detail &&
                  detail.client_company &&
                  detail.client_company.name) ||
                  '-'}
							</p>
						</div>
					</div>

					<div className="row mb-2">
						<div className="col-md-6 text-md-right px-4">
							<p>Date</p>
						</div>
						<div className="col-md-6">
							<p className="detail-value-key">
								{detail &&
                  moment(detail.created_at || '-').format('DD/MM/YY HH:mm')}
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<p>
						<Translate id="instore_presence" />
					</p>
					<div className="d-flex">
						<div className="col-5 px-0">
							<Progress
								value={percent}
								className={`height-progressbar progressbar-enform${getProgressbarColor(
									percent
								)}`}
							/>
						</div>
						<p className="font-weight-bold">{simplifyNum(percent)}%</p>
					</div>
				</div>
			</div>
		</div>)
	);
}

export default Overview;
