import * as React from 'react';
import { TOP_HEADBAND_HEIGHT, TopHeadBandContext } from '../../containers_v2/topHeadBand/TopHeadBandProvider';
import { useKeyPress } from '../../utils/customHooks';
import { PopupStateContext } from './PopupStateContext';
import { PopupMode, PopupProps, PopupStyle } from './model/Model';
import { Background, Container, PopupWrapper } from './style/Style';

function checkClickOut(ref: React.RefObject<HTMLDivElement>, clickOut: (() => void) | undefined, disableClickOut: boolean, setPopupState: React.Dispatch<React.SetStateAction<string[]>>, popupId: string) {
	const headBand = React.useContext(TopHeadBandContext);
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (!disableClickOut) {
				if (ref.current && !ref.current.contains(event.target) && ((headBand.ref.current == null) || !headBand.ref.current?.contains(event.target))) {
					if (clickOut) {
						setPopupState(state => {
							if (state[state.length - 1] === popupId) {
								clickOut();
							}
							return state;
						});
					}
				}
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, disableClickOut, headBand.ref, clickOut]);
}

function Popup(props: PopupProps & {name?: string}): JSX.Element {
	const [clickOutDisable, setClickOutDisable] = React.useState<boolean>(props.disableOutClick ?? false);
	const [escDisabled, setEscDisabled] = React.useState<boolean>(props.disableEsc ?? false);
	const headBand = React.useContext(TopHeadBandContext);
	const { popupState, setPopupState } = React.useContext(PopupStateContext);

	const wrapperRef = React.useRef<HTMLDivElement>(null);

	const [popupStyle, setPopupStyle] = React.useState<PopupStyle>(props.popupStyle ?? {});
	const [hasBackground, setHasBackground] = React.useState<boolean | undefined>(props.hasBackground);
	const [isOpen, setOpen] = React.useState<boolean>(false);

	const [animationEnd, setAnimationEnd] = React.useState<boolean>(false);
	const popupId = React.useId();
	const index = popupState.findIndex(p => p == popupId);

	React.useEffect(() => {
		setPopupState(state => {
			if (props.isOpen) {
				if (!state.find(id => id == popupId)) return [...state, popupId];
			} else {
				const index = state.findIndex(id => id == popupId);
				if (index >= 0) {
					const newPopupstate = [...state];
					newPopupstate.splice(index, 1);
					return newPopupstate;
				}
			}
			return state;
		});
		return () => {
			setPopupState(state => {
				const index = state.findIndex(id => id == popupId);
				if (index >= 0) {
					const newPopupstate = [...state];
					newPopupstate.splice(index, 1);
					return newPopupstate;
				}
				return state;
			});
		};
	}, [props.isOpen]);

	// is execute before event js event onClick
	useKeyPress([{ key: 'Escape' }], () => {
		if (!escDisabled) {
			if (popupState[popupState.length - 1] == popupId) {
				props.onClickOut?.();
			}
		}});

	checkClickOut(wrapperRef, props.onClickOut, clickOutDisable, setPopupState, popupId);

	React.useEffect(() => {
		const height = headBand.isOpen ? 'calc(100% - 60px)' : '100%';
		const newPopupStyle = { ...(props.popupStyle ?? {}) };
		switch (props.popupMode) {
			case PopupMode.Centered:
				newPopupStyle.top = '50%';
				newPopupStyle.left = '50%';
				newPopupStyle.transform = 'translate(-50%, -50%)';
				newPopupStyle.position = 'fixed';
				newPopupStyle.borderRadius = '10px';
				if (popupStyle.fitContent)
					newPopupStyle.containerTransform = 'translateY(-50%)';
				setHasBackground(true);
				break;
			case PopupMode.Extended:
				newPopupStyle.width = 'calc((100vw - 60px) * 0.75)';
				newPopupStyle.left = 'calc((100vw - 60px) * 0.25 + 60px)';
				newPopupStyle.boxShadow = '0px 0px 16px rgba(0, 0, 0, 0.06)';
				newPopupStyle.animate = !isOpen;
				setHasBackground(false);
				break;
			case PopupMode.Default:
				newPopupStyle.width = 'calc(100% - 60px)';
				newPopupStyle.left = '60px';
				newPopupStyle.boxShadow = undefined;
				newPopupStyle.animate = !isOpen;
				newPopupStyle.height = newPopupStyle.height ?? height;
				newPopupStyle.top = headBand.isOpen ? TOP_HEADBAND_HEIGHT : newPopupStyle.top ?? '0%';
				setHasBackground(true);
				break;
			case PopupMode.Details:
				newPopupStyle.width = '25vw';
				if (newPopupStyle.minWidth) {
					newPopupStyle.left = `min(100vw - ${newPopupStyle.minWidth}, 75vw)`;
				} else {
					newPopupStyle.left = '75vw';
				}
				newPopupStyle.boxShadow = '0px 0px 16px rgba(0, 0, 0, 0.06)';
				newPopupStyle.animate = !isOpen;
				newPopupStyle.height = newPopupStyle.height ?? height;
				newPopupStyle.top = headBand.isOpen ? TOP_HEADBAND_HEIGHT : newPopupStyle.top ?? '0%';
				setHasBackground(hasBackground ? true : false);
				break;
			default:
				break;
		}
		setPopupStyle(newPopupStyle);
	}, [JSON.stringify(props.popupStyle), props.popupMode, headBand.isOpen]);

	React.useEffect(() => {
		setClickOutDisable(props.disableOutClick ?? false);
	}, [props.disableOutClick]);

	React.useEffect(() => {
		setEscDisabled(props.disableEsc ?? false);
	}, [props.disableEsc]);

	React.useEffect(() => {
		if (!props.isOpen) {
			setAnimationEnd(false);
			setOpen(false);
		}
	}, [props.isOpen]);

	return (
		<div>
			{props.isOpen && (hasBackground || props.catchOutsideEventClick) && <Background zIndex={popupStyle.zIndex} noColor={props.catchOutsideEventClick}/>}
			<PopupWrapper
				onAnimationEnd={() => setAnimationEnd(true)}
				animationStyle={popupStyle?.animationStyle}
				width={popupStyle?.width}
				height={popupStyle?.height ?? (popupStyle?.fitContent ? 'fit-content' : undefined)}
				top={popupStyle?.top}
				boxShadow={popupStyle?.boxShadow}
				borderRadius={popupStyle?.borderRadius}
				zIndex={popupStyle.zIndex}
				left={popupStyle.left}
				minWidth={popupStyle.minWidth}
				minHeight={popupStyle.minHeight}
				isOpen={props.isOpen}
				animate={popupStyle.animate && !animationEnd}
				transform={popupStyle.transform}
				position={popupStyle.position}
				maxHeight={popupStyle.maxHeight}
				noTransition={popupStyle.noTransition}
			>
				{props.isOpen && (
					<div ref={wrapperRef} style={{ width: '100%', height: '100%' }}>
						<Container
							borderRadius={popupStyle?.borderRadius}
							displayB={props.isOpen ? 'initial' : 'none'}
							padding={popupStyle.padding}
							overflow={popupStyle.overflow}
							fitContent={popupStyle?.fitContent}
							transform={popupStyle?.containerTransform}
						>
							<PopupProvider isChild={index > 0 || (index == -1 && popupState.length > 0)}>
								{props.content ?? props.children}
							</PopupProvider>
						</Container>
					</div>
				)}
			</PopupWrapper >
		</div>
	);
}

export default Popup;

export interface PopupContextType {
	isChild: boolean
}

const defaultBehaviour: PopupContextType = {
	isChild: false
};

export const PopupContext = React.createContext<PopupContextType>(defaultBehaviour);

export function PopupProvider(props: {
	children: React.ReactNode,
	isChild: boolean
}) {
	const { isChild } = props;

	return <PopupContext.Provider value={{ isChild }}>
		{props.children}
	</PopupContext.Provider>;
}
