/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {message, Upload} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {get} from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {uploadFile} from '../../../store/actions/formbuilder.action';

const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

class UploadImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      fileList: [],
      modalPreview: false
    };
  }

  componentDidMount() {
    const { input } = this.props;
    const img = get(input, 'value.img');

    if (img) {
      this.setState({
        fileList: img
      });
    }
    if (input.value) {
      input.onChange(input.value);
    } else {
      input.onChange({ img: [] });
    }
  }

  customRequest = ({ onSuccess, onError, file }) => {
    const { uploadFile, input } = this.props;
    const formData = new FormData();
    this.setState({ loading: true });
    formData.append('type', 'camera_field');
    formData.append('file', file);

    uploadFile(formData).then(
      response => {
        const fileList = [
          {
            url: response.data.data.temp_url,
            status: 'done',
            name: response.data.data.url,
            id: response.data.data.id,
            uid: 0
          }
        ];

        this.setState({
          loading: false,
          fileList
        });

        input.onChange({ img: this.state.fileList });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const {
      input,
      name,
      meta: { error, warning, touched },
      label,
      requiredStar,
      disabled,
      id
    } = this.props;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { fileList, modalPreview } = this.state;
    return (
      <div className="form-group mb-0 dropzone-icon">
        <div className="row">
          <div className="col-md-3 my-auto text-right">
            <label htmlFor={id}>
              {requiredStar === true ? (
                <span className="text-required">* </span>
              ) : (
                ''
              )}
              {label}
            </label>
          </div>
          <div className="col-md-8">
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              customRequest={this.customRequest}
              showUploadList={false}
              multiple={false}
              disabled={disabled}
              beforeUpload={beforeUpload}
            >
              {fileList.length ? (
                <img
                  src={fileList[0].url}
                  alt="images"
                  className="img-preview-cs"
                  style={{ width: '100%' }}
                  onClick={() =>
                    disabled && this.setState({ modalPreview: true })
                  }
                />
              ) : (
                uploadButton
              )}
            </Upload>
            {touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}

            {modalPreview && (
              <Lightbox
                mainSrc={fileList[0].url}
                onCloseRequest={() => this.setState({ modalPreview: false })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.formbuilder.file,
  isUploading: state.formbuilder.isUploading
});

export default connect(
  mapStateToProps,
  { uploadFile }
)(UploadImage);
