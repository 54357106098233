import * as React from 'react';
import styled from 'styled-components';
import Dropdown from '../dropdown/Dropdown';
import { DropdownData } from '../dropdown/model/Model';
import { getLanguageCode } from '../../containers_v2/reports/utils';

const LanguagePickerContainer = styled.div`
    font-size: 23px;
`;

export default function LanguagePicker(): JSX.Element {
	const lang = getLanguageCode();
	const allLanguages: DropdownData[] = [
		{ value: 'fr', label: '🇫🇷' },
		{ value: 'en', label: '🇬🇧' }
	];
	const [value, setValue] = React.useState<DropdownData>(allLanguages.find(l => l.value == lang) ?? allLanguages[0]);

	return <LanguagePickerContainer>
		<Dropdown
			name='languagePicker'
			datalist={allLanguages}
			selectedValue={value}
			onChange={(data: DropdownData) => {
				setValue(data);
				localStorage.setItem('language', data.value);
				window.location.reload();
			}}
			JSXButton={() => value.label}
			dropdownStyle={{
				optionFontSize: '23px',
				optionWidth: '65px',
				optionLeft: '-21px'
			}}
		/>
	</LanguagePickerContainer>;
}
