import React, {Component} from 'react';
import {connect} from 'react-redux';
import {valSorting} from '../../helpers/textConfig';
import {getAlphabet} from '../../store/actions/select.action';

class AlphabeticalSelector extends Component {
  componentDidMount() {
    const paramsData = JSON.parse(localStorage.getItem('params'));
    if (paramsData?.alphabet !== undefined && paramsData?.alphabet !== null) {
      this.props.getAlphabet(paramsData.alphabet[0]);
    }
  }

  handleFilter = value => {
    this.props.getAlphabet(value);
    value === null
      ? this.props.filterByAlphabet(null)
      : this.props.filterByAlphabet([value]);
  };
  render() {
    const { alphabet } = this.props;
    return (
      <div className="d-flex w-75 justify-content-center">
        {valSorting.map(value => (
          <div
            className="px-2 py-1 page-background d-flex align-items-center pointer"
            style={{ background: alphabet === value && '#00b2ff' }}
            onClick={() => {
              if (alphabet === value) {
                this.handleFilter(null);
              } else {
                this.handleFilter(value);
              }
            }}
          >
            <small
              className={`text-uppercase ${alphabet === value && 'text-white'}`}
              style={{ color: '#828282' }}
            >
              {value}
            </small>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  alphabet: state.select.alphabet
});

export default connect(mapStateToProps, {
  getAlphabet
})(AlphabeticalSelector);
