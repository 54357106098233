import SortableElement from './sortable-element';
import BaseFormElements from './form-elements';

const { DropdownList, FreeText, Numerical, Checkbox, TextArea } = BaseFormElements;

const FormElements = {};

FormElements.FreeText = SortableElement(FreeText);
FormElements.Numerical = SortableElement(Numerical);
FormElements.DropdownList = SortableElement(DropdownList);
FormElements.Checkbox = SortableElement(Checkbox);
FormElements.TextArea = SortableElement(TextArea)

export default FormElements;
