export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';

export const FETCH_ALL_COMPANIES_REQUEST = 'FETCH_ALL_COMPANIES_REQUEST';
export const FETCH_ALL_COMPANIES_SUCCESS = 'FETCH_ALL_COMPANIES_SUCCESS';
export const FETCH_ALL_COMPANIES_ERROR = 'FETCH_ALL_COMPANIES_ERROR';

export const DETAIL_COMPANIES_REQUEST = 'DETAIL_COMPANIES_REQUEST';
export const DETAIL_COMPANIES_SUCCESS = 'DETAIL_COMPANIES_SUCCESS';
export const DETAIL_COMPANIES_ERROR = 'DETAIL_COMPANIES_ERROR';

export const DETAIL_PIN_REQUEST = 'DETAIL_PIN_REQUEST';
export const DETAIL_PIN_SUCCESS = 'DETAIL_PIN_SUCCESS';
export const DETAIL_PIN_ERROR = 'DETAIL_PIN_ERROR';

export const CREATE_COMPANIES_REQUEST = 'CREATE_COMPANIES_REQUEST';
export const CREATE_COMPANIES_SUCCESS = 'CREATE_COMPANIES_SUCCESS';
export const CREATE_COMPANIES_ERROR = 'CREATE_COMPANIES_ERROR';

export const UPDATE_COMPANIES_REQUEST = 'UPDATE_COMPANIES_REQUEST';
export const UPDATE_COMPANIES_SUCCESS = 'UPDATE_COMPANIES_SUCCESS';
export const UPDATE_COMPANIES_ERROR = 'UPDATE_COMPANIES_ERROR';

export const DELETE_COMPANIES_REQUEST = 'UPDATE_COMPANIES_REQUEST';
export const DELETE_COMPANIES_SUCCESS = 'UPDATE_COMPANIES_SUCCESS';
export const DELETE_COMPANIES_ERROR = 'UPDATE_COMPANIES_ERROR';

export const GET_ALL_COMPANIES_REQUEST = 'GET_ALL_COMPANIES_REQUEST';
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS';
export const GET_ALL_COMPANIES_ERROR = 'GET_ALL_COMPANIES_ERROR';

export const SEARCH_COMPANIES = 'SEARCH_COMPANIES';

export const SORT_DATA = 'SORT_DATA';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

// CREATE LINKED ELEMENTS
export const CREATE_LINKEDELEMENT_REQUEST = 'CREATE_LINKEDELEMENT_REQUEST';
export const CREATE_LINKEDELEMENT_SUCCESS = 'CREATE_LINKEDELEMENT_SUCCESS';
export const CREATE_LINKEDELEMENT_ERROR = 'CREATE_LINKEDELEMENT_ERROR';

// GET LINKED ELEMENTS

export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';

export const GET_OPPORTUNITIES_REQUEST = 'GET_OPPORTUNITIES_REQUEST';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';
export const GET_OPPORTUNITIES_ERROR = 'GET_OPPORTUNITIES_ERROR';

export const GET_PICTURES_REQUEST = 'GET_PICTURES_REQUEST';
export const GET_PICTURES_SUCCESS = 'GET_PICTURES_SUCCESS';
export const GET_PICTURES_ERROR = 'GET_PICTURES_ERROR';

export const GET_EMAIL_REQUEST = 'GET_EMAIL_REQUEST';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_ERROR = 'GET_EMAIL_ERROR';

export const GET_EMAIL_DETAIL_REQUEST = 'GET_NOTE_EMAIL_REQUEST';
export const GET_EMAIL_DETAIL_SUCCESS = 'GET_NOTE_EMAIL_SUCCESS';
export const GET_EMAIL_DETAIL_ERROR = 'GET_NOTE_EMAIL_ERROR';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const GET_EVENT_DETAIL_REQUEST = 'GET_EVENT_DETAIL_REQUEST';
export const GET_EVENT_DETAIL_SUCCESS = 'GET_EVENT_DETAIL_SUCCESS';
export const GET_EVENT_DETAIL_ERROR = 'GET_EVENT_DETAIL_ERROR';

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_ERROR = 'GET_NOTES_ERROR';

export const GET_NOTE_DETAIL_REQUEST = 'GET_NOTE_DETAIL_REQUEST';
export const GET_NOTE_DETAIL_SUCCESS = 'GET_NOTE_DETAIL_SUCCESS';
export const GET_NOTE_DETAIL_ERROR = 'GET_NOTE_DETAIL_ERROR';

export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';

// MODIFY LINKED ELEMENTS
export const DELETE_COMPANY_FILE_REQUEST = 'DELETE_COMPANY_FILE_REQUEST';
export const DELETE_COMPANY_FILE_SUCCESS = 'DELETE_COMPANY_FILE_SUCCESS';
export const DELETE_COMPANY_FILE_ERROR = 'DELETE_COMPANY_FILE_ERROR';

export const DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const FETCH_CLIENT_STATUS_REQUEST = 'FETCH_CLIENT_STATUS_REQUEST';
export const FETCH_CLIENT_STATUS_SUCCESS = 'FETCH_CLIENT_STATUS_SUCCESS';
export const FETCH_CLIENT_STATUS_ERROR = 'FETCH_CLIENT_STATUS_ERROR';

export const SET_FILTER_STATUS_ID = 'SET_FILTER_STATUS_ID';
export const SET_FILTER_OWNER_ID = 'SET_FILTER_OWNER_ID';
export const SET_FILTER_GROUP_ID = 'SET_FILTER_GROUP_ID';
export const SET_FILTER_TAG_ID = 'SET_FILTER_TAG_ID';
export const SET_INITIAL_FILTER = 'SET_INITIAL_FILTER';

export const FETCH_COMPANY_IN_EACH_COUNTRY_REQUEST =
  'FETCH_COMPANY_IN_EACH_COUNTRY_REQUEST';
export const FETCH_COMPANY_IN_EACH_COUNTRY_SUCCESS =
  'FETCH_COMPANY_IN_EACH_COUNTRY_SUCCESS';
export const FETCH_COMPANY_IN_EACH_COUNTRY_ERROR =
  'FETCH_COMPANY_IN_EACH_COUNTRY_ERROR';

export const MAP_REDIRECT = 'MAP_REDIRECT';
export const WEB_VIEW = 'WEB_VIEW';

export const RANGE_SELECTED = 'RANGE_SELECTED';

export const FETCH_COMPANIES_MAP_LIST_REQUEST =
  'FETCH_COMPANIES_MAP_LIST_REQUEST';
export const FETCH_COMPANIES_MAP_LIST_SUCCESS =
  'FETCH_COMPANIES_MAP_LIST_SUCCESS';
export const FETCH_COMPANIES_MAP_LIST_ERROR = 'FETCH_COMPANIES_MAP_LIST_ERROR';

export const INSTORE_PRESENCES = 'INSTORE_PRESENCES';
export const INSTORE_PRESENCES_CALCULATE_CHILD = 'INSTORE_PRESENCES_CALCULATE_CHILD';
