import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import Calendar from './linkedElements/Calendar';
import Documents from './linkedElements/Documents';
import Notes from './linkedElements/Notes';
import LinkedFormdata from './linkedElements/LinkedFormData';
import PageLoader from '../../../components/PageLoader';
import { formatCurrency } from '../../../containers_v2/reports/utils';
import noUserImage from 'images/icons/user.svg';
import Overview from './subDetails/Overview';
import Revenue from './subDetails/Revenue';
import Activities from './subDetails/Activities';

class OpportunitiesDetail extends Component {
	state = {
		activeTab: 1,
		activeHeadTab: 1,

		eventsData: [],
		eventsCount: [],
		notesData: [],
		documentsData: [],
		linkedData: []
	};

	componentDidMount() {
		const {
			match,
			getEvents,
			getNotes,
			getDocuments,
			getSalesOperations,
			getLinkedform,
			getDetailform,
			getAllform,
			getTradeshows,
			getMerchandisings
		} = this.props;

		getEvents({
			limit: 3,
			page: 1,
			sort: 'desc',
			by: 'startDate',
			opportunityId: match.params.id
		});

		getNotes({
			limit: 3,
			page: 1,
			sort: 'desc',
			by: 'startDate',
			opportunityId: match.params.id
		});

		getDocuments({
			limit: 3,
			page: 1,
			opportunityId: match.params.id
		});

		getSalesOperations(
			{
				opportunityId: match.params.id
			},
			'tradeshows'
		);
		getLinkedform({
			page: 1,
			limit: 4,
			link_id: match.params.id,
			link_type: 'opportunity',
			sort_by: 'updated_at',
			sort: 'DESC'
		});
		getAllform({
			link_id: match.params.id,
			link_type: 'opportunity'
		});
		getDetailform({ link_id: match.params.id, link_type: 'opportunity' });
		getTradeshows(`opportunityId=${match.params.id}`);
		getMerchandisings(`opportunityId=${match.params.id}`);
	}

	componentWillReceiveProps(nextProps) {
		const { data, detailLinkedForm } = this.props;
		const { eventsData, notesData, documentsData, linkedData } = this.state;

		// Initial Data Loading / See less
		const linkedDetail = nextProps.detailLinkedForm.linkedForm;
		if (detailLinkedForm.linkedForm !== linkedDetail) {
			if (nextProps.detailLinkedForm.paginationLinkedForm.currentPage <= 1) {
				this.setState({
					linkedData: linkedDetail
				});
			}
		}

		if (nextProps.data.paginationEvents.currentPage <= 1) {
			this.setState({
				eventsData: nextProps.data.events,
				eventsCount: nextProps.data.paginationEvents.count
			});
		}
		if (nextProps.data.paginationNotes.currentPage <= 1) {
			this.setState({
				notesData: nextProps.data.notes
			});
		}
		if (nextProps.data.paginationDocuments.currentPage <= 1) {
			this.setState({
				documentsData: nextProps.data.documents
			});
		}

		// On see More
		if (nextProps.detailLinkedForm.paginationLinkedForm.currentPage > 1) {
			if (detailLinkedForm.linkedForm !== linkedDetail) {
				const dataArray = linkedData;
				linkedDetail.map(data => dataArray.push(data));
				this.setState({
					linkedData: dataArray
				});
			}
		}

		if (nextProps.data.paginationEvents.currentPage > 1) {
			if (data.events !== nextProps.data.events) {
				const dataArray = eventsData;
				nextProps.data.events.map(data => dataArray.push(data));

				this.setState({
					eventsData: dataArray
				});
			}
		}

		if (nextProps.data.paginationNotes.currentPage > 1) {
			if (data.notes !== nextProps.data.notes) {
				const dataArray = notesData;
				nextProps.data.notes.map(data => dataArray.push(data));

				this.setState({
					notesData: dataArray
				});
			}
		}

		if (nextProps.data.paginationDocuments.currentPage > 1) {
			if (data.documents !== nextProps.data.documents) {
				const dataArray = documentsData;
				nextProps.data.documents.map(data => dataArray.push(data));

				this.setState({
					documentsData: dataArray
				});
			}
		}
	}

	onFetchLinkedForm = page => {
		const { getLinkedform, detailLinkedForm, match } = this.props;
		const pagination = detailLinkedForm.paginationLinkedForm;
		getLinkedform({
			page: page || pagination.currentPage + 1,
			limit: 4,
			link_id: match.params.id,
			link_type: 'opportunity',
			sort_by: 'updated_at',
			sort: 'DESC'
		});
	};

	onFetchEvents = page => {
		const { match, getEvents, data } = this.props;

		getEvents({
			limit: 3,
			sort: 'desc',
			by: 'startDate',
			page: page || data.paginationEvents.currentPage + 1,
			opportunityId: match.params.id
		});
	};

	onFetchNotes = page => {
		const { match, getNotes, data } = this.props;

		getNotes({
			limit: 3,
			sort: 'desc',
			by: 'startDate',
			page: page || data.paginationNotes.currentPage + 1,
			opportunityId: match.params.id
		});
	};

	onFetchDocuments = page => {
		const { match, getDocuments, data } = this.props;

		getDocuments({
			limit: 3,
			page: page || data.paginationDocuments.currentPage + 1,
			opportunityId: match.params.id,
			type: 'document'
		});
	};

	toggle = tab => {
		this.setState({
			activeTab: tab
		});
	};

	toggleHead = tab => {
		this.setState({
			activeHeadTab: tab
		});
	};

	render() {
		const {
			data,
			match,
			current,
			linkedFormdata,
			detailLinkedForm,
			status
		} = this.props;
		const { dataDetail } = data;
		const { id } = match.params;
		const { activeTab, activeHeadTab } = this.state;
		const pagination = detailLinkedForm.paginationLinkedForm;
		const idStatus =
      dataDetail &&
      dataDetail.opportunityStatus &&
      dataDetail.opportunityStatus.id;

		return (
			<div>
				<div className="mx-3 detail-top">
					<div className="d-flex">
						{status &&
              status.map(value => (
              	<div
              		style={{
              			minWidth: `${100 / status.length}%`,
              			maxWidth: 100 / status.length > 17 && '10rem'
              		}}
              	>
              		<div
              			className="nav-status p-1 text-truncate"
              			style={{
              				background:
                        idStatus === value.id && value.colorStatus.colorCode,
              				color: idStatus === value.id ? '#FFF' : '#90a0b7'
              			}}
              		>
              			<span data-toggle="tooltip" title={value.name}>
              				{value.name}
              			</span>
              		</div>
              	</div>
              ))}
					</div>
					<Nav className="detail-head-tab d-flex justify-content-end">
						<NavItem>
							<NavLink
								active={activeHeadTab === 1}
								onClick={() => {
									this.toggleHead(1);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="overview" />
								</h2>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={activeHeadTab === 2}
								onClick={() => {
									this.toggleHead(2);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="revenue" />
								</h2>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={activeHeadTab === 3}
								onClick={() => {
									this.toggleHead(3);
								}}
							>
								<h2 className="card-title mb-0">
									<Translate id="activity" />
								</h2>
							</NavLink>
						</NavItem>
					</Nav>
					{data.isLoadingDetail ? (
						<div>
							<PageLoader />
						</div>
					) : (
						<div className="px-3 pb-5">
							<div className="row mb-3 detail-head">
								<div className="col-1 text-right" />
								<div className="col-11">
									<h4>
										<strong>
											{data.dataDetail.title || '-'}
											<span>-{formatCurrency(dataDetail.amount ? dataDetail.amount : 0)}</span>
										</strong>
									</h4>
									<img
										src={
											(dataDetail &&
                        dataDetail.inchargeUser &&
                        dataDetail.inchargeUser.photoUrl) ||
                      noUserImage
										}
										alt=""
										className="rounded-circle image-thumbnail"
										width="30"
										height="30"
									/>
									<span>
										{(dataDetail.inchargeUser &&
                      dataDetail.inchargeUser.name) ||
                      '-'}
									</span>
								</div>
							</div>
							{activeHeadTab === 1 && <Overview dataDetail={dataDetail} />}
							{activeHeadTab === 2 && <Revenue dataDetail={dataDetail} />}
							{activeHeadTab === 3 && <Activities dataDetail={dataDetail} />}
						</div>
					)}
				</div>

				<Nav className="text-center d-flex justify-content-start tab-custom m-3">
					{/* <NavItem className="m-auto">
            <NavLink
              active={activeTab === 7}
              onClick={() => {
                this.toggle(7);
              }}
            >
              <h2 className="card-title mb-0">Timeline</h2>
            </NavLink>
          </NavItem> */}
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 1}
							onClick={() => {
								this.toggle(1);
							}}
						>
							<h2 className="card-title mb-0">
								<Translate id="overview" />
							</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 3}
							onClick={() => {
								this.toggle(3);
							}}
						>
							<h2 className="card-title mb-0">
								<Translate id="event" />
							</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 4}
							onClick={() => {
								this.toggle(4);
							}}
						>
							<h2 className="card-title mb-0">Notes</h2>
						</NavLink>
					</NavItem>
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 5}
							onClick={() => {
								this.toggle(5);
							}}
						>
							<h2 className="card-title mb-0">Documents</h2>
						</NavLink>
					</NavItem>
					{/* <NavItem className="m-auto">
            <NavLink
              active={activeTab === 6}
              onClick={() => {
                this.toggle(6);
              }}
            >
              <h2 className="card-title mb-0">
                <Translate id="sales operations" />
              </h2>
            </NavLink>
          </NavItem> */}
					<NavItem className="m-auto">
						<NavLink
							active={activeTab === 7}
							onClick={() => {
								this.toggle(7);
							}}
						>
							<h2 className="card-title mb-0">Forms</h2>
						</NavLink>
					</NavItem>
				</Nav>
				{/* {console.log('data', data)} */}
				<div className="tab-content">
					<div
						className={
							activeTab === 1 || activeTab === 3
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Calendar
							data={this.state.eventsData}
							count={this.state.eventsCount}
							id={{
								ownerId: current && current.id,
								opportunityId: id,
								contactId: dataDetail && dataDetail.contactId,
								clientCompanyId: dataDetail && dataDetail.clientCompanyId
							}}
							isFirstPage={data.paginationEvents.currentPage === 1}
							isLastPage={
								data.paginationEvents.currentPage >=
                data.paginationEvents.lastPage
							}
							isLoading={data.isLoadingEvents}
							onFetch={this.onFetchEvents}
						/>
					</div>
					<div
						className={
							activeTab === 1 || activeTab === 4
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Notes
							data={this.state.notesData}
							count={data.paginationNotes.count}
							id={{
								ownerId: current && current.id,
								opportunityId: id,
								contactId: dataDetail && dataDetail.contactId,
								clientCompanyId: dataDetail && dataDetail.clientCompanyId
							}}
							isFirstPage={data.paginationNotes.currentPage === 1}
							isLastPage={
								data.paginationNotes.currentPage >=
                data.paginationNotes.lastPage
							}
							isLoading={data.isLoadingNotes}
							onFetch={this.onFetchNotes}
						/>
					</div>
					<div
						className={
							activeTab === 1 || activeTab === 5
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<Documents
							data={this.state.documentsData}
							count={data.paginationDocuments.count}
							id={{
								ownerId: current && current.id,
								opportunityId: id
							}}
							isFirstPage={data.paginationDocuments.currentPage === 1}
							isLastPage={
								data.paginationDocuments.currentPage >=
                data.paginationDocuments.lastPage
							}
							isLoading={data.isLoadingDocuments}
							onFetch={this.onFetchDocuments}
						/>
					</div>
					{/* <div
            className={
              activeTab === 1 || activeTab === 6
                ? 'tab-pane active'
                : 'tab-pane'
            }
          >
            <SalesOperations
              data={data.dataDetail && data.dataDetail}
              dataSalesOpration={salesOperation && salesOperation}
              id={{
                ownerId: current && current.id,
                clientCompanyId: id,
                opportunityId: match.params.id,
                clientStatusId:
                  dataDetail.clientStatus && dataDetail.clientStatus.id
              }}
              tradeshows={
                salesOperation.dataTradeshows && salesOperation.dataTradeshows
              }
              merchandisings={
                salesOperation.dataMerchadisings &&
                salesOperation.dataMerchadisings
              }
            />
          </div> */}
					<div
						className={
							activeTab === 1 || activeTab === 7
								? 'tab-pane active'
								: 'tab-pane'
						}
					>
						<LinkedFormdata
							data={data.dataDetail && data.dataDetail}
							linkedFormdata={linkedFormdata && linkedFormdata}
							id={{
								ownerId: current && current.id,
								opportunityId: id,
								clientStatusId:
                  dataDetail.clientStatus && dataDetail.clientStatus.id
							}}
							isFirstPage={pagination.currentPage === 1}
							isLastPage={pagination.currentPage >= pagination.lastPage}
							linkedData={this.state.linkedData}
							onFetchLinkedForm={this.onFetchLinkedForm}
							{...this.props}
						/>
					</div>
					{/* <div className={activeTab === 7 ? 'tab-pane active' : 'tab-pane'}>
            <Timeline match={match} />
          </div> */}
				</div>
			</div>
		);
	}
}

export default OpportunitiesDetail;
