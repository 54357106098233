import * as React from 'react';
import { FilterResult } from '../../../components_v2/filter/model/Model';

export interface TableContextType {
	hiddenColumns: string[],
	setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>,
	sortColumns: string[],
	setSortColumns: React.Dispatch<React.SetStateAction<string[]>>,
	filterResult: FilterResult | undefined,
	setFilterResult: React.Dispatch<React.SetStateAction<FilterResult | undefined>>,
	loaded: boolean,
	setLoaded: React.Dispatch<React.SetStateAction<boolean>>,
}

const defaultBehaviour: TableContextType = {
	hiddenColumns: [],
	setHiddenColumns: () => undefined,
	sortColumns: [],
	setSortColumns: () => undefined,
	filterResult: undefined,
	setFilterResult: () => undefined,
	loaded: false,
	setLoaded: () => undefined
};

export const TableContext = React.createContext<TableContextType>(defaultBehaviour);

export function TableProvider(props: {
	children: React.ReactNode
}) {
	const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([]);
	const [sortColumns, setSortColumns] = React.useState<string[]>([]);
	const [filterResult, setFilterResult] = React.useState<FilterResult>();
	const [loaded, setLoaded] = React.useState(false);

	return <TableContext.Provider value={{ hiddenColumns, setHiddenColumns, sortColumns, setSortColumns, filterResult, setFilterResult, loaded, setLoaded }}>
		{props.children}
	</TableContext.Provider>;
}