import { Table } from '../../../../../components_v2/table/Table';
import { ShelfAuditProduct } from '../../../model/models';
import * as React from 'react';
import { TableRow, TableRowTitle } from '../../../../orders/templateOrders/style/Style';
import { Translate } from 'react-localize-redux';
import { Container } from '../../../../client-companies/popup/LinkedElements/style/LinkedElementStyle';
import { translateToString } from '../../../../../styles/global/translate';
import { NoDataText } from '../../../../client-companies/style/Style';
import {
	Link,
	TABLE_COLUMN_XL_WIDTH,
	TABLE_COLUMN_WIDTH,
	TABLE_IMAGE_PADDING,
	TABLE_IMAGE_WIDTH
} from '../../../../products/style';
import noImage from 'images/no-image.jpg';
import { CircleBoolDiv } from '../../../style/styles';
import { GreenSidely, RedSidely } from '../../../../../styles/global/css/Utils';

export default function ShelfAuditAbsentProducts({ absentProducts, openProduct, isFullOpen }: {
    absentProducts: ShelfAuditProduct[],
    openProduct: (productId: number) => void,
    isFullOpen: boolean
}) {
	const columns = [
		{
			id: 'product_image',
			Header:<CircleBoolDiv value={false} style={{ marginLeft:'5px' }}/>,
			accessor: (row: ShelfAuditProduct) => <Link onClick={() => openProduct(row.product_id)}
				background={row.product_image ?? noImage}/>,
			width: TABLE_IMAGE_WIDTH,
			padding: TABLE_IMAGE_PADDING.DEFAULT,
			noDefaultResizeDiv: true,
		},
		{
			id: 'product_name',
			Header: <Translate id='name'/>,
			accessor: (row: ShelfAuditProduct) => <TableRowTitle
				onClick={() => openProduct(row.product_id)}>{row.product_name}</TableRowTitle>,
			minWidth: isFullOpen ? TABLE_COLUMN_XL_WIDTH : TABLE_COLUMN_WIDTH,
		},
		{
			id: 'present_last',
			Header: translateToString('shelf_audit.column.present_last'),
			accessor: (row: ShelfAuditProduct) => <TableRowTitle
				style={{ color: row.present_last ? GreenSidely : RedSidely }}>{translateToString(`shelf_audit.present_last.${row.present_last}`)}</TableRowTitle>,
			minWidth: isFullOpen ? TABLE_COLUMN_XL_WIDTH : TABLE_COLUMN_WIDTH,
		},
		{
			id: 'missing_reason_name',
			Header: translateToString('shelf_audit.column.missing_reason'),
			accessor: (row: ShelfAuditProduct) =>
				<TableRow>{translateToString(row.missing_reason_name ? row.missing_reason_name.toLowerCase() : 'uninformed')}</TableRow>,
			minWidth: TABLE_COLUMN_WIDTH,
		}
	];

	return <Container>
		{absentProducts
			? <Table columns={columns} data={absentProducts} height="100%"/>
			: <NoDataText>{translateToString('shelf_audit.no_data.absent_products')}</NoDataText>
		}
	</Container>;
}