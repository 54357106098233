import styled from 'styled-components';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';

const PhotoContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
`;

const FileContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
`;

const Photo = styled(DefaultImage)`
    width: 30px;
    height: 30px;
`;

const FileBlock = styled.div``;

const FileImage = styled(DefaultImage)``;

const FileName = styled.p`
    ${DefaultText}
`;

const Delete = styled(DefaultImage)`
    cursor: pointer;
`;

const QuotaText = styled.p`
    ${DefaultText}
`;

export {
	PhotoContainer,
	Photo,
	Delete,
	QuotaText,
	FileBlock,
	FileImage,
	FileName,
	FileContainer
};
