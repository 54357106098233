/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CHECKOUT_SUM_STEPS, DEFAULT_MAP_PIN_COLOR, dataFromDistribution, handlePinColor } from './cluster';
import { InitialMapRow, MapData, Point } from './model';
import { FetchKey, MinMax, MinMaxAdditionalColumn } from './MapView';
import { useRecoilValue } from 'recoil';
import { AFrequencyEventType, getAvgColorByFrequencyScore, getDaysDelay, getEmojiByFrequencyScore, getRemainingDays } from '../../atoms/global/frequency';
import { MapContext } from './MapContext';
import { AUsers } from '../../atoms/global/users';
import { SidelyBlack } from '../../styles/global/css/Utils';
import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import { PieChart } from 'react-minimal-pie-chart';
import MapToolTip from './toolTip';
import { IFrequency } from 'proto/protobufs';
import { ToolTipTd } from './style';
import { Translate } from '../../styles/global/translate';
import { HideTrashDiv } from '../products/style';
import { PinLassoPosition } from './lasso';
import { formatCurrency, getColorForPercentage } from '../reports/utils';
import * as React from 'react';
import { Evolution } from '../reports/interpretor/ReportInterpretor';
import { AAdditionalColumns } from '../../atoms/additionalColumns';
import { AAdditionalFieldColumns } from '../../atoms/additionalFieldColumns';
import { genAdditionalColumnPinData, getStepColors } from './mapAdditionalColumns';
import { AAssortments } from '../../atoms/assortment';
import { CompanyStatus } from '../client-companies/model/Model';
import { ACalculatedFields } from '../../atoms/calculatedFields';

export default function MapPin(props: {
  color?: string
  size?: string,
  style?: React.CSSProperties
}): JSX.Element {
	const size = props.size ?? '32';
	return <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='pb-1' style={props.style}>
		<g clipPath="url(#clip0)">
			<g filter="url(#filter0_d)">
				<path fillRule="evenodd" clipRule="evenodd" d="M5 13.0384C5 7.15692 9.74914 2.40002 15.6211 2.40002C21.493 2.40002 26.2422 7.15692 26.2422 13.0384C26.2422 19.3759 19.5357 28.1146 16.7894 31.4429C16.1825 32.1724 15.0749 32.1724 14.4679 31.4429C11.7065 28.1146 5 19.3759 5 13.0384ZM11.8283 13.043C11.8283 15.1403 13.5276 16.8425 15.6215 16.8425C17.7154 16.8425 19.4148 15.1403 19.4148 13.043C19.4148 10.9458 17.7154 9.24361 15.6215 9.24361C13.5276 9.24361 11.8283 10.9458 11.8283 13.043Z" fill={props.color ?? DEFAULT_MAP_PIN_COLOR} />
			</g>
		</g>
		<defs>
			<filter id="filter0_d" x="3" y="-0.399976" width="25.2422" height="33.59" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="-0.8" />
				<feGaussianBlur stdDeviation="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
			<clipPath id="clip0">
				<rect width="32" height="32" fill="white" />
			</clipPath>
		</defs>
	</svg>;
}


export function MapPinComponent(props: {
	cluster: Point,
	companiesInLasso: InitialMapRow[],
	modalOptions: { isOpen: boolean, idSelected: number },
	tabType: FetchKey,
	rangeColor: { firstRange: number, lastRange: number },
	night: boolean,
	addingToLassoOnClick: boolean,
	setIsModalRightOpen: React.Dispatch<React.SetStateAction<boolean>>,
	setCompaniesInLasso: React.Dispatch<React.SetStateAction<InitialMapRow[]>>,
	onMapMarkerCompanyClicked: (companyId: number, noCloseCluster?: true) => void,
	mapData: MapData | undefined,
	statuses: CompanyStatus[],
	minMaxAdditionalColumn: MinMaxAdditionalColumn[],
	minMaxCalculatedFields: MinMaxAdditionalColumn[],
	checkoutsMinMax?: MinMax
}) {
	const selectedFrequencyEventType = useRecoilValue(AFrequencyEventType);
	const { hoveredPin, setHoveredPin } = React.useContext(MapContext);
	const users = useRecoilValue(AUsers);
	const { companiesInLasso, modalOptions, tabType, rangeColor, night, addingToLassoOnClick, setIsModalRightOpen, setCompaniesInLasso, onMapMarkerCompanyClicked } = props;
	const val = props.cluster.properties;
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const assortments = useRecoilValue(AAssortments);
	const calculatedFields = useRecoilValue(ACalculatedFields);

	const indexInLasso = companiesInLasso.findIndex(e => e.id == val.detail.id);
	let child: React.ReactNode;
	let outsideChild: React.ReactNode = undefined;
	let divProps: object;
	if (val.detail.id == modalOptions.idSelected) {
		divProps = {
			style: {
				width: 40,
				height: 40,
				transform: 'translate(-22px, -37px)'
			},
		};
		child = <>
			<MapPin color='#000000' size='40' />
			<div className='text-tooltips font-weight-bold trash' style={{ backgroundColor: night ? SidelyBlack : undefined, color: night ? 'white' : undefined }}>
				{' '}
				{val.detail.name && val.detail.name}
			</div>
		</>;
	} else if (tabType === 'instore') {
		let data: DataEntry[] = [];

		if (val.detail && val.detail.distribution && val.detail.distribution.length >= 7) {
			data = dataFromDistribution(val.detail.distribution, true);
		}
		const style_2 = {
			width: '30px',
			transform: 'translate(-15px, -15px)'
		};
		const style_3 = {
			width: '10px',
			transform: 'translate(-7px, -7px)'
		};

		divProps = {
			style: {
				cursor: 'pointer',
				diplay: 'block',
				position: 'relative',
				zIndex: 0
			}
		};
		child = <>
			<div style={data.length >= 7 ? style_2 : style_3}>
				{data.length >= 7 &&
							<PieChart
								className='circle'
								lineWidth={70}
								center={[50, 50]}
								viewBoxSize={[100, 100]}
								data={data}
							/>
				}
				{data.length < 6 &&
							<span className='circle-dot' />
				}
				<MapToolTip data={data.filter(d => d.value > 0)} instore title={val.detail.name!} night={night}/>
			</div>
		</>;
	} else {
		let pinColor: string | undefined;
		let children: React.ReactNode = undefined;
		let subTitle: string | undefined;
		if (typeof tabType === 'string') {
			switch (tabType) {
				case 'status':
					pinColor = val.color;
					subTitle = props.statuses.find(s => s.id === val.detail.clientStatusId)?.name;
					break;
				case 'per_users': {
					const user = users?.find(o => o.id == val.detail.ownerId);
					pinColor = user?.color;
					subTitle = user?.name;
					break;
				}
				case 'checkouts_sum': {
					if (!val.detail.checkoutEvolution || val.detail.checkoutEvolution.currentSum === null || val.detail.checkoutEvolution.currentSum === undefined || !props.checkoutsMinMax) break;
					const div = (props.checkoutsMinMax.max - props.checkoutsMinMax.min) / CHECKOUT_SUM_STEPS;
					const index = Math.floor(val.detail.checkoutEvolution.currentSum / div);
					pinColor = getStepColors({ r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 }, index, CHECKOUT_SUM_STEPS);
					children = <table>
						<tr><ToolTipTd colSpan={2} center>{formatCurrency(val.detail.checkoutEvolution.currentSum ?? 0)}</ToolTipTd></tr>
						<tr><ToolTipTd><Translate id='before_last_checkout_income'/>:</ToolTipTd><ToolTipTd>{formatCurrency(val.detail.checkoutEvolution.previousSum ?? 0)}</ToolTipTd></tr>
					</table>;
					break;
				}
				case 'checkouts': {
					if (!val.detail.checkoutEvolution || val.detail.checkoutEvolution.evolution === null || val.detail.checkoutEvolution.evolution === undefined) break;
					let perc = val.detail.checkoutEvolution.evolution < 0 ? -val.detail.checkoutEvolution.evolution : val.detail.checkoutEvolution.evolution;
					perc = perc > 100 ? 1 : perc / 100;
					pinColor = getColorForPercentage(perc, 1, val.detail.checkoutEvolution.evolution > 0
						? [
							{ pct: 0, color: { r: 195, g: 252, b: 221 } },
							{ pct: 1, color: { r: 5, g: 119, b: 57 } }
						]
						: [
							{ pct: 0, color: { r: 255, g: 215, b: 212 } },
							{ pct: 1, color: { r: 162, g: 21, b: 9 } }
						]);
					children = <table>
						<tr><ToolTipTd colSpan={2} center><Evolution kind='small' percentage={val.detail.checkoutEvolution.evolution / 100}/></ToolTipTd></tr>
						<tr><ToolTipTd><Translate id='last_checkout_income'/>:</ToolTipTd><ToolTipTd>{formatCurrency(val.detail.checkoutEvolution.currentSum ?? 0)}</ToolTipTd></tr>
						<tr><ToolTipTd><Translate id='before_last_checkout_income'/>:</ToolTipTd><ToolTipTd>{formatCurrency(val.detail.checkoutEvolution.previousSum ?? 0)}</ToolTipTd></tr>
					</table>;
					break;
				}
				case 'frequencies': {
					const frequency: IFrequency | undefined | null = val.detail.frequencies?.[selectedFrequencyEventType];
					if (frequency && frequency.score && frequency.target) {
						pinColor = getAvgColorByFrequencyScore(frequency.score);
						children = <table>
							<tr><ToolTipTd colSpan={2} center>{getEmojiByFrequencyScore(frequency.score)}</ToolTipTd></tr>
							<tr><ToolTipTd><Translate id='frequency_delay'/>:</ToolTipTd><ToolTipTd>{frequency.score.toFixed(2)}</ToolTipTd></tr>
							{frequency.score >= 1 && <tr><ToolTipTd><Translate id='days_delay'/>:</ToolTipTd><ToolTipTd> {getDaysDelay(frequency).toFixed()}</ToolTipTd></tr>}
							{frequency.score < 1 && <tr><ToolTipTd><Translate id='remaining_days'/>:</ToolTipTd><ToolTipTd>{getRemainingDays(frequency).toFixed()}</ToolTipTd></tr>}
						</table>;
					}
				}
					break;
				default:
					pinColor = handlePinColor(val.detail, tabType, rangeColor);
			}
		} else if ('additionalColumn' in tabType) {
			const additionalColumn = additionalColumns.find(ac => ac.id == tabType.additionalColumn);
			if (additionalColumn) {
				const idString = additionalColumn.id.toString();
				const res = genAdditionalColumnPinData(
					val.detail.additionalColumnsValue?.[idString],
					additionalColumn.type,
					rangeColor,
					assortments,
					val.detail,
					props.minMaxAdditionalColumn,
					false,
					idString
				);
				child = res.child;
				pinColor = res.color;
				children = res.children;
				subTitle = res.subTitle;
			}
		} else if ('additionalFieldColumn' in tabType) {
			const additionalColumn = additionalFieldColumns.find(afc => afc.field_id == tabType.additionalFieldColumn);
			if (additionalColumn) {
				const idString = additionalColumn.field_id.toString();
				const res = genAdditionalColumnPinData(
					val.detail.additionalFieldColumnsValue?.[idString],
					additionalColumn.field_type,
					rangeColor,
					assortments,
					val.detail,
					props.minMaxAdditionalColumn,
					true,
					idString
				);
				child = res.child;
				pinColor = res.color;
				children = res.children;
				subTitle = res.subTitle;
			}
		} else if ('calculatedFieldColumn' in tabType) {
			const calculatedFieldColumn = calculatedFields.find(cf => cf.id == tabType.calculatedFieldColumn);
			if (calculatedFieldColumn) {
				const idString = calculatedFieldColumn.id.toString();
				const res = genAdditionalColumnPinData(
					val.detail.calculatedFieldColumnsValue?.[idString],
					'ReportColumn',
					rangeColor,
					assortments,
					val.detail,
					props.minMaxCalculatedFields,
					true,
					idString
				);
				child = res.child;
				pinColor = res.color;
				children = res.children;
				subTitle = res.subTitle;
			}
		}

		divProps = {
			style : {
				transform: child ? undefined : 'translate(-18px, -36px)',
				height: '36px',
				width: '100%',
				diplay: 'block',
				position: 'relative',
				zIndex: 0,
				color: `${tabType === 'status' ? val.color : handlePinColor(val.detail, tabType, rangeColor)}`
			},
		};
		if (!child) outsideChild = <MapToolTip
			instore
			title={val.detail.name!}
			night={night}
			up={!children}
			subTitle={subTitle}
		>
			{children}
		</MapToolTip>;
		if (!child) {
			if (pinColor != DEFAULT_MAP_PIN_COLOR)
				child = <MapPin color={pinColor} />;
			else
				child = <span className='circle-dot' style={{ marginLeft: '10px', marginTop: '10px' }}/>;
		}
	}

	return <HideTrashDiv
		hoverStyle='z-index: 1;'
		hoverTrashStyle='z-index: 1;'
		onMouseEnter={() => indexInLasso !== -1 && setHoveredPin(val.detail.id!)}
		onMouseLeave={() => indexInLasso !== -1 && setHoveredPin(undefined)}
	>
		{outsideChild}
		<div
			{...divProps}
			className='map-pin cursor-pointer crime-marker text-center'
			onClick={() => {
				if (addingToLassoOnClick) {
					setIsModalRightOpen(true);
					setCompaniesInLasso(Array.from(new Set(companiesInLasso.concat([val.detail as InitialMapRow]))));
				} else {
					onMapMarkerCompanyClicked(val.detail.id!);
				}
			}}
		>
			{child}
		</div>
		{indexInLasso !== -1 && <PinLassoPosition position={indexInLasso + 1} selected={hoveredPin === val.id} />}
	</HideTrashDiv>;
}