import { FilterType } from 'bindings/filters/FilterType';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { FilterValue } from 'react-table';
import { useRecoilValue } from 'recoil';
import { ObjectAction } from '../../../../../web-types/objectActions';
import { AAdditionalColumns } from '../../../atoms/additionalColumns';
import { getFields } from '../../../atoms/forms';
import { AUsers } from '../../../atoms/global/users';
import { getEventStatuses, getEventTypes } from '../../../containers_v2/client-companies/data/action';
import { getClientStatuses } from '../../../containers_v2/import/actions';
import { Owner } from '../../../containers_v2/orders/model/Model';
import getScope from '../../../containers_v2/permissions/getScope';
import { getAssortments } from '../../../containers_v2/products/action';
import { TARGET_ENUM_LIST } from '../../../containers_v2/targets/Targets';
import storeLang from '../../../helpers/storeLang';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { Open } from '../../../styles/global/css/Open';
import { BlueSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToString } from '../../../styles/global/translate';
import { checkClickOut } from '../../dropdown/Dropdown';
import DropdownTagsCloud from '../../dropdown/DropdownTagsCloud';
import { Checkbox } from '../../dropdown/style/Style';
import { Radio } from '../../filterList/style/Style';
import InputSearch from '../../input/InputSearch';
import { getTags } from '../../toolbarFilter/tags/action';
import { TagType } from '../../toolbarFilter/tags/Model';
import { ALL_GRANULARITY } from '../../toolbarFilter/time/GranularitySelect';
import { toSnakeCase } from '../../utils';
import {
	FilterAction,
	FilterAdditionalValue,
	FilterColumn,
	FilterId,
	FilterItem,
	FilterList,
	FilterQueryResult,
	FilterTree,
	FilterValueOrValues,
	FilterValueResult
} from '../model/Model';
import {
	Category,
	CategoryBody,
	CategoryFilterRow,
	CategoryFilterText,
	CategoryName,
	Container,
	DropdownContainer,
	DropdownContainerLeft,
	DropdownContainerRight,
	DropdownOptionBody,
	DropdownOptionContainer,
	DropdownOptionHeader,
	FilterText,
	ItemContainer,
	ItemCross,
	ItemText,
	RadioContainer
} from '../style/AdvancedFilterStyle';
import { filterIdFormater } from './FilterSelection';
import { AProducts } from '../../../atoms/product';
import { FlexDiv } from '../../../containers_v2/products/style';
import { getMetadataPeriods } from '../../../containers_v2/forms/actions';
import { ACampaigns } from '../../../atoms/campaignAtom';

const sortOwners = (a: Owner, b: Owner): number => {
	if (a.isYou) return -1;
	if (b.isYou) return 1;
	return a.name.localeCompare(b.name);
};

function switchFilterToIndex(filter: FilterAction | undefined): number | undefined {
	switch (filter) {
		case FilterAction.IS:
			return 0;
		case FilterAction.IS_NOT:
			return 1;
		case FilterAction.CONTAINS_LIST:
			return 2;
		case FilterAction.DOES_NOT_CONTAINS_LIST:
			return 3;
		case FilterAction.IS_EMPTY:
			return 4;
		case FilterAction.IS_NOT_EMPTY:
			return 5;
		default:
			return;
	}
}

function ListFilter(props: {
	id: FilterId
	title: string
	list?: FilterList
	onReturn: () => void
	onSubmit: (value: FilterValueResult) => void
	type?: FilterType
	additionalValue?: FilterAdditionalValue
	permission?: ObjectAction
	selectedValue: { filter: FilterValueResult, tree: FilterTree } | undefined
	tagType: TagType | undefined,
	overflow?: string
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	let selectedValue: FilterQueryResult | undefined;
	if (props.selectedValue && 'val' in props.selectedValue.tree) {
		selectedValue = props.selectedValue.tree.val;
	}

	const [index, setindex] = React.useState<number | undefined>(switchFilterToIndex(props.selectedValue?.filter?.action));
	const [values, setValues] = React.useState<FilterItem[]>([]);
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [search, setSearch] = React.useState<string>('');
	const [list, setList] = React.useState<FilterList>(props.list ?? { categories: [] });
	const [filterColumns, setFilterColumns] = React.useState<FilterColumn[]>(props.list?.categories?.map((c, i) => ({ id: i, isOpen: true })) ?? []);
	const owners = useRecoilValue(AUsers);
	const scope = getScope(props.permission);
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const isAdditionalColumns = props.type === 'multi_select' || props.type === 'select';
	const products = useRecoilValue(AProducts);
	const campaigns = Object.values(useRecoilValue(ACampaigns));

	const wrapperRef = React.useRef<HTMLDivElement>(null);

	checkClickOut(wrapperRef, setOpen);

	const id = filterIdFormater(props.id, props.additionalValue);

	function resetValues(items?: any[]) {
		if (!items && !list.categories[0]?.items) {
			setValues([]);
			return;
		}
		switch (props.type) {
			case 'tag': {
				const selectedValues = selectedValue?.value?.split(/(.*?\|)/g).map(s => s.split(/(.*?&)/g)).reduce((acc, s) => [...acc, ...s]).filter(s => s).map(s => {
					const last = s.slice(-1);
					if (last === '&' || last === '|') return { value: s.slice(0, -1), operator: last };
					return { value: s, operator: '&' };
				});
				if (!selectedValues) return;
				const values = selectedValues.reduce((acc, { value, operator }) => {
					const item = (items ?? list.categories[0].items).find(t => t.id!.toString() === value);
					if (!item) return acc;
					return [...acc, {
						id: item.id,
						label: item.label,
						value: {
							tag: item.value,
							operator: operator == '&' ? 'and' : 'or'
						}
					}];
				}, []);
				setValues(values);
				return;
			}
			default: {
				const selectedValues = selectedValue?.value?.split(',');
				if (!selectedValues) return;
				const values = selectedValues.reduce((acc, value) => {
					const item = (items ?? list.categories[0].items).find(t => t.id!.toString() === value);
					if (!item) return acc;
					return [...acc, {
						id: item.id,
						label: item.label,
						value: item.value
					}];
				}, []);
				setValues(values);
				return;
			}
		}
	}

	React.useEffect(() => {
		switch (props.type) {
			case 'multi_select':
			case 'select': {
				if (typeof props.id == 'string') return;
				if ('additional_field_columns' in props.id) {
					const fieldId = props.id.additional_field_columns[0];
					getFields().then(res => {
						const field = res.find(f => f.id == fieldId);
						if (!field?.data || !Array.isArray(field.data)) return;
						const items = field.data.map(item => ({ label: item, id: item, value: item }));
						resetValues(items);
						setList({
							categories: [{
								name: 'Select',
								items
							}]
						});
					});
				} else if ('additional_columns' in props.id) {
					const fieldId = props.id.additional_columns[0].id;
					const field = additionalColumns.find(f => f.id == fieldId);
					if (!field?.data || !Array.isArray(field.data)) return;
					const items = field.data.map(item => ({ label: item, id: item, value: item }));
					resetValues(items);
					setList({
						categories: [{
							name: 'Select',
							items
						}]
					});
				}
				break;
			}
			case 'catalogue': {
				getAssortments().then(res => {
					const items = res.data.assortments.map(e => ({ label: e.name, value: e, id: e.id }));
					resetValues(items);
					setList({
						categories: [{
							name: 'Assortments',
							items
						}]
					});
				});
				break;
			}
			case 'campaign': {
				const items = campaigns.map(p => ({ label: p.name, value: p, id: p.id }));
				resetValues(items);
				setList({
					categories: [{
						name: 'Campaigns',
						items
					}]
				});
				break;
			}
			case 'period': {
				getMetadataPeriods().then(res => {
					const items = res.map(p => ({ label: p.metadata, value: { id: p.metadata, name: p.metadata }, id: p.metadata }));
					resetValues(items);
					setList({
						categories: [{
							name: 'Periods',
							items
						}]
					});
				});
				break;
			}
			case 'product': {
				const items = products.map(p => ({ label: p.name, value: p, id: p.uuid }));
				resetValues(items);
				setList({
					categories: [{
						name: 'Products',
						items
					}]
				});
				break;
			}
			case 'granularity': {
				const items = ALL_GRANULARITY.map(g => ({ id: g, label: translateToString(g), value: { id: g, name: translateToString(g) } }));
				resetValues(items);
				setList({
					categories: [{
						name: 'Granularities',
						items
					}]
				});
				break;
			}
			case 'target_enum': {
				const items = TARGET_ENUM_LIST.map(g => ({ id: g, label: g, value: { id: toSnakeCase(g), name: g } }));
				resetValues(items);
				setList({
					categories: [{
						name: 'TargetEnum',
						items
					}]
				});
				break;
			}
			case 'account_type': {
				type AccountType = 'client' | 'demo' | 'template' | 'trial';
				const ALL_ACCOUNT_TYPES: AccountType[] = ['client', 'demo', 'template', 'trial'];
				const items = ALL_ACCOUNT_TYPES.map(g => ({ id: g, label: g, value: { id: toSnakeCase(g), name: g } }));
				resetValues(items);
				setList({
					categories: [{
						name: 'AccountType',
						items
					}]
				});
				break;
			}
			case 'tag':
				getTags(props.tagType ?? TagType.COMPANY).then(response => {
					const items = response.sort((a, b) => a.name!.localeCompare(b.name!)).map(t => ({
						id: t.id!,
						label: t.name!,
						value: t
					}));
					resetValues(items);
					setList({
						categories: [{
							name: 'Tags',
							items
						}]
					});
				});
				break;
			case 'user': {
				const items = owners.map(r => ({ id: r.id, label: `${r.name}${r.isYou ? ` (${translateToString('me')})` : ''}`, value: r }));
				resetValues(items);
				setList({
					categories: [
						{
							name: translate('global.users').toString(),
							items: owners.filter(o => o.status && (scope === 'User' ? o.isYou : true)).sort(sortOwners).map(r => ({
								id: r.id,
								label: `${r.name}${r.isYou ? ` (${translateToString('me')})` : ''}`,
								value: r
							}))
						},
						{
							name: translate('global.inactive_users').toString(),
							items: owners.filter(o => !o.status && (scope === 'User' ? o.isYou : true)).sort(sortOwners).map(r => ({
								id: r.id,
								label: `${r.name}${r.isYou ? ` (${translateToString('me')})` : ''}`,
								value: r
							}))
						}
					]
				});
				break;
			}
			case 'status':
				getClientStatuses().then(response => {
					const items = response.data.sort((a, b) => a.sequence - b.sequence).map(r => ({
						id: r.id,
						label: r.name,
						value: r
					}));
					resetValues(items);
					setList({
						categories: [{
							name: 'Statuses',
							items
						}]
					});
				});
				break;
			case 'event_status_id':
				getEventStatuses().then(response => {
					const items = response.sort((a, b) => a.name.localeCompare(b.name)).map(r => {
						r.name = translate(r.name).toString();
						return {
							id: r.id,
							label: r.name,
							value: r
						};
					});
					resetValues(items);
					setList({
						categories: [{
							name: 'Statuses',
							items
						}]
					});
				});
				break;
			case 'event_type_id':
				getEventTypes().then(response => {
					const items = response.sort((a, b) => a.name.localeCompare(b.name)).map(r => {
						r.name = translate(`event.${r.name}`).toString();
						return {
							id: r.id,
							label: r.name,
							value: r
						};
					});
					resetValues(items);
					setList({
						categories: [{
							name: 'Types',
							items
						}]
					});
				});
				break;
		}
	}, []);

	React.useEffect(() => {
		setOpen(false);
	}, [index]);

	React.useEffect(() => {
		setFilterColumns(list.categories.map((_, id) => ({ id, isOpen: true })));
	}, [JSON.stringify(props.list), JSON.stringify(list)]);

	function submit(): void {
		if (!(values.length > 0 || index === 4 || index === 5)) {
			return;
		}

		// @ts-expect-error Retro compability
		const type: FilterType = props.type ?? 'list';
		let action: FilterAction;
		const b: FilterValueOrValues<FilterValue> = { values };

		switch (index) {
			case 0:
				action = FilterAction.IS;
				b.values = values.map(v => v.value);
				break;
			case 1:
				action = FilterAction.IS_NOT;
				b.values = values.map(v => v.value);
				break;
			case 2:
				action = FilterAction.CONTAINS_LIST;
				b.values = values.map(v => v.value);
				break;
			case 3:
				action = FilterAction.DOES_NOT_CONTAINS_LIST;
				b.values = values.map(v => v.value);
				break;
			case 4:
				action = FilterAction.IS_EMPTY;
				break;
			case 5:
				action = FilterAction.IS_NOT_EMPTY;
				break;
			default:
				return;
		}

		props.onSubmit({
			id,
			title: props.title,
			type,
			action,
			titleComment: props.additionalValue?.titleComment,
			...b
		});
	}

	const dropdown = React.useMemo(() => {
		if (props.type == 'tag') {
			return <DropdownTagsCloud
				emptyWhenNone
				tags={list.categories[0]?.items.map(i => i.value) ?? []}
				selected={values.map(i => i.value.tag) ?? []}
				operators={['and', ...values.map(t => t.value.operator ?? 'and')]}
				onChange={(tags, operators) => {
					setValues(tags.map((t, i) => ({ label: t.name!, value: { tag: t, operator: operators[i + 1] ?? 'and' }, id: t.id! })));
				}}
				onDelete={tagId => {
					const index = values.findIndex(t => t.id == tagId);
					if (index >= 0) {
						values.splice(index, 1);
						setValues([...values]);
					}
				}}
				combination
			/>;
		}
		return (
		// @ts-expect-error ref
			(<DropdownContainer onClick={() => setOpen(!isOpen)} innerRef={wrapperRef}>
				<DropdownContainerLeft>
					{
						values
							.sort((a, b) => a.label.localeCompare(b.label))
							.map((v, i) => {
								return (
									<ItemContainer key={`ListFilterDropdownContainerValue[${i}]`}>
										<ItemText>{v.label}</ItemText>
										<ItemCross onClick={(e) => {
											e.stopPropagation();
											setValues(values.filter(value => value.label !== v.label));
										}}>x</ItemCross>
									</ItemContainer>
								);
							})
					}
				</DropdownContainerLeft>
				<DropdownContainerRight>
					<Open isOpen={isOpen} />
				</DropdownContainerRight>
				{
					isOpen &&
					<DropdownOptionContainer onClick={(e) => e.stopPropagation()}>
						<DropdownOptionHeader>
							<InputSearch placeholder={translate('global.search').toString()} name='search_list_filter' type='text' onChange={(value) => setSearch(value)} />
						</DropdownOptionHeader>
						<DropdownOptionBody>
							{
								list.categories
									.filter(f => f.items.filter(item => item.label.toLowerCase().includes(search.toLocaleLowerCase())).length > 0)
									.map((c, i) => {
										return (
											<>
												{
													list.categories.length > 1 &&
													<Category onClick={() => {
														const filter = filterColumns.find(f => f.id === i);
														if (filter != null) {
															filter.isOpen = !filter.isOpen;
															setFilterColumns(filterColumns.filter(f => f.id !== filter.id).concat(filter));
														}
													}}>
														<CategoryName>{c.name}</CategoryName>
														<Open isOpen={filterColumns.find(f => f.id === i)?.isOpen ?? true} />
													</Category>
												}
												{
													c.items.length > 0 &&
													<CategoryBody isOpen={filterColumns.find(f => f.id === i)?.isOpen ?? true}>
														{
															c.items
																.filter(item => item.label.toLowerCase().includes(search.toLocaleLowerCase()))
															// .sort((a, b) => a.label.localeCompare(b.label))
																.map((item, i) => {
																	return (
																		<CategoryFilterRow key={`ListfilterCategoryBody[${i}]`} onClick={() => {
																			if (values.find(v => v.id === item.id) !== undefined) {
																				setValues(values.filter(v => v.id !== item.id));
																			} else {
																				setValues(values.concat(item));
																			}
																		}}>
																			<FlexDiv gap='12px'>
																				<Checkbox isActive={values.find(v => v.id === item.id) !== undefined} />
																				<CategoryFilterText margin="0 0 0 5px">{item.label}</CategoryFilterText>
																			</FlexDiv>
																		</CategoryFilterRow>
																	);
																})
														}
													</CategoryBody>
												}
											</>
										);
									})
							}

						</DropdownOptionBody>
					</DropdownOptionContainer>
				}
			</DropdownContainer>)
		);
	}, [JSON.stringify(props.list), list, isOpen, JSON.stringify(values), search, JSON.stringify(filterColumns)]);

	return (
		<Container overflow={props.overflow}>
			<FilterText color={BlueSidely} cursor="pointer" fontSize="11px" onClick={() => props.onReturn()}>&lt;<Translate id='back' /></FilterText>
			<FilterText margin="10px 0" color={SidelyBlack} fontWeight="500" fontSize="14px">{props.title}</FilterText>
			<RadioContainer onClick={() => setindex(0)}>
				<Radio isActive={index === 0} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is' /></FilterText>
			</RadioContainer>
			{
				index === 0 &&
				dropdown
			}
			<RadioContainer onClick={() => setindex(1)}>
				<Radio isActive={index === 1} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not' /></FilterText>
			</RadioContainer>
			{
				index === 1 &&
				dropdown
			}
			{isAdditionalColumns && <RadioContainer onClick={() => setindex(2)}>
				<Radio isActive={index === 2} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='contains' /></FilterText>
			</RadioContainer>}
			{
				index === 2 &&
				dropdown
			}
			{isAdditionalColumns && <RadioContainer onClick={() => setindex(3)}>
				<Radio isActive={index === 3} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='do_not_contains' /></FilterText>
			</RadioContainer>}
			{
				index === 3 &&
				dropdown
			}
			{!props.type?.includes('account_type') && <RadioContainer onClick={() => setindex(4)}>
				<Radio isActive={index === 4} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_empty' /></FilterText>
			</RadioContainer>}
			{!props.type?.includes('account_type') && <RadioContainer onClick={() => setindex(5)}>
				<Radio isActive={index === 5} />
				<FilterText fontSize="14px" color="rgba(37, 65, 83, 0.7)" margin="0 0 0 5px"><Translate id='is_not_empty' /></FilterText>
			</RadioContainer>}

			<DefaultButton width="125px" disabled={
				!(values.length > 0 || index === 4 || index === 5)
			} onClick={submit}><Translate id='add_filter' /></DefaultButton>
		</Container>
	);
}

export default ListFilter;
