import * as React from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { BlueSidely, SidelyBlack } from '../../styles/global/css/Utils';
import { getLeftOrRightImage } from '../map/modalLeft/ModalLeftFilter';
import { FormTemplate } from '../forms/jsonValidator';


const GalleryText = styled.div<{ selected: boolean }>`
	color: ${p => p.selected ? BlueSidely : ''};
	transition: color 0.1s;

	&:hover {
		color: ${BlueSidely};
	}
`;

const GalleryTiles = styled.div`
  height: 53px;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
`;

export function GalleryModalLeft(
	props: {
		setSelected: (type: FormTemplate) => void
		selected: FormTemplate
		templateList: FormTemplate[]
		isModalOpen: boolean
		setIsModalOpen: (type: boolean) => void
	}
): JSX.Element {

	return (
		<div className="filter-modal" style={{ marginTop: '52px', height: 'calc(100% - 107px)', marginLeft: -10, zIndex: 2 }}>
			<div className={props.isModalOpen ? 'box-filter-modal' : 'd-none'} style={{ width: '261.2px' }}>
				<h5 className="p-3" style={{ color: SidelyBlack }}>
					<Translate id="forms" />
				</h5>
				<Nav tabs vertical className="nav-pin">
				{...props.templateList.map(template =>
					<GalleryTiles key={`Typelist[${template.name}]`}>
						<NavItem key={template.name} >
							<NavLink onClick={_e => {
								props.selected !== template && props.setSelected(template);
							}}>
								{props.selected == template && <div className="active-block" />}
								<GalleryText selected={props.selected == template}>
									{template.name}
								</GalleryText>
							</NavLink>
						</NavItem>
					</GalleryTiles>
				)}
				</Nav>
			</div>
			<button
				type="button"
				className="btn btn-filter-modal"
				onClick={() => props.setIsModalOpen(!props.isModalOpen)}
			>
				<img
					src={getLeftOrRightImage(props.isModalOpen)}
					alt=""
				/>
			</button>
		</div>
	);
}

export default GalleryModalLeft;
