import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useStore } from 'react-redux';
import { Redirect } from 'react-router';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../../atoms/global/users';
import Dropdown from '../../../../../components_v2/dropdown/Dropdown';
import DropdownSearch from '../../../../../components_v2/dropdown/DropdownSearch';
import { DropdownData } from '../../../../../components_v2/dropdown/model/Model';
import Input from '../../../../../components_v2/input/Input';
import storeLang from '../../../../../helpers/storeLang';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { getCompanyDetail } from '../../../../client-companies/data/action';
import { CompanyDetail } from '../../../../client-companies/model/Model';
import { LoadingState, LoadingStateEnum } from '../../../../import/model';
import { getFilteredCompanies, getOrder } from '../../../data/Data';
import { getOrderCreator, getSubTotalTTC, setOrderCreator } from '../../../global/globalFunction';
import { ClientCompany, Contact, Opportunity, Order, OrderCreator, Owner, POURCENTAGE, Product, SelectedProduct } from '../../../model/Model';
import { Body, Button, Container, Footer, Header, PopupRow, Title } from '../style/PopupNewOrderStyle';

function submit_create(
	selectedCompany: ClientCompany | null,
	selectedContact: any,
	selectedOwner: Owner | null,
	selectedOpportunity: any,
	selectedName: string,
	order: Order | undefined
): Order | null {
	if (selectedName.trim().length === 0 || (selectedCompany == null) || (selectedOwner == null) || (order == null)) {
		return null;
	}

	const newOrder = {
		...order,
		reference: selectedName,
		client_company_id: selectedCompany.id,
		owner_id: selectedOwner.id,
		owner_name: selectedOwner.name,
		owner_photo: selectedOwner.photoUrl
	} as Order;

	return newOrder;
}

function submit(
	selectedCompany: ClientCompany | null,
	selectedContact: any,
	selectedOwner: Owner | null,
	selectedOpportunity: any,
	selectedName: string,
	order: OrderCreator
): boolean {
	if ((selectedCompany == null) || (selectedOwner == null)) {
		return false;
	}
	setOrderCreator(selectedName, selectedCompany, null, selectedOwner, selectedContact, selectedOpportunity, order.selectedProducts ? order.selectedProducts : [], order.discount ? order.discount : 0, order.discountType ? order.discountType : POURCENTAGE, order.order_status_id, order.payment_id, order.comment);
	return true;
}

function getCompanyOptions(companies: ClientCompany[]): DropdownData[] {
	return companies.map(company => {
		return {
			label: company.name,
			value: company
		} as DropdownData;
	});
}

function getOwnerOptions(owners: Owner[]): DropdownData[] {
	return owners.map(owner => {
		return {
			label: owner.name,
			value: owner,
			image: owner.photoUrl,
			selected: owner.isYou
		} as DropdownData;
	});
}

function getContactOptions(contacts: Contact[]): DropdownData[] {
	return contacts.map(c => {
		return {
			label: c.lastName + ' ' + c.firstName,
			value: c,
			image: c.photoUrl,
			selected: contacts[0].id === c.id
		} as DropdownData;
	});
}

function getOpportunityOptions(opportunities: Opportunity[]): DropdownData[] {
	return opportunities.map(o => {
		return {
			label: o.title,
			value: o,
			selected: opportunities[0].id === o.id
		} as DropdownData;
	});
}

function createDuplicateOrderCreator(order: Order): OrderCreator {
	const products = order.products?.map((p, index) => {
		return {
			id: index,
			amount: p.quantity,
			discount: (p.discount * 100.0) ?? 0,
			discountType: p.discount_id ?? POURCENTAGE,
			name: p.product_name,
			tax: (p.product_tax * 100.0) ?? 0,
			price: p.price,
			productImage: (p.photo_urls != null) && p.photo_urls?.length > 0 ? p.photo_urls[0] : '',
			HTPrice: getSubTotalTTC(
				p.price,
				p.quantity,
				(p.discount * 100.0),
				p.discount_id,
				(p.product_tax * 100.0)),
			product: {
				photo_urls: p.photo_urls,
				name: p.product_name,
				outer: 1,
				id: p.product_id,
				reference: p.product_reference ?? '',
				barcode: p.product_barcode ?? '',
				code_upc: p.product_upc_code ?? '',
				brand_id: p.brand_id,
				category_id: p.category_id,
				category_name: p.category_name
			} as Product
		} as SelectedProduct;
	});

	const orderCreator = {
		selectedProducts: products,
		order_status_id: order.order_status_id,
		payment_id: order.payment_id,
		comment: order.comment,
		discount: (order.discount * 100.0),
		discountType: order.discount_id
	} as OrderCreator;

	return orderCreator;
}

function PopupNewOrder(props: {
  onClose?: Function
  orderId?: number
  onCreate?: Function
  clientCompanyId?: number
}): JSX.Element {
	const user = useStore().getState().user?.current;
	const translate = getTranslate(storeLang.getState().localize);

	const [redirect, setRedirect] = React.useState<JSX.Element>(<></>);

	const [order, setOrder] = React.useState<OrderCreator>(getOrderCreator());
	const [optionClientCompanies, setOptionClientCompanies] = React.useState<DropdownData[] | null>(null);
	const [optionOwners, setOptionOwners] = React.useState<DropdownData[] | null>(null);
	const [optionContacts, setOptionContacts] = React.useState<DropdownData[] | null>(null);
	const [optionOpportunities, setOptionOpportunities] = React.useState<DropdownData[] | null>(null);

	const [selectedName, setSelectedName] = React.useState<string>(order.name ? order.name : '');
	const [selectedCompany, setSelectedCompany] = React.useState<ClientCompany | null>(order.company ? order.company : null);
	const [selectedOwner, setSelectedOwner] = React.useState<Owner | null>(order.owner ? order.owner : null);
	const [selectedContact, setSelectedContact] = React.useState<any>(null);
	const [selectedOpportunity, setSelectedOpportunity] = React.useState<any>(null);
	const [orderDup, setOrderDup] = React.useState<Order>();
	const usersResponse = useRecoilValue(AUsers);

	const [loadingState, setLoadingState] = React.useState<LoadingState>(LoadingStateEnum.LOADING);

	const [companyDetail, setCompanyDetail] = React.useState<CompanyDetail>();

	React.useEffect(() => {
		const selected = optionClientCompanies?.find(o => o.selected);
		if (selected != null) {
			setSelectedCompany(selected.value);
		}
	}, [JSON.stringify(optionClientCompanies)]);

	React.useEffect(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		const apis = async() => {
			// getClientCompanies().then(response => {
			//     setOptionClientCompanies(getCompanyOptions(response));
			//     selectedCompany == null && setSelectedCompany(response.length !== 0 ? response[0] : null);
			// });
			// const usersResponse = await getUsers(user?.id || -1);
			setOptionOwners(getOwnerOptions(usersResponse));
			const owner = usersResponse.find(u => u.isYou);
			selectedOwner == null && setSelectedOwner((owner != null) ? owner : (usersResponse.length > 0 ? usersResponse[0] : null));
			// .then(response => {
			//     setOptionOwners(getOwnerOptions(response, selectedOwner));
			//     selectedOwner == null && setSelectedOwner(response.length !== 0 ? response[0] : null);
			// }).catch(error => {
			//     console.log(error);
			// });

			if (props.orderId) {
				getOrder(props.orderId).then(response => {
					setOrderDup(response);
					setOrder(createDuplicateOrderCreator(response));
					setSelectedName(response.reference);
					setSelectedOwner(usersResponse.find(u => u.id == response.owner_id) ?? null);

					// setSelectedOwner(optionOwners?.find(own => own.id == response.owner_id))

					// getUser(response.owner_id).then(response_u => {
					//     setSelectedOwner(response_u);
					// });

					// getClientCompany(response.client_company_id).then(response_cc => {
					//     setSelectedCompany(response_cc);
					// });
				});
			}

			if (props.clientCompanyId) {
				getCompanyDetail(props.clientCompanyId).then(response => {
					if (response != null) {
						setCompanyDetail(response);
					}
				});
			}

			// getContacts().then(response => {
			//     setOptionContacts(getContactOptions(response));
			//     //setSelectedContact(response.length !== 0 ? response[0] : null);
			// }).catch(error => {
			//     console.log(error);
			// });

			// getOpportunities().then(response => {
			//     setOptionOpportunities(getOpportunityOptions(response));
			//     //setSelectedOpportunity(response.length !== 0 ? response[0] : null);
			// }).catch(error => {
			//     console.log(error);
			// });
		};
		apis();
	}, [props.orderId]);

	React.useEffect(() => {
		if (optionOwners != null) {
			setLoadingState(LoadingStateEnum.LOADED);
		}
	}, [optionOwners]);

	if (loadingState === LoadingStateEnum.LOADING) {
		return (
			<Loader width="30px" center />
		);
	}

	return (
		<Container>
			{ redirect }
			<Header>
				<PopupRow>
					<Title>Création</Title>
				</PopupRow>
			</Header>
			<Body>
				<PopupRow>
					<Dropdown
						datalist={(optionOwners != null) ? optionOwners : []}
						name="ownerId"
						label={translate('orders.templateOrder.field.owner').toString()}
						placeholder="pick_owner"
						required
						dropdownStyle={{ optionHeight: '200px', width: '300px', optionWidth: '300px', height: '40px' }}
						readOnly
						onChange={(value: DropdownData) => setSelectedOwner(value.value)}
						selectedValue={optionOwners?.find(o => o.value == selectedOwner)}
					/>
				</PopupRow>
				<PopupRow>

					<DropdownSearch
						datalist={optionClientCompanies ?? []}
						name="clientCompanyName"
						label={translate('orders.templateOrder.field.company').toString()}
						required
						optionHeight={200}
						width={300}
						link={'/companies?id='}
						onChange={(value) => setSelectedCompany(value?.value)}
						selectedValue={optionClientCompanies?.find(comp => JSON.stringify(comp.value) == JSON.stringify(selectedCompany)) || null}
						defaultSearch={companyDetail?.company_name}
						onSearchChange={async(search, offset) => {
							return await getFilteredCompanies(search, 20, offset)
								.then(res => {
									const ret: boolean = res.data.length == 0 || res.data.length < 20;

									if (offset !== undefined) {
										setOptionClientCompanies([...((optionClientCompanies != null) ? optionClientCompanies : []), ...res.data.map((company: ClientCompany) => {
											return {
												label: company.name,
												value: company,
												selected: props.clientCompanyId ? company.id === props.clientCompanyId : false
											};
										})]);
									} else {
										setOptionClientCompanies(res.data.map(company => {
											return {
												label: company.name,
												value: company,
												selected: props.clientCompanyId ? company.id === props.clientCompanyId : false
											};
										}));
									}
									return ret;
								})
								.catch(error => {
									console.log(error);
									return false;
								});
						}}
					/>
				</PopupRow>
				<PopupRow>
					<Input
						name="order_name"
						type="text"
						label={translate('orders.templateOrder.field.commandName').toString()}
						value={selectedName}
						maxLength={255}
						inputStyle={{ width: '300px', height: '40px' }}
						onChange={(value) => setSelectedName(value)}
					/>
				</PopupRow>
				{
					/**
                    <PopupRow>
                    <Dropdown
                        datalist={optionContacts}
                        name="contact"
                        label="Contact"
                        optionHeight={200}
                        onChange={(value) => setSelectedContact(value.value)}
                    />
                </PopupRow>
                <PopupRow>
                    <Dropdown
                        datalist={optionOpportunities}
                        name="opportunity"
                        label="Opportunity"
                        optionHeight={200}
                        onChange={(value) => setSelectedOpportunity(value.value)}
                    />
                </PopupRow>

                        */
				}
			</Body>
			<Footer>
				<PopupRow flexDirection="center">
					<Button disabled={(selectedCompany == null) || (selectedOwner == null)} type="button" onClick={() => {
						const r = submit(
							selectedCompany,
							selectedContact,
							selectedOwner,
							selectedOpportunity,
							selectedName,
							order
						);
						if (r) {
							props.onClose?.();
							setRedirect(<Redirect push to="/orders/select-products" />);
						}
					}}><Translate id='continue'/></Button>
				</PopupRow>
			</Footer>
		</Container>
	);
}

export default PopupNewOrder;
