import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { getLang, stringToColour } from '../utils';

function filterData(filters: any, data: any) {
	data.datasets = data.datasets.filter(dataset => {
		return filters.filter(f => f == dataset.label).length > 0;
	});
	return data;
}

export default function PresencePerProductChart(props: {
  data: any
  filters: string[]
}) {
	const { data, filters } = props;
	console.log(filters);
	const translate = getTranslate(storeLang.getState().localize);
	const datasets: any[] = [];

	if (data.datasets != null && data.datasets != undefined) {
		for (const dataset of data.datasets) {
			const color = stringToColour(dataset.label);
			datasets.push({
				label: dataset.label,
				data: dataset.data,
				borderColor: color,
				backgroundColor: color + '80',
				lineTension: 0.1,
				borderWidth: 1
			});
		}
	}
	let labels = [];
	if (data.x_axis != null && data.x_axis != undefined && data.x_axis.length > 0) {
		labels = data.x_axis.map(x_axis_label => {
			const date = new Date(x_axis_label);
			const now = new Date(Date.now());
			if (date.getDay() == now.getDay() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
				return translate('shelf_audit.sections.misc.today').toString();
			} else {
				return date.toLocaleString(getLang(), { month: 'short', year: 'numeric' });
			}
		});
	}
	const formated_data = {
		labels,
		datasets
	};

	const options = {
		responsive: true,
		plugins: {
			datalabels: {
				display: function() {
					return false;
				},
			},
			legend: {
				position: 'bottom' as const
			},
			title: {
				display: true
				// text: 'Chart.js Line Chart'
			}
		},
		scales: {
			y: {
				beginAtZero: true
			}
		}
	};

	return <Line
		options={options}
		data={filterData(filters, formated_data)}
	/>;
}
