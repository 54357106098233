const normalizePhone = value => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 12) {
		return onlyNums;
	}
};

export default normalizePhone;
