import {
	CREATE_FORMBUILDER_ERROR,
	CREATE_FORMBUILDER_REQUEST,
	CREATE_FORMBUILDER_SUCCESS,
	CREATE_ICONS_ERROR,
	CREATE_ICONS_REQUEST,
	CREATE_ICONS_SUCCESS,
	DETAIL_FORMBUILDER_ERROR,
	DETAIL_FORMBUILDER_REQUEST,
	DETAIL_FORMBUILDER_SUCCESS,
	FETCH_FORMBUILDER_EMPTY,
	FETCH_FORMBUILDER_ERROR,
	FETCH_FORMBUILDER_REQUEST,
	FETCH_FORMBUILDER_SUCCESS,
	FETCH_ICONS_ERROR,
	FETCH_ICONS_REQUEST,
	FETCH_ICONS_SUCCESS,
	SELECT_USER_FORM,
	SET_MODAL_DUPLICATE,
	UPDATE_FORMBUILDER_ERROR,
	UPDATE_FORMBUILDER_REQUEST,
	UPDATE_FORMBUILDER_SUCCESS,
	UPLOAD_FILE_ERROR,
	UPLOAD_FILE_REQUEST,
	UPLOAD_FILE_SUCCESS
} from '../actions/formbuilder.action';

const initState = {
	data: [],
	file: null,
	icons: [],
	selectUserForm: [],
	dataDetail: null,
	search: '',
	sortField: null,
	sort: 'desc',
	isLoading: false,
	isLoadingCreateIcon: false,
	isLoadingDetail: false,
	isUploading: false,
	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},
	activeFilter: {
		expenseStatusId: null
	},

	duplicateForm: {
		id: null,
		name: null
	}
};

export default function CompaniesReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_FORMBUILDER_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_FORMBUILDER_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.meta.current_page,
					lastPage: action.payload.meta.last_page,
					count: action.payload.meta.count,
					recordPerPage: action.payload.meta.record_per_page
				},
				isLoading: false
			};
		case FETCH_FORMBUILDER_ERROR:
			return {
				...state,
				isLoading: false
			};
		case FETCH_FORMBUILDER_EMPTY:
			return {
				...state,
				data: []
			};

		case FETCH_ICONS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case FETCH_ICONS_SUCCESS: {
			return {
				...state,
				icons: action.payload.data ? action.payload.data : [],
				isLoading: false
			};
		}

		case FETCH_ICONS_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_ICONS_REQUEST:
			return {
				...state,
				isLoadingCreateIcon: true
			};

		case CREATE_ICONS_SUCCESS: {
			return {
				...state,
				isLoadingCreateIcon: false
			};
		}

		case CREATE_ICONS_ERROR:
			return {
				...state,
				isLoadingCreateIcon: false
			};

		case CREATE_FORMBUILDER_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case CREATE_FORMBUILDER_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};

		case CREATE_FORMBUILDER_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_FORMBUILDER_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_FORMBUILDER_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoading: false
			};

		case UPDATE_FORMBUILDER_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DETAIL_FORMBUILDER_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_FORMBUILDER_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_FORMBUILDER_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case UPLOAD_FILE_REQUEST:
			return {
				...state,
				isUploading: true
			};

		case UPLOAD_FILE_SUCCESS:
			return {
				...state,
				file: action.payload.data,
				isUploading: false
			};

		case UPLOAD_FILE_ERROR:
			return {
				...state,
				isUploading: false
			};

		case SET_MODAL_DUPLICATE:
			return {
				...state,
				duplicateForm: {
					id: action.id,
					name: action.name
				}
			};
		case SELECT_USER_FORM:
			return {
				...state,
				selectUserForm: action.payload.data
			};
		default:
			return state;
	}
}
