/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import {formatCurrency} from '../../../../containers_v2/reports/utils';

class Details extends Component {
  state = {};

  render() {
    const { dataDetail } = this.props;

    return (
      <div className="detail-body">
        <div className="row">
          <div className="col-md-3 text-md-right px-4">
            <p><Translate id="Opened opportunities" /></p>
          </div>
          <div className="col-md-9">
            <p className="detail-value-key">
              {dataDetail.oppenedOpportunities || '-'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 text-md-right px-4">
            <p>
              <Translate id="Signed Opportunities" />
            </p>
          </div>
          <div className="col-md-9">
            <p className="detail-value-key">
              {dataDetail.signedOpportunities || '-'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 text-md-right px-4">
            <p>
              <Translate id="lost opportunities" />
            </p>
          </div>
          <div className="col-md-9">
            <p className="detail-value-key">
              {dataDetail.lostOpportunities || '-'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 text-md-right px-4">
            <p>
              <Translate id="Sum of signed opprtunities" />
            </p>
          </div>
          <div className="col-md-9">
            <p className="detail-value-key">
              {formatCurrency(dataDetail.sumOfSignedOpportunities ? dataDetail.sumOfSignedOpportunities : 0)}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Details;
