import loginImage2 from 'images/login/login-image-2.png';
import loginImage1 from 'images/login/login-image-1.png';
import sidelyWhiteNew from 'images/login/sidely-white-new.png';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import 'react-toastify/dist/ReactToastify.css';
import { emailValid } from '../../../components/FieldCustom';
import styled from 'styled-components';
import { sendEmail } from './action';
import LanguagePicker from '../../../components_v2/languagePicker/LanguagePicker';

type loadingState = 'loading' | 'loaded' | 'error'

const Description = styled.div`
    text-align: left;
    color: white;
    margin-top: 30px;
    width: 270px;
`;

const DescriptionTitle = styled.div`
    font-size: 24px;
    line-height: 32px;
`;
const DescriptionText = styled.div`
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
`;

const LoginForm = styled.form`
    margin-top: 30px;
    width: 270px
`;

const LanguagePickerContainer = styled.div`
    position: absolute;
    right: 23px;
    top: 10px;
`;

export default function Forgot() {
	const [email, setEmail] = React.useState<string>('');
	const [loadingState, setLoadingState] = React.useState<loadingState | null>(null);

	function onSubmit() {
		sendEmail(email).catch(console.error);
		setLoadingState('loaded');
	}

	function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter' && email && emailValid(email) == undefined) {
			onSubmit();
		}
	}

	return (
		(<div>
			<div className="container-fluid">
				<div className="row page-login">
					<div className="col form text-center">
						<div className="d-flex justify-content-center align-items-center flex-column" style={{
							top: '50%',
							position: 'relative',
							transform: 'translateY(-50%)'
						}}>
							<div className="d-md-flex">
								<img
									className="logo-sidely"
									src={sidelyWhiteNew}
									alt=""
									height="50"
								/>
							</div>
							<br />
							{loadingState == 'loaded'
								? <div>
									<Description>
										<DescriptionTitle>
											<Translate id="login.meet_in_mail_box" />
										</DescriptionTitle>
										<DescriptionText>
											<Translate id="login.link_for_new_password" />
										</DescriptionText>
									</Description>
								</div>
								: <div>
									<Description>
										<DescriptionTitle>
											<Translate id="login.change_password" />
										</DescriptionTitle>
										<DescriptionText>
											<Translate id="login.forgot_your_password" />
										</DescriptionText>
									</Description>
									<LoginForm onSubmit={onSubmit}>
										<FormInput
											name="email"
											type="email"
											required
											placeholder="Email"
											value={email}
											onChange={value => setEmail(value.target.value)}
											onKeyPress={handleKeyPress}
										/>
										<div className="form-group row form-login-action">
											<div className="col-md-12 text-right">
												<Button
													className="btn btn-primary btn-login"
													onClick={onSubmit}
													loading={loadingState == 'loading'}
													spinColor="#003d79"
													disabled={!email || emailValid(email) !== undefined}
												>
													{loadingState != 'loading' && (
														<span className="text-white"><Translate id="login.modify" /></span>
													)}
												</Button>
											</div>
										</div>
									</LoginForm>
								</div>
							}
						</div>
					</div>
					<div className="col image-login text-center">
						<div className="image d-flex justify-content-center align-items-center flex-column">
							<div className="image-login-bot d-md-flex">
								<img
									className="image-login-bot"
									src={loginImage1}
									alt=""
								/>
								<img
									className="image-login-top"
									style={{ top: '25%' }}
									src={loginImage2}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<LanguagePickerContainer><LanguagePicker /></LanguagePickerContainer>
		</div>)
	);
}

function FormInput(props: {
  placeholder: string
  required: boolean
  type: React.HTMLInputTypeAttribute
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  name: string
  showPassword?: React.ReactNode
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {
	const { placeholder, required, type, onKeyPress, name, showPassword, value, onChange } = props;
	return (
		<div className="form-group">
			<input
				placeholder={placeholder}
				required={required}
				type={type}
				className="form-control"
				onKeyDown={onKeyPress}
				value={value}
				onChange={onChange}
			/>
			{name === 'password' && (
				<div className="input-group-append show-password">
					<span className="input-group-text">{showPassword}</span>
				</div>
			)}
		</div>
	);
}
