import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import PopupNewOrder from '../../containers_v2/orders/templateOrders/subPage/popup/PopupNewOrder';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';
import Restricted from '../../containers_v2/permissions/Restricted';
import NoData from '../../containers_v2/noData/NoData';
import { translateToString } from '../../styles/global/translate';

function OrderCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  clientCompanyId?: number
}): JSX.Element {
	return (
		(<Popup popupStyle={{
			width: '600px',
			height: '400px',
			borderRadius: '10px'
		}}
		isOpen={props.isOpen}
		hasBackground={true}
		popupMode={PopupMode.Centered}
		content={<Restricted to={{ objectAction: 'CreateOrder' }} fallback={<>
			<img src={close} onClick={() => props.setOpen(false)} />
			<NoData message={translateToString('you_are_not_autorized')} height='calc(100% - 25px)'/>
		</>}>
			<PopupNewOrder clientCompanyId={props.clientCompanyId} />
		</Restricted>}
		onClickOut={() => props.setOpen(false)}
		/>)
	);
}

export default OrderCreation;
