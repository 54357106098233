import * as React from 'react';
import Input from './Input';
import { InputAddressResult, InputCompletionProps } from './model/Model';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { OptionBlock, OptionContainer, OptionText } from '../dropdown/style/Style';
import { checkClickOut } from '../dropdown/Dropdown';

function InputAddress(props: InputCompletionProps): JSX.Element {
	const [isOpen, setOpen] = React.useState<boolean>(false);
	const [address, setAddress] = React.useState<string>(props.value?.toString() ?? '');

	const optionWidth = props.optionWidth ?? '300px';
	const optionHeight = props.optionHeight ?? '100px';
	const optionLeft = props.optionLeft ?? '';
	const optionTop = props.optionTop ? props.optionTop : (props.inputStyle?.height ?? '30px');

	const wrapperRef = React.useRef<HTMLDivElement>(null);

	checkClickOut(wrapperRef, setOpen);

	React.useEffect(() => {
		setAddress(props.value?.toString() ?? '');
	}, [props.value]);

	function handleAddress(address: string): void {
		setAddress(address);

		let streetNumber = '';
		let route = '';
		let country = '';
		let city = '';
		let postCode = '';

		geocodeByAddress(address).then(results => {
			for (const addr of results[0].address_components) {
				const StreetExist = addr.types.find(type => type == 'street_number');
				const RouteExist = addr.types.find(type => type == 'route');
				const countryExist = addr.types.find(type => type == 'country');
				let cityExist = addr.types.find(type => type == 'locality');
				const postCodeExist = addr.types.find(type => type == 'postal_code');

				if (StreetExist != undefined) {
					streetNumber = addr.long_name;
				}

				if (RouteExist != undefined) {
					route = addr.long_name;
				}

				if (countryExist != undefined) {
					country = addr.long_name;
				}

				if (cityExist != undefined) {
					city = addr.long_name;
				}

				if (cityExist == undefined && city == '') {
					cityExist = addr.types.find(
						type => type == 'administrative_area_level_2'
					);

					if (cityExist != undefined) {
						city = addr.long_name;
					}
				}

				if (postCodeExist != undefined) {
					postCode = addr.long_name;
				}
			}

			return getLatLng(results[0]);
		}).then(latLng => {
			const inputAddress = {
				city,
				country,
				postcode: postCode,
				route,
				street_number: streetNumber,
				longitude: latLng.lng,
				latitude: latLng.lat
			} as InputAddressResult;
			props.onChange?.(inputAddress);
			setAddress(`${streetNumber} ${route}`);
			setOpen(false);
		})
			.catch(error => console.error('Error', error));
	}

	return (
		<PlacesAutocomplete
			value={address}
			onChange={(v) => {
				props.onChangeStringAddress?.(v);
				setAddress(v);
				setOpen(true);
			}}
			onSelect={handleAddress}
		>
			{({ getInputProps, suggestions }) => (
				<div style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-end', alignItems: 'flex-end', position: 'relative' }} ref={wrapperRef}>
					<Input {...props} extraInputAttribute={getInputProps()} />
					{
						isOpen &&
						<OptionContainer height={optionHeight} width={optionWidth} left={optionLeft} top={optionTop}>
							{
								suggestions.map((suggestion, i) => {
									return (
										<OptionBlock
											key={`InputAddressSuggestion[${i}]`}
											onMouseDown={() => {
												handleAddress(suggestion.description);
											}}
										>
											<OptionText>{suggestion.description}</OptionText>
										</OptionBlock>

									);
								})
							}
						</OptionContainer>
					}
				</div>
			)}
		</PlacesAutocomplete>
	);
}

export default InputAddress;
