import new_tabImage from 'images/icon/new_tab.svg';
import userImage from 'images/icon/user.png';
import closeImage from 'images/icons/orders/close.svg';
import * as moment from 'moment';
import * as React from 'react';
import { getOrder } from '../../../data/Data';
import { Order, POURCENTAGE } from '../../../model/Model';
import {
	Column,
	Columns,
	Container,
	ContainerBody,
	ContainerLeft,
	ContainerProductSum,
	ContainerResult,
	ContainerRight,
	Form,
	Header,
	HeaderBlock,
	HeaderImage,
	HeaderStatus,
	HeaderTitle,
	Info,
	InfoArea,
	InfoBoxValue,
	InfoImage,
	InfoLink,
	InfoTitle,
	InfoValue,
	ListProducts,
	Product,
	ProductBlock,
	ProductImage,
	ProductSummary,
	Result,
	Text
} from '../style/PopupOrderDetailStyle';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../../../helpers/storeLang';
import { LoadingState, LoadingStateEnum } from '../../../../import/model';
import { formatCurrency, getCurrencySymbol } from '../../../../../containers_v2/reports/utils';
import { Loader } from '../../../../../styles/global/css/GlobalLoader';
import { DarkGreySidely2 } from '../../../../../styles/global/css/Utils';
import { Translate } from '../../../../../styles/global/translate';
import Restricted from '../../../../permissions/Restricted';
import noImage from 'images/no-image.jpg';

function PopupOrderDetail(props: {
  order_id: number
  onClose?: () => void
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const [order, setOrder] = React.useState<Order>();
	const [selected, setSelected] = React.useState<number>(1);
	const [loadingState, setLoadingState] = React.useState<LoadingState>(LoadingStateEnum.LOADING);

	React.useEffect(() => {
		setLoadingState(LoadingStateEnum.LOADING);
		getOrder(props.order_id).then((response) => {
			setOrder(response);
			setLoadingState(LoadingStateEnum.LOADED);
		});
	}, []);

	if (loadingState === LoadingStateEnum.LOADING) {
		return (
			<Loader width='40px' center />
		);
	}

	const total_products: number = order?.products?.reduce((prev, current) => (current.price * current.quantity) + prev, 0) ?? 0;
	return (
		(<Container>
			<Header>
				<HeaderImage src={closeImage} onClick={props.onClose} />
				<HeaderBlock>
					<HeaderTitle>{order?.reference}</HeaderTitle>
					<HeaderStatus
						backgroundColor={(order != null) ? order.order_color_status_code : '#000000'}>{order?.order_status_name}</HeaderStatus>
				</HeaderBlock>
			</Header>
			<ContainerBody>
				<ContainerLeft>
					<Columns>
						<Column onClick={() => setSelected(1)} selected={selected === 1} isUnderlined={selected === 1} columnNb={2}><Translate id='order' /></Column>
						<Column onClick={() => setSelected(2)} selected={selected === 2} isUnderlined={selected === 2} columnNb={2}><Translate id='orders.templateOrder.orderDetail.billing' /></Column>
					</Columns>
					{
						selected === 1 &&
			<Form>
				<Info width='80%' withUnderline>
					<InfoTitle>{translate('orders.templateOrder.field.date').toString()}</InfoTitle>
					<InfoValue>{order?.created_at ? moment.utc(order?.created_at).local().format('DD/MM/YYYY HH:mm') : ''}</InfoValue>
				</Info>
				<Info width='80%' withUnderline>
					<InfoTitle>{translate('orders.templateOrder.field.owner').toString()}</InfoTitle>
					<InfoBoxValue>
						<InfoImage src={order?.owner_photo && order.owner_photo.length > 0 ? order.owner_photo : userImage} alt='' onError={(e) => e.currentTarget.src = userImage} />
						<InfoValue>{order?.owner_name}</InfoValue>
					</InfoBoxValue>
				</Info>
				<Info width='80%' withUnderline>
					<InfoTitle>{translate('orders.templateOrder.field.company').toString()}</InfoTitle>
					<InfoBoxValue>
						<InfoValue weight={700}>{order?.client_company_name}</InfoValue>
						{
							(order != null) && order.client_company_id &&
							<Restricted to={{ objectAction: 'ViewCompany' }}>
								<InfoLink src={new_tabImage} alt='link' onClick={() => {
									window.open(`/companies?id=${order?.client_company_id}`);
								}} />
							</Restricted>
						}
					</InfoBoxValue>
				</Info>
				<Info marginTop='10%'>
					<InfoTitle>{translate('orders.templateOrder.orderDetail.comment').toString()}</InfoTitle>
					<InfoArea readOnly value={order?.comment}></InfoArea>
				</Info>
			</Form>
					}

					{
						selected === 2 &&
			<Form>
				<Info height='40%'>
					<InfoTitle
						weight={600}>{translate('orders.templateOrder.orderDetail.deliveryAddress').toString()}</InfoTitle>
					<InfoArea readOnly height='100%'>{order?.delivery_address}</InfoArea>
				</Info>
				<Info height='40%'>
					<InfoTitle
						weight={600}>{translate('orders.templateOrder.orderDetail.billingAddress').toString()}</InfoTitle>
					<InfoArea readOnly height='100%'>{order?.billing_address}</InfoArea>
				</Info>
			</Form>
					}
				</ContainerLeft>

				<ContainerRight>
					<Columns justifyContent='flex-start'>
						<Column columnNb={10} selected>{translate('orders.templateOrder.orderDetail.summary').toString()}</Column>
					</Columns>

					<ContainerProductSum>
						<Text
							weight={500}>{(order != null) && (order.products?.length ?? 0)} {translate('orders.templateOrder.orderDetail.products').toString()}</Text>
						<Text weight={500}>{formatCurrency((order != null) && order.sum_with_tax ? order.sum_with_tax : 0.0)}</Text>
					</ContainerProductSum>

					<ListProducts>
						<ProductSummary>
							<ProductBlock width={`${(100 / 5)}%`}><Text fontSize='12px'
								color={DarkGreySidely2}>{translate('orders.templateOrder.field.photo').toString()}</Text></ProductBlock>
							<ProductBlock width={`${(100 / 5)}%`}><Text fontSize='12px'
								color={DarkGreySidely2}>{translate('orders.templateOrder.field.product').toString()}</Text></ProductBlock>
							<ProductBlock width={`${(100 / 5)}%`}><Text fontSize='12px'
								color={DarkGreySidely2}>{translate('orders.templateOrder.field.quantity').toString()}</Text></ProductBlock>
							<ProductBlock width={`${(100 / 5)}%`}><Text fontSize='12px'
								color={DarkGreySidely2}>{translate('orders.templateOrder.field.totalProduct').toString()}</Text></ProductBlock>
							<ProductBlock width={`${(100 / 5)}%`}><Text fontSize='12px'
								color={DarkGreySidely2}>{translate('orders.templateOrder.field.discount').toString()}</Text></ProductBlock>
						</ProductSummary>
						{order?.products?.map((product) => (
							<Product key={`OrderDetailsProduct[${product.id}]`}>
								<ProductBlock width={`${(100 / 5)}%`}><ProductImage
									src={((product.photo_urls != null) && product.photo_urls.length > 0 ? product.photo_urls[0] : noImage)}
									alt='img' onError={(e) => e.currentTarget.src = noImage} /></ProductBlock>
								<ProductBlock width={`${(100 / 5)}%`}><Text fontSize='12px' textAlign='left' height='100%'>{product.product_name}</Text></ProductBlock>
								<ProductBlock width={`${(100 / 5)}%`}><Text fontSize='12px' textAlign='left'>{product.quantity}</Text></ProductBlock>
								<ProductBlock
									width={`${(100 / 5)}%`}><Text fontSize='12px' textAlign='left'>{formatCurrency(product.quantity * product.price)}</Text></ProductBlock>
								<ProductBlock
									width={`${(100 / 5)}%`}><Text fontSize='12px' textAlign='left'>{(product.discount ?? 0) * (product.discount_id === POURCENTAGE ? 100 : 1)}{product.discount_id === POURCENTAGE ? '%' : getCurrencySymbol()}</Text></ProductBlock>
							</Product>
						))}
					</ListProducts>

					<ContainerResult>
						<Result>
							<Text>{translate('orders.templateOrder.orderDetail.totalProduct').toString()}</Text>
							<Text>{formatCurrency(total_products)}</Text>
						</Result>
						<Result>
							<Text>{translate('orders.templateOrder.orderDetail.totalDiscount').toString()}</Text>
							<Text>{formatCurrency(total_products - (order?.sum_without_tax ?? 0))}</Text>
						</Result>
						<Result>
							<Text>{translate('orders.templateOrder.orderDetail.totalHT').toString()}</Text>
							<Text>{formatCurrency(order?.sum_without_tax ? order.sum_without_tax : 0)}</Text>
						</Result>
						<Result>
							<Text>{translate('orders.templateOrder.orderDetail.totalTax').toString()}</Text>
							<Text>{formatCurrency((order?.sum_with_tax ?? 0) - (order?.sum_without_tax ?? 0))}</Text>
						</Result>
						<Result>
							<Text weight={700}>{translate('orders.templateOrder.orderDetail.totalTTC').toString()}</Text>
							<Text weight={700}>{formatCurrency(order?.sum_with_tax ? order.sum_with_tax : 0)}</Text>
						</Result>
					</ContainerResult>
				</ContainerRight>
			</ContainerBody>
		</Container>)
	);
}

export default PopupOrderDetail;
