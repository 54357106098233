import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadFile, { createRetrivedData } from './UploadFile';
import TableView from './TableView';
import Matching from './Matching';
import { retrieveWork } from './actions';
import { ImportColumns, LoadingState, MatchedRowType, Panel, RowType } from './model';
import AlexandriaRetriever from './Alexandria';
import Restricted from '../permissions/Restricted';
import { ToolbarState } from '../globals/mainPage/mainPage';

export default function Import(props: {
	setToolBarState: (value: ToolbarState) => void
}) {
	const [loadingState, setLoadingState] = React.useState<LoadingState>('loading');
	const [data, setData] = React.useState<RowType[]>([]);
	const [columns, setColumns] = React.useState<ImportColumns[]>([]);
	const [panel, setPanel] = React.useState<Panel>(Panel.UploadView);
	const [matchedList, setMatchedList] = React.useState<MatchedRowType>({});
	const [myTimeout, setMyTimeout] = React.useState<NodeJS.Timeout | undefined>(undefined);
	const dispatch = useDispatch();
	const [images_, setImages_] = React.useState<object[]>([]);
	const images = React.useRef(images_);
	function setImages(a) {
		images.current = a;
		setImages_(a);
	}
	// @ts-expect-error selector
	const selector = useSelector(state => state.webSocket);

	React.useEffect(() => {
		if (selector.connected && (data.length > 0)) {
			retrieveWork().then(res => {
				const json = JSON.parse(res.data);
				const rw = createRetrivedData(json.values, matchedList);
				const newData: RowType[] = [];
				data.forEach(row => {
					const rw_row = rw.find((rw_row) => rw_row.SidelyRow.value === row.SidelyRow.value);
					if (rw_row == null) {
						newData.push(row);
						return;
					}
					for (const [key, value] of Object.entries(row)) {
						if (!(value.value === rw_row[key].value || (value.value == '' && rw_row[key].value == null))) {
							newData.push(row);
							return;
						}
					}
					newData.push(rw_row);
				});
				setData([...newData]);
				setLoadingState('loaded');
			});
		}
	}, [selector.connected]);

	React.useEffect(() => {
		if (data.length > 0) {
			createRetrivedData(selector.check, matchedList).forEach(e => {
				const line = data.findIndex(row => row.SidelyRow.value == e.SidelyRow.value);
				if (line < data.length && line >= 0) {
					data[line] = e;
				}
			});
			setData([...data]);
		}
	}, [selector.slice]);

	React.useEffect(() => {
		clearTimeout(myTimeout);
		setMyTimeout(setTimeout(() => {
			if (selector.connected) {
				if (loadingState === 'loadingImport') {
					retrieveWork().then(res => {
						const json = JSON.parse(res.data);
						const rw = createRetrivedData(json.values, matchedList).map(e => e.SidelyRow.value);
						setData([...data.filter(e => rw.includes(e.SidelyRow.value))]);
						setLoadingState('loaded');
					});
				} else {
					setLoadingState('loaded');
				}
			}
			dispatch({ type: 'SHOW_PROGRESS_BAR', value: false });
		}, 3000));
	}, [selector.is_job_running]);

	return (
		<Restricted to={{ objectAction: 'CreateImport' }}>
			<>
				{panel == Panel.UploadView &&
					<UploadFile
						setToolBarState={props.setToolBarState}
						setData={setData}
						setColumns={setColumns}
						setPanel={setPanel}
						setMatchedList={setMatchedList}
					/>
				}
				{panel == Panel.Matching &&
					<Matching
						setToolBarState={props.setToolBarState}
						columns={columns}
						setColumns={setColumns}
						setPanel={setPanel}
						matchedList={matchedList}
						setMatchedList={setMatchedList}
						data={data}
						setData={setData}
						images={images}
						setImages={setImages}
					/>
				}
				{panel == Panel.TableView &&
					<TableView
						setToolBarState={props.setToolBarState}
						columns={columns}
						data={data}
						setData={setData}
						loadingState={loadingState}
						setLoadingState={setLoadingState}
						matchedList={matchedList}
						setPanel={setPanel}
						images={images}
					/>
				}
				{(panel == Panel.AlexandriaStep1 || panel == Panel.AlexandriaStep2) &&
					<AlexandriaRetriever
						setToolBarState={props.setToolBarState}
						panel={panel}
						setPanel={setPanel}
						setData={setData}
						setColumns={setColumns}
						setMatchedList={setMatchedList}
					/>
				}
			</>
		</Restricted>
	);
}
