import tradeshowsImage from 'images/salesops_icons/tradeshows.png';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import TableListingPage from './components/TableListingPage';
import { deleteData, fetchData, setModalDuplicate } from '../../store/actions/formbuilder.action';
import { filterOpportunityStatus } from '../../store/actions/modal.action';
import { currentData } from '../../store/actions/user.action';
import DropdownAction from './components/DropdownAction';
import store from './formElement/builderLib/services/store';

class FormBuilder extends React.Component {
	static columns = props => {
		const { history, data, setModalDuplicate } = props;

		const columns = [
			{
				dataField: 'name',
				text: <Translate id="form_templates" />,
				formatter: (cell, row) => (
					<div
						style={{ display: 'flex', alignItems: 'center', width: '100%' }}
						onClick={() => {
							history.push(`/formBuilder/formData/${row.id}/list`);
						}}
						className="pointer"
					>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'left',
								alignItems: 'center',
								marginRight: 10
							}}>
							<img
								style={{ height: 'auto', width: 20, marginRight: 10, borderRadius: '50%' }}
								src={row.icon_url}
								alt={row.name}
								onError={e => {
									e.target.onerror = null;
									e.target.src = tradeshowsImage;
								}}
							/>
							<p style={{
								margin: '0',
								padding: '0',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								width: '90%'
							}}>{row.name || '-'}</p>
						</div>

					</div>
				)
			},
			{
				dataField: 'updated_at',
				text: <Translate id="Last updated" />,
				formatter: (cell, row) => (
					<div>
						{/* {console.log(row.lastUpdated)} */}
						{row.updated_at ? moment(row.updated_at).format('DD/MM/YY') : '-'}
					</div>
				)
			},
			{
				dataField: 'record_count',
				text: <Translate id="number_of_record" />,
				formatter: (cell, row) => <div>{row.record_count || '0'}</div>
			},
			{
				dataField: 'quickAction',
				// classes: 'text-right',
				text: <Translate id="quick_action" />,
				formatter: (cell, row) => (
					<div>
						<DropdownAction
							id={row.id}
							count={data.pagination.count}
							name={row.name}
							permission='FreeForm'
							{...props}
						/>
					</div>
				)
			}
		];
		return columns;
	};

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		store.dispatch('deleteDB');
	}

	render() {
		return (
			<Fragment>
				<div style={{ width: 200 }} />
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	data: state.formbuilder,
	current: state.user,
	subPath: 'salesOperations/type',
	modal: state.modal
});
export default connect(mapStateToProps, {
	fetchData,
	deleteData,
	currentData,
	filterOpportunityStatus,
	setModalDuplicate
})(
	TableListingPage({ name: 'Form Builder', columns: FormBuilder.columns })(
		FormBuilder
	)
);
