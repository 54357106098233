/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import TableWrapper from './TableWrapper';
import qs from 'query-string';
import Restricted from '../../../../containers_v2/permissions/Restricted';

const TableListingPage = tableProp => WrappedComponent =>
	(class TableListingHOC extends Component {
		constructor(props) {
			super(props);
			this.state = {
				params: {
					page: 1,
					limit: 50
				}
			};
		}

		componentDidMount() {
			const { match } = this.props;
			const instance = match.params.id !== undefined;
			const qParams = qs.parse(this.props.location.search, {
				arrayFormat: 'bracket'
			});
			const paramsData = { ...this.state.params, ...qParams };
			if (!instance) {
				localStorage.removeItem('instance');
			} else {
				paramsData['shelf_audit_template_id'] = match.params.id;
				this.setState({ params: paramsData });
			}
			const formTemplate = localStorage.getItem('enFormTemplate');
			if (formTemplate) {
				localStorage.removeItem('enFormTemplate');
			}
			this.props.fetchData(paramsData);
		}

		handleLimit = limit => {
			const paramsData = { ...this.state.params, limit, page: 1 };
			this.props.fetchData(paramsData);
			this.setState({ params: paramsData });
		};

		filterByAlphabet = abj => {
			const paramsData = {
				...this.state.params,
				alphabet: abj === null ? null : `["${abj}"]`,
				page: 1
			};
			this.props.fetchData(paramsData);
			this.setState({ params: paramsData });
		};

		handleSort = (sort, by) => { };

		onPageChange = page => {
			const paramsData = { ...this.state.params, page };
			this.props.fetchData(paramsData);
			this.setState({ params: paramsData });
		};

		onExport = row => {
			const { match } = this.props;
			const companyId = this.state.params.client_company_id || null;
			const params = { client_company_id: companyId, shelf_audit_instance_id: row };
			this.props.exportExcel(match.params.id, params);
		};

		onImport = formData => { };

		onBulkDelete = id => { };

		render() {
			const { data, isLoadingCurrent, current, fieldInstaller } = this.props;
			const { columns, noCreate, name } = tableProp;
			const { sort } = this.state;
			const shelfAuditStatus = fieldInstaller.find(f => f.name === name);
			return (
				<Restricted to={{ objectAction: 'ReadShelfAudit' }}>
					<div className="content-wrapper pt-md-5 mt-md-1">
						<div className="row">
							<div className="col-lg-12 grid-margin stretch-card">
								<div className="card rounded bg-table">
									{shelfAuditStatus !== undefined &&
                    <TableWrapper
                    	loading={data.isLoading || isLoadingCurrent}
                    	data={data}
                    	defaultSortDirection={sort}
                    	columns={columns(this.props)}
                    	page={data.pagination.currentPage}
                    	sizePerPage={data.pagination.recordPerPage}
                    	lastPage={data.pagination.lastPage}
                    	totalSize={data.pagination.count}
                    	onTableChange={this.handleTableChange}
                    	handleFilter={this.handleFilter}
                    	handleSort={this.handleSort}
                    	history={this.props.history}
                    	disableAddBtn={tableProp.disableAddBtn}
                    	customPath={tableProp.customPath}
                    	disablePagination={tableProp.disablePagination}
                    	noCreate={noCreate}
                    	customAction={
                    		<WrappedComponent {...this.props} state={this.state} />
                    	}
                    	route={this.props.route}
                    	onPageChange={this.onPageChange}
                    	onNext={this.onNext}
                    	onPrev={this.onPrev}
                    	onFirstPage={this.onFirstPage}
                    	onLastPage={this.onLastPage}
                    	onExport={this.onExport}
                    	onImport={this.onImport}
                    	onBulkDelete={this.onBulkDelete}
                    	current={current}
                    	limit={this.state.limit}
                    	handleLimit={this.handleLimit}
                    	setModal={this.props.setModal}
                    	filterByAlphabet={this.filterByAlphabet}
                    	match={this.props.match}
                    />
									}
								</div>
							</div>
						</div>
					</div>
				</Restricted>
			);
		}
	});

export default TableListingPage;
