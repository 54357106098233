import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import { FileResult } from './model/Model';
import { AddBlockFolder } from './style/InputImageStyle';
import styled from 'styled-components';
import { FilterBlue } from '../../styles/global/css/Utils';

export enum InputErrorMode {
  LIMIT_REACHED
}

const InputFileLabel = styled.label`
    cursor: pointer;
	width: 25px;
	height: 25px;
	flex-shrink: 0;
	margin: 0;

    &:hover {
        filter: ${FilterBlue};
    }
`;

export interface InputError {
  type: InputErrorMode
  message: string
}

function InputFile(props: {
  onChange?: (value: FileResult) => void
  onError?: (value: InputError) => void
  limitMo?: number
  backgroundColor?: string
  hideAddIcon?: boolean
  accept?: string,
  child?: JSX.Element
  readAsText?: boolean
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const reader = new FileReader();

	return <>
		<InputFileLabel htmlFor="file-selector">
			{props.child}
			{!props.child && !props.hideAddIcon && <AddBlockFolder backgroundColor={props.backgroundColor} />}
		</InputFileLabel>
		<input type="file" id="file-selector" accept={props.accept} onInput={(e) => {
			const files = e.currentTarget.files;
			const file: File | undefined = (files != null) && files.length > 0 ? files[0] : undefined;
			if (file != null) {
				const url = URL.createObjectURL(file);

				if (props.limitMo && parseFloat(((file.size / 1024) / 1024).toFixed(4)) > props.limitMo) {
					props.onError?.({
						type: InputErrorMode.LIMIT_REACHED,
						message: translate('global.fire.size_exceeded').toString().replace('{{SIZE}}', props.limitMo.toString())
					});
					return;
				}

				reader.onload = evt => {
					if (props.readAsText) {
						props.onChange?.({
							file,
							content: evt.target?.result?.toString() ?? '',
							contentType: '',
							url
						});
						return;
					}
					const tab = evt.target?.result?.toString().split(',');
					const contents = (tab != null) ? tab[1] : '';
					const contentType = (tab != null) ? tab[0] : '';
					props.onChange?.({
						file,
						content: contents,
						contentType,
						url
					});
				};
				if (props.readAsText) reader.readAsText(file);
				else reader.readAsDataURL(file);
			}
		}} style={{ display: 'none' }}/>

	</>;
}

export default InputFile;
