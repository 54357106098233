import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';

export type ReportGroup = {
	report_group_id: number,
	report_group_name: string,
	sequence: number,
	created_by: number,
	public: boolean,
	reports: number[],
	shared_with: number[],
	is_deleted: boolean
}

export async function getUserCategories(userId: number): Promise<ReportGroup[]> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/reports/groups/` + userId).then(res => res.data);
}

export async function syncUserCategories(userId: number, reportGroups: ReportGroup[]): Promise<void> {
	return await axios.put(`${URL_FOUNDATION}/api/v2/reports/groups/` + userId, reportGroups);
}