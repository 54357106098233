import magnifying_glass from 'images/icons/magnifying_glass.svg';
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import debounce from 'lodash/debounce';

class CompaniesSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false,
			search: ''
		};
		this.delayedCallback = debounce(this.searchCall, 1000);
	}

	componentDidMount() {
		const paramsData = JSON.parse(localStorage.getItem('params'));
		const mapParams = JSON.parse(localStorage.getItem('mapParams'));
		let search = '';
		if (paramsData.title) {
			search = paramsData.title;
		}
		if (paramsData.name) {
			search = paramsData.name;
		}
		// this.props.searchData(search);
		this.setState({ search });
	}

	handleChange = e => {
		e.persist();
		this.delayedCallback(e);
		this.setState({ search: e.target.value });
	};

	searchCall = e => {
		const { searchData } = this.props;
		searchData(e.target.value);
	};

	render() {
		const { viewType, placeholder } = this.props;
		const { focus } = this.state;

		return (
			(<div className="m-0">
				<div
					className={`input-group search-v1 ${focus ? 'focused' : ''} `}
					style={{ paddingLeft: viewType === 'map' ? '0px' : '13px' }}
				>
					<div
						className="input-group-prepend"
						onKeyDown={this.handleKeyDown}
						role="button"
						tabIndex="0"
					>
						<div className="input-group-prepend">
							<span className="input-group-text radius-select-label p-2">
								<img alt="ProfileImage" src={magnifying_glass} height="20" />
							</span>
						</div>
					</div>
					<Translate>
						{({ translate }) => (
							<input
								value={this.state.search}
								type="text"
								className="form-control"
								placeholder={translate(placeholder || 'search_company')}
								onFocus={() => {
									this.setState({
										focus: true
									});
								}}
								onBlur={() => {
									this.setState({
										focus: false
									});
								}}
								onChange={this.handleChange}
							/>
						)}
					</Translate>
				</div>
			</div>)
		);
	}
}

export default CompaniesSearch;
