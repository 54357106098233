import { URL_API, URL_FOUNDATION } from '../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { queryConstructor } from '../reports/utils';
import * as moment from 'moment';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

function createDate(range) {
	if (!range) { return ''; }
	return (range.gteDate ? range.gteDate : '') + '_' + (range.lteDate ? moment(range.lteDate).format('YYYY-MM-DD') : '');
}

type FormBody = {
	shelf_audit_templates?: number[],
	// LEGACY QUERY
	tags?: string[],
	userIdList?: number[],
	products?: number[],
	periodeRange: {
		gteDate: string;
		lteDate: string;
	} | null | undefined,
	free_form_templates?: string[]
}

export async function getFormGallery(state: FormBody, limit: number | undefined, offset: number | undefined, type: string, cancelToken) {
	axios.defaults.headers.common.Authorization = `${token}`;

	let query = queryConstructor(state, createDate(state.periodeRange));
	if (limit !== undefined) {
		query += '&limit=' + limit;
	}
	if (offset !== undefined) {
		query += '&offset=' + offset;
	}
	if (state.shelf_audit_templates !== undefined) {
		query += `&shelf_audit_templates=${state.shelf_audit_templates}`;
	}
	if (state.free_form_templates !== undefined) {
		query += `&free_form_templates=${state.free_form_templates}`;
	}
	query += '&type_list=' + type;
	return await axios({
		method: 'get',
		url: `${PATH}/gallery/forms${query}`,
		cancelToken
	});
}

export async function exportGallery(state, limit: number | undefined, offset: number | undefined, type: string) {
	axios.defaults.headers.common.Authorization = `${token}`;

	let query = queryConstructor(state, createDate(state.periodeRange));
	if (limit !== undefined) {
		query += '&limit=' + limit;
	}
	if (offset !== undefined) {
		query += '&offset=' + offset;
	}
	query += '&type_list=' + type;
	return await axios({
		method: 'post',
		url: `${PATH}/export_gallery${query}`
	});
}

export async function getFieldReportInstallers() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_API}/api/fieldReportInstallers`
	});
}
