/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prefer-stateless-function */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import StartSection from './elements/StartSection';
import FreeText from './elements/FreeText';
import Numerical from './elements/Numerical';
import Header from './elements/Header';
import EndSection from './elements/EndSection';
import Image from './elements/Image';
import Website from './elements/Website';
import DatePicker from './elements/Date';
import DropdownList from './elements/DropdownList';
import Checkbox from './elements/Checkbox';
import Email from './elements/Email';
import AddressField from './elements/AddressField';
import Document from './elements/Document';
import UserField from './elements/UserField';
import Hyperlink from './elements/Hyperlink';
import Calculation from './elements/Calculation';
import StartTable from './elements/StartTable';
import EndTable from './elements/EndTable';
import Camera from './elements/Camera';

const FormElements = {};

FormElements.StartSection = StartSection;
FormElements.EndSection = EndSection;
FormElements.StartTable = StartTable;
FormElements.EndTable = EndTable;
FormElements.Header = Header;
FormElements.FreeText = FreeText;
FormElements.Numerical = Numerical;
FormElements.Website = Website;
FormElements.DropdownList = DropdownList;
FormElements.Checkbox = Checkbox;
FormElements.Email = Email;
FormElements.AddressField = AddressField;
FormElements.DatePicker = DatePicker;
FormElements.Image = Image;
FormElements.Hyperlink = Hyperlink;
FormElements.Document = Document;
FormElements.UserField = UserField;
FormElements.Calculation = Calculation;
FormElements.Camera = Camera;

export default FormElements;
