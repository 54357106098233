import React, { Component, Fragment } from 'react';

class CheckboxGroupFilter extends Component {
	state = {};

	handleCheckedAll = isChecked => {
		const { handleCheckedStatus, type } = this.props;
		handleCheckedStatus(isChecked, null, type);
	};

	handleChecked = (isChecked, value) => {
		const { input, handleCheckedStatus, type } = this.props;
		const newValue = [...input.value];
		if (isChecked) {
			newValue.push(value);
			handleCheckedStatus(isChecked, value, type);
		} else {
			handleCheckedStatus(isChecked, value, type);
			newValue.splice(newValue.indexOf(value), 1);
		}
		input.onChange(newValue);
	};

	checkboxComponent = () => {
		const { options, valChecked, disabled, name, disableLine } = this.props;

		if (options.length > 0) {
			return options.map((option, index) => {
				const i = index;
				return (
					<Fragment key={i}>
						<label className="ml-3" key={i}>
							<input
								name={`${name}[${i}]`}
								id={`${name}[${i}]`}
								type="checkbox"
								value={option.value}
								checked={valChecked && valChecked.includes(option.value)}
								onChange={e =>
									this.handleChecked(e.target.checked, option.value)
								}
								disabled={disabled}
							/>
							<div className="checkbox-style" />
							<span className="ml-4 ellipsis" style={{ width:'200px', display:'inline-block' }}>{option.label}</span>
						</label>
						{!disableLine && <hr className="my-2" />}
					</Fragment>
				);
			});
		}
		return null;
	};

	render() {
		const {
			checkedAll,
			name,
			meta: { touched, error, warning, },
			disableLine,
			disableFistCheckBox
		} = this.props;

		// console.log(checkedAll);

		return (
			<div className="form-group check-sidely">
				{!disableFistCheckBox && <label className="ml-3">
					<input
						name={`${name}-all`}
						type="checkbox"
						value="all"
						checked={checkedAll}
						onChange={e => this.handleChecked(e.target.checked)}
					/>
					<div className="checkbox-style" />
				</label>}
				{!disableLine && <hr className="my-2" />}
				{this.checkboxComponent()}
				{touched &&
          ((error && <p className="form-error m-0">{error}</p>) ||
            (warning && <p className="form-error m-0">{warning}</p>))}
			</div>
		);
	}
}

export default CheckboxGroupFilter;
