/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import RenderSelectInput from '../../select/RenderSelectInputModal';
import { postExport } from '../../../actions_v2/export_actions';
// import storeLang from '../../../helpers/storeLang'
// import { getTranslate, Translate } from 'react-localize-redux'
import 'react-toastify/dist/ReactToastify.css';

class ModalFilterOwner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataType: 'CSV'
		};
	}



	exportFile = dataType => {
		const { dataExport, onExport, dispatch } = this.props;
		const { exportType, exportKind } = this.props;

		if (exportType != null && exportType != undefined) {
			postExport(dataType, '', { type: exportType, kind: exportKind })
				.then(_ => {
					dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'exports.ready_toast' }, state: 'loading' } });
					// toast.success(translate('exports.ready_toast'), {
					//   position: 'top-right',
					//   autoClose: 4000,
					//   hideProgressBar: true,
					//   closeOnClick: true,
					//   pauseOnHover: true,
					//   draggable: true,
					//   progress: undefined,
					// });
				})
				.catch(e => {
					dispatch({ type: 'INSERT_MESSAGE', value: { message: { text: 'import.export_error_message' }, state: 'error' } });
					// toast.error(translate('exports.ready_toast'), {
					//   position: 'top-right',
					//   autoClose: 4000,
					//   hideProgressBar: true,
					//   closeOnClick: true,
					//   pauseOnHover: true,
					//   draggable: true,
					//   progress: undefined,
					// });
					console.log(e);
				});
		} else {
			// legacy code
			onExport(dataExport, dataType);
		}
	};

	handleType = data => {
		this.setState({
			dataType: data.value
		});
	};

	render() {
		const { dataType } = this.state;
		const opt = [
			{
				label: 'CSV',
				value: 'CSV'
			},
			// {
			//   label: 'Excel',
			//   value: 'xlsx'
			// }
		];

		return (
			<div>
				<Modal
					isOpen={this.props.isOpen}
					toggle={this.props.toggle}
					className={this.props.className}
					centered
				>
					<ModalHeader
						toggle={this.props.toggle}
						style={{ borderBottom: 'none' }}
					>
            Export File
					</ModalHeader>
					<ModalBody>
						<div className="text-center">
							<Field
								name="data_type"
								component={RenderSelectInput}
								label="Type File"
								requiredStar
								options={opt}
								id="inputOwner"
								onChange={this.handleType}
								placeholder="Select type file"
							/>
						</div>
					</ModalBody>
					<ModalFooter style={{ borderTop: 'none' }}>
						<Button
							color="primary"
							className="px-5 mb-2"
							onClick={() => {
								this.exportFile(dataType);
								this.props.toggle();
							}}
						>
              Export
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => ({});

export default reduxForm({
	form: 'modalFilterOwner'
})(
	connect(
		mapStateToProps,
		{}
	)(ModalFilterOwner)
);
