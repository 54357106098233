/* eslint-disable react/sort-comp */
import React, {Component} from 'react';
import swal from 'sweetalert2';
import TableWrapper from './TableWrapper';
import Restricted from '../../../containers_v2/permissions/Restricted';

const TableListingPage = tableProp => WrappedComponent =>
  (class TableListingHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sort: 'asc',
        by: 'lastName',
        page: 1,
        limit: 50,
        status: true,
        name: null,
        ownerId: null,
        tableData: [],
        clientCompanyId: '',
        alphabet: null,
        tagsId: []
      };
    }

    componentDidMount() {
      const { currentData, fetchData } = this.props;
      currentData();
      this.props.getAlphabet(null);
      const pr = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({ ...pr, by: this.state.by })
      );
      const params = { ...pr, by: this.state.by };
      fetchData(params);
    }

    componentWillReceiveProps(nextProps) {
      const { data, fetchData } = this.props;
      // On Search
      if (data.search !== nextProps.data.search) {
        const name = nextProps.data.search;
        const pr = JSON.parse(localStorage.getItem('params'));
        fetchData({ ...pr, name });
        localStorage.setItem('params', JSON.stringify({ ...pr, name }));
        this.setState({ page: 1, name });
      }
      // On Filter
      if (data.sort !== nextProps.data.sort) {
        this.handleSort(nextProps.data.sort, nextProps.data.sortField);
      }
    }

    closeFilterCompanyModal = () => {
      const { togleFilterCompaniesModal } = this.props;
      togleFilterCompaniesModal(false);
    };

    handleLimit = limit => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, limit });
      localStorage.setItem('params', JSON.stringify({ ...pr, limit }));
      this.setState({ page: 1, limit });
    };

    filterByAlphabet = val => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, alphabet: val });
      localStorage.setItem('params', JSON.stringify({ ...pr, alphabet: val }));
      this.setState({ page: 1, alphabet: val });
    };

    handleSort = (sort, by) => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, sort, by });
      localStorage.setItem('params', JSON.stringify({ ...pr, sort, by }));
      this.setState({ page: 1, sort, by });
    };

    handleFilterTag = value => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, page: 1, tagsId: value });
      localStorage.setItem(
        'params',
        JSON.stringify({ ...pr, page: 1, tagsId: value })
      );
      this.setState({ page: 1, tagsId: value });
    };

    onNext = () => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({ ...pr, page: pr.page + 1 });
      localStorage.setItem(
        'params',
        JSON.stringify({ ...pr, page: pr.page + 1 })
      );
      this.setState({ page: pr.page + 1 });
    };

    onPrev = () => {
      const {
        tagsId,
        limit,
        name,
        sort,
        by,
        ownerId,
        alphabet,
        clientCompanyId
      } = this.state;
      const { fetchData } = this.props;
      const page = JSON.parse(localStorage.getItem('params')).page;
      const params = {
        page: page - 1,
        name,
        sort,
        by,
        tagsId,
        alphabet,
        limit,
        ownerId,
        clientCompanyId
      };
      fetchData(params);
      localStorage.setItem('params', JSON.stringify(params));
      this.setState({ page: page - 1 });
    };

    onFirstPage = () => {
      const {
        tagsId,
        limit,
        name,
        sort,
        by,
        ownerId,
        alphabet,
        clientCompanyId
      } = this.state;
      const { fetchData } = this.props;
      const params = {
        page: 1,
        name,
        sort,
        by,
        tagsId,
        alphabet,
        limit,
        ownerId,
        clientCompanyId
      };
      fetchData(params);
      localStorage.setItem('params', JSON.stringify(params));
      this.setState({ page: 1 });
    };

    onLastPage = () => {
      const {
        tagsId,
        limit,
        name,
        sort,
        by,
        ownerId,
        alphabet,
        clientCompanyId
      } = this.state;
      const { fetchData, data } = this.props;
      const params = {
        page: data.pagination.lastPage,
        name,
        sort,
        by,
        tagsId,
        alphabet,
        limit,
        ownerId,
        clientCompanyId
      };
      fetchData(params);
      localStorage.setItem('params', JSON.stringify(params));
      this.setState({ page: data.pagination.lastPage });
    };

    // see more mixt-view
    onFetch = () => {
      const { fetchData, data } = this.props;
      const {
        tagsId,
        limit,
        ownerId,
        name,
        by,
        alphabet,
        clientCompanyId
      } = this.state;
      if (data.pagination.currentPage < data.pagination.lastPage) {
        const params = {
          page: data.pagination.currentPage + 1,
          limit,
          ownerId,
          name,
          by,
          tagsId,
          alphabet,
          clientCompanyId
        };
        fetchData(params);
        localStorage.setItem('params', JSON.stringify(params));
      }
    };

    onExport = (id, type) => {
      const { exportExcel, exportCsv } = this.props;
      const { clientCompanyId } = this.state;
      if (type === 'csv') {
        exportCsv({
          id,
          clientCompanyId
        });
      } else {
        exportExcel({
          id,
          clientCompanyId
        });
      }
    };

    onImport = formData => {
      const { importExcel } = this.props;
      importExcel(formData);
    };

    onBulkDelete = id => {
      const { bulkDelete, translate } = this.props;
      if (id.length > 0) {
        swal
          .fire({
            ...translate,
            type: 'warning',
            confirmButtonColor: '#f7685b'
          })
          .then(result => {
            if (result.value) {
              bulkDelete({
                id
              });
            }
          });
      } else {
        swal.fire({
          title: 'Attention!',
          text: `No data selected!.`,
          type: 'warning',
          showConfirmButton: false
        });
      }
    };

    handleFilterCompanies = param => {
      const { fetchData } = this.props;
      const pr = JSON.parse(localStorage.getItem('params'));
      fetchData({
        ...pr,
        page: 1,
        clientCompanyId: param.companyId != 'all' ? param.companyId : null
      });
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...pr,
          page: 1,
          clientCompanyId: param.companyId != 'all' ? param.companyId : null
        })
      );
      this.setState({
        page: 1,
        clientCompanyId: param.companyId != 'all' ? param.companyId : null
      });
    };

    render() {
      const {
        data,
        isLoadingCurrent,
        current,
        modal,
        modalErrorValidate
      } = this.props;
      const { columns } = tableProp;
      const { sort } = this.state;
      return (
        <Restricted to={{ objectAction: 'ReadContact' }}>
          <div className="content-wrapper pt-md-5 mt-md-1">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card rounded bg-table">
                  <TableWrapper
                    loading={data.isLoading || isLoadingCurrent}
                    data={data.data}
                    dataMixt={this.state.tableData}
                    defaultSortDirection={sort}
                    columns={columns(this.props)}
                    page={data.pagination.currentPage}
                    sizePerPage={data.pagination.recordPerPage}
                    lastPage={data.pagination.lastPage}
                    totalSize={data.pagination.count}
                    onTableChange={this.handleTableChange}
                    handleFilter={this.handleFilter}
                    handleSort={this.handleSort}
                    history={this.props.history}
                    disableAddBtn={tableProp.disableAddBtn}
                    customPath={tableProp.customPath}
                    disablePagination={tableProp.disablePagination}
                    customAction={
                      <WrappedComponent {...this.props} state={this.state} />
                    }
                    route={this.props.route}
                    onPageChange={this.onPageChange}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    onFirstPage={this.onFirstPage}
                    onLastPage={this.onLastPage}
                    onFetch={this.onFetch}
                    onExport={this.onExport}
                    onImport={this.onImport}
                    onBulkDelete={this.onBulkDelete}
                    handleFilterTag={this.handleFilterTag}
                    current={current}
                    modal={modal}
                    closeFilterCompanyModal={this.closeFilterCompanyModal}
                    filterCompanies={this.handleFilterCompanies}
                    limit={this.state.limit}
                    handleLimit={this.handleLimit}
                    filterByAlphabet={this.filterByAlphabet}
                    modalErrorValidate={modalErrorValidate}
                    errorValidation={data.errorValidation}
                  />
                </div>
              </div>
            </div>
          </div>
        </Restricted>
      );
    }
  });

export default TableListingPage;
