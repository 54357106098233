/* eslint-disable react/jsx-no-bind */
import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';

class PropStartTable extends Component {
  state = {};

  render() {
    const { element, updateElement, editElementProp } = this.props;

    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Start Table</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize
            type="text"
            className="form-control"
            defaultValue={element.field_name}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'field_name', 'value')}
          />
        </div>

        <div className="form-group mt-5">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <input
            type="text"
            className="form-control"
            disabled={this.props.isEdit}
            defaultValue={element.system_field_name}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'system_field_name', 'value')}
          />
        </div>
      </div>
    );
  }
}

export default PropStartTable;
