import * as React from 'react';
import ReactFlow, { Background, Controls, MiniMap, Node as ReactFlowNode, Edge as ReactFlowEdge, Position, ReactFlowInstance, BackgroundVariant } from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { Form } from '../create-from-template/type';
import { useFunctionState } from '../../../utils/customHooks';
import { FlexDiv } from '../../products/style';
import { BlueSidely, GreySidely, LightBlueSidely, RedSidely } from '../../../styles/global/css/Utils';
import * as _ from 'lodash';
import { PaginationValue } from '../jsonValidator';
import { BoolEditor, EditNextScreens, ErrorWrapper, PageNameEditor, WarningWrapper, Wrapper } from './componentsEditor';
import { addFieldToPage, pageExists, pageValidator } from './formProcessingTools';
import { toast } from 'react-toastify';
import Input from '../../../components_v2/input/Input';
import { EditLayout } from './layoutEditor';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import Popup from '../../../components_v2/popup/Popup';
import { LeftModal } from '../FormTemplateCreator';
import { kebabCase } from 'lodash';
import { Field, getFields } from '../../../atoms/forms';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import Close from 'images/icons/orders/close.svg';
import { Translate, translateToNode, translateToString } from '../../../styles/global/translate';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { TitleAndChild } from '../../client-companies/popup/Detail';
import { LoadingStateEnum } from '../../import/model';


export function launchToastError(message: string) {
	toast.error(message, {
		position: 'top-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined
	});
}

export function launchToastMessage(message: string) {
	toast.info(message, {
		position: 'top-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined
	});
}
const DefaultNodeStyle = { };

const WarningNodeStyle = { border: '2px solid #ff973280' };
const WarningSelectedNodeStyle = { background: '#F88158', border: '2px solid #ff973280' };

const ErrorNodeStyle = { border: '2px solid #FF1010' };
const ErrorSelectedNodeStyle = { background: '#F75D59', border: '2px solid #FF1010', color: 'white' };

const SelectedNodeStyle = {
	border: '2px solid ${BlueSidely}',
	background: LightBlueSidely
};

const InfoSelectedNodeStyle = { border: '1px solid  #f3f4bc ', background: '#feffd1' };
const InfoNodeStyle = { background: '#feffd1' };

function PanelEditor(props: { pageNumber: number, setPageEditing: (value: number | undefined) => void}) {
	const { form, setForm } = React.useContext(FormEditorContext);
	const [newForm] = React.useState<Form>(form);
	const [addFieldPopup, setAddFieldPopup] = React.useState<boolean>(false);
	const pageName = form[props.pageNumber].name;
	const [fields, setFields] = React.useState<Field[]>();
	const alert = useAlert();

	const [pageCode, setPageCode] = React.useState<string>(JSON.stringify(form[props.pageNumber], null, 2));

	React.useEffect(() => {
		setPageCode(JSON.stringify(form[props.pageNumber], null, 2));
	}, [form, props.pageNumber]);

	React.useEffect(() => {
		getFields().then(setFields);
	}, []);


	if (fields) {
		return <>
			{pageName && addFieldPopup && <Popup
				popupStyle={{ noTransition: true }}
				isOpen={addFieldPopup} popupMode={PopupMode.Details} onClickOut={() => setAddFieldPopup(false)} content={
					<>
						<div style={{ width: '100%', height: 'calc(100vh - 120px)' }}>
							<LeftModal
								externalUse={true}
								allowEdit={false}
								onFieldClicked={(f) => {
									setForm(addFieldToPage(newForm, pageName, {
										slug: f.slug,
										metadata: {}
									}));
									setAddFieldPopup(false);
								}}
								errors={[]}
							/>
						</div>
					</>}
			/>}
			<div style={{
				cursor: 'pointer',
			}} onClick={() => props.setPageEditing(undefined)}>
				<img src={Close} alt='close'/>
			</div>
			<PageNameEditor path='name' pageNumber={props.pageNumber} />
			<DefaultButton
				buttonStyle={ButtonStyle.White}
				style={{
					position: 'absolute',
					top: '54px',
					right: '475px'
				}}
				onClick={() => {
					alert({
						title: 'Confirmation de suppression',
						// eslint-disable-next-line react/no-unescaped-entities
						content: <>Voulez-vous vraiment supprimer cette page: "{pageName}" ?</>,
						buttons: [
							{
								title: translateToNode('cancel'),
								res: AlertRes.Break,
								style: ButtonStyle.White
							},
							{
								title: translateToNode('yes'),
								res: AlertRes.Ok,
								style: ButtonStyle.Error
							}
						],
					}).then((res) => {
						if (res === AlertRes.Ok) {
							props.setPageEditing(undefined);
							newForm.splice(props.pageNumber, 1);
							setForm(newForm);
						}
					});
				}}
			><Translate id='form_editor.delete_this_page' />
			</DefaultButton>
			<TitleAndChild
				title={'Paramètres de la page'}>
				<div style={{ alignItems: 'center', width: '100%' }}>
					<Wrapper>
						<Wrapper style={{ display: 'flex' }}>
							<BoolEditor defaultIsEnabled path='progression' pageNumber={props.pageNumber} /><Translate id='form_editor.enable_progression' />
						</Wrapper>
						<Wrapper>
							Clic sur bouton suivant mène vers:
							{<EditNextScreens path={'next_screens'} pageNumber={props.pageNumber} component={form[props.pageNumber].next_screens} />}
						</Wrapper>
					</Wrapper>
				</div>
			</TitleAndChild>
			<hr />
			<TitleAndChild defaultOpen={true} title={<Translate id='form_editor.display_order' />}>
				<div style={{ width: '100%' }}>
					<EditLayout fieldList={fields} path='layout' pageNumber={props.pageNumber} layout={form[props.pageNumber].layout} />
				</div>
			</TitleAndChild>
			<hr />
			<TitleAndChild title={'Éditer le code'}>
				<div>
					<Input spellcheck={false} type='text' textArea={true} inputStyle={{ color: 'black', height: '500px', width: '900px' }} name='page'
						value={pageCode}
						onChange={(text) => {
							setPageCode(text);
						}}
					></Input>
					<DefaultButton
						disabled={pageCode === JSON.stringify(form[props.pageNumber], null, 2)}
						onClick={() => {
							try {
								const newPage = JSON.parse(pageCode);
								setForm(form.map((page, index) => index == props.pageNumber ? newPage : page));
							} catch (e) {
								launchToastError('Erreur de syntaxe');
							}
						}}>
						<Translate id='save' />
					</DefaultButton>
				</div>
			</TitleAndChild>
		</>;
	}
}

export const FormEditorContext = React.createContext<{
	form: Form
	setForm: React.Dispatch<React.SetStateAction<Form>>
		}>({ form: {} as Form, setForm: () => undefined });

function FormEditorProvider(props: {form: string, setForm: (value: string) => void, children: React.ReactNode }): JSX.Element {
	const [form, setForm] = useFunctionState<Form>(JSON.parse(props.form), React.useCallback(({ newValue }) => {
		props.setForm(JSON.stringify(newValue, null, 2));
		return newValue;
	}, []));

	React.useEffect(() => {
		setForm(JSON.parse(props.form));
	}, [props.form]);

	return <FormEditorContext.Provider value={{ form, setForm }}>
		{props.children}
	</FormEditorContext.Provider>;
}

function calc_layouted_elements(form: Form, fields: Field[], pageEditing: number | undefined): {nodes: ReactFlowNode[], edges: ReactFlowEdge[] } {
	const initialEdges: ReactFlowEdge[] = [];
	const initialNodes: ReactFlowNode[] = [];

	form.forEach((screen, index) => {
		if (screen.name && fields) {
			let style = DefaultNodeStyle;

			if (index == pageEditing) {
				switch (pageValidator(form, screen.name, fields)) {
					case 'warning':
						style = WarningSelectedNodeStyle;
						break;
					case 'error':
						style = ErrorSelectedNodeStyle;
						break;
					case 'info':
						style = InfoSelectedNodeStyle;
						break;
					default:
						style = SelectedNodeStyle;
						break;
				}
			} else {
				switch (pageValidator(form, screen.name, fields)) {
					case 'warning':
						style = WarningNodeStyle;
						break;
					case 'error':
						style = ErrorNodeStyle;
						break;
					case 'info':
						style = InfoNodeStyle;
						break;
					default:
						break;
				}
			}

			initialNodes.push({
				id: screen.name,
				position: { x: 0, y: 0 },
				data: { label: screen.name, index: index },
				style: style
			});
		}
	});

	const UpStyle = { stroke: GreySidely };
	const DownStyle = { stroke: BlueSidely, strokeWidth: 3 };


	form.forEach((screen) => {
		screen.next_screens.forEach(nextScreen => {
			if (nextScreen && nextScreen.screen_name && screen.name) {
				initialEdges.push({
					id: `${screen.name}-${nextScreen.screen_name}`,
					source: nextScreen.screen_name,
					target: screen.name,
					style: UpStyle,
				});
			}
		});

		screen.components.forEach(component => {
			if (component.type == 'pagination' && typeof component.data == 'object' && !Array.isArray(component.data)) {
				const pagination = component.data;
				if (pagination && pagination['value']) {
					pagination['value'].forEach((value: PaginationValue) => {
						if (value.screen && value.screen.screen_name && screen.name) {
							if (pageExists(form, value.screen.screen_name)) {
								initialEdges.push({
									id: `${screen.name}-${value.screen.screen_name}`,
									target: value.screen.screen_name,
									source: screen.name,
									style: DownStyle,
									// markerEnd: ArrowDown,
								});
							} else {
								if (!value.screen.calculated_screen_name) {
									initialEdges.push({
										id: `${screen.name}-${value.screen.screen_name}-error`,
										target: `${value.screen.screen_name}-error`,
										source: screen.name,
										style: DownStyle,
										// markerEnd: ArrowDown,
									});
									initialNodes.push({
										id: `${value.screen.screen_name}-error`,
										position: { x: 0, y: 0 },
										data: { label: value.screen.screen_name, index: -1 },
										style: { background: RedSidely },
									});
								}
							}
						}
					});
				}
			}
			else if (component.type === 'links' && Array.isArray(component.data)) {
				component.data.forEach((link: string) => {
					if (screen.name && link != '')
						if (pageExists(form, link)) {
							initialEdges.push({
								id: `${screen.name}-${link}`,
								target: link,
								source: screen.name,
								style: DownStyle,
							});
						}
						else {
							initialEdges.push({
								id: `${screen.name}-${link}-error`,
								target: `${link}-error`,
								source: screen.name,
								style: DownStyle,
								// markerEnd: ArrowDown,
							});
							initialNodes.push({
								id: `${link}-error`,
								position: { x: 0, y: 0 },
								data: { label: link, index: -1 },
								style: { background: RedSidely },
							});
						}
				});
			}
			else if (component.screen && screen.name) {
				if (component.screen.screen_name && pageExists(form, component.screen.screen_name)) {
					initialEdges.push({
						id: `${screen.name}-${component.screen.screen_name}`,
						target: component.screen.screen_name,
						source: screen.name,
						style: DownStyle,
					});
				} else {
					if (!component.screen.calculated_screen_name) {
						initialEdges.push({
							id: `${screen.name}-${component.screen.screen_name}-error`,
							target: `${component.screen.screen_name}-error`,
							source: screen.name,
							style: DownStyle,
						});
						initialNodes.push({
							id: `${component.screen.screen_name}-error`,
							position: { x: 0, y: 0 },
							data: { label: component.screen.screen_name, index: -1 },
							style: { background: RedSidely },
						});
					}
				}
			}
		});
	});
	
	const dagreGraph = new dagre.graphlib.Graph();
	dagreGraph.setDefaultEdgeLabel(() => ({}));
	
	
	const nodeWidth = 172;
	const nodeHeight = 36;

	const getLayoutedElements = (nodes: ReactFlowNode[], edges: ReactFlowEdge[], direction = 'BR') => {
		const isHorizontal = direction === 'LR';

		dagreGraph.setGraph({ rankdir: direction });

		nodes.forEach((node) => {
			dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
		});

		edges.forEach((edge) => {
			dagreGraph.setEdge(edge.source, edge.target);
		});

		dagre.layout(dagreGraph);

		nodes.forEach((node) => {
			const nodeWithPosition = dagreGraph.node(node.id);
			node.targetPosition = isHorizontal ? Position.Left : Position.Top;
			node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
			node.position = {
				x: nodeWithPosition.x - nodeWidth / 2,
				y: nodeWithPosition.y - nodeHeight / 2,
			};
			return node;
		});
	
		return { nodes, edges };
	};

	return getLayoutedElements(
		initialNodes,
		initialEdges
	);
}


function _VisualFormEditor() {
	const { form } = React.useContext(FormEditorContext);
	const [pageEditing, setPageEditing] = React.useState<number | undefined>(undefined);
	const [reactFlowInstance, setReactFlowInstance] = React.useState<ReactFlowInstance | undefined>(undefined);
	const [layoutedEdges, setLayoutedEdges] = React.useState<ReactFlowEdge[]>([]);
	const [layoutedNodes, setLayoutedNodes] = React.useState<ReactFlowNode[]>([]);
	const [fields, setFields] = React.useState<Field[]>();


	React.useEffect(() => {
		getFields().then(setFields);
	}, []);

	React.useEffect(() => {
		if (form && fields) {
			const { nodes, edges } = calc_layouted_elements(form, fields, pageEditing);
			setLayoutedEdges(edges);
			setLayoutedNodes(nodes);
		}
	}, [JSON.stringify(form), fields, pageEditing]);


	React.useEffect(() => {
		setTimeout(() => reactFlowInstance?.fitView(), 0);
	}, [pageEditing]);
	


	function nodeClickEvent(event: React.MouseEvent, node: ReactFlowNode) {
		if (!node.id.includes('-error')) {
			if (pageEditing != undefined) {
				setPageEditing(node.data.index);
			} else {
				setPageEditing(node.data.index);
				setTimeout(() => reactFlowInstance?.fitView(), 0);
			}
		} else {
			setPageEditing(undefined);
			launchToastError(node.id.replace('-error', '') + ' does not exist in this form.');
		}
	}	

	function onInit(instance: ReactFlowInstance) {
		setReactFlowInstance(instance);
	}
	return <>
		{/* Todo: add page with guided interface and different templates */}
		<FlexDiv
			style={
				{
					overflow: 'auto',
				}	
			}
		>


			<div style={{ width: 'calc(' + (pageEditing != undefined ? 33 : 100) + 'vw - 60px)', height: 'calc(100vh - 120px)' }}>
				{layoutedNodes.length >= 1 && <ReactFlow
					fitView={true}
					nodes={layoutedNodes}
					edges={layoutedEdges}
					onNodeClick={nodeClickEvent}
					onInit={onInit}
					proOptions={{ hideAttribution: true }}
				>
					<Controls />
					<MiniMap />
					<Background variant={BackgroundVariant.Dots} gap={12} size={1} />
				</ReactFlow>}
			</div>
			{pageEditing != undefined && <div style={{ height: 'calc(100vh - 120px)', width: 'calc(69vw - 120px)', backgroundColor: 'white', overflow: 'auto' }}>
				<PanelEditor setPageEditing={setPageEditing} pageNumber={pageEditing} />
			</div>}
		</FlexDiv>
	</>;
}

export function check_if_form_uses_id(form: Form): boolean {
	return form.some((page) => page.fields.some((field) => field.field_id !== undefined));
}

export function update_form_to_use_slugs(form: Form, fields: Field[], lib: string, setLib: React.Dispatch<React.SetStateAction<string>>): Form {
	const newForm = _.cloneDeep(form);
	const replace_acc: Record<string, string>[] = [];
	const replace_id_acc: Record<string, string>[] = [];



	newForm.map((page) => {
		page.fields.map((field) => {
			if (field && field.field_id) {
				const newField = fields.find((f) => f.id === field.field_id);
				if (newField) {
					lib = lib.replaceAll(field.field_id.toString(), '"' + newField.slug + '"');
					replace_id_acc.push({ old: field.field_id.toString(), new: newField.slug });
				}
				if (newField && field.name) {
					replace_acc.push({ old: field.name, new: newField.slug });
					field.slug = newField.slug;
					field.name = undefined;
					field.field_id = undefined;
				}
				else {
					field.slug = field.name ? kebabCase(field.name) : 'undefined';
					field.field_id = undefined;
					field.name = undefined;
				}
			}
		});
	});
	let anotherNewForm = JSON.parse(JSON.stringify(newForm));

	replace_acc.forEach((replace) => {
		if (replace.old !== 'type' && replace.old !== 'slug' && replace.old !== 'metadata' && replace.old !== 'field_id' && replace.old !== 'name' && replace.old !== 'value') {
			anotherNewForm = JSON.parse(JSON.stringify(anotherNewForm).replaceAll('"' + replace.old + '"', '"' + replace.new + '"'));
			anotherNewForm = JSON.parse(JSON.stringify(anotherNewForm).replaceAll('\\"' + replace.old + '\\"', '\\"' + replace.new + '\\"'));
		}
	});

	replace_id_acc.forEach((replace) => {
		anotherNewForm = JSON.parse(JSON.stringify(anotherNewForm, null, 2).replaceAll('getSavedFieldByIdAndCurrentMetadataAndSubMetadata(' + replace.old, 'getSavedFieldByIdAndCurrentMetadataAndSubMetadata(' + '\\"' + replace.new + '\\"'));
		anotherNewForm = JSON.parse(JSON.stringify(anotherNewForm, null, 2).replaceAll('getSavedFieldByIdAndCurrentMetadata(' + replace.old, 'getSavedFieldByIdAndCurrentMetadata(' + '\\"' + replace.new + '\\"'));
	});

	setLib(lib);
	return anotherNewForm;
}


// eslint-disable-next-line react/display-name
const VisualFormEditor = React.forwardRef((props: {
	setLoadingState: React.Dispatch<React.SetStateAction<LoadingStateEnum>>,
	setErrors: React.Dispatch<React.SetStateAction<object[][]>>,
	screens: string,
	setScreens: (value: string) => void,
	lib: string,
	setLib: React.Dispatch<React.SetStateAction<string>>
	exportForm: () => void
}, parentRef) => {
	const [openEditFields, setOpenEditFields] = React.useState<boolean>(false);
	React.useImperativeHandle(parentRef, () => ({
		onErrorClicked: console.log,
		onFieldClicked: console.log
	}), []);
	const [fields, setFields] = React.useState<Field[]>();
	const hasWeird = props.lib.includes('evaluateChildFunction');
	const hasIndex = props.screens.includes('"index":');
	const alert = useAlert();

	React.useEffect(() => {
		getFields().then(setFields);
	}, []);

	if (check_if_form_uses_id(JSON.parse(props.screens)) && fields) {

		return <>
			<div>
				{!hasIndex && <Wrapper>
					Le formulaire doit être mis à jour pour utiliser l éditeur de formulaire visuel. Nous vous conseillons de sauvegarder le formulaire avant de le mettre à jour.
				</Wrapper>}
				{hasWeird && !hasIndex && <WarningWrapper>
					Le formulaire utilise des fonctions personnalisées, veuillez les vérifier les slugs dans la bibliothèque après la mise à jour.
				</WarningWrapper>}
				{hasIndex && <ErrorWrapper>
					Impossible de mettre à jour le formulaire.
				</ErrorWrapper>}
			</div>
			{!hasIndex && <DefaultButton
				onClick={
					() => {
						//
						alert({
							title: translateToString('form_editor.convert.you_are_converting'),
							content: <Translate id='form_editor.convert.have_you_made_backup' />,
							buttons: [
								{
									title: translateToNode('cancel'),
									res: AlertRes.Break,
									style: ButtonStyle.White
								},
								{
									title: translateToNode('yes'),
									res: AlertRes.Ok,
									style: ButtonStyle.Error
								}
							],
						}).then((res) => {
							if (res === AlertRes.Ok) {
								props.exportForm();
								props.setScreens(JSON.stringify(update_form_to_use_slugs(JSON.parse(props.screens), fields, props.lib, props.setLib), null, 2));
							}
						});
					}
				}>
				Mettre à jour le formulaire
			</DefaultButton>}
		</>;
	}

	return <>
		<Popup isOpen={openEditFields} popupMode={PopupMode.Details} onClickOut={() => setOpenEditFields(false)} content={
			<div style={{ width: '100%', height: '100%' }}>
				<LeftModal
					externalUse={true}
					allowEdit={true}
					errors={[]}

				/>
			</div>}
		/>

		<FormEditorProvider
			form={props.screens}
			setForm={props.setScreens}
		>
			<_VisualFormEditor/>
		</FormEditorProvider>
	</>;

});

export default VisualFormEditor;