import { ShelfAuditDetail, ShelfAuditSimple, ShelfAuditTemplate } from './model/models';
import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';
import { DefaultTableQuery } from '../checkouts/actions';

export async function getShelfAudit(
	shelfAuditId: number,
	client_company_id: number,
): Promise<ShelfAuditDetail> {
	return await axios
		.get(`${URL_FOUNDATION}/api/v2/shelf-audits/instances/${shelfAuditId}?client_company_id=${client_company_id}`).then(res => res.data).catch(e => {
			console.log(e);
			return undefined;
		});
}

export async function deleteShelfAudit(id: number): Promise<unknown> {
	return await axios.delete(`${URL_FOUNDATION}/api/v2/shelf-audits/instances/${id}`).then(res => res.data);
}

interface GetShelfAuditTemplatesQueryParams {
	limit: number,
	offset: number,
	order_by?: string,
	descending?: boolean
}

export async function getShelfAuditTemplates({
	limit,
	offset,
	order_by,
	descending
}: GetShelfAuditTemplatesQueryParams): Promise<ShelfAuditTemplate[]> {
	let url = `${URL_FOUNDATION}/api/v2/shelf-audits/templates?limit=${limit}&offset=${offset}`;
	if (order_by) url += `&order_by=${order_by}`;
	if (descending !== undefined) url += `&descending=${descending}`;
	return await axios.get(url).then(res => res.data).catch(e => {
		console.log(e);
		return [];
	});
}

interface ShelfAuditTableQuery extends DefaultTableQuery {
	client_company_id?: number
}

export async function getShelfAuditsQueryByTemplateId(template_id: number, body: ShelfAuditTableQuery): Promise<ShelfAuditSimple[]> {
	const url = `${URL_FOUNDATION}/api/v2/shelf-audits/templates/${template_id}/instances`;
	return await axios.post(url, body).then(res => res.data).catch(e => {
		console.log(e);
		return [];
	});
}
