/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React, {Component, Fragment} from 'react';
import swal from 'sweetalert2';
import {get} from 'lodash';
import moment from 'moment';
import TableWrapper from './TableWrapper';

const TableListingPage = tableProp => WrappedComponent =>
  (class TableListingHOC extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sort: 'desc',
        by: null,
        page: 1,
        limit: 50,
        createdOn: null,
        user_id: null,
        groupId: null,
        user_form_id: null
      };
    }

    componentWillReceiveProps(nextProps) {
      const { getterValue, fetchData, match, findLinkedform } = this.props;
      const { page, limit } = this.state;
      if (
        JSON.stringify(getterValue) !== JSON.stringify(nextProps.getterValue)
      ) {
        if (match.params.linkedId) {
          findLinkedform({
            link_id: match.params.linkedId,
            link_type: match.params.type,
            user_form_id: get(nextProps.getterValue, 'id'),
            page: 1,
            limit: 10
          });
        } else {
          fetchData({
            page,
            limit,
            user_form_id: get(nextProps.getterValue, 'id')
          });
        }

        this.setState({
          user_form_id: get(nextProps.getterValue, 'id')
        });
      }
    }

    handleSort = (sort, by) => {};

    handleFilter = data => {
      const { fetchData } = this.props;
      const { limit, date_type, user_id, user_form_id } = this.state;
      fetchData({
        page: 1,
        limit,
        user_form_id,
        created_at:
          !data.date_type || ['all', null].includes(data.date_type)
            ? null
            : moment().format('YYYY-MM-DD'),
        date_type: ['all', 'today'].includes(data.date_type)
          ? null
          : data.date_type || date_type,
        user_id: data.user_id === 'all' ? null : data.user_id || user_id
      });

      this.setState({
        page: 1,
        date_type:
          data.date_type === 'all' ? null : data.date_type || date_type,
        user_id: data.user_id === 'all' ? null : data.user_id || user_id
      });
    };

    handleFilterGroup = data => {};

    onInitViewList = () => {};

    onNext = () => {
      const {
        sort,
        by,
        page,
        user_id,
        user_form_id,
        date_type,
        limit
      } = this.state;
      const { fetchData, subPath, match, findLinkedform } = this.props;
      const params = {
        page: page + 1,
        limit
      };
      if (match.params.linkedId) {
        findLinkedform({
          ...params,
          link_id: match.params.linkedId,
          link_type: match.params.type,
          user_form_id
        });
      } else {
        fetchData(
          {
            ...params,
            sort,
            by,
            date_type,
            user_id,
            user_form_id
          },
          subPath
        );
      }
      this.setState({ page: page + 1 });
    };

    onPrev = () => {
      const { sort, by, page, user_id, user_form_id, date_type } = this.state;
      const { fetchData, subPath, match, findLinkedform } = this.props;
      const params = {
        page: page - 1,
        limit: 10
      };
      if (match.params.linkedId) {
        findLinkedform({
          ...params,
          link_id: match.params.linkedId,
          link_type: match.params.type,
          user_form_id
        });
      } else {
        fetchData(
          {
            ...params,
            sort,
            by,
            date_type,
            user_form_id,
            user_id
          },
          subPath
        );
      }
      this.setState({ page: page - 1 });
    };

    onFirstPage = () => {
      const { sort, by, user_id, user_form_id, date_type, limit } = this.state;
      const { fetchData, subPath, match, findLinkedform } = this.props;
      const params = {
        page: 1,
        limit
      };
      if (match.params.linkedId) {
        findLinkedform({
          ...params,
          link_id: match.params.linkedId,
          link_type: match.params.type,
          user_form_id
        });
      } else {
        fetchData(
          {
            ...params,
            sort,
            by,
            date_type,
            user_form_id,
            user_id
          },
          subPath
        );
      }
      this.setState({ page: 1 });
    };

    onLastPage = () => {
      const { sort, by, user_id, user_form_id, limit } = this.state;
      const { fetchData, data, subPath, findLinkedform, match } = this.props;
      const params = {
        page: data.pagination.lastPage,
        limit
      };
      if (match.params.linkedId) {
        findLinkedform({
          ...params,
          link_id: match.params.linkedId,
          link_type: match.params.type,
          user_form_id
        });
      } else {
        fetchData(
          {
            ...params,
            sort,
            by,
            user_form_id,
            user_id
          },
          subPath
        );
      }
      this.setState({ page: data.pagination.lastPage });
    };

    toggle() {
      this.setState({
        modal: false
      });
    }

    onExport = id => {
      const { exportExcel, getterValue } = this.props;
      const user_form_id = get(getterValue, 'id');
      const user_form_name = get(getterValue, 'name');
      if (id && id.length > 0) {
        const params = `user_form_field_id=${id.join('&user_form_field_id=')}`;
        exportExcel(params, user_form_id, user_form_name);
      } else {
        exportExcel(null, user_form_id, user_form_name);
      }
    };

    onImport = formData => {};

    onBulkDelete = id => {
      const { bulkDelete, translate, fetchData } = this.props;
      const { page, limit, user_form_id } = this.state;
      if (id && id.length > 0) {
        const params = `id=${id.join('&id=')}`;
        swal
          .fire({
            ...translate,
            type: 'warning',
            confirmButtonColor: '#f7685b'
          })
          .then(result => {
            if (result.value) {
              bulkDelete(params).then(() =>
                fetchData({
                  page,
                  limit,
                  user_form_id
                })
              );
            }
          });
      } else {
        swal.fire({
          title: 'Attention!',
          text: `No data selected!.`,
          type: 'warning',
          showConfirmButton: false
        });
      }
    };

    handleLimit = limit => {
      const { sort, by, page, user_id, user_form_id, date_type } = this.state;
      const { fetchData, subPath, match, findLinkedform } = this.props;
      const params = {
        page,
        limit
      };
      const pr = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem('params', JSON.stringify({ ...pr, limit }));
      if (match.params.linkedId) {
        findLinkedform({
          ...params,
          link_id: match.params.linkedId,
          link_type: match.params.type,
          user_form_id
        });
      } else {
        fetchData(
          {
            ...params,
            sort,
            by,
            date_type,
            user_id,
            user_form_id
          },
          subPath
        );
      }
      this.setState({ limit });
    };

    render() {
      const { data, current, modal, match, getterValue } = this.props;
      const { columns } = tableProp;
      const { sort } = this.state;

      return (
        <div className="content-wrapper pt-md-5 mt-md-1">
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card rounded bg-table">
                <Fragment>
                  <TableWrapper
                    loading={current.isLoading || data.isLoading}
                    dataNoPagination={data.dataNoPagination}
                    data={data.data}
                    current={current.current}
                    defaultSortDirection={sort}
                    columns={columns(this.props)}
                    page={data.pagination.currentPage}
                    sizePerPage={data.pagination.recordPerPage}
                    lastPage={data.pagination.lastPage}
                    totalSize={data.pagination.count}
                    onTableChange={this.handleTableChange}
                    handleFilter={this.handleFilter}
                    handleFilterGroup={this.handleFilterGroup}
                    history={this.props.history}
                    disableAddBtn={tableProp.disableAddBtn}
                    customPath={tableProp.customPath}
                    disablePagination={tableProp.disablePagination}
                    route={this.props.route}
                    onPageChange={this.onPageChange}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    onFirstPage={this.onFirstPage}
                    onLastPage={this.onLastPage}
                    modal={modal}
                    onInitViewList={this.onInitViewList}
                    onExport={this.onExport}
                    onBulkDelete={this.onBulkDelete}
                    onImport={this.onImport}
                    match={match}
                    getterValue={getterValue}
                    handleLimit={this.handleLimit}
                    limit={this.state.limit}
                  />
                </Fragment>

                <WrappedComponent {...this.props} state={this.state} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  });

export default TableListingPage;
