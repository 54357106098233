import * as React from 'react';
import { Editor } from '../../reports/generic/genericEditorRecursive';
import { Translate, translateToString } from '../../../styles/global/translate';
import Popup from '../../../components_v2/popup/Popup';
import styled from 'styled-components';
import { BorderColor, SidelyBlack, DarkGreySidely2, BlueSidely } from '../../../styles/global/css/Utils';
import { ReportFiltersContext } from '../../reports/generic/generic';
import { FlexDiv } from '../../products/style';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { translateToNode } from '../../../styles/global/translate';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { BeginPeriods, EndPeriods, GenericEditorContext, useSetFilters } from '../../reports/generic/genericEditor';
import { TitleAndChild, LineHoverDiv } from '../../client-companies/popup/Detail';
import Input from '../../../components_v2/input/Input';
import { initFilters, getFinalyFilteredProducts } from '../../reports/generic/generic';
import { CalculatedField, CalculatedFieldTarget } from '../../../atoms/calculatedFields';
import { AProducts } from '../../../atoms/product';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { TimeRange } from 'bindings/reports/generic/TimeRange';
import { OnDateChangeType, RawDatePicker } from '../../../components_v2/toolbarFilter/time/DatePicker';
import moment from 'moment';
import { momentToNaiveDateTime } from '../../../components_v2/utils';
import _ from 'lodash';

const CalcFieldTitle = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	color: ${SidelyBlack};
	gap: 15px;
	padding: 0 10px 10px 10px;
	height: 50px;
	font-size: 16px;
	font-weight: 600;
	justify-content: space-between;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 4%;
		width: 92%;
		border-bottom: 2px solid ${BorderColor};
	}
`;

const OptionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 50%;
	height: calc(100% - 50px);
	padding: 20px 15px 0 0;
	border-right: 2px solid ${BorderColor};
	overflow-y: scroll;
	overflow-x: hidden;
`;

const SaveButton = styled(DefaultButton)`
	width: 7vw;
	margin: 5px;
`;

const FiltersWrapper = styled(LineHoverDiv)`
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding-left: 20px;
	width: 97%;
`;

const MandatoryField = styled.p`
	color: ${DarkGreySidely2};
	font-size: 13px;
    &:before {
		color: red;
		margin-right: 3px;
        content: "*";
    }
`;

const BoolSliderContainer = styled.div<{isActive?: boolean}>`
	position: relative;
	width: 50px;
	background-color: ${props => props.isActive ? BlueSidely : DarkGreySidely2};
	height: 26px;
	border-radius: 26px;
	transition: background-color 0.3s;
	cursor: pointer;
`;

const BoolSliderDot = styled.div<{ isActive?: boolean }>`
	position: absolute;
	top: 4px;
	left: 4px;
	width: 18px;
	height: 18px;
	background-color: white;
	border-radius: 50%;
	transform: ${props => props.isActive ? 'translateX(24px)' : 'translateX(0)'};
	transition: transform 0.3s;
`;

function BoolSlider(props: {isActive: boolean, onClick: () => void}): JSX.Element {
	return <>
		<BoolSliderContainer onClick={props.onClick} isActive={props.isActive}>
			<BoolSliderDot isActive={props.isActive}/>
		</BoolSliderContainer>
	</>;
}

export function CalculatedFieldsCreation(props: { 
		isOpen: boolean,
		setIsOpen: (value: boolean) => void,
		setCalculatedField: (value: CalculatedField | null) => void,
		calculatedField: CalculatedField | null,
	}) {
	const { isOpen, setIsOpen, setCalculatedField, calculatedField } = props;
	const { report, setReport } = React.useContext(GenericEditorContext);
	const [calcFieldName, setCalcFieldName] = React.useState<string>(calculatedField?.field_name || '');
	const trimmedCalcFieldName = calcFieldName.trim();
	const [dynamicDateRange, setDynamicDateRange] = React.useState<TimeRange>(calculatedField?.report?.range ? {
		begin: calculatedField.report.range.begin,
		end: calculatedField.report.range.end
	} : { begin: '90DaysAgo', end: 'Today' });
	const [staticDateRange, setStaticDateRange] = React.useState<OnDateChangeType>(calculatedField?.report?.range ? {
		startDate: moment(calculatedField.report.range.begin),
		endDate: moment(calculatedField.report.range.end)
	} : { startDate: moment().subtract(3, 'month').startOf('day'), endDate: moment().startOf('day') });
	const { filters, jsxFilters } = React.useContext(ReportFiltersContext);
	const owners = useRecoilValue(AUsers);
	const [columnSelected, setColumnSelected] = React.useState<boolean>(false);
	const [defaultFilters, setDefaultFilters] = React.useState<string | null>(null);
	const products = useRecoilValue(AProducts);
	const [periodIsDynamic, setPeriodIsDynamic] = React.useState<boolean>(false);
	const BeginPeriodTypes: DropdownData[] = [];
	const EndPeriodTypes: DropdownData[] = [];

	BeginPeriodTypes.push({ value: undefined, label: translateToString('report_editor.period.begin_default') });
	EndPeriodTypes.push({ value: undefined, label: translateToString('report_editor.period.end_default') });
	
	BeginPeriods.map(e => (BeginPeriodTypes.push({ value: e, label: translateToString('report_editor.period.' + e) })));
	EndPeriods.map(e => (EndPeriodTypes.push({ value: e, label: translateToString('report_editor.period.' + e) })));


	React.useEffect(() => {
		if (calculatedField) {
			const { filters } = initFilters(calculatedField.report);
			setDefaultFilters(JSON.stringify(filters));
		}
	}, [calculatedField]);

	function hasChangesCreate(): boolean {
		return columnSelected && trimmedCalcFieldName.length > 0 && ((dynamicDateRange.begin !== null && dynamicDateRange.end !== null) || (staticDateRange.startDate !== null && staticDateRange.endDate !== null));
	}

	function hasChangesEdit(): boolean {
		if (!calculatedField || trimmedCalcFieldName.length <= 0) return false;
		const filtersForComparison = _.cloneDeep(filters);
		if (filtersForComparison?.my_filters?.products?.all === true) {
			filtersForComparison.my_filters = { 'products':{ 'all':true,'products':[] } };
		}
		const basicChanges = columnSelected || calculatedField.field_name !== trimmedCalcFieldName || defaultFilters !== JSON.stringify(filtersForComparison);
		if (periodIsDynamic && dynamicDateRange.begin !== null && dynamicDateRange.end !== null) {
			return basicChanges || (
				calculatedField.report.range?.begin !== dynamicDateRange.begin || calculatedField.report.range?.end !== dynamicDateRange.end
			);
		}
		else if (staticDateRange.startDate !== null && staticDateRange.endDate !== null) {
			return basicChanges || (
				calculatedField.report.range?.begin !== momentToNaiveDateTime(staticDateRange.startDate) || calculatedField.report.range?.end !== momentToNaiveDateTime(staticDateRange.endDate)
			);
		}
		return false;
	}

	const unlockSave = calculatedField ? hasChangesEdit() : hasChangesCreate();

	React.useEffect(() => {
		if (isOpen) {
			if (calculatedField && calculatedField.report) {
				setReport(calculatedField.report);
				setCalcFieldName(calculatedField.field_name);
				if (calculatedField.report.range) {
					if (moment(calculatedField.report.range.begin).isValid() && moment(calculatedField.report.range.end).isValid()) {
						setPeriodIsDynamic(false);
						setStaticDateRange({
							startDate: moment(calculatedField.report.range.begin),
							endDate: moment(calculatedField.report.range.end)
						});
						setDynamicDateRange({ begin: '90DaysAgo', end: 'Today' });
					}
					else {
						setPeriodIsDynamic(true);
						setDynamicDateRange({
							begin: calculatedField.report.range.begin,
							end: calculatedField.report.range.end
						});
						setStaticDateRange({ startDate: moment().subtract(3, 'month').startOf('day'), endDate: moment().startOf('day') });
					}
				}
			}
			else if (!calculatedField) {
				setCalcFieldName('');
				setDynamicDateRange({ begin: '90DaysAgo', end: 'Today' });
				setStaticDateRange({ startDate: moment().subtract(3, 'month').startOf('day'), endDate: moment().startOf('day') });
				setReport({
					'group_by': 'Company',
					'panels': [
						{ 'columns': [], products: undefined, name: '' }
					],
					'range': { begin: staticDateRange.startDate ? momentToNaiveDateTime(staticDateRange.startDate) : '', end: staticDateRange.endDate ? momentToNaiveDateTime(staticDateRange.endDate) : '' },
					'window': null,
					'evolution': null,
					'variation': null,
					'filter': null
				});
			}
		}
	}, [isOpen]);

	useSetFilters(setReport, report, filters, owners);

	return <>
		<Popup isOpen={isOpen} hasBackground disableOutClick onClickOut={() => setIsOpen(false)} 
			popupStyle={{ top: '0', left: '40vw', height: '100vh', width: '60vw', animate: true, boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.06)' }}
			content={<>
				<CalcFieldTitle>
					<div style={{ padding: '0 0 0 15px' }}><Translate id={calculatedField ? 'calculated_fields.edit' : 'calculated_fields.create'}/></div>
					<FlexDiv >
						<SaveButton
							buttonStyle={ButtonStyle.White}
							onClick={() => {
								setCalculatedField(null);
								setCalcFieldName('');
								setColumnSelected(false);
								setIsOpen(false);
							}}>
							{translateToNode('cancel')}
						</SaveButton>
						<SaveButton
							disabled={!unlockSave}
							onClick={() => {
								setCalcFieldName('');
								setColumnSelected(false);
								const finallyFilteredProducts = getFinalyFilteredProducts(filters, products);
								if (finallyFilteredProducts)
									report.product_filter = finallyFilteredProducts;
								if (periodIsDynamic && dynamicDateRange.begin && dynamicDateRange.end)
									report.range = { begin: dynamicDateRange.begin, end: dynamicDateRange.end };
								else if (staticDateRange.startDate && staticDateRange.endDate)
									report.range = { begin: momentToNaiveDateTime(staticDateRange.startDate), end: momentToNaiveDateTime(staticDateRange.endDate) };
								setCalculatedField({
									id: calculatedField ? calculatedField.id : { unsync: Date.now() },
									target: CalculatedFieldTarget.Company,
									field_name: trimmedCalcFieldName,
									report: report
								});
								setIsOpen(false);
							}}>
							{translateToNode('save')}
						</SaveButton>
					</FlexDiv>
				</CalcFieldTitle>
				<FlexDiv width='100%' height='100%' align='stretch' padding='0px 10px 20px 10px'>
					<OptionsWrapper>
						<FlexDiv align='stretch' flow='column' gap='10px' padding='0 0 0 15px'>
							<FlexDiv flow='column' align='stretch' gap='8px'>
								<MandatoryField><Translate id='calculated_fields.field_name'/></MandatoryField>
								<Input 
									name={'calc_field_name'}
									type={'text'} 
									value={calcFieldName}
									onChange={(value) => setCalcFieldName(value)}
									inputStyle={{ 
										height: '40px', 
										width: '25vw', 
										padding: '0 0 0 10px',
										borderRadius: '5px' }}/>
							</FlexDiv>
							<FlexDiv flow='column' align='stretch' gap='12px'>
								<MandatoryField><Translate id='calculated_fields.field_period' /></MandatoryField>
								<FlexDiv justify='center' gap='12px'>
									<Translate id='report_editor.period.static_period' />
									<BoolSlider isActive={periodIsDynamic} onClick={() => setPeriodIsDynamic(!periodIsDynamic)}/>
									<Translate id='report_editor.period.dynamic_period' />
								</FlexDiv>
								{ periodIsDynamic ?
									<Dropdown
										name='range'
										dropdownStyle={{ width: '25vw', height: '38px', optionWidth: '25vw' }}
										readOnly
										datalist={BeginPeriodTypes}
										selectedValue={BeginPeriodTypes.find(a => {
											if (dynamicDateRange && dynamicDateRange.begin !== '90DaysAgo') {
												return a.value === dynamicDateRange.begin;
											} else {
												return a.value === undefined;
											}
										})
										}
										onChange={value => {
											setDynamicDateRange({ ...dynamicDateRange, begin: value.value ?? '90DaysAgo', end: 'Today' });
										}}
									/>
									:
									<RawDatePicker future={true} start={staticDateRange.startDate} end={staticDateRange.endDate} onDatesChange={(dates) => setStaticDateRange(dates)} fillWidth vertical/>
								}
							</FlexDiv>
						</FlexDiv>
						<TitleAndChild
							title={translateToString('calculated_fields.data_filters')}
							margin='0 0 0 0'
							addDiv
							defaultOpen={true}
							localCollapseStyle={{ justifyContent: 'left' }}>
							<FiltersWrapper>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.form_instances'/>
									{jsxFilters && jsxFilters[0] && jsxFilters[0][1].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.form_models'/>
									{jsxFilters && jsxFilters[1] && jsxFilters[1][1].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.form_creator'/>
									{jsxFilters && jsxFilters[1] && jsxFilters[1][0].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch'>
									<div style={{ padding:'0 0 8px 0' }}><Translate id='report_editor.filter_categories.product_categories'/></div>
									{jsxFilters && jsxFilters[2] && jsxFilters[2][0].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.brands'/>
									{jsxFilters && jsxFilters[2] && jsxFilters[2][1].child}
								</FlexDiv>
								<FlexDiv flow='column' align='stretch' gap='8px'>
									<Translate id='report_editor.filter_categories.product_tags'/>
									{jsxFilters && jsxFilters[2] && jsxFilters[2][2].child}
								</FlexDiv>
							</FiltersWrapper>
						</TitleAndChild>
					</OptionsWrapper>
					<div style={{ width: '50%', height: '97%', paddingTop: '10px' }}>
						<Editor
							column={calculatedField ? calculatedField.report.panels[0].columns[0] : undefined}
							path={'panels[0].columns'}
							index={0}
							onSave={() => {setColumnSelected(true);}}
							isCalcField
						/> 
					</div>
				</FlexDiv>
			</>}/>
	</>;
}