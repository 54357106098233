import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

const AglobalInputSearch: AtomState<string> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_input_search', // UNIQUE ID
		default: ''
	})
};

const AorderInputSearch: AtomState<string> = {
	category: AtomCategory.ORDER,
	atom: atom({
		key: 'atom_order_input_search', // UNIQUE ID
		default: ''
	})
};

const AgalleryInputSearch: AtomState<string> = {
	category: AtomCategory.GALLERY,
	atom: atom({
		key: 'atom_gallery_input_search', // UNIQUE ID
		default: ''
	})
};

const AcompanyInputSearch: AtomState<string> = {
	category: AtomCategory.COMPANY,
	atom: atom({
		key: 'atom_company_input_search', // UNIQUE ID
		default: ''
	})
};

const AcalendarInputSearch: AtomState<string> = {
	category: AtomCategory.CALENDAR,
	atom: atom({
		key: 'atom_calendar_input_search', // UNIQUE ID
		default: ''
	})
};

const AInputSearch: RecoilState<string> = selector({
	key: 'atom_input_search',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<string> {
	switch (category) {
		case AtomCategory.GLOBAL:
			return AglobalInputSearch.atom;
		case AtomCategory.ORDER:
			return AorderInputSearch.atom;
		case AtomCategory.GALLERY:
			return AgalleryInputSearch.atom;
		case AtomCategory.COMPANY:
			return AcompanyInputSearch.atom;
		case AtomCategory.CALENDAR:
			return AcalendarInputSearch.atom;
		default:
			return AglobalInputSearch.atom;
	}
}

export {
	AorderInputSearch,
	AcompanyInputSearch,
	AgalleryInputSearch,
	AglobalInputSearch,
	AcalendarInputSearch,
	AInputSearch
};
