/* eslint-disable no-unused-vars */
import React from 'react';
import Select from 'react-select';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import renderSelectInput from './select/RenderSelectInput';
import renderselectmultiple from './select/RenderSelectOptionMultiple';
import renderSelectOption from './select/RenderSelectOption';
import renderSelectInpuNoLabel from './select/RenderSelectInputNoLabel';
import renderSelectOptionNoLabel from './select/RenderSelectOptionNoLabel';
import { colourStyles } from '../helpers/colorStyle';
import calendarImage from 'images/icon/calendar.png';

export const required = value => (value ? undefined : 'Fill The Field');

export const emailValid = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
		? 'Invalid email address'
		: undefined;

export const maxLength = max => value =>
	value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = min => value =>
	value && value.length < min ? `Must be ${min} characters or less` : undefined;

export const maxLength300 = maxLength(300);

export const minLength8 = minLength(8);

export const minLength6 = minLength(6);
export const minLength1 = minLength(1);

export const minValue0 = value => {
	if (value < 0) {
		return 0;
	}
	return value;
};

export const minValue1 = value => {
	if (value < 1) {
		return 1;
	}
	return value;
};

export function formatNumber(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function formatNumberComma(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const normalizeNumber = value => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');
	return formatNumber(onlyNums);
};

export const lessThan = otherField => (value, previousValue, allValues) =>
	value && parseFloat(value) < parseFloat(allValues.values[otherField])
		? undefined
		: `value must not be greater than ${otherField}`;

export const greaterThan = otherField => (value, previousValue, allValues) =>
	value && parseFloat(value) > parseFloat(allValues.values[otherField])
		? undefined
		: `value cannot be lower than ${otherField}`;

export const characterEachWord = str => {
	const matches = str.match(/\b(\w)/g);
	return <div>{matches.join('')}</div>;
};

export const renderField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	onChange,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group row">
			{!noLabel && (
				<div className="col-md-2">
					<label htmlFor={id}>
						{requiredStar === true ? (
							<span className="text-required">* </span>
						) : (
							''
						)}
						{label}
					</label>
				</div>
			)}

			<div className="col d-flex align-items-center">
				<input
					{...input}
					placeholder={placeholder}
					type={type}
					disabled={disabled}
					id={id}
					onChange={value => {
						input.onChange(value);
						onChange(value);
					}}
					className={`form-control ${touched && error ? 'focus-error' : ''}`}
				/>
				{touched &&
          ((error && <span className="form-error">{error}</span>) ||
            (warning && <span className="form-error">{warning}</span>))}
			</div>
		</div>
	</div>
);

export const renderEmailField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group row">
			<div className="col-md-2">
				<div className="">
					{!noLabel && (
						<label htmlFor={id}>
							{label}
							{requiredStar === true ? (
								<span className="text-required"> *</span>
							) : (
								''
							)}
						</label>
					)}
				</div>
				<div className="">
					{' '}
					{touched &&
            ((error && error !== 'Required' && (
            	<span
            		className={`form-error ${
            			error === 'email tersedia' ? 'text-green' : null
            		}`}
            	>
            		{error}
            	</span>
            )) ||
              (warning && <span className="form-error">{warning}</span>))}
				</div>
			</div>

			<div className="col">
				<div
					className={`form-control-email ${
						touched && error !== 'email tersedia' ? 'focus-error' : ''
					} col`}
				>
					<input
						{...input}
						placeholder={placeholder}
						type={type}
						disabled={disabled}
						id={id}
						className="form-control-subEmail"
					/>
					{touched && error === 'email tersedia' && (
						<div className="circle-green">
							<i className="fas fa-check text-white" />
						</div>
					)}
				</div>
			</div>
		</div>
	</div>
);

export const generatePassword = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	requiredStar,
	generate,
	id,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<label htmlFor={id}>
				{label}
				{requiredStar === true ? <span className="text-required"> *</span> : ''}
			</label>
			<div className="d-flex">
				<input
					{...input}
					placeholder={placeholder}
					type={type}
					disabled={disabled}
					id={id}
					className="form-control"
				/>
				<button className="btn btn-xs ml-2 btn-primary" onClick={generate}>
          Generate
				</button>
			</div>
			{touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const checkField = ({
	input,
	disabled,
	label,
	id,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="form-check">
				<label className="form-check-label">
					<input
						{...input}
						checked={input.value === true}
						disabled={disabled}
						type="checkbox"
						className="form-check-input"
					/>
					{label}
					<i className="input-helper" />
				</label>
			</div>
		</div>

		{touched &&
      ((error && <span className="form-error">{error}</span>) ||
        (warning && <span className="form-error">{warning}</span>))}
	</div>
);

export const selectField = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	children,
	requiredStar,
	id,
	appendright,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group row">
			<div className="col-md-2">
				<label htmlFor={id}>
					{requiredStar === true ? (
						<span className="text-required">* </span>
					) : (
						''
					)}
					{label}
				</label>
			</div>
			<div className="col">
				<InputGroup>
					{appendright && (
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<img
									alt="ProfileImage"
									className="imgMounth rounded-circle ml-2 my-auto"
									src={calendarImage}
								/>
							</InputGroupText>
						</InputGroupAddon>
					)}
					<select
						{...input}
						type={type}
						disabled={disabled}
						id={id}
						placeholder={placeholder}
						className="form-control"
					>
						{placeholder && (
							<option disabled value="">
								{placeholder}
							</option>
						)}
						{children}
					</select>
				</InputGroup>
				{touched &&
          ((error && <span className="form-error">{error}</span>) ||
            (warning && <span className="form-error">{warning}</span>))}
			</div>
		</div>
	</div>
);

export { renderSelectInput };
export { renderSelectInpuNoLabel };
export { renderSelectOption };
export { renderselectmultiple };
export { renderSelectOptionNoLabel };

export const textAreaField = ({
	input,
	placeholder,
	type,
	label,
	id,
	disabled,
	requiredStar,
	rows,
	disableLabel,
	countText,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="d-flex w-100">
				{!disableLabel && (
					<label htmlFor={id}>
						{label}
						{requiredStar === true ? (
							<span className="text-required"> *</span>
						) : (
							''
						)}
					</label>
				)}
				<label
					htmlFor={id}
					className={`d-flex ml-auto ${countText < 0 ? 'text-red' : ''}`}
				>
          ({countText})
				</label>
			</div>
			<textarea
				{...input}
				placeholder={placeholder}
				type={type}
				rows={rows}
				disabled={disabled}
				className={`form-control ${touched && error ? 'focus-error' : ''}`}
			/>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const textAreaFieldV2 = ({
	input,
	placeholder,
	type,
	label,
	id,
	disabled,
	requiredStar,
	rows,
	disableLabel,
	countText,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="d-flex w-100">
				{!disableLabel && (
					<label htmlFor={id}>
						{label}
						{requiredStar === true ? (
							<span className="text-required"> *</span>
						) : (
							''
						)}
					</label>
				)}
			</div>
			<textarea
				{...input}
				placeholder={placeholder}
				type={type}
				rows={rows}
				disabled={disabled}
				className={`form-control ${touched && error ? 'focus-error' : ''}`}
			/>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const renderFieldRow = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	requiredStar,
	id,
	disableLabel,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="row">
				{!disableLabel && (
					<div className="col-lg-4">
						<label htmlFor={id}>
							{label}
							{requiredStar === true ? (
								<span className="text-required"> *</span>
							) : (
								''
							)}
						</label>
					</div>
				)}
				<div className="col-lg-8">
					<input
						{...input}
						placeholder={placeholder}
						type={type}
						disabled={disabled}
						id={id}
						className={`form-control ${touched && error ? 'focus-error' : ''}`}
					/>
				</div>
			</div>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const renderFieldRowCustom = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	requiredStar,
	id,
	disableLabel,
	meta: { touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="row">
				{!disableLabel && (
					<div className="col-lg-5">
						<label htmlFor={id}>
							{label}
							{requiredStar === true ? (
								<span className="text-required"> *</span>
							) : (
								''
							)}
						</label>
					</div>
				)}
				<div className="col-lg-7" style={{ paddingLeft: 25, paddingRight: 0 }}>
					<input
						{...input}
						placeholder={placeholder}
						type={type}
						disabled={disabled}
						id={id}
						className={`form-control ${touched && error ? 'focus-error' : ''}`}
					/>
				</div>
			</div>
			{touched &&
        ((error && error !== 'Required' && (
        	<span className="form-error">{error}</span>
        )) ||
          (warning && <span className="form-error">{warning}</span>))}
		</div>
	</div>
);

export const renderFieldValidation = ({
	input,
	placeholder,
	type,
	disabled,
	label,
	noLabel,
	requiredStar,
	id,
	meta: { asyncValidating, touched, error, warning }
}) => (
	<div>
		<div className="form-group">
			<div className="d-flex justify-content-between">
				{!noLabel && (
					<label htmlFor={id}>
						{label}
						{requiredStar === true ? (
							<span className="text-required"> *</span>
						) : (
							''
						)}
					</label>
				)}
				<span className="form-error">Warning</span>
				{/* {touched &&
          ((error && error !== 'Required' && (
            <span className="form-error">{error}</span>
          )) ||
            (warning && <span className="form-error">{warning}</span>))} */}
			</div>

			<input
				{...input}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				id={id}
				className={`form-control ${touched && error ? 'focus-error' : ''}`}
			/>
		</div>
	</div>
);

export const multipleSelect = ({
	input,
	id,
	requiredStar,
	noLabel,
	label,
	icon,
	className,
	placeholder,
	options,
	closeMenu,
	multiple,
	clearable,
	disabled,
	meta: { touched, error, warning }
}) => {
	const onBlur = () => {
		const blurHandler = input.onBlur;
		if (blurHandler) {
			blurHandler(input.value);
		}
	};

	return (
		<div className="form-group row">
			{!noLabel && (
				<div className="col-md-3 text-right mt-2">
					<label htmlFor={id}>
						{requiredStar === true ? (
							<span className="text-danger">* </span>
						) : (
							''
						)}
						{label}
					</label>
				</div>
			)}
			<div className="col-md-9 my-auto">
				<div className="row">
					<div className="col-12 d-flex flex-row">
						{icon && (
							<div className="input-group-prepend">
								<span className="input-group-text p-2">
									<img alt="ProfileImage" src={icon} height="20" />
								</span>
							</div>
						)}
						<Select
							{...input}
							id={id}
							options={options}
							optionClassName="needsclick"
							className={`form-control p-0 border-0 ${
								touched && error ? 'focus-error-select' : ''
							} ${className && 'selectFilter'}`}
							styles={colourStyles}
							placeholder={placeholder}
							// value={initValue(options, input)}
							onChange={value => {
								input.onChange(value);
							}}
							onBlur={onBlur}
							closeMenuOnSelect={closeMenu}
							isMulti={multiple}
							isClearable={clearable}
							isDisabled={disabled}
						/>
					</div>
					<div className="col-12">
						{touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}
					</div>
				</div>
			</div>
		</div>
	);
};

export const multipleSelectOpenModal = ({
	input,
	id,
	requiredStar,
	noLabel,
	label,
	icon,
	className,
	placeholder,
	options,
	closeMenu,
	multiple,
	clearable,
	disabled,
	onOpenModal,
	meta: { touched, error, warning }
}) => {
	const onBlur = () => {
		const blurHandler = input.onBlur;
		if (blurHandler) {
			blurHandler(input.value);
		}
	};

	return (
		<div className="form-group row">
			{!noLabel && (
				<div className="col-md-3 text-right mt-2">
					<label htmlFor={id}>
						{requiredStar === true ? (
							<span className="text-danger">* </span>
						) : (
							''
						)}
						{label}
					</label>
				</div>
			)}
			<div className="col-md-9 my-auto">
				<div className="row">
					<div className="col-12 d-flex flex-row" onClick={onOpenModal}>
						{icon && (
							<div className="input-group-prepend">
								<span className="input-group-text p-2">
									<img alt="ProfileImage" src={icon} height="20" />
								</span>
							</div>
						)}
						<Select
							{...input}
							id={id}
							options={options}
							optionClassName="needsclick"
							className={`form-control p-0 border-0 ${
								touched && error ? 'focus-error-select' : ''
							} ${className && 'selectFilter'}`}
							styles={colourStyles}
							placeholder={placeholder}
							// value={initValue(options, input)}
							onChange={value => {
								input.onChange(value);
							}}
							onBlur={onBlur}
							closeMenuOnSelect={closeMenu}
							isMulti={multiple}
							isClearable={false}
							isDisabled
							openMenuOnClick={false}
							openMenuOnFocus={false}
						/>
					</div>
					<div className="col-12">
						{touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}
					</div>
				</div>
			</div>
		</div>
	);
};
