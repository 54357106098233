import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Route } from '../Routes';
import SystemSettings from '../../containers_v2/settings/systemSetting';

const SystemSettingsRoutes = (): Route[] => [
	{
		component: SystemSettings,
		path: '/settings',
		title: <Translate id="system_settings" />,
		name: 'System Settings',
		key: 'system_settings',
		sectionKey: 'system_settings',
		restricted: false,
		exact: true
	}
];

export default SystemSettingsRoutes;
