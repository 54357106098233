import * as React from 'react';
import Input from '../../components_v2/input/Input';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { translateToString } from '../../styles/global/translate';
import { CreationPopupContainer, creationPopupInputStyle } from '../client-companies/style/Style';
import { LoadingStateEnum } from '../import/model';
import { postMapping } from './action';

export default function NewMapping(props: {
	isOpen: boolean,
	setIsOpen: (b: boolean) => void,
	onCreate?: () => void,
	mappingId: number
}) {

	const close = () => props.setIsOpen(false);

	return <Popup
		isOpen={props.isOpen}
		onClickOut={close}
		popupStyle={{ animate: true, height: '100%', top: '0%' }}
		popupMode={PopupMode.Details}
		content={<NewMappingContent
			onCreate={() => {
				close();
				props.onCreate?.();
			}}
			onClose={close}
			mappingId={props.mappingId}
		/>}
	/>;
}

function NewMappingContent(props: {
	onCreate?: () => void,
	onClose: () => void,
	mappingId: number
}) {
	const [name, setName] = React.useState<string>();
	const [loadingState, setLoading] = React.useState<LoadingStateEnum>();

	const submit = React.useCallback((name: string) => {
		setLoading(LoadingStateEnum.LOADING);
		postMapping({ name, mapping_type_id: props.mappingId }).then(() => {
			setLoading(LoadingStateEnum.LOADED);
			props.onCreate?.();
		});
	}, [props.mappingId]);

	return <PopupCreation
		title={translateToString('create_mapping')}
		canValidate={name !== undefined && name.length > 0}
		onSubmit={() => name && submit(name)}
		loading={loadingState}
		onClose={props.onClose}
	>
		<CreationPopupContainer>
			<Input
				inputStyle={creationPopupInputStyle}
				name="name"
				type="text"
				placeholder={translateToString('name').toString()}
				label={translateToString('name').toString()}
				onChange={setName}
				required
			/>
		</CreationPopupContainer>
	</PopupCreation>;
}