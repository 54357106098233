import product from 'images/icon/systemSettings/product.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import storeLang from '../../../helpers/storeLang';
import { getAllProduct } from './action';
import { useRecoilState } from 'recoil';
import { AProductFilter } from '../../../atoms/filter/productsFilterAtom';
import { GenericToolbarFilterProps } from '../ToolbarFilter';

type loadingState = 'loading' | 'loaded' | 'error'
interface products {
  value: number
  label: string
}

export default function ProductFilter(props: GenericToolbarFilterProps & { atomId: number }) {
	const _ = props;
	const [loadingState, setLoadingState] = React.useState<loadingState>('loading');
	const [value, setValue] = React.useState<number[]>([-1]);
	const [options, setOptions] = React.useState<products[]>([]);
	const [filteredProducts, setFilteredProducts] = useRecoilState(AProductFilter);
	const translate = getTranslate(storeLang.getState().localize);

	React.useEffect(() => {
		if (loadingState == 'loading') {
			try {
				getAllProduct()
					.then(res => {
						setOptions(res.data.data.map((e) => { return { label: e.name, value: e.id }; }));
						if (filteredProducts == undefined) {
							setValue([-1, ...res.data.data.map((e) => e.id)]);
							setFilteredProducts({ products: res.data.data.map((e) => e.id), all: true });
						} else {
							setValue([...(filteredProducts.products.length == res.data.data.length ? [-1] : []), ...filteredProducts.products]);
						}
						setLoadingState('loaded');
					})
					.catch(e => {
						console.log(e);
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState]);

	if (props.hidden) return <></>;

	function handleChecked(isChecked, option) {
		let newValue: number[] = [...value];
		if (option == -1 && isChecked) {
			newValue = [-1, ...options.map((e) => e.value)];
		} else if (option == -1) {
			newValue = [];
		} else if (isChecked) {
			newValue.push(option);
			if (newValue.length == options.length) { newValue.push(-1); }
		} else {
			newValue.splice(newValue.indexOf(option), 1);
			const index = newValue.indexOf(-1);
			if (index >= 0) {
				newValue.splice(index, 1);
			}
		}
		setValue(newValue);
		const ret = newValue.slice();
		if (ret.includes(-1)) {
			ret.splice(ret.indexOf(-1), 1);
		}
		setFilteredProducts({ products: ret, all: filteredProducts?.all ?? false });
	}

	return (
		(<div style={{ width: 180, zIndex: '9' }}>
			{loadingState == 'error' && <></>}
			{(loadingState == 'loaded' || loadingState == 'loading') &&
				<UncontrolledButtonDropdown
					direction="down"
					className="form-filter-opportunity w-100"
					style={{ minWidth: 0, padding: '0 5px', border: '1px solid rgba(0,0,0,0.125)' }}
				>
					<div style={{ textAlign: 'center', padding: '7px' }}>
						<img src={product} style={{ height: '20px' }} />
					</div>
					<DropdownToggle className="toggler p-0 ellipsis" caret>
						<span className="ellipsis w-100 text-left text-black">
							{value.length === options.length + 1
								? (
									<Translate id="all" />
								)
								: value.length !== 0
									? (
										`${value.length} ${translate('selected')}`
									)
									: (
										translate('none') + ' ' + translate('selected')
									)}
						</span>
						<i
							className="fas fa-caret-down mr-0 ml-2"
							style={{ color: 'hsl(0,0%,80%)' }}
						/>
					</DropdownToggle>
					<DropdownMenu
						className="check-sidely"
						style={{
							boxShadow: '0px 1px 5px #cfcfcf',
							width: 215,
							marginLeft: '-60px',
							zIndex: 1,
							padding: '10px 10px 1px',
							overflowY: 'auto',
							maxHeight: 300
						}}
					>

						<label className="d-block" key={-1}>
							<input
								name={`ToolBarProducts[${-1}]`}
								id={`ToolBarProducts[${-1}]`}
								type="checkbox"
								value={-1}
								checked={value.includes(-1)}
								onChange={e =>
									handleChecked(e.target.checked, -1)
								}
							/>
							<div className="checkbox-style top-2" style={{ top: '4px' }} />
							<span className="d-block w-100 ml-4">{value.includes(-1) ? translate('none') as string : translate('all') as string}</span>
						</label>
						<div style={{ paddingBottom: '5px' }}>
							<DropdownItem divider />
						</div>
						{options.map((option, i) => {
							return (
								<label className="d-block" key={i}>
									<input
										name={`ToolBarProducts[${i}]`}
										id={`ToolBarProducts[${i}]`}
										type="checkbox"
										value={option.value}
										checked={value.includes(option.value)}
										onChange={e =>
											handleChecked(e.target.checked, option.value)
										}
									/>
									<div className="checkbox-style top-2" style={{ top: '4px' }} />
									<span className="d-block w-100 ml-4">{option.label}</span>
								</label>
							);
						})}
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			}
		</div>)
	);
}
