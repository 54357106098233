import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { LoadingState } from '../../containers_v2/import/model';
import { SidelyBlack } from '../../styles/global/css/Utils';

export interface ValueLabel {
  value: number
  label: string
}

export default function SelelectWithSearch(props: {
  onChange: (value: ValueLabel | null) => void
  onSearchChange: (search: string, offset: number | undefined) => Promise<boolean>
  label: string | ReactJSXElement
  placeholder
  link: string
  details: ValueLabel
  options: ValueLabel[]
  readOnly: boolean
}): ReactJSXElement {
	const options = Array.from(new Set([...props.options]));
	const [value, setValue] = React.useState<string>('');
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [isAtTheEnd, setIsAtTheEnd] = React.useState<boolean>(true);
	const [localTimeout, setLocalTimeout] = React.useState<any>(undefined);
	const [loadingState, setLoadingState] = React.useState<LoadingState>('loaded');

	React.useEffect(() => { setValue(props.details?.label || ''); }, [props.details]);
	React.useEffect(() => { setLoadingState('loaded'); }, [props.options]);

	function handleInput(input: React.ChangeEvent<HTMLInputElement>) {
		setValue(input.target.value || '');
		if ((input.target.value || '').length !== 0) {
			setIsOpen(true);
		}
		clearTimeout(localTimeout);
		setLocalTimeout(setTimeout(async(local_value) => {
			setLoadingState('loading');
			setIsAtTheEnd(await props.onSearchChange(local_value, undefined));
		}, 250, input.target.value || ''));
	}

	function truncate(companyName: string, max: number): string {
		if (companyName.length >= max) { return companyName.substring(0, max) + '...'; }
		return companyName;
	}

	function TranslateOrLoad(props: {
    isLoading: boolean
  }): ReactJSXElement {
		if (props.isLoading) {
			return (
				<div style={{ width: '38px', margin: 'auto' }}>
					<i className="lds-dual-ring" />
				</div>
			);
		} else {
			return <Translate id="global.load_more" />;
		}
	}

	return (
		<div>
			<div className="form-group row">
				<div className="col-md-3 text-right mt-2">
					<label htmlFor={'inputOwner'}>
						{props.label}
					</label>
				</div>
				<div className="col-md-9">
					<div className="row">
						<div className="col-12">
							{/* Link if is in read only */}
							{props.link && props.readOnly && props.details
								? (
									<a href={`${props.link}${props.details.value}`}
										style={{ width: '94%', height: '100%', position: 'absolute', zIndex: 1, color: SidelyBlack, fontWeight: 400 }}
									/>
								)
								: props.readOnly
									? (
										<a style={{ width: '94%', height: '100%', position: 'absolute', zIndex: 1, cursor: 'default' }} />
									)
									: (<></>)}
							<button
								className={'custom-select-with-add'}
								type="button"
								onClick={() => {
									if (!props.readOnly) { setIsOpen(!isOpen); }
								}}
							>
								<div style={{ textAlign: 'left' }}>
									<input
										className={`custom-select-input ${!value && 'form-control text-placeholder'}`}
										type="text"
										value={value}
										placeholder={props.placeholder}
										onChange={value => {
											handleInput(value);
										}}
										style={{ width: '80%', fontWeight: '400' }}
										// onKeyDown={value => handleClickSpace.bind(this, value)}
									/>
								</div>
								{/* Clean button */}
								{value && !props.readOnly && (
									<button
										className="btn btn-transparent p-0"
										style={{
											position: 'absolute',
											fontSize: '14px',
											right: '56px',
											top: '12px',
											color: 'hsl(0,0%,80%)'
										}}
										type="button"
										onClick={async e => {
											setLoadingState('loading');
											setValue('');
											props.onChange(null);
											setIsAtTheEnd(await props.onSearchChange('', undefined));
											setIsOpen(false);
										}}
									>
										<i className="fas fa-times" />
									</button>
								)}
								<div className="custom-chevron-with-add">
									<i className="fas fa-chevron-down" />
								</div>
							</button>
						</div>
						{/* dropdown */}
						{isOpen && (
							<div
								style={{ left: 13, top: 45, width: '93%', padding: 0 }}
								className="col-md-12 custom-dropdown-box"
								onMouseLeave={() => setIsOpen(false)}
								//  ref={setWrapperRef}
							>
								<div className="dummy-realative" style={{ height: isAtTheEnd ? '100%' : undefined }}>
									<div className="custom-options overflow-auto custom-scroll" style={{ width: '100%', margin: 0, height: isAtTheEnd ? '100%' : undefined }}>
										{options.length > 0 && value.length
											? (
												<ul>
													{options.map(opt => (
														<li
															onClick={() => {
																props.onChange(opt);
																setIsOpen(false);
															}}
															style={{ textOverflow: 'ellipsis' }}
														>
															{truncate(opt.label, 40)}
														</li>
													))}
												</ul>
											)
											: (
												<p className="font-weight-medium text-center mt-3">
													<Translate id="No results found" />
												</p>
											)}
									</div>
								</div>
								{!isAtTheEnd && (
									<div style={{ textAlign: 'center', color: 'rgb(0, 178, 255)', cursor: 'pointer', fontSize: 13 }} onClick={async() => {
										setLoadingState('loading');
										setIsAtTheEnd(await props.onSearchChange(value, options.length));
									}}>
										<TranslateOrLoad
											isLoading={loadingState === 'loading'}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div >
			</div>
		</div>
	);
}
