/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/**
 * <Form />
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {EventEmitter} from 'fbemitter';
import FormValidator from './form-validator';
import FormPreviews from './form-preview';
import CollapseWrapper from './CollapseWrapper';
import DynamicTable from './previews/DynamicTable';

const { Image, Checkboxes, Signature, Download, Camera } = FormPreviews;

// Show created form when you click 'Preview' button on the top.
export default class ReactForm extends React.Component {
  form;

  inputs = {};

  answerData;

  constructor(props) {
    super(props);
    this.answerData = this._convert(props.answer_data);
    this.emitter = new EventEmitter();
  }

  _convert(answers) {
    if (Array.isArray(answers)) {
      const result = {};
      answers.forEach(x => {
        if (x.name.indexOf('tags_') > -1) {
          result[x.name] = x.value.map(y => y.value);
        } else {
          result[x.name] = x.value;
        }
      });
      return result;
    }
    return answers;
  }

  _getDefaultValue(item) {
    return this.answerData[item.field_name];
  }

  _optionsDefaultValue(item) {
    const defaultValue = this._getDefaultValue(item);
    if (defaultValue) {
      return defaultValue;
    }

    const defaultChecked = [];
    item.options.forEach(option => {
      if (this.answerData[`option_${option.key}`]) {
        defaultChecked.push(option.key);
      }
    });
    return defaultChecked;
  }

  _getItemValue(item, ref) {
    let $item = {
      element: item.element,
      value: ''
    };
    if (item.element === 'Rating') {
      $item.value = ref.inputField.current.state.rating;
    } else if (item.element === 'Tags') {
      $item.value = ref.inputField.current.state.value;
    } else if (item.element === 'DatePicker') {
      $item.value = ref.state.value;
    } else if (item.element === 'Camera') {
      $item.value = ref.state.img
        ? ref.state.img.replace('data:image/png;base64,', '')
        : '';
    } else if (ref && ref.inputField) {
      $item = ReactDOM.findDOMNode(ref.inputField.current);
      if (typeof $item.value === 'string') {
        $item.value = $item.value.trim();
      }
    }
    return $item;
  }

  _isIncorrect(item) {
    let incorrect = false;
    if (item.canHaveAnswer) {
      const ref = this.inputs[item.field_name];
      if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
        item.options.forEach(option => {
          const $option = ReactDOM.findDOMNode(
            ref.options[`child_ref_${option.key}`]
          );
          if (
            (option.hasOwnProperty('correct') && !$option.checked) ||
            (!option.hasOwnProperty('correct') && $option.checked)
          ) {
            incorrect = true;
          }
        });
      } else {
        const $item = this._getItemValue(item, ref);
        if (item.element === 'Rating') {
          if ($item.value.toString() !== item.correct) {
            incorrect = true;
          }
        } else if (
          $item.value.toLowerCase() !== item.correct.trim().toLowerCase()
        ) {
          incorrect = true;
        }
      }
    }
    return incorrect;
  }

  _isInvalid(item) {
    let invalid = false;
    if (item.required === true) {
      const ref = this.inputs[item.field_name];
      if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
        let checked_options = 0;
        item.options.forEach(option => {
          const $option = ReactDOM.findDOMNode(
            ref.options[`child_ref_${option.key}`]
          );
          if ($option.checked) {
            checked_options += 1;
          }
        });
        if (checked_options < 1) {
          // errors.push(item.label + ' is required!');
          invalid = true;
        }
      } else {
        const $item = this._getItemValue(item, ref);
        if (item.element === 'Rating') {
          if ($item.value === 0) {
            invalid = true;
          }
        } else if ($item.value === undefined || $item.value.length < 1) {
          invalid = true;
        }
      }
    }
    return invalid;
  }

  _collect(item) {
    const itemData = { name: item.field_name };
    const ref = this.inputs[item.field_name];
    if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
      const checked_options = [];
      item.options.forEach(option => {
        const $option = ReactDOM.findDOMNode(
          ref.options[`child_ref_${option.key}`]
        );
        if ($option.checked) {
          checked_options.push(option.key);
        }
      });
      itemData.value = checked_options;
    } else {
      if (!ref) return null;
      itemData.value = this._getItemValue(item, ref).value;
    }
    return itemData;
  }

  _collectFormData(data) {
    const formData = [];
    data.forEach(item => {
      const item_data = this._collect(item);
      if (item_data) {
        formData.push(item_data);
      }
    });
    return formData;
  }

  _getSignatureImg(item) {
    const ref = this.inputs[item.field_name];
    const $canvas_sig = ref.canvas.current;
    if ($canvas_sig) {
      const base64 = $canvas_sig
        .toDataURL()
        .replace('data:image/png;base64,', '');
      const isEmpty = $canvas_sig.isEmpty();
      const $input_sig = ReactDOM.findDOMNode(ref.inputField.current);
      if (isEmpty) {
        $input_sig.value = '';
      } else {
        $input_sig.value = base64;
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    let errors = [];
    if (!this.props.skip_validations) {
      errors = this.validateForm();
      // Publish errors, if any.
      this.emitter.emit('formValidation', errors);
    }

    // Only submit if there are no errors.
    if (errors.length < 1) {
      const { onSubmit } = this.props;
      if (onSubmit) {
        const data = this._collectFormData(this.props.data);
        onSubmit(data);
      } else {
        const $form = ReactDOM.findDOMNode(this.form);
        $form.submit();
      }
    }
  }

  validateForm() {
    const errors = [];
    let data_items = this.props.data;

    if (this.props.display_short) {
      data_items = this.props.data.filter(i => i.alternateForm === true);
    }

    data_items.forEach(item => {
      if (item.element === 'Signature') {
        this._getSignatureImg(item);
      }

      if (this._isInvalid(item)) {
        errors.push(`${item.label} is required!`);
      }

      if (this.props.validateForCorrectness && this._isIncorrect(item)) {
        errors.push(`${item.label} was answered incorrectly!`);
      }
    });

    return errors;
  }

  getInputElement(item) {
    const Input = FormPreviews[item.element];
    return (
      <Input
        handleChange={this.handleChange}
        ref={c => (this.inputs[item.field_name] = c)}
        mutable={true}
        key={`form_${item.id}`}
        data={item}
        read_only={this.props.read_only}
        defaultValue={this._getDefaultValue(item)}
      />
    );
  }

  getSimpleElement = item => {
    const Element = FormPreviews[item.element];
    return <Element data={item} />;
    // return Element;
  };

  itemChildSecond = items => {
    return items.map(item => {
      if (!item) return null;
      if (item.element === 'Table') return null;
      if (item.element === 'Section') return null;
      if (item.element === 'Header') return null;
      if (item.element === 'Camera') return null;
      if (item.element === 'Image') return null;
      return this.getSimpleElement(item);
    });
  };

  itemChild = items => {
    const { type } = this.props;

    const arr = [...items];

    // Set final array
    const finalArray = [];

    // Set sub object for groups (Childs)
    let subObj = {};

    // Flag for sub section stuff
    let inSubSection = false;

    let inSubTable = false;

    // Loop array
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].element === 'EndSection') {
        // If we have end_section
        // Set flag off
        inSubSection = false;
        // Push sub object to final array
        finalArray.push(subObj);
      } else if (arr[i].element === 'StartSection') {
        // If we get start_section
        // Set flag on
        inSubSection = true;
        // Set new sub object, set childs array in it
        subObj = {
          id: arr[i].id,
          element: 'Section',
          field_name: arr[i].field_name,
          child: []
        };
      } else if (inSubSection) {
        // If we have active flag (true)
        // Push child to section array
        subObj.child.push({
          id: arr[i].id,
          element: arr[i].element,
          ...arr[i]
        });
      } else if (arr[i].element === 'EndTable') {
        // If we have end_section
        // Set flag off
        inSubTable = false;
        // Push sub object to final array
        finalArray.push(subObj);
      } else if (arr[i].element === 'StartTable') {
        // If we get start_section
        // Set flag on
        inSubTable = true;
        // Set new sub object, set childs array in it
        subObj = {
          id: arr[i].id,
          element: 'Table',
          field_name: arr[i].field_name,
          child: []
        };
      } else if (inSubTable) {
        // If we have active flag (true)
        // Push child to section array
        subObj.child.push({
          id: arr[i].id,
          element: arr[i].element,
          ...arr[i]
        });
      } else {
        // Everything else push straight to final array
        finalArray.push(arr[i]);
      }
    }

    return finalArray.map(item => {
      if (!item) return null;
      if (item.element === 'Table')
        return (
          <DynamicTable data={item} type={type}>
            {this.itemChildSecond(item.child)}
          </DynamicTable>
        );
      if (item.element === 'Section')
        return (
          <CollapseWrapper
            title={item.field_name}
            className="section-builder mt-3"
          >
            {this.itemChildSecond(item.child)}
          </CollapseWrapper>
        );
      return this.getSimpleElement(item);
    });
  };

  render() {
    let data_items = this.props.data;
    // console.log('dataItems', data_items);

    if (this.props.display_short) {
      data_items = this.props.data.filter(i => i.alternateForm === true);
    }

    data_items.forEach(item => {
      if (
        item &&
        item.readOnly &&
        item.variableKey &&
        this.props.variables[item.variableKey]
      ) {
        this.answerData[item.field_name] = this.props.variables[
          item.variableKey
        ];
      }
    });

    const arr = [...data_items];

    // Set final array
    const finalArray = [];

    // Set sub object for groups (Childs)
    let subObj = {};

    // Flag for sub section stuff
    let inSubSection = false;

    let inSubTable = false;

    // Loop array
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].element === 'EndSection') {
        // If we have end_section
        // Set flag off
        inSubSection = false;
        // Push sub object to final array
        finalArray.push(subObj);
      } else if (arr[i].element === 'StartSection') {
        // If we get start_section
        // Set flag on
        inSubSection = true;
        // Set new sub object, set childs array in it
        subObj = {
          id: arr[i].id,
          element: 'Section',
          field_name: arr[i].field_name,
          child: []
        };
      } else if (inSubSection) {
        // If we have active flag (true)
        // Push child to section array
        subObj.child.push({
          id: arr[i].id,
          element: arr[i].element,
          ...arr[i]
        });
      } else if (arr[i].element === 'EndTable') {
        // If we have end_section
        // Set flag off
        inSubTable = false;
        // Push sub object to final array
        finalArray.push(subObj);
      } else if (arr[i].element === 'StartTable') {
        // If we get start_section
        // Set flag on
        inSubTable = true;
        // Set new sub object, set childs array in it
        subObj = {
          id: arr[i].id,
          element: 'Table',
          field_name: arr[i].field_name,
          child: []
        };
      } else if (inSubTable) {
        // If we have active flag (true)
        // Push child to section array
        subObj.child.push({
          id: arr[i].id,
          element: arr[i].element,
          ...arr[i]
        });
      } else {
        // Everything else push straight to final array
        finalArray.push(arr[i]);
      }
    }

    const items = finalArray.map(item => {
      if (!item) return null;

      switch (item.element) {
        case 'Table':
          return <DynamicTable>{this.itemChild(item.child)}</DynamicTable>;
        case 'Section':
          return (
            <CollapseWrapper
              title={item.field_name}
              className="section-builder mt-3"
            >
              {this.itemChild(item.child)}
            </CollapseWrapper>
          );
        default:
          return this.getSimpleElement(item);
      }
    });

    const formTokenStyle = {
      display: 'none'
    };

    console.log('qwerty');

    return (
      <div className="p-3" style={{ maxHeight: 500, overflow: 'auto' }}>
        <FormValidator emitter={this.emitter} />
        <div className="react-form-builder-form">
          <i
            className="fas fa-times btn-close pointer"
            onClick={this.props.onClose}
          />
          <form
            encType="multipart/form-data"
            ref={c => (this.form = c)}
            action={this.props.form_action}
            onSubmit={this.handleSubmit.bind(this)}
            method={this.props.form_method}
          >
            {this.props.authenticity_token && (
              <div style={formTokenStyle}>
                <input name="utf8" type="hidden" value="&#x2713;" />
                <input
                  name="authenticity_token"
                  type="hidden"
                  value={this.props.authenticity_token}
                />
                <input
                  name="task_id"
                  type="hidden"
                  value={this.props.task_id}
                />
              </div>
            )}
            {items}
          </form>
        </div>
      </div>
    );
  }
}

ReactForm.defaultProps = { validateForCorrectness: false };
