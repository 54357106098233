import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';
import { alertAction } from '../reducers/app.reducer';

const cookies = new Cookies();

export const GET_DATA_TARGET_REQUEST = 'GET_DATA_TARGET_REQUEST';
export const GET_DATA_TARGET_SUCCESS = 'GET_DATA_TARGET_SUCCESS';
export const GET_DATA_TARGET_ERROR = 'GET_DATA_TARGET_ERROR';

export const GET_OVERVIEW_OPORTUNITIES_REQUEST =
  'GET_OVERVIEW_OPORTUNITIES_REQUEST';
export const GET_OVERVIEW_OPORTUNITIES_SUCCESS =
  'GET_OVERVIEW_OPORTUNITIES_SUCCESS';
export const GET_OVERVIEW_OPORTUNITIES_ERROR =
  'GET_OVERVIEW_OPORTUNITIES_ERROR';

export const GET_OVERVIEW_GRAPH_REQUEST = 'GET_OVERVIEW_GRAPH_REQUEST';
export const GET_OVERVIEW_GRAPH_SUCCESS = 'GET_OVERVIEW_GRAPH_SUCCESS';
export const GET_OVERVIEW_GRAPH_ERROR = 'GET_OVERVIEW_GRAPH_ERROR';

export const GET_USER_GRAPH_REQUEST = 'GET_USER_GRAPH_REQUEST';
export const GET_USER_GRAPH_SUCCESS = 'GET_USER_GRAPH_SUCCESS';
export const GET_USER_GRAPH_ERROR = 'GET_USER_GRAPH_ERROR';

export const GET_ALL_USER_GRAPH_REQUEST = 'GET_ALL_USER_GRAPH_REQUEST';
export const GET_ALL_USER_GRAPH_SUCCESS = 'GET_ALL_USER_GRAPH_SUCCESS';
export const GET_ALL_USER_GRAPH_ERROR = 'GET_ALL_USER_GRAPH_ERROR';

export const GET_GROUP_GRAPH_REQUEST = 'GET_GROUP_GRAPH_REQUEST';
export const GET_GROUP_GRAPH_SUCCESS = 'GET_GROUP_GRAPH_SUCCESS';
export const GET_GROUP_GRAPH_ERROR = 'GET_GROUP_GRAPH_ERROR';

export const GET_ALL_GROUP_GRAPH_REQUEST = 'GET_ALL_GROUP_GRAPH_REQUEST';
export const GET_ALL_GROUP_GRAPH_SUCCESS = 'GET_ALL_GROUP_GRAPH_SUCCESS';
export const GET_ALL_GROUP_GRAPH_ERROR = 'GET_ALL_GROUP_GRAPH_ERROR';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';


export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_GROUP_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${URL_API}/api/groups/newGroup`,
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_GROUP_SUCCESS,
						payload: response.data
					});
					window.location.href = '/teamManagement';
				},
				error => {
					dispatch({
						type: CREATE_GROUP_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response && error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_GROUP_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_GROUP_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${URL_API}/api/groups/id/${id}`,
			data
		})
			.then(
				response => {
					dispatch({
						type: UPDATE_GROUP_SUCCESS,
						payload: response.data
					});
					window.location.href = `/teamManagement/detail/group/${id}`;
				},
				error => {
					dispatch({
						type: UPDATE_GROUP_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response && error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: UPDATE_GROUP_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_GROUP_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${URL_API}/api/groups/${id}`
		}).then(
			response => {
				dispatch({
					type: DELETE_GROUP_SUCCESS,
					payload: response.data
				});
				dispatch(
					alertAction({
						text: 'User has been deleted',
						show: true,
						type: 'danger'
					})
				);
				window.location.href = '/teamManagement';
			},
			error => {
				dispatch({
					type: DELETE_GROUP_ERROR,
					error: error.response
				});
			}
		);
	};
}

export function deleteUser(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: DELETE_USER_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${URL_API}/api/users/id/${id}`,
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_GROUP_SUCCESS,
						payload: response.data
					});
					window.location.href = '/teamManagement';
				},
				error => {
					dispatch({
						type: CREATE_GROUP_ERROR,
						error: error.response
					});
					swal.fire(
						'Error!',
						'Sorry you don\'t have access to delete this record',
						'error'
					);
					if (error.response && error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_GROUP_ERROR,
					error
				});
				throw error;
			});
	};
}