/* eslint-disable lines-between-class-members */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import TableListingPage from '../components/tableComponent/TableListingPage';
import { deleteData, exportExcel, fetchDataInstance as fetchData, setModal } from '../../../store/actions/enForm.action';
import DropdownAction from '../components/DropdownAction';
import moment from 'moment';
import { getBgColor } from '../../../helpers/data/enrichedForm';

class ShelfAuditInstance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static columns = props => {
		const { history, data } = props;

		const columns = [
			{
				text: 'Date',
				formatter: (cell, row) => (
					<div
						className="table-key"
						onClick={() => {
							history.push(`/enform/detaildata/${row.id}`);
						}}
					>
						{moment(row.created_at || '-').format('L - LT') || '-'}
					</div>
				)
			},
			{
				text: <Translate id="company" />,
				formatter: (cell, row) => (
					<div>{(row.client_company && row.client_company.name) || '-'}</div>
				)
			},
			{
				text: <Translate id="owner" />,
				formatter: (cell, row) => (
					<div>{(row.owner && row.owner.name) || '-'}</div>
				)
			},
			{
				text: <Translate id="number of products" />,
				formatter: (cell, row) => (
					<div className="ml-5">{row.record_available_count || '0'}</div>
				)
			},
			{
				text: <Translate id="missing products" />,
				formatter: (cell, row) => (
					<div className="ml-5">
						{row.record_count - row.record_available_count || '0'}
					</div>
				)
			},
			{
				text: <Translate id="instore_presence" />,
				formatter: (cell, row) => (
					<div
						className="p-2 text-center bgCard"
						style={{
							background: getBgColor(row.reprasentation) || '#fff',
							width: '65%'
						}}
					>
						{`${row.reprasentation}%` || '0%'}
					</div>
				)
			},
			{
				dataField: 'customerPlan',
				text: <Translate id="quick_action" />,
				headerStyle: { width: '100px' },
				style: { width: '100px' },
				formatter: (cell, row) => (
					<div>
						<DropdownAction
							id={row.id}
							name={row.notes}
							permission='ShelfAudit'
							actions={['delete']}
							path="enform/list"
							count={0}
							url="shelfAuditInstance"
							{...props}
						/>
					</div>
				)
			}
		];
		return columns;
	};

	render() {
		return (
			<Fragment>
				<div style={{ width: 200 }} />
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	data: state.enform,
	current: state.user.current,
	isLoadingCurrent: state.user.isLoading,
	fieldInstaller: state.enform.fieldInstaller
});

export default connect(mapStateToProps, {
	fetchData,
	deleteData,
	setModal,
	exportExcel
})(
	TableListingPage({
		name: 'shelf_audit',
		columns: ShelfAuditInstance.columns,
		noCreate: true
	})(ShelfAuditInstance)
);
