import React, {Component} from 'react';
import {connect} from 'react-redux';
import TextAreaAutosize from 'react-textarea-autosize';
import {message, Upload} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {uploadFile} from '../../../../../store/actions/formbuilder.action';

const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

class PropStartSection extends Component {
  state = {};

  // componentWillReceiveProps(nextProps) {
  //   const { data, editElementProp } = this.props;

  //   if (data !== nextProps.data && nextProps.data) {
  //     console.log('nextProps', nextProps.data);
  //     editElementProp.bind(this, 'default_image_id', nextProps.data.id);
  //   }
  // }

  handleChange = value => {
    const { uploadFile, handleUploadFile } = this.props;
    const formData = new FormData();

    formData.append('type', 'image_field');
    formData.append('file', value.file.originFileObj);
    uploadFile(formData).then(
      response => {
        console.log('successupload');
        handleUploadFile(response.data.data.id, response.data.data.temp_url);
      },
      () => {
        console.log('error upload');
      }
    );
  };

  render() {
    const {
      data,
      isUploading,
      element,
      updateElement,
      editElementProp
    } = this.props;
    const uploadButton = (
      <div>
        {isUploading ? (
          <LoadingOutlined />
        ) : (
          <div className="ant-upload-text">
            <i className="demo-icon icon-image" />
          </div>
        )}
      </div>
    );

    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Image</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize
            type="text"
            className="form-control"
            defaultValue={element.field_name}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'field_name', 'value')}
          />
        </div>

        <div className="custom-control custom-checkbox">
          <input
            id="is-default-to-today"
            className="custom-control-input"
            type="checkbox"
            defaultChecked={element.is_display_field_name}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(
              this,
              'is_display_field_name',
              'checked'
            )}
          />
          <label className="custom-control-label" htmlFor="is-default-to-today">
            Display field name
          </label>
        </div>

        <div className="form-group mt-3">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <input
            type="text"
            className="form-control"
            disabled={this.props.isEdit}
            defaultValue={element.system_field_name}
            onBlur={updateElement.bind(this)}
            onChange={editElementProp.bind(this, 'system_field_name', 'value')}
          />
        </div>

        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          onChange={this.handleChange}
          disabled={isUploading || this.props.isEdit}
        >
          {element.default_image_src ? (
            <img
              src={element.default_image_src}
              alt="avatar"
              style={{ width: '100%' }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.formbuilder.file,
  isUploading: state.formbuilder.isUploading
});

export default connect(
  mapStateToProps,
  { uploadFile }
)(PropStartSection);
