import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Restricted from '../../permissions/Restricted';
import { getGroups, getUserGroup } from '../action';
import { Group, GroupDetailResult, ObjectActionScope, User } from '../model';
import { Button } from '../style';
import { Body, BodyLeft, BodyRight, Container, Footer } from './GroupDetailStyle';
import { ActionOpen } from './GroupList';
import GroupMembers from './GroupMembers';
import PermissionTable from './PermissionTable';
import close from 'images/icons/orders/close.svg';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';


function GroupDetail(props: {
  groups: React.MutableRefObject<Group[]>
  setGroups: (groups: Group[]) => void
  groupId: number
  onDelete: (group: Group) => void
  onCreate: (value: GroupDetailResult) => void
  onUpdate: (value: GroupDetailResult) => void
  actionOpen: ActionOpen
  onClose: () => void
}): JSX.Element {
	const { groups, setGroups, groupId } = props;
	const currentGroup = groups.current.find(g => g.id === groupId);

	const [usersGroup, setUsersGroup] = React.useState<User[]>([]);
	const [groupName, setGroupName] = React.useState<string>(currentGroup?.name ?? '');
	const [policies, setPolicies] = React.useState<ObjectActionScope[]>([]);

	React.useEffect(() => {
		if (groupId) {
			getUserGroup(groupId)
				.then(res => setUsersGroup(res.data))
				.catch(console.log);
		}
	}, [groups.current, groupId]);

	return (
		<Container>
			<Body>
				<BodyLeft>
					<DefaultImage cursor="pointer" src={close} onClick={() => props.onClose()} />
					<PermissionTable
						groupsName={groups.current.filter(g => g.id !== groupId).map(g => g.name)}
						group={currentGroup}
						setGroupName={(name: string) => setGroupName(name)}
						onDelete={async() => await getGroups().then(res => setGroups(res.data))}
						onUpdate={setPolicies}
					/>
				</BodyLeft>
				<BodyRight>
					<GroupMembers
						users={usersGroup}
						groupId={groupId}
						updateMembers={(values) => setUsersGroup(values)}
					/>
				</BodyRight>
			</Body>
			<Footer>
				<Restricted to={{ objectAction: props.actionOpen === ActionOpen.CREATE ? 'CreateAccountSettings' : 'UpdateAccountSettings' }}>
					<Button
						buttonWidth={200}
						disabled={groupName.length <= 0 || props.groups.current.find(g => currentGroup?.id != g.id && g.name == groupName) != undefined}
						onClick={() => {
							const result = {
								group: currentGroup,
								groupName,
								members: usersGroup,
								policies
							} as GroupDetailResult;

							if (props.actionOpen === ActionOpen.CREATE) {
								props.onCreate(result);
							} else {
								props.onUpdate(result);
							}
						}}
					>
						<Translate id={'global.save'} />
					</Button>
				</Restricted>
				{
					props.actionOpen != ActionOpen.CREATE &&
					<Restricted to={{ objectAction: 'DeleteAccountSettings' }}>
						<Button error
							buttonWidth={200}
							onClick={() => (currentGroup != null) && props.onDelete(currentGroup)}
						>
							<Translate id="global.delete" />
						</Button>
					</Restricted>

				}
			</Footer>
		</Container>
	);
}

export default GroupDetail;
