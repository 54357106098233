import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Input from '../../components_v2/input/Input';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import { translateToString } from '../../styles/global/translate';
import { CreationPopupContainer, creationPopupInputStyle } from '../client-companies/style/Style';
import { LoadingStateEnum } from '../import/model';
import { EmailTemplate, EmailTemplateSubmit } from './model/models';
import styled from 'styled-components';
import Switch from '../../components_v2/Switch/Switch';
import { SwitchText } from '../calendar/style/NewEventStyle';
import { FlexDiv } from '../products/style';
import { postEmailTemplate, putEmailTemplate } from '../settings/subSettings/actions';
import { DynamicTextEditable } from './dynamicTextEditable';
import { trimWith } from '../../components_v2/utils';
import { useStore } from 'react-redux';
import * as _ from 'lodash';

const ETCreationPopupContainer = styled(CreationPopupContainer)`
	padding: 0px 10%;
`;

interface NewEmailTemplateProps {
    isOpen: boolean,
	setIsOpen: (b: boolean) => void,
	onCreate?: () => void,
	emailTemplate?: EmailTemplate
}
export default function NewEmailTemplate(props: NewEmailTemplateProps) {
	const { isOpen, setIsOpen, onCreate, emailTemplate } = props;
	const close = () => setIsOpen(false);
	return <Popup
		isOpen={isOpen}
		onClickOut={close}
		popupStyle={{ animate: true, height: '100%', top: '0%' }}
		popupMode={PopupMode.Default}
		content={<NewEmailTemplateContent
			isOpen={isOpen}
			onCreate={() => {
				close();
				onCreate?.();
			}}
			onClose={close}
			emailTemplate={emailTemplate}
		/>}
	/>;
}

interface NewEmailTemplateContentProps {
    onCreate?: () => void
    onClose: () => void
	isOpen: boolean
    emailTemplate?: EmailTemplate
}
function NewEmailTemplateContent(props: NewEmailTemplateContentProps) {
	const {	onCreate, onClose, emailTemplate } = props;
	const [name, setName] = React.useState<string | undefined>(emailTemplate?.name);
	const [isPublic, setIsPublic] = React.useState<boolean | undefined>(emailTemplate?.public); 
	const [subject, setSubject] = React.useState<string | undefined>(emailTemplate?.data?.subject);
	const [body, setBody] = React.useState<string | undefined>(emailTemplate?.data?.body);
	const [loadingState, setLoading] = React.useState<LoadingStateEnum>();
	const store = useStore().getState();


	React.useEffect(() => {
		setName(emailTemplate?.name);
		setIsPublic(emailTemplate?.public ?? false);
		setSubject(emailTemplate?.data?.subject);
		setBody(emailTemplate?.data?.body);
	}, [emailTemplate]);

	/**
	 * This use effect is to update body state at the first render moment
	 * and also the moment in which other state like(public, subject etc) is updated
	 * Because, when execute parseBodyFromTranslated() variables in body must be translated state
	 * One day, instead of manipulate directly JSDOM and lots of regex to translate(#(en | fr)# <=> #variable_name#)
	 * If you can put element at the position of cursor in contentEditable, code will be less complex
	 * The biggest problem at this moment is that we have to translate #variable_name# to #translated_name# when render
	 * and parsing all text in contentEditable, finding and translate all #translated_name# to #variable_name# before submit
	 */
	React.useEffect(() => {
		const bodyEl = document.getElementById('dynamic-text-editable');
		if (bodyEl?.innerText) {
			const newBody = bodyEl.innerText;
			setBody(newBody);
		}
	}, [isPublic, subject, name]);

	const onSubmit = React.useCallback(
		async(emailTemplateSubmit: EmailTemplateSubmit, emailTemplate: EmailTemplate | undefined) => {
			setLoading(LoadingStateEnum.LOADING);
			const id = emailTemplate?.id;

			//update
			if (id) await putEmailTemplate(id, emailTemplateSubmit);
			// create
			else await postEmailTemplate(emailTemplateSubmit);

			setLoading(LoadingStateEnum.LOADED);
			onCreate?.();
		}, []);

	function parseBodyFromTranslated() {
		const varRegex = /#[\w\s\u00E9]+#/g;
		return (body as string).replace(varRegex, current => {
			const trimmed = trimWith(current, '#');
			switch (trimmed) {
				case translateToString('email_template.name'):
					return '#name#';
				case translateToString('email_template.first_name'):
					return '#first_name#';
				case translateToString('email_template.last_name'):
					return '#last_name#';
				case translateToString('email_template.company_name'):
					return '#company_name#';
				default:
					return '';
			}
		})
			.replace(/(.?)\n/g, (match, lastCharacter) => {
				if (lastCharacter === '#') return lastCharacter;
				return match;
			});
	}
	function handleSubmit() {
		if (name && isPublic !== undefined && subject && body) {
			const parsedBody = parseBodyFromTranslated();
			onSubmit({ name, public:isPublic, data:{ subject, body:parsedBody } } , emailTemplate);
		}
	}
	/**
     * verify if any element is empty or undefined
     */
	function isInvalidString(str: string | undefined): boolean {
		return !str || str.length == 0;
	}

	/**
	 * iterate [name, subject, body] and verify if any element is invalid
	 * also, verify there is a changed things in data
	 */
	function canValidate(): boolean {
		if ([name, subject, body].some(el => isInvalidString(el))) return false;
		if (emailTemplate) {
			const {
				data:{ subject:originSubject, body:originBody },
				...origin
			} = emailTemplate;
			const parsedBody = parseBodyFromTranslated();

			const isEqualWithoutBody = _.isEqual(
				{ ...origin,data:{ subject:originSubject } },
				{ name, public:isPublic, data:{ subject } });

			if (!isEqualWithoutBody) return true;
			if (removeAllNewLine(originBody) === removeAllNewLine(parsedBody)) return false;
		}
		return true;

		/**
		 * As origin body and parsedBody has always different
		 * because of the thing that innerText returns '\n' after each HTMLElement
		 * So I decide to compare remove all '\n'
		 * Disadvantage is when client change only newline, il doesn't catch difference
		 * But I think that is an KISS way to manage that case instead of adding complex regex for a little things
		 */
		function removeAllNewLine(text: string) {
			return text.replace(/\n/g, '');
		}
	}

	return <PopupCreation
		title={translateToString(emailTemplate ? 'email_template_modification' : 'email_template_creation')}
		canValidate={canValidate()}
		onSubmit={handleSubmit}
		loading={loadingState}
		onClose={onClose}
		messageButton={emailTemplate
			? <Translate id='global.edit' />
			: <Translate id='global.create' />
		}
	>
		<ETCreationPopupContainer gap='0'>
			<FlexDiv>
				<SwitchText>{translateToString('public')}</SwitchText>
				<Switch value={isPublic} onChange={(value) => setIsPublic(value)} />
			</FlexDiv>
			<Input
				inputStyle={{ margin:'15px 0', ...creationPopupInputStyle }}
				name="name"
				type="text"
				placeholder={translateToString('name')}
				label={translateToString('name')}
				onChange={setName}
				required
				value={name}
			/>
			<Input
				inputStyle={{ margin:'15px 0',...creationPopupInputStyle }}
				name="subject"
				type="text"
				placeholder={translateToString('email_template_subject')}
				label={translateToString('email_template_subject')}
				onChange={setSubject}
				required
				value={subject}
			/>
			<DynamicTextEditable
				setValue={setBody}
				value={body ?? ''}
				label={translateToString('email_template_body')}
				isOpen={props.isOpen}
				isOwner={!emailTemplate?.id || store.user.current.id === emailTemplate?.owner_id}
			/>
		</ETCreationPopupContainer>
	</PopupCreation>;
}