/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { truncate } from '../../../../helpers/textConfig';
import { selectCompany } from '../../../../store/actions/select.action';
import ModalCreateCompany from '../../../../components/modal/create/ModalCreateCompany';

class SelectCompany extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			placeholder: null,
			value: null,
			options: [],
			isModalOpen: false
		};
		this.props.input.onChange(0);
		this.delayedCallback = debounce(this.searchCall, 1000);
	}

	componentDidMount() {
		const { placeholder, selectCompany, initValue } = this.props;
		document.addEventListener('mousedown', this.handleClickOutside);
		document.addEventListener('keydown', this.handleClickSpace);

		selectCompany();
		this.setState({
			placeholder: placeholder || 'placeholder',
			value: initValue || null
		});
	}

	componentWillReceiveProps(nextProps) {
		const { data, dataCreated, selectCompany, input } = this.props;

		if (data !== nextProps.data) {
			const options = nextProps.data.map(data => ({
				value: data.id,
				label: data.name
			}));

			this.setState({ options });
		}

		if (dataCreated !== nextProps.dataCreated) {
			selectCompany();
			const value = {
				value: nextProps.dataCreated.id,
				label: nextProps.dataCreated.name
			};
			this.handleChange(value);
			this.onCloseModal();
		}

		if (input.value !== nextProps.input.value) {
			this.setState({
				value: nextProps.input.value.label || null
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleClickSpace);
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef = node => {
		this.wrapperRef = node;
	};

	handleClickOutside = event => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ isOpen: false });
		}
	};

	handleClickSpace = event => {
		const { isOpen } = this.state;
		if (event.keyCode === 32 && isOpen) {
			this.setState(prevState => ({ isOpen: !prevState.isOpen }));
		}
	};

	handleDropdown = () => {
		this.setState(prevState => ({ isOpen: !prevState.isOpen }));
	};

	handleChange = opt => {
		const { input } = this.props;
		this.setState({
			isOpen: false,
			value: opt.label || null
		});
		input.onChange(opt);
	};

	handleInput = event => {
		event && event.persist();
		this.setState({
			value: event ? event.target.value : ''
		});
		this.delayedCallback(event || null);
	};

	searchCall = event => {
		const { selectCompany } = this.props;
		selectCompany({ name: event ? event.target.value : '' });
	};

	onOpenModal = () => {
		this.setState({
			isModalOpen: true
		});
	};

	onCloseModal = () => {
		this.setState({
			isModalOpen: false
		});
	};

	render() {
		const {
			requiredStar,
			label,
			input,
			readOnly,
			meta: { touched, error, warning }
		} = this.props;
		const { isOpen, placeholder, options, value } = this.state;

		return (
			<div className="form-group row">
				<div className="col-md-3 text-right mt-2">
					<label htmlFor="label">
						{requiredStar === true ? (
							<span className="text-required">* </span>
						) : (
							''
						)}
						{label}
					</label>
				</div>

				<div className="col-md-9">
					<div className="row">
						<div className="col-md-12">
							<button
								className={`${isOpen && 'active'} ${touched &&
                  error &&
                  'focus-error-select'} custom-select-with-add ${
									readOnly ? 'disabled-hyperlink' : ''
								} `}
								type="button"
								onClick={() => {
									!readOnly && this.handleDropdown();
								}}
							>
								{/* <p className={`${value ? 'text-value' : 'text-placeholder'}`}>
                  {value || placeholder}
                </p> */}
								{/* {console.log('value', value)} */}
								<input
									className={`custom-select-input ${!value &&
                    'text-placeholder'}`}
									type="text"
									value={value}
									placeholder={placeholder}
									onChange={value => {
										this.handleInput(value);
									}}
									readOnly={readOnly}
								/>

								{value && (
									<button
										className="btn btn-transparent p-0"
										style={{
											position: 'absolute',
											fontSize: '14px',
											right: '56px',
											top: '12px',
											color: 'hsl(0,0%,80%)'
										}}
										type="button"
										onClick={e => {
											e.stopPropagation();
											if (!readOnly) {
												input.onChange(0);
												this.handleInput(0);
												this.setState({
													value: ''
												});
											}
										}}
									>
										<i className="fas fa-times" />
									</button>
								)}

								<div className="custom-chevron-with-add">
									<i className="fas fa-chevron-down" />
								</div>
							</button>
						</div>
						{isOpen && (
							<div
								className="col-md-12 custom-dropdown-box"
								ref={this.setWrapperRef}
							>
								<input {...input} type="hidden" />
								<div className="dummy-realative">
									<div className="custom-options overflow-auto custom-scroll">
										{options.length > 0 ? (
											<ul>
												{options.map(opt => (
													<li onClick={this.handleChange.bind(this, opt)}>
														{truncate(opt.label, 30)}
													</li>
												))}
											</ul>
										) : (
											<p className="font-weight-medium text-center mt-3">
                        No results found
											</p>
										)}
									</div>
									<button
										className="btn-create-dropdown"
										type="button"
										onClick={() => {
											this.handleDropdown();
											this.onOpenModal();
										}}
									>
										<p>New Company +</p>
									</button>
								</div>
							</div>
						)}
						<div className="col-md-12">
							{touched &&
                ((error && error !== 'Required' && (
                	<span className="form-error">{error}</span>
                )) ||
                  (warning && <span className="form-error">{warning}</span>))}
						</div>
					</div>
				</div>
				<ModalCreateCompany
					isOpen={this.state.isModalOpen}
					toggle={() => {
						this.onCloseModal();
					}}
					onClick={() => {
						this.onCloseModal();
					}}
					initName={value}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	data: state.select.company,
	dataCreated: state.companies.dataCreated
});

export default connect(
	mapStateToProps,
	{ selectCompany }
)(SelectCompany);
