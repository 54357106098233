import list_with_pen from 'images/icon/list_with_pen.svg';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { ATimeRangeFilter } from '../../../atoms/filter/timeFilterAtom';
import Dropdown from '../../dropdown/Dropdown';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { translateToString } from '../../../styles/global/translate';
import { GenericToolbarFilterProps } from '../ToolbarFilter';

export default function TimeRangeFilter(props: GenericToolbarFilterProps & {
  atomId: number
  defaultValue?: number,
  disabled?: boolean
}): JSX.Element {
	const { defaultValue } = props;
	const options: number[] = [1, 2, 3, 6, 9, 12];
	const [timeRange, setTimeRange] = useRecoilState(ATimeRangeFilter);
	const [selectedValue, setSelectedValue] = React.useState<number>(timeRange ?? defaultValue ?? 1);

	// if atom is undefined, init him
	if (timeRange == undefined) {
		setTimeRange(defaultValue ?? 1);
	}
	if (props.hidden) return <></>;

	return <Dropdown
		disabled={props.disabled}
		dropdownStyle={DROPDOWN_FILTER_STYLE}
		selectedValue={{ label: translateToString('tool_bar.range_mounth', [[' NUMBER ', selectedValue.toString()]]), value: selectedValue }}
		datalist={options.map(value => ({ label: translateToString('tool_bar.range_mounth', [[' NUMBER ', value.toString()]]), value }))}
		readOnly
		freezeShowLabel
		name='dropdown_scope_filter'
		onChange={value => {
			setTimeRange(value.value);
			setSelectedValue(value.value);
		}}
		image={list_with_pen}
	/>;
}
