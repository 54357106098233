import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';
import { handleErrorWithMessage, handleImportProduct } from '../../helpers/error';
import { modalErrorValidate } from './modal.action';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';

const cookies = new Cookies();

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const DETAIL_PRODUCTS_REQUEST = 'DETAIL_PRODUCTS_REQUEST';
export const DETAIL_PRODUCTS_SUCCESS = 'DETAIL_PRODUCTS_SUCCESS';
export const DETAIL_PRODUCTS_ERROR = 'DETAIL_PRODUCTS_ERROR';

export const CREATE_PRODUCTS_REQUEST = 'CREATE_PRODUCTS_REQUEST';
export const CREATE_PRODUCTS_SUCCESS = 'CREATE_PRODUCTS_SUCCESS';
export const CREATE_PRODUCTS_ERROR = 'CREATE_PRODUCTS_ERROR';

export const UPDATE_PRODUCTS_REQUEST = 'UPDATE_PRODUCTS_REQUEST';
export const UPDATE_PRODUCTS_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_ERROR = 'UPDATE_PRODUCTS_ERROR';

export const DELETE_PRODUCTS_REQUEST = 'DELETE_PRODUCTS_REQUEST';
export const DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS';
export const DELETE_PRODUCTS_ERROR = 'DELETE_PRODUCTS_ERROR';

export const CREATE_ASSORTMENT_REQUEST = 'CREATE_ASSORTMENT_REQUEST';
export const CREATE_ASSORTMENT_SUCCESS = 'CREATE_ASSORTMENT_SUCCESS';
export const CREATE_ASSORTMENT_ERROR = 'CREATE_ASSORTMENT_ERROR';

export const UPDATE_ASSORTMENT_REQUEST = 'UPDATE_ASSORTMENT_REQUEST';
export const UPDATE_ASSORTMENT_SUCCESS = 'UPDATE_ASSORTMENT_SUCCESS';
export const UPDATE_ASSORTMENT_ERROR = 'UPDATE_ASSORTMENT_ERROR';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SORT_PRODUCTS_DATA = 'SORT_PRODUCTS_DATA';
export const SET_PRODUCT_OPTION = 'SET_PRODUCT_OPTION';
export const SET_DETAIL_ASSORTMENT = 'SET_DETAIL_ASSORTMENT';
export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT';
export const SET_MODAL_ASSORTMENT = 'SET_MODAL_ASSORTMENT';
export const GET_ALL_CATALOGUES = 'GET_ALL_CATALOGUES';

const PATH = `${URL_API}/api/products`;
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export function fetchData(data, type) {
	const urlType = JSON.parse(localStorage.getItem('productOption'));
	let URL_PATH = 'products';
	if (type == undefined) {
		URL_PATH = urlType === 'products' ? 'products' : 'catalogues';
	} else {
		URL_PATH = type;
	}

	const brandsId = data.brandsId?.length ? [...data.brandsId] : [];
	const cataloguesId = data.cataloguesId?.length ? [...data.cataloguesId] : [];
	const categoriesId = data.categoriesId?.length ? [...data.categoriesId] : [];
	data.brandsId = data.brandsId !== undefined ? [] : undefined;
	data.cataloguesId = data.cataloguesId !== undefined ? [] : undefined;
	data.categoriesId = data.categoriesId !== undefined ? [] : undefined;

	if (URL_PATH == 'catalogues') {
		data.brandsId = undefined;
		data.cataloguesId = undefined;
		data.categoriesId = undefined;
	}

	return dispatch => {
		dispatch({
			type: FETCH_PRODUCTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_API}/api/${URL_PATH}`,
			params: data
		})
			.then(
				response => {
					if (data.brandsId && data.cataloguesId && data.categoriesId) {
						response.data.data = response.data.data.filter(product => {
							if (
								((product.categoryId == null && categoriesId.includes(0)) || (product.categoryId != null && categoriesId.includes(product.categoryId))) &&
                ((product.brandId == null && brandsId.includes(0)) || (product.brandId != null && brandsId.includes(product.brandId))) &&
                ((product.catalogueId.length === 0 && cataloguesId.includes(0)) || (product.catalogueId.length !== 0 && cataloguesId.some(ci => product.catalogueId.includes(ci))))
							) {
								return product;
							}
						});
					}

					dispatch({
						type: FETCH_PRODUCTS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_PRODUCTS_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_PRODUCTS_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailData(id) {
	const urlType = JSON.parse(localStorage.getItem('productOption'));
	const URL_PATH = urlType === 'products' ? 'products' : 'catalogues';

	return dispatch => {
		dispatch({
			type: DETAIL_PRODUCTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_API}/api/${URL_PATH}/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_PRODUCTS_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_PRODUCTS_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_PRODUCTS_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_PRODUCTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_PRODUCTS_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success').then(() => {
					window.location.href = `/products-v2?id=${response.data.data.id}`;
					// });
				},
				error => {
					dispatch({
						type: CREATE_PRODUCTS_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.message, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_PRODUCTS_ERROR,
					error
				});
				throw error;
			});
	};
}

export function createFromSelect(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: CREATE_PRODUCTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: CREATE_PRODUCTS_SUCCESS,
						payload: response.data
					});
					// swal.fire('Success!', 'Data created!', 'success');
				},
				error => {
					dispatch({
						type: CREATE_PRODUCTS_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_PRODUCTS_ERROR,
					error
				});
				throw error;
			});
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_PRODUCTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_PRODUCTS_SUCCESS,
					payload: response.data
				});
				window.location.href = `/products-v2?id=${id}`;
			},
			error => {
				dispatch({
					type: UPDATE_PRODUCTS_ERROR,
					error: error.response
				});
				swal.fire('Error!', error.response.data.message, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function deleteData(id, isRedirect) {
	const translate = getTranslate(storeLang.getState().localize);
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const urlType = JSON.parse(localStorage.getItem('productOption'));
	const URL_PATH = urlType === 'products' ? 'products' : 'catalogues';
	return dispatch => {
		dispatch({
			type: DELETE_PRODUCTS_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${URL_API}/api/${URL_PATH}/${id}`
		}).then(
			() => {
				if (isRedirect) {
					window.location.href = '/products-v2';
				} else {
					dispatch(fetchData());
				}
			},
			error => {
				// dispatch({ type: DELETE_PRODUCTS_ERROR, error: error.response });
				swal.fire('Error!', translate(`legacy.errors.${error.response.data.message}`), 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function bulkDelete(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const urlType = JSON.parse(localStorage.getItem('productOption'));
	const URL_PATH = urlType === 'products' ? 'products' : 'catalogues';

	return async dispatch => {
		try {
			await axios({
				method: 'DELETE',
				url: `${URL_API}/api/${URL_PATH}`,
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json'
				},
				data
			});
			dispatch(fetchData());
		} catch (error) {
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function createAssortment(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: CREATE_ASSORTMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${URL_API}/api/catalogues`,
				data
			});
			dispatch({
				type: CREATE_ASSORTMENT_SUCCESS,
				payload: response.data
			});
			// dispatch(fetchData(params))
			window.location.href = '/products-v2';
		} catch (error) {
			swal.fire('Error!', error.response.data.message, 'error');
			// handleErrorWithMessage(error, dispatch, CREATE_ASSORTMENT_ERROR);
		}
	};
}

export function updateAssortment(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return async dispatch => {
		dispatch({
			type: UPDATE_ASSORTMENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'PUT',
				url: `${URL_API}/api/catalogues/${id}`,
				data
			});
			dispatch({
				type: UPDATE_ASSORTMENT_SUCCESS,
				payload: response.data
			});
			window.location.href = '/products-v2';
		} catch (error) {
			handleErrorWithMessage(error, dispatch, UPDATE_ASSORTMENT_ERROR);
		}
	};
}

export function exportExcel(path, type) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/${path}`
			// params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', type);
					document.body.appendChild(link);
					link.click();
				},
				error => {
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				throw error;
			});
}

export function importExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		dispatch({
			type: IMPORT_EXCEL_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/importExcel`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data
			});
			dispatch({
				type: IMPORT_EXCEL_SUCCESS,
				payload: response.data
			});
			window.location.href = '/products-v2';
		} catch (error) {
			handleImportProduct(
				error,
				dispatch,
				IMPORT_EXCEL_ERROR,
				modalErrorValidate
			);
		}
	};
}

export const searchData = data => async dispatch => {
	dispatch({
		type: SEARCH_PRODUCTS,
		payload: data
	});
};

export const sortData = data => async dispatch => {
	dispatch({
		type: SORT_PRODUCTS_DATA,
		payload: data
	});
};

export const setProductOption = data => async dispatch => {
	dispatch({
		type: SET_PRODUCT_OPTION,
		payload: data
	});
};

export const setDetailAssortment = data => async dispatch => {
	dispatch({
		type: SET_DETAIL_ASSORTMENT,
		payload: data
	});
};

export const setModalStatus = data => async dispatch => {
	dispatch({
		type: SET_MODAL_ASSORTMENT,
		payload: data
	});
};

export const getAllProduct = () => async dispatch => {
	const res = await axios.get(`${URL_API}/api/products/All`);
	dispatch({
		type: GET_ALL_PRODUCT,
		payload: res.data.data
	});
};

export const getAllCatalogues = () => async dispatch => {
	const res = await axios.get(`${URL_API}/api/catalogues`);
	dispatch({
		type: GET_ALL_CATALOGUES,
		payload: res.data.data
	});
};
