import { RecoilState, atom, selector } from 'recoil';
import { AtomCategory, AtomState } from '../utils/model/Model';
import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';

// ----------[ ShelfAuditTemplatePicker ]---------- //

export type ShelfAuditTemplate = {
	id: number,
	name: string,
	catalogue_id: number,
	icon: string
}

const AGlobalShelfAuditTemplatePicker: AtomState<ShelfAuditTemplate[] | undefined> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_shelf_audit_template_picker',
		default: undefined
	})
};

export const AShelfAuditTemplatePicker: RecoilState<ShelfAuditTemplate[] | undefined> = selector({
	key: 'atom_shelf_audit_template_picker',
	get: () => AGlobalShelfAuditTemplatePicker.atom,
	set: ({ set }, newValue) => set(AGlobalShelfAuditTemplatePicker.atom, newValue)
});


export function getShelfAuditTemplates(): Promise<ShelfAuditTemplate[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/shelf-audits/templates?limit=999`).then(res => res.data);
}