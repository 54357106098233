import * as React from 'react';
import SliderFilter from './SliderFilter';

export default function SliderFilterBoxChecker(props) {
	const { minValue, maxValue, color = '', reverted, sliderLabel, label, valueAlwaysDisplay, step = 1, handleChange, pieFilter } = props;

	function updateSlider(label: string, value: number[]): void {
		const filter = pieFilter[label];
		filter.value = [value[0], value[1]];
		handleChange(label, filter);
	}

	function moveSlider(label: string, value: number[]): void {
		const filter = pieFilter[label];
		filter.value = [value[0], value[1]];
		handleChange(label, filter, false);
	}

	return (
		<div>
			{
				/*
            <div className='d-flex'>
                <div className='form-group check-sidely my-2'>
                  <label className='ml-3 mb-3'>
                          <input
                            type="checkbox"
                            checked={pieFilter[sliderLabel].isActive}
                            onChange={e => {
                                let filter = pieFilter[sliderLabel];
                                filter.isActive = !filter.isActive;
                                handleChange(sliderLabel, filter);
                            }}
                        />

                    <div className='checkbox-style' />
                  </label>
                </div>
                <small className='ml-4 my-2'>
                  {label}
                </small>
            </div>
            */
			}
			<div className='d-flex'>
				<div className='form-group check-sidely my-2'>
					<label className='ml-3 mb-3'>
						<div className='checkbox-style' />
					</label>
				</div>
				<small className='my-2'>
					{label}
				</small>
			</div>
			<div className="ml-2 mr-2">
				<SliderFilter
					minValue={minValue}
					maxValue={maxValue}
					value={pieFilter[sliderLabel].value}
					color={color}
					reverted={reverted}
					label={sliderLabel}
					valueAlwaysDisplay={valueAlwaysDisplay}
					step={step}
					handleChangeCommited={updateSlider}
					handleChange={moveSlider}
					isActive={pieFilter[sliderLabel].isActive}
				/>
			</div>
		</div>
	);
}
