export const colourStyles = {
	valueContainer: styles => ({
		...styles,
		overflowX: 'scroll',
		overflowY: 'hidden',
		display: '-webkit-inline-box',
		whiteSpace: 'nowrap',
		flexWrap: 'nowrap',
		flexDirection: 'row',
		position: 'relative'
	}),
	placeholder: defaultStyles => ({
		...defaultStyles,
		color: '#cdcdcd'
	}),
	control: styles => ({
		...styles,
		border: '1px solid rgba(0, 0, 0, 0.125)',
		borderRadius: '5px',
		'&:hover': {
			borderColor: '#00b2ff'
		},
		minHeight: '38px',
		backgroundColor: 'white'
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: '#fff',
				color: data.color
			}
		};
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: 'white',
			borderRadius: '5px',
			border: '1px solid',
			borderStyle: data.type && data.type == 'operator' ? 'none' : 'solid',
			borderColor: data.color
		};
	},
	multiValueLabel: (styles, { data }) => {
		return {
			...styles,
			color: data.color,
			textDecoration: data.type && data.type == 'operator' ? 'underline' : 'none',
			fontFamily: 'Poppins'
		};
	},
	multiValueRemove: (styles, { data }) => {
		return data.type && data.type == 'operator'
			? {
				...styles,
				':hover': {
					color: 'black',
					padding: '2px 4px 4px 4px'
				}
			}
			: {
				...styles,
				color: data.color,
				':hover': {
					backgroundColor: data.color,
					color: 'white',
					padding: '2px 4px 4px 4px'
				}
			};
	}
};

export const styleOption = {
	placeholder: defaultStyles => ({
		...defaultStyles,
		color: '#cdcdcd'
	}),
	control: (styles, state) => ({
		...styles,
		borderColor: state.isFocused ? '#00b2ff' : '#f2f2f2',
		borderRadius: '0px 5px 5px 0px',
		borderLeft: 'transparent',
		boxShadow: state.isFocused ? 'none' : '',
		'&:hover': {
			borderColor: '#00b2ff'
		},
		minHeight: '38px',
		backgroundColor: 'white'
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: '#fff',
				color: data.color
			}
		};
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: 'white',
			borderRadius: '5px',
			border: '1px solid',
			borderColor: data.color
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: 'transparant',
			color: data.color,
			padding: '2px 4px 4px 4px'
		}
	}),
	menuList: styles => ({
		...styles,
		'::-webkit-scrollbar': {
			width: '10px',
			borderRadius: '5px',
			background: '#f3f3f3 !important'
		},
		'::-webkit-scrollbar-track': {
			borderRadius: '5px'
		},
		'::-webkit-scrollbar-thumb': {
			background: '#00b2ff',
			borderRadius: '5px'
		}
	})
};
