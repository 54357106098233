import drag_and_drop_dots from 'images/icons/drag_and_drop_dots.svg';
import * as React from 'react';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LightBlueSidely } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';
import { getLeftOrRightImage } from '../modalLeft/ModalLeftFilter';
import { InitialMapRow } from '../model';
import { FixedSizeList, areEqual } from 'react-window';
import { useWindowDimensions } from '../../../components_v2/utils';
import closeIcon from 'images/icon/closeIcon.png';
import useAlert from '../../alert/UseAlert';
import { translateToString } from '../../../styles/global/translate';
import { AlertRes } from '../../alert/AlertProvider';
import { MapContext } from '../MapContext';

export enum Panel {
  BasicList = 0,
  MassEvents = 1,
  AssignTo = 2,
}

const translatePath = 'map.modal_right';

export const HideScrollBar = styled.div`
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chro onSelect={setOperatorme, Safari and Opera */

  &::-webkit-scrollbar {
	display: none;
  }`;

const CompanyContainer = styled(FlexDiv)<{ selected: boolean }>`
	${p => p.selected ? `background-color: ${LightBlueSidely};` : ''}
	padding: 0.5em 0;
	height: 55px;
`;

const BlackDot = styled.div`
	background-color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	cursor: pointer;
	color: white;
	font-size: 9px;
	font-weight: 500;
	border-radius: 50%;
`;

export default function ModalRight(props: {
  companies: InitialMapRow[]
  setCompaniesInLasso: React.Dispatch<React.SetStateAction<InitialMapRow[]>>
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
	const { hoveredPin, setHoveredPin } = React.useContext(MapContext);
	const { isOpen, setIsOpen } = props;
	const alert = useAlert();

	React.useEffect(() => {
		if (!props.companies.length) setIsOpen(false);
	}, [props.companies]);

	function Item(p: ({ provided, item: InitialMapRow, style?: object, index: number })) {
		const { provided, item, style, index } = p;
		const [localTimeout, setLocalTimeout] = React.useState<NodeJS.Timeout>();

		const handleMouseLeave = () => {
			clearTimeout(localTimeout);
			setHoveredPin(undefined);
		};
		return (
			<div
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				ref={provided.innerRef}
				style={{ ...style, ...provided.draggableProps.style }}
				onMouseEnter={() => setLocalTimeout(setTimeout(() => setHoveredPin(item.id), 200))}
				onMouseLeave={handleMouseLeave}
			>
				<CompanyContainer justify='space-evenly' selected={typeof hoveredPin === 'object' ? hoveredPin.some(id => id === item.id) : item.id === hoveredPin}>
					<img
						style={{ width: 12, cursor: 'pointer' }}
						src={closeIcon}
						onClick={() => {
							props.setCompaniesInLasso(companies => {
								companies.splice(companies.indexOf(item), 1);
								return [...companies];
							});
						} } />
					<div style={{
						display: 'inline-block',
						width: '155px',
						verticalAlign: 'middle',
						fontFamily: 'Poppins',
						fontStyle: 'normal',
						fontWeight: 'normal',
						fontSize: '13px',
						lineHeight: '19px'
					}}>
						{item.name}
					</div>
					<img src={drag_and_drop_dots} />
					<BlackDot>
						{index + 1}
					</BlackDot>
				</CompanyContainer>
				<hr style={{ width: '90%', margin: '0 5%' }} />
			</div>
		);
	}

	const Row = React.memo(function Row(p: { data: InitialMapRow[], index: number, style: object }) {
		const { data, index, style } = p;
		const c = data[index];
		return <Draggable draggableId={c.id.toString()} index={index} key={c.id}>
			{provided => <Item provided={provided} item={c} style={style} index={index}/>}
		</Draggable>;
	}, areEqual);

	function handleOnDragEnd(result) {
		if (!result.destination) {
			return;
		}
		props.setCompaniesInLasso(companies => {
			const [reorderedItem] = companies.splice(result.source.index, 1);
			companies.splice(result.destination.index, 0, reorderedItem);
			return [...companies];
		});
	}
	const { height } = useWindowDimensions();

	return (
		(<DragDropContext onDragEnd={handleOnDragEnd}>
			<div className='filter-modal'
				style={{ marginTop: '52px', height: 'calc(100vh - 107px)', right: '0px', overflow: 'visible' }}>
				{props.companies.length > 0 && 
					<button
						type='button'
						className='btn btn-filter-modal'
						onClick={() => setIsOpen(!isOpen)}
						style={{ boxShadow: '-5px 0px 3px rgba(0, 0, 0, 0.24)' }}
					>
						<img
							src={getLeftOrRightImage(!isOpen)}
							alt=''
						/>
					</button>
				}
				<div className={isOpen ? 'box-filter-modal' : 'd-none'} style={{ overflow: 'visible', width: '275px' }}>
					{isOpen && <>
						<div className='d-flex justify-content-between align-items-center px-2'>
							<div style={{
								fontFamily: 'Poppins',
								fontStyle: 'normal',
								fontWeight: 'bold',
								fontSize: '14px',
								padding: '16px 8px 16px 16px',
								color: 'rgb(50, 60, 71)'
							}}>
								{props.companies.length} <Translate id={`${translatePath}.companies_selected`} />
							</div>
							<img
								src={closeIcon}
								className="custom-icon-3 my-auto mx-3"
								alt=""
								onClick={() => {
									alert({
										title: translateToString(`${translatePath}.delete_selection`),
										content: translateToString(`${translatePath}.are_you_sure_delete_selection`),
										buttons: [{ title: translateToString(`${translatePath}.yes_delete_it`), res: AlertRes.Ok }],
										gap: '0px'
									}).then((res) => {
										if (res === AlertRes.Ok) {
											props.setCompaniesInLasso([]);
										}
									});
								}}
							/>
						</div>
						<HideScrollBar style={{
							height: 'calc(100vh - 190px)'
						}}>
							<Droppable
								droppableId="companies"
								mode="virtual"
								renderClone={(provided, snapshot, rubric) => (
									<Item
										provided={provided}
										item={props.companies[rubric.source.index]}
										index={rubric.source.index}
									/>
								)}>
								{(provided) => (
									<FixedSizeList
										height={height - 190}
										itemCount={props.companies.length}
										itemSize={55}
										width={275}
										outerRef={provided.innerRef}
										itemData={props.companies}
									>
										{Row}
									</FixedSizeList>
								)}
							</Droppable>
						</HideScrollBar>
					</>}
				</div>
			</div>
		</DragDropContext>)
	);
}