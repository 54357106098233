import * as moment from 'moment';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AInputSearch } from '../../../atoms/filter/InputSearchFilter';
import { ADatePicker, AGranularitySelect } from '../../../atoms/filter/timeFilterAtom';
import { AViews, DbView, MetricsView, NewView, getViews, postView, putView } from '../../../atoms/global/views';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import Switch from '../../../components_v2/Switch/Switch';
import { FilterResult } from '../../../components_v2/filter/model/Model';
import { FilterParameter, convertFilters } from '../../../components_v2/filter/pages/FilterList';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import { momentToNaiveDateTime, useWindowDimensions } from '../../../components_v2/utils';
import PopupView, { ViewCreation } from '../../../components_v2/view/PopupView';
import ViewPicker from '../../../components_v2/view/ViewPicker';
import { SidebarButton } from '../../client-companies/style/Style';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import { FlexDiv } from '../../products/style';
import ReportingInterpretor from '../../reports/interpretor/ReportInterpretor';
import { Report } from '../../reports/interpretor/bareReportingDecoder';
import { getMetricsFilters, getMetricsV2 } from '../actions';


export default function MetricsV2(props: {
    setToolBarState: (value: ToolbarState) => void,
}) {
	// Report
	const [report, setReport] = React.useState<Report>();

	// Toolbars
	const { height, width } = useWindowDimensions();
	const [pickedDates, setPickedDates] = useRecoilState(ADatePicker);
	const [granularity, setGranularity] = useRecoilState(AGranularitySelect);
	const search = useRecoilValue(AInputSearch);
	const [representaion, setRepresentation] = React.useState<string>('classic');

	// Filters
	const [filterParams, setFilterParams] = React.useState<FilterParameter[]>();
	const [filterResult, setFilterResult] = React.useState<FilterResult>();

	const [keepDatesSwitch, setkeepDatesSwitch] = React.useState<boolean>(false);

	// Views
	const [views, setViews] = useRecoilState(AViews);
	const [isViewOpen, setIsViewOpen] = React.useState<boolean>(false);
	const [selectedView, setSelectedView_] = React.useState<DbView<MetricsView> | undefined>(views.metrics?.find(v => v.id.toString() === localStorage.getItem('metrics_view_id')));

	const DEFAULT_FILTER_RESULT: FilterResult = {
		values: {
			array: [],
			combinator: 'and'
		},
		formatted: undefined
	};

	const saveView = React.useCallback(async(v: ViewCreation) => {
		const data: NewView<'metrics'> = {
			data: {
				filters: filterResult ?? DEFAULT_FILTER_RESULT,
				columns: [],
				name: v.name,
				type: 'metrics',
				granularity: granularity,
				representation: representaion,
				pickedDates: keepDatesSwitch ? pickedDates : undefined
			},
			public: v.public
		};
		await (v.id ? putView(v.id, data) : postView(data));
		getViews().then(newViews => {
			setSelectedView_(newViews.metrics.find(nv => v.id === nv.id || !views.metrics.find(v => v.id == nv.id)));
			setViews(newViews);
		});
	}, [views, filterResult, granularity, keepDatesSwitch, representaion]);


	const [subComponentToolBarState, setSubComponentToolBarState] = React.useState<ToolbarState>();
	React.useEffect(() => {
		if (pickedDates && pickedDates[0] && pickedDates[1]) {
			getMetricsV2({ begin: momentToNaiveDateTime(pickedDates[0]), end: momentToNaiveDateTime(pickedDates[1]), granularity: granularity, representation: representaion, filters: filterResult?.formatted }).then(response => setReport(response));
		}
	}, [pickedDates, granularity, representaion, filterResult, views]);

	React.useEffect(() => {
		getMetricsFilters().then(response => setFilterParams(response.filters));
	}, []);


	React.useEffect(() => {
		props.setToolBarState({
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ViewPicker
					noBorder
					selectedView={selectedView}
					views={views.metrics}
					onViewChange={view => {
						setSelectedView_(view);
						let filters: FilterResult;
						if (view) {
							filters = { ...view.data.filters, formatted: convertFilters(view.data.filters.values) };
							setGranularity(view.data.granularity);
							setFilterResult(filters);
							setRepresentation(view.data.representation);
							view.data.pickedDates && setPickedDates(view.data.pickedDates?.map(d => d ? moment(d) : undefined) as any);
						}
						else {
							setFilterResult(DEFAULT_FILTER_RESULT);
							setGranularity('month');
							setRepresentation('classic');
							setPickedDates(pickedDates);
						}
					}}
				/>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 200}
					category={AtomCategory.GLOBAL}
					elements={[
						{
							kind: ToolbarElement.DATE_PICKER,
						},
						ToolbarElement.GRANULARITY_SELECT,
						ToolbarElement.INPUT_SEARCH,
					]}
				/>
                Percentage / Creations
				<Switch
					value={representaion === 'classic'}
					onChange={() => { if (representaion == 'classic') { setRepresentation('percentage'); } else if (representaion == 'percentage') { setRepresentation('classic'); } }}
				/>
				{subComponentToolBarState?.bottomLeftToolbarComponent}
				{(filterResult?.formatted || representaion === 'percentage' || granularity !== 'month') && <SidebarButton onClick={() => { setIsViewOpen(true); }}><Translate id='save_view' /></SidebarButton>}

			</FlexDiv>,
			bottomRightToolbarComponent: subComponentToolBarState?.bottomRightToolbarComponent ?? <></>

		});
	}, [report, subComponentToolBarState, filterResult, views, keepDatesSwitch]);
	if (report != null) {
		const v = {
			...report, rows: report.rows.filter(r => {
				const x = r.primaryCell[0].value.val;
				if (x && 'text' in x) {
					return x.text.toLowerCase().includes(search.toLowerCase());
				}
				return true;
			})
		};

		return <> <ReportingInterpretor
			onCompanyClick={() => undefined}
			onFormInstanceClick={() => undefined}
			filterParams={filterParams}
			filterResult={filterResult}
			onFiltersChange={filter => { setFilterResult(filter); }}
			setToolBarState={setSubComponentToolBarState}
			report={v}
			height={height - 260}></ReportingInterpretor >
		<PopupView
			isOpen={isViewOpen}
			view={selectedView}
			onClickOut={() => setIsViewOpen(false)}
			onValidation={v => saveView(v).then(_ => setIsViewOpen(false))}
			child={<div>Conserver les dates choisies<Switch value={keepDatesSwitch} onChange={v => setkeepDatesSwitch(v)}/></div>}
		/>
		</>;

	}
	return (<div><ComponentLoader loadingState={LoadingStateEnum.LOADING} allScreen noBackground /></div>);

}
