import React, {Component} from 'react';

// import ComponentHeader from './ComponentHeader';

class StartSection extends Component {
  state = {};

  render() {
    const { data } = this.props;
    return <div className="start-section"> {data.field_name} </div>;
  }
}

export default StartSection;
