import React, {Component} from 'react';
import {change, Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {Translate} from 'react-localize-redux';
import {renderField, required} from '../../FieldModal';
import SelectInCharge from '../../select/SelectInCharge';
import SelectStatus from '../../select/SelectClientStatus';
import {createFromSelect} from '../../../store/actions/companies.action';
import {normalizeCapitalize} from '../../../helpers/fieldMask';

class ModalCreateCompany extends Component {
  state = {};

  componentWillReceiveProps(nextProps) {
    const { initName, dispatch } = this.props;

    if (initName !== nextProps.initName) {
      dispatch(change('modalCompanyForm', 'name', nextProps.initName));
    }
  }

  onSubmit = value => {
    const { createFromSelect } = this.props;
    const formData = new FormData();

    const keys = ['ownerId', 'name', 'clientStatusId'];

    keys.map(key => {
      value[key] && formData.append(key, value[key].value || value[key]);
    });

    createFromSelect(formData);
  };

  render() {
    const { handleSubmit, isLoading } = this.props;
    // console.log('isLoading', isLoading);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader toggle={this.props.toggle}>
          <Translate id="new_company" />
        </ModalHeader>
        <ModalBody className="px-4">
          <form
            className="forms-sample background-form"
            onSubmit={handleSubmit(this.onSubmit)}
            style={{ boxShadow: 'none' }}
          >
            <Translate>
              {({ translate }) => (
                <div className="row">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-12">
                        <SelectInCharge
                          name="ownerId"
                          label={<Translate id="owner" />}
                          placeholder={translate('pick_owner')}
                          requiredStar
                          validate={[required]}
                        />
                        <Field
                          name="name"
                          requiredStar
                          type="text"
                          component={renderField}
                          label="Company Name"
                          placeholder="Input Company Name"
                          id="inputOwner"
                          validate={[required]}
                          normalize={normalizeCapitalize}
                        />
                        <SelectStatus
                          name="clientStatusId"
                          label={translate('status')}
                          placeholder="Choose Status"
                          requiredStar
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4" />
                </div>
              )}
            </Translate>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit(this.onSubmit)}
            loading={isLoading}
            spinColor="#003d79"
          >
            <span className="text-white">
              {!isLoading && <Translate id="save" />}
            </span>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.companies.isLoading
});

export default reduxForm({
  form: 'modalCompanyForm'
})(
  connect(
    mapStateToProps,
    { createFromSelect }
  )(ModalCreateCompany)
);
