import axios from 'axios';
import { DashboardItem, DashboardItemType } from './Dashboard';
import { URL_FOUNDATION } from '../../config/keys';
import { DashboardValue } from './Widget';
import { ClientCompany } from '../orders/model/Model';

export function getDashboard(screen_id: number): Promise<DashboardItem[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/dashboard/${screen_id}`).then(res => res.data);
}

export function updateDashboard(screen_id: number, items: DashboardItem<number | undefined>[]): Promise<void> {
	return axios.put(`${URL_FOUNDATION}/api/v2/dashboard/${screen_id}`, items).then(res => res.data);
}

export function getDashboardChart(type: DashboardItemType, itemId: number): Promise<DashboardValue> {
	return axios.get(`${URL_FOUNDATION}/api/v2/dashboard/${type}/${itemId}`).then(res => {
		return { type, value: res.data };
	});
}

export function getClientCompanyById(id: number): Promise<{ id: number, name: string }> {
	return axios.get(`${URL_FOUNDATION}/api/v2/client-companies/${id}`).then(res => res.data).then(data => {
		return { id: data.company_id, name: data.company_name };
	});
}