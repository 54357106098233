import styled from 'styled-components';
import { DefaultButton } from '../../../../../styles/global/css/GlobalButton';
import { DefaultText } from '../../../../../styles/global/css/GlobalText';
import { SidelyBlack } from '../../../../../styles/global/css/Utils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 85%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const Header = styled.div`
    flex-grow: 1;
`;

const Body = styled.div`
    width: 89%;
    flex-grow: 2;
`;

const Footer = styled.div`
    flex-grow: 1;
`;

interface PopupRowProps {
  flexDirection?: string
}
const PopupRow = styled.div<PopupRowProps>`
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    justify-content: ${p => p.flexDirection ? p.flexDirection : 'end'};
`;

const Title = styled.h3`
    ${DefaultText}
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${SidelyBlack};
    margin-left: auto;
    margin-right: auto;
`;

const Button = styled(DefaultButton)`
    width: 130px;
    height: 42px;
`;

export {
	Container,
	PopupRow,
	Button,
	Title,
	Header,
	Body,
	Footer
};
