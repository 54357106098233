import { URL_FOUNDATION } from '../../config/keys';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { PostTaxBody, Tax, TaxType } from './models';
import { decodeReport, Report } from '../reports/interpretor/bareReportingDecoder';
import { ServerAdvancedFilter } from '../client-companies/model/Model';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import { FilterParameter } from '../../components_v2/filter/pages/FilterList';
import { FilterId, isCheckoutEvolutionFilter, isExternalIdFilter, isFrequencyFilter, isLastEventDateFilter, isNextEventDateFilter } from '../../components_v2/filter/model/Model';
import { FilterType } from 'bindings/filters/FilterType';
import { translateToString } from '../../styles/global/translate';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

export async function getTaxes(): Promise<Tax[]> {
	return await axios.get<Tax[]>(`${URL_FOUNDATION}/api/tax`).then(res => res.data);
}

export async function getTaxTypes(): Promise<TaxType[]> {
	return await axios.get<TaxType[]>(`${URL_FOUNDATION}/api/tax/types`).then(res => res.data);
}


export async function postTax(body: PostTaxBody): Promise<any> {
	return await axios.post<TaxType[]>(`${URL_FOUNDATION}/api/tax`, body).then(res => res.data);
}

export async function getMetricsV2(body: any): Promise<Report> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.post<ArrayBufferLike>(`${URL_FOUNDATION}/api/metrics-v2`, body, { responseType: 'arraybuffer' })
		.then(res => {
			const u8Array = new Uint8Array(res.data);
			return decodeReport(u8Array);
		});
}

export type FilterCategory = typeof filterCategories[number];
export const getMetricsFitlersCategories = (filterId: FilterId): FilterCategory[] => {
	switch (filterId) {
		case 'client_created_at':
		case 'client_updated_at':
		case 'client_status':
		case 'client_account_type':
			return ['clients'];
	}
	return [];
};

export const filterCategories = ['clients', 'users'] as const;

export async function getMetricsFilters(): Promise<{filters: FilterParameter[], columns: FilterId[]}> {
	const translate = getTranslate(storeLang.getState().localize);
	try {
		const result = await axios.get<ServerAdvancedFilter[]>(`${URL_FOUNDATION}/api/metrics-v2/filters`)
			.then(response => ({
				filters: filterCategories.map((fc): FilterParameter => ({
					category: translate(fc).toString(),
					filters: response.data
						.reduce((acc, filter) => [...acc, ...toLocalFilter(filter)], [])
						.filter(companyFilter => getMetricsFitlersCategories(companyFilter[0]).includes(fc))
						.map(companyFilter => ({ id: companyFilter[0], name: translateToString(companyFilter[0].toString()), type: companyFilter[1] }))
				})),
				columns: response.data.reduce((acc, filter) => [...acc, ...toLocalFilter(filter)], []).map(r => r[0])
			}));
		return result;
	} catch (error) {
		console.error(error);
		return { filters: [], columns: [] };
	}
}
function toLocalFilter(companyFilter: [FilterId, FilterType]): ServerAdvancedFilter[] {
	if (isCheckoutEvolutionFilter(companyFilter[0])) {
		return [
			[
				{ checkout: { element: 'previous_sum' } },
				companyFilter[1]
			],
			[
				{ checkout: { element: 'current_sum' } },
				companyFilter[1]
			],
			[
				{ checkout: { element: 'evolution' } },
				companyFilter[1]
			]
		];
	}
	if (isFrequencyFilter(companyFilter[0])) {
		return [
			[
				{ frequencies: { element: 'score' } },
				companyFilter[1]
			],
			[
				{ frequencies: { element: 'frequency' } },
				companyFilter[1]
			]
		];
	}
	if (isNextEventDateFilter(companyFilter[0])) {
		return [
			[
				{ next_event_date: null },
				companyFilter[1]
			]
		];
	}
	if (isLastEventDateFilter(companyFilter[0])) {
		return [
			[
				{ last_event_date: null },
				companyFilter[1]
			]
		];
	}
	if (isExternalIdFilter(companyFilter[0])) {
		return [
			[
				{ external_id: null },
				companyFilter[1]
			]
		];

	}
	return [[companyFilter[0], companyFilter[1]]];
}

