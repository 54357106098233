import * as React from 'react';
import { Button, Container, Image, Text } from './style/noDataStyle';
import { translateToString } from '../../styles/global/translate';
import NoDataImage from 'images/icons/noData/no_data.svg';
import NoDataImageError from 'images/icons/noData/no_data_error.svg';

type Props = {
	messageButton?: string
	urlButton?: string
	height?: string
	backgroundColor?: string
	borderRadius?: string
	isError?: boolean
	onClickButton?: () => void
}

export function Unhautorized(props: Props) {
	return <NoData {...props} message={translateToString('you_are_not_autorized')} />;
}

export default function NoData(props: Props & { message: string }) {
	return (
		<Container height={props.height} backgroundColor={props.backgroundColor} borderRadius={props.borderRadius}>
			<Image
				src={props.isError ? NoDataImageError : NoDataImage}
				alt='no_data_image'
			/>
			<Text>
				{props.message}
			</Text>
			{
				((props.onClickButton || props.urlButton) && props.messageButton) &&
				<Button onClick={_ => {
					if (props.onClickButton) props.onClickButton();
					else window.open(props.urlButton, '_blank');
				}}> {props.messageButton}
				</Button>
			}

		</Container>
	);
}
