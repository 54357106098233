/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

class ModalFilterOwner extends Component {
  render() {
    const { detailLinkedForm, match, history, name, type } = this.props;
    const { linkedDetailForm } = detailLinkedForm;
    // const name = detail && detail.name;
    return (
      <Modal
        isOpen={this.props.isOpen}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          className="text-center pb-0"
          style={{ borderBottom: 'none' }}
        >
          <Translate id="create new form from this list" />
        </ModalHeader>
        <ModalBody>
          <div className="searchDashboard">
            <div className="modal-error-validate scrollSearch">
              <ul className="py-1" style={{ listStyle: 'none' }}>
                {linkedDetailForm &&
                  linkedDetailForm.map(value => (
                    <li
                      className="text-capitalize pointer"
                      onClick={() => {
                        history.push(
                          `/formBuilder/formData/${
                            value.user_form_id
                          }/create?linkedId=${
                            match.params.id
                          }&linkedName=${name}&type=${type}`
                        );
                      }}
                    >
                      <i
                        className="mr-2 fas fa-circle"
                        style={{ color: '#00b2ff', fontSize: '9px' }}
                      />
                      {value.user_form_name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {}
)(ModalFilterOwner);
