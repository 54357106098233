/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */

import React from 'react';
import CollapseWrapper from './CollapseWrapper';
import DynamicTable from './fields/DynamicTable';
import FormField from './FormField';
import { processApiToBuilder } from './services/fieldGetter';

export default class FormReal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onSubmit = e => {};

	getSimpleElement = item => {
		const { type, typeUser } = this.props;
		const Element = FormField[item.element];
		// console.log('itemElement', item);
		return <Element data={item} type={type} typeUser={typeUser} />;
		// return Element;
	};

	itemChildSecond = items =>
		items.map(item => {
			if (!item) return null;
			if (item.element === 'Table') return null;
			if (item.element === 'Section') return null;
			if (item.element === 'Header') return null;
			if (item.element === 'Camera') return null;
			if (item.element === 'Image') return null;
			return this.getSimpleElement({ ...item, id: '' });
		});

	itemChild = (items, parentType) => {
		const { type } = this.props;
		return items.map(item => {
			if (!item) return null;
			if (item.element === 'Table')
				return (
					<DynamicTable data={item} type={type}>
						{this.itemChildSecond(processApiToBuilder(item.details.fields))}
					</DynamicTable>
				);
			if (item.element === 'Section')
				return (
					<CollapseWrapper
						title={item.field_name}
						className="section-builder mt-3"
					>
						{this.itemChild(item.child)}
					</CollapseWrapper>
				);

			const processedItem = {
				...item,
				id: parentType === 'section' ? item.id : ''
			};
			return this.getSimpleElement(processedItem);
		});
	};

	render() {
		let data_items = this.props.data;
		const { type } = this.props;
		// console.log('dataItems', data_items);

		if (this.props.display_short) {
			data_items = this.props.data.filter(i => i.alternateForm === true);
		}

		const arr = [...data_items];

		// Set final array
		const finalArray = [];

		// Set sub object for groups (Childs)
		let subObj = {};

		// Flag for sub section stuff
		let inSubSection = false;

		let inSubTable = false;

		// Loop array
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].element === 'EndSection') {
				// If we have end_section
				// Set flag off
				inSubSection = false;
				// Push sub object to final array
				finalArray.push(subObj);
			} else if (arr[i].element === 'StartSection') {
				// If we get start_section
				// Set flag on
				inSubSection = true;
				// Set new sub object, set childs array in it
				subObj = {
					id: arr[i].id,
					element: 'Section',
					content: arr[i].content,
					field_name: arr[i].field_name,
					child: []
				};
			} else if (inSubSection) {
				// If we have active flag (true)
				// Push child to section array
				subObj.child.push({
					id: arr[i].id,
					element: arr[i].element,
					...arr[i]
				});
			} else if (arr[i].element === 'EndTable') {
				// If we have end_section
				// Set flag off
				inSubTable = false;
				// Push sub object to final array
				finalArray.push(subObj);
			} else if (arr[i].element === 'StartTable') {
				// If we get start_section
				// Set flag on
				inSubTable = true;
				// Set new sub object, set childs array in it
				subObj = {
					id: arr[i].id,
					element: 'Table',
					content: arr[i].content,
					child: []
				};
			} else if (inSubTable) {
				// If we have active flag (true)
				// Push child to section array
				subObj.child.push({
					id: arr[i].id,
					element: arr[i].element,
					...arr[i]
				});
			} else {
				// Everything else push straight to final array
				finalArray.push(arr[i]);
			}
		}

		const items = finalArray.map(item => {
			if (!item) return null;
			switch (item.element) {
				case 'Table':
					return (
						<DynamicTable data={item} type={type}>
							{this.itemChild(
								processApiToBuilder(item.details.fields),
								'table'
							)}
						</DynamicTable>
					);
				case 'Section':
					return (
						<CollapseWrapper
							title={item.field_name}
							className="section-builder mt-3"
						>
							{this.itemChild(item.child, 'section')}
						</CollapseWrapper>
					);
				default:
					return this.getSimpleElement(item);
			}
		});

		return <div>{items}</div>;
	}
}
