import * as React from 'react';
import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { Data } from './ProductModalLeft';

const Label = styled.p`
	${DefaultText}
	margin-left: 1rem;
	color: #4f4f4f;
	font-weight: 400;
`;

export default function RenderCheckbox(props: {
	list: Data[],
	onChange: (list: Data[]) => void
}): JSX.Element {

	function onClickCheckbox(currentData: Data): void {
		props.onChange(props.list.map(l => {
			if (l.id === currentData.id) {
				l.checked = !l.checked;
			}
			return l;
		}));
	}

	return (
		<>
			{
				props.list.map(l => (
					<div className="my-2 check-sidely px-2 d-flex" key={`CheckBox[${l.id}][${l.label}]`}>
						<label className="ml-2 mr-3 my-2">
							<input
								type="checkbox"
								name={l.label}
								className="mr-3"
								checked={l.checked ? l.checked : false}
								onChange={e => {
									onClickCheckbox(l);
								}}
							/>
							<div className="checkbox-style" />
						</label>
						<Label style={{ marginLeft: '1rem', color: '#4f4f4f' }}>
							{l.label}
						</Label>
					</div>
				))
			}
		</>
	);
}
