import * as React from 'react';
import { useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { LoadingState, LoadingStateEnum } from '../../containers_v2/import/model';
import storeLang from '../../helpers/storeLang';
import { Loader } from '../../styles/global/css/GlobalLoader';
import {
	ModalLeftBlockContainer,
	ModalLeftBody,
	ModalLeftButton,
	ModalLeftContainer,
	ModalLeftReset,
	ModalLeftTitle,
	ModalLeftTitleContainer
} from './styles/ModalLeftStyle';

function ModalLeft(props: {
  title: string
  content?: JSX.Element
  loading?: LoadingState
  children?
  onOpen?: (isOpen: boolean) => void
  hasReset?: boolean
  onReset?: () => void
}) {
	const [isOpen, setOpen] = useState<boolean>(false);
	const translate = getTranslate(storeLang.getState().localize);

	return (
		<ModalLeftContainer isOpen={isOpen}>
			<ModalLeftBlockContainer isOpen={isOpen}>
				<ModalLeftTitleContainer>
					<ModalLeftTitle>{props.title}</ModalLeftTitle>
					{
						props.hasReset &&
						<ModalLeftReset onClick={() => props.onReset && props.onReset()} >{translate('product.modal_left_filter.reset_all').toString()}</ModalLeftReset>
					}
				</ModalLeftTitleContainer>

				<ModalLeftBody>
					{
						(props.loading && props.loading === LoadingStateEnum.LOADING)
							? <Loader width="30px" center />
							: (props.children ? props.children : props.content)
					}
				</ModalLeftBody>
			</ModalLeftBlockContainer>
			<ModalLeftButton isOpen={isOpen} onClick={(e) => {
				setOpen(!isOpen);
				props.onOpen && props.onOpen(!isOpen);
			}} />
		</ModalLeftContainer>
	);
}

export default ModalLeft;
