import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BorderColor, DisabledColor, RedSidely } from '../../../styles/global/css/Utils';
import { InputMode } from '../model/Model';

interface ContainerProps {
  marginLeft?: string
  marginRight?: string
  height?: string
  flexDirection?: string
  width?: string
  padding?: string
  margin?: string
  flexShrink?: number
  alignSelf?: string
}

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: ${p => p.flexDirection ?? 'row'};
    width: ${p => p.width ?? 'fit-content'};
    height: ${p => p.height ? p.height : '30px'};
    margin-left: ${p => p.marginLeft ? p.marginLeft : 'inherit'};
    margin-right: ${p => p.marginRight ? p.marginRight : 'inherit'};
    ${p => p.padding ? `padding: ${p.padding};` : ''}
    ${p => p.margin ? `margin: ${p.margin};` : ''}
    ${p => p.flexShrink !== undefined ? `flex-shrink: ${p.flexShrink};` : ''}
    ${p => p.alignSelf ? `align-self: ${p.alignSelf};` : ''}
    // z-index: 1;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    white-space: nowrap;
`;

const Asterisk = styled.p`
    padding: 0;
    margin: 0;
    color: red;
    align-self: center;
    margin-right: 3px;
    
    &:before {
        content: "*";
    }
`;

const Label = styled.p`
    ${DefaultText}
    align-self: center;
    text-align: end;
    font-style: normal;
    font-size: 12px;
`;

interface InputLogoProps {
  maxHeight?: string
}

const InputLogo = styled.div<InputLogoProps>`
    max-height: ${p => p.maxHeight ? p.maxHeight : ''};
    margin-left: 5px;
`;

interface InputContainerProps {
  height?: string
  borderRadius?: string
  backgroundColor?: string
  hasBorder?: boolean
  border?: string
  cursor?: string
}

const InputContainer = styled.div<InputContainerProps>`
    width: 100%;
    height: ${p => p.height ? p.height : ''};
    border: ${p => p.hasBorder ? '1px solid ${BorderColor}' : 'none'};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    background-color: ${p => p.backgroundColor ? p.backgroundColor : 'initial'};
    border-radius: ${p => p.borderRadius ? p.borderRadius : '4px'};
    ${p => p.cursor ? `cursor: ${p.cursor};` : ''}
`;

interface InputBlockProps {
  Width?: string
  height?: string
  maxWidth?: string
  minWidth?: string
  textAlign?: string
  borderRadius?: string
  backgroundColor?: string
  placeholderColor?: string
  padding?: string
  hasBorder?: boolean
  border?: string
  fontSize?: string
  fontWeight?: string
  removePlaceHolderOnFocus?: boolean
  mode?: InputMode
}

const TextArea = styled.textarea<InputBlockProps>`
${DefaultText}
font-size: 12px;
padding: ${p => p.padding ?? '5px'};
box-sizing: border-box;
border: ${p => p.border ?? `1px solid ${BorderColor}`};
border-left: ${p => p.hasBorder ? 'none' : ''};
width: ${p => p.Width ? p.Width : '300px'};
height: ${p => p.height ? p.height : ''};
max-width: ${p => p.maxWidth ? p.maxWidth : ''};
min-width: ${p => p.minWidth ? p.minWidth : ''};
text-align: ${p => p.textAlign ? p.textAlign : ''};
flex-grow: 2;
background-color: ${p => p.backgroundColor ? p.backgroundColor : 'transparent'};
border-radius: ${p => p.borderRadius ? p.borderRadius : 'initial'};

&:focus {
    border: ${p => p.border ?? `1px solid ${BorderColor}`};
    border-left: ${p => p.hasBorder ? 'none' : ''};
    border-radius: ${p => p.borderRadius ? p.borderRadius : 'initial'};
} 

&:focus-visible {
    border: ${p => p.border ?? `1px solid ${BorderColor}`};
    border-left: ${p => p.hasBorder ? 'none' : ''};
    border-radius: ${p => p.borderRadius ? p.borderRadius : 'initial'};
}

&:disabled {
    background-color: ${DisabledColor};
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${p => p.placeholderColor ?? '#D4D0D2'};
    opacity: 1;
}

::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${p => p.placeholderColor ?? '#D4D0D2'};
    opacity: 1;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: ${p => p.placeholderColor ?? '#D4D0D2'};
    opacity: 1;
}
    ${p => {
		switch (p.mode) {
			case InputMode.Error:
				return `
                border-color: ${RedSidely};
                color: ${RedSidely};
            `;
			default: return '';
		}
	}}
`;

const InputBlock = styled.input<InputBlockProps>`
    ${DefaultText}
    font-size: ${p => p.fontSize ?? '12px'};
    padding: ${p => p.padding ?? '5px'};
    box-sizing: border-box;
    border: ${p => p.border ?? `1px solid ${BorderColor}`};
    border-left: ${p => p.hasBorder ? 'none' : ''};
    width: ${p => p.Width ? p.Width : '300px'};
    height: ${p => p.height ? p.height : ''};
    max-width: ${p => p.maxWidth ? p.maxWidth : ''};
    min-width: ${p => p.minWidth ? p.minWidth : ''};
    text-align: ${p => p.textAlign ? p.textAlign : ''};
    flex-grow: 2;
    background-color: ${p => p.backgroundColor ? p.backgroundColor : 'transparent'};
    border-radius: ${p => p.borderRadius ? p.borderRadius : 'initial'};
    ${p => p.color ? `color: ${p.color};` : ''}
    ${p => p.fontWeight ? `font-weight: ${p.fontWeight};` : ''}
    
    &:focus {
        border: ${p => p.border ?? `1px solid ${BorderColor}`};
        border-left: ${p => p.hasBorder ? 'none' : ''};
        border-radius: ${p => p.borderRadius ? p.borderRadius : 'initial'};
    }

    &:focus-visible {
        border: ${p => p.border ?? `1px solid ${BorderColor}`};
        border-left: ${p => p.hasBorder ? 'none' : ''};
        border-radius: ${p => p.borderRadius ? p.borderRadius : 'initial'};
    }

    &:disabled {
        background-color: ${DisabledColor};
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${p => p.placeholderColor ?? '#D4D0D2'};
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${p => p.placeholderColor ?? '#D4D0D2'};
        opacity: 1;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${p => p.placeholderColor ?? '#D4D0D2'};
        opacity: 1;
    }
    
    ${p => p.removePlaceHolderOnFocus ? `
        &:focus::placeholder {
            color: transparent;
        }
        ` : ''}
    ${p => {
		switch (p.mode) {
			case InputMode.Error:
				return `
                    border-color: ${RedSidely} !important;
                    color: ${RedSidely};
                `;
			default: return '';
		}
	}}
    
`;

const SearchImage = styled.img`
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 5px;
`;

export {
	Container,
	Label,
	InputBlock,
	Asterisk,
	LabelContainer,
	InputContainer,
	InputLogo,
	SearchImage,
	TextArea
};
