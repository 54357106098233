/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {Field} from 'redux-form';
import DateField from '../../../components/DateField';
import {required} from '../../../../../components/FieldModal';
import {fieldName} from '../services/fieldGetter';

class FreeText extends Component {
  state = {};

  render() {
    const { data, dynamicField, type } = this.props;
    const validate = data.is_required ? [required] : [];
    const name = fieldName(data, dynamicField);

    return (
      <Field
        name={name}
        id={`id-${name}`}
        type="text"
        component={DateField}
        label={data.is_display_field_name && data.field_name}
        placeholder=""
        requiredStar={data.is_required}
        validate={validate}
        readOnly={type === 'detail'}
      />
    );
  }
}

export default FreeText;
