import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { Redirect } from 'react-router';
import swal from 'sweetalert2';
import Breadcrum from '../../../components_v2/breadcrum/Breadcrum';
import { BreadcrumElement } from '../../../components_v2/breadcrum/model/Model';
import storeLang from '../../../helpers/storeLang';
import { RedSidely } from '../../../styles/global/css/Utils';
import { ToolbarBox, ToolbarImage } from '../../globals/defaultToolbar/style/Style';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { Tax } from '../../settings/models';
import { ClientCompany, Contact, Opportunity, OrderCreator, Owner, POURCENTAGE, SelectedProduct } from '../model/Model';
import OrderBox from 'images/icons/orders/order_box.svg';

const LOCALSTORAGE_NAME = 'cartship_order';

function getOrderCreator(): OrderCreator {
	const order = localStorage.getItem(LOCALSTORAGE_NAME);
	if (order) {
		const orderCreator = JSON.parse(order) as OrderCreator;
		return orderCreator;
	} else {
		return {} as OrderCreator;
	}
}

function setOrderCreator(name: string, company: ClientCompany, creationDate: string | null, owner: Owner, contact: Contact, opportunity: Opportunity, selectedProducts: SelectedProduct[], discount: number, discountType: number, status_id: number | undefined, payment_id: number | undefined, comment: string): void {
	const newOrder = {
		name,
		company,
		creationDate,
		owner,
		selectedProducts,
		discount,
		discountType,
		contact,
		opportunity,
		order_status_id: status_id,
		payment_id,
		comment
	} as OrderCreator;
	localStorage.setItem(LOCALSTORAGE_NAME, JSON.stringify(newOrder));
}

async function cleanOrderCreator(): Promise<void> {
	localStorage.removeItem(LOCALSTORAGE_NAME);
}

function getTotal(price: number | null, amount: number, discount: number, discountType: number, globalDiscount: number, globalDiscountType: number, difference: number, taxes?: Tax[]): number {
	if (!price) {
		return 0.0;
	}

	let result = 0.0;
	const newPrice = price * amount;

	result = (discountType == POURCENTAGE) ? (newPrice - (newPrice * (discount / 100.0))) : newPrice - discount; // local discount

	if (globalDiscount !== 0) {
		if (globalDiscountType === POURCENTAGE) {
			result = (result - (result * (globalDiscount / 100.0)));
		} else {
			result = result * (difference / 100.0);
		}
	}

	let resultTax = 0.0;

	taxes?.sort((a, b) => a.sequence - b.sequence).forEach(t => {
		if (t.tax_value) {
			if (t.is_additive) {
				resultTax += t.tax_type_name === 'percentage' ? ((result + resultTax) * (t.tax_value / 100.0)) : t.tax_value;
			} else {
				resultTax += t.tax_type_name === 'percentage' ? (result * (t.tax_value / 100.0)) : t.tax_value;
			}
		}
	});

	result += resultTax;

	// taxes?.map(t => {
	//     if (t.tax_value && !t.is_additive) {
	//         resultTax += t.tax_type_name === 'percentage' ? (result * (t.tax_value / 100.0)) : t.tax_value;
	//     }
	// });

	// result = result + resultTax;

	// taxes?.sort((a, b) => a.sequence - b.sequence).map(t => {
	//     if (t.tax_value && t.is_additive) {
	//         result += t.tax_type_name === 'percentage' ? (result * (t.tax_value / 100.0)) : t.tax_value;
	//     }
	// });

	return result;
}

function getSubTotal(price: number | null, amount: number, discount: number, discountType: number, withTax: boolean, taxes?: Tax[]): number {
	if (!price || !amount) {
		return 0.0;
	}

	let result = 0.0;
	const newPrice = price * amount;
	result = (discountType == POURCENTAGE) ? (newPrice - (newPrice * (discount / 100.0))) : newPrice - discount;

	let resultTax = 0.0;

	if (withTax) {
		taxes?.sort((a, b) => a.sequence - b.sequence).forEach(t => {
			if (t.tax_value) {
				if (t.is_additive) {
					resultTax += t.tax_type_name === 'percentage' ? ((result + resultTax) * (t.tax_value / 100.0)) : t.tax_value;
				} else {
					resultTax += t.tax_type_name === 'percentage' ? (result * (t.tax_value / 100.0)) : t.tax_value;
				}
			}
		});
		// taxes?.map(t => {
		//     if (t.tax_value && !t.is_additive) {
		//         resultTax += t.tax_type_name === 'percentage' ? (result * (t.tax_value / 100.0)) : t.tax_value;
		//     }
		// });
	}

	result += resultTax;

	if (withTax) {
		// taxes?.sort((a, b) => a.sequence - b.sequence).map(t => {
		//     if (t.tax_value && t.is_additive) {
		//         result += t.tax_type_name === 'percentage' ? (result * (t.tax_value / 100.0)) : t.tax_value;
		//     }
		// });
	}

	return result;
}

function getSubTotalHT(price: number | null, amount: number, discount: number, discountType: number): number {
	return getSubTotal(price, amount, discount, discountType, false);
}

function getSubTotalTTC(price: number | null, amount: number, discount: number, discountType: number, taxes?: Tax[]): number {
	return getSubTotal(price, amount, discount, discountType, true, taxes);
}

function getDifference(selectedProducts: SelectedProduct[], globalDiscount: number) {
	if (selectedProducts.length === 0) {
		return 0;
	}

	const subTotal = getsubTotal_totalHT(selectedProducts);
	return (100 * (subTotal - globalDiscount)) / subTotal;
}

function getDiscountProductsPrice(sp: SelectedProduct): number {
	if (!sp.price) {
		return 0.0;
	}
	const newPrice = (sp.price * sp.amount);
	return (sp.discountType == POURCENTAGE) ? newPrice * (sp.discount / 100.0) : sp.discount;
}

function getTotalDiscountProductsPrice(selectedProducts: SelectedProduct[]): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => getDiscountProductsPrice(c) + prev, 0);
}

function getDiscountGlobalDiscountPrice(sp: SelectedProduct, globalDiscount: number, globalDiscountType: number, difference: number): number {
	if (!sp.price) {
		return 0.0;
	}

	let result = 0.0;
	const newPrice = (sp.price * sp.amount);
	const productDiscount = (sp.discountType == POURCENTAGE) ? newPrice * (sp.discount / 100.0) : sp.discount; // local discount
	if (globalDiscount !== 0) {
		result = (globalDiscountType === POURCENTAGE) ? productDiscount * (globalDiscount / 100.0) : productDiscount * ((100.0 - difference) / 100.0);
	}
	return result;
}

function getTotalDiscounGlobalPrice(selectedProducts: SelectedProduct[], globalDiscount: number, globalDiscountType: number, difference: number): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => getDiscountGlobalDiscountPrice(c, globalDiscount, globalDiscountType, difference) + prev, 0);
}

function getDiscountPrice(sp: SelectedProduct, globalDiscount: number, globalDiscountType: number, difference: number): number {
	if (!sp.price) {
		return 0.0;
	}

	let result = 0.0;
	let result2 = 0.0;
	const newPrice = (sp.price * sp.amount);
	result = (sp.discountType == POURCENTAGE) ? newPrice * (sp.discount / 100.0) : sp.discount; // local discount
	if (globalDiscount !== 0) {
		result2 = (globalDiscountType === POURCENTAGE) ? result * (globalDiscount / 100.0) : result * ((100.0 - difference) / 100.0);
	}
	return result + result2;
}

function getTotalDiscountPrice(selectedProducts: SelectedProduct[], globalDiscount: number, globalDiscountType: number, difference: number): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => getDiscountPrice(c, globalDiscount, globalDiscountType, difference) + prev, 0);
}

function getsubTotal_totalHT(selectedProducts: SelectedProduct[]): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => getSubTotalHT(c.price, c.amount, c.discount, c.discountType) + prev, 0);
}

function getsubTotal_totalTTC(selectedProducts: SelectedProduct[]): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => getSubTotalTTC(c.price, c.amount, c.discount, c.discountType, c.extra_taxes) + prev, 0);
}

function getTaxPrice(sp: SelectedProduct): number {
	if (!sp.price) {
		return 0.0;
	}

	const newPrice = (sp.price * sp.amount);

	const result = sp.discountType === POURCENTAGE ? newPrice - (newPrice * (sp.discount / 100.0)) : newPrice - sp.discount;

	let resultTax = 0.0;
	sp.extra_taxes?.sort((a, b) => a.sequence - b.sequence).forEach(t => {
		if (t.tax_value) {
			if (t.is_additive) {
				resultTax += t.tax_type_name === 'percentage' ? ((result + resultTax) * (t.tax_value / 100.0)) : t.tax_value;
			} else {
				resultTax += t.tax_type_name === 'percentage' ? (result * (t.tax_value / 100.0)) : t.tax_value;
			}
		}
	});

	return resultTax;
}

function getTotalTaxPrice(selectedProducts: SelectedProduct[]): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => getTaxPrice(c) + prev, 0);
}

function getTotalTTC(selectedProducts: SelectedProduct[], globalDiscount: number, globalDiscountType: number, difference: number): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => getTotal(c.price, c.amount, c.discount, c.discountType, globalDiscount, globalDiscountType, difference, c.extra_taxes) + prev, 0);
}

function getTotalProduct(selectedProducts: SelectedProduct[]): number {
	return selectedProducts.filter(sp => sp.price != null).reduce((prev, c) => ((c.price ? c.price : 0) * c.amount) + prev, 0);
}

function getBreadcrumElements(reference: string): BreadcrumElement[] {
	const translate = getTranslate(storeLang.getState().localize);
	return [
		{
			img_url: OrderBox,
			link: '/orders'
		},
		{
			label: (reference?.length ?? 0) === 0 ? translate('orders.global.toolbar.noReference').toString() : reference,
			link: '/orders/select-products'
		},
		{
			label: translate('orders.summary.toolbar.subtitle').toString(),
			link: '/orders/summary'
		},
		{
			label: translate('orders.billing.toolbar.subtitle').toString(),
			link: '/orders/billing'
		}
	];
}

function updatebottomLeftToolbar(setToolBarState: React.Dispatch<React.SetStateAction<ToolbarState>>, breadcrumIndex: number, reference: string, jsxElement?: JSX.Element): void {
	setToolBarState(prev => ({
		...prev,
		bottomLeftToolbarComponent: (
			<ToolbarBox>
				<Breadcrum elements={getBreadcrumElements(reference)} selectedElement={breadcrumIndex} breadcrumStyle={{ marginLeft: '20px' }} />
				{
					jsxElement
				}
			</ToolbarBox>
		)
	}));
}

function warningdDelete(setRedirect: Function, redirectTo: string) {
	const translate = getTranslate(storeLang.getState().localize);

	swal({
		title: translate('global.fire.alert_title').toString(),
		text: translate('orders.fire.confirmationEmptyOrder').toString(),
		type: 'warning',
		confirmButtonText: translate('global.fire.confirm_message').toString(),
		confirmButtonColor: RedSidely
	})
		.then(result => {
			if (result.value) {
				setRedirect(<Redirect to={redirectTo} />);
				cleanOrderCreator();
			}
		});
}

function closebottomRightToolbar(setToolbarState: React.Dispatch<React.SetStateAction<ToolbarState>>, setRedirect: Function, redirectTo: string): void {
	setToolbarState(prev => ({
		...prev,
		bottomRightToolbarComponent: (
			<ToolbarBox>
				<ToolbarImage hasPointer src={close} onClick={() => {
					warningdDelete(setRedirect, redirectTo);
				}} />
			</ToolbarBox>
		)
	}));
}

export {
	getOrderCreator,
	setOrderCreator,
	cleanOrderCreator,
	getTotal,
	getSubTotalHT,
	getSubTotalTTC,
	getDifference,
	getDiscountPrice,
	getTotalDiscountPrice,
	getsubTotal_totalHT,
	getsubTotal_totalTTC,
	getTaxPrice,
	getTotalTaxPrice,
	getTotalTTC,
	getTotalProduct,
	updatebottomLeftToolbar,
	closebottomRightToolbar,
	getTotalDiscountProductsPrice,
	getTotalDiscounGlobalPrice
};
