/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import {selectProspectOrigin} from '../../store/actions/select.action';

class SelectProspectOrigin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectProspectOrigin } = this.props;
    selectProspectOrigin();
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (data !== nextProps.data) {
      const opt = nextProps.data.map(data => ({
        value: data.id,
        label: data.name
      }));
      this.setState({ opt });
    }
  }

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      // data,
      placeholder
    } = this.props;
    // console.log('data', data);
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="inputOwner"
        placeholder={placeholder}
        validate={validate}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.prospectOrigin
});

export default connect(
  mapStateToProps,
  { selectProspectOrigin }
)(SelectProspectOrigin);
