import closeImage from 'images/icons/orders/close.svg';
import * as React from 'react';
import { optimizeVisits, putEvents, PutEventsBody } from './EventsApi';
import ModalLeft from './ModalLeft';
import SidelyCalendar, { CalendarEvent, CalendarEvents, EventsTypes } from './Calendar';
import { createRandomColorPerUser, momentToNaiveDateTime, useWindowDimensions } from '../../components_v2/utils';
import { ToolbarState } from '../globals/mainPage/mainPage';
import ToolbarFilter, { ToolbarElement } from '../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../atoms/utils/model/Model';
import { ViewSwitch, viewType } from '../../myToolbar/ViewSwitch';
import { useRecoilValue } from 'recoil';
import { AUserFilter } from '../../atoms/filter/usersFilterAtom';
import { ATagFilter } from '../../atoms/filter/tagsFilterAtom';
import { AEventStatusFilter, AEventTypeFilter } from '../../atoms/filter/eventFiltersAtom';
import PageLoader from '../../components_v2/pageLoader/PageLoader';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../helpers/storeLang';
import { RepresentationMode } from './model/Model';
import { BlueSidely, SidelyBlack } from '../../styles/global/css/Utils';
import { AUsers } from '../../atoms/global/users';
import CalendarList, { CalendarEventUpdater } from './CalendarList';
import { Interconnection, MapEventOptions, OptimizeCompany } from '../map/modalRight/model';
import * as moment from 'moment';
import { ModalState } from '../products/model';
import styled from 'styled-components';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { AEventTypes } from '../../atoms/global/events';
import { CalendarMapView } from '../map/calendarMapView';
import Splitter from '../../components_v2/splitter/Splitter';
import { ComponentLoader, OptimizationInformations, OptimizePopup } from '../map/modalRight/ModalCalendar';
import { LoadingStateEnum } from '../import/model';
import useAlert from '../alert/UseAlert';
import PopupEvent from './popup/Event';
import { NewEventDefaultValues } from './popup/NewEvent';
import Add from '../../components_v2/add/Add';
import { FlexDiv } from '../products/style';
import Restricted from '../permissions/Restricted';
import { Unhautorized } from '../noData/NoData';
import PopupPromotion from '../promotions/PopupPromotion';
import ShelfAuditPopup from '../shelfAudit/popup/ShelfAuditPopup';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { InstancePopup } from '../forms/formInstancePopup';

type loadingState = 'loading' | 'loaded' | 'error' | null

export function initialCalendarFilters(changed: boolean) {
	return {
		types: [EventsTypes.Event, EventsTypes.Promotion],
		changed
	};
}

const CloseMapButton = styled(DefaultButton)<{ url: string, top?: number }>`
	font-size: 14px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	padding: 10px;
	background: white;
	position: absolute;
	top: ${p => p.top ?? 0}px;
	left: 0;
	height: 40px;
	width: 40px;
	display: flex;
	background-image: url(${p => p.url});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 25px;
`;

const AnimatedMapButton = styled(DefaultButton)<{ top?: number }>`
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	background: white;
	position: absolute;
	top: ${p => p.top ?? 0}px;
	left: 0;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;

	.speed-bar-left {
		transition: width 1s;
	}

	.speed-bar-right {
		transition: width 1s;
	}

	.speed-contoler {
		transition: 1s;
		transform-origin: 50% calc(50% - -3px);
		rotate: -90deg;
	}

	&:hover {
		.speed-contoler {
			rotate: 0deg;
		}

		.speed-bar-left {
			width: 4.5px;
		}

		.speed-bar-right {
			width: 1px;
		}
		
		.speed-bar-middle {
			animation: grow-then-shrink 1s;
		}
	}

	@keyframes grow-then-shrink {
		0%, 100% {
			width: 4.5px;
		}
		50% {
			width: 2.75px;
		}
	}
`;

export default function CalendarView(props: {
  setToolBarState: (value: ToolbarState) => void
}) {
	const translate = getTranslate(storeLang.getState().localize);
	const { height } = useWindowDimensions();
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [optimizationLoadingState, setOptimizationLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [rawEventsCalendar, setRawCalendar] = React.useState<CalendarEvents[]>([]);
	const [filters, setFilters] = React.useState<object>(initialCalendarFilters(false));
	const [reset, setReset] = React.useState<boolean>(false);
	const [view, setView] = React.useState<viewType>('calendar');
	const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
	const [modalState, setModalState] = React.useState<ModalState<{ event?: CalendarEvent, defaultValues?: NewEventDefaultValues }>>({ isOpen: false });
	const [promotionModalState, setPromotionModalState] = React.useState<ModalState<number>>({ isOpen: false });
	const [formModalState, setFormModalState] = React.useState<ModalState<string>>({ isOpen: false, fullOpenMode: false });
	const [representationMode, setRepresentationMode] = React.useState<RepresentationMode>();
	const owners = useRecoilValue(AUsers);
	const colors = React.useMemo(() => createRandomColorPerUser(owners), []);
	const [hoveredEvent, setHoveredEvent] = React.useState<string | number | undefined>(undefined);
	const [hovered, setHovered] = React.useState<string | undefined>(undefined);
	const [mapModalState, setMapModalState] = React.useState<ModalState<moment.Moment>>({ isOpen: false });
	const [date, setDate] = React.useState<Date>(new Date());
	const atomEventTypes = useRecoilValue(AEventTypes);
	const [modalStateOptimize, setModalStateOptimize] = React.useState<ModalState<OptimizeCompany[]>>({ isOpen: false });
	const [shelfAuditModal, setShelfAuditModal] = React.useState<ModalState>({ isOpen: false });
	const alert = useAlert();
	
	React.useEffect(() => {
		if (view !== 'list') {
			props.setToolBarState({
				title: translate('calendar.title').toString(),
				bottomLeftToolbarComponent: <>
					<ToolbarFilter
						permission='ReadEvent'
						category={AtomCategory.CALENDAR}
						elements={[
							{
								kind: ToolbarElement.USER_FILTER,
								user: 'me'
							},
							ToolbarElement.EVENT_STATUS_FILTER,
							ToolbarElement.EVENT_TYPE_FILTER,
							ToolbarElement.TAG_FILTER
						]}
					/>
					{loadingState == 'loading' && <div style={{ marginLeft: 5 }}><PageLoader size={20} /></div>}
				</>,
				bottomRightToolbarComponent: <FlexDiv gap='10px'>
					<ViewSwitch dispatch={res => setView(res.data)} value={view} />
					<Restricted to={{ objectAction: 'CreateEvent' }}>
						<Add onClick={() => setModalState({ isOpen: true })} />
					</Restricted>
				</FlexDiv>
			});
		}
	}, [view, loadingState]);

	const filteredUsers = useRecoilValue(AUserFilter);
	const filteredStatuses = useRecoilValue(AEventStatusFilter);
	const filteredTypes = useRecoilValue(AEventTypeFilter);
	const filteredTags = useRecoilValue(ATagFilter);

	// force update
	function refreshData() {
		setLoadingState(null);
		setReset(true);
		forceUpdate();
	}

	// open or close modal
	function toggleModal() {
		setModalState({
			isOpen: !modalState.isOpen,
		});
	}

	function handleFilter(key: string, value) {
		if (key === 'reset') {
			setFilters(initialCalendarFilters(true));
			return;
		}
		const newFilters = filters;
		newFilters[key] = value;
		setFilters({ ...newFilters });
	}

	function onSelectSlot(e) {
		setModalState({
			...modalState,
			isOpen: true,
			data: {
				defaultValues: {
					start: new Date(e.start),
					end: new Date(e.end)
				}
			}
		});
	}

	let eventStyle: undefined | ((event: CalendarEvents, hovered: boolean) => ({ style }));

	switch (representationMode) {
		case 'per_users':
			eventStyle = (event: CalendarEvents, hovered: boolean) => {
				if (event.type === EventsTypes.Promotion) {
					const filter = hovered ? 'brightness(50%)' : '';
					return {
						style: {
							backgroundColor: '#FFFFFF',
							border: `1px solid ${event.color ?? BlueSidely}`,
							padding: 0,
							color:  'black',
							filter
						}
					};
				}
				let incharge: number | undefined = undefined;
				if ('incharge' in event) {
					incharge = event.incharge;
				} else if ('checkIn' in event) {
					incharge = event.checkIn.user_id;
				} else if ('owner_id' in event) {
					incharge = event.owner_id;
				}
				return {
					style: {
						backgroundColor: colors?.find(c => c.owner.id == incharge)?.color ?? BlueSidely,
						color: 'white',
						filter: `brightness(${hovered ? 80 : 100}%)`
					}
				};
			};
			break;
	}

	const mapEvents = rawEventsCalendar.reduce((acc: MapEventOptions[], e): MapEventOptions[] => {
		switch (e.type) {
			case EventsTypes.CheckIn:
				if (!acc[0]) {
					acc[0] = { events: [], lineColor: SidelyBlack };
				}
				acc[0].events.push({
					companyId: e.checkIn.client_company_id,
					eventId: e.id,
					eventName: 'Check In' + e.checkIn.company_name ? ' -- ' + e.checkIn.company_name : '',
					companyName: e.checkIn.company_name ?? '',
					lat: e.checkIn.latitude,
					lng: e.checkIn.longitude,
					time: moment(e.start)
				});
				break;
			case EventsTypes.Event: {
				const baseEvent = atomEventTypes.find(ae => ae.id === e.eventTypeId);
				if (e.allDay) return acc;
				if (baseEvent?.map && e.latitude && e.longitude && e.companieName) {
					if (!acc[1]) {
						acc[1] = { events: [], lineColor: baseEvent.color, eventType: baseEvent.id };
					}
					acc[1].events.push({
						companyId: e.clientCompanyId ?? -1,
						eventId: e.id,
						eventName: e.title,
						companyName: e.companieName,
						lat: e.latitude,
						lng: e.longitude,
						time: moment(e.start),
						color: baseEvent.color
					});
				}
			}
		}
		return acc;
	}, []);

	const svg = <svg id="speed-container" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 46 46" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
		<path className="speed-contoler" id="speed-contoler" d="M31.6308,16.1029l-7.1765,7.055c-.7741-.3729-1.6551-.4602-2.4874-.2464s-1.5621.715-2.0606,1.4149-.7336,1.5535-.6636,2.4099.4403,1.6605,1.0458,2.2703s1.4069.9858,2.2628,1.0618s1.7111-.153,2.4145-.6466s1.2097-1.2199,1.4294-2.0506.1387-1.7123-.2287-2.4891l7.1886-7.055-1.7243-1.7242Z" fill="#666666"/><path d="M22.9126,6.3036c-3.8217-.00438-7.5649,1.08501-10.7874,3.1395s-5.78972,4.9883-7.39852,8.4549-2.19195,7.3211-1.68058,11.1084s2.0959,7.3493,4.56645,10.2651l.36429.425h29.87146l.3643-.425c2.4705-2.9158,4.055-6.4777,4.5664-10.2651s-.0718-7.6419-1.6806-11.1084-4.176-6.4004-7.3985-8.4549-6.9656-3.14388-10.7873-3.1395ZM11.2918,13.1279c2.894-2.5579,6.5529-4.08634,10.4065-4.34716v.01214h2.4285v0c4.2567.3087,8.2555,2.15612,11.2497,5.19732c2.9943,3.0412,4.7793,7.0682,5.0218,11.3291h-4.2257v2.4286h4.2864c-.2826,3.4786-1.5976,6.7936-3.7764,9.52h-27.54005c-2.17882-2.7264-3.49381-6.0414-3.77643-9.52h4.18929v-2.4286h-4.18929c.20336-3.8557,1.67628-7.5358,4.18929-10.4671" fill="#666666" />
		<rect className="speed-bar-left" id="speed-bar-left" width="1" height="2.43" rx="0" ry="0" transform="matrix(.693577 0.720383-.720383 0.693577 11.232456 12.997996)" fill="#666666" strokeWidth="0"/>
		<rect className="speed-bar-middle" id="speed-bar-middle" width="4.5" height="2.43" rx="0" ry="0" transform="matrix(-.014999 0.999888-.999888-.014999 24.16118 8.504911)" fill="#666666" strokeWidth="0"/>
		<rect className="speed-bar-right" id="speed-bar-right" width="4.5" height="2.43" rx="0" ry="0" transform="matrix(-.720383 0.693577 0.693577 0.720383 35.061367 12.967957)" fill="#666666" strokeWidth="0"/>
	</svg>;

	const onOptimize = (_ids: string[], interconnections: Interconnection[], infos: OptimizationInformations) => {
		setOptimizationLoadingState(LoadingStateEnum.LOADING);
		const start = moment(date).set({ hour: infos.start.getHours(), minute: infos.start.getMinutes(), second: 0, millisecond: 0 });
		const visits = interconnections.reduce((acc, interconnection) => {
			const event = rawEventsCalendar.find(e => 'id' in e && e.id.toString() == interconnection.toWaypoint);
			if (!event || !('id' in event)) return acc;
			return [...acc, { travel_duration: interconnection.time, id: parseInt(event.id.toString()), duration: (event.end.getTime() - event.start.getTime()) / 1000 }];
		}, []);
		optimizeVisits({
			visits,
			first_visit: momentToNaiveDateTime(start),
			working_hours: {
				start: momentToNaiveDateTime(start),
				end: momentToNaiveDateTime(moment(infos.end))
			},
			pause: infos.pause ? {
				time: momentToNaiveDateTime(moment(infos.pause.time)),
				duration: infos.pause.duration_sec
			} : undefined,
			working_days: []
		})
			.then(sorted_events => {
				let overlapedEvents = 0;
				const events: PutEventsBody[] = sorted_events.map(se => {
					if (moment(se.start).format('LL') !== start.format('LL')) {
						overlapedEvents += 1;
						return [se.id, { start: moment(moment(se.start).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm:ss'), end: moment(moment(se.end).format('YYYY-MM-DD 23:59:00')).utc().format('YYYY-MM-DD HH:mm:ss') }];
					}
					return [se.id, { start: moment(se.start).utc().format('YYYY-MM-DD HH:mm:ss'), end: moment(se.end).utc().format('YYYY-MM-DD HH:mm:ss') }];
				});
				if (overlapedEvents) {
					setOptimizationLoadingState(LoadingStateEnum.LOADED);
					alert({
						title: 'Attention',
						content: translate('{{NUMBER}}_events_exceed_the_day').toString().replace('{{NUMBER}}', overlapedEvents.toString()),
						svg: 'warning',
						noButtons: true
					});
					return;
				}
				putEvents(events)
					.then(_ => {
						refreshData();
						setOptimizationLoadingState(LoadingStateEnum.LOADED);
					})
					.catch(e => {
						console.error(e);
						setOptimizationLoadingState(LoadingStateEnum.ERROR);
					});
			})
			.catch(e => {
				console.error(e);
				setOptimizationLoadingState(LoadingStateEnum.ERROR);
			});
	};

	return (
		<Restricted to={{ objectAction: 'ViewCalendar' }} fallback={<Unhautorized />}>
			<div>
				<div className={view !== 'calendar' ? 'd-none' : ''}><ModalLeft handleFilter={handleFilter} onRepresentationChange={setRepresentationMode} colors={colors} /></div>
				{!loadingState && view === 'calendar' &&
						<div className="list-loader">
							<PageLoader />
						</div>
				}
				{loadingState === 'error' && <p>Error fetching data</p>}
				{view === 'list' && <CalendarList
					onSelectEvent={event => setModalState({ data: { event }, isOpen: true })}
					setToolBarState={props.setToolBarState}
					setView={setView}
					refreshData={refreshData}
				/>}
				{view === 'calendar' && <>
					{/* @ts-expect-error I assure, this is working */}
					<Splitter
						style={{ height: height - 128 }}
						direction="vertical"
						sizes={mapModalState.isOpen ? [66, 33] : [100]}
						ishidden={(!mapModalState.isOpen).toString()}
						minSize={0}
					>
						<div style={{ overflow: 'auto', height: mapModalState.isOpen ? undefined : '100%' }} className={view !== 'calendar' ? '' : 'table-bg'}>
							<SidelyCalendar
								isMapOpen={mapModalState.isOpen}
								events={rawEventsCalendar}
								setEvents={setRawCalendar}
								onSelectSlot={onSelectSlot}
								onSelectEvent={(event: CalendarEvents) => {
									switch (event.type) {
										case EventsTypes.Event:
											setModalState({ data: { event }, isOpen: true });
											break;
										case EventsTypes.ShelfAudit:
											setShelfAuditModal({ fullOpenMode:false, isOpen: true, data:{ shelfAuditId:event.id, clientCompanyId:event.companyId } });
											break;
										case EventsTypes.FreeForm:
											window.open(`/formBuilder/formData/${event.link}`);
											break;
										case EventsTypes.Order:
											window.open(`/orders?id=${event.id}`);
											break;
										case EventsTypes.CheckIn:
											window.open(`/companies?id=${event.checkIn.client_company_id}`);
											break;
										case EventsTypes.Promotion:
											setPromotionModalState({ data: event.promotion_id, isOpen: true });
											break;
										case EventsTypes.Form:
											setFormModalState({ isOpen: true, data: event.id, fullOpenMode: true });
											break;

									}
								}}
								filters={filters}
								toolBarState={{
									userId: filteredUsers,
									statusId: filteredStatuses,
									typeId: filteredTypes,
									tags: filteredTags
								}}
								setLoadingState={setLoadingState}
								loadingState={loadingState}
								reset={reset}
								setReset={setReset}
								style={{ width: 'auto', margin: 'auto' }}
								eventStyle={eventStyle}
								onEventHover={event => {
									if (!event || !('id' in event)) return setHovered(undefined);
									setHovered(event.id.toString());
								}}
								hoveredEventId={hoveredEvent}
								onMapClicked={date => {
									setMapModalState({ isOpen: true, data: moment(date) });
									setDate(date);
								}}
								date={date}
								onDateChange={setDate}
							/>
						</div>
						<div style={{ height: mapModalState.isOpen ? undefined : 0, overflow: 'hidden' }}>
							<Restricted
								to={{ objectAction: 'ViewMap' }}
								fallback={<div style={{ position: 'relative', height: '100%' }}>
									<CloseMapButton onClick={() => setMapModalState({ isOpen: false })} url={closeImage} />
									<Unhautorized height='100%'/>
								</div>}
							>
								{mapModalState.isOpen && <>
									<div style={{ position: 'relative', zIndex: 1 }}>
										<CloseMapButton onClick={() => setMapModalState({ isOpen: false })} url={closeImage} />
										<AnimatedMapButton onClick={_ => setModalStateOptimize({
											isOpen: true,
											data: mapEvents.filter(me => atomEventTypes.some(ae => ae.id === me.eventType && ae.map))
												?.[0]
												?.events
												?.filter(e => e.time.format('LL') == moment(date).format('LL'))
												?.map((e): OptimizeCompany => ({
													id: parseInt(`${e.eventId}`),
													lat: e.lat,
													lng: e.lng
												}))
										})
										} top={50}>
											{svg}
										</AnimatedMapButton>
									</div>
									<CalendarMapView
										height='100%'
										mapEvents={mapEvents}
										hovered={hovered}
										setHoveredEvent={setHoveredEvent}
										defaultTime={mapModalState.data}
										time={moment(date)}
										onTimeChange={m => setDate(m.toDate())}
									/>
								</>}
							</Restricted>
						</div>
					</Splitter>
					<ComponentLoader height={`${height}px`} loadingState={optimizationLoadingState} allScreen zIndex='10'/>
				</>}
				<PopupEvent
					isOpen={modalState.isOpen}
					setIsOpen={toggleModal}
					onDelete={refreshData}
					onCreate={refreshData}
					onValueChange={modalState.data?.event?.id ? (values, id) => CalendarEventUpdater(values, id, rawEventsCalendar, setRawCalendar) : undefined}
					event={modalState.data?.event}
					defaultValues={modalState.data?.defaultValues}
				/>
				<OptimizePopup
					companies={modalStateOptimize.data}
					errorCompanies={[]}
					onOptimize={onOptimize}
					isOpen={modalStateOptimize.isOpen}
					setIsOpen={() => setModalStateOptimize({ isOpen: false })}
					allInformations
				/>
				<PopupPromotion
					setIsOpen={(isOpen: boolean) => setPromotionModalState({ isOpen })}
					isOpen={promotionModalState.isOpen}
					promotionId={promotionModalState.data}
					onDelete={refreshData}
				/>
				<ShelfAuditPopup
					shelfAuditId={shelfAuditModal.data?.shelfAuditId}
					clientCompanyId={shelfAuditModal.data?.clientCompanyId}
					isOpen={shelfAuditModal.isOpen}
					fullOpen={shelfAuditModal.fullOpenMode}
					handleResizePopup={() => setShelfAuditModal((prev) => ({
						...prev,
						fullOpenMode: (prev.fullOpenMode !== true)
					}))}
					onClickOut={() => setShelfAuditModal({ isOpen: false })}
					popupStyle={{ animate: true, width: '450px' }}
					popupMode={shelfAuditModal.fullOpenMode ? PopupMode.Default : PopupMode.Details}
					isChild={false}
				/>
				<InstancePopup modalState={formModalState} isOpen={formModalState.isOpen}
					onClickOut={() => setFormModalState({ ...formModalState, isOpen: false, })}/>
			</div>
		</Restricted>
	);
}