/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.Row = (function() {

    /**
     * Properties of a Row.
     * @exports IRow
     * @interface IRow
     * @property {number|null} [companyId] Row companyId
     * @property {string|null} [name] Row name
     * @property {string|null} [mail] Row mail
     * @property {string|null} [phone] Row phone
     * @property {string|null} [website] Row website
     * @property {string|null} [companyAddress] Row companyAddress
     * @property {string|null} [companyPostcode] Row companyPostcode
     * @property {string|null} [companyCity] Row companyCity
     * @property {string|null} [companyCountry] Row companyCountry
     * @property {number|null} [ownerId] Row ownerId
     * @property {number|null} [statusId] Row statusId
     * @property {Array.<number>|null} [tagIds] Row tagIds
     * @property {google.protobuf.ITimestamp|null} [createdAt] Row createdAt
     * @property {google.protobuf.ITimestamp|null} [updatedAt] Row updatedAt
     * @property {number|null} [createdBy] Row createdBy
     * @property {number|null} [latitude] Row latitude
     * @property {number|null} [longitude] Row longitude
     * @property {number|null} [updatedBy] Row updatedBy
     * @property {number|null} [parentId] Row parentId
     * @property {string|null} [parentName] Row parentName
     * @property {number|null} [dn] Row dn
     * @property {google.protobuf.ITimestamp|null} [lastShelfAuditDate] Row lastShelfAuditDate
     * @property {number|null} [latestShelfAuditId] Row latestShelfAuditId
     * @property {IEvent|null} [nextEventDate] Row nextEventDate
     * @property {IEvent|null} [latestEventDate] Row latestEventDate
     * @property {IFrequency|null} [frequency] Row frequency
     * @property {string|null} [externalId] Row externalId
     * @property {ICheckoutEvolution|null} [checkoutEvolution] Row checkoutEvolution
     * @property {Array.<number>|null} [shelfAuditTemplates] Row shelfAuditTemplates
     * @property {Object.<string,IAdditionalColumnsSubValue>|null} [additionalColumnsValue] Row additionalColumnsValue
     * @property {Object.<string,IAdditionalColumnsSubValue>|null} [additionalFieldColumnsValue] Row additionalFieldColumnsValue
     * @property {IUuidAndDate|null} [lastFormDate] Row lastFormDate
     * @property {Object.<string,IAdditionalColumnsSubValue>|null} [calculatedFieldColumnsValue] Row calculatedFieldColumnsValue
     */

    /**
     * Constructs a new Row.
     * @exports Row
     * @classdesc Represents a Row.
     * @implements IRow
     * @constructor
     * @param {IRow=} [properties] Properties to set
     */
    function Row(properties) {
        this.tagIds = [];
        this.shelfAuditTemplates = [];
        this.additionalColumnsValue = {};
        this.additionalFieldColumnsValue = {};
        this.calculatedFieldColumnsValue = {};
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Row companyId.
     * @member {number} companyId
     * @memberof Row
     * @instance
     */
    Row.prototype.companyId = 0;

    /**
     * Row name.
     * @member {string} name
     * @memberof Row
     * @instance
     */
    Row.prototype.name = "";

    /**
     * Row mail.
     * @member {string} mail
     * @memberof Row
     * @instance
     */
    Row.prototype.mail = "";

    /**
     * Row phone.
     * @member {string} phone
     * @memberof Row
     * @instance
     */
    Row.prototype.phone = "";

    /**
     * Row website.
     * @member {string} website
     * @memberof Row
     * @instance
     */
    Row.prototype.website = "";

    /**
     * Row companyAddress.
     * @member {string} companyAddress
     * @memberof Row
     * @instance
     */
    Row.prototype.companyAddress = "";

    /**
     * Row companyPostcode.
     * @member {string} companyPostcode
     * @memberof Row
     * @instance
     */
    Row.prototype.companyPostcode = "";

    /**
     * Row companyCity.
     * @member {string} companyCity
     * @memberof Row
     * @instance
     */
    Row.prototype.companyCity = "";

    /**
     * Row companyCountry.
     * @member {string} companyCountry
     * @memberof Row
     * @instance
     */
    Row.prototype.companyCountry = "";

    /**
     * Row ownerId.
     * @member {number} ownerId
     * @memberof Row
     * @instance
     */
    Row.prototype.ownerId = 0;

    /**
     * Row statusId.
     * @member {number} statusId
     * @memberof Row
     * @instance
     */
    Row.prototype.statusId = 0;

    /**
     * Row tagIds.
     * @member {Array.<number>} tagIds
     * @memberof Row
     * @instance
     */
    Row.prototype.tagIds = $util.emptyArray;

    /**
     * Row createdAt.
     * @member {google.protobuf.ITimestamp|null|undefined} createdAt
     * @memberof Row
     * @instance
     */
    Row.prototype.createdAt = null;

    /**
     * Row updatedAt.
     * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
     * @memberof Row
     * @instance
     */
    Row.prototype.updatedAt = null;

    /**
     * Row createdBy.
     * @member {number} createdBy
     * @memberof Row
     * @instance
     */
    Row.prototype.createdBy = 0;

    /**
     * Row latitude.
     * @member {number} latitude
     * @memberof Row
     * @instance
     */
    Row.prototype.latitude = 0;

    /**
     * Row longitude.
     * @member {number} longitude
     * @memberof Row
     * @instance
     */
    Row.prototype.longitude = 0;

    /**
     * Row updatedBy.
     * @member {number} updatedBy
     * @memberof Row
     * @instance
     */
    Row.prototype.updatedBy = 0;

    /**
     * Row parentId.
     * @member {number} parentId
     * @memberof Row
     * @instance
     */
    Row.prototype.parentId = 0;

    /**
     * Row parentName.
     * @member {string} parentName
     * @memberof Row
     * @instance
     */
    Row.prototype.parentName = "";

    /**
     * Row dn.
     * @member {number} dn
     * @memberof Row
     * @instance
     */
    Row.prototype.dn = 0;

    /**
     * Row lastShelfAuditDate.
     * @member {google.protobuf.ITimestamp|null|undefined} lastShelfAuditDate
     * @memberof Row
     * @instance
     */
    Row.prototype.lastShelfAuditDate = null;

    /**
     * Row latestShelfAuditId.
     * @member {number} latestShelfAuditId
     * @memberof Row
     * @instance
     */
    Row.prototype.latestShelfAuditId = 0;

    /**
     * Row nextEventDate.
     * @member {IEvent|null|undefined} nextEventDate
     * @memberof Row
     * @instance
     */
    Row.prototype.nextEventDate = null;

    /**
     * Row latestEventDate.
     * @member {IEvent|null|undefined} latestEventDate
     * @memberof Row
     * @instance
     */
    Row.prototype.latestEventDate = null;

    /**
     * Row frequency.
     * @member {IFrequency|null|undefined} frequency
     * @memberof Row
     * @instance
     */
    Row.prototype.frequency = null;

    /**
     * Row externalId.
     * @member {string} externalId
     * @memberof Row
     * @instance
     */
    Row.prototype.externalId = "";

    /**
     * Row checkoutEvolution.
     * @member {ICheckoutEvolution|null|undefined} checkoutEvolution
     * @memberof Row
     * @instance
     */
    Row.prototype.checkoutEvolution = null;

    /**
     * Row shelfAuditTemplates.
     * @member {Array.<number>} shelfAuditTemplates
     * @memberof Row
     * @instance
     */
    Row.prototype.shelfAuditTemplates = $util.emptyArray;

    /**
     * Row additionalColumnsValue.
     * @member {Object.<string,IAdditionalColumnsSubValue>} additionalColumnsValue
     * @memberof Row
     * @instance
     */
    Row.prototype.additionalColumnsValue = $util.emptyObject;

    /**
     * Row additionalFieldColumnsValue.
     * @member {Object.<string,IAdditionalColumnsSubValue>} additionalFieldColumnsValue
     * @memberof Row
     * @instance
     */
    Row.prototype.additionalFieldColumnsValue = $util.emptyObject;

    /**
     * Row lastFormDate.
     * @member {IUuidAndDate|null|undefined} lastFormDate
     * @memberof Row
     * @instance
     */
    Row.prototype.lastFormDate = null;

    /**
     * Row calculatedFieldColumnsValue.
     * @member {Object.<string,IAdditionalColumnsSubValue>} calculatedFieldColumnsValue
     * @memberof Row
     * @instance
     */
    Row.prototype.calculatedFieldColumnsValue = $util.emptyObject;

    /**
     * Creates a new Row instance using the specified properties.
     * @function create
     * @memberof Row
     * @static
     * @param {IRow=} [properties] Properties to set
     * @returns {Row} Row instance
     */
    Row.create = function create(properties) {
        return new Row(properties);
    };

    /**
     * Encodes the specified Row message. Does not implicitly {@link Row.verify|verify} messages.
     * @function encode
     * @memberof Row
     * @static
     * @param {IRow} message Row message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Row.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.companyId);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.mail != null && Object.hasOwnProperty.call(message, "mail"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.mail);
        if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
        if (message.website != null && Object.hasOwnProperty.call(message, "website"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.website);
        if (message.companyAddress != null && Object.hasOwnProperty.call(message, "companyAddress"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.companyAddress);
        if (message.companyPostcode != null && Object.hasOwnProperty.call(message, "companyPostcode"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.companyPostcode);
        if (message.companyCity != null && Object.hasOwnProperty.call(message, "companyCity"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.companyCity);
        if (message.companyCountry != null && Object.hasOwnProperty.call(message, "companyCountry"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.companyCountry);
        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.ownerId);
        if (message.statusId != null && Object.hasOwnProperty.call(message, "statusId"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.statusId);
        if (message.tagIds != null && message.tagIds.length) {
            writer.uint32(/* id 12, wireType 2 =*/98).fork();
            for (var i = 0; i < message.tagIds.length; ++i)
                writer.int32(message.tagIds[i]);
            writer.ldelim();
        }
        if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
            $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
            $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy"))
            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.createdBy);
        if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
            writer.uint32(/* id 16, wireType 1 =*/129).double(message.latitude);
        if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
            writer.uint32(/* id 17, wireType 1 =*/137).double(message.longitude);
        if (message.updatedBy != null && Object.hasOwnProperty.call(message, "updatedBy"))
            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.updatedBy);
        if (message.parentId != null && Object.hasOwnProperty.call(message, "parentId"))
            writer.uint32(/* id 23, wireType 0 =*/184).int32(message.parentId);
        if (message.parentName != null && Object.hasOwnProperty.call(message, "parentName"))
            writer.uint32(/* id 24, wireType 2 =*/194).string(message.parentName);
        if (message.dn != null && Object.hasOwnProperty.call(message, "dn"))
            writer.uint32(/* id 26, wireType 5 =*/213).float(message.dn);
        if (message.lastShelfAuditDate != null && Object.hasOwnProperty.call(message, "lastShelfAuditDate"))
            $root.google.protobuf.Timestamp.encode(message.lastShelfAuditDate, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
        if (message.latestShelfAuditId != null && Object.hasOwnProperty.call(message, "latestShelfAuditId"))
            writer.uint32(/* id 29, wireType 0 =*/232).int32(message.latestShelfAuditId);
        if (message.nextEventDate != null && Object.hasOwnProperty.call(message, "nextEventDate"))
            $root.Event.encode(message.nextEventDate, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.latestEventDate != null && Object.hasOwnProperty.call(message, "latestEventDate"))
            $root.Event.encode(message.latestEventDate, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        if (message.frequency != null && Object.hasOwnProperty.call(message, "frequency"))
            $root.Frequency.encode(message.frequency, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
        if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
            writer.uint32(/* id 33, wireType 2 =*/266).string(message.externalId);
        if (message.checkoutEvolution != null && Object.hasOwnProperty.call(message, "checkoutEvolution"))
            $root.CheckoutEvolution.encode(message.checkoutEvolution, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
        if (message.shelfAuditTemplates != null && message.shelfAuditTemplates.length) {
            writer.uint32(/* id 35, wireType 2 =*/282).fork();
            for (var i = 0; i < message.shelfAuditTemplates.length; ++i)
                writer.int32(message.shelfAuditTemplates[i]);
            writer.ldelim();
        }
        if (message.additionalColumnsValue != null && Object.hasOwnProperty.call(message, "additionalColumnsValue"))
            for (var keys = Object.keys(message.additionalColumnsValue), i = 0; i < keys.length; ++i) {
                writer.uint32(/* id 36, wireType 2 =*/290).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                $root.AdditionalColumnsSubValue.encode(message.additionalColumnsValue[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
            }
        if (message.additionalFieldColumnsValue != null && Object.hasOwnProperty.call(message, "additionalFieldColumnsValue"))
            for (var keys = Object.keys(message.additionalFieldColumnsValue), i = 0; i < keys.length; ++i) {
                writer.uint32(/* id 37, wireType 2 =*/298).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                $root.AdditionalColumnsSubValue.encode(message.additionalFieldColumnsValue[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
            }
        if (message.lastFormDate != null && Object.hasOwnProperty.call(message, "lastFormDate"))
            $root.UuidAndDate.encode(message.lastFormDate, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
        if (message.calculatedFieldColumnsValue != null && Object.hasOwnProperty.call(message, "calculatedFieldColumnsValue"))
            for (var keys = Object.keys(message.calculatedFieldColumnsValue), i = 0; i < keys.length; ++i) {
                writer.uint32(/* id 39, wireType 2 =*/314).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                $root.AdditionalColumnsSubValue.encode(message.calculatedFieldColumnsValue[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
            }
        return writer;
    };

    /**
     * Encodes the specified Row message, length delimited. Does not implicitly {@link Row.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Row
     * @static
     * @param {IRow} message Row message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Row.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Row message from the specified reader or buffer.
     * @function decode
     * @memberof Row
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Row} Row
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Row.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Row(), key, value;
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.companyId = reader.int32();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.mail = reader.string();
                    break;
                }
            case 4: {
                    message.phone = reader.string();
                    break;
                }
            case 5: {
                    message.website = reader.string();
                    break;
                }
            case 6: {
                    message.companyAddress = reader.string();
                    break;
                }
            case 7: {
                    message.companyPostcode = reader.string();
                    break;
                }
            case 8: {
                    message.companyCity = reader.string();
                    break;
                }
            case 9: {
                    message.companyCountry = reader.string();
                    break;
                }
            case 10: {
                    message.ownerId = reader.int32();
                    break;
                }
            case 11: {
                    message.statusId = reader.int32();
                    break;
                }
            case 12: {
                    if (!(message.tagIds && message.tagIds.length))
                        message.tagIds = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.tagIds.push(reader.int32());
                    } else
                        message.tagIds.push(reader.int32());
                    break;
                }
            case 13: {
                    message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            case 15: {
                    message.createdBy = reader.int32();
                    break;
                }
            case 16: {
                    message.latitude = reader.double();
                    break;
                }
            case 17: {
                    message.longitude = reader.double();
                    break;
                }
            case 18: {
                    message.updatedBy = reader.int32();
                    break;
                }
            case 23: {
                    message.parentId = reader.int32();
                    break;
                }
            case 24: {
                    message.parentName = reader.string();
                    break;
                }
            case 26: {
                    message.dn = reader.float();
                    break;
                }
            case 27: {
                    message.lastShelfAuditDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            case 29: {
                    message.latestShelfAuditId = reader.int32();
                    break;
                }
            case 30: {
                    message.nextEventDate = $root.Event.decode(reader, reader.uint32());
                    break;
                }
            case 31: {
                    message.latestEventDate = $root.Event.decode(reader, reader.uint32());
                    break;
                }
            case 32: {
                    message.frequency = $root.Frequency.decode(reader, reader.uint32());
                    break;
                }
            case 33: {
                    message.externalId = reader.string();
                    break;
                }
            case 34: {
                    message.checkoutEvolution = $root.CheckoutEvolution.decode(reader, reader.uint32());
                    break;
                }
            case 35: {
                    if (!(message.shelfAuditTemplates && message.shelfAuditTemplates.length))
                        message.shelfAuditTemplates = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.shelfAuditTemplates.push(reader.int32());
                    } else
                        message.shelfAuditTemplates.push(reader.int32());
                    break;
                }
            case 36: {
                    if (message.additionalColumnsValue === $util.emptyObject)
                        message.additionalColumnsValue = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = null;
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = $root.AdditionalColumnsSubValue.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.additionalColumnsValue[key] = value;
                    break;
                }
            case 37: {
                    if (message.additionalFieldColumnsValue === $util.emptyObject)
                        message.additionalFieldColumnsValue = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = null;
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = $root.AdditionalColumnsSubValue.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.additionalFieldColumnsValue[key] = value;
                    break;
                }
            case 38: {
                    message.lastFormDate = $root.UuidAndDate.decode(reader, reader.uint32());
                    break;
                }
            case 39: {
                    if (message.calculatedFieldColumnsValue === $util.emptyObject)
                        message.calculatedFieldColumnsValue = {};
                    var end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = null;
                    while (reader.pos < end2) {
                        var tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = $root.AdditionalColumnsSubValue.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.calculatedFieldColumnsValue[key] = value;
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Row message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Row
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Row} Row
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Row.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Row message.
     * @function verify
     * @memberof Row
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Row.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.companyId != null && message.hasOwnProperty("companyId"))
            if (!$util.isInteger(message.companyId))
                return "companyId: integer expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.mail != null && message.hasOwnProperty("mail"))
            if (!$util.isString(message.mail))
                return "mail: string expected";
        if (message.phone != null && message.hasOwnProperty("phone"))
            if (!$util.isString(message.phone))
                return "phone: string expected";
        if (message.website != null && message.hasOwnProperty("website"))
            if (!$util.isString(message.website))
                return "website: string expected";
        if (message.companyAddress != null && message.hasOwnProperty("companyAddress"))
            if (!$util.isString(message.companyAddress))
                return "companyAddress: string expected";
        if (message.companyPostcode != null && message.hasOwnProperty("companyPostcode"))
            if (!$util.isString(message.companyPostcode))
                return "companyPostcode: string expected";
        if (message.companyCity != null && message.hasOwnProperty("companyCity"))
            if (!$util.isString(message.companyCity))
                return "companyCity: string expected";
        if (message.companyCountry != null && message.hasOwnProperty("companyCountry"))
            if (!$util.isString(message.companyCountry))
                return "companyCountry: string expected";
        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
            if (!$util.isInteger(message.ownerId))
                return "ownerId: integer expected";
        if (message.statusId != null && message.hasOwnProperty("statusId"))
            if (!$util.isInteger(message.statusId))
                return "statusId: integer expected";
        if (message.tagIds != null && message.hasOwnProperty("tagIds")) {
            if (!Array.isArray(message.tagIds))
                return "tagIds: array expected";
            for (var i = 0; i < message.tagIds.length; ++i)
                if (!$util.isInteger(message.tagIds[i]))
                    return "tagIds: integer[] expected";
        }
        if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
            var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
            if (error)
                return "createdAt." + error;
        }
        if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
            var error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
            if (error)
                return "updatedAt." + error;
        }
        if (message.createdBy != null && message.hasOwnProperty("createdBy"))
            if (!$util.isInteger(message.createdBy))
                return "createdBy: integer expected";
        if (message.latitude != null && message.hasOwnProperty("latitude"))
            if (typeof message.latitude !== "number")
                return "latitude: number expected";
        if (message.longitude != null && message.hasOwnProperty("longitude"))
            if (typeof message.longitude !== "number")
                return "longitude: number expected";
        if (message.updatedBy != null && message.hasOwnProperty("updatedBy"))
            if (!$util.isInteger(message.updatedBy))
                return "updatedBy: integer expected";
        if (message.parentId != null && message.hasOwnProperty("parentId"))
            if (!$util.isInteger(message.parentId))
                return "parentId: integer expected";
        if (message.parentName != null && message.hasOwnProperty("parentName"))
            if (!$util.isString(message.parentName))
                return "parentName: string expected";
        if (message.dn != null && message.hasOwnProperty("dn"))
            if (typeof message.dn !== "number")
                return "dn: number expected";
        if (message.lastShelfAuditDate != null && message.hasOwnProperty("lastShelfAuditDate")) {
            var error = $root.google.protobuf.Timestamp.verify(message.lastShelfAuditDate);
            if (error)
                return "lastShelfAuditDate." + error;
        }
        if (message.latestShelfAuditId != null && message.hasOwnProperty("latestShelfAuditId"))
            if (!$util.isInteger(message.latestShelfAuditId))
                return "latestShelfAuditId: integer expected";
        if (message.nextEventDate != null && message.hasOwnProperty("nextEventDate")) {
            var error = $root.Event.verify(message.nextEventDate);
            if (error)
                return "nextEventDate." + error;
        }
        if (message.latestEventDate != null && message.hasOwnProperty("latestEventDate")) {
            var error = $root.Event.verify(message.latestEventDate);
            if (error)
                return "latestEventDate." + error;
        }
        if (message.frequency != null && message.hasOwnProperty("frequency")) {
            var error = $root.Frequency.verify(message.frequency);
            if (error)
                return "frequency." + error;
        }
        if (message.externalId != null && message.hasOwnProperty("externalId"))
            if (!$util.isString(message.externalId))
                return "externalId: string expected";
        if (message.checkoutEvolution != null && message.hasOwnProperty("checkoutEvolution")) {
            var error = $root.CheckoutEvolution.verify(message.checkoutEvolution);
            if (error)
                return "checkoutEvolution." + error;
        }
        if (message.shelfAuditTemplates != null && message.hasOwnProperty("shelfAuditTemplates")) {
            if (!Array.isArray(message.shelfAuditTemplates))
                return "shelfAuditTemplates: array expected";
            for (var i = 0; i < message.shelfAuditTemplates.length; ++i)
                if (!$util.isInteger(message.shelfAuditTemplates[i]))
                    return "shelfAuditTemplates: integer[] expected";
        }
        if (message.additionalColumnsValue != null && message.hasOwnProperty("additionalColumnsValue")) {
            if (!$util.isObject(message.additionalColumnsValue))
                return "additionalColumnsValue: object expected";
            var key = Object.keys(message.additionalColumnsValue);
            for (var i = 0; i < key.length; ++i) {
                var error = $root.AdditionalColumnsSubValue.verify(message.additionalColumnsValue[key[i]]);
                if (error)
                    return "additionalColumnsValue." + error;
            }
        }
        if (message.additionalFieldColumnsValue != null && message.hasOwnProperty("additionalFieldColumnsValue")) {
            if (!$util.isObject(message.additionalFieldColumnsValue))
                return "additionalFieldColumnsValue: object expected";
            var key = Object.keys(message.additionalFieldColumnsValue);
            for (var i = 0; i < key.length; ++i) {
                var error = $root.AdditionalColumnsSubValue.verify(message.additionalFieldColumnsValue[key[i]]);
                if (error)
                    return "additionalFieldColumnsValue." + error;
            }
        }
        if (message.lastFormDate != null && message.hasOwnProperty("lastFormDate")) {
            var error = $root.UuidAndDate.verify(message.lastFormDate);
            if (error)
                return "lastFormDate." + error;
        }
        if (message.calculatedFieldColumnsValue != null && message.hasOwnProperty("calculatedFieldColumnsValue")) {
            if (!$util.isObject(message.calculatedFieldColumnsValue))
                return "calculatedFieldColumnsValue: object expected";
            var key = Object.keys(message.calculatedFieldColumnsValue);
            for (var i = 0; i < key.length; ++i) {
                var error = $root.AdditionalColumnsSubValue.verify(message.calculatedFieldColumnsValue[key[i]]);
                if (error)
                    return "calculatedFieldColumnsValue." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Row message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Row
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Row} Row
     */
    Row.fromObject = function fromObject(object) {
        if (object instanceof $root.Row)
            return object;
        var message = new $root.Row();
        if (object.companyId != null)
            message.companyId = object.companyId | 0;
        if (object.name != null)
            message.name = String(object.name);
        if (object.mail != null)
            message.mail = String(object.mail);
        if (object.phone != null)
            message.phone = String(object.phone);
        if (object.website != null)
            message.website = String(object.website);
        if (object.companyAddress != null)
            message.companyAddress = String(object.companyAddress);
        if (object.companyPostcode != null)
            message.companyPostcode = String(object.companyPostcode);
        if (object.companyCity != null)
            message.companyCity = String(object.companyCity);
        if (object.companyCountry != null)
            message.companyCountry = String(object.companyCountry);
        if (object.ownerId != null)
            message.ownerId = object.ownerId | 0;
        if (object.statusId != null)
            message.statusId = object.statusId | 0;
        if (object.tagIds) {
            if (!Array.isArray(object.tagIds))
                throw TypeError(".Row.tagIds: array expected");
            message.tagIds = [];
            for (var i = 0; i < object.tagIds.length; ++i)
                message.tagIds[i] = object.tagIds[i] | 0;
        }
        if (object.createdAt != null) {
            if (typeof object.createdAt !== "object")
                throw TypeError(".Row.createdAt: object expected");
            message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
        }
        if (object.updatedAt != null) {
            if (typeof object.updatedAt !== "object")
                throw TypeError(".Row.updatedAt: object expected");
            message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
        }
        if (object.createdBy != null)
            message.createdBy = object.createdBy | 0;
        if (object.latitude != null)
            message.latitude = Number(object.latitude);
        if (object.longitude != null)
            message.longitude = Number(object.longitude);
        if (object.updatedBy != null)
            message.updatedBy = object.updatedBy | 0;
        if (object.parentId != null)
            message.parentId = object.parentId | 0;
        if (object.parentName != null)
            message.parentName = String(object.parentName);
        if (object.dn != null)
            message.dn = Number(object.dn);
        if (object.lastShelfAuditDate != null) {
            if (typeof object.lastShelfAuditDate !== "object")
                throw TypeError(".Row.lastShelfAuditDate: object expected");
            message.lastShelfAuditDate = $root.google.protobuf.Timestamp.fromObject(object.lastShelfAuditDate);
        }
        if (object.latestShelfAuditId != null)
            message.latestShelfAuditId = object.latestShelfAuditId | 0;
        if (object.nextEventDate != null) {
            if (typeof object.nextEventDate !== "object")
                throw TypeError(".Row.nextEventDate: object expected");
            message.nextEventDate = $root.Event.fromObject(object.nextEventDate);
        }
        if (object.latestEventDate != null) {
            if (typeof object.latestEventDate !== "object")
                throw TypeError(".Row.latestEventDate: object expected");
            message.latestEventDate = $root.Event.fromObject(object.latestEventDate);
        }
        if (object.frequency != null) {
            if (typeof object.frequency !== "object")
                throw TypeError(".Row.frequency: object expected");
            message.frequency = $root.Frequency.fromObject(object.frequency);
        }
        if (object.externalId != null)
            message.externalId = String(object.externalId);
        if (object.checkoutEvolution != null) {
            if (typeof object.checkoutEvolution !== "object")
                throw TypeError(".Row.checkoutEvolution: object expected");
            message.checkoutEvolution = $root.CheckoutEvolution.fromObject(object.checkoutEvolution);
        }
        if (object.shelfAuditTemplates) {
            if (!Array.isArray(object.shelfAuditTemplates))
                throw TypeError(".Row.shelfAuditTemplates: array expected");
            message.shelfAuditTemplates = [];
            for (var i = 0; i < object.shelfAuditTemplates.length; ++i)
                message.shelfAuditTemplates[i] = object.shelfAuditTemplates[i] | 0;
        }
        if (object.additionalColumnsValue) {
            if (typeof object.additionalColumnsValue !== "object")
                throw TypeError(".Row.additionalColumnsValue: object expected");
            message.additionalColumnsValue = {};
            for (var keys = Object.keys(object.additionalColumnsValue), i = 0; i < keys.length; ++i) {
                if (typeof object.additionalColumnsValue[keys[i]] !== "object")
                    throw TypeError(".Row.additionalColumnsValue: object expected");
                message.additionalColumnsValue[keys[i]] = $root.AdditionalColumnsSubValue.fromObject(object.additionalColumnsValue[keys[i]]);
            }
        }
        if (object.additionalFieldColumnsValue) {
            if (typeof object.additionalFieldColumnsValue !== "object")
                throw TypeError(".Row.additionalFieldColumnsValue: object expected");
            message.additionalFieldColumnsValue = {};
            for (var keys = Object.keys(object.additionalFieldColumnsValue), i = 0; i < keys.length; ++i) {
                if (typeof object.additionalFieldColumnsValue[keys[i]] !== "object")
                    throw TypeError(".Row.additionalFieldColumnsValue: object expected");
                message.additionalFieldColumnsValue[keys[i]] = $root.AdditionalColumnsSubValue.fromObject(object.additionalFieldColumnsValue[keys[i]]);
            }
        }
        if (object.lastFormDate != null) {
            if (typeof object.lastFormDate !== "object")
                throw TypeError(".Row.lastFormDate: object expected");
            message.lastFormDate = $root.UuidAndDate.fromObject(object.lastFormDate);
        }
        if (object.calculatedFieldColumnsValue) {
            if (typeof object.calculatedFieldColumnsValue !== "object")
                throw TypeError(".Row.calculatedFieldColumnsValue: object expected");
            message.calculatedFieldColumnsValue = {};
            for (var keys = Object.keys(object.calculatedFieldColumnsValue), i = 0; i < keys.length; ++i) {
                if (typeof object.calculatedFieldColumnsValue[keys[i]] !== "object")
                    throw TypeError(".Row.calculatedFieldColumnsValue: object expected");
                message.calculatedFieldColumnsValue[keys[i]] = $root.AdditionalColumnsSubValue.fromObject(object.calculatedFieldColumnsValue[keys[i]]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Row message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Row
     * @static
     * @param {Row} message Row
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Row.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.tagIds = [];
            object.shelfAuditTemplates = [];
        }
        if (options.objects || options.defaults) {
            object.additionalColumnsValue = {};
            object.additionalFieldColumnsValue = {};
            object.calculatedFieldColumnsValue = {};
        }
        if (options.defaults) {
            object.companyId = 0;
            object.name = "";
            object.mail = "";
            object.phone = "";
            object.website = "";
            object.companyAddress = "";
            object.companyPostcode = "";
            object.companyCity = "";
            object.companyCountry = "";
            object.ownerId = 0;
            object.statusId = 0;
            object.createdAt = null;
            object.updatedAt = null;
            object.createdBy = 0;
            object.latitude = 0;
            object.longitude = 0;
            object.updatedBy = 0;
            object.parentId = 0;
            object.parentName = "";
            object.dn = 0;
            object.lastShelfAuditDate = null;
            object.latestShelfAuditId = 0;
            object.nextEventDate = null;
            object.latestEventDate = null;
            object.frequency = null;
            object.externalId = "";
            object.checkoutEvolution = null;
            object.lastFormDate = null;
        }
        if (message.companyId != null && message.hasOwnProperty("companyId"))
            object.companyId = message.companyId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.mail != null && message.hasOwnProperty("mail"))
            object.mail = message.mail;
        if (message.phone != null && message.hasOwnProperty("phone"))
            object.phone = message.phone;
        if (message.website != null && message.hasOwnProperty("website"))
            object.website = message.website;
        if (message.companyAddress != null && message.hasOwnProperty("companyAddress"))
            object.companyAddress = message.companyAddress;
        if (message.companyPostcode != null && message.hasOwnProperty("companyPostcode"))
            object.companyPostcode = message.companyPostcode;
        if (message.companyCity != null && message.hasOwnProperty("companyCity"))
            object.companyCity = message.companyCity;
        if (message.companyCountry != null && message.hasOwnProperty("companyCountry"))
            object.companyCountry = message.companyCountry;
        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
            object.ownerId = message.ownerId;
        if (message.statusId != null && message.hasOwnProperty("statusId"))
            object.statusId = message.statusId;
        if (message.tagIds && message.tagIds.length) {
            object.tagIds = [];
            for (var j = 0; j < message.tagIds.length; ++j)
                object.tagIds[j] = message.tagIds[j];
        }
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
        if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
            object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
        if (message.createdBy != null && message.hasOwnProperty("createdBy"))
            object.createdBy = message.createdBy;
        if (message.latitude != null && message.hasOwnProperty("latitude"))
            object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
        if (message.longitude != null && message.hasOwnProperty("longitude"))
            object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
        if (message.updatedBy != null && message.hasOwnProperty("updatedBy"))
            object.updatedBy = message.updatedBy;
        if (message.parentId != null && message.hasOwnProperty("parentId"))
            object.parentId = message.parentId;
        if (message.parentName != null && message.hasOwnProperty("parentName"))
            object.parentName = message.parentName;
        if (message.dn != null && message.hasOwnProperty("dn"))
            object.dn = options.json && !isFinite(message.dn) ? String(message.dn) : message.dn;
        if (message.lastShelfAuditDate != null && message.hasOwnProperty("lastShelfAuditDate"))
            object.lastShelfAuditDate = $root.google.protobuf.Timestamp.toObject(message.lastShelfAuditDate, options);
        if (message.latestShelfAuditId != null && message.hasOwnProperty("latestShelfAuditId"))
            object.latestShelfAuditId = message.latestShelfAuditId;
        if (message.nextEventDate != null && message.hasOwnProperty("nextEventDate"))
            object.nextEventDate = $root.Event.toObject(message.nextEventDate, options);
        if (message.latestEventDate != null && message.hasOwnProperty("latestEventDate"))
            object.latestEventDate = $root.Event.toObject(message.latestEventDate, options);
        if (message.frequency != null && message.hasOwnProperty("frequency"))
            object.frequency = $root.Frequency.toObject(message.frequency, options);
        if (message.externalId != null && message.hasOwnProperty("externalId"))
            object.externalId = message.externalId;
        if (message.checkoutEvolution != null && message.hasOwnProperty("checkoutEvolution"))
            object.checkoutEvolution = $root.CheckoutEvolution.toObject(message.checkoutEvolution, options);
        if (message.shelfAuditTemplates && message.shelfAuditTemplates.length) {
            object.shelfAuditTemplates = [];
            for (var j = 0; j < message.shelfAuditTemplates.length; ++j)
                object.shelfAuditTemplates[j] = message.shelfAuditTemplates[j];
        }
        var keys2;
        if (message.additionalColumnsValue && (keys2 = Object.keys(message.additionalColumnsValue)).length) {
            object.additionalColumnsValue = {};
            for (var j = 0; j < keys2.length; ++j)
                object.additionalColumnsValue[keys2[j]] = $root.AdditionalColumnsSubValue.toObject(message.additionalColumnsValue[keys2[j]], options);
        }
        if (message.additionalFieldColumnsValue && (keys2 = Object.keys(message.additionalFieldColumnsValue)).length) {
            object.additionalFieldColumnsValue = {};
            for (var j = 0; j < keys2.length; ++j)
                object.additionalFieldColumnsValue[keys2[j]] = $root.AdditionalColumnsSubValue.toObject(message.additionalFieldColumnsValue[keys2[j]], options);
        }
        if (message.lastFormDate != null && message.hasOwnProperty("lastFormDate"))
            object.lastFormDate = $root.UuidAndDate.toObject(message.lastFormDate, options);
        if (message.calculatedFieldColumnsValue && (keys2 = Object.keys(message.calculatedFieldColumnsValue)).length) {
            object.calculatedFieldColumnsValue = {};
            for (var j = 0; j < keys2.length; ++j)
                object.calculatedFieldColumnsValue[keys2[j]] = $root.AdditionalColumnsSubValue.toObject(message.calculatedFieldColumnsValue[keys2[j]], options);
        }
        return object;
    };

    /**
     * Converts this Row to JSON.
     * @function toJSON
     * @memberof Row
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Row.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Row
     * @function getTypeUrl
     * @memberof Row
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Row.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Row";
    };

    return Row;
})();

$root.AdditionalColumnsSubValue = (function() {

    /**
     * Properties of an AdditionalColumnsSubValue.
     * @exports IAdditionalColumnsSubValue
     * @interface IAdditionalColumnsSubValue
     * @property {string|null} [name] AdditionalColumnsSubValue name
     * @property {string|null} [value] AdditionalColumnsSubValue value
     */

    /**
     * Constructs a new AdditionalColumnsSubValue.
     * @exports AdditionalColumnsSubValue
     * @classdesc Represents an AdditionalColumnsSubValue.
     * @implements IAdditionalColumnsSubValue
     * @constructor
     * @param {IAdditionalColumnsSubValue=} [properties] Properties to set
     */
    function AdditionalColumnsSubValue(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdditionalColumnsSubValue name.
     * @member {string} name
     * @memberof AdditionalColumnsSubValue
     * @instance
     */
    AdditionalColumnsSubValue.prototype.name = "";

    /**
     * AdditionalColumnsSubValue value.
     * @member {string} value
     * @memberof AdditionalColumnsSubValue
     * @instance
     */
    AdditionalColumnsSubValue.prototype.value = "";

    /**
     * Creates a new AdditionalColumnsSubValue instance using the specified properties.
     * @function create
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {IAdditionalColumnsSubValue=} [properties] Properties to set
     * @returns {AdditionalColumnsSubValue} AdditionalColumnsSubValue instance
     */
    AdditionalColumnsSubValue.create = function create(properties) {
        return new AdditionalColumnsSubValue(properties);
    };

    /**
     * Encodes the specified AdditionalColumnsSubValue message. Does not implicitly {@link AdditionalColumnsSubValue.verify|verify} messages.
     * @function encode
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {IAdditionalColumnsSubValue} message AdditionalColumnsSubValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdditionalColumnsSubValue.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        return writer;
    };

    /**
     * Encodes the specified AdditionalColumnsSubValue message, length delimited. Does not implicitly {@link AdditionalColumnsSubValue.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {IAdditionalColumnsSubValue} message AdditionalColumnsSubValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdditionalColumnsSubValue.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdditionalColumnsSubValue message from the specified reader or buffer.
     * @function decode
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdditionalColumnsSubValue} AdditionalColumnsSubValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdditionalColumnsSubValue.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdditionalColumnsSubValue();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    message.value = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdditionalColumnsSubValue message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdditionalColumnsSubValue} AdditionalColumnsSubValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdditionalColumnsSubValue.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdditionalColumnsSubValue message.
     * @function verify
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdditionalColumnsSubValue.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        return null;
    };

    /**
     * Creates an AdditionalColumnsSubValue message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdditionalColumnsSubValue} AdditionalColumnsSubValue
     */
    AdditionalColumnsSubValue.fromObject = function fromObject(object) {
        if (object instanceof $root.AdditionalColumnsSubValue)
            return object;
        var message = new $root.AdditionalColumnsSubValue();
        if (object.name != null)
            message.name = String(object.name);
        if (object.value != null)
            message.value = String(object.value);
        return message;
    };

    /**
     * Creates a plain object from an AdditionalColumnsSubValue message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {AdditionalColumnsSubValue} message AdditionalColumnsSubValue
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdditionalColumnsSubValue.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.name = "";
            object.value = "";
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        return object;
    };

    /**
     * Converts this AdditionalColumnsSubValue to JSON.
     * @function toJSON
     * @memberof AdditionalColumnsSubValue
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdditionalColumnsSubValue.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdditionalColumnsSubValue
     * @function getTypeUrl
     * @memberof AdditionalColumnsSubValue
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdditionalColumnsSubValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdditionalColumnsSubValue";
    };

    return AdditionalColumnsSubValue;
})();

$root.Table = (function() {

    /**
     * Properties of a Table.
     * @exports ITable
     * @interface ITable
     * @property {number|Long|null} [count] Table count
     * @property {Array.<IRow>|null} [row] Table row
     */

    /**
     * Constructs a new Table.
     * @exports Table
     * @classdesc Represents a Table.
     * @implements ITable
     * @constructor
     * @param {ITable=} [properties] Properties to set
     */
    function Table(properties) {
        this.row = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Table count.
     * @member {number|Long} count
     * @memberof Table
     * @instance
     */
    Table.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Table row.
     * @member {Array.<IRow>} row
     * @memberof Table
     * @instance
     */
    Table.prototype.row = $util.emptyArray;

    /**
     * Creates a new Table instance using the specified properties.
     * @function create
     * @memberof Table
     * @static
     * @param {ITable=} [properties] Properties to set
     * @returns {Table} Table instance
     */
    Table.create = function create(properties) {
        return new Table(properties);
    };

    /**
     * Encodes the specified Table message. Does not implicitly {@link Table.verify|verify} messages.
     * @function encode
     * @memberof Table
     * @static
     * @param {ITable} message Table message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Table.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.count != null && Object.hasOwnProperty.call(message, "count"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.count);
        if (message.row != null && message.row.length)
            for (var i = 0; i < message.row.length; ++i)
                $root.Row.encode(message.row[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Table message, length delimited. Does not implicitly {@link Table.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Table
     * @static
     * @param {ITable} message Table message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Table.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Table message from the specified reader or buffer.
     * @function decode
     * @memberof Table
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Table} Table
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Table.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Table();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.count = reader.int64();
                    break;
                }
            case 2: {
                    if (!(message.row && message.row.length))
                        message.row = [];
                    message.row.push($root.Row.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Table message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Table
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Table} Table
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Table.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Table message.
     * @function verify
     * @memberof Table
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Table.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.count != null && message.hasOwnProperty("count"))
            if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                return "count: integer|Long expected";
        if (message.row != null && message.hasOwnProperty("row")) {
            if (!Array.isArray(message.row))
                return "row: array expected";
            for (var i = 0; i < message.row.length; ++i) {
                var error = $root.Row.verify(message.row[i]);
                if (error)
                    return "row." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Table message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Table
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Table} Table
     */
    Table.fromObject = function fromObject(object) {
        if (object instanceof $root.Table)
            return object;
        var message = new $root.Table();
        if (object.count != null)
            if ($util.Long)
                (message.count = $util.Long.fromValue(object.count)).unsigned = false;
            else if (typeof object.count === "string")
                message.count = parseInt(object.count, 10);
            else if (typeof object.count === "number")
                message.count = object.count;
            else if (typeof object.count === "object")
                message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
        if (object.row) {
            if (!Array.isArray(object.row))
                throw TypeError(".Table.row: array expected");
            message.row = [];
            for (var i = 0; i < object.row.length; ++i) {
                if (typeof object.row[i] !== "object")
                    throw TypeError(".Table.row: object expected");
                message.row[i] = $root.Row.fromObject(object.row[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Table message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Table
     * @static
     * @param {Table} message Table
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Table.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.row = [];
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.count = options.longs === String ? "0" : 0;
        if (message.count != null && message.hasOwnProperty("count"))
            if (typeof message.count === "number")
                object.count = options.longs === String ? String(message.count) : message.count;
            else
                object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
        if (message.row && message.row.length) {
            object.row = [];
            for (var j = 0; j < message.row.length; ++j)
                object.row[j] = $root.Row.toObject(message.row[j], options);
        }
        return object;
    };

    /**
     * Converts this Table to JSON.
     * @function toJSON
     * @memberof Table
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Table.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Table
     * @function getTypeUrl
     * @memberof Table
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Table.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Table";
    };

    return Table;
})();

$root.Frequency = (function() {

    /**
     * Properties of a Frequency.
     * @exports IFrequency
     * @interface IFrequency
     * @property {number|null} [count] Frequency count
     * @property {number|null} [score] Frequency score
     * @property {number|null} [target] Frequency target
     */

    /**
     * Constructs a new Frequency.
     * @exports Frequency
     * @classdesc Represents a Frequency.
     * @implements IFrequency
     * @constructor
     * @param {IFrequency=} [properties] Properties to set
     */
    function Frequency(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Frequency count.
     * @member {number} count
     * @memberof Frequency
     * @instance
     */
    Frequency.prototype.count = 0;

    /**
     * Frequency score.
     * @member {number} score
     * @memberof Frequency
     * @instance
     */
    Frequency.prototype.score = 0;

    /**
     * Frequency target.
     * @member {number} target
     * @memberof Frequency
     * @instance
     */
    Frequency.prototype.target = 0;

    /**
     * Creates a new Frequency instance using the specified properties.
     * @function create
     * @memberof Frequency
     * @static
     * @param {IFrequency=} [properties] Properties to set
     * @returns {Frequency} Frequency instance
     */
    Frequency.create = function create(properties) {
        return new Frequency(properties);
    };

    /**
     * Encodes the specified Frequency message. Does not implicitly {@link Frequency.verify|verify} messages.
     * @function encode
     * @memberof Frequency
     * @static
     * @param {IFrequency} message Frequency message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Frequency.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.count != null && Object.hasOwnProperty.call(message, "count"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
        if (message.score != null && Object.hasOwnProperty.call(message, "score"))
            writer.uint32(/* id 2, wireType 5 =*/21).float(message.score);
        if (message.target != null && Object.hasOwnProperty.call(message, "target"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.target);
        return writer;
    };

    /**
     * Encodes the specified Frequency message, length delimited. Does not implicitly {@link Frequency.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Frequency
     * @static
     * @param {IFrequency} message Frequency message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Frequency.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Frequency message from the specified reader or buffer.
     * @function decode
     * @memberof Frequency
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Frequency} Frequency
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Frequency.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Frequency();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.count = reader.int32();
                    break;
                }
            case 2: {
                    message.score = reader.float();
                    break;
                }
            case 3: {
                    message.target = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Frequency message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Frequency
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Frequency} Frequency
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Frequency.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Frequency message.
     * @function verify
     * @memberof Frequency
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Frequency.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.count != null && message.hasOwnProperty("count"))
            if (!$util.isInteger(message.count))
                return "count: integer expected";
        if (message.score != null && message.hasOwnProperty("score"))
            if (typeof message.score !== "number")
                return "score: number expected";
        if (message.target != null && message.hasOwnProperty("target"))
            if (!$util.isInteger(message.target))
                return "target: integer expected";
        return null;
    };

    /**
     * Creates a Frequency message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Frequency
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Frequency} Frequency
     */
    Frequency.fromObject = function fromObject(object) {
        if (object instanceof $root.Frequency)
            return object;
        var message = new $root.Frequency();
        if (object.count != null)
            message.count = object.count | 0;
        if (object.score != null)
            message.score = Number(object.score);
        if (object.target != null)
            message.target = object.target | 0;
        return message;
    };

    /**
     * Creates a plain object from a Frequency message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Frequency
     * @static
     * @param {Frequency} message Frequency
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Frequency.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.count = 0;
            object.score = 0;
            object.target = 0;
        }
        if (message.count != null && message.hasOwnProperty("count"))
            object.count = message.count;
        if (message.score != null && message.hasOwnProperty("score"))
            object.score = options.json && !isFinite(message.score) ? String(message.score) : message.score;
        if (message.target != null && message.hasOwnProperty("target"))
            object.target = message.target;
        return object;
    };

    /**
     * Converts this Frequency to JSON.
     * @function toJSON
     * @memberof Frequency
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Frequency.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Frequency
     * @function getTypeUrl
     * @memberof Frequency
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Frequency.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Frequency";
    };

    return Frequency;
})();

$root.CheckoutEvolution = (function() {

    /**
     * Properties of a CheckoutEvolution.
     * @exports ICheckoutEvolution
     * @interface ICheckoutEvolution
     * @property {number|null} [currentSum] CheckoutEvolution currentSum
     * @property {number|null} [previousSum] CheckoutEvolution previousSum
     * @property {number|null} [evolution] CheckoutEvolution evolution
     * @property {number|null} [currentUvcSum] CheckoutEvolution currentUvcSum
     * @property {number|null} [previousUvcSum] CheckoutEvolution previousUvcSum
     * @property {number|null} [uvcEvolution] CheckoutEvolution uvcEvolution
     */

    /**
     * Constructs a new CheckoutEvolution.
     * @exports CheckoutEvolution
     * @classdesc Represents a CheckoutEvolution.
     * @implements ICheckoutEvolution
     * @constructor
     * @param {ICheckoutEvolution=} [properties] Properties to set
     */
    function CheckoutEvolution(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckoutEvolution currentSum.
     * @member {number} currentSum
     * @memberof CheckoutEvolution
     * @instance
     */
    CheckoutEvolution.prototype.currentSum = 0;

    /**
     * CheckoutEvolution previousSum.
     * @member {number} previousSum
     * @memberof CheckoutEvolution
     * @instance
     */
    CheckoutEvolution.prototype.previousSum = 0;

    /**
     * CheckoutEvolution evolution.
     * @member {number} evolution
     * @memberof CheckoutEvolution
     * @instance
     */
    CheckoutEvolution.prototype.evolution = 0;

    /**
     * CheckoutEvolution currentUvcSum.
     * @member {number} currentUvcSum
     * @memberof CheckoutEvolution
     * @instance
     */
    CheckoutEvolution.prototype.currentUvcSum = 0;

    /**
     * CheckoutEvolution previousUvcSum.
     * @member {number} previousUvcSum
     * @memberof CheckoutEvolution
     * @instance
     */
    CheckoutEvolution.prototype.previousUvcSum = 0;

    /**
     * CheckoutEvolution uvcEvolution.
     * @member {number} uvcEvolution
     * @memberof CheckoutEvolution
     * @instance
     */
    CheckoutEvolution.prototype.uvcEvolution = 0;

    /**
     * Creates a new CheckoutEvolution instance using the specified properties.
     * @function create
     * @memberof CheckoutEvolution
     * @static
     * @param {ICheckoutEvolution=} [properties] Properties to set
     * @returns {CheckoutEvolution} CheckoutEvolution instance
     */
    CheckoutEvolution.create = function create(properties) {
        return new CheckoutEvolution(properties);
    };

    /**
     * Encodes the specified CheckoutEvolution message. Does not implicitly {@link CheckoutEvolution.verify|verify} messages.
     * @function encode
     * @memberof CheckoutEvolution
     * @static
     * @param {ICheckoutEvolution} message CheckoutEvolution message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckoutEvolution.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.currentSum != null && Object.hasOwnProperty.call(message, "currentSum"))
            writer.uint32(/* id 1, wireType 5 =*/13).float(message.currentSum);
        if (message.previousSum != null && Object.hasOwnProperty.call(message, "previousSum"))
            writer.uint32(/* id 2, wireType 5 =*/21).float(message.previousSum);
        if (message.evolution != null && Object.hasOwnProperty.call(message, "evolution"))
            writer.uint32(/* id 3, wireType 5 =*/29).float(message.evolution);
        if (message.currentUvcSum != null && Object.hasOwnProperty.call(message, "currentUvcSum"))
            writer.uint32(/* id 4, wireType 5 =*/37).float(message.currentUvcSum);
        if (message.previousUvcSum != null && Object.hasOwnProperty.call(message, "previousUvcSum"))
            writer.uint32(/* id 5, wireType 5 =*/45).float(message.previousUvcSum);
        if (message.uvcEvolution != null && Object.hasOwnProperty.call(message, "uvcEvolution"))
            writer.uint32(/* id 6, wireType 5 =*/53).float(message.uvcEvolution);
        return writer;
    };

    /**
     * Encodes the specified CheckoutEvolution message, length delimited. Does not implicitly {@link CheckoutEvolution.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckoutEvolution
     * @static
     * @param {ICheckoutEvolution} message CheckoutEvolution message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckoutEvolution.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckoutEvolution message from the specified reader or buffer.
     * @function decode
     * @memberof CheckoutEvolution
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckoutEvolution} CheckoutEvolution
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckoutEvolution.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckoutEvolution();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.currentSum = reader.float();
                    break;
                }
            case 2: {
                    message.previousSum = reader.float();
                    break;
                }
            case 3: {
                    message.evolution = reader.float();
                    break;
                }
            case 4: {
                    message.currentUvcSum = reader.float();
                    break;
                }
            case 5: {
                    message.previousUvcSum = reader.float();
                    break;
                }
            case 6: {
                    message.uvcEvolution = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckoutEvolution message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckoutEvolution
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckoutEvolution} CheckoutEvolution
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckoutEvolution.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckoutEvolution message.
     * @function verify
     * @memberof CheckoutEvolution
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckoutEvolution.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.currentSum != null && message.hasOwnProperty("currentSum"))
            if (typeof message.currentSum !== "number")
                return "currentSum: number expected";
        if (message.previousSum != null && message.hasOwnProperty("previousSum"))
            if (typeof message.previousSum !== "number")
                return "previousSum: number expected";
        if (message.evolution != null && message.hasOwnProperty("evolution"))
            if (typeof message.evolution !== "number")
                return "evolution: number expected";
        if (message.currentUvcSum != null && message.hasOwnProperty("currentUvcSum"))
            if (typeof message.currentUvcSum !== "number")
                return "currentUvcSum: number expected";
        if (message.previousUvcSum != null && message.hasOwnProperty("previousUvcSum"))
            if (typeof message.previousUvcSum !== "number")
                return "previousUvcSum: number expected";
        if (message.uvcEvolution != null && message.hasOwnProperty("uvcEvolution"))
            if (typeof message.uvcEvolution !== "number")
                return "uvcEvolution: number expected";
        return null;
    };

    /**
     * Creates a CheckoutEvolution message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckoutEvolution
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckoutEvolution} CheckoutEvolution
     */
    CheckoutEvolution.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckoutEvolution)
            return object;
        var message = new $root.CheckoutEvolution();
        if (object.currentSum != null)
            message.currentSum = Number(object.currentSum);
        if (object.previousSum != null)
            message.previousSum = Number(object.previousSum);
        if (object.evolution != null)
            message.evolution = Number(object.evolution);
        if (object.currentUvcSum != null)
            message.currentUvcSum = Number(object.currentUvcSum);
        if (object.previousUvcSum != null)
            message.previousUvcSum = Number(object.previousUvcSum);
        if (object.uvcEvolution != null)
            message.uvcEvolution = Number(object.uvcEvolution);
        return message;
    };

    /**
     * Creates a plain object from a CheckoutEvolution message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckoutEvolution
     * @static
     * @param {CheckoutEvolution} message CheckoutEvolution
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckoutEvolution.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.currentSum = 0;
            object.previousSum = 0;
            object.evolution = 0;
            object.currentUvcSum = 0;
            object.previousUvcSum = 0;
            object.uvcEvolution = 0;
        }
        if (message.currentSum != null && message.hasOwnProperty("currentSum"))
            object.currentSum = options.json && !isFinite(message.currentSum) ? String(message.currentSum) : message.currentSum;
        if (message.previousSum != null && message.hasOwnProperty("previousSum"))
            object.previousSum = options.json && !isFinite(message.previousSum) ? String(message.previousSum) : message.previousSum;
        if (message.evolution != null && message.hasOwnProperty("evolution"))
            object.evolution = options.json && !isFinite(message.evolution) ? String(message.evolution) : message.evolution;
        if (message.currentUvcSum != null && message.hasOwnProperty("currentUvcSum"))
            object.currentUvcSum = options.json && !isFinite(message.currentUvcSum) ? String(message.currentUvcSum) : message.currentUvcSum;
        if (message.previousUvcSum != null && message.hasOwnProperty("previousUvcSum"))
            object.previousUvcSum = options.json && !isFinite(message.previousUvcSum) ? String(message.previousUvcSum) : message.previousUvcSum;
        if (message.uvcEvolution != null && message.hasOwnProperty("uvcEvolution"))
            object.uvcEvolution = options.json && !isFinite(message.uvcEvolution) ? String(message.uvcEvolution) : message.uvcEvolution;
        return object;
    };

    /**
     * Converts this CheckoutEvolution to JSON.
     * @function toJSON
     * @memberof CheckoutEvolution
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckoutEvolution.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckoutEvolution
     * @function getTypeUrl
     * @memberof CheckoutEvolution
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckoutEvolution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckoutEvolution";
    };

    return CheckoutEvolution;
})();

$root.Event = (function() {

    /**
     * Properties of an Event.
     * @exports IEvent
     * @interface IEvent
     * @property {number|null} [id] Event id
     * @property {google.protobuf.ITimestamp|null} [date] Event date
     */

    /**
     * Constructs a new Event.
     * @exports Event
     * @classdesc Represents an Event.
     * @implements IEvent
     * @constructor
     * @param {IEvent=} [properties] Properties to set
     */
    function Event(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Event id.
     * @member {number} id
     * @memberof Event
     * @instance
     */
    Event.prototype.id = 0;

    /**
     * Event date.
     * @member {google.protobuf.ITimestamp|null|undefined} date
     * @memberof Event
     * @instance
     */
    Event.prototype.date = null;

    /**
     * Creates a new Event instance using the specified properties.
     * @function create
     * @memberof Event
     * @static
     * @param {IEvent=} [properties] Properties to set
     * @returns {Event} Event instance
     */
    Event.create = function create(properties) {
        return new Event(properties);
    };

    /**
     * Encodes the specified Event message. Does not implicitly {@link Event.verify|verify} messages.
     * @function encode
     * @memberof Event
     * @static
     * @param {IEvent} message Event message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Event.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
        if (message.date != null && Object.hasOwnProperty.call(message, "date"))
            $root.google.protobuf.Timestamp.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Event message, length delimited. Does not implicitly {@link Event.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Event
     * @static
     * @param {IEvent} message Event message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Event.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Event message from the specified reader or buffer.
     * @function decode
     * @memberof Event
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Event} Event
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Event.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Event();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int32();
                    break;
                }
            case 2: {
                    message.date = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Event message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Event
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Event} Event
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Event.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Event message.
     * @function verify
     * @memberof Event
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Event.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id))
                return "id: integer expected";
        if (message.date != null && message.hasOwnProperty("date")) {
            var error = $root.google.protobuf.Timestamp.verify(message.date);
            if (error)
                return "date." + error;
        }
        return null;
    };

    /**
     * Creates an Event message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Event
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Event} Event
     */
    Event.fromObject = function fromObject(object) {
        if (object instanceof $root.Event)
            return object;
        var message = new $root.Event();
        if (object.id != null)
            message.id = object.id | 0;
        if (object.date != null) {
            if (typeof object.date !== "object")
                throw TypeError(".Event.date: object expected");
            message.date = $root.google.protobuf.Timestamp.fromObject(object.date);
        }
        return message;
    };

    /**
     * Creates a plain object from an Event message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Event
     * @static
     * @param {Event} message Event
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Event.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.id = 0;
            object.date = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.date != null && message.hasOwnProperty("date"))
            object.date = $root.google.protobuf.Timestamp.toObject(message.date, options);
        return object;
    };

    /**
     * Converts this Event to JSON.
     * @function toJSON
     * @memberof Event
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Event.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Event
     * @function getTypeUrl
     * @memberof Event
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Event.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Event";
    };

    return Event;
})();

$root.UuidAndDate = (function() {

    /**
     * Properties of an UuidAndDate.
     * @exports IUuidAndDate
     * @interface IUuidAndDate
     * @property {string|null} [uuid] UuidAndDate uuid
     * @property {google.protobuf.ITimestamp|null} [date] UuidAndDate date
     */

    /**
     * Constructs a new UuidAndDate.
     * @exports UuidAndDate
     * @classdesc Represents an UuidAndDate.
     * @implements IUuidAndDate
     * @constructor
     * @param {IUuidAndDate=} [properties] Properties to set
     */
    function UuidAndDate(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UuidAndDate uuid.
     * @member {string} uuid
     * @memberof UuidAndDate
     * @instance
     */
    UuidAndDate.prototype.uuid = "";

    /**
     * UuidAndDate date.
     * @member {google.protobuf.ITimestamp|null|undefined} date
     * @memberof UuidAndDate
     * @instance
     */
    UuidAndDate.prototype.date = null;

    /**
     * Creates a new UuidAndDate instance using the specified properties.
     * @function create
     * @memberof UuidAndDate
     * @static
     * @param {IUuidAndDate=} [properties] Properties to set
     * @returns {UuidAndDate} UuidAndDate instance
     */
    UuidAndDate.create = function create(properties) {
        return new UuidAndDate(properties);
    };

    /**
     * Encodes the specified UuidAndDate message. Does not implicitly {@link UuidAndDate.verify|verify} messages.
     * @function encode
     * @memberof UuidAndDate
     * @static
     * @param {IUuidAndDate} message UuidAndDate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UuidAndDate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
        if (message.date != null && Object.hasOwnProperty.call(message, "date"))
            $root.google.protobuf.Timestamp.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UuidAndDate message, length delimited. Does not implicitly {@link UuidAndDate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UuidAndDate
     * @static
     * @param {IUuidAndDate} message UuidAndDate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UuidAndDate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UuidAndDate message from the specified reader or buffer.
     * @function decode
     * @memberof UuidAndDate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UuidAndDate} UuidAndDate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UuidAndDate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UuidAndDate();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.uuid = reader.string();
                    break;
                }
            case 2: {
                    message.date = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UuidAndDate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UuidAndDate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UuidAndDate} UuidAndDate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UuidAndDate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UuidAndDate message.
     * @function verify
     * @memberof UuidAndDate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UuidAndDate.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.uuid != null && message.hasOwnProperty("uuid"))
            if (!$util.isString(message.uuid))
                return "uuid: string expected";
        if (message.date != null && message.hasOwnProperty("date")) {
            var error = $root.google.protobuf.Timestamp.verify(message.date);
            if (error)
                return "date." + error;
        }
        return null;
    };

    /**
     * Creates an UuidAndDate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UuidAndDate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UuidAndDate} UuidAndDate
     */
    UuidAndDate.fromObject = function fromObject(object) {
        if (object instanceof $root.UuidAndDate)
            return object;
        var message = new $root.UuidAndDate();
        if (object.uuid != null)
            message.uuid = String(object.uuid);
        if (object.date != null) {
            if (typeof object.date !== "object")
                throw TypeError(".UuidAndDate.date: object expected");
            message.date = $root.google.protobuf.Timestamp.fromObject(object.date);
        }
        return message;
    };

    /**
     * Creates a plain object from an UuidAndDate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UuidAndDate
     * @static
     * @param {UuidAndDate} message UuidAndDate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UuidAndDate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.uuid = "";
            object.date = null;
        }
        if (message.uuid != null && message.hasOwnProperty("uuid"))
            object.uuid = message.uuid;
        if (message.date != null && message.hasOwnProperty("date"))
            object.date = $root.google.protobuf.Timestamp.toObject(message.date, options);
        return object;
    };

    /**
     * Converts this UuidAndDate to JSON.
     * @function toJSON
     * @memberof UuidAndDate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UuidAndDate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UuidAndDate
     * @function getTypeUrl
     * @memberof UuidAndDate
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UuidAndDate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UuidAndDate";
    };

    return UuidAndDate;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

$root.Map = (function() {

    /**
     * Namespace Map.
     * @exports Map
     * @namespace
     */
    var Map = {};

    Map.InitialMapRow = (function() {

        /**
         * Properties of an InitialMapRow.
         * @memberof Map
         * @interface IInitialMapRow
         * @property {number|null} [id] InitialMapRow id
         * @property {number|null} [lat] InitialMapRow lat
         * @property {number|null} [lon] InitialMapRow lon
         * @property {string|null} [name] InitialMapRow name
         * @property {number|null} [ownerId] InitialMapRow ownerId
         * @property {number|null} [clientStatusId] InitialMapRow clientStatusId
         * @property {string|null} [colorCode] InitialMapRow colorCode
         * @property {string|null} [city] InitialMapRow city
         * @property {string|null} [postCode] InitialMapRow postCode
         * @property {string|null} [country] InitialMapRow country
         * @property {Object.<string,Map.IAdditionalColumnsSubValue>|null} [calculatedFieldColumnsValue] InitialMapRow calculatedFieldColumnsValue
         */

        /**
         * Constructs a new InitialMapRow.
         * @memberof Map
         * @classdesc Represents an InitialMapRow.
         * @implements IInitialMapRow
         * @constructor
         * @param {Map.IInitialMapRow=} [properties] Properties to set
         */
        function InitialMapRow(properties) {
            this.calculatedFieldColumnsValue = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InitialMapRow id.
         * @member {number} id
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.id = 0;

        /**
         * InitialMapRow lat.
         * @member {number} lat
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.lat = 0;

        /**
         * InitialMapRow lon.
         * @member {number} lon
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.lon = 0;

        /**
         * InitialMapRow name.
         * @member {string} name
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.name = "";

        /**
         * InitialMapRow ownerId.
         * @member {number} ownerId
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.ownerId = 0;

        /**
         * InitialMapRow clientStatusId.
         * @member {number} clientStatusId
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.clientStatusId = 0;

        /**
         * InitialMapRow colorCode.
         * @member {string} colorCode
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.colorCode = "";

        /**
         * InitialMapRow city.
         * @member {string} city
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.city = "";

        /**
         * InitialMapRow postCode.
         * @member {string} postCode
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.postCode = "";

        /**
         * InitialMapRow country.
         * @member {string} country
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.country = "";

        /**
         * InitialMapRow calculatedFieldColumnsValue.
         * @member {Object.<string,Map.IAdditionalColumnsSubValue>} calculatedFieldColumnsValue
         * @memberof Map.InitialMapRow
         * @instance
         */
        InitialMapRow.prototype.calculatedFieldColumnsValue = $util.emptyObject;

        /**
         * Creates a new InitialMapRow instance using the specified properties.
         * @function create
         * @memberof Map.InitialMapRow
         * @static
         * @param {Map.IInitialMapRow=} [properties] Properties to set
         * @returns {Map.InitialMapRow} InitialMapRow instance
         */
        InitialMapRow.create = function create(properties) {
            return new InitialMapRow(properties);
        };

        /**
         * Encodes the specified InitialMapRow message. Does not implicitly {@link Map.InitialMapRow.verify|verify} messages.
         * @function encode
         * @memberof Map.InitialMapRow
         * @static
         * @param {Map.IInitialMapRow} message InitialMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitialMapRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
            if (message.lon != null && Object.hasOwnProperty.call(message, "lon"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.lon);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.ownerId);
            if (message.clientStatusId != null && Object.hasOwnProperty.call(message, "clientStatusId"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.clientStatusId);
            if (message.colorCode != null && Object.hasOwnProperty.call(message, "colorCode"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.colorCode);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.city);
            if (message.postCode != null && Object.hasOwnProperty.call(message, "postCode"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.postCode);
            if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.country);
            if (message.calculatedFieldColumnsValue != null && Object.hasOwnProperty.call(message, "calculatedFieldColumnsValue"))
                for (var keys = Object.keys(message.calculatedFieldColumnsValue), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.Map.AdditionalColumnsSubValue.encode(message.calculatedFieldColumnsValue[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified InitialMapRow message, length delimited. Does not implicitly {@link Map.InitialMapRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.InitialMapRow
         * @static
         * @param {Map.IInitialMapRow} message InitialMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitialMapRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InitialMapRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.InitialMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.InitialMapRow} InitialMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitialMapRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.InitialMapRow(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.lat = reader.double();
                        break;
                    }
                case 3: {
                        message.lon = reader.double();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.ownerId = reader.int32();
                        break;
                    }
                case 6: {
                        message.clientStatusId = reader.int32();
                        break;
                    }
                case 7: {
                        message.colorCode = reader.string();
                        break;
                    }
                case 8: {
                        message.city = reader.string();
                        break;
                    }
                case 9: {
                        message.postCode = reader.string();
                        break;
                    }
                case 10: {
                        message.country = reader.string();
                        break;
                    }
                case 11: {
                        if (message.calculatedFieldColumnsValue === $util.emptyObject)
                            message.calculatedFieldColumnsValue = {};
                        var end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            var tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.Map.AdditionalColumnsSubValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.calculatedFieldColumnsValue[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InitialMapRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.InitialMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.InitialMapRow} InitialMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitialMapRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InitialMapRow message.
         * @function verify
         * @memberof Map.InitialMapRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InitialMapRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.lat != null && message.hasOwnProperty("lat"))
                if (typeof message.lat !== "number")
                    return "lat: number expected";
            if (message.lon != null && message.hasOwnProperty("lon"))
                if (typeof message.lon !== "number")
                    return "lon: number expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                if (!$util.isInteger(message.ownerId))
                    return "ownerId: integer expected";
            if (message.clientStatusId != null && message.hasOwnProperty("clientStatusId"))
                if (!$util.isInteger(message.clientStatusId))
                    return "clientStatusId: integer expected";
            if (message.colorCode != null && message.hasOwnProperty("colorCode"))
                if (!$util.isString(message.colorCode))
                    return "colorCode: string expected";
            if (message.city != null && message.hasOwnProperty("city"))
                if (!$util.isString(message.city))
                    return "city: string expected";
            if (message.postCode != null && message.hasOwnProperty("postCode"))
                if (!$util.isString(message.postCode))
                    return "postCode: string expected";
            if (message.country != null && message.hasOwnProperty("country"))
                if (!$util.isString(message.country))
                    return "country: string expected";
            if (message.calculatedFieldColumnsValue != null && message.hasOwnProperty("calculatedFieldColumnsValue")) {
                if (!$util.isObject(message.calculatedFieldColumnsValue))
                    return "calculatedFieldColumnsValue: object expected";
                var key = Object.keys(message.calculatedFieldColumnsValue);
                for (var i = 0; i < key.length; ++i) {
                    var error = $root.Map.AdditionalColumnsSubValue.verify(message.calculatedFieldColumnsValue[key[i]]);
                    if (error)
                        return "calculatedFieldColumnsValue." + error;
                }
            }
            return null;
        };

        /**
         * Creates an InitialMapRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.InitialMapRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.InitialMapRow} InitialMapRow
         */
        InitialMapRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.InitialMapRow)
                return object;
            var message = new $root.Map.InitialMapRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.lat != null)
                message.lat = Number(object.lat);
            if (object.lon != null)
                message.lon = Number(object.lon);
            if (object.name != null)
                message.name = String(object.name);
            if (object.ownerId != null)
                message.ownerId = object.ownerId | 0;
            if (object.clientStatusId != null)
                message.clientStatusId = object.clientStatusId | 0;
            if (object.colorCode != null)
                message.colorCode = String(object.colorCode);
            if (object.city != null)
                message.city = String(object.city);
            if (object.postCode != null)
                message.postCode = String(object.postCode);
            if (object.country != null)
                message.country = String(object.country);
            if (object.calculatedFieldColumnsValue) {
                if (typeof object.calculatedFieldColumnsValue !== "object")
                    throw TypeError(".Map.InitialMapRow.calculatedFieldColumnsValue: object expected");
                message.calculatedFieldColumnsValue = {};
                for (var keys = Object.keys(object.calculatedFieldColumnsValue), i = 0; i < keys.length; ++i) {
                    if (typeof object.calculatedFieldColumnsValue[keys[i]] !== "object")
                        throw TypeError(".Map.InitialMapRow.calculatedFieldColumnsValue: object expected");
                    message.calculatedFieldColumnsValue[keys[i]] = $root.Map.AdditionalColumnsSubValue.fromObject(object.calculatedFieldColumnsValue[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an InitialMapRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.InitialMapRow
         * @static
         * @param {Map.InitialMapRow} message InitialMapRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InitialMapRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.objects || options.defaults)
                object.calculatedFieldColumnsValue = {};
            if (options.defaults) {
                object.id = 0;
                object.lat = 0;
                object.lon = 0;
                object.name = "";
                object.ownerId = 0;
                object.clientStatusId = 0;
                object.colorCode = "";
                object.city = "";
                object.postCode = "";
                object.country = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.lat != null && message.hasOwnProperty("lat"))
                object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
            if (message.lon != null && message.hasOwnProperty("lon"))
                object.lon = options.json && !isFinite(message.lon) ? String(message.lon) : message.lon;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                object.ownerId = message.ownerId;
            if (message.clientStatusId != null && message.hasOwnProperty("clientStatusId"))
                object.clientStatusId = message.clientStatusId;
            if (message.colorCode != null && message.hasOwnProperty("colorCode"))
                object.colorCode = message.colorCode;
            if (message.city != null && message.hasOwnProperty("city"))
                object.city = message.city;
            if (message.postCode != null && message.hasOwnProperty("postCode"))
                object.postCode = message.postCode;
            if (message.country != null && message.hasOwnProperty("country"))
                object.country = message.country;
            var keys2;
            if (message.calculatedFieldColumnsValue && (keys2 = Object.keys(message.calculatedFieldColumnsValue)).length) {
                object.calculatedFieldColumnsValue = {};
                for (var j = 0; j < keys2.length; ++j)
                    object.calculatedFieldColumnsValue[keys2[j]] = $root.Map.AdditionalColumnsSubValue.toObject(message.calculatedFieldColumnsValue[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this InitialMapRow to JSON.
         * @function toJSON
         * @memberof Map.InitialMapRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InitialMapRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for InitialMapRow
         * @function getTypeUrl
         * @memberof Map.InitialMapRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InitialMapRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.InitialMapRow";
        };

        return InitialMapRow;
    })();

    Map.AdditionalColumnsSubValue = (function() {

        /**
         * Properties of an AdditionalColumnsSubValue.
         * @memberof Map
         * @interface IAdditionalColumnsSubValue
         * @property {string|null} [name] AdditionalColumnsSubValue name
         * @property {string|null} [value] AdditionalColumnsSubValue value
         */

        /**
         * Constructs a new AdditionalColumnsSubValue.
         * @memberof Map
         * @classdesc Represents an AdditionalColumnsSubValue.
         * @implements IAdditionalColumnsSubValue
         * @constructor
         * @param {Map.IAdditionalColumnsSubValue=} [properties] Properties to set
         */
        function AdditionalColumnsSubValue(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalColumnsSubValue name.
         * @member {string} name
         * @memberof Map.AdditionalColumnsSubValue
         * @instance
         */
        AdditionalColumnsSubValue.prototype.name = "";

        /**
         * AdditionalColumnsSubValue value.
         * @member {string} value
         * @memberof Map.AdditionalColumnsSubValue
         * @instance
         */
        AdditionalColumnsSubValue.prototype.value = "";

        /**
         * Creates a new AdditionalColumnsSubValue instance using the specified properties.
         * @function create
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {Map.IAdditionalColumnsSubValue=} [properties] Properties to set
         * @returns {Map.AdditionalColumnsSubValue} AdditionalColumnsSubValue instance
         */
        AdditionalColumnsSubValue.create = function create(properties) {
            return new AdditionalColumnsSubValue(properties);
        };

        /**
         * Encodes the specified AdditionalColumnsSubValue message. Does not implicitly {@link Map.AdditionalColumnsSubValue.verify|verify} messages.
         * @function encode
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {Map.IAdditionalColumnsSubValue} message AdditionalColumnsSubValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalColumnsSubValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified AdditionalColumnsSubValue message, length delimited. Does not implicitly {@link Map.AdditionalColumnsSubValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {Map.IAdditionalColumnsSubValue} message AdditionalColumnsSubValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalColumnsSubValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdditionalColumnsSubValue message from the specified reader or buffer.
         * @function decode
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.AdditionalColumnsSubValue} AdditionalColumnsSubValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalColumnsSubValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.AdditionalColumnsSubValue();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.value = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdditionalColumnsSubValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.AdditionalColumnsSubValue} AdditionalColumnsSubValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalColumnsSubValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdditionalColumnsSubValue message.
         * @function verify
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdditionalColumnsSubValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates an AdditionalColumnsSubValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.AdditionalColumnsSubValue} AdditionalColumnsSubValue
         */
        AdditionalColumnsSubValue.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.AdditionalColumnsSubValue)
                return object;
            var message = new $root.Map.AdditionalColumnsSubValue();
            if (object.name != null)
                message.name = String(object.name);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from an AdditionalColumnsSubValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {Map.AdditionalColumnsSubValue} message AdditionalColumnsSubValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdditionalColumnsSubValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                object.value = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this AdditionalColumnsSubValue to JSON.
         * @function toJSON
         * @memberof Map.AdditionalColumnsSubValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdditionalColumnsSubValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdditionalColumnsSubValue
         * @function getTypeUrl
         * @memberof Map.AdditionalColumnsSubValue
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdditionalColumnsSubValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.AdditionalColumnsSubValue";
        };

        return AdditionalColumnsSubValue;
    })();

    Map.CheckoutEvolution = (function() {

        /**
         * Properties of a CheckoutEvolution.
         * @memberof Map
         * @interface ICheckoutEvolution
         * @property {number|null} [currentSum] CheckoutEvolution currentSum
         * @property {number|null} [previousSum] CheckoutEvolution previousSum
         * @property {number|null} [evolution] CheckoutEvolution evolution
         * @property {number|null} [currentUvcSum] CheckoutEvolution currentUvcSum
         * @property {number|null} [previousUvcSum] CheckoutEvolution previousUvcSum
         * @property {number|null} [uvcEvolution] CheckoutEvolution uvcEvolution
         */

        /**
         * Constructs a new CheckoutEvolution.
         * @memberof Map
         * @classdesc Represents a CheckoutEvolution.
         * @implements ICheckoutEvolution
         * @constructor
         * @param {Map.ICheckoutEvolution=} [properties] Properties to set
         */
        function CheckoutEvolution(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckoutEvolution currentSum.
         * @member {number} currentSum
         * @memberof Map.CheckoutEvolution
         * @instance
         */
        CheckoutEvolution.prototype.currentSum = 0;

        /**
         * CheckoutEvolution previousSum.
         * @member {number} previousSum
         * @memberof Map.CheckoutEvolution
         * @instance
         */
        CheckoutEvolution.prototype.previousSum = 0;

        /**
         * CheckoutEvolution evolution.
         * @member {number} evolution
         * @memberof Map.CheckoutEvolution
         * @instance
         */
        CheckoutEvolution.prototype.evolution = 0;

        /**
         * CheckoutEvolution currentUvcSum.
         * @member {number} currentUvcSum
         * @memberof Map.CheckoutEvolution
         * @instance
         */
        CheckoutEvolution.prototype.currentUvcSum = 0;

        /**
         * CheckoutEvolution previousUvcSum.
         * @member {number} previousUvcSum
         * @memberof Map.CheckoutEvolution
         * @instance
         */
        CheckoutEvolution.prototype.previousUvcSum = 0;

        /**
         * CheckoutEvolution uvcEvolution.
         * @member {number} uvcEvolution
         * @memberof Map.CheckoutEvolution
         * @instance
         */
        CheckoutEvolution.prototype.uvcEvolution = 0;

        /**
         * Creates a new CheckoutEvolution instance using the specified properties.
         * @function create
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {Map.ICheckoutEvolution=} [properties] Properties to set
         * @returns {Map.CheckoutEvolution} CheckoutEvolution instance
         */
        CheckoutEvolution.create = function create(properties) {
            return new CheckoutEvolution(properties);
        };

        /**
         * Encodes the specified CheckoutEvolution message. Does not implicitly {@link Map.CheckoutEvolution.verify|verify} messages.
         * @function encode
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {Map.ICheckoutEvolution} message CheckoutEvolution message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckoutEvolution.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentSum != null && Object.hasOwnProperty.call(message, "currentSum"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.currentSum);
            if (message.previousSum != null && Object.hasOwnProperty.call(message, "previousSum"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.previousSum);
            if (message.evolution != null && Object.hasOwnProperty.call(message, "evolution"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.evolution);
            if (message.currentUvcSum != null && Object.hasOwnProperty.call(message, "currentUvcSum"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.currentUvcSum);
            if (message.previousUvcSum != null && Object.hasOwnProperty.call(message, "previousUvcSum"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.previousUvcSum);
            if (message.uvcEvolution != null && Object.hasOwnProperty.call(message, "uvcEvolution"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.uvcEvolution);
            return writer;
        };

        /**
         * Encodes the specified CheckoutEvolution message, length delimited. Does not implicitly {@link Map.CheckoutEvolution.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {Map.ICheckoutEvolution} message CheckoutEvolution message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckoutEvolution.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CheckoutEvolution message from the specified reader or buffer.
         * @function decode
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.CheckoutEvolution} CheckoutEvolution
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckoutEvolution.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.CheckoutEvolution();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentSum = reader.float();
                        break;
                    }
                case 2: {
                        message.previousSum = reader.float();
                        break;
                    }
                case 3: {
                        message.evolution = reader.float();
                        break;
                    }
                case 4: {
                        message.currentUvcSum = reader.float();
                        break;
                    }
                case 5: {
                        message.previousUvcSum = reader.float();
                        break;
                    }
                case 6: {
                        message.uvcEvolution = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CheckoutEvolution message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.CheckoutEvolution} CheckoutEvolution
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckoutEvolution.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CheckoutEvolution message.
         * @function verify
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CheckoutEvolution.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currentSum != null && message.hasOwnProperty("currentSum"))
                if (typeof message.currentSum !== "number")
                    return "currentSum: number expected";
            if (message.previousSum != null && message.hasOwnProperty("previousSum"))
                if (typeof message.previousSum !== "number")
                    return "previousSum: number expected";
            if (message.evolution != null && message.hasOwnProperty("evolution"))
                if (typeof message.evolution !== "number")
                    return "evolution: number expected";
            if (message.currentUvcSum != null && message.hasOwnProperty("currentUvcSum"))
                if (typeof message.currentUvcSum !== "number")
                    return "currentUvcSum: number expected";
            if (message.previousUvcSum != null && message.hasOwnProperty("previousUvcSum"))
                if (typeof message.previousUvcSum !== "number")
                    return "previousUvcSum: number expected";
            if (message.uvcEvolution != null && message.hasOwnProperty("uvcEvolution"))
                if (typeof message.uvcEvolution !== "number")
                    return "uvcEvolution: number expected";
            return null;
        };

        /**
         * Creates a CheckoutEvolution message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.CheckoutEvolution} CheckoutEvolution
         */
        CheckoutEvolution.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.CheckoutEvolution)
                return object;
            var message = new $root.Map.CheckoutEvolution();
            if (object.currentSum != null)
                message.currentSum = Number(object.currentSum);
            if (object.previousSum != null)
                message.previousSum = Number(object.previousSum);
            if (object.evolution != null)
                message.evolution = Number(object.evolution);
            if (object.currentUvcSum != null)
                message.currentUvcSum = Number(object.currentUvcSum);
            if (object.previousUvcSum != null)
                message.previousUvcSum = Number(object.previousUvcSum);
            if (object.uvcEvolution != null)
                message.uvcEvolution = Number(object.uvcEvolution);
            return message;
        };

        /**
         * Creates a plain object from a CheckoutEvolution message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {Map.CheckoutEvolution} message CheckoutEvolution
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CheckoutEvolution.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currentSum = 0;
                object.previousSum = 0;
                object.evolution = 0;
                object.currentUvcSum = 0;
                object.previousUvcSum = 0;
                object.uvcEvolution = 0;
            }
            if (message.currentSum != null && message.hasOwnProperty("currentSum"))
                object.currentSum = options.json && !isFinite(message.currentSum) ? String(message.currentSum) : message.currentSum;
            if (message.previousSum != null && message.hasOwnProperty("previousSum"))
                object.previousSum = options.json && !isFinite(message.previousSum) ? String(message.previousSum) : message.previousSum;
            if (message.evolution != null && message.hasOwnProperty("evolution"))
                object.evolution = options.json && !isFinite(message.evolution) ? String(message.evolution) : message.evolution;
            if (message.currentUvcSum != null && message.hasOwnProperty("currentUvcSum"))
                object.currentUvcSum = options.json && !isFinite(message.currentUvcSum) ? String(message.currentUvcSum) : message.currentUvcSum;
            if (message.previousUvcSum != null && message.hasOwnProperty("previousUvcSum"))
                object.previousUvcSum = options.json && !isFinite(message.previousUvcSum) ? String(message.previousUvcSum) : message.previousUvcSum;
            if (message.uvcEvolution != null && message.hasOwnProperty("uvcEvolution"))
                object.uvcEvolution = options.json && !isFinite(message.uvcEvolution) ? String(message.uvcEvolution) : message.uvcEvolution;
            return object;
        };

        /**
         * Converts this CheckoutEvolution to JSON.
         * @function toJSON
         * @memberof Map.CheckoutEvolution
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CheckoutEvolution.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CheckoutEvolution
         * @function getTypeUrl
         * @memberof Map.CheckoutEvolution
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CheckoutEvolution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.CheckoutEvolution";
        };

        return CheckoutEvolution;
    })();

    Map.InitialMapTable = (function() {

        /**
         * Properties of an InitialMapTable.
         * @memberof Map
         * @interface IInitialMapTable
         * @property {Array.<Map.IInitialMapRow>|null} [rows] InitialMapTable rows
         */

        /**
         * Constructs a new InitialMapTable.
         * @memberof Map
         * @classdesc Represents an InitialMapTable.
         * @implements IInitialMapTable
         * @constructor
         * @param {Map.IInitialMapTable=} [properties] Properties to set
         */
        function InitialMapTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InitialMapTable rows.
         * @member {Array.<Map.IInitialMapRow>} rows
         * @memberof Map.InitialMapTable
         * @instance
         */
        InitialMapTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new InitialMapTable instance using the specified properties.
         * @function create
         * @memberof Map.InitialMapTable
         * @static
         * @param {Map.IInitialMapTable=} [properties] Properties to set
         * @returns {Map.InitialMapTable} InitialMapTable instance
         */
        InitialMapTable.create = function create(properties) {
            return new InitialMapTable(properties);
        };

        /**
         * Encodes the specified InitialMapTable message. Does not implicitly {@link Map.InitialMapTable.verify|verify} messages.
         * @function encode
         * @memberof Map.InitialMapTable
         * @static
         * @param {Map.IInitialMapTable} message InitialMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitialMapTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.InitialMapRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified InitialMapTable message, length delimited. Does not implicitly {@link Map.InitialMapTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.InitialMapTable
         * @static
         * @param {Map.IInitialMapTable} message InitialMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitialMapTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InitialMapTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.InitialMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.InitialMapTable} InitialMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitialMapTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.InitialMapTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.InitialMapRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InitialMapTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.InitialMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.InitialMapTable} InitialMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitialMapTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InitialMapTable message.
         * @function verify
         * @memberof Map.InitialMapTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InitialMapTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.InitialMapRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates an InitialMapTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.InitialMapTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.InitialMapTable} InitialMapTable
         */
        InitialMapTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.InitialMapTable)
                return object;
            var message = new $root.Map.InitialMapTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.InitialMapTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.InitialMapTable.rows: object expected");
                    message.rows[i] = $root.Map.InitialMapRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an InitialMapTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.InitialMapTable
         * @static
         * @param {Map.InitialMapTable} message InitialMapTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InitialMapTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.InitialMapRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this InitialMapTable to JSON.
         * @function toJSON
         * @memberof Map.InitialMapTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InitialMapTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for InitialMapTable
         * @function getTypeUrl
         * @memberof Map.InitialMapTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InitialMapTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.InitialMapTable";
        };

        return InitialMapTable;
    })();

    Map.EventsMapRow = (function() {

        /**
         * Properties of an EventsMapRow.
         * @memberof Map
         * @interface IEventsMapRow
         * @property {number|null} [id] EventsMapRow id
         * @property {number|null} [nextEventType] EventsMapRow nextEventType
         * @property {google.protobuf.ITimestamp|null} [nextEventDate] EventsMapRow nextEventDate
         * @property {number|null} [lastEventType] EventsMapRow lastEventType
         * @property {google.protobuf.ITimestamp|null} [lastEventDate] EventsMapRow lastEventDate
         */

        /**
         * Constructs a new EventsMapRow.
         * @memberof Map
         * @classdesc Represents an EventsMapRow.
         * @implements IEventsMapRow
         * @constructor
         * @param {Map.IEventsMapRow=} [properties] Properties to set
         */
        function EventsMapRow(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventsMapRow id.
         * @member {number} id
         * @memberof Map.EventsMapRow
         * @instance
         */
        EventsMapRow.prototype.id = 0;

        /**
         * EventsMapRow nextEventType.
         * @member {number} nextEventType
         * @memberof Map.EventsMapRow
         * @instance
         */
        EventsMapRow.prototype.nextEventType = 0;

        /**
         * EventsMapRow nextEventDate.
         * @member {google.protobuf.ITimestamp|null|undefined} nextEventDate
         * @memberof Map.EventsMapRow
         * @instance
         */
        EventsMapRow.prototype.nextEventDate = null;

        /**
         * EventsMapRow lastEventType.
         * @member {number} lastEventType
         * @memberof Map.EventsMapRow
         * @instance
         */
        EventsMapRow.prototype.lastEventType = 0;

        /**
         * EventsMapRow lastEventDate.
         * @member {google.protobuf.ITimestamp|null|undefined} lastEventDate
         * @memberof Map.EventsMapRow
         * @instance
         */
        EventsMapRow.prototype.lastEventDate = null;

        /**
         * Creates a new EventsMapRow instance using the specified properties.
         * @function create
         * @memberof Map.EventsMapRow
         * @static
         * @param {Map.IEventsMapRow=} [properties] Properties to set
         * @returns {Map.EventsMapRow} EventsMapRow instance
         */
        EventsMapRow.create = function create(properties) {
            return new EventsMapRow(properties);
        };

        /**
         * Encodes the specified EventsMapRow message. Does not implicitly {@link Map.EventsMapRow.verify|verify} messages.
         * @function encode
         * @memberof Map.EventsMapRow
         * @static
         * @param {Map.IEventsMapRow} message EventsMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventsMapRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.nextEventType != null && Object.hasOwnProperty.call(message, "nextEventType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nextEventType);
            if (message.nextEventDate != null && Object.hasOwnProperty.call(message, "nextEventDate"))
                $root.google.protobuf.Timestamp.encode(message.nextEventDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.lastEventType != null && Object.hasOwnProperty.call(message, "lastEventType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lastEventType);
            if (message.lastEventDate != null && Object.hasOwnProperty.call(message, "lastEventDate"))
                $root.google.protobuf.Timestamp.encode(message.lastEventDate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EventsMapRow message, length delimited. Does not implicitly {@link Map.EventsMapRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.EventsMapRow
         * @static
         * @param {Map.IEventsMapRow} message EventsMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventsMapRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EventsMapRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.EventsMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.EventsMapRow} EventsMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventsMapRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.EventsMapRow();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.nextEventType = reader.int32();
                        break;
                    }
                case 3: {
                        message.nextEventDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.lastEventType = reader.int32();
                        break;
                    }
                case 5: {
                        message.lastEventDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EventsMapRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.EventsMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.EventsMapRow} EventsMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventsMapRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EventsMapRow message.
         * @function verify
         * @memberof Map.EventsMapRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventsMapRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.nextEventType != null && message.hasOwnProperty("nextEventType"))
                if (!$util.isInteger(message.nextEventType))
                    return "nextEventType: integer expected";
            if (message.nextEventDate != null && message.hasOwnProperty("nextEventDate")) {
                var error = $root.google.protobuf.Timestamp.verify(message.nextEventDate);
                if (error)
                    return "nextEventDate." + error;
            }
            if (message.lastEventType != null && message.hasOwnProperty("lastEventType"))
                if (!$util.isInteger(message.lastEventType))
                    return "lastEventType: integer expected";
            if (message.lastEventDate != null && message.hasOwnProperty("lastEventDate")) {
                var error = $root.google.protobuf.Timestamp.verify(message.lastEventDate);
                if (error)
                    return "lastEventDate." + error;
            }
            return null;
        };

        /**
         * Creates an EventsMapRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.EventsMapRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.EventsMapRow} EventsMapRow
         */
        EventsMapRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.EventsMapRow)
                return object;
            var message = new $root.Map.EventsMapRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.nextEventType != null)
                message.nextEventType = object.nextEventType | 0;
            if (object.nextEventDate != null) {
                if (typeof object.nextEventDate !== "object")
                    throw TypeError(".Map.EventsMapRow.nextEventDate: object expected");
                message.nextEventDate = $root.google.protobuf.Timestamp.fromObject(object.nextEventDate);
            }
            if (object.lastEventType != null)
                message.lastEventType = object.lastEventType | 0;
            if (object.lastEventDate != null) {
                if (typeof object.lastEventDate !== "object")
                    throw TypeError(".Map.EventsMapRow.lastEventDate: object expected");
                message.lastEventDate = $root.google.protobuf.Timestamp.fromObject(object.lastEventDate);
            }
            return message;
        };

        /**
         * Creates a plain object from an EventsMapRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.EventsMapRow
         * @static
         * @param {Map.EventsMapRow} message EventsMapRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventsMapRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.nextEventType = 0;
                object.nextEventDate = null;
                object.lastEventType = 0;
                object.lastEventDate = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.nextEventType != null && message.hasOwnProperty("nextEventType"))
                object.nextEventType = message.nextEventType;
            if (message.nextEventDate != null && message.hasOwnProperty("nextEventDate"))
                object.nextEventDate = $root.google.protobuf.Timestamp.toObject(message.nextEventDate, options);
            if (message.lastEventType != null && message.hasOwnProperty("lastEventType"))
                object.lastEventType = message.lastEventType;
            if (message.lastEventDate != null && message.hasOwnProperty("lastEventDate"))
                object.lastEventDate = $root.google.protobuf.Timestamp.toObject(message.lastEventDate, options);
            return object;
        };

        /**
         * Converts this EventsMapRow to JSON.
         * @function toJSON
         * @memberof Map.EventsMapRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventsMapRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventsMapRow
         * @function getTypeUrl
         * @memberof Map.EventsMapRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventsMapRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.EventsMapRow";
        };

        return EventsMapRow;
    })();

    Map.EventsMapTable = (function() {

        /**
         * Properties of an EventsMapTable.
         * @memberof Map
         * @interface IEventsMapTable
         * @property {Array.<Map.IEventsMapRow>|null} [rows] EventsMapTable rows
         */

        /**
         * Constructs a new EventsMapTable.
         * @memberof Map
         * @classdesc Represents an EventsMapTable.
         * @implements IEventsMapTable
         * @constructor
         * @param {Map.IEventsMapTable=} [properties] Properties to set
         */
        function EventsMapTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventsMapTable rows.
         * @member {Array.<Map.IEventsMapRow>} rows
         * @memberof Map.EventsMapTable
         * @instance
         */
        EventsMapTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new EventsMapTable instance using the specified properties.
         * @function create
         * @memberof Map.EventsMapTable
         * @static
         * @param {Map.IEventsMapTable=} [properties] Properties to set
         * @returns {Map.EventsMapTable} EventsMapTable instance
         */
        EventsMapTable.create = function create(properties) {
            return new EventsMapTable(properties);
        };

        /**
         * Encodes the specified EventsMapTable message. Does not implicitly {@link Map.EventsMapTable.verify|verify} messages.
         * @function encode
         * @memberof Map.EventsMapTable
         * @static
         * @param {Map.IEventsMapTable} message EventsMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventsMapTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.EventsMapRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified EventsMapTable message, length delimited. Does not implicitly {@link Map.EventsMapTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.EventsMapTable
         * @static
         * @param {Map.IEventsMapTable} message EventsMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventsMapTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EventsMapTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.EventsMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.EventsMapTable} EventsMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventsMapTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.EventsMapTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.EventsMapRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EventsMapTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.EventsMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.EventsMapTable} EventsMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventsMapTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EventsMapTable message.
         * @function verify
         * @memberof Map.EventsMapTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventsMapTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.EventsMapRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates an EventsMapTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.EventsMapTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.EventsMapTable} EventsMapTable
         */
        EventsMapTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.EventsMapTable)
                return object;
            var message = new $root.Map.EventsMapTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.EventsMapTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.EventsMapTable.rows: object expected");
                    message.rows[i] = $root.Map.EventsMapRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an EventsMapTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.EventsMapTable
         * @static
         * @param {Map.EventsMapTable} message EventsMapTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventsMapTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.EventsMapRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this EventsMapTable to JSON.
         * @function toJSON
         * @memberof Map.EventsMapTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventsMapTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventsMapTable
         * @function getTypeUrl
         * @memberof Map.EventsMapTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventsMapTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.EventsMapTable";
        };

        return EventsMapTable;
    })();

    Map.TagsMapRow = (function() {

        /**
         * Properties of a TagsMapRow.
         * @memberof Map
         * @interface ITagsMapRow
         * @property {number|null} [id] TagsMapRow id
         * @property {Array.<number>|null} [tags] TagsMapRow tags
         */

        /**
         * Constructs a new TagsMapRow.
         * @memberof Map
         * @classdesc Represents a TagsMapRow.
         * @implements ITagsMapRow
         * @constructor
         * @param {Map.ITagsMapRow=} [properties] Properties to set
         */
        function TagsMapRow(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagsMapRow id.
         * @member {number} id
         * @memberof Map.TagsMapRow
         * @instance
         */
        TagsMapRow.prototype.id = 0;

        /**
         * TagsMapRow tags.
         * @member {Array.<number>} tags
         * @memberof Map.TagsMapRow
         * @instance
         */
        TagsMapRow.prototype.tags = $util.emptyArray;

        /**
         * Creates a new TagsMapRow instance using the specified properties.
         * @function create
         * @memberof Map.TagsMapRow
         * @static
         * @param {Map.ITagsMapRow=} [properties] Properties to set
         * @returns {Map.TagsMapRow} TagsMapRow instance
         */
        TagsMapRow.create = function create(properties) {
            return new TagsMapRow(properties);
        };

        /**
         * Encodes the specified TagsMapRow message. Does not implicitly {@link Map.TagsMapRow.verify|verify} messages.
         * @function encode
         * @memberof Map.TagsMapRow
         * @static
         * @param {Map.ITagsMapRow} message TagsMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagsMapRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.tags != null && message.tags.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.tags.length; ++i)
                    writer.int32(message.tags[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified TagsMapRow message, length delimited. Does not implicitly {@link Map.TagsMapRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.TagsMapRow
         * @static
         * @param {Map.ITagsMapRow} message TagsMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagsMapRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TagsMapRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.TagsMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.TagsMapRow} TagsMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagsMapRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.TagsMapRow();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.tags.push(reader.int32());
                        } else
                            message.tags.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TagsMapRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.TagsMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.TagsMapRow} TagsMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagsMapRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TagsMapRow message.
         * @function verify
         * @memberof Map.TagsMapRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TagsMapRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (var i = 0; i < message.tags.length; ++i)
                    if (!$util.isInteger(message.tags[i]))
                        return "tags: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a TagsMapRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.TagsMapRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.TagsMapRow} TagsMapRow
         */
        TagsMapRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.TagsMapRow)
                return object;
            var message = new $root.Map.TagsMapRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".Map.TagsMapRow.tags: array expected");
                message.tags = [];
                for (var i = 0; i < object.tags.length; ++i)
                    message.tags[i] = object.tags[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a TagsMapRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.TagsMapRow
         * @static
         * @param {Map.TagsMapRow} message TagsMapRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagsMapRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults)
                object.id = 0;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (var j = 0; j < message.tags.length; ++j)
                    object.tags[j] = message.tags[j];
            }
            return object;
        };

        /**
         * Converts this TagsMapRow to JSON.
         * @function toJSON
         * @memberof Map.TagsMapRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagsMapRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagsMapRow
         * @function getTypeUrl
         * @memberof Map.TagsMapRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagsMapRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.TagsMapRow";
        };

        return TagsMapRow;
    })();

    Map.TagsMapTable = (function() {

        /**
         * Properties of a TagsMapTable.
         * @memberof Map
         * @interface ITagsMapTable
         * @property {Array.<Map.ITagsMapRow>|null} [rows] TagsMapTable rows
         */

        /**
         * Constructs a new TagsMapTable.
         * @memberof Map
         * @classdesc Represents a TagsMapTable.
         * @implements ITagsMapTable
         * @constructor
         * @param {Map.ITagsMapTable=} [properties] Properties to set
         */
        function TagsMapTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TagsMapTable rows.
         * @member {Array.<Map.ITagsMapRow>} rows
         * @memberof Map.TagsMapTable
         * @instance
         */
        TagsMapTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new TagsMapTable instance using the specified properties.
         * @function create
         * @memberof Map.TagsMapTable
         * @static
         * @param {Map.ITagsMapTable=} [properties] Properties to set
         * @returns {Map.TagsMapTable} TagsMapTable instance
         */
        TagsMapTable.create = function create(properties) {
            return new TagsMapTable(properties);
        };

        /**
         * Encodes the specified TagsMapTable message. Does not implicitly {@link Map.TagsMapTable.verify|verify} messages.
         * @function encode
         * @memberof Map.TagsMapTable
         * @static
         * @param {Map.ITagsMapTable} message TagsMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagsMapTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.TagsMapRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TagsMapTable message, length delimited. Does not implicitly {@link Map.TagsMapTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.TagsMapTable
         * @static
         * @param {Map.ITagsMapTable} message TagsMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagsMapTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TagsMapTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.TagsMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.TagsMapTable} TagsMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagsMapTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.TagsMapTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.TagsMapRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TagsMapTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.TagsMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.TagsMapTable} TagsMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagsMapTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TagsMapTable message.
         * @function verify
         * @memberof Map.TagsMapTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TagsMapTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.TagsMapRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TagsMapTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.TagsMapTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.TagsMapTable} TagsMapTable
         */
        TagsMapTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.TagsMapTable)
                return object;
            var message = new $root.Map.TagsMapTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.TagsMapTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.TagsMapTable.rows: object expected");
                    message.rows[i] = $root.Map.TagsMapRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TagsMapTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.TagsMapTable
         * @static
         * @param {Map.TagsMapTable} message TagsMapTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagsMapTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.TagsMapRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this TagsMapTable to JSON.
         * @function toJSON
         * @memberof Map.TagsMapTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagsMapTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TagsMapTable
         * @function getTypeUrl
         * @memberof Map.TagsMapTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TagsMapTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.TagsMapTable";
        };

        return TagsMapTable;
    })();

    Map.DistributionMapRow = (function() {

        /**
         * Properties of a DistributionMapRow.
         * @memberof Map
         * @interface IDistributionMapRow
         * @property {number|null} [id] DistributionMapRow id
         * @property {Array.<number>|null} [distribution] DistributionMapRow distribution
         * @property {google.protobuf.ITimestamp|null} [lastShelfAuditDate] DistributionMapRow lastShelfAuditDate
         */

        /**
         * Constructs a new DistributionMapRow.
         * @memberof Map
         * @classdesc Represents a DistributionMapRow.
         * @implements IDistributionMapRow
         * @constructor
         * @param {Map.IDistributionMapRow=} [properties] Properties to set
         */
        function DistributionMapRow(properties) {
            this.distribution = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DistributionMapRow id.
         * @member {number} id
         * @memberof Map.DistributionMapRow
         * @instance
         */
        DistributionMapRow.prototype.id = 0;

        /**
         * DistributionMapRow distribution.
         * @member {Array.<number>} distribution
         * @memberof Map.DistributionMapRow
         * @instance
         */
        DistributionMapRow.prototype.distribution = $util.emptyArray;

        /**
         * DistributionMapRow lastShelfAuditDate.
         * @member {google.protobuf.ITimestamp|null|undefined} lastShelfAuditDate
         * @memberof Map.DistributionMapRow
         * @instance
         */
        DistributionMapRow.prototype.lastShelfAuditDate = null;

        /**
         * Creates a new DistributionMapRow instance using the specified properties.
         * @function create
         * @memberof Map.DistributionMapRow
         * @static
         * @param {Map.IDistributionMapRow=} [properties] Properties to set
         * @returns {Map.DistributionMapRow} DistributionMapRow instance
         */
        DistributionMapRow.create = function create(properties) {
            return new DistributionMapRow(properties);
        };

        /**
         * Encodes the specified DistributionMapRow message. Does not implicitly {@link Map.DistributionMapRow.verify|verify} messages.
         * @function encode
         * @memberof Map.DistributionMapRow
         * @static
         * @param {Map.IDistributionMapRow} message DistributionMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DistributionMapRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.distribution != null && message.distribution.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.distribution.length; ++i)
                    writer.double(message.distribution[i]);
                writer.ldelim();
            }
            if (message.lastShelfAuditDate != null && Object.hasOwnProperty.call(message, "lastShelfAuditDate"))
                $root.google.protobuf.Timestamp.encode(message.lastShelfAuditDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DistributionMapRow message, length delimited. Does not implicitly {@link Map.DistributionMapRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.DistributionMapRow
         * @static
         * @param {Map.IDistributionMapRow} message DistributionMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DistributionMapRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DistributionMapRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.DistributionMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.DistributionMapRow} DistributionMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DistributionMapRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.DistributionMapRow();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.distribution && message.distribution.length))
                            message.distribution = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.distribution.push(reader.double());
                        } else
                            message.distribution.push(reader.double());
                        break;
                    }
                case 3: {
                        message.lastShelfAuditDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DistributionMapRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.DistributionMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.DistributionMapRow} DistributionMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DistributionMapRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DistributionMapRow message.
         * @function verify
         * @memberof Map.DistributionMapRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DistributionMapRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.distribution != null && message.hasOwnProperty("distribution")) {
                if (!Array.isArray(message.distribution))
                    return "distribution: array expected";
                for (var i = 0; i < message.distribution.length; ++i)
                    if (typeof message.distribution[i] !== "number")
                        return "distribution: number[] expected";
            }
            if (message.lastShelfAuditDate != null && message.hasOwnProperty("lastShelfAuditDate")) {
                var error = $root.google.protobuf.Timestamp.verify(message.lastShelfAuditDate);
                if (error)
                    return "lastShelfAuditDate." + error;
            }
            return null;
        };

        /**
         * Creates a DistributionMapRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.DistributionMapRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.DistributionMapRow} DistributionMapRow
         */
        DistributionMapRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.DistributionMapRow)
                return object;
            var message = new $root.Map.DistributionMapRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.distribution) {
                if (!Array.isArray(object.distribution))
                    throw TypeError(".Map.DistributionMapRow.distribution: array expected");
                message.distribution = [];
                for (var i = 0; i < object.distribution.length; ++i)
                    message.distribution[i] = Number(object.distribution[i]);
            }
            if (object.lastShelfAuditDate != null) {
                if (typeof object.lastShelfAuditDate !== "object")
                    throw TypeError(".Map.DistributionMapRow.lastShelfAuditDate: object expected");
                message.lastShelfAuditDate = $root.google.protobuf.Timestamp.fromObject(object.lastShelfAuditDate);
            }
            return message;
        };

        /**
         * Creates a plain object from a DistributionMapRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.DistributionMapRow
         * @static
         * @param {Map.DistributionMapRow} message DistributionMapRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DistributionMapRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.distribution = [];
            if (options.defaults) {
                object.id = 0;
                object.lastShelfAuditDate = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.distribution && message.distribution.length) {
                object.distribution = [];
                for (var j = 0; j < message.distribution.length; ++j)
                    object.distribution[j] = options.json && !isFinite(message.distribution[j]) ? String(message.distribution[j]) : message.distribution[j];
            }
            if (message.lastShelfAuditDate != null && message.hasOwnProperty("lastShelfAuditDate"))
                object.lastShelfAuditDate = $root.google.protobuf.Timestamp.toObject(message.lastShelfAuditDate, options);
            return object;
        };

        /**
         * Converts this DistributionMapRow to JSON.
         * @function toJSON
         * @memberof Map.DistributionMapRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DistributionMapRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DistributionMapRow
         * @function getTypeUrl
         * @memberof Map.DistributionMapRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DistributionMapRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.DistributionMapRow";
        };

        return DistributionMapRow;
    })();

    Map.DistributionMapTable = (function() {

        /**
         * Properties of a DistributionMapTable.
         * @memberof Map
         * @interface IDistributionMapTable
         * @property {Array.<Map.IDistributionMapRow>|null} [rows] DistributionMapTable rows
         */

        /**
         * Constructs a new DistributionMapTable.
         * @memberof Map
         * @classdesc Represents a DistributionMapTable.
         * @implements IDistributionMapTable
         * @constructor
         * @param {Map.IDistributionMapTable=} [properties] Properties to set
         */
        function DistributionMapTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DistributionMapTable rows.
         * @member {Array.<Map.IDistributionMapRow>} rows
         * @memberof Map.DistributionMapTable
         * @instance
         */
        DistributionMapTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new DistributionMapTable instance using the specified properties.
         * @function create
         * @memberof Map.DistributionMapTable
         * @static
         * @param {Map.IDistributionMapTable=} [properties] Properties to set
         * @returns {Map.DistributionMapTable} DistributionMapTable instance
         */
        DistributionMapTable.create = function create(properties) {
            return new DistributionMapTable(properties);
        };

        /**
         * Encodes the specified DistributionMapTable message. Does not implicitly {@link Map.DistributionMapTable.verify|verify} messages.
         * @function encode
         * @memberof Map.DistributionMapTable
         * @static
         * @param {Map.IDistributionMapTable} message DistributionMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DistributionMapTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.DistributionMapRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DistributionMapTable message, length delimited. Does not implicitly {@link Map.DistributionMapTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.DistributionMapTable
         * @static
         * @param {Map.IDistributionMapTable} message DistributionMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DistributionMapTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DistributionMapTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.DistributionMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.DistributionMapTable} DistributionMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DistributionMapTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.DistributionMapTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.DistributionMapRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DistributionMapTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.DistributionMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.DistributionMapTable} DistributionMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DistributionMapTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DistributionMapTable message.
         * @function verify
         * @memberof Map.DistributionMapTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DistributionMapTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.DistributionMapRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DistributionMapTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.DistributionMapTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.DistributionMapTable} DistributionMapTable
         */
        DistributionMapTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.DistributionMapTable)
                return object;
            var message = new $root.Map.DistributionMapTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.DistributionMapTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.DistributionMapTable.rows: object expected");
                    message.rows[i] = $root.Map.DistributionMapRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a DistributionMapTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.DistributionMapTable
         * @static
         * @param {Map.DistributionMapTable} message DistributionMapTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DistributionMapTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.DistributionMapRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this DistributionMapTable to JSON.
         * @function toJSON
         * @memberof Map.DistributionMapTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DistributionMapTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DistributionMapTable
         * @function getTypeUrl
         * @memberof Map.DistributionMapTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DistributionMapTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.DistributionMapTable";
        };

        return DistributionMapTable;
    })();

    Map.OrdersMapRow = (function() {

        /**
         * Properties of an OrdersMapRow.
         * @memberof Map
         * @interface IOrdersMapRow
         * @property {number|null} [id] OrdersMapRow id
         * @property {google.protobuf.ITimestamp|null} [lastOrderDate] OrdersMapRow lastOrderDate
         * @property {number|null} [orderStatusId] OrdersMapRow orderStatusId
         */

        /**
         * Constructs a new OrdersMapRow.
         * @memberof Map
         * @classdesc Represents an OrdersMapRow.
         * @implements IOrdersMapRow
         * @constructor
         * @param {Map.IOrdersMapRow=} [properties] Properties to set
         */
        function OrdersMapRow(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrdersMapRow id.
         * @member {number} id
         * @memberof Map.OrdersMapRow
         * @instance
         */
        OrdersMapRow.prototype.id = 0;

        /**
         * OrdersMapRow lastOrderDate.
         * @member {google.protobuf.ITimestamp|null|undefined} lastOrderDate
         * @memberof Map.OrdersMapRow
         * @instance
         */
        OrdersMapRow.prototype.lastOrderDate = null;

        /**
         * OrdersMapRow orderStatusId.
         * @member {number} orderStatusId
         * @memberof Map.OrdersMapRow
         * @instance
         */
        OrdersMapRow.prototype.orderStatusId = 0;

        /**
         * Creates a new OrdersMapRow instance using the specified properties.
         * @function create
         * @memberof Map.OrdersMapRow
         * @static
         * @param {Map.IOrdersMapRow=} [properties] Properties to set
         * @returns {Map.OrdersMapRow} OrdersMapRow instance
         */
        OrdersMapRow.create = function create(properties) {
            return new OrdersMapRow(properties);
        };

        /**
         * Encodes the specified OrdersMapRow message. Does not implicitly {@link Map.OrdersMapRow.verify|verify} messages.
         * @function encode
         * @memberof Map.OrdersMapRow
         * @static
         * @param {Map.IOrdersMapRow} message OrdersMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrdersMapRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.lastOrderDate != null && Object.hasOwnProperty.call(message, "lastOrderDate"))
                $root.google.protobuf.Timestamp.encode(message.lastOrderDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.orderStatusId != null && Object.hasOwnProperty.call(message, "orderStatusId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.orderStatusId);
            return writer;
        };

        /**
         * Encodes the specified OrdersMapRow message, length delimited. Does not implicitly {@link Map.OrdersMapRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.OrdersMapRow
         * @static
         * @param {Map.IOrdersMapRow} message OrdersMapRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrdersMapRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OrdersMapRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.OrdersMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.OrdersMapRow} OrdersMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrdersMapRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.OrdersMapRow();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.lastOrderDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.orderStatusId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OrdersMapRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.OrdersMapRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.OrdersMapRow} OrdersMapRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrdersMapRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OrdersMapRow message.
         * @function verify
         * @memberof Map.OrdersMapRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OrdersMapRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.lastOrderDate != null && message.hasOwnProperty("lastOrderDate")) {
                var error = $root.google.protobuf.Timestamp.verify(message.lastOrderDate);
                if (error)
                    return "lastOrderDate." + error;
            }
            if (message.orderStatusId != null && message.hasOwnProperty("orderStatusId"))
                if (!$util.isInteger(message.orderStatusId))
                    return "orderStatusId: integer expected";
            return null;
        };

        /**
         * Creates an OrdersMapRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.OrdersMapRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.OrdersMapRow} OrdersMapRow
         */
        OrdersMapRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.OrdersMapRow)
                return object;
            var message = new $root.Map.OrdersMapRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.lastOrderDate != null) {
                if (typeof object.lastOrderDate !== "object")
                    throw TypeError(".Map.OrdersMapRow.lastOrderDate: object expected");
                message.lastOrderDate = $root.google.protobuf.Timestamp.fromObject(object.lastOrderDate);
            }
            if (object.orderStatusId != null)
                message.orderStatusId = object.orderStatusId | 0;
            return message;
        };

        /**
         * Creates a plain object from an OrdersMapRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.OrdersMapRow
         * @static
         * @param {Map.OrdersMapRow} message OrdersMapRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OrdersMapRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.lastOrderDate = null;
                object.orderStatusId = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.lastOrderDate != null && message.hasOwnProperty("lastOrderDate"))
                object.lastOrderDate = $root.google.protobuf.Timestamp.toObject(message.lastOrderDate, options);
            if (message.orderStatusId != null && message.hasOwnProperty("orderStatusId"))
                object.orderStatusId = message.orderStatusId;
            return object;
        };

        /**
         * Converts this OrdersMapRow to JSON.
         * @function toJSON
         * @memberof Map.OrdersMapRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OrdersMapRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OrdersMapRow
         * @function getTypeUrl
         * @memberof Map.OrdersMapRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OrdersMapRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.OrdersMapRow";
        };

        return OrdersMapRow;
    })();

    Map.OrdersMapTable = (function() {

        /**
         * Properties of an OrdersMapTable.
         * @memberof Map
         * @interface IOrdersMapTable
         * @property {Array.<Map.IOrdersMapRow>|null} [rows] OrdersMapTable rows
         */

        /**
         * Constructs a new OrdersMapTable.
         * @memberof Map
         * @classdesc Represents an OrdersMapTable.
         * @implements IOrdersMapTable
         * @constructor
         * @param {Map.IOrdersMapTable=} [properties] Properties to set
         */
        function OrdersMapTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrdersMapTable rows.
         * @member {Array.<Map.IOrdersMapRow>} rows
         * @memberof Map.OrdersMapTable
         * @instance
         */
        OrdersMapTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new OrdersMapTable instance using the specified properties.
         * @function create
         * @memberof Map.OrdersMapTable
         * @static
         * @param {Map.IOrdersMapTable=} [properties] Properties to set
         * @returns {Map.OrdersMapTable} OrdersMapTable instance
         */
        OrdersMapTable.create = function create(properties) {
            return new OrdersMapTable(properties);
        };

        /**
         * Encodes the specified OrdersMapTable message. Does not implicitly {@link Map.OrdersMapTable.verify|verify} messages.
         * @function encode
         * @memberof Map.OrdersMapTable
         * @static
         * @param {Map.IOrdersMapTable} message OrdersMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrdersMapTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.OrdersMapRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified OrdersMapTable message, length delimited. Does not implicitly {@link Map.OrdersMapTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.OrdersMapTable
         * @static
         * @param {Map.IOrdersMapTable} message OrdersMapTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrdersMapTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OrdersMapTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.OrdersMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.OrdersMapTable} OrdersMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrdersMapTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.OrdersMapTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.OrdersMapRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OrdersMapTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.OrdersMapTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.OrdersMapTable} OrdersMapTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrdersMapTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OrdersMapTable message.
         * @function verify
         * @memberof Map.OrdersMapTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OrdersMapTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.OrdersMapRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates an OrdersMapTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.OrdersMapTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.OrdersMapTable} OrdersMapTable
         */
        OrdersMapTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.OrdersMapTable)
                return object;
            var message = new $root.Map.OrdersMapTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.OrdersMapTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.OrdersMapTable.rows: object expected");
                    message.rows[i] = $root.Map.OrdersMapRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an OrdersMapTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.OrdersMapTable
         * @static
         * @param {Map.OrdersMapTable} message OrdersMapTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OrdersMapTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.OrdersMapRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this OrdersMapTable to JSON.
         * @function toJSON
         * @memberof Map.OrdersMapTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OrdersMapTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OrdersMapTable
         * @function getTypeUrl
         * @memberof Map.OrdersMapTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OrdersMapTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.OrdersMapTable";
        };

        return OrdersMapTable;
    })();

    Map.AdditionalColumnsTable = (function() {

        /**
         * Properties of an AdditionalColumnsTable.
         * @memberof Map
         * @interface IAdditionalColumnsTable
         * @property {Array.<Map.IAdditionalColumnRow>|null} [rows] AdditionalColumnsTable rows
         */

        /**
         * Constructs a new AdditionalColumnsTable.
         * @memberof Map
         * @classdesc Represents an AdditionalColumnsTable.
         * @implements IAdditionalColumnsTable
         * @constructor
         * @param {Map.IAdditionalColumnsTable=} [properties] Properties to set
         */
        function AdditionalColumnsTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalColumnsTable rows.
         * @member {Array.<Map.IAdditionalColumnRow>} rows
         * @memberof Map.AdditionalColumnsTable
         * @instance
         */
        AdditionalColumnsTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new AdditionalColumnsTable instance using the specified properties.
         * @function create
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {Map.IAdditionalColumnsTable=} [properties] Properties to set
         * @returns {Map.AdditionalColumnsTable} AdditionalColumnsTable instance
         */
        AdditionalColumnsTable.create = function create(properties) {
            return new AdditionalColumnsTable(properties);
        };

        /**
         * Encodes the specified AdditionalColumnsTable message. Does not implicitly {@link Map.AdditionalColumnsTable.verify|verify} messages.
         * @function encode
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {Map.IAdditionalColumnsTable} message AdditionalColumnsTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalColumnsTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.AdditionalColumnRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdditionalColumnsTable message, length delimited. Does not implicitly {@link Map.AdditionalColumnsTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {Map.IAdditionalColumnsTable} message AdditionalColumnsTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalColumnsTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdditionalColumnsTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.AdditionalColumnsTable} AdditionalColumnsTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalColumnsTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.AdditionalColumnsTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.AdditionalColumnRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdditionalColumnsTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.AdditionalColumnsTable} AdditionalColumnsTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalColumnsTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdditionalColumnsTable message.
         * @function verify
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdditionalColumnsTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.AdditionalColumnRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdditionalColumnsTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.AdditionalColumnsTable} AdditionalColumnsTable
         */
        AdditionalColumnsTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.AdditionalColumnsTable)
                return object;
            var message = new $root.Map.AdditionalColumnsTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.AdditionalColumnsTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.AdditionalColumnsTable.rows: object expected");
                    message.rows[i] = $root.Map.AdditionalColumnRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdditionalColumnsTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {Map.AdditionalColumnsTable} message AdditionalColumnsTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdditionalColumnsTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.AdditionalColumnRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this AdditionalColumnsTable to JSON.
         * @function toJSON
         * @memberof Map.AdditionalColumnsTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdditionalColumnsTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdditionalColumnsTable
         * @function getTypeUrl
         * @memberof Map.AdditionalColumnsTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdditionalColumnsTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.AdditionalColumnsTable";
        };

        return AdditionalColumnsTable;
    })();

    Map.AdditionalColumnRow = (function() {

        /**
         * Properties of an AdditionalColumnRow.
         * @memberof Map
         * @interface IAdditionalColumnRow
         * @property {number|null} [id] AdditionalColumnRow id
         * @property {Object.<string,Map.IAdditionalColumnsSubValue>|null} [additionalColumnsValue] AdditionalColumnRow additionalColumnsValue
         */

        /**
         * Constructs a new AdditionalColumnRow.
         * @memberof Map
         * @classdesc Represents an AdditionalColumnRow.
         * @implements IAdditionalColumnRow
         * @constructor
         * @param {Map.IAdditionalColumnRow=} [properties] Properties to set
         */
        function AdditionalColumnRow(properties) {
            this.additionalColumnsValue = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalColumnRow id.
         * @member {number} id
         * @memberof Map.AdditionalColumnRow
         * @instance
         */
        AdditionalColumnRow.prototype.id = 0;

        /**
         * AdditionalColumnRow additionalColumnsValue.
         * @member {Object.<string,Map.IAdditionalColumnsSubValue>} additionalColumnsValue
         * @memberof Map.AdditionalColumnRow
         * @instance
         */
        AdditionalColumnRow.prototype.additionalColumnsValue = $util.emptyObject;

        /**
         * Creates a new AdditionalColumnRow instance using the specified properties.
         * @function create
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {Map.IAdditionalColumnRow=} [properties] Properties to set
         * @returns {Map.AdditionalColumnRow} AdditionalColumnRow instance
         */
        AdditionalColumnRow.create = function create(properties) {
            return new AdditionalColumnRow(properties);
        };

        /**
         * Encodes the specified AdditionalColumnRow message. Does not implicitly {@link Map.AdditionalColumnRow.verify|verify} messages.
         * @function encode
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {Map.IAdditionalColumnRow} message AdditionalColumnRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalColumnRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.additionalColumnsValue != null && Object.hasOwnProperty.call(message, "additionalColumnsValue"))
                for (var keys = Object.keys(message.additionalColumnsValue), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.Map.AdditionalColumnsSubValue.encode(message.additionalColumnsValue[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified AdditionalColumnRow message, length delimited. Does not implicitly {@link Map.AdditionalColumnRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {Map.IAdditionalColumnRow} message AdditionalColumnRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalColumnRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdditionalColumnRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.AdditionalColumnRow} AdditionalColumnRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalColumnRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.AdditionalColumnRow(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        if (message.additionalColumnsValue === $util.emptyObject)
                            message.additionalColumnsValue = {};
                        var end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            var tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.Map.AdditionalColumnsSubValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.additionalColumnsValue[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdditionalColumnRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.AdditionalColumnRow} AdditionalColumnRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalColumnRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdditionalColumnRow message.
         * @function verify
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdditionalColumnRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.additionalColumnsValue != null && message.hasOwnProperty("additionalColumnsValue")) {
                if (!$util.isObject(message.additionalColumnsValue))
                    return "additionalColumnsValue: object expected";
                var key = Object.keys(message.additionalColumnsValue);
                for (var i = 0; i < key.length; ++i) {
                    var error = $root.Map.AdditionalColumnsSubValue.verify(message.additionalColumnsValue[key[i]]);
                    if (error)
                        return "additionalColumnsValue." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdditionalColumnRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.AdditionalColumnRow} AdditionalColumnRow
         */
        AdditionalColumnRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.AdditionalColumnRow)
                return object;
            var message = new $root.Map.AdditionalColumnRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.additionalColumnsValue) {
                if (typeof object.additionalColumnsValue !== "object")
                    throw TypeError(".Map.AdditionalColumnRow.additionalColumnsValue: object expected");
                message.additionalColumnsValue = {};
                for (var keys = Object.keys(object.additionalColumnsValue), i = 0; i < keys.length; ++i) {
                    if (typeof object.additionalColumnsValue[keys[i]] !== "object")
                        throw TypeError(".Map.AdditionalColumnRow.additionalColumnsValue: object expected");
                    message.additionalColumnsValue[keys[i]] = $root.Map.AdditionalColumnsSubValue.fromObject(object.additionalColumnsValue[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdditionalColumnRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {Map.AdditionalColumnRow} message AdditionalColumnRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdditionalColumnRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.objects || options.defaults)
                object.additionalColumnsValue = {};
            if (options.defaults)
                object.id = 0;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            var keys2;
            if (message.additionalColumnsValue && (keys2 = Object.keys(message.additionalColumnsValue)).length) {
                object.additionalColumnsValue = {};
                for (var j = 0; j < keys2.length; ++j)
                    object.additionalColumnsValue[keys2[j]] = $root.Map.AdditionalColumnsSubValue.toObject(message.additionalColumnsValue[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this AdditionalColumnRow to JSON.
         * @function toJSON
         * @memberof Map.AdditionalColumnRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdditionalColumnRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdditionalColumnRow
         * @function getTypeUrl
         * @memberof Map.AdditionalColumnRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdditionalColumnRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.AdditionalColumnRow";
        };

        return AdditionalColumnRow;
    })();

    Map.CheckoutsTable = (function() {

        /**
         * Properties of a CheckoutsTable.
         * @memberof Map
         * @interface ICheckoutsTable
         * @property {Array.<Map.ICheckoutRow>|null} [rows] CheckoutsTable rows
         */

        /**
         * Constructs a new CheckoutsTable.
         * @memberof Map
         * @classdesc Represents a CheckoutsTable.
         * @implements ICheckoutsTable
         * @constructor
         * @param {Map.ICheckoutsTable=} [properties] Properties to set
         */
        function CheckoutsTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckoutsTable rows.
         * @member {Array.<Map.ICheckoutRow>} rows
         * @memberof Map.CheckoutsTable
         * @instance
         */
        CheckoutsTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new CheckoutsTable instance using the specified properties.
         * @function create
         * @memberof Map.CheckoutsTable
         * @static
         * @param {Map.ICheckoutsTable=} [properties] Properties to set
         * @returns {Map.CheckoutsTable} CheckoutsTable instance
         */
        CheckoutsTable.create = function create(properties) {
            return new CheckoutsTable(properties);
        };

        /**
         * Encodes the specified CheckoutsTable message. Does not implicitly {@link Map.CheckoutsTable.verify|verify} messages.
         * @function encode
         * @memberof Map.CheckoutsTable
         * @static
         * @param {Map.ICheckoutsTable} message CheckoutsTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckoutsTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.CheckoutRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CheckoutsTable message, length delimited. Does not implicitly {@link Map.CheckoutsTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.CheckoutsTable
         * @static
         * @param {Map.ICheckoutsTable} message CheckoutsTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckoutsTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CheckoutsTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.CheckoutsTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.CheckoutsTable} CheckoutsTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckoutsTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.CheckoutsTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.CheckoutRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CheckoutsTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.CheckoutsTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.CheckoutsTable} CheckoutsTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckoutsTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CheckoutsTable message.
         * @function verify
         * @memberof Map.CheckoutsTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CheckoutsTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.CheckoutRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CheckoutsTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.CheckoutsTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.CheckoutsTable} CheckoutsTable
         */
        CheckoutsTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.CheckoutsTable)
                return object;
            var message = new $root.Map.CheckoutsTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.CheckoutsTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.CheckoutsTable.rows: object expected");
                    message.rows[i] = $root.Map.CheckoutRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CheckoutsTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.CheckoutsTable
         * @static
         * @param {Map.CheckoutsTable} message CheckoutsTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CheckoutsTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.CheckoutRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this CheckoutsTable to JSON.
         * @function toJSON
         * @memberof Map.CheckoutsTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CheckoutsTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CheckoutsTable
         * @function getTypeUrl
         * @memberof Map.CheckoutsTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CheckoutsTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.CheckoutsTable";
        };

        return CheckoutsTable;
    })();

    Map.CheckoutRow = (function() {

        /**
         * Properties of a CheckoutRow.
         * @memberof Map
         * @interface ICheckoutRow
         * @property {number|null} [id] CheckoutRow id
         * @property {Map.ICheckoutEvolution|null} [checkoutEvolution] CheckoutRow checkoutEvolution
         */

        /**
         * Constructs a new CheckoutRow.
         * @memberof Map
         * @classdesc Represents a CheckoutRow.
         * @implements ICheckoutRow
         * @constructor
         * @param {Map.ICheckoutRow=} [properties] Properties to set
         */
        function CheckoutRow(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckoutRow id.
         * @member {number} id
         * @memberof Map.CheckoutRow
         * @instance
         */
        CheckoutRow.prototype.id = 0;

        /**
         * CheckoutRow checkoutEvolution.
         * @member {Map.ICheckoutEvolution|null|undefined} checkoutEvolution
         * @memberof Map.CheckoutRow
         * @instance
         */
        CheckoutRow.prototype.checkoutEvolution = null;

        /**
         * Creates a new CheckoutRow instance using the specified properties.
         * @function create
         * @memberof Map.CheckoutRow
         * @static
         * @param {Map.ICheckoutRow=} [properties] Properties to set
         * @returns {Map.CheckoutRow} CheckoutRow instance
         */
        CheckoutRow.create = function create(properties) {
            return new CheckoutRow(properties);
        };

        /**
         * Encodes the specified CheckoutRow message. Does not implicitly {@link Map.CheckoutRow.verify|verify} messages.
         * @function encode
         * @memberof Map.CheckoutRow
         * @static
         * @param {Map.ICheckoutRow} message CheckoutRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckoutRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.checkoutEvolution != null && Object.hasOwnProperty.call(message, "checkoutEvolution"))
                $root.Map.CheckoutEvolution.encode(message.checkoutEvolution, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CheckoutRow message, length delimited. Does not implicitly {@link Map.CheckoutRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.CheckoutRow
         * @static
         * @param {Map.ICheckoutRow} message CheckoutRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckoutRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CheckoutRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.CheckoutRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.CheckoutRow} CheckoutRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckoutRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.CheckoutRow();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.checkoutEvolution = $root.Map.CheckoutEvolution.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CheckoutRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.CheckoutRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.CheckoutRow} CheckoutRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckoutRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CheckoutRow message.
         * @function verify
         * @memberof Map.CheckoutRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CheckoutRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.checkoutEvolution != null && message.hasOwnProperty("checkoutEvolution")) {
                var error = $root.Map.CheckoutEvolution.verify(message.checkoutEvolution);
                if (error)
                    return "checkoutEvolution." + error;
            }
            return null;
        };

        /**
         * Creates a CheckoutRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.CheckoutRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.CheckoutRow} CheckoutRow
         */
        CheckoutRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.CheckoutRow)
                return object;
            var message = new $root.Map.CheckoutRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.checkoutEvolution != null) {
                if (typeof object.checkoutEvolution !== "object")
                    throw TypeError(".Map.CheckoutRow.checkoutEvolution: object expected");
                message.checkoutEvolution = $root.Map.CheckoutEvolution.fromObject(object.checkoutEvolution);
            }
            return message;
        };

        /**
         * Creates a plain object from a CheckoutRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.CheckoutRow
         * @static
         * @param {Map.CheckoutRow} message CheckoutRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CheckoutRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.checkoutEvolution = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.checkoutEvolution != null && message.hasOwnProperty("checkoutEvolution"))
                object.checkoutEvolution = $root.Map.CheckoutEvolution.toObject(message.checkoutEvolution, options);
            return object;
        };

        /**
         * Converts this CheckoutRow to JSON.
         * @function toJSON
         * @memberof Map.CheckoutRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CheckoutRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CheckoutRow
         * @function getTypeUrl
         * @memberof Map.CheckoutRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CheckoutRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.CheckoutRow";
        };

        return CheckoutRow;
    })();

    Map.AdditionalFieldsTable = (function() {

        /**
         * Properties of an AdditionalFieldsTable.
         * @memberof Map
         * @interface IAdditionalFieldsTable
         * @property {Array.<Map.IAdditionalFieldRow>|null} [rows] AdditionalFieldsTable rows
         */

        /**
         * Constructs a new AdditionalFieldsTable.
         * @memberof Map
         * @classdesc Represents an AdditionalFieldsTable.
         * @implements IAdditionalFieldsTable
         * @constructor
         * @param {Map.IAdditionalFieldsTable=} [properties] Properties to set
         */
        function AdditionalFieldsTable(properties) {
            this.rows = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalFieldsTable rows.
         * @member {Array.<Map.IAdditionalFieldRow>} rows
         * @memberof Map.AdditionalFieldsTable
         * @instance
         */
        AdditionalFieldsTable.prototype.rows = $util.emptyArray;

        /**
         * Creates a new AdditionalFieldsTable instance using the specified properties.
         * @function create
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {Map.IAdditionalFieldsTable=} [properties] Properties to set
         * @returns {Map.AdditionalFieldsTable} AdditionalFieldsTable instance
         */
        AdditionalFieldsTable.create = function create(properties) {
            return new AdditionalFieldsTable(properties);
        };

        /**
         * Encodes the specified AdditionalFieldsTable message. Does not implicitly {@link Map.AdditionalFieldsTable.verify|verify} messages.
         * @function encode
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {Map.IAdditionalFieldsTable} message AdditionalFieldsTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalFieldsTable.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (var i = 0; i < message.rows.length; ++i)
                    $root.Map.AdditionalFieldRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdditionalFieldsTable message, length delimited. Does not implicitly {@link Map.AdditionalFieldsTable.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {Map.IAdditionalFieldsTable} message AdditionalFieldsTable message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalFieldsTable.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdditionalFieldsTable message from the specified reader or buffer.
         * @function decode
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.AdditionalFieldsTable} AdditionalFieldsTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalFieldsTable.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.AdditionalFieldsTable();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.Map.AdditionalFieldRow.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdditionalFieldsTable message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.AdditionalFieldsTable} AdditionalFieldsTable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalFieldsTable.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdditionalFieldsTable message.
         * @function verify
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdditionalFieldsTable.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (var i = 0; i < message.rows.length; ++i) {
                    var error = $root.Map.AdditionalFieldRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdditionalFieldsTable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.AdditionalFieldsTable} AdditionalFieldsTable
         */
        AdditionalFieldsTable.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.AdditionalFieldsTable)
                return object;
            var message = new $root.Map.AdditionalFieldsTable();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".Map.AdditionalFieldsTable.rows: array expected");
                message.rows = [];
                for (var i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".Map.AdditionalFieldsTable.rows: object expected");
                    message.rows[i] = $root.Map.AdditionalFieldRow.fromObject(object.rows[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdditionalFieldsTable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {Map.AdditionalFieldsTable} message AdditionalFieldsTable
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdditionalFieldsTable.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (var j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.Map.AdditionalFieldRow.toObject(message.rows[j], options);
            }
            return object;
        };

        /**
         * Converts this AdditionalFieldsTable to JSON.
         * @function toJSON
         * @memberof Map.AdditionalFieldsTable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdditionalFieldsTable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdditionalFieldsTable
         * @function getTypeUrl
         * @memberof Map.AdditionalFieldsTable
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdditionalFieldsTable.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.AdditionalFieldsTable";
        };

        return AdditionalFieldsTable;
    })();

    Map.AdditionalFieldRow = (function() {

        /**
         * Properties of an AdditionalFieldRow.
         * @memberof Map
         * @interface IAdditionalFieldRow
         * @property {number|null} [id] AdditionalFieldRow id
         * @property {Object.<string,Map.IAdditionalColumnsSubValue>|null} [additionalFieldColumnsValue] AdditionalFieldRow additionalFieldColumnsValue
         */

        /**
         * Constructs a new AdditionalFieldRow.
         * @memberof Map
         * @classdesc Represents an AdditionalFieldRow.
         * @implements IAdditionalFieldRow
         * @constructor
         * @param {Map.IAdditionalFieldRow=} [properties] Properties to set
         */
        function AdditionalFieldRow(properties) {
            this.additionalFieldColumnsValue = {};
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdditionalFieldRow id.
         * @member {number} id
         * @memberof Map.AdditionalFieldRow
         * @instance
         */
        AdditionalFieldRow.prototype.id = 0;

        /**
         * AdditionalFieldRow additionalFieldColumnsValue.
         * @member {Object.<string,Map.IAdditionalColumnsSubValue>} additionalFieldColumnsValue
         * @memberof Map.AdditionalFieldRow
         * @instance
         */
        AdditionalFieldRow.prototype.additionalFieldColumnsValue = $util.emptyObject;

        /**
         * Creates a new AdditionalFieldRow instance using the specified properties.
         * @function create
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {Map.IAdditionalFieldRow=} [properties] Properties to set
         * @returns {Map.AdditionalFieldRow} AdditionalFieldRow instance
         */
        AdditionalFieldRow.create = function create(properties) {
            return new AdditionalFieldRow(properties);
        };

        /**
         * Encodes the specified AdditionalFieldRow message. Does not implicitly {@link Map.AdditionalFieldRow.verify|verify} messages.
         * @function encode
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {Map.IAdditionalFieldRow} message AdditionalFieldRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalFieldRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.additionalFieldColumnsValue != null && Object.hasOwnProperty.call(message, "additionalFieldColumnsValue"))
                for (var keys = Object.keys(message.additionalFieldColumnsValue), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.Map.AdditionalColumnsSubValue.encode(message.additionalFieldColumnsValue[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified AdditionalFieldRow message, length delimited. Does not implicitly {@link Map.AdditionalFieldRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {Map.IAdditionalFieldRow} message AdditionalFieldRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdditionalFieldRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdditionalFieldRow message from the specified reader or buffer.
         * @function decode
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Map.AdditionalFieldRow} AdditionalFieldRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalFieldRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Map.AdditionalFieldRow(), key, value;
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        if (message.additionalFieldColumnsValue === $util.emptyObject)
                            message.additionalFieldColumnsValue = {};
                        var end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            var tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.Map.AdditionalColumnsSubValue.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.additionalFieldColumnsValue[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdditionalFieldRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Map.AdditionalFieldRow} AdditionalFieldRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdditionalFieldRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdditionalFieldRow message.
         * @function verify
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdditionalFieldRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.additionalFieldColumnsValue != null && message.hasOwnProperty("additionalFieldColumnsValue")) {
                if (!$util.isObject(message.additionalFieldColumnsValue))
                    return "additionalFieldColumnsValue: object expected";
                var key = Object.keys(message.additionalFieldColumnsValue);
                for (var i = 0; i < key.length; ++i) {
                    var error = $root.Map.AdditionalColumnsSubValue.verify(message.additionalFieldColumnsValue[key[i]]);
                    if (error)
                        return "additionalFieldColumnsValue." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdditionalFieldRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Map.AdditionalFieldRow} AdditionalFieldRow
         */
        AdditionalFieldRow.fromObject = function fromObject(object) {
            if (object instanceof $root.Map.AdditionalFieldRow)
                return object;
            var message = new $root.Map.AdditionalFieldRow();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.additionalFieldColumnsValue) {
                if (typeof object.additionalFieldColumnsValue !== "object")
                    throw TypeError(".Map.AdditionalFieldRow.additionalFieldColumnsValue: object expected");
                message.additionalFieldColumnsValue = {};
                for (var keys = Object.keys(object.additionalFieldColumnsValue), i = 0; i < keys.length; ++i) {
                    if (typeof object.additionalFieldColumnsValue[keys[i]] !== "object")
                        throw TypeError(".Map.AdditionalFieldRow.additionalFieldColumnsValue: object expected");
                    message.additionalFieldColumnsValue[keys[i]] = $root.Map.AdditionalColumnsSubValue.fromObject(object.additionalFieldColumnsValue[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdditionalFieldRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {Map.AdditionalFieldRow} message AdditionalFieldRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdditionalFieldRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.objects || options.defaults)
                object.additionalFieldColumnsValue = {};
            if (options.defaults)
                object.id = 0;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            var keys2;
            if (message.additionalFieldColumnsValue && (keys2 = Object.keys(message.additionalFieldColumnsValue)).length) {
                object.additionalFieldColumnsValue = {};
                for (var j = 0; j < keys2.length; ++j)
                    object.additionalFieldColumnsValue[keys2[j]] = $root.Map.AdditionalColumnsSubValue.toObject(message.additionalFieldColumnsValue[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this AdditionalFieldRow to JSON.
         * @function toJSON
         * @memberof Map.AdditionalFieldRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdditionalFieldRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdditionalFieldRow
         * @function getTypeUrl
         * @memberof Map.AdditionalFieldRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdditionalFieldRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Map.AdditionalFieldRow";
        };

        return AdditionalFieldRow;
    })();

    return Map;
})();

$root.Tag = (function() {

    /**
     * Properties of a Tag.
     * @exports ITag
     * @interface ITag
     * @property {number|null} [id] Tag id
     * @property {string|null} [name] Tag name
     * @property {string|null} [color] Tag color
     */

    /**
     * Constructs a new Tag.
     * @exports Tag
     * @classdesc Represents a Tag.
     * @implements ITag
     * @constructor
     * @param {ITag=} [properties] Properties to set
     */
    function Tag(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Tag id.
     * @member {number} id
     * @memberof Tag
     * @instance
     */
    Tag.prototype.id = 0;

    /**
     * Tag name.
     * @member {string} name
     * @memberof Tag
     * @instance
     */
    Tag.prototype.name = "";

    /**
     * Tag color.
     * @member {string} color
     * @memberof Tag
     * @instance
     */
    Tag.prototype.color = "";

    /**
     * Creates a new Tag instance using the specified properties.
     * @function create
     * @memberof Tag
     * @static
     * @param {ITag=} [properties] Properties to set
     * @returns {Tag} Tag instance
     */
    Tag.create = function create(properties) {
        return new Tag(properties);
    };

    /**
     * Encodes the specified Tag message. Does not implicitly {@link Tag.verify|verify} messages.
     * @function encode
     * @memberof Tag
     * @static
     * @param {ITag} message Tag message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Tag.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.color != null && Object.hasOwnProperty.call(message, "color"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.color);
        return writer;
    };

    /**
     * Encodes the specified Tag message, length delimited. Does not implicitly {@link Tag.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Tag
     * @static
     * @param {ITag} message Tag message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Tag.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Tag message from the specified reader or buffer.
     * @function decode
     * @memberof Tag
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Tag} Tag
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Tag.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Tag();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int32();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.color = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Tag message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Tag
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Tag} Tag
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Tag.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Tag message.
     * @function verify
     * @memberof Tag
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Tag.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id))
                return "id: integer expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.color != null && message.hasOwnProperty("color"))
            if (!$util.isString(message.color))
                return "color: string expected";
        return null;
    };

    /**
     * Creates a Tag message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Tag
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Tag} Tag
     */
    Tag.fromObject = function fromObject(object) {
        if (object instanceof $root.Tag)
            return object;
        var message = new $root.Tag();
        if (object.id != null)
            message.id = object.id | 0;
        if (object.name != null)
            message.name = String(object.name);
        if (object.color != null)
            message.color = String(object.color);
        return message;
    };

    /**
     * Creates a plain object from a Tag message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Tag
     * @static
     * @param {Tag} message Tag
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Tag.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.id = 0;
            object.name = "";
            object.color = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.color != null && message.hasOwnProperty("color"))
            object.color = message.color;
        return object;
    };

    /**
     * Converts this Tag to JSON.
     * @function toJSON
     * @memberof Tag
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Tag.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Tag
     * @function getTypeUrl
     * @memberof Tag
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Tag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Tag";
    };

    return Tag;
})();

$root.Tags = (function() {

    /**
     * Properties of a Tags.
     * @exports ITags
     * @interface ITags
     * @property {Array.<ITag>|null} [tag] Tags tag
     */

    /**
     * Constructs a new Tags.
     * @exports Tags
     * @classdesc Represents a Tags.
     * @implements ITags
     * @constructor
     * @param {ITags=} [properties] Properties to set
     */
    function Tags(properties) {
        this.tag = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Tags tag.
     * @member {Array.<ITag>} tag
     * @memberof Tags
     * @instance
     */
    Tags.prototype.tag = $util.emptyArray;

    /**
     * Creates a new Tags instance using the specified properties.
     * @function create
     * @memberof Tags
     * @static
     * @param {ITags=} [properties] Properties to set
     * @returns {Tags} Tags instance
     */
    Tags.create = function create(properties) {
        return new Tags(properties);
    };

    /**
     * Encodes the specified Tags message. Does not implicitly {@link Tags.verify|verify} messages.
     * @function encode
     * @memberof Tags
     * @static
     * @param {ITags} message Tags message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Tags.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tag != null && message.tag.length)
            for (var i = 0; i < message.tag.length; ++i)
                $root.Tag.encode(message.tag[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Tags message, length delimited. Does not implicitly {@link Tags.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Tags
     * @static
     * @param {ITags} message Tags message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Tags.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Tags message from the specified reader or buffer.
     * @function decode
     * @memberof Tags
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Tags} Tags
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Tags.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Tags();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.tag && message.tag.length))
                        message.tag = [];
                    message.tag.push($root.Tag.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Tags message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Tags
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Tags} Tags
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Tags.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Tags message.
     * @function verify
     * @memberof Tags
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Tags.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tag != null && message.hasOwnProperty("tag")) {
            if (!Array.isArray(message.tag))
                return "tag: array expected";
            for (var i = 0; i < message.tag.length; ++i) {
                var error = $root.Tag.verify(message.tag[i]);
                if (error)
                    return "tag." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Tags message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Tags
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Tags} Tags
     */
    Tags.fromObject = function fromObject(object) {
        if (object instanceof $root.Tags)
            return object;
        var message = new $root.Tags();
        if (object.tag) {
            if (!Array.isArray(object.tag))
                throw TypeError(".Tags.tag: array expected");
            message.tag = [];
            for (var i = 0; i < object.tag.length; ++i) {
                if (typeof object.tag[i] !== "object")
                    throw TypeError(".Tags.tag: object expected");
                message.tag[i] = $root.Tag.fromObject(object.tag[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Tags message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Tags
     * @static
     * @param {Tags} message Tags
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Tags.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.tag = [];
        if (message.tag && message.tag.length) {
            object.tag = [];
            for (var j = 0; j < message.tag.length; ++j)
                object.tag[j] = $root.Tag.toObject(message.tag[j], options);
        }
        return object;
    };

    /**
     * Converts this Tags to JSON.
     * @function toJSON
     * @memberof Tags
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Tags.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Tags
     * @function getTypeUrl
     * @memberof Tags
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Tags.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Tags";
    };

    return Tags;
})();

module.exports = $root;
