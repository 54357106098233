import long_arrow from 'images/icons/import/long_arrow.svg';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getTranslate, Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { isSuperAdmin, useWindowDimensions } from '../../components_v2/utils';
import { ImportColumns, MatchedRowType, MatchedType, Model, Panel, RowType, SidelyRowStatus, TransformationType } from './model';
import swal from 'sweetalert2';
import storeLang from '../../helpers/storeLang';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { setRedisImage } from './actions';
import * as Antd from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { BlueSidely, GreySidely, RedSidely, SidelyBlack } from '../../styles/global/css/Utils';
import { useRecoilValue } from 'recoil';
import { AExternalMappings } from '../../atoms/global/mappings';
import { MappingResume } from '../../../../web-types/mapping';
import { FlexDiv } from '../products/style';
import { Open } from '../../styles/global/css/Open';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { ToolbarState } from '../globals/mainPage/mainPage';
import TableCellToolTip, { TableCellToolTipProps } from '../../components_v2/table/TableCellToolTip';
import { translateToNode } from '../../styles/global/translate';
import { Tax } from '../settings/models';
import { getTaxes } from '../settings/actions';

import CompanyBlack from 'images/menu_icon/company_black.svg';
import ContactBlack from 'images/menu_icon/contact_black.svg';
import OpportunityBlack from 'images/menu_icon/opportunity_black.svg';
import EventBlack from 'images/menu_icon/event_black.svg';
import ProductMenuBlack from 'images/menu_icon/product_menu_black.svg';
import OrderBoxBlack from 'images/menu_icon/order_box_black.svg';
import NotesBlack from 'images/menu_icon/notes_black.svg';
import AssortmentsBlack from 'images/setting_icons/assortments_black.svg';
import CheckoutBlack from 'images/menu_icon/checkout_black.svg';

import CompanyBlue from 'images/menu_icon/company_blue.svg';
import ContactBlue from 'images/menu_icon/contact_blue.svg';
import OpportunityBlue from 'images/menu_icon/opportunity_blue.svg';
import EventBlue from 'images/menu_icon/event_blue.svg';
import ProductMenuBlue from 'images/menu_icon/product_menu_blue.svg';
import OrderBoxBlue from 'images/menu_icon/order_box_blue.svg';
import NotesBlue from 'images/menu_icon/notes_blue.svg';
import AssortmentsBlue from 'images/setting_icons/assortments_blue.svg';
import CheckoutBlue from 'images/menu_icon/checkout_blue.svg';
import InfoAltImage from 'images/vendors/info-alt.svg';
import { AAdditionalColumns, AdditionalColumn } from '../../atoms/additionalColumns';

const Style = styled.div`
	h3 {
		font-size: 15px;
	}
`;

interface BaseList {
  value: string
  mandatory?: boolean
  translate?: boolean
  secondMatchingValue?: string
  matchingLink?: number
  unMatchingLink?: number
  category?: string
  notAlexandria?: boolean
  additionalValue?: unknown
  type?: TransformationType,
  label?: string
}

interface MatchingRow {
  value: string
  mandatory: boolean
  model: Model
  label: string | ReactJSXElement
  id: number
  unMatchingLink?: number
  secondMatchingValue: string
  matchingLink?: number
  translate: boolean
  category?: string
  notAlexandria?: boolean
  additionalValue?: unknown
  type?: TransformationType
}

const LongUpload = styled.div`
	.ant-btn {
		width: 100%;
	}
	.ant-upload {
		width: 568px;
		display: inline-block;
	}
`;

const UploadedColumn = styled.div<{ empty?: boolean }>`
	background: ${props => props.empty ? '#F5F5F5' : 'rgba(0, 178, 255, 0.08)'};
	height: 52px;
	width: calc(90% / 2);
	color: rgba(37, 65, 83, 0.8);
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding-left: 20px;
	border-radius: 5px;
	font-size: 13px;
`;

const MatchingCase = styled.div`
	border: 1px solid #C7C7C7;
	border-radius: 5px;
	height: 52px;
	width: 100%;
	padding: 15px 0 0 20px;
	font-size: 13px;
	color: rgba(37, 65, 83, 0.8);
	background-color: white;
	margin-top: 20px
`;

const MatchingContainer = styled.div<{ empty?: boolean }>`
	border: ${props => props.empty ? '1px' : '0px'} dashed #C7C7C7;
	opacity: ${props => props.empty ? '0.3' : '1'};
	width: calc(90% / 2);
	border-radius: 5px;
	height: 52px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding-left: ${p => p.empty ? '20' : '0'}px;
	font-size: 13px;
`;

const SidelyFieldsContainer = styled.div`
	width: 90%;
	margin: 0px 0 10px 5%;
	.collapse {
		margin: -20px 0 20px 0;
	}
`;

const ExplainaryText = styled.p`
	font-size: 13px;
	text-align: center;
	margin: 2em;
`;

function getLabel(model: Model, value: string, mandatory: boolean, translateString: boolean, to_string?: boolean): string | ReactJSXElement {
	const translate = getTranslate(storeLang.getState().localize);
	let imgSrc: string;
	switch (model) {
		case Model.Company:
			imgSrc = CompanyBlack;
			break;
		case Model.Contact:
			imgSrc = ContactBlack;
			break;
		case Model.Opportunity:
			imgSrc = OpportunityBlack;
			break;
		case Model.Event:
			imgSrc = EventBlack;
			break;
		case Model.Product:
			imgSrc = ProductMenuBlack;
			break;
		case Model.Order:
			imgSrc = OrderBoxBlack;
			break;
		case Model.Note:
			imgSrc = NotesBlack;
			break;
		case Model.Assortment:
			imgSrc = AssortmentsBlack;
			break;
		case Model.Checkout:
			imgSrc = CheckoutBlack;
	}
	const translatedString = translateString ? translate(`import.sidely_fields.${value}`) : value;
	if (to_string) {
		return `<p><img src="${imgSrc}" width="25px" /> ${translatedString}</p>`;
	} else {
		return (
			<>
				<img src={imgSrc} style={{ width: '25px', marginRight: '20px' }} />
				<p style={{ color: RedSidely, display: 'inline-block', marginRight: '5px' }}>{mandatory ? '*' : ''}</p>
				{translatedString}
			</>
		);
	}
}

export function getListFromModel(model: Model): MatchingRow[] {
	switch (model) {
		case Model.Company: return CompanyModel([], []);
		case Model.Contact: return ContactModel([]);
		case Model.Opportunity: return OpportunityModel([]);
		case Model.Event: return EventModel([]);
		case Model.Product: return ProductModel([], []);
		case Model.Note: return NoteModel([]);
		case Model.Order: return OrderModel([], []);
		case Model.Assortment: return AssortmentModel([]);
		case Model.Checkout: return CheckoutModel([]);
	}
}

export function CompanyModel(externalMappings: MappingResume[], additional_columns: AdditionalColumn[]): MatchingRow[] {
	const categories = ['company_informations', 'frequencies', 'company.columns.external_id', 'additional_columns'];
	const baseList: BaseList[] = [
		{ value: 'company_id', translate: true, category: categories[0], notAlexandria: true, type: TransformationType.CompanyId },
		{ value: 'company_name', mandatory: true, translate: true, category: categories[0] },
		{ value: 'status_name', mandatory: true, translate: true, category: categories[0] },
		{ value: 'billing_address', translate: true, category: categories[0] },
		{ value: 'city', translate: true, category: categories[0] },
		{ value: 'post_code', translate: true, category: categories[0] },
		{ value: 'country', translate: true, category: categories[0] },
		{ value: 'phone', translate: true, category: categories[0] },
		{ value: 'website', translate: true, category: categories[0] },
		{ value: 'email', translate: true, category: categories[0] },
		{ value: 'owner_name', mandatory: true, translate: true, category: categories[0] },
		{ value: 'latitude', translate: true, category: categories[0], mandatory: true },
		{ value: 'longitude', translate: true, category: categories[0], mandatory: true },
		{ value: 'tags', translate: true, category: categories[0] },
		{ value: 'task_frequency', translate: true, category: categories[1], notAlexandria: true },
		{ value: 'call_frequency', translate: true, category: categories[1], notAlexandria: true },
		{ value: 'meeting_frequency', translate: true, category: categories[1], notAlexandria: true },
		{ value: 'visit_frequency', translate: true, category: categories[1], notAlexandria: true },
		{ value: 'sms_frequency', translate: true, category: categories[1], notAlexandria: true },
		{ value: 'email_frequency', translate: true, category: categories[1], notAlexandria: true },
		{ value: 'animation_frequency', translate: true, category: categories[1], notAlexandria: true },
		{ value: 'promotion_frequency', translate: true, category: categories[1], notAlexandria: true },
	];
	externalMappings.filter(mapping => mapping.mapping_type_id == 1)
		.forEach(mapping => baseList.push({
			value: mapping.name,
			mandatory: false,
			translate: false,
			secondMatchingValue: 'external_id',
			category: categories[2],
			unMatchingLink: 1
		}));
	additional_columns.filter(ac => ac.type != 'ReportColumn').forEach(ac => {
		let type;
		switch (ac.type) {
			case 'Date': type = TransformationType.Date;
				break;
			default: type = undefined;
		}
		return baseList.push({
			value: '__ADDITIONAL_COLUMN__ ' + ac.id,
			label: ac.name,
			mandatory: false,
			translate: false,
			category: categories[3],
			notAlexandria: true,
			type
		});
	});
	// baseList.push({ value: 'tags', translate: true });
	return (baseList.map((value, id): MatchingRow => ({
		...value,
		mandatory: value.mandatory ?? false,
		model: Model.Company,
		label: getLabel(Model.Company, value.label ?? value.value, value.mandatory ?? false, value.translate ?? false),
		id,
		secondMatchingValue: value.secondMatchingValue ?? '',
		translate: value.translate ?? false,
	})));
}

function ContactModel(externalMappings: MappingResume[]): MatchingRow[] {
	const categories = ['contact_informations', 'company.columns.external_id'];
	const baseList: BaseList[] = [
		{ value: 'contact_id', translate: true, category: categories[0] },
		{ value: 'first_name', translate: true, mandatory: true, category: categories[0] },
		{ value: 'last_name', translate: true, mandatory: true, category: categories[0] },
		{ value: 'company_id', translate: true, category: categories[0], type: TransformationType.CompanyId },
		{ value: 'status_name', translate: true, mandatory: true, category: categories[0] },
		{ value: 'email', translate: true, category: categories[0] },
		{ value: 'phone', translate: true, category: categories[0] },
		{ value: 'position', translate: true, category: categories[0] },
		{ value: 'owner_name', translate: true, mandatory: true, category: categories[0] },
		{ value: 'picture', translate: true, category: categories[0] }
	];
	externalMappings.filter(mapping => mapping.mapping_type_id == 2)
		.forEach(mapping => baseList.push({
			value: mapping.name,
			secondMatchingValue: 'external_id',
			category: categories[1]
		}));
	baseList.push({ value: 'tags', translate: true, category: categories[0] });
	return (baseList.map((value, id): MatchingRow => ({
		...value,
		mandatory: value.mandatory ?? false,
		model: Model.Contact,
		label: getLabel(Model.Contact, value.value, value.mandatory ?? false, value.translate ?? false),
		id,
		secondMatchingValue: value.secondMatchingValue ?? '',
		translate: value.translate ?? false,
	})));
}

function OpportunityModel(_externalMappings: MappingResume[]): MatchingRow[] {
	return [];
}

function EventModel(externalMappings: MappingResume[]): MatchingRow[] {
	const categories = ['event_informations', 'company.columns.external_id', 'external_company_id'];
	const baseList: BaseList[] = [
		{ value: 'event_id', translate: true, category: categories[0] },
		{ value: 'title', translate: true, category: categories[0], mandatory: true },
		{ value: 'description', translate: true, category: categories[0] },
		{ value: 'start_date', translate: true, mandatory: true, category: categories[0], type: TransformationType.Date },
		{ value: 'end_date', translate: true, mandatory: true, category: categories[0], type: TransformationType.Date },
		{ value: 'event_type', translate: true, mandatory: true, category: categories[0] },
		{ value: 'status_name', translate: true, mandatory: true, category: categories[0] },
		{ value: 'owner_name', translate: true, mandatory: true, category: categories[0] },
		{ value: 'company_id', translate: true, category: categories[0], type: TransformationType.CompanyId },
		{ value: 'contact_id', translate: true, category: categories[0] },
		{ value: 'opportunity_id', translate: true, category: categories[0] },
	];
	externalMappings.filter(mapping => mapping.mapping_type_id === 6)
		.forEach(mapping => baseList.push({ value: mapping.name, translate: false, secondMatchingValue: 'external_id', matchingLink: 2, category: categories[1] }));
	externalMappings.filter(mapping => mapping.mapping_type_id === 1)
		.forEach(mapping => baseList.push({ value: mapping.name, translate: false, secondMatchingValue: 'external_company', matchingLink: 2, category: categories[2] }));
	return baseList.map((value: BaseList, id): MatchingRow => ({
		...value,
		mandatory: value.mandatory ?? false,
		model: Model.Event,
		label: getLabel(Model.Event, value.value, value.mandatory ?? false, value.translate ?? false),
		id,
		secondMatchingValue: value.secondMatchingValue ?? '',
		translate: value.translate ?? false,
	}));
}

function ProductModel(externalMappings: MappingResume[], taxes: Tax[]): MatchingRow[] {
	const categories = ['product_informations', 'company.columns.external_id', 'taxes'];
	const baseList: BaseList[] = [
		{ value: 'product_id', category: categories[0], translate: true, type: TransformationType.ProductId },
		{ value: 'product_name', category: categories[0], mandatory: true, translate: true },
		{ value: 'reference', category: categories[0], mandatory: true, translate: true },
		{ value: 'category', category: categories[0], translate: true, mandatory: true },
		{ value: 'barcode', category: categories[0], mandatory: true, translate: true },
		{ value: 'code_upc', category: categories[0], mandatory: true, translate: true },
		{ value: 'brand', category: categories[0], translate: true },
		{ value: 'stock', category: categories[0], translate: true },
		{ value: 'width', category: categories[0], translate: true },
		{ value: 'height', category: categories[0], translate: true },
		{ value: 'depth', category: categories[0], translate: true },
		{ value: 'price', category: categories[0], translate: true },
		{ value: 'recommended_price', category: categories[0], translate: true },
		// { value: 'tax', category: categories[0], translate: true },
		{ value: 'outer', category: categories[0], translate: true },
		{ value: 'summary', category: categories[0], translate: true },
		{ value: 'description', category: categories[0], translate: true },
		{ value: 'status_name', category: categories[0], translate: true },
		{ value: 'picture', category: categories[0], translate: true },
		{ value: 'tags', translate: true, category: categories[0] }
	];
	taxes.forEach(tax => {
		baseList.push({
			value: tax.name,
			category: categories[2],
			secondMatchingValue: 'tax',
			additionalValue: {
				id: tax.id,
				header_key: tax.name
			}
		});
	});
	externalMappings.filter(mapping => mapping.mapping_type_id == 3)
		.forEach(mapping => baseList.push({
			value: mapping.name,
			secondMatchingValue: 'external_id',
			category: categories[1]
		}));
	return (baseList.map((value, id): MatchingRow => ({
		...value,
		value: value.value,
		mandatory: value.mandatory ?? false,
		model: Model.Product,
		label: getLabel(Model.Product, value.value, value.mandatory ?? false, value.translate ?? false),
		id,
		secondMatchingValue: value.secondMatchingValue ?? '',
		translate: value.translate ?? false,
	})));
}

function NoteModel(_: MappingResume[]): MatchingRow[] {
	return ([
		{ value: 'note_id', translate: true },
		{ value: 'title', translate: true, mandatory: true },
		{ value: 'body', translate: true, mandatory: true },
		{ value: 'contact_id', translate: true, matchingLink: 1 },
		{ value: 'company_id', translate: true, matchingLink: 1, type: TransformationType.CompanyId },
		{ value: 'opportunity_id', translate: true, matchingLink: 1 },
		{ value: 'owner_name', translate: true, mandatory: true }
	].map((value: BaseList, id): MatchingRow => ({
		...value,
		mandatory: value.mandatory ?? false,
		model: Model.Note,
		label: getLabel(Model.Note, value.value, value.mandatory ?? false, value.translate ?? false),
		id,
		secondMatchingValue: value.secondMatchingValue ?? '',
		translate: value.translate ?? false,
	})));
}

function OrderModel(externalMappings: MappingResume[], taxes: Tax[]): MatchingRow[] {
	const categories = ['order_informations', 'order_product_informations', 'taxes', 'company.columns.external_id', 'external_company_id'];
	const baseList: BaseList[] = [
		// Order
		{ value: 'order_id', category: categories[0], translate: true, matchingLink: 1 },
		{ value: 'order_reference', category: categories[0], translate: true },
		{ value: 'company_id', category: categories[0], matchingLink: 2, translate: true, type: TransformationType.CompanyId },
		{ value: 'owner_name', category: categories[0], mandatory: true, translate: true },
		{ value: 'contact_id', category: categories[0], translate: true },
		{ value: 'opportunity_id', category: categories[0], translate: true },
		{ value: 'order_discount_number', category: categories[0], translate: true },
		{ value: 'order_discount_type', category: categories[0], translate: true },
		{ value: 'billing_address', category: categories[0], translate: true },
		{ value: 'delivery_address', category: categories[0], translate: true },
		{ value: 'payment_name', category: categories[0], translate: true },
		{ value: 'status_name', category: categories[0], mandatory: true, translate: true },
		{ value: 'comment', category: categories[0], translate: true },
		{ value: 'order_date', category: categories[0], translate: true, type: TransformationType.Date },
		// OrderProduct
		{ value: 'order_product_id', category: categories[1], translate: true },
		{ value: 'product_id', category: categories[1], mandatory: true, translate: true, type: TransformationType.ProductId },
		{ value: 'product_quantity', category: categories[1], mandatory: true, translate: true },
		{ value: 'product_discount_number', category: categories[1], translate: true },
		{ value: 'product_discount_type', category: categories[1], translate: true },
		{ value: 'product_price', category: categories[1], translate: true }
	];
	taxes.forEach(tax => {
		baseList.push({
			value: tax.name,
			category: categories[2],
			secondMatchingValue: 'tax',
			additionalValue: {
				id: tax.id,
				header_key: tax.name
			}
		});
	});
	externalMappings.filter(mapping => mapping.mapping_type_id == 4)
		.forEach(mapping => baseList.push({ value: mapping.name, translate: false, secondMatchingValue: 'external_id', matchingLink: 1, category: categories[3] }));
	externalMappings.filter(mapping => mapping.mapping_type_id == 1)
		.forEach(mapping => baseList.push({ value: mapping.name, translate: false, secondMatchingValue: 'external_company', matchingLink: 2, category: categories[4] }));
	return (baseList.map((value, id): MatchingRow => ({
		...value,
		mandatory: value.mandatory ?? false,
		model: Model.Order,
		label: getLabel(Model.Order, value.value, value.mandatory ?? false, value.translate ?? false),
		id,
		secondMatchingValue: value.secondMatchingValue ?? '',
		translate: value.translate ?? false,
	})));
}

function AssortmentModel(externalMappings: MappingResume[]): MatchingRow[] {
	const categories = ['assortments', 'assortment_products', 'company.columns.external_id'];
	const baseList: BaseList[] = [
		{ value: 'assortment_id', category: categories[0], translate: true },
		{ value: 'name', category: categories[0], mandatory: true, translate: true },
		{ value: 'distributor_reference', category: categories[1], translate: true },
		{ value: 'product_id', category: categories[1], translate: true, mandatory: true, type: TransformationType.ProductId },
		{ value: 'price', category: categories[1], mandatory: true, translate: true },
		{ value: 'recommended_price', category: categories[1], mandatory: true, translate: true }
	];
	externalMappings.filter(mapping => mapping.mapping_type_id == 5)
		.forEach(mapping => baseList.push({
			value: mapping.name,
			secondMatchingValue: 'external_id',
			category: categories[2]
		}));
	return (baseList.map((value, id): MatchingRow => {
		return {
			...value,
			mandatory: value.mandatory ?? false,
			model: Model.Assortment,
			label: getLabel(Model.Assortment, value.value, value.mandatory ?? false, value.translate ?? false),
			id,
			secondMatchingValue: value.secondMatchingValue ?? '',
			translate: value.translate ?? false,
		};
	}));
}

function CheckoutModel(externalMappings: MappingResume[]): MatchingRow[] {
	const categories = ['product_informations', 'pricing', 'company.columns.external_id', 'company_informations'];
	const baseList: BaseList[] = [
		{ value: 'company_id', translate: true, category: categories[3], type: TransformationType.CompanyId },
		{ value: 'company_name', category: categories[3], mandatory: true, translate: true },
		{ value: 'post_code', category: categories[3], translate: true },
		{ value: 'billing_address', category: categories[3], translate: true },
		{ value: 'date', category: categories[0], translate: true, type: TransformationType.Date, mandatory: true },
		{ value: 'barcode', category: categories[0], translate: true, mandatory: true },
		{ value: 'unit', category: categories[0], translate: true },
		{ value: 'product_unit_number', category: categories[0], translate: true },
		{ value: 'consumer_sales_unit', category: categories[0], translate: true },
		{ value: 'cumulative_consumer_sales_unit', category: categories[0], translate: true },
		{ value: 'volumetric_quantities', category: categories[0], translate: true },
		{ value: 'cumulative_volumetric_quantities', category: categories[0], translate: true },
		{ value: 'sum_with_tax', category: categories[1], translate: true },
		{ value: 'sum_without_tax', category: categories[1], translate: true },
		{ value: 'cumulative_sum_with_tax', category: categories[1], translate: true },
		{ value: 'cumulative_sum_without_tax', category: categories[1], translate: true },
		{ value: 'average_price', category: categories[1], translate: true },
		{ value: 'average_price_evolution', category: categories[1], translate: true },
	];

	externalMappings.filter(mapping => mapping.mapping_type_id == 1)
		.forEach(mapping => baseList.push({
			value: mapping.name,
			secondMatchingValue: 'external_id',
			category: categories[2],
			matchingLink: 1
		}));
	return (baseList.map((value, id): MatchingRow => {
		return {
			...value,
			mandatory: value.mandatory ?? false,
			model: Model.Checkout,
			label: getLabel(Model.Checkout, value.value, value.mandatory ?? false, value.translate ?? false),
			id,
			secondMatchingValue: value.secondMatchingValue ?? '',
			translate: value.translate ?? false,
		};
	}));
}


function getModelName(model: Model): string {
	switch (model) {
		case Model.Company:
			return 'companies';
		case Model.Contact:
			return 'contacts';
		case Model.Opportunity:
			return 'opportunities';
		case Model.Event:
			return 'events';
		case Model.Product:
			return 'products';
		case Model.Order:
			return 'orders_';
		case Model.Note:
			return 'notes';
		case Model.Assortment:
			return 'assortments';
		case Model.Checkout:
			return 'checkout';
	}
}

function getModelListName(model?: Model): string | undefined {
	switch (model) {
		case undefined: return undefined;
		case Model.Company:
			return 'companyList';
		case Model.Contact:
			return 'contactList';
		case Model.Opportunity:
			return 'opportunityList';
		case Model.Event:
			return 'eventList';
		case Model.Product:
			return 'productList';
		case Model.Order:
			return 'orderList';
		case Model.Note:
			return 'noteList';
		case Model.Assortment:
			return 'assortmentList';
		case Model.Checkout:
			return 'checkoutList';
	}
}

function toComparativeString(str: string): string {
	return Array.from(str.trim().toLowerCase()).reduce((acc, c) => c == ' ' || c == '_' ? acc : acc + c, '');
}

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

async function reduce_image_file_size(base64Str, MAX_WIDTH = 300, MAX_HEIGHT = 300) {
	const resized_base64 = await new Promise((resolve) => {
		const img = new Image();
		img.src = base64Str;
		img.onload = () => {
			const canvas = document.createElement('canvas');
			let width = img.width;
			let height = img.height;

			if (width > height) {
				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}
			canvas.width = width;
			canvas.height = height;
			const ctx = canvas.getContext('2d');
			ctx?.drawImage(img, 0, 0, width, height);
			resolve(canvas.toDataURL()); // this will return base64 image results after resize
		};
	});
	return resized_base64;
}

type MatchingDeplacement<T = any> = {
	function: (r: T[]) => void,
	list: T[],
	index: number,
	splice: number,
	replace?: boolean
};

function textContent(elem: React.ReactElement | string): string {
	if (!elem) {
		return '';
	}
	if (typeof elem === 'string') {
		return elem;
	}
	const children = elem.props && elem.props.children;
	if (children instanceof Array) {
		return children.map(textContent).join('');
	}
	return textContent(children);
}

export default function Matching(props: {
  columns: ImportColumns[]
  setColumns: (columns: ImportColumns[]) => void
  setPanel: (panel: Panel) => void
  matchedList: MatchedRowType
  setMatchedList: (list: MatchedRowType) => void
  data: RowType[]
  setData: (data: RowType[]) => void
  images: React.MutableRefObject<object[]>
  setImages: (images: object[]) => void
  setToolBarState: (value: ToolbarState) => void
}) {
	const translate = getTranslate(storeLang.getState().localize);
	const { height } = useWindowDimensions();
	const externalMappings = useRecoilValue(AExternalMappings);
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const [taxes, setTaxes] = React.useState<Tax[]>([]);
	const [companyList, setCompanyList] = React.useState<MatchingRow[]>([...CompanyModel(externalMappings, additionalColumns)]);
	const [contactList, setContactList] = React.useState<MatchingRow[]>([...ContactModel(externalMappings)]);
	const [opportunityList, setOpportunityList] = React.useState<MatchingRow[]>([...OpportunityModel(externalMappings)]);
	const [eventList, setEventList] = React.useState<MatchingRow[]>([...EventModel(externalMappings)]);
	const [productList, setProductList] = React.useState<MatchingRow[]>([...ProductModel(externalMappings, taxes)]);
	const [orderList, setOrderList] = React.useState<MatchingRow[]>([...OrderModel(externalMappings, taxes)]);
	const [noteList, setNoteList] = React.useState<MatchingRow[]>([...NoteModel(externalMappings)]);
	const [assortmentList, setAssortmentList] = React.useState<MatchingRow[]>([...AssortmentModel(externalMappings)]);
	const [checkoutList, setCheckoutList] = React.useState<MatchingRow[]>([...CheckoutModel(externalMappings)]);
	const [tmpMatch, setTmpMatch] = React.useState<MatchedType[]>(Array(props.columns.length).fill(undefined));
	const [model, setModel] = React.useState<Model>();
	const [firstLine, setFirstLine] = React.useState<boolean>(true);
	const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
	const { setMatchedList, images, setImages } = props;

	React.useEffect(() => {
		getTaxes().then(taxes => {
			setTaxes(taxes);
			setProductList([...ProductModel(externalMappings, taxes)]);
			setOrderList([...OrderModel(externalMappings, taxes)]);
		}).catch(console.error);
	}, []);

	function handleChange(info) {
		if (info.file.status === 'uploading') {
			return;
		} else if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, loadedFile => {
				reduce_image_file_size(loadedFile).then((result) => {
					let index = images.current.findIndex(e => e['name'] == info.file.name);
					index = index >= 0 ? index : images.current.length;
					images.current[index] = {};
					images.current[index]['file'] = result;
					images.current[index]['name'] = info.file.name;
					setImages([...images.current]);
				});
			});
		} else if (info.file.status === 'removed') {
			const index = images.current.findIndex(e => e['name'] == info.file.name);
			images.current.splice(index, 1);
			setImages([...images.current]);
		}
	}

	function matchList() {
		const snakeCaseList = props.columns.map(e => toComparativeString(e.Header));
		console.log(getModelList(model));
		const modelList = getModelList(model).map(e => {
			return ({
				raw: toComparativeString(e.value),
				translated: toComparativeString(translate(`import.sidely_fields.${e.value}`) as string),
				label: toComparativeString(textContent(e.label)),
				id: e.id
			});
		});
		snakeCaseList.forEach((title, i) => {
			if (tmpMatch[i]) {
				return;
			}
			for (const obj of modelList) {
				if (title == obj.raw || title == obj.translated || title == obj.label) {
					const source_index = getModelList(model).findIndex(e => e.id == obj.id);
					if (source_index < 0) {
						return;
					}
					handleOnDragEnd({
						destination: {
							droppableId: `MatchingListContainer-${i}`,
							index: 0
						},
						source: {
							droppableId: getModelListName(model),
							index: source_index
						}
					});
					return;
				}
			}
		});
	}

	function getModelList(model?: Model): MatchingRow[] {
		switch (model) {
			case undefined: return [];
			case Model.Company:
				return companyList;
			case Model.Contact:
				return contactList;
			case Model.Opportunity:
				return opportunityList;
			case Model.Event:
				return eventList;
			case Model.Product:
				return productList;
			case Model.Order:
				return orderList;
			case Model.Note:
				return noteList;
			case Model.Assortment:
				return assortmentList;
			case Model.Checkout:
				return checkoutList;
		}
	}

	function getModelFunction(model: Model): (rows: MatchingRow[]) => void {
		switch (model) {
			case Model.Company:
				return setCompanyList;
			case Model.Contact:
				return setContactList;
			case Model.Opportunity:
				return setOpportunityList;
			case Model.Event:
				return setEventList;
			case Model.Product:
				return setProductList;
			case Model.Order:
				return setOrderList;
			case Model.Note:
				return setNoteList;
			case Model.Assortment:
				return setAssortmentList;
			case Model.Checkout:
				return setCheckoutList;
		}
	}

	function handleOnDragEnd(result) {
		if (!result.destination) {
			return;
		}
		let source: MatchingDeplacement | undefined = undefined;
		switch (result.source.droppableId.split('-')[0]) {
			case 'companyList':
				source = { function: setCompanyList, list: companyList, index: result.source.index, splice: 1 };
				break;
			case 'contactList':
				source = { function: setContactList, list: contactList, index: result.source.index, splice: 1 };
				break;
			case 'opportunityList':
				source = { function: setOpportunityList, list: opportunityList, index: result.source.index, splice: 1 };
				break;
			case 'eventList':
				source = { function: setEventList, list: eventList, index: result.source.index, splice: 1 };
				break;
			case 'productList':
				source = { function: setProductList, list: productList, index: result.source.index, splice: 1 };
				break;
			case 'orderList':
				source = { function: setOrderList, list: orderList, index: result.source.index, splice: 1 };
				break;
			case 'noteList':
				source = { function: setNoteList, list: noteList, index: result.source.index, splice: 1 };
				break;
			case 'assortmentList':
				source = { function: setAssortmentList, list: assortmentList, index: result.source.index, splice: 1 };
				break;
			case 'MatchingListContainer':
				source = { function: setTmpMatch, list: tmpMatch, index: result.source.droppableId.split('-')[1], splice: 1, replace: true };
				break;
			case 'checkoutList':
				source = { function: setCheckoutList, list: checkoutList, index: result.source.index, splice: 1 };
				break;	
		}
		if (!source) return;
		let destination: object = {};
		const [reorderedItem] = source.replace ? source.list.splice(source.index, source.splice, undefined) : source.list.splice(source.index, source.splice);
		switch (result.destination.droppableId.split('-')[0]) {
			case 'MatchingListContainer':
				destination = { function: setTmpMatch, list: tmpMatch, index: result.destination.droppableId.split('-')[1], splice: 1 };
				break;
			default:
				destination = { function: getModelFunction(reorderedItem.model), list: getModelList(reorderedItem.model), index: 0, splice: 0 };
		}
		destination['list'].splice(destination['index'], destination['splice'], reorderedItem);
		if (destination['splice'] === 0) {
			destination['list'].sort((a, b) => a.id - b.id);
		}
		destination['function']([...destination['list']]);
		source.function([...source.list]);
	}

	function createFinalDatas() {
		const new_match: { [key: string]: MatchedType } = tmpMatch.reduce((acc, elem, i) => {
			if (elem) {
				acc[`${props.columns[i].Header}`] = elem;
			}
			return acc;
		}, {});
		let new_data: RowType[] = props.data.map(row => {
			const ret = { SidelyRow: row.SidelyRow };
			for (const [key, v] of Object.entries(new_match)) {
				ret[key] = { ...row[key], type: v.type };
			}
			return ret;
		});
		const new_columns = props.columns.filter(col => Object.keys(new_match).includes(col.Header));
		if (!firstLine) {
			const new_matching_fl = {};
			const new_columns_fl: { Header: string, accessor: string }[] = [];
			let i = 0;
			for (const [_, value] of Object.entries(new_match)) {
				new_matching_fl[value.value] = value;
				new_columns_fl[i] = { Header: value.value, accessor: value.value };
				i += 1;
			}
			new_data = [props.columns.reduce((acc, e) => {
				acc[e.Header] = { value: e.Header, status: SidelyRowStatus.Edited };
				return acc;
			}, {
				SidelyRow: { value: -1, status: SidelyRowStatus.Edited }
			})].concat(new_data);
			props.setData(new_data
				.map(e => {
					const ret = {
						SidelyRow: {
							value: e.SidelyRow.value as number + 1,
							status: e.SidelyRow.status,
							type: e.SidelyRow.type
						}
					};
					for (const [key, value] of Object.entries(new_match)) {
						ret[value.value] = e[key];
					}
					return ret;
				}));
			props.setColumns(new_columns_fl);
			setMatchedList(new_matching_fl);
		} else {
			setMatchedList(new_match);
			props.setData(new_data);
			props.setColumns(new_columns);
		}
	}

	function isMandatoryMissing() {
		return tmpMatch
			.reduce((acc: Model[], match) => {
				if (match && match.model !== undefined) {
					if (!acc.includes(match.model)) {
						acc.push(match.model);
					}
				}
				return acc;
			}, [])
			.reduce((acc: string[], model) => {
				return [...acc, ...getModelList(model).filter(e => e.mandatory).map(e => getLabel(model, e.value, false, e.translate, true))];
			}, []);
	}

	function getContradictoryList(): string[] {
		const model = tmpMatch.find(match => match?.model !== undefined)?.model;
		if (model == undefined) {
			return [];
		}
		return tmpMatch.reduce((acc: string[], row) => {
			if (!row?.unMatchingLink || tmpMatch.reduce((acc, r) => acc + (r?.unMatchingLink === row.unMatchingLink ? 1 : 0), 0) < 2) return acc;
			if (!acc[row.unMatchingLink]) acc[row.unMatchingLink] = '';
			acc[row.unMatchingLink] = acc[row.unMatchingLink] + getLabel(model ?? 0, row.value, false, row.translate, true);
			return acc;
		}, []);
	}

	function isLinkMissing(): string[] {
		const model = tmpMatch.find(match => match?.model !== undefined)?.model;
		if (model == undefined) {
			return [];
		}
		return getModelList(model)?.reduce((acc: string[], row) => {
			if (row.matchingLink !== undefined) {
				if (tmpMatch.find(match => match?.matchingLink == row.matchingLink) == undefined) {
					if (acc[row.matchingLink] == undefined) {
						acc[row.matchingLink] = '';
					}
					acc[row.matchingLink] = acc[row.matchingLink] + getLabel(model ?? 0, row.value, false, row.translate, true);
				}
			}
			return acc;
		}, []);
	}

	async function uploadImages(skip?: boolean) {
		if (!skip && tmpMatch.some(e => e?.value == 'picture')) {
			setModalOpen(true);
		} else {
			if (images.current.length > 0) {
				for (const sub_list of images.current.reduce((acc: { lst: object[][], i: number }, img) => {
					if (acc.lst[acc.i].length == 5) {
						acc.i += 1;
						acc.lst[acc.i] = [];
					}
					acc.lst[acc.i].push(img);
					return acc;
				}, { lst: [[]], i: 0 })['lst']) {
					await setRedisImage(sub_list);
				}
			}
			createFinalDatas();
			props.setPanel(Panel.TableView);
		}
	}

	props.setToolBarState({
		bottomLeftToolbarComponent: <>
			<img src={InfoAltImage} width={15} style={{ display: 'inline-block' }} />
			<p style={{ fontSize: '12px', display: 'inline-block', margin: 0, marginLeft: '10px' }}>
				<Translate id="import.match_fields_from_file_with_sidely" />
			</p>
		</>,
		bottomRightToolbarComponent: <>
			<Button
				style={{
					height: 42,
					background: '#FFFFFF',
					border: `2px solid ${BlueSidely}`,
					color: BlueSidely,
					borderRadius: '4px',
					width: '12em',
					margin: '0 1em'
				}}
				onClick={() => {
					setCompanyList([...CompanyModel(externalMappings, additionalColumns)]);
					setContactList([...ContactModel(externalMappings)]);
					setOpportunityList([...OpportunityModel(externalMappings)]);
					setEventList([...EventModel(externalMappings)]);
					setProductList([...ProductModel(externalMappings, taxes)]);
					setOrderList([...OrderModel(externalMappings, taxes)]);
					setNoteList([...NoteModel(externalMappings)]);
					setAssortmentList([...AssortmentModel(externalMappings)]);
					setCheckoutList([...CheckoutModel(externalMappings)]);
					setModel(undefined);
					setTmpMatch([]);
					props.setMatchedList({});
					props.setPanel(Panel.UploadView);
				}}
			>
				<Translate id='global.cancel' />
			</Button>
			<Button
				style={{
					height: 42,
					background: BlueSidely,
					color: '#FFFFFF',
					borderRadius: '4px',
					width: '12em',
					margin: '0 1em'
				}}
				onClick={() => {
					const mandatoryList = isMandatoryMissing();
					const linkList = isLinkMissing();
					const contradictoryList = getContradictoryList();
					if (!tmpMatch.some(e => e !== undefined)) {
						// @ts-expect-error error
						swal.fire({
							title: translate('global.error') + ' !',
							text: translate('import.please_match_one_element'),
							type: 'error',
							confirmButtonColor: RedSidely,
							confirmButtonText: 'Ok'
						});
					} else if (mandatoryList.length > 0) {
						// @ts-expect-error error
						swal.fire({
							title: translate('global.error') + ' !',
							html: `<p>${translate('import.theses_mandatory_fields_not_match')}</p>` +
								mandatoryList.reduce((acc: string, model) => acc + model, ''),
							type: 'error',
							confirmButtonColor: RedSidely,
							confirmButtonText: 'Ok'
						});
					} else if (contradictoryList.length > 0) {
						// @ts-expect-error error
						swal.fire({
							title: translate('global.error') + ' !',
							html: `<p>${translate('import.theses_fields_are_incompatible')}</p>` +
								contradictoryList.reduce((acc: string, model) => {
									if (model == undefined) { return acc; } else if (acc.length == 0) { return model; }
									return acc + `<p>${translate('import.and_one_of')}</p>` + model;
								}, ''),
							type: 'error',
							confirmButtonColor: RedSidely,
							confirmButtonText: 'Ok'
						});
					} else if (linkList.length > 0) {
						// @ts-expect-error error
						swal.fire({
							title: translate('global.error') + ' !',
							html: `<p>${translate('import.theses_fields_are_linked')}</p>` +
								linkList.reduce((acc: string, model) => {
									if (model == undefined) { return acc; } else if (acc.length == 0) { return model; }
									return acc + `<p>${translate('import.and_one_of')}</p>` + model;
								}, ''),
							type: 'error',
							confirmButtonColor: RedSidely,
							confirmButtonText: 'Ok'
						});
					} else if (tmpMatch.some(e => e === undefined)) {
						const unMatchedList = tmpMatch.reduce((acc, e, i) => {
							if (e === undefined) {
								acc += '<p>- ' + props.columns[i].Header + '</p>';
							}
							return acc;
						}, '');
							// @ts-expect-error warning
						swal.fire({
							title: 'Attention !',
							html: `<p>${translate('import.theses_fields_not_match')}</p>` +
								`${unMatchedList}` +
								`<p>${translate('import.do_you_want_to_continue')}</p>`,
							type: 'warning',
							showCancelButton: true,
							cancelButtonColor: RedSidely,
							cancelButtonText: translate('global.cancel'),
							confirmButtonColor: BlueSidely,
							confirmButtonText: translate('global.yes'),
							reverseButtons: true
						})
							.then(result => {
								if (result.value) {
									uploadImages();
								}
							});
					} else {
						uploadImages();
					}
				}}
			>
				<Translate id='next' />
			</Button>
		</>
	});

	return (
		<Style>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<Droppable droppableId="board" isDropDisabled>
					{provided => (
						<div style={{ margin: '0px 0 0 3px', display: 'flex' }} ref={provided.innerRef} {...provided.droppableProps}>
							<div style={{ width: '59%', marginRight: '1%', height: height - 150, display: 'inline-block', backgroundColor: 'white' }} >
								<div style={{ height: '60px' }}>
									<h3 style={{ marginLeft: '25px', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
										<Translate id="import.fields_from_your_file" />
									</h3>
								</div>
								<div className="form-group check-sidely" style={{ marginLeft: '10px' }}>
									<label className="ml-3" key={'checkBoxFistLine'}>
										<input
											name={'checkBoxFistLine'}
											id={'checkBoxFistLine'}
											type="checkbox"
											checked={firstLine}
											onChange={() => setFirstLine(!firstLine)}
										/>
										<div className="checkbox-style" />
										<span className="ml-4"><Translate id="import.first_line" /></span>
									</label>
								</div>
								<div style={{ overflow: 'auto', height: height - 250 }}>
									{props.columns.map((raw_column, index) => {
										const column = raw_column.Header;
										const empty = tmpMatch[index] === undefined;
										return (
											(<FlexDiv key={`ToMatch[${index}]`} width='100%' gap='3%' padding='1% 2%'>
												<UploadedColumn empty={empty}>
													{column}
												</UploadedColumn>
												<img src={long_arrow} width={'10%'} />
												<Droppable droppableId={`MatchingListContainer-${index}`} isDropDisabled={tmpMatch[index] !== undefined} index={index}>
													{(dropProvided) => (
														<MatchingContainer innerRef={dropProvided.innerRef} empty={empty}>
															{empty && <Translate id="import.dnd_sidely_field_here" />}
															<Draggable
																draggableId={`MatchingList-${index}`}
																index={index}
															>
																{(dragProvided, dragSnapshot) => (
																	<div
																		ref={dragProvided.innerRef}
																		{...dragProvided.draggableProps}
																		{...dragProvided.dragHandleProps}
																		// eslint-disable-next-line react/no-unknown-property
																		isdragging={`${dragSnapshot.isDragging}`}
																		style={{ ...dragProvided.draggableProps.style, position: empty ? 'relative' : '', marginTop: '-20px', width: '100%' }}
																	>
																		{empty
																			? (
																				<></>
																			)
																			: (
																				<MatchingCase>
																					{tmpMatch[index] !== undefined && tmpMatch[index].label}
																				</MatchingCase>
																			)}
																	</div>

																)}

															</Draggable>
														</MatchingContainer>
													)}
												</Droppable>
											</FlexDiv>)
										);
									})}
								</div>
							</div>
							<div style={{ width: '40%', display: 'inline-block', backgroundColor: 'white', height: height - 150 }}>
								<FlexDiv height='60px' justify='space-between'>
									<h3 className='col-5' style={{ margin: 0, marginLeft: '25px' }}>
										<Translate id="import.sidely_fields.sidely_fields" />
									</h3>
									<FlexDiv
										gap='5px'
										style={{
											color: model === undefined ? GreySidely : BlueSidely,
											cursor: model === undefined ? 'not-allowed' : 'pointer',
											marginRight: 35,
											fontSize: 12
										}}
										onClick={matchList}>
										<Translate id='import.auto_match' />
										<div style={{ fontSize: '1.5em', lineHeight: '15px', marginTop: '-3px' }}>
                                            ⇋
										</div>
									</FlexDiv>
								</FlexDiv>
								<hr style={{ width: '100%', margin: '0' }} />
								<NavBar setModel={setModel} model={model} matching={tmpMatch} />
								<hr style={{ width: '100%', margin: '0', marginBottom: '0.5rem' }} />
								{model === undefined && <ExplainaryText>{translateToNode('choose_category_above')}</ExplainaryText>}
								<div style={{ overflow: 'auto', height: height - 300 }}>
									<Droppable droppableId={getModelListName(model)} >
										{(dropProvided) => (
											<SidelyFieldsContainer innerRef={dropProvided.innerRef}>
												{Object.entries(
													getModelList(model)
														.reduce((acc: { [key: string]: MatchingRow[] }, matching) => {
															const category = matching.category ?? 'no_category';
															if (!acc[category]) {
																acc[category] = [matching];
																return acc;
															}
															acc[category].push(matching);
															return acc;
														}, { 'no_category': [] })
												).reduce(([acc, index]: [JSX.Element[], number], [key, matchingCases]) => [
													[acc, <><CategorySplitter title={key} rows={matchingCases} model={model} index={index} /></>],
													index + matchingCases.length
												], [[], 0])[0]}
											</SidelyFieldsContainer>
										)}
									</Droppable>
								</div>
							</div>
						</div>
					)}
				</Droppable>
				<Modal
					isOpen={isModalOpen}
					toggle={() => setModalOpen(!isModalOpen)}
					centered
				>
					<ModalHeader><Translate id="import.upload_pictures" /></ModalHeader>
					<ModalBody>
						<LongUpload>
							<Antd.Upload
								listType="picture"
								maxCount={props.data.length}
								accept='.png,.jpeg,.jpg'
								multiple
								onChange={handleChange}
								customRequest={({ onSuccess }) => {
									setTimeout(() => {
										onSuccess?.('ok');
									}, 0);
								}}
							>
								<Antd.Button icon={<UploadOutlined />}><Translate id="import.upload_pictures" /></Antd.Button>
							</Antd.Upload>
						</LongUpload>
					</ModalBody>
					<ModalFooter>
						<Button
							style={{
								height: 42,
								background: BlueSidely,
								color: '#FFFFFF',
								borderRadius: '4px'
							}}
							onClick={async() => await uploadImages(true)}>Ok</Button>
					</ModalFooter>
				</Modal>
			</DragDropContext>
		</Style>
	);
}

const OpenContainer = styled.div`
	${DefaultText};
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 15px;
	gap: 10px;
	justify-content: space-between;
	padding: 10px;
	color: ${SidelyBlack};
	cursor: pointer;
	top: 0px;
	background: white;
	position: sticky;
`;

function CategorySplitter(props: { title: string, rows: MatchingRow[], model?: Model, index: number }) {
	const [isOpen, setIsOpen] = React.useState(true);

	return <>
		{props.title !== 'no_category' && 
			<OpenContainer onClick={() => setIsOpen(!isOpen)}>
				<Translate id={props.title} />
				<Open isOpen={isOpen} width={17} height={16} />
			</OpenContainer>
		}
		<Collapse isOpen={isOpen}>
			{props.rows.map((matchingCase, index) => 
				<Draggable
					key={matchingCase.id}
					draggableId={`${getModelListName(props.model)}-${matchingCase.id}`}
					index={props.index + index}
				>
					{(dragProvided, dragSnapshot) => (
						<div
							ref={dragProvided.innerRef}
							{...dragProvided.draggableProps}
							{...dragProvided.dragHandleProps}
							// eslint-disable-next-line react/no-unknown-property
							isdragging={`${dragSnapshot.isDragging}`}
						>
							<MatchingCase>
								{matchingCase.label}
							</MatchingCase>
						</div>
					)}
				</Draggable>
			)}
		</Collapse>
	</>;
}

function NavBar(props: {
  setModel: (model: Model) => void
  model?: Model
  matching: MatchedType[]
}) {
	const [hovered, setHovered] = React.useState<TableCellToolTipProps>();
	const admin = isSuperAdmin();

	function buttonImage(localModel: Model, disabled?: boolean) {
		let imgSrc: string;
		switch (localModel) {
			case Model.Company:
				imgSrc = localModel === props.model ? CompanyBlue : CompanyBlack;
				break;
			case Model.Contact:
				imgSrc = localModel === props.model ? ContactBlue : ContactBlack;
				break;
			case Model.Opportunity:
				imgSrc = localModel === props.model ? OpportunityBlue : OpportunityBlack;
				break;
			case Model.Event:
				imgSrc = localModel === props.model ? EventBlue : EventBlack;
				break;
			case Model.Product:
				imgSrc = localModel === props.model ? ProductMenuBlue : ProductMenuBlack;
				break;
			case Model.Order:
				imgSrc = localModel === props.model ? OrderBoxBlue : OrderBoxBlack;
				break;
			case Model.Note:
				imgSrc = localModel === props.model ? NotesBlue : NotesBlack;
				break;
			case Model.Assortment:
				imgSrc = localModel === props.model ? AssortmentsBlue : AssortmentsBlack;
				break;
			case Model.Checkout:
				imgSrc = localModel === props.model ? CheckoutBlue : CheckoutBlack;
				break;
		}
		return (
			<img
				onMouseEnter={e => {
					// @ts-expect-error It works
					const bounds = e.target.getBoundingClientRect?.();
					if (!bounds) return;
					setHovered({
						bounds,
						content: translateToNode(getModelName(localModel))
					});
				}}
				onMouseLeave={() => {
					setHovered(undefined);
				}}
				src={imgSrc}
				style={{ cursor: disabled ? 'not-allowed' : 'pointer', width: '30px' }}
				onClick={() => disabled ? {} : props.setModel(localModel)} />
		);
	}

	return <>
		<FlexDiv height='70px' justify='space-evenly'>
			{buttonImage(Model.Company, props.matching.some(e => e && e.model !== Model.Company))}
			{buttonImage(Model.Contact, props.matching.some(e => e && e.model !== Model.Contact))}
			{/* {buttonImage(Model.Opportunity, props.matching.some(e => e && e.model !== Model.Opportunity) || true)} */}
			{buttonImage(Model.Event, props.matching.some(e => e && e.model !== Model.Event))}
			{buttonImage(Model.Product, props.matching.some(e => e && e.model !== Model.Product))}
			{buttonImage(Model.Order, props.matching.some(e => e && e.model !== Model.Order))}
			{buttonImage(Model.Note, props.matching.some(e => e && e.model !== Model.Note))}
			{buttonImage(Model.Assortment, props.matching.some(e => e && e.model !== Model.Assortment))}
			{admin && buttonImage(Model.Checkout, props.matching.some(e => e && e.model !== Model.Checkout))}
		</FlexDiv>
		{hovered && <TableCellToolTip {...hovered} center />}
	</>;
}