import * as React from 'react';
import { isSuperAdmin } from '../components_v2/utils';
import { FlexDiv } from '../containers_v2/products/style';
import calendarImage from 'images/icon/small_calendar2.svg';
import CalendarImageGrey from 'images/icon/small_calendar_grey.svg';
import ListViewImage from 'images/ui_icon/listView_black.svg';
import ListViewImageGrey from 'images/ui_icon/listView_grey.svg';

export type viewType = 'list' | 'calendar' | 'list_beta'

interface View {
  view: viewType
  activeUrl: string
  innactiveUrl: string
  admin?: boolean
}

export function ViewSwitch(props: { dispatch: ({ type, data }) => void, value?: viewType }) {
	const [view, setView] = React.useState<viewType>(props.value ?? 'calendar');
	const isAdmin = isSuperAdmin();

	function setViewAndDispatch(e: viewType) {
		setView(e);
		props.dispatch({ type: 'view', data: e });
		localStorage.setItem('calendarView', e);
	}

	const viewList: View[] = [
		{ view: 'calendar', activeUrl: calendarImage, innactiveUrl: CalendarImageGrey },
		{ view: 'list', activeUrl: ListViewImage, innactiveUrl: ListViewImageGrey }
	];

	return (
		<FlexDiv gap='10px'>
			{viewList.map((current_view, i) => {
				if (current_view.admin && !isAdmin) return <></>;
				return <div
					key={`ViewSwitch[${i}]`}
					onClick={() => setViewAndDispatch(current_view.view)}
					style={{ cursor: 'pointer' }}
				>
					<img
						src={
							view === current_view.view
								? current_view.activeUrl
								: current_view.innactiveUrl
						}
						className="custom-icon-views"
						alt=""
					/>
				</div>;
			})}
		</FlexDiv>
	);
}
