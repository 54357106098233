import add_blueImage from 'images/icon/add_blue.png';
import eyeImage from 'images/icons/company/eye.svg';
import productImage from 'images/icon/systemSettings/product.svg';
import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { useRecoilValue } from 'recoil';
import swal from 'sweetalert2';
import { AInputSearch } from '../../atoms/filter/InputSearchFilter';
import { ATagFilter } from '../../atoms/filter/tagsFilterAtom';
import { AtomCategory } from '../../atoms/utils/model/Model';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import { Checkbox } from '../../components_v2/filterList/style/Style';
import PageLoader from '../../components_v2/pageLoader/PageLoader';
import Pagination from '../../components_v2/pagination/Pagination';
import { Table, TableSortType } from '../../components_v2/table/Table';
import ToolbarFilter, { ToolbarElement } from '../../components_v2/toolbarFilter/ToolbarFilter';
import { getUrlParameterByName, removeAllUrlParameters } from '../../components_v2/utils';
import storeLang from '../../helpers/storeLang';
import { ToolbarImage } from '../globals/defaultToolbar/style/Style';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { LoadingState } from '../import/model';
import Restricted from '../permissions/Restricted';
import { formatCurrency } from '../reports/utils';
import { exportExcel, get_products, getBrands, getCategories, updateProduct, updateProducts } from './action';
import { ModalState, Product } from './model';
import ProductPopup from './product';
import { Container, FlexDiv, Link, LoaderContainer, ProductPicture, Status, TABLE_IMAGE_PADDING, TABLE_IMAGE_WIDTH } from './style';
import { useHistory } from 'react-router';
import ProductCreation from './productCreation';
import { PartialOpen } from '../client-companies/style/Style';
import { TableRow } from '../orders/templateOrders/style/Style';
import { DarkGreySidely, LightBlueSidely, RedSidely } from '../../styles/global/css/Utils';
import ModalLeft from '../../components_v2/modalLeft/ModalLeft';
import ProductModalLeft, { HierarchyData, HierarchyDataToData, QuickFilterData, QuickFilterResult } from './modalLeft/ProductModalLeft';
import { getAssortments } from '../orders/data/Data';
import NoData, { Unhautorized } from '../noData/NoData';
import usePermission from '../permissions/usePermission';
import optionGrey from 'images/icon/options_grey.png';
import SystemSettingIcon from 'images/setting_icons/system_setting_icon.svg';
import { translateToString } from '../../styles/global/translate';
import noImage from 'images/no-image.jpg';


const DEFAULT_LIMIT = 50;

function productcolumns(products: Product[], setProducts: (products: Product[]) => void, refresh: () => void, openProduct: (product: Product, full: boolean) => void) {
	const translate = getTranslate(storeLang.getState().localize);
	const DELETE = 1;
	const all_checked = products.every(row => row.checked);
	const list = [
		{
			label: translate('global.quick_action.delete').toString(),
			value: DELETE
		}
	];
	return [
		{
			id: 'select',
			Header: <Checkbox isActive={all_checked} onClick={() => {
				if (all_checked) {
					setProducts(products.map(p => ({ ...p, checked: false })));
				} else {
					setProducts(products.map(p => ({ ...p, checked: true })));
				}
			}} />,
			accessor: (row: Product) => {
				return (
					<Checkbox isActive={row.checked ?? false} onClick={() => {
						const index = products.findIndex(p => p.id == row.id);
						if (index >= 0) {
							products[index].checked = !(row.checked ?? false);
							setProducts([...products]);
						}
					}} />
				);
			},
			width: 40,
			disableSortBy: true,
			unresizeable: true
		},
		{
			id: 'photo',
			Header: ' ',
			accessor: (row: Product) => <Link centered onClick={() => openProduct(row, true)} background={row.photos_url_id?.[0]?.[0] ?? noImage} ></Link>,
			disableSortBy: true,
			width: TABLE_IMAGE_WIDTH,
			padding:TABLE_IMAGE_PADDING.DEFAULT,
			unresizeable: true
		},
		{
			id: 'lower(p.name)',
			Header: <Translate id='name' />,
			accessor: (row: Product) => <>
				<TableRow onClick={() => openProduct(row, true)} fontWeight="500" color={DarkGreySidely} cursor="pointer">
					{row.name ?? '-'}
				</TableRow>
				<PartialOpen src={eyeImage} onClick={() => openProduct(row, false)} />
			</>,
			width: undefined
		},
		{
			id: 'lower(reference)',
			Header: <Translate id='reference' />,
			accessor: (row: Product) => <TableRow>{row.reference ?? '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'lower(c.name)',
			Header: <Translate id='category' />,
			accessor: (row: Product) => <TableRow>{row.category_name ?? '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'price',
			Header: <Translate id='price' />,
			accessor: (row: Product) => <TableRow>{row.price ? formatCurrency(row.price) : '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'recommended_price',
			Header: <Translate id='recommended price' />,
			accessor: (row: Product) => <TableRow>{row.recommended_price ? formatCurrency(row.recommended_price) : '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'stock',
			Header: <Translate id='stock' />,
			accessor: (row: Product) => <TableRow>{row.stock ?? '-'}</TableRow>,
			width: undefined
		},
		{
			id: 'status_id',
			Header: <Translate id='Avaibility' />,
			accessor: (row: Product) => <Status color={row.status_color_code}>{row.status_name ?? '-'}</Status>,
			width: undefined
		},
		{
			id: 'actions',
			Header: <img src={SystemSettingIcon} style={{ width: 18 }} />,
			accessor: (row: Product) => <Restricted to={{ objectAction: 'DeleteCatalog' }}>
				<Dropdown
					name={'quick_action'}
					datalist={list}
					readOnly
					dropdownStyle={{ width: '10px', optionWidth: '100px', height: '30px', fontSize: 13, optionLeft: '-70px' }}
					JSXButton={() => <img
						src={optionGrey}
						width={25}
						height={25}
						style={{ transform: 'rotate(90deg)', marginLeft: '-3px' }} />}
					onChange={(value: DropdownData) => {
						switch (value.value) {
							case DELETE:
								// @ts-expect-error swal
								swal.fire({
									title: 'Attention!',
									text: translate('global.fire.delete_message').toString().replace('{{NAME}}', `'${row.name}'`),
									type: 'warning',
									confirmButtonText: translate('global.yes'),
									confirmButtonColor: RedSidely
								})
									.then(result => {
										if (result.value) {
											updateProduct(row.id, { deleted: true })
												.then(refresh)
												.catch(console.log);
										}
									});
								break;
						}
					} } />
			</Restricted>,
			width: 40,
			disableSortBy: true,
			unresizeable: true
		}
	];
}

export default function ProductListing(props: {
  setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [products, setProducts] = React.useState<Product[]>([]);
	const [loadingState, setLoadingState] = React.useState<LoadingState>('loading');
	const [pagination, setPagination] = React.useState({ step: DEFAULT_LIMIT, offset: 0 });
	const [max, setMax] = React.useState<number>(0);
	const [sort, setSort] = React.useState<TableSortType | undefined>(undefined);
	const [modalState, setModalState] = React.useState<ModalState>({ isOpen: false });
	const [isCreationOpen, setIsCreationOpen] = React.useState<boolean>(false);
	const exportCreatable = usePermission({ objectAction: 'CreateCatalog' });
	const massDeletable = usePermission({ objectAction: 'DeleteCatalog' });

	const translate = getTranslate(storeLang.getState().localize);

	const [quickFilterResultData, setQuickFilterResultData] = React.useState<QuickFilterResult>({
		catalogues: [],
		brands: [],
		categories: []
	});
	const [quickFilterData, setQuickFilterData] = React.useState<QuickFilterData>({
		catalogues: [],
		brands: [],
		categories: []
	});

	const inputSearchText = useRecoilValue(AInputSearch);
	const tags = useRecoilValue(ATagFilter);
	const history = useHistory();

	function checkUrlParams() {
		const productId = getUrlParameterByName('id');
		const mode = getUrlParameterByName('mode');
		if (productId) {
			switch (mode) {
				case 'detail':
					setModalState({ isOpen: true, fullOpenMode: false, data: { productId } });
					break;
				case 'default':
				default:
					setModalState({ isOpen: true, fullOpenMode: true, data: { productId } });
					break;
			}
		}
	}

	function categoriesToHierarchyData(data: any[]): HierarchyData[] {
		return data.map(d => {
			return {
				id: d.id,
				label: d.name,
				value: d.id,
				checked: true,
				children: d.children ? categoriesToHierarchyData(d.children) : []
			} as HierarchyData;
		});
	}

	function resetCategories(data: HierarchyData[]): HierarchyData[] {
		return data.map(d => {
			return {
				...d,
				children: d.children ? resetCategories(d.children) : [],
				checked: true
			};
		});
	}

	function resetQuickFilter(): void {
		setQuickFilterData({
			brands: quickFilterData.brands.map(b => { return { ...b, checked: true }; }),
			catalogues: quickFilterData.catalogues.map(b => { return { ...b, checked: true }; }),
			categories: resetCategories(quickFilterData.categories),
		});
		//TODO refresh request
	}

	React.useEffect(() => {

		let count = 3;
		let filters: QuickFilterResult = { brands: [], catalogues: [], categories: [] };
		getBrands().then(res => {
			const result = [{ id: -1, label: translate('global.modal.no_brands').toString(), value: -1, checked: true }, ...res.data.map(d => {
				return { id: d.id, label: d.name, value: d.id, checked: true };
			})];
			setQuickFilterData((prev) => {
				return { ...prev, brands: result };
			});
			filters = { ...filters, brands: result };

			count = count - 1;
			if (count <= 0) {
				setQuickFilterResultData(filters);
			}
		});

		getAssortments().then(res => {
			const result = [{ id: -1, label: translate('global.modal.no_assortments').toString(), value: -1, checked: true }, ...res.map(d => {
				return { id: d.id, label: d.name, value: d.id, checked: true };
			})];
			setQuickFilterData((prev) => {
				return { ...prev, catalogues: result };
			});
			filters = { ...filters, catalogues: result };

			count = count - 1;
			if (count <= 0) {
				setQuickFilterResultData(filters);
			}
		});

		getCategories().then(res => {
			const result = [{ id: -1, label: translate('global.modal.no_categories').toString(), value: -1, checked: true }, ...categoriesToHierarchyData(res.data)];
			setQuickFilterData((prev) => {
				return { ...prev, categories: result };
			});
			filters = { ...filters, categories: HierarchyDataToData(result) };

			count = count - 1;
			if (count <= 0) {
				setQuickFilterResultData(filters);
			}
		});
	}, []);

	React.useEffect(() => {
		checkUrlParams();
		history.listen(checkUrlParams);
	}, [history]);

	React.useEffect(() => {
		const data: DropdownData[] = [];
		if (massDeletable) data.push({ label: <Translate id='Delete selection' />, value: 'delete' });
		if (exportCreatable) data.push({ label: 'export', value: 'export' });

		props.setToolBarState({
			title: translate('products') as string,
			bottomLeftToolbarComponent: <>
				<ToolbarFilter
					category={AtomCategory.PRODUCTS}
					elements={[
						{
							kind: ToolbarElement.TAG_FILTER,
							tagType: 'product'
						}
					]}
				/>
				{loadingState == 'loading' && <LoaderContainer><PageLoader size={20} /></LoaderContainer>}
			</>,
			bottomRightToolbarComponent: <>
				<ToolbarFilter
					category={AtomCategory.PRODUCTS}
					elements={[
						ToolbarElement.INPUT_SEARCH
					]}
				/>
				<Restricted to={{ objectAction: 'CreateCatalog' }}>
					<ToolbarImage
						marginLeft='10px'
						width='25px'
						height='25px'
						hasPointer src={add_blueImage}
						backgroundColor={LightBlueSidely}
						round
						onClick={() => setIsCreationOpen(true)}
					/>
				</Restricted>
				<Restricted to={[
					{ objectAction: 'DeleteCatalog' },
					{ objectAction: 'CreateExport' },
				]}>
					<Dropdown dropdownStyle={{ optionLeft: '-115px', optionWidth: '160px' }} datalist={data} name={'options'}
						JSXButton={() => <ToolbarImage marginLeft='10px' hasPointer src={optionGrey} />}
						onChange={(value: DropdownData) => {
							switch (value.value) {
								case 'export':
									exportExcel();
									break;
								case 'delete':
									if (products.filter(p => p.checked).length > 0) {
									// @ts-expect-error swal
										swal.fire({
											text: translate('Are you sure you want to delete selected data from the database?') as string,
											type: 'warning',
											confirmButtonColor: RedSidely
										})
											.then(result => {
												if (result.value) {
													updateProducts(products.filter(p => p.checked).map(p => ({ id: p.id, deleted: true })))
														.then(() => setPagination({ ...pagination }))
														.catch(console.log);
												}
											});
									} else {
									// @ts-expect-error swal
										swal.fire({
											title: 'Attention!',
											text: translate('No data selected !') as string,
											type: 'warning',
											showConfirmButton: false
										});
									}
									break;
								default:
									break;
							}
						}}
					/>
				</Restricted>
			</>
		});
	}, [products, loadingState]);

	React.useEffect(() => {
		setLoadingState('loading');
		const tagsFilter = tags?.length ?? 0 > 0 ? tags?.reduce((acc, t) => acc + t + '-', '').slice(0, -1) : undefined;
		get_products({
			limit: pagination.step,
			offset: pagination.offset,
			search: inputSearchText.length > 0 ? inputSearchText : undefined,
			sort,
			tags: tagsFilter,
			categories: quickFilterResultData.categories.length > 0 ? quickFilterResultData.categories.map(d => d.id) : [-2],
			brands: quickFilterResultData.brands.length > 0 ? quickFilterResultData.brands.map(d => d.id) : [-2],
			catalogues: quickFilterResultData.catalogues.length > 0 ? quickFilterResultData.catalogues.map(d => d.id) : [-2]
		})
			.then(res => {
				setMax(res.data.count);
				setProducts(res.data.products);
				setLoadingState('loaded');
			})
			.catch(e => {
				console.log(e);
				setLoadingState('error');
			});
	}, [pagination, inputSearchText, sort, tags, quickFilterResultData]);

	const columns = React.useMemo(() => productcolumns(products, setProducts, () => setPagination({ ...pagination }), (product, fullOpenMode) => {
		history.push({
			search: `?id=${product.id}&mode=${fullOpenMode ? 'default' : 'detail'}`
		});
	}), [products]);

	return <Restricted to={{ objectAction: 'ReadCatalog' }} fallback={<Unhautorized />} >
		<Container>
			<div style={{ position: 'absolute', zIndex: 30 }}>
				<ModalLeft title={translate('global.modal.quickFilter').toString()} hasReset onReset={resetQuickFilter}>
					<ProductModalLeft data={quickFilterData} onChange={(values) => setQuickFilterResultData(values)} />
				</ModalLeft>
			</div>
			<FlexDiv flow='column' width='100%'>
				<Table height='calc(100vh - 170px)'
					columns={columns}
					data={products}
					onSort={sort => setSort(sort[0])}
					initialSortBy={sort}
				/>
				{loadingState === 'loaded' && !products.length && <div style={{ position: 'absolute' }}><NoData message={translateToString('no_products')} /></div>}
				<Pagination label={translate('products').toString()} amount={max} steps={[DEFAULT_LIMIT, 100, 200, 500]} onChange={(value) => setPagination({ ...pagination, ...value })} />
				<ProductPopup
					isOpen={modalState.isOpen}
					setIsOpen={isOpen => {
						if (!isOpen) { removeAllUrlParameters(); }
						setModalState({ ...modalState, isOpen });
					}}
					product={modalState.data?.product}
					productId={modalState.data?.productId}
					fullOpenMode={modalState.fullOpenMode}
					refresh={() => setPagination({ ...pagination })}
				/>
				<Restricted to={{ objectAction: 'CreateCatalog' }}>
					<ProductCreation
						refresh={() => setPagination({ ...pagination })}
						isOpen={isCreationOpen}
						setIsOpen={(b: boolean) => setIsCreationOpen(b)}
					/>
				</Restricted>
			</FlexDiv>
		</Container>
	</Restricted>;
}
