import React, {Component} from 'react';
import SelectInCharge from '../../../../../components/select/SelectAllUser';
import {required} from '../../../../../components/FieldModal';
import {fieldName} from '../services/fieldGetter';

class UserField extends Component {
  state = {};

  render() {
    const { data, dynamicField, type } = this.props;

    const name = fieldName(data, dynamicField);

    const validate = data.is_required ? [required] : [];
    return (
      <SelectInCharge
        name={name}
        id={`id-${name}`}
        type="text"
        label={data.is_display_field_name && data.field_name}
        placeholder=""
        requiredStar={data.is_required}
        validate={validate}
        readOnly={type === 'detail'}
      />
    );
  }
}

export default UserField;
