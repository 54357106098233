import add_blue from 'images/icon/add_blue.png';
import React, { Component } from 'react';
import moment from 'moment';
import ModalCreateOpportunity from './modals/ModalCreateOpportunity';
import { truncate } from '../../../../helpers/textConfig';
import noUserImage from 'images/icons/user.svg';
import swal from 'sweetalert2';
import { Translate } from 'react-localize-redux';
import { formatCurrency } from '../../../../containers_v2/reports/utils';

class Opportunities extends Component {
	state = {};

	onAlert = () => {
		swal.fire({
			title: 'Attention!',
			text: 'This contact not asociated with any company, please link this contact to the company first!.',
			type: 'warning',
			showConfirmButton: false
		});
	};

	toggle() {
		this.setState({
			modal: false
		});
	}

	render() {
		const {
			data,
			id,
			onFetch,
			isLoading,
			isFirstPage,
			isLastPage,
			count
		} = this.props;

		return (
			(<div>
				<div className="detail-top m-3 p-3">
					<div className="row mb-2">
						<div className="col-md-9">
							<div className="my-auto">
								<span className="card-title">
									<Translate id="opportunities" /> ({count || '0'}){' '}
								</span>
							</div>
						</div>
						<div className="col-md-3 d-flex justify-content-end align-items-end">
							<div
								className="my-auto custom-icon-blue rounded-circle "
								onClick={() =>
									id.clientCompanyId
										? this.setState({
											modal: true
										})
										: this.onAlert()
								}
							>
								<img
									src={add_blue}
									className="custom-icon"
									alt=""
								/>
							</div>
						</div>
					</div>
					{data.length > 0 ? (
						<div className="row">
							{data.map(val => (
								<div className="col-md-3 px-2 mb-3">
									<div
										className="card custom-card pointer mx-auto"
										onClick={() => {
											window.location.href = `/opportunities/detail/${val.id}`;
										}}
									>
										<div
											className="card-header p-0"
											style={{
												background: val.opportunityStatusColorCode
											}}
										>
											<p className="m-0 p-0 text-white text-center opportunity-head">
												{val.opportunityStatusName || '-'}
											</p>
										</div>
										{/* <div className="card-body d-flex flex-row px-2 py-0 my-auto"> */}
										<div
											className="d-flex flex-row px-2 py-0 my-auto"
											style={{ borderRadius: '6px' }}
										>
											<div className="my-auto">
												<img
													src={val.inchargeUserPhoto || noUserImage}
													alt=""
													className="rounded-circle"
													width="32"
													height="32"
												/>
											</div>
											<div className="d-block mt-1 ml-3">
												<p className="opportunity-body-1 mb-0">
													{truncate(val.title || '-', 20)}
												</p>
												<p className="opportunity-body-2 mb-0">
													{moment(val.deadline).format('DD/MM/YY')}
												</p>
												<p className="opportunity-body-1 mb-0">
													{val.amount ? formatCurrency(val.amount) : '-'}
												</p>
											</div>
											<div
												className={`${(!val.probability ||
                          val.probability === '0') &&
                          'bg-0'}
                        ${val.probability === '10' && 'bg-10'}
                        ${val.probability === '20' && 'bg-20'}
                        ${val.probability === '30' && 'bg-30'}
                        ${val.probability === '40' && 'bg-40'}
                        ${val.probability === '50' && 'bg-50'}
                        ${val.probability === '60' && 'bg-60'}
                        ${val.probability === '70' && 'bg-70'}
                        ${val.probability === '80' && 'bg-80'}
                        ${val.probability === '90' && 'bg-90'}
                        ${val.probability === '100' &&
                          'bg-100'} opportunity-badge text-center`}
												width="26"
												height="26"
											>
												<span className="opportunity-badge-text">
													{val.probability ? val.probability : '0'}%
												</span>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<p
							style={{
								fontSize: '14px',
								margin: '15px 0 10px',
								color: '#707683'
							}}
						>
							<Translate id="no opportunities" />
						</p>
					)}
					{count > 4 &&
            (!isLoading ? (
            	<div className="row mt-2">
            		<button
            			className={`${isLastPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-more col-6`}
            			onClick={() => {
            				!isLastPage && onFetch();
            			}}
            		>
            			<Translate id="see_more" />
            		</button>
            		<button
            			className={`${isFirstPage ? 'text-secondary' : 'text-primary'
            			} btn btn-link see-less col-6`}
            			onClick={() => {
            				!isFirstPage && onFetch(1);
            			}}
            		>
            			<Translate id="see_less" />
            		</button>
            	</div>
            ) : (
            	<div className="d-flex">
            		<i className="lds-dual-ring mx-auto" />
            	</div>
            ))}
				</div>
				<ModalCreateOpportunity
					isOpen={this.state.modal}
					toggle={() => {
						this.toggle();
					}}
					onClick={() => {
						this.toggle();
					}}
					id={id}
				/>
			</div>)
		);
	}
}

export default Opportunities;
