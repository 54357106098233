import * as React from 'react';
import { Asterisk, } from '../../components_v2/dropdown/style/Style';
import { Label, LabelContainer } from '../../components_v2/input/style/Style';
import colorList from '../../components_v2/avatar/Data/ColorList';
import { translateToString } from '../../styles/global/translate';
import { trimWith } from '../../components_v2/utils';
import { Text, Container, VariableTextButton, VariablesContainer } from './style/styles';
import { VariableColor } from './model/models';
import { VARIABLE_ARRAY } from './model/const';
import closeIcon from 'images/icon/closeIcon.png';


interface DynamicTextEditableProps {
    value: string
    setValue: React.Dispatch<React.SetStateAction<string>>
	label: string
	isOpen: boolean
	isOwner: boolean
}
export function DynamicTextEditable({ value, setValue, label,isOpen, isOwner }: DynamicTextEditableProps) {
	const contentRef = React.useRef(null);

	React.useEffect(() => {
		const varRegex = /#[\w\s\u00E9]+#/g;

		const current = document.getElementById('dynamic-text-editable');

		if (current) {
			current.innerHTML = current.innerHTML
				.replace(varRegex, (current) => {
					const element = document.getElementById(current);
					const color = element?.getAttribute('color');
					const text = trimWith(current, '#');
					const block = createVariableHTMLElement(color, text ?? '');
					return block.outerHTML;
				})
				.replace(/\n/g, (match) => {
					return match + '</br>';
				});

			//because .outerHTML doesn't send eventListener
			current.querySelectorAll('img').forEach(el => {
				el.addEventListener('click', handleOnDelete);
			});
		}
	}, [isOpen]);

	function handleOnDelete(e) {
		const target = e.currentTarget;
		//prevent to put same listener multiple time
		target.removeEventListener('click', handleOnDelete);
		target.parentNode.remove();

		const current = document.getElementById('dynamic-text-editable');
		if (current?.innerText)
			setValue(current.innerText);
	}

	function createVariableHTMLElement(color: string | null | undefined, text: string) {
		const newBlock = document.createElement('div');
		newBlock.style.display = 'inline-flex';
		newBlock.style.justifyContent = 'space-between';
		newBlock.style.alignItems = 'center';
		newBlock.style.borderRadius = '5px';
		newBlock.style.margin = '2px 5px';
		newBlock.style.fontSize = '12px';
		newBlock.style.padding = '0 0 0 10px';
		newBlock.style.color = 'white';
		newBlock.style.backgroundColor = color ?? 'black';
		newBlock.style.userSelect = 'none';
		newBlock.contentEditable = 'false';
		newBlock.textContent = `#${translateToString('email_template.' + text)}#`;

		const divider = document.createElement('div');
		divider.style.backgroundColor = 'inline-block';
		divider.style.backgroundColor = 'white';
		divider.style.width = '1px';
		divider.style.height = '20px';
		divider.style.marginLeft = '5px';

		const deleteButton = document.createElement('img');
		deleteButton.src = closeIcon;
		deleteButton.style.borderLeft = '1px solid white';
		deleteButton.style.marginLeft = '6.5px';
		deleteButton.style.filter = 'contrast(300%)';
		deleteButton.style.height = '20px';
		deleteButton.style.padding = '6.5px';
		deleteButton.style.cursor = 'pointer';
		deleteButton.onclick = (e) => handleOnDelete(e);

		newBlock.append(deleteButton);
		return newBlock;
	}

	function handleChange(event: React.ChangeEvent<HTMLDivElement>) {
		event.preventDefault();
		const newValue = event.currentTarget.innerText;
		if (newValue)
			setValue(newValue);
	}
	function handleInsertVariable(event: React.MouseEvent<HTMLButtonElement>) {
		const { currentTarget } = event;
		const selection = window.getSelection();
		const textElement = document.getElementById('dynamic-text-editable');
		
		//
		if (isOwner && isValid()) {
			const color = currentTarget.getAttribute('color');
			const range = (selection as Selection).getRangeAt(0);
			const block = createVariableHTMLElement(color, trimWith(currentTarget.id, '#'));
				
			range.deleteContents();
			range.insertNode(block);
			range.insertNode(document.createTextNode('')); //put textNode to move cursor
			range.collapse(false);

			const newValue = textElement?.innerText;
			if (newValue) setValue(newValue);
		} 

		function isValid() {
			const focusNode = (selection as Selection).focusNode;

			let currentNode = focusNode;
			while (currentNode) {
				if (currentNode.isSameNode(textElement)) 
					return true;
				currentNode = currentNode.parentNode;
			}
			return false;
		}

	}
	function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
		if (event.key === 'Enter') {
			event.preventDefault();

			const selection = window.getSelection();
			const range = (selection as Selection).getRangeAt(0);

			const br = document.createElement('br');
			range.insertNode(br);
			range.collapse(false);
			setValue(event.currentTarget.innerText);
		}
	}

	return React.useMemo(() => {
		return <Container>
			<LabelContainer>
				<Label>{translateToString('email_template.variable_list')}</Label>
			</LabelContainer>
			<VariablesContainer>
				{ VARIABLE_ARRAY.map((item, index) => (
					<VariableTextButton
						key={`${item.text}[${index}]`}
						id={item.varName}
						color={colorList[VariableColor[item.text.toUpperCase()]]}
						onClick={handleInsertVariable}
					>#{translateToString(`email_template.${item.text}`)}#
					</VariableTextButton>
				))}
			</VariablesContainer>
			<LabelContainer>
				<Asterisk />
				<Label>{label}</Label>
			</LabelContainer>
			<Text
				id="dynamic-text-editable"
				contentEditable={isOwner}
				suppressContentEditableWarning
				onInput={handleChange}
				onBlur={handleChange}
				onKeyDown={handleKeyDown}
				ref={contentRef}
			>{value}</Text>
		</Container>;
	},[]);
}

