/* eslint-disable camelcase */
import React from 'react';
import {get} from 'lodash';
import PropStartSection from './property/PropStartSection';
import PropEndSection from './property/PropEndSection';
import PropHeader from './property/PropHeader';
import PropImage from './property/PropImage';
import PropFreeText from './property/PropFreeText';
import PropNumerical from './property/PropNumerical';
import PropWebsite from './property/PropWebsite';
import PropDate from './property/PropDate';
import PropDropdownList from './property/PropDropdownList';
import PropCheckbox from './property/PropCheckbox';
import PropEmail from './property/PropEmail';
import PropAddressField from './property/PropAddressField';
import PropDocument from './property/PropDocument';
import PropUserField from './property/PropUserField';
import PropHyperlink from './property/PropHyperlink';
import PropCalculation from './property/PropCalcualtion';
import PropStartTable from './property/PropStartTable';
import PropEndTable from './property/PropEndTable';
import PropCamera from './property/PropCamera';
import {Translate} from 'react-localize-redux'


export default class FormElementsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: this.props.element,
      dirty: false
    };
  }

  updateElement = () => {
    const this_element = this.state.element;
    // to prevent ajax calls with no change
    if (this.state.dirty) {
      this.props.updateElement.call(this.props.preview, this_element);
      this.setState({ dirty: false });
    }
  };

  editElementProp = (elemProperty, targProperty, e) => {
    // elemProperty could be content or label
    // targProperty could be value or checked
    const this_element = this.state.element;
    this_element[elemProperty] = e.target[targProperty];
    this.setState(
      {
        element: this_element,
        dirty: true
      },
      () => {
        if (targProperty === 'checked') {
          this.updateElement();
        }
      }
    );
  };

  handleUploadFile = (imageId, imageSrc) => {
    const this_element = this.state.element;
    this_element.default_image_id = imageId;
    this_element.default_image_src = imageSrc;
    this.setState({
      element: this_element,
      dirty: true
    });
  };

  renderProp = () => {
    const { element } = this.props;

    const el = get(element, 'element');
    const disabled = get(element, 'disabled');
    switch (el) {
      case 'StartSection':
        return (
          <PropStartSection
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'EndSection':
        return (
          <PropEndSection
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'StartTable':
        return (
          <PropStartTable
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'EndTable':
        return (
          <PropEndTable
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Header':
        return (
          <PropHeader
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Image':
        return (
          <PropImage
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
            handleUploadFile={this.handleUploadFile}
          />
        );

      case 'FreeText':
        return (
          <PropFreeText
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Numerical':
        return (
          <PropNumerical
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Website':
        return (
          <PropWebsite
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'DropdownList':
        return (
          <PropDropdownList
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'DatePicker':
        return (
          <PropDate
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Checkbox':
        return (
          <PropCheckbox
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Email':
        return (
          <PropEmail
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'AddressField':
        return (
          <PropAddressField
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Document':
        return (
          <PropDocument
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'UserField':
        return (
          <PropUserField
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Hyperlink':
        return (
          <PropHyperlink
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Calculation':
        return (
          <PropCalculation
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      case 'Camera':
        return (
          <PropCamera
            {...this.props}
            updateElement={this.updateElement}
            isEdit={disabled}
            editElementProp={this.editElementProp}
          />
        );

      default:
        return <div />;
    }
  };

  render() {
    if (this.state.dirty) {
      this.props.element.dirty = true;
    }

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="mb-0 font-weight-bold"><Translate id="Field properties" /></div>
          <i
            className="fas fa-times dismiss-edit my-0"
            onClick={this.props.manualEditModeOff}
          />
        </div>
        {this.renderProp()}
      </div>
    );
  }
}
FormElementsEdit.defaultProps = { className: 'edit-element-fields' };
