export const FETCH_COMPANIES_BY_OPPORTUNITIES_REQUEST =
  'FETCH_COMPANIES_BY_OPPORTUNITIES_REQUEST';
export const FETCH_COMPANIES_BY_OPPORTUNITIES_SUCCESS =
  'FETCH_COMPANIES_BY_OPPORTUNITIES_SUCCESS';
export const FETCH_COMPANIES_BY_OPPORTUNITIES_ERROR =
  'FETCH_COMPANIES_BY_OPPORTUNITIES_ERROR';

export const SET_FILTER_MAP_DEADLINE = 'SET_FILTER_MAP_DEADLINE';
export const SET_FILTER_MAP_INCHARGE = 'SET_FILTER_MAP_INCHARGE';
export const SET_FILTER_MAP_GROUP = 'SET_FILTER_MAP_GROUP';
