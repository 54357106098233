import { URL_JANUS } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

export async function isConnectedExternalService(service: string) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios.get(
		`${URL_JANUS}/api/v1/oauth/connected?service=${service}`
	);
}

export async function removeIntegration(service: string): Promise<void> {
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios.delete(
		`${URL_JANUS}/api/v1/oauth/remove?service=${service}`
	).then(res => res.data);
}
