import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../../../helpers/storeLang';
import TextareaAutosize from 'react-autosize-textarea';
import * as moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

function b64DecodeUnicode(str) {
	return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
}

function deserialize_parts(parts: any[]) {
	let deserialized_text = '';
	let deserialized_html = '';
	for (const part of parts) {
		switch (part.mimeType) {
			case 'text/html':
				part.body.size > 0 ? deserialized_html = b64DecodeUnicode(part.body.data.replace(/-/g, '+').replace(/_/g, '/')) : '';
				break;
			case 'text/plain':
				part.body.size > 0 ? deserialized_text = b64DecodeUnicode(part.body.data.replace(/-/g, '+').replace(/_/g, '/')) : '';
				break;
			case 'multipart/alternative':
				const ret = deserialize_parts(part.parts);
				if (ret.deserialized_html) {
					deserialized_html = ret.deserialized_html;
				}
				if (ret.deserialized_text) {
					deserialized_text = ret.deserialized_text;
				}
				break;
			case 'image/png':
				break;
		}
	}
	return { deserialized_html, deserialized_text };
}

export default function ModalEmailDetails(props: { mail, isOpen: boolean, toggle: Function }) {
	const translate = getTranslate(storeLang.getState().localize);
	const { mail, isOpen, toggle } = props;

	let body_text = '';
	let body_html = '';
	let body = '';
	if (mail) {
		if (mail.payload.body.size > 0) {
			body_text = b64DecodeUnicode(mail.payload.body?.data?.replace(/-/g, '+').replace(/_/g, '/'));
		} else if (mail.payload.parts && mail.payload.parts?.length > 0) {
			const { deserialized_html, deserialized_text } = deserialize_parts(mail.payload.parts);
			if (deserialized_html) {
				body_html = deserialized_html;
			}
			if (deserialized_text) {
				body_text = deserialized_text;
			}
		}
		if (body_html) {
			body = body_html;
		} else {
			body = body_text;
		}
	}

	const from = mail && mail.payload.headers.find(e => e.name == 'From')?.value;
	const object = mail && mail.payload.headers.find(e => e.name == 'Subject')?.value;
	const asHtml = /<\/?[a-z][\s\S]*>/i.test(body);
	const date = mail ? moment(mail.payload.headers.find(e => e.name == 'Date')?.value).format('DD/MM/YYYY HH:mm') : moment().format('DD/MM/YYYY HH:mm');
	return (
		<Modal
			size="lg"
			style={{ maxWidth: '1000px', width: '50%' }}
			isOpen={isOpen}
			toggle={toggle}
			centered
		>
			<ModalHeader toggle={toggle}>
				<div style={{ float: 'left' }}>
					{object || ''}
				</div>
				<div style={{ float: 'right', right: '40px', position: 'absolute' }}>
					{date}
				</div>
			</ModalHeader>
			<ModalBody>
				<ModalInput
					label={<Translate id="emails.from" />}
					readOnly={true}
					requiredStar={false}
					placeholder={translate('emails.from')}
					details={from}
					onChange={() => { }}
					error={null}
					size={7}
				/>
				<ModalInput
					label={<Translate id="emails.object" />}
					readOnly={true}
					requiredStar={false}
					placeholder={translate('emails.object')}
					details={object}
					onChange={() => { }}
					error={null}
					size={7}
				/>
				{asHtml
					? <div>{ReactHtmlParser(body)}</div>
					: <ModalInput
						readOnly={true}
						requiredStar={false}
						placeholder={translate('emails.body')}
						details={body}
						onChange={() => { }}
						error={null}
						size={12}
					/>}
			</ModalBody>
			<ModalFooter className={'View'}>
				<div>
					<p className="text-by word-break mb-0">
						{date}
					</p>
				</div>
			</ModalFooter>
		</Modal>
	);
}

function ModalInput(props: { label?, requiredStar: boolean, placeholder, readOnly: boolean, onChange: Function, details: string, error: string | null, size: number }) {
	const { label, requiredStar, placeholder, readOnly, onChange, details, error, size } = props;
	return (
		<div>
			<div className="form-group row">
				{label && <div className="col-md-3 text-right mt-2">
					<label className="text-capitalize">
						{requiredStar
							? (
								<span className="text-danger">* </span>
							)
							: (
								''
							)}
						{label}
					</label>
				</div>}

				<div
					className={`my-auto col-md-${size}`}
				>
					<div className="row">
						<div className="col-12">
							<TextareaAutosize
								placeholder={placeholder}
								type={'text'}
								readOnly={readOnly}
								onChange={value =>
								// @ts-expect-error
									onChange(value.target.value)
								}
								value={details}
								maxRows={40}
								className={`form-control ${error ? 'focus-error' : ''}`}
							/>
						</div>
						<div className="col-12">
							{error && <span className="form-error">{error}</span>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
