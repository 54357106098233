import { DashboardItem } from '../Dashboard';
import * as React from 'react';
import { getUserColor } from '../../../components_v2/avatar/Data/Data';
import { ChartOptions } from 'chart.js';
import { FlexDiv } from '../../products/style';
import { Translate } from '../../../styles/global/translate';
import { Doughnut } from 'react-chartjs-2';
import { DeleteButton, EditButton, CreateButton, WidgetTitle, WidgetTitleWrapper, CircleDiv } from '../Widget';
import styled from 'styled-components';


const DEFAULT_FONT_SIDELY = { family: 'Poppins' };
interface DashBoardCountForDoughnutChart {
	name: string
	count: number
	color?: string
}
export default function DoughnutChartWidget(props: {
	// eslint-disable-next-line @typescript-eslint/ban-types
	value: DashBoardCountForDoughnutChart[],
	item: DashboardItem,
	onCreationClicked?: () => void,
	onClickDelete?: (id: number) => void
	onClickEdit?: (item: DashboardItem) => void
	isHover?: boolean
}) {
	const { value, onCreationClicked, item, onClickDelete , isHover, onClickEdit } = props;
	const totalCount = value.reduce((acc, curr) => acc + curr.count, 0);


	const data = {
		labels: value.map(current => current.name),
		datasets: [
			{
				data: value.map(current => current.count),
				backgroundColor: value.map((current) => current.color ?? getUserColor(current.name)),
				borderWidth: 2,
			},
		],
	};

	const options: ChartOptions = {
		plugins: {
			datalabels: {
				font: {
					...DEFAULT_FONT_SIDELY,
					size: 14,
				},
				color: '#fff',
				formatter: function(value) {
					const percentage = Math.round(value / totalCount * 100);
					if (percentage > 4)
						return percentage.toFixed(0) + '%';
					return '';
				},
			},
			legend: {
				display:false,
				align: 'center',
				position: 'bottom',
				labels: {
					usePointStyle: true,
					font: { ...DEFAULT_FONT_SIDELY }
				},
			},
			tooltip: {
				bodyFont: { ...DEFAULT_FONT_SIDELY },
			}
		}
	};
	return <>
		<WidgetTitleWrapper>
			<FlexDiv justify="space-between" align='center' width='100%'>
				<WidgetTitle>
					{item.title ?? <Translate id='dashboard.widget.status_by_point_of_sale'/>}
				</WidgetTitle>
				<FlexDiv justify="flex-end" gap="2px">
					{onCreationClicked && <CreateButton onClickCreate={onCreationClicked} />}
					{ isHover && onClickEdit && <EditButton onClickEdit={() => onClickEdit(item)}/>}
					{ isHover && onClickDelete && <DeleteButton onClickDelete={() => onClickDelete(item.id as number)} />}
				</FlexDiv>
			</FlexDiv>
		</WidgetTitleWrapper>
		<FlexDiv flow='column' justify='center' align='center'>
			<div style={{ width:'200px', padding:'5px', }}>
				<Doughnut data={data} options={options} />
			</div>
		</FlexDiv>
		<CustomLegend datalist={value}/>
	</>;
}

const CustomLegendWrapper = styled.div`
	display: flex;
  	flex-direction: column;
`;

interface CustomLegendProps {
	datalist: DashBoardCountForDoughnutChart[],
}
function CustomLegend(props: CustomLegendProps) {
	const { datalist } = props;
	return <CustomLegendWrapper>
		{datalist.sort((a,b) => b.count - a.count).map((data, index) =>
			(<FlexDiv key={`legend_data[${index}]`} fontSize="10px" justify="space-between">
				<FlexDiv>
					<CircleDiv color={data.color ?? getUserColor(data.name)}/>
					<div>{data.name}</div>
				</FlexDiv>
				<div>{data.count}</div>
			</FlexDiv>))
		}
	</CustomLegendWrapper>;

}