import React, {Component} from 'react';
import {connect} from 'react-redux';
import {message, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {get} from 'lodash';
import {uploadFile} from '../../../store/actions/formbuilder.action';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

class UploadMultpleImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      loading: false
    };
  }

  componentDidMount() {
    const { input } = this.props;
    const img = get(input, 'value.img');

    if (img) {
      this.setState({
        fileList: img
      });
    }
    if (input.value) {
      input.onChange(input.value);
    } else {
      input.onChange({ img: [] });
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  customRequest = ({ onSuccess, onError, file }) => {
    const { uploadFile, input } = this.props;
    const formData = new FormData();
    this.setState({ loading: true });
    formData.append('type', 'camera_field');
    formData.append('file', file);

    uploadFile(formData).then(
      response => {
        this.state.fileList.push({
          url: response.data.data.temp_url,
          status: 'done',
          name: response.data.data.url,
          id: response.data.data.id
        });
        this.state.fileList.map((v, i) => {
          v.uid = i;
        });
        this.setState({
          fileList: this.state.fileList,
          loading: false
        });
        input.onChange({ img: this.state.fileList });
        onSuccess();
      },
      () => {
        this.setState({ loading: false });
        onError();
      }
    );
  };

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    });
  };

  removeFile = file => {
    this.state.fileList.map((v, i) => {
      if (file.uid === v.uid) {
        this.state.fileList.splice(i, 1);
        this.setState({ fileList: this.state.fileList });
        this.props.input.onChange({ img: this.state.fileList });
      }
    });
  };

  render() {
    const {
      input,
      name,
      meta: { error, warning, touched },
      label,
      requiredStar,
      disabled,
      multiple,
      id
    } = this.props;
    const {
      previewVisible,
      previewImage,
      fileList,
      previewTitle,
      loading
    } = this.state;
    const uploadButton = !disabled && (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const uploading = (
      <div className="ant-upload-list ant-upload-list-picture-card">
        <div className="ant-upload-list-item ant-upload-list-item-uploading">
          <div className="ant-upload-list-item-info">
            <span>
              <div className="ant-upload-list-item-uploading-text">
                Uploading...
              </div>
            </span>
          </div>

          <div className="ant-upload-list-item-progress" />
        </div>
      </div>
    );

    const showUploadList = {
      showRemoveIcon: false
    };

    return (
      <div className="form-group mb-0 dropzone-icon">
        <div className="row">
          <div className="col-md-3 my-auto text-right">
            <label htmlFor={id}>
              {requiredStar === true ? (
                <span className="text-required">* </span>
              ) : (
                ''
              )}
              {label}
            </label>
          </div>
          <div className="col-md-8">
            <div className="clearfix">
              {loading && uploading}

              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                name="file"
                customRequest={this.customRequest}
                fileList={fileList}
                showUploadList={disabled ? showUploadList : true}
                onPreview={this.handlePreview}
                multiple={multiple}
                disabled={disabled}
                // onChange={this.handleChange}
                beforeUpload={beforeUpload}
                onRemove={this.removeFile}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              {/* <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={previewImage}
                />
              </Modal> */}

              {previewVisible && (
                <Lightbox
                  mainSrc={previewImage}
                  onCloseRequest={() =>
                    this.setState({ previewVisible: false })
                  }
                />
              )}
            </div>
            {touched &&
              ((error && <span className="form-error">{error}</span>) ||
                (warning && <span className="form-error">{warning}</span>))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.formbuilder.file,
  isUploading: state.formbuilder.isUploading
});

export default connect(
  mapStateToProps,
  { uploadFile }
)(UploadMultpleImage);
