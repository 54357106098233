import * as React from 'react';
import { isSuperAdmin } from '../../components_v2/utils';

interface Props {
  children: React.ReactNode,
  skip?: boolean
}

const RestrictedSuperAdmin: React.FunctionComponent<Props> = ({ children, skip }) => {
	const admin = isSuperAdmin();

	if (admin || skip) {
		return <>{children}</>;
	}

	return <></>;
};

export default RestrictedSuperAdmin;
