import React, {Component} from 'react';
import {CustomInput, FormGroup} from 'reactstrap';

class DateField extends Component {
  state = {};

  render() {
    const {
      input,
      id,
      name,
      label,
      requiredStar,
      disabled,
      onClick,
      noLabel
    } = this.props;

    return (
      <FormGroup>
        <div className="row">
          {!noLabel && (
            <div className="col-md-3 text-right">
              <label htmlFor={id}>
                {requiredStar === true ? (
                  <span className="text-danger">* </span>
                ) : (
                  ''
                )}
                {label}
              </label>
            </div>
          )}
          <div className="col-md-5 mx-2">
            <div className="row">
              <div className="col-12">
                <CustomInput
                  {...input}
                  checked={input.value === true}
                  type="switch"
                  id={id}
                  name={name}
                  color="green"
                  requiredStar={requiredStar}
                  disabled={disabled}
                  onClick={onClick}
                />
              </div>
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}

export default DateField;
