import { Translate, translateToString } from '../../../../styles/global/translate';
import Input from '../../../../components_v2/input/Input';
import DropdownOwners from '../../../../components_v2/dropdown/DropdownOwners';
import Dropdown from '../../../../components_v2/dropdown/Dropdown';
import { colorStatusMapping, colorTypeMapping } from '../../../calendar/model/Model';
import { ALL_GRANULARITY } from '../../../../components_v2/toolbarFilter/time/GranularitySelect';
import { DropdownData } from '../../../../components_v2/dropdown/model/Model';
import { Granularity } from '../../../../../../typelib/tabula/typings/bindings/time/Granularity';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../../components_v2/popup/PopupCreation';
import { DashboardContext, DEFAULT_LAYOUTS } from '../../Dashboard';
import * as React from 'react';
import {
	BottomBar,
	CreationPopupTitle, CreatorProps,
	DASHBOARD_CREATION_DROPDOWN_STYLE,
	DASHBOARD_INPUT_STYLE, DashboardPopupContext,
	PopupContainer
} from '../DashboardPopup';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../../atoms/global/users';
import DropdownTagsCloudInDashBoardPopup from '../DropDownTagsCloudInDashboardPopup';
import { DropdownSearchCompanies } from '../../../../components_v2/dropdown/DropdownSearch';

export default function EventCountCreator(props: CreatorProps) {
	const users = useRecoilValue(AUsers);
	const { onSave, handleBack } = props;
	const { currentItem } = useContext(DashboardContext)!;
	const {
		user ,
		setUser,
		setEventType,
		eventType,
		setStatus,
		status,
		setGranularity,
		granularity,
		title,
		setTitle,
		changedItems,
		setChangedItems,
		company,
		setCompany,
		selectedTags,
	} = useContext(DashboardPopupContext)!;

	return <><PopupContainer isCreator>
		<CreationPopupTitle>{`${translateToString('parameter')} ${translateToString('dashboard.events')}`}</CreationPopupTitle>
		<Input name='title' type='text' value={title} onChange={setTitle} inputStyle={DASHBOARD_INPUT_STYLE} label={translateToString('title')}/>
		<DropdownOwners
			border
			dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
			label={translateToString('users')}
			users={users.map(value => ({ value, label: value.name }))}
			onChange={setUser}
			selected={user}
			cancellable
		/>
		<DropdownSearchCompanies
			name='parent-company-selector'
			parent dropdownStyle={{ labelUp: true }}
			label={translateToString('Parent company')}
			onChange={setCompany}
			selectedValue={company}
			placeholder={translateToString('all')}
		/>
		<Dropdown
			dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
			name=''
			datalist={colorTypeMapping.map(t => ({ value: t, label: translateToString(`event.${t.label}`) }))}
			label={translateToString('type')}
			onChange={setEventType}
			selectedValue={eventType}
			placeholder={translateToString('all')}
			cancellable
		/>
		<Dropdown
			dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
			name=''
			datalist={colorStatusMapping.map(s => ({ value: s, label: translateToString(s.label) }))}
			label={translateToString('status')}
			onChange={setStatus}
			selectedValue={status}
			placeholder={translateToString('all')}
			cancellable
		/>
		<Dropdown
			dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
			name=''
			datalist={ALL_GRANULARITY.map(g => ({ label: translateToString(`${g}`), value: g }))}
			label={translateToString('granularity')}
			selectedValue={{
				label: granularity ? translateToString(granularity) : '',
				value: granularity ?? undefined
			}}
			onChange={(value: DropdownData<Granularity>) => setGranularity(value.value)}
			placeholder={translateToString('all')}
			cancellable
		/>
		<DropdownTagsCloudInDashBoardPopup/>
	</PopupContainer>
	<BottomBar>
		<DefaultButton margin='0' buttonStyle={ButtonStyle.White} onClick={handleBack}><Translate
			id='back'/></DefaultButton>
		<DefaultButton margin='0' onClick={() => {
			const id = currentItem ? currentItem.id : { unsync: Date.now() };
			const layout = currentItem ? currentItem.layout : {
				...DEFAULT_LAYOUTS['EventCount'],
				i: JSON.stringify(id)
			};
			changedItems.push({
				item_id: company?.value.id ?? undefined,
				item_type: 'EventCount',
				additional_values: {
					user: user?.value?.id,
					event_type: eventType?.value?.id,
					event_status: status?.value?.id,
					granularity,
					tags: selectedTags.length ? selectedTags.map(t => ({ id:t.id, operator:t.value.operator })) : undefined
				},
				id,
				layout,
				title: title?.trim().length ? title : undefined,
				screen_id:props.activeTab
			});
			setChangedItems([...changedItems]);
			onSave();
		}}><Translate id='save'/></DefaultButton>
	</BottomBar></>;
}