import styled from 'styled-components';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { DefaultText } from '../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, DarkGreySidely, DarkGreySidely2, RedSidely, SidelyBlack } from '../../styles/global/css/Utils';

export const RightContainer = styled.div`
    height: calc(100vh - 128px);
    background-color: white;
`;

export const Columns = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    border-bottom: 3px solid ${BorderColor};
    width: 100%;
    height: 41px;
`;

interface ColumnProps {
  selected?: boolean
}

export const Column = styled.div<ColumnProps>`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 41px;
    text-align: center;
    color: ${p => p.selected ? BlueSidely : DarkGreySidely};
    cursor: pointer;
    border-bottom: 3px solid  ${p => p.selected ? BlueSidely : BorderColor};
    width: 25%;
    margin-bottom: -3px;

    &:hover {
        color: ${BlueSidely};
        border-bottom: 3px solid  ${BlueSidely};
    }
`;

export const ProfilePic = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
`;

export const UserPhoto = styled.img`
    height: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
`;

export const UserList = styled.ul`
    list-style: none;
    padding: 0;
    height: 100%;
    overflow-y: auto;
    margin: 0;
`;

export const UserDescription = styled.li<{ width?: number, clickable?: boolean }>`
    justify-content: space-around;
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${p => p.width ?? 500}px;
    ${p => p.clickable ? 'cursor: pointer;' : ''}
    

    &:hover {
        ${p => p.clickable ? 'background-color: #f0f0f0;' : ''}
        ${p => p.clickable ? 'border-radius: 6px;' : ''}
    }
`;

export const Button = styled(DefaultButton)<{ margin?: string, buttonWidth?: number }>`
    margin: ${p => p.margin ?? ''};
    width: ${p => p.buttonWidth ?? ''}px;
    height: 37px;
`;

export const PanelContainer = styled.div`
    padding: 10px;
    overflow: scroll;
`;

export const Close = styled.img`
    height: 30px;
    cursor: pointer;
`;

interface TableRowProps {
  cursor?: string
}

export const TableRow = styled.div<TableRowProps>`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${p => p.color ? p.color : DarkGreySidely2};
    cursor: ${p => p.cursor ?? 'auto'};
    &:focus-visible {
        border: none;
        outline: none;
    }

    &:focus {
        border: none;
        outline: none;
    }
`;

export const NameInput = styled.input<{ error: boolean }>`
    ${DefaultText}
    border: 1px solid ${p => p.error ? RedSidely : BorderColor};
    border-radius: 6px;
    padding: 5px;
    margin: 10px 0 30px 0;

    &:focus {
        border: 1px solid ${p => p.error ? RedSidely : BlueSidely};
        border-radius: 6px;
    }

    &:hover {
        border: 1px solid ${p => p.error ? RedSidely : BlueSidely};
        border-radius: 6px;
    }
`;

export const Title = styled.div<{ center?: boolean }>`
    color: ${SidelyBlack};
    font-weight: 600;
    font-size: 14px;
    text-align: ${p => p.center ? 'center' : ''};
`;
