import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import styled from 'styled-components';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import DropdownOwners from '../../../components_v2/dropdown/DropdownOwners';
import DropdownStatus from '../../../components_v2/dropdown/DropdownStatus';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { FilterTree } from '../../../components_v2/filter/model/Model';
import storeLang from '../../../helpers/storeLang';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { FlexDiv } from '../../products/style';
import { SidelyBlack } from '../../../styles/global/css/Utils';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import { ClientCompany as CC, Owner } from '../../orders/model/Model';
import { getFilteredCompanies } from '../../orders/data/Data';
import Input from '../../../components_v2/input/Input';
import Popup from '../../../components_v2/popup/Popup';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import { bulkEditContact, QueryBulkEdit } from '../data/Data';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import { CompanyStatus } from '../../client-companies/model/Model';

enum BulkEditEnum {
	Owner,
	Status,
	Company,
	Position,
}

const DROPDOWN_STYLE = {
	containerWidth: '100%',
	optionWidth: '100%',
	labelUp: true,
	optionHeight: '150px',
	height: '50px',
	labelStyle: {
		fontSize: '14px',
		fontWeight: '500',
		padding: '0 0 15px 0',
		color: SidelyBlack,
	},
	width: '100%'
};

const ButtonText = styled.div`
  font-weight: 600;
  font-size: 13px;
`;

type Props = {
	ids: number[],
	refresh?: () => void,
	onClose?: () => void,
	isAll?: boolean,
	filters?: FilterTree,
	search?: string
}

export default function ContactBulkEdition(props: {
	isOpen: boolean,
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	statuses: CompanyStatus[]
} & Props) {
	const usersValue = useRecoilValue(AUsers);
	const users = usersValue.map(u => ({ label: u.name, value: u }));
	return <Popup
		isOpen={props.isOpen}
		popupMode={PopupMode.Details}
		onClickOut={() => {
			props.setIsOpen(false);
		}}
		popupStyle={{ animate: true, height: '100%', top: '0%' }}
	>
		<PopupCreation
			title={<Translate id='mass_modification' />}
			hideValidation
			onClose={() => {
				props.setIsOpen(false);
			}}
		>
			<ContactBulkEditionValue {...props} users={users} statuses={props.statuses.map(s => ({ value: s, label: s.name, color: s.color_code }))}/>
		</PopupCreation>
	</Popup>;
}

function ContactBulkEditionValue(props: Props & {users: DropdownData<Owner>[], statuses: DropdownData<CompanyStatus>[]}): JSX.Element {
	const { ids: contacts, refresh, isAll, filters, search, users, statuses } = props;
	const [selectedProperty, setSelectedProperty] = React.useState<BulkEditEnum>();
	const translate = getTranslate(storeLang.getState().localize);

	// Owner
	const [owner, setOwner] = React.useState<DropdownData | undefined>(users?.[0]);

	// Status
	const [status, setStatus] = React.useState<DropdownData | undefined>(statuses?.[0]);

	// Company
	const [company, setCompany] = React.useState<DropdownData<CC>>();
	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData<CC>[]>([]);

	// Position
	const [position, setPosition] = React.useState<string>();

	const ContactProperties: DropdownData<BulkEditEnum>[] = [
		{ label: translate('owner').toString(), value: BulkEditEnum.Owner },
		{ label: translate('status').toString(), value: BulkEditEnum.Status },
		{ label: translate('company').toString(), value: BulkEditEnum.Company },
		{ label: translate('position').toString(), value: BulkEditEnum.Position }
	];

	const isDisabled = (): boolean => {
		switch (selectedProperty) {
			case BulkEditEnum.Owner:
				return owner == undefined;
			case BulkEditEnum.Status:
				return status == undefined;
			case BulkEditEnum.Company:
				return company == undefined;
			case BulkEditEnum.Position:
				return position === undefined;
			default:
				return true;
		}
	};

	const subComponent = () => {
		switch (selectedProperty) {
			case BulkEditEnum.Owner:
				return <DropdownOwners
					dropdownStyle={DROPDOWN_STYLE}
					onChange={setOwner}
					users={users ?? []}
					selected={owner}
					border
					label={<Translate id='company_owner' />}
				/>;
			case BulkEditEnum.Status:
				return <DropdownStatus
					name='dropdown-status'
					dropdownStyle={DROPDOWN_STYLE}
					datalist={statuses ?? []}
					onChange={(value: DropdownData) => setStatus(value)}
					selectedValue={status}
				/>;
			case BulkEditEnum.Company:
				return <DropdownSearch
					label={translate('company').toString()}
					dropdownStyle={DROPDOWN_STYLE}
					onSearchChange={async(search, offset) => {
						try {
							const res = await getFilteredCompanies(search, 20, offset);
							const ret: boolean = res.data.length == 0 || res.data.length < 20;
							if (offset !== undefined) {
								setOptionCompanies([...optionCompanies, ...res.data.map((company: CC) => ({
									label: company.name,
									value: company
								}))]);
							} else {
								setOptionCompanies(res.data.map(company_1 => ({
									label: company_1.name,
									value: company_1
								})));
							}
							return ret;
						} catch (error) {
							console.error(error);
							return false;
						}
					}}
					datalist={optionCompanies}
					selectedValue={company ?? null}
					name=''
					onChange={(value: DropdownData<CC>) => setCompany(value)}
				/>;
			case BulkEditEnum.Position:
				return <>
					<Input
						label={translate('position').toString()}
						name='BulkEditPosition'
						type='text'
						inputStyle={DROPDOWN_STYLE}
						onChange={setPosition}
					/>
				</>;
			default:
				return <></>;
		}
	};

	const onEdit = () => {
		props.onClose?.();
		const body: QueryBulkEdit = {
			contacts,
			all: isAll ?? false,
			filters,
			search
		};
		let key: string;
		switch (selectedProperty) {
			case undefined: return;
			case BulkEditEnum.Owner:
				key = 'owner';
				body.value = owner?.value.id;
				break;
			case BulkEditEnum.Status:
				key = 'status';
				body.value = status?.value.id;
				break;
			case BulkEditEnum.Company:
				key = 'company';
				body.value = company?.value?.id;
				break;
			case BulkEditEnum.Position:
				key = 'position';
				body.value = position;
				break;
		}
		bulkEditContact(key, body)
			.then(refresh)
			.catch(console.error);
	};

	return <FlexDiv flow='column' gap='50px' margin='50px 0 0 0' padding='0 5% 0 5%' align='stretch'>
		<Dropdown<BulkEditEnum>
			dropdownStyle={DROPDOWN_STYLE}
			datalist={ContactProperties}
			name='dropdown_type'
			readOnly
			label={<Translate id='property_to_update' />}
			onChange={(value: DropdownData<BulkEditEnum>) => setSelectedProperty(value.value)}
		/>
		{subComponent()}
		{selectedProperty !== undefined && <DefaultButton disabled={isDisabled()} onClick={onEdit} height='30px'>
			<ButtonText>
				<Translate id='edit' />
			</ButtonText>
		</DefaultButton>
		}
	</FlexDiv>;
}