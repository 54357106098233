import axios from 'axios';
import { Cookies } from 'react-cookie';
import { atom, selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';
import { AtomCategory, AtomState } from './utils/model/Model';
import { Id } from '../containers_v2/settings/subSettings/statusesAndTagsSettings';
import { GenericReport } from 'bindings/reports/generic/GenericReport';

export enum CalculatedFieldTarget {
	Company = 'Company',
}

export type CalculatedField = {
	id: number;
	target: CalculatedFieldTarget;
	field_name: string;
	report: GenericReport;
	deleted?: boolean;
};

export type CompanyCalculatedField = [Id, object];

// -----------------------[ ATOM ]----------------------- //
export const AGlobalCalculatedFields: AtomState<CalculatedField[]> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_calculated_fields', // UNIQUE ID
		default: getCalculatedFields()
	})
};

export const ACalculatedFields = selector({
	key: 'ACalculatedFields',
	get: async({ get }) => get(AGlobalCalculatedFields.atom),
	set: ({ set }, newValue) => set(AGlobalCalculatedFields.atom, newValue),
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getCalculatedFields(): Promise<CalculatedField[]> {
	const res = await axios.get<CalculatedField[]>(`${URL_FOUNDATION}/api/v2/calculated-fields`).then(res => res.data);
	return res;
}

export async function postCalculatedFields(data: Omit<CalculatedField, 'id'>): Promise<number> {
	const res = await axios.post<number>(`${URL_FOUNDATION}/api/v2/calculated-fields`, data).then(res => res.data);
	return res;
}

export async function getCalculatedFieldsByCompany(clientCompanyId: number): Promise<CompanyCalculatedField[]> {
	const res = await axios.get<CompanyCalculatedField[]>(`${URL_FOUNDATION}/api/v2/calculated-fields/client-company/${clientCompanyId}`).then(res => res.data);
	return res;
}