import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';

class PropCalculation extends Component {
  state = {};

  render() {
    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Calculation</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize type="text" className="form-control" />
        </div>

        <div className="form-group  mt-3">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <TextAreaAutosize type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Type of calculation
          </label>
          <select id="optionSelect" className="form-control">
            <option>Multiplication</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field 1
          </label>
          <select id="optionSelect" className="form-control">
            <option>Discount</option>
            <option>Price</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field 2
          </label>
          <select id="optionSelect" className="form-control">
            <option>Discount</option>
            <option>Price</option>
          </select>
        </div>
      </div>
    );
  }
}

export default PropCalculation;
