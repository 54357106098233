// import { useLayoutEffect } from 'react';

export const customStyles = {
	option: (provided, state) => ({
		...provided,
		padding: 10,
		fontSize: 12,
		backgroundColor: state.isFocused && '#00b2ff',
		color: state.isFocused && '#ffffff',
		// ':hover': {
		//   ...provided[':active'],
		//   backgroundColor: !state.isSelected && 'rgba(0, 178, 255, 0.15 )'
		// }
	}),
	menu: provided => ({
		...provided,
		textAlign: 'left',
		zIndex: 9
	}),
	container: provided => ({
		...provided,
		fontSize: 12
	}),
	control: provided => ({
		...provided,
		cursor: 'pointer',
		borderColor: '#f2f2f2',
		borderRadius: '5px',
		boxShadow: 'none',
		'&:hover': {
			borderColor: '#f2f2f2'
		},
		minHeight: '38px',
		backgroundColor: 'white'
	}),
	singleValue: provided => ({
		...provided,
		color: 'inherit'
	}),
	indicatorSeparator: provided => ({
		...provided,
		backgroundColor: '#f2f2f2',
		display: 'none'
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: '5px',
		color: state.isFocused ? 'hsl(0,0%,80%)' : 'hsl(0,0%,80%)',
		'&:hover': {
			color: 'hsl(0,0%,80%)'
		}
	}),
	placeholder: defaultStyles => ({
		...defaultStyles,
		color: '#cdcdcd'
	}),

	valueContainer: (provided, state) => ({
		...provided,
		height: '30px',
		fontWeight: '400'
	})
};

export const selectLimitStyles = {
	option: (provided, state) => ({
		...provided,
		padding: 10,
		fontSize: 12,
		backgroundColor: state.isSelected ? '#7F3F98' : '#fff'
	}),
	container: provided => ({
		...provided,
		fontSize: 12
	}),
	control: provided => ({
		...provided,
		borderColor: '#f2f2f2',
		borderRadius: '5px',
		minHeight: '32px'
	}),
	indicatorSeparator: provided => ({
		...provided,
		backgroundColor: '#f2f2f2'
	}),
	dropdownIndicator: provided => ({
		...provided,
		padding: '5px'
	})
};

export const dropdownFilterStyles = {
	option: (provided, state) => ({
		...provided,
		padding: 10,
		backgroundColor: state.isSelected ? '#707683' : '#fff',
		fontSize: 12
	}),
	container: provided => ({
		...provided,
		fontSize: 12
	}),
	control: provided => ({
		...provided,
		borderColor: '#FFFFFF',
		borderRadius: '5px',
		border: '0.1px solid rgba(37, 65, 83, 0.3)',
		minHeight: '32px'
	}),
	indicatorSeparator: provided => ({
		...provided,
		backgroundColor: '#f2f2f2',
		display: 'none'
	}),
	menu: provided => ({
		...provided,
		marginTop: '-2px',
		border: '5px',
		borderTop: 'none',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0
	}),
	dropdownIndicator: provided => ({
		...provided,
		padding: '5px'
	})
};
