import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const uploadFile = (fieldName, data) => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const formData = new FormData();
	formData.append('images', data);

	return dispatch => {
		dispatch({
			type: UPLOAD_FILE_REQUEST
		});
		return axios({
			method: 'POST',
			url: `${URL_API}/api/files`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data: formData
		}).then(
			response => {
				dispatch({
					type: UPLOAD_FILE_SUCCESS,
					payload: response.data,
					fieldName
				});
				return response;
			},
			error => {
				dispatch({
					type: UPLOAD_FILE_ERROR
				});
				swal.fire('Error!', 'Upload failed, please try again', 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
				throw error;
			}
		);
	};
};

export const SET_FILES = 'SET_FILES';
export const setFiles = (fieldName, files) => async dispatch => {
	dispatch({
		type: SET_FILES,
		payload: files,
		fieldName
	});
};
