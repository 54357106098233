import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_FOUNDATION } from '../../../config/keys';
import { PostNoteParams } from '../model/Model';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

export async function postNote(params: PostNoteParams): Promise<number> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/v2/linked-elements/notes`, params)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return -1;
	}
}
