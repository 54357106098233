import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OpportunitiesDetail from './components/OpportunitiesDetail';
import { deleteData, detailData, fetchData, getDocuments, getEvents, getNotes } from '../../store/actions/opportunities.action';
import { getAllform, getDetailform, getLinkedform, getLinkedId } from '../../store/actions/formdata.action';
import { currentData } from '../../store/actions/user.action';
import { fetchData as getSalesOperations, getMerchandisings, getTradeshows } from '../../store/actions/salesOperations.action';
import { selectOpportunityStatus } from '../../store/actions/select.action';
import DropdownActionDetail from '../../components/DropdownActionDetail';
import FilterHandlePage from '../../components/FilterHandlePage';

class OpportunitiesDetailFull extends Component {
	state = {
		prev: null,
		next: null,
		index: null
	};

	componentDidMount() {
		const {
			detailData,
			match,
			currentData,
			fetchData,
		} = this.props;
		currentData();
		detailData(match.params.id);
		fetchData();
		this.props.selectOpportunityStatus(true);
	}

	componentWillReceiveProps(nextProps) {
		const { match, data } = this.props;
		if (data.data !== nextProps.data.data) {
			nextProps.data.data.length > 1 &&
        nextProps.data.data.map((value, i) => {
        	if (value.id === parseInt(match.params.id)) {
        		this.setState({
        			next:
                i !== 9
                	? (nextProps.data.data[i + 1] &&
                      nextProps.data.data[i + 1].id) ||
                    null
                	: null,
        			prev:
                i !== 0
                	? (nextProps.data.data[i - 1] &&
                      nextProps.data.data[i - 1].id) ||
                    null
                	: null,
        			index: i
        		});
        	}
        });
		}
	}

	render() {
		const { route, data, match } = this.props;

		return (
			<div>
				<div className="d-flex background-bar sticky-v2 right-panel">
					<div className="pr-3 my-auto">
						<Link to="/opportunities" style={{ textDecoration: 'none' }}>
							<h2 className="card-title mb-0">{route.title}</h2>
						</Link>
					</div>
					<div className="px-3 my-auto">
						<h2 className="card-title font-weight-bold mb-0">
							{(data && data.dataDetail && data.dataDetail.title) || '-'}
						</h2>
					</div>
					<div className="pl-5 my-auto">
						<FilterHandlePage
							getPage={this.props.getPage}
							page={this.props.page}
							index={this.state.index}
							next={this.state.next}
							prev={this.state.prev}
							path="/opportunities/detail"
						/>
					</div>
					<div className="ml-auto mx-2">
						<DropdownActionDetail
							id={match.params.id}
							name={!data.isLoading && data.dataDetail.title}
							path="opportunities"
							{...this.props}
						/>
					</div>
				</div>

				<OpportunitiesDetail {...this.props} />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	data: state.opportunities,
	page: state.page.page,
	salesOperation: state.salesOperations,
	status: state.select.opportunityStatus,
	linkedFormdata: state.formdata.allLinkedData,
	detailLinkedForm: state.formdata
});

export default connect(
	mapStateToProps,
	{
		deleteData,
		detailData,
		getEvents,
		getNotes,
		getDocuments,
		currentData,
		getSalesOperations,
		getTradeshows,
		getMerchandisings,
		fetchData,
		selectOpportunityStatus,
		getLinkedform,
		getLinkedId,
		getDetailform,
		getAllform,
	}
)(OpportunitiesDetailFull);
