import * as React from 'react';
import { CompanyPhoto } from '../../containers_v2/client-companies/model/Model';
import NewPhoto from '../../containers_v2/client-companies/popup/NewPhoto';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';

function PhotoCreation(props: {
  isOpen: boolean
  setOpen: (value: boolean) => void
  onCreate?: (values: CompanyPhoto[]) => void
  clientCompanyId?: number
  maxPhoto?: number
}): JSX.Element {
	function close(): void {
		props.setOpen(false);
	}

	return (
		<Popup
			isOpen={props.isOpen}
			onClickOut={() => close()}
			popupStyle={{ animate: true, top: '0%' }}
			popupMode={PopupMode.Details}
			content={<NewPhoto
				onClickOut={() => close()}
				onCreate={(values) => {
					(props.onCreate != null) && props.onCreate(values);
					close();
				}}
				clientCompanyId={props.clientCompanyId}
				maxPhoto={props.maxPhoto}
			/>}
		/>
	);
}

export default PhotoCreation;
