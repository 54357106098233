import { Cookies } from 'react-cookie';
import axios from 'axios';
import { URL_API } from '../../config/keys';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

export async function getFieldReportInstallers() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_API}/api/fieldReportInstallers`
	});
}

const SELECT_BRAND = 'SELECT_BRAND';
export const selectBrand = async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/brands/all`);

	dispatch({
		type: SELECT_BRAND,
		payload: res.data.data
	});
};

const SELECT_CATALOGUE = 'SELECT_CATALOGUE';
export const selectCatalogue = async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/catalogues/all`);

	dispatch({
		type: SELECT_CATALOGUE,
		payload: res.data.data
	});
};

const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const selectCategory = async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/categories/all`);

	dispatch({
		type: SELECT_CATEGORY,
		payload: res.data.data
	});
};

const SELECT_MENU = 'products';
export const selectMenu = async(name, dispatch) => {
	dispatch({
		type: SELECT_MENU,
		payload: name
	});
};
