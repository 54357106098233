import * as React from 'react';
import { Translate } from 'react-localize-redux';
import Opportunities from '../../containers/opportunities/Opportunities';
import DetailOpportunities from '../../containers/opportunities/DetailOpportunities';
import OpportunitiesForm from '../../containers/opportunities/OpportunitiesForm';
import { Route } from '../Routes';
import OpportunitiesRouteBlack from 'images/menu_icon/opportunity_black.svg';
import OpportunitiesRouteBlue from 'images/menu_icon/opportunity_blue.svg';
import OpportunitiesRouteWhite from 'images/menu_icon/opportunity_white.svg';

const OpportunitiesRoutes = (): Route[] => [
	{
		component: Opportunities,
		path: '/opportunities',
		name: <Translate id="opportunities" />,
		role: ['all'],
		key: 'opportunities',
		sectionKey: 'opportunities',
		logoImg: OpportunitiesRouteBlack,
		hoverImg: OpportunitiesRouteBlue,
		activeImg: OpportunitiesRouteWhite,
		title: 'opportunities',
		subTitle: 'opportunities',
		restricted: false,
		exact: true,
		permission: ['ViewOpportunity']
	},
	{
		component: OpportunitiesForm,
		path: '/opportunities/create',
		key: 'opportunity',
		sectionKey: 'opportunities',
		title: 'opportunities',
		name: 'New Opportunity',
		detail: 'Opportunity Details',
		role: ['admin'],
		exact: true
	},
	{
		component: OpportunitiesForm,
		path: '/opportunities/edit/:id',
		key: 'opportunity',
		sectionKey: 'opportunities',
		title: 'opportunities',
		name: 'Edit Opportunity',
		detail: 'Opportunity Details',
		role: ['admin'],
		exact: true
	},
	{
		component: OpportunitiesForm,
		path: '/opportunities/duplicate/:idDupe',
		key: 'opportunity',
		sectionKey: 'opportunities',
		title: 'opportunities',
		name: 'Duplicate Opportunity',
		detail: 'Opportunity Details',
		role: ['admin'],
		exact: true
	},
	{
		component: DetailOpportunities,
		path: '/opportunities/detail/:id',
		key: 'opportunity',
		sectionKey: 'opportunities',
		title: <Translate id="opportunities" />,
		name: 'Opportunity Detail',
		role: ['admin'],
		exact: true
	}
];

export default OpportunitiesRoutes;
