import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import {Translate} from 'react-localize-redux'

class PropDate extends Component {
  state = {};

  render() {
    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Date</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize
            type="text"
            className="form-control"
            defaultValue={this.props.element.field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'field_name',
              'value'
            )}
          />
        </div>

        <div className="custom-control custom-checkbox">
          <input
            id="is-default-to-today"
            className="custom-control-input"
            type="checkbox"
            defaultChecked={this.props.element.is_display_field_name}
            onBlur={this.props.updateElement.bind(this)}
            disabled={this.props.isEdit}
            onChange={this.props.editElementProp.bind(
              this,
              'is_display_field_name',
              'checked'
            )}
          />
          <label className="custom-control-label" htmlFor="is-default-to-today">
            Display field name
          </label>
        </div>

        <div className="form-group mt-3">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <input
            type="text"
            className="form-control"
            disabled={this.props.isEdit}
            defaultValue={this.props.element.system_field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'system_field_name',
              'value'
            )}
          />
        </div>

        <div className="custom-control custom-checkbox">
          <input
            id="is_default_value_today"
            className="custom-control-input"
            type="checkbox"
            disabled={this.props.isEdit}
            defaultChecked={this.props.element.is_default_value_today}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'is_default_value_today',
              'checked'
            )}
          />
          <label
            className="custom-control-label"
            htmlFor="is_default_value_today"
          >
            Set initial value as today
          </label>
        </div>

        <div className="form-group mt-3">
          <label className="control-label" htmlFor="">
            Validation
          </label>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              id="is-required"
              className="custom-control-input"
              type="checkbox"
              disabled={this.props.isEdit}
              defaultChecked={this.props.element.is_required}
              onBlur={this.props.updateElement.bind(this)}
              onChange={this.props.editElementProp.bind(
                this,
                'is_required',
                'checked'
              )}
            />
            <label className="custom-control-label" htmlFor="is-required">
              <Translate id="Field required" />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default PropDate;
