import user from 'images/icons/user.svg';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { CompleteTime } from '../../../../helpers/time';
import ModalComments from './ModalComments';
import PenUrl from 'images/ui_icon/pen_black.svg';
import PenBlueUrl from 'images/ui_icon/pen_blue.svg';


function Comments(props) {
	const { detail } = props;
	const [modal, setModal] = useState({ status: false, mode: 'View' });
	const [dataDetail, setDetail] = useState(null);
	const [penUrl, setPenUrl] = useState(PenUrl);
	const onHover = (pen) => {
		switch (pen) {
			case 'pen_blue.svg': return setPenUrl(PenBlueUrl);
			default: return setPenUrl(PenUrl);
		}
	};
	// if (!detail?.notes) {return <></>}
	return (
		(<div>
			<div className="detail-top m-3 p-3">
				<div className="mb-2">
					<span className="card-title">
						<Translate id="comment" />
					</span>
				</div>
				{detail?.notes ?
					<div className="card card-notes relative-btn-container">
						<div className="detail-top mt-0 p-2">
							<button
								className="btn-edit-absolute"
								style={{ right: '10px' }}
								onClick={e => {
									e.preventDefault();
									setDetail(detail);
									setModal({ status: true, mode: 'edit' });
								}}
							>
								<img
									src={penUrl}
									alt=""
									width="15px"
									height="15px"
									onMouseEnter={() => onHover('pen_blue.svg')}
									onMouseLeave={() => onHover('pen_black.svg')}
								/>
							</button>
							<div className="d-flex align-items-center pointer"
								onClick={() => {
									setModal({ status: true, mode: 'View' });
									setDetail(detail);
								}}
							>
								<img
									src={
										(detail && detail.owner && detail.owner.photo_url) || user
									}
									className="img-xs rounded-circle image-thumbnail mr-2"
								/>
								{detail && (
									<Translate>
										{({ translate }) => (
											<div className="pl-3" style={{ width: '90%' }}>
												<p className="mb-1 text-description word-break textLimitNote">
													{detail.notes || 'no comment'}
												</p>
												<p className="mb-1 text-by word-break">
													{`${translate('by')} ${detail.owner ? detail.owner.name : ''
													}  ${translate('on')}  ${CompleteTime(
														detail.created_at
													)}`}
												</p>
											</div>
										)}
									</Translate>
								)}
							</div>
						</div>
					</div> : <div style={{ color: '#707683', fontSize: '12px', marginLeft: 6 }}>
						<Translate id="no comment" />
					</div>}
			</div>
			<ModalComments
				isOpen={modal.status}
				toggle={() => setModal({ status: false, mode: 'View' })}
				onHover={onHover}
				penUrl={penUrl}
				mode={modal.mode}
				onChangeMode={e => setModal({ status: true, mode: e })}
				detail={dataDetail}
			/>
		</div>)
	);
}

export default Comments;
