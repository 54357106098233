import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectInCharge} from '../../../../../store/actions/select.action';
import {currentData} from '../../../../../store/actions/user.action';

class UserField extends Component {
  state = {
    opt: []
  };

  componentDidMount() {
    const { currentData } = this.props;
    currentData();
  }

  componentWillReceiveProps(nextProps) {
    const { current, selectInCharge, dataUser } = this.props;

    if (current !== nextProps.current) {
      selectInCharge(
        nextProps.current.id,
        nextProps.current.companyId,
        nextProps.current.role.slug
      );
    }
    const opt = [];

    if (dataUser !== nextProps.dataUser) {
      opt.push(<option value={current.id}>{current.name}</option>);

      nextProps.dataUser.map(data => {
        current.id !== data.id &&
          opt.push(<option value={data.id}>{data.name}</option>);
        return null;
      });

      this.setState({ opt });
    }
  }

  render() {
    const { data, current } = this.props;
    const { opt } = this.state;

    const defaultValue = data.is_default_user && current.id;

    console.log('current', defaultValue);

    // console.log('opt', opt);
    return (
      <div className="mt-3">
        <div className="form-group">
          <label className="control-label" htmlFor={`id-${data.id}`}>
            {data.is_required && <span className="text-danger">* </span>}
            {data.is_display_field_name && data.field_name}
          </label>
          <select
            id={`id-${data.id}`}
            className="form-control"
            defaultValue={defaultValue}
          >
            <option value="" selected={!defaultValue} disabled>
              Select user
            </option>
            {opt}
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataUser: state.select.inCharge,
  current: state.user.current
});

export default connect(
  mapStateToProps,
  { selectInCharge, currentData }
)(UserField);
