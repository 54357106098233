/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import swal from 'sweetalert2';
import { filter, get } from 'lodash';
import TableWrapper from './TableWrapper';
import Restricted from '../../../containers_v2/permissions/Restricted';

const TableListingPage = tableProp => WrappedComponent =>
	(class TableListingHOC extends Component {
		constructor(props) {
			super(props);
			this.state = {
				sort: 'desc',
				by: null,
				page: 1,
				limit: 50,
				createdOn: null,
				ownerId: null,
				groupId: null
			};
		}

		componentDidMount() {
			const { fetchData } = this.props;
			const { page, limit } = this.state;
			fetchData({ page, limit });
		}

		componentWillReceiveProps(nextProps) {
			const { current, fetchData, data, subPath } = this.props;
			const user = nextProps.current.current;

			// const { page, sort, by, incharge } = this.state;

			// if (current.current !== user) {
			//   if (user != undefined) {
			//     if (user.role.slug == 'manager') {
			//       if (user.group.length > 1) {
			//         // fetchData({ groupId: user.group[1].id }, subPath);
			//         fetchData({}, subPath);
			//       }
			//     } else if (user.role.slug == 'sales') {
			//       fetchData({ ownerId: user.id }, subPath);
			//     } else {
			//       fetchData(null, subPath);
			//     }
			//   }
			// }

			// On Sort
			if (data.sort !== nextProps.data.sort) {
				this.handleSort(nextProps.data.sort, nextProps.data.sortField);
			}
		}

		handleSort = (sort, by) => {
			const { fetchData, subPath } = this.props;
			const { page, createdOn, ownerId, groupId } = this.state;
			fetchData(
				{
					page,
					sort,
					by,
					createdOn,
					ownerId,
					groupId
				},
				subPath
			);
			this.setState({
				sort,
				by
			});
		};

		handleFilter = data => {
			const { fetchData, subPath } = this.props;
			const { sort, by, createdOn, ownerId, groupId } = this.state;

			fetchData(
				{
					page: 1,
					sort,
					by,
					createdOn:
            data.createdOn === 'all' ? null : data.createdOn || createdOn,
					ownerId: data.ownerId === 'all' ? null : data.ownerId || ownerId
				},
				subPath
			);

			this.setState({
				page: 1,
				createdOn:
          data.createdOn === 'all' ? null : data.createdOn || createdOn,
				ownerId: data.ownerId === 'all' ? null : data.ownerId || ownerId
			});
		};

		handleFilterGroup = data => {
			const { fetchData, subPath } = this.props;
			const { sort, by, createdOn, ownerId, groupId } = this.state;

			console.log(this.props);

			fetchData(
				{
					page: 1,
					sort,
					by,
					createdOn:
            data.createdOn === 'all' ? null : data.createdOn || createdOn,
					groupId: data.groupId === 'all' ? null : data.groupId || groupId
				},
				subPath
			);

			this.setState({
				page: 1,
				createdOn:
          data.createdOn === 'all' ? null : data.createdOn || createdOn,
				groupId: data.groupId === 'all' ? null : data.groupId || groupId
			});
		};

		onInitViewList = () => {
			const { fetchData, subPath } = this.props;
			const { sort, by, createdOn, ownerId } = this.state;

			fetchData(
				{
					page: 1,
					sort,
					by,
					createdOn,
					ownerId
				},
				subPath
			);

			this.setState({
				page: 1
			});
		};

		onNext = () => {
			const { sort, by, page, createdOn, ownerId, limit } = this.state;
			const { fetchData, subPath } = this.props;
			fetchData(
				{
					page: page + 1,
					// sort,
					limit
					// by,
					// createdOn,
					// ownerId
				},
				subPath
			);
			this.setState({ page: page + 1 });
		};

		onPrev = () => {
			const { sort, by, page, createdOn, ownerId, limit } = this.state;
			const { fetchData, subPath } = this.props;
			fetchData(
				{
					page: page - 1,
					// sort,

					limit
				},
				subPath
			);
			this.setState({ page: page - 1 });
		};

		onFirstPage = () => {
			const { sort, by, createdOn, ownerId, limit } = this.state;
			const { fetchData, subPath } = this.props;
			fetchData(
				{
					page: 1,
					sort,
					by,
					createdOn,
					ownerId,
					limit
				},
				subPath
			);
			this.setState({ page: 1 });
		};

		onLastPage = () => {
			const { sort, by, createdOn, ownerId, limit } = this.state;
			const { fetchData, data, subPath } = this.props;
			fetchData(
				{
					page: data.pagination.lastPage,
					sort,
					by,
					createdOn,
					ownerId,
					limit
				},
				subPath
			);
			this.setState({ page: data.pagination.lastPage });
		};

		toggle() {
			this.setState({
				modal: false
			});
		}

		onExport = (id, type) => {
			// console.log(id, type);
			const { exportExcelData, route, exportCsv } = this.props;
			const salesType = route.idPath;
			// console.log(salesType);
			if (type === 'csv') {
				exportCsv(
					{
						id
					},
					salesType
				);
			} else {
				exportExcelData(
					{
						id
					},
					salesType
				);
			}
		};

		onImport = formData => {
			const { importExcel, route } = this.props;
			importExcel(formData, route.idPath);
		};

		onBulkDelete = id => {
			const { bulkDelete, route } = this.props;
			// console.log(route.subPath);

			if (id.length > 0) {
				swal
					.fire({
						title: 'Attention!',
						text: 'Are you sure you want to delete selected data from the database? Any related elements will also be deleted.',
						type: 'warning',
						confirmButtonText: 'Yes, I do',
						confirmButtonColor: '#f7685b'
					})
					.then(result => {
						if (result.value) {
							bulkDelete(
								{
									id
								},
								route.subPath
							);
						}
					});
			} else {
				swal.fire({
					title: 'Attention!',
					text: 'No data selected!.',
					type: 'warning',
					showConfirmButton: false
				});
			}
		};

		checkRoleSetting = role => {
			if (role.roleId === 1 || role.roleId === 2) {
				return true;
			}
			return false;
		};

		handleLimit = limit => {
			const { fetchData } = this.props;
			const { sort, by } = this.state;
			const params = { sort, by, page: 1, limit };
			const pr = JSON.parse(localStorage.getItem('params'));
			localStorage.setItem('params', JSON.stringify({ ...pr, limit }));
			fetchData(params);
			this.setState({ page: 1, limit });
		};

		render() {
			const {
				data,
				current,
				modal,
				route,
				match,
				getterValue,
				setModalDuplicate
			} = this.props;
			const { columns } = tableProp;
			const { sort } = this.state;
			const currentUser = get(current, 'current', '');
			const columnsFilter = filter(
				columns(this.props),
				o => o.dataField !== 'quickAction'
			);
			const columnForm =
        currentUser && this.checkRoleSetting(currentUser)
        	? columns(this.props)
        	: columnsFilter;

			return (
				<Restricted to={{ objectAction: 'ReadFreeForm' }}>
					<div className="content-wrapper pt-md-5 mt-md-1">
						<div className="row">
							<div className="col-lg-12 grid-margin stretch-card">
								<div className="card rounded bg-table">
									<Fragment>
										<TableWrapper
											loading={current.isLoading || data.isLoading}
											dataNoPagination={data.dataNoPagination}
											data={data.data}
											current={current.current}
											defaultSortDirection={sort}
											columns={columnForm}
											page={data.pagination.currentPage}
											sizePerPage={data.pagination.recordPerPage}
											lastPage={data.pagination.lastPage}
											totalSize={data.pagination.count}
											onTableChange={this.handleTableChange}
											handleFilter={this.handleFilter}
											handleFilterGroup={this.handleFilterGroup}
											history={this.props.history}
											disableAddBtn={tableProp.disableAddBtn}
											customPath={tableProp.customPath}
											disablePagination={tableProp.disablePagination}
											route={this.props.route}
											onPageChange={this.onPageChange}
											onNext={this.onNext}
											onPrev={this.onPrev}
											onFirstPage={this.onFirstPage}
											onLastPage={this.onLastPage}
											modal={modal}
											setModalDuplicate={setModalDuplicate}
											duplicateForm={data.duplicateForm}
											onInitViewList={this.onInitViewList}
											onExport={this.onExport}
											onBulkDelete={this.onBulkDelete}
											onImport={this.onImport}
											match={match}
											getterValue={getterValue}
											handleLimit={this.handleLimit}
											limit={this.state.limit}
										/>
									</Fragment>

									<WrappedComponent {...this.props} state={this.state} />
								</div>
							</div>
						</div>
					</div>
				</Restricted>
			);
		}
	});

export default TableListingPage;
