/* eslint-disable camelcase */
import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import {Translate} from 'react-localize-redux'

class PropDropdownList extends Component {
  state = {};

  render() {
    // const { select } = this.state;
    const { accepted_values } = this.props.element;
    const options = accepted_values && accepted_values.split(';');
    const select = ['Empty', ...options];

    // const arrayVal = this.props.element.accepted_values && this.props.element.accepted_values.split(';');
    // console.log('arrayVal', options, select);

    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Dropdown List</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize
            type="text"
            className="form-control"
            defaultValue={this.props.element.field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'field_name',
              'value'
            )}
          />
        </div>

        <div className="custom-control custom-checkbox">
          <input
            id="is-display-field-name"
            className="custom-control-input"
            type="checkbox"
            disabled={this.props.isEdit}
            defaultChecked={this.props.element.is_display_field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'is_display_field_name',
              'checked'
            )}
          />
          <label
            className="custom-control-label"
            htmlFor="is-display-field-name"
          >
            Display field name
          </label>
        </div>

        <div className="form-group mt-3">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <input
            type="text"
            disabled={this.props.isEdit}
            className="form-control"
            defaultValue={this.props.element.system_field_name}
            onBlur={this.props.updateElement.bind(this)}
            onChange={this.props.editElementProp.bind(
              this,
              'system_field_name',
              'value'
            )}
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Validation
          </label>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              id="is-required"
              className="custom-control-input"
              type="checkbox"
              defaultChecked={this.props.element.is_required}
              onBlur={this.props.updateElement.bind(this)}
              disabled={this.props.isEdit}
              onChange={this.props.editElementProp.bind(
                this,
                'is_required',
                'checked'
              )}
            />
            <label className="custom-control-label" htmlFor="is-required">
              <Translate id="Field required" />
            </label>
          </div>
        </div>

        <div className="form-group mt-3">
          <label className="control-label" htmlFor="">
            Accepted values
          </label>
          <TextAreaAutosize
            type="text"
            className="form-control"
            disabled={this.props.isEdit}
            onChange={this.props.editElementProp.bind(
              this,
              'accepted_values',
              'value'
            )}
            defaultValue={this.props.element.accepted_values}
            onBlur={this.props.updateElement.bind(this)}
          />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            <Translate id="Initial Value" />
          </label>
          <select
            id="optionSelect"
            className="form-control"
            onChange={this.props.editElementProp.bind(
              this,
              'initial_value',
              'value'
            )}
            defaultValue={this.props.element.initial_value}
            disabled={this.props.isEdit}
            onBlur={this.props.updateElement.bind(this)}
          >
            {select && select.map(data => <option>{data}</option>)}
          </select>
        </div>
      </div>
    );
  }
}

export default PropDropdownList;
