import add_blue from 'images/icon/add_blue.png';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { Translate } from 'react-localize-redux';
// import Filter from './Filter';
// import FilterUser from './FilterUser';
import ListView from './ListView';
import ModalExport from '../../../components/modal/create/ModalExportData';
import ModalImportExcel from '../../../components/modal/create/ModalImportExcel';
import ModalCreateForm from './ModalCreateForm';
import Restricted from '../../../containers_v2/permissions/Restricted';

class TableWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOptionsOpen: false,
			dropdownCreateOpen: false,
			viewType: 'list',
			currentDetailId: null,
			done: false,
			modalFilterDate: false,
			modalFilterOwner: false,
			modalFilterGroup: false,
			modalExport: false,
			modal: false,
			selectedRows: [],
			selectedRowsArray: [],
			modalImport: false
		};
	}

	componentWillReceiveProps(nextProps) {
		const { data, duplicateForm } = this.props;
		if (
			nextProps.data.length > 0 &&
      data !== nextProps.data &&
      !this.state.done
		) {
			this.setState({
				currentDetailId: nextProps.data[0].id,
				done: true
			});
		}

		if (duplicateForm !== nextProps.duplicateForm) {
			this.setState({
				modal: !!nextProps.duplicateForm.id
			});
		}
	}

	onToggleFilterDate = () => {
		this.setState(prevState => ({
			modalFilterDate: !prevState.modalFilterDate
		}));
	};

	onToggleFilterOwner = () => {
		this.setState(prevState => ({
			modalFilterOwner: !prevState.modalFilterOwner
		}));
	};

	onToggleFilterGroup = () => {
		this.setState(prevState => ({
			modalFilterGroup: !prevState.modalFilterGroup
		}));
	};

	toggleOptions = () => {
		this.setState(prevState => ({
			dropdownOptionsOpen: !prevState.dropdownOptionsOpen
		}));
	};

	toggleCreate = () => {
		this.setState(prevState => ({
			dropdownCreateOpen: !prevState.dropdownCreateOpen
		}));
	};

	onSelect = row => {
		// console.log('row', row);
		this.setState({
			selectedRows: row.toString(),
			selectedRowsArray: row
		});
	};

	onOpenModalExport = () => {
		this.setState({ modalExport: true });
	};

	onCloseModalExport = () => {
		this.setState({ modalExport: false });
	};

	onOpenModalImport = () => {
		this.setState({ modalImport: true });
	};

	onCloseModal = () => {
		this.setState({ modalImport: false });
	};

	toggle() {
		this.setState({
			modal: false
		});
		// reset modal duplicate
		this.props.setModalDuplicate();
	}

	render() {
		const { viewType } = this.state;
		const {
			data,
			page,
			onTableChange,
			route,
			totalSize,
			columns,
			lastPage,
			handleFilter,
			handleFilterGroup,
			isSelectRow,
			showPagination,
			loading,
			enableDownload,
			disablePagination,
			onNext,
			onPrev,
			onFirstPage,
			onLastPage,
			onExport,
			current,
			getterValue,
			onBulkDelete,
			onImport,
			match,
			duplicateId,
			limit,
			handleLimit
		} = this.props;

		return (
			(<div>
				<div className="table-bg sticky-v2 right-panel">
					<div className="row py-1 d-flex justify-content-between">
						{route.subPath ? (
							<Fragment>
								<div className="col-md-3 card-title mb-0 my-auto d-flex justify-content-start pl-3">
									<Link to="/formBuilder">
										<Translate id={route.title} />
									</Link>
									<h2 className="card-title font-weight-bold mb-0">
										{/* {route.subTitle} */}
										{get(getterValue, 'name')}
									</h2>
								</div>
								{/* <div className="col-md-7  d-flex flex-row">
                  <Filter onFilter={handleFilter} />
                  <FilterUser
                    onFilter={handleFilter}
                    handleFilterGroup={handleFilterGroup}
                    current={current}
                  />
                </div> */}
							</Fragment>
						) : (
							<div className="col-md-8">
								{/* <div className="col-md-3  d-flex flex-row">
                  <Filter onFilter={handleFilter} />
                  <FilterUser
                    onFilter={handleFilter}
                    handleFilterGroup={handleFilterGroup}
                    current={current}
                  />
                </div> */}
							</div>
						)}
						<div className="col-md-2 d-flex justify-content-end pr-3 pointer">
							<Restricted to={{ objectAction: 'CreateFreeForm' }}>
								{route.subPath ? (
									<div className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle">
										<a href={`/formBuilder/formData/${match.params.id}/create`}>
											<img
												src={add_blue}
												className="custom-icon"
												alt=""
											/>
										</a>
									</div>
								) : (
									<div className="my-auto mx-1">
										{current &&
                      current.role.slug !== 'sales' &&
                      current.role.slug !== 'manager' && (
											<div className="ml-4 mr-1 my-auto custom-icon-blue rounded-circle">
												<a
													onClick={() => {
														this.setState({
															modal: true
														});
													}}
												>
													<img
														src={add_blue}
														className="custom-icon"
														alt=""
													/>
												</a>
											</div>
										)}
									</div>
								)}
							</Restricted>
						</div>
						<ModalCreateForm
							isOpen={this.state.modal}
							toggle={() => {
								this.toggle();
							}}
							onClick={() => {
								this.toggle();
							}}
						/>
					</div>
				</div>
				{viewType === 'list' && (
					<ListView
						data={data}
						page={page}
						onTableChange={onTableChange}
						route={route}
						totalSize={totalSize}
						columns={columns}
						lastPage={lastPage}
						handleFilter={handleFilter}
						isSelectRow={isSelectRow}
						showPagination={showPagination}
						loading={loading}
						enableDownload={enableDownload}
						disablePagination={disablePagination}
						onNext={onNext}
						onPrev={onPrev}
						onLastPage={onLastPage}
						onFirstPage={onFirstPage}
						onSelect={this.onSelect}
						limit={limit}
						handleLimit={handleLimit}
					/>
				)}
				<ModalExport
					isOpen={this.state.modalExport}
					toggle={this.onCloseModalExport}
					dataExport={this.state.selectedRows}
					onExport={onExport}
				/>
				<ModalImportExcel
					isOpen={this.state.modalImport}
					toggle={this.onCloseModal}
					onImport={onImport}
				/>
				{/* {viewType === 'mixt' && (
          <KanbanView
            data={dataNoPagination}
            page={page}
            lastPage={lastPage}
            onChangeTab={this.onChangeDetailId}
            route={route}
            currentDetailId={currentDetailId}
          />
        )}
        {viewType === 'map' && <MapView data={data} />} */}
			</div>)
		);
	}
}

export default TableWrapper;
