import { Container, FullOpen, HeaderLeft, LeftContainer } from '../../client-companies/style/PopupStyle';
import modal_open from 'images/icons/company/modal_open.svg';
import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import Popup from '../../../components_v2/popup/Popup';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import close from 'images/icons/orders/close.svg';
import { HeaderTitle } from '../../../components_v2/popup/style/PopupCreationStyle';
import { FlexDiv, RightContainer } from '../../products/style';
import { BorderColor } from '../../../styles/global/css/Utils';
import Input from '../../../components_v2/input/Input';
import { creationPopupInputStyle } from '../../client-companies/style/Style';
import {
	PercentBarWrapperProps,
	ShelfAuditDetail,
	ShelfAuditContentProps,
	ShelfAuditPopupProps,
	UserBlockWrapperProps
} from '../model/models';
import { UserBlock } from '../../../components_v2/dropdown/DropdownOwners';
import { translateToString } from '../../../styles/global/translate';
import * as moment from 'moment';
import { useRecoilValue } from 'recoil';
import { AUsers } from '../../../atoms/global/users';
import PercentBar from './PercentBar';
import { inputStyle, InputStyleContainer, InputStyleDiv, InputStyleLabel, PercentDiv } from '../style/styles';
import { getColorForPercentage } from '../../reports/utils';
import { getShelfAudit } from '../actions';
import ShelfAuditPopupLinkedElements from './innerPopupContent/ShelfAuditPopupLinkedElements';
import { TableRowDnEvolutionContainer } from '../../orders/templateOrders/style/Style';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import { ModalState } from '../../products/model';
import { InputStyle } from '../../../components_v2/input/model/Model';


export function UserBlockWrapper(props: PropsWithChildren<UserBlockWrapperProps>) {
	return <InputStyleContainer>
		<InputStyleLabel>{props.label}</InputStyleLabel>
		<InputStyleDiv>
			{props.children}
		</InputStyleDiv>
	</InputStyleContainer>;
}

function PercentBarWrapper(props: PropsWithChildren<PercentBarWrapperProps>) {
	return <InputStyleContainer>
		<InputStyleLabel>{props.label}</InputStyleLabel>
		<InputStyleDiv
			padding={props.style.padding} border={props.style.border} gap="10px"
			fontSize={props.style.fontSize}>
			{props.children}
		</InputStyleDiv>
	</InputStyleContainer>;
}

function ShelfAuditContent({
	onClickOut, shelfAudit, isFullOpen, handleResizePopup, onDisableClose, isChild, clientCompanyId
}: ShelfAuditContentProps) {
	const owners = useRecoilValue(AUsers);
	const dnPercent = `${(shelfAudit.dn * 100).toFixed(2)}%`;
	const dnEvolutionPercent = (Math.abs((shelfAudit.dn_evolution ?? 0) * 100)).toFixed(2);
	const dateFormatted = moment.utc(shelfAudit.date).format('L LT');
	const prevDateFormatted = shelfAudit.previous_date ? moment.utc(shelfAudit.previous_date).format('L LT') : '-';

	const [shelfAuditChildModal, setShelfChildAuditModal] = useState<ModalState>({ isOpen: false });
	const dynamicStyle = !isChild && shelfAudit.previous_id ? { cursor: 'pointer', fontWeight: 600 } : undefined;

	return <Container>
		<LeftContainer isFullOpen={isFullOpen}>
			<HeaderLeft>
				<FlexDiv
					width="100%" justify="space-evenly" gap="20px" padding="5px 10px 10px 10px"
					borderBottom={`1px solid ${BorderColor}`} margin="0 0 20px 0">
					<DefaultImage cursor="pointer" src={close} onClick={onClickOut}/>
					<HeaderTitle>{`${translateToString('shelf_audit.popup.shelf_audit_title')} - ${dateFormatted}`}</HeaderTitle>
					{<FullOpen src={modal_open} isActive={isFullOpen} onClick={handleResizePopup}/>}
				</FlexDiv>
			</HeaderLeft>
			<FlexDiv flow="column" padding="0 15px">
				<Input
					inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
					name="shelf_audit_template"
					type="text"
					label={translateToString('shelf_audit.popup.shelf_audit_template')}
					value={shelfAudit.name}
					disableChange
				/>
				<UserBlockWrapper label={`${translateToString('shelf_audit.popup.owner')}`}>
					<UserBlock user={owners.find(el => el.id === shelfAudit.owner_id)}/>
				</UserBlockWrapper>
				<Input
					inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
					name="company_name"
					type="text"
					label={translateToString('shelf_audit.popup.company_name')}
					value={shelfAudit.company_name}
					disableChange
				/>
				<Input
					inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
					name="date"
					type="text"
					label="Date"
					value={dateFormatted}
					disableChange
				/>
				<PercentBarWrapper
					label={translateToString('shelf_audit.popup.dn')}
					style={{ padding: '0px', border: '0', fontSize: '12px' }}
				>
					<PercentBar value={dnPercent} backgroundColor={getColorForPercentage(shelfAudit.dn)}/>
					<PercentDiv fontWeight="600">{dnPercent}</PercentDiv>
					<TableRowDnEvolutionContainer dnEvolution={shelfAudit.dn_evolution}>
						{shelfAudit.dn_evolution && shelfAudit.dn_evolution > 0 ? <span>&#8593;</span> : undefined}
						{shelfAudit.dn_evolution && shelfAudit.dn_evolution < 0 ? <span>&#8595;</span> : undefined}
						{!shelfAudit.dn_evolution ? '= -' : dnEvolutionPercent}%
					</TableRowDnEvolutionContainer>
				</PercentBarWrapper>
				<Input
					inputStyle={{ ...inputStyle, ...creationPopupInputStyle, ...dynamicStyle } as InputStyle}
					name="previous_date"
					type="text"
					label={translateToString('shelf_audit.popup.previous_shelf_audit')}
					value={prevDateFormatted}
					disableChange
					onClick={() => {
						if (shelfAudit.previous_date === null) return;
						setShelfChildAuditModal(prev => ({ ...prev, isOpen: true }));
					}}
				/>
			</FlexDiv>
			{!isFullOpen && <ShelfAuditPopupLinkedElements
				shelfAudit={shelfAudit} isFullOpen={isFullOpen}
				onDisableClose={onDisableClose}/>}
		</LeftContainer>
		<RightContainer isOpen={isFullOpen}>
			<ShelfAuditPopupLinkedElements
				shelfAudit={shelfAudit} isFullOpen={isFullOpen}
				onDisableClose={onDisableClose}/>
		</RightContainer>
		{(!isChild && shelfAudit.previous_id) && <ShelfAuditPopup
			shelfAuditId={shelfAudit.previous_id}
			clientCompanyId={clientCompanyId}
			popupStyle={{ animate: true, width: '450px' }}
			isChild={true}
			isOpen={shelfAuditChildModal.isOpen}
			fullOpen={shelfAuditChildModal.fullOpenMode}
			handleResizePopup={() => setShelfChildAuditModal((prev) => ({
				...prev,
				fullOpenMode: (prev.fullOpenMode !== true)
			}))}
			popupMode={shelfAuditChildModal.fullOpenMode ? PopupMode.Default : PopupMode.Details}
			onClickOut={() => setShelfChildAuditModal({ isOpen: false })}
		/>}
	</Container>;
}

function ShelfAuditPopup(props: ShelfAuditPopupProps) {
	const [shelfAudit, setShelfAudit] = useState<ShelfAuditDetail | undefined>();
	const [disableOutClick, setDisableClickOut] = useState<boolean>(false);

	useEffect(() => {
		if (props.shelfAuditId && props.clientCompanyId)
			getShelfAudit(props.shelfAuditId, props.clientCompanyId).then(setShelfAudit).catch(e => console.log(e));
	}, [props.shelfAuditId]);
	return <>
		{
			shelfAudit && <Popup
				isOpen={props.isOpen}
				onClickOut={props.onClickOut}
				popupStyle={props.popupStyle}
				popupMode={props.popupMode}
				disableOutClick={disableOutClick}
				disableEsc={disableOutClick}
				content={<ShelfAuditContent
					onClickOut={props.onClickOut}
					shelfAudit={shelfAudit}
					isFullOpen={props.fullOpen ?? false}
					handleResizePopup={() => props.handleResizePopup()}
					onDisableClose={(value) => setDisableClickOut(value)}
					clientCompanyId={props.clientCompanyId}
					isChild={props.isChild}
				/>}
			/>
		}
	</>;
}

export default ShelfAuditPopup;