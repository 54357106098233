import * as moment from 'moment';
import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ARefresh } from '../../../atoms/global/refresh';
import { AUsers } from '../../../atoms/global/users';
import DropdownSearch from '../../../components_v2/dropdown/DropdownSearch';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import Input from '../../../components_v2/input/Input';
import PopupCreation from '../../../components_v2/popup/PopupCreation';
import storeLang from '../../../helpers/storeLang';
import { SpanAreaLabel, TextArea, TextAreaContainer, TextAreaLabel } from '../../calendar/style/NewEventStyle';
import { CompanyNote } from '../../client-companies/model/Model';
import { CreationPopupContainer, creationPopupInputStyle } from '../../client-companies/style/Style';
import { normalizeCapital } from '../../globals/FieldFunction';
import { LoadingStateEnum } from '../../import/model';
import { getFilteredCompanies } from '../../orders/data/Data';
import { ClientCompany } from '../../orders/model/Model';
import { postNote } from '../data/Data';
import { PostNoteParams } from '../model/Model';
import { Spacer } from '../style/NewOpportunityStyle';
import { LinkedElementId } from '../../../containers/companies/components/linkedElements/actions';

function NewNote(props: {
  onClickOut?: () => void
  onCreate?: (value: CompanyNote) => void
  id?: LinkedElementId
}): JSX.Element {
	const translate = getTranslate(storeLang.getState().localize);
	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [title, setTitle] = React.useState<string>('');
	const [bodyText, setBodyText] = React.useState<string>('');

	const [optionCompanies, setOptionCompanies] = React.useState<DropdownData[]>([]);
	const [company, setCompany] = React.useState<ClientCompany>();

	const users = useRecoilValue(AUsers);
	const [, setRefreshingAtom] = useRecoilState(ARefresh);

	function submit(): void {
		if (title.trim().length > 0 && bodyText.trim().length > 0) {
			const params: PostNoteParams = {
				title: title.trim(),
				body: bodyText.trim(),
				id: props.id ?? { Company: ((company != null) ? company.id : -1) }
			};

			setLoading(LoadingStateEnum.LOADING);
			postNote(params).then(response => {
				const user = users.find(u => u.isYou);
				if (response !== -1) {
					const nNote = {
						note_id: response,
						note_body: bodyText.trim(),
						note_title: title.trim(),
						note_count: 1,
						client_company_id: props.id ?? ((company != null) ? company.id : -1),
						note_created_at: moment.utc().toLocaleString(),
						user_email: user?.email,
						user_id: user?.id,
						user_name: user?.name,
						user_photo: user?.photoUrl
					} as CompanyNote;
					(props.onCreate != null) && props.onCreate(nNote);
					setRefreshingAtom({ timeline: {} });
					setLoading(LoadingStateEnum.LOADED);
				}
			});
		}
	}

	function body(): JSX.Element {
		return (
			<CreationPopupContainer>

				<Input
					inputStyle={creationPopupInputStyle}
					name="title"
					type="text"
					placeholder={translate('company.creation.note.title').toString()}
					label={translate('company.creation.note.title').toString()}
					required
					normalize={[normalizeCapital]}
					onChange={(value) => setTitle(value)}
				/>

				<Spacer />

				<TextAreaContainer>
					<TextAreaLabel><SpanAreaLabel>*</SpanAreaLabel> {translate('company.creation.note.description').toString()}</TextAreaLabel>
					<TextArea onChange={(e) => setBodyText(e.currentTarget.value)} />
				</TextAreaContainer>

				{
					!props.id &&
					<>
						<Spacer />

						<DropdownSearch
							datalist={optionCompanies}
							name="clientCompanyName"
							label={translate('company.creation.note.company').toString()}
							optionHeight={200}
							width={250}
							labelUp
							required
							link={'/companies?id='}
							onChange={(value) => {
								setCompany(value?.value);
							}}
							selectedValue={optionCompanies.find(comp => JSON.stringify(comp.value) == JSON.stringify(optionCompanies))}
							onSearchChange={async(search, offset) => {
								return await getFilteredCompanies(search, 20, offset)
									.then(res => {
										const ret: boolean = res.data.length == 0 || res.data.length < 20;
										if (offset !== undefined) {
											setOptionCompanies([...optionCompanies, ...res.data.map((company: ClientCompany) => {
												return {
													label: company.name,
													value: company
												};
											})]);
										} else {
											setOptionCompanies(res.data.map(company => {
												return {
													label: company.name,
													value: company
												};
											}));
										}
										return ret;
									})
									.catch(error => {
										console.log(error);
										return false;
									});
							}}
						/>
					</>
				}

			</CreationPopupContainer>
		);
	}

	return (
		<PopupCreation
			title={translate('company.creation.note.create_note').toString()}
			content={body()}
			canValidate={title.trim().length > 0 && bodyText.trim().length > 0}
			onSubmit={() => submit()}
			loading={loading}
			onClose={() => (props.onClickOut != null) && props.onClickOut()}
		/>
	);
}

export default NewNote;
