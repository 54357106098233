import * as React from 'react';
import { LoadingStateEnum } from '../containers_v2/import/model';

interface StateProps<S> {
	initialState: S | (() => S),
	fn?: (s: { oldValue: S, newValue: S }) => S
}

/**
 * Same as React.useState but apply a function whenever the state is updated
 * The function is not called when the state is initialized
 * @param  {S} initialState The initial state
 * @param  {(s: { oldValue: S, newValue: S }) => S} fn Function called every time the state is updated
 * This function has 2 parameters, the old value, and the new one.
 * The function return the new state
 * @return {[S, React.Dispatch<React.SetStateAction<S>>, React.Dispatch<React.SetStateAction<S>>]} The state, the setter, and the setter without the function
 */
export function useFunctionState<S = undefined>(initialState: StateProps<S>['initialState'], fn?: StateProps<S>['fn']): [S, React.Dispatch<React.SetStateAction<S>>, React.Dispatch<React.SetStateAction<S>>] {
	const [state, setState_] = React.useState(initialState);
	// implement the callback ref pattern
	const callbackRef = React.useRef(fn);
	React.useLayoutEffect(() => {
		callbackRef.current = fn;
	});
	const setState = React.useCallback((input: (oldValue: S) => S | S) => setState_(oldValue => {
		let newValue: S;
		if (typeof input === 'function') {
			newValue = input(oldValue);
		} else {
			newValue = input;
		}
		return callbackRef.current ? callbackRef.current({ oldValue, newValue }) : newValue;
	}), []);
	return [state, setState, setState_];
}

/**
 * Same as useFunctionState but doesn't return the value of the state but a mutable reference on it
 * @see useFunctionState for implementation
 * @param  {S} initialState The initial state
 * @param  {(s: { oldValue: S, newValue: S }) => S} fn Function called every time the state is updated
 * This function has 2 parameters, the old value, and the new one.
 * The function return the new state
 * @return {[S, React.Dispatch<React.SetStateAction<S>>]} The same as React.useState
 */
export function useRefState<S = undefined>(initialState: StateProps<S>['initialState'], fn?: StateProps<S>['fn']): [React.MutableRefObject<S>, React.Dispatch<React.SetStateAction<S>>, S] {
	const [state_, setState] = useFunctionState(initialState, (p: { oldValue: S, newValue: S }) => {
		let value = p.newValue;
		if (fn) value = fn(p);
		state.current = value;
		return value;
	});
	const state = React.useRef(state_);
	return [state, setState, state_];
}

/**
 * Load the script given in params and return the state
 * @param  {string} url The script's url
 * @return {LoadingStateEnum} The current state of the script
 */
export const useExternalScript = (url: string): LoadingStateEnum => {
	const [state, setState] = React.useState<LoadingStateEnum>(url ? LoadingStateEnum.LOADING : LoadingStateEnum.ERROR);

	React.useEffect(() => {
		if (!url) {
			setState(LoadingStateEnum.ERROR);
			return;
		}

		let script = document.querySelector(`script[src="${url}"]`);

		const handleScript = (e) => {
			setState(e.type === 'load' ? LoadingStateEnum.LOADED : LoadingStateEnum.ERROR);
		};

		if (!script) {
			script = document.createElement('script');
			// @ts-expect-error it works
			script.type = 'application/javascript';
			// @ts-expect-error it works
			script.src = url;
			// @ts-expect-error it works
			script.async = true;
			document.body.appendChild(script);
		}
		script.addEventListener('load', handleScript);
		script.addEventListener('error', handleScript);

		return () => {
			script?.removeEventListener('load', handleScript);
			script?.removeEventListener('error', handleScript);
		};
	}, [url]);

	return state;
};

type Key = {
	key: string,
	ctrlKey?: boolean,
	altKey?: boolean,
	shiftKey?: boolean,
	metaKey?: boolean
};

/**
 * Call a function when a key is pressed
 * @param  {Key[]} keys keys to listen to
 * @param  {(key: Key, ev: KeyboardEvent) => void} fn Function called every time a listened key is pressed
 * @param  {Node} node The node where the event listener is attached
 * @param  {boolean} noPreventDefault If true, the default behavior of the key is not prevented
 */
export function useKeyPress(keys: Key[], callback?: (key: Key, ev: KeyboardEvent) => void, node?: Node, noPreventDefault?: boolean) {
	// implement the callback ref pattern
	const callbackRef = React.useRef(callback);
	React.useLayoutEffect(() => {
		callbackRef.current = callback;
	});

	const handleKeyPress = React.useCallback(
		(event: KeyboardEvent) => {
			const key = keys.find((key) => {
				if (event.key !== key.key) return false;
				if (key.altKey && !event.altKey) return false;
				if (key.ctrlKey && !event.ctrlKey) return false;
				if (key.shiftKey && !event.shiftKey) return false;
				if (key.metaKey && !event.metaKey) return false;
				return true;
			});
			if (key !== undefined) {
				if (!noPreventDefault) event.preventDefault();
				callbackRef.current?.(key, event);
			}
		},
		[keys]
	);

	React.useEffect(() => {
	// target is either the provided node or the document
		const targetNode = node ?? document;
		targetNode && targetNode.addEventListener('keydown', handleKeyPress);

		return () => targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
	}, [handleKeyPress, node]);
}
