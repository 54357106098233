import {
	CREATE_CONTACT_ERROR,
	CREATE_CONTACT_FILE_ERROR,
	CREATE_CONTACT_FILE_REQUEST,
	CREATE_CONTACT_FILE_SUCCESS,
	CREATE_CONTACT_REQUEST,
	CREATE_CONTACT_SUCCESS,
	CREATE_EVENT_ERROR,
	CREATE_EVENT_REQUEST,
	CREATE_EVENT_SUCCESS,
	CREATE_NOTE_ERROR,
	CREATE_NOTE_REQUEST,
	CREATE_NOTE_SUCCESS,
	CREATE_OPPORTUNITY_ERROR,
	CREATE_OPPORTUNITY_REQUEST,
	CREATE_OPPORTUNITY_SUCCESS,
	DETAIL_CONTACT_ERROR,
	DETAIL_CONTACT_REQUEST,
	DETAIL_CONTACT_SUCCESS,
	FETCH_CONTACT_ERROR,
	FETCH_CONTACT_REQUEST,
	FETCH_CONTACT_SUCCESS,
	GET_ACTIVITIES_ERROR,
	GET_ACTIVITIES_REQUEST,
	GET_ACTIVITIES_SUCCESS,
	GET_DOCUMENTS_ERROR,
	GET_DOCUMENTS_REQUEST,
	GET_DOCUMENTS_SUCCESS,
	GET_EMAIL_DETAIL_ERROR,
	GET_EMAIL_DETAIL_REQUEST,
	GET_EMAIL_DETAIL_SUCCESS,
	GET_EMAILS_ERROR,
	GET_EMAILS_REQUEST,
	GET_EMAILS_SUCCESS,
	GET_EVENT_DETAIL_ERROR,
	GET_EVENT_DETAIL_REQUEST,
	GET_EVENT_DETAIL_SUCCESS,
	GET_EVENTS_ERROR,
	GET_EVENTS_REQUEST,
	GET_EVENTS_SUCCESS,
	GET_NOTE_DETAIL_ERROR,
	GET_NOTE_DETAIL_REQUEST,
	GET_NOTE_DETAIL_SUCCESS,
	GET_NOTES_ERROR,
	GET_NOTES_REQUEST,
	GET_NOTES_SUCCESS,
	GET_OPPORTUNITIES_ERROR,
	GET_OPPORTUNITIES_REQUEST,
	GET_OPPORTUNITIES_SUCCESS,
	IMPORT_EXCEL_ERROR,
	IMPORT_EXCEL_REQUEST,
	IMPORT_EXCEL_SUCCESS,
	SEARCH_CONTACTS,
	SORT_DATA,
	UPDATE_CONTACT_ERROR,
	UPDATE_CONTACT_REQUEST,
	UPDATE_CONTACT_SUCCESS
} from '../actions/contacts.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	current: null,
	data: [],
	dataCreated: null,
	dataDetail: [],
	detail: [],
	search: null,
	sortField: null,
	sort: 'asc',

	pagination: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},

	// LINKED ELEMENTS
	isLoadingOpportunities: false,
	isLoadingEvents: false,
	isLoadingActivities: false,
	isLoadingNotes: false,
	isLoadingEmails: false,
	isLoadingDocuments: false,
	opportunities: [],
	events: [],
	activities: [],
	notes: [],
	emails: [],
	documents: [],
	errorValidation: [],

	noteDetail: [],
	emailDetail: [],
	eventDetail: [],

	paginationOpportunities: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},

	paginationEvents: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},

	paginationActivities: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},

	paginationNotes: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},

	paginationEmails: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	},

	paginationDocuments: {
		currentPage: 1,
		lastPage: 1,
		count: 0,
		recordPerPage: 0
	}
};

export default function ContactsReducer(state = initState, action) {
	switch (action.type) {
		case FETCH_CONTACT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case FETCH_CONTACT_SUCCESS:
			return {
				...state,
				data: action.payload.data ? action.payload.data : [],
				pagination: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoading: false
			};
		case FETCH_CONTACT_ERROR:
			return {
				...state,
				data: [],
				isLoading: false
			};

		case DETAIL_CONTACT_REQUEST:
			return {
				...state,
				isLoadingDetail: true
			};
		case DETAIL_CONTACT_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoadingDetail: false
			};
		case DETAIL_CONTACT_ERROR:
			return {
				...state,
				isLoadingDetail: false
			};

		case CREATE_CONTACT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_CONTACT_SUCCESS:
			return {
				...state,
				dataCreated: action.payload.data,
				isLoading: false
			};
		case CREATE_CONTACT_ERROR:
			return {
				...state,
				isLoading: false
			};

		case UPDATE_CONTACT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case UPDATE_CONTACT_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_CONTACT_ERROR:
			return {
				...state,
				isLoading: false
			};

		case SEARCH_CONTACTS:
			return {
				...state,
				search: action.payload
			};

		case SORT_DATA:
			return {
				...state,
				sortField: action.payload,
				sort: state.sort === 'desc' ? 'asc' : 'desc'
			};

		case IMPORT_EXCEL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case IMPORT_EXCEL_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case IMPORT_EXCEL_ERROR:
			return {
				...state,
				errorValidation: action.error.validation,
				isLoading: false
			};

		// CREATE LINKED ELEMENT
		case CREATE_OPPORTUNITY_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_OPPORTUNITY_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_OPPORTUNITY_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_CONTACT_FILE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_CONTACT_FILE_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_CONTACT_FILE_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_EVENT_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_EVENT_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_EVENT_ERROR:
			return {
				...state,
				isLoading: false
			};

		case CREATE_NOTE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case CREATE_NOTE_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case CREATE_NOTE_ERROR:
			return {
				...state,
				isLoading: false
			};

		// GET LINKED ELEMENT
		case GET_OPPORTUNITIES_REQUEST:
			return {
				...state,
				isLoadingOpportunities: true
			};
		case GET_OPPORTUNITIES_SUCCESS:
			return {
				...state,
				opportunities: action.payload.data || [],
				paginationOpportunities: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingOpportunities: false
			};
		case GET_OPPORTUNITIES_ERROR:
			return {
				...state,
				isLoadingOpportunities: false
			};

		case GET_ACTIVITIES_REQUEST:
			return {
				...state,
				isLoadingActivities: true
			};
		case GET_ACTIVITIES_SUCCESS:
			return {
				...state,
				activities: action.payload.data,
				paginationActivities: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingActivities: false
			};
		case GET_ACTIVITIES_ERROR:
			return {
				...state,
				isLoadingActivities: false
			};

		case GET_NOTES_REQUEST:
			return {
				...state,
				isLoadingNotes: true
			};
		case GET_NOTES_SUCCESS:
			return {
				...state,
				notes: action.payload.data || [],
				paginationNotes: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingNotes: false
			};
		case GET_NOTES_ERROR:
			return {
				...state,
				isLoadingNotes: false
			};

		case GET_NOTE_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_NOTE_DETAIL_SUCCESS:
			return {
				...state,
				noteDetail: action.payload.data,
				isLoading: false
			};
		case GET_NOTE_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_EMAILS_REQUEST:
			return {
				...state,
				isLoadingEmails: true
			};
		case GET_EMAILS_SUCCESS:
			return {
				...state,
				emails: action.payload.data || [],
				paginationEmails: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingEmails: false
			};
		case GET_EMAILS_ERROR:
			return {
				...state,
				isLoadingEmails: false
			};

		case GET_EMAIL_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_EMAIL_DETAIL_SUCCESS:
			return {
				...state,
				emailDetail: action.payload.data,
				isLoading: false
			};
		case GET_EMAIL_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_EVENTS_REQUEST:
			return {
				...state,
				isLoadingEvents: true
			};
		case GET_EVENTS_SUCCESS:
			return {
				...state,
				events: action.payload.data || [],
				paginationEvents: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingEvents: false
			};
		case GET_EVENTS_ERROR:
			return {
				...state,
				isLoadingNotes: false
			};

		case GET_EVENT_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_EVENT_DETAIL_SUCCESS:
			return {
				...state,
				eventDetail: action.payload.data,
				isLoading: false
			};
		case GET_EVENT_DETAIL_ERROR:
			return {
				...state,
				isLoading: false
			};

		case GET_DOCUMENTS_REQUEST:
			return {
				...state,
				isLoadingDocuments: true
			};
		case GET_DOCUMENTS_SUCCESS:
			return {
				...state,
				documents: action.payload.data || [],
				paginationDocuments: {
					currentPage: action.payload.pagination.currentPage,
					lastPage: action.payload.pagination.lastPage,
					count: action.payload.pagination.count,
					recordPerPage: action.payload.pagination.recordPerPage
				},
				isLoadingDocuments: false
			};
		case GET_DOCUMENTS_ERROR:
			return {
				...state,
				isLoadingDocuments: false
			};

		default:
			return state;
	}
}
