import * as React from 'react';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import { PopupMode, PopupStyle } from '../../../components_v2/popup/model/Model';
import Popup from '../../../components_v2/popup/Popup';
import { CompanyEvent } from '../../client-companies/model/Model';
import { LoadingStateEnum } from '../../import/model';
import { CalendarEvent, EventsTypes } from '../Calendar';
import { getEvent } from '../EventsApi';
import { ModalFields } from '../ModalEventForm';
import { isAllDayDates } from '../utils';
import NewEvent, { EventMode, NewEventDefaultValues } from './NewEvent';
import usePermission from '../../permissions/usePermission';
import Restricted from '../../permissions/Restricted';

export function CompanyEventToCalendarEvent(event: CompanyEvent): CalendarEvent {
	const start = new Date(Date.parse(event.event_start_date + 'Z'));
	const end = new Date(Date.parse(event.event_end_date + 'Z'));
	return {
		type: EventsTypes.Event,
		id: event.event_id,
		eventStatusId: event.event_status_id,
		eventTypeId: event.event_type_id,
		title: event.event_title,
		start,
		end,
		allDay: isAllDayDates(start, end),
		incharge: event.incharge_id,
		description: event.event_description,
		clientCompanyId: event.client_company_id,
		contactId: event.contact_id,
	};
}

type EventModification = [string, any, string | undefined]

export interface PopupEventProps {
	isOpen: boolean
	setIsOpen: (b: boolean) => void
	event?: CalendarEvent
	onValueChange?: (values: Array<EventModification>, eventId?: number) => void
	popupStyle?: PopupStyle
	hide?: ModalFields[]
	onDelete?: () => void
	defaultValues?: NewEventDefaultValues
	onCreate?: () => void
	eventId?: number
}

export default function PopupEvent(props: PopupEventProps) {
	const [editionList, setEditionList] = React.useState<Array<EventModification>>([]);
	const [event, setEvent] = React.useState<CalendarEvent>();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const readOnly = !usePermission({ objectAction: event?.id ? 'UpdateEvent' : 'CreateEvent' }, { ownerId: props.event?.incharge });
	const [eventMode, setEventMode] = React.useState<EventMode>(); 

	React.useEffect(() => setEditionList([]), [props.isOpen]);
	React.useEffect(() => {
		setEvent(props.event);
	}, [props.event]);

	const onValueChange = (value: Array<EventModification>) => {
		setEditionList([
			...editionList.filter(([s, __, _]) => !value.some(([s2, _, __]) => s2 == s)),
			...value.filter(([s, v, _]) => {
				switch (s) {
					case 'type_id':
						return props.event?.eventTypeId !== v;
					case 'incharge':
						return props.event?.incharge !== v;
					case 'form':
						return props.event?.form !== v;
					case 'status_id':
						return props.event?.eventStatusId !== v;
					case 'company_id':
						return props.event?.clientCompanyId !== v.updated || (v == 'deleted' && !props.event?.clientCompanyId);
					case 'contact_id':
						return props.event?.contactId !== v.updated || (v == 'deleted' && !props.event?.contactId);
					case 'title':
						return props.event?.[s] !== v;
					case 'description':
						return props.event?.[s] !== v.updated || (v == 'deleted' && !props.event?.description);
					default: return true;
				}
			})
		]);
	};

	React.useEffect(() => {
		if (props.eventId) {
			setLoadingState(LoadingStateEnum.LOADING);
			getEvent(props.eventId).then(event => {
				setLoadingState(LoadingStateEnum.LOADED);
				if (!event) {
					setEvent(undefined);
					return;
				}
				const start = new Date(Date.parse(event.startDate));
				const end = new Date(Date.parse(event.endDate));
				setEvent({
					...event,
					type: EventsTypes.Event,
					start,
					end,
					allDay: isAllDayDates(start, end),
				});
			}).catch(e => {
				console.error(e);
				setLoadingState(LoadingStateEnum.ERROR);
			});
		} else {
			setEvent(undefined);
		}
	}, [props.eventId]);

	return <Popup
		isOpen={props.isOpen}
		popupMode={PopupMode.Details}
		onClickOut={() => props.setIsOpen(false)}
		popupStyle={{ ...props.popupStyle, animate: true }}
		catchOutsideEventClick
	>
		<Restricted to={{ objectAction: 'ReadEvent' }} ownerId={event?.incharge}>
			{loadingState === LoadingStateEnum.LOADING
				? <PageLoader />
				: <NewEvent
					eventMode={eventMode}
					readonly={readOnly}
					defaultValues={props.defaultValues}
					hide={props.hide}
					event={event}
					edition={eventMode !== EventMode.Creation}
					onValueChange={onValueChange}
					onClickOut={() => {
						props.setIsOpen(false);
						setEventMode(undefined);
					}}
					hideValidation={editionList.length == 0 && eventMode !== EventMode.Creation}
					onSubmit={props.onValueChange && event && eventMode !== EventMode.Creation ? () => {
						props.onValueChange?.(editionList, event.id);
						props.setIsOpen(false);
						setEventMode(undefined);
					} : undefined}
					onCreate={() => {
						props.onCreate?.();
						props.setIsOpen(false);
						setEventMode(undefined);
					}}
					onDelete={() => {
						props.onDelete?.();
						props.setIsOpen(false);
						setEventMode(undefined);
					}}
					onDuplicate={() => {
						setEventMode(EventMode.Creation);
					}}
				/>
			}
		</Restricted>
	</Popup>;
}
