import axios from 'axios';
import { Cookies } from 'react-cookie';
import { selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';


export type Product = {
	id: number
	uuid: string,
    name: string,
	brand?: number,
	category?: number,
	tags: number[],
}

// -----------------------[ ATOM ]----------------------- //
export const ACompanies = selector<Record<number, string>>({
	key: 'ACompanies',
	get: async() => getClientCompanies().then(a => a.reduce((acc, [id, name]) => {
		acc[id] = name;
		return acc;
	}, {} as Record<number, string>))
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getClientCompanies(): Promise<[number, string][]> {
	try {
		return await axios({
			method: 'get',
			url: `${URL_FOUNDATION}/api/v2/client-companies/atom`
		}).then(res => res.data);
	} catch (e) {
		console.error(e);
		return [];
	}
}