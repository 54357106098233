import * as React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { MappingResume } from '../../../../../web-types/mapping';
import { AExternalMappings, getExternalMappings } from '../../../atoms/global/mappings';
import Add from '../../../components_v2/add/Add';
import { DeleteDot } from '../../../styles/global/css/Dot';
import { SidelyBlack } from '../../../styles/global/css/Utils';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { AlertRes } from '../../alert/AlertProvider';
import useAlert from '../../alert/UseAlert';
import NewMapping from '../../mappings/newMapping';
import Restricted from '../../permissions/Restricted';
import { ModalState } from '../../products/model';
import { FlexDiv } from '../../products/style';
import { deleteMapping } from './actions';
import { SystemSettingsWrapper, SystemSettingTitle } from './styles';
import { ToolbarState } from '../../globals/mainPage/mainPage';

const MappingText = styled.div`
	color: ${SidelyBlack};
`;

const mappingTypeIdToString = (id: number): string => {
	switch (id) {
		case 1: return 'ClientCompanies';
		case 2: return 'Contacts';
		case 3: return 'Products';
		case 4: return 'orders';
		case 5: return 'assortments';
		case 6: return 'events';
		default: throw 'unknown mapping';
	}
};

export default function MappingFieldsSettings(props: {setToolBarState: (state: ToolbarState) => void}) {
	const [mappings, setMappings] = useRecoilState(AExternalMappings);
	const fetchMappings = async() => setMappings(await getExternalMappings());
	const [modalState, setModalState] = React.useState<ModalState<number>>({ isOpen: false });
	const sortedMappings = React.useMemo(() => mappings.reduce((acc: MappingResume[][], m) => {
		acc[m.mapping_type_id - 1].push({ ...m });
		return acc;
	}, [[], [], [], [], [], []]), [mappings]);
	const alert = useAlert();

	React.useEffect(() => {
		props.setToolBarState({});
	}, []);

	return <SystemSettingsWrapper>
		{sortedMappings.map((mappings, i) => {
			const type_id = i + 1;
			return <>
				<SystemSettingTitle>{translateToNode(mappingTypeIdToString(type_id) + '_mapping_field')}</SystemSettingTitle>
				{mappings.map(m => {
					let base = mappingTypeIdToString(type_id);
					if (base === 'orders') base = 'orders_';
					return <>
						<FlexDiv gap='1em'>
							<MappingText>{m.name}</MappingText>
							<Restricted to={{ objectAction: 'DeleteCompanySettings' }}>
								<DeleteDot size='1.5em' fontSize='1em' onClick={() => alert({
									title: translateToString('do_you_want_to_delete_the_target', [['target', 'mapping']]),
									content: <div>{translateToNode('attention_delete_target', { variables: [['target', 'mapping'], ['base', translateToString(base).toLocaleLowerCase()]] })}</div>,
									width: '600px',
									mode: 'delete'
								}).then(res => {
									if (res === AlertRes.Ok) {
										deleteMapping(m.id)
											.then(_ => {
												fetchMappings();
											});
									}
								})} />
							</Restricted>
						</FlexDiv>
					</>;
				})}
				<Restricted to={{ objectAction: 'CreateCompanySettings' }}>
					<Add onClick={() => setModalState({ isOpen: true, data: type_id })} />
				</Restricted>
				<div />
			</>;
		})}
		<NewMapping isOpen={modalState.isOpen} setIsOpen={b => setModalState({ isOpen: b })} onCreate={fetchMappings} mappingId={modalState.data!} />
	</SystemSettingsWrapper>;
}