import axios from 'axios';
import * as React from 'react';
import styled from 'styled-components';
import { URL_FOUNDATION } from '../../config/keys';
import InputImage from '../input/InputImage';
import { ImageResult } from '../input/model/Model';
import Dropdown from './Dropdown';
import { DropdownData, DropdownStyle } from './model/Model';

async function get_form_icons() {
	return await axios.get(`${URL_FOUNDATION}/api/forms/icons`);
}

const IconFormImg = styled.img`
    max-width: 25px;
    max-height: 25px;
`;

export interface FormIcon {
  id: string
  code: string
  name: string
  url: string
}

export default function DropdownFormIcon(props: {
  dropdownStyle?: DropdownStyle
  onChange?: (icon: DropdownData) => void
  label?: JSX.Element | string
  required?: boolean
  cancelCreation?: boolean
  refresh?: Object
  iconId?: string
}): JSX.Element {
	const [icons, setIcons] = React.useState<FormIcon[]>([]);
	const [image, setImage_] = React.useState<ImageResult>();
	const [selectedValue, setSelectedValue] = React.useState<DropdownData>();
	const [inputImage, setInputImage] = React.useState<JSX.Element>(<InputImage size={25} onChange={setImage} />);
	const ref = React.useRef(inputImage);
	function setImage(res: ImageResult) {
		setSelectedValue({
			label: '',
			value: res,
			imageJsx: <IconFormImg src={res.url} />
		});
		props.onChange?.({
			label: '',
			value: res
		});
		setImage_(res);
	}

	React.useEffect(() => {
		if (props.refresh != null) {
			setInputImage(<InputImage size={25} onChange={setImage} />);
			setSelectedValue(undefined);
			setImage_(undefined);
		}
	}, [props.refresh]);

	React.useEffect(() => {
		if (props.iconId && icons) {
			const icon = icons.find(i => i.id == props.iconId);
			if (icon != null) {
				setSelectedValue({
					label: '',
					value: icon.id,
					imageJsx: <IconFormImg src={icon.url} />
				});
				props.onChange?.({
					label: '',
					value: icon.id
				});
			}
		}
	}, [props.iconId, icons]);

	React.useEffect(() => {
		get_form_icons()
			.then(res => setIcons(res.data))
			.catch(console.log);
	}, []);

	const maped: DropdownData[] = icons.map(icon => {
		return {
			label: '',
			value: icon.id,
			imageJsx: <IconFormImg src={icon.url} />
		};
	});
	if (!props.cancelCreation) {
		maped.unshift({
			label: '',
			value: image,
			imageJsx: ref.current
		});
	}

	return (
		<Dropdown
			allwaysOpen
			dropdownStyle={props.dropdownStyle}
			gallery
			datalist={maped}
			name={'select_dropdown_form_icons'}
			containerClickable
			readOnly
			label={props.label}
			required={props.required}
			onChange={(value: DropdownData) => {
				if (value.imageJsx !== ref.current) {
					props.onChange?.(value);
				}
				setSelectedValue(value);
			}}
			selectedValue={selectedValue}
		/>
	);
}
