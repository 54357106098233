import axios from 'axios';
import { URL_FOUNDATION } from '../../../config/keys';
import { CompanyNote } from '../../client-companies/model/Model';
import { Owner } from '../../orders/model/Model';

export async function getNoteById(id: number, owners: Owner[]): Promise<CompanyNote> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/notes/${id}`).then((resp): CompanyNote => {
		const dbNote = resp.data;
		const owner = owners.find(o => o.id == dbNote.user_id);
		return {
			note_count: 0,
			note_id: dbNote.id,
			client_company_id: dbNote.client_company_id,
			note_title: dbNote.title,
			note_body: dbNote.body,
			note_created_at: dbNote.created_at,
			user_id: dbNote.user_id,
			user_name: owner?.name ?? '',
			user_email: owner?.email ?? '',
			user_photo: owner?.photoUrl ?? ''
		};
	});
}
