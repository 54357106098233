import user from 'images/icons/user.svg';
import arrow_down from 'images/icon/arrow_down.png';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getTranslate, Translate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { useStore } from 'react-redux';
import { toast } from 'react-toastify';
import { HideScrollBar, Panel } from './ModalRight';
import ModalCalendar from './ModalCalendar';
import Select from 'react-select';
import { customStyles } from '../../../helpers/styleSelect';
import { selectInCharge, WEEK_DAY_LIST, optimizeVisits, VisitsOptimizationBody } from '../../calendar/EventsApi';
import { Interconnection } from './model';
import { BorderColor, BorderColor2, DarkGreySidely, RedSidely } from '../../../styles/global/css/Utils';
import { InitialMapRow } from '../model';
import { FlexDiv } from '../../products/style';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { AEventTypes } from '../../../atoms/global/events';
import { useRecoilValue } from 'recoil';
import { DayPickerModal } from '../../../components_v2/toolbarFilter/time/DatePicker';
import * as moment from 'moment';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import { translateToString } from '../../../styles/global/translate';
import Input from '../../../components_v2/input/Input';
import { dateToNaiveDateTime, momentToNaiveDateTime, useWindowDimensions } from '../../../components_v2/utils';
import { InputMode } from '../../../components_v2/input/model/Model';
import calendarImage from 'images/icon/small_calendar2.svg';
import ClockImage from 'images/vendors/alarm-clock.svg';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { creationPopupDropdownStyle } from '../../client-companies/style/Style';
import { Spacer } from '../../contact/style/NewContactStyle';
import Restricted from '../../permissions/Restricted';
import { getFormTemplates } from '../../../atoms/forms';
import { FormTemplate } from '../../forms/jsonValidator';

const MAX_COMPANIES = 100;

const Button = styled(DefaultButton)`
	margin: 0;
	width: 40%;
`;

const Titles = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 6px;
  color: rgba(37, 65, 83, 0.8);
`;

const Mandatory = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: ${RedSidely};
  display: inline;
`;

const DatePickerStyle = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
	height: 50px;
	font-family: Poppins;
	font-style: normal;
	font-size: 12px;
  }
`;

const ErrorMessage = styled.div`
  width: 100%;
  font-size: 11px;
  color: #dc3545;
`;

const DurationPickerStyle = styled.div`
  color: ${DarkGreySidely};
  text-decoration: underline;
  text-decoration-color: ${DarkGreySidely};
  font-size: x-small;
  font-family: Poppins;
  min-width: 28px;
`;

const DurationPickerModalWrapper = styled.div<{ isOpen: boolean, top?: boolean }>`
	position: absolute;
	top: ${p => p.top ? -10 : 55}px;
	left: 46px;
	max-height: ${p => p.isOpen ? '270px' : '0px'};
	box-shadow: 0 2px 6px rgba(0,0,0,0.05),0 0 0 1px rgba(0,0,0,0.07);
	z-index: 1;
	${p => p.isOpen ? `
		&:after {
			content: " ";
			position: absolute;
			left:50%;
			${p.top ? 'top' : 'bottom'}: 100%;  /* At the top of the tooltip */
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: ${p.top ? BorderColor2 : 'transparent' } transparent ${p.top ? 'transparent' : BorderColor2} transparent;
		}
	` : ''}
	translate: -50% ${p => p.top ? '-100%' : ''};
`;

export function simpleTimePicker(disabled: boolean, currDate: Date | null, onChange: (d: Date) => void, error: string | null, placeholder?: string) {
	const [isOpen, setIsOpen] = React.useState<{ isOpen: boolean, top?: boolean}>({ isOpen: false });
	const [search, setSearch] = React.useState<string>();
	const { height } = useWindowDimensions();

	return (
		<div style={{ width: '100%', position: 'relative' }}>
			<DurationPickerModalWrapper isOpen={isOpen.isOpen} top={isOpen.top}>
				{isOpen?.isOpen && <DayPickerModal
					search={search}
					noAbsolute
					future
					onClickOut={() => {
						setIsOpen({ isOpen: false });
						setSearch(undefined);
					}}
					noDayButton
					time={true}
					date={currDate ? moment(currDate) : undefined}
					noDate
					onDateChange={date => {
						setIsOpen({ isOpen: false });
						onChange(date.toDate());
						setSearch(undefined);
					}}
				/>}
			</DurationPickerModalWrapper>
			<div className='form-group row' style={{ marginBottom: '0' }}>
				<div className='col-12' style={{ position: 'relative' }}>
					<Input
						onChange={setSearch}
						inputStyle={{
							containerWidth: '100%',
							width: '100%',
							height: '50px',
							borderRadius: '4px',
							padding:'14px',
						}}
						name={''}
						type='string'
						value={
							search !== undefined
								? search : currDate 
									? moment(currDate).format('HH:mm') : undefined
						}
						mode={error ? InputMode.Error : undefined}
						onClick={e => {
							!disabled && setIsOpen({ isOpen: true, top: e.screenY > height / 2 + 80 });
						} }
						placeholder={placeholder ?? ''}
					/>
				</div>
			</div>
		</div>
	);
}

export function simpleDatePicker(disabled: boolean, currDate: Date, onChange: Function) {
	return (
		<div style={{ width: '100%' }}>
			<div className='form-group row'>
				<div className='col-12' style={{ position: 'relative' }}>
					<DatePickerStyle>
						<DatePicker
							className={'form-control font-date'}
							popperPlacement={'bottom'}
							selected={currDate}
							dateFormat='dd/MM/yyyy'
							placeholderText='DD/MM/YYYY'
							showYearDropdown
							onChange={onChange}
							disabled={disabled}
							components={{ DropdownIndicator: (x) => x }}
						/>
						<div className='icon-field'>
							<img
								src={calendarImage}
								style={{ width: '20px', margin: '8px' }}
								alt=''
							/>
						</div>
					</DatePickerStyle>
				</div>
			</div>
		</div>
	);
}

export function simpleDurationPicker(
	currentNumber: string,
	currentLabel: string,
	setTimeOperator: Function,
	placeholder: string,
	error?: string | null
) {
	const [dropdownOpen, setDropdownOpen] = React.useState(false);
	const translate = getTranslate(storeLang.getState().localize);

	function toggleDropdown() {
		setDropdownOpen(!dropdownOpen);
	}

	return (
		(<div style={{
			background: '#FFFFFF',
			border: `1px solid ${error ? RedSidely : BorderColor}`,
			boxSizing: 'border-box',
			borderRadius: '4px',
			height: '50px',
			width: '100%',
			display: 'flex',
			alignItems: 'center'
		}}>
			<Input
				type='text'
				value={currentNumber}
				onChange={nb => setTimeOperator({ nb })}
				placeholder={(translate('map.modal_right.' + placeholder) as string)}
				inputStyle={{ border: 'none', containerWidth: 'calc(100% - 114px)', fontSize: '12px', padding: '14px', color: error ? RedSidely : '', width: '100%' }}
				name={''}
			/>
			<ButtonDropdown
				isOpen={dropdownOpen}
				toggle={toggleDropdown}
				style={{ border: 'none' }}
			>
				<DropdownToggle id="dropdown-custom-components"
					style={{ background: '#ffffff', border: 'none', padding: '0px 30px 0 10px' }} >
					<DurationPickerStyle>
						{currentLabel}
						<img src={arrow_down} style={{ height: '5px' }} />
					</DurationPickerStyle>
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem eventKey="1" style={{ fontSize: 'x-small', fontFamily: 'Poppins' }}
						onClick={() => {
							setTimeOperator({ op: 'min' });
						}}>
			min
					</DropdownItem>
					<DropdownItem eventKey="2" style={{ fontSize: 'x-small', fontFamily: 'Poppins' }}
						onClick={() => {
							setTimeOperator({ op: 'h' });
						}}>
			h
					</DropdownItem>
				</DropdownMenu>
			</ButtonDropdown>
			<img src={ClockImage} style={{ width: '20px' }} />
		</div>)
	);
}

function checkBoxList(valChecked: number[], setValChecked: Function) {
	const translate = getTranslate(storeLang.getState().localize);
	const options = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
		.map((e, i) => { return { label: translate('map.modal_right.' + e) as string, value: i }; });
	const name = 'createEventFromLasso';

	function handleChecked(isChecked: boolean, value: number) {
		const newValue = valChecked;
		if (isChecked) {
			newValue.push(value);
		} else {
			newValue.splice(newValue.indexOf(value), 1);
		}
		setValChecked([...newValue]);
	}

	return (
		<div>
			{options.map((option, i) => (
				<React.Fragment key={`check_box_day_of_weeks[${i}]`}>
					<label style={{ width: '110px', height: '30px', margin: '0' }} className='check-sidely'>
						<input
							name={`${name}[${i}]`}
							id={`${name}[${i}]`}
							type="checkbox"
							value={option.value}
							checked={valChecked && valChecked.includes(option.value)}
							onChange={e =>
								handleChecked(e.target.checked, option.value)
							}
						/>
						<div className="checkbox-style" style={{ top: '7px' }} />
						<span style={{
							display: 'inline',
							position: 'relative',
							fontFamily: 'Poppins',
							fontStyle: 'normal',
							fontWeight: 'normal',
							fontSize: '12px',
							lineHeight: '21px',
							left: '20px'
						}}>{option.label}</span>
					</label>
				</React.Fragment>

			))}
		</div>
	);
}

function generateAjustMinBy15() {
	const date = new Date();
	const min = date.getMinutes();
	const moduloBy15 = min % 15;
	if (moduloBy15 > 0) 
		date.setMinutes(min + (15 - moduloBy15));
	return date;
}

export default function CreateEventFromLasso(props: {
  type?: { id: number, name: string}
  panel?: Panel
  companies: InitialMapRow[]
  onBack: () => void
  onCreate?: () => void
  noLimit?: boolean
  onChildChange?: (b: boolean) => void
  v2?: boolean
  noTitle?: boolean
}) {
	const translate = getTranslate(storeLang.getState().localize);
	const translateString = (str: string) => (translate(str) as string);
	const [maxEvent, setMaxEvent] = React.useState('');
	const [firstEventDate, setFirstEventDate] = React.useState(new Date());
	const [firstEventHour, setFirstEventHour] = React.useState(generateAjustMinBy15());
	const [hourStartDay, setHourStartDay] = React.useState(new Date(2000, 1, 1, 8, 0));
	const [hourEndDay, setHourEndDay] = React.useState(new Date(2000, 1, 1, 17, 0));
	const [pauseHour, setPauseHour] = React.useState<Date | null>(null);
	const [durationPicker, setDurationPicker] = React.useState({ fst: { nb: '', op: 'min' }, snd: { nb: '', op: 'min' }, trd: { nb: '', op: 'min' } });
	const [daysSelected, setDaysSelected] = React.useState([0, 1, 2, 3, 4]);
	const [globalError, setGlobalError] = React.useState<boolean>(false);
	const [buttonError, setButtonError] = React.useState<boolean>(false);
	const [modalOpen, setModalOpen_] = React.useState<boolean>(false);
	const [events, setEvents] = React.useState<any[]>([]);
	const [inCharge, setInCharge] = React.useState<object>({ details: null, list: [] });
	const name: string = (props.type != null) ? translateString(`event.${props.type.name}`) : '';
	const data = useStore().getState();
	const eventTypes = useRecoilValue(AEventTypes);
	const [form, setForm] = React.useState<FormTemplate>();
	const [formTemplates, setFormTemplates] = React.useState<FormTemplate[]>([]);


	const [title, setTitle] = React.useState<string>();
	const [description, setDescription] = React.useState<string>();

	const setModalOpen = (b: boolean) => {
		setModalOpen_(b);
		props.onChildChange?.(b);
	};

	React.useEffect(() => {
		if (props.panel === Panel.MassEvents) {
			if (props.companies.length > MAX_COMPANIES) {
				toast.warn(translate('map.modal_right.warning_over_100_events') as string);
			}
		}
	}, [props.panel]);

	React.useEffect(() => {
		selectInCharge(data.user?.current?.id, data.user?.current?.companyId, '')
			.then(incharges => {
				const inChargeList = incharges.data.data.map(e => {
					return {
						value: e.id,
						label: (
							<div>
								{' '}
								<img
									src={e.photoUrl || user}
									alt=""
									className="img-xs rounded-circle image-thumbnail mr-2"
								/>{' '}
								{`${e.name} ${e.id == data.user?.current?.id ? translate('calendar.you') : ''}`}
							</div>
						)
					};
				});
				setInCharge({ list: inChargeList, details: inChargeList.find(e => e.value == data.user?.current?.id) });
			})
			.catch(e => {
				console.error(e);
			});
		getFormTemplates().then(response => {
			setFormTemplates(response);
		});
	}, []);

	function getError(key: string, error?: boolean) {
		const localError = typeof (error) !== 'undefined' ? error : globalError;
		const order = {
			duration_each_event: 'fst',
			time_between_each_event: 'snd',
			duration_pause: 'trd'
		};
		switch (key) {
			case 'max_events_per_day':
				if (!(/^\d+$/.test(maxEvent) || maxEvent.length == 0)) {
					return translateString('map.modal_right.fill_this_with_numbers_only');
				} else if (maxEvent.length > 0 && parseFloat(maxEvent) <= 0) {
					return translateString('map.modal_right.must_be_greater_than_0');
				}
				return null;
			case 'first_event_hour':
				return (!firstEventHour && localError ? translateString('map.modal_right.fill_the_field') : null);
			case 'hour_end_day':
				return (!hourEndDay && localError ? translateString('map.modal_right.fill_the_field') : null);
			case 'hour_start_day':
				return (!hourStartDay && localError ? translateString('map.modal_right.fill_the_field') : null);
			case 'duration_each_event':
			case 'time_between_each_event':
			case 'duration_pause': {
				const orderKey = order[key];
				if (!(/^\d+$/.test(durationPicker[orderKey].nb) || durationPicker[orderKey].nb.length == 0)) {
					return translateString('map.modal_right.fill_this_with_numbers_only');
				} else if (durationPicker[orderKey].op === 'min' && durationPicker[orderKey].nb.length > 0) {
					return (parseFloat(durationPicker[orderKey].nb) > 60 * 60 * 24 ? translateString('map.modal_right.duration_bigger_than_a_day') : null);
				} else if (durationPicker[orderKey].op === 'h' && durationPicker[orderKey].nb.length > 0) {
					return (parseFloat(durationPicker[orderKey].nb) > 24 ? translateString('map.modal_right.duration_bigger_than_a_day') : null);
				} else if (durationPicker[orderKey].nb.length == 0 && orderKey == 'fst' && localError) {
					return translateString('map.modal_right.fill_the_field');
				} else if (durationPicker[orderKey].nb.length == 0 && orderKey == 'trd' && localError && (pauseHour != null)) {
					return translateString('map.modal_right.duration_is_needed_if_pause_time_is_setted');
				}
				return null;
			}
			case 'days_of_activity':
				if (localError && daysSelected.length == 0) {
					return translateString('map.modal_right.fill_the_field');
				}
				return null;
			default:
				return null;
		}
	}

	function asAnError() {
		const allKeys = [
			'max_events_per_day',
			'first_event_hour',
			'duration_each_event',
			'time_between_each_event',
			'duration_pause',
			'days_of_activity',
			'hour_end_day',
			'hour_start_day'
		];
		return (allKeys.map(e => getError(e, true)).some(e => e !== null));
	}

	async function createEventsList(companies?: any[], interconnections?: Interconnection[]): Promise<any[]> {
		const eventDuration: number = parseFloat(durationPicker.fst.nb) * 1000 * 60 * (durationPicker.fst.op === 'min' ? 1 : 60) / 1000;
		let rawTravelDuration = parseFloat(durationPicker.snd.nb);
		if (isNaN(rawTravelDuration)) {
			rawTravelDuration = 0;
		}
		const travelDuration: number = rawTravelDuration * 1000 * 60 * (durationPicker.snd.op === 'min' ? 1 : 60) / 1000;
		const visits = (companies ?? props.companies).map((c, i) => ({
			id: c.id,
			duration: eventDuration,
			travel_duration: interconnections?.[i]?.time ?? travelDuration
		}));
		
		const body: VisitsOptimizationBody = {
			visits,
			first_visit: momentToNaiveDateTime(moment(firstEventDate).set({ hour: firstEventHour.getHours(), minute: firstEventHour.getMinutes(), second: 0, millisecond: 0 })),
			working_hours: {
				start: dateToNaiveDateTime(hourStartDay),
				end: dateToNaiveDateTime(hourEndDay)
			},
			pause: pauseHour ? {
				time: dateToNaiveDateTime(pauseHour),
				duration: (parseFloat(durationPicker.trd.nb) * 1000 * 60 * (durationPicker.trd.op === 'min' ? 1 : 60)) / 1000
			} : undefined,
			working_days: daysSelected.map(ds => WEEK_DAY_LIST[ds]),
			max_event_day: maxEvent ? parseInt(maxEvent) : undefined
		};
		try {
			const res = await optimizeVisits(body);
			return res.reduce((acc: any[], r) => {
				const company = (companies ?? props.companies).find(c => r.id === (c.id ?? c.company_id));
				if (!company) return acc;
				acc.push({
					eventTypeId: props.type?.id,
					eventStatusId: 1,
					title: `${title ? title : name} -- ${company.name ?? ''}`,
					companyName: company.name,
					clientCompanyId: (company.id ?? company.company_id)?.toString(),
					description,
					incharge: `${inCharge['details']?.value}`,
					startDate: new Date(r.start),
					endDate: new Date(r.end),
				});
				return acc;
			}, []);
		} catch (e) {
			console.error(e);
			return [];
		}
	}

	function isInWorkDay(lowerBound: Date) {
		const trueStartDay: Date = new Date(2000, 1, 1, hourStartDay.getHours(), hourStartDay.getMinutes(), 0, 0);
		const trueEndDay: Date = new Date(2000, 1, 1, hourEndDay.getHours(), hourEndDay.getMinutes(), 0, 0);
		const toCompareLower: Date = new Date(2000, 1, 1, lowerBound.getHours(), lowerBound.getMinutes(), 0, 0);
		if (trueStartDay.getTime() < trueEndDay.getTime()) {
			return trueStartDay.getTime() <= toCompareLower.getTime() && trueEndDay.getTime() > toCompareLower.getTime();
		}
		return !(trueStartDay.getTime() > toCompareLower.getTime() && trueEndDay.getTime() <= toCompareLower.getTime());
	}

	return (
		<>
			{!props.noTitle &&
		<div style={{
			fontFamily: 'Poppins',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '14px',
			color: 'rgb(50, 60, 71)',
			margin: '22px 0px 40px 25px'
		}}>
			<Translate id='map.modal_right.plan_your' />{name}{name.toLowerCase().slice(-1) === 's' ? '' : 's'}
		</div>
			}

			<div style={{
				overflow: 'auto',
				padding: '0 25px',
				height: !props.noTitle ? 'calc(100vh - 291px)' : 'calc(100vh - 138px - 5%)'
			}}>
				<Titles><Translate id='map.modal_right.owner' /></Titles>
				<div style={{ height: '63px' }}>
					<Select
						id={'inputOwner'}
						name={'select_in_charge'}
						styles={{
							...customStyles,
							container: provided => ({
								...provided,
								fontSize: 12,
								width: '100%',
								height: 50
							})
						}}
						placeholder={translate('calendar.pick_in_charge')}
						optionClassName="needsclick"
						options={inCharge.list}
						value={inCharge.details}
						isSearchable={false}
						onChange={(value) => setInCharge({ ...inCharge, details: value })}
						className={`${globalError && !inCharge.details?.value ? 'focus-error focus-error-select' : ''}`}
					/>
				</div>
				<Titles><Translate id='title' /></Titles>
				<div style={{ height: '75px' }}>
					<div style={{
						background: '#FFFFFF',
						border: `1px solid ${getError('title') ? RedSidely : BorderColor}`,
						boxSizing: 'border-box',
						borderRadius: '4px',
						height: '50px',
						width: '100%'
					}}>
						<Input
							type='text'
							value={title}
							onChange={e => setTitle(e)}
							placeholder={translateToString('title')}
							name={''}
							inputStyle={{
								border: 'none',
								width: '100%',
								height: '100%',
								padding: '14px'

							}}
						/>
					</div>
					<ErrorMessage>{getError('title')}</ErrorMessage>
				</div>

				<Titles><Translate id='description' /></Titles>
				<Input
					type='text'
					value={description}
					onChange={e => setDescription(e)}
					placeholder={translateToString('description')}
					name={''}
					textArea
					inputStyle={{ containerWidth: '100%', fontSize: '12px', height: '10rem', margin: '0 0 25px 0', padding:'14px' }}
				/>

				<Titles><Translate id='map.modal_right.max_events_per_day' /></Titles>
				<div style={{ height: 80 }}>
					<Input
						type='text'
						value={maxEvent}
						onChange={e => setMaxEvent(e)}
						placeholder={'Max'}
						name={''}
						inputStyle={{
							containerWidth: '100%',
							width: '100%',
							fontSize: '12px',
							height: '50px',
							border: getError('max_events_per_day') ? `1px solid ${RedSidely}` : undefined,
							padding: '14px'
						}}
					/>
					<ErrorMessage>{getError('max_events_per_day')}</ErrorMessage>
				</div>


				<Restricted to={{ objectAction: 'ReadForm' }}>
					<Dropdown<FormTemplate>
						disabled={false}
						placeholder={translateToString('select_template')}
						dropdownStyle={creationPopupDropdownStyle}
						datalist={formTemplates.map(o => ({ label: o.name, value: o }))}
						name='dropdown_forms'
						readOnly
						cancellable
						label={translate('form').toString()}
						onChange={value => {
							setForm(value?.value);
						}}
						selectedValue={form ? { label: form.name, value: form } : undefined}
					/>
					<Spacer />
				</Restricted>
				<Titles><Mandatory>* </Mandatory><Translate id='map.modal_right.first_event' /></Titles>
				<div style={{ height: '60px' }}>
					{simpleDatePicker(false, firstEventDate, (e) => setFirstEventDate(e))}
				</div>
				<div style={{ height: '75px' }}>
					{simpleTimePicker(false, firstEventHour, (e) => e && setFirstEventHour(e), getError('first_event_hour'))}
					<ErrorMessage>{getError('first_event_hour')}</ErrorMessage>
				</div>
				<Titles><Mandatory>* </Mandatory><Translate id='map.modal_right.duration_each_event' /></Titles>
				<div style={{ height: '75px' }}>
					{simpleDurationPicker(durationPicker.fst.nb, durationPicker.fst.op, (e) => setDurationPicker({ ...durationPicker, fst: { ...durationPicker.fst, ...e } }), 'duration', getError('duration_each_event'))}
					<ErrorMessage>{getError('duration_each_event')}</ErrorMessage>
				</div>
				<Titles><Translate id='map.modal_right.time_between_each_event' /></Titles>
				<div style={{ height: '75px' }}>
					{simpleDurationPicker(durationPicker.snd.nb, durationPicker.snd.op, (e) => setDurationPicker({ ...durationPicker, snd: { ...durationPicker.snd, ...e } }), 'time', getError('time_between_each_event'))}
					<ErrorMessage>{getError('time_between_each_event')}</ErrorMessage>
				</div>

				<Titles><Mandatory>* </Mandatory><Translate id='map.modal_right.hour_start_day' /></Titles>
				<div style={{ height: '75px' }}>
					{simpleTimePicker(false, hourStartDay, (e) => e && setHourStartDay(e), getError('hour_start_day'))}
					<ErrorMessage>{getError('hour_start_day')}</ErrorMessage>
				</div>
				<Titles><Mandatory>* </Mandatory><Translate id='map.modal_right.hour_end_day' /></Titles>
				<div style={{ height: '75px' }}>
					{simpleTimePicker(false, hourEndDay, (e) => e && setHourEndDay(e), getError('hour_end_day'))}
					<ErrorMessage>{getError('hour_end_day')}</ErrorMessage>
				</div>

				<Titles><Translate id='map.modal_right.pause_time' /></Titles>
				<div style={{ height: '75px' }}>
					{simpleTimePicker(false, pauseHour, (e) => setPauseHour(e), null, translate('hour').toString())}
				</div>
				<Titles><Translate id='map.modal_right.duration_pause' /></Titles>
				<div style={{ height: '75px' }}>
					{simpleDurationPicker(durationPicker.trd.nb, durationPicker.trd.op, (e) => setDurationPicker({ ...durationPicker, trd: { ...durationPicker.trd, ...e } }), 'duration', getError('duration_pause'))}
					<ErrorMessage>{getError('duration_pause')}</ErrorMessage>
				</div>
				<Titles><Mandatory>* </Mandatory><Translate id='map.modal_right.days_of_activity' /></Titles>
				<ErrorMessage>{getError('days_of_activity')}</ErrorMessage>
				{checkBoxList(daysSelected, setDaysSelected)}
			</div>
			<FlexDiv justify='space-evenly' height='60px' margin='20px 0'>
				<Button
					buttonStyle={ButtonStyle.White}
					onClick={() => {
						setGlobalError(false);
						props.onBack();
					}}
				>
					<Translate id='back' />
				</Button>
				<Button
					buttonStyle={buttonError ? ButtonStyle.Error : undefined}
					onClick={() => {
						if (asAnError()) {
							setGlobalError(true);
							setButtonError(true);
							setTimeout(() => {
								setButtonError(false);
							}, 750);
						} else {
							setGlobalError(false);
							setButtonError(false);
							const trueStartDay = new Date(2000, 1, 1, hourStartDay.getHours(), hourStartDay.getMinutes(), 0, 0);
							const trueEndDay = new Date(2000, 1, 1, hourEndDay.getHours(), hourEndDay.getMinutes(), 0, 0);
							if (trueStartDay.getTime() > trueEndDay.getTime()) {
								trueEndDay.setDate(2);
							}
							const truePause = (pauseHour != null) ? new Date(2000, 1, 1, pauseHour.getHours(), pauseHour.getMinutes(), 0, 0) : null;
							if ((truePause != null) && trueStartDay.getTime() > truePause.getTime()) {
								truePause.setDate(2);
							}
							const pauseDuration: number = parseFloat(durationPicker.trd.nb) * 1000 * 60 * (durationPicker.trd.op === 'min' ? 1 : 60);
							const eventDuration = parseFloat(durationPicker.fst.nb) * 1000 * 60 * (durationPicker.fst.op === 'min' ? 1 : 60);
							const dayDuration = trueEndDay.getTime() - trueStartDay.getTime();
							const morningDuration = (truePause == null) ? dayDuration : truePause.getTime() - trueStartDay.getTime();
							const afternoonDuration = (truePause == null) ? dayDuration : trueEndDay.getTime() - truePause.getTime() - pauseDuration;
							if (props.companies.length > MAX_COMPANIES && !props.noLimit) {
								toast.error(translate('map.modal_right.can_t_create_more_than_100_events_at_a_time') as string);
							} else if ((pauseHour != null) && !isInWorkDay(pauseHour)) {
								toast.error(translate('map.modal_right.pause_out_of_work_day') as string);
							} else if (eventDuration > dayDuration || eventDuration > morningDuration || eventDuration > afternoonDuration) {
								toast.error(translate('map.modal_right.event_duration_to_big') as string);
							} else {
								createEventsList().then(res => {
									setEvents(res);
									setModalOpen(!modalOpen);
								});
							}
						}
					}}
				>
					<Translate id='next' />
				</Button>
			</FlexDiv>
			<ModalCalendar
				onEventCreate={props.onCreate}
				v2={props.v2}
				isVisit={eventTypes.some(e => e.id === props.type?.id && e.map)}
				isOpen={modalOpen}
				toggleModal={() => setModalOpen(!modalOpen)}
				events={events}
				name={name}
				form_id={form?.id}
				onClose={props.onBack}
				id={inCharge.details?.value}
				companies={props.companies}
				onOptimize={(ids: string[], interconnections: Interconnection[]) => {
					const newCompanies = ids.map(id => props.companies.find(c => c.id.toString() == id));
					createEventsList(newCompanies, interconnections).then(setEvents);
				}}
			/>
		</>
	);
}
