import { URL_API, URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function getPresencePerBrandOrCategoryPerCompany(query, cancelToken) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/reports/shelf-audits/presence-per-brand-or-category-per-company${query}`,
		cancelToken
	});
}

export async function exportPresencePerBrandOrCategoryPerCompany(query) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios.post(`${PATH}/export${query}`, {
		output: 'CSV',
		kind: {
			type: 'Reports',
			kind: 'Presence'
		}
	});
}

export async function getUsers(current) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	if (current.role.slug === 'manager' || current.role.slug === 'owner') {
		return await axios.get(`${URL_API}/api/users/manager/${current.id}`);
	} else {
		return await axios.get(`${URL_API}/api/users/company/${current.companyId}`);
	}
}
