import axios from 'axios';
import { URL_CERBERUS } from '../../../config/keys';

export async function sendEmail(email: string) {
	return await axios.post(`${URL_CERBERUS}/api/v1/reset-password`, { email });
}

export async function changePassword(token: string, password: string) {
	axios.defaults.headers.common.Authorization = `Bearer ${token}`;
	return await axios.put(`${URL_CERBERUS}/api/v1/password`, { password });
}
