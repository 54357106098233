import React from 'react';
import {Translate} from 'react-localize-redux';
import CheckBoxOption from './CheckBoxOption';

function ComponentFields(props) {
	const { selectData, handleSelected, onClickValue, elements } = props;
	const allElements = {
		merchandising: selectData.filter(m => m.category === 'merchandising'),
		price: selectData.filter(m => m.category === 'price'),
		sales: selectData.filter(m => m.category === 'sales'),
		quantities: selectData.filter(c => c.category === 'quantities'),
		vouchers: selectData.filter(v => v.category === 'vouchers'),
		notes: selectData.filter(v => v.category === 'notes')
	};

	const listLabel = [
		'merchandising',
		'price',
		'sales',
		'quantities',
		'vouchers',
		'notes'
	];
	return (
		<div className="row mt-8em mb-5">
			<Translate>
				{({ translate }) => (
					<div className="p-5 ml-4 detail-top my-0" style={{ width: '95%' }}>
						<h5>
							<Translate id="step" />
							{' '}3 -{' '}
							<Translate id="Pick the elements that you wish to monitor for each available product" />
						</h5>
						<p>
							<Translate id="Tick the elements that you want your teams to monitor in the..." />
						</p>
						{listLabel.map(label => (
							<div>
								<h6 className="mt-4 mb-0">{translate(label)}</h6>
								<div className="row">
									{allElements[label]
										// .filter((m, i) => m.name != 'goodiesDistribution' && m.name != 'discountLevel' && m.name != 'outOfStock')
										.map((m, i) => (
											<div className="col-3">
												<CheckBoxOption
													name={m.name}
													index={i}
													value={m.id}
													key={`checkbox-${m.id}`}
													typeHover={m.field_type}
													description={m.name}
													typeValue={m.field_type}
													hover
													valCheked={elements}
													handleSelected={handleSelected}
													onClickValue={onClickValue}
												/>
											</div>
										))}
								</div>
							</div>
						))}
					</div>
				)}
			</Translate>
		</div>
	);
}

export default ComponentFields;
