import { atom, RecoilState, selector } from 'recoil';
import { Atoggle } from '../global/Toggle';
import { AtomCategory, AtomState } from '../utils/model/Model';

export interface ARefreshType {
    timeline?: object,
	calendar?: object
}

const AGlobalRefresh: AtomState<ARefreshType> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_refresh', // UNIQUE ID
		default: {}
	})
};

const ARefresh: RecoilState<ARefreshType> = selector({
	key: 'atom_refresh',
	get: ({ get }) => {
		const category: AtomCategory = get(Atoggle);
		return getAtom(category);
	},
	set: ({ get, set }, newValue) => {
		const category: AtomCategory = get(Atoggle);
		set(getAtom(category), newValue);
	}
});

function getAtom(category: AtomCategory): RecoilState<ARefreshType> {
	switch (category) {
		case AtomCategory.GLOBAL:
		default:
			return AGlobalRefresh.atom;
	}
}

export {
	ARefresh
};
