import { RecoilState } from 'recoil';

enum AtomCategory {
  GLOBAL = 'globals',
  ORDER = 'order',
  GALLERY = 'gallery',
  COMPANY = 'company',
  REPORTS = 'reporting',
  PRODUCTS = 'products',
  ASSORTMENTS = 'assortments',
  CALENDAR = 'calendar'
}

interface AtomState<T> {
  category: AtomCategory
  atom: RecoilState<T>
}

export {
	AtomCategory
};

export type {
	AtomState
};
