/* eslint-disable lines-between-class-members */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import TableListingPage from './components/TableListingPage';
import {
	bulkDelete,
	deleteData,
	exportCsv,
	exportExcel,
	fetchData,
	fetchDataNoPagination,
	importExcel,
	searchData,
	setStatusFilter,
	sortData
} from '../../store/actions/opportunities.action';
import { filterOpportunityStatus, modalErrorValidate, togleFilterCompaniesModal } from '../../store/actions/modal.action';
import { getAlphabet, selectOpportunityStatus } from '../../store/actions/select.action';
import { currentData } from '../../store/actions/user.action';
import DropdownAction from '../../components/DropdownAction';
import { formatCurrency } from '../../containers_v2/reports/utils';
import { setWidth } from '../../store/actions/fieldSettings.action';

class Customer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static columns = props => {
		const {
			history,
			sortData,
			data,
			filterOpportunityStatus,
			togleFilterCompaniesModal
		} = props;

		const onFilterOpportunityStatus = () => {
			filterOpportunityStatus(true);
		};

		const openFilterCompaniesModal = () => {
			togleFilterCompaniesModal(true);
		};

		const onSorting = field => {
			sortData(field);
		};

		const customStyle = {
			style: {
				color: '#00b2ff'
			}
		};

		const columns = [
			{
				dataField: 'id',
				text: <Translate id="title" />,
				formatter: (cell, row) => (
					<div
						className="table-key titleEvent"
						style={{ width: '8rem' }}
						onClick={() => {
							history.push(`/opportunities/detail/${row.id}`);
						}}
					>
						{row.title}
					</div>
				)
			},
			{
				dataField: 'clientCompanyName',
				text: <Translate id="company" />,

				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button className="filter-table" onClick={openFilterCompaniesModal}>
							{data.activeFilter.clientCompanyId !== 'all' ? (
								<i
									className={'fas fa-filter'}
									{...(props.data.activeFilter.clientCompanyId && {
										...customStyle
									})}
								/>
							) : (
								<i className={'fas fa-filter'} />
							)}
						</button>
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
								(data.sort === 'asc' ? (
									<i className="fas fa-caret-down" />
								) : (
									<i className="fas fa-caret-up" />
								))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div className="titleEvent" style={{ width: '7rem' }}>
						{row.clientCompanyName || '-'}
					</div>
				)
			},
			{
				dataField: 'createdAt',
				text: <Translate id="createat" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
								(data.sort === 'asc' ? (
									<i className="fas fa-caret-down" />
								) : (
									<i className="fas fa-caret-up" />
								))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div>
						{row.createdAt
							? moment(row.createdAt || '-').format('DD/MM/YY')
							: '-'}
					</div>
				)
			},
			{
				dataField: 'deadline',
				text: <Translate id="deadline" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
								(data.sort === 'asc' ? (
									<i className="fas fa-caret-down" />
								) : (
									<i className="fas fa-caret-up" />
								))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div>
						{row.deadline ? moment(row.deadline).format('DD/MM/YY') : '-'}
					</div>
				)
			},
			{
				dataField: 'opportunityStatusId',
				text: <Translate id="status" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={onFilterOpportunityStatus}
						>
							<i
								className="fas fa-filter"
								{...(props.data.activeFilter.opportunityStatusId && {
									...customStyle
								})}
							/>
						</button>
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
								(data.sort === 'asc' ? (
									<i className="fas fa-caret-down" />
								) : (
									<i className="fas fa-caret-up" />
								))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div
						className="p-2 text-center bgCard"
						style={{
							background: row.opportunityStatusColorCode || '#fff',
							color: row.opportunityStatusId == null && 'black'
						}}
					>
						{row.opportunityStatusName || '-'}
					</div>
				)
			},
			{
				dataField: 'probability',
				text: <Translate id="probability" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
								(data.sort === 'asc' ? (
									<i className="fas fa-caret-down" />
								) : (
									<i className="fas fa-caret-up" />
								))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div>{`${row.probability ? row.probability : '0'}%`}</div>
				)
			},
			{
				dataField: 'amount',
				text: <Translate id="amount" />,
				headerFormatter: (cell, row) => (
					<div>
						{cell.text}{' '}
						<button
							className="filter-table"
							onClick={() => {
								onSorting(cell.dataField);
							}}
						>
							{data.sortField !== cell.dataField && (
								<i className="fas fa-caret-down" />
							)}
							{data.sortField === cell.dataField &&
								(data.sort === 'asc' ? (
									<i className="fas fa-caret-down" />
								) : (
									<i className="fas fa-caret-up" />
								))}
						</button>
					</div>
				),
				formatter: (cell, row) => (
					<div>{row.amount ? formatCurrency(row.amount) : '-'}</div>
				)
			},
			{
				dataField: 'customerPlan',
				text: <Translate id="quick_action" />,
				formatter: (cell, row) => (
					<div style={{ height: '33px' }}>
						<DropdownAction
							id={row.id}
							name={row.title}
							permission='Opportunity'
							path="opportunities"
							{...props}
						/>
					</div>
				)
			}
		];
		return columns;
	};

	render() {
		return (
			<Fragment>
				<div style={{ width: 200 }} />
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	data: state.opportunities,
	current: state.user.current,
	isLoadingCurrent: state.user.isLoading,
	modal: state.modal,
	status: state.select.opportunityStatus,
	statusWidth: state.fieldSettings.statusWidth
});

export default connect(mapStateToProps, {
	fetchData,
	fetchDataNoPagination,
	deleteData,
	currentData,
	sortData,
	filterOpportunityStatus,
	importExcel,
	exportExcel,
	exportCsv,
	bulkDelete,
	setStatusFilter,
	togleFilterCompaniesModal,
	getAlphabet,
	modalErrorValidate,
	selectOpportunityStatus,
	setWidth,
	searchData
})(TableListingPage({ name: 'CUSTOMER', columns: Customer.columns })(Customer));
