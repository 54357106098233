/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import DateRangePicker from 'react-daterange-picker';
import moment from 'moment';
import {Translate} from 'react-localize-redux';

const stateDefinitions = {
  available: {
    color: null,
    label: 'Available'
  },
  selected: {
    color: 'blue',
    label: 'Selected'
  }
};

class ModalFilterDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null
    };
  }

  handleSelect = (range, states) => {
    // range is a moment-range object
    this.setState({
      value: range,
      states
    });
  };

  render() {
    const { onFilter, type, view } = this.props;
    const pr = JSON.parse(localStorage.getItem('params'));
    const data = view ? this.state.value && moment(this.state.value._d).format('YYYY-MM-DD') : {
      gteDate:
        this.state.value && moment(this.state.value.start).format('YYYY-MM-DD'),
      lteDate:
        this.state.value && moment(this.state.value.end).format('YYYY-MM-DD')
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          style={{ borderBottom: 'none' }}
        >
          {view ? <Translate id="Select Date" /> : <Translate id="Select Date Range" />}
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <DateRangePicker
              firstOfWeek={1}
              numberOfCalendars={1}
              selectionType={view ? "single" : "range"}
              // initialYear={2019}
              initialDate={new Date()}
              // minimumDate={new Date()}
              stateDefinitions={stateDefinitions}
              // dateStates={dateRanges}
              defaultState="available"
              // showLegend
              value={this.state.value}
              onSelect={this.handleSelect}
              maximumDate={view ? new Date() : undefined}
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <Button
            color="primary"
            className="px-5 mb-2"
            onClick={() => {
              onFilter(type, data);
              localStorage.setItem(
                'params',
                JSON.stringify({ ...pr, periode: 'range' })
              );
              this.props.toggle();
            }}
          >
            <Translate id="save" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  data: state.opportunities
});

export default reduxForm({
  form: 'modalFilterDate'
})(connect(mapStateToProps, {})(ModalFilterDate));
