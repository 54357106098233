/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Component} from 'react';
import moment from 'moment';
import {Translate} from 'react-localize-redux';

// eslint-disable-next-line react/prefer-stateless-function
class DetailLinkedForm extends Component {
  render() {
    const { data, match, type } = this.props;
    return (
      <div
        className="col-md-3 mb-3 pointer"
        onClick={() => {
          window.location.href = `/formBuilder/formData/${
            data.user_form_id
          }/list/${match.params.id}/${type}`;
        }}
      >
        <div className="detail-top mt-0 p-4">
          <div className="row">
            <div className="col-md-3 d-flex justify-content-center align-items-center">
              <img src={data.icon_url} alt="" width="30" />
            </div>
            <div className="col-md-9 pl-0">
              <p className="font-weight-bold mb-0 titleLimit">
                {data.user_form_name}
              </p>
              <p className="mb-0">
                {`${data.row_count}`} <Translate id="forms" />
              </p>
              <p className="mb-0">
                {data.updated_at !== ''
                  ? moment(data.updated_at).format('DD/MM/YYYY')
                  : moment(data.created_at).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailLinkedForm;
