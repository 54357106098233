import React, { Component } from 'react';
import arrowLeft from 'images/icon/arrow_left.svg';
import arrowRight from 'images/icon/arrow_right.svg';

class FilterHandlePage extends Component {

	render() {
		const { next, prev, getPage, index, page, path } = this.props;
		return (
			<div className="d-flex">
				<div className="px-2 pointer" 
					onClick={() => {
						if (prev !== null) {
							window.location.href = `${path}/${parseInt(prev)}`;
						}
					}}>
					<img
						src={arrowLeft}
					/> 
				</div>
				<div className="px-2 pointer" 
					onClick={() => {
						if (next !== null && next) {
							window.location.href = `${path}/${parseInt(next)}`;
						}
					}}>
					<img
						src={arrowRight}
					/> 
				</div>
			</div>
		);
	}
}

export default FilterHandlePage;