/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
// import SelectGroupAll from '../../select/SelectGroup';
import SelectGroup from '../../select/SelectGroupAuth';
import * as action from '../../../store/actions/mapOpportunities.action';
import {Translate} from 'react-localize-redux';

class ModalFilterGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onFilter, groupId, type, current } = this.props;
    // console.log(current);
    const data = {
      groupId: groupId && groupId.value,
      incharge: null
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        centered
      >
        <ModalHeader
          toggle={this.props.toggle}
          style={{ borderBottom: 'none' }}
        >
          <Translate id="select_spesific_group" />
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <SelectGroup
              name="groupId"
              label="Group"
              placeholder="Pick Group"
              current={current && current}
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <Button
            color="primary"
            className="px-5 mb-2"
            onClick={() => {
              onFilter(type, data);
              this.props.setFilterGroup(data.groupId);
              this.props.toggle();
            }}
          >
            <Translate id="save" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const selector = formValueSelector('modalFilterGroup');

const mapStateToProps = state => ({
  groupId: selector(state, 'groupId')
});

const mapDispatchToProps = {
  setFilterGroup: action.setFilterMapOpportunityGroup
};

export default reduxForm({
  form: 'modalFilterGroup'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalFilterGroup)
);
