import * as React from 'react';
import styled from 'styled-components';
import CategorySelector, { Category } from '../../components_v2/Selector/CategorySelector';
import { translateToString } from '../../styles/global/translate';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { useHistory } from 'react-router';
import { To } from '../../Types';

const CategoriesContainer = styled.div`
	width: 20rem;
	background-color: white;
	margin: -10px 0 0 -10px;
	height: calc(100% + 10px);
	flex-shrink: 0;
	overflow: auto;
`;

const Wrapper = styled.div`
	height: calc(100vh - 118px);
	display: flex;
`;

const SettingWrapper = styled.div`
	width: 100%;
	background-color: white;
	margin: 0 0 10px 10px;
	overflow: hidden;
`;

export type SystemCategory = {
	children: SubSystemCategory[],
	title: string
}

export type SubSystemCategory = {
	name: string,
	restricted?: To | To[],
	owner?: boolean
	admin?: boolean
	children: {
		owner?: boolean
		admin?: boolean,
		key: string,
		component: React.ReactNode,
		restricted?: To | To[]
	}[]
}

export default function SettingsInterpreter(props: {
	setToolBarState: (value: ToolbarState) => void,
	categories: SystemCategory[],
	defaultCategory: string
}) {
	const [selectedCategory_, setSelectedCategory_] = React.useState<string>(props.defaultCategory);
	const selectedCategory = React.useRef(selectedCategory_);
	const setSelectedCategory = React.useCallback((category: string) => {
		selectedCategory.current = category;
		setSelectedCategory_(category);
	}, []);
	const history = useHistory();

	function checkUrlParams() {
		if (window.location.hash) {
			const category = window.location.hash.slice(1);
			if (category === selectedCategory.current) return;
			setSelectedCategory(category);
		}
	}

	React.useEffect(() => {
		checkUrlParams();
		history.listen(checkUrlParams);
	}, [history]);

	const categories: [Category<{ key: string, component: React.ReactNode }>[], string][] = React.useMemo(() => {
		return props.categories.map(bigc => [bigc.children.map(c => ({ ...c,
			children: c.children.map(c => ({
				admin: c.admin,
				restricted: c.restricted,
				value: c,
				active: selectedCategory.current === c.key,
				name: translateToString(c.key),
				owner: c.owner,
				onClick: () => {
					if (c.key === selectedCategory.current) return;
					history.replace('#' + c.key);
					setSelectedCategory(c.key);
					checkUrlParams();
				}
			}))
		})), bigc.title]);
	}, [selectedCategory_]);

	React.useEffect(() => {
		props.setToolBarState({});
	}, []);


	const component: React.ReactNode | undefined = React.useMemo(() => categories.reduce((acc, c) => [...acc, ...c[0]], []).reduce((acc, c) => {
		if (acc) return acc;
		return c.children.find(child => child.value?.key === selectedCategory.current)?.value?.component;
	}, undefined), [categories]);

	return <Wrapper> 
		<CategoriesContainer>
			{categories.map(([c, title], i) => <CategorySelector key={`categories[${i}]`} categories={c} title={title}/>)}
		</CategoriesContainer>
		<SettingWrapper>
			{component}
		</SettingWrapper>
	</Wrapper>;
}