import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
import { URL_API, URL_API_BUILDER, URL_FOUNDATION } from '../../config/keys';
import { logoutUser } from './auth.action';
import { exportDocuments } from '../../helpers/textConfig';

const cookies = new Cookies();

export const CREATE_ENFORM_REQUEST = 'CREATE_ENFORM_REQUEST';
export const CREATE_ENFORM_SUCCESS = 'CREATE_ENFORM_SUCCESS';
export const CREATE_ENFORM_ERROR = 'CREATE_ENFORM_ERROR';

export const FETCH_ENFORM_REQUEST = 'FETCH_ENFORM_REQUEST';
export const FETCH_ENFORM_SUCCESS = 'FETCH_ENFORM_SUCCESS';
export const FETCH_ENFORM_ERROR = 'FETCH_ENFORM_ERROR';

export const GET_LINKED_ENFORM_REQUEST = 'GET_LINKED_ENFORM_REQUEST';
export const GET_LINKED_ENFORM = 'GET_LINKED_ENFORM';

export const FETCH_SHELFAUDIT_INSTANCE_REQUEST =
    'FETCH_SHELFAUDIT_INSTANCE_REQUEST';
export const FETCH_SHELFAUDIT_INSTANCE_SUCCESS =
    'FETCH_SHELFAUDIT_INSTANCE_SUCCESS';
export const FETCH_SHELFAUDIT_INSTANCE_ERROR =
    'FETCH_SHELFAUDIT_INSTANCE_ERROR';

export const DETAIL_SHELF_AUDIT_REQUEST = 'DETAIL_SHELF_AUDIT_REQUEST';
export const DETAIL_SHELF_AUDIT_SUCCESS = 'DETAIL_SHELF_AUDIT_SUCCESS';
export const DETAIL_SHELF_AUDIT_ERROR = 'DETAIL_SHELF_AUDIT_ERROR';

export const UPDATE_ENFORM_REQUEST = 'UPDATE_ENFORM_REQUEST';
export const UPDATE_ENFORM_SUCCESS = 'UPDATE_ENFORM_SUCCESS';
export const UPDATE_ENFORM_ERROR = 'UPDATE_ENFORM_ERROR';

export const GET_ALL_SHELF_AUDIT = 'GET_ALL_SHELF_AUDIT';
export const SET_MODAL = 'SET_MODAL';

export const DETAIL_TEMPLATE_REQUEST = 'DETAIL_TEMPLATE_REQUEST';
export const DETAIL_TEMPLATE_SUCCESS = 'DETAIL_TEMPLATE_SUCCESS';

export const SET_FIELD_REPORT_INSTALLER = 'SET_FIELD_REPORT_INSTALLER';
export const GET_FIELD_REPORT_INSTALLER = 'GET_FIELD_REPORT_INSTALLER';

export const SHEL_AUDIT_SELECT = 'SHELF_AUDIT_SELECT';

const token = cookies.get('id_token') || null;
axios.defaults.headers.common.Authorization = `${token}`;

const PATH = `${URL_API_BUILDER}/v1/api`;

export const getAllSelfAudit = () => async dispatch => {
	const res = await axios.get(`${PATH}/shelfAuditField/select`);
	dispatch({
		type: GET_ALL_SHELF_AUDIT,
		payload: res.data.data
	});
};

export function createData(data, url, history) {
	return dispatch => {
		dispatch({ type: CREATE_ENFORM_REQUEST, loading: true });
		return axios({
			method: 'POST',
			url: `${PATH}/${url}`,
			data
		}).then(
			response => {
				dispatch({
					type: CREATE_ENFORM_SUCCESS,
					payload: response.data
				});
				// localStorage.setItem(
				//   'enFormOption',
				//   JSON.stringify({ urlImage, valImage })
				// );
				localStorage.removeItem('enFormTemplate');
				history.push('/shelf-audits');
			},
			error => {
				dispatch({ type: CREATE_ENFORM_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function updateData(data, id) {
	return dispatch => {
		dispatch({ type: UPDATE_ENFORM_REQUEST, loading: true });
		return axios({
			method: 'PUT',
			url: `${PATH}/shelfAuditTemplate/id/${id}`,
			data
		}).then(
			response => {
				dispatch({
					type: UPDATE_ENFORM_SUCCESS,
					payload: response.data
				});
				// localStorage.setItem(
				//   'enFormOption',
				//   JSON.stringify({ urlImage, valImage })
				// );
				localStorage.removeItem('enFormTemplate');
				window.location.href = '/shelf-audits';
			},
			error => {
				dispatch({ type: UPDATE_ENFORM_ERROR, error: error.response });
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function fetchData(params) {
	return dispatch => {
		dispatch({
			type: FETCH_ENFORM_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/shelfAuditTemplate`,
			params
		})
			.then(
				response => {
					dispatch({
						type: FETCH_ENFORM_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_ENFORM_ERROR,
						error: error.response
					});
					// if (error.response.status === 401) {
					//   dispatch(logoutUser());
					// }
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_ENFORM_ERROR,
					error
				});

				throw error;
			});
	};
}

export function fetchDataInstance(params) {
	return dispatch => {
		dispatch({
			type: FETCH_SHELFAUDIT_INSTANCE_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/shelfAuditInstance`,
			params
		})
			.then(
				response => {
					dispatch({
						type: FETCH_SHELFAUDIT_INSTANCE_SUCCESS,
						payload: response.data
					});
				},
				error => {
					console.log(JSON.stringify(error.response));
					dispatch({
						type: FETCH_SHELFAUDIT_INSTANCE_ERROR,
						error: error.response
					});
					// if (error.response.status === 401) {
					//   dispatch(logoutUser());
					// }
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_SHELFAUDIT_INSTANCE_ERROR,
					error
				});

				throw error;
			});
	};
}

export function detailTemplate(id) {
	return dispatch => {
		dispatch({ type: DETAIL_TEMPLATE_REQUEST, loading: true });
		return axios({
			method: 'get',
			url: `${PATH}/shelfAuditTemplate/id/${id}`
		}).then(response => {
			console.log(id, response);
			dispatch({
				type: DETAIL_TEMPLATE_SUCCESS,
				payload: response.data
			});
		});
	};
}

export function shelfAuditSelect() {
	return dispatch =>
		axios({
			method: 'get',
			url: `${PATH}/shelfAuditField/select`
		}).then(response => {
			dispatch({
				type: SHEL_AUDIT_SELECT,
				payload: response.data
			});
		});
}

// export function detailInstance(id) {
//   return dispatch => {
//     dispatch({
//       type: DETAIL_SHELF_AUDIT_REQUEST,
//       loading: true
//     });
//     return axios({
//       method: 'get',
//       url: `${PATH}/shelfAuditInstance/idProtected/${id}`
//     })
//       .then(
//         response => {
//           dispatch({
//             type: DETAIL_SHELF_AUDIT_SUCCESS,
//             payload: response.data
//           });
//         },
//         error => {
//           dispatch({
//             type: DETAIL_SHELF_AUDIT_ERROR,
//             error: error.response
//           });
//         }
//       )
//       .catch(error => {
//         dispatch({
//           type: DETAIL_SHELF_AUDIT_ERROR,
//           error
//         });
//         throw error;
//       });
//   };
// }

export function detailInstance(id) {
	return dispatch => {
		dispatch({
			type: DETAIL_SHELF_AUDIT_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_FOUNDATION}/api/shelf-audit/instance/${id}`
		})
			.then(
				response => {
					console.log(response);
					dispatch({
						type: DETAIL_SHELF_AUDIT_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_SHELF_AUDIT_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_SHELF_AUDIT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteData(id, url, params) {
	return dispatch => {
		return axios({
			method: 'DELETE',
			url: `${PATH}/${url}/id/${id}`
		}).then(
			() => {
				if (url === 'shelfAuditTemplate') {
					dispatch(fetchData(params));
				} else {
					dispatch(fetchDataInstance(params));
				}
			},
			error => {
				if (error.response.data.stat_msg == 'form_already_filled') {
					swal.fire(
						'Error!',
						'Sorry you can\'t delete this template because there is data in this form, you need to delete the form data one by one in order to delete this template',
						'error'
					);
				} else {
					swal.fire('Error!', error.response.data.errors, 'error');
				}
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export const setModal = status => async dispatch => {
	dispatch({
		type: SET_MODAL,
		payload: status
	});
};

export function getInstaller() {
	return dispatch => {
		return axios({
			method: 'get',
			url: `${URL_API}/api/fieldReportInstallers`
		}).then(response => {
			dispatch({
				type: GET_FIELD_REPORT_INSTALLER,
				payload: response.data
			});
		});
	};
}

export function exportExcel(id, data) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		try {
			const response = await axios({
				method: 'get',
				responseType: 'blob',
				url: `${PATH}/excel/shelfAuditInstance/${id}`,
				params: data
			});
			if (response.data) {
				exportDocuments(response.data, 'shelfAuditInstance');
			}
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function editNote(data, id) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		try {
			const response = await axios({
				method: 'PUT',
				url: `${PATH}/shelfAuditInstance/note/id/${id}`,
				headers: { 'Content-Type': 'multipart/form-data' },
				data
			});
			dispatch(detailInstance(id));
		} catch (error) {
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

// export function getLinkedEnform(params) {
//     return dispatch => {
//         dispatch({
//             type: GET_LINKED_ENFORM_REQUEST,
//             loading: true
//         });
//         return axios({
//                 method: 'get',
//                 url: `${PATH}/shelfAuditTemplate`,
//                 params
//             })
//             .then(
//                 response => {
//                     dispatch({
//                         type: GET_LINKED_ENFORM,
//                         payload: response.data
//                     });
//                 }
//             )
//     };
// }

export function getLinkedEnform(params) {
	return dispatch => {
		dispatch({
			type: GET_LINKED_ENFORM_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${URL_FOUNDATION}/api/crm/client-companies/${params.client_company_id}/shelf-audit-templates`
		})
			.then(
				response => {
					console.log(response.data);
					dispatch({
						type: GET_LINKED_ENFORM,
						payload: response.data
					});
				}
			);
	};
}
