import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { DarkGreySidely2, SidelyBlack } from '../../../styles/global/css/Utils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleSection = styled.p`
    ${DefaultText}
    width: 100%;
    color: ${SidelyBlack};
    font-size: 14px;
    font-weight: 500;
`;

const SmallTitle = styled(TitleSection)`
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0.5em;
    color: ${DarkGreySidely2};
`;


const SubTitleSection = styled.p`
    ${DefaultText}
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: ${SidelyBlack};
    margin-bottom: 5%;
`;

const Spacer = styled.div<{ height?: number }>`
    width: 100%;
    margin: ${p => p.height ?? '5'}% 0 ${p => p.height ?? '5'}% 0;
`;

const CheckboxContainer = styled.div`
    display: flex;
    cursor: pointer;
`;

const CheckboxText = styled.div`
    ${DefaultText}
    padding-left: 10px;
`;

const Required = styled.span`
    &:before {
        content: '*';
        color: red;
    }
`;

const Label = styled.p`
    ${DefaultText}
    margin-top: 20px;
`;

export {
	Container,
	TitleSection,
	SubTitleSection,
	Spacer,
	CheckboxContainer,
	CheckboxText,
	Label,
	Required,
	SmallTitle
};
