import * as React from 'react';
import { UserBlock } from '../../components_v2/dropdown/DropdownOwners';
import { Column } from '../../components_v2/table/Table';
import { translateToString } from '../../styles/global/translate';
import { ColumnResume } from '../client-companies/Companies';
import { DateAccessor } from '../client-companies/data/CompanyColumns';
import { ColumnHidder } from '../contact/data/ContactColumns';
import { Owner } from '../orders/model/Model';
import { TableRow } from '../orders/templateOrders/style/Style';
import { CHECKOUT_HIDDEN_LOCAL_STORAGE, CHECKOUT_SORT_LOCAL_STORAGE, Checkout } from './Checkouts';

const ALL_CHECKOUT_COLUMNS: ColumnResume[] = [
	{ name: 'date', excluded: true },
	{ name: 'client_company_name', excluded: true },
	{ name: 'unit' },
	{ name: 'product_name' },
	{ name: 'product_unit_number' },
	{ name: 'consumer_sales_unit' },
	{ name: 'volumetric_quantities' },
	{ name: 'sum_with_tax' },
	{ name: 'sum_without_tax' },
	{ name: 'cumulative_consumer_sales_unit' },
	{ name: 'cumulative_volumetric_quantities' },
	{ name: 'cumulative_sum_with_tax' },
	{ name: 'cumulative_sum_without_tax' },
	{ name: 'average_price' },
	{ name: 'average_price_evolution' },
	{ name: 'created_at' },
	{ name: 'created_by', hidded: true },
	{ name: 'updated_at', hidded: true },
	{ name: 'updated_by', hidded: true },
	{ name: 'quick_actions', excluded: true }
];

const COLUMN_STRING = ['product_name', 'unit'];
const COLUMNS_NUMBER = ['product_unit_number', 'consumer_sales_unit', 'volumetric_quantities', 'sum_with_tax', 'sum_without_tax', 'cumulative_consumer_sales_unit', 'cumulative_volumetric_quantities', 'cumulative_sum_with_tax', 'cumulative_sum_without_tax', 'average_price', 'average_price_evolution'];
export default function CheckoutColumns(
	users: Owner[]
): Array<Column<Checkout>> {
	return [
		{
			id: 'date',
			Header: translateToString('date'),
			accessor: row => <DateAccessor date={row.date} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
			freeze: 'left'
		},
		{
			id: 'client_company_name',
			Header: translateToString('company_name'),
			accessor: row => <TableRow>{row.client_company_name}</TableRow>,
			width: 150,
			minWidth: 150,
			type: 'string',
			freeze: 'left'
		},
		...COLUMN_STRING.map((column_key): Column<Checkout> => ({
			id: column_key,
			Header: translateToString(column_key),
			accessor: row => <TableRow>{row[column_key]}</TableRow>,
			width: 150,
			minWidth: 150,
			type: 'string',
		})),
		...COLUMNS_NUMBER.map((column_key): Column<Checkout> => ({
			id: column_key,
			Header: translateToString(column_key),
			accessor: row => <TableRow>{row[column_key]}</TableRow>,
			width: 150,
			minWidth: 150,
			type: 'numeric',
		})),
		{
			id: 'created_at',
			Header: translateToString('created_at'),
			accessor: row => <DateAccessor date={row.created_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'created_by',
			Header: translateToString('created_by'),
			accessor: row => <UserBlock user={users?.find(u => u.id == row.created_by)} />,
			width: 200,
			minWidth: 150,
			type: 'user'
		},
		{
			id: 'updated_at',
			Header: translateToString('updated_at'),
			accessor: row => <DateAccessor date={row.updated_at} />,
			width: 150,
			minWidth: 150,
			type: 'temporal',
		},
		{
			id: 'updated_by',
			Header: translateToString('updated_by'),
			accessor: row => <UserBlock user={users?.find(u => u.id == row.updated_by)} />,
			width: 200,
			minWidth: 150,
			type: 'user'
		},
		{
			id: 'quick_actions',
			noHeaderEllipsis: true,
			Header: <ColumnHidder
				categories={[]}
				columns={ALL_CHECKOUT_COLUMNS}
				sortColumnKey={CHECKOUT_SORT_LOCAL_STORAGE}
				hiddenColumnKey={CHECKOUT_HIDDEN_LOCAL_STORAGE}
			/>,
			width: 40,
			minWidth: 40,
			disableSortBy: true,
			disableFilter: true,
			unresizeable: true,
			freeze: 'right'
		}
	];
}
