import tag from 'images/icons/tag.svg';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { renderselectmultiple } from '../../components/Field';
import { getAllTag } from '../../store/actions/fieldSettings.action';
import { setFilterTag } from '../../store/actions/companies.action';
import { fetchCompanies } from '../../store/actions/mapOpportunities.action';
import { Translate } from 'react-localize-redux';

class FilterTag extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataTag: []
		};
	}

	componentDidMount() {
		const { getAllTag, type, dispatch } = this.props;
		getAllTag(true, type);
	}

	componentWillReceiveProps(nextProps) {
		const { tag, dispatch } = this.props;

		if (tag.dataTag !== nextProps.tag.dataTag) {
			const paramsData = JSON.parse(localStorage.getItem('params'));
			const dataTag = [];
			const valDispatch = [];
			nextProps.tag.dataTag.map(data => {
				dataTag.push({
					value: data.id,
					label: data.name,
					color: data.colorStatus.colorCode || 'black'
				});
				if (paramsData.tagsId !== undefined) {
					if (paramsData.tagsId.includes(data.id)) {
						valDispatch.push({
							value: data.id,
							label: data.name,
							color: data.colorStatus.colorCode || 'black'
						});
					}
				}
			});
			dispatch(change('tag', 'tagId', valDispatch));
			this.setState({ dataTag });
		}
	}

	filterAllData = value => {
		const { handleFilterTag, viewType, fetchCompanies, typeList } = this.props;
		if (viewType === 'map' && typeList === 'companies') {
			this.props.setFilterTag(value);
		} else if (viewType === 'map' && typeList === 'opportunities') {
			fetchCompanies('', '', '', value);
		} else {
			handleFilterTag(value);
		}
	};

	render() {
		const { viewType } = this.props;
		this.state.dataTag.sort((a, b) => {
			if (a.color !== b.color) {
				return a.color > b.color ? 1 : -1;
			} else {
				return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
			}
		});
		return (
			(<div className={`w-70 ${viewType && viewType === 'map' && 'w-100'}`}>
				<Field
					name="tagId"
					id="tagId"
					className="selectFilter"
					component={renderselectmultiple}
					options={this.state.dataTag}
					placeholder={<Translate id="select_tag" />}
					closeMenu={false}
					filterAllData={this.filterAllData}
					icon={tag}
					multiple
					clearable
					viewType={viewType}
				/>
			</div>)
		);
	}
}

const mapStateToProps = state => ({
	tag: state.fieldSettings
});

export default reduxForm({
	form: 'tag'
})(
	connect(mapStateToProps, { getAllTag, fetchCompanies, setFilterTag })(
		FilterTag
	)
);
