import { DEFAULT_LAYOUTS } from './Dashboard';
import { translateToString } from '../../styles/global/translate';


export const DEFAULT_ITEMS: any = {
	parentDn: [{
		item_type: 'ParentDn',
		item_id: undefined,
		id: -42,
		title: 'D.t.N enseigne',
		additional_values: { user: 161 },
	}],
	event: [
		{
			item_type: 'EventCount',
			item_id: undefined,
			id: -42,
			title: 'Compteur d\'évènements',
			additional_values: { granularity: 'day', event_type: 1, event_status: 1, user: 161 }
		},
		{
			item_type: 'EventFrequency',
			item_id: undefined,
			id: -42,
			title: 'Fréquences',
			additional_values: {}
		},
		{
			item_type: 'EventFrequencyByUser',
			item_id: undefined,
			id: -42,
			title: 'Fréquences Par Utilisateur',
			additional_values: {}
		}
	],
	status: [
		{
			item_type: 'Status',
			item_id: undefined,
			id: -42,
			title: 'Statut par point de vente',
			additional_values: {},
		},
		{
			item_type: 'Company',
			item_id: undefined,
			id: -42,
			title: 'Points de vente par enseigne',
			additional_values: {},
		},
		{
			item_type: 'ClientCompanyStatusCount',
			item_id: undefined,
			id: -42,
			title: 'Evolution statuts de points de vente',
			additional_values: { granularity: 'day', event_type: 1, event_status: 1, user: 161 }
		},
		{
			item_type: 'TopCompanyByCheckout',
			item_id: undefined,
			id: -42,
			title: 'Top points de vente par sortie de caisse',
			additional_values: {}
		},
	]
};
