import add_blue from 'images/icon/add_blue.png';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import DetailLinkedForm from '../../../../components/detail/linkedForm/LinkedForm';
import ModalListLinkedForm from '../../../../components/modal/ModalListLinkedForm';
import { Translate } from 'react-localize-redux';

class SalesOperations extends Component {
	state = {
		modal: false,
		count: 0
	};

	componentWillReceiveProps(nextProps) {
		const { detailLinkedForm } = this.props;
		const detail = nextProps.detailLinkedForm.allLinkedData;
		if (detailLinkedForm.allLinkedData !== detail) {
			if (detail !== null) {
				let count = 0;
				detail.map(val => {
					count = count + val.row_count;
				});
				this.setState({ count });
			}
		}
	}

	toggle() {
		this.setState({
			modal: false
		});
	}

	openModal = () => {
		this.setState({
			modal: true
		});
	};

	closeModal = () => {
		this.setState({ modal: false });
	};

	render() {
		const {
			match,
			linkedData,
			detailLinkedForm,
			isFirstPage,
			isLastPage,
			data
		} = this.props;
		const name = data && data.dataDetail;
		return (
			(<div>
				<div className="detail-top m-3 p-3">
					<div className="row mb-2">
						<div className="col-md-9">
							<div className="my-auto">
								<span className="card-title">
									<Translate id="forms" /> ({this.state.count}){' '}
								</span>
							</div>
						</div>
						<div className="col-md-3 d-flex justify-content-end align-items-end">
							<div
								className="my-auto custom-icon-blue rounded-circle"
								onClick={() => this.openModal()}
							>
								<img
									src={add_blue}
									className="custom-icon"
									alt=""
								/>
							</div>
						</div>
					</div>
					{linkedData.length !== 0 ? (
						<Fragment>
							<div className="row">
								{linkedData.map(val => (
									<DetailLinkedForm data={val} match={match} type="contact" />
								))}
							</div>
							{detailLinkedForm.paginationLinkedForm.count > 4 && (
								<div className="row mt-2">
									<button
										className={`${
											isLastPage ? 'text-secondary' : 'text-primary'
										} btn btn-link see-more col-6`}
										onClick={() => {
											!isLastPage && this.props.onFetchLinkedForm();
										}}
									>
										<Translate id="see more" />
									</button>
									<button
										className={`${'text-secondary'} btn btn-link see-less col-6`}
										className={`${
											isFirstPage ? 'text-secondary' : 'text-primary'
										} btn btn-link see-less col-6`}
										onClick={() => {
											!isFirstPage > 1 && this.props.onFetchLinkedForm(1);
										}}
									>
										<Translate id="see less" />
									</button>
								</div>
							)}
						</Fragment>
					) : (
						<p
							style={{
								fontSize: '14px',
								margin: '15px 0 10px',
								color: '#707683'
							}}
						>
							<Translate id="No Formbuilder" />
						</p>
					)}
				</div>
				<ModalListLinkedForm
					isOpen={this.state.modal}
					toggle={() => {
						this.closeModal();
					}}
					type="contact"
					detail={data.dataDetail}
					name={
						data.dataDetail &&
            `${data.dataDetail.firstName} ${data.dataDetail.lastName}`
					}
					{...this.props}
				/>
			</div>)
		);
	}
}

export default SalesOperations;
