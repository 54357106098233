import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { Open } from '../../../styles/global/css/Open';
import { Body, BodyCollapse, Container, Header, HeaderText } from './modalStyle';
import RenderCheckAll from './RenderCheckAll';
import RenderCheckbox from './RenderCheckbox';
import RenderCheckboxHierarchy from './RenderCheckboxHierarchy';

export interface Data {
    id: number
    label: string
    value: any
    checked?: boolean
}

export interface HierarchyData extends Data {
    children?: HierarchyData[]
}

export interface QuickFilterData {
    categories: HierarchyData[]
    brands: Data[]
    catalogues: Data[]
}

export interface QuickFilterResult {
    categories: Data[]
    brands: Data[]
    catalogues: Data[]
}

function Categories(props: {
    title: string,
    list: HierarchyData[],
    onChange: (list: HierarchyData[]) => void
}): JSX.Element {
	const [isOpen, setOpen] = React.useState<boolean>(false);

	return (
		<Container>
			<Header onClick={() => setOpen(!isOpen)}>
				<HeaderText>{props.title}</HeaderText>
				<Open isOpen={isOpen} />
			</Header>
			<BodyCollapse isOpen={isOpen}>
				<Body>
					{
						props.list && props.list.length > 0 &&
                        <RenderCheckAll list={HierarchyDataToData(props.list)} onChange={(values) => props.onChange(values)} />
					}
					<RenderCheckboxHierarchy onReset={props.list.find(l => !l.checked) === undefined} list={props.list} onChange={(values) => props.onChange(values)} />
				</Body>
			</BodyCollapse>
		</Container>
	);
}

function List(props: {
    list: Data[],
    onChange: (list: Data[]) => void,
    title: string
}): JSX.Element {
	const [isOpen, setOpen] = React.useState<boolean>(false);

	return (
		<Container>
			<Header onClick={() => setOpen(!isOpen)}>
				<HeaderText>{props.title}</HeaderText>
				<Open isOpen={isOpen} />
			</Header>
			<BodyCollapse isOpen={isOpen}>
				<Body>
					{
						props.list && props.list.length > 0 &&
                        <RenderCheckAll list={props.list} onChange={(values) => props.onChange(values)} />
					}
					<RenderCheckbox list={props.list} onChange={(values) => props.onChange(values)} />
				</Body>
			</BodyCollapse>
		</Container>
	);
}

export function HierarchyDataToData(data: HierarchyData[]): Data[] {
	let result: Data[] = [];
	data.forEach(d => {
		result.push(d);
		result = d.children && d.children.length > 0 ? result.concat(HierarchyDataToData(d.children)) : result;
	});

	return result;
}

function ProductModalLeft(props: {
    data: QuickFilterData,
    onChange: (result: QuickFilterResult) => void
}): JSX.Element {
	const [data, setData] = React.useState<QuickFilterData>(props.data);

	const translate = getTranslate(storeLang.getState().localize);
	React.useEffect(() => {
		setData(props.data);
	}, [props.data]);

	function updateData(newData: QuickFilterData): void {
		setData(newData);

		props.onChange({
			catalogues: newData.catalogues.filter(a => a.checked),
			brands: newData.brands.filter(b => b.checked),
			categories: HierarchyDataToData(newData.categories).filter(c => c.checked)
		});
	}

	return (
		<div style={{ padding: '0 20px' }}>
			<Categories title={translate('product.modal_left_filter.title.categories').toString()} list={data.categories} onChange={(values) => updateData({ ...data, categories: values })} />
			<List title={translate('product.modal_left_filter.title.brands').toString()} list={data.brands} onChange={(values) => updateData({ ...data, brands: values })} />
			<List title={translate('product.modal_left_filter.title.assortments').toString()} list={data.catalogues} onChange={(values) => updateData({ ...data, catalogues: values })} />
		</div>
	);
}

export default ProductModalLeft;