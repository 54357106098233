/* eslint-disable lines-between-class-members */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import PageLoader from '../../../../components/PageLoader';
import AlphabetSelector from '../../../../components/tableActions/AlphabeticalSelector';
import SelectPaginate from '../../../../components/select/PaginationOption';
import NoData from '../../../../containers_v2/noData/NoData';
import Previous2 from 'images/icon/previous_2.png';
import Previous from 'images/icon/previous.png';
import Next from 'images/icon/next.png';
import Next2 from 'images/icon/next_2.png';

class ListView extends Component {
	constructor(props) {
		super(props);
		this.state = { selected: [] };
	}

	componentWillReceiveProps(nextProps) {
		const { data } = this.props;
		if (data !== nextProps.data) {
			this.setState({
				selected: []
			});
		}
	}

	handleOnSelect = (row, isSelect) => {
		const { handleMultipleSelect, onSelect } = this.props;
		const { selected } = this.state;
		if (isSelect) {
			onSelect([...selected, row.id]);
			this.setState(() => ({
				selected: [...selected, row.id]
			}));

			handleMultipleSelect([...selected, row.id]);
		} else {
			onSelect(selected.filter(x => x !== row.id));
			this.setState(() => ({
				selected: selected.filter(x => x !== row.id)
			}));

			handleMultipleSelect(selected.filter(x => x !== row.id));
		}
	};

	handleSelectAll = (isSelect, rows) => {
		const { handleMultipleSelect, onSelect } = this.props;
		this.setState({ selected: [] });
		if (isSelect) {
			const selected = [];

			rows.forEach(element => {
				selected.push(element.id);
			});

			this.setState({
				selected
			});
			onSelect(selected);

			handleMultipleSelect(selected);
		} else {
			this.setState({
				selected: []
			});
			onSelect([]);

			handleMultipleSelect([]);
		}
	};

	download = () => {
		this.props.downloadExcel();
	};

	render() {
		const {
			data,
			page,
			onTableChange,
			totalSize,
			columns,
			lastPage,
			showPagination,
			loading,
			disablePagination,
			onNext,
			onPrev,
			onFirstPage,
			onLastPage,
			handleLimit,
			limit,
			filterByAlphabet
		} = this.props;

		const loadData = [];

		for (let i = 0; i < 10; i += 1) {
			loadData.push(i);
		}
		return loading ? (
			<div className="list-loader">
				<PageLoader />
			</div>
		) : (
			<React.Fragment>
				{/* <div>tes</div> */}
				<div className="table-responsive">
					{
						data.length !== 0 ?
							<BootstrapTable
								remote
								bootstrap4
								keyField="id"
								data={data}
								columns={columns}
								bordered={false}
								selectRow={{
									mode: 'checkbox',
									// bgColor: '#F4F3FA',
									clickToSelect: false,
									selected: this.state.selected,
									onSelect: this.handleOnSelect,
									onSelectAll: this.handleSelectAll,
									hideSelectColumn: false,
									// !isSelectRow,
									headerColumnStyle: {
										width: '10px'
									},
									selectColumnStyle: {
										width: '10px'
									},
									classes: 'text-blueish'
								}}
								loading={loading}
								onTableChange={onTableChange}
								noDataIndication={}
								overlay={overlayFactory({
									spinner: true,
									background: 'rgba(192,192,192,0.3)'
								})}
							/>
							:
							<NoData
								message={<Translate id="global.nodata.message.opportunity" />}
							/>
					}
          
				</div>
				{!disablePagination && (
					<div className="row sticky-v3 right-panel list-bottom-nav">
						<div className="col-md-10 d-flex">
							<div className="d-flex w-25 align-items-center">
								<SelectPaginate 
									handleLimit={handleLimit}
									limit={limit}
								/>
								<small style={{ color: '#828282' }}>
									<span>{data.length} of</span>
									<span>
										<strong> {totalSize} <Translate id="opportunities" /></strong>
									</span>
								</small>
							</div>
							<AlphabetSelector 
								filterByAlphabet={filterByAlphabet}
							/>
						</div>
						<div className="col-md-2 d-flex justify-content-center">
							{showPagination === undefined &&
                !showPagination &&
                data.length > 0 && (
								<div className="float-right pagination-cs">
									<button
										className={`btn btn-link p-2 page-background ${
											page === 1 ? 'btn-disabled' : ''
										}`}
										disabled={page === 1}
										onClick={onFirstPage}
									>
										<img
											src={Previous2}
											alt=""
											width="7px"
										/>
									</button>
									<button
										className={`btn btn-link p-2 page-background ${
											page === 1 ? 'btn-disabled' : ''
										}`}
										disabled={page === 1}
										onClick={onPrev}
									>
										<img
											src={Previous}
											alt=""
											width="6px"
										/>
									</button>
									<small>
										<span className=" p-2 page-background">{`${page}`}</span>
									</small>
									<button
										className={`btn btn-link p-2 page-background${
											page === lastPage ? 'btn-disabled' : ''
										}`}
										disabled={page === lastPage}
										onClick={onNext}
									>
										<img
											src={Next}
											alt=""
											width="6px"
										/>
									</button>
									<button
										className={`btn btn-link p-2 page-background${
											page === lastPage ? 'btn-disabled' : ''
										}`}
										disabled={page === lastPage}
										onClick={onLastPage}
									>
										<img
											src={Next2}
											alt=""
											width="7px"
										/>
									</button>
								</div>
							)}
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default ListView;
