import React, {Component} from 'react';
import Select, {components} from 'react-select';
import {customStyles} from '../../helpers/styleSelect2';

const CaretDownIcon = () => {
  return <i className="fas fa-caret-down" />;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

class RenderSelectOptionNoLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBlur = e => {
    const blurHandler = this.props.onBlur;
    if (blurHandler) {
      blurHandler({
        type: 'blur',
        target: {
          value: e.target.value
        }
      });
    }
  };

  render() {
    const {
      input,
      options,
      name,
      id,
      disabled,
      placeholder,
      requiredStar,
      icon,
      addClass,
      meta: { touched, error, warning }
    } = this.props;
    return (
      <div className="selectOption">
        <div className="form-group row m-0">
          <div
            className={`col-12 px-0 react-select-container ${
              icon ? `input-group` : ''
            }`}
          >
            <div className="col-12 d-flex flex-row">
              {icon && (
                <div className="input-group-prepend">
                  <span className="input-group-text p-2">
                    <img alt="ProfileImage" src={icon} height="20" />
                  </span>
                </div>
              )}
              <Select
                {...input}
                id={id}
                name={name}
                isSearchable={false}
                isDisabled={disabled}
                className={` ${
                  touched && error
                    ? 'focus-error-select react-select-container'
                    : 'react-select-container'
                }`}
                classNamePrefix="react-select"
                styles={customStyles}
                placeholder={placeholder}
                optionClassName="needsclick"
                options={options}
                // components={{ Option: CustomOption }}
                components={{ DropdownIndicator }}
                value={
                  input.value &&
                  options.find(option => option.value === input.value)
                }
                onChange={value => {
                  input.onChange(value);
                }}
                onBlur={this.onBlur}
              />
            </div>

            <div className="col-12">
              {touched &&
                ((error && error !== 'Required' && (
                  <span className="form-error">{error}</span>
                )) ||
                  (warning && <span className="form-error">{warning}</span>))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RenderSelectOptionNoLabel;
