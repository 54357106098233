import * as React from 'react';
import styled from 'styled-components';
import { BlueSidely } from '../../styles/global/css/Utils';

let drawnPoly;

export enum LassoDot {
  Default = 'default',
  List = 'list',
  Instore = 'instore'
}

export function onLassoClick(map, setLasso) {
	if (!map.lassoEnabled) {
		clearDrawnPoly();
		setLasso(null);
		enableLasso(map);
	} else {
		clearDrawnPoly();
		disableLasso(map);
	}
}

function enableLasso(map) {
	map.setOptions({
		lassoEnabled: true,
		draggable: false,
		zoomControl: false,
		scrollwheel: false,
		disableDoubleClickZoom: false,
		draggableCursor: 'crosshair'
	});
}

function disableLasso(map) {
	map.setOptions({
		lassoEnabled: false,
		draggable: true,
		zoomControl: true,
		scrollwheel: true,
		disableDoubleClickZoom: true,
		draggableCursor: 'default'
	});
}

export function clearDrawnPoly() {
	// Clear previous polygon
	if (drawnPoly != null && drawnPoly != undefined) {
		drawnPoly.setMap(null);
		drawnPoly = null;
	}
}

function drawWithLasso(map, maps, setLasso) {
	clearDrawnPoly();
	// the polygon
	const newPoly = new maps.Polyline({
		strokeColor: '#00B4FF',
		strokeOpacity: 0.5,
		strokeWeight: 2,
		map,
		clickable: false
	});

	// move-listener
	const move = maps.event.addListener(map, 'mousemove', function(e) {
		newPoly.getPath().push(e.latLng);
	});

	// mouseup-listener
	maps.event.addListenerOnce(map, 'mouseup', function() {
		maps.event.removeListener(move);
		const path = newPoly.getPath();
		newPoly.setMap(null);
		drawnPoly = new maps.Polygon({
			map,
			path,
			strokeColor: '#00B4FF',
			strokeOpacity: 0.5,
			strokeWeight: 2,
			fillColor: '#00B4FF',
			fillOpacity: 0.25

		});
		setLasso(drawnPoly);
		// disableLasso(map);
	});
}

export function activateMapListeners(map, maps, setMaps, setLasso) {
	setMaps(maps);
	map.addListener('mousedown', () => {
		if (map.lassoEnabled) {
			drawWithLasso(map, maps, setLasso);
		}
	});
}

export function DotLassoPosition(props: {
  position: number
  type: LassoDot
  top?: string
  left?: string
  color?: string
}) {
	return (
		<span className={'dot'} style={{
			backgroundColor: props.color ?? '#000000',
			width: props.type == LassoDot.List ? '22px' : '29px',
			height: props.type == LassoDot.List ? '22px' : '29px',
			position: props.type == LassoDot.List ? undefined : 'absolute',
			top: props.top ?? (props.type == LassoDot.Default ? '5px' : '17px'),
			left: props.left ?? (props.type == LassoDot.Default ? '-16px' : '-14px'),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}}>
			<div style={{
				color: '#FFFFFF',
				fontFamily: 'Poppins',
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: props.type == LassoDot.List ? '13px' : '15px'
			}}>
				{props.position}
			</div>
		</span>
	);
}

type PinLassoPositionStyle = {
	size?: string,
	top?: string,
	right?: string,
	translateValue?: string
};

const PinLassoPositionDiv = styled.div<PinLassoPositionStyle & { selected?: boolean }>`
	transition: 0.1s;
	background-color: ${p => p.selected ? BlueSidely : 'black'};
	width: calc(${p => p.size ?? '20px'} + ${p => p.selected ? '5px' : '0px'});
	height: calc(${p => p.size ?? '20px'} + ${p => p.selected ? '5px' : '0px'});
	font-weight: 500;
	font-size: ${p => p.selected ? '11px' : '9px'};
	border-radius: 50%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	top: ${p => p.top ?? '-100%'};
	${p => p.right ? `right: ${p.right};` : ''}
	${p => p.translateValue ? `translate: ${p.translateValue};` : ''}
`;

export function PinLassoPosition(props: {
	position?: number
	style?: PinLassoPositionStyle,
	selected?: boolean
}) {
	return <PinLassoPositionDiv {...props.style} selected={props.selected}>{props.position}</PinLassoPositionDiv>;
}