import { atom, selector } from 'recoil';
import { AtomCategory, AtomState } from '../utils/model/Model';
import { AFormTemplate } from '../forms';

// -----------------------[ ATOM ]----------------------- //
const AGlobalSelectedFormId: AtomState<number> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_selected_form_id',
		default: selector<number>({
			key: 'ASelectedFormIdDepth',
			get: ({ get }) => {
				const templates = get(AFormTemplate);
				const id = parseInt(localStorage.getItem('selected_form_id') ?? '-1');
				const template = templates.find(e => e.id === id);
  
				if (!template) return templates[0]?.id ?? -1;
				return id;
			},
		}),
	})
};

export const ASelectedFormId = selector<number>({
	key: 'ASelectedFormId',
	get: ({ get }) => get(AGlobalSelectedFormId.atom),
	set: ({ set }, newValue) => {
		localStorage.setItem('selected_form_id', newValue.toString());
		return set(AGlobalSelectedFormId.atom, newValue);
	}
});