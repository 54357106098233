import React, {Component, Fragment} from 'react';
import {change, Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {Translate} from 'react-localize-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {required, textAreaField} from '../../../../components/FieldModal';
import {editNote} from '../../../../store/actions/enForm.action';
import {CompleteTime} from '../../../../helpers/time';

class ModalComments extends Component {
    state = {};

    componentWillReceiveProps(nextProps) {
        const { detail, dispatch } = this.props;

        if (detail !== nextProps.detail) {
            dispatch(change('modalNoteForm', 'notes', nextProps.detail.notes));
        }
    }

    onSubmit = value => {
        const { editNote, detail } = this.props;
        editNote(value, detail.id);
        this.props.toggle()
    };

    render() {
        const {
            handleSubmit,
            isLoading,
            penUrl,
            onChangeMode,
            onHover,
            detail,
            mode
        } = this.props;

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className={this.props.className}
                centered
            >
                <ModalHeader toggle={this.props.toggle}>
                    <Translate id={mode} />{` `}<Translate id="comment" />
                    <div className="positionButton d-flex flex-row">
                        {mode === 'View' && (
                            getButton({ penUrl, onChangeMode, onHover })
                        )}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <form
                        className="forms-sample background-form"
                        onSubmit={handleSubmit(this.onSubmit)}
                        style={{ boxShadow: 'none' }}
                    >
                        <Fragment>
                            <div className="row">
                                <div className="col-md-11">
                                    <Translate>
                                        {({ translate }) => (
                                            <div className="col-md-12">
                                                <Field
                                                    name="notes"
                                                    type="text"
                                                    component={textAreaField}
                                                    label={<Translate id="comment" />}
                                                    placeholder={translate('comment')}
                                                    readOnly={mode === 'View'}
                                                    validate={[required]}
                                                />
                                            </div>
                                        )}
                                    </Translate>
                                </div>
                                <div className="col-md-4" />
                            </div>
                        </Fragment>
                    </form>
                </ModalBody>
                <ModalFooter className={mode === 'View' ? 'justify-content-start' : ''}>
                    {mode === 'View' ? (
                        <div>
                            <Translate>
                                {({ translate }) => (
                                    <p className="text-by word-break mb-0">
                                        {`${translate('by')} ${detail.owner ? detail.owner.name : ''
                                            }  ${translate('on')}  ${CompleteTime(
                                                detail.created_at
                                            )}`}
                                    </p>
                                )}
                            </Translate>
                        </div>
                    ) : (
                        <Button
                            color="primary"
                            onClick={handleSubmit(this.onSubmit)}
                            loading={isLoading}
                            spinColor="#003d79"
                        >
                            {!isLoading && <Translate id="save" />}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

export const getButton = ({ onChangeMode, onHover, penUrl }) => (
    <button
        className="btn-edit-modal"
        onClick={() => onChangeMode('Edit')}
    >
        <img
            src={penUrl}
            alt=""
            width="13px"
            height="13px"
            className="mr-2"
            onMouseEnter={() => onHover('pen_blue.svg')}
            onMouseLeave={() => onHover('pen_black.svg')}
        />
    </button>
)

const mapStateToProps = state => ({
});

export default reduxForm({
    form: 'modalNoteForm'
})(connect(mapStateToProps, { editNote })(ModalComments));
