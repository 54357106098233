import { HTMLInputTypeAttribute } from 'react';
import { LabelStyle } from '../../dropdown/model/Model';

interface InputStyle {
  textAlign?: boolean
  marginLeft?: string
  marginRight?: string
  padding?: string
  width?: string
  height?: string
  borderRadius?: string
  backgroundColor?: string
  color?: string
  placeholderColor?: string
  hasInputBorder?: boolean
  labelUp?: boolean
  border?: string
  fontSize?: string,
  fontWeight?: string
  containerWidth?: string
  cursor?: string
  labelStyle?: LabelStyle
  removePlaceHolderOnFocus?: boolean
  margin?: string
  flexShrink?: number
  textOverflow?: string
  display?: string
  alignSelf?: string
}
export enum InputMode {
  Error
}

interface InputProps {
  value?: string | number
  onChange?: (value: any) => void
  name: string
  type: HTMLInputTypeAttribute
  id?: string
  placeholder?: string
  required?: boolean
  label?: string | JSX.Element
  normalize?: Array<(s: string | number) => string | number>
  validate?: Array<(s: string | number) => boolean>
  disabled?: boolean
  step?: number
  maxLength?: number
  inputStyle?: InputStyle
  JSXContent?: JSX.Element
  delay?: number
  extraInputAttribute?: JSX.Element
  textArea?: boolean
  onClick?: React.MouseEventHandler
  disableChange?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  paddingLabel?: boolean
  mode?: InputMode
  min?: number
  max?: number
  pattern?: string
  spellcheck?: boolean
}

interface InputCompletionProps extends InputProps {
  onChangeStringAddress?: (value: string) => void
  optionWidth?: string
  optionHeight?: string
  optionLeft?: string
  optionTop?: string
}

interface InputAddressResult {
  street_number: string
  route: string
  country: string
  city: string
  postcode: string
  longitude: number
  latitude: number
}

interface ImageThumbnail {
  original: File,
  thumbnail: File,
  url: string,
}

interface ImageResult {
  file: File
  url: string
  content: string
  contentType: string
}

type FileResult = ImageResult

export {
	InputStyle,
	InputProps,
	InputCompletionProps,
	InputAddressResult,
	ImageThumbnail,
	ImageResult,
	FileResult
};
