import { Translate, translateToString } from '../../../../styles/global/translate';
import { DashboardContext, DEFAULT_LAYOUTS, Page } from '../../Dashboard';
import Input from '../../../../components_v2/input/Input';
import { DropdownSearchCompanies } from '../../../../components_v2/dropdown/DropdownSearch';
import Dropdown from '../../../../components_v2/dropdown/Dropdown';
import { colorTypeMapping } from '../../../calendar/model/Model';
import { DefaultButton } from '../../../../styles/global/css/GlobalButton';
import { ButtonStyle } from '../../../../components_v2/popup/PopupCreation';
import * as React from 'react';
import {
	BottomBar,
	CreationPopupTitle, CreatorProps,
	DASHBOARD_CREATION_DROPDOWN_STYLE,
	DASHBOARD_INPUT_STYLE, DashboardPopupContext,
	PopupContainer
} from '../DashboardPopup';
import { useContext } from 'react';
import DropdownTagsCloudInDashBoardPopup from '../DropDownTagsCloudInDashboardPopup';

export default function EventFrequencyCreator(props: CreatorProps) {
	const { onSave, handleBack } = props;
	const { currentItem } = useContext(DashboardContext)!;
	const {
		page,
		setEventType,
		eventType,
		title,
		setTitle,
		changedItems,
		setChangedItems,
		company,
		setCompany,
		selectedTags
	} = useContext(DashboardPopupContext)!;


	return <>
		<PopupContainer isCreator>
			<CreationPopupTitle>{`${translateToString('parameter')} ${translateToString('frequency')}
					${page === Page.EventFrequencyByUserCreator
		? translateToString('by') + ' ' + translateToString('user')
		: '' }`}
			</CreationPopupTitle>
			<Input name='title' type='text' value={title} onChange={setTitle} inputStyle={DASHBOARD_INPUT_STYLE} label={translateToString('title')}/>
			<DropdownSearchCompanies
				name='parent-company-selector'
				parent dropdownStyle={{ labelUp: true }}
				label={translateToString('Parent company')}
				onChange={setCompany}
				selectedValue={company}
				placeholder={translateToString('all')}
			/>
			<Dropdown
				required
				dropdownStyle={DASHBOARD_CREATION_DROPDOWN_STYLE}
				name=''
				datalist={colorTypeMapping.map(t => ({ value: t, label: translateToString(`event.${t.label}`) }))}
				label={translateToString('type')}
				onChange={setEventType}
				selectedValue={eventType}
				placeholder={translateToString('all')}
				cancellable
			/>
			<DropdownTagsCloudInDashBoardPopup/>
		</PopupContainer>
		<BottomBar>
			<DefaultButton margin='0' buttonStyle={ButtonStyle.White} onClick={handleBack}><Translate
				id='back'/></DefaultButton>
			<DefaultButton
				margin='0'
				disabled={typeof eventType?.value?.id !== 'number'}
				onClick={() => {
					const id = currentItem ? currentItem.id : { unsync: Date.now() };
					const layout = currentItem ? currentItem.layout : {
						...DEFAULT_LAYOUTS[page === Page.EventFrequencyByUserCreator ? 'EventFrequencyByUser' : 'EventFrequency'],
						i: JSON.stringify(id)
					};
					changedItems.push({
						item_id: company?.value.id ?? undefined,
						item_type: page === Page.EventFrequencyByUserCreator ? 'EventFrequencyByUser' : 'EventFrequency',
						additional_values: {
							event_type: eventType?.value?.id,
							tags: selectedTags.length ? selectedTags.map(t => ({ id:t.id, operator:t.value.operator })) : undefined
						},
						id,
						layout,
						title: title?.trim().length ? title : undefined,
						screen_id:props.activeTab,
					});
					setChangedItems([...changedItems]);
					onSave();
				}}><Translate id='save'/></DefaultButton>
		</BottomBar></>;

}