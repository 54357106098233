import { FilterType } from 'bindings/filters/FilterType';
import { IAdditionalColumnsSubValue, ICheckoutEvolution, IEvent, IFrequency, ITag, IUuidAndDate } from '../../../../typings/proto/protobufs';
import { ScopeType } from '../../../atoms/filter/ScopeAtom';
import { FilterId, FilterQueryResult, FilterTree } from '../../../components_v2/filter/model/Model';
import { LinkedElementId } from '../../../containers/companies/components/linkedElements/actions';

interface CompanyParams {
  order_by?: string | object
  descending?: boolean
  limit?: number
  offset?: number
  search?: string
  scope?: ScopeType
  filters?: FilterQueryResult[],
  columns?: FilterId[]
  new_filters?: FilterTree
}

interface ClientCompanyAddressEdit {
  address?: string
  city?: string
  post_code?: string
  country?: string
}

interface ClientCompanyEdit {
  client_company_id: number
  company_name?: string
  owner_id?: number
  delivery_address?: ClientCompanyAddressEdit
  billing_address?: string
  billing_city?: string
  billing_postcode?: string
  billing_country?: string
  phone?: string | null
  website?: string | null
  email?: string | null
  client_status_id?: number
  latitude?: number
  longitude?: number
}

interface NoteCompanyEdit {
  client_company_id: number
  note_id: number
  title?: string
  body?: string
}

interface ClientCompanyCreateImage {
  name: string
  file: string
}

interface ClientCompanyCreate {
  company_name: string
  owner_id: number
  client_status_id: number
  website?: string
  phone?: string
  email?: string
  billing_address?: string
  billing_post_code?: string
  billing_city?: string
  billing_country?: string
  delivery_address?: string
  delivery_post_code?: string
  delivery_city?: string
  delivery_country?: string
  latitude?: number
  longitude?: number
  isBillingDelivery?: boolean
  tags?: number[]
  company_parent_id?: number
  logo?: ClientCompanyCreateImage
}

export enum TimelineElement {
  CONTACT = 'Contact',
  EVENT = 'Event',
  OPPORTUNITY = 'Opportunity',
  ORDER = 'Order',
  SHELF_AUDIT = 'ShelfAudit',
  NOTE = 'Note',
  STATUS_CHANGE = 'StatusChange'
}

interface ClientCompanyTimelineParams extends ClientCompanyParams {
  search?: string
  user_id?: number
  element?: TimelineElement
}

interface ClientCompanyParams {
  client_company_id: number
  limit?: number
  offset?: number
}

interface ClientCompanyDeleteParams {
  id: number
  client_company_id: number
}

interface DbCompany {
  companies: Company[]
  total: number
}

interface Company {
  company_id: number
  name: string
  email?: string
  phone?: string
  company_city?: string
  company_address?: string
  company_post_code?: string
  company_country?: string
  website?: string
  status_id: number
  company_status_name: string
  company_status_color: string
  owner_id: number
  owner_name: string
  owner_photo: string
  tags: ITag[]
  checked?: boolean
  created_at?: Date
  updated_at?: Date
  created_by?: number
  updated_by?: number
  latitude?: number
  longitude?: number
  parent_id?: number
  parent_name?: string
  dn?: number
  frequency?: IFrequency
  latest_shelf_audit_date?: Date
  latest_shelf_audit_id?: number
  latest_event_date?: IEvent
  next_event_date?: IEvent
  externalId?: string
  checkoutEvolution?: ICheckoutEvolution
  shelfAuditTemplates?: number[]
  additionalColumnsValue: { [k: string]: IAdditionalColumnsSubValue }
  additionalFieldColumnsValue: { [k: string]: IAdditionalColumnsSubValue }
  calculatedFieldColumnsValue: { [k: string]: IAdditionalColumnsSubValue }
  lastFormDate: (IUuidAndDate|null);
  error?: string
}

interface CompanyDetail {
  company_id: number
  company_name: string
  company_logo: string
  company_email: string
  company_phone: string
  company_website: string
  status_id: number
  status_name: string
  status_color: string
  parent_id?: number
  parent_name?: string
  owner_id: number
  owner_name: string
  owner_photo: string
  company_billing_address: string
  company_billing_city: string
  company_billing_post_code: string
  company_billing_country: string
  company_delivery_address: string
  company_delivery_city: string
  company_delivery_post_code: string
  company_delivery_country: string
  tag_ids: number[]
  tags: ITag[],
  error?: string
}

interface CompanyTimeline {
  id?: number
  uuid?: string,
  action_type: string
  user_id?: number
  user_name?: string
  user_photo?: string
  title?: string
  sub_title?: string
  created_at: string
  updated_at?: string
}

interface CompanyLinkedElementAmount {
  amount_contacts: number
  amount_events: number
  amount_orders: number
  amount_opportunities: number
  amount_notes: number
  amount_photos: number
  amount_documents: number
  amount_shelf_audit: number
  amount_free_forms: number
  amount_promotions: number
}

interface CompanyContact {
  contact_count: number
  contact_id: number
  company_id: number
  client_company_id: number
  contact_last_name: string
  contact_first_name: string
  contact_photo: string
  contact_phone: string
  contact_email: string
  contact_position: string
  contact_status_id: number
  contact_status_name: string
  contact_status_color: string
}

interface CompanyContactDb {
  contacts: CompanyContact[]
  total: number
}

interface CompanyEvent {
  event_count: number
  event_id: number
  company_id: number
  client_company_id: number
  event_title: string
  event_description: string
  event_start_date: string
  event_end_date: string
  event_status_id: number
  event_status_name: string
  event_status_color: string
  event_type_id: number
  event_type_name: string
  incharge_id: number
  incharge_name: string
  incharge_email: string
  incharge_photo: string
  contact_id?: number
  form?: number
}

interface CompanyEventDb {
  events: CompanyEvent[]
  total: number
}

interface CompanyOpportunity {
  opportunity_count: number
  opportunity_id: number
  company_id: number
  client_company_id: number
  opportunity_title: string
  opportunity_created_at: string
  opportunity_deadline: string
  opportunity_revenue: number
  opportunity_probability: number
  opportunity_status_id: number
  opportunity_status_name: string
  opportunity_status_color: string
  incharge_id: number
  incharge_name: string
  incharge_email: string
  incharge_photo: string
}

interface CompanyOpportunityDb {
  opportunities: CompanyOpportunity[]
  total: number
}

type ServerAdvancedFilter = [FilterId, FilterType]

interface CompanyOrder {
  order_count: number
  order_id: number
  company_id: number
  client_company_id: number
  order_reference: string
  order_sum_with_tax: number
  order_date: string
  owner_id: number
  owner_name: string
  owner_email: string
  owner_photo: string
}

interface CompanyOrderDb {
  orders: CompanyOrder[]
  total: number
}

interface CompanyPhoto {
  photo_count: number
  photo_id: number
  client_company_id: number
  photo_name: string
  photo_url: string
  photo_created_at: string
}

interface CompanyPhotoDb {
  photos: CompanyPhoto[]
  total: number
}

interface CompanyDocument {
  document_count: number
  document_id: number
  id: number
  document_name: string
  document_url: string
  user_name: string
  user_photo: string
  document_created_at: string
}

interface CompanyDocumentDb {
  documents: CompanyDocument[]
  total: number
}

interface CompanyShelfAudit {
  shelf_audit_count: number
  template_id: number
  name: string
  created_at: string
  updated_at: string
  catalogue_id: number
  catalogue_name: string
  url: string
  amount: number
}

interface CompanyShelfAuditDb {
  shelf_audits: CompanyShelfAudit[]
  total: number
}

export interface CompanyFreeForm {
  count: number
  id: string
  name: string
  url: string
  free_forms_count: number
}

interface CompanyNote {
  note_count: number
  note_id: number
  client_company_id?: number
  note_title: string
  note_body: string
  note_created_at: string
  user_id: number
  user_name: string
  user_email: string
  user_photo: string
}

interface CompanyNoteDb {
  notes: CompanyNote[]
  total: number
}

interface EventType {
  id: number
  name: string
  status: boolean
  created_at: string
  updated_at: string
  deleted_at: string
}

interface EventStatus {
  id: number
  sequence: number
  name: string
  slug: string
  color_code: string
  status: boolean
  created_at: string
  updated_at: string
  deleted_at: string
}

interface ProspectOrigin {
  id: number
  sequence: number
  name: string
  status: boolean
  created_at: string
  updated_at: string
}

interface PostPhotoImageParams {
  name: string
  file: string

}

interface PostPhotoParams {
  client_company_id: number
  images: PostPhotoImageParams[]
}

interface PostDocumentParams {
  id: LinkedElementId
  documents: PostPhotoImageParams[]
}

interface CompanyStatus {
  id: number
  company_id: number
  user_id: number | null
  sequence: number
  name: string
  slug: string
  color_code: string
  status: boolean
  created_at: string
  updated_at: string
  deleted_at: string
}

interface FreeForm {
  id: string
  name: string
  url?: string
}

export type {
	ClientCompanyAddressEdit,
	ClientCompanyCreate, ClientCompanyDeleteParams, ClientCompanyEdit, ClientCompanyParams, ClientCompanyTimelineParams, Company, CompanyContact,
	CompanyContactDb, CompanyDetail, CompanyDocument, CompanyDocumentDb, CompanyEvent,
	CompanyEventDb, CompanyLinkedElementAmount, CompanyNote, CompanyNoteDb, CompanyOpportunity,
	CompanyOpportunityDb,
	CompanyOrder,
	CompanyOrderDb, CompanyParams, CompanyPhoto,
	CompanyPhotoDb, CompanyShelfAudit, CompanyShelfAuditDb, CompanyStatus, CompanyTimeline, DbCompany, EventStatus, EventType, FreeForm,
	NoteCompanyEdit, PostDocumentParams, PostPhotoImageParams,
	PostPhotoParams, ProspectOrigin, ServerAdvancedFilter
};
