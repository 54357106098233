import optionGrey from 'images/icon/options_grey.png';
import eye from 'images/icons/company/eye.svg';
import * as React from 'react';
import { DragDropContext, Draggable, DraggableLocation, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { ButtonAction } from '../../containers_v2/client-companies/style/PopupStyle';
import { ModalState } from '../../containers_v2/products/model';
import { BorderColor, DarkGreySidely, LightBlueSidely, LightGreySidely } from '../../styles/global/css/Utils';
import { Translate, translateToString } from '../../styles/global/translate';
import { useFunctionState } from '../../utils/customHooks';
import Dropdown from '../dropdown/Dropdown';
import Popup from '../popup/Popup';
import PopupCreation from '../popup/PopupCreation';
import { PopupMode } from '../popup/model/Model';

const ITEM_SPACING = 8;
const NO_CATEGORY_ID = 'LONG_ID_SO_CLIENT_DO_NOT_USE_IT_AS_CATEGORY_ID';
export type KanbanCategory<IdType> = {
	name: string,
	id: IdType
}
interface InnerKanbanCategory<IdType, T> extends KanbanCategory<IdType> {
	items: Array<KanbanItem<IdType, T>>,
	emptyCategory?: boolean
}

export type KanbanItem<IdType, T> = {
	id: string,
	categoryId: IdType,
	value: T
};

const ItemWrapper = styled.div`
	border: 1px solid ${BorderColor};
	border-radius: 4px;
	padding: 10px;
	background-color: white;
	overflow: hidden;
`;

const CategoryHeader = styled.div<{ empty?: boolean, noSticky?: boolean }>`
	height: 30px;
	padding: 0 ${ITEM_SPACING + 2}px 0 ${ITEM_SPACING + 2}px;
	display: flex;
	align-items: center;
	gap: 5px;
	margin: 0;
	${p => p.noSticky ? '' : 'position: sticky; top: 0;'}
	background-color: white;
	${p => p.empty ? `color: ${DarkGreySidely}; font-weight: 500; font-style: italic;` : ''}
`;

const CategoryTitle = styled.h4<{ empty?: boolean }>`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin: 0;
	${p => p.empty ? `color: ${DarkGreySidely}; font-weight: 500; font-style: italic;` : ''}
`;

const CategoryItemCount = styled.p`
	margin: 0;
`;

const KanbanGrid = styled.div<{ categoriesLen: number, height?: string }>`
	display: grid;
	grid-template-columns: repeat(${p => p.categoriesLen}, max(250px, ${p => 100 / p.categoriesLen}%));
	overflow: auto;
	${p => p.height ? `height: ${p.height};` : ''}
	background-color: white;
`;

const CategoryContainer = styled.div``;

const getItemStyle = (isDragging, draggableStyle, clickable) => ({
	userSelect: 'none',
	margin: `0 0 ${ITEM_SPACING}px 0`,
	background: isDragging ? LightGreySidely : null,
	...draggableStyle,
	cursor: clickable ? 'pointer' : undefined
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? LightBlueSidely : undefined,
	padding: ITEM_SPACING,
	height: 'calc(100% - 30px)'
});

function reorder<IdType, T>(category: InnerKanbanCategory<IdType, T>, startIndex: number, endIndex: number): InnerKanbanCategory<IdType, T> {
	const items = Array.from(category.items);
	const [removed] = items.splice(startIndex, 1);
	items.splice(endIndex, 0, removed);

	return {
		...category,
		items
	};
}

/**
 * Moves an item from one list to another list.
 */
function move<IdType, T>(source: InnerKanbanCategory<IdType, T>, destination: InnerKanbanCategory<IdType, T>, droppableSource: DraggableLocation, droppableDestination: DraggableLocation, sourceIndex: number, destinationIndex: number): { [key: number]: InnerKanbanCategory<IdType, T> } {
	const sourceClone = Array.from(source.items);
	const destClone = Array.from(destination.items);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, { ...removed, categoryId: destination.id });


	const result: { [key: number]: InnerKanbanCategory<IdType, T> } = {};
	result[sourceIndex] = { ...source, items: sourceClone };
	result[destinationIndex] = { ...destination, items: destClone };

	return result;
}

const PAGINATION_NB = 30;

export default function KanbanView<IdType, T = unknown>(props: {
	items: KanbanItem<IdType, T>[],
	categories: KanbanCategory<IdType>[],
	onChange?: (items: KanbanItem<IdType, T>[]) => void,
	onItemCategoryChange?: (item: KanbanItem<IdType, T>, newCategoryId: IdType) => Promise<KanbanItem<IdType, T> | undefined>,
	itemDisplayer: (item: KanbanItem<IdType, T>) => React.ReactNode,
	height?: string
	emptyCategory?: boolean,
	defaultHiddenCategories?: Set<IdType | null>,
	onHiddenCategoriesChange?: (hiddenCategories: Set<IdType | null>) => void,
	onItemClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: KanbanItem<IdType, T>) => void,
 }): JSX.Element {
	const [categories, setCategories, setCategoriesWithoutFunc] = useFunctionState<Array<InnerKanbanCategory<IdType, T>>>([], ({ newValue }) => {
		props.onChange?.(newValue.flatMap(cat => cat.items));
		return newValue;
	});
	const [pagination, setPagination] = React.useState(1);
	const [hiddenCategories, setHiddenCategories, setHiddenCategoriesWithoutFunc] = useFunctionState<Set<IdType | null>>(props.defaultHiddenCategories ?? new Set, ({ newValue }) => {
		props.onHiddenCategoriesChange?.(newValue);
		return newValue;
	});
	const [categoryPopup, setCategoryPopup] = React.useState<ModalState<number>>({ isOpen: false });

	React.useEffect(() => {
		const catMap: Map<IdType, KanbanItem<IdType, T>[]> = props.categories.reduce((acc, cat) => { acc.set(cat.id, []); return acc; }, new Map);
		const emptyCategory: InnerKanbanCategory<IdType, T> = { items: [], name: translateToString('without_category'), id: null as IdType, emptyCategory: true };
		props.items.forEach(item => {
			const cat = catMap.get(item.categoryId);
			if (cat) cat.push(item);
			else emptyCategory.items.push(item);
		});
		const categories = props.categories.map(cat => ({
			...cat,
			items: catMap.get(cat.id) ?? []
		}));
		if (props.emptyCategory) categories.unshift(emptyCategory);
		setCategoriesWithoutFunc(categories);
		setHiddenCategoriesWithoutFunc(props.defaultHiddenCategories ?? new Set);
		setCategoryPopup({ isOpen: false });
	}, [props.items, props.categories]);

	async function onDragEnd(result: DropResult, _provided: ResponderProvided) {
		const { source, destination } = result;

		if (!destination) return;
		const sInd = categories.findIndex(cat => (cat.emptyCategory ? NO_CATEGORY_ID : cat.id) === source.droppableId);
		const dInd = categories.findIndex(cat => (cat.emptyCategory ? NO_CATEGORY_ID : cat.id) === destination.droppableId);

		if (sInd === dInd) {
			const items = reorder(categories[sInd], source.index, destination.index);
			setCategories(categories => {
				const newState = [...categories];
				newState[sInd] = items;
				return newState;
			});
		} else {
			const objectBefore = { ...categories[sInd].items[source.index] };
			const result = move(categories[sInd], categories[dInd], source, destination, sInd, dInd);
			const newState = [...categories];
			newState[sInd] = result[sInd];
			newState[dInd] = result[dInd];
			setCategories(newState);

			if (props.onItemCategoryChange) {
				const res = await props.onItemCategoryChange(objectBefore, categories[dInd].id);
				if (res) {
					setCategories(categories => {
						categories.reduce((acc, cat) => {
							if (acc) return true;
							const index = cat.items.findIndex(item => item.id === res.id);
							if (index !== -1) cat.items[index] = res;
							return index !== -1;
						}, false);
						return [...categories];
					});
				}
			}
		}
	}

	const onScroll = React.useCallback((e: React.UIEvent<HTMLDivElement>) => {
		const target = e.target as HTMLDivElement;
		if (target.scrollTop > target.scrollHeight * 0.7) {
			setCategories(categories => {
				const max = categories.reduce((acc, cat) => Math.max(acc, cat.items.length), 0);
				setPagination(pagination => {
					if (pagination * PAGINATION_NB >= max) return pagination;
					return pagination + 1;
				});
				return categories;
			});
		}
	}, []);

	const filteredCategories = categories.filter(c => !hiddenCategories.has(c.id));
	return <KanbanGrid categoriesLen={filteredCategories.length != categories.length ? filteredCategories.length + 1 : categories.length} height={props.height} onScroll={onScroll}>
		<DragDropContext onDragEnd={onDragEnd}>
			{filteredCategories.map((category, categoryIndex) => (
				<Droppable key={categoryIndex} droppableId={category.emptyCategory ? NO_CATEGORY_ID : category.id}>
					{(provided, snapshot) => (
						<CategoryContainer>
							<CategoryHeader empty={category.emptyCategory}>
								<CategoryTitle empty={category.emptyCategory}>
									{category.name}
								</CategoryTitle>
								<CategoryItemCount>
											({category.items.length})
								</CategoryItemCount>
								<Dropdown
									datalist={['hide_category'].map(e => ({ value: e, label: translateToString(e) }))}
									name='dropdown_actions'
									readOnly
									JSXButton={() => (
										<img
											src={optionGrey}
											className="custom-icon"
											style={{ marginTop: '3px' }}
											alt=""
										/>
									)}
									dropdownStyle={{
										optionWidth: '200px',
										optionLeft: '-175px',
										margin: '0 0 0 auto'
									}}
									onChange={() => setHiddenCategories(hiddenCategories => new Set(hiddenCategories).add(category.id))}
								/>
							</CategoryHeader>
							<div
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
								{...provided.droppableProps}
							>
								{category.items?.slice(0, pagination * PAGINATION_NB).map((item, index) => (
									<Draggable
										key={item.id}
										draggableId={item.id}
										index={index}
									>
										{(provided, snapshot) => (
											<ItemWrapper
												innerRef={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
													props.onItemClick !== undefined
												)}
												onClick={props.onItemClick ? (e) => props.onItemClick?.(e, item) : undefined}
											>
												{props.itemDisplayer(item)}
											</ItemWrapper>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						</CategoryContainer>
					)}
				</Droppable>
			))}
			{categories.length != filteredCategories.length && <CategoryContainer>
				<CategoryHeader empty>
					<CategoryTitle empty>
						<Translate id='hidden_categories' />
					</CategoryTitle>
					<CategoryItemCount>
						({categories.length - filteredCategories.length})
					</CategoryItemCount>
				</CategoryHeader>
				{categories.filter(c => hiddenCategories.has(c.id)).map(category => <CategoryHeader key={`HiddenCategory${category.id}`} noSticky>
					<CategoryTitle>
						{category.name}
					</CategoryTitle>
					<CategoryItemCount>
						({category.items.length})
					</CategoryItemCount>
					<ButtonAction parent={CategoryHeader} src={eye} onClick={() => {
						setCategoryPopup({ isOpen: true, data: categories.findIndex(c => c.id === category.id) });

					}}/>
				</CategoryHeader>)}
			</CategoryContainer>}
			<Popup isOpen={categoryPopup.isOpen} disableOutClick popupMode={PopupMode.Details} onClickOut={() => setCategoryPopup({ isOpen: false })}>
				{categoryPopup.data !== undefined && <PopupCreation
					title={categories[categoryPopup.data].name}
					onClose={() => setCategoryPopup({ isOpen: false })}
					hideValidation
					dropdown={{
						data: [{ value: 'hide column', label: translateToString('show_category') }],
						onChange: () => {
							setCategoryPopup({ isOpen: false });
							setHiddenCategories(hc => {
								const clone = new Set(hc);
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								clone.delete(categories[categoryPopup.data!].id);
								return clone;
							});
						}
					}}
				>
					<Droppable key={categoryPopup.data} droppableId={categories[categoryPopup.data].emptyCategory ? NO_CATEGORY_ID : categories[categoryPopup.data].id}>
						{(provided, snapshot) => (
							<CategoryContainer>
								<div
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
									{...provided.droppableProps}
								>
									{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
									{categories[categoryPopup.data!].items?.slice(0, pagination * PAGINATION_NB).map((item, index) => (
										<Draggable
											key={item.id}
											draggableId={item.id}
											index={index}
										>
											{(provided, snapshot) => (
												<ItemWrapper
													innerRef={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style,
														props.onItemClick !== undefined
													)}
													onClick={props.onItemClick ? (e) => props.onItemClick?.(e, item) : undefined}
												>
													{props.itemDisplayer(item)}
												</ItemWrapper>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							</CategoryContainer>
						)}
					</Droppable></PopupCreation>}</Popup>
		</DragDropContext>
	</KanbanGrid>;
}
