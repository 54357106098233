import styled from 'styled-components';
import axios from 'axios';
import { URL_JANUS } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import * as React from 'react';
import { LoadingState } from '../../import/model';
import PageLoader from '../../../components_v2/pageLoader/PageLoader';
import { DarkGreySidely } from '../../../styles/global/css/Utils';

const Background = styled.div`
    background-color: ${DarkGreySidely};
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 17px;
`;

const ColumnContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

async function syncAccount(code: string, thirdParty: string, pkceVerifier: string | null) {
	return await axios.post(`${URL_JANUS}/api/v1/oauth/callback?code=${code}&third_party=${thirdParty}${pkceVerifier ? `&pkce_verifier=${pkceVerifier}` : ''}`);
}

const NoToken = (): JSX.Element => (
	<ColumnContainer>
		<div>
            You are not logged yet, please log in and retry
		</div>
		<a href="/login">log in</a>
	</ColumnContainer>
);

const Loaded = (props: {thirdParty: string}): JSX.Element => {
	return (
		<ColumnContainer>
			<div>
				{props.thirdParty} is setted, you can close this page
			</div>
		</ColumnContainer>
	);
};

// eslint-disable-next-line react/display-name
const JanusOAuth = (thirdParty: string) => () => {
	const cookies = new Cookies();
	const windowParams = new URLSearchParams(window.location.search);
	const code = windowParams.get('code');
	const token = cookies.get('id_token');
	const pkceVerifier = cookies.get('pkce_verifier');
	const [loadingState, setLoadingState] = React.useState<LoadingState | null>(null);

	React.useEffect(() => {
		if (code && token && !loadingState) {
			setLoadingState('loading');
			syncAccount(code, thirdParty, pkceVerifier)
				.then(() => setLoadingState('loaded'))
				.catch(err => {
					console.log(err);
					setLoadingState('error');
				});
		}
	}, [code, token]);

	if (!code) {
		return <Background>
            No {thirdParty} code provided
		</Background>;
	}

	return <Background>
		{
			token
				? loadingState == 'loading' || !loadingState
					? <PageLoader size={50} />
					: loadingState == 'error'
						? <>ERROR</>
						: <Loaded thirdParty={thirdParty}/>
				: <NoToken />
		}
	</Background>;
};

export default JanusOAuth;