import ProductListing from '../../containers_v2/products/productListing';
import AssortmentListing from '../../containers_v2/products/assortmentListing';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Route } from '../Routes';
import ProductRouteBlack from 'images/menu_icon/product_menu_black.svg';
import ProductRouteBlue from 'images/menu_icon/product_menu_blue.svg';
import ProductRouteWhite from 'images/menu_icon/product_menu_white.svg';
import AssortmentListingV3 from '../../containers_v2/products/assortimentV3';

const ProductsRoutesV2 = (): Route[] => [
	{
		component: ProductListing,
		path: '/products-v2',
		name: <Translate id="catalog" />,
		key: 'catalog',
		sectionKey: 'catalog',
		logoImg: ProductRouteBlack,
		hoverImg: ProductRouteBlue,
		activeImg: ProductRouteWhite,
		title: 'Products',
		subTitle: 'Products',
		restricted: false,
		exact: true,
		menu: false,
		subMenu: 'products',
		permission: ['ViewCatalog'],
		subroutes: [
			{ path: '/products-v2', name: <Translate id="products" />, subMenu: 'products-v2', permission: ['ViewCatalog'] },
			{ path: '/assortments-v2', name: <Translate id="assortments" />, subMenu: 'assortments-v2', permission: ['ViewCatalog'] },
			{ path: '/assortments-v3', name: <Translate id="assortments.beta" />, permission: ['ViewCatalog'], admin: true, beta: true },
		],
		beta: true
	},
	{
		component: AssortmentListing,
		path: '/assortments-v2',
		title: 'assortments',
		subTitle: 'assortments',
		restricted: false,
		exact: true,
		subMenu: 'assortments',
		key: 'assortments',
		permission: ['ViewCatalog'],
		beta: true
	},
	{
		component: AssortmentListingV3,
		path: '/assortments-v3',
		title: 'assortments',
		subTitle: 'assortments',
		restricted: false,
		exact: true,
		subMenu: 'assortments',
		key: 'assortments',
		permission: ['ViewCatalog'],
		beta: true
	},
	
];

export default ProductsRoutesV2;
