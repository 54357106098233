import { URL_API, URL_FOUNDATION } from '../../../config/keys';
import { Cookies } from 'react-cookie';
import axios from 'axios';

const cookies = new Cookies();
const PATH = `${URL_FOUNDATION}/api`;
const token = cookies.get('id_token') || null;

export async function getEventsPerUser(query, cancelToken) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${PATH}/reports/events/events-per-user${query}`,
		cancelToken
	});
}

export async function getEventTypes() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_API}/api/eventTypes`
	});
}

export async function getEventStatuses() {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'get',
		url: `${URL_API}/api/eventStatuses`
	});
}
