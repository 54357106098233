import styled from 'styled-components';

interface ContainerProps {
  withBorder?: boolean
  height?: string
  backgroundColor?: string
  margin?: string
}

const Container = styled.table<ContainerProps>`
    height: ${p => p.height ? p.height : '50px'};
    width: 100%;
    border-radius: 10px;
    background-color: ${p => p.backgroundColor ? p.backgroundColor : '#FFFFFF'};
    border: ${p => p.withBorder ? '0.717519px solid #D5D5D5' : ''};
    box-sizing: ${p => p.withBorder ? 'border-box' : ''};
    border-radius: ${p => p.withBorder ? '7px' : ''};
    margin: ${p => p.margin ?? ''};
`;

interface CardBlockProps {
  padding?: string;
}
const CardBlock = styled.tr<CardBlockProps>`
    height: inherit;
    display: -webkit-box;
    border-radius: 20px;
    padding: ${p => p.padding ?? '10px'}
`;

interface CardTdProps {
  width?: string
  backgroundColor?: string
}

const CardTd = styled.td<CardTdProps>`
    width: ${p => p.width ?? '130px'};
    height: fit-content;
    background-color: ${p => p.backgroundColor ?? 'initial'};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export {
	Container,
	CardBlock,
	CardTd
};
