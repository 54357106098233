import chartImage from 'images/icons/chart.svg';
import { Granularity } from 'bindings/time/Granularity';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { AGranularitySelect } from '../../../atoms/filter/timeFilterAtom';
import Dropdown from '../../dropdown/Dropdown';
import { DropdownData } from '../../dropdown/model/Model';
import { DROPDOWN_FILTER_STYLE } from '../brands/BrandFilter';
import { GenericToolbarFilterProps } from '../ToolbarFilter';
import { translateToString } from '../../../styles/global/translate';
import { ReportFiltersContext } from '../../../containers_v2/reports/generic/generic';

export const ALL_GRANULARITY: Granularity[] = ['day', 'week', 'month', 'quarter', 'year'];

export default function GranularitySelect(props: GenericToolbarFilterProps): JSX.Element {
	const { filters, setFilters } = React.useContext(ReportFiltersContext);
	const [granularity, setGranularity] = useRecoilState(AGranularitySelect);
	if (props.hidden) return <></>;

	let localGranularity;
	if (props.context) {
		localGranularity = filters.evolution;
		if (localGranularity === undefined) {
			localGranularity = 'month';
		}
	} else {
		localGranularity = granularity;
	}

	return (
		<Dropdown
			dropdownStyle={{ ...DROPDOWN_FILTER_STYLE, width: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.width, containerWidth: props.fullScreen ? '100%' : DROPDOWN_FILTER_STYLE.containerWidth }}
			readOnly
			selectedValue={{ label: translateToString(localGranularity), value: localGranularity }}
			datalist={ALL_GRANULARITY.map(g => ({ label: translateToString(g), value: g }))}
			name='dropdown_granularity_filter'
			onChange={(value: DropdownData<Granularity>) => {
				if (props.context) {
					setFilters(filters => ({ ...filters, evolution: value.value }));
				} else {
					setGranularity(value.value);
				}
			}}
			image={chartImage}
		/>
	);
}