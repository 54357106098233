import * as React from 'react';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { Data } from './ProductModalLeft';

const Label = styled.p`
	${DefaultText}
	margin-left: 1rem;
	color: #4f4f4f;
	font-weight: 400;
`;

export default function RenderCheckAll(props: {
	list: Data[],
	onChange: (list: Data[]) => void
}): JSX.Element {

	function getIsCheckedAll(): boolean {
		const result = props.list.find(l => !l.checked);
		return result === undefined;
	}

	function toggleCheck(): void {
		const currentValue = getIsCheckedAll();
		props.onChange(props.list.map(l => {
			l.checked = !currentValue;
			return l;
		}));
	}

	return (
		<div className="my-2 check-sidely px-2 d-flex">
			<label className="ml-2 mr-3 my-2">
				<input
					type="checkbox"
					className="mr-3"
					checked={getIsCheckedAll()}
					onChange={e => {
						toggleCheck();
					}}
				/>
				<div className="checkbox-style" />
			</label>
			<Label className="mt-1 mb-0">
				<Translate id='all' />
			</Label>
		</div>
	);
}
