import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { LocalizeProvider } from 'react-localize-redux';
import Routes from './routes/Routes';
import store from './helpers/store';
import storeLang from './helpers/storeLang';
import { currentData } from './store/actions/user.action';
import './styles/style.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'antd/dist/antd.css';
import { CookiesProvider } from 'react-cookie';
import { RecoilRoot } from 'recoil';
import PageLoader from './components_v2/pageLoader/PageLoader';
import { FlexDiv } from './containers_v2/products/style';


const history = createBrowserHistory();

store.dispatch(currentData());

ReactDOM.render(
	<RecoilRoot>
		<React.Suspense fallback={<FlexDiv height='100vh' width='100vw' justify='center'><PageLoader /></FlexDiv>}>
			<CookiesProvider>
				<LocalizeProvider store={storeLang}>
					<Provider store={store}>
						<Router history={history}>
							<div>{renderRoutes(Routes)}</div>
						</Router>
					</Provider>
				</LocalizeProvider>
			</CookiesProvider>
		</React.Suspense>
	</RecoilRoot>,
	document.getElementById('root')
);
