import * as React from 'react';
import { Container, Round } from './style/Style';

function Switch(props: {
  value?: boolean
  onChange?: (value: boolean) => void
  readonly?: boolean
  size?: string
}): JSX.Element {
	const [value, setValue] = React.useState<boolean>(props.value ?? false);

	React.useEffect(() => {
		setValue(props.value ?? false);
	}, [props.value]);

	return (
		<Container size={props.size ?? '20px'} isActive={value} onClick={() => {
			if (props.readonly) return;
			const newValue = !value;
			setValue(newValue);
			props.onChange?.(newValue);
		}}>
			<Round size={props.size ?? '20px'} isActive={value} />
		</Container>
	);
}

export default Switch;
