import * as React from 'react';
import { Body, ListContainer, ListText } from '../../../client-companies/style/LinkedElementStyle';
import { Open } from '../../../../styles/global/css/Open';
import { DEFAULT_LINKED_ELEMENT_OPEN_SIZE } from '../../../client-companies/popup/LinkedElements/LinkedElements';
import { translateToString } from '../../../../styles/global/translate';
import { ShelfAuditDetail } from '../../model/models';
import ShelfAuditComments from './linkedElements/ShelfAuditComments';
import ShelfAuditPresentProducts from './linkedElements/ShelfAuditPresentProducts';
import ShelfAuditAbsentProducts from './linkedElements/ShelfAuditAbsentProducts';
import ShelfAuditPictures from './linkedElements/ShelfAuditPictures';


const STATE_KEY_LIST = [
	['comments', false],
	['pictures', false],
	['present_products', false],
	['absent_products', false],
] as const;
type StateKey = typeof STATE_KEY_LIST[number][0];
type State<T> = { [key in StateKey]: T };


function LinkedElementTitle(props: {
    amount: number | undefined,
    switchOpen: (value: StateKey) => void,
    openList: State<boolean>,
    stateKey: StateKey,
    first?: boolean,
}) {
	const { switchOpen, openList, stateKey } = props;

	return <ListContainer onClick={() => switchOpen(stateKey)} borderTop={props.first ? 'none' : undefined}>
		<Open isOpen={openList[stateKey]} size={DEFAULT_LINKED_ELEMENT_OPEN_SIZE}/>
		<ListText>{`${translateToString(`shelf_audit.linked_elements.${stateKey}`)} ${props.amount !== undefined ? `(${props.amount})` : ''}`}</ListText>
	</ListContainer>;
}

function genDefaultState(isFullOpen: boolean): State<boolean> {
	return {
		comments: isFullOpen,
		pictures: isFullOpen,
		present_products: isFullOpen,
		absent_products: isFullOpen
	};
}

function handleOpenProduct(productId: number) {
	window.open(`/products-v2?id=${productId}&mode=default`);
}

function LinkedElement(props: {
    stateKey: StateKey,
    switchOpen: (value: StateKey) => void,
    openList: State<boolean>,
    shelfAudit: ShelfAuditDetail,
    creatable: boolean,
    first: boolean,
    isFullOpen: boolean,
    onDisableClose: (val: boolean) => void
}) {
	const { stateKey, switchOpen, openList, shelfAudit, first, onDisableClose } = props;

	function getAmount() {
		const current = shelfAudit[stateKey];
		if (stateKey === 'pictures') return (shelfAudit.beginning_pictures?.length ?? 0) + (shelfAudit.end_pictures?.length ?? 0);
		else if (stateKey.includes('products')) return current?.length ?? 0;
		return undefined;
	}

	function toggleLinkedElement() {
		switch (stateKey) {
			case 'comments':
				return <ShelfAuditComments comments={shelfAudit.comments} owner_id={shelfAudit.owner_id}
					date={shelfAudit.date}/>;
			case 'pictures':
				return <ShelfAuditPictures
					beginning_pictures={shelfAudit.beginning_pictures}
					end_pictures={shelfAudit.end_pictures} isFullOpen={props.isFullOpen}
					onDisableClose={onDisableClose}
				/>;
			case 'present_products':
				return <ShelfAuditPresentProducts presentProducts={shelfAudit.present_products}
					openProduct={handleOpenProduct}
					isFullOpen={props.isFullOpen}
				/>;
			case 'absent_products':
				return <ShelfAuditAbsentProducts absentProducts={shelfAudit.absent_products}
					openProduct={handleOpenProduct}
					isFullOpen={props.isFullOpen}
				/>;
			default:
				return null;
		}
	}

	return <>
		<LinkedElementTitle
			switchOpen={switchOpen}
			openList={openList}
			stateKey={stateKey}
			first={first}
			amount={getAmount()}
		/>
		{openList[stateKey] && toggleLinkedElement()}
	</>;
}

export default function ShelfAuditPopupLinkedElements(props: {
    shelfAudit: ShelfAuditDetail,
    isFullOpen: boolean,
    onDisableClose: (val: boolean) => void
}) {
	const { shelfAudit, isFullOpen, onDisableClose } = props;
	const [openList, setOpenList] = React.useState<State<boolean>>(genDefaultState(isFullOpen));


	const switchState = React.useCallback((fn: React.Dispatch<React.SetStateAction<State<boolean>>>) => (value: StateKey) => fn(state => {
		state[value] = !state[value];
		return { ...state };
	}), []);


	return <Body>
		{STATE_KEY_LIST.map(([key, creatable], i) => <LinkedElement
			key={`ContactLinkedElement[${key}]`}
			switchOpen={switchState(setOpenList)}
			openList={openList}
			stateKey={key}
			first={i === 0}
			creatable={creatable}
			shelfAudit={shelfAudit}
			isFullOpen={isFullOpen}
			onDisableClose={onDisableClose}
		/>)}
	</Body>;
}

