import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API, URL_FOUNDATION, URL_JANUS } from '../../../../config/keys';
import { Email } from './Emails';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

export interface EmailInformation {
  company: boolean
  id: number
  email: string
  company_id: number
}

interface MailRes {
  nextPageToken: string
  messages: Email[]
  emailInformations: EmailInformation[],
  token_error?: boolean
}

export type LinkedElementId = { 'Contact': number } | { 'Company': number }

export async function getMails(max: number, next_page_token: string | undefined, id: LinkedElementId): Promise<MailRes> {
	axios.defaults.headers.common.Authorization = `${token}`;

	return axios.post<MailRes>(`${URL_JANUS}/api/v1/get-mails`, { max, id, next_page_token }).then(res => res.data);
}

export async function createEvent(data) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'POST',
		url: `${URL_API}/api/events`,
		headers: { 'Content-Type': 'multipart/form-data' },
		data
	});
}

export async function getOrders(companyId: number, limit: number, offset: number) {
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'GET',
		url: `${URL_FOUNDATION}/api/crm/client-companies/${companyId}/orders?limit=${limit}&offset=${offset}&sort_on=order_date&desc=true`,
		headers: { 'Content-Type': 'multipart/form-data' }
	});
}
