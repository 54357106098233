import * as React from 'react';
import alertPopup, { AlertProps, AlertPropsDelete } from './Alert';
import { translateToNode } from '../../styles/global/translate';

export enum AlertRes {
	Ok,
	Break
}

interface AlertContextType {
	alert: (p: AlertProps) => Promise<AlertRes>,
	alertDelete: (p: AlertPropsDelete & { name: string }) => Promise<AlertRes>
}

const defaultBehaviour: AlertContextType = {
	alert: async() => AlertRes.Ok,
	alertDelete: async() => AlertRes.Ok
};

export const AlertContext = React.createContext<AlertContextType>(defaultBehaviour);

export function AlertProvider(props: {
	children: React.ReactNode
}) {
	const [child, setChild] = React.useState<React.ReactNode>();

	async function alert(props: AlertProps) {
		return alertPopup({
			setChild,
			...props
		});
	}
	async function alertDelete(props: AlertPropsDelete & { name: string }) {
		return alert({
			mode: 'delete',
			title: translateToNode('global.fire.alert_title'),
			content: <div>{translateToNode('global.fire.delete_message_node', { variables: [['NAME', props.name]] })}</div>,
			...props			
		});
	}

	return <AlertContext.Provider value={{ alert, alertDelete }}>
		{child}
		{props.children}
	</AlertContext.Provider>;
}