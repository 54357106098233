import open_new_tab from 'images/icons/company/open_new_tab.svg';
import modal_open from 'images/icons/company/modal_open.svg';
import company from 'images/icons/company.svg';
import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ACompanyEdition } from '../../../atoms/company/companyEdition';
import { ARefresh } from '../../../atoms/global/refresh';
import { AUsers } from '../../../atoms/global/users';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { Loader } from '../../../styles/global/css/GlobalLoader';
import { Open } from '../../../styles/global/css/Open';
import { SidelyBlack } from '../../../styles/global/css/Utils';
import { Translate, translateToString } from '../../../styles/global/translate';
import { getClientStatuses } from '../../import/actions';
import { LoadingStateEnum } from '../../import/model';
import { Owner } from '../../orders/model/Model';
import { HeaderTitleParent } from '../../orders/templateOrders/subPage/style/PopupOrderDetailStyle';
import Restricted from '../../permissions/Restricted';
import { FlexDiv } from '../../products/style';
import { getCompanyDetail, getCompanyLinkedElementAmounts, getCompanyTimeline, updateCompany } from '../data/action';
import { deleteCompanyIdPopup } from '../data/CompanyColumns';
import {
	ClientCompanyEdit,
	CompanyDetail,
	CompanyLinkedElementAmount,
	CompanyStatus,
	CompanyTimeline,
	TimelineElement
} from '../model/Model';
import {
	Body,
	BodyHeaderStatus,
	BodyMainTextEditable,
	Container,
	DetailDots,
	FullOpen,
	Header,
	HeaderBlock,
	HeaderExtends,
	HeaderRight,
	LeftContainer,
	Logo,
	MiddleContainer,
	RightContainer
} from '../style/PopupStyle';
import Detail, { TitleAndChild } from './Detail';
import LinkedElements from './LinkedElements/LinkedElements';
import QuickActions from './QuickActions';
import Timeline from './Timeline';
import { Unhautorized } from '../../noData/NoData';
import optionGrey from 'images/icon/options_grey.png';
import Frequencies from './Frequencies';
import { Period, Promotion } from '../../promotions/model';
import { getCompanyPromotions } from './action';
import * as moment from 'moment';
import {
	HeadBandButton,
	HeadBandClose,
	TOP_HEADBAND_HEIGHT,
	TopHeadBandChildType,
	TopHeadBandContext
} from '../../topHeadBand/TopHeadBandProvider';
import styled from 'styled-components';
import promotionIcon from 'images/icons/promotion.svg';
import { RangeControllerInput } from '../../promotions/PromotionsColumns';
import { useFunctionState } from '../../../utils/customHooks';
import { runningPromotion } from './LinkedElements/sections/Promotion';
import { blurOnEnterPressed } from '../../../components_v2/input/Input';
import { PopupContext } from '../../../components_v2/popup/Popup';
import CompanyReports from './CompanyReports';
import { MetaPanelSelector } from '../../../components_v2/Selector/PanelSelector';
import timelineImg from 'images/icons/timeIcon.svg';
import dashboardImg from 'images/icons/chart.svg';
import KeyResults from './KeyResults';
import { AlertContext } from '../../alert/AlertProvider';
import ShelfAuditPopup from '../../shelfAudit/popup/ShelfAuditPopup';
import { ModalState } from '../../products/model';
import PermissionContext from '../../permissions/PermissionContext';
import AdditionalColumns from './AdditionalColumns';
import { InstancePopup } from '../../forms/formInstancePopup';
import ClientCompany from '../../../components_v2/clientCompany/ClientCompany';

const RightContainerHeaderWrapper = styled.div`
	width:100%;
	display:flex;
	justify-content: space-between;
	padding-right: 20px;
`;

const CompanyPromotionHeadBandDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	padding: 0 15px;
`;

const Carousel = styled.div`
	height: ${TOP_HEADBAND_HEIGHT};
	position: relative;
	width: calc(100% - 5em - 175px);
`;

const CarouselItem = styled.div<{ index: number }>`
	height: ${TOP_HEADBAND_HEIGHT};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	transition: translate 0.5s;
	translate: 0 -${p => p.index * 100}%;
	gap: 1em;
`;

export const DetailPopupHeader = styled(Header)`
	height:auto;
	flex-shrink: 0;
	align-items: flex-start;
`;

export const DetailPopupLeftContainer = styled(LeftContainer)`
	height:auto;
	overflow-y: auto;
`;

const TitleNoShrink = styled.h4`
	flex-shrink: 0;
	margin: 0;
	color: white;
`;

const PromotionName = styled.h5`
	flex-shrink: 0;
	margin: 0;
	color: white;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export function PromotionCarousel(props: { promotions: { periods: Period[], id: number, name: string }[] }) {
	const [index, setIndex] = React.useState<number>(0);
	const [localTimeout, setLocalTimeout] = useFunctionState<NodeJS.Timeout | undefined>(undefined, ({ oldValue, newValue }) => {
		clearTimeout(oldValue);
		return newValue;
	});

	React.useEffect(() => {
		const swapPromotionIndex = (down: boolean) => setTimeout(() => {
			setIndex(index => {
				if (down && index === props.promotions.length - 1) {
					setLocalTimeout(swapPromotionIndex(!down));
					return index - 1;
				} else if (!down && index === 0) {
					setLocalTimeout(swapPromotionIndex(!down));
					return index + 1;
				}
				setLocalTimeout(swapPromotionIndex(down));
				return down ? index + 1 : index - 1;
			});
		}, 5000);
		setLocalTimeout(swapPromotionIndex(true));
		return () => {
			clearTimeout(localTimeout);
		};
	}, [props.promotions.length]);

	const currentTime = moment();
	return <Carousel>
		{props.promotions.map(p => {
			const activePeriods = p.periods.filter(period => moment.utc(period.start).diff(currentTime, 'minutes') < 0 && moment.utc(period.end).diff(currentTime, 'minutes') > 0);
			if (!activePeriods) return undefined;
			return <CarouselItem key={`CarrouselItem[${p.id}]`} index={index} >
				<img src={promotionIcon} height='50%'/>
				<PromotionName>
					{p.name}
				</PromotionName>
				{activePeriods.map((activePeriod, i) => <FlexDiv key={`CarrousselItemPromotion[${p.id}][${i}]`}>
					{activePeriod.name}
					<RangeControllerInput startDate={moment.utc(activePeriod.start)} endDate={moment.utc(activePeriod.end)} noEdit white/>
				</FlexDiv>)}
			</CarouselItem>;
		})}
	</Carousel>;
}

function CompanyPromotionHeadBand(props: { promotions: Promotion[] }) {
	const headBand = React.useContext(TopHeadBandContext);
	const close = () => {
		headBand.setIsOpen(false);
		headBand.setChild(undefined);
	};

	return <CompanyPromotionHeadBandDiv>
		<FlexDiv gap='5em' flexShrink={1} width='100%' overflow='hidden'>
			<TitleNoShrink>
				<Translate id='current_campagnes' />
			</TitleNoShrink>
			<PromotionCarousel promotions={props.promotions} />
		</FlexDiv>
		<FlexDiv justify='right' gap='2.5em' flexShrink={0}>
			<HeadBandButton onClick={() => window.open('/promotions')}><Translate id='see_campagnes' /></HeadBandButton>
			<HeadBandClose onClick={close} />
		</FlexDiv>
	</CompanyPromotionHeadBandDiv>;
}

function CompanyPopup(props: {
  clientCompanyId: number
  mode: PopupMode
  setFullOpen: (value: boolean) => void
  onClickOut: () => void
  onDisableClickOut: (value: boolean) => void
  noFullScreen?: boolean
}): JSX.Element {
	const TIMELINE_LIMIT = 20;
	const { isChild } = React.useContext(PopupContext);

	const [loading, setLoading] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [loadingLeft, setLoadingLeft] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);

	const [companyInformation, setCompanyInformation] = React.useState<CompanyDetail>();

	const [companyName, setCompanyName] = React.useState<string>('');
	const [statuses, setStatuses] = React.useState<CompanyStatus[]>([]);
	const [status, setStatus] = React.useState<{ color: string, name: string }>();

	const [isFullOpen, setFullOpen] = React.useState<boolean>(props.mode === PopupMode.Default);
	const [isMiddleOpen, setMiddleOpen] = React.useState<boolean>(true);
	const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);

	const [timeline, setTimeline] = React.useState<CompanyTimeline[]>();

	const [companyDetail, setCompanyDetail] = React.useState<CompanyDetail>();
	const [linkedAmount, setLinkedAmount] = React.useState<CompanyLinkedElementAmount>();
	const setAtomCompanyEdition = useSetRecoilState(ACompanyEdition);
	const owners = useRecoilValue(AUsers);
	const setRefreshingAtom = useSetRecoilState(ARefresh);
	const { alertDelete } = React.useContext(AlertContext);
	const [promotions, setPromotions] = React.useState<Promotion[]>([]);
	const headBand = React.useContext(TopHeadBandContext);
	const [activeCenterIndex, setActiveCenterIndex] = React.useState<number>(0);
	const [shelfAuditModal, setShelfAuditModal] = useState<ModalState>({ isOpen:false });
	const [formModal, setFormModal] = useState<ModalState<string>>({ isOpen:false });
	const [subCompanyState, setSubCompanyState] = useState<ModalState<number>>({ isOpen:false });
	const { isAllowedTo } = React.useContext(PermissionContext);
	React.useEffect(() => {
		if (!isAllowedTo({ objectAction: 'ReadCampaign' })) return;
		if (headBand.childType !== undefined && headBand.childType !== TopHeadBandChildType.Promotion) return;
		const currentProms = promotions.filter(runningPromotion);
		if (currentProms.length === 0) return;
		headBand.setIsOpen(true);
		headBand.setChild({ child: <CompanyPromotionHeadBand promotions={currentProms}/>, options: { noButton: true }, type: TopHeadBandChildType.Promotion });
		return () => {
			headBand.setIsOpen(false);
			headBand.setChild(undefined);
		};
	}, [promotions]);

	const refreshingAtom = useRecoilValue(ARefresh);

	React.useEffect(() => {
		if (refreshingAtom.timeline !== undefined) updateLinkedElementAmounts();
	}, [refreshingAtom.timeline]);

	async function update(params: ClientCompanyEdit): Promise<void> {
		return updateCompany(params).then(() => setAtomCompanyEdition({ ...params, ressource_id: params.client_company_id }));
	}

	function updateStatus(nstatus: CompanyStatus): void {
		if (status?.name !== nstatus.name) {
			setStatus({ name: nstatus.name, color: nstatus.color_code });
			update({
				client_company_id: props.clientCompanyId,
				client_status_id: nstatus.id
			} as ClientCompanyEdit)
				.then(_ => setRefreshingAtom({ timeline: {} }));
		}
	}

	function updateCompanyName(name: string): void {
		if (name !== companyName) {
			setCompanyName(name);
			update({
				client_company_id: props.clientCompanyId,
				company_name: name
			} as ClientCompanyEdit);
		}
	}

	const fetchCompanyDetails = async() => await getCompanyDetail(props.clientCompanyId).then(response => {
		if (response != null) {
			setStatus({ color: response.status_color, name: response.status_name });
			setCompanyName(response.company_name);
			setCompanyInformation(response);
			setCompanyDetail(response);
		}
		setLoading(LoadingStateEnum.LOADED);
	});

	const updatePromotions = () => getCompanyPromotions(props.clientCompanyId).then(setPromotions);

	React.useEffect(() => {
		setLoading(LoadingStateEnum.LOADING);
		getClientStatuses().then(response => {
			setStatuses(response.data);
		});
		fetchCompanyDetails();
		updateTimeline();
		updateLinkedElementAmounts();
		updatePromotions();

	}, [props.clientCompanyId]);

	React.useEffect(() => {
		setFullOpen(props.mode === PopupMode.Default);
	}, [props.mode]);

	if (loading === LoadingStateEnum.LOADING) {
		return <Loader center width="40px" />;
	}

	function updateLinkedElementAmounts(): void {
		getCompanyLinkedElementAmounts(props.clientCompanyId).then(response => {
			setLinkedAmount(response);
		});
	}

	function updateTimeline(element?: TimelineElement, owner?: Owner, newSearch?: string): void {
		getCompanyTimeline({
			client_company_id: props.clientCompanyId,
			limit: TIMELINE_LIMIT,
			offset: 0,
			element,
			search: newSearch,
			user_id: owner?.id
		}).then(tm => {
			setTimeline(tm);
		});
	}

	function switchFullMode(): void {
		const n = !isFullOpen;
		setFullOpen(n);
		props.setFullOpen(n);
	}

	const unhautorized = <>
		<DefaultImage cursor="pointer" src={close} onClick={() => props.onClickOut()} />
		<Unhautorized />
	</>;
	const isSelf = companyDetail?.owner_id === owners.find(o => o.isYou)?.id;

	const linked = <LinkedElements
		clientCompanyId={props.clientCompanyId}
		isExtend={!isMiddleOpen && isFullOpen}
		linkedAmount={linkedAmount}
		onUpdate={() => {
			updateTimeline();
			updateLinkedElementAmounts();
			updatePromotions();
		}}
		onDisableClickOut={props.onDisableClickOut}
		companyDetail={companyDetail}
		isSelf={isSelf}
		promotions={promotions}
	/>;
	const dotDropdown = <Restricted to={{ objectAction: 'DeleteCompany' }} isSelf={isSelf}>
		<Dropdown
			dropdownStyle={{ optionWidth: '160px', optionLeft: 'calc(-160px + 100%)' }}
			datalist={[
				{
					label: translateToString('global.quick_action.delete'),
					value: 'DELETE'
				}]}
			name='options'
			JSXButton={() => <DetailDots src={optionGrey} />}
			onChange={(value: DropdownData<string>) => {
				switch (value.value) {
					case 'DELETE':
						deleteCompanyIdPopup(alertDelete, props.clientCompanyId, companyInformation?.company_name ?? '', () => {
							setAtomCompanyEdition({ deleted: props.clientCompanyId });
							props.onClickOut();
						});
				}
			}}
		/>
	</Restricted>;

	return <Restricted to={{ objectAction: 'ReadCompany' }} isSelf={isSelf} fallback={unhautorized}>
		<Container>
			<DetailPopupLeftContainer isFullOpen={isFullOpen}>
				<DetailPopupHeader>
					<DefaultImage cursor="pointer" src={close} onClick={() => props.onClickOut()} />
					<FlexDiv width="calc(100% - 70px)" flow='column' justify='space-evenly' flexShrink={0}>
						<FlexDiv width='100%' align='stretch'>
							<FlexDiv margin="5px 0 0 10px" padding="5px 0 0 5px" width='calc(100% - 35px)' align='flex-start'>

								<Logo src={companyInformation?.company_logo ?? company} />

								<FlexDiv flow="column" margin="0 0 0 10px" align="flex-start" width='calc(100% - 60px)' gap='2px'>
									<HeaderBlock flexDirection="row" error={companyDetail?.error == 'company_name'}>
										<Restricted
											to={{ objectAction: 'UpdateCompany' }}
											fallback={<div 
												style={{
													fontSize: '15px',
													color: SidelyBlack,
													padding: '0px',
													fontWeight: '700'
												}}
											>
												{companyDetail?.company_name}
											</div>}
											isSelf={isSelf}
										>
											<BodyMainTextEditable
												marginLeft='0px'
												fontSize='15px'
												fontWeight='700'
												color={SidelyBlack}
												suppressContentEditableWarning
												contentEditable
												onKeyDownCapture={blurOnEnterPressed}
												onBlur={(e) => {
													const newTitle = e.currentTarget.innerText.trim();
													newTitle !== companyDetail?.company_name && newTitle && updateCompanyName(newTitle);
												}}
											>
												{companyDetail?.company_name}
											</BodyMainTextEditable>
										</Restricted>
									</HeaderBlock>
									<HeaderBlock flexDirection="row">
										<Restricted
											to={{ objectAction: 'UpdateCompany' }}
											fallback={<BodyHeaderStatus backgroundColor={status?.color}>
												{status?.name ?? '-'}
											</BodyHeaderStatus>}
											isSelf={isSelf}
										>
											<Dropdown
												dropdownStyle={{ optionWidth: 'fit-content', height: '21px' }}
												name="select_status"
												datalist={statuses.sort((a, b) => a.sequence - b.sequence).map(s => ({ label: s.name, value: s, color: s.color_code }))}
												JSXButton={() => <FlexDiv gap="5px">
													<BodyHeaderStatus backgroundColor={status?.color}>
														{status?.name ?? '-'}
													</BodyHeaderStatus>
													<Open isOpen={dropdownOpen} size={10} />
												</FlexDiv>}
												JSXContent={(status: DropdownData) => <BodyHeaderStatus inDropdown backgroundColor={status.color}>{status.label ?? '-'}</BodyHeaderStatus>}
												onChange={(value: DropdownData) => updateStatus(value.value)}
												onOpen={setDropdownOpen}
											/>
										</Restricted>
									</HeaderBlock>
								</FlexDiv>
							</FlexDiv>
						</FlexDiv>
						<QuickActions company={companyDetail} onCreate={() => updateLinkedElementAmounts()} isSelf={isSelf} />
					</FlexDiv>
					<HeaderRight>
						{!isChild && !props.noFullScreen && <FullOpen src={modal_open} isActive={isFullOpen} onClick={() => switchFullMode()} />}
						{(isChild || props.noFullScreen) && <FullOpen isActive src={open_new_tab} onClick={() => window.open(`/companies?id=${props.clientCompanyId}`, '_blank')?.focus()} />}
						{!isFullOpen && dotDropdown}
					</HeaderRight>
				</DetailPopupHeader>
				<Body columns>
					<Detail
						clientCompanyId={props.clientCompanyId}
						loading={loadingLeft}
						setLoading={setLoadingLeft}
						owners={owners}
						companyDetail={companyDetail}
						companyInformation={companyInformation}
						refresh={fetchCompanyDetails}
						isSelf={isSelf}
						isExtend={isFullOpen}
						openSubCompany={setSubCompanyState}
					/>
					<KeyResults
						clientCompanyId={props.clientCompanyId}
						toggleShelfAuditPopup={(id, fullOpenMode) => setShelfAuditModal({ fullOpenMode, isOpen: true, data: id })} />
					<Restricted to={{ objectAction: 'ReadForm' }}>
						<AdditionalColumns
							isSelf={isSelf}
							clientCompanyId={props.clientCompanyId}
							openSubCompany={setSubCompanyState}
						/>
					</Restricted>
					<Frequencies
						isSelf={isSelf}
						clientCompanyId={props.clientCompanyId}
					/>
					{!isFullOpen && <div style={{ padding: '0 15px' }}>
						<TitleAndChild title={<Translate id='linked_elements'/>} >
							{linked}
						</TitleAndChild>
					</div>}
				</Body>
			</DetailPopupLeftContainer>
			{isFullOpen && <>
				<MiddleContainer isOpen={isMiddleOpen} isFullOpen={isFullOpen}>
					{isMiddleOpen && <>
						<MetaPanelSelector panels={[{ label: 'Timeline', img: timelineImg }, { label: 'Dashboard', img: dashboardImg }]} activeIndex={activeCenterIndex} onClick={(_, i) => setActiveCenterIndex(i)} />
						{activeCenterIndex === 0 && <>
							{!timeline && <Loader center />}
							{timeline && <Timeline
								isExtend={isMiddleOpen}
								clientCompanyId={props.clientCompanyId}
								limit={TIMELINE_LIMIT}
								timeline={timeline}
								onRefresh={updateTimeline}
								onTimelineScroll={(response) => setTimeline(timeline.concat(response))}
								owners={owners}
								onDisableClickOut={props.onDisableClickOut}
								onNoteDelete={(id) => {
									setTimeline(timeline.filter(t => !(t.action_type.toLocaleLowerCase() === 'note' && t.id === id)));
									updateLinkedElementAmounts();
								}}
								toggleShelfAuditPopup={(id, fullOpenMode) => setShelfAuditModal({ fullOpenMode, isOpen: true, data: id })}
								toggleFormPopup={(data) => setFormModal({ isOpen: true, data, fullOpenMode: true })}
							/>}
						</>}
						{activeCenterIndex === 1 && <CompanyReports id={props.clientCompanyId} />}
					</>}
				</MiddleContainer>
				<RightContainer isOpen={isMiddleOpen} isFullOpen={isFullOpen}>
					<RightContainerHeaderWrapper>
						<HeaderExtends isOpen={isMiddleOpen} src={modal_open} onClick={() => setMiddleOpen(!isMiddleOpen)} />
						{isFullOpen && dotDropdown}
					</RightContainerHeaderWrapper>
					{linked}
				</RightContainer>
			</>}
			<ShelfAuditPopup
				shelfAuditId={shelfAuditModal.data}
				clientCompanyId={props.clientCompanyId}
				isOpen={shelfAuditModal.isOpen}
				fullOpen={shelfAuditModal.fullOpenMode}
				handleResizePopup={() => setShelfAuditModal((prev) => ({ ...prev, fullOpenMode:(prev.fullOpenMode !== true) }))}
				onClickOut={() => setShelfAuditModal({ isOpen:false })}
				popupStyle={{ animate: true, width:'450px' }}
				popupMode={shelfAuditModal.fullOpenMode ? PopupMode.Default : PopupMode.Details}
				disableOutClick
				disableEsc
				isChild = {true}
			/>
			<InstancePopup modalState={formModal} isOpen={formModal.isOpen} onClickOut={() => setFormModal({ ...formModal, isOpen: false })}/>
		</Container>
		<ClientCompany
			isOpen={subCompanyState.isOpen}
			setOpen={isOpen => setSubCompanyState({ ...subCompanyState, isOpen })}
			clientCompanyId={subCompanyState.data}
		/>
	</Restricted>;
}

export default CompanyPopup;
