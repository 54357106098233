import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API } from '../../config/keys';
import { handleError } from '../../helpers/error';

const cookies = new Cookies();

export const SELECT_INCHARGE = 'SELECT_INCHARGE';
export const SELECT_ALL_USER = 'SELECT_ALL_USER';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_GROUP_BY_MANAGER = 'SELECT_GROUP_BY_MANAGER';
export const SELECT_CLIENT_STATUS = 'SELECT_CLIENT_STATUS';
export const SELECT_OPPORTUNITY_STATUS = 'SELECT_OPPORTUNITY_STATUS';
export const SELECT_CONTACT = 'SELECT_CONTACT';
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const SELECT_OPPORTUNITY = 'SELECT_OPPORTUNITY';
export const SELECT_EVENT_TYPE = 'SELECT_EVENT_TYPE';
export const SELECT_EVENT_STATUS = 'SELECT_EVENT_STATUS';
export const SELECT_PROSPECT_ORIGIN = 'SELECT_PROSPECT_ORIGIN';
export const SELECT_INTEREST = 'SELECT_INTEREST';
export const SELECT_TRADESHOW_TYPE = 'SELECT_TRADESHOW_TYPE';
export const SELECT_EXPENSE_CATEGORY = 'SELECT_EXPENSE_CATEGORY';
export const SELECT_EXPENSE_STATUS = 'SELECT_EXPENSE_STATUS';
export const SELECT_EXPENSE_CURRENCY = 'SELECT_EXPENSE_CURRENCY';
export const SELECT_EXPENSE_PAYMENT_METHOD = 'SELECT_EXPENSE_PAYMENT_METHOD';
export const SELECT_ALL_YEARS = 'SELECT_ALL_YEARS';
export const SELECT_USER_TYPE = 'SELECT_USER-TYPE';
export const SELECT_MANAGER = 'SELECT_MANAGER';
export const SELECT_TARGET_TYPE = 'SELECT_TARGET_TYPE';
export const SELECT_USER_BY_MANAGER = 'SELECT_USER_BY_MANAGER';
export const GET_USER_IMAGE = 'GET_USER_IMAGE';
export const GET_GROUP_IMAGE = 'GET_GROUP_IMAGE';
export const GET_ALPHABET = 'GET_ALPHABET';
export const SELECT_DATA_CITY = 'GET_DATA_CITY';

export const GET_LIST_COMPANIES_REQUEST = 'GET_LIST_COMPANIES_REQUEST';
export const GET_LIST_COMPANIES_SUCCESS = 'GET_LIST_COMPANIES_SUCCESS';
export const GET_LIST_COMPANIES_ERROR = 'GET_LIST_COMPANIES_ERROR';

export const selectInCharge = (userId, companyId, role) => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	let res = null;

	if (role === 'manager') {
		res = await axios.get(`${URL_API}/api/users/manager/${userId}`);
	} else {
		res = await axios.get(`${URL_API}/api/users/company/${companyId}`);
	}

	dispatch({
		type: SELECT_INCHARGE,
		payload: res.data
	});
};

export const selectAllUser = id => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(`${URL_API}/api/users/company/${id}`);
	dispatch({
		type: SELECT_ALL_USER,
		payload: res.data
	});
};

export const selectGroup = params => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios({
		method: 'GET',
		url: `${URL_API}/api/groups`,
		params
	});
	dispatch({
		type: SELECT_GROUP,
		payload: res.data
	});
};

export const selectGroupAuth = params => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios({
		method: 'GET',
		url: `${URL_API}/api/groups/user`,
		params
	});
	dispatch({
		type: SELECT_GROUP_BY_MANAGER,
		payload: res.data
	});
};

export const selectClientStatus = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(`${URL_API}/api/clientStatuses`);
	dispatch({
		type: SELECT_CLIENT_STATUS,
		payload: res.data
	});
};

export const selectOpportunityStatus = status => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(
		`${URL_API}/api/opportunityStatuses?status=${status}`
	);
	dispatch({
		type: SELECT_OPPORTUNITY_STATUS,
		payload: res.data
	});
};

export const selectContact = (clientCompanyId, params) => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const url = clientCompanyId
		? `${URL_API}/api/contacts/clientCompany/id/${clientCompanyId}`
		: `${URL_API}/api/contacts/AllRefactor`;

	const res = await axios({
		method: 'get',
		url,
		params
	});
	dispatch({
		type: SELECT_CONTACT,
		payload: res.data
	});
};

export const selectOpportunity = (
	params,
	clientCompanyId
) => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const url = clientCompanyId
		? `${URL_API}/api/opportunities/clientCompany/id/${clientCompanyId}`
		: `${URL_API}/api/opportunities/AllRefactor`;

	const res = await axios({
		method: 'get',
		url,
		params
	});
	dispatch({
		type: SELECT_OPPORTUNITY,
		payload: res.data
	});
};

export const selectCompany = params => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios({
		method: 'get',
		url: `${URL_API}/api/clientCompanies/AllRefactor`,
		params
	});
	dispatch({
		type: SELECT_COMPANY,
		payload: res.data
	});
};

export const selectEventType = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(`${URL_API}/api/eventTypes`);
	dispatch({
		type: SELECT_EVENT_TYPE,
		payload: res.data
	});
};

export const selectEventStatus = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(`${URL_API}/api/eventStatuses`);
	dispatch({
		type: SELECT_EVENT_STATUS,
		payload: res.data
	});
};

export const selectProspectOrigin = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	const res = await axios.get(`${URL_API}/api/prospectOrigins`);
	dispatch({
		type: SELECT_PROSPECT_ORIGIN,
		payload: res.data
	});
};

export const getAlphabet = data => async dispatch => {
	dispatch({
		type: GET_ALPHABET,
		payload: data
	});
};

export const getAllCompanies = params => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	try {
		dispatch({
			type: GET_LIST_COMPANIES_REQUEST
		});
		const response = await axios({
			method: 'get',
			url: `${URL_API}/api/clientCompanies/listCompany`,
			params
		});
		dispatch({
			type: GET_LIST_COMPANIES_SUCCESS,
			payload: response.data.data
		});
	} catch (error) {
		handleError(error, dispatch, GET_LIST_COMPANIES_ERROR);
	}
};

export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const selectCategory = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/categories/all`);

	dispatch({
		type: SELECT_CATEGORY,
		payload: res.data.data
	});
};

export const SELECT_CATEGORY_HIERARCHY = 'SELECT_CATEGORY_HIERARCHY';
export const selectCategoryHierarchy = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(
		`${URL_API}/api/categories/Hierarchy?sort=asc&by=name`
	);

	dispatch({
		type: SELECT_CATEGORY_HIERARCHY,
		payload: res.data.data
	});
};

export const SELECT_BRAND = 'SELECT_BRAND';
export const selectBrand = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/brands/all`);

	dispatch({
		type: SELECT_BRAND,
		payload: res.data.data
	});
};

export const SELECT_CATALOGUE = 'SELECT_CATALOGUE';
export const selectCatalogue = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/catalogues/all`);

	dispatch({
		type: SELECT_CATALOGUE,
		payload: res.data.data
	});
};

export const SELECT_TAG = 'SELECT_TAG';
export const selectTag = params => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/tags`, { params });

	dispatch({
		type: SELECT_TAG,
		payload: res.data.data
	});
};

export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const selectProduct = () => async dispatch => {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const res = await axios.get(`${URL_API}/api/products/all`);

	dispatch({
		type: SELECT_PRODUCT,
		payload: res.data.data
	});
};
