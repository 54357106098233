import * as moment from 'moment';
import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { SetterOrUpdater, useRecoilState, useSetRecoilState } from 'recoil';
import { AInputSearch } from '../../atoms/filter/InputSearchFilter';
import { Atoggle } from '../../atoms/global/Toggle';
import { AtomCategory } from '../../atoms/utils/model/Model';
import { ToolbarBox } from '../../containers_v2/globals/defaultToolbar/style/Style';
import { translateToString } from '../../styles/global/translate';
import InputSearch from '../input/InputSearch';
import { PopupMode } from '../popup/model/Model';
import Popup from '../popup/Popup';
import BrandFilter from './brands/BrandFilter';
import EventStatusFilter from './events/EventStatusFilter';
import EventTypesFilter from './events/EventTypeFilter';
import ProductFilter from './products/productFilter';
import { MoreFilterToggle } from './style/Style';
import TagFilter from './tags/tagFilter';
import DatePicker, { DayPickerModal } from './time/DatePicker';
import GranularitySelect from './time/GranularitySelect';
import TimeRangeFilter from './time/TimeRangeFilter';
import UserFilter from './users/userFilter';
import UserPicker from './users/userPicker';
import { ObjectAction } from '../../../../web-types/objectActions';
import ProductPerCategoryFilter from './products/productPerCategoryFilter';
import ShelfAuditTemplatePicker from './shelfAudit/ShelfAuditTemplatePicker';
import FreeFormTemplatePicker from './free_forms/FreeFormTemplatePicker';
import AverageOrInstantPicker from './other/AverageOrInstantPicker';
import VariationSelect from './time/VariationSelect';
import FormTemplateTemplatePicker from './forms/FormTemplatePicker';
import TimeRangeFilter2, { TimeRange2 } from './time/TimeRangeFilter2';
import TimeRangeFilter3, { TimeRange3 } from './time/TimeRangeFilter3';

export enum ToolbarElement {
	INPUT_SEARCH,
	DATE_PICKER,
	USER_PICKER,
	TAG_FILTER,
	PRODUCT_FILTER,
	PRODUCT_PER_CATEGORY,
	TIME_RANGE_FILTER,
	USER_FILTER,
	EVENT_STATUS_FILTER,
	EVENT_TYPE_FILTER,
	BRAND_FILTER,
	GRANULARITY_SELECT,
	SHELF_AUDIT_TEMPLATE_PICKER,
	FREE_FORM_TEMPLATE_PICKER,
	AVERAGE_OR_INSTANT_PCIKER,
	VARIATION_PICKER,
	NEW_FORM_INSTANCE_PICKER,
	DAY_PICKER,
	TIME_RANGE_FILTER_2,
	TIME_RANGE_FILTER_3,
}

export type GenericToolbarFilterProps = {
	hidden?: boolean,
	fullScreen?: boolean,
	context?: boolean
}

type GenericToolbarFilterElement = GenericToolbarFilterProps & {
	kind: Exclude<
		ToolbarElement,
		ToolbarElement.TIME_RANGE_FILTER_2
		| ToolbarElement.AVERAGE_OR_INSTANT_PCIKER
		| ToolbarElement.PRODUCT_PER_CATEGORY
		| ToolbarElement.TIME_RANGE_FILTER
		| ToolbarElement.USER_FILTER
		| ToolbarElement.TAG_FILTER
		| ToolbarElement.DATE_PICKER
		| ToolbarElement.USER_PICKER
		| ToolbarElement.TIME_RANGE_FILTER_3
		| ToolbarElement.BRAND_FILTER
	>,
	
}

interface UserPickerElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.USER_PICKER
	logo?: string
	deleted?: boolean
}

interface DatePickerElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.DATE_PICKER
	future?: boolean
	oneDay?: boolean
	defaultStart?: moment.Moment
	defaultEnd?: moment.Moment
}

export type TagType = 'product' | 'company'

interface TagFilterElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.TAG_FILTER
	tagType?: TagType
	//   tagFilters?: string[]
}

interface UserFilterElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.USER_FILTER
	user?: 'all' | 'me'
}

interface TimeRangeFilterElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.TIME_RANGE_FILTER
	defaultValue?: number,
	disabled?: boolean
}

interface ProductPerCategoryElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.PRODUCT_PER_CATEGORY
	max?: number
}

interface AverageOrInstantPickerElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.AVERAGE_OR_INSTANT_PCIKER,
	disabled?: boolean;
}

interface TimeRangeFilterElement2 extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.TIME_RANGE_FILTER_2
	defaultValue?: TimeRange2 | null,
	disabled?: boolean
	future?: boolean
	allowNull?: boolean
}

interface TimeRangeFilterElement3 extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.TIME_RANGE_FILTER_3
	defaultValue?: TimeRange3,
	allowNull?: boolean
}

interface BrandFilterElement extends Omit<GenericToolbarFilterElement, 'kind'> {
	kind: ToolbarElement.BRAND_FILTER
	withoutBrandOption?: boolean
}

type WrappedToolbarElement = BrandFilterElement | UserPickerElement | DatePickerElement | TimeRangeFilterElement | UserFilterElement | TagFilterElement | ProductPerCategoryElement | AverageOrInstantPickerElement | TimeRangeFilterElement2 | GenericToolbarFilterElement | TimeRangeFilterElement3

export type ToolbarFilterElement = WrappedToolbarElement
	| ToolbarElement.INPUT_SEARCH
	| ToolbarElement.TAG_FILTER
	| ToolbarElement.PRODUCT_FILTER
	| ToolbarElement.TIME_RANGE_FILTER
	| ToolbarElement.USER_FILTER
	| ToolbarElement.EVENT_STATUS_FILTER
	| ToolbarElement.EVENT_TYPE_FILTER
	| ToolbarElement.BRAND_FILTER
	| ToolbarElement.GRANULARITY_SELECT
	| ToolbarElement.SHELF_AUDIT_TEMPLATE_PICKER
	| ToolbarElement.FREE_FORM_TEMPLATE_PICKER
	| ToolbarElement.VARIATION_PICKER
	| ToolbarElement.NEW_FORM_INSTANCE_PICKER
	| ToolbarElement.DAY_PICKER
	| ToolbarElement.PRODUCT_PER_CATEGORY

function inputSearch(inputSearchText: string, setInputSearchText: SetterOrUpdater<string>): JSX.Element {
	return (
		<InputSearch
			name={'filter_toolbar_input'}
			type={'text'}
			onChange={(value) => setInputSearchText(value)}
			value={inputSearchText}
			delay={500}
			placeholder={translateToString('search')}
		/>
	);
}



function SwitchFilters(props: {
	element: ToolbarFilterElement,
	atomId: number,
	inputSearchText: string,
	setInputSearchText: SetterOrUpdater<string>,
	permission?: ObjectAction
}): JSX.Element {
	const { element, atomId, inputSearchText, setInputSearchText, permission } = props;
	const mapped: WrappedToolbarElement	 = typeof element === 'number' ? { kind: element } : element;
	switch (mapped.kind) {
		case ToolbarElement.INPUT_SEARCH:
			return inputSearch(inputSearchText, setInputSearchText);
		case ToolbarElement.USER_PICKER:
			return <UserPicker hidden={mapped.hidden} logo={mapped.logo} atomId={atomId} deleted={mapped.deleted} permission={permission} />;
		case ToolbarElement.DATE_PICKER:
			return <DatePicker hidden={mapped.hidden} atomId={atomId} future={mapped.future} oneDay={mapped.oneDay} defaultStart={mapped.defaultStart} defaultEnd={mapped.defaultEnd} />;
		case ToolbarElement.TAG_FILTER:
			return <TagFilter hidden={mapped.hidden} atomId={atomId} tagType={mapped['tagType']} />;
		case ToolbarElement.PRODUCT_FILTER:
			return <ProductFilter hidden={mapped.hidden} atomId={atomId} />;
		case ToolbarElement.TIME_RANGE_FILTER:
			return <TimeRangeFilter hidden={mapped.hidden} atomId={atomId} defaultValue={mapped['defaultValue']} disabled={mapped['disabled']} />;
		case ToolbarElement.USER_FILTER:
			return <UserFilter hidden={mapped.hidden} atomId={atomId} user={mapped['user']} permission={permission} />;
		case ToolbarElement.EVENT_STATUS_FILTER:
			return <EventStatusFilter hidden={mapped.hidden} />;
		case ToolbarElement.EVENT_TYPE_FILTER:
			return <EventTypesFilter hidden={mapped.hidden} />;
		case ToolbarElement.BRAND_FILTER:
			return <BrandFilter hidden={mapped.hidden} withoutBrandOption={mapped.withoutBrandOption ?? false}/>;
		case ToolbarElement.GRANULARITY_SELECT:
			return <GranularitySelect hidden={mapped.hidden} />;
		case ToolbarElement.PRODUCT_PER_CATEGORY:
			return <ProductPerCategoryFilter hidden={mapped.hidden} atomId={atomId} max={mapped.max} />;
		case ToolbarElement.SHELF_AUDIT_TEMPLATE_PICKER:
			return <ShelfAuditTemplatePicker hidden={mapped.hidden} />;
		case ToolbarElement.FREE_FORM_TEMPLATE_PICKER:
			return <FreeFormTemplatePicker hidden={mapped.hidden} />;
		case ToolbarElement.AVERAGE_OR_INSTANT_PCIKER:
			return <AverageOrInstantPicker hidden={mapped.hidden} disabled={mapped.disabled} />;
		case ToolbarElement.VARIATION_PICKER:
			return <VariationSelect hidden={mapped.hidden} />;
		case ToolbarElement.NEW_FORM_INSTANCE_PICKER:
			return <FormTemplateTemplatePicker hidden={mapped.hidden} />;
		case ToolbarElement.DAY_PICKER:
			return <DayPickerModal hidden={mapped.hidden} />;
		case ToolbarElement.TIME_RANGE_FILTER_2:
			return <TimeRangeFilter2 hidden={mapped.hidden} defaultValue={mapped.defaultValue} disabled={mapped.disabled} future={mapped.future} allowNull={mapped.allowNull}/>;
		case ToolbarElement.TIME_RANGE_FILTER_3:
			return <TimeRangeFilter3 hidden={mapped.hidden} defaultValue={mapped.defaultValue} allowNull={mapped.allowNull}/>;
	}
}

function MoreFilters(props: { filters?: JSX.Element[] }): JSX.Element {
	const [isOpen, setIsOpen] = React.useState(false);
	if ((props.filters == null) || props.filters.length == 0) {
		return <></>;
	}

	return <div>
		<MoreFilterToggle onClick={() => setIsOpen(!isOpen)}><Translate id="more_filters" /></MoreFilterToggle>
		<Popup isOpen={isOpen}
			onClickOut={() => setIsOpen(false)}
			popupStyle={{ animate: true, height: '100%', top: '0%' }}
			popupMode={PopupMode.Details}
			content={<div>
				{props.filters.map((f, i) => <div key={`popupFilterContent[${i}]`} style={{ margin: 20 }}>{f}</div>)}
			</div>}
		/>
	</div>;
}

type Props = {
	category: AtomCategory
	elements?: ToolbarFilterElement[]
	maxWidth?: number
	permission?: ObjectAction
};

function ToolbarFilter(props: Props): JSX.Element {
	const setToggle = useSetRecoilState(Atoggle);
	const ref = React.useRef<HTMLDivElement>(null);
	const [filters, setFilters] = React.useState<JSX.Element[] | undefined>([]);
	const [inputSearchText, setInputSearchText] = useRecoilState(AInputSearch);
	const [i, setI] = React.useState(0);
	const [oldProps, setOldProps] = React.useState<Props>();
	const [moreFilter, setMoreFilter] = React.useState<ToolbarFilterElement[]>([]);

	React.useEffect(() => {
		if (JSON.stringify(oldProps?.elements) !== JSON.stringify(props.elements)) {
			setI(0);
			const trace: ToolbarElement[] = [];
			setFilters(props.elements?.map((e, i) => {
				const mapped = typeof e === 'number' ? { kind: e } : e;
				const atomId = trace.filter(elem => elem == mapped.kind).length;
				trace.push(mapped.kind);
				return <SwitchFilters
					key={`ToolbarFilterFilter[${e}][${i}]`}
					element={e}
					atomId={atomId}
					inputSearchText={inputSearchText}
					setInputSearchText={setInputSearchText}
					permission={props.permission}
				/>;
			}));
			setMoreFilter([]);
		}
		if (oldProps?.category !== props.category) {
			setToggle(props.category);
		}
		if (oldProps?.maxWidth !== props.maxWidth) {
			setI(0);
			setMoreFilter([]);
		}
		setOldProps(props);
	}, [JSON.stringify(props)]);

	React.useEffect(() => {
		if (ref.current && props.maxWidth && ref.current.clientWidth > props.maxWidth && (props.elements != null)) {
			const new_i = i + 1;
			setMoreFilter([]);
			setI(new_i);
		}
	}, [ref.current, moreFilter, props.maxWidth, props.elements]);

	let inside;
	let in_modal;
	if (i > 0) {
		inside = filters?.slice(0, -i);
		in_modal = filters?.slice(-i);
	} else {
		inside = filters;
		in_modal = undefined;
	}

	// @ts-expect-error ref
	return <ToolbarBox innerRef={ref} zIndex={9}>
		{inside}
		<MoreFilters filters={in_modal} />
	</ToolbarBox>;
}

export default ToolbarFilter;
