import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_FOUNDATION } from '../../../config/keys';
import { PostContactParams } from '../model/Model';
import { ContactRow } from '../../../../typings/bindings/contacts/ContactRow';
import { ContactDetail } from '../../../../typings/bindings/contacts/ContactDetail';
import { FilterParameter } from '../../../components_v2/filter/pages/FilterList';
import { translateToString } from '../../../styles/global/translate';
import { ServerAdvancedFilter } from '../../client-companies/model/Model';
import { FilterId, FilterTree } from '../../../components_v2/filter/model/Model';
import { UpdatedField } from '../../../../../web-types/api';
import * as moment from 'moment';

const cookies = new Cookies();
const token = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token}`;

export async function postContact(params: PostContactParams): Promise<number> {
	try {
		const result = await axios.post(`${URL_FOUNDATION}/api/v2/client-companies/${params.client_company_id}/contacts`, params)
			.then(response => {
				return response.data;
			});
		return result;
	} catch (error) {
		console.error(error);
		return -1;
	}
}

export async function queryContact(params: object): Promise<ContactRow[]> {
	return axios.post<ContactRow[]>(`${URL_FOUNDATION}/api/v2/contacts/query`, params).then(res => res.data.map(c => ({
		...c,
		updated_at: c.updated_at ? moment.utc(c.updated_at).local().toString() : null,
		created_at: c.created_at ? moment.utc(c.created_at).local().toString() : null
	})));
}

const filterCategories = ['most_used_filters', 'contact_informations', 'contact_activity'] as const;
type FilterCategory = typeof filterCategories[number];
const getContactFitlersCategories = (filterId: FilterId): FilterCategory[] => {
	switch (filterId) {
		case 'first_name':
		case 'last_name':
		case 'client_company_id':
		case 'client_company_name':
		case 'email':
		case 'phone':
		case 'position':
			return ['contact_informations'];
		case 'owner_id':
		case 'tag_id':
			return ['most_used_filters', 'contact_informations'];
		case 'created_at':
		case 'created_by':
		case 'updated_at':
		case 'updated_by':
			return ['contact_activity'];
	}
	return [];
};

const toTranslateString = (filterId: FilterId): string => {
	switch (filterId) {
		case 'owner_id': return 'owner';
		case 'tag_id': return 'tags';
	}
	if (typeof filterId === 'string')
		return filterId;
	return '';
};

export async function getContactFilters(): Promise<FilterParameter[]> {
	try {
		const result = await axios.get<ServerAdvancedFilter[]>(`${URL_FOUNDATION}/api/v2/contacts/filters`)
			.then(response => filterCategories.map((fc): FilterParameter => ({
				category: translateToString(fc),
				filters: response.data
					.filter(companyFilter => getContactFitlersCategories(companyFilter[0]).includes(fc))
					.map(companyFilter => ({ id: companyFilter[0], name: translateToString(toTranslateString(companyFilter[0])), type: companyFilter[1] }))
			})));
		return result;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export type QueryUpdateContact = {
	owner_id?: number,
	first_name?: UpdatedField<string>,
	last_name?: UpdatedField<string>,
	client_company_id?: UpdatedField<number>,
	status_id?: number,
	position?: UpdatedField<string>,
	phone?: UpdatedField<string>,
	email?: UpdatedField<string>,
	tags?: Array<number>,
}

export async function updateContact(contactId: number, params: QueryUpdateContact): Promise<void> {
	return axios.put(`${URL_FOUNDATION}/api/v2/contacts/${contactId}`, params).then(_ => { _;});
}

export async function deleteContact(contactId: number): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/contacts/${contactId}`).then(_ => { _;});
}

export async function deleteContacts(contactIds: number[], isAllSelected: boolean, filters: FilterTree | undefined, search: string | undefined): Promise<void> {
	const params = {
		contacts: contactIds,
		all: isAllSelected,
		filters,
		search
	};
	return axios.post(`${URL_FOUNDATION}/api/v2/contacts/bulk-edit/delete`, params).then(_ => { _; });
}

export type QueryBulkEdit = {
	contacts?: number[],
	value?: string | number,
	all: boolean,
	search?: string,
	filters?: FilterTree
}

export async function bulkEditContact(key: string, body: QueryBulkEdit): Promise<void> {
	return axios.post(`${URL_FOUNDATION}/api/v2/contacts/bulk-edit/${key}`, body).then(_ => { _; });
}

export async function getContact(id: number): Promise<ContactDetail> {
	return axios.get(`${URL_FOUNDATION}/api/v2/contacts/${id}`).then(res => res.data);
}

export type ContactLinkedElementAmount = {
	amount_events: number,
	amount_opportunities: number,
	amount_documents: number,
	amount_notes: number
}

export async function getContactLinkedElementAmounts(id: number): Promise<ContactLinkedElementAmount> {
	return axios.get(`${URL_FOUNDATION}/api/v2/contacts/${id}/linked-element-counts`).then(res => res.data);

}