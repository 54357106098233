import React, {Component} from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import {Translate} from 'react-localize-redux'

class PropWebsite extends Component {
  state = {};

  render() {
    return (
      <div>
        <div className="form-group">
          <label className="control-label" htmlFor="optionSelect">
            Field Type
          </label>
          <select id="optionSelect" className="form-control">
            <option>Website</option>
          </select>
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Field Name
          </label>
          <TextAreaAutosize type="text" className="form-control" />
        </div>

        <div className="custom-control custom-checkbox">
          <input
            id="is-default-to-today"
            className="custom-control-input"
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor="is-default-to-today">
            Display field name
          </label>
        </div>

        <div className="form-group mt-3">
          <label className="control-label" htmlFor="">
            System field
          </label>
          <input type="text" className="form-control" />
        </div>

        <div className="form-group">
          <label className="control-label" htmlFor="">
            Validation
          </label>
        </div>
        <div className="custom-control custom-checkbox">
          <input
            id="is-required"
            className="custom-control-input"
            type="checkbox"
          />
          <label className="custom-control-label" htmlFor="is-required">
            <Translate id="Field required" />
          </label>
        </div>

        <div className="form-group mt-3">
          <label className="control-label" htmlFor="">
            <Translate id="Initial Value" />
          </label>
          <input type="text" className="form-control" />
        </div>
      </div>
    );
  }
}

export default PropWebsite;
