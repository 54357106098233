import * as React from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { BlueSidely, SidelyBlack } from '../../styles/global/css/Utils';
import { TypeList } from './Gallery';
import { getLeftOrRightImage } from '../map/modalLeft/ModalLeftFilter';

interface modalProps {
  setSelected: (type: TypeList) => void
  selected: string
  setIsModalOpen: (type: boolean) => void
  isModalOpen: boolean
  typeList: TypeList[]
}

const GalleryText = styled.div<{ selected: boolean }>`
	color: ${p => p.selected ? BlueSidely : ''};
	transition: color 0.1s;

	&:hover {
		color: ${BlueSidely};
	}
`;

const GalleryTiles = styled.div`
  height: 53px;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
`;

export function ModalLeft(props: modalProps): JSX.Element {
	const { selected, typeList } = props;
	return (
		<div className="filter-modal" style={{ marginTop: '52px', height: 'calc(100% - 107px)', marginLeft: -10, zIndex: 2 }}>
			<div className={props.isModalOpen ? 'box-filter-modal' : 'd-none'} style={{ width: '261.2px' }}>
				<h5 className="p-3" style={{ color: SidelyBlack }}>
					<Translate id="field_of_reporting" />
				</h5>
				<Nav tabs vertical className="nav-pin">
					{...typeList.map(type =>
						<GalleryTiles key={`Typelist[${type}]`}>
							<NavItem key={type} >
								<NavLink onClick={_e => selected !== type && props.setSelected(type)}>
									{selected == type && <div className="active-block" />}
									<GalleryText selected={selected == type}>
										<Translate id={'tool_bar.' + type} />
									</GalleryText>
								</NavLink>
							</NavItem>
						</GalleryTiles>
					)}
				</Nav>
			</div>
			<button
				type="button"
				className="btn btn-filter-modal"
				onClick={() => props.setIsModalOpen(!props.isModalOpen)}
			>
				<img
					src={getLeftOrRightImage(props.isModalOpen)}
					alt=""
				/>
			</button>
		</div>
	);
}
