import * as React from 'react';
import { loadingState } from '../../../components_v2/models';
import axios from 'axios';
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { getLang, queryConstructor } from '../utils';
import { getData } from './actions';
import { reportingAction, reportingState } from '../Reducer';
import PageLoader from '../../../components/PageLoader';
import { getTranslate } from 'react-localize-redux';
import storeLang from '../../../helpers/storeLang';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { useRecoilValue } from 'recoil';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import ToolbarFilter, { ToolbarElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import RefreshButton from '../../../myToolbar/RefreshButton';

import { useWindowDimensions } from '../../../components_v2/utils';
import { BorderColor } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export function OrdersEvolution(props: {
	setToolBarState: (value: ToolbarState) => void
	state: reportingState
	dispatch: (action: reportingAction) => void
	width: number
	height: number
}) {
	const [loadingState, setLoadingState] = React.useState<loadingState>(null);
	const [data, setData] = React.useState<any>({});

	const filteredTags = useRecoilValue(ATagFilter);
	const pickedUsers = useRecoilValue(AUserPicker(0));
	const { width } = useWindowDimensions();

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 120}
					category={AtomCategory.GLOBAL}
					elements={[
						{
							kind: ToolbarElement.USER_PICKER
						},
						ToolbarElement.TAG_FILTER
					]}
				/>
				<RefreshButton onFilter={() => setLoadingState(null)} isLoading={loadingState == 'loading'}/>
			</FlexDiv>,
			bottomRightToolbarComponent: <></>
		});
	}, [loadingState, width]);

	React.useEffect(() => {
		if (!loadingState && (filteredTags != null) && (pickedUsers != null)) {
			try {
				if (!loadingState) {
					setLoadingState('loading');
				}
				if (props.state.cancelTokenSource) {
					props.state.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				getData(queryConstructor({
					tags: filteredTags,
					userIdList: pickedUsers?.[0]
				}, undefined), cancelTokenSource.token)
					.then(res => {
						// set default left filter - all -
						props.state.filters.PresencePerUsers = res.data.names;
						props.dispatch({
							type: 'SET_FILTERS',
							value: { ...props.state.filters }
						});

						// set list of users
						props.dispatch({
							type: 'SET_USERS',
							value: res.data.names
						});
						setData(res.data);
						if (loadingState != 'loaded') {
							setLoadingState('loaded');
						}
						props.dispatch({ type: 'SET_IS_LOADING', value: false });
					})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.log('Canceling job', e);
						} else {
							console.log(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState('error');
					});
			} catch (e) {
				console.log(e);
				setLoadingState('error');
			}
		}
	}, [loadingState, props, filteredTags, pickedUsers]);

	const datasets: any[] = [];
	const translate = getTranslate(storeLang.getState().localize);

	if (data.datasets != null && data.datasets != undefined && data.datasets.length >= 5) {
		datasets.push({
			label: translate(`shelf_audit.legend.${data.datasets[0].label}`),
			data: data.datasets[0].data,
			borderColor: 'rgb(255, 99, 132)',
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
			lineTension: 0.1,
			borderWidth: 1
		});
		datasets.push({
			label: translate(`shelf_audit.legend.${data.datasets[1].label}`),
			data: data.datasets[1].data,
			borderColor: 'rgb(255, 99, 132)',
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
			lineTension: 0.1,
			borderWidth: 1
		});
		datasets.push({
			label: translate(`shelf_audit.legend.${data.datasets[2].label}`),
			data: data.datasets[2].data,
			borderColor: 'rgb(53, 162, 235)',
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
			lineTension: 0.1,
			borderWidth: 1
		});
		datasets.push({
			label: translate(`shelf_audit.legend.${data.datasets[3].label}`),
			data: data.datasets[3].data,
			borderColor: 'rgb(53, 162, 235)',
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
			lineTension: 0.1,
			borderWidth: 1
		});
		datasets.push({
			label: translate(`shelf_audit.legend.${data.datasets[4].label}`),
			data: data.datasets[4].data,
			borderColor: 'rgb(20, 20, 20)',
			backgroundColor: 'rgba(20, 20, 20, 0.5)',
			lineTension: 0.1,
			borderWidth: 1
		});
	}

	let labels = [];
	if (data.labels != null && data.labels != undefined && data.labels.length > 0) {
		labels = data.labels.map(label => {
			const date = new Date(label);
			const now = new Date(Date.now());
			if (date.getDay() == now.getDay() && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()) {
				return translate('shelf_audit.sections.misc.today').toString();
			} else {
				return date.toLocaleString(getLang(), { month: 'short', year: 'numeric' });
			}
		});
	}
	const formated_data = {
		labels,
		datasets
	};

	const options = {
		responsive: true,
		plugins: {
			datalabels: {
				display: function() {
					return false;
				},
			},
			legend: {
				position: 'bottom' as const
			},
			title: {
				display: true
				// text: 'Chart.js Line Chart'
			}
		},
		scales: {
			y: {
				beginAtZero: true
			}
		}
	};

	return (
		<div style={{ fontSize: '12px' }}>
			{(loadingState == 'loading' || !loadingState) && <div className='list-loader'><PageLoader/></div>}
			{loadingState == 'error' && <p>Error fetching data</p>}
			{loadingState == 'loaded' &&
				<div style={{
					backgroundColor: '#FFFFFF',
					// width: `${props.width - 30}px`,
					height: `calc(${props.height}px - 130px)`,
					border: `1px solid ${BorderColor}`
				}}>
					<Line options={options} data={formated_data}/>
				</div>
			}
		</div>
	);
}
