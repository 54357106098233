/* eslint-disable array-callback-return */
/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import RenderSelectInput from './RenderSelectInputModal';
import {selectClientStatus} from '../../store/actions/select.action';
import {Translate} from 'react-localize-redux';

class SelectStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: []
    };
  }

  componentDidMount() {
    const { selectClientStatus } = this.props;
    selectClientStatus();
  }

  componentWillReceiveProps(nextProps) {
    const { data, route } = this.props;
    if (data !== nextProps.data) {
      const opt = [];
      if (route && route.path === '/companies') {
        opt.push({
          value: null,
          label: <Translate id="all" />
        });
      }
      nextProps.data.map(data =>
        opt.push({
          value: data.id,
          label: data.name
        })
      );
      this.setState({ opt });
    }
  }

  render() {
    const { opt } = this.state;
    const {
      validate,
      label,
      requiredStar,
      name,
      placeholder,
      disabled
    } = this.props;
    return (
      <Field
        name={name}
        component={RenderSelectInput}
        label={label}
        requiredStar={requiredStar}
        options={opt}
        id="inputOwner"
        disabled={disabled}
        placeholder={placeholder}
        validate={validate}
        isClearable={false}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.select.clientStatus
});

export default connect(
  mapStateToProps,
  {
    selectClientStatus
  }
)(SelectStatus);
