import axios from 'axios';
import { Cookies } from 'react-cookie';
import { URL_API, URL_FOUNDATION } from '../../../config/keys';

const cookies = new Cookies();

export async function getAllProduct() {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return await axios({
		method: 'GET',
		url: `${URL_API}/api/products/all`
	});
}

export type CategoryAndProducts = {
	id?: number,
	name?: string,
	description?: string,
	products: { id: number, name: string, summary?: string, checked?: boolean }[],
	children: CategoryAndProducts[]
}

export async function getProductPerCategories(): Promise<CategoryAndProducts[]> {
	return axios.get<CategoryAndProducts[]>(`${URL_FOUNDATION}/api/v2/products/per-assortments`).then(res => res.data.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0));
}