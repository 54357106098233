import axios from 'axios';
import { Cookies } from 'react-cookie';
import swal from 'sweetalert2';
// import iziToast from 'izitoast';
import { URL_API } from '../../config/keys';
import { logoutUser } from './auth.action';

const cookies = new Cookies();

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';

export const CHANGE_LOADING = 'CHANGE_LOADING';

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_ERROR = 'FETCH_EVENT_ERROR';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';

export const DETAIL_EVENT_REQUEST = 'DETAIL_EVENT_REQUEST';
export const DETAIL_EVENT_SUCCESS = 'DETAIL_EVENT_SUCCESS';
export const DETAIL_EVENT_ERROR = 'DETAIL_EVENT_ERROR';

export const DELETE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';

export const IMPORT_EXCEL_REQUEST = 'IMPORT_EXCEL_REQUEST';
export const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
export const IMPORT_EXCEL_ERROR = 'IMPORT_EXCEL_ERROR';

export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';
export const GET_DATE = 'GET_DATE';

export const SORT_DATA = 'SORT_DATA';
export const GET_EVENT_BY_TYPE = 'GET_EVENT_BY_TYPE';

const PATH = `${URL_API}/api`;

export function createData(data, id, user) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return async dispatch => {
		dispatch({
			type: CREATE_EVENT_REQUEST,
			loading: true
		});
		try {
			const response = await axios({
				method: 'POST',
				url: `${PATH}/events`,
				headers: { 'Content-Type': 'multipart/form-data' },
				data
			});
			dispatch({
				type: CREATE_EVENT_SUCCESS,
				payload: response.data
			});
			window.location.href = '/calendar';
		} catch (error) {
			dispatch({ type: CREATE_EVENT_ERROR, error: error.response });
			swal.fire('Error!', error.response.data.errors, 'error');
			if (error.response.status === 401) {
				dispatch(logoutUser());
			}
		}
	};
}

export function fetchData(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: FETCH_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/events`,
			params: data
		})
			.then(
				response => {
					dispatch({
						type: FETCH_EVENT_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: FETCH_EVENT_ERROR,
						error: error.response
					});
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: FETCH_EVENT_ERROR,
					error
				});

				throw error;
			});
	};
}

export function updateData(data, id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: UPDATE_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'PUT',
			url: `${PATH}/events/id/${id}`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: UPDATE_EVENT_SUCCESS,
						payload: response.data
					});
					// window.location.href = `/event`;
				},
				error => {
					dispatch({
						type: UPDATE_EVENT_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: CREATE_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function deleteData(id, refreshData) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	const paramsData = JSON.parse(localStorage.getItem('params'));

	return dispatch => {
		dispatch({
			type: DELETE_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'DELETE',
			url: `${PATH}/events/${id}`
		}).then(
			() => {
				// window.location.href = `/event`;
				refreshData();
				dispatch({
					type: CHANGE_LOADING,
					loading: false
				});
			},
			error => {
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function bulkDelete(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		return axios({
			method: 'DELETE',
			url: `${PATH}/events`,
			headers: {
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/json'
			},
			data
		}).then(
			() => {
				dispatch(fetchData());
			},
			error => {
				swal.fire('Error!', error.response.data.errors, 'error');
				if (error.response.status === 401) {
					dispatch(logoutUser());
				}
			}
		);
	};
}

export function detailData(id) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch => {
		dispatch({
			type: DETAIL_EVENT_REQUEST,
			loading: true
		});
		return axios({
			method: 'get',
			url: `${PATH}/events/id/${id}`
		})
			.then(
				response => {
					dispatch({
						type: DETAIL_EVENT_SUCCESS,
						payload: response.data
					});
				},
				error => {
					dispatch({
						type: DETAIL_EVENT_ERROR,
						error: error.response
					});
				}
			)
			.catch(error => {
				dispatch({
					type: DETAIL_EVENT_ERROR,
					error
				});
				throw error;
			});
	};
}

export function exportExcel(data, path, type) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;

	return dispatch =>
	// dispatch({ type: FETCH_COMPANIES_REQUEST, loading: true });
		axios({
			method: 'get',
			responseType: 'blob',
			url: `${PATH}/events/${path}`,
			params: data
		})
			.then(
				response => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', type);
					document.body.appendChild(link);
					link.click();
				},
				error => {
					// dispatch({ type: FETCH_COMPANIES_ERROR, error: error.response });
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				throw error;
			});
}

export function importEventExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return axios({
		method: 'POST',
		url: `${PATH}/events/importExcel`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data
	});
}

export function importExcel(data) {
	const token = cookies.get('id_token') || null;
	axios.defaults.headers.common.Authorization = `${token}`;
	return dispatch => {
		dispatch({
			type: IMPORT_EXCEL_REQUEST,
			loading: true
		});
		return axios({
			method: 'POST',
			url: `${PATH}/events/importExcel`,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data
		})
			.then(
				response => {
					dispatch({
						type: IMPORT_EXCEL_SUCCESS,
						payload: response.data
					});
					window.location.href = '/calendar';
				},
				error => {
					dispatch({
						type: IMPORT_EXCEL_ERROR,
						error: error.response
					});
					swal.fire('Error!', error.response.data.errors, 'error');
					if (error.response.status === 401) {
						dispatch(logoutUser());
					}
				}
			)
			.catch(error => {
				dispatch({
					type: IMPORT_EXCEL_ERROR,
					error
				});
				throw error;
			});
	};
}

export const setActiveFilter = data => async dispatch => {
	dispatch({
		type: SET_ACTIVE_FILTER,
		payload: data
	});
};

export const sortData = data => async dispatch => {
	dispatch({
		type: SORT_DATA,
		payload: data
	});
};

export const getDate = date => async dispatch => {
	dispatch({
		type: GET_DATE,
		payload: date
	});
};