import * as React from 'react';
import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import { SidelyBlack } from '../../styles/global/css/Utils';
import styled from 'styled-components';

const ToolTipContainer = styled.div<{ night?: boolean, up?: boolean, cluster?: boolean }>`
	position: absolute;
	z-index: 1;
	width: fit-content;
	padding: 7px 15px;
	${p => p.night ? `background-color:${SidelyBlack}; color: white;` : ''};
	transform: ${p => p.up ? 'translate(calc(-50% - 3px), -65px)' : `translate(calc(-100% - 25px), ${p.cluster ? '-50%' : 'calc(-50% - 13px)'})`};
	font-size: 11px; 
	&:after {
		content: " ";
		position: absolute;
		${p => p.up ? 'top' : 'left'}: 100%;
		${p => p.up ? 'left' : 'top'}: 50%;
		margin-${p => p.up ? 'left' : 'top'}: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: ${p => p.up ? p.night ? SidelyBlack : 'white' : 'transparent' } transparent transparent ${p => p.up ? 'transparent' : p.night ? SidelyBlack : 'white'};
	}
`;

export default function MapToolTip(props: {
	data?: DataEntry[],
	instore?: boolean,
	title?: string,
	night?: boolean,
	up?: boolean,
	children?: React.ReactNode,
	cluster?: boolean,
	subTitle?: string
}) {
	const { data, instore, title, night, subTitle } = props;
	return (
		(<ToolTipContainer className='description-tooltips trash' night={night} up={props.up} cluster={props.cluster}>
			{title && <span className='font-weight-bold' style={{ marginBottom: '2px', whiteSpace: 'nowrap' }}>
				{title}
			</span>}
			<div>
				{subTitle}
			</div>
			<table>
				<tbody>
					{data?.map((d, i) =>
						<tr key={`mapToolTipValue${i}`}>
							<td style={{ textAlign: 'right', padding: '0px', paddingRight: 10, whiteSpace: 'nowrap' }}>
								{d.label}
							</td>
							<td style={{ textAlign: 'center', padding: '0px', paddingRight: 10 }}>
								<span className='dot' style={{ backgroundColor: d.color }} />
							</td>
							<td style={{ textAlign: 'left', padding: '0px' }}>
								<span>{instore ? `${d.value.toFixed(2).replace(/(\.0+|0+)$/, '')}%` : d.value}</span>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{props.children}
		</ToolTipContainer>)
	);
}
