import * as React from 'react';
import { getTranslate } from 'react-localize-redux';
import FilterRadioList from '../../../../components_v2/filterList/FilterRadioList';
import { FilterRadioData } from '../../../../components_v2/filterList/model/Model';
import storeLang from '../../../../helpers/storeLang';
import { getAssortments } from '../../data/Data';
import { Assortment } from '../../model/Model';
import { Container } from '../style/ModalLeftStyle';

function ModalLeftContent(props: {
  onChangeAssortment?: (value: Assortment | null) => void
}) {
	const translate = getTranslate(storeLang.getState().localize);
	const [assortments, setAssortments] = React.useState<Assortment[]>([]);

	React.useEffect(() => {
		getAssortments().then(response => {
			setAssortments(response);
		});
	}, []);

	function getAssortmentsData(): FilterRadioData[] {
		return assortments
			.map(a => {
				return {
					label: a.name,
					value: a
				} as FilterRadioData;
			});
	}

	return (
		<Container>
			<FilterRadioList label={translate('orders.selectProduct.modalleft.productPrice').toString()} allButton allButtonLabel={translate('orders.selectProduct.modalleft.outOfAssortment').toString()} data={getAssortmentsData()} onChange={(v) => {
				(props.onChangeAssortment != null) && props.onChangeAssortment(v.value !== 'all' ? v.value : null);
			}} />
		</Container>
	);
}

export default ModalLeftContent;
