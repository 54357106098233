import {
	CREATE_GROUP_ERROR,
	CREATE_GROUP_REQUEST,
	CREATE_GROUP_SUCCESS,
	DELETE_GROUP_ERROR,
	DELETE_GROUP_REQUEST,
	DELETE_GROUP_SUCCESS,
	DELETE_MEMBER_ERROR,
	DELETE_MEMBER_REQUEST,
	DELETE_MEMBER_SUCCESS,
	DELETE_USER_ERROR,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	GET_ALL_GROUP_GRAPH_ERROR,
	GET_ALL_GROUP_GRAPH_REQUEST,
	GET_ALL_GROUP_GRAPH_SUCCESS,
	GET_ALL_USER_GRAPH_ERROR,
	GET_ALL_USER_GRAPH_REQUEST,
	GET_ALL_USER_GRAPH_SUCCESS,
	GET_DATA_TARGET_ERROR,
	GET_DATA_TARGET_REQUEST,
	GET_DATA_TARGET_SUCCESS,
	GET_GROUP_GRAPH_ERROR,
	GET_GROUP_GRAPH_REQUEST,
	GET_GROUP_GRAPH_SUCCESS,
	GET_OVERVIEW_GRAPH_ERROR,
	GET_OVERVIEW_GRAPH_REQUEST,
	GET_OVERVIEW_GRAPH_SUCCESS,
	GET_OVERVIEW_OPORTUNITIES_ERROR,
	GET_OVERVIEW_OPORTUNITIES_REQUEST,
	GET_OVERVIEW_OPORTUNITIES_SUCCESS,
	GET_USER_GRAPH_ERROR,
	GET_USER_GRAPH_REQUEST,
	GET_USER_GRAPH_SUCCESS,
	UPDATE_GROUP_ERROR,
	UPDATE_GROUP_REQUEST,
	UPDATE_GROUP_SUCCESS
} from '../actions/selesTeam.action';

const initState = {
	isLoading: false,
	isLoadingDetail: false,
	current: {
		name: 'username'
	},
	data: [],
	oportunities: [],
	graph: [],
	userGraph: [],
	allUserGraph: [],
	allGroupGraph: [],
	groupGraph: [],
	chatData: {},
	chatContent: [],
	chatSuccess: {},
	search: '',
	dataDetail: [],
	detail: [],
	detailGroup: []
};

export default function targetManagReducer(state = initState, action) {
	switch (action.type) {
		case GET_DATA_TARGET_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_DATA_TARGET_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				isLoading: false
			};
		case GET_DATA_TARGET_ERROR:
			return {
				...state,
				isLoading: false
			};
		case GET_OVERVIEW_OPORTUNITIES_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_OVERVIEW_OPORTUNITIES_SUCCESS:
			return {
				...state,
				oportunities: action.payload.data,
				isLoading: false
			};
		case GET_OVERVIEW_OPORTUNITIES_ERROR:
			return {
				...state,
				isLoading: false
			};
		case GET_OVERVIEW_GRAPH_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_OVERVIEW_GRAPH_SUCCESS:
			return {
				...state,
				graph: action.payload.data,
				isLoading: false
			};
		case GET_OVERVIEW_GRAPH_ERROR:
			return {
				...state,
				isLoading: false
			};
		case CREATE_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case CREATE_GROUP_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoading: false
			};

		case CREATE_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};
		case UPDATE_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_GROUP_SUCCESS:
			return {
				...state,
				dataDetail: action.payload.data,
				isLoading: false
			};

		case UPDATE_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};

		case DELETE_GROUP_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case DELETE_GROUP_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case DELETE_GROUP_ERROR:
			return {
				...state,
				isLoading: false
			};
		case DELETE_USER_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case DELETE_USER_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case DELETE_USER_ERROR:
			return {
				...state,
				isLoading: false
			};
		case DELETE_MEMBER_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case DELETE_MEMBER_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case DELETE_MEMBER_ERROR:
			return {
				...state,
				isLoading: false
			};
		case GET_USER_GRAPH_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_USER_GRAPH_SUCCESS:
			return {
				...state,
				userGraph: action.payload.data,
				isLoading: false
			};
		case GET_USER_GRAPH_ERROR:
			return {
				...state,
				// userGraph: null,
				isLoading: false
			};
		case GET_ALL_USER_GRAPH_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_ALL_USER_GRAPH_SUCCESS:
			return {
				...state,
				allUserGraph: action.payload.data,
				isLoading: false
			};
		case GET_ALL_USER_GRAPH_ERROR:
			return {
				...state,
				// userGraph: null,
				isLoading: false
			};
		case GET_ALL_GROUP_GRAPH_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_ALL_GROUP_GRAPH_SUCCESS:
			return {
				...state,
				allGroupGraph: action.payload.data,
				isLoading: false
			};
		case GET_ALL_GROUP_GRAPH_ERROR:
			return {
				...state,
				// userGraph: null,
				isLoading: false
			};
		case GET_GROUP_GRAPH_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case GET_GROUP_GRAPH_SUCCESS:
			return {
				...state,
				groupGraph: action.payload.data,
				isLoading: false
			};
		case GET_GROUP_GRAPH_ERROR:
			return {
				...state,
				// userGraph: null,
				isLoading: false
			};
		default:
			return state;
	}
}
