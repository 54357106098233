import * as moment from 'moment';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { BlueSidely, BorderColor, DarkGreySidely } from '../../styles/global/css/Utils';
import { Period, Promotion } from './model';
import { DefaultText, DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { Translate } from '../../styles/global/translate';
import { useIsInViewport } from '../../components_v2/table/bodyCell';
import CheverontWhite from 'images/icon/cheveront_white.svg';
import { Open } from '../../styles/global/css/Open';
import { Collapse } from 'reactstrap';
import { FlexDiv } from '../products/style';
import { PeriodNavigator, PeriodNavigatorType, PeriodView, PeriodViewSelector } from '../calendar/Calendar';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { PartialOpen } from '../client-companies/style/Style';
import eye from 'images/icons/company/eye.svg';

const PERIOD_ROW_HEIGHT = 45;
const HEIGHT_TRANSITION_TIME = 0.5;

const DAY_WIDTH = 24 * 2;
const PADDING_DAYS = 200;
const SCROLL_PADDING = (PADDING_DAYS / 3) * DAY_WIDTH;

const HEADER_HEIGHT = 70;
const OPTIONS_HEIGHT = 26;

const GanttWrapper = styled.div<{ height?: string }>`
	width: 100%;
	height: ${p => p.height ?? '100%'};
	overflow: hidden;
	display: flex;
	flex-flow: column;
	background-color: white;
	position: relative;
`;

const HeaderSyte = css`
	height: ${HEADER_HEIGHT}px;
	width: 100%;
	display: flex;
	align-items: end;
	flex-shrink: 0;
	border-bottom: 1px solid ${BorderColor};
`;

const HeaderWrapper = styled.div`
	${HeaderSyte}
	overflow: hidden;
	position: relative;
`;

const BodyWrapper = styled.div`
	overflow: auto;
	position: relative;
	height: 100%;
`;

const TimeLine = styled.div<{ left: number, bodyHeight: number }>`
	position absolute;
	border-left: 1px solid ${BlueSidely};
	left: ${p => p.left}px;
	height: max(100%, ${p => p.bodyHeight}px);
	top: 0;
`;

const DataContainerStyle = styled.div<{ open?: boolean, periodsLength: number }>`
	height: ${p => p.open ? PERIOD_ROW_HEIGHT * (p.periodsLength + 1) : PERIOD_ROW_HEIGHT}px;
	transition: height ${HEIGHT_TRANSITION_TIME}s;
`;

const TitleLeftPromotion = styled(DataContainerStyle)`
	${DefaultText}
	display: flex;
	align-items: start;
	width: 100%;
	padding: 0 15px;
`;

const TitleLeftPromotionTextWrapper = styled.div<{ child?: boolean }>`
	color: ${DarkGreySidely};
	height: ${PERIOD_ROW_HEIGHT}px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	${({ child }) => child ? `
		font-size: 12px;
		padding-left: 10px;
		font-weight: 400;
	` : `
		font-weight: 500;
		position: sticky;
		top: 0px;
		background-color: white;
	`}
`;

const TitleLeftPromotionTextCss = css`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
`; 

const TitleLeftPromotionText = styled.div`
	${TitleLeftPromotionTextCss}
`;


const PopupLeftDiv = styled.div`
	position: absolute;
	width: 250px;
	background-color: white;
	height: 100%;
	border-right: 1px solid ${BorderColor};
	transition: width ${HEIGHT_TRANSITION_TIME}s;
	display: flex;
	flex-flow: column;
`;

const TitleLeft = styled.h4`
	overflow: hidden;
	margin: 0;
	transition: padding 0.1s;
`;

const PopupLeftTitleContainer = styled.div`
	${HeaderSyte}
	height: ${HEADER_HEIGHT + OPTIONS_HEIGHT}px;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	padding: 0 15px;
`;

const TitleLeftPromotionWrapper = styled.div`
	height: 100%;
	overflow: hidden;
`;


const DataContainerDiv = styled(DataContainerStyle)`
	display: flex;
	position: relative;
`;

const EventWrapper = styled.div<{ left: number, width: number, color?: string, i: number }>`
	position: absolute;
	height: calc(${PERIOD_ROW_HEIGHT}px * 0.75);
	top: calc(${p => p.i * PERIOD_ROW_HEIGHT}px + ${PERIOD_ROW_HEIGHT}px * 0.5);
	transition: top ${HEIGHT_TRANSITION_TIME}s;
	translate: 0 -50%;
	left: ${p => p.left}px;
	width: ${p => p.width}px;
	display: flex;
	align-items: center;
	background-color: ${p => p.color ?? BlueSidely};
	border-radius: 10px;
	opacity: 0.75;
	cursor: pointer;
`;

const EventArrow = styled.div<{ left?: boolean, i: number, visible: boolean }>`
	position: fixed;
	width: 18px;
	height: 18px;
	transform: scale(${({ visible }) => visible ? '1, 1' : '0, 0'});
	translate: -5px calc(${p => p.i * PERIOD_ROW_HEIGHT}px + 13px);
	transition: translate ${HEIGHT_TRANSITION_TIME}s, transform 0.25s;
	cursor: pointer;
	${({ left }) => left ? 'left: 340px' : 'right: 0px'};
	z-index: 1;
	opacity: 0.7;
	background: url('${CheverontWhite}');
	background-color: #a3b5ce;
	background-repeat: no-repeat;
	background-position: center;
	rotate: ${({ left }) => left ? '' : '-'}90deg;
	border-radius: 3px;
	background-size: 10px;
`;

const EventText = styled.div`
	${DefaultText}
	position: sticky;
	left: 250px;
	transition: left ${HEIGHT_TRANSITION_TIME}s;
	color: black;
	padding: 0 15px;
	white-space: nowrap;
`;

const BackgroundDiv = styled.div<{ height: number }>`
	position: absolute;
	display: flex;
	height: max(100%, ${p => p.height}px);
	transition: height ${HEIGHT_TRANSITION_TIME}s;
`;

const HeaderContainerDiv = styled.div<{ weekEnd?: boolean, dayDistance: number, hasGap: boolean }>`
	width: ${p => p.dayDistance < 1 ? DAY_WIDTH / p.dayDistance : DAY_WIDTH}px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	${p => p.weekEnd ? `background-color: ${BorderColor};` : ''}
	height: ${DAY_WIDTH}px;
	${p => p.hasGap ? 'gap: 0.5em;' : ''}
`;

const BodyContainerDiv = styled(HeaderContainerDiv)<{ dayDistance: number }>`
	height: 100%;
	width: ${p => DAY_WIDTH / p.dayDistance}px;
`;

const InnerHeaderDiv = styled.div<{ today?: boolean }>`
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	${p => p.today ? `
		border-radius: 50%;
		background-color: ${BlueSidely};
		color: white;
	` : ''}
`;

const HeaderContainerWrapper = styled.div<{ dayDistance: number }>`
	display: flex;
	flex-flow: column;
	height: 100%;
	width: ${p => p.dayDistance < 1 ? DAY_WIDTH / p.dayDistance : DAY_WIDTH}px;
	justify-content: flex-end;
`;

const MonthDiv = styled(DefaultTextDiv)<{ dayDistance: number, currentDay: number }>`
	white-space: nowrap;
	height: 25px;
	display: flex;
	padding: 7px 10px;
	position: absolute;
	height: 100%;
	${p => p.dayDistance > 1 ? '' : `border-left: 1px solid ${BorderColor};`}
	translate: calc((-${DAY_WIDTH}px / ${p => p.dayDistance}) * ${p => p.currentDay});
`;

const PeriodSelectorWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	background: white;
	z-index: 1;
	translate: -50% 100%;
	left: 50%;
	bottom: 100%;
	// box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
`;

const PeriodNavigatorWrapper = styled(PeriodSelectorWrapper)`
	left: calc(100% - 15px);
	translate: -100% 100%;
`;

const HourDiv = styled(InnerHeaderDiv)`
	width: ${DAY_WIDTH}px;
`;

const OptionsWrapper = styled.div`
	height: ${OPTIONS_HEIGHT}px;
	flex-shrink: 0;
`;

const PERIOD_VIEW_MAPPING: [PeriodView, number][] = [['day', 1 / 24], ['week', 0.5], ['month', 1], ['quarter', 3], ['year', 10]];

export default function GanttChart(props: {
	promotions: Promotion[],
	onEventClick?: (p: { promotion: Promotion, mode: PopupMode }) => void | undefined,
	onNewEvent?: () => void,
	height?: string
}) {
	const [date, setDate] = React.useState<moment.Moment>(moment());
	const [dayDistance, setDayDistance] = React.useState<number>(1);
	const dates: moment.Moment[] = [];
	const currentDate = moment(date).subtract(PADDING_DAYS * dayDistance, 'd');
	const endDate = moment(date).add(PADDING_DAYS * dayDistance, 'd');
	const [, setNextDateScroll] = React.useState<boolean>(true);
	const [openIds, setOpenIds] = React.useState<boolean[]>([]);
	while (currentDate <= endDate) {
		dates.push(moment(currentDate));
		currentDate.add(1, 'day');
	}
	const bodyRef = React.useRef<HTMLDivElement>();
	const headerRef = React.useRef<HTMLDivElement>();
	const popupRef = React.useRef<HTMLDivElement>();
	const dateRef = React.useRef<HTMLDivElement>();
	const setRef = React.useCallback((ref: React.MutableRefObject<HTMLDivElement | undefined>) => (node: HTMLDivElement) => {
		if (!node) return;
		ref.current = node;
	}, []);

	const onPeriodScroll = React.useCallback(scroll => {
		if (!headerRef.current || !bodyRef.current) return;
		headerRef.current.scrollLeft = scroll.target.scrollLeft;
		if (popupRef.current) {
			popupRef.current.scrollTop = scroll.target.scrollTop;
		}
		if (scroll.target.scrollLeft < SCROLL_PADDING) {
			setDate(date => {
				const newDate = moment(date);
				newDate.subtract(PADDING_DAYS * dayDistance, 'd');
				if (!headerRef.current || !bodyRef.current) return newDate;
				headerRef.current.scrollLeft = scroll.target.scrollLeft + DAY_WIDTH * PADDING_DAYS;
				bodyRef.current.scrollLeft = scroll.target.scrollLeft + DAY_WIDTH * PADDING_DAYS;
				return newDate;
			});
		} else if (scroll.target.scrollLeft > (PADDING_DAYS * 2 + 1) * DAY_WIDTH - 2 * SCROLL_PADDING) {
			setDate(date => {
				const newDate = moment(date);
				newDate.add(PADDING_DAYS * dayDistance, 'd');
				if (!headerRef.current || !bodyRef.current) return newDate;
				headerRef.current.scrollLeft = scroll.target.scrollLeft - DAY_WIDTH * PADDING_DAYS;
				bodyRef.current.scrollLeft = scroll.target.scrollLeft - DAY_WIDTH * PADDING_DAYS;
				return newDate;
			});
		}
	}, [dayDistance]);
	const today = moment().format('L');
	const bodyHeight = props.promotions.reduce((acc, p) => acc + (openIds[p.id] ? p.periods.length + 1 : 1), 0) * PERIOD_ROW_HEIGHT;

	const onView = React.useCallback((view: PeriodView) => {
		setDayDistance(() => PERIOD_VIEW_MAPPING.find(([v, _]) => v === view)?.[1] ?? 1);
	}, []);
	const onNavigate = React.useCallback((period: PeriodNavigatorType) => {
		const test = PERIOD_VIEW_MAPPING.find(([_, p]) => p === dayDistance)?.[0];
		if (!test) return;
		switch (period) {
			case 'next': return setDate(date => {
				const newDate = moment(date);
				newDate.add(1, test);
				return newDate;
			});
			case 'previous': return setDate(date => {
				const newDate = moment(date);
				newDate.subtract(1, test);
				return newDate;
			});
			case 'today': return setDate(date => {
				if (date.format('L') === moment().format('L')) {
					dateRef.current?.scrollIntoView({
						inline: 'center'
					});
				} else {
					setNextDateScroll(true);
				}
				return moment();
			});
		}
	}, [dayDistance]);

	return <GanttWrapper height={props.height}>
		<OptionsWrapper>
			<PeriodSelectorWrapper>
				<PeriodViewSelector views={PERIOD_VIEW_MAPPING.map(p => p[0])} onView={onView} selectedView={PERIOD_VIEW_MAPPING.find(([_, d]) => d === dayDistance)?.[0]}/>
			</PeriodSelectorWrapper>
			<PeriodNavigatorWrapper>
				<PeriodNavigator onNavigate={onNavigate}/>
			</PeriodNavigatorWrapper>
		</OptionsWrapper>
		<HeaderWrapper innerRef={setRef(headerRef)}>
			{dates.map((d, i) => <HeaderContainer date={d} key={`date[${d.toISOString()}]`} today={today} i={i} dayDistance={dayDistance}/>)}
		</HeaderWrapper>
		<BodyWrapper onScroll={onPeriodScroll} innerRef={setRef(bodyRef)}>
			<Background dates={dates} bodyHeight={bodyHeight} centralDate={date} setNextDateScroll={setNextDateScroll} dayDistance={dayDistance} setRef={setRef(dateRef)}/>
			{props.promotions.map(d => <BodyContainer
				dayDistance={dayDistance}
				dataOpen={openIds[d.id]}
				date={date}
				data={d}
				key={`data[${d.id}]`}
				dates={dates}
				onEventClick={props.onEventClick}
				onEventScroll={date => {
					setDate(date);
					setNextDateScroll(true);
				}}
			/>)}
			<CurrentTimeLine dates={dates} bodyHeight={bodyHeight} dayDistance={dayDistance}/>
		</BodyWrapper>
		<PopupLeft
			promotions={props.promotions}
			onNewEvent={props.onNewEvent}
			onEventClick={props.onEventClick}
			setRef={setRef(popupRef)}
			openIds={openIds}
			setOpenIds={setOpenIds}
			onEventScroll={date => {
				setDate(date);
				setNextDateScroll(true);
			}}
		/>
	</GanttWrapper>;
}

function CurrentTimeLine(props: { dates: moment.Moment[], bodyHeight: number, dayDistance: number }) {
	const currentTime = moment();
	const start = moment(props.dates[0]).startOf('day');
	const end = props.dates[props.dates.length - 1];
	if (currentTime > end || currentTime < start) return <></>;
	const hourWidth = ((props.dayDistance < 1 ? DAY_WIDTH / props.dayDistance : DAY_WIDTH) / 24);
	const left = currentTime.diff(start, 'd') * (DAY_WIDTH / props.dayDistance) + hourWidth * (currentTime.hour() - 1) + (hourWidth / 60) * currentTime.minute();
	return <TimeLine left={left} bodyHeight={props.bodyHeight}/>;
}

function PopupLeft(props: {
	promotions: Promotion[],
	onNewEvent?: () => void,
	onEventClick?: (p: { promotion: Promotion, mode: PopupMode }) => void | undefined,
	setRef: (node) => void,
	openIds: boolean[],
	setOpenIds: React.Dispatch<React.SetStateAction<boolean[]>>,
	onEventScroll: (start: moment.Moment) => void,
}) {

	const isAllOpen = props.promotions.every(p => props.openIds[p.id]);
	return <PopupLeftDiv>
		<PopupLeftTitleContainer>
			<TitleLeft>
				<Translate id='campagnes' />
			</TitleLeft>
			<Open isOpen={isAllOpen} onClick={() => {
				props.promotions.forEach(p => props.openIds[p.id] = !isAllOpen);
				props.setOpenIds(o => [...o]);
			}}/>
		</PopupLeftTitleContainer>
		<TitleLeftPromotionWrapper innerRef={props.setRef}>
			{props.promotions.map(p => {
				const isOpen = props.openIds[p.id];
				return <TitleLeftPromotion key={`Title[${p.id}]`} open={props.openIds[p.id]} periodsLength={p.periods.length}>
					<FlexDiv height='100%' width='100%' flow='column' align='stretch'>
						<TitleLeftPromotionTextWrapper>
							<TitleLeftPromotionText onClick={() => props.onEventClick?.({ promotion: p, mode: PopupMode.Default })}>
								{p.name}
							</TitleLeftPromotionText>
							<FlexDiv gap='1em'>
								<PartialOpen src={eye} onClick={() => props.onEventClick?.({ promotion: p, mode: PopupMode.Details })} parent={TitleLeftPromotionTextWrapper} />
								<Open isOpen={isOpen} onClick={() => props.setOpenIds(b => {
									b[p.id] = !(b[p.id] ?? false);
									return [...b];
								})} />
							</FlexDiv>
						</TitleLeftPromotionTextWrapper>
						<Collapse isOpen={isOpen}>
							{p.periods.map((period, i) => 
								<TitleLeftPromotionTextWrapper key={`PeriodName[${p.id}][${i}]`} child>
									<TitleLeftPromotionText onClick={() => props.onEventScroll(moment(period.start))}>
										{period.name}
									</TitleLeftPromotionText>
								</TitleLeftPromotionTextWrapper>
							)}
						</Collapse>
					</FlexDiv>
				</TitleLeftPromotion>;
			})}
		</TitleLeftPromotionWrapper>
	</PopupLeftDiv>;
}

function BodyContainer(props: {
	date: moment.Moment
	data: Promotion,
	dates: moment.Moment[],
	onEventClick?: (p: { promotion: Promotion, mode: PopupMode }) => void | undefined,
	onEventScroll: (start: moment.Moment) => void,
	dataOpen: boolean,
	dayDistance: number
}) {

	return <DataContainerDiv open={props.dataOpen} periodsLength={props.data.periods.length}>
		{props.data.periods.sort((a, b) => moment(a.start).diff(moment(b.start))).map((p, i) => <Event
			dayDistance={props.dayDistance}
			i={i + 1}
			open={props.dataOpen}
			key={`Event[${props.data.id}][${i}]`}
			data={p}
			dates={props.dates}
			onEventScroll={props.onEventScroll}
			date={props.date}
			id={props.data.id}
		/>)}
	</DataContainerDiv>;
}

function Background(props: {dates: moment.Moment[], bodyHeight: number, centralDate: moment.Moment, setNextDateScroll: React.Dispatch<React.SetStateAction<boolean>>, dayDistance: number, setRef: (node: HTMLElement) => void }) {
	return <BackgroundDiv height={props.bodyHeight}>
		{props.dates.map(d => <BackgroundDayElement dayDistance={props.dayDistance} key={`Background-Date[${d.toISOString()}]`} date={d} centralDate={props.centralDate} setNextDateScroll={props.setNextDateScroll}
			setRef={props.setRef}
		/>)}
	</BackgroundDiv>;
}

function BackgroundDayElement(props: { date: moment.Moment, centralDate: moment.Moment, setNextDateScroll: React.Dispatch<React.SetStateAction<boolean>>, dayDistance: number, setRef: (node: HTMLElement) => void}) {
	const ref = React.useRef<HTMLElement>();
	React.useEffect(() => {
		if (props.centralDate.format('L') === props.date.format('L') && ref.current) {
			props.setRef(ref.current);
			props.setNextDateScroll(b => {
				if (!b) return false;
				ref.current?.scrollIntoView({
					inline: 'center'
				});
				return false;
			});
		}
	}, [props.centralDate]);
	return <BodyContainerDiv weekEnd={props.dayDistance <= 1 && props.date.day() % 6 === 0} dayDistance={props.dayDistance} innerRef={React.useCallback((node: HTMLElement) => ref.current = node, [])} hasGap />;
}

function Event(props: {
	i: number,
	open: boolean,
	data: Period,
	dates: moment.Moment[],
	onEventScroll: (start: moment.Moment) => void,
	date: moment.Moment,
	id: number,
	dayDistance: number
}) {
	const { data, dates } = props;
	const ref = React.useRef<HTMLDivElement>();
	const [, overflowPosition] = useIsInViewport(ref, false, { left: -350 }, props.date);
	const setRef = React.useCallback(node => ref.current = node, []);
	const start = dates[0];
	const eventStart = moment(data.start).startOf('d');
	const eventEnd = moment(data.end).startOf('d');

	const scrollToEvent = () => props.onEventScroll(moment(data.start));
	const isDay = props.dayDistance == 1 / 24;

	if (eventEnd < eventStart) return <></>;
	const HourWidth = ((DAY_WIDTH / props.dayDistance) / 24);
	const MinuteWidth = HourWidth / 60;
	const startHour = (moment(data.start).hour() - 1) * HourWidth;
	const left = (eventStart.diff(start, 'd') + 1) * (DAY_WIDTH / props.dayDistance) + startHour + MinuteWidth * moment(data.start).minute();
	const width = (eventEnd.diff(eventStart, 'd') - (isDay ? 1 : 0)) * (DAY_WIDTH / props.dayDistance) + (moment(data.end).hour() - 1) * HourWidth - startHour + MinuteWidth * moment(data.end).minute();
	return <>
		<EventWrapper left={left} width={width} onClick={scrollToEvent} innerRef={setRef} color={data.color} i={props.open ? props.i : 0}>
			<EventText>
				{data.name}
			</EventText>
		</EventWrapper>
		<EventArrow left onClick={scrollToEvent} i={props.open ? props.i : 0} visible={overflowPosition === 'left'}/>
		<EventArrow onClick={scrollToEvent} i={props.open ? props.i : 0} visible={overflowPosition === 'right'}/>
	</>;
}

function HeaderContainer(props: {
	date: moment.Moment,
	today: string,
	i: number,
	dayDistance: number
}) {
	if (props.dayDistance > 1 && props.i % props.dayDistance !== 0) return <></>;
	const minus1 = moment(props.date).subtract(props.dayDistance, 'd');
	const firstDayOfMonth = minus1.month() !== props.date.month();
	const isDay = props.dayDistance === 1 / 24;
	const dates: moment.Moment[] = [];
	for (let i = 0; i < 24; i++) {
		const d = moment();
		d.set('hour', i + 1).set('minute', 0);
		dates.push(d);
	}

	return <HeaderContainerWrapper dayDistance={props.dayDistance}>
		{(firstDayOfMonth || isDay) && <MonthDiv dayDistance={props.dayDistance} currentDay={isDay ? 0 : moment(props.date).date() - 1}>
			{props.date.format(isDay ? 'LL' : 'MMMM YYYY')}
		</MonthDiv>}
		<HeaderContainerDiv dayDistance={props.dayDistance} hasGap={!isDay}>
			{isDay
				? dates.map((d, i) => <HourDiv key={`Hours[${i}][${props.date.toISOString()}]`}>
					{d.format('LT')}
				</HourDiv>)
				: <>
					{props.dayDistance < 1 && <InnerHeaderDiv>
						{props.date.format('dd')[0].toUpperCase()}
					</InnerHeaderDiv>}
					<InnerHeaderDiv today={props.today === props.date.format('L')}>
						{props.date.format('D')}
					</InnerHeaderDiv>
				</>
			}
		</HeaderContainerDiv>
	</HeaderContainerWrapper>;
}