import jwtDecode from 'jwt-decode';

function getCookie(cname) {
	const name = cname + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function getCurrentUserId() {
	const token = getCookie('id_token');
	const decodedToken = jwtDecode(token);
	if (decodedToken == undefined) {
		return 0;
	}
	return decodedToken.id;
}

export function getCurrentUser() {
	const token = getCookie('id_token');
	const decodedToken = jwtDecode(token);
	if (decodedToken == undefined) {
		return 0;
	}
	return decodedToken;
}