import { ObjectAction, Scope } from '../../../../web-types/objectActions';

export interface Group {
  id: number
  name: string
  user_count: number
}

export interface AuthObjectActions {
  id: number
  company_id: number
  group_id: number
  group_name: string
  object_action_id: number
  action_id: number
  action_name: string
  object_id: number
  object_name: string
  limited_to_ownership: boolean
  created_at: string
  updated_at?: string
  created_by: number
  updated_by?: string
  scope_name: string
  scope_id: number
}

export interface User {
  id: number
  name: string
  email: string
  phone: string
  photo: string
  role_id: number
  role_name: string
  to_remove: boolean
}

export interface MemberRow {
  count: number
  id: number
  name: string
  photo?: string
  status: boolean
  email?: string
  phone?: string
  group_info: any[]
  is_owner: boolean
  edit?: string
  error?: string[]
}

export interface ObjectActionScope {
  groupId: number
  policyId: number | undefined
  objectAction?: ObjectAction
  scope?: Scope
  toRemove: boolean
}

export interface GroupDetailResult {
  group: Group
  policies: ObjectActionScope[]
  members: User[]
  groupName: string
}

export interface Policy {
  id: number
  plan_id: number
  plan_name: string
  object_action_id: number
  object_id: number
  object_name: string
  action_id: number
  action_name: string
}

export interface AuthPolicy {
  Plan: Policy[]
}

export interface CererusAuthPolicy {
  id: number
  company_id: number
  group_id: number
  group_name: string
  object_action: ObjectAction
  scope: Scope
  created_at: Date
  updated_at: Date
  created_by: number
  updated_by?: number
}

export const DEFAULT_STEP = 50;

export interface PlanPolicy {
  id: number
  plan_id: number
  plan_name: string
  object_action: ObjectAction
}
