import { Image } from '../../../../../components_v2/avatar/style/Style';
import * as React from 'react';
import { Container } from '../../../../client-companies/popup/LinkedElements/style/LinkedElementStyle';
import { FlexDiv } from '../../../../products/style';
import { translateToString } from '../../../../../styles/global/translate';
import { NoDataText } from '../../../../client-companies/style/Style';
import styled from 'styled-components';
import { DefaultText } from '../../../../../styles/global/css/GlobalText';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Section = styled.div`
  margin-left: 33px;
`;
const Subtitle = styled.div`
  ${DefaultText};
  margin: 10px 0;
`;

enum PictureType {
	BEGINNING,
	END,
	UNDEFINED,
}

export default function ShelfAuditPictures({ beginning_pictures, end_pictures, isFullOpen, onDisableClose }: {
	beginning_pictures: string[],
	end_pictures: string[],
	isFullOpen: boolean,
	onDisableClose: (val: boolean) => void
}) {
	const [pictureType, setPictureType] = useState<PictureType>(PictureType.UNDEFINED);
	const [current, setCurrent] = useState<number>(0);
	const [isOpen, setIsOpen] = useState(false);

	function getAttributes() {
		const currentPictures = (pictureType === PictureType.BEGINNING ? beginning_pictures : end_pictures);
		return {
			mainSrc: currentPictures[current],
			nextSrc: currentPictures[current + 1],
			prevSrc: currentPictures[current - 1],
		};
	}


	useEffect(() => {
		onDisableClose(isOpen);
	}, [isOpen]);
	return <Container flexDirection="column">
		<Section>
			<Subtitle>{translateToString('shelf_audit.linked_elements.before')}:</Subtitle>
			{beginning_pictures?.length > 0
				? <FlexDiv gap="20px">
					{beginning_pictures.length > 0 && beginning_pictures.map((url, i) =>
						<Image
							key={`beginning_pictures[${i}]`}
							width={isFullOpen ? '160px' : '100px'}
							url={url}
							onClick={() => {
								setPictureType(PictureType.BEGINNING);
								setCurrent(i);
								setIsOpen(true);
							}}/>)
					}
				</FlexDiv>
				: <NoDataText>{translateToString('shelf_audit.no_data.pictures')}</NoDataText>
			}
		</Section>
		<Section>
			<Subtitle>{translateToString('shelf_audit.linked_elements.after')}:</Subtitle>
			{end_pictures?.length > 0
				? <FlexDiv gap="20px">
					{end_pictures.length > 0 && end_pictures.map((url, i) =>
						<Image
							key={`end_pictures[${i}]`}
							width={isFullOpen ? '160px' : '100px'} url={url}
							onClick={() => {
								setPictureType(PictureType.END);
								setCurrent(i);
								setIsOpen(true);
							}}
						/>
					)}
				</FlexDiv>
				: <NoDataText>{translateToString('shelf_audit.no_data.pictures')}</NoDataText>
			}
		</Section>
		{isOpen && pictureType !== PictureType.UNDEFINED &&
			<Lightbox
				{...getAttributes()}
				onCloseRequest={() => {
					onDisableClose(false);
					setIsOpen(false);
				}}
				onMovePrevRequest={() => setCurrent(current - 1)}
				onMoveNextRequest={() => setCurrent(current + 1)}
			/>
		}
	</Container>;
}
